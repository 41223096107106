import {Component, ElementRef, Input} from '@angular/core';
import {PortvisitUtils} from "../../refdata/portvisit-utils";
import {checkValidity, sendCommand} from '../../common/utils';
import {AppContext} from '../../app-context';
import {
  CancelCommercialRelease,
  CommercialRelease,
  DeclareCommercialRelease
} from "@portbase/bezoekschip-service-typescriptmodels";

@Component({
  selector: 'app-commercial-release-declaration',
  templateUrl: './commercial-release-declaration.component.html',
  styleUrls: ['./commercial-release-declaration.component.css']
})
export class CommercialReleaseDeclarationComponent {
  appContext = AppContext;
  refData = PortvisitUtils;

  @Input() release: CommercialRelease;

  constructor(private element: ElementRef) {
  }

  send(collapseContent: HTMLDivElement) {
    if (checkValidity(this.element)) {
      sendCommand('com.portbase.bezoekschip.common.api.commercialrelease.DeclareCommercialRelease', <DeclareCommercialRelease>{
        releaseData: this.release.releaseData,
        id: this.release.id,
        crn: this.release.crn
      }, () => {
        AppContext.registerSuccess('The declaration was sent successfully.');
        $(collapseContent).collapse("hide");
      });
    }
  }

  cancel() {
    sendCommand('com.portbase.bezoekschip.common.api.commercialrelease.CancelCommercialRelease',
      <CancelCommercialRelease>{id: this.release.id},
      () => AppContext.registerSuccess('The declaration was cancelled successfully.'));
  }
}
