<ng-container>

  <div>
    <b>{{ name }}</b>
    <app-info class="formGroupLabel" [wide]="false" [placement]="'left'">
      <div class="tooltipContent">
        <b>Considering</b>
        <ul>
          <div *ngFor="let consider of splitConsidering()">
            <li>
              {{consider}}
            </li>
          </div>
        </ul>
      </div>
    </app-info>
  </div>

  <app-visit-exemptions-exemption-general [exemption]="exemption"></app-visit-exemptions-exemption-general>

  <ng-container>
    <div class="row fieldset">
      <div class="col-md">
        <app-form-group label="Penetration Depth Of Spudcans (cm)">
          <input [(ngModel)]="exemption['penetrationDepthOfSpudcans']" id="penetrationDepthOfSpudcans" type="number" rows="3" maxlength="5" class="form-control" required>
        </app-form-group>
      </div>
      <div class="col-md">
        <app-form-group label="Diameter Of Spudcans (cm)">
          <input [(ngModel)]="exemption['diameterOfSpudcans']" id="diameterOfSpudcans" type="number" rows="3" maxlength="5" class="form-control" required>
        </app-form-group>
      </div>
    </div>
    <div class="row fieldset">
      <div class="col-md">
        <app-form-group>
          <app-info class="formGroupLabel">
            <label>Soil Survey Conducted</label>
            <div class="tooltipContent">Prior to the request, a soil survey must be carried out by the operator to determine the presence of culverts, cables, pipes, etc. in the soil.</div>
          </app-info>
          <input [(ngModel)]="exemption['soilSurveyConducted']" id="soilSurveyConducted" type="checkbox" class="form-check-input" required>
        </app-form-group>
      </div>
      <div class="col-md">
      </div>
    </div>
    <div class="row fieldset">
      <div class="col-md">
        <app-form-group label="Reason for request for exemption">
          <textarea [(ngModel)]="exemption['reason']" id="reason" type="text" rows="3" maxlength="1024" class="form-control" required></textarea>
        </app-form-group>
      </div>
      <div class="col-md">
        <app-form-group label="Details">
          <textarea [(ngModel)]="exemption['details']" id="details" type="text" rows="3" maxlength="256" class="form-control"></textarea>
        </app-form-group>
      </div>
    </div>
  </ng-container>
</ng-container>
