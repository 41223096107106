import {Component} from '@angular/core';
import {
  DangerInformation,
  Exemption_4_13_2,
  Exemption_4_13_2_StorageType,
  Exemption_4_13_2_TransshipmentType,
} from '@portbase/bezoekschip-service-typescriptmodels';
import {ExemptionBaseComponent} from "../exemption_base/exemption_base.component";
import {PortvisitUtils} from "../../../../../refdata/portvisit-utils";

@Component({
  selector: 'app-visit-exemptions-exemption-4-13-2',
  templateUrl: './exemption_4_13_2.component.html',
  styleUrls: ['./exemption_4_13_2.component.css']
})
export class Exemption_4_13_2Component extends ExemptionBaseComponent {

  exemptionSubType: Exemption_4_13_2;
  storageTypes: Exemption_4_13_2_StorageType[] = [null, Exemption_4_13_2_StorageType.TEMPORARY, Exemption_4_13_2_StorageType.DIRECT_REMOVAL];
  transshipmentTypes: Exemption_4_13_2_TransshipmentType[] = [null, Exemption_4_13_2_TransshipmentType.MOBILE_CRANE, Exemption_4_13_2_TransshipmentType.OTHER];

  storageTypesFormatter = (storageType: Exemption_4_13_2_StorageType) => {
    switch (storageType) {
      case null:
        return 'None';
      case "TEMPORARY":
        return 'Yes';
      case "DIRECT_REMOVAL":
        return 'No, direct removal with truck';
    }
  };

  transshipmentTypesFormatter = (transshipmentType: Exemption_4_13_2_TransshipmentType) => {
    switch (transshipmentType) {
      case null:
        return 'None';
      case "MOBILE_CRANE":
        return 'Mobile crane';
      case "OTHER":
        return 'Other';
    }
  };

  dangerousGoods?: DangerInformation
  portVisitUtils = PortvisitUtils;

  onDangerousGoodsChange(dangerousGoods: DangerInformation) {
    this.exemption['substanceName'] = dangerousGoods.name;
    this.exemption['unVnNumber'] = dangerousGoods.unCode;
  }
}

