import {Observable, Subscriber} from "rxjs";

export function toBase64(file: File): Observable<String> {
  const reader: FileReader = new FileReader();
  reader.readAsArrayBuffer(file);
  return new Observable((s: Subscriber<String>) => {
    reader.onload = (e: any) => {
      let binary = '';
      const bytes = new Uint8Array(e.target.result);
      const length = bytes.byteLength;
      for (let i = 0; i < length; i++) {
        binary += String.fromCharCode(bytes[i]);
      }
      s.next(btoa(binary));
      s.complete();
    };
  });
}
