import {Component, Input} from '@angular/core';
import {Berth, ExemptionReferenceData} from '@portbase/bezoekschip-service-typescriptmodels';
import {sendQuery} from '../../common/utils';

@Component({
  selector: 'app-edit-exemption',
  templateUrl: './edit-exemption.component.html',
  styleUrls: ['./edit-exemption.component.scss']
})
export class EditExemptionComponent {
  @Input() exemptionInfo: ExemptionReferenceData;
  municipalities = [
    "Dordrecht",
    "Gorinchem",
    "Moerdijk",
    "Nijmegen",
    "Papendrecht",
    "Rotterdam",
    "Schiedam",
    "Stellendam",
    "Vlaardingen",
    "Zwijndrecht",
  ]

  exemptionTypes : string[] = [
    "Exemption 3.2",
    "Exemption 3.4",
    "Exemption 3.5",
    "Exemption 3.6",
    "Exemption 3.11",
    "Exemption 3.13",
    "Exemption 4.1",
    "Exemption 4.5",
    "Exemption 4.6",
    "Exemption 4.11",
    "Exemption 4.11b",
    "Exemption 4.13 (1)",
    "Exemption 4.13 (2)",
    "Exemption 5.2",
    "Exemption 5.3",
    "Exemption 5.4, 5.5, 5.6",
    "Exemption 6.3.1",
    "Exemption 6.3.3, 6.3.4",
    "Exemption 6.3.5",
    "Exemption 6.4",
    "Exemption 6.5",
    "Exemption 7.1 lid 1",
    "Exemption 7.1 lid 2",
    "Exemption 8.1"
  ]

  berthSearchFunction = term => {
    return sendQuery('com.portbase.bezoekschip.common.api.visit.FindBerthsAndBuoys', {term: term, untyped: true},
      {caching: false});
  }

  berthFormatter = (value: Berth) => value.name + ' – ' + value.code +
(value.bollards ? ' (' + value.bollards.start + '-' + value.bollards.end	 + ')': '');

  get municipalityNotSelected(): boolean {
    return this.exemptionInfo.municipalities == null || this.exemptionInfo.municipalities.length == 0;
  }

  isMunicipalitySelected(consideringMunicipality: string): boolean {
    return this.exemptionInfo.municipalities?.includes(consideringMunicipality);
  }
}
