import {Component} from '@angular/core';
import {ExemptionBaseComponent} from "../exemption_base/exemption_base.component";
import {Exemption_3_6, Exemption_3_6_ReasonType} from '@portbase/bezoekschip-service-typescriptmodels';

@Component({
  selector: 'app-visit-exemptions-exemption-3-6',
  templateUrl: './exemption_3_6.component.html',
  styleUrls: ['./exemption_3_6.component.css']
})
export class Exemption_3_6Component extends ExemptionBaseComponent {

  exemptionSubType: Exemption_3_6;
  reasonTypes: Exemption_3_6_ReasonType[] = [null, Exemption_3_6_ReasonType.PUSH_PULL_TEST, Exemption_3_6_ReasonType.OTHER];

  reasonTypesFormatter = (reasonType: Exemption_3_6_ReasonType) => {
    switch (reasonType) {
      case null:
        return 'None';
      case "PUSH_PULL_TEST":
        return 'Perform push/pull test';
      case "OTHER":
        return 'Other';
    }
  };
}

