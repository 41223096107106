<div *ngIf="declaration.releaseData as data" [ngClass]="{'fieldset disabled' :
     (!appContext.isCommercialReleaseDeclarant() && !appContext.isCommercialReleaseEditor())
     || declaration.releaseStatus?.status =='DECLARED' || appContext.isHinterlandTerminal()}">
  <div class="row">
    <div class="col-md">
      <app-form-group label="Id" *ngIf="declaration.id && !!declaration.releaseStatus?.status">
        <input required class="form-control" [ngModel]="declaration.id" disabled>
      </app-form-group>
      <app-form-group label="Consignment number">
        <app-search required [ngModel]="data.consignmentNumber" [minCharacters]="3"
                    [searchFunction]="refData.findAllConsignments" resultFormatter="masterConsignment.consignmentNumber"
                    (ngModelChange)="selectConsignment($event)"
                    newValueFromInputFunction="masterConsignment.consignmentNumber"
                    [disabled]="!!declaration.releaseStatus?.status">
        </app-search>
      </app-form-group>

      <ng-container *ngIf="data.consignmentNumber">
        <ng-container *ngIf="selectedConsignment?.crn; else noMli">
          <app-form-group label="Container number" *ngIf="data.consignmentNumber">
            <app-select placeholder="– Select a container number –" [(ngModel)]="data.equipmentNumber" required
                        [addSelectedIfNotExists]="true"
                        [disabled]="!!declaration.releaseStatus?.status"
                        [refreshWatch]="selectedConsignment" [refreshProvider]="containersInConsignment"></app-select>
          </app-form-group>
          <app-form-group label="Container operator">
            <app-select [(ngModel)]="data.containerOperator" [addSelectedIfNotExists]="true"
                        [refreshProvider]="carriersProvider" [refreshWatch]="selectedConsignment?.iamConnectedId
                    || data.containerOperator?.cargoDeclarantShortName" dataKey="smdgCode" required
                        [formatter]="refData.containerOperatorFormatter" placeholder="– Select a container operator –">
            </app-select>
          </app-form-group>
          <app-form-group label="Terminal">
            <app-select disabled required [ngModel]="data.terminalShortName" [options]="terminals"
                        [addSelectedIfNotExists]="true"></app-select>
          </app-form-group>
          <app-form-group label="CRN">
            <input class="form-control" [ngModel]="declaration.crn" disabled>
          </app-form-group>
        </ng-container>

        <ng-template #noMli>
          <app-form-group label="Container number">
            <input class="form-control" [(ngModel)]="data.equipmentNumber"
                   required [disabled]="!!declaration.releaseStatus?.status"
                   (change)="data.equipmentNumber=$event.target?.value?.toUpperCase()">
          </app-form-group>
          <app-form-group label="Container operator">
            <app-search [(ngModel)]="data.containerOperator" dataKey="name"
                        [searchFunction]="refData.findContainerOperators" required
                        [inputFormatter]="refData.containerOperatorFormatter"
                        [disabled]="!!declaration.releaseStatus?.status"></app-search>
          </app-form-group>
          <app-form-group label="Terminal">
            <app-select required [(ngModel)]="data.terminalShortName" [options]="terminals"
                        [addSelectedIfNotExists]="true" [disabled]="!!declaration.releaseStatus?.status"></app-select>
          </app-form-group>
          <app-form-group label="CRN">
            <input class="form-control" [(ngModel)]="declaration.crn" [disabled]="!!declaration.releaseStatus?.status"
                   (change)="declaration.crn=$event.target?.value?.toUpperCase()">
          </app-form-group>
        </ng-template>
      </ng-container>
    </div>

    <div class="col-md">
      <app-form-group label="Release-to party">
        <app-search required [(ngModel)]="data.releaseToParty" [minCharacters]="0"
                    [searchFunction]="findReleaseToParties" [inputFormatter]="refData.formatReleaseToParty"
                    [disabled]="!!declaration.releaseStatus?.status"></app-search>
      </app-form-group>
      <app-form-group label="Inland operator">
        <app-search [(ngModel)]="data.inlandOperator" [minCharacters]="3"
                    [searchFunction]="refData.findInlandOperatorsByEanCode"
                    [inputFormatter]="refData.inlandOperatorFormatter"
                    [disabled]="!!declaration.releaseStatus?.status">
                    (ngModelChange)="data.inlandOperator = $event" placeholder="Search by EAN code"
                    [resultFormatter]="refData.inlandOperatorFormatter">
        </app-search>
      </app-form-group>
      <app-form-group label="Release deadline">
        <app-date-field [defaultTime]="'12:00'" [(ngModel)]="data.releaseDeadline"></app-date-field>
      </app-form-group>
      <app-form-group label="Release modality">
        <app-select placeholder="No preference" [nullOption]="true" [options]="['barge', 'road', 'rail']"
                    [(ngModel)]="data.releaseModality"></app-select>
      </app-form-group>
      <app-form-group label="Remarks">
        <textarea class="form-control" [(ngModel)]="data.remarks"></textarea>
      </app-form-group>
    </div>
  </div>
</div>
