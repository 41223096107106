import {
  NonNegativeQuantityField,
  parseExcel,
  RequiredField,
  ValidatedField,
  WorkBookTemplate
} from '../../common/upload/excel.utils';
import {
  toBase64
} from '../../common/upload/upload.utils';
import {clearValidation} from '../../common/utils';
import {VisitContext} from '../visit-context';
import {AppContext} from '../../app-context';

const shipsStoresTemplate: WorkBookTemplate = {
  sheets: [
    {
      name: "Ship's stores",
      template: {
        items: {
          SPIRITS: new NonNegativeQuantityField("B6", 0),
          BEER: new NonNegativeQuantityField("B7", 0),
          WINE: new NonNegativeQuantityField("B8", 0),
          OTHER: new NonNegativeQuantityField("B9", 0),
          CIGARETTES: new NonNegativeQuantityField("B10", 0),
          CIGARS: new NonNegativeQuantityField("B11", 0),
          TOBACCO: new NonNegativeQuantityField("B12", 0),
          FUEL_OIL: new NonNegativeQuantityField("B13", 0),
          LUBRICATING_OIL: new NonNegativeQuantityField("B14", 0),
          MEAT: new NonNegativeQuantityField("B15", 0),
          NARCOTICS: new NonNegativeQuantityField("B16", 0),
          FIRE_ARMS: new NonNegativeQuantityField("B17", 0)
        }
      }
    },
    {
      name: "General",
      template: {
        imoNumber: new ValidatedField(new RequiredField('B4'),
          value => {
            const expectedImo = VisitContext.visit.vessel.imoCode;
            if (String(value) !== expectedImo) {
              throw 'The IMO code in the Excel file (' + value + ') does not match IMO code of the vessel (' + expectedImo + ').';
            }
          }),
        version: new ValidatedField(new RequiredField('B6'),
          value => {
            if (value !== '1.1') {
              throw 'The version of your Excel file is not supported. Please download the latest template and try again.';
            }
          })
      }
    },
    {
      name: 'Verificatie',
      template: {
        verificationUuid: new ValidatedField(new RequiredField('A1'),
          value => {
            if (value !== '1e32c1b492c76425b63bee170f6ac40766ec06c9') {
              throw 'Your Excel file could not be verified. Please download the latest template and try again.';
            }
          })
      }
    }
  ]
};

export function uploadShipsStoresForm(excelFile: File) {
  clearValidation(this.element);
  AppContext.clearAlerts();
  toBase64(excelFile).subscribe(value => this.uploadedXls = value);
  parseExcel(excelFile, shipsStoresTemplate).subscribe(model => this.context.visit.shipStoresDeclaration.items = model.items)
}
