import {Component} from '@angular/core';
import {ExemptionBaseComponent} from "../exemption_base/exemption_base.component";
import {Exemption_3_13} from '@portbase/bezoekschip-service-typescriptmodels';

@Component({
  selector: 'app-visit-exemptions-exemption-3-13',
  templateUrl: './exemption_3_13.component.html',
  styleUrls: ['./exemption_3_13.component.css']
})
export class Exemption_3_13Component extends ExemptionBaseComponent {
  exemptionSubType: Exemption_3_13;
}

