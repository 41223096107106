import {Component} from "@angular/core";
import {Observable} from "rxjs";
import {BunkerProduct, FuelExemption, RequestExemption} from "@portbase/bezoekschip-service-typescriptmodels";
import {BunkeringService} from "../../bunkering.service";
import {closeEditModal, sendCommand} from "../../../common/utils";

@Component({
  selector: 'app-edit-fuel-exemption',
  templateUrl: './edit-fuel-exemption.html',
  styleUrls: []
})
export class EditFuelExemption {
  data: FuelExemption;

  products(term: string): Observable<BunkerProduct[]> {
    return BunkeringService.products(term);
  }

  formatProduct(product: BunkerProduct) {
    return product.type + ' (' + product.provisionType + ') ' + ' (' + product.density + ') ' + product.unitOfMeasure;
  }

  cancel() {
    closeEditModal();
  }

  send(): void {
    sendCommand('com.portbase.bezoekschip.common.api.visit.exemption.RequestExemption',
      <RequestExemption>{"exemption": this.data},
      () => closeEditModal());
  }
}
