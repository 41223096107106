import {Component, Input} from '@angular/core';
import {AppContext} from "../../../../app-context";
import moment from "moment";
import {
  IE3ContainerProcessSummary,
  IE3ContainerStatus,
  IE3RefreshContainerProcessSummary,
  TaskMessageStatus
} from "@portbase/bezoekschip-service-typescriptmodels";
import {ContainerUtils} from "../../container.utils";
import {CheckboxSelectionState, sendCommand} from "../../../../common/utils";
import {DeclarationMessageStatus} from "../../../visit-overview/visit-overview-item/visit-overview.utils";
import {PortvisitUtils} from "../../../../refdata/portvisit-utils";

@Component({
  selector: 'app-container-overview-item',
  templateUrl: './container-overview-item.component.html',
  styleUrls: ['./container-overview-item.component.scss']
})
export class ContainerOverviewItemComponent {
  utils = ContainerUtils;
  appContext = AppContext;

  container: IE3ContainerProcessSummary;
  @Input() term: string;
  @Input() depth: number;
  @Input() collapsible: boolean;

  selectionWidth: number = 40;
  paddingPerStep: number = 20;
  collapsed: boolean = true;

  protected _dischargeStatus: DeclarationMessageStatus;
  protected _dischargeStatuses: DeclarationMessageStatus[];
  protected _dischargeListStatus: DeclarationMessageStatus;

  @Input("container")
  set setContainer(container: IE3ContainerProcessSummary) {
    this.container = container;
    this._dischargeStatus = this.getDischargeStatus();
    this._dischargeStatuses = [this._dischargeStatus];
    this._dischargeListStatus = this.getDischargeListStatus();
  }

  formatDate = (dateString: string): string => !!dateString ? moment(dateString).format("DD MMM HH:mm") : null;

  get selectable() {
    return this.container.consignmentDataSummaries.length == 0;
  }

  get indentionDepth() {
    return this.depth * this.paddingPerStep;
  }

  get selectionState(): CheckboxSelectionState {
    return this.container["selected"];
  }

  set selectionState(value: CheckboxSelectionState) {
    this.container["selected"] = value;
  }

  getDischargeStatus = (): DeclarationMessageStatus => {
    return {
      name: "Discharge details",
      taskStatus: this.getDischargeTaskStatus(),
      messages: this.container.terminalDataSummary?.actualTimeOfHandling
        ? [`Discharged at: ${this.formatDate(this.container.terminalDataSummary?.actualTimeOfHandling)}`] : [],
      customLabel: this.  getDischargeLabel()
    }
  }

  private getDischargeTaskStatus(): TaskMessageStatus {
    switch (this.container.status) {
      case IE3ContainerStatus.DISCHARGED:
        return !this.container.consignmentDataSummaries?.length ? TaskMessageStatus.ERROR : TaskMessageStatus.ACCEPTED;
      case IE3ContainerStatus.EXPECTED:
        return TaskMessageStatus.UNKNOWN;
    }
  }

  getDischargeListStatus = (): DeclarationMessageStatus => {
    if (this.container.dischargeListData != null) {
      return {
        name: "Discharge list details",
        taskStatus: TaskMessageStatus.ACCEPTED,
        messages: [`Sent in discharge list to terminal: ${this.container.dischargeListData.terminalname} - ${this.container.dischargeListData.terminalCode}`],
        customLabel: 'Listed'
      }
    }
    return null;
  }

  private getDischargeLabel(): string {
    switch (this.container.status) {
      case IE3ContainerStatus.DISCHARGED:
        return !this.container.consignmentDataSummaries?.length ? "Overlanded" : "Discharged";
      default:
        return PortvisitUtils.enumFormatter(this.container.status);
    }
  }

  validSelector = (input: string): string => "SELECTOR_" + input.replace(/[^a-zA-Z0-9]/g, '');

  refreshSummary = () =>
    sendCommand('com.portbase.bezoekschip.common.api.consignments.commands.RefreshContainerProcessSummary', <IE3RefreshContainerProcessSummary>{
      containerProcessId: this.container.containerProcessId
    }, (summary: IE3ContainerProcessSummary) => {
      this.container = summary.removed ? null : summary;
      AppContext.registerSuccess('Container summary has been rebuild successfully.');
    });
}
