<ng-container>

  <div>
    <b>{{ name }}</b>
    <app-info class="formGroupLabel" [wide]="false" [placement]="'left'">
      <div class="tooltipContent">
        <b>Considering</b>
        <ul>
          <div *ngFor="let consider of splitConsidering()">
            <li>
              {{consider}}
            </li>
          </div>
        </ul>
      </div>
    </app-info>
  </div>

  <app-visit-exemptions-exemption-general [exemption]="exemption"></app-visit-exemptions-exemption-general>

  <ng-container>
    <div class="row fieldset">
      <div class="col-md">
        <app-form-group label="Type of cargo">
          <input [(ngModel)]="exemption['typeOfCargo']" id="typeOfCargo" type="text" maxlength="256" class="form-control" required>
        </app-form-group>
      </div>
      <div class="col-md">
        <app-form-group label="Decontamination company name">
          <input [(ngModel)]="exemption['decontaminationCompanyName']" id="decontaminationCompanyName" type="text" maxlength="256" class="form-control" required>
        </app-form-group>
      </div>
    </div>
    <div class="row fieldset">
      <div class="col-md">
        <app-form-group label="Name of gassing contractor foreman">
          <input [(ngModel)]="exemption['nameOfGassingContractorForeman']" id="nameOfGassingContractorForeman" type="text" maxlength="256" class="form-control" required>
        </app-form-group>
      </div>
      <div class="col-md">
        <app-form-group label="Decontamination company address">
          <textarea [(ngModel)]="exemption['decontaminationCompanyAddress']" id="decontaminationCompanyAddress" type="text" rows="3" maxlength="1024" class="form-control" required></textarea>
        </app-form-group>
      </div>
    </div>
    <div class="row fieldset">
      <div class="col-md">
        <app-form-group label="Decontamination company phone number">
          <input [(ngModel)]="exemption['decontaminationCompanyPhoneNumber']" id="decontaminationCompanyPhoneNumber" type="text" maxlength="256" class="form-control" required>
        </app-form-group>
      </div>
      <div class="col-md">
        <app-form-group label="Disinfectants to be used">
          <input [(ngModel)]="exemption['disinfectantsToBeUsed']" id="disinfectantsToBeUsed" type="text" maxlength="256" class="form-control" required>
        </app-form-group>
      </div>
    </div>
    <div class="row fieldset">
      <div class="col-md">
        <app-form-group label="Name and owner of service vessel (if being used)">
          <input [(ngModel)]="exemption['nameAndOwnerOfVessel']" id="nameAndOwnerOfVessel" type="text" maxlength="256" class="form-control" required>
        </app-form-group>
      </div>
      <div class="col-md">
      </div>
    </div>
    <div class="row fieldset">
      <div class="col-md">
        <app-form-group>
          <app-info class="formGroupLabel">
            <label>From</label>
            <div class="tooltipContent">Duration of decontamination activities, applying gas pressure or venting</div>
          </app-info>
          <app-date-field [(ngModel)]="exemption['from']" id="from" required></app-date-field>
        </app-form-group>
      </div>
      <div class="col-md">
      </div>
    </div>
    <div class="row fieldset">
      <div class="col-md">
        <app-form-group>
          <app-info class="formGroupLabel">
            <label>To</label>
            <div class="tooltipContent">Duration of decontamination activities, applying gas pressure or venting</div>
          </app-info>
          <app-date-field [(ngModel)]="exemption['to']" id="to" required></app-date-field>
        </app-form-group>
      </div>
      <div class="col-md">
      </div>
    </div>
    <div class="row fieldset">
      <div class="col-md">
        <app-form-group label="Reason for request for exemption">
          <textarea [(ngModel)]="exemption['reason']" id="reason" type="text" rows="3" maxlength="1024" class="form-control" required></textarea>
        </app-form-group>
      </div>
      <div class="col-md">
        <app-form-group label="Details">
          <textarea [(ngModel)]="exemption['details']" id="details" type="text" rows="3" maxlength="256" class="form-control"></textarea>
        </app-form-group>
      </div>
    </div>
  </ng-container>
</ng-container>
