import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  NgZone,
  OnDestroy,
  ViewChild
} from '@angular/core';
import {VisitContext} from '../visit-context';
import {
  cast,
  checkValidity,
  clearValidation,
  cloneObject,
  dispatchChangeEvent,
  sendCommand,
  wrapCommand
} from '../../common/utils';
import {
  CompleteDangerousGoods,
  DangerousGoods,
  DangerousGoodsDeclaration,
  DeclarationType,
  Loading,
  SaveDangerousGoods,
  SaveNextDangerousGoodsDeclaration
} from '@portbase/bezoekschip-service-typescriptmodels';
import {AppContext} from '../../app-context';
import {ActivatedRoute} from '@angular/router';
import {toBase64} from '../../common/upload/upload.utils';
import {uploadDangerousGoods} from './dangerous-goods.upload';
import {DangerousGoodsModel, sortStowage} from './dangerous-goods.model';

@Component({
  selector: 'app-dangerous-goods',
  templateUrl: './dangerous-goods.component.html',
  styleUrls: ['./dangerous-goods.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DangerousGoodsComponent implements OnDestroy {
  DANGEROUS_GOODS = DeclarationType.DANGEROUS_GOODS;

  context = VisitContext;
  appContext = AppContext;
  uploadedXls: String;
  dangerousGoods: DangerousGoodsModel;

  cargoAgent: string;

  @ViewChild('outerDiv') outerChild;

  constructor(private element: ElementRef, route: ActivatedRoute, private zone: NgZone,
              private changeDetector: ChangeDetectorRef) {
    route.params.subscribe(params => {
      initializeDeclaration(this.cargoAgent = params['shortName']);
      this.dangerousGoods = <any>VisitContext.dangerousGoodsDeclaration.dangerousGoods;
      setTimeout(() => this.changeDetector.detectChanges(), 0);
    });
  }

  ngOnDestroy(): void {
    VisitContext.dangerousGoodsDeclaration = undefined;
  }

  upload = (file: File) => {
    clearValidation(this.element);
    AppContext.clearAlerts();
    toBase64(file).subscribe(value => this.uploadedXls = value);
    uploadDangerousGoods(file).subscribe(dangerousGoods => {
      this.zone.runOutsideAngular(() => {
        VisitContext.dangerousGoodsDeclaration.dangerousGoods = new DangerousGoodsModel(dangerousGoods, this.cargoAgent);
        dispatchChangeEvent(this.outerChild.nativeElement);
        setTimeout(() => this.changeDetector.detectChanges(), 0);
      });
    }, error => {
      setTimeout(() => this.changeDetector.detectChanges(), 0);
    });
  };

  save = () => {
    clearValidation(this.element);
    let command = <SaveDangerousGoods>{
      crn: this.context.visit.crn,
      dangerousGoods: this.context.dangerousGoodsDeclaration.dangerousGoods,
      cargoDeclarantShortName: this.context.dangerousGoodsDeclaration.cargoDeclarant.shortName
    };
    sendCommand('com.portbase.bezoekschip.common.api.dangerousgoods.SaveDangerousGoods', this.addExcelUpload(command), () => {
      this.context.replaceVisit(this.context.visit);
      initializeDeclaration(this.cargoAgent);
      AppContext.registerSuccess('The declaration was saved successfully.');
    });
  };

  saveNextDangerousGoodsDeclaration = () => {
    clearValidation(this.element);
    let command = <SaveNextDangerousGoodsDeclaration>{
      crn: this.context.visit.crn,
      nextDangerousGoodsDeclaration: cast(this.context.dangerousGoodsDeclaration)
    };
    sendCommand('com.portbase.bezoekschip.common.api.visit.SaveNextDangerousGoodsDeclaration', this.addExcelUpload(command), () => {
      this.context.visit.nextDangerousGoodsDeclaration = cast(this.context.dangerousGoodsDeclaration);
      this.context.replaceVisit(this.context.visit);
      initializeDeclaration(this.cargoAgent);
      AppContext.registerSuccess('The declaration was saved successfully.');
    });
  };

  complete = () => {
    if (checkValidity(this.element)) {
      let command = <CompleteDangerousGoods>{
        crn: this.context.visit.crn,
        dangerousGoods: this.context.dangerousGoodsDeclaration.dangerousGoods,
        cargoDeclarantShortName: this.context.dangerousGoodsDeclaration.cargoDeclarant.shortName
      };
      sendCommand('com.portbase.bezoekschip.common.api.dangerousgoods.CompleteDangerousGoods', this.addExcelUpload(command), () => {
        this.context.dangerousGoodsDeclaration.completed = true;
        this.context.dangerousGoodsDeclaration.declared = false;
        this.context.replaceVisit(this.context.visit);
        setTimeout(() => AppContext.registerSuccess('The declaration was completed successfully.'), 100);
        if (VisitContext.isOrganisationOnlyCargoDeclarant()) {
          history.go(-2);
        } else {
          history.back();
        }
      });
    }
  };

  cancel = () => {
    sendCommand('com.portbase.bezoekschip.common.api.dangerousgoods.CompleteDangerousGoods',
      <CompleteDangerousGoods>{
        crn: VisitContext.visit.crn,
        dangerousGoods: <DangerousGoods>{goods: [], stowageAtArrival: [], handlings: []},
        cargoDeclarantShortName: this.context.dangerousGoodsDeclaration.cargoDeclarant.shortName
      }, () => {
        this.context.dangerousGoodsDeclaration.completed = true;
        this.context.dangerousGoodsDeclaration.declared = false;
        this.context.dangerousGoodsDeclaration.dangerousGoods = new DangerousGoodsModel(<DangerousGoods>{
          goods: [],
          stowageAtArrival: [],
          handlings: []
        }, this.cargoAgent);
        this.context.replaceVisit(this.context.visit);
        setTimeout(() => AppContext.registerSuccess('The declaration was cancelled successfully.'), 100);
        if (VisitContext.isOrganisationOnlyCargoDeclarant()) {
          history.go(-2);
        } else {
          history.back();
        }
      });
  };

  addExcelUpload = command => this.uploadedXls ? wrapCommand(command, this.uploadedXls) : command;

  onChange = () => {
    if (VisitContext.dangerousGoodsDeclaration) {
      this.dangerousGoods = VisitContext.dangerousGoodsDeclaration.dangerousGoods = new DangerousGoodsModel(cloneObject(VisitContext.dangerousGoodsDeclaration.dangerousGoods), this.cargoAgent);
      setTimeout(() => this.changeDetector.detectChanges(), 0);
    }
  };

  isNotOwnDangerousGoodsDeclaration() {
    if (this.context.dangerousGoodsDeclaration && this.context.dangerousGoodsDeclaration.cargoDeclarant && this.context.dangerousGoodsDeclaration.visitDeclarant) {
      return this.context.dangerousGoodsDeclaration.cargoDeclarant.shortName !== this.appContext.userProfile.organisation.shortName
        && this.context.dangerousGoodsDeclaration.visitDeclarant.shortName != this.appContext.userProfile.organisation.shortName;
    }
    return false;
  }

  private isOriginalCreatorOfVisit() {
    return !!this.context.savedVisit.agentsAtArrival && this.context.savedVisit.agentsAtArrival.includes(this.cargoAgent);
  }

  showStowageAtArrival() {
    return !(this.context.savedVisit.hasBeenTransferred && this.dangerousGoods.isBulkOnly && !this.isOriginalCreatorOfVisit());
  }
}

function initializeDeclaration(cargoAgent: string) {
  if (!VisitContext.visit.dangerousGoodsDeclarations) {
    VisitContext.visit.dangerousGoodsDeclarations = [];
  }

  let emptyDeclaration = <DangerousGoodsDeclaration>{
    dangerousGoods: {goods: [], stowageAtArrival: [], handlings: []},
    cargoDeclarant: VisitContext.visit.cargoDeclarants.find(d => d.shortName === cargoAgent)
  };

  let declaration = VisitContext.visit.dangerousGoodsDeclarations.find(d => d.cargoDeclarant.shortName === cargoAgent);
  if (VisitContext.isOrganisationNextDeclarant()) {
    declaration = cast(VisitContext.visit.nextDangerousGoodsDeclaration);
  }

  if (!declaration) {
    declaration = emptyDeclaration;
    VisitContext.visit.dangerousGoodsDeclarations.push(declaration);
  }
  sortStowage(declaration.dangerousGoods.stowageAtArrival);
  declaration.dangerousGoods.handlings.filter(h => h.type === 'loading' || h.type === 'blending').forEach(h => sortStowage((<Loading>h).stowages));
  declaration.dangerousGoods = new DangerousGoodsModel(declaration.dangerousGoods, cargoAgent);
  VisitContext.dangerousGoodsDeclaration = <any>declaration;
}
