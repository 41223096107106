<div class="fieldset" [ngClass]="{'disabled' :context.findLatestDeclaration(SECURITY)?.status === 'DECLARED'}">
  <div class="row sticky-top bg-white" style="top:6.125rem">
    <div class="col-md-12">
      <app-alerting></app-alerting>
    </div>
    <div class="col-md-12 mt-2" *ngIf="getRejectReason() as rejectReason">
      <app-status-alert type="danger">Port authority: {{rejectReason}}</app-status-alert>
    </div>
    <div class="col-md-12">
      <app-action-nav-bar>
        <!--Buttons-->

        <div class="d-flex flex-row-reverse">
          <!-- Admin actions -->
          <div class="ms-2">
            <app-security-admin></app-security-admin>
          </div>

            <ng-container *ngIf="!appContext.isPortAuthority() && context.isVlissingenOrTerneuzenVisit()">
              <div class="btn-group fieldset">
                <button (click)="save()" id="saveSecurity" type="button" class="btn btn-info ms-2"
                        title="Save" style="min-width: 6rem" tabindex="-1">Save
                </button>
              </div>
              <!-- Upload button -->
              <div class="ms-2">
                <a class="btn btn-secondary" role="button" (click)="uploadInput.click()"
                   title="Upload security declaration">
                  <span class="fa fa-file-upload"></span><input type="file" hidden
                                                                (change)="upload($event.target.files[0], uploadCallback); $event.target.value = '';"
                                                                #uploadInput>
                </a>
              </div>
            </ng-container>
            <ng-container *ngIf="!appContext.isPortAuthority() && !context.isVlissingenOrTerneuzenVisit()">
            <!-- User actions -->
            <div class="btn-group ms-2" [ngClass]="{'always-enabled' : appContext.hasRole('VisitDeclarant')
                      && !context.visit.cancelled
                      && !(context.findLatestDeclaration(SECURITY)?.status === 'DECLARED')}">
              <button [disabled]="!context.hasBeenAcceptedAtLeastOnce(VISIT)" (click)="saveAndSend()" id="sendSecurity"
                      type="button" class="btn btn-info" tabindex="-1">
                Save and send
              </button>
              <!-- Dropdown button -->
              <button type="button" class="btn btn-info dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown"
                      aria-haspopup="true" aria-expanded="false">
                <span class="visually-hidden">Toggle Dropdown</span>
              </button>
              <div class="dropdown-menu dropdown-menu-end">
                <ng-container *ngIf="!context.findLatestDeclaration(SECURITY)">
                  <a class="dropdown-item" href="#" (click)="$event.preventDefault(); save()"
                     id="saveSecurityDeclaration"
                     role="button">
                    <i class="fa fa-save text-success" aria-hidden="true"></i> Save
                  </a>
                </ng-container>
              </div>
            </div>
            <!-- Upload button -->
            <div class="ms-2">
              <a class="btn btn-secondary" role="button" (click)="uploadInput.click()"
                 title="Upload security declaration">
                <span class="fa fa-file-upload"></span><input type="file" hidden
                                                              (change)="upload($event.target.files[0], uploadCallback); $event.target.value = '';"
                                                              #uploadInput>
              </a>
            </div>
          </ng-container>
        </div>
      </app-action-nav-bar>
    </div>

    <app-sspi-clearance class="col-md-12"></app-sspi-clearance>

    <div class="col-md-12">
      <div *ngIf="!context.hasBeenAcceptedAtLeastOnce(VISIT) && !context.isVlissingenOrTerneuzenVisit()" class="row justify-content-end hint-box">
        <div>Security can be sent once the visit declaration has been accepted.</div>
      </div>
    </div>
  </div>

  <!--Security report-->
  <div class="row">
    <div class="declaration-block col-md-6">
      <h2>Security report</h2>
      <app-form-group>
        <label for="reportRequired">Report required</label>
        <app-select [ngModel]="context.visit.securityDeclaration.securityReportRequired"
                    (ngModelChange)="initializeSecurityReport($event)" required
                    [options]="securityDeclarationOptions()" [formatter]="reportRequiredFormatter"
                    id="reportRequired"></app-select>
        <span *ngIf="canFillInSecurityDeclaration() && context.visit.securityDeclaration.prefilledByCaptain">Captain prefilled this information</span>
      </app-form-group>
      <div *ngIf="canFillInSecurityDeclaration()">
        <app-form-group>
          <app-info class="formGroupLabel">
            <label>CSO name</label>
            <span
              class="tooltipContent">Name of the Company Security Officer. If unknown, please fill in "UNKNOWN".</span>
          </app-info>
          <input required [(ngModel)]="context.visit.securityDeclaration.companySecurityOfficer.name"
                 id="csoName" type="text" maxlength="70" class="form-control">
        </app-form-group>
        <app-form-group>
          <app-info class="formGroupLabel">
            <label>CSO email</label>
            <span class="tooltipContent">Email of the Company Security Officer. An email and/or phone number is required. If unknown, please fill in "UNKNOWN".</span>
          </app-info>
          <input [required]="!context.visit.securityDeclaration.companySecurityOfficer.phoneNumber"
                 [(ngModel)]="context.visit.securityDeclaration.companySecurityOfficer.email"
                 id="csoEmail" type="text" maxlength="50" class="form-control">
        </app-form-group>
        <app-form-group>
          <app-info class="formGroupLabel">
            <label>CSO phone</label>
            <span class="tooltipContent">Phone number of the Company Security Officer. An email and/or phone number is required. If unknown, please fill in "UNKNOWN".</span>
          </app-info>
          <input [required]="!context.visit.securityDeclaration.companySecurityOfficer.email"
                 [(ngModel)]="context.visit.securityDeclaration.companySecurityOfficer.phoneNumber"
                 id="csoTelephone" type="text" maxlength="15" class="form-control">
        </app-form-group>
        <app-form-group>
          <app-info class="formGroupLabel">
            <label>Security level</label>
            <span class="tooltipContent">The security level at which the ship is currently operating.</span>
          </app-info>
          <app-select required [(ngModel)]="context.visit.securityDeclaration.currentSecurityLevel"
                      [options]='["SL1", "SL2", "SL3"]' id="securityLevel"></app-select>
        </app-form-group>
      </div>
    </div>

    <!--Security documents-->
    <div class="declaration-block col-md-6"
         *ngIf="canFillInSecurityDeclaration()">
      <h2>Security documents</h2>
      <app-form-group>
        <app-info class="formGroupLabel">
          <label>SSP on board</label>
          <span class="tooltipContent">Indicate if the ship has an approved Ship Security Plan on board.</span>
        </app-info>
        <app-yes-no required [(ngModel)]="context.visit.securityDeclaration.approvedSspDocumentOnBoard"
                    id="sspOnBoard"></app-yes-no>
      </app-form-group>
      <app-form-group>
        <app-info class="formGroupLabel">
          <label>ISSC available</label>
          <span class="tooltipContent">Indicate if the ship has an International Ship Security Certificate.</span>
        </app-info>
        <app-radio required [ngModel]="context.visit.securityDeclaration.isscSecurityDocument.availability"
                   (ngModelChange)="initializeIssc($event)"
                   [formatter]="availabilityFormatter" [options]="['YES', 'NO', 'INTERIM']" [inline]="true"
                   id="isscAvailable"></app-radio>
      </app-form-group>
      <div *ngIf="context.visit.securityDeclaration.isscSecurityDocument.availability === 'YES'
                  || context.visit.securityDeclaration.isscSecurityDocument.availability === 'INTERIM'">
        <app-form-group>
          <label for="expiryDate">Expiry date</label>
          <app-date-field required [(ngModel)]="context.visit.securityDeclaration.isscSecurityDocument.expiryDate"
                         [dateOnly]="true" id="expiryDate"></app-date-field>
        </app-form-group>
        <app-form-group>
          <app-info class="formGroupLabel">
            <label>Issued by</label>
            <span class="tooltipContent">Name of the administration or Recognised Security Organisation (RSO) that has issued the ISSC of the ship. If you choose "Other", please specify the organisation on the bottom of the page in "ANY SECURITY RELATED MATTER TO REPORT".</span>
          </app-info>
          <app-select required [(ngModel)]="context.visit.securityDeclaration.isscSecurityDocument.issuer"
                      [optionsProvider]="issuerProvider" dataKey="code" optionLabel="name" id="isscIssuer"></app-select>
        </app-form-group>
      </div>
      <app-form-group *ngIf="!isscValid">
        <label for="reasonNoValidIssc">Reason no (valid) ISSC</label>
        <textarea required [(ngModel)]="context.visit.securityDeclaration.isscSecurityDocument.reasonNoValidIsscOnBoard"
                  id="reasonNoValidIssc" maxlength="512" class="form-control"></textarea>
      </app-form-group>
    </div>
  </div>

  <div class="row">
    <!--Port facilities-->
    <div class="declaration-block col-md-12" *ngIf="canFillInSecurityDeclaration()">
      <h2>
        <app-info>
          Previous ports and facilities
          <span class="tooltipContent">Make sure the last previous port is at the top. When less than 10 previous port facilities are being declared, a reason is mandatory.</span>
        </app-info>
      </h2>
      <div>
      </div>
      <app-previous-ports-and-facilities></app-previous-ports-and-facilities>
      <div *ngIf="incompleteListOfPortFacilities()" class="col-auto form-check form-switch mt-2 ms-3 mb-3 me-n3 me-lg-n4 me-xl-n5" style="min-width: 10rem">
          <div class="row">
            <div class="col-md-3">
              <input type="checkbox" [disabled]="disabled()" class="form-check-input" id="enableReasonLessPortFacilities"
                 [(ngModel)]="enableReasonLessPortFacilities" (ngModelChange)="clearReason()">
              <label class="form-check-label"
                 for="enableReasonLessPortFacilities">{{enableReasonLessPortFacilities ? 'Reason for less than 10 port facilities' : 'Reason for less than 10 port facilities?'}}</label>
            </div>
            <div class="col-md-4">
                <app-select *ngIf="enableReasonLessPortFacilities"
                    [(ngModel)]="context.visit.securityDeclaration.reasonLessPortFacilities"
                    [optionsProvider]="reasonProvider()" [formatter]="reasonFormatter" placeholder="– Select reason for less than 10 port facilities –"
                    id="reasonLessPortFacilities" ></app-select>
            </div>
            <div class="col-md-5"></div>
            </div>
      </div>
    </div>

    <!--Ship to ship activities-->
    <div class="declaration-block col-md-12" *ngIf="canFillInSecurityDeclaration()">
      <h2>
        <app-info>
          Ship to ship activities
          <span class="tooltipContent">Make sure the last activity is at the top.</span>
        </app-info>
      </h2>
      <app-ship-to-ship-activities></app-ship-to-ship-activities>
    </div>

    <!--Other security related matter-->
    <div class="declaration-block col-md-12" *ngIf="canFillInSecurityDeclaration()">
      <h2>
        <div class="col-auto form-check form-switch" style="min-width: 10rem">
          <input type="checkbox" [disabled]="disabled()" class="form-check-input" id="enableSecurityRelatedMatters"
               [(ngModel)]="enableSecurityRelatedMatters" (ngModelChange)="clearSecurityRelatedMatter()">
        <label class="form-check-label" style="padding-top: 4px !important;"
               for="enableSecurityRelatedMatters">{{enableSecurityRelatedMatters ? 'Any other security related matter' : 'Any other security related matter?'}}</label>
      </div>
      </h2>
      <textarea *ngIf="enableSecurityRelatedMatters" [(ngModel)]="context.visit.securityDeclaration.securityRelatedMatter" id="otherSecurityRelatedMatter"
                maxlength="512" class="form-control"></textarea>
      <div>
        <span *ngIf="!validSecurityMatters" class="ng-invalid invalid-feedback mb-2" style="margin-left: 2.5rem">When security related method is selected, a text should be entered.</span>
      </div>
  </div>

  <!--<div class="mt-4">{{context.visit.securityDeclaration | json}}</div>-->
</div>
<!-- MOBILE (buttons bottom right) -->
<div
  [ngClass]="{'always-enabled' : appContext.hasRole('VisitDeclarant') && !context.visit.cancelled
  && !(context.findLatestDeclaration(STO)?.status === 'DECLARED')}">
  <div class="fixed-bottom mb-3 pe-2 btn-group d-block d-lg-none" style="max-width: 100vw;">
    <div class="row justify-content-end g-2">
      <div class="col-auto">
        <button type="button" class="btn btn-info dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true"
                aria-expanded="false">
          Actions
        </button>
        <div class="dropdown-menu dropdown-menu-end">
          <ng-container *ngIf="!appContext.isPortAuthority()">
            <button [disabled]="!context.hasBeenAcceptedAtLeastOnce(VISIT)" (click)="saveAndSend()"
                    type="button" class="dropdown-item" tabindex="-1">
              Save and send
            </button>
          </ng-container>
          <ng-container *ngIf="!context.findLatestDeclaration(SECURITY)">
            <div>
              <a class="dropdown-item" href="#" (click)="$event.preventDefault(); save()"
                 role="button">
                Save
              </a>
            </div>
          </ng-container>
          <ng-container>
            <div>
              <a class="dropdown-item" role="button" (click)="uploadInput.click()" title="Upload security declaration">
                Upload<input type="file" hidden (change)="upload($event.target.files[0], uploadCallback); $event.target.value = '';"
                             #uploadInput>
              </a>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="col-auto">
        <app-security-admin></app-security-admin>
      </div>
    </div>
  </div>
</div>
