<h3 class="py-2">Export reference data from flux search</h3>

<div class="row">
  <div class="col">
    <app-select [(ngModel)]="type"
                [options]="['refdata_vessel', 'refdata_carrier', 'refdata_port', 'refdata_portofcall',
                'refdata_location', 'refdata_berth', 'refdata_terminal', 'refdata_portfacility',
                'refdata_pilotexemption', 'refdata_dangerousgood', 'refdata_dangerinformation', 'refdata_wastematerial',
                'refdata_bunkerproduct', 'refdata_bunkerproductsupplier', 'refdata_bunkerlicense', 'refdata_sizetype',
                'refdata_documenttype', 'refdata_goodsclassification', 'refdata_party', 'refdata_warehouselicense',
                'refdata_vesselservice', 'user-preferences', 'refdata_transittemplate', 'refdata_terminalemail',
                'refdata_exemptionreferencedata']">
    </app-select>
  </div>
  <div class="col">
    <a *ngIf="!!type" [href]="'/api/refdata/flux-export?type=' + type" class="btn btn-outline-info">Download</a>
  </div>
</div>
