import {Component} from '@angular/core';

import * as html2pdf from 'html2pdf.js';
import {DeclarationType, Visit} from '@portbase/bezoekschip-service-typescriptmodels';
import {VisitContext} from '../visit-context';

@Component({
  selector: 'app-confirmation-of-purchase',
  templateUrl: './confirmation-of-purchase.component.html',
  styleUrls: ['./confirmation-of-purchase.component.css']
})
export class ConfirmationOfPurchaseComponent {
  PAX = DeclarationType.PAX;
  STO = DeclarationType.STO;

  context = VisitContext;
  title = 'ConfirmationOfPurchase';
  crewPrice = 3.07;
  shipStoresPrice = 2.88;
  numberFormatter = new Intl.NumberFormat('nl-NL', {minimumFractionDigits: 2});

  get visit(): Visit {
    return this.context.visit;
  }

  downloadConfirmation() {
    let element = document.getElementById('toPrint');
    let opt = {
      image: {type: 'jpeg', quality: 0.98},
      html2canvas: {scale: 2}
    };
    html2pdf().set(opt).from(element).toPdf().save(this.title + this.visit.crn + ".pdf");
  }

  getCrewPrice() {
    return "€ " + this.numberFormatter.format(this.crewPrice);
  }

  getShipStoresPrice() {
    return "€ " + this.numberFormatter.format(this.shipStoresPrice);
  }

  getCurrentDate() {
    const options = <Intl.DateTimeFormatOptions>{year: "numeric", month: 'numeric', day: 'numeric'};
    return new Date().toLocaleDateString('nl-NL', options);
  }

  calculateTotalPrice(): string {
    const crew = this.context.findLatestDeclaration(DeclarationType.PAX);
    const stores = this.context.findLatestDeclaration(DeclarationType.STO);
    if (crew && stores) {
      return this.numberFormatter.format(this.crewPrice + this.shipStoresPrice);
    } else if (crew) {
      return this.numberFormatter.format(this.crewPrice);
    } else {
      return this.numberFormatter.format(this.shipStoresPrice);
    }
  }
}
