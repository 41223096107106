import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {AppContext} from "../../../app-context";
import {
  BezoekschipOrganisation,
  GetVisit,
  IE3ConsignmentProcessSummary,
  IE3ConsignmentsResult,
  IE3FilingType,
  IE3FindConsignmentProcesses,
  IE3FindConsignments,
  IE3RegisterNotificationOfArrival,
  Visit
} from "@portbase/bezoekschip-service-typescriptmodels";
import {combineLatest, Observable} from "rxjs";
import {
  CheckboxSelectionState,
  openConfirmationModalWithCallback,
  openEditModal,
  sendCommand,
  sendQuery
} from "../../../common/utils";
import {map} from "rxjs/operators";
import {WebsocketService} from "../../../common/websocket.service";
import {SentenceCasePipe} from "../../../common/sentence-case.pipe";
import {ConsignmentUtils} from "../consignment.utils";
import {ActivatedRoute, Router} from "@angular/router";
import lodash from "lodash";
import {
  MasterConsignmentDetailsComponent,
  MasterConsignmentDetailsComponentData
} from "../details/master-consignment-details/master-consignment-details.component";
import {FacetUtils} from "../../common/facets/facet-utils";
import {FacetedOverview} from "../../common/overview/faceted-overview";
import {FacetedOverviewFilters} from "../../common/overview/overview.component";
import {QuickView} from "../../common/facets/facet-filter/facet-filter.component";
import {PortvisitUtils} from "../../../refdata/portvisit-utils";
import {ModalConfirmAutofocus, ModalConfirmAutofocusData} from "../../../common/modal/modal-confirm.component";
import {ConsignmentDownloadUtils} from "../consignment-download.utils";
import {ConsignmentRules} from "../consignment.rules";

const quickViews: QuickView[] = [{
  code: "ALL_DECLARATIONS",
  name: "All declarations",
  icon: "fa-calendar-days",
  hidden: () => AppContext.isAdmin() && AppContext.isProduction(),
  facets: {}
}, {
  code: "ACTION_REQUIRED",
  name: "Action required",
  icon: "fa-circle-exclamation",
  facets: {
    actionRequired: ["true"],
    fromVisit: ["false"]
  }
}, {
  code: "DRAFT",
  name: "Not declared",
  icon: "fa-circle-question",
  facets: {
    draft: ["true"],
    fromVisit: ["false"]
  }
}];

@Component({
  selector: 'app-consignments-overview',
  templateUrl: './consignments-overview.component.html',
  styleUrls: ['./consignments-overview.component.scss'],
  providers: [WebsocketService]
})
export class ConsignmentsOverviewComponent extends FacetedOverview<IE3ConsignmentProcessSummary, ConsignmentOverviewFilters> implements OnInit {
  protected readonly utils = ConsignmentUtils;

  appContext = AppContext;
  data: IE3ConsignmentProcessSummary[] = [];
  crn: string;

  timeOfArrival: string;
  @ViewChild("registerArrivalModalBody") registerArrivalModalBody: TemplateRef<any>;
  @ViewChild("downloadModalBody") downloadModalBody: TemplateRef<any>;

  downloadCrn: string;
  downloadVisit: Visit;
  selectedDeclarant: BezoekschipOrganisation;

  isSelectable = (record: IE3ConsignmentProcessSummary) => ConsignmentRules.isSummaryAllowedToDeclare(record);

  constructor(private websocket: WebsocketService<IE3ConsignmentProcessSummary>, router: Router, route: ActivatedRoute) {
    super(undefined, quickViews, quickViews.find(q => q.code === "ACTION_REQUIRED"));
    route.params.subscribe(params => {
      route.queryParams.subscribe(queryParams => {
        this.crn = this.downloadCrn = params["crn"];
        this.onCrnChange(this.crn);
        const cargoDeclarantId = params["cargoDeclarantId"];
        if (this.crn) {
          this.filters.overviewFilters.term = this.crn;
          this.filters.overviewFilters.facets = {};
          this.filters.overviewFilters.selectedQuickView = this.quickViews[0];
          if (cargoDeclarantId) {
            this.filters.overviewFilters.hiddenFacets = this.filters.overviewFilters.hiddenFacets || {};
            this.filters.overviewFilters.hiddenFacets.iamConnectedId = [cargoDeclarantId];
          }
        } else {
          this.setLocalStorageKey("consignment-overview-filters");
        }
      })
    });
  }

  getData = (term: string): Observable<IE3ConsignmentsResult> => {
    if (!this.filters.overviewFilters) {
      this.filters.overviewFilters = FacetUtils.defaultOverviewFilters();
    }
    this.loading = true;
    this.saveFiltersInLocalStorage(this.filters);
    return sendQuery("com.portbase.bezoekschip.common.api.consignments.queries.FindConsignments", <IE3FindConsignments>{
      term: term,
      from: this.from,
      maxHits: this.maxItems,
      facetFilters: this.getFacetFilters()
    }, {showSpinner: true}).pipe(map((result: IE3ConsignmentsResult) => {
      this.loading = false;
      return result;
    }));
  };

  ngOnInit(): void {
    this.websocket.initialise(`/api/ui/consignments`,
      (update: IE3ConsignmentProcessSummary) => this.updateFromWebsocket(update), true);
  }

  createConsignment = () => openEditModal(MasterConsignmentDetailsComponent, <MasterConsignmentDetailsComponentData>{
    consignmentProcessId: null,
    crn: this.crn
  });

  renderRecords = (result: IE3ConsignmentsResult, append: boolean) => {
    this.saveFiltersInLocalStorage(this.filters);
    if (result.consignments.length < this.maxItems) {
      this.endReached = true;
    }
    this.data = append ? (this.data || []).concat(result.consignments) : result.consignments;
    this.setFacetsFromResult(result.facets);
  }

  trackByRecord(index: number, record: IE3ConsignmentProcessSummary): any {
    return record.masterConsignment.consignmentNumber;
  }

  private updateFromWebsocket(update: IE3ConsignmentProcessSummary) {
    const existingConsignment = this.data.find(
      c => c.consignmentProcessId === update.consignmentProcessId);
    if (existingConsignment) {
      this.data[this.data.indexOf(existingConsignment)] = update;
    }
  }

  nameFormatter = (name: string): string => {
    switch (name) {
      case "placeOfLoading":
        return "Place of loading";
      case "dischargeTerminalName":
        return "Discharge terminal";
      case "filingType":
        return "Filing type";
      case "houseConsignmentsCount":
        return "Number of house consignments";
      case "equipmentCount":
        return "Number of equipments";
      case "masterConsignment/customsProcess":
        return "Customs process";
      case "masterConsignment/customsStatus":
        return "Customs status";
      case "ensStatus":
        return "ENS status";
      case "temporaryStorageStatus":
        return "TS status";
      case "declarantName":
        return "Organisation";
      case "hasShortLandedContainers":
        return "Shortlanded equipment";
      case "hasClearanceDifferences":
        return "Clearance differences";
      default:
        return SentenceCasePipe.formatWithSpaces(name);
    }
  }

  getValueFormatter = (name: string): (value: string) => string => {
    switch (name) {
      case "filingType":
        return (v) => ConsignmentUtils.filingTypeFormatter(v as IE3FilingType, false);
      case "dischargeTerminalName":
        return SentenceCasePipe.format;
      case "masterConsignment/customsProcess":
        return ConsignmentUtils.getCustomsProcessCode;
      case "masterConsignment/customsStatus":
        return ConsignmentUtils.getCustomsStatusCode;
      case "ensStatus":
        return (v) => PortvisitUtils.enumFormatter(v).replace("Ens", "ENS");
      case "temporaryStorageStatus":
        return PortvisitUtils.enumFormatter;
      default:
        return null;
    }
  }

  getHiddenFacets = (): string[] => {
    return ["count", "equipmentCount", "totalGrossWeight", "houseConsignmentsCount", "containerNumbers", "allowedViewers",
      "iamConnectedId", "activeCrns", "fromVisit"].concat(AppContext.isAdmin() ? [] : ["declarantName"]);
  }

  get grossWeight(): number {
    return lodash.sum(this.allFacets?.find(f => f.name === "totalGrossWeight")?.values
      .map(v => v.count * parseInt(v.value))) || 0;
  }

  get masterConsignmentCount(): number {
    return lodash.sum(this.allFacets?.find(f => f.name === "count")?.values
      .map(v => v.count)) || 0;
  }

  get houseConsignmentCount(): number {
    return lodash.sum(this.allFacets?.find(f => f.name === "houseConsignmentsCount")?.values
      .map(v => v.count * parseInt(v.value))) || 0;
  }

  get equipmentCount(): number {
    return lodash.sum(this.allFacets?.find(f => f.name === "equipmentCount")?.values
      .map(v => v.count * parseInt(v.value))) || 0;
  }

  get anyRecordSelectable(): boolean {
    return this.data?.length && this.data.some(d => this.isSelectable(d));
  }

  getSelectedConsignments(): IE3ConsignmentProcessSummary[] {
    return this.data.filter(c => c["selected"] == true);
  }

  get nrOfSelectedConsignments(): number {
    return this.getSelectedConsignments().length;
  }

  openRegisterArrivalModal = () => openConfirmationModalWithCallback((confirmed) => {
    if (confirmed) {
      sendCommand("com.portbase.bezoekschip.common.api.consignments.commands.RegisterNotificationOfArrival", <IE3RegisterNotificationOfArrival>{
        cargoDeclarantId: this.appContext.userProfile.organisation?.iamConnectedId,
        consignmentProcessIds: this.getSelectedConsignments().map(s => s.consignmentProcessId),
        ata: this.timeOfArrival
      }, () => this.getSelectedConsignments().forEach(c => c["selected"] = CheckboxSelectionState.unselected));
    }
    this.timeOfArrival = null;
  }, ModalConfirmAutofocus, <ModalConfirmAutofocusData>{
    type: "primary",
    title: "Register arrival",
    confirmText: "Register arrival",
    cancelText: "Cancel",
    modalSize: "lg",
    body: this.registerArrivalModalBody
  }, 'static');

  downloadMrns = () => openConfirmationModalWithCallback((confirmed) => {
    if (confirmed) {
      return this.findConsignmentProcesses({crn: this.downloadCrn, cargoDeclarantId: this.selectedDeclarant?.iamConnectedId})
        .subscribe(c => ConsignmentDownloadUtils.downloadMrns(this.downloadCrn, c));
    }
  }, ModalConfirmAutofocus, <ModalConfirmAutofocusData>{
    type: "primary",
    title: "Download MRNs",
    confirmText: "Download",
    cancelText: "Close",
    body: this.downloadModalBody
  }, 'static');

  downloadBlDetails = () => openConfirmationModalWithCallback((confirmed) => {
    if (confirmed) {
      return combineLatest([this.getVisit(this.downloadCrn), this.findConsignmentProcesses({
        crn: this.downloadCrn,
        cargoDeclarantId: this.selectedDeclarant?.iamConnectedId
      })]).subscribe(c => ConsignmentDownloadUtils.downloadBlDetails(c[0], c[1]));
    }
  }, ModalConfirmAutofocus, <ModalConfirmAutofocusData>{
    type: "primary",
    title: "B/L details download",
    confirmText: "Download",
    cancelText: "Close",
    body: this.downloadModalBody
  }, 'static');

  downloadClearanceDifferences = () => openConfirmationModalWithCallback((confirmed) => {
    if (confirmed) {
      return combineLatest([this.getVisit(this.downloadCrn), this.findConsignmentProcesses({
        crn: this.downloadCrn,
        cargoDeclarantId: this.selectedDeclarant?.iamConnectedId,
        facetFilters: [{
          facetName: "clearanceDifferences",
          values: ["true"]
        }]
      })]).subscribe(c => ConsignmentDownloadUtils.downloadClearanceDifferences(c[0], c[1]));
    }
  }, ModalConfirmAutofocus, <ModalConfirmAutofocusData>{
    type: "primary",
    title: "Clearance differences",
    confirmText: "Download",
    cancelText: "Close",
    body: this.downloadModalBody
  }, 'static');

  protected onCrnChange = (crn: string) => {
    this.downloadCrn = crn;
    if (crn) {
      this.getVisit(crn).subscribe((v: Visit) => this.downloadVisit = v);
    } else {
      this.downloadVisit = null;
    }
  }

  private getVisit = (crn: string) =>
    sendQuery("com.portbase.bezoekschip.common.api.visit.GetVisit", <GetVisit>{crn: crn});
  private findConsignmentProcesses = (query: IE3FindConsignmentProcesses) =>
    sendQuery("com.portbase.bezoekschip.common.api.consignments.queries.FindConsignmentProcesses", query, {
      showSpinner: true
    });
}

interface ConsignmentOverviewFilters extends FacetedOverviewFilters<any> {
}
