<button (mouseover)="hoverStatusIndicatorSpan(_statusIndicatorSpanId, true)"
        (mouseout)="hoverStatusIndicatorSpan(_statusIndicatorSpanId, false)"
        id="{{_statusIndicatorButtonId}}" *ngIf="this.messageStatus !== 'DISABLED'" onPopover data-bs-toggle="popover" [popoverContent]="popoverContent" placement="top"
        popoverClass="pop-auto-container" class="btn btn-link link-dark p-0">
  <div class="d-inline-block d-xl-none">
    <app-task-message-status-icon [messageStatus]="messageStatus"></app-task-message-status-icon>
  </div>
  <div class="d-none d-xl-inline-block">
    <ng-container *ngIf="summarizeIcon else showAllIcons">
      <app-task-message-status-icon [messageStatus]="messageStatus"></app-task-message-status-icon>
    </ng-container>
    <ng-template #showAllIcons>
      <ng-container *ngFor="let messageStatus of messageStatuses">
        <app-task-message-status-icon [messageStatus]="messageStatus.taskStatus"></app-task-message-status-icon>
      </ng-container>
    </ng-template>
  </div>
  <span class="d-none d-sm-inline-block text-start">
    <ng-content></ng-content>
  </span>
</button>

<ng-template #popoverContent>
  <div class="w-100 h-100 text-start p-1 pe-4" [style.max-width.px]="320">
    <div class="btn-close-wrapper rounded-circle position-absolute end-0 top-0 mt-2 me-2">
      <button type="button" class="btn-close" data-bs-toggle="popover" aria-label="Close"></button>
    </div>
    <ng-container *ngFor="let messageStatus of messageStatuses">
      <div *ngIf="messageStatus.taskStatus !== 'DISABLED'" class="mb-3 message-status">
        <span class="d-block fw-medium mb-2">{{messageStatus.name | sentenceCase}}</span>

          <div>
            <div class="row g-2">
              <app-task-message-status [messageStatus]="messageStatus.taskStatus" [reason]="messageStatus.reason"
                                       [customLabel]="messageStatus.customLabel" [sentenceCasePipe]="messageStatus.sentenceCase"
                                       [showTooltip]="false" class="col-12"></app-task-message-status>

              <div *ngFor="let message of messageStatus.messages" class="col-12">
                <i *ngIf="!messageStatus.hideComment" class="fa-light fa-fw fa-comment me-1"></i>
                <span *ngIf="messageStatus.asInnerHtml" [innerHTML]="message" style="line-height: 1.25rem"></span>
                <span *ngIf="!messageStatus.asInnerHtml" style="line-height: 1.25rem">{{message}}</span>
              </div>
            </div>
          </div>

      </div>
    </ng-container>
  </div>
</ng-template>
