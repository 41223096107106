import {Component} from '@angular/core';
import {
  Exemption_5_3
} from '@portbase/bezoekschip-service-typescriptmodels';
import {ExemptionBaseComponent} from "../exemption_base/exemption_base.component";

@Component({
  selector: 'app-visit-exemptions-exemption-5-3',
  templateUrl: './exemption_5_3.component.html',
  styleUrls: ['./exemption_5_3.component.css']
})
export class Exemption_5_3Component extends ExemptionBaseComponent {
  exemptionSubType: Exemption_5_3;
}

