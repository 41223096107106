<div class="row narrow-gutters mb-2">
  <div class="col-md-auto" *ngIf="!!declaration.releaseStatus.status">
    <span class="btn-group dropup mx-1 always-enabled">
        <button class="btn btn-outline-secondary dropdown-toggle py-1" style="min-width: 7rem" type="button"
                data-bs-toggle="dropdown">Incoming
        <span class="caret"></span></button>
        <ul class="dropdown-menu dropdown-menu-end">
              <li (click)="utils.fetchProcessIdAndOpenMessageManagement('coreor-in-' + declaration.id)"
                  class="dropdown-item">Commercial release from carrier</li>
        </ul>
    </span>
  </div>
  <div class="col-md-auto ms-auto" *ngIf="!!declaration.releaseStatus.status">
    <span class="btn-group dropup mx-1 always-enabled">
        <button class="btn btn-outline-secondary dropdown-toggle py-1" style="min-width: 7rem" type="button"
                data-bs-toggle="dropdown">Outgoing
        <span class="caret"></span></button>
        <ul class="dropdown-menu dropdown-menu-end">
              <li (click)="utils.fetchProcessIdAndOpenMessageManagement('coreor-out-' + declaration.id)"
                  class="dropdown-item">Commercial release to terminal</li>
        </ul>
    </span>
  </div>

</div>
