import {
  BerthVisitInfo,
  Chemical,
  ChemicalData,
  ConsignmentDifference,
  ContainerGood,
  ContainerGoodData,
  DischargeResult,
  IncomingMessage,
  InspectionUpdate,
  Solid,
  SolidData
} from '@portbase/bezoekschip-service-typescriptmodels';

export const VisitMock = {
  NLRTM23903642: {
    "crn": "NLRTM23903642",
    "copyOfVisit": "NLRTM23903622",
    "portOfCall": {
      "sortingKey": 0,
      "port": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM",
        "countryUnCode": "NL",
        "euPort": true
      },
      "portAuthority": {
        "fullName": "Havenbedrijf Rotterdam N.V.",
        "shortName": "HBRRTM",
        "iamConnectedId": null,
        "portAuthorityId": "HbR",
        "emailAddress": "HCC@portofrotterdam.com",
        "address": "Wilhelminakade 909",
        "city": "Rotterdam",
        "zipCode": "3072 AP",
        "countryUnCode": "NL",
        "countryName": "Netherlands",
        "phoneNumber": "+31-10-2521195",
        "faxNumber": "+31-10-2521000",
        "contact": "Diana van Eijk",
        "arrivalLocationHouseNumber": null,
        "customsZipCode": null,
        "customsEORINumber": "NL24354561",
        "ean": null,
        "chamberOfCommerceNumber": null,
        "scacCode": null,
        "ensEnabled": false,
        "cargoImportEnabled": false,
        "cargoImportEnabledByDefault": false
      },
      "customsOffice": {
        "name": "Rotterdam Haven/Kantoor Maasvlakte",
        "unCode": "NL000396"
      },
      "ataReported": true,
      "atdReported": true,
      "atdPortReported": true,
      "paDeclarationRequired": true,
      "swDeclarationRequired": true,
      "wasteEnabled": true,
      "dangerousGoodsEnabled": true,
      "harbourDuesEnabled": false,
      "orderNauticalServicesEnabled": true,
      "enableNotificationsToPa": false,
      "authoriseOrganisationsDisabled": false,
      "tugBoatsRequired": true,
      "portAuthorityClearance": true
    },
    "vessel": {
      "imoCode": "9051478",
      "name": "MSC JEMIMA",
      "radioCallSign": "3EAE3",
      "motUnCode": "1511",
      "motName": "Full container ship/cellular vessel",
      "summerDeadWeight": 42288,
      "maxWidth": 32.26,
      "flagCode": "PAN",
      "flagCountryUnCode": "PA",
      "netTonnage": 14641,
      "grossTonnage": 30971,
      "registrationPlaceUnloCode": "PAPTY",
      "registrationPlaceName": "Panamá, Ciudad de",
      "registrationDate": "1994-03-10",
      "mmsiNumber": "352138000",
      "fullLength": 202,
      "emailAddress": null,
      "statCode5": "A33A2CC",
      "containerShip": true,
      "lngShip": false
    },
    "owner": {
      "fullName": "Cross-Ocean B.V.",
      "shortName": "CROSSOCEANRTM",
      "iamConnectedId": "19431da8-4118-11ea-a528-e77ac7ae1b2f",
      "portAuthorityId": "CROSS OCEAN",
      "emailAddress": "vl-test1@portbase.com",
      "address": "Conradstraat 38",
      "city": "Rotterdam",
      "zipCode": "3013 AP",
      "countryUnCode": "NL",
      "countryName": "Netherlands",
      "phoneNumber": "010-2522222",
      "faxNumber": "010-2522250",
      "contact": "Service Desk",
      "arrivalLocationHouseNumber": "1",
      "customsZipCode": "1000 AA",
      "customsEORINumber": "NL100004118",
      "ean": null,
      "chamberOfCommerceNumber": "24137366",
      "scacCode": "CROS",
      "ensEnabled": true,
      "cargoImportEnabled": true,
      "cargoImportEnabledByDefault": true
    },
    "nextOwner": null,
    "originalOwner": {
      "fullName": "Cross-Ocean B.V.",
      "shortName": "CROSSOCEANRTM",
      "iamConnectedId": "19431da8-4118-11ea-a528-e77ac7ae1b2f",
      "portAuthorityId": "CROSS OCEAN",
      "emailAddress": "vl-test1@portbase.com",
      "address": "Conradstraat 38",
      "city": "Rotterdam",
      "zipCode": "3013 AP",
      "countryUnCode": "NL",
      "countryName": "Netherlands",
      "phoneNumber": "010-2522222",
      "faxNumber": "010-2522250",
      "contact": "Service Desk",
      "arrivalLocationHouseNumber": "1",
      "customsZipCode": "1000 AA",
      "customsEORINumber": "NL100004118",
      "ean": null,
      "chamberOfCommerceNumber": "24137366",
      "scacCode": "CROS",
      "ensEnabled": true,
      "cargoImportEnabled": true,
      "cargoImportEnabledByDefault": true
    },
    "declarant": {
      "fullName": "Cross-Ocean B.V.",
      "shortName": "CROSSOCEANRTM",
      "iamConnectedId": "19431da8-4118-11ea-a528-e77ac7ae1b2f",
      "portAuthorityId": "CROSS OCEAN",
      "emailAddress": "vl-test1@portbase.com",
      "address": "Conradstraat 38",
      "city": "Rotterdam",
      "zipCode": "3013 AP",
      "countryUnCode": "NL",
      "countryName": "Netherlands",
      "phoneNumber": "010-2522222",
      "faxNumber": "010-2522250",
      "contact": "Service Desk",
      "arrivalLocationHouseNumber": "1",
      "customsZipCode": "1000 AA",
      "customsEORINumber": "NL100004118",
      "ean": null,
      "chamberOfCommerceNumber": "24137366",
      "scacCode": "CROS",
      "ensEnabled": true,
      "cargoImportEnabled": true,
      "cargoImportEnabledByDefault": true
    },
    "nextDeclarant": null,
    "originalDeclarant": {
      "fullName": "Cross-Ocean B.V.",
      "shortName": "CROSSOCEANRTM",
      "iamConnectedId": "19431da8-4118-11ea-a528-e77ac7ae1b2f",
      "portAuthorityId": "CROSS OCEAN",
      "emailAddress": "vl-test1@portbase.com",
      "address": "Conradstraat 38",
      "city": "Rotterdam",
      "zipCode": "3013 AP",
      "countryUnCode": "NL",
      "countryName": "Netherlands",
      "phoneNumber": "010-2522222",
      "faxNumber": "010-2522250",
      "contact": "Service Desk",
      "arrivalLocationHouseNumber": "1",
      "customsZipCode": "1000 AA",
      "customsEORINumber": "NL100004118",
      "ean": null,
      "chamberOfCommerceNumber": "24137366",
      "scacCode": "CROS",
      "ensEnabled": true,
      "cargoImportEnabled": true,
      "cargoImportEnabledByDefault": true
    },
    "financialDeclarant": {
      "fullName": "Cross-Ocean B.V.",
      "shortName": "CROSSOCEANRTM",
      "iamConnectedId": "19431da8-4118-11ea-a528-e77ac7ae1b2f",
      "portAuthorityId": "CROSS OCEAN",
      "emailAddress": "vl-test1@portbase.com",
      "address": "Conradstraat 38",
      "city": "Rotterdam",
      "zipCode": "3013 AP",
      "countryUnCode": "NL",
      "countryName": "Netherlands",
      "phoneNumber": "010-2522222",
      "faxNumber": "010-2522250",
      "contact": "Service Desk",
      "arrivalLocationHouseNumber": "1",
      "customsZipCode": "1000 AA",
      "customsEORINumber": "NL100004118",
      "ean": null,
      "chamberOfCommerceNumber": "24137366",
      "scacCode": "CROS",
      "ensEnabled": true,
      "cargoImportEnabled": true,
      "cargoImportEnabledByDefault": true
    },
    "nominatedFinancialDeclarant": null,
    "orderNauticalServices": true,
    "orderIncomingMovement": true,
    "creditNumberHolderSameAsDeclarant": false,
    "declarantResponsibleForCustoms": false,
    "cargoDeclarants": [
      {
        "fullName": "Cross-Ocean B.V.",
        "shortName": "CROSSOCEANRTM",
        "iamConnectedId": "19431da8-4118-11ea-a528-e77ac7ae1b2f",
        "portAuthorityId": "CROSS OCEAN",
        "emailAddress": "info@portbase.com",
        "address": "Conradstraat 38",
        "city": "Rotterdam",
        "zipCode": "3013 AP",
        "countryUnCode": "NL",
        "countryName": "Netherlands",
        "phoneNumber": "010-2522222",
        "faxNumber": "010-2522250",
        "contact": "Patrick van den Berg",
        "arrivalLocationHouseNumber": "1",
        "customsZipCode": "1000 AA",
        "customsEORINumber": "NL100004118",
        "ean": null,
        "chamberOfCommerceNumber": "24137366",
        "scacCode": "CROS",
        "ensEnabled": true,
        "cargoImportEnabled": true,
        "cargoImportEnabledByDefault": true
      }
    ],
    "shipOperators": [
      {
        "fullName": "Cross-Ocean B.V.",
        "shortName": "CROSSOCEANRTM",
        "iamConnectedId": "19431da8-4118-11ea-a528-e77ac7ae1b2f",
        "portAuthorityId": "CROSS OCEAN",
        "emailAddress": "vl-test1@portbase.com",
        "address": "Conradstraat 38",
        "city": "Rotterdam",
        "zipCode": "3013 AP",
        "countryUnCode": "NL",
        "countryName": "Netherlands",
        "phoneNumber": "010-2522222",
        "faxNumber": "010-2522250",
        "contact": "Service Desk",
        "arrivalLocationHouseNumber": "1",
        "customsZipCode": "1000 AA",
        "customsEORINumber": "NL100004118",
        "ean": null,
        "chamberOfCommerceNumber": "24137366",
        "scacCode": "CROS",
        "ensEnabled": true,
        "cargoImportEnabled": true,
        "cargoImportEnabledByDefault": true
      },
      {
        "fullName": "Cross-Ocean B.V.",
        "shortName": "CROSSOCEANRTM",
        "iamConnectedId": "19431da8-4118-11ea-a528-e77ac7ae1b2f",
        "portAuthorityId": "CROSS OCEAN",
        "emailAddress": "vl-test1@portbase.com",
        "address": "Conradstraat 38",
        "city": "Rotterdam",
        "zipCode": "3013 AP",
        "countryUnCode": "NL",
        "countryName": "Netherlands",
        "phoneNumber": "010-2522222",
        "faxNumber": "010-2522250",
        "contact": "Service Desk",
        "arrivalLocationHouseNumber": "1",
        "customsZipCode": "1000 AA",
        "customsEORINumber": "NL100004118",
        "ean": null,
        "chamberOfCommerceNumber": "24137366",
        "scacCode": "CROS",
        "ensEnabled": true,
        "cargoImportEnabled": true,
        "cargoImportEnabledByDefault": true
      },
      {
        "fullName": "Havenbedrijf Rotterdam N.V.",
        "shortName": "HBRRTM",
        "iamConnectedId": null,
        "portAuthorityId": "HbR",
        "emailAddress": "HCC@portofrotterdam.com",
        "address": "Wilhelminakade 909",
        "city": "Rotterdam",
        "zipCode": "3072 AP",
        "countryUnCode": "NL",
        "countryName": "Netherlands",
        "phoneNumber": "+31-10-2521195",
        "faxNumber": "+31-10-2521000",
        "contact": "Diana van Eijk",
        "arrivalLocationHouseNumber": null,
        "customsZipCode": null,
        "customsEORINumber": "NL24354561",
        "ean": null,
        "chamberOfCommerceNumber": null,
        "scacCode": null,
        "ensEnabled": false,
        "cargoImportEnabled": false,
        "cargoImportEnabledByDefault": false
      }
    ],
    "additionalViewers": [],
    "authorisations": {
      "NLRTM23903642-EFICE": {
        "authorisationId": "NLRTM23903642-EFICE",
        "aggregateId": "NLRTM23903642",
        "consumerShortName": "EFICE",
        "roles": [
          "VisitDeclarant"
        ]
      },
      "NLRTM23903642-PORTXCHANGE": {
        "authorisationId": "NLRTM23903642-PORTXCHANGE",
        "aggregateId": "NLRTM23903642",
        "consumerShortName": "PORTXCHANGE",
        "roles": [
          "VisitReader"
        ]
      }
    },
    "visitDeclaration": {
      "clientReferenceNumber": "Jeroen",
      "vesselEmailAddress": null,
      "etaFirstEntryEu": null,
      "arrivalVoyage": {
        "voyageNumber": null,
        "carrier": {
          "customsId": "NL005140985",
          "name": "Cross-Ocean B.V.",
          "scacCode": "CROC",
          "smdgCode": null,
          "bicCode": null,
          "cargoDeclarantShortName": null,
          "boxOperators": []
        }
      },
      "previousPorts": [
        {
          "id": "13e9b82e-4e55-4abc-a992-6e26adcf906b",
          "arrival": "2023-12-10T11:00:00Z",
          "departure": "2023-12-12T11:00:00Z",
          "port": {
            "name": "Hamburg",
            "locationUnCode": "DEHAM",
            "countryUnCode": "DE",
            "euPort": true
          },
          "portFacilityVisits": []
        }
      ],
      "portVisit": {
        "pilotStation": {
          "code": "MC",
          "name": "Maascenter",
          "atSea": true
        },
        "entryPoint": {
          "code": "LL",
          "name": "Lage Licht",
          "atSea": true
        },
        "etaPort": "2023-12-15T23:00:00Z",
        "portEntry": {
          "origin": "SEA",
          "baseForPlanning": "PILOT_BOARDING_PLACE",
          "earliestTimeOfPortEntry": null,
          "entryDependency": null,
          "etaPilotBoardingPlace": "2023-12-15T23:00:00Z",
          "etaSeaBuoy": "2023-12-15T23:00:00Z",
          "intention": "REQUEST_FOR_ENTRY",
          "requestedEtaPilotBoardingPlace": null
        },
        "firstMovement": {
          "vesselDraft": 14.2,
          "vesselMasterName": "Jeroen",
          "numberOfCrew": 9,
          "numberOfPassengers": 0,
          "cargo": "CONTAINERIZED",
          "orderEmail": null,
          "orderSms": null,
          "pilotExemption": null,
          "offStandardBeam": null,
          "pilotService": {
            "required": true,
            "serviceProvider": {
              "name": "Loodswezen",
              "portAuthorityId": "LRR"
            },
            "remarks": null
          },
          "order": false,
          "cancellationReason": null,
          "stormPilotageInformation": {
            "heliSuitable": null,
            "hoisting": null,
            "remotePilotage": null,
            "imoLoaRegistered": null,
            "lowFreeboard": null,
            "remarks": null
          }
        },
        "passingThroughTugboats": null,
        "defectTypes": [],
        "defectTypeRemarks": null,
        "berthVisits": [
          {
            "id": "fe9de138-6fcc-482d-9e9d-535792dc04af",
            "callId": null,
            "berth": {
              "name": "AMALIAH APMT DEEPSEA KADE",
              "shortName": null,
              "code": "R8408",
              "terminalCode": "4254",
              "terminalName": "AMALIAH APMT DEEPSEA KADE",
              "organisationShortName": "APMII",
              "organisationName": "APM Terminals Maasvlakte II B.V.",
              "berthGroupCode": "APMT",
              "buoy": false,
              "harbourDuesArea": true,
              "partyToNotify": null,
              "bollards": {
                "start": 46,
                "end": 51
              },
              "mooringPoles": null
            },
            "terminal": null,
            "stevedore": null,
            "quay": null,
            "eta": "2023-12-16T01:15:00Z",
            "requestedEta": null,
            "ata": null,
            "etd": "2023-12-16T23:00:00Z",
            "requestedEtd": null,
            "atd": null,
            "mooring": "NO_PREFERENCE",
            "remarks": null,
            "visitPurposes": [
              "DISCHARGE",
              "LOADING"
            ],
            "bollardFrom": null,
            "bollardTo": null,
            "tugboatAtArrival": {
              "required": true,
              "serviceProvider": {
                "name": "Boluda",
                "portAuthorityId": "BOLUDA"
              },
              "requiredQuantity": "TWO",
              "remarks": null
            },
            "boatmenAtArrival": {
              "required": true,
              "serviceProvider": {
                "name": "KRVE",
                "portAuthorityId": "RVE"
              },
              "remarks": null
            },
            "boatmenAtDeparture": {
              "required": true,
              "serviceProvider": {
                "name": "KRVE",
                "portAuthorityId": "RVE"
              },
              "remarks": null
            },
            "tugboatAtDeparture": {
              "required": true,
              "serviceProvider": {
                "name": "Boluda",
                "portAuthorityId": "BOLUDA"
              },
              "requiredQuantity": "TWO",
              "remarks": null
            },
            "nextMovement": {
              "vesselDraft": 14,
              "vesselMasterName": "Jeroen",
              "numberOfCrew": 9,
              "numberOfPassengers": 0,
              "cargo": "CONTAINERIZED",
              "orderEmail": null,
              "orderSms": null,
              "pilotExemption": null,
              "offStandardBeam": null,
              "pilotService": {
                "required": true,
                "serviceProvider": {
                  "name": "Loodswezen",
                  "portAuthorityId": "LRR"
                },
                "remarks": null
              },
              "order": null,
              "cancellationReason": null,
              "stormPilotageInformation": null
            },
            "vesselServiceCode": null,
            "vesselServiceName": null,
            "discharge": null,
            "load": null,
            "operatorRemarks": null,
            "restow": null,
            "terminalPlanningEnabled": false,
            "shortName": "APMII"
          },
          {
            "id": "85a7b325-c0ad-42a1-8e94-a097e00743de",
            "callId": null,
            "berth": {
              "name": "AMAZONEH ECT DELTA DDE",
              "shortName": null,
              "code": "R8144",
              "terminalCode": "4810",
              "terminalName": "AMAZONEH ECT DELTA DDE",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V.",
              "berthGroupCode": "ECT DELTA",
              "buoy": false,
              "harbourDuesArea": true,
              "partyToNotify": null,
              "bollards": {
                "start": 109,
                "end": 113
              },
              "mooringPoles": null
            },
            "terminal": null,
            "stevedore": null,
            "quay": null,
            "eta": "2023-12-17T23:00:00Z",
            "requestedEta": null,
            "ata": null,
            "etd": "2023-12-18T23:00:00Z",
            "requestedEtd": null,
            "atd": null,
            "mooring": "NO_PREFERENCE",
            "remarks": null,
            "visitPurposes": [
              "DISCHARGE",
              "LOADING"
            ],
            "bollardFrom": null,
            "bollardTo": null,
            "tugboatAtArrival": {
              "required": true,
              "serviceProvider": {
                "name": "Boluda",
                "portAuthorityId": "BOLUDA"
              },
              "requiredQuantity": "ONE",
              "remarks": null
            },
            "boatmenAtArrival": {
              "required": true,
              "serviceProvider": {
                "name": "KRVE",
                "portAuthorityId": "RVE"
              },
              "remarks": null
            },
            "boatmenAtDeparture": {
              "required": true,
              "serviceProvider": {
                "name": "KRVE",
                "portAuthorityId": "RVE"
              },
              "remarks": null
            },
            "tugboatAtDeparture": {
              "required": true,
              "serviceProvider": {
                "name": "Boluda",
                "portAuthorityId": "BOLUDA"
              },
              "requiredQuantity": "TWO",
              "remarks": null
            },
            "nextMovement": {
              "vesselDraft": 12,
              "vesselMasterName": "Jeroen",
              "numberOfCrew": 9,
              "numberOfPassengers": 0,
              "cargo": "CONTAINERIZED",
              "orderEmail": null,
              "orderSms": null,
              "pilotExemption": null,
              "offStandardBeam": null,
              "pilotService": {
                "required": true,
                "serviceProvider": {
                  "name": "Loodswezen",
                  "portAuthorityId": "LRR"
                },
                "remarks": null
              },
              "order": null,
              "cancellationReason": null,
              "stormPilotageInformation": {
                "heliSuitable": null,
                "hoisting": null,
                "remotePilotage": null,
                "imoLoaRegistered": null,
                "lowFreeboard": null,
                "remarks": null
              }
            },
            "vesselServiceCode": null,
            "vesselServiceName": null,
            "discharge": null,
            "load": null,
            "operatorRemarks": null,
            "restow": null,
            "terminalPlanningEnabled": false,
            "shortName": "ECTDELTA"
          }
        ],
        "dangerousGoodsLoading": false,
        "dangerousGoodsDischarge": false,
        "dangerousGoodsTransit": false,
        "vesselInspectionRequired": false,
        "shipConfiguration": null,
        "exitPoint": {
          "code": "LL",
          "name": "Lage Licht",
          "atSea": true
        },
        "etdPort": "2023-12-19T23:00:00Z",
        "atdPort": null,
        "ataPort": null
      },
      "departureVoyage": {
        "voyageNumber": null,
        "carrier": {
          "customsId": "NL005140985",
          "name": "Cross-Ocean B.V.",
          "scacCode": "CROC",
          "smdgCode": null,
          "bicCode": null,
          "cargoDeclarantShortName": null,
          "boxOperators": []
        }
      },
      "nextPorts": [
        {
          "id": "9da53744-4071-4814-bfe1-a8f60c9fb6d3",
          "arrival": "2023-12-21T11:00:00Z",
          "departure": "2023-12-22T11:00:00Z",
          "port": {
            "name": "Willemstad",
            "locationUnCode": "CWWIL",
            "countryUnCode": "CW",
            "euPort": false
          },
          "customsOffice": null
        }
      ]
    },
    "nextVisitDeclaration": null,
    "securityDeclaration": {
      "securityReportRequired": "YES",
      "companySecurityOfficer": {
        "name": "jhjifj",
        "email": "qojdsiuj",
        "phoneNumber": "qiudgui"
      },
      "currentSecurityLevel": "SL1",
      "approvedSspDocumentOnBoard": false,
      "isscSecurityDocument": {
        "availability": "NO",
        "expiryDate": null,
        "reasonNoValidIsscOnBoard": "dsffd",
        "issuer": null
      },
      "shipToShipActivities": [],
      "securityRelatedMatter": null,
      "reasonLessPortFacilities": null
    },
    "wasteDeclaration": null,
    "wasteCollections": [],
    "shipStoresDeclaration": null,
    "dangerousGoodsDeclarations": [],
    "portAuthorityDangerousGoodsDeclarations": null,
    "nextDangerousGoodsDeclaration": null,
    "importDeclarations": [],
    "transhipments": [],
    "transitDeclarationMap": {},
    "importCargoSubscriptions": [],
    "loadingDeclarations": [],
    "paxDeclarationSummaries": [],
    "healthDeclarationSummary": null,
    "etaPortAis": null,
    "ignoreEtaPortAis": false,
    "cancelled": false,
    "terminalPlanningEnabled": false,
    "terminalPlanningApproved": false,
    "ataRegisteredByPa": false,
    "visitKnownAtPa": true,
    "dangerousGoodsEnabled": true,
    "cargoImportEnabled": true,
    "ataAcknowledged": false,
    "clearances": {
      "sspi": null,
      "emas": {
        "departureBerthVisitId": null,
        "arrivalBerthVisitId": null,
        "status": "PENDING",
        "attentionPoints": [],
        "remarks": null
      },
      "emgs": {
        "departureBerthVisitId": null,
        "arrivalBerthVisitId": null,
        "status": "PENDING",
        "attentionPoints": [],
        "remarks": null
      },
      "mdoh": null,
      "ekhList": [
        {
          "departureBerthVisitId": "fe9de138-6fcc-482d-9e9d-535792dc04af",
          "arrivalBerthVisitId": "85a7b325-c0ad-42a1-8e94-a097e00743de",
          "status": "PENDING",
          "attentionPoints": [],
          "remarks": null,
          "channelBound": false,
          "tideRestricted": false
        },
        {
          "departureBerthVisitId": "85a7b325-c0ad-42a1-8e94-a097e00743de",
          "arrivalBerthVisitId": null,
          "status": "APPROVED",
          "attentionPoints": [],
          "remarks": null,
          "channelBound": false,
          "tideRestricted": false
        },
        {
          "departureBerthVisitId": null,
          "arrivalBerthVisitId": "fe9de138-6fcc-482d-9e9d-535792dc04af",
          "status": "APPROVED",
          "attentionPoints": [],
          "remarks": null,
          "channelBound": false,
          "tideRestricted": false
        }
      ]
    },
    "declarations": [
      {
        "type": "VISIT",
        "id": null,
        "status": "ACCEPTED",
        "rejectReasons": null,
        "timeStamp": "2023-12-14T14:40:21.206Z"
      },
      {
        "type": "WPCS",
        "id": null,
        "status": "DECLARED",
        "rejectReasons": null,
        "timeStamp": "2023-12-14T14:40:25.316Z"
      },
      {
        "type": "VISIT",
        "id": null,
        "status": "REJECTED",
        "rejectReasons": "Rejected by Customs: EORI number or TCUIN number is NOT valid (Declarant EORI identification)",
        "timeStamp": "2023-12-14T14:40:42.511Z"
      },
      {
        "type": "VISIT",
        "id": null,
        "status": "ACCEPTED",
        "rejectReasons": null,
        "timeStamp": "2023-12-14T15:07:04.639Z"
      },
      {
        "type": "NOA",
        "id": null,
        "status": "REJECTED",
        "rejectReasons": "Rejected by Customs: EORI number or TCUIN number is NOT valid (Declarant EORI identification)",
        "timeStamp": "2023-12-14T15:07:19.418Z"
      },
      {
        "type": "NOD",
        "id": null,
        "status": "REJECTED",
        "rejectReasons": "Rejected by Customs: EORI number or TCUIN number is NOT valid (Declarant EORI identification)",
        "timeStamp": "2023-12-14T15:07:19.529Z"
      }
    ],
    "uploads": {},
    "berthVisitInfos": {},
    "terminalVisits": {},
    "terminalMessageStatuses": {
      "fe9de138-6fcc-482d-9e9d-535792dc04af": "DISABLED",
      "85a7b325-c0ad-42a1-8e94-a097e00743de": "DISABLED"
    },
    "additionalIncomingMovementInfo": null,
    "incomingMovementHarbourMasterInfo": null,
    "incomingMovementPilotServiceInfo": null,
    "incomingTidalWindowStatus": null,
    "created": "2023-12-14T14:39:14.522Z",
    "updated": "2023-12-14T15:07:19.529Z",
    "berthVisitIdOfTransfer": null,
    "hasBeenTransferred": false,
    "agentAtArrival": null,
    "agentsAtArrival": null,
    "logItems": [
      {
        "timestamp": "2023-12-14T14:39:14.522Z",
        "type": "com.portbase.bezoekschip.common.api.visit.CreateVisit",
        "user": "Service Desk",
        "action": "Visit created",
        "eventId": "72f122ba-84d2-431f-aaf5-6001b6d58f14",
        "crn": "NLRTM23903642",
        "shortName": "CROSSOCEANRTM",
        "fullName": "Cross-Ocean B.V.",
        "allowedViewers": [
          "CROSSOCEANRTM",
          "HBRRTM"
        ]
      },
      {
        "timestamp": "2023-12-14T14:40:21.206Z",
        "type": "com.portbase.bezoekschip.common.api.visit.DeclareVisit",
        "user": "Service Desk",
        "action": "Visit information declared",
        "eventId": "09c76ec9-088c-4e00-b359-900240e31342",
        "crn": "NLRTM23903642",
        "shortName": "CROSSOCEANRTM",
        "fullName": "Cross-Ocean B.V.",
        "allowedViewers": [
          "CROSSOCEANRTM",
          "HBRRTM"
        ]
      },
      {
        "timestamp": "2023-12-14T14:40:24.829Z",
        "type": "com.portbase.bezoekschip.common.api.visit.AcceptVisit",
        "user": "hamis",
        "action": "Visit information received",
        "eventId": "6936297b-75f2-481e-9d1c-463271c18f9c",
        "crn": "NLRTM23903642",
        "shortName": "HBRRTM",
        "fullName": "Havenbedrijf Rotterdam N.V.",
        "allowedViewers": [
          "CROSSOCEANRTM",
          "HBRRTM"
        ]
      },
      {
        "timestamp": "2023-12-14T14:40:42.511Z",
        "type": "com.portbase.bezoekschip.common.api.visit.RejectVisit",
        "user": null,
        "action": "Visit information rejected",
        "eventId": "29d7cbba-1a5a-4f79-89a1-bab20b7c0218",
        "crn": "NLRTM23903642",
        "shortName": "DOUAAPELD",
        "fullName": "Douane Backoffice",
        "allowedViewers": [
          "CROSSOCEANRTM",
          "HBRRTM"
        ]
      },
      {
        "timestamp": "2023-12-14T14:40:50.496Z",
        "type": "com.portbase.bezoekschip.common.api.visit.pa.clearance.RegisterEkhClearanceCommand",
        "user": "portEventsClient",
        "action": "EKH Clearance received",
        "eventId": "1756d69e-a1e7-4853-a9c8-61e643eebb31",
        "crn": "NLRTM23903642",
        "shortName": "HBRRTM",
        "fullName": "Havenbedrijf Rotterdam N.V.",
        "allowedViewers": [
          "CROSSOCEANRTM",
          "HBRRTM"
        ]
      },
      {
        "timestamp": "2023-12-14T14:40:50.622Z",
        "type": "com.portbase.bezoekschip.common.api.visit.pa.clearance.RegisterEkhClearanceCommand",
        "user": "portEventsClient",
        "action": "EKH Clearance received",
        "eventId": "9b257b77-9c24-4ae9-8474-977c25436a7b",
        "crn": "NLRTM23903642",
        "shortName": "HBRRTM",
        "fullName": "Havenbedrijf Rotterdam N.V.",
        "allowedViewers": [
          "CROSSOCEANRTM",
          "HBRRTM"
        ]
      },
      {
        "timestamp": "2023-12-14T14:40:50.630Z",
        "type": "com.portbase.bezoekschip.common.api.visit.pa.clearance.RegisterEkhClearanceCommand",
        "user": "portEventsClient",
        "action": "EKH Clearance received",
        "eventId": "844d9a9e-eec8-497d-8a31-07974284e832",
        "crn": "NLRTM23903642",
        "shortName": "HBRRTM",
        "fullName": "Havenbedrijf Rotterdam N.V.",
        "allowedViewers": [
          "CROSSOCEANRTM",
          "HBRRTM"
        ]
      },
      {
        "timestamp": "2023-12-14T14:40:50.642Z",
        "type": "com.portbase.bezoekschip.common.api.visit.pa.clearance.RegisterEmasClearanceCommand",
        "user": "portEventsClient",
        "action": "EMAS Clearance received",
        "eventId": "10b468db-a04f-499e-877c-7b4004a8319f",
        "crn": "NLRTM23903642",
        "shortName": "HBRRTM",
        "fullName": "Havenbedrijf Rotterdam N.V.",
        "allowedViewers": [
          "CROSSOCEANRTM",
          "HBRRTM"
        ]
      },
      {
        "timestamp": "2023-12-14T14:40:50.720Z",
        "type": "com.portbase.bezoekschip.common.api.visit.pa.clearance.RegisterEmgsClearanceCommand",
        "user": "portEventsClient",
        "action": "EMGS Clearance received",
        "eventId": "87ea732b-62ae-4146-9686-4cdfaeea0e4a",
        "crn": "NLRTM23903642",
        "shortName": "HBRRTM",
        "fullName": "Havenbedrijf Rotterdam N.V.",
        "allowedViewers": [
          "CROSSOCEANRTM",
          "HBRRTM"
        ]
      },
      {
        "timestamp": "2023-12-14T14:41:00.493Z",
        "type": "com.portbase.bezoekschip.common.api.visit.pa.clearance.RegisterEkhClearanceCommand",
        "user": "portEventsClient",
        "action": "EKH Clearance received",
        "eventId": "adefda73-606a-4283-bef5-fcaf4909338e",
        "crn": "NLRTM23903642",
        "shortName": "HBRRTM",
        "fullName": "Havenbedrijf Rotterdam N.V.",
        "allowedViewers": [
          "CROSSOCEANRTM",
          "HBRRTM"
        ]
      },
      {
        "timestamp": "2023-12-14T14:41:10.390Z",
        "type": "com.portbase.bezoekschip.common.api.visit.pa.clearance.RegisterEkhClearanceCommand",
        "user": "portEventsClient",
        "action": "EKH Clearance received",
        "eventId": "5d961997-521c-44a2-9f01-b5136f7878bc",
        "crn": "NLRTM23903642",
        "shortName": "HBRRTM",
        "fullName": "Havenbedrijf Rotterdam N.V.",
        "allowedViewers": [
          "CROSSOCEANRTM",
          "HBRRTM"
        ]
      },
      {
        "timestamp": "2023-12-14T15:07:04.639Z",
        "type": "com.portbase.bezoekschip.common.api.visit.DeclareVisit",
        "user": "Service Desk",
        "action": "Visit information declared",
        "eventId": "7fca55ca-016a-4728-9174-39b210e76a18",
        "crn": "NLRTM23903642",
        "shortName": "CROSSOCEANRTM",
        "fullName": "Cross-Ocean B.V.",
        "allowedViewers": [
          "CROSSOCEANRTM",
          "HBRRTM"
        ]
      },
      {
        "timestamp": "2023-12-14T15:07:08.973Z",
        "type": "com.portbase.bezoekschip.common.api.visit.AcceptVisit",
        "user": "hamis",
        "action": "Visit information received",
        "eventId": "e8958fd3-197c-4597-b4e8-77aa0171ada8",
        "crn": "NLRTM23903642",
        "shortName": "HBRRTM",
        "fullName": "Havenbedrijf Rotterdam N.V.",
        "allowedViewers": [
          "CROSSOCEANRTM",
          "HBRRTM"
        ]
      },
      {
        "timestamp": "2023-12-14T15:07:19.418Z",
        "type": "com.portbase.bezoekschip.common.api.visit.RejectVisit",
        "user": null,
        "action": "Visit information rejected",
        "eventId": "5a41c916-5b9e-4e7b-882b-bc45a3b385ef",
        "crn": "NLRTM23903642",
        "shortName": "DOUAAPELD",
        "fullName": "Douane Backoffice",
        "allowedViewers": [
          "CROSSOCEANRTM",
          "HBRRTM"
        ]
      },
      {
        "timestamp": "2023-12-14T15:07:19.529Z",
        "type": "com.portbase.bezoekschip.common.api.visit.RejectVisit",
        "user": null,
        "action": "Visit information rejected",
        "eventId": "d91e1a17-c8df-425c-9851-b78a3bc5cf68",
        "crn": "NLRTM23903642",
        "shortName": "DOUAAPELD",
        "fullName": "Douane Backoffice",
        "allowedViewers": [
          "CROSSOCEANRTM",
          "HBRRTM"
        ]
      }
    ],
    "passages": [],
    "transitDeclarations": [],
    "visitStatus": "EXPECTED",
    "allowedViewers": [
      {
        "fullName": "Havenbedrijf Rotterdam N.V.",
        "shortName": "HBRRTM",
        "iamConnectedId": null,
        "portAuthorityId": "HbR",
        "emailAddress": "HCC@portofrotterdam.com",
        "address": "Wilhelminakade 909",
        "city": "Rotterdam",
        "zipCode": "3072 AP",
        "countryUnCode": "NL",
        "countryName": "Netherlands",
        "phoneNumber": "+31-10-2521195",
        "faxNumber": "+31-10-2521000",
        "contact": "Diana van Eijk",
        "arrivalLocationHouseNumber": null,
        "customsZipCode": null,
        "customsEORINumber": "NL24354561",
        "ean": null,
        "chamberOfCommerceNumber": null,
        "scacCode": null,
        "ensEnabled": false,
        "cargoImportEnabled": false,
        "cargoImportEnabledByDefault": false
      },
      {
        "fullName": "Cross-Ocean B.V.",
        "shortName": "CROSSOCEANRTM",
        "iamConnectedId": "19431da8-4118-11ea-a528-e77ac7ae1b2f",
        "portAuthorityId": "CROSS OCEAN",
        "emailAddress": "info@portbase.com",
        "address": "Conradstraat 38",
        "city": "Rotterdam",
        "zipCode": "3013 AP",
        "countryUnCode": "NL",
        "countryName": "Netherlands",
        "phoneNumber": "010-2522222",
        "faxNumber": "010-2522250",
        "contact": "Patrick van den Berg",
        "arrivalLocationHouseNumber": "1",
        "customsZipCode": "1000 AA",
        "customsEORINumber": "NL100004118",
        "ean": null,
        "chamberOfCommerceNumber": "24137366",
        "scacCode": "CROS",
        "ensEnabled": true,
        "cargoImportEnabled": true,
        "cargoImportEnabledByDefault": true
      },
      {
        "fullName": "Cross-Ocean B.V.",
        "shortName": "CROSSOCEANRTM",
        "iamConnectedId": "19431da8-4118-11ea-a528-e77ac7ae1b2f",
        "portAuthorityId": "CROSS OCEAN",
        "emailAddress": "vl-test1@portbase.com",
        "address": "Conradstraat 38",
        "city": "Rotterdam",
        "zipCode": "3013 AP",
        "countryUnCode": "NL",
        "countryName": "Netherlands",
        "phoneNumber": "010-2522222",
        "faxNumber": "010-2522250",
        "contact": "Service Desk",
        "arrivalLocationHouseNumber": "1",
        "customsZipCode": "1000 AA",
        "customsEORINumber": "NL100004118",
        "ean": null,
        "chamberOfCommerceNumber": "24137366",
        "scacCode": "CROS",
        "ensEnabled": true,
        "cargoImportEnabled": true,
        "cargoImportEnabledByDefault": true
      }
    ],
    "planned": false
  },
  NLSCE21000425:
    {
      "crn": "NLSCE21000425",
      "copyOfVisit": null,
      "portOfCall": {
        "port": {
          "name": "Scheveningen",
          "locationUnCode": "NLSCE",
          "countryUnCode": "NL",
          "euPort": true
        },
        "portAuthority": {
          "fullName": "Havenbedrijf Scheveningen",
          "shortName": "HBSSCE",
          "portAuthorityId": "NLSCEHBS",
          "emailAddress": "roger.reubzaet@denhaag.nl",
          "address": "Visafslagweg 1",
          "city": "Den Haag",
          "countryUnCode": "NL",
          "countryName": "Netherlands",
          "zipCode": "2583 DM",
          "phoneNumber": "070-3527711",
          "faxNumber": "070-3522151",
          "contact": "Roger Reubzaet",
          "customsEORINumber": null,
          "ean": null,
          "chamberOfCommerceNumber": null,
          "scacCode": null,
          "ensEnabled": false,
          "cargoImportEnabled": false,
          "cargoImportEnabledByDefault": false
        },
        "customsOffice": {
          "name": "Amsterdam/Kantoor Amsterdam",
          "unCode": "NL000854"
        },
        "ataReported": true,
        "atdReported": true,
        "atdPortReported": false,
        "paDeclarationRequired": true,
        "swDeclarationRequired": true,
        "wasteEnabled": false,
        "dangerousGoodsEnabled": false,
        "harbourDuesEnabled": false,
        "orderNauticalServicesEnabled": false,
        "enableNotificationsToPa": false,
        "authoriseOrganisationsDisabled": false,
        "tugBoatsRequired": true
      },
      "vessel": {
        "imoCode": "9185932",
        "name": "BEAR",
        "radioCallSign": "ORRV",
        "motUnCode": "1723",
        "motName": "Offshore support vessel",
        "summerDeadWeight": 2854,
        "maxWidth": 16.8,
        "flagCode": "BEL",
        "flagCountryUnCode": "BE",
        "netTonnage": 777,
        "grossTonnage": 2590,
        "registrationPlaceUnloCode": "BEANR",
        "registrationPlaceName": "Antwerpen",
        "registrationDate": "1999-01-22",
        "mmsiNumber": "205694000",
        "fullLength": 73.5,
        "emailAddress": null
      },
      "owner": {
        "fullName": "Oudkerk B.V.",
        "shortName": "OUDKERKRTM",
        "portAuthorityId": "OKBV",
        "emailAddress": "agencies@oudkerk.com",
        "address": "Terwenakker 42-44",
        "city": "Rotterdam",
        "countryUnCode": "NL",
        "countryName": "Netherlands",
        "zipCode": "3011 XS",
        "phoneNumber": "010-2013777",
        "faxNumber": "010-4330048",
        "contact": "Tim van der Laan",
        "customsEORINumber": "NL007168019",
        "ean": null,
        "chamberOfCommerceNumber": "24164038",
        "scacCode": "OUDK",
        "ensEnabled": true,
        "cargoImportEnabled": true,
        "cargoImportEnabledByDefault": true
      },
      "nextOwner": null,
      "declarant": {
        "fullName": "Oudkerk B.V.",
        "shortName": "OUDKERKRTM",
        "portAuthorityId": "OKBV",
        "emailAddress": "agencies@oudkerk.com",
        "address": "Terwenakker 42-44",
        "city": "Rotterdam",
        "countryUnCode": "NL",
        "countryName": "Netherlands",
        "zipCode": "3011 XS",
        "phoneNumber": "010-2013777",
        "faxNumber": "010-4330048",
        "contact": "Tim van der Laan",
        "customsEORINumber": "NL007168019",
        "ean": null,
        "chamberOfCommerceNumber": "24164038",
        "scacCode": "OUDK",
        "ensEnabled": true,
        "cargoImportEnabled": true,
        "cargoImportEnabledByDefault": true
      },
      "nextDeclarant": null,
      "financialDeclarant": {
        "fullName": "Oudkerk B.V.",
        "shortName": "OUDKERKRTM",
        "portAuthorityId": "OKBV",
        "emailAddress": "agencies@oudkerk.com",
        "address": "Terwenakker 42-44",
        "city": "Rotterdam",
        "countryUnCode": "NL",
        "countryName": "Netherlands",
        "zipCode": "3011 XS",
        "phoneNumber": "010-2013777",
        "faxNumber": "010-4330048",
        "contact": "Tim van der Laan",
        "customsEORINumber": "NL007168019",
        "ean": null,
        "chamberOfCommerceNumber": "24164038",
        "scacCode": "OUDK",
        "ensEnabled": true,
        "cargoImportEnabled": true,
        "cargoImportEnabledByDefault": true
      },
      "nominatedFinancialDeclarant": null,
      "orderNauticalServices": false,
      "orderIncomingMovement": false,
      "creditNumberHolderSameAsDeclarant": false,
      "declarantResponsibleForCustoms": false,
      "cargoDeclarants": [
        {
          "fullName": "Oudkerk B.V.",
          "shortName": "OUDKERKRTM",
          "portAuthorityId": "OKBV",
          "emailAddress": "agencies@oudkerk.com",
          "address": "Terwenakker 42-44",
          "city": "Rotterdam",
          "countryUnCode": "NL",
          "countryName": "Netherlands",
          "zipCode": "3011 XS",
          "phoneNumber": "010-2013777",
          "faxNumber": "010-4330048",
          "contact": "Tim van der Laan",
          "customsEORINumber": "NL007168019",
          "ean": null,
          "chamberOfCommerceNumber": "24164038",
          "scacCode": "OUDK",
          "ensEnabled": true,
          "cargoImportEnabled": true,
          "cargoImportEnabledByDefault": true
        }
      ],
      "shipOperators": [
        {
          "fullName": "Oudkerk B.V.",
          "shortName": "OUDKERKRTM",
          "portAuthorityId": "OKBV",
          "emailAddress": "agencies@oudkerk.com",
          "address": "Terwenakker 42-44",
          "city": "Rotterdam",
          "countryUnCode": "NL",
          "countryName": "Netherlands",
          "zipCode": "3011 XS",
          "phoneNumber": "010-2013777",
          "faxNumber": "010-4330048",
          "contact": "Tim van der Laan",
          "customsEORINumber": "NL007168019",
          "ean": null,
          "chamberOfCommerceNumber": "24164038",
          "scacCode": "OUDK",
          "ensEnabled": true,
          "cargoImportEnabled": true,
          "cargoImportEnabledByDefault": true
        },
        {
          "fullName": "Oudkerk B.V.",
          "shortName": "OUDKERKRTM",
          "portAuthorityId": "OKBV",
          "emailAddress": "agencies@oudkerk.com",
          "address": "Terwenakker 42-44",
          "city": "Rotterdam",
          "countryUnCode": "NL",
          "countryName": "Netherlands",
          "zipCode": "3011 XS",
          "phoneNumber": "010-2013777",
          "faxNumber": "010-4330048",
          "contact": "Tim van der Laan",
          "customsEORINumber": "NL007168019",
          "ean": null,
          "chamberOfCommerceNumber": "24164038",
          "scacCode": "OUDK",
          "ensEnabled": true,
          "cargoImportEnabled": true,
          "cargoImportEnabledByDefault": true
        },
        {
          "fullName": "Havenbedrijf Scheveningen",
          "shortName": "HBSSCE",
          "portAuthorityId": "NLSCEHBS",
          "emailAddress": "roger.reubzaet@denhaag.nl",
          "address": "Visafslagweg 1",
          "city": "Den Haag",
          "countryUnCode": "NL",
          "countryName": "Netherlands",
          "zipCode": "2583 DM",
          "phoneNumber": "070-3527711",
          "faxNumber": "070-3522151",
          "contact": "Roger Reubzaet",
          "customsEORINumber": null,
          "ean": null,
          "chamberOfCommerceNumber": null,
          "scacCode": null,
          "ensEnabled": false,
          "cargoImportEnabled": false,
          "cargoImportEnabledByDefault": false
        }
      ],
      "additionalViewers": [],
      "visitDeclaration": {
        "clientReferenceNumber": "087321",
        "vesselEmailAddress": null,
        "etaFirstEntryEu": null,
        "arrivalVoyage": {
          "voyageNumber": "087321",
          "carrier": {
            "customsId": "NL007168019",
            "name": "Oudkerk B.V.",
            "scacCode": "OUDK",
            "smdgCode": null,
            "cargoDeclarantShortName": null
          }
        },
        "previousPorts": [
          {
            "id": "6137dfdb-e77f-4c0e-a7e3-28a5560bbab4",
            "arrival": "2021-09-02T10:00:00Z",
            "departure": "2021-09-05T10:00:00Z",
            "port": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "portFacilityVisits": [
              {
                "portFacility": {
                  "code": "NLRTM-9999",
                  "name": "UNKNOWN"
                },
                "arrivalDate": "2021-09-02",
                "departureDate": "2021-09-05",
                "securityLevel": "SL1",
                "additionalSecurityMeasures": null
              }
            ]
          },
          {
            "id": "596fc767-f1af-4275-aa49-c447a926514e",
            "arrival": "2021-08-26T10:00:00Z",
            "departure": "2021-08-26T10:00:00Z",
            "port": {
              "name": "Las Palmas de Gran Canaria",
              "locationUnCode": "ESLPA",
              "countryUnCode": "ES",
              "euPort": true
            },
            "portFacilityVisits": [
              {
                "portFacility": {
                  "code": "ESLPA-9999",
                  "name": "UNKNOWN"
                },
                "arrivalDate": "2021-08-26",
                "departureDate": "2021-08-26",
                "securityLevel": "SL1",
                "additionalSecurityMeasures": null
              }
            ]
          },
          {
            "id": "c3bab22f-8cf9-4c15-a537-57ca13ac014e",
            "arrival": "2021-07-28T10:00:00Z",
            "departure": "2021-08-11T10:00:00Z",
            "port": {
              "name": "Rio de Janeiro",
              "locationUnCode": "BRRIO",
              "countryUnCode": "BR",
              "euPort": false
            },
            "portFacilityVisits": [
              {
                "portFacility": {
                  "code": "BRRIO-9999",
                  "name": "UNKNOWN"
                },
                "arrivalDate": "2021-07-28",
                "departureDate": "2021-08-11",
                "securityLevel": "SL1",
                "additionalSecurityMeasures": null
              }
            ]
          },
          {
            "id": "54379595-2406-4111-9cc6-da7f57125210",
            "arrival": "2021-07-15T10:00:00Z",
            "departure": "2021-07-15T10:00:00Z",
            "port": {
              "name": "Belem",
              "locationUnCode": "BRBEL",
              "countryUnCode": "BR",
              "euPort": false
            },
            "portFacilityVisits": [
              {
                "portFacility": {
                  "code": "BRBEL-9999",
                  "name": "UNKNOWN"
                },
                "arrivalDate": "2021-07-15",
                "departureDate": "2021-07-15",
                "securityLevel": "SL1",
                "additionalSecurityMeasures": null
              }
            ]
          },
          {
            "id": "b84a7131-21f5-45ac-87e4-096ccbb8826a",
            "arrival": "2021-07-01T10:00:00Z",
            "departure": "2021-07-07T10:00:00Z",
            "port": {
              "name": "Pointe Noire",
              "locationUnCode": "CGPNR",
              "countryUnCode": "CG",
              "euPort": false
            },
            "portFacilityVisits": [
              {
                "portFacility": {
                  "code": "CGPNR-9999",
                  "name": "UNKNOWN"
                },
                "arrivalDate": "2021-07-01",
                "departureDate": "2021-07-07",
                "securityLevel": "SL1",
                "additionalSecurityMeasures": null
              }
            ]
          },
          {
            "id": "db8ed348-ba8f-4de0-a337-f846ccf6c884",
            "arrival": "2021-06-07T10:00:00Z",
            "departure": "2021-06-18T10:00:00Z",
            "port": {
              "name": "Rio de Janeiro",
              "locationUnCode": "BRRIO",
              "countryUnCode": "BR",
              "euPort": false
            },
            "portFacilityVisits": [
              {
                "portFacility": {
                  "code": "BRRIO-9999",
                  "name": "UNKNOWN"
                },
                "arrivalDate": "2021-06-07",
                "departureDate": "2021-06-18",
                "securityLevel": "SL1",
                "additionalSecurityMeasures": null
              }
            ]
          },
          {
            "id": "74886659-a499-4518-9af7-43ad7b8eedba",
            "arrival": "2021-05-17T10:00:00Z",
            "departure": "2021-05-21T10:00:00Z",
            "port": {
              "name": "Pointe Noire",
              "locationUnCode": "CGPNR",
              "countryUnCode": "CG",
              "euPort": false
            },
            "portFacilityVisits": [
              {
                "portFacility": {
                  "code": "CGPNR-9999",
                  "name": "UNKNOWN"
                },
                "arrivalDate": "2021-05-19",
                "departureDate": "2021-05-21",
                "securityLevel": "SL1",
                "additionalSecurityMeasures": null
              },
              {
                "portFacility": {
                  "code": "CGPNR-9999",
                  "name": "UNKNOWN"
                },
                "arrivalDate": "2021-05-17",
                "departureDate": "2021-05-19",
                "securityLevel": "SL1",
                "additionalSecurityMeasures": null
              }
            ]
          },
          {
            "id": "8ef735e4-f994-41d2-8968-f56904dbb64c",
            "arrival": "2021-05-15T10:00:00Z",
            "departure": "2021-05-16T10:00:00Z",
            "port": {
              "name": "Soyo",
              "locationUnCode": "AOSZA",
              "countryUnCode": "AO",
              "euPort": false
            },
            "portFacilityVisits": [
              {
                "portFacility": {
                  "code": "AOSZA-9999",
                  "name": "UNKNOWN"
                },
                "arrivalDate": "2021-05-15",
                "departureDate": "2021-05-16",
                "securityLevel": "SL1",
                "additionalSecurityMeasures": null
              }
            ]
          },
          {
            "id": "d9840e5c-392f-4fce-82a2-91b7026720c3",
            "arrival": "2021-05-10T10:00:00Z",
            "departure": "2021-05-14T10:00:00Z",
            "port": {
              "name": "Pointe Noire",
              "locationUnCode": "CGPNR",
              "countryUnCode": "CG",
              "euPort": false
            },
            "portFacilityVisits": [
              {
                "portFacility": {
                  "code": "CGPNR-9999",
                  "name": "UNKNOWN"
                },
                "arrivalDate": "2021-05-10",
                "departureDate": "2021-05-14",
                "securityLevel": "SL1",
                "additionalSecurityMeasures": null
              }
            ]
          }
        ],
        "portVisit": {
          "pilotStation": null,
          "entryPoint": {
            "code": "SCHNZ",
            "name": "Zee",
            "atSea": null
          },
          "etaPort": "2021-09-21T15:00:00Z",
          "portEntry": null,
          "firstMovement": {
            "vesselDraft": 6.5,
            "vesselMasterName": "Denis Kozuscenko",
            "numberOfCrew": 14,
            "numberOfPassengers": 0,
            "cargo": "BALLAST_CONDITION",
            "orderEmail": null,
            "orderSms": null,
            "pilotExemption": null,
            "offStandardBeam": null,
            "pilotService": {
              "required": false,
              "serviceProvider": null,
              "remarks": null
            },
            "order": null,
            "stormPilotageInformation": null
          },
          "passingThroughTugboats": null,
          "defectTypes": [],
          "defectTypeRemarks": null,
          "berthVisits": [
            {
              "id": "de5fdfa6-19da-4b8c-954a-44826b9478bd",
              "berth": {
                "name": "LANGE KANT 1E HAVEN",
                "shortName": null,
                "code": "SCEL1",
                "terminalCode": "SCEL1",
                "terminalName": "LANGE KANT 1E HAVEN",
                "organisationShortName": null,
                "organisationName": null,
                "berthGroupCode": "LANGE KANT 1E HAVEN",
                "buoy": false,
                "harbourDuesArea": false,
                "partyToNotify": null
              },
              "eta": null,
              "requestedEta": null,
              "ata": "2021-09-21T14:45:00Z",
              "etd": null,
              "requestedEtd": null,
              "atd": null,
              "mooring": "NO_PREFERENCE",
              "remarks": null,
              "visitPurposes": [
                "BUNKERING"
              ],
              "bollardFrom": null,
              "bollardTo": null,
              "tugboatAtArrival": {
                "required": false,
                "serviceProvider": {
                  "name": "Havensleepdienst Scheveningen BV",
                  "portAuthorityId": "SLS"
                },
                "requiredQuantity": null,
                "remarks": null
              },
              "boatmenAtArrival": {
                "required": false,
                "serviceProvider": null,
                "remarks": null
              },
              "boatmenAtDeparture": {
                "required": false,
                "serviceProvider": null,
                "remarks": null
              },
              "tugboatAtDeparture": {
                "required": false,
                "serviceProvider": {
                  "name": "Havensleepdienst Scheveningen BV",
                  "portAuthorityId": "SLS"
                },
                "requiredQuantity": null,
                "remarks": null
              },
              "nextMovement": {
                "vesselDraft": 6.5,
                "vesselMasterName": "Denis Kozuscenko",
                "numberOfCrew": 1,
                "numberOfPassengers": 0,
                "cargo": "BALLAST_CONDITION",
                "orderEmail": null,
                "orderSms": null,
                "pilotExemption": null,
                "offStandardBeam": null,
                "pilotService": {
                  "required": false,
                  "serviceProvider": null,
                  "remarks": null
                },
                "order": null,
                "stormPilotageInformation": null
              }
            }
          ],
          "dangerousGoodsLoading": false,
          "dangerousGoodsDischarge": false,
          "dangerousGoodsTransit": false,
          "vesselInspectionRequired": false,
          "shipConfiguration": null,
          "exitPoint": {
            "code": "SCHNZ",
            "name": "Zee",
            "atSea": null
          },
          "etdPort": "2021-09-22T18:00:00Z",
          "atdPort": null,
          "ataPort": "2021-09-21T14:45:00Z"
        },
        "departureVoyage": {
          "voyageNumber": "087321",
          "carrier": {
            "customsId": "NL007168019",
            "name": "Oudkerk B.V.",
            "scacCode": "OUDK",
            "smdgCode": null,
            "cargoDeclarantShortName": null
          }
        },
        "nextPorts": [
          {
            "id": "da0c295d-f994-4cc4-8057-a81846afbf81",
            "arrival": "2021-09-23T10:00:00Z",
            "departure": null,
            "port": {
              "name": "North Sea",
              "locationUnCode": "XZZNO",
              "countryUnCode": "NL",
              "euPort": true
            },
            "customsOffice": null
          }
        ]
      },
      "nextVisitDeclaration": null,
      "securityDeclaration": {
        "securityReportRequired": "YES",
        "companySecurityOfficer": {
          "name": "Johan Zonneveld",
          "email": "johan.zonneveld@boskalis.com",
          "phoneNumber": "31620614625"
        },
        "currentSecurityLevel": "SL1",
        "approvedSspDocumentOnBoard": true,
        "isscSecurityDocument": {
          "availability": "YES",
          "expiryDate": "2024-10-21",
          "reasonNoValidIsscOnBoard": null,
          "issuer": {
            "code": "80",
            "name": "Other"
          }
        },
        "shipToShipActivities": [],
        "securityRelatedMatter": null
      },
      "wasteDeclaration": null,
      "wasteCollections": [],
      "shipStoresDeclaration": {
        "items": {
          "SPIRITS": 0,
          "BEER": 0,
          "WINE": 0,
          "OTHER": 0,
          "CIGARETTES": 55800,
          "CIGARS": 0,
          "TOBACCO": 1.7,
          "FUEL_OIL": 638000,
          "LUBRICATING_OIL": 27847,
          "MEAT": 150,
          "NARCOTICS": 0.00401,
          "FIRE_ARMS": 0
        }
      },
      "dangerousGoodsDeclarations": [],
      "nextDangerousGoodsDeclaration": null,
      "importDeclarations": [],
      "transitDeclarationMap": {},
      "loadingDeclarations": [],
      "paxDeclarationSummaries": [],
      "healthDeclarationSummary": null,
      "etaPortAis": null,
      "ignoreEtaPortAis": false,
      "cancelled": false,
      "ataRegisteredByPa": true,
      "visitKnownAtPa": true,
      "dangerousGoodsEnabled": false,
      "cargoImportEnabled": true,
      "ataAcknowledged": true,
      "declarations": [
        {
          "type": "VISIT",
          "id": null,
          "status": "ACCEPTED",
          "rejectReasons": null,
          "timeStamp": "2021-09-21T13:33:43.842Z"
        },
        {
          "type": "WPCS",
          "id": null,
          "status": "DECLARED",
          "rejectReasons": null,
          "timeStamp": "2021-09-21T13:33:57.226Z"
        },
        {
          "type": "VISIT",
          "id": null,
          "status": "ACCEPTED",
          "rejectReasons": null,
          "timeStamp": "2021-09-21T13:47:26.595Z"
        },
        {
          "type": "WPCS",
          "id": null,
          "status": "DECLARED",
          "rejectReasons": null,
          "timeStamp": "2021-09-21T13:47:26.676Z"
        },
        {
          "type": "SECURITY",
          "id": null,
          "status": "ACCEPTED",
          "rejectReasons": null,
          "timeStamp": "2021-09-21T14:15:04.298Z"
        },
        {
          "type": "WPCS",
          "id": null,
          "status": "DECLARED",
          "rejectReasons": null,
          "timeStamp": "2021-09-21T14:15:04.381Z"
        },
        {
          "type": "VISIT",
          "id": null,
          "status": "ACCEPTED",
          "rejectReasons": null,
          "timeStamp": "2021-09-21T14:20:19.692Z"
        },
        {
          "type": "WPCS",
          "id": null,
          "status": "DECLARED",
          "rejectReasons": null,
          "timeStamp": "2021-09-21T14:20:19.865Z"
        },
        {
          "type": "STO",
          "id": null,
          "status": "ACCEPTED",
          "rejectReasons": null,
          "timeStamp": "2021-09-21T14:40:28.073Z"
        },
        {
          "type": "WPCS",
          "id": null,
          "status": "DECLARED",
          "rejectReasons": null,
          "timeStamp": "2021-09-21T14:45:45.295Z"
        }
      ],
      "uploads": {
        "SECURITY": "b3f362d8-8846-425f-a19e-a03b9f061d03"
      },
      "additionalIncomingMovementInfo": null,
      "berthVisitInfos": {},
      "incomingMovementHarbourMasterInfo": null,
      "incomingTidalWindowStatus": null,
      "lastMessageId": "6241191a-b304-4b84-b928-9e9068fa8f99",
      "created": "2021-09-21T13:25:58.267Z",
      "updated": "2021-09-21T14:46:22.328Z",
      "berthVisitIdOfTransfer": null,
      "hasBeenTransferred": false,
      "previousMessageId": "1957a6fa-7169-489a-a249-5628858e25a8",
      "nextMessageId": null,
      "transitDeclarations": [],
      "allowedViewers": [
        {
          "fullName": "Havenbedrijf Scheveningen",
          "shortName": "HBSSCE",
          "portAuthorityId": "NLSCEHBS",
          "emailAddress": "roger.reubzaet@denhaag.nl",
          "address": "Visafslagweg 1",
          "city": "Den Haag",
          "countryUnCode": "NL",
          "countryName": "Netherlands",
          "zipCode": "2583 DM",
          "phoneNumber": "070-3527711",
          "faxNumber": "070-3522151",
          "contact": "Roger Reubzaet",
          "customsEORINumber": null,
          "ean": null,
          "chamberOfCommerceNumber": null,
          "scacCode": null,
          "ensEnabled": false,
          "cargoImportEnabled": false,
          "cargoImportEnabledByDefault": false
        },
        {
          "fullName": "Oudkerk B.V.",
          "shortName": "OUDKERKRTM",
          "portAuthorityId": "OKBV",
          "emailAddress": "agencies@oudkerk.com",
          "address": "Terwenakker 42-44",
          "city": "Rotterdam",
          "countryUnCode": "NL",
          "countryName": "Netherlands",
          "zipCode": "3011 XS",
          "phoneNumber": "010-2013777",
          "faxNumber": "010-4330048",
          "contact": "Tim van der Laan",
          "customsEORINumber": "NL007168019",
          "ean": null,
          "chamberOfCommerceNumber": "24164038",
          "scacCode": "OUDK",
          "ensEnabled": true,
          "cargoImportEnabled": true,
          "cargoImportEnabledByDefault": true
        }
      ],
      "visitStatus": "ARRIVED"
    },
  NLRTM21018153: {
    "crn": "NLRTM21018153",
    "copyOfVisit": "NLRTM21010029",
    "portOfCall": {
      "port": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM",
        "countryUnCode": "NL",
        "euPort": true
      },
      "portAuthority": {
        "fullName": "Havenbedrijf Rotterdam N.V.",
        "shortName": "HBRRTM",
        "portAuthorityId": "HbR",
        "emailAddress": "HCC@portofrotterdam.com",
        "address": "Wilhelminakade 909",
        "city": "Rotterdam",
        "countryUnCode": "NL",
        "countryName": "Netherlands",
        "zipCode": "3072 AP",
        "phoneNumber": "+31-10-2521195",
        "faxNumber": "+31-10-2521000",
        "contact": "Diana van Eijk",
        "customsEORINumber": "NL24354561",
        "ean": null,
        "chamberOfCommerceNumber": null,
        "scacCode": null,
        "ensEnabled": false,
        "cargoImportEnabled": false,
        "cargoImportEnabledByDefault": false
      },
      "customsOffice": {
        "name": "Rotterdam Haven/Kantoor Maasvlakte",
        "unCode": "NL000396"
      },
      "ataReported": true,
      "atdReported": true,
      "atdPortReported": true,
      "paDeclarationRequired": true,
      "swDeclarationRequired": true,
      "wasteEnabled": true,
      "dangerousGoodsEnabled": true,
      "harbourDuesEnabled": false,
      "orderNauticalServicesEnabled": true,
      "enableNotificationsToPa": false,
      "authoriseOrganisationsDisabled": false,
      "tugBoatsRequired": true,
      "portAuthorityClearance": true
    },
    "vessel": {
      "imoCode": "8711837",
      "name": "MANDALAY",
      "radioCallSign": "9V3438",
      "motUnCode": "1511",
      "motName": "Full container ship/cellular vessel",
      "summerDeadWeight": 30156,
      "maxWidth": 30.47,
      "flagCode": "SGP",
      "flagCountryUnCode": "SG",
      "netTonnage": 10013,
      "grossTonnage": 27279,
      "registrationPlaceUnloCode": "SGSIN",
      "registrationPlaceName": "Singapore",
      "registrationDate": "2019-09-05",
      "mmsiNumber": "563024900",
      "fullLength": 188.84,
      "emailAddress": null
    },
    "owner": {
      "fullName": "CMA-CGM (Holland) B.V.",
      "shortName": "CMACGMRHN",
      "portAuthorityId": "CMA-CGM",
      "emailAddress": "rtm.shipops@cma-cgm.com",
      "address": "Achterdijk 55",
      "city": "Rhoon",
      "countryUnCode": "NL",
      "countryName": "Netherlands",
      "zipCode": "3161 EB",
      "phoneNumber": "010-2998199",
      "faxNumber": "010-2998190",
      "contact": "Adrie Tiemens",
      "customsEORINumber": "NL806025463",
      "ean": null,
      "chamberOfCommerceNumber": "24276503",
      "scacCode": null,
      "ensEnabled": false,
      "cargoImportEnabled": true,
      "cargoImportEnabledByDefault": true
    },
    "nextOwner": null,
    "declarant": {
      "fullName": "CMA-CGM (Holland) B.V.",
      "shortName": "CMACGMRHN",
      "portAuthorityId": "CMA-CGM",
      "emailAddress": "rtm.shipops@cma-cgm.com",
      "address": "Achterdijk 55",
      "city": "Rhoon",
      "countryUnCode": "NL",
      "countryName": "Netherlands",
      "zipCode": "3161 EB",
      "phoneNumber": "010-2998199",
      "faxNumber": "010-2998190",
      "contact": "Adrie Tiemens",
      "customsEORINumber": "NL806025463",
      "ean": null,
      "chamberOfCommerceNumber": "24276503",
      "scacCode": null,
      "ensEnabled": false,
      "cargoImportEnabled": true,
      "cargoImportEnabledByDefault": true
    },
    "nextDeclarant": null,
    "financialDeclarant": {
      "fullName": "CMA-CGM (Holland) B.V.",
      "shortName": "CMACGMRHN",
      "portAuthorityId": "CMA-CGM",
      "emailAddress": "rtm.shipops@cma-cgm.com",
      "address": "Achterdijk 55",
      "city": "Rhoon",
      "countryUnCode": "NL",
      "countryName": "Netherlands",
      "zipCode": "3161 EB",
      "phoneNumber": "010-2998199",
      "faxNumber": "010-2998190",
      "contact": "Adrie Tiemens",
      "customsEORINumber": "NL806025463",
      "ean": null,
      "chamberOfCommerceNumber": "24276503",
      "scacCode": null,
      "ensEnabled": false,
      "cargoImportEnabled": true,
      "cargoImportEnabledByDefault": true
    },
    "nominatedFinancialDeclarant": null,
    "orderNauticalServices": true,
    "orderIncomingMovement": true,
    "creditNumberHolderSameAsDeclarant": false,
    "declarantResponsibleForCustoms": false,
    "cargoDeclarants": [
      {
        "fullName": "CMA-CGM (Holland) B.V.",
        "shortName": "CMACGMRHN",
        "portAuthorityId": "CMA-CGM",
        "emailAddress": "rtm.shipops@cma-cgm.com",
        "address": "Achterdijk 55",
        "city": "Rhoon",
        "countryUnCode": "NL",
        "countryName": "Netherlands",
        "zipCode": "3161 EB",
        "phoneNumber": "010-2998199",
        "faxNumber": "010-2998190",
        "contact": "Marcel den Boogert",
        "customsEORINumber": "NL806025463",
        "ean": null,
        "chamberOfCommerceNumber": "24276503",
        "scacCode": null,
        "ensEnabled": false,
        "cargoImportEnabled": true,
        "cargoImportEnabledByDefault": true
      },
      {
        "fullName": "Broekman Shipping B.V.",
        "shortName": "BSDRTM",
        "portAuthorityId": "BROEKMAN SHIPPING",
        "emailAddress": "marine@broekmanlogistics.com",
        "address": "Waalhaven Z.z. 21",
        "city": "Rotterdam",
        "countryUnCode": "NL",
        "countryName": "Netherlands",
        "zipCode": "3089 JH",
        "phoneNumber": "+31-10-4873398",
        "faxNumber": null,
        "contact": "Frank Volker",
        "customsEORINumber": "NL800822274",
        "ean": null,
        "chamberOfCommerceNumber": "24194505",
        "scacCode": "BROE",
        "ensEnabled": true,
        "cargoImportEnabled": true,
        "cargoImportEnabledByDefault": true
      }
    ],
    "shipOperators": [
      {
        "fullName": "CMA-CGM (Holland) B.V.",
        "shortName": "CMACGMRHN",
        "portAuthorityId": "CMA-CGM",
        "emailAddress": "rtm.shipops@cma-cgm.com",
        "address": "Achterdijk 55",
        "city": "Rhoon",
        "countryUnCode": "NL",
        "countryName": "Netherlands",
        "zipCode": "3161 EB",
        "phoneNumber": "010-2998199",
        "faxNumber": "010-2998190",
        "contact": "Adrie Tiemens",
        "customsEORINumber": "NL806025463",
        "ean": null,
        "chamberOfCommerceNumber": "24276503",
        "scacCode": null,
        "ensEnabled": false,
        "cargoImportEnabled": true,
        "cargoImportEnabledByDefault": true
      },
      {
        "fullName": "CMA-CGM (Holland) B.V.",
        "shortName": "CMACGMRHN",
        "portAuthorityId": "CMA-CGM",
        "emailAddress": "rtm.shipops@cma-cgm.com",
        "address": "Achterdijk 55",
        "city": "Rhoon",
        "countryUnCode": "NL",
        "countryName": "Netherlands",
        "zipCode": "3161 EB",
        "phoneNumber": "010-2998199",
        "faxNumber": "010-2998190",
        "contact": "Adrie Tiemens",
        "customsEORINumber": "NL806025463",
        "ean": null,
        "chamberOfCommerceNumber": "24276503",
        "scacCode": null,
        "ensEnabled": false,
        "cargoImportEnabled": true,
        "cargoImportEnabledByDefault": true
      },
      {
        "fullName": "Havenbedrijf Rotterdam N.V.",
        "shortName": "HBRRTM",
        "portAuthorityId": "HbR",
        "emailAddress": "HCC@portofrotterdam.com",
        "address": "Wilhelminakade 909",
        "city": "Rotterdam",
        "countryUnCode": "NL",
        "countryName": "Netherlands",
        "zipCode": "3072 AP",
        "phoneNumber": "+31-10-2521195",
        "faxNumber": "+31-10-2521000",
        "contact": "Diana van Eijk",
        "customsEORINumber": "NL24354561",
        "ean": null,
        "chamberOfCommerceNumber": null,
        "scacCode": null,
        "ensEnabled": false,
        "cargoImportEnabled": false,
        "cargoImportEnabledByDefault": false
      }
    ],
    "additionalViewers": [],
    "visitDeclaration": {
      "clientReferenceNumber": "0RP9FS1MA",
      "vesselEmailAddress": null,
      "etaFirstEntryEu": null,
      "arrivalVoyage": {
        "voyageNumber": "0RP9FS1MA",
        "carrier": {
          "customsId": "FR56202442200486",
          "name": "CMA-CGM",
          "scacCode": "CMDU",
          "smdgCode": "CMA",
          "cargoDeclarantShortName": "CMACGMRHN"
        }
      },
      "previousPorts": [
        {
          "id": "b8b3722e-1ae1-4d0b-bd30-cbee3f8e3cf2",
          "arrival": null,
          "departure": "2021-09-20T10:00:00Z",
          "port": {
            "name": "Zeebrugge",
            "locationUnCode": "BEZEE",
            "countryUnCode": "BE",
            "euPort": true
          },
          "portFacilityVisits": [
            {
              "portFacility": {
                "code": "BEZEE-0024",
                "name": "CSP Zeebrugge Terminal"
              },
              "arrivalDate": null,
              "departureDate": null,
              "securityLevel": "SL1",
              "additionalSecurityMeasures": "N/A"
            }
          ]
        },
        {
          "id": "2c4ce689-24dd-4f77-b750-91e4bdbac949",
          "arrival": null,
          "departure": null,
          "port": {
            "name": "Philadelphia",
            "locationUnCode": "USPHL",
            "countryUnCode": "US",
            "euPort": false
          },
          "portFacilityVisits": [
            {
              "portFacility": {
                "code": "USPHL-0001",
                "name": "Philadelphia Port Area"
              },
              "arrivalDate": null,
              "departureDate": null,
              "securityLevel": "SL1",
              "additionalSecurityMeasures": "N/A"
            }
          ]
        },
        {
          "id": "21df1fd2-333d-46b8-b9be-a507787e1c39",
          "arrival": null,
          "departure": null,
          "port": {
            "name": "Savannah",
            "locationUnCode": "USSAV",
            "countryUnCode": "US",
            "euPort": false
          },
          "portFacilityVisits": [
            {
              "portFacility": {
                "code": "USSAV-0001",
                "name": "Savannah Port Area"
              },
              "arrivalDate": null,
              "departureDate": null,
              "securityLevel": "SL1",
              "additionalSecurityMeasures": null
            }
          ]
        },
        {
          "id": "28c83b90-42b9-45a6-9a60-7aa20947e424",
          "arrival": null,
          "departure": null,
          "port": {
            "name": "Papeete",
            "locationUnCode": "PFPPT",
            "countryUnCode": "PF",
            "euPort": false
          },
          "portFacilityVisits": []
        },
        {
          "id": "ae54abbf-04d3-492d-9044-644dfcbc8dba",
          "arrival": null,
          "departure": null,
          "port": {
            "name": "Tauranga",
            "locationUnCode": "NZTRG",
            "countryUnCode": "NZ",
            "euPort": false
          },
          "portFacilityVisits": [
            {
              "portFacility": {
                "code": "NZTRG-0001",
                "name": "Port of Tauranga Ltd"
              },
              "arrivalDate": null,
              "departureDate": null,
              "securityLevel": "SL1",
              "additionalSecurityMeasures": null
            }
          ]
        },
        {
          "id": "761aa031-489e-42ed-bf02-52b8ae75bec9",
          "arrival": null,
          "departure": null,
          "port": {
            "name": "Melbourne",
            "locationUnCode": "AUMEL",
            "countryUnCode": "AU",
            "euPort": false
          },
          "portFacilityVisits": [
            {
              "portFacility": {
                "code": "AUMEL-0013",
                "name": "VIC-MEL-DP World-West Swanson Terminal"
              },
              "arrivalDate": null,
              "departureDate": null,
              "securityLevel": "SL1",
              "additionalSecurityMeasures": "N/A"
            }
          ]
        },
        {
          "id": "e8292e37-26c0-4c04-a692-3916889ad809",
          "arrival": null,
          "departure": null,
          "port": {
            "name": "Sydney",
            "locationUnCode": "AUSYD",
            "countryUnCode": "AU",
            "euPort": false
          },
          "portFacilityVisits": [
            {
              "portFacility": {
                "code": "AUSYD-0002",
                "name": "NSW-SYD-Patrick Stevedores (Botany)"
              },
              "arrivalDate": null,
              "departureDate": null,
              "securityLevel": "SL1",
              "additionalSecurityMeasures": "N/A"
            }
          ]
        },
        {
          "id": "973261bb-4dc8-4222-9f60-cbe1fd01774a",
          "arrival": null,
          "departure": null,
          "port": {
            "name": "Brisbane",
            "locationUnCode": "AUBNE",
            "countryUnCode": "AU",
            "euPort": false
          },
          "portFacilityVisits": [
            {
              "portFacility": {
                "code": "AUBNE-0088",
                "name": "DP World - Fisherman Islands Terminal"
              },
              "arrivalDate": null,
              "departureDate": null,
              "securityLevel": "SL1",
              "additionalSecurityMeasures": "N/A"
            }
          ]
        },
        {
          "id": "5b97dd2f-adf0-4189-a1da-abd02ecfb130",
          "arrival": null,
          "departure": null,
          "port": {
            "name": "Nouméa",
            "locationUnCode": "NCNOU",
            "countryUnCode": "NC",
            "euPort": false
          },
          "portFacilityVisits": [
            {
              "portFacility": {
                "code": "NCNOU-0006",
                "name": "Nouméa - Quai nord-ouest - Doniambo"
              },
              "arrivalDate": null,
              "departureDate": null,
              "securityLevel": "SL1",
              "additionalSecurityMeasures": "N/A"
            }
          ]
        },
        {
          "id": "7ea44ca0-b96a-4055-9411-8d116ae67f1f",
          "arrival": null,
          "departure": null,
          "port": {
            "name": "Kingston",
            "locationUnCode": "JMKIN",
            "countryUnCode": "JM",
            "euPort": false
          },
          "portFacilityVisits": [
            {
              "portFacility": {
                "code": "JMKIN-0002",
                "name": "Kingston Freeport Terminal Ltd."
              },
              "arrivalDate": null,
              "departureDate": null,
              "securityLevel": "SL1",
              "additionalSecurityMeasures": null
            }
          ]
        },
        {
          "id": "2b5060de-942d-436f-b790-9e50f290519e",
          "arrival": null,
          "departure": null,
          "port": {
            "name": "Savannah",
            "locationUnCode": "USSAV",
            "countryUnCode": "US",
            "euPort": false
          },
          "portFacilityVisits": [
            {
              "portFacility": {
                "code": "USSAV-0001",
                "name": "Savannah Port Area"
              },
              "arrivalDate": null,
              "departureDate": null,
              "securityLevel": "SL1",
              "additionalSecurityMeasures": null
            }
          ]
        },
        {
          "id": "aaeacab9-e7a3-49e8-84f2-6e6d5cb15353",
          "arrival": null,
          "departure": null,
          "port": {
            "name": "New York",
            "locationUnCode": "USNYC",
            "countryUnCode": "US",
            "euPort": false
          },
          "portFacilityVisits": [
            {
              "portFacility": {
                "code": "USNYC-0001",
                "name": "New York Port Area"
              },
              "arrivalDate": null,
              "departureDate": null,
              "securityLevel": "SL1",
              "additionalSecurityMeasures": null
            }
          ]
        },
        {
          "id": "57cf87fb-13bd-403a-b238-bcb550079cef",
          "arrival": null,
          "departure": null,
          "port": {
            "name": "Manzanillo",
            "locationUnCode": "PAMIT",
            "countryUnCode": "PA",
            "euPort": false
          },
          "portFacilityVisits": []
        }
      ],
      "portVisit": {
        "pilotStation": {
          "code": "MC",
          "name": "Maascenter",
          "atSea": true
        },
        "entryPoint": {
          "code": "LL",
          "name": "Lage Licht",
          "atSea": true
        },
        "etaPort": "2021-09-25T02:00:00Z",
        "portEntry": {
          "origin": "SEA",
          "etaSeaBuoy": "2021-09-25T02:00:00Z",
          "etaPilotBoardingPlace": "2021-09-25T02:00:00Z",
          "requestedEtaPilotBoardingPlace": "2021-09-25T02:00:00Z",
          "earliestTimeOfPortEntry": null,
          "intention": "REQUEST_FOR_ENTRY",
          "baseForPlanning": "PILOT_BOARDING_PLACE",
          "entryDependency": null
        },
        "firstMovement": {
          "vesselDraft": 7.7,
          "vesselMasterName": "Capt. Punia Ravinder Singh",
          "numberOfCrew": 25,
          "numberOfPassengers": 0,
          "cargo": "BALLAST_CONDITION",
          "orderEmail": null,
          "orderSms": null,
          "pilotExemption": null,
          "offStandardBeam": null,
          "pilotService": {
            "required": true,
            "serviceProvider": {
              "name": "Loodswezen",
              "portAuthorityId": "LRR"
            },
            "remarks": null
          },
          "order": true,
          "stormPilotageInformation": {
            "heliSuitable": null,
            "hoisting": null,
            "remotePilotage": null,
            "imoLoaRegistered": null,
            "lowFreeboard": null,
            "remarks": null
          }
        },
        "passingThroughTugboats": null,
        "defectTypes": [],
        "defectTypeRemarks": null,
        "berthVisits": [
          {
            "id": "5589ebb3-69eb-4f2e-b979-39f728662ae3",
            "berth": {
              "name": "AMALIAH RWG DS 1",
              "shortName": null,
              "code": "R8970",
              "terminalCode": "4284",
              "terminalName": "AMALIAH RWG DS 1",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)",
              "berthGroupCode": "Rotterdam World Gateway B.V.",
              "buoy": false,
              "harbourDuesArea": true,
              "partyToNotify": null
            },
            "eta": "2021-09-25T03:45:00Z",
            "requestedEta": "2021-06-25T09:45:00Z",
            "ata": "2021-09-25T03:39:15Z",
            "etd": "2021-09-26T03:00:00Z",
            "requestedEtd": "2021-09-26T03:00:00Z",
            "atd": "2021-09-26T03:22:49Z",
            "mooring": "STARBOARD_SIDE",
            "remarks": null,
            "visitPurposes": [
              "DISCHARGE",
              "LOADING"
            ],
            "bollardFrom": 3,
            "bollardTo": 15,
            "tugboatAtArrival": {
              "required": true,
              "serviceProvider": {
                "name": "Boluda",
                "portAuthorityId": "BOLUDA"
              },
              "requiredQuantity": "PILOT_DETERMINES",
              "remarks": null
            },
            "boatmenAtArrival": {
              "required": true,
              "serviceProvider": {
                "name": "KRVE",
                "portAuthorityId": "RVE"
              },
              "remarks": null
            },
            "boatmenAtDeparture": {
              "required": true,
              "serviceProvider": {
                "name": "KRVE",
                "portAuthorityId": "RVE"
              },
              "remarks": null
            },
            "tugboatAtDeparture": {
              "required": true,
              "serviceProvider": {
                "name": "Boluda",
                "portAuthorityId": "BOLUDA"
              },
              "requiredQuantity": "ONE",
              "remarks": null
            },
            "nextMovement": {
              "vesselDraft": 8.7,
              "vesselMasterName": "Capt. Punia Ravinder Singh",
              "numberOfCrew": 25,
              "numberOfPassengers": 0,
              "cargo": "BALLAST_CONDITION",
              "orderEmail": null,
              "orderSms": null,
              "pilotExemption": null,
              "offStandardBeam": null,
              "pilotService": {
                "required": true,
                "serviceProvider": {
                  "name": "Loodswezen",
                  "portAuthorityId": "LRR"
                },
                "remarks": null
              },
              "order": true,
              "stormPilotageInformation": {
                "heliSuitable": null,
                "hoisting": null,
                "remotePilotage": null,
                "imoLoaRegistered": null,
                "lowFreeboard": null,
                "remarks": null
              }
            }
          }
        ],
        "dangerousGoodsLoading": true,
        "dangerousGoodsDischarge": true,
        "dangerousGoodsTransit": true,
        "vesselInspectionRequired": false,
        "shipConfiguration": null,
        "exitPoint": {
          "code": "LL",
          "name": "Lage Licht",
          "atSea": true
        },
        "etdPort": "2021-09-26T05:00:00Z",
        "atdPort": "2021-09-26T05:43:37Z",
        "ataPort": "2021-09-25T03:39:15Z"
      },
      "departureVoyage": {
        "voyageNumber": "0RP9FS1MA",
        "carrier": {
          "customsId": "FR56202442200486",
          "name": "CMA-CGM",
          "scacCode": "CMDU",
          "smdgCode": "CMA",
          "cargoDeclarantShortName": "CMACGMRHN"
        }
      },
      "nextPorts": [
        {
          "id": "dd938ba2-db19-4fee-a058-eba4d6ebfbb4",
          "arrival": "2021-09-27T08:00:00Z",
          "departure": null,
          "port": {
            "name": "Felixstowe",
            "locationUnCode": "GBFXT",
            "countryUnCode": "GB",
            "euPort": false
          },
          "customsOffice": null
        }
      ]
    },
    "nextVisitDeclaration": null,
    "securityDeclaration": {
      "securityReportRequired": "YES",
      "companySecurityOfficer": {
        "name": "CAPT.PANKAJ BANERJEE",
        "email": "pankaj.banerjee@bs-shipmanagement.com",
        "phoneNumber": "85225618838"
      },
      "currentSecurityLevel": "SL1",
      "approvedSspDocumentOnBoard": true,
      "isscSecurityDocument": {
        "availability": "YES",
        "expiryDate": "2025-02-09",
        "reasonNoValidIsscOnBoard": "N/A",
        "issuer": {
          "code": "20",
          "name": "Lloyd's Register"
        }
      },
      "shipToShipActivities": [
        {
          "location": "ROTTERDAM",
          "type": "TAKING_BUNKERS",
          "startDate": null,
          "endDate": null,
          "compliantWithSsp": true,
          "securityMeasures": "N/A"
        },
        {
          "location": "MANZANILLO",
          "type": "TAKING_BUNKERS",
          "startDate": null,
          "endDate": null,
          "compliantWithSsp": true,
          "securityMeasures": "N/A"
        },
        {
          "location": "MELBOURNE",
          "type": "TAKING_BUNKERS",
          "startDate": null,
          "endDate": null,
          "compliantWithSsp": true,
          "securityMeasures": "N/A"
        }
      ],
      "securityRelatedMatter": null
    },
    "wasteDeclaration": {
      "portOfLastDelivery": {
        "name": "London Gateway Port",
        "locationUnCode": "GBLGP",
        "countryUnCode": "GB",
        "euPort": false
      },
      "lastDeliveryDate": "2021-06-24",
      "wasteItems": [
        {
          "type": "SLUDGE",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 30,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 11.5,
          "maxDedicatedCapacity": 113.49,
          "quantityToBeGenerated": 13.5,
          "portForRetainedWaste": {
            "name": "Kingston",
            "locationUnCode": "JMKIN",
            "countryUnCode": "JM",
            "euPort": false
          }
        },
        {
          "type": "FUEL_REMNANTS",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "BILGE_WATER",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 18.2,
          "maxDedicatedCapacity": 44.8,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": {
            "name": "London Gateway Port",
            "locationUnCode": "GBLGP",
            "countryUnCode": "GB",
            "euPort": false
          }
        },
        {
          "type": "USED_ENGINE_OIL",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "FOOD_WASTE",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0.02,
          "maxDedicatedCapacity": 0.2,
          "quantityToBeGenerated": 0.01,
          "portForRetainedWaste": {
            "name": "London Gateway Port",
            "locationUnCode": "GBLGP",
            "countryUnCode": "GB",
            "euPort": false
          }
        },
        {
          "type": "PLASTIC_CLEAN",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 1.8,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 1.9,
          "maxDedicatedCapacity": 2.1,
          "quantityToBeGenerated": 0.01,
          "portForRetainedWaste": {
            "name": "London Gateway Port",
            "locationUnCode": "GBLGP",
            "countryUnCode": "GB",
            "euPort": false
          }
        },
        {
          "type": "PLASTIC_CONTAMINATED",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "SMALL_DANGEROUS_WASTE",
          "berthIdForPickup": null,
          "collector": null,
          "specification": "Paints / Chemical Drums & Light Bulbs / Tubes",
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0.02,
          "maxDedicatedCapacity": 0.22,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": {
            "name": "London Gateway Port",
            "locationUnCode": "GBLGP",
            "countryUnCode": "GB",
            "euPort": false
          }
        },
        {
          "type": "OTHER_DOMESTIC_WASTE",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "INTERNATIONAL_CATERING_WASTE",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "PAPER_OR_CARTON",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0.2,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0.4,
          "maxDedicatedCapacity": 0.5,
          "quantityToBeGenerated": 0.02,
          "portForRetainedWaste": {
            "name": "London Gateway Port",
            "locationUnCode": "GBLGP",
            "countryUnCode": "GB",
            "euPort": false
          }
        },
        {
          "type": "RAGS",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0.05,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0.2,
          "maxDedicatedCapacity": 0.4,
          "quantityToBeGenerated": 0.01,
          "portForRetainedWaste": {
            "name": "London Gateway Port",
            "locationUnCode": "GBLGP",
            "countryUnCode": "GB",
            "euPort": false
          }
        },
        {
          "type": "GLASS",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0.05,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0.1,
          "maxDedicatedCapacity": 0.1,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": {
            "name": "London Gateway Port",
            "locationUnCode": "GBLGP",
            "countryUnCode": "GB",
            "euPort": false
          }
        },
        {
          "type": "METAL_OR_STEELWIRES",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0.05,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0.1,
          "maxDedicatedCapacity": 0.1,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": {
            "name": "London Gateway Port",
            "locationUnCode": "GBLGP",
            "countryUnCode": "GB",
            "euPort": false
          }
        },
        {
          "type": "BOTTLES",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0.05,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0.1,
          "maxDedicatedCapacity": 0.1,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": {
            "name": "London Gateway Port",
            "locationUnCode": "GBLGP",
            "countryUnCode": "GB",
            "euPort": false
          }
        },
        {
          "type": "CROCKERY",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "INCINERATOR_ASHES",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0.19,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0.2,
          "maxDedicatedCapacity": 0.2,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": {
            "name": "London Gateway Port",
            "locationUnCode": "GBLGP",
            "countryUnCode": "GB",
            "euPort": false
          }
        },
        {
          "type": "ANIMAL_CARCASSES",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "EWASTE_WHOLE_DEVICES",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "EWASTE_DEVICES",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "PYROTECHNICS",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "BATTERIES",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0.01,
          "maxDedicatedCapacity": 0.03,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": {
            "name": "London Gateway Port",
            "locationUnCode": "GBLGP",
            "countryUnCode": "GB",
            "euPort": false
          }
        },
        {
          "type": "FIRE_EXTINGUISHERS",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "CAR_TYRES_OR_RUBBER",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "COOLING_LIQUIDS",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "PROPELLER_SHAFT_GREASE",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "FUMIGATION_PRODUCTS",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "FILTER_OR_RAGS_BAGS",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "WOOD",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "OIL_CONTAMINATED_DRUMS",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "FISHING_GEAR",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "COOKING_OIL",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0.02,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0.02,
          "maxDedicatedCapacity": 0.06,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": {
            "name": "London Gateway Port",
            "locationUnCode": "GBLGP",
            "countryUnCode": "GB",
            "euPort": false
          }
        },
        {
          "type": "OPERATIONAL_WASTE",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0.18,
          "maxDedicatedCapacity": 0.2,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": {
            "name": "London Gateway Port",
            "locationUnCode": "GBLGP",
            "countryUnCode": "GB",
            "euPort": false
          }
        },
        {
          "type": "SEWAGE_WASTE_BLACK",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 10,
          "maxDedicatedCapacity": 41.97,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": {
            "name": "London Gateway Port",
            "locationUnCode": "GBLGP",
            "countryUnCode": "GB",
            "euPort": false
          }
        },
        {
          "type": "SEWAGE_WASTE_GREY",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 10,
          "maxDedicatedCapacity": 41.97,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": {
            "name": "London Gateway Port",
            "locationUnCode": "GBLGP",
            "countryUnCode": "GB",
            "euPort": false
          }
        },
        {
          "type": "DIRTY_BALLASTWATER",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "WASHWATER_OIL",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "OILY_WASTE_FROM_CLEANING",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "OTHER_OILY_WASTE",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "CARGO_RESIDUES_OR_WASHWATER",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "DIRTY_BALLASTWATER_CHEMICALS",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "OTHER_CARGO_RESIDUES",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "WASHWATER_DRY_CARGO_HME",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "WASHWATER_DRY_CARGO_NON_HME",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "DRY_CARGO_RESIDUES_HME",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "DRY_CARGO_RESIDUES_NON_HME",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "OTHER_CARGO_RESIDUES_NON_TANKERS",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        }
      ]
    },
    "wasteCollections": [],
    "shipStoresDeclaration": {
      "items": {
        "SPIRITS": 0,
        "BEER": 143.52,
        "WINE": 0,
        "OTHER": 0,
        "CIGARETTES": 15800,
        "CIGARS": 0,
        "TOBACCO": 0,
        "FUEL_OIL": 7320,
        "LUBRICATING_OIL": 53500,
        "MEAT": 313,
        "NARCOTICS": 0.00388,
        "FIRE_ARMS": 0
      }
    },
    "dangerousGoodsDeclarations": [
      {
        "dangerousGoods": {
          "goods": [
            {
              "type": "containerGood",
              "id": "8928c45c-6535-495a-ac68-cb478be64fc3",
              "goodData": {
                "type": "containerGood",
                "name": "ALCOHOLIC BEVERAGES WITH MORE THAN 24% BUT NOT MORE THAN 70% ALCOHOL BY VOLUME",
                "unCode": "3065",
                "hazardClass": "3",
                "subsidiaryRisk": null,
                "emsFireCode": "F-E",
                "emsSpillageCode": "S-D",
                "properties": "Alcoholic beverages containing more than 24% alcohol but not more than 70% by volume, when transported as part of the manufacturing process, may be transported in wooden barrels with a capacity of more than 250 L and not more than 500 L meeting the general requirements of 4.1.1, as appropriate, on the following conditions: .1 the wooden barrels should be checked and tightened before filling; .2 sufficient ullage (not less than 3%) should be left to allow for the expansion of the liquid; ...",
                "segregationGroup": null,
                "packingGroup": "MINOR_DANGER",
                "goodCode": "102185",
                "gdsCode": null,
                "stowageType": "container"
              },
              "flashPoint": 23,
              "segregationInformation": null,
              "remarks": null,
              "allowingIncompleteRadioactivity": false,
              "radioactive": false,
              "radioactivity": null
            },
            {
              "type": "containerGood",
              "id": "fab52912-7142-4e7b-b25f-38bc7ac740da",
              "goodData": {
                "type": "containerGood",
                "name": "ALCOHOLIC BEVERAGES WITH MORE THAN 24% BUT NOT MORE THAN 70% ALCOHOL BY VOLUME",
                "unCode": "3065",
                "hazardClass": "3",
                "subsidiaryRisk": null,
                "emsFireCode": "F-E",
                "emsSpillageCode": "S-D",
                "properties": "Alcoholic beverages containing more than 24% alcohol but not more than 70% by volume, when transported as part of the manufacturing process, may be transported in wooden barrels with a capacity of more than 250 L and not more than 500 L meeting the general requirements of 4.1.1, as appropriate, on the following conditions: .1 the wooden barrels should be checked and tightened before filling; .2 sufficient ullage (not less than 3%) should be left to allow for the expansion of the liquid; ...",
                "segregationGroup": null,
                "packingGroup": "MINOR_DANGER",
                "goodCode": "102185",
                "gdsCode": null,
                "stowageType": "container"
              },
              "flashPoint": 23,
              "segregationInformation": null,
              "remarks": null,
              "allowingIncompleteRadioactivity": false,
              "radioactive": false,
              "radioactivity": null
            },
            {
              "type": "containerGood",
              "id": "7dec052a-1a1d-4779-bdb3-b89cc43655ae",
              "goodData": {
                "type": "containerGood",
                "name": "DIESEL FUEL",
                "unCode": "1202",
                "hazardClass": "3",
                "subsidiaryRisk": null,
                "emsFireCode": "F-E",
                "emsSpillageCode": "S-E",
                "properties": "Immiscible with water.",
                "segregationGroup": null,
                "packingGroup": "MINOR_DANGER",
                "goodCode": "100548",
                "gdsCode": null,
                "stowageType": "container"
              },
              "flashPoint": 60,
              "segregationInformation": null,
              "remarks": null,
              "allowingIncompleteRadioactivity": false,
              "radioactive": false,
              "radioactivity": null
            },
            {
              "type": "containerGood",
              "id": "9a9af302-3f08-4753-95a9-37683da2e977",
              "goodData": {
                "type": "containerGood",
                "name": "AMINES, LIQUID, CORROSIVE, N.O.S.",
                "unCode": "2735",
                "hazardClass": "8",
                "subsidiaryRisk": null,
                "emsFireCode": "F-A",
                "emsSpillageCode": "S-B",
                "properties": "Colourless to yellowish liquids or solutions with a pungent odour. Miscible with or soluble in water. When involved in a fire, evolve toxic gases. Corrosive to most metals, especially to copper and its alloys. React violently with acids. Cause burns to skin, eyes and mucous membranes.",
                "segregationGroup": "SGG18",
                "packingGroup": "MINOR_DANGER",
                "goodCode": "101847",
                "gdsCode": null,
                "stowageType": "container"
              },
              "flashPoint": null,
              "segregationInformation": null,
              "remarks": null,
              "allowingIncompleteRadioactivity": false,
              "radioactive": false,
              "radioactivity": null
            },
            {
              "type": "containerGood",
              "id": "35f47548-cf95-459c-8928-2e031c600c77",
              "goodData": {
                "type": "containerGood",
                "name": "TOXIC LIQUID, CORROSIVE, ORGANIC, N.O.S.",
                "unCode": "2927",
                "hazardClass": "6.1",
                "subsidiaryRisk": "8",
                "emsFireCode": "F-A",
                "emsSpillageCode": "S-B",
                "properties": "Toxic if swallowed, by skin contact or by inhalation. Causes burns to skin, eyes and mucous membranes.",
                "segregationGroup": null,
                "packingGroup": "MEDIUM_DANGER",
                "goodCode": "102057",
                "gdsCode": null,
                "stowageType": "container"
              },
              "flashPoint": null,
              "segregationInformation": null,
              "remarks": null,
              "allowingIncompleteRadioactivity": false,
              "radioactive": false,
              "radioactivity": null
            },
            {
              "type": "containerGood",
              "id": "816eb3f0-ada1-43c2-9a1c-fab5f934bb7f",
              "goodData": {
                "type": "containerGood",
                "name": "METHYL CHLORIDE",
                "unCode": "1063",
                "hazardClass": "2.1",
                "subsidiaryRisk": null,
                "emsFireCode": "F-D",
                "emsSpillageCode": "S-U",
                "properties": "Liquefied, flammable gas. Explosive limits: 8% to 20%. Heavier than air (1.8).",
                "segregationGroup": null,
                "packingGroup": null,
                "goodCode": "100424",
                "gdsCode": null,
                "stowageType": "container"
              },
              "flashPoint": null,
              "segregationInformation": null,
              "remarks": null,
              "allowingIncompleteRadioactivity": false,
              "radioactive": false,
              "radioactivity": null
            },
            {
              "type": "containerGood",
              "id": "014286e5-6fee-4034-a6e1-63de0391249a",
              "goodData": {
                "type": "containerGood",
                "name": "METHYL CHLORIDE",
                "unCode": "1063",
                "hazardClass": "2.1",
                "subsidiaryRisk": null,
                "emsFireCode": "F-D",
                "emsSpillageCode": "S-U",
                "properties": "Liquefied, flammable gas. Explosive limits: 8% to 20%. Heavier than air (1.8).",
                "segregationGroup": null,
                "packingGroup": null,
                "goodCode": "100424",
                "gdsCode": null,
                "stowageType": "container"
              },
              "flashPoint": null,
              "segregationInformation": null,
              "remarks": null,
              "allowingIncompleteRadioactivity": false,
              "radioactive": false,
              "radioactivity": null
            },
            {
              "type": "containerGood",
              "id": "28b52f99-2ad1-423b-a869-6a12eac37bcb",
              "goodData": {
                "type": "containerGood",
                "name": "METHYL CHLORIDE",
                "unCode": "1063",
                "hazardClass": "2.1",
                "subsidiaryRisk": null,
                "emsFireCode": "F-D",
                "emsSpillageCode": "S-U",
                "properties": "Liquefied, flammable gas. Explosive limits: 8% to 20%. Heavier than air (1.8).",
                "segregationGroup": null,
                "packingGroup": null,
                "goodCode": "100424",
                "gdsCode": null,
                "stowageType": "container"
              },
              "flashPoint": null,
              "segregationInformation": null,
              "remarks": null,
              "allowingIncompleteRadioactivity": false,
              "radioactive": false,
              "radioactivity": null
            },
            {
              "type": "containerGood",
              "id": "2a8e4a7e-5149-4dd9-b624-c0b85019884d",
              "goodData": {
                "type": "containerGood",
                "name": "METHYL CHLORIDE",
                "unCode": "1063",
                "hazardClass": "2.1",
                "subsidiaryRisk": null,
                "emsFireCode": "F-D",
                "emsSpillageCode": "S-U",
                "properties": "Liquefied, flammable gas. Explosive limits: 8% to 20%. Heavier than air (1.8).",
                "segregationGroup": null,
                "packingGroup": null,
                "goodCode": "100424",
                "gdsCode": null,
                "stowageType": "container"
              },
              "flashPoint": null,
              "segregationInformation": null,
              "remarks": null,
              "allowingIncompleteRadioactivity": false,
              "radioactive": false,
              "radioactivity": null
            },
            {
              "type": "containerGood",
              "id": "4cb7c377-1cf4-4661-8da7-e784118e823d",
              "goodData": {
                "type": "containerGood",
                "name": "METHYL CHLORIDE",
                "unCode": "1063",
                "hazardClass": "2.1",
                "subsidiaryRisk": null,
                "emsFireCode": "F-D",
                "emsSpillageCode": "S-U",
                "properties": "Liquefied, flammable gas. Explosive limits: 8% to 20%. Heavier than air (1.8).",
                "segregationGroup": null,
                "packingGroup": null,
                "goodCode": "100424",
                "gdsCode": null,
                "stowageType": "container"
              },
              "flashPoint": null,
              "segregationInformation": null,
              "remarks": null,
              "allowingIncompleteRadioactivity": false,
              "radioactive": false,
              "radioactivity": null
            },
            {
              "type": "containerGood",
              "id": "b1f9256a-0e15-44e7-bd34-691abb6ebd5c",
              "goodData": {
                "type": "containerGood",
                "name": "METHYL CHLORIDE",
                "unCode": "1063",
                "hazardClass": "2.1",
                "subsidiaryRisk": null,
                "emsFireCode": "F-D",
                "emsSpillageCode": "S-U",
                "properties": "Liquefied, flammable gas. Explosive limits: 8% to 20%. Heavier than air (1.8).",
                "segregationGroup": null,
                "packingGroup": null,
                "goodCode": "100424",
                "gdsCode": null,
                "stowageType": "container"
              },
              "flashPoint": null,
              "segregationInformation": null,
              "remarks": null,
              "allowingIncompleteRadioactivity": false,
              "radioactive": false,
              "radioactivity": null
            },
            {
              "type": "containerGood",
              "id": "45a93c0e-368f-4ab7-91a5-5b76ade72109",
              "goodData": {
                "type": "containerGood",
                "name": "METHYL CHLORIDE",
                "unCode": "1063",
                "hazardClass": "2.1",
                "subsidiaryRisk": null,
                "emsFireCode": "F-D",
                "emsSpillageCode": "S-U",
                "properties": "Liquefied, flammable gas. Explosive limits: 8% to 20%. Heavier than air (1.8).",
                "segregationGroup": null,
                "packingGroup": null,
                "goodCode": "100424",
                "gdsCode": null,
                "stowageType": "container"
              },
              "flashPoint": null,
              "segregationInformation": null,
              "remarks": null,
              "allowingIncompleteRadioactivity": false,
              "radioactive": false,
              "radioactivity": null
            },
            {
              "type": "containerGood",
              "id": "0908b5e0-55ea-4be5-8ae7-0a90a5e0f001",
              "goodData": {
                "type": "containerGood",
                "name": "DIETHYLENETRIAMINE",
                "unCode": "2079",
                "hazardClass": "8",
                "subsidiaryRisk": null,
                "emsFireCode": "F-A",
                "emsSpillageCode": "S-B",
                "properties": "Yellow hygroscopic liquid with ammoniacal odour. Soluble in water. Strongly alkaline, corrosive. Can form explosive mixtures with nitric acid. Reacts with oxidizing substances. Corrosive to copper and its alloys. Reacts violently with acids. Liquid and vapour can cause severe damage to skin and eyes.",
                "segregationGroup": "SGG18",
                "packingGroup": "MEDIUM_DANGER",
                "goodCode": "101366",
                "gdsCode": null,
                "stowageType": "container"
              },
              "flashPoint": null,
              "segregationInformation": null,
              "remarks": null,
              "allowingIncompleteRadioactivity": false,
              "radioactive": false,
              "radioactivity": null
            },
            {
              "type": "containerGood",
              "id": "277dc7f0-d121-403b-9735-503498b2f1cd",
              "goodData": {
                "type": "containerGood",
                "name": "METHYL CHLORIDE",
                "unCode": "1063",
                "hazardClass": "2.1",
                "subsidiaryRisk": null,
                "emsFireCode": "F-D",
                "emsSpillageCode": "S-U",
                "properties": "Liquefied, flammable gas. Explosive limits: 8% to 20%. Heavier than air (1.8).",
                "segregationGroup": null,
                "packingGroup": null,
                "goodCode": "100424",
                "gdsCode": null,
                "stowageType": "container"
              },
              "flashPoint": null,
              "segregationInformation": null,
              "remarks": null,
              "allowingIncompleteRadioactivity": false,
              "radioactive": false,
              "radioactivity": null
            },
            {
              "type": "containerGood",
              "id": "c952c716-7408-4599-bbfa-e63163486fed",
              "goodData": {
                "type": "containerGood",
                "name": "METHYL CHLORIDE",
                "unCode": "1063",
                "hazardClass": "2.1",
                "subsidiaryRisk": null,
                "emsFireCode": "F-D",
                "emsSpillageCode": "S-U",
                "properties": "Liquefied, flammable gas. Explosive limits: 8% to 20%. Heavier than air (1.8).",
                "segregationGroup": null,
                "packingGroup": null,
                "goodCode": "100424",
                "gdsCode": null,
                "stowageType": "container"
              },
              "flashPoint": null,
              "segregationInformation": null,
              "remarks": null,
              "allowingIncompleteRadioactivity": false,
              "radioactive": false,
              "radioactivity": null
            },
            {
              "type": "containerGood",
              "id": "d3da4c69-ddc8-4a62-9f33-2bc915fa9949",
              "goodData": {
                "type": "containerGood",
                "name": "METHYL CHLORIDE",
                "unCode": "1063",
                "hazardClass": "2.1",
                "subsidiaryRisk": null,
                "emsFireCode": "F-D",
                "emsSpillageCode": "S-U",
                "properties": "Liquefied, flammable gas. Explosive limits: 8% to 20%. Heavier than air (1.8).",
                "segregationGroup": null,
                "packingGroup": null,
                "goodCode": "100424",
                "gdsCode": null,
                "stowageType": "container"
              },
              "flashPoint": null,
              "segregationInformation": null,
              "remarks": null,
              "allowingIncompleteRadioactivity": false,
              "radioactive": false,
              "radioactivity": null
            },
            {
              "type": "containerGood",
              "id": "97de2f82-53fe-4798-a438-b69782cc6c7f",
              "goodData": {
                "type": "containerGood",
                "name": "METHYL CHLORIDE",
                "unCode": "1063",
                "hazardClass": "2.1",
                "subsidiaryRisk": null,
                "emsFireCode": "F-D",
                "emsSpillageCode": "S-U",
                "properties": "Liquefied, flammable gas. Explosive limits: 8% to 20%. Heavier than air (1.8).",
                "segregationGroup": null,
                "packingGroup": null,
                "goodCode": "100424",
                "gdsCode": null,
                "stowageType": "container"
              },
              "flashPoint": null,
              "segregationInformation": null,
              "remarks": null,
              "allowingIncompleteRadioactivity": false,
              "radioactive": false,
              "radioactivity": null
            },
            {
              "type": "containerGood",
              "id": "a17b1eba-a950-4e02-a5c8-6787b47353df",
              "goodData": {
                "type": "containerGood",
                "name": "METHYL CHLORIDE",
                "unCode": "1063",
                "hazardClass": "2.1",
                "subsidiaryRisk": null,
                "emsFireCode": "F-D",
                "emsSpillageCode": "S-U",
                "properties": "Liquefied, flammable gas. Explosive limits: 8% to 20%. Heavier than air (1.8).",
                "segregationGroup": null,
                "packingGroup": null,
                "goodCode": "100424",
                "gdsCode": null,
                "stowageType": "container"
              },
              "flashPoint": null,
              "segregationInformation": null,
              "remarks": null,
              "allowingIncompleteRadioactivity": false,
              "radioactive": false,
              "radioactivity": null
            },
            {
              "type": "containerGood",
              "id": "682c4077-7201-44e3-9e78-296d7c0b7758",
              "goodData": {
                "type": "containerGood",
                "name": "METHYL CHLORIDE",
                "unCode": "1063",
                "hazardClass": "2.1",
                "subsidiaryRisk": null,
                "emsFireCode": "F-D",
                "emsSpillageCode": "S-U",
                "properties": "Liquefied, flammable gas. Explosive limits: 8% to 20%. Heavier than air (1.8).",
                "segregationGroup": null,
                "packingGroup": null,
                "goodCode": "100424",
                "gdsCode": null,
                "stowageType": "container"
              },
              "flashPoint": null,
              "segregationInformation": null,
              "remarks": null,
              "allowingIncompleteRadioactivity": false,
              "radioactive": false,
              "radioactivity": null
            },
            {
              "type": "containerGood",
              "id": "f5f3bb38-c5d6-470e-a56a-25c02000490b",
              "goodData": {
                "type": "containerGood",
                "name": "ACETIC ACID SOLUTION MORE THAN 10% AND LESS THAN 50% ACID, BY MASS",
                "unCode": "2790",
                "hazardClass": "8",
                "subsidiaryRisk": null,
                "emsFireCode": "F-A",
                "emsSpillageCode": "S-B",
                "properties": "Colourless liquid with a pungent odour. Miscible with water. Corrosive to lead and most other metals. Corrosive to skin, eyes and mucous membranes.",
                "segregationGroup": "SGG1",
                "packingGroup": "MINOR_DANGER",
                "goodCode": "101927",
                "gdsCode": null,
                "stowageType": "container"
              },
              "flashPoint": null,
              "segregationInformation": null,
              "remarks": null,
              "allowingIncompleteRadioactivity": false,
              "radioactive": false,
              "radioactivity": null
            },
            {
              "type": "containerGood",
              "id": "4c7cd9ed-aad0-4618-b2ed-abb7512805ec",
              "goodData": {
                "type": "containerGood",
                "name": "ACETIC ACID SOLUTION MORE THAN 10% AND LESS THAN 50% ACID, BY MASS",
                "unCode": "2790",
                "hazardClass": "8",
                "subsidiaryRisk": null,
                "emsFireCode": "F-A",
                "emsSpillageCode": "S-B",
                "properties": "Colourless liquid with a pungent odour. Miscible with water. Corrosive to lead and most other metals. Corrosive to skin, eyes and mucous membranes.",
                "segregationGroup": "SGG1",
                "packingGroup": "MINOR_DANGER",
                "goodCode": "101927",
                "gdsCode": null,
                "stowageType": "container"
              },
              "flashPoint": null,
              "segregationInformation": null,
              "remarks": null,
              "allowingIncompleteRadioactivity": false,
              "radioactive": false,
              "radioactivity": null
            },
            {
              "type": "containerGood",
              "id": "5c2458c0-3d21-4e77-bd5a-972c1c4072d5",
              "goodData": {
                "type": "containerGood",
                "name": "ACETIC ACID SOLUTION MORE THAN 10% AND LESS THAN 50% ACID, BY MASS",
                "unCode": "2790",
                "hazardClass": "8",
                "subsidiaryRisk": null,
                "emsFireCode": "F-A",
                "emsSpillageCode": "S-B",
                "properties": "Colourless liquid with a pungent odour. Miscible with water. Corrosive to lead and most other metals. Corrosive to skin, eyes and mucous membranes.",
                "segregationGroup": "SGG1",
                "packingGroup": "MINOR_DANGER",
                "goodCode": "101927",
                "gdsCode": null,
                "stowageType": "container"
              },
              "flashPoint": null,
              "segregationInformation": null,
              "remarks": null,
              "allowingIncompleteRadioactivity": false,
              "radioactive": false,
              "radioactivity": null
            },
            {
              "type": "containerGood",
              "id": "cff8cec6-b0e9-4611-85f8-fe6e7b470613",
              "goodData": {
                "type": "containerGood",
                "name": "POLYAMINES, LIQUID, CORROSIVE, N.O.S.",
                "unCode": "2735",
                "hazardClass": "8",
                "subsidiaryRisk": null,
                "emsFireCode": "F-A",
                "emsSpillageCode": "S-B",
                "properties": "Colourless to yellowish liquids or solutions with a pungent odour. Miscible with or soluble in water. When involved in a fire, evolve toxic gases. Corrosive to most metals, especially to copper and its alloys. React violently with acids. Cause burns to skin, eyes and mucous membranes.",
                "segregationGroup": "SGG18",
                "packingGroup": "MINOR_DANGER",
                "goodCode": "101847",
                "gdsCode": null,
                "stowageType": "container"
              },
              "flashPoint": null,
              "segregationInformation": null,
              "remarks": null,
              "allowingIncompleteRadioactivity": false,
              "radioactive": false,
              "radioactivity": null
            },
            {
              "type": "containerGood",
              "id": "c51d3608-2fda-4ca4-ac67-cece156c5b28",
              "goodData": {
                "type": "containerGood",
                "name": "AEROSOLS",
                "unCode": "1950",
                "hazardClass": "2",
                "subsidiaryRisk": "See SP63",
                "emsFireCode": "F-D",
                "emsSpillageCode": "S-U",
                "properties": null,
                "segregationGroup": null,
                "packingGroup": null,
                "goodCode": "101234",
                "gdsCode": null,
                "stowageType": "container"
              },
              "flashPoint": null,
              "segregationInformation": null,
              "remarks": null,
              "allowingIncompleteRadioactivity": false,
              "radioactive": false,
              "radioactivity": null
            },
            {
              "type": "containerGood",
              "id": "73243d12-2313-4c77-b76e-a71fb2e608ae",
              "goodData": {
                "type": "containerGood",
                "name": "TRIETHYLENETETRAMINE",
                "unCode": "2259",
                "hazardClass": "8",
                "subsidiaryRisk": null,
                "emsFireCode": "F-A",
                "emsSpillageCode": "S-B",
                "properties": "Moderately viscous, yellow combustible liquid with an ammoniacal odour. Miscible with water. Strongly alkaline. Can form explosive mixtures with nitric acid. When involved in a fire, evolves toxic gases. Corrosive to copper and copper alloys. Reacts violently with acids. Liquid and vapours cause burns to skin, eyes and mucous membranes. Causes skin allergy.",
                "segregationGroup": "SGG18",
                "packingGroup": "MEDIUM_DANGER",
                "goodCode": "101433",
                "gdsCode": null,
                "stowageType": "container"
              },
              "flashPoint": null,
              "segregationInformation": null,
              "remarks": null,
              "allowingIncompleteRadioactivity": false,
              "radioactive": false,
              "radioactivity": null
            },
            {
              "type": "containerGood",
              "id": "d22af0a4-765b-4bad-b277-39b0926829e0",
              "goodData": {
                "type": "containerGood",
                "name": "TRIMETHYLCHLOROSILANE",
                "unCode": "1298",
                "hazardClass": "3",
                "subsidiaryRisk": "8",
                "emsFireCode": "F-E",
                "emsSpillageCode": "S-C",
                "properties": "Colourless liquid. Flashpoint: below -18°C c.c. Explosive limits: 1.8% to 6%. Boiling point: 57°C. Immiscible with water. Readily hydrolysed by moisture, evolving hydrogen chloride, a toxic and corrosive gas. Causes burns to skin, eyes and mucous membranes.",
                "segregationGroup": "SGG1",
                "packingGroup": "MEDIUM_DANGER",
                "goodCode": "100635",
                "gdsCode": null,
                "stowageType": "container"
              },
              "flashPoint": -20,
              "segregationInformation": null,
              "remarks": null,
              "allowingIncompleteRadioactivity": false,
              "radioactive": false,
              "radioactivity": null
            },
            {
              "type": "containerGood",
              "id": "90d23b77-f7e8-43c6-9ef7-fa8d3991d5ed",
              "goodData": {
                "type": "containerGood",
                "name": "ADHESIVES CONTAINING FLAMMABLE LIQUID",
                "unCode": "1133",
                "hazardClass": "3",
                "subsidiaryRisk": null,
                "emsFireCode": "F-E",
                "emsSpillageCode": "S-D",
                "properties": "Adhesives are solutions of gums, resins, etc., usually volatile due to the solvents. Miscibility with water depends upon their composition.",
                "segregationGroup": null,
                "packingGroup": "MINOR_DANGER",
                "goodCode": "100481",
                "gdsCode": null,
                "stowageType": "container"
              },
              "flashPoint": -18,
              "segregationInformation": null,
              "remarks": null,
              "allowingIncompleteRadioactivity": false,
              "radioactive": false,
              "radioactivity": null
            },
            {
              "type": "containerGood",
              "id": "ffc65bdf-017d-4b80-a41f-ef9f8ad431d1",
              "goodData": {
                "type": "containerGood",
                "name": "ADHESIVES CONTAINING FLAMMABLE LIQUID",
                "unCode": "1133",
                "hazardClass": "3",
                "subsidiaryRisk": null,
                "emsFireCode": "F-E",
                "emsSpillageCode": "S-D",
                "properties": "Adhesives are solutions of gums, resins, etc., usually volatile due to the solvents. Miscibility with water depends upon their composition.",
                "segregationGroup": null,
                "packingGroup": "MEDIUM_DANGER",
                "goodCode": "100480",
                "gdsCode": null,
                "stowageType": "container"
              },
              "flashPoint": -1,
              "segregationInformation": null,
              "remarks": null,
              "allowingIncompleteRadioactivity": false,
              "radioactive": false,
              "radioactivity": null
            },
            {
              "type": "containerGood",
              "id": "c75ee6ac-fa6c-4f3c-810d-def5a1a0a040",
              "goodData": {
                "type": "containerGood",
                "name": "ADHESIVES CONTAINING FLAMMABLE LIQUID",
                "unCode": "1133",
                "hazardClass": "3",
                "subsidiaryRisk": null,
                "emsFireCode": "F-E",
                "emsSpillageCode": "S-D",
                "properties": "Adhesives are solutions of gums, resins, etc., usually volatile due to the solvents. Miscibility with water depends upon their composition.",
                "segregationGroup": null,
                "packingGroup": "MINOR_DANGER",
                "goodCode": "100481",
                "gdsCode": null,
                "stowageType": "container"
              },
              "flashPoint": -18,
              "segregationInformation": null,
              "remarks": null,
              "allowingIncompleteRadioactivity": false,
              "radioactive": false,
              "radioactivity": null
            },
            {
              "type": "containerGood",
              "id": "0525a7e8-f71f-48cd-9a6a-34f6144a2d73",
              "goodData": {
                "type": "containerGood",
                "name": "FLAMMABLE LIQUID, N.O.S.",
                "unCode": "1993",
                "hazardClass": "3",
                "subsidiaryRisk": null,
                "emsFireCode": "F-E",
                "emsSpillageCode": "S-E",
                "properties": null,
                "segregationGroup": null,
                "packingGroup": "MEDIUM_DANGER",
                "goodCode": "101282",
                "gdsCode": null,
                "stowageType": "container"
              },
              "flashPoint": -9,
              "segregationInformation": null,
              "remarks": null,
              "allowingIncompleteRadioactivity": false,
              "radioactive": false,
              "radioactivity": null
            },
            {
              "type": "containerGood",
              "id": "4f25db47-9f82-465c-a4f9-aa7f339dfc5b",
              "goodData": {
                "type": "containerGood",
                "name": "ENVIRONMENTALLY HAZARDOUS SUBSTANCE, LIQUID, N.O.S.",
                "unCode": "3082",
                "hazardClass": "9",
                "subsidiaryRisk": null,
                "emsFireCode": "F-A",
                "emsSpillageCode": "S-F",
                "properties": null,
                "segregationGroup": null,
                "packingGroup": "MINOR_DANGER",
                "goodCode": "102197",
                "gdsCode": null,
                "stowageType": "container"
              },
              "flashPoint": null,
              "segregationInformation": null,
              "remarks": null,
              "allowingIncompleteRadioactivity": false,
              "radioactive": false,
              "radioactivity": null
            },
            {
              "type": "containerGood",
              "id": "aee7883a-000e-4e99-bfde-a9ab2e0f4f88",
              "goodData": {
                "type": "containerGood",
                "name": "ADHESIVES CONTAINING FLAMMABLE LIQUID",
                "unCode": "1133",
                "hazardClass": "3",
                "subsidiaryRisk": null,
                "emsFireCode": "F-E",
                "emsSpillageCode": "S-D",
                "properties": "Adhesives are solutions of gums, resins, etc., usually volatile due to the solvents. Miscibility with water depends upon their composition.",
                "segregationGroup": null,
                "packingGroup": "MEDIUM_DANGER",
                "goodCode": "100480",
                "gdsCode": null,
                "stowageType": "container"
              },
              "flashPoint": -1,
              "segregationInformation": null,
              "remarks": null,
              "allowingIncompleteRadioactivity": false,
              "radioactive": false,
              "radioactivity": null
            },
            {
              "type": "containerGood",
              "id": "1a47f89c-f2b7-49d0-8496-bf5f5a4282fb",
              "goodData": {
                "type": "containerGood",
                "name": "TETRACHLOROETHYLENE",
                "unCode": "1897",
                "hazardClass": "6.1",
                "subsidiaryRisk": null,
                "emsFireCode": "F-A",
                "emsSpillageCode": "S-A",
                "properties": "Colourless liquid with an ethereal odour. When involved in a fire, evolves extremely toxic fumes (phosgene). Toxic if swallowed, by skin contact or by inhalation.",
                "segregationGroup": "SGG10",
                "packingGroup": "MINOR_DANGER",
                "goodCode": "101194",
                "gdsCode": null,
                "stowageType": "container"
              },
              "flashPoint": null,
              "segregationInformation": null,
              "remarks": null,
              "allowingIncompleteRadioactivity": false,
              "radioactive": false,
              "radioactivity": null
            },
            {
              "type": "containerGood",
              "id": "fde6559f-ef7f-47e7-becd-49e516d6ffc6",
              "goodData": {
                "type": "containerGood",
                "name": "BUTYL ACETATES",
                "unCode": "1123",
                "hazardClass": "3",
                "subsidiaryRisk": null,
                "emsFireCode": "F-E",
                "emsSpillageCode": "S-D",
                "properties": "Colourless liquids with a pineapple-like odour. Immiscible with water. normal-BUTYL ACETATE: flashpoint 27°C c.c. Explosive limits: 1.5% to 15%.",
                "segregationGroup": null,
                "packingGroup": "MINOR_DANGER",
                "goodCode": "100471",
                "gdsCode": null,
                "stowageType": "container"
              },
              "flashPoint": 25,
              "segregationInformation": null,
              "remarks": null,
              "allowingIncompleteRadioactivity": false,
              "radioactive": false,
              "radioactivity": null
            },
            {
              "type": "containerGood",
              "id": "039c99a6-f2f2-4639-830d-b2926f5ef47c",
              "goodData": {
                "type": "containerGood",
                "name": "FLAMMABLE LIQUID, N.O.S.",
                "unCode": "1993",
                "hazardClass": "3",
                "subsidiaryRisk": null,
                "emsFireCode": "F-E",
                "emsSpillageCode": "S-E",
                "properties": null,
                "segregationGroup": null,
                "packingGroup": "MINOR_DANGER",
                "goodCode": "101281",
                "gdsCode": null,
                "stowageType": "container"
              },
              "flashPoint": 25,
              "segregationInformation": null,
              "remarks": null,
              "allowingIncompleteRadioactivity": false,
              "radioactive": false,
              "radioactivity": null
            },
            {
              "type": "containerGood",
              "id": "f80125e3-c7c1-413e-8a7f-c2b24a874b73",
              "goodData": {
                "type": "containerGood",
                "name": "ENVIRONMENTALLY HAZARDOUS SUBSTANCE, LIQUID, N.O.S.",
                "unCode": "3082",
                "hazardClass": "9",
                "subsidiaryRisk": null,
                "emsFireCode": "F-A",
                "emsSpillageCode": "S-F",
                "properties": null,
                "segregationGroup": null,
                "packingGroup": "MINOR_DANGER",
                "goodCode": "102197",
                "gdsCode": null,
                "stowageType": "container"
              },
              "flashPoint": null,
              "segregationInformation": null,
              "remarks": null,
              "allowingIncompleteRadioactivity": false,
              "radioactive": false,
              "radioactivity": null
            },
            {
              "type": "containerGood",
              "id": "e07ef996-e2b0-464f-9817-b9e540310332",
              "goodData": {
                "type": "containerGood",
                "name": "FLAMMABLE LIQUID, N.O.S.",
                "unCode": "1993",
                "hazardClass": "3",
                "subsidiaryRisk": null,
                "emsFireCode": "F-E",
                "emsSpillageCode": "S-E",
                "properties": null,
                "segregationGroup": null,
                "packingGroup": "MINOR_DANGER",
                "goodCode": "101281",
                "gdsCode": null,
                "stowageType": "container"
              },
              "flashPoint": 25,
              "segregationInformation": null,
              "remarks": null,
              "allowingIncompleteRadioactivity": false,
              "radioactive": false,
              "radioactivity": null
            },
            {
              "type": "containerGood",
              "id": "c1a2c7a4-a8d1-4170-810a-2de38db27f68",
              "goodData": {
                "type": "containerGood",
                "name": "ESTERS, N.O.S.",
                "unCode": "3272",
                "hazardClass": "3",
                "subsidiaryRisk": null,
                "emsFireCode": "F-E",
                "emsSpillageCode": "S-D",
                "properties": null,
                "segregationGroup": null,
                "packingGroup": "MINOR_DANGER",
                "goodCode": "102483",
                "gdsCode": null,
                "stowageType": "container"
              },
              "flashPoint": 45,
              "segregationInformation": null,
              "remarks": null,
              "allowingIncompleteRadioactivity": false,
              "radioactive": false,
              "radioactivity": null
            },
            {
              "type": "containerGood",
              "id": "2a966b1c-e920-46f2-bc9c-47c636364d96",
              "goodData": {
                "type": "containerGood",
                "name": "PETROLEUM DISTILLATES, N.O.S.",
                "unCode": "1268",
                "hazardClass": "3",
                "subsidiaryRisk": null,
                "emsFireCode": "F-E",
                "emsSpillageCode": "S-E",
                "properties": "Immiscible with water.",
                "segregationGroup": null,
                "packingGroup": "MINOR_DANGER",
                "goodCode": "100605",
                "gdsCode": null,
                "stowageType": "container"
              },
              "flashPoint": 41,
              "segregationInformation": null,
              "remarks": null,
              "allowingIncompleteRadioactivity": false,
              "radioactive": false,
              "radioactivity": null
            },
            {
              "type": "containerGood",
              "id": "b89e3dc7-cde2-4c5d-b83b-6470d7826b70",
              "goodData": {
                "type": "containerGood",
                "name": "FLAMMABLE LIQUID, N.O.S.",
                "unCode": "1993",
                "hazardClass": "3",
                "subsidiaryRisk": null,
                "emsFireCode": "F-E",
                "emsSpillageCode": "S-E",
                "properties": null,
                "segregationGroup": null,
                "packingGroup": "MINOR_DANGER",
                "goodCode": "101281",
                "gdsCode": null,
                "stowageType": "container"
              },
              "flashPoint": 24,
              "segregationInformation": null,
              "remarks": null,
              "allowingIncompleteRadioactivity": false,
              "radioactive": false,
              "radioactivity": null
            },
            {
              "type": "containerGood",
              "id": "fa3aaac6-b8de-4bb1-ae03-c9870284d0e2",
              "goodData": {
                "type": "containerGood",
                "name": "FLAMMABLE LIQUID, N.O.S.",
                "unCode": "1993",
                "hazardClass": "3",
                "subsidiaryRisk": null,
                "emsFireCode": "F-E",
                "emsSpillageCode": "S-E",
                "properties": null,
                "segregationGroup": null,
                "packingGroup": "MINOR_DANGER",
                "goodCode": "101281",
                "gdsCode": null,
                "stowageType": "container"
              },
              "flashPoint": 25,
              "segregationInformation": null,
              "remarks": null,
              "allowingIncompleteRadioactivity": false,
              "radioactive": false,
              "radioactivity": null
            },
            {
              "type": "containerGood",
              "id": "c8eceaa4-b22d-4630-9f6c-4dee209f35ad",
              "goodData": {
                "type": "containerGood",
                "name": "BUTYL ACETATES",
                "unCode": "1123",
                "hazardClass": "3",
                "subsidiaryRisk": null,
                "emsFireCode": "F-E",
                "emsSpillageCode": "S-D",
                "properties": "Colourless liquids with a pineapple-like odour. Immiscible with water. normal-BUTYL ACETATE: flashpoint 27°C c.c. Explosive limits: 1.5% to 15%.",
                "segregationGroup": null,
                "packingGroup": "MINOR_DANGER",
                "goodCode": "100471",
                "gdsCode": null,
                "stowageType": "container"
              },
              "flashPoint": 25,
              "segregationInformation": null,
              "remarks": null,
              "allowingIncompleteRadioactivity": false,
              "radioactive": false,
              "radioactivity": null
            },
            {
              "type": "containerGood",
              "id": "df64746f-9b03-46cc-83ab-397ebcdda74b",
              "goodData": {
                "type": "containerGood",
                "name": "PETROLEUM DISTILLATES, N.O.S.",
                "unCode": "1268",
                "hazardClass": "3",
                "subsidiaryRisk": null,
                "emsFireCode": "F-E",
                "emsSpillageCode": "S-E",
                "properties": "Immiscible with water.",
                "segregationGroup": null,
                "packingGroup": "MINOR_DANGER",
                "goodCode": "100605",
                "gdsCode": null,
                "stowageType": "container"
              },
              "flashPoint": 41,
              "segregationInformation": null,
              "remarks": null,
              "allowingIncompleteRadioactivity": false,
              "radioactive": false,
              "radioactivity": null
            },
            {
              "type": "containerGood",
              "id": "946ccf95-c7a0-47f9-ade7-85afe71ff530",
              "goodData": {
                "type": "containerGood",
                "name": "FLAMMABLE LIQUID, N.O.S.",
                "unCode": "1993",
                "hazardClass": "3",
                "subsidiaryRisk": null,
                "emsFireCode": "F-E",
                "emsSpillageCode": "S-E",
                "properties": null,
                "segregationGroup": null,
                "packingGroup": "MINOR_DANGER",
                "goodCode": "101281",
                "gdsCode": null,
                "stowageType": "container"
              },
              "flashPoint": 24,
              "segregationInformation": null,
              "remarks": null,
              "allowingIncompleteRadioactivity": false,
              "radioactive": false,
              "radioactivity": null
            },
            {
              "type": "containerGood",
              "id": "801833b0-9132-43bf-b4f9-07906c6f31d7",
              "goodData": {
                "type": "containerGood",
                "name": "FLAMMABLE LIQUID, N.O.S.",
                "unCode": "1993",
                "hazardClass": "3",
                "subsidiaryRisk": null,
                "emsFireCode": "F-E",
                "emsSpillageCode": "S-E",
                "properties": null,
                "segregationGroup": null,
                "packingGroup": "MINOR_DANGER",
                "goodCode": "101281",
                "gdsCode": null,
                "stowageType": "container"
              },
              "flashPoint": 25,
              "segregationInformation": null,
              "remarks": null,
              "allowingIncompleteRadioactivity": false,
              "radioactive": false,
              "radioactivity": null
            },
            {
              "type": "containerGood",
              "id": "07478a24-0440-41c1-8436-a2f9218af665",
              "goodData": {
                "type": "containerGood",
                "name": "ESTERS, N.O.S.",
                "unCode": "3272",
                "hazardClass": "3",
                "subsidiaryRisk": null,
                "emsFireCode": "F-E",
                "emsSpillageCode": "S-D",
                "properties": null,
                "segregationGroup": null,
                "packingGroup": "MINOR_DANGER",
                "goodCode": "102483",
                "gdsCode": null,
                "stowageType": "container"
              },
              "flashPoint": 45,
              "segregationInformation": null,
              "remarks": null,
              "allowingIncompleteRadioactivity": false,
              "radioactive": false,
              "radioactivity": null
            }
          ],
          "stowageAtArrival": [
            {
              "type": "container",
              "stowageNumber": "APZU3423900",
              "goodId": "8928c45c-6535-495a-ac68-cb478be64fc3",
              "portOfLoading": {
                "name": "Kingston",
                "locationUnCode": "JMKIN",
                "countryUnCode": "JM",
                "euPort": false
              },
              "portOfDischarge": {
                "name": "Le Havre",
                "locationUnCode": "FRLEH",
                "countryUnCode": "FR",
                "euPort": true
              },
              "weight": 17000.0,
              "trailer": false,
              "uncleanTankContainer": false,
              "position": "0030206",
              "numberOfOuterPackages": 66,
              "outerPackageType": {
                "code": null,
                "name": "DEN BARRELS",
                "bulk": false
              },
              "numberOfInnerPackages": null,
              "innerPackageType": null,
              "transportInLimitedQuantity": false,
              "netWeight": 10600.0,
              "netExplosiveMass": null,
              "goodIdIsMissing": null
            },
            {
              "type": "container",
              "stowageNumber": "BMOU2062960",
              "goodId": "fab52912-7142-4e7b-b25f-38bc7ac740da",
              "portOfLoading": {
                "name": "Kingston",
                "locationUnCode": "JMKIN",
                "countryUnCode": "JM",
                "euPort": false
              },
              "portOfDischarge": {
                "name": "Le Havre",
                "locationUnCode": "FRLEH",
                "countryUnCode": "FR",
                "euPort": true
              },
              "weight": 17000.0,
              "trailer": false,
              "uncleanTankContainer": false,
              "position": "0030208",
              "numberOfOuterPackages": 66,
              "outerPackageType": {
                "code": null,
                "name": "DEN BARRELS",
                "bulk": false
              },
              "numberOfInnerPackages": null,
              "innerPackageType": null,
              "transportInLimitedQuantity": false,
              "netWeight": 10600.0,
              "netExplosiveMass": null,
              "goodIdIsMissing": null
            },
            {
              "type": "container",
              "stowageNumber": "DFSU2090590",
              "goodId": "7dec052a-1a1d-4779-bdb3-b89cc43655ae",
              "portOfLoading": {
                "name": "Kingston",
                "locationUnCode": "JMKIN",
                "countryUnCode": "JM",
                "euPort": false
              },
              "portOfDischarge": {
                "name": "Rotterdam",
                "locationUnCode": "NLRTM",
                "countryUnCode": "NL",
                "euPort": true
              },
              "weight": 1000.0,
              "trailer": false,
              "uncleanTankContainer": false,
              "position": "0030982",
              "numberOfOuterPackages": 4,
              "outerPackageType": {
                "code": null,
                "name": "RECONSTITUTED WOOD BOXES",
                "bulk": false
              },
              "numberOfInnerPackages": null,
              "innerPackageType": null,
              "transportInLimitedQuantity": false,
              "netWeight": 675.0,
              "netExplosiveMass": null,
              "goodIdIsMissing": null
            }
          ],
          "handlings": [
            {
              "type": "discharge",
              "berthVisitId": "5589ebb3-69eb-4f2e-b979-39f728662ae3",
              "stowages": [
                {
                  "stowageNumber": "DFSU2090590",
                  "type": "container",
                  "emptied": true,
                  "amount": null
                }
              ],
              "shipToShip": false,
              "keepInert": null
            },
            {
              "type": "loading",
              "berthVisitId": "5589ebb3-69eb-4f2e-b979-39f728662ae3",
              "stowages": [
                {
                  "type": "container",
                  "stowageNumber": "ECMU1542908",
                  "goodId": "9a9af302-3f08-4753-95a9-37683da2e977",
                  "portOfLoading": {
                    "name": "Rotterdam",
                    "locationUnCode": "NLRTM",
                    "countryUnCode": "NL",
                    "euPort": true
                  },
                  "portOfDischarge": {
                    "name": "London Gateway Port",
                    "locationUnCode": "GBLGP",
                    "countryUnCode": "GB",
                    "euPort": false
                  },
                  "weight": 16736.0,
                  "trailer": false,
                  "uncleanTankContainer": false,
                  "position": "0210184",
                  "numberOfOuterPackages": 16,
                  "outerPackageType": {
                    "code": null,
                    "name": "A1 COMPOSITE STEEL IBC WITH RIGI",
                    "bulk": false
                  },
                  "numberOfInnerPackages": null,
                  "innerPackageType": null,
                  "transportInLimitedQuantity": false,
                  "netWeight": 15808.0,
                  "netExplosiveMass": null,
                  "goodIdIsMissing": null
                },
                {
                  "type": "container",
                  "stowageNumber": "TCLU3152746",
                  "goodId": "35f47548-cf95-459c-8928-2e031c600c77",
                  "portOfLoading": {
                    "name": "Rotterdam",
                    "locationUnCode": "NLRTM",
                    "countryUnCode": "NL",
                    "euPort": true
                  },
                  "portOfDischarge": {
                    "name": "London Gateway Port",
                    "locationUnCode": "GBLGP",
                    "countryUnCode": "GB",
                    "euPort": false
                  },
                  "weight": 17964.0,
                  "trailer": false,
                  "uncleanTankContainer": false,
                  "position": "0230184",
                  "numberOfOuterPackages": 18,
                  "outerPackageType": {
                    "code": null,
                    "name": "A1 COMPOSITE STEEL IBC WITH RIGI",
                    "bulk": false
                  },
                  "numberOfInnerPackages": null,
                  "innerPackageType": null,
                  "transportInLimitedQuantity": false,
                  "netWeight": 16920.0,
                  "netExplosiveMass": null,
                  "goodIdIsMissing": null
                },
                {
                  "type": "container",
                  "stowageNumber": "TIFU5750022",
                  "goodId": "816eb3f0-ada1-43c2-9a1c-fab5f934bb7f",
                  "portOfLoading": {
                    "name": "Rotterdam",
                    "locationUnCode": "NLRTM",
                    "countryUnCode": "NL",
                    "euPort": true
                  },
                  "portOfDischarge": {
                    "name": "Savannah",
                    "locationUnCode": "USSAV",
                    "countryUnCode": "US",
                    "euPort": false
                  },
                  "weight": 26300.0,
                  "trailer": false,
                  "uncleanTankContainer": false,
                  "position": "0010884",
                  "numberOfOuterPackages": 1,
                  "outerPackageType": null,
                  "numberOfInnerPackages": null,
                  "innerPackageType": null,
                  "transportInLimitedQuantity": false,
                  "netWeight": 20020.0,
                  "netExplosiveMass": null,
                  "goodIdIsMissing": null
                },
                {
                  "type": "container",
                  "stowageNumber": "TIFU5750043",
                  "goodId": "014286e5-6fee-4034-a6e1-63de0391249a",
                  "portOfLoading": {
                    "name": "Rotterdam",
                    "locationUnCode": "NLRTM",
                    "countryUnCode": "NL",
                    "euPort": true
                  },
                  "portOfDischarge": {
                    "name": "Savannah",
                    "locationUnCode": "USSAV",
                    "countryUnCode": "US",
                    "euPort": false
                  },
                  "weight": 26360.0,
                  "trailer": false,
                  "uncleanTankContainer": false,
                  "position": "0090182",
                  "numberOfOuterPackages": 1,
                  "outerPackageType": null,
                  "numberOfInnerPackages": null,
                  "innerPackageType": null,
                  "transportInLimitedQuantity": false,
                  "netWeight": 20080.0,
                  "netExplosiveMass": null,
                  "goodIdIsMissing": null
                },
                {
                  "type": "container",
                  "stowageNumber": "TIFU5750059",
                  "goodId": "28b52f99-2ad1-423b-a869-6a12eac37bcb",
                  "portOfLoading": {
                    "name": "Rotterdam",
                    "locationUnCode": "NLRTM",
                    "countryUnCode": "NL",
                    "euPort": true
                  },
                  "portOfDischarge": {
                    "name": "Savannah",
                    "locationUnCode": "USSAV",
                    "countryUnCode": "US",
                    "euPort": false
                  },
                  "weight": 26320.0,
                  "trailer": false,
                  "uncleanTankContainer": false,
                  "position": "0010784",
                  "numberOfOuterPackages": 1,
                  "outerPackageType": null,
                  "numberOfInnerPackages": null,
                  "innerPackageType": null,
                  "transportInLimitedQuantity": false,
                  "netWeight": 20040.0,
                  "netExplosiveMass": null,
                  "goodIdIsMissing": null
                },
                {
                  "type": "container",
                  "stowageNumber": "TIFU5750064",
                  "goodId": "2a8e4a7e-5149-4dd9-b624-c0b85019884d",
                  "portOfLoading": {
                    "name": "Rotterdam",
                    "locationUnCode": "NLRTM",
                    "countryUnCode": "NL",
                    "euPort": true
                  },
                  "portOfDischarge": {
                    "name": "Savannah",
                    "locationUnCode": "USSAV",
                    "countryUnCode": "US",
                    "euPort": false
                  },
                  "weight": 26360.0,
                  "trailer": false,
                  "uncleanTankContainer": false,
                  "position": "0090282",
                  "numberOfOuterPackages": 1,
                  "outerPackageType": null,
                  "numberOfInnerPackages": null,
                  "innerPackageType": null,
                  "transportInLimitedQuantity": false,
                  "netWeight": 20080.0,
                  "netExplosiveMass": null,
                  "goodIdIsMissing": null
                },
                {
                  "type": "container",
                  "stowageNumber": "TIFU5750212",
                  "goodId": "4cb7c377-1cf4-4661-8da7-e784118e823d",
                  "portOfLoading": {
                    "name": "Rotterdam",
                    "locationUnCode": "NLRTM",
                    "countryUnCode": "NL",
                    "euPort": true
                  },
                  "portOfDischarge": {
                    "name": "Savannah",
                    "locationUnCode": "USSAV",
                    "countryUnCode": "US",
                    "euPort": false
                  },
                  "weight": 26300.0,
                  "trailer": false,
                  "uncleanTankContainer": false,
                  "position": "0031082",
                  "numberOfOuterPackages": 1,
                  "outerPackageType": null,
                  "numberOfInnerPackages": null,
                  "innerPackageType": null,
                  "transportInLimitedQuantity": false,
                  "netWeight": 20060.0,
                  "netExplosiveMass": null,
                  "goodIdIsMissing": null
                },
                {
                  "type": "container",
                  "stowageNumber": "TIFU5750233",
                  "goodId": "b1f9256a-0e15-44e7-bd34-691abb6ebd5c",
                  "portOfLoading": {
                    "name": "Rotterdam",
                    "locationUnCode": "NLRTM",
                    "countryUnCode": "NL",
                    "euPort": true
                  },
                  "portOfDischarge": {
                    "name": "Savannah",
                    "locationUnCode": "USSAV",
                    "countryUnCode": "US",
                    "euPort": false
                  },
                  "weight": 26340.0,
                  "trailer": false,
                  "uncleanTankContainer": false,
                  "position": "0010882",
                  "numberOfOuterPackages": 1,
                  "outerPackageType": null,
                  "numberOfInnerPackages": null,
                  "innerPackageType": null,
                  "transportInLimitedQuantity": false,
                  "netWeight": 20060.0,
                  "netExplosiveMass": null,
                  "goodIdIsMissing": null
                },
                {
                  "type": "container",
                  "stowageNumber": "TIFU5750254",
                  "goodId": "45a93c0e-368f-4ab7-91a5-5b76ade72109",
                  "portOfLoading": {
                    "name": "Rotterdam",
                    "locationUnCode": "NLRTM",
                    "countryUnCode": "NL",
                    "euPort": true
                  },
                  "portOfDischarge": {
                    "name": "Savannah",
                    "locationUnCode": "USSAV",
                    "countryUnCode": "US",
                    "euPort": false
                  },
                  "weight": 26240.0,
                  "trailer": false,
                  "uncleanTankContainer": false,
                  "position": "0071284",
                  "numberOfOuterPackages": 1,
                  "outerPackageType": null,
                  "numberOfInnerPackages": null,
                  "innerPackageType": null,
                  "transportInLimitedQuantity": false,
                  "netWeight": 19960.0,
                  "netExplosiveMass": null,
                  "goodIdIsMissing": null
                },
                {
                  "type": "container",
                  "stowageNumber": "EXFU5540070",
                  "goodId": "0908b5e0-55ea-4be5-8ae7-0a90a5e0f001",
                  "portOfLoading": {
                    "name": "Rotterdam",
                    "locationUnCode": "NLRTM",
                    "countryUnCode": "NL",
                    "euPort": true
                  },
                  "portOfDischarge": {
                    "name": "Savannah",
                    "locationUnCode": "USSAV",
                    "countryUnCode": "US",
                    "euPort": false
                  },
                  "weight": 25770.0,
                  "trailer": false,
                  "uncleanTankContainer": false,
                  "position": "0110102",
                  "numberOfOuterPackages": 1,
                  "outerPackageType": null,
                  "numberOfInnerPackages": null,
                  "innerPackageType": null,
                  "transportInLimitedQuantity": false,
                  "netWeight": 22120.0,
                  "netExplosiveMass": null,
                  "goodIdIsMissing": null
                },
                {
                  "type": "container",
                  "stowageNumber": "TIFU5750260",
                  "goodId": "277dc7f0-d121-403b-9735-503498b2f1cd",
                  "portOfLoading": {
                    "name": "Rotterdam",
                    "locationUnCode": "NLRTM",
                    "countryUnCode": "NL",
                    "euPort": true
                  },
                  "portOfDischarge": {
                    "name": "Savannah",
                    "locationUnCode": "USSAV",
                    "countryUnCode": "US",
                    "euPort": false
                  },
                  "weight": 26260.0,
                  "trailer": false,
                  "uncleanTankContainer": false,
                  "position": "0031084",
                  "numberOfOuterPackages": 1,
                  "outerPackageType": null,
                  "numberOfInnerPackages": null,
                  "innerPackageType": null,
                  "transportInLimitedQuantity": false,
                  "netWeight": 20020.0,
                  "netExplosiveMass": null,
                  "goodIdIsMissing": null
                },
                {
                  "type": "container",
                  "stowageNumber": "TIFU5750320",
                  "goodId": "c952c716-7408-4599-bbfa-e63163486fed",
                  "portOfLoading": {
                    "name": "Rotterdam",
                    "locationUnCode": "NLRTM",
                    "countryUnCode": "NL",
                    "euPort": true
                  },
                  "portOfDischarge": {
                    "name": "Savannah",
                    "locationUnCode": "USSAV",
                    "countryUnCode": "US",
                    "euPort": false
                  },
                  "weight": 26380.0,
                  "trailer": false,
                  "uncleanTankContainer": false,
                  "position": "0010782",
                  "numberOfOuterPackages": 1,
                  "outerPackageType": null,
                  "numberOfInnerPackages": null,
                  "innerPackageType": null,
                  "transportInLimitedQuantity": false,
                  "netWeight": 20100.0,
                  "netExplosiveMass": null,
                  "goodIdIsMissing": null
                },
                {
                  "type": "container",
                  "stowageNumber": "TIFU5750444",
                  "goodId": "d3da4c69-ddc8-4a62-9f33-2bc915fa9949",
                  "portOfLoading": {
                    "name": "Rotterdam",
                    "locationUnCode": "NLRTM",
                    "countryUnCode": "NL",
                    "euPort": true
                  },
                  "portOfDischarge": {
                    "name": "Savannah",
                    "locationUnCode": "USSAV",
                    "countryUnCode": "US",
                    "euPort": false
                  },
                  "weight": 26320.0,
                  "trailer": false,
                  "uncleanTankContainer": false,
                  "position": "0030982",
                  "numberOfOuterPackages": 1,
                  "outerPackageType": null,
                  "numberOfInnerPackages": null,
                  "innerPackageType": null,
                  "transportInLimitedQuantity": false,
                  "netWeight": 20040.0,
                  "netExplosiveMass": null,
                  "goodIdIsMissing": null
                },
                {
                  "type": "container",
                  "stowageNumber": "TIFU5750450",
                  "goodId": "97de2f82-53fe-4798-a438-b69782cc6c7f",
                  "portOfLoading": {
                    "name": "Rotterdam",
                    "locationUnCode": "NLRTM",
                    "countryUnCode": "NL",
                    "euPort": true
                  },
                  "portOfDischarge": {
                    "name": "Savannah",
                    "locationUnCode": "USSAV",
                    "countryUnCode": "US",
                    "euPort": false
                  },
                  "weight": 26420.0,
                  "trailer": false,
                  "uncleanTankContainer": false,
                  "position": "0051284",
                  "numberOfOuterPackages": 1,
                  "outerPackageType": null,
                  "numberOfInnerPackages": null,
                  "innerPackageType": null,
                  "transportInLimitedQuantity": false,
                  "netWeight": 20140.0,
                  "netExplosiveMass": null,
                  "goodIdIsMissing": null
                },
                {
                  "type": "container",
                  "stowageNumber": "TIFU5750552",
                  "goodId": "a17b1eba-a950-4e02-a5c8-6787b47353df",
                  "portOfLoading": {
                    "name": "Rotterdam",
                    "locationUnCode": "NLRTM",
                    "countryUnCode": "NL",
                    "euPort": true
                  },
                  "portOfDischarge": {
                    "name": "Savannah",
                    "locationUnCode": "USSAV",
                    "countryUnCode": "US",
                    "euPort": false
                  },
                  "weight": 26460.0,
                  "trailer": false,
                  "uncleanTankContainer": false,
                  "position": "0051282",
                  "numberOfOuterPackages": 1,
                  "outerPackageType": null,
                  "numberOfInnerPackages": null,
                  "innerPackageType": null,
                  "transportInLimitedQuantity": false,
                  "netWeight": 20180.0,
                  "netExplosiveMass": null,
                  "goodIdIsMissing": null
                },
                {
                  "type": "container",
                  "stowageNumber": "TIFU5750568",
                  "goodId": "682c4077-7201-44e3-9e78-296d7c0b7758",
                  "portOfLoading": {
                    "name": "Rotterdam",
                    "locationUnCode": "NLRTM",
                    "countryUnCode": "NL",
                    "euPort": true
                  },
                  "portOfDischarge": {
                    "name": "Savannah",
                    "locationUnCode": "USSAV",
                    "countryUnCode": "US",
                    "euPort": false
                  },
                  "weight": 26460.0,
                  "trailer": false,
                  "uncleanTankContainer": false,
                  "position": "0310682",
                  "numberOfOuterPackages": 1,
                  "outerPackageType": null,
                  "numberOfInnerPackages": null,
                  "innerPackageType": null,
                  "transportInLimitedQuantity": false,
                  "netWeight": 20180.0,
                  "netExplosiveMass": null,
                  "goodIdIsMissing": null
                },
                {
                  "type": "container",
                  "stowageNumber": "UTCU4820045",
                  "goodId": "f5f3bb38-c5d6-470e-a56a-25c02000490b",
                  "portOfLoading": {
                    "name": "Rotterdam",
                    "locationUnCode": "NLRTM",
                    "countryUnCode": "NL",
                    "euPort": true
                  },
                  "portOfDischarge": {
                    "name": "Savannah",
                    "locationUnCode": "USSAV",
                    "countryUnCode": "US",
                    "euPort": false
                  },
                  "weight": 25308.0,
                  "trailer": false,
                  "uncleanTankContainer": false,
                  "position": "0110204",
                  "numberOfOuterPackages": 1,
                  "outerPackageType": null,
                  "numberOfInnerPackages": null,
                  "innerPackageType": null,
                  "transportInLimitedQuantity": false,
                  "netWeight": 21588.0,
                  "netExplosiveMass": null,
                  "goodIdIsMissing": null
                },
                {
                  "type": "container",
                  "stowageNumber": "UTCU4904212",
                  "goodId": "4c7cd9ed-aad0-4618-b2ed-abb7512805ec",
                  "portOfLoading": {
                    "name": "Rotterdam",
                    "locationUnCode": "NLRTM",
                    "countryUnCode": "NL",
                    "euPort": true
                  },
                  "portOfDischarge": {
                    "name": "Savannah",
                    "locationUnCode": "USSAV",
                    "countryUnCode": "US",
                    "euPort": false
                  },
                  "weight": 25408.0,
                  "trailer": false,
                  "uncleanTankContainer": false,
                  "position": "0110202",
                  "numberOfOuterPackages": 1,
                  "outerPackageType": null,
                  "numberOfInnerPackages": null,
                  "innerPackageType": null,
                  "transportInLimitedQuantity": false,
                  "netWeight": 21588.0,
                  "netExplosiveMass": null,
                  "goodIdIsMissing": null
                },
                {
                  "type": "container",
                  "stowageNumber": "UTCU4998340",
                  "goodId": "5c2458c0-3d21-4e77-bd5a-972c1c4072d5",
                  "portOfLoading": {
                    "name": "Rotterdam",
                    "locationUnCode": "NLRTM",
                    "countryUnCode": "NL",
                    "euPort": true
                  },
                  "portOfDischarge": {
                    "name": "Savannah",
                    "locationUnCode": "USSAV",
                    "countryUnCode": "US",
                    "euPort": false
                  },
                  "weight": 25388.0,
                  "trailer": false,
                  "uncleanTankContainer": false,
                  "position": "0090202",
                  "numberOfOuterPackages": 1,
                  "outerPackageType": null,
                  "numberOfInnerPackages": null,
                  "innerPackageType": null,
                  "transportInLimitedQuantity": false,
                  "netWeight": 21588.0,
                  "netExplosiveMass": null,
                  "goodIdIsMissing": null
                },
                {
                  "type": "container",
                  "stowageNumber": "WINU2655711",
                  "goodId": "cff8cec6-b0e9-4611-85f8-fe6e7b470613",
                  "portOfLoading": {
                    "name": "Rotterdam",
                    "locationUnCode": "NLRTM",
                    "countryUnCode": "NL",
                    "euPort": true
                  },
                  "portOfDischarge": {
                    "name": "Savannah",
                    "locationUnCode": "USSAV",
                    "countryUnCode": "US",
                    "euPort": false
                  },
                  "weight": 26840.0,
                  "trailer": false,
                  "uncleanTankContainer": false,
                  "position": "0090102",
                  "numberOfOuterPackages": 1,
                  "outerPackageType": null,
                  "numberOfInnerPackages": null,
                  "innerPackageType": null,
                  "transportInLimitedQuantity": false,
                  "netWeight": 23280.0,
                  "netExplosiveMass": null,
                  "goodIdIsMissing": null
                },
                {
                  "type": "container",
                  "stowageNumber": "APHU6695489",
                  "goodId": "c51d3608-2fda-4ca4-ac67-cece156c5b28",
                  "portOfLoading": {
                    "name": "Rotterdam",
                    "locationUnCode": "NLRTM",
                    "countryUnCode": "NL",
                    "euPort": true
                  },
                  "portOfDischarge": {
                    "name": "New York",
                    "locationUnCode": "USNYC",
                    "countryUnCode": "US",
                    "euPort": false
                  },
                  "weight": 16527.0,
                  "trailer": false,
                  "uncleanTankContainer": false,
                  "position": "0140208",
                  "numberOfOuterPackages": 14850,
                  "outerPackageType": {
                    "code": null,
                    "name": "EXPANDED PLASTICS BOXES",
                    "bulk": false
                  },
                  "numberOfInnerPackages": null,
                  "innerPackageType": null,
                  "transportInLimitedQuantity": true,
                  "netWeight": 11391.0,
                  "netExplosiveMass": null,
                  "goodIdIsMissing": null
                },
                {
                  "type": "container",
                  "stowageNumber": "HGTU2401350",
                  "goodId": "73243d12-2313-4c77-b76e-a71fb2e608ae",
                  "portOfLoading": {
                    "name": "Rotterdam",
                    "locationUnCode": "NLRTM",
                    "countryUnCode": "NL",
                    "euPort": true
                  },
                  "portOfDischarge": {
                    "name": "Savannah",
                    "locationUnCode": "USSAV",
                    "countryUnCode": "US",
                    "euPort": false
                  },
                  "weight": 25260.0,
                  "trailer": false,
                  "uncleanTankContainer": false,
                  "position": "0310582",
                  "numberOfOuterPackages": 1,
                  "outerPackageType": null,
                  "numberOfInnerPackages": null,
                  "innerPackageType": null,
                  "transportInLimitedQuantity": false,
                  "netWeight": 21700.0,
                  "netExplosiveMass": null,
                  "goodIdIsMissing": null
                },
                {
                  "type": "container",
                  "stowageNumber": "EURU1020505",
                  "goodId": "d22af0a4-765b-4bad-b277-39b0926829e0",
                  "portOfLoading": {
                    "name": "Rotterdam",
                    "locationUnCode": "NLRTM",
                    "countryUnCode": "NL",
                    "euPort": true
                  },
                  "portOfDischarge": {
                    "name": "New York",
                    "locationUnCode": "USNYC",
                    "countryUnCode": "US",
                    "euPort": false
                  },
                  "weight": 20540.0,
                  "trailer": false,
                  "uncleanTankContainer": false,
                  "position": "0150102",
                  "numberOfOuterPackages": 1,
                  "outerPackageType": null,
                  "numberOfInnerPackages": null,
                  "innerPackageType": null,
                  "transportInLimitedQuantity": false,
                  "netWeight": 15140.0,
                  "netExplosiveMass": null,
                  "goodIdIsMissing": null
                },
                {
                  "type": "container",
                  "stowageNumber": "TGHU3926857",
                  "goodId": "90d23b77-f7e8-43c6-9ef7-fa8d3991d5ed",
                  "portOfLoading": {
                    "name": "Rotterdam",
                    "locationUnCode": "NLRTM",
                    "countryUnCode": "NL",
                    "euPort": true
                  },
                  "portOfDischarge": {
                    "name": "Papeete",
                    "locationUnCode": "PFPPT",
                    "countryUnCode": "PF",
                    "euPort": false
                  },
                  "weight": 304.6,
                  "trailer": false,
                  "uncleanTankContainer": false,
                  "position": "0110908",
                  "numberOfOuterPackages": 100,
                  "outerPackageType": {
                    "code": null,
                    "name": "FIBREBOARD BOXES",
                    "bulk": false
                  },
                  "numberOfInnerPackages": null,
                  "innerPackageType": null,
                  "transportInLimitedQuantity": true,
                  "netWeight": 226.5,
                  "netExplosiveMass": null,
                  "goodIdIsMissing": null
                },
                {
                  "type": "container",
                  "stowageNumber": "TGHU3926857",
                  "goodId": "ffc65bdf-017d-4b80-a41f-ef9f8ad431d1",
                  "portOfLoading": {
                    "name": "Rotterdam",
                    "locationUnCode": "NLRTM",
                    "countryUnCode": "NL",
                    "euPort": true
                  },
                  "portOfDischarge": {
                    "name": "Papeete",
                    "locationUnCode": "PFPPT",
                    "countryUnCode": "PF",
                    "euPort": false
                  },
                  "weight": 22.0,
                  "trailer": false,
                  "uncleanTankContainer": false,
                  "position": "0110908",
                  "numberOfOuterPackages": 15,
                  "outerPackageType": {
                    "code": null,
                    "name": "FIBREBOARD BOXES",
                    "bulk": false
                  },
                  "numberOfInnerPackages": null,
                  "innerPackageType": null,
                  "transportInLimitedQuantity": true,
                  "netWeight": 17.0,
                  "netExplosiveMass": null,
                  "goodIdIsMissing": null
                },
                {
                  "type": "container",
                  "stowageNumber": "TGHU3926857",
                  "goodId": "c75ee6ac-fa6c-4f3c-810d-def5a1a0a040",
                  "portOfLoading": {
                    "name": "Rotterdam",
                    "locationUnCode": "NLRTM",
                    "countryUnCode": "NL",
                    "euPort": true
                  },
                  "portOfDischarge": {
                    "name": "Papeete",
                    "locationUnCode": "PFPPT",
                    "countryUnCode": "PF",
                    "euPort": false
                  },
                  "weight": 1963.7,
                  "trailer": false,
                  "uncleanTankContainer": false,
                  "position": "0110908",
                  "numberOfOuterPackages": 485,
                  "outerPackageType": {
                    "code": null,
                    "name": "FIBREBOARD BOXES",
                    "bulk": false
                  },
                  "numberOfInnerPackages": null,
                  "innerPackageType": null,
                  "transportInLimitedQuantity": true,
                  "netWeight": 1641.4,
                  "netExplosiveMass": null,
                  "goodIdIsMissing": null
                },
                {
                  "type": "container",
                  "stowageNumber": "TGHU3926857",
                  "goodId": "0525a7e8-f71f-48cd-9a6a-34f6144a2d73",
                  "portOfLoading": {
                    "name": "Rotterdam",
                    "locationUnCode": "NLRTM",
                    "countryUnCode": "NL",
                    "euPort": true
                  },
                  "portOfDischarge": {
                    "name": "Papeete",
                    "locationUnCode": "PFPPT",
                    "countryUnCode": "PF",
                    "euPort": false
                  },
                  "weight": 112.1,
                  "trailer": false,
                  "uncleanTankContainer": false,
                  "position": "0110908",
                  "numberOfOuterPackages": 20,
                  "outerPackageType": {
                    "code": null,
                    "name": "FIBREBOARD BOXES",
                    "bulk": false
                  },
                  "numberOfInnerPackages": null,
                  "innerPackageType": null,
                  "transportInLimitedQuantity": true,
                  "netWeight": 94.8,
                  "netExplosiveMass": null,
                  "goodIdIsMissing": null
                },
                {
                  "type": "container",
                  "stowageNumber": "TGHU3926857",
                  "goodId": "4f25db47-9f82-465c-a4f9-aa7f339dfc5b",
                  "portOfLoading": {
                    "name": "Rotterdam",
                    "locationUnCode": "NLRTM",
                    "countryUnCode": "NL",
                    "euPort": true
                  },
                  "portOfDischarge": {
                    "name": "Papeete",
                    "locationUnCode": "PFPPT",
                    "countryUnCode": "PF",
                    "euPort": false
                  },
                  "weight": 8.4,
                  "trailer": false,
                  "uncleanTankContainer": false,
                  "position": "0110908",
                  "numberOfOuterPackages": 15,
                  "outerPackageType": {
                    "code": null,
                    "name": "FIBREBOARD BOXES",
                    "bulk": false
                  },
                  "numberOfInnerPackages": null,
                  "innerPackageType": null,
                  "transportInLimitedQuantity": true,
                  "netWeight": 3.2,
                  "netExplosiveMass": null,
                  "goodIdIsMissing": null
                },
                {
                  "type": "container",
                  "stowageNumber": "TGHU3926857",
                  "goodId": "aee7883a-000e-4e99-bfde-a9ab2e0f4f88",
                  "portOfLoading": {
                    "name": "Rotterdam",
                    "locationUnCode": "NLRTM",
                    "countryUnCode": "NL",
                    "euPort": true
                  },
                  "portOfDischarge": {
                    "name": "Papeete",
                    "locationUnCode": "PFPPT",
                    "countryUnCode": "PF",
                    "euPort": false
                  },
                  "weight": 26.5,
                  "trailer": false,
                  "uncleanTankContainer": false,
                  "position": "0110908",
                  "numberOfOuterPackages": 15,
                  "outerPackageType": {
                    "code": null,
                    "name": "FIBREBOARD BOXES",
                    "bulk": false
                  },
                  "numberOfInnerPackages": null,
                  "innerPackageType": null,
                  "transportInLimitedQuantity": true,
                  "netWeight": 20.7,
                  "netExplosiveMass": null,
                  "goodIdIsMissing": null
                },
                {
                  "type": "container",
                  "stowageNumber": "TCLU3945945",
                  "goodId": "1a47f89c-f2b7-49d0-8496-bf5f5a4282fb",
                  "portOfLoading": {
                    "name": "Rotterdam",
                    "locationUnCode": "NLRTM",
                    "countryUnCode": "NL",
                    "euPort": true
                  },
                  "portOfDischarge": {
                    "name": "Savannah",
                    "locationUnCode": "USSAV",
                    "countryUnCode": "US",
                    "euPort": false
                  },
                  "weight": 27302.8,
                  "trailer": false,
                  "uncleanTankContainer": false,
                  "position": "0090204",
                  "numberOfOuterPackages": 72,
                  "outerPackageType": {
                    "code": null,
                    "name": "STEEL DRUMS, NON-REMOVABLE HEAD",
                    "bulk": false
                  },
                  "numberOfInnerPackages": null,
                  "innerPackageType": null,
                  "transportInLimitedQuantity": false,
                  "netWeight": 23400.0,
                  "netExplosiveMass": null,
                  "goodIdIsMissing": null
                },
                {
                  "type": "container",
                  "stowageNumber": "SEGU9170250",
                  "goodId": "fde6559f-ef7f-47e7-becd-49e516d6ffc6",
                  "portOfLoading": {
                    "name": "Rotterdam",
                    "locationUnCode": "NLRTM",
                    "countryUnCode": "NL",
                    "euPort": true
                  },
                  "portOfDischarge": {
                    "name": "New York",
                    "locationUnCode": "USNYC",
                    "countryUnCode": "US",
                    "euPort": false
                  },
                  "weight": 774.9,
                  "trailer": false,
                  "uncleanTankContainer": false,
                  "position": "0260884",
                  "numberOfOuterPackages": 4,
                  "outerPackageType": {
                    "code": null,
                    "name": "STEEL DRUMS, REMOVABLE HEAD",
                    "bulk": false
                  },
                  "numberOfInnerPackages": null,
                  "innerPackageType": null,
                  "transportInLimitedQuantity": false,
                  "netWeight": 700.0,
                  "netExplosiveMass": null,
                  "goodIdIsMissing": null
                },
                {
                  "type": "container",
                  "stowageNumber": "SEGU9170250",
                  "goodId": "039c99a6-f2f2-4639-830d-b2926f5ef47c",
                  "portOfLoading": {
                    "name": "Rotterdam",
                    "locationUnCode": "NLRTM",
                    "countryUnCode": "NL",
                    "euPort": true
                  },
                  "portOfDischarge": {
                    "name": "New York",
                    "locationUnCode": "USNYC",
                    "countryUnCode": "US",
                    "euPort": false
                  },
                  "weight": 2324.7,
                  "trailer": false,
                  "uncleanTankContainer": false,
                  "position": "0260884",
                  "numberOfOuterPackages": 12,
                  "outerPackageType": {
                    "code": null,
                    "name": "STEEL DRUMS, REMOVABLE HEAD",
                    "bulk": false
                  },
                  "numberOfInnerPackages": null,
                  "innerPackageType": null,
                  "transportInLimitedQuantity": false,
                  "netWeight": 2100.0,
                  "netExplosiveMass": null,
                  "goodIdIsMissing": null
                },
                {
                  "type": "container",
                  "stowageNumber": "SEGU9170250",
                  "goodId": "f80125e3-c7c1-413e-8a7f-c2b24a874b73",
                  "portOfLoading": {
                    "name": "Rotterdam",
                    "locationUnCode": "NLRTM",
                    "countryUnCode": "NL",
                    "euPort": true
                  },
                  "portOfDischarge": {
                    "name": "New York",
                    "locationUnCode": "USNYC",
                    "countryUnCode": "US",
                    "euPort": false
                  },
                  "weight": 93.92,
                  "trailer": false,
                  "uncleanTankContainer": false,
                  "position": "0260884",
                  "numberOfOuterPackages": 4,
                  "outerPackageType": {
                    "code": null,
                    "name": "STEEL DRUMS, REMOVABLE HEAD",
                    "bulk": false
                  },
                  "numberOfInnerPackages": null,
                  "innerPackageType": null,
                  "transportInLimitedQuantity": false,
                  "netWeight": 80.0,
                  "netExplosiveMass": null,
                  "goodIdIsMissing": null
                },
                {
                  "type": "container",
                  "stowageNumber": "TRIU8734256",
                  "goodId": "e07ef996-e2b0-464f-9817-b9e540310332",
                  "portOfLoading": {
                    "name": "Rotterdam",
                    "locationUnCode": "NLRTM",
                    "countryUnCode": "NL",
                    "euPort": true
                  },
                  "portOfDischarge": {
                    "name": "New York",
                    "locationUnCode": "USNYC",
                    "countryUnCode": "US",
                    "euPort": false
                  },
                  "weight": 9298.8,
                  "trailer": false,
                  "uncleanTankContainer": false,
                  "position": "0260984",
                  "numberOfOuterPackages": 48,
                  "outerPackageType": {
                    "code": null,
                    "name": "STEEL DRUMS, REMOVABLE HEAD",
                    "bulk": false
                  },
                  "numberOfInnerPackages": null,
                  "innerPackageType": null,
                  "transportInLimitedQuantity": false,
                  "netWeight": 8400.0,
                  "netExplosiveMass": null,
                  "goodIdIsMissing": null
                },
                {
                  "type": "container",
                  "stowageNumber": "TRIU8734256",
                  "goodId": "c1a2c7a4-a8d1-4170-810a-2de38db27f68",
                  "portOfLoading": {
                    "name": "Rotterdam",
                    "locationUnCode": "NLRTM",
                    "countryUnCode": "NL",
                    "euPort": true
                  },
                  "portOfDischarge": {
                    "name": "New York",
                    "locationUnCode": "USNYC",
                    "countryUnCode": "US",
                    "euPort": false
                  },
                  "weight": 1073.28,
                  "trailer": false,
                  "uncleanTankContainer": false,
                  "position": "0260984",
                  "numberOfOuterPackages": 6,
                  "outerPackageType": {
                    "code": null,
                    "name": "STEEL DRUMS, REMOVABLE HEAD",
                    "bulk": false
                  },
                  "numberOfInnerPackages": null,
                  "innerPackageType": null,
                  "transportInLimitedQuantity": false,
                  "netWeight": 960.0,
                  "netExplosiveMass": null,
                  "goodIdIsMissing": null
                },
                {
                  "type": "container",
                  "stowageNumber": "TTNU8664827",
                  "goodId": "2a966b1c-e920-46f2-bc9c-47c636364d96",
                  "portOfLoading": {
                    "name": "Rotterdam",
                    "locationUnCode": "NLRTM",
                    "countryUnCode": "NL",
                    "euPort": true
                  },
                  "portOfDischarge": {
                    "name": "New York",
                    "locationUnCode": "USNYC",
                    "countryUnCode": "US",
                    "euPort": false
                  },
                  "weight": 774.9,
                  "trailer": false,
                  "uncleanTankContainer": false,
                  "position": "0260684",
                  "numberOfOuterPackages": 4,
                  "outerPackageType": {
                    "code": null,
                    "name": "STEEL DRUMS, REMOVABLE HEAD",
                    "bulk": false
                  },
                  "numberOfInnerPackages": null,
                  "innerPackageType": null,
                  "transportInLimitedQuantity": false,
                  "netWeight": 700.0,
                  "netExplosiveMass": null,
                  "goodIdIsMissing": null
                },
                {
                  "type": "container",
                  "stowageNumber": "TTNU8664827",
                  "goodId": "b89e3dc7-cde2-4c5d-b83b-6470d7826b70",
                  "portOfLoading": {
                    "name": "Rotterdam",
                    "locationUnCode": "NLRTM",
                    "countryUnCode": "NL",
                    "euPort": true
                  },
                  "portOfDischarge": {
                    "name": "New York",
                    "locationUnCode": "USNYC",
                    "countryUnCode": "US",
                    "euPort": false
                  },
                  "weight": 7942.73,
                  "trailer": false,
                  "uncleanTankContainer": false,
                  "position": "0260684",
                  "numberOfOuterPackages": 41,
                  "outerPackageType": {
                    "code": null,
                    "name": "STEEL DRUMS, REMOVABLE HEAD",
                    "bulk": false
                  },
                  "numberOfInnerPackages": null,
                  "innerPackageType": null,
                  "transportInLimitedQuantity": false,
                  "netWeight": 7175.0,
                  "netExplosiveMass": null,
                  "goodIdIsMissing": null
                },
                {
                  "type": "container",
                  "stowageNumber": "TTNU8664827",
                  "goodId": "fa3aaac6-b8de-4bb1-ae03-c9870284d0e2",
                  "portOfLoading": {
                    "name": "Rotterdam",
                    "locationUnCode": "NLRTM",
                    "countryUnCode": "NL",
                    "euPort": true
                  },
                  "portOfDischarge": {
                    "name": "New York",
                    "locationUnCode": "USNYC",
                    "countryUnCode": "US",
                    "euPort": false
                  },
                  "weight": 6005.48,
                  "trailer": false,
                  "uncleanTankContainer": false,
                  "position": "0260684",
                  "numberOfOuterPackages": 31,
                  "outerPackageType": {
                    "code": null,
                    "name": "STEEL DRUMS, REMOVABLE HEAD",
                    "bulk": false
                  },
                  "numberOfInnerPackages": null,
                  "innerPackageType": null,
                  "transportInLimitedQuantity": false,
                  "netWeight": 5425.0,
                  "netExplosiveMass": null,
                  "goodIdIsMissing": null
                },
                {
                  "type": "container",
                  "stowageNumber": "TRIU8161431",
                  "goodId": "c8eceaa4-b22d-4630-9f6c-4dee209f35ad",
                  "portOfLoading": {
                    "name": "Rotterdam",
                    "locationUnCode": "NLRTM",
                    "countryUnCode": "NL",
                    "euPort": true
                  },
                  "portOfDischarge": {
                    "name": "New York",
                    "locationUnCode": "USNYC",
                    "countryUnCode": "US",
                    "euPort": false
                  },
                  "weight": 1162.35,
                  "trailer": false,
                  "uncleanTankContainer": false,
                  "position": "0260784",
                  "numberOfOuterPackages": 6,
                  "outerPackageType": {
                    "code": null,
                    "name": "STEEL DRUMS, REMOVABLE HEAD",
                    "bulk": false
                  },
                  "numberOfInnerPackages": null,
                  "innerPackageType": null,
                  "transportInLimitedQuantity": false,
                  "netWeight": 1050.0,
                  "netExplosiveMass": null,
                  "goodIdIsMissing": null
                },
                {
                  "type": "container",
                  "stowageNumber": "TRIU8161431",
                  "goodId": "df64746f-9b03-46cc-83ab-397ebcdda74b",
                  "portOfLoading": {
                    "name": "Rotterdam",
                    "locationUnCode": "NLRTM",
                    "countryUnCode": "NL",
                    "euPort": true
                  },
                  "portOfDischarge": {
                    "name": "New York",
                    "locationUnCode": "USNYC",
                    "countryUnCode": "US",
                    "euPort": false
                  },
                  "weight": 193.73,
                  "trailer": false,
                  "uncleanTankContainer": false,
                  "position": "0260784",
                  "numberOfOuterPackages": 1,
                  "outerPackageType": {
                    "code": null,
                    "name": "STEEL DRUMS, REMOVABLE HEAD",
                    "bulk": false
                  },
                  "numberOfInnerPackages": null,
                  "innerPackageType": null,
                  "transportInLimitedQuantity": false,
                  "netWeight": 175.0,
                  "netExplosiveMass": null,
                  "goodIdIsMissing": null
                },
                {
                  "type": "container",
                  "stowageNumber": "TRIU8161431",
                  "goodId": "946ccf95-c7a0-47f9-ade7-85afe71ff530",
                  "portOfLoading": {
                    "name": "Rotterdam",
                    "locationUnCode": "NLRTM",
                    "countryUnCode": "NL",
                    "euPort": true
                  },
                  "portOfDischarge": {
                    "name": "New York",
                    "locationUnCode": "USNYC",
                    "countryUnCode": "US",
                    "euPort": false
                  },
                  "weight": 11429.78,
                  "trailer": false,
                  "uncleanTankContainer": false,
                  "position": "0260784",
                  "numberOfOuterPackages": 59,
                  "outerPackageType": {
                    "code": null,
                    "name": "STEEL DRUMS, REMOVABLE HEAD",
                    "bulk": false
                  },
                  "numberOfInnerPackages": null,
                  "innerPackageType": null,
                  "transportInLimitedQuantity": false,
                  "netWeight": 10325.0,
                  "netExplosiveMass": null,
                  "goodIdIsMissing": null
                },
                {
                  "type": "container",
                  "stowageNumber": "TRIU8161431",
                  "goodId": "801833b0-9132-43bf-b4f9-07906c6f31d7",
                  "portOfLoading": {
                    "name": "Rotterdam",
                    "locationUnCode": "NLRTM",
                    "countryUnCode": "NL",
                    "euPort": true
                  },
                  "portOfDischarge": {
                    "name": "New York",
                    "locationUnCode": "USNYC",
                    "countryUnCode": "US",
                    "euPort": false
                  },
                  "weight": 774.9,
                  "trailer": false,
                  "uncleanTankContainer": false,
                  "position": "0260784",
                  "numberOfOuterPackages": 4,
                  "outerPackageType": {
                    "code": null,
                    "name": "STEEL DRUMS, REMOVABLE HEAD",
                    "bulk": false
                  },
                  "numberOfInnerPackages": null,
                  "innerPackageType": null,
                  "transportInLimitedQuantity": false,
                  "netWeight": 700.0,
                  "netExplosiveMass": null,
                  "goodIdIsMissing": null
                },
                {
                  "type": "container",
                  "stowageNumber": "TRIU8161431",
                  "goodId": "07478a24-0440-41c1-8436-a2f9218af665",
                  "portOfLoading": {
                    "name": "Rotterdam",
                    "locationUnCode": "NLRTM",
                    "countryUnCode": "NL",
                    "euPort": true
                  },
                  "portOfDischarge": {
                    "name": "New York",
                    "locationUnCode": "USNYC",
                    "countryUnCode": "US",
                    "euPort": false
                  },
                  "weight": 715.52,
                  "trailer": false,
                  "uncleanTankContainer": false,
                  "position": "0260784",
                  "numberOfOuterPackages": 4,
                  "outerPackageType": {
                    "code": null,
                    "name": "STEEL DRUMS, REMOVABLE HEAD",
                    "bulk": false
                  },
                  "numberOfInnerPackages": null,
                  "innerPackageType": null,
                  "transportInLimitedQuantity": false,
                  "netWeight": 640.0,
                  "netExplosiveMass": null,
                  "goodIdIsMissing": null
                }
              ],
              "shipToShip": false,
              "keepInert": null
            }
          ]
        },
        "cargoDeclarant": {
          "fullName": "CMA-CGM (Holland) B.V.",
          "shortName": "CMACGMRHN",
          "portAuthorityId": "CMA-CGM",
          "emailAddress": "rtm.shipops@cma-cgm.com",
          "address": "Achterdijk 55",
          "city": "Rhoon",
          "countryUnCode": "NL",
          "countryName": "Netherlands",
          "zipCode": "3161 EB",
          "phoneNumber": "010-2998199",
          "faxNumber": "010-2998190",
          "contact": "Marcel den Boogert",
          "customsEORINumber": "NL806025463",
          "ean": null,
          "chamberOfCommerceNumber": "24276503",
          "scacCode": null,
          "ensEnabled": false,
          "cargoImportEnabled": true,
          "cargoImportEnabledByDefault": true
        },
        "visitDeclarant": {
          "fullName": "CMA-CGM (Holland) B.V.",
          "shortName": "CMACGMRHN",
          "portAuthorityId": "CMA-CGM",
          "emailAddress": "rtm.shipops@cma-cgm.com",
          "address": "Achterdijk 55",
          "city": "Rhoon",
          "countryUnCode": "NL",
          "countryName": "Netherlands",
          "zipCode": "3161 EB",
          "phoneNumber": "010-2998199",
          "faxNumber": "010-2998190",
          "contact": "Adrie Tiemens",
          "customsEORINumber": "NL806025463",
          "ean": null,
          "chamberOfCommerceNumber": "24276503",
          "scacCode": null,
          "ensEnabled": false,
          "cargoImportEnabled": true,
          "cargoImportEnabledByDefault": true
        },
        "visitOwner": {
          "fullName": "CMA-CGM (Holland) B.V.",
          "shortName": "CMACGMRHN",
          "portAuthorityId": "CMA-CGM",
          "emailAddress": "rtm.shipops@cma-cgm.com",
          "address": "Achterdijk 55",
          "city": "Rhoon",
          "countryUnCode": "NL",
          "countryName": "Netherlands",
          "zipCode": "3161 EB",
          "phoneNumber": "010-2998199",
          "faxNumber": "010-2998190",
          "contact": "Adrie Tiemens",
          "customsEORINumber": "NL806025463",
          "ean": null,
          "chamberOfCommerceNumber": "24276503",
          "scacCode": null,
          "ensEnabled": false,
          "cargoImportEnabled": true,
          "cargoImportEnabledByDefault": true
        },
        "upload": null,
        "completed": true,
        "declared": true,
        "declaredByVisitDeclarant": true,
        "processIds": [
          "92855082bf8e4489b7c59d65a583b32c",
          "762ed1bd55664e8fb86a1f50261366e4",
          "762ed1bd55664e8fb86a1f50261366e4"
        ]
      }
    ],
    "nextDangerousGoodsDeclaration": null,
    "importDeclarations": [
      {
        "cargoDeclarant": {
          "fullName": "CMA-CGM (Holland) B.V.",
          "shortName": "CMACGMRHN",
          "portAuthorityId": "CMA-CGM",
          "emailAddress": "rtm.shipops@cma-cgm.com",
          "address": "Achterdijk 55",
          "city": "Rhoon",
          "countryUnCode": "NL",
          "countryName": "Netherlands",
          "zipCode": "3161 EB",
          "phoneNumber": "010-2998199",
          "faxNumber": "010-2998190",
          "contact": "Marcel den Boogert",
          "customsEORINumber": "NL806025463",
          "ean": null,
          "chamberOfCommerceNumber": "24276503",
          "scacCode": null,
          "ensEnabled": false,
          "cargoImportEnabled": true,
          "cargoImportEnabledByDefault": true
        },
        "timestamp": "2021-10-01T06:59:50.004Z",
        "containers": [
          {
            "number": "APHU6358338",
            "portOfLoading": {
              "name": "Tauranga",
              "locationUnCode": "NZTRG",
              "countryUnCode": "NZ",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-08-20T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 3870,
            "bookingReference": "AKD0268683",
            "shipperSealNumber": null,
            "carrierSealNumber": "NZ301187",
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "APHU6629450",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 3870,
            "bookingReference": "AKD0268929",
            "shipperSealNumber": null,
            "carrierSealNumber": "SAL832542",
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "APHU6768271",
            "portOfLoading": {
              "name": "Tauranga",
              "locationUnCode": "NZTRG",
              "countryUnCode": "NZ",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-08-20T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 3870,
            "bookingReference": "AKD0268683",
            "shipperSealNumber": null,
            "carrierSealNumber": "NZ301202",
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "APHU6889720",
            "portOfLoading": {
              "name": "Tauranga",
              "locationUnCode": "NZTRG",
              "countryUnCode": "NZ",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-08-20T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 3870,
            "bookingReference": "AKD0269154",
            "shipperSealNumber": null,
            "carrierSealNumber": "NZ311069",
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "APHU7060862",
            "portOfLoading": {
              "name": "Tauranga",
              "locationUnCode": "NZTRG",
              "countryUnCode": "NZ",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-08-20T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 3870,
            "bookingReference": "AKD0269043",
            "shipperSealNumber": null,
            "carrierSealNumber": "NZ301289",
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "APZU2126949",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 2220,
            "bookingReference": "AKD0268650",
            "shipperSealNumber": null,
            "carrierSealNumber": "NZ299843",
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "APZU2132730",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 2220,
            "bookingReference": "AKD0267922",
            "shipperSealNumber": null,
            "carrierSealNumber": "BLNZ200799",
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "APZU3454599",
            "portOfLoading": {
              "name": "Nouméa",
              "locationUnCode": "NCNOU",
              "countryUnCode": "NC",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-08-04T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 2220,
            "bookingReference": "NMA0110071",
            "shipperSealNumber": null,
            "carrierSealNumber": "NA",
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "APZU3598533",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 2220,
            "bookingReference": "AKD0269163",
            "shipperSealNumber": null,
            "carrierSealNumber": "W053941",
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "APZU3778582",
            "portOfLoading": {
              "name": "Nouméa",
              "locationUnCode": "NCNOU",
              "countryUnCode": "NC",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-08-04T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 2220,
            "bookingReference": "NMA0110071",
            "shipperSealNumber": null,
            "carrierSealNumber": "NA",
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "APZU3828683",
            "portOfLoading": {
              "name": "Nouméa",
              "locationUnCode": "NCNOU",
              "countryUnCode": "NC",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-08-04T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 2220,
            "bookingReference": "NMA0110071",
            "shipperSealNumber": null,
            "carrierSealNumber": "NA",
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "APZU4866625",
            "portOfLoading": {
              "name": "Tauranga",
              "locationUnCode": "NZTRG",
              "countryUnCode": "NZ",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-08-20T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "42G1",
              "name": "40'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 3700,
            "bookingReference": "AKD0269053",
            "shipperSealNumber": null,
            "carrierSealNumber": "BS1A004216",
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "AXIU1652252",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 3820,
            "bookingReference": "AKD0268044",
            "shipperSealNumber": null,
            "carrierSealNumber": "2MF61224",
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "BAFU8890024",
            "portOfLoading": {
              "name": "Brisbane",
              "locationUnCode": "AUBNE",
              "countryUnCode": "AU",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-08-08T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22T6",
              "name": "20'x8'x8'6\" Tank Container Test pressure 6 bar"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 3920,
            "bookingReference": "MBE0353890",
            "shipperSealNumber": null,
            "carrierSealNumber": "2777689",
            "customsSealNumber": null,
            "shippersOwned": true,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "BAFU8890370",
            "portOfLoading": {
              "name": "Brisbane",
              "locationUnCode": "AUBNE",
              "countryUnCode": "AU",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-08-08T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22T0",
              "name": "20'x8'x8'6\" Tank Container Test pressure 0,45 bar"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 3930,
            "bookingReference": "MBE0353890",
            "shipperSealNumber": null,
            "carrierSealNumber": "2777688",
            "customsSealNumber": null,
            "shippersOwned": true,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "BEAU4044853",
            "portOfLoading": {
              "name": "Tauranga",
              "locationUnCode": "NZTRG",
              "countryUnCode": "NZ",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-08-20T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G0",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 3850,
            "bookingReference": "AKD0269220",
            "shipperSealNumber": null,
            "carrierSealNumber": "BS1A003687",
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "BLKU2520467",
            "portOfLoading": {
              "name": "Brisbane",
              "locationUnCode": "AUBNE",
              "countryUnCode": "AU",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-08-08T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22T6",
              "name": "20'x8'x8'6\" Tank Container Test pressure 6 bar"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 3420,
            "bookingReference": "MBE0353890",
            "shipperSealNumber": null,
            "carrierSealNumber": "2777684",
            "customsSealNumber": null,
            "shippersOwned": true,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "BLKU2585468",
            "portOfLoading": {
              "name": "Brisbane",
              "locationUnCode": "AUBNE",
              "countryUnCode": "AU",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-08-08T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22T6",
              "name": "20'x8'x8'6\" Tank Container Test pressure 6 bar"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 0,
            "bookingReference": "MBE0353890",
            "shipperSealNumber": null,
            "carrierSealNumber": "2777683",
            "customsSealNumber": null,
            "shippersOwned": true,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "BLKU2604655",
            "portOfLoading": {
              "name": "Brisbane",
              "locationUnCode": "AUBNE",
              "countryUnCode": "AU",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-08-08T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22K2",
              "name": "20 x 8,6 Liquid tank dangerous gds 2.65-10 bar"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 3420,
            "bookingReference": "MBE0353890",
            "shipperSealNumber": null,
            "carrierSealNumber": "2777690",
            "customsSealNumber": null,
            "shippersOwned": true,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "BMOU4045469",
            "portOfLoading": {
              "name": "Tauranga",
              "locationUnCode": "NZTRG",
              "countryUnCode": "NZ",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-08-20T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 3860,
            "bookingReference": "AKD0269033",
            "shipperSealNumber": null,
            "carrierSealNumber": "NZ301271",
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "BRKU1254471",
            "portOfLoading": {
              "name": "Brisbane",
              "locationUnCode": "AUBNE",
              "countryUnCode": "AU",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-08-08T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22T6",
              "name": "20'x8'x8'6\" Tank Container Test pressure 6 bar"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 3420,
            "bookingReference": "MBE0353890",
            "shipperSealNumber": null,
            "carrierSealNumber": "2777685",
            "customsSealNumber": null,
            "shippersOwned": true,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "CAIU3393773",
            "portOfLoading": {
              "name": "Nouméa",
              "locationUnCode": "NCNOU",
              "countryUnCode": "NC",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-08-04T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G0",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 2200,
            "bookingReference": "NMA0110071",
            "shipperSealNumber": null,
            "carrierSealNumber": "NA",
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "CAIU3468445",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 2200,
            "bookingReference": "AKD0269189",
            "shipperSealNumber": null,
            "carrierSealNumber": "W059081",
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "CAIU3874233",
            "portOfLoading": {
              "name": "Nouméa",
              "locationUnCode": "NCNOU",
              "countryUnCode": "NC",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-08-04T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 2160,
            "bookingReference": "NMA0110071",
            "shipperSealNumber": null,
            "carrierSealNumber": "NA",
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "CARU3715217",
            "portOfLoading": {
              "name": "Kingston",
              "locationUnCode": "JMKIN",
              "countryUnCode": "JM",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-11T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 2200,
            "bookingReference": "HAT0101288",
            "shipperSealNumber": null,
            "carrierSealNumber": "G7422052",
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "CGMU3040294",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22R1",
              "name": "20'x8'x8'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 2880,
            "bookingReference": "AKD0269376",
            "shipperSealNumber": null,
            "carrierSealNumber": "NZMPI01216412",
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "CGMU3044345",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22R1",
              "name": "20'x8'x8'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 2880,
            "bookingReference": "AKD0269480",
            "shipperSealNumber": null,
            "carrierSealNumber": "NZMPI01301171",
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "CGMU3077652",
            "portOfLoading": {
              "name": "Tauranga",
              "locationUnCode": "NZTRG",
              "countryUnCode": "NZ",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-08-20T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22R1",
              "name": "20'x8'x8'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 2880,
            "bookingReference": "AKD0269495",
            "shipperSealNumber": null,
            "carrierSealNumber": "NZMPI01301179",
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "CGMU5054595",
            "portOfLoading": {
              "name": "Tauranga",
              "locationUnCode": "NZTRG",
              "countryUnCode": "NZ",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-08-20T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 4780,
            "bookingReference": "AKD0269360",
            "shipperSealNumber": null,
            "carrierSealNumber": "2MF64180",
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "CMAU0527017",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "20G1",
              "name": "20G1"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 2190,
            "bookingReference": "PPT0113496",
            "shipperSealNumber": null,
            "carrierSealNumber": "G6216089",
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "CMAU0747310",
            "portOfLoading": {
              "name": "Nouméa",
              "locationUnCode": "NCNOU",
              "countryUnCode": "NC",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-08-04T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 1960,
            "bookingReference": "NMA0110071",
            "shipperSealNumber": null,
            "carrierSealNumber": "NA",
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "CMAU0804385",
            "portOfLoading": {
              "name": "Nouméa",
              "locationUnCode": "NCNOU",
              "countryUnCode": "NC",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-08-04T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 1960,
            "bookingReference": "NMA0110071",
            "shipperSealNumber": null,
            "carrierSealNumber": "NA",
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "CMAU0866155",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 1960,
            "bookingReference": "AKD0267916",
            "shipperSealNumber": null,
            "carrierSealNumber": "BLNZ200479",
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "CMAU0884797",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 1960,
            "bookingReference": "AKD0269163",
            "shipperSealNumber": null,
            "carrierSealNumber": "W053956",
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "CMAU0942419",
            "portOfLoading": {
              "name": "Nouméa",
              "locationUnCode": "NCNOU",
              "countryUnCode": "NC",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-08-04T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 2100,
            "bookingReference": "NMA0110071",
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "CMAU0993355",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 2100,
            "bookingReference": "AKD0268229",
            "shipperSealNumber": null,
            "carrierSealNumber": "W058973",
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "CMAU1698631",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 2220,
            "bookingReference": "AKD0269163",
            "shipperSealNumber": null,
            "carrierSealNumber": "W053801",
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "CMAU1867794",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 2190,
            "bookingReference": "AKD0268637",
            "shipperSealNumber": null,
            "carrierSealNumber": "004943",
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "CMAU2007420",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 2190,
            "bookingReference": "AKD0268626",
            "shipperSealNumber": null,
            "carrierSealNumber": "001628",
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "CMAU2080951",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 2190,
            "bookingReference": "AKD0268647",
            "shipperSealNumber": null,
            "carrierSealNumber": "2MF91758",
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "CMAU3010901",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 2100,
            "bookingReference": "AKD0267890",
            "shipperSealNumber": null,
            "carrierSealNumber": "BLNZ200903",
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "CMAU3012545",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 2100,
            "bookingReference": "AKD0269792",
            "shipperSealNumber": null,
            "carrierSealNumber": "NZ299849",
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "CMAU3146591",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 2100,
            "bookingReference": "AKD0268229",
            "shipperSealNumber": null,
            "carrierSealNumber": "W058962",
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "CMAU3199190",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 2100,
            "bookingReference": "AKD0268229",
            "shipperSealNumber": null,
            "carrierSealNumber": "W058972",
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "CMAU3254153",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 2100,
            "bookingReference": "AKD0267916",
            "shipperSealNumber": null,
            "carrierSealNumber": "BLNZ200907",
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "CMAU3254425",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 2100,
            "bookingReference": "AKD0268015",
            "shipperSealNumber": null,
            "carrierSealNumber": "W059018",
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "CMAU4685772",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 3860,
            "bookingReference": "AKD0268821",
            "shipperSealNumber": null,
            "carrierSealNumber": "W045993",
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "CMAU4885050",
            "portOfLoading": {
              "name": "Tauranga",
              "locationUnCode": "NZTRG",
              "countryUnCode": "NZ",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-08-20T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 3300,
            "bookingReference": "AKD0268683",
            "shipperSealNumber": null,
            "carrierSealNumber": "NZ301196",
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "CMAU6284059",
            "portOfLoading": {
              "name": "Nouméa",
              "locationUnCode": "NCNOU",
              "countryUnCode": "NC",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-08-04T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 3700,
            "bookingReference": "NMA0107916",
            "shipperSealNumber": null,
            "carrierSealNumber": "AGS0085140",
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "CMAU7183844",
            "portOfLoading": {
              "name": "Kingston",
              "locationUnCode": "JMKIN",
              "countryUnCode": "JM",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-11T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 3700,
            "bookingReference": "HAT0101289",
            "shipperSealNumber": null,
            "carrierSealNumber": "G7422051",
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "CMAU7326586",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 3700,
            "bookingReference": "AKD0268680",
            "shipperSealNumber": null,
            "carrierSealNumber": "NZ301159",
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "CMAU7361252",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 3700,
            "bookingReference": "AKD0268831",
            "shipperSealNumber": null,
            "carrierSealNumber": "NZ301224",
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "CMAU7487575",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 3700,
            "bookingReference": "AKD0268680",
            "shipperSealNumber": null,
            "carrierSealNumber": "NZ301201",
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "CMAU7636160",
            "portOfLoading": {
              "name": "Tauranga",
              "locationUnCode": "NZTRG",
              "countryUnCode": "NZ",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-08-20T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 3700,
            "bookingReference": "AKD0269154",
            "shipperSealNumber": null,
            "carrierSealNumber": "NZ311065",
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "CMAU7896104",
            "portOfLoading": {
              "name": "Nouméa",
              "locationUnCode": "NCNOU",
              "countryUnCode": "NC",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-08-04T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 3700,
            "bookingReference": "NMA0110061",
            "shipperSealNumber": null,
            "carrierSealNumber": "CEA835130",
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "CRXU3398494",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 2250,
            "bookingReference": "AKD0269163",
            "shipperSealNumber": null,
            "carrierSealNumber": "W053907",
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "DFSU2090590",
            "portOfLoading": {
              "name": "Kingston",
              "locationUnCode": "JMKIN",
              "countryUnCode": "JM",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-11T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 2185,
            "bookingReference": "GSR0121013",
            "shipperSealNumber": null,
            "carrierSealNumber": "OF074107",
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "DFSU3116410",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 2160,
            "bookingReference": "NPL0105940",
            "shipperSealNumber": null,
            "carrierSealNumber": "H3191070",
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "DTOU0006432",
            "portOfLoading": {
              "name": "Papeete",
              "locationUnCode": "PFPPT",
              "countryUnCode": "PF",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-07-28T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22T0",
              "name": "20'x8'x8'6\" Tank Container Test pressure 0,45 bar"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 3830,
            "bookingReference": "PPT0113576",
            "shipperSealNumber": null,
            "carrierSealNumber": "NA",
            "customsSealNumber": null,
            "shippersOwned": true,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "DTOU0023527",
            "portOfLoading": {
              "name": "Papeete",
              "locationUnCode": "PFPPT",
              "countryUnCode": "PF",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-07-28T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22T0",
              "name": "20'x8'x8'6\" Tank Container Test pressure 0,45 bar"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 3980,
            "bookingReference": "PPT0113576",
            "shipperSealNumber": null,
            "carrierSealNumber": "NA",
            "customsSealNumber": null,
            "shippersOwned": true,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "DTOU0059068",
            "portOfLoading": {
              "name": "Nouméa",
              "locationUnCode": "NCNOU",
              "countryUnCode": "NC",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-08-04T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22T0",
              "name": "20'x8'x8'6\" Tank Container Test pressure 0,45 bar"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 3650,
            "bookingReference": "NMA0110062",
            "shipperSealNumber": null,
            "carrierSealNumber": "NA",
            "customsSealNumber": null,
            "shippersOwned": true,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "DTOU0059237",
            "portOfLoading": {
              "name": "Nouméa",
              "locationUnCode": "NCNOU",
              "countryUnCode": "NC",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-08-04T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22T0",
              "name": "20'x8'x8'6\" Tank Container Test pressure 0,45 bar"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 3850,
            "bookingReference": "NMA0110062",
            "shipperSealNumber": null,
            "carrierSealNumber": "NA",
            "customsSealNumber": null,
            "shippersOwned": true,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "DTOU0064486",
            "portOfLoading": {
              "name": "Nouméa",
              "locationUnCode": "NCNOU",
              "countryUnCode": "NC",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-08-04T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22T6",
              "name": "20'x8'x8'6\" Tank Container Test pressure 6 bar"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 3850,
            "bookingReference": "NMA0110062",
            "shipperSealNumber": null,
            "carrierSealNumber": "NA",
            "customsSealNumber": null,
            "shippersOwned": true,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "DTOU0067607",
            "portOfLoading": {
              "name": "Papeete",
              "locationUnCode": "PFPPT",
              "countryUnCode": "PF",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-07-28T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22T0",
              "name": "20'x8'x8'6\" Tank Container Test pressure 0,45 bar"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 3650,
            "bookingReference": "PPT0113576",
            "shipperSealNumber": null,
            "carrierSealNumber": "NA",
            "customsSealNumber": null,
            "shippersOwned": true,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "DTOU0069908",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22T0",
              "name": "20'x8'x8'6\" Tank Container Test pressure 0,45 bar"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 3650,
            "bookingReference": "PPT0112894",
            "shipperSealNumber": null,
            "carrierSealNumber": "NA",
            "customsSealNumber": null,
            "shippersOwned": true,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "DTOU0072183",
            "portOfLoading": {
              "name": "Nouméa",
              "locationUnCode": "NCNOU",
              "countryUnCode": "NC",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-08-04T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22T2",
              "name": "20'x8'x8'6\" Tank Container Test pressure 2,65 bar"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 3850,
            "bookingReference": "NMA0110062",
            "shipperSealNumber": null,
            "carrierSealNumber": "NA",
            "customsSealNumber": null,
            "shippersOwned": true,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "DTOU0076635",
            "portOfLoading": {
              "name": "Papeete",
              "locationUnCode": "PFPPT",
              "countryUnCode": "PF",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-07-28T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22T2",
              "name": "20'x8'x8'6\" Tank Container Test pressure 2,65 bar"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 3650,
            "bookingReference": "PPT0113576",
            "shipperSealNumber": null,
            "carrierSealNumber": "NA",
            "customsSealNumber": null,
            "shippersOwned": true,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "DTOU0082601",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22T0",
              "name": "20'x8'x8'6\" Tank Container Test pressure 0,45 bar"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 3650,
            "bookingReference": "PPT0112894",
            "shipperSealNumber": null,
            "carrierSealNumber": "NA",
            "customsSealNumber": null,
            "shippersOwned": true,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "DTOU0084142",
            "portOfLoading": {
              "name": "Nouméa",
              "locationUnCode": "NCNOU",
              "countryUnCode": "NC",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-08-04T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22T0",
              "name": "20'x8'x8'6\" Tank Container Test pressure 0,45 bar"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 3850,
            "bookingReference": "NMA0110062",
            "shipperSealNumber": null,
            "carrierSealNumber": "NA",
            "customsSealNumber": null,
            "shippersOwned": true,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "DTOU0084625",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22T0",
              "name": "20'x8'x8'6\" Tank Container Test pressure 0,45 bar"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 3650,
            "bookingReference": "PPT0112894",
            "shipperSealNumber": null,
            "carrierSealNumber": "NA",
            "customsSealNumber": null,
            "shippersOwned": true,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "DTOU0084918",
            "portOfLoading": {
              "name": "Nouméa",
              "locationUnCode": "NCNOU",
              "countryUnCode": "NC",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-08-04T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22T0",
              "name": "20'x8'x8'6\" Tank Container Test pressure 0,45 bar"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 3650,
            "bookingReference": "NMA0110062",
            "shipperSealNumber": null,
            "carrierSealNumber": "NA",
            "customsSealNumber": null,
            "shippersOwned": true,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "DTOU0095677",
            "portOfLoading": {
              "name": "Nouméa",
              "locationUnCode": "NCNOU",
              "countryUnCode": "NC",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-08-04T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22T0",
              "name": "20'x8'x8'6\" Tank Container Test pressure 0,45 bar"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 3650,
            "bookingReference": "NMA0110062",
            "shipperSealNumber": null,
            "carrierSealNumber": "NA",
            "customsSealNumber": null,
            "shippersOwned": true,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "DTOU0097746",
            "portOfLoading": {
              "name": "Nouméa",
              "locationUnCode": "NCNOU",
              "countryUnCode": "NC",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-08-04T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22T0",
              "name": "20'x8'x8'6\" Tank Container Test pressure 0,45 bar"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 3850,
            "bookingReference": "NMA0110062",
            "shipperSealNumber": null,
            "carrierSealNumber": "NA",
            "customsSealNumber": null,
            "shippersOwned": true,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "DTOU0099559",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22T0",
              "name": "20'x8'x8'6\" Tank Container Test pressure 0,45 bar"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 3420,
            "bookingReference": "NMA0110054",
            "shipperSealNumber": null,
            "carrierSealNumber": "TANK",
            "customsSealNumber": null,
            "shippersOwned": true,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "ECMU9516720",
            "portOfLoading": {
              "name": "Tauranga",
              "locationUnCode": "NZTRG",
              "countryUnCode": "NZ",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-08-20T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 3900,
            "bookingReference": "AKD0269033",
            "shipperSealNumber": null,
            "carrierSealNumber": "NZ301283",
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "FCIU2736053",
            "portOfLoading": {
              "name": "Nouméa",
              "locationUnCode": "NCNOU",
              "countryUnCode": "NC",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-08-04T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 2220,
            "bookingReference": "NMA0110061",
            "shipperSealNumber": null,
            "carrierSealNumber": "CEA835238",
            "customsSealNumber": null,
            "shippersOwned": true,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "FCIU3214963",
            "portOfLoading": {
              "name": "Nouméa",
              "locationUnCode": "NCNOU",
              "countryUnCode": "NC",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-08-04T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 2220,
            "bookingReference": "NMA0110071",
            "shipperSealNumber": null,
            "carrierSealNumber": "NA",
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "FCIU3264943",
            "portOfLoading": {
              "name": "Nouméa",
              "locationUnCode": "NCNOU",
              "countryUnCode": "NC",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-08-04T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 2220,
            "bookingReference": "NMA0110071",
            "shipperSealNumber": null,
            "carrierSealNumber": "NA",
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "FCIU3426896",
            "portOfLoading": {
              "name": "Nouméa",
              "locationUnCode": "NCNOU",
              "countryUnCode": "NC",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-08-04T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 2220,
            "bookingReference": "NMA0110071",
            "shipperSealNumber": null,
            "carrierSealNumber": "NA",
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "FCIU3752151",
            "portOfLoading": {
              "name": "Nouméa",
              "locationUnCode": "NCNOU",
              "countryUnCode": "NC",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-08-04T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 2220,
            "bookingReference": "NMA0110071",
            "shipperSealNumber": null,
            "carrierSealNumber": "NA",
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "FCIU4635566",
            "portOfLoading": {
              "name": "Nouméa",
              "locationUnCode": "NCNOU",
              "countryUnCode": "NC",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-08-04T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 2180,
            "bookingReference": "NMA0110071",
            "shipperSealNumber": null,
            "carrierSealNumber": "NA",
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "FSCU7138984",
            "portOfLoading": {
              "name": "Tauranga",
              "locationUnCode": "NZTRG",
              "countryUnCode": "NZ",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-08-20T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 3700,
            "bookingReference": "AKD0269982",
            "shipperSealNumber": null,
            "carrierSealNumber": "NZ299878",
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "FSCU7144544",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 3700,
            "bookingReference": "AKD0268680",
            "shipperSealNumber": null,
            "carrierSealNumber": "NZ301200",
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "GLDU3525518",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 2185,
            "bookingReference": "AKD0269746",
            "shipperSealNumber": null,
            "carrierSealNumber": "W059090",
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "HJMU2110191",
            "portOfLoading": {
              "name": "Nouméa",
              "locationUnCode": "NCNOU",
              "countryUnCode": "NC",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-08-04T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G0",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 2240,
            "bookingReference": "NMA0110071",
            "shipperSealNumber": null,
            "carrierSealNumber": "NA",
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "LBOU0112905",
            "portOfLoading": {
              "name": "Nouméa",
              "locationUnCode": "NCNOU",
              "countryUnCode": "NC",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-08-04T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "25K1",
              "name": "20'x8'x>8'6\" Bulk container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 4030,
            "bookingReference": "NMA0110062",
            "shipperSealNumber": null,
            "carrierSealNumber": "NA",
            "customsSealNumber": null,
            "shippersOwned": true,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "LBOU0114235",
            "portOfLoading": {
              "name": "Nouméa",
              "locationUnCode": "NCNOU",
              "countryUnCode": "NC",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-08-04T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "25K1",
              "name": "20'x8'x>8'6\" Bulk container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 4030,
            "bookingReference": "NMA0110062",
            "shipperSealNumber": null,
            "carrierSealNumber": "NA",
            "customsSealNumber": null,
            "shippersOwned": true,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "LBOU0120095",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "25T0",
              "name": "25T0"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 3420,
            "bookingReference": "NMA0110054",
            "shipperSealNumber": null,
            "carrierSealNumber": "TANK",
            "customsSealNumber": null,
            "shippersOwned": true,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "LBOU0120475",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "25T0",
              "name": "25T0"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 3420,
            "bookingReference": "NMA0110054",
            "shipperSealNumber": null,
            "carrierSealNumber": "TANK",
            "customsSealNumber": null,
            "shippersOwned": true,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "LCRU2024025",
            "portOfLoading": {
              "name": "Nouméa",
              "locationUnCode": "NCNOU",
              "countryUnCode": "NC",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-08-04T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G0",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 2180,
            "bookingReference": "NMA0110061",
            "shipperSealNumber": null,
            "carrierSealNumber": "CEA1026695",
            "customsSealNumber": null,
            "shippersOwned": true,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "MAGU2273884",
            "portOfLoading": {
              "name": "Nouméa",
              "locationUnCode": "NCNOU",
              "countryUnCode": "NC",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-08-04T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 2130,
            "bookingReference": "NMA0110071",
            "shipperSealNumber": null,
            "carrierSealNumber": "NA",
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "SEGU1669073",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G0",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 2180,
            "bookingReference": "AKD0269534",
            "shipperSealNumber": null,
            "carrierSealNumber": "2MF88837",
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "SEGU1750821",
            "portOfLoading": {
              "name": "Nouméa",
              "locationUnCode": "NCNOU",
              "countryUnCode": "NC",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-08-04T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 2180,
            "bookingReference": "NMA0110071",
            "shipperSealNumber": null,
            "carrierSealNumber": "NA",
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "SEGU4863251",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 3830,
            "bookingReference": "AKD0268667",
            "shipperSealNumber": null,
            "carrierSealNumber": "NZ314478",
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "SEGU4903328",
            "portOfLoading": {
              "name": "Kingston",
              "locationUnCode": "JMKIN",
              "countryUnCode": "JM",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-11T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 3830,
            "bookingReference": "PSP0163519",
            "shipperSealNumber": null,
            "carrierSealNumber": "H1146464",
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "SEGU6358449",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G0",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 3700,
            "bookingReference": "AKD0268823",
            "shipperSealNumber": null,
            "carrierSealNumber": "3MF12610",
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "SEKU4317698",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 3700,
            "bookingReference": "AKD0268823",
            "shipperSealNumber": null,
            "carrierSealNumber": "3MF12502",
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "SEKU4336018",
            "portOfLoading": {
              "name": "Tauranga",
              "locationUnCode": "NZTRG",
              "countryUnCode": "NZ",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-08-20T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 3700,
            "bookingReference": "AKD0268492",
            "shipperSealNumber": null,
            "carrierSealNumber": "NZMPI01242577",
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TCKU1065943",
            "portOfLoading": {
              "name": "Nouméa",
              "locationUnCode": "NCNOU",
              "countryUnCode": "NC",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-08-04T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 2100,
            "bookingReference": "NMA0110071",
            "shipperSealNumber": null,
            "carrierSealNumber": "NA",
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TCKU1075005",
            "portOfLoading": {
              "name": "Nouméa",
              "locationUnCode": "NCNOU",
              "countryUnCode": "NC",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-08-04T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 2100,
            "bookingReference": "NMA0110071",
            "shipperSealNumber": null,
            "carrierSealNumber": "NA",
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TCLU1523848",
            "portOfLoading": {
              "name": "Tauranga",
              "locationUnCode": "NZTRG",
              "countryUnCode": "NZ",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-08-20T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 3840,
            "bookingReference": "AKD0269033",
            "shipperSealNumber": null,
            "carrierSealNumber": "NZ301293",
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TCLU2474563",
            "portOfLoading": {
              "name": "Nouméa",
              "locationUnCode": "NCNOU",
              "countryUnCode": "NC",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-08-04T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 2200,
            "bookingReference": "NMA0110071",
            "shipperSealNumber": null,
            "carrierSealNumber": "NA",
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TCLU3722089",
            "portOfLoading": {
              "name": "Nouméa",
              "locationUnCode": "NCNOU",
              "countryUnCode": "NC",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-08-04T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 2200,
            "bookingReference": "NMA0110071",
            "shipperSealNumber": null,
            "carrierSealNumber": "NA",
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TCLU3941929",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 2200,
            "bookingReference": "AKD0269163",
            "shipperSealNumber": null,
            "carrierSealNumber": "W053817",
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TCLU5161773",
            "portOfLoading": {
              "name": "Kingston",
              "locationUnCode": "JMKIN",
              "countryUnCode": "JM",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-11T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 3840,
            "bookingReference": "HAT0101312",
            "shipperSealNumber": null,
            "carrierSealNumber": "G7422020",
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TCLU6358480",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 3840,
            "bookingReference": "AKD0268680",
            "shipperSealNumber": null,
            "carrierSealNumber": "NZ301191",
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TCNU2383476",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 3700,
            "bookingReference": "AKD0268680",
            "shipperSealNumber": null,
            "carrierSealNumber": "NZ301158",
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TCNU2628368",
            "portOfLoading": {
              "name": "Tauranga",
              "locationUnCode": "NZTRG",
              "countryUnCode": "NZ",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-08-20T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G0",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 3700,
            "bookingReference": "AKD0269864",
            "shipperSealNumber": null,
            "carrierSealNumber": "W057324",
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TCNU4229177",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 3900,
            "bookingReference": "AKD0268023",
            "shipperSealNumber": null,
            "carrierSealNumber": "0039634",
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TCNU4827820",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 3900,
            "bookingReference": "AKD0268680",
            "shipperSealNumber": null,
            "carrierSealNumber": "NZ301154",
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TCNU5147371",
            "portOfLoading": {
              "name": "Tauranga",
              "locationUnCode": "NZTRG",
              "countryUnCode": "NZ",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-08-20T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 3840,
            "bookingReference": "AKD0269191",
            "shipperSealNumber": null,
            "carrierSealNumber": "NZ311068",
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TCNU5361892",
            "portOfLoading": {
              "name": "Tauranga",
              "locationUnCode": "NZTRG",
              "countryUnCode": "NZ",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-08-20T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 3900,
            "bookingReference": "AKD0268683",
            "shipperSealNumber": null,
            "carrierSealNumber": "NZ301205",
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TCNU7544660",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 3810,
            "bookingReference": "AKD0268680",
            "shipperSealNumber": null,
            "carrierSealNumber": "NZ301160",
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TCNU8563990",
            "portOfLoading": {
              "name": "Tauranga",
              "locationUnCode": "NZTRG",
              "countryUnCode": "NZ",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-08-20T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 3950,
            "bookingReference": "AKD0269033",
            "shipperSealNumber": null,
            "carrierSealNumber": "NZ301284",
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TEMU0035867",
            "portOfLoading": {
              "name": "Nouméa",
              "locationUnCode": "NCNOU",
              "countryUnCode": "NC",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-08-04T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 2180,
            "bookingReference": "NMA0110071",
            "shipperSealNumber": null,
            "carrierSealNumber": "NA",
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TEMU0992508",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G0",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 2100,
            "bookingReference": "AKD0269719",
            "shipperSealNumber": null,
            "carrierSealNumber": "W059096",
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TEMU1022416",
            "portOfLoading": {
              "name": "Nouméa",
              "locationUnCode": "NCNOU",
              "countryUnCode": "NC",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-08-04T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 2100,
            "bookingReference": "NMA0110071",
            "shipperSealNumber": null,
            "carrierSealNumber": "NA",
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TEMU2264815",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G0",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 2200,
            "bookingReference": "AKD0269719",
            "shipperSealNumber": null,
            "carrierSealNumber": "W059084",
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TEMU2385397",
            "portOfLoading": {
              "name": "Nouméa",
              "locationUnCode": "NCNOU",
              "countryUnCode": "NC",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-08-04T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G0",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 2200,
            "bookingReference": "NMA0110071",
            "shipperSealNumber": null,
            "carrierSealNumber": "NA",
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TEMU2406226",
            "portOfLoading": {
              "name": "Nouméa",
              "locationUnCode": "NCNOU",
              "countryUnCode": "NC",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-08-04T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 2200,
            "bookingReference": "NMA0110071",
            "shipperSealNumber": null,
            "carrierSealNumber": "NA",
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TEMU2523454",
            "portOfLoading": {
              "name": "Nouméa",
              "locationUnCode": "NCNOU",
              "countryUnCode": "NC",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-08-04T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 2200,
            "bookingReference": "NMA0110071",
            "shipperSealNumber": null,
            "carrierSealNumber": "NA",
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TEMU2552750",
            "portOfLoading": {
              "name": "Nouméa",
              "locationUnCode": "NCNOU",
              "countryUnCode": "NC",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-08-04T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 2200,
            "bookingReference": "NMA0110071",
            "shipperSealNumber": null,
            "carrierSealNumber": "NA",
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TEMU2855269",
            "portOfLoading": {
              "name": "Nouméa",
              "locationUnCode": "NCNOU",
              "countryUnCode": "NC",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-08-04T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 2200,
            "bookingReference": "NMA0110071",
            "shipperSealNumber": null,
            "carrierSealNumber": "NA",
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TEMU3311022",
            "portOfLoading": {
              "name": "Nouméa",
              "locationUnCode": "NCNOU",
              "countryUnCode": "NC",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-08-04T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 2200,
            "bookingReference": "NMA0110071",
            "shipperSealNumber": null,
            "carrierSealNumber": "NA",
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TEMU4245434",
            "portOfLoading": {
              "name": "Nouméa",
              "locationUnCode": "NCNOU",
              "countryUnCode": "NC",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-08-04T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 2180,
            "bookingReference": "NMA0110071",
            "shipperSealNumber": null,
            "carrierSealNumber": "NA",
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TGBU2170212",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 2100,
            "bookingReference": "AKD0268014",
            "shipperSealNumber": null,
            "carrierSealNumber": "W059005",
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TGCU2064132",
            "portOfLoading": {
              "name": "Nouméa",
              "locationUnCode": "NCNOU",
              "countryUnCode": "NC",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-08-04T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 2130,
            "bookingReference": "NMA0110071",
            "shipperSealNumber": null,
            "carrierSealNumber": "NA",
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TGHU1694650",
            "portOfLoading": {
              "name": "Nouméa",
              "locationUnCode": "NCNOU",
              "countryUnCode": "NC",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-08-04T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 2200,
            "bookingReference": "NMA0110071",
            "shipperSealNumber": null,
            "carrierSealNumber": "NA",
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TGHU8650870",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 3910,
            "bookingReference": "AKD0268680",
            "shipperSealNumber": null,
            "carrierSealNumber": "NZ301214",
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TLLU2756061",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G0",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 2100,
            "bookingReference": "AKD0268048",
            "shipperSealNumber": null,
            "carrierSealNumber": "W059006",
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TLLU2760082",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G0",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 2100,
            "bookingReference": "AKD0268229",
            "shipperSealNumber": null,
            "carrierSealNumber": "W058961",
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TLLU2767615",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G0",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 2100,
            "bookingReference": "AKD0269719",
            "shipperSealNumber": null,
            "carrierSealNumber": "W059086",
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TLLU2770795",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G0",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 2100,
            "bookingReference": "AKD0268229",
            "shipperSealNumber": null,
            "carrierSealNumber": "W058967",
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TLLU3229300",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 2100,
            "bookingReference": "AKD0269719",
            "shipperSealNumber": null,
            "carrierSealNumber": "W059017",
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TLLU4241316",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G0",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 3700,
            "bookingReference": "AKD0266831",
            "shipperSealNumber": null,
            "carrierSealNumber": "SAL832541",
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TLLU4352794",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G0",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 3700,
            "bookingReference": "AKD0268811",
            "shipperSealNumber": null,
            "carrierSealNumber": "B031089",
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TRHU1053642",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 2100,
            "bookingReference": "AKD0268015",
            "shipperSealNumber": null,
            "carrierSealNumber": "W059007",
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TRHU1071013",
            "portOfLoading": {
              "name": "Nouméa",
              "locationUnCode": "NCNOU",
              "countryUnCode": "NC",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-08-04T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 2100,
            "bookingReference": "NMA0110071",
            "shipperSealNumber": null,
            "carrierSealNumber": "NA",
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TRHU1083971",
            "portOfLoading": {
              "name": "Nouméa",
              "locationUnCode": "NCNOU",
              "countryUnCode": "NC",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-08-04T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 2100,
            "bookingReference": "NMA0110071",
            "shipperSealNumber": null,
            "carrierSealNumber": "NA",
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TRHU3089623",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 2180,
            "bookingReference": "AKD0268229",
            "shipperSealNumber": null,
            "carrierSealNumber": "W056968",
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TRHU3865727",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 2100,
            "bookingReference": "AKD0268015",
            "shipperSealNumber": null,
            "carrierSealNumber": "W059027",
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TRHU6500975",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 3700,
            "bookingReference": "AKD0269794",
            "shipperSealNumber": null,
            "carrierSealNumber": "NZ299855",
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TRIU6625430",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22R1",
              "name": "20'x8'x8'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 3040,
            "bookingReference": "AKD0268708",
            "shipperSealNumber": null,
            "carrierSealNumber": "NZMPI01216425",
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TTNU1159356",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 2100,
            "bookingReference": "AKD0269163",
            "shipperSealNumber": null,
            "carrierSealNumber": "W053909",
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "XINU1282208",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": 2150,
            "bookingReference": "AKD0269163",
            "shipperSealNumber": null,
            "carrierSealNumber": "W054000",
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "BMOU9630300",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "BMOU9630337",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "BMOU9630949",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "BMOU9630980",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "CAIU5658362",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "CAIU5658969",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "CAIU5659040",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "CAIU5659733",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "CAIU5660020",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "CAIU5660230",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "CAIU5660288",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "CAIU5660420",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "CAIU5660781",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "CAIU5661509",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "CGMU5008178",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "CGMU5012141",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "CGMU5117008",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "CGMU5177579",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "CGMU5224963",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "CGMU5327869",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "CGMU5401760",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "CGMU5478119",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "CGMU5531070",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "CGMU6550727",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "CRSU6115620",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "CXRU1577517",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "SEGU9405138",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "SEGU9406047",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "SEGU9406448",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "SEGU9406704",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "SEGU9411906",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "SEGU9411932",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "SEGU9412348",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "SEGU9412380",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "SEGU9413302",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "SEGU9413323",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "SEGU9413508",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "SEGU9413703",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "SEGU9414675",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "SEGU9414736",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "SEGU9414741",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "SEGU9415265",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "SEGU9415326",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "SEGU9415460",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "SEGU9416240",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "SEGU9416404",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "SEGU9416560",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "SEGU9416615",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "SEGU9416641",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "SEGU9423086",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "SEGU9423316",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "SEKU9010404",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "SEKU9010549",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "SEKU9010976",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "SEKU9011144",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "SEKU9013851",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "SEKU9014159",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "SEKU9014708",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "SEKU9014781",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "SEKU9014930",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "SEKU9060391",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "SEKU9060405",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "SEKU9060473",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "SEKU9060806",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "SEKU9060935",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "SEKU9060982",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "SEKU9060998",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "SEKU9061124",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "SEKU9064545",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "SEKU9065156",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "SZLU9222721",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "SZLU9222742",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "SZLU9222871",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "SZLU9223667",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "SZLU9224149",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "SZLU9224220",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "SZLU9224405",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "SZLU9225037",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "SZLU9225171",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "SZLU9225269",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "SZLU9225485",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "SZLU9225649",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "SZLU9225736",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "SZLU9226917",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "SZLU9226959",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "SZLU9226970",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TEMU9573919",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TEMU9574345",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TEMU9575280",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TEMU9575763",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TEMU9576728",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TEMU9577088",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TEMU9577154",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TEMU9577318",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TEMU9577410",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TEMU9577489",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TEMU9577560",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TEMU9578269",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TEMU9578398",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TEMU9578654",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TEMU9579157",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TEMU9579882",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TEMU9580245",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TEMU9580271",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TGHU9941437",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TRIU8611040",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TRIU8691410",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TTNU8650783",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TTNU8662969",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TTNU8703815",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TTNU8950327",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TTNU8950477",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TTNU8950610",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TTNU8950775",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TTNU8950815",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TTNU8950836",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TTNU8951318",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TTNU8951581",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TTNU8951914",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TTNU8952470",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TTNU8952989",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TTNU8953630",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TTNU8953748",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TTNU8954106",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TTNU8954488",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TTNU8954678",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TTNU8954826",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TTNU8955479",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TTNU8956011",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TTNU8956562",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TTNU8956602",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TTNU8956789",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TTNU8956958",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TTNU8957358",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TTNU8958014",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TTNU8959279",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TTNU8960027",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TTNU8960942",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TTNU8961131",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TTNU8961255",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TTNU8961337",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TTNU8962035",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TTNU8962544",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TTNU8962863",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TTNU8962890",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TTNU8963330",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TTNU8963371",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TTNU8963751",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TTNU8963999",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TTNU8964090",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          }
        ],
        "consignments": [
          {
            "consignmentNumber": "CMDUAKD0266831",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "movementReferenceNumbers": [
              "21BEN0000013965014"
            ],
            "partShipment": false,
            "movementType": "PIER_TO_PIER",
            "customsStatus": null,
            "customsProcess": "SEA_IN_SEA_OUT",
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": {
              "rate": 1.1805,
              "currency": {
                "unCode": "USD",
                "description": "U.S.A.-dollar"
              }
            },
            "consignor": {
              "eoriNumber": null,
              "name": "JF HILLEBRAND NEW ZEALAND LIMITED",
              "address": "26-30 PROSFORD STREET",
              "zipCode": "2022",
              "city": "AUCKLAND",
              "countryCode": "NZ",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "JF HILLEBRAND SCANDINAVIA A/S",
              "address": "SMAKKEDALEN 4",
              "zipCode": "2820",
              "city": "GENTOFTE",
              "countryCode": "DK",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [
              {
                "eoriNumber": null,
                "name": "JF HILLEBRAND SCANDINAVIA A/S",
                "address": "SMAKKEDALEN 4",
                "zipCode": "2820",
                "city": "GENTOFTE",
                "countryCode": "DK",
                "authorised": null,
                "id": null,
                "ownerShortName": null
              }
            ],
            "placeOfOrigin": null,
            "placeOfDestination": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euLocation": false
            },
            "placeOfDelivery": "ROTTERDAM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "0RP9FS1MA",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "Wine of fresh grapes, including fortified wines ; grape must",
                "grossWeight": 13950,
                "numberOfOuterPackages": 1860,
                "outerPackageType": {
                  "code": "CS",
                  "name": "Case",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "2204",
                  "description": "Wine of fresh grapes, including fortified wines; grape must other than that of heading|2009"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "TLLU4241316",
                    "numberOfPackages": 1860,
                    "grossWeight": 13950
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "CMDUAKD0267890",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "movementReferenceNumbers": [
              "21BEN0000013964989"
            ],
            "partShipment": false,
            "movementType": "PIER_TO_PIER",
            "customsStatus": null,
            "customsProcess": "SEA_IN_SEA_OUT",
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": {
              "rate": 1.1805,
              "currency": {
                "unCode": "USD",
                "description": "U.S.A.-dollar"
              }
            },
            "consignor": {
              "eoriNumber": null,
              "name": "KUEHNE + NAGEL NZ LTD",
              "address": "126 MONTREAL STREET",
              "zipCode": ".",
              "city": "CHRISTCHURCH",
              "countryCode": "NZ",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "KUEHNE + NAGEL LIMITED",
              "address": "LAKEVIEW EAST",
              "zipCode": "DA2 6QE",
              "city": "DARTFORD",
              "countryCode": "GB",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [
              {
                "eoriNumber": null,
                "name": "KUEHNE + NAGEL LTD.",
                "address": "LAKEVIEW EAST",
                "zipCode": "DA2 6QE",
                "city": "DARTFORD",
                "countryCode": "GB",
                "authorised": null,
                "id": null,
                "ownerShortName": null
              }
            ],
            "placeOfOrigin": null,
            "placeOfDestination": {
              "name": "Liverpool",
              "locationUnCode": "GBLIV",
              "countryUnCode": "GB",
              "euLocation": false
            },
            "placeOfDelivery": "ROTTERDAM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "0RP9FS1MA",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "Wine of fresh grapes, including fortified wines ; grape must",
                "grossWeight": 24000,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "BG",
                  "name": "Bags",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "2204",
                  "description": "Wine of fresh grapes, including fortified wines; grape must other than that of heading|2009"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "CMAU3010901",
                    "numberOfPackages": 1,
                    "grossWeight": 24000
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "CMDUAKD0267916",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "movementReferenceNumbers": [
              "21BEN0000013964962"
            ],
            "partShipment": false,
            "movementType": "PIER_TO_PIER",
            "customsStatus": null,
            "customsProcess": "SEA_IN_SEA_OUT",
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": {
              "rate": 1.1805,
              "currency": {
                "unCode": "USD",
                "description": "U.S.A.-dollar"
              }
            },
            "consignor": {
              "eoriNumber": null,
              "name": "KUEHNE + NAGEL NZ LTD",
              "address": "126 MONTREAL STREET",
              "zipCode": ".",
              "city": "CHRISTCHURCH",
              "countryCode": "NZ",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "KUEHNE + NAGEL LIMITED",
              "address": "LAKEVIEW EAST",
              "zipCode": "DA2 6QE",
              "city": "DARTFORD",
              "countryCode": "GB",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [
              {
                "eoriNumber": null,
                "name": "KUEHNE + NAGEL LTD.",
                "address": "LAKEVIEW EAST",
                "zipCode": "DA2 6QE",
                "city": "DARTFORD",
                "countryCode": "GB",
                "authorised": null,
                "id": null,
                "ownerShortName": null
              }
            ],
            "placeOfOrigin": null,
            "placeOfDestination": {
              "name": "Liverpool",
              "locationUnCode": "GBLIV",
              "countryUnCode": "GB",
              "euLocation": false
            },
            "placeOfDelivery": "ROTTERDAM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "0RP9FS1MA",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "Wine of fresh grapes, including fortified wines ; grape must",
                "grossWeight": 24000,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "BG",
                  "name": "Bags",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "2204",
                  "description": "Wine of fresh grapes, including fortified wines; grape must other than that of heading|2009"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "CMAU3254153",
                    "numberOfPackages": 1,
                    "grossWeight": 24000
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 2,
                "description": "Wine of fresh grapes, including fortified wines ; grape must",
                "grossWeight": 24000,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "BG",
                  "name": "Bags",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "2204",
                  "description": "Wine of fresh grapes, including fortified wines; grape must other than that of heading|2009"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "CMAU0866155",
                    "numberOfPackages": 1,
                    "grossWeight": 24000
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "CMDUAKD0267922",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "movementReferenceNumbers": [
              "21BEN0000014145202"
            ],
            "partShipment": false,
            "movementType": "PIER_TO_DOOR",
            "customsStatus": null,
            "customsProcess": "SEA_IN_SEA_OUT",
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": {
              "rate": 1.1805,
              "currency": {
                "unCode": "USD",
                "description": "U.S.A.-dollar"
              }
            },
            "consignor": {
              "eoriNumber": null,
              "name": "KUEHNE + NAGEL NZ LTD",
              "address": "126 MONTREAL STREET",
              "zipCode": ".",
              "city": "CHRISTCHURCH",
              "countryCode": "NZ",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "KUEHNE + NAGEL LIMITED",
              "address": "LAKEVIEW EAST",
              "zipCode": "DA2 6QE",
              "city": "DARTFORD",
              "countryCode": "GB",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [
              {
                "eoriNumber": null,
                "name": "KUEHNE + NAGEL LTD.",
                "address": "LAKEVIEW EAST",
                "zipCode": "DA2 6QE",
                "city": "DARTFORD",
                "countryCode": "GB",
                "authorised": null,
                "id": null,
                "ownerShortName": null
              }
            ],
            "placeOfOrigin": null,
            "placeOfDestination": {
              "name": "Liverpool",
              "locationUnCode": "GBLIV",
              "countryUnCode": "GB",
              "euLocation": false
            },
            "placeOfDelivery": "ROTTERDAM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "0RP9FS1MA",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "Wine of fresh grapes, including fortified wines ; grape must",
                "grossWeight": 24000,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "BG",
                  "name": "Bags",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "2204",
                  "description": "Wine of fresh grapes, including fortified wines; grape must other than that of heading|2009"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "APZU2132730",
                    "numberOfPackages": 1,
                    "grossWeight": 24000
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "CMDUAKD0268014",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "movementReferenceNumbers": [
              "21BEN0000013964730"
            ],
            "partShipment": false,
            "movementType": "PIER_TO_PIER",
            "customsStatus": null,
            "customsProcess": "SEA_IN_SEA_OUT",
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": {
              "rate": 1.1805,
              "currency": {
                "unCode": "USD",
                "description": "U.S.A.-dollar"
              }
            },
            "consignor": {
              "eoriNumber": null,
              "name": "JF HILLEBRAND NEW ZEALAND LIMITED",
              "address": "26-30 PROSFORD STREET",
              "zipCode": "2022",
              "city": "AUCKLAND",
              "countryCode": "NZ",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "JF HILLEBRAND SCANDINAVIA A/S",
              "address": "SMAKKEDALEN 4",
              "zipCode": "2820",
              "city": "GENTOFTE",
              "countryCode": "DK",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [
              {
                "eoriNumber": null,
                "name": "JF HILLEBRAND SCANDINAVIA A/S",
                "address": "SMAKKEDALEN 4",
                "zipCode": "2820",
                "city": "GENTOFTE",
                "countryCode": "DK",
                "authorised": null,
                "id": null,
                "ownerShortName": null
              }
            ],
            "placeOfOrigin": null,
            "placeOfDestination": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euLocation": false
            },
            "placeOfDelivery": "ROTTERDAM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "0RP9FS1MA",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "Wine of fresh grapes, including fortified wines ; grape must",
                "grossWeight": 23793,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "2204",
                  "description": "Wine of fresh grapes, including fortified wines; grape must other than that of heading|2009"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "TGBU2170212",
                    "numberOfPackages": 1,
                    "grossWeight": 23793
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "CMDUAKD0268015",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "movementReferenceNumbers": [
              "21BEN0000013964855"
            ],
            "partShipment": false,
            "movementType": "PIER_TO_PIER",
            "customsStatus": null,
            "customsProcess": "SEA_IN_SEA_OUT",
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": {
              "rate": 1.1805,
              "currency": {
                "unCode": "USD",
                "description": "U.S.A.-dollar"
              }
            },
            "consignor": {
              "eoriNumber": null,
              "name": "JF HILLEBRAND NEW ZEALAND LIMITED",
              "address": "26-30 PROSFORD STREET",
              "zipCode": "2022",
              "city": "AUCKLAND",
              "countryCode": "NZ",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "TRANS OCEAN BULK LOGISTICS LIMITED",
              "address": "TRANS OCEAN HOUSE",
              "zipCode": "SO30 2UH",
              "city": "SOUTHAMPTON",
              "countryCode": "GB",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [
              {
                "eoriNumber": null,
                "name": "TRANS OCEAN BULK LOGISTICS LTD",
                "address": "TRANS OCEAN HOUSE",
                "zipCode": "SO30 2UH",
                "city": "SOUTHAMPTON",
                "countryCode": "GB",
                "authorised": null,
                "id": null,
                "ownerShortName": null
              }
            ],
            "placeOfOrigin": null,
            "placeOfDestination": {
              "name": "Teesport",
              "locationUnCode": "GBTEE",
              "countryUnCode": "GB",
              "euLocation": false
            },
            "placeOfDelivery": "ROTTERDAM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "0RP9FS1MA",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "Wine of fresh grapes, including fortified wines ; grape must",
                "grossWeight": 24001,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "2204",
                  "description": "Wine of fresh grapes, including fortified wines; grape must other than that of heading|2009"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "CMAU3254425",
                    "numberOfPackages": 1,
                    "grossWeight": 24001
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 2,
                "description": "Wine of fresh grapes, including fortified wines ; grape must",
                "grossWeight": 24003,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "2204",
                  "description": "Wine of fresh grapes, including fortified wines; grape must other than that of heading|2009"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "TRHU1053642",
                    "numberOfPackages": 1,
                    "grossWeight": 24003
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 3,
                "description": "Wine of fresh grapes, including fortified wines ; grape must",
                "grossWeight": 24004,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "2204",
                  "description": "Wine of fresh grapes, including fortified wines; grape must other than that of heading|2009"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "TRHU3865727",
                    "numberOfPackages": 1,
                    "grossWeight": 24004
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "CMDUAKD0268023",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "movementReferenceNumbers": [
              "21BEN0000013965121"
            ],
            "partShipment": false,
            "movementType": "PIER_TO_PIER",
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": {
              "rate": 1.1805,
              "currency": {
                "unCode": "USD",
                "description": "U.S.A.-dollar"
              }
            },
            "consignor": {
              "eoriNumber": null,
              "name": "JF HILLEBRAND NEW ZEALAND LIMITED",
              "address": "26-30 PROSFORD STREET",
              "zipCode": "2022",
              "city": "AUCKLAND",
              "countryCode": "NZ",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "JF HILLEBRAND SCANDINAVIA A/S",
              "address": "SMAKKEDALEN 4",
              "zipCode": "2820",
              "city": "GENTOFTE",
              "countryCode": "DK",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [
              {
                "eoriNumber": null,
                "name": "JF HILLEBRAND SCANDINAVIA A/S",
                "address": "SMAKKEDALEN 4",
                "zipCode": "2820",
                "city": "GENTOFTE",
                "countryCode": "DK",
                "authorised": null,
                "id": null,
                "ownerShortName": null
              }
            ],
            "placeOfOrigin": null,
            "placeOfDestination": {
              "name": "Moss",
              "locationUnCode": "NOMSS",
              "countryUnCode": "NO",
              "euLocation": false
            },
            "placeOfDelivery": "ROTTERDAM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "0RP9FS1MA",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "Wine of fresh grapes, including fortified wines ; grape must",
                "grossWeight": 19225,
                "numberOfOuterPackages": 1250,
                "outerPackageType": {
                  "code": "CS",
                  "name": "Case",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "2204",
                  "description": "Wine of fresh grapes, including fortified wines; grape must other than that of heading|2009"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "TCNU4229177",
                    "numberOfPackages": 1250,
                    "grossWeight": 19225
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "CMDUAKD0268044",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "movementReferenceNumbers": [
              "21BEN0000013964764"
            ],
            "partShipment": false,
            "movementType": "PIER_TO_PIER",
            "customsStatus": null,
            "customsProcess": "SEA_IN_SEA_OUT",
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": {
              "rate": 1.1805,
              "currency": {
                "unCode": "USD",
                "description": "U.S.A.-dollar"
              }
            },
            "consignor": {
              "eoriNumber": null,
              "name": "JF HILLEBRAND NEW ZEALAND LIMITED",
              "address": "26-30 PROSFORD STREET",
              "zipCode": "2022",
              "city": "AUCKLAND",
              "countryCode": "NZ",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "JF HILLEBRAND SCANDINAVIA A/S",
              "address": "SMAKKEDALEN 4",
              "zipCode": "2820",
              "city": "GENTOFTE",
              "countryCode": "DK",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [
              {
                "eoriNumber": null,
                "name": "JF HILLEBRAND SCANDINAVIA A/S",
                "address": "SMAKKEDALEN 4",
                "zipCode": "2820",
                "city": "GENTOFTE",
                "countryCode": "DK",
                "authorised": null,
                "id": null,
                "ownerShortName": null
              }
            ],
            "placeOfOrigin": null,
            "placeOfDestination": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euLocation": false
            },
            "placeOfDelivery": "ROTTERDAM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "0RP9FS1MA",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "Wine of fresh grapes, including fortified wines ; grape must",
                "grossWeight": 25178,
                "numberOfOuterPackages": 3468,
                "outerPackageType": {
                  "code": "CS",
                  "name": "Case",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "2204",
                  "description": "Wine of fresh grapes, including fortified wines; grape must other than that of heading|2009"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "AXIU1652252",
                    "numberOfPackages": 3468,
                    "grossWeight": 25178
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "CMDUAKD0268048",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "movementReferenceNumbers": [
              "21BEN0000013965113"
            ],
            "partShipment": false,
            "movementType": "PIER_TO_PIER",
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "JF HILLEBRAND NEW ZEALAND LIMITED",
              "address": "26-30 PROSFORD STREET",
              "zipCode": "2022",
              "city": "AUCKLAND",
              "countryCode": "NZ",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "JF HILLEBRAND SCANDINAVIA A/S",
              "address": "SMAKKEDALEN 4",
              "zipCode": "2820",
              "city": "GENTOFTE",
              "countryCode": "DK",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [
              {
                "eoriNumber": null,
                "name": "JF HILLEBRAND SCANDINAVIA A/S",
                "address": "SMAKKEDALEN 4",
                "zipCode": "2820",
                "city": "GENTOFTE",
                "countryCode": "DK",
                "authorised": null,
                "id": null,
                "ownerShortName": null
              }
            ],
            "placeOfOrigin": null,
            "placeOfDestination": {
              "name": "København",
              "locationUnCode": "DKCPH",
              "countryUnCode": "DK",
              "euLocation": true
            },
            "placeOfDelivery": "ROTTERDAM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "0RP9FS1MA",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "Wine of fresh grapes, including fortified wines ; grape must",
                "grossWeight": 24000,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "FE",
                  "name": "Flexitank",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "2204",
                  "description": "Wine of fresh grapes, including fortified wines; grape must other than that of heading|2009"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "TLLU2756061",
                    "numberOfPackages": 1,
                    "grossWeight": 24000
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "CMDUAKD0268229",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "movementReferenceNumbers": [
              "21BEN0000013964705"
            ],
            "partShipment": false,
            "movementType": "PIER_TO_PIER",
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": {
              "rate": 1.1805,
              "currency": {
                "unCode": "USD",
                "description": "U.S.A.-dollar"
              }
            },
            "consignor": {
              "eoriNumber": null,
              "name": "JF HILLEBRAND NEW ZEALAND LIMITED",
              "address": "26-30 PROSFORD STREET",
              "zipCode": "2022",
              "city": "AUCKLAND",
              "countryCode": "NZ",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "JF HILLEBRAND DEUTSCHLAND GMBH",
              "address": "CARL ZEISS STR 6",
              "zipCode": "55129",
              "city": "MAINZ",
              "countryCode": "DE",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [
              {
                "eoriNumber": null,
                "name": "JF HILLEBRAND DEUTSCHLAND GMBH",
                "address": "CARL ZEISS STR 6",
                "zipCode": "55129",
                "city": "MAINZ",
                "countryCode": "DE",
                "authorised": null,
                "id": null,
                "ownerShortName": null
              }
            ],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "ROTTERDAM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "0RP9FS1MA",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "Wine of fresh grapes, including fortified wines ; grape must",
                "grossWeight": 23820,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "2204",
                  "description": "Wine of fresh grapes, including fortified wines; grape must other than that of heading|2009"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "CMAU0993355",
                    "numberOfPackages": 1,
                    "grossWeight": 23820
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 2,
                "description": "Wine of fresh grapes, including fortified wines ; grape must",
                "grossWeight": 23931,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "2204",
                  "description": "Wine of fresh grapes, including fortified wines; grape must other than that of heading|2009"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "CMAU3146591",
                    "numberOfPackages": 1,
                    "grossWeight": 23931
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 3,
                "description": "Wine of fresh grapes, including fortified wines ; grape must",
                "grossWeight": 23951,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "2204",
                  "description": "Wine of fresh grapes, including fortified wines; grape must other than that of heading|2009"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "CMAU3199190",
                    "numberOfPackages": 1,
                    "grossWeight": 23951
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 4,
                "description": "Wine of fresh grapes, including fortified wines ; grape must",
                "grossWeight": 23901,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "2204",
                  "description": "Wine of fresh grapes, including fortified wines; grape must other than that of heading|2009"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "TLLU2760082",
                    "numberOfPackages": 1,
                    "grossWeight": 23901
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 5,
                "description": "Wine of fresh grapes, including fortified wines ; grape must",
                "grossWeight": 23961,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "2204",
                  "description": "Wine of fresh grapes, including fortified wines; grape must other than that of heading|2009"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "TLLU2770795",
                    "numberOfPackages": 1,
                    "grossWeight": 23961
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 6,
                "description": "Wine of fresh grapes, including fortified wines ; grape must",
                "grossWeight": 23840,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "2204",
                  "description": "Wine of fresh grapes, including fortified wines; grape must other than that of heading|2009"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "TRHU3089623",
                    "numberOfPackages": 1,
                    "grossWeight": 23840
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "CMDUAKD0268492",
            "portOfLoading": {
              "name": "Tauranga",
              "locationUnCode": "NZTRG",
              "countryUnCode": "NZ",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-08-20T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "movementReferenceNumbers": [
              "21BEN0000014835821"
            ],
            "partShipment": false,
            "movementType": "PIER_TO_PIER",
            "customsStatus": null,
            "customsProcess": "SEA_IN_SEA_OUT",
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "DAIRY GOAT CO OPERATIVE (NZ) LTD",
              "address": "18 GALLAGHER DRIVE",
              "zipCode": ".",
              "city": "HAMILTON",
              "countryCode": "NZ",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "OOO BIBICALL RUS",
              "address": "OLYMPIC AVE 29 BUILDING 2",
              "zipCode": "141006",
              "city": "MOSCOW",
              "countryCode": "RU",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [
              {
                "eoriNumber": null,
                "name": "GBR TRADE AND TECHNOLOGY",
                "address": "39A FROGNAL",
                "zipCode": "NW3 6YD",
                "city": "LONDON",
                "countryCode": "GB",
                "authorised": null,
                "id": null,
                "ownerShortName": null
              }
            ],
            "placeOfOrigin": null,
            "placeOfDestination": {
              "name": "Helsinki (Helsingfors)",
              "locationUnCode": "FIHEL",
              "countryUnCode": "FI",
              "euLocation": true
            },
            "placeOfDelivery": "ROTTERDAM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "0RP9FS1MA",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "Preparations suitable for infants or young children, put up",
                "grossWeight": 19524,
                "numberOfOuterPackages": 3149,
                "outerPackageType": {
                  "code": "CT",
                  "name": "Carton",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "1901",
                  "description": "Malt extract; food preparations of flour, groats, meal, starch or malt extract, not containing cocoa or containing less than 40 percent  by weight of cocoa calculated on a totally defatted basis, not elsewhere specified or included; food preparations of goods of headings|0401|to 0404, not containing cocoa or containing less than 5 percent  by weight of cocoa calculated on a totally defatted basis, not elsewhere specified or included"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "SEKU4336018",
                    "numberOfPackages": 3149,
                    "grossWeight": 19524
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "CMDUAKD0268626",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "movementReferenceNumbers": [
              "21BEN0000014145261"
            ],
            "partShipment": false,
            "movementType": "PIER_TO_DOOR",
            "customsStatus": null,
            "customsProcess": "SEA_IN_SEA_OUT",
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": {
              "rate": 1.1805,
              "currency": {
                "unCode": "USD",
                "description": "U.S.A.-dollar"
              }
            },
            "consignor": {
              "eoriNumber": null,
              "name": "ALLIED PICKFORDS",
              "address": "15C RAKINO WAY",
              "zipCode": "1060",
              "city": "MOUNT WELLINGTON",
              "countryCode": "NZ",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "JOHN MASON INTERNATIONAL LIMITED",
              "address": "35 WILSON ROAD",
              "zipCode": "L36 6AE",
              "city": "LIVERPOOL",
              "countryCode": "GB",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [
              {
                "eoriNumber": null,
                "name": "CHRISTOPHER EVANS",
                "address": "35 WILSON ROAD",
                "zipCode": "L36 6AE",
                "city": "LIVERPOOL",
                "countryCode": "GB",
                "authorised": null,
                "id": null,
                "ownerShortName": null
              }
            ],
            "placeOfOrigin": null,
            "placeOfDestination": {
              "name": "Felixstowe",
              "locationUnCode": "GBFXT",
              "countryUnCode": "GB",
              "euLocation": false
            },
            "placeOfDelivery": "ROTTERDAM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "0RP9FS1MA",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "PERSONAL PROPERTY BELONGING TO NATURAL PERSONS TRANSFERRING THEIR NORMAL PLACE OF RESIDENCE",
                "grossWeight": 3920,
                "numberOfOuterPackages": 207,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "CMAU2007420",
                    "numberOfPackages": 207,
                    "grossWeight": 3920
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "CMDUAKD0268637",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "movementReferenceNumbers": [
              "21BEN0000014183328"
            ],
            "partShipment": false,
            "movementType": "PIER_TO_PIER",
            "customsStatus": null,
            "customsProcess": "SEA_IN_SEA_OUT",
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": {
              "rate": 1.1805,
              "currency": {
                "unCode": "USD",
                "description": "U.S.A.-dollar"
              }
            },
            "consignor": {
              "eoriNumber": null,
              "name": "ALLIED PICKFORDS",
              "address": "43 SEAVIEW ROAD",
              "zipCode": ".",
              "city": "WELLINGTON",
              "countryCode": "NZ",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "PICKFORDS MOVE MANAGEMENT LTD",
              "address": "UNIT 10 PICKFORDS PARK LAXCON CLOSE",
              "zipCode": "NW10 0TG",
              "city": "LONDON",
              "countryCode": "GB",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [
              {
                "eoriNumber": null,
                "name": "PICKFORDS UK",
                "address": "UNIT 10 PICKFORDS PARK LAXCON CLOSE",
                "zipCode": "NW10 0TG",
                "city": "LONDON",
                "countryCode": "GB",
                "authorised": null,
                "id": null,
                "ownerShortName": null
              }
            ],
            "placeOfOrigin": null,
            "placeOfDestination": {
              "name": "Grangemouth",
              "locationUnCode": "GBGRG",
              "countryUnCode": "GB",
              "euLocation": false
            },
            "placeOfDelivery": "ROTTERDAM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "0RP9FS1MA",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "personal prop.blg to natural persons transfer their residenc",
                "grossWeight": 2650,
                "numberOfOuterPackages": 164,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "9905",
                  "description": "Certain goods, as provided for in Council Regulation (EC) No 1186/2009 (Import and Export), Personal property belonging to natural persons transferring their normal place of residence"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "CMAU1867794",
                    "numberOfPackages": 164,
                    "grossWeight": 2650
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "CMDUAKD0268647",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "movementReferenceNumbers": [
              "21BEN0000014108390"
            ],
            "partShipment": false,
            "movementType": "PIER_TO_PIER",
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": {
              "rate": 1.1805,
              "currency": {
                "unCode": "USD",
                "description": "U.S.A.-dollar"
              }
            },
            "consignor": {
              "eoriNumber": null,
              "name": "JF HILLEBRAND NEW ZEALAND LIMITED",
              "address": "26-30 PROSFORD STREET",
              "zipCode": "2022",
              "city": "AUCKLAND",
              "countryCode": "NZ",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "JF HILLEBRAND SWEDEN AB",
              "address": "ARMATURVAGEN 4",
              "zipCode": "136 50",
              "city": "JORDBRO",
              "countryCode": "SE",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [
              {
                "eoriNumber": null,
                "name": "JF HILLEBRAND SWEDEN AB",
                "address": "ARMATURVAGEN 4",
                "zipCode": "136 50",
                "city": "JORDBRO",
                "countryCode": "SE",
                "authorised": null,
                "id": null,
                "ownerShortName": null
              }
            ],
            "placeOfOrigin": null,
            "placeOfDestination": {
              "name": "Stockholm",
              "locationUnCode": "SESTO",
              "countryUnCode": "SE",
              "euLocation": true
            },
            "placeOfDelivery": "ROTTERDAM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "0RP9FS1MA",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "Wine of fresh grapes, including fortified wines ; grape must",
                "grossWeight": 7290,
                "numberOfOuterPackages": 900,
                "outerPackageType": {
                  "code": "CS",
                  "name": "Case",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "2204",
                  "description": "Wine of fresh grapes, including fortified wines; grape must other than that of heading|2009"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "CMAU2080951",
                    "numberOfPackages": 900,
                    "grossWeight": 7290
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "CMDUAKD0268650",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "movementReferenceNumbers": [
              "21BEN0000014108405"
            ],
            "partShipment": false,
            "movementType": "PIER_TO_PIER",
            "customsStatus": null,
            "customsProcess": "SEA_IN_SEA_OUT",
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": {
              "rate": 1.1805,
              "currency": {
                "unCode": "USD",
                "description": "U.S.A.-dollar"
              }
            },
            "consignor": {
              "eoriNumber": null,
              "name": "FISHER & PAYKEL HEALTHCARE LIMITED",
              "address": "15 MAURICE PAYKEL PLACE",
              "zipCode": "1701",
              "city": "EAST TAMAKI",
              "countryCode": "NZ",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "FISHER AND PAYKEL HEALTHCARE LLC",
              "address": "BAKHRUSHINA STREET BLOCK 1",
              "zipCode": "101000",
              "city": "MOSCOW",
              "countryCode": "RU",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [
              {
                "eoriNumber": null,
                "name": "FISHER AND PAYKEL HEALTHCARE LLC",
                "address": "BAKHRUSHINA STREET BLOCK 1",
                "zipCode": "101000",
                "city": "MOSCOW",
                "countryCode": "RU",
                "authorised": null,
                "id": null,
                "ownerShortName": null
              }
            ],
            "placeOfOrigin": null,
            "placeOfDestination": {
              "name": "Saint Petersburg (ex Leningrad)",
              "locationUnCode": "RULED",
              "countryUnCode": "RU",
              "euLocation": false
            },
            "placeOfDelivery": "ROTTERDAM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "0RP9FS1MA",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "MEDICAL APPLIANCES",
                "grossWeight": 2151,
                "numberOfOuterPackages": 163,
                "outerPackageType": {
                  "code": "CT",
                  "name": "Carton",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "APZU2126949",
                    "numberOfPackages": 163,
                    "grossWeight": 2151
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "CMDUAKD0268667",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "movementReferenceNumbers": [
              "21BEN0000014145162"
            ],
            "partShipment": false,
            "movementType": "PIER_TO_PIER",
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": {
              "rate": 1.1805,
              "currency": {
                "unCode": "USD",
                "description": "U.S.A.-dollar"
              }
            },
            "consignor": {
              "eoriNumber": null,
              "name": "JF HILLEBRAND NEW ZEALAND LIMITED",
              "address": "26-30 PROSFORD STREET",
              "zipCode": "2022",
              "city": "AUCKLAND",
              "countryCode": "NZ",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "JF HILLEBRAND BENELUX BV",
              "address": "MAASKADE 119",
              "zipCode": "3071 NK",
              "city": "ROTTERDAM",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [
              {
                "eoriNumber": null,
                "name": "JF HILLEBRAND BENELUX BV",
                "address": "MAASKADE 119",
                "zipCode": "3071 NK",
                "city": "ROTTERDAM",
                "countryCode": "NL",
                "authorised": null,
                "id": null,
                "ownerShortName": null
              }
            ],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "ROTTERDAM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "0RP9FS1MA",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "Wine of fresh grapes, including fortified wines ; grape must",
                "grossWeight": 22192,
                "numberOfOuterPackages": 3040,
                "outerPackageType": {
                  "code": "CS",
                  "name": "Case",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "2204",
                  "description": "Wine of fresh grapes, including fortified wines; grape must other than that of heading|2009"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "SEGU4863251",
                    "numberOfPackages": 3040,
                    "grossWeight": 22192
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "CMDUAKD0268680",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "movementReferenceNumbers": [
              "21BEN0000014108223"
            ],
            "partShipment": false,
            "movementType": "PIER_TO_PIER",
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "MAINFREIGHT AIR & OCEAN LIMITED",
              "address": "107 WESTNEY ROAD",
              "zipCode": "1071",
              "city": "AUCKLAND",
              "countryCode": "NZ",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "INTER NORDIC TRANSPORT",
              "address": "AXEL GRUHNS VEJ 10",
              "zipCode": "8270",
              "city": "HOJBJERG",
              "countryCode": "DK",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [
              {
                "eoriNumber": null,
                "name": "INTER NORDIC TRANSPORT",
                "address": "AXEL GRUHNS VEJ 10",
                "zipCode": "8270",
                "city": "HOJBJERG",
                "countryCode": "DK",
                "authorised": null,
                "id": null,
                "ownerShortName": null
              }
            ],
            "placeOfOrigin": null,
            "placeOfDestination": {
              "name": "København",
              "locationUnCode": "DKCPH",
              "countryUnCode": "DK",
              "euLocation": true
            },
            "placeOfDelivery": "ROTTERDAM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "0RP9FS1MA",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "Tableware and kitchenware, of plastics",
                "grossWeight": 6710,
                "numberOfOuterPackages": 213,
                "outerPackageType": {
                  "code": "CT",
                  "name": "Carton",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "3924",
                  "description": "Tableware, kitchenware, other household articles and hygienic or toilet articles, of plastics"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "CMAU7326586",
                    "numberOfPackages": 213,
                    "grossWeight": 6710
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 2,
                "description": "Tableware and kitchenware, of plastics",
                "grossWeight": 5751,
                "numberOfOuterPackages": 213,
                "outerPackageType": {
                  "code": "CT",
                  "name": "Carton",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "3924",
                  "description": "Tableware, kitchenware, other household articles and hygienic or toilet articles, of plastics"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "TCNU2383476",
                    "numberOfPackages": 213,
                    "grossWeight": 5751
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 3,
                "description": "Tableware and kitchenware, of plastics",
                "grossWeight": 6710,
                "numberOfOuterPackages": 213,
                "outerPackageType": {
                  "code": "CT",
                  "name": "Carton",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "3924",
                  "description": "Tableware, kitchenware, other household articles and hygienic or toilet articles, of plastics"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "TCNU7544660",
                    "numberOfPackages": 213,
                    "grossWeight": 6710
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 4,
                "description": "Tableware and kitchenware, of plastics",
                "grossWeight": 6710,
                "numberOfOuterPackages": 213,
                "outerPackageType": {
                  "code": "CT",
                  "name": "Carton",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "3924",
                  "description": "Tableware, kitchenware, other household articles and hygienic or toilet articles, of plastics"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "TCNU4827820",
                    "numberOfPackages": 213,
                    "grossWeight": 6710
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 5,
                "description": "Tableware and kitchenware, of plastics",
                "grossWeight": 6710,
                "numberOfOuterPackages": 213,
                "outerPackageType": {
                  "code": "CT",
                  "name": "Carton",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "3924",
                  "description": "Tableware, kitchenware, other household articles and hygienic or toilet articles, of plastics"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "TCLU6358480",
                    "numberOfPackages": 213,
                    "grossWeight": 6710
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 6,
                "description": "Tableware and kitchenware, of plastics",
                "grossWeight": 6710,
                "numberOfOuterPackages": 213,
                "outerPackageType": {
                  "code": "CT",
                  "name": "Carton",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "3924",
                  "description": "Tableware, kitchenware, other household articles and hygienic or toilet articles, of plastics"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "CMAU7487575",
                    "numberOfPackages": 213,
                    "grossWeight": 6710
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 7,
                "description": "Tableware and kitchenware, of plastics",
                "grossWeight": 6710,
                "numberOfOuterPackages": 213,
                "outerPackageType": {
                  "code": "CT",
                  "name": "Carton",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "3924",
                  "description": "Tableware, kitchenware, other household articles and hygienic or toilet articles, of plastics"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "FSCU7144544",
                    "numberOfPackages": 213,
                    "grossWeight": 6710
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 8,
                "description": "Tableware and kitchenware, of plastics",
                "grossWeight": 6047,
                "numberOfOuterPackages": 1042,
                "outerPackageType": {
                  "code": "CT",
                  "name": "Carton",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "3924",
                  "description": "Tableware, kitchenware, other household articles and hygienic or toilet articles, of plastics"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "TGHU8650870",
                    "numberOfPackages": 1042,
                    "grossWeight": 6047
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "CMDUAKD0268683",
            "portOfLoading": {
              "name": "Tauranga",
              "locationUnCode": "NZTRG",
              "countryUnCode": "NZ",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-08-20T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "movementReferenceNumbers": [
              "21BEN0000014835880"
            ],
            "partShipment": false,
            "movementType": "PIER_TO_PIER",
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "MAINFREIGHT AIR & OCEAN LIMITED",
              "address": "107 WESTNEY ROAD",
              "zipCode": "1071",
              "city": "AUCKLAND",
              "countryCode": "NZ",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "INTER NORDIC TRANSPORT",
              "address": "AXEL GRUHNS VEJ 10",
              "zipCode": "8270",
              "city": "HOJBJERG",
              "countryCode": "DK",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [
              {
                "eoriNumber": null,
                "name": "INTER NORDIC TRANSPORT",
                "address": "AXEL GRUHNS VEJ 10",
                "zipCode": "8270",
                "city": "HOJBJERG",
                "countryCode": "DK",
                "authorised": null,
                "id": null,
                "ownerShortName": null
              }
            ],
            "placeOfOrigin": null,
            "placeOfDestination": {
              "name": "København",
              "locationUnCode": "DKCPH",
              "countryUnCode": "DK",
              "euLocation": true
            },
            "placeOfDelivery": "ROTTERDAM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "0RP9FS1MA",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "Tableware and kitchenware, of plastics",
                "grossWeight": 5751,
                "numberOfOuterPackages": 213,
                "outerPackageType": {
                  "code": "CT",
                  "name": "Carton",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "3924",
                  "description": "Tableware, kitchenware, other household articles and hygienic or toilet articles, of plastics"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "APHU6358338",
                    "numberOfPackages": 213,
                    "grossWeight": 5751
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 2,
                "description": "Tableware and kitchenware, of plastics",
                "grossWeight": 5751,
                "numberOfOuterPackages": 213,
                "outerPackageType": {
                  "code": "CT",
                  "name": "Carton",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "3924",
                  "description": "Tableware, kitchenware, other household articles and hygienic or toilet articles, of plastics"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "APHU6768271",
                    "numberOfPackages": 213,
                    "grossWeight": 5751
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 3,
                "description": "Tableware and kitchenware, of plastics",
                "grossWeight": 5751,
                "numberOfOuterPackages": 213,
                "outerPackageType": {
                  "code": "CT",
                  "name": "Carton",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "3924",
                  "description": "Tableware, kitchenware, other household articles and hygienic or toilet articles, of plastics"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "CMAU4885050",
                    "numberOfPackages": 213,
                    "grossWeight": 5751
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 4,
                "description": "Tableware and kitchenware, of plastics",
                "grossWeight": 5751,
                "numberOfOuterPackages": 213,
                "outerPackageType": {
                  "code": "CT",
                  "name": "Carton",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "3924",
                  "description": "Tableware, kitchenware, other household articles and hygienic or toilet articles, of plastics"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "TCNU5361892",
                    "numberOfPackages": 213,
                    "grossWeight": 5751
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "CMDUAKD0268708",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "movementReferenceNumbers": [
              "21BEN0000013965090"
            ],
            "partShipment": false,
            "movementType": "PIER_TO_PIER",
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": {
              "rate": 1.1805,
              "currency": {
                "unCode": "USD",
                "description": "U.S.A.-dollar"
              }
            },
            "consignor": {
              "eoriNumber": null,
              "name": "ANZCO FOODS LIMITED",
              "address": "5 ROBIN MANN PLACE",
              "zipCode": "8053",
              "city": "CHRISTCHURCH",
              "countryCode": "NZ",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "ANZCO FOODS EU BV",
              "address": "MARKT 32",
              "zipCode": "9700",
              "city": "OUDENAARDE",
              "countryCode": "BE",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [
              {
                "eoriNumber": null,
                "name": "ANZCO FOODS EU BV",
                "address": "MARKT 32",
                "zipCode": "9700",
                "city": "OUDENAARDE",
                "countryCode": "BE",
                "authorised": null,
                "id": null,
                "ownerShortName": null
              }
            ],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "ROTTERDAM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "0RP9FS1MA",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "BEEF & LAMB",
                "grossWeight": 12184,
                "numberOfOuterPackages": 965,
                "outerPackageType": {
                  "code": "CT",
                  "name": "Carton",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "TRIU6625430",
                    "numberOfPackages": 965,
                    "grossWeight": 12184
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "CMDUAKD0268811",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "movementReferenceNumbers": [
              "21BEN0000013965148"
            ],
            "partShipment": false,
            "movementType": "PIER_TO_PIER",
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": {
              "rate": 1.1805,
              "currency": {
                "unCode": "USD",
                "description": "U.S.A.-dollar"
              }
            },
            "consignor": {
              "eoriNumber": null,
              "name": "JF HILLEBRAND NEW ZEALAND LIMITED",
              "address": "26-30 PROSFORD STREET",
              "zipCode": "2022",
              "city": "AUCKLAND",
              "countryCode": "NZ",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "JF HILLEBRAND BENELUX BV",
              "address": "MAASKADE 119",
              "zipCode": "3071 NK",
              "city": "ROTTERDAM",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [
              {
                "eoriNumber": null,
                "name": "JF HILLEBRAND BENELUX BV",
                "address": "MAASKADE 119",
                "zipCode": "3071 NK",
                "city": "ROTTERDAM",
                "countryCode": "NL",
                "authorised": null,
                "id": null,
                "ownerShortName": null
              }
            ],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "ROTTERDAM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "0RP9FS1MA",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "Wine of fresh grapes, including fortified wines ; grape must",
                "grossWeight": 22569,
                "numberOfOuterPackages": 3080,
                "outerPackageType": {
                  "code": "CS",
                  "name": "Case",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "2204",
                  "description": "Wine of fresh grapes, including fortified wines; grape must other than that of heading|2009"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "TLLU4352794",
                    "numberOfPackages": 3080,
                    "grossWeight": 22569
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "CMDUAKD0268821",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "movementReferenceNumbers": [
              "21BEN0000013964903"
            ],
            "partShipment": false,
            "movementType": "PIER_TO_PIER",
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": {
              "rate": 1.1805,
              "currency": {
                "unCode": "USD",
                "description": "U.S.A.-dollar"
              }
            },
            "consignor": {
              "eoriNumber": null,
              "name": "JF HILLEBRAND NEW ZEALAND LIMITED",
              "address": "26-30 PROSFORD STREET",
              "zipCode": "2022",
              "city": "AUCKLAND",
              "countryCode": "NZ",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "JF HILLEBRAND BENELUX BV",
              "address": "MAASKADE 119",
              "zipCode": "3071 NK",
              "city": "ROTTERDAM",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [
              {
                "eoriNumber": null,
                "name": "JF HILLEBRAND BENELUX BV",
                "address": "MAASKADE 119",
                "zipCode": "3071 NK",
                "city": "ROTTERDAM",
                "countryCode": "NL",
                "authorised": null,
                "id": null,
                "ownerShortName": null
              }
            ],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "ROTTERDAM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "0RP9FS1MA",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "Wine of fresh grapes, including fortified wines ; grape must",
                "grossWeight": 11200,
                "numberOfOuterPackages": 1400,
                "outerPackageType": {
                  "code": "CS",
                  "name": "Case",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "2204",
                  "description": "Wine of fresh grapes, including fortified wines; grape must other than that of heading|2009"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "CMAU4685772",
                    "numberOfPackages": 1400,
                    "grossWeight": 11200
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 2,
                "description": "Wine of fresh grapes, including fortified wines ; grape must",
                "grossWeight": 10439,
                "numberOfOuterPackages": 1368,
                "outerPackageType": {
                  "code": "CS",
                  "name": "Case",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "2204",
                  "description": "Wine of fresh grapes, including fortified wines; grape must other than that of heading|2009"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "CMAU4685772",
                    "numberOfPackages": 1368,
                    "grossWeight": 10439
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "CMDUAKD0268823",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "movementReferenceNumbers": [
              "21BEN0000013964780"
            ],
            "partShipment": false,
            "movementType": "PIER_TO_PIER",
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": {
              "rate": 1.1805,
              "currency": {
                "unCode": "USD",
                "description": "U.S.A.-dollar"
              }
            },
            "consignor": {
              "eoriNumber": null,
              "name": "JF HILLEBRAND NEW ZEALAND LIMITED",
              "address": "26-30 PROSFORD STREET",
              "zipCode": "2022",
              "city": "AUCKLAND",
              "countryCode": "NZ",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "JF HILLEBRAND BENELUX BV",
              "address": "MAASKADE 119",
              "zipCode": "3071 NK",
              "city": "ROTTERDAM",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [
              {
                "eoriNumber": null,
                "name": "JF HILLEBRAND BENELUX BV",
                "address": "MAASKADE 119",
                "zipCode": "3071 NK",
                "city": "ROTTERDAM",
                "countryCode": "NL",
                "authorised": null,
                "id": null,
                "ownerShortName": null
              }
            ],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "ROTTERDAM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "0RP9FS1MA",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "Wine of fresh grapes, including fortified wines ; grape must",
                "grossWeight": 13440,
                "numberOfOuterPackages": 1680,
                "outerPackageType": {
                  "code": "CS",
                  "name": "Case",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "2204",
                  "description": "Wine of fresh grapes, including fortified wines; grape must other than that of heading|2009"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "SEGU6358449",
                    "numberOfPackages": 1680,
                    "grossWeight": 13440
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 2,
                "description": "Wine of fresh grapes, including fortified wines ; grape must",
                "grossWeight": 11200,
                "numberOfOuterPackages": 1400,
                "outerPackageType": {
                  "code": "CS",
                  "name": "Case",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "2204",
                  "description": "Wine of fresh grapes, including fortified wines; grape must other than that of heading|2009"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "SEGU6358449",
                    "numberOfPackages": 1400,
                    "grossWeight": 11200
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 3,
                "description": "Wine of fresh grapes, including fortified wines ; grape must",
                "grossWeight": 24640,
                "numberOfOuterPackages": 3080,
                "outerPackageType": {
                  "code": "CS",
                  "name": "Case",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "2204",
                  "description": "Wine of fresh grapes, including fortified wines; grape must other than that of heading|2009"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "SEKU4317698",
                    "numberOfPackages": 3080,
                    "grossWeight": 24640
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "CMDUAKD0268831",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "movementReferenceNumbers": [
              "21BEN0000013965065"
            ],
            "partShipment": false,
            "movementType": "PIER_TO_PIER",
            "customsStatus": null,
            "customsProcess": "SEA_IN_SEA_OUT",
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": {
              "rate": 1.1805,
              "currency": {
                "unCode": "USD",
                "description": "U.S.A.-dollar"
              }
            },
            "consignor": {
              "eoriNumber": null,
              "name": "SISTEMA PLASTICS LTD",
              "address": "15 TE TIKI ROAD",
              "zipCode": "2022",
              "city": "MANGERE",
              "countryCode": "NZ",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "LTD VECTRA HOUSHOLD",
              "address": "PROEZD SEREBRYAKOVA D14 STR 1",
              "zipCode": "129343",
              "city": "MOSCOW",
              "countryCode": "RU",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [
              {
                "eoriNumber": null,
                "name": "LLC \"AVTOKHIMTRANS\"",
                "address": "KULTURY PROSPECT 44",
                "zipCode": "190000",
                "city": "PETERSBURG",
                "countryCode": "RU",
                "authorised": null,
                "id": null,
                "ownerShortName": null
              }
            ],
            "placeOfOrigin": null,
            "placeOfDestination": {
              "name": "Saint Petersburg (ex Leningrad)",
              "locationUnCode": "RULED",
              "countryUnCode": "RU",
              "euLocation": false
            },
            "placeOfDelivery": "ROTTERDAM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "0RP9FS1MA",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "Tableware and kitchenware, of plastics",
                "grossWeight": 6170,
                "numberOfOuterPackages": 8089,
                "outerPackageType": {
                  "code": "CT",
                  "name": "Carton",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "3924",
                  "description": "Tableware, kitchenware, other household articles and hygienic or toilet articles, of plastics"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "CMAU7361252",
                    "numberOfPackages": 8089,
                    "grossWeight": 6170
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "CMDUAKD0268929",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "movementReferenceNumbers": [
              "21BEN0000013964820"
            ],
            "partShipment": false,
            "movementType": "PIER_TO_PIER",
            "customsStatus": null,
            "customsProcess": "SEA_IN_SEA_OUT",
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": {
              "rate": 1.1805,
              "currency": {
                "unCode": "USD",
                "description": "U.S.A.-dollar"
              }
            },
            "consignor": {
              "eoriNumber": null,
              "name": "JF HILLEBRAND NEW ZEALAND LIMITED",
              "address": "26-30 PROSFORD STREET",
              "zipCode": "2022",
              "city": "AUCKLAND",
              "countryCode": "NZ",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "JF HILLEBRAND SCANDINAVIA A/S",
              "address": "SMAKKEDALEN 4",
              "zipCode": "2820",
              "city": "GENTOFTE",
              "countryCode": "DK",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [
              {
                "eoriNumber": null,
                "name": "JF HILLEBRAND SCANDINAVIA A/S",
                "address": "SMAKKEDALEN 4",
                "zipCode": "2820",
                "city": "GENTOFTE",
                "countryCode": "DK",
                "authorised": null,
                "id": null,
                "ownerShortName": null
              }
            ],
            "placeOfOrigin": null,
            "placeOfDestination": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euLocation": false
            },
            "placeOfDelivery": "ROTTERDAM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "0RP9FS1MA",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "Wine of fresh grapes, including fortified wines ; grape must",
                "grossWeight": 24998,
                "numberOfOuterPackages": 3472,
                "outerPackageType": {
                  "code": "CS",
                  "name": "Case",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "2204",
                  "description": "Wine of fresh grapes, including fortified wines; grape must other than that of heading|2009"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "APHU6629450",
                    "numberOfPackages": 3472,
                    "grossWeight": 24998
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "CMDUAKD0269033",
            "portOfLoading": {
              "name": "Tauranga",
              "locationUnCode": "NZTRG",
              "countryUnCode": "NZ",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-08-20T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "movementReferenceNumbers": [
              "21BEN0000014835830"
            ],
            "partShipment": false,
            "movementType": "PIER_TO_PIER",
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "MAINFREIGHT AIR & OCEAN LIMITED",
              "address": "107 WESTNEY ROAD",
              "zipCode": "1071",
              "city": "AUCKLAND",
              "countryCode": "NZ",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "MAINFREIGHT N.V.",
              "address": "NOORDERLAAN 31A",
              "zipCode": "2030",
              "city": "ANTWERPEN",
              "countryCode": "BE",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [
              {
                "eoriNumber": null,
                "name": "MAINFREIGHT N.V.",
                "address": "NOORDERLAAN 31A",
                "zipCode": "2030",
                "city": "ANTWERPEN",
                "countryCode": "BE",
                "authorised": null,
                "id": null,
                "ownerShortName": null
              }
            ],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "ROTTERDAM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "0RP9FS1MA",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "Tableware and kitchenware, of plastics",
                "grossWeight": 4340,
                "numberOfOuterPackages": 7816,
                "outerPackageType": {
                  "code": "CT",
                  "name": "Carton",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "3924",
                  "description": "Tableware, kitchenware, other household articles and hygienic or toilet articles, of plastics"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "BMOU4045469",
                    "numberOfPackages": 7816,
                    "grossWeight": 4340
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 2,
                "description": "Tableware and kitchenware, of plastics",
                "grossWeight": 6912,
                "numberOfOuterPackages": 7416,
                "outerPackageType": {
                  "code": "CT",
                  "name": "Carton",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "3924",
                  "description": "Tableware, kitchenware, other household articles and hygienic or toilet articles, of plastics"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "ECMU9516720",
                    "numberOfPackages": 7416,
                    "grossWeight": 6912
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 3,
                "description": "Tableware and kitchenware, of plastics",
                "grossWeight": 6775,
                "numberOfOuterPackages": 6426,
                "outerPackageType": {
                  "code": "CT",
                  "name": "Carton",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "3924",
                  "description": "Tableware, kitchenware, other household articles and hygienic or toilet articles, of plastics"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "TCLU1523848",
                    "numberOfPackages": 6426,
                    "grossWeight": 6775
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 4,
                "description": "Tableware and kitchenware, of plastics",
                "grossWeight": 7862,
                "numberOfOuterPackages": 10371,
                "outerPackageType": {
                  "code": "CT",
                  "name": "Carton",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "3924",
                  "description": "Tableware, kitchenware, other household articles and hygienic or toilet articles, of plastics"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "TCNU8563990",
                    "numberOfPackages": 10371,
                    "grossWeight": 7862
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "CMDUAKD0269043",
            "portOfLoading": {
              "name": "Tauranga",
              "locationUnCode": "NZTRG",
              "countryUnCode": "NZ",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-08-20T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "movementReferenceNumbers": [
              "21BEN0000014835872"
            ],
            "partShipment": false,
            "movementType": "PIER_TO_PIER",
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "MAINFREIGHT AIR & OCEAN LIMITED",
              "address": "107 WESTNEY ROAD",
              "zipCode": "1071",
              "city": "AUCKLAND",
              "countryCode": "NZ",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "INTER NORDIC TRANSPORT",
              "address": "AXEL GRUHNS VEJ 10",
              "zipCode": "8270",
              "city": "HOJBJERG",
              "countryCode": "DK",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [
              {
                "eoriNumber": null,
                "name": "INTER NORDIC TRANSPORT",
                "address": "AXEL GRUHNS VEJ 10",
                "zipCode": "8270",
                "city": "HOJBJERG",
                "countryCode": "DK",
                "authorised": null,
                "id": null,
                "ownerShortName": null
              }
            ],
            "placeOfOrigin": null,
            "placeOfDestination": {
              "name": "København",
              "locationUnCode": "DKCPH",
              "countryUnCode": "DK",
              "euLocation": true
            },
            "placeOfDelivery": "ROTTERDAM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "0RP9FS1MA",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "Tableware and kitchenware, of plastics",
                "grossWeight": 5691,
                "numberOfOuterPackages": 6004,
                "outerPackageType": {
                  "code": "CT",
                  "name": "Carton",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "3924",
                  "description": "Tableware, kitchenware, other household articles and hygienic or toilet articles, of plastics"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "APHU7060862",
                    "numberOfPackages": 6004,
                    "grossWeight": 5691
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "CMDUAKD0269053",
            "portOfLoading": {
              "name": "Tauranga",
              "locationUnCode": "NZTRG",
              "countryUnCode": "NZ",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-08-20T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "movementReferenceNumbers": [
              "21BEN0000014835864"
            ],
            "partShipment": false,
            "movementType": "PIER_TO_PIER",
            "customsStatus": null,
            "customsProcess": "SEA_IN_SEA_OUT",
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "JF HILLEBRAND NEW ZEALAND LIMITED",
              "address": "26-30 PROSFORD STREET",
              "zipCode": "2022",
              "city": "AUCKLAND",
              "countryCode": "NZ",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "JF HILLEBRAND IRELAND LTD",
              "address": "UNIT 27 3RD FLOOR THE HYDE BUILDING",
              "zipCode": ".",
              "city": "CARRICKMINES",
              "countryCode": "IE",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [
              {
                "eoriNumber": null,
                "name": "JF HILLEBRAND IRELAND LTD",
                "address": "UNIT 27 3RD FLOOR THE HYDE BUILDING",
                "zipCode": ".",
                "city": "CARRICKMINES",
                "countryCode": "IE",
                "authorised": null,
                "id": null,
                "ownerShortName": null
              }
            ],
            "placeOfOrigin": null,
            "placeOfDestination": {
              "name": "Dublin",
              "locationUnCode": "IEDUB",
              "countryUnCode": "IE",
              "euLocation": true
            },
            "placeOfDelivery": "ROTTERDAM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "0RP9FS1MA",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "Wine of fresh grapes, including fortified wines ; grape must",
                "grossWeight": 21476,
                "numberOfOuterPackages": 2958,
                "outerPackageType": {
                  "code": "CS",
                  "name": "Case",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "2204",
                  "description": "Wine of fresh grapes, including fortified wines; grape must other than that of heading|2009"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "APZU4866625",
                    "numberOfPackages": 2958,
                    "grossWeight": 21476
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "CMDUAKD0269154",
            "portOfLoading": {
              "name": "Tauranga",
              "locationUnCode": "NZTRG",
              "countryUnCode": "NZ",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-08-20T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "movementReferenceNumbers": [
              "21BEN0000014835899"
            ],
            "partShipment": false,
            "movementType": "PIER_TO_PIER",
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "TENON CLEARWOOD LP",
              "address": "199 CENTENNIAL DRIVE",
              "zipCode": "3330",
              "city": "TAUPO",
              "countryCode": "NZ",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "KEBONY NORGE AS",
              "address": "HAVNEVEGEN 35",
              "zipCode": "3739",
              "city": "SKIEN",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [
              {
                "eoriNumber": null,
                "name": "GREENCARRIER SHIPPING",
                "address": "POSTBOKS 1",
                "zipCode": "3251",
                "city": "LARVIK",
                "countryCode": "NO",
                "authorised": null,
                "id": null,
                "ownerShortName": null
              }
            ],
            "placeOfOrigin": null,
            "placeOfDestination": {
              "name": "Larvik",
              "locationUnCode": "NOLAR",
              "countryUnCode": "NO",
              "euLocation": false
            },
            "placeOfDelivery": "ROTTERDAM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "0RP9FS1MA",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "LUMBER",
                "grossWeight": 20340,
                "numberOfOuterPackages": 8,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "APHU6889720",
                    "numberOfPackages": 8,
                    "grossWeight": 20340
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 2,
                "description": "LUMBER",
                "grossWeight": 23120,
                "numberOfOuterPackages": 10,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "CMAU7636160",
                    "numberOfPackages": 10,
                    "grossWeight": 23120
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "CMDUAKD0269163",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "movementReferenceNumbers": [
              "21BEN0000014145443"
            ],
            "partShipment": false,
            "movementType": "PIER_TO_PIER",
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": {
              "rate": 1.1805,
              "currency": {
                "unCode": "USD",
                "description": "U.S.A.-dollar"
              }
            },
            "consignor": {
              "eoriNumber": null,
              "name": "SPRING CREEK VINTNERS",
              "address": "41 GOULAND ROAD",
              "zipCode": "7202",
              "city": "SPRING CREEK",
              "countryCode": "NZ",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "LIDL STIFTUNG & CO.KG",
              "address": "ABT IMPORT, STIFTSBERGSTR 1",
              "zipCode": "74167",
              "city": "NECKARSULM",
              "countryCode": "DE",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [
              {
                "eoriNumber": null,
                "name": "LIDL STIFTUNG & CO.KG",
                "address": "ABT IMPORT, STIFTSBERGSTR 1",
                "zipCode": "74167",
                "city": "NECKARSULM",
                "countryCode": "DE",
                "authorised": null,
                "id": null,
                "ownerShortName": null
              }
            ],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "NLMOE",
            "onCarriageDetails": null,
            "agentVoyageNumber": "0RP9FS1MA",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "Wine of fresh grapes, including fortified wines ; grape must",
                "grossWeight": 14300,
                "numberOfOuterPackages": 1960,
                "outerPackageType": {
                  "code": "CS",
                  "name": "Case",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "2204",
                  "description": "Wine of fresh grapes, including fortified wines; grape must other than that of heading|2009"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "APZU3598533",
                    "numberOfPackages": 1960,
                    "grossWeight": 14300
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 2,
                "description": "Wine of fresh grapes, including fortified wines ; grape must",
                "grossWeight": 14300,
                "numberOfOuterPackages": 1960,
                "outerPackageType": {
                  "code": "CS",
                  "name": "Case",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "2204",
                  "description": "Wine of fresh grapes, including fortified wines; grape must other than that of heading|2009"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "CMAU0884797",
                    "numberOfPackages": 1960,
                    "grossWeight": 14300
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 3,
                "description": "Wine of fresh grapes, including fortified wines ; grape must",
                "grossWeight": 14300,
                "numberOfOuterPackages": 1960,
                "outerPackageType": {
                  "code": "CS",
                  "name": "Case",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "2204",
                  "description": "Wine of fresh grapes, including fortified wines; grape must other than that of heading|2009"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "CMAU1698631",
                    "numberOfPackages": 1960,
                    "grossWeight": 14300
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 4,
                "description": "Wine of fresh grapes, including fortified wines ; grape must",
                "grossWeight": 14300,
                "numberOfOuterPackages": 1960,
                "outerPackageType": {
                  "code": "CS",
                  "name": "Case",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "2204",
                  "description": "Wine of fresh grapes, including fortified wines; grape must other than that of heading|2009"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "CRXU3398494",
                    "numberOfPackages": 1960,
                    "grossWeight": 14300
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 5,
                "description": "Wine of fresh grapes, including fortified wines ; grape must",
                "grossWeight": 14300,
                "numberOfOuterPackages": 1960,
                "outerPackageType": {
                  "code": "CS",
                  "name": "Case",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "2204",
                  "description": "Wine of fresh grapes, including fortified wines; grape must other than that of heading|2009"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "TCLU3941929",
                    "numberOfPackages": 1960,
                    "grossWeight": 14300
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 6,
                "description": "Wine of fresh grapes, including fortified wines ; grape must",
                "grossWeight": 14300,
                "numberOfOuterPackages": 1960,
                "outerPackageType": {
                  "code": "CS",
                  "name": "Case",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "2204",
                  "description": "Wine of fresh grapes, including fortified wines; grape must other than that of heading|2009"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "TTNU1159356",
                    "numberOfPackages": 1960,
                    "grossWeight": 14300
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 7,
                "description": "Wine of fresh grapes, including fortified wines ; grape must",
                "grossWeight": 14300,
                "numberOfOuterPackages": 1960,
                "outerPackageType": {
                  "code": "CS",
                  "name": "Case",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "2204",
                  "description": "Wine of fresh grapes, including fortified wines; grape must other than that of heading|2009"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "XINU1282208",
                    "numberOfPackages": 1960,
                    "grossWeight": 14300
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "CMDUAKD0269189",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "movementReferenceNumbers": [
              "21BEN0000013964847"
            ],
            "partShipment": false,
            "movementType": "PIER_TO_PIER",
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": {
              "rate": 1.1805,
              "currency": {
                "unCode": "USD",
                "description": "U.S.A.-dollar"
              }
            },
            "consignor": {
              "eoriNumber": null,
              "name": "JF HILLEBRAND NEW ZEALAND LIMITED",
              "address": "26-30 PROSFORD STREET",
              "zipCode": "2022",
              "city": "AUCKLAND",
              "countryCode": "NZ",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "JF HILLEBRAND SPAIN SA",
              "address": "AVDA DE LA CATEDRAL 6 8 2 1",
              "zipCode": "08002",
              "city": "BARCELONA",
              "countryCode": "ES",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [
              {
                "eoriNumber": null,
                "name": "JF HILLEBRAND SPAIN SA",
                "address": "AVDA DE LA CATEDRAL 6 8 2 1",
                "zipCode": "08002",
                "city": "BARCELONA",
                "countryCode": "ES",
                "authorised": null,
                "id": null,
                "ownerShortName": null
              }
            ],
            "placeOfOrigin": null,
            "placeOfDestination": {
              "name": "Bilbao",
              "locationUnCode": "ESBIO",
              "countryUnCode": "ES",
              "euLocation": true
            },
            "placeOfDelivery": "ROTTERDAM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "0RP9FS1MA",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "Wine of fresh grapes, including fortified wines ; grape must",
                "grossWeight": 24000,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "2204",
                  "description": "Wine of fresh grapes, including fortified wines; grape must other than that of heading|2009"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "CAIU3468445",
                    "numberOfPackages": 1,
                    "grossWeight": 24000
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "CMDUAKD0269191",
            "portOfLoading": {
              "name": "Tauranga",
              "locationUnCode": "NZTRG",
              "countryUnCode": "NZ",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-08-20T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "movementReferenceNumbers": [
              "21BEN0000014835947"
            ],
            "partShipment": false,
            "movementType": "PIER_TO_PIER",
            "customsStatus": null,
            "customsProcess": "SEA_IN_SEA_OUT",
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "TENON CLEARWOOD LP",
              "address": "199 CENTENNIAL DRIVE",
              "zipCode": "3330",
              "city": "TAUPO",
              "countryCode": "NZ",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "OSUKALNS",
              "address": "BEBRU STREET 104A",
              "zipCode": "LV5201",
              "city": "JEKABPILS",
              "countryCode": "LV",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [
              {
                "eoriNumber": null,
                "name": "OSUKALNS",
                "address": "BEBRU STREET 104A",
                "zipCode": "LV5201",
                "city": "JEKABPILS",
                "countryCode": "LV",
                "authorised": null,
                "id": null,
                "ownerShortName": null
              }
            ],
            "placeOfOrigin": null,
            "placeOfDestination": {
              "name": "Riga",
              "locationUnCode": "LVRIX",
              "countryUnCode": "LV",
              "euLocation": true
            },
            "placeOfDelivery": "ROTTERDAM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "0RP9FS1MA",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "Pine (Pinus spp.) sawn or chipped lengthwise, sliced or peel",
                "grossWeight": 24240,
                "numberOfOuterPackages": 10,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "4407",
                  "description": "Wood sawn or chipped lengthwise, sliced or peeled, whether or not planed, sanded or end-jointed, of a thickness exceeding 6|mm"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "TCNU5147371",
                    "numberOfPackages": 10,
                    "grossWeight": 24240
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "CMDUAKD0269220",
            "portOfLoading": {
              "name": "Tauranga",
              "locationUnCode": "NZTRG",
              "countryUnCode": "NZ",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-08-20T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "movementReferenceNumbers": [
              "21BEN0000014835920"
            ],
            "partShipment": false,
            "movementType": "PIER_TO_PIER",
            "customsStatus": null,
            "customsProcess": "SEA_IN_SEA_OUT",
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "KUEHNE + NAGEL NZ LTD",
              "address": "30 AINTREE AVENUE",
              "zipCode": ".",
              "city": "AUCKLAND",
              "countryCode": "NZ",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "KUEHNE + NAGEL LIMITED",
              "address": "2ND FLOOR 2350 THE QUADRANT",
              "zipCode": "BS32 4AQ",
              "city": "BRISTOL",
              "countryCode": "GB",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [
              {
                "eoriNumber": null,
                "name": "KUEHNE + NAGEL LTD.",
                "address": "2ND FLOOR 2350 THE QUADRANT",
                "zipCode": "BS32 4AQ",
                "city": "BRISTOL",
                "countryCode": "GB",
                "authorised": null,
                "id": null,
                "ownerShortName": null
              }
            ],
            "placeOfOrigin": null,
            "placeOfDestination": {
              "name": "Felixstowe",
              "locationUnCode": "GBFXT",
              "countryUnCode": "GB",
              "euLocation": false
            },
            "placeOfDelivery": "ROTTERDAM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "0RP9FS1MA",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "Mechano-therapy appliances; massage apparatus; psychological",
                "grossWeight": 5957,
                "numberOfOuterPackages": 711,
                "outerPackageType": {
                  "code": "CT",
                  "name": "Carton",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "9019",
                  "description": "Mechano-therapy appliances; massage apparatus; psychological aptitude-testing apparatus; ozone therapy, oxygen therapy, aerosol therapy, artificial respiration or other therapeutic respiration apparatus"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "BEAU4044853",
                    "numberOfPackages": 711,
                    "grossWeight": 5957
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "CMDUAKD0269360",
            "portOfLoading": {
              "name": "Tauranga",
              "locationUnCode": "NZTRG",
              "countryUnCode": "NZ",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-08-20T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "movementReferenceNumbers": [
              "21BEN0000014835813"
            ],
            "partShipment": false,
            "movementType": "PIER_TO_PIER",
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "KUEHNE + NAGEL NZ LTD",
              "address": "30 AINTREE AVENUE",
              "zipCode": ".",
              "city": "AUCKLAND",
              "countryCode": "NZ",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "KUEHNE + NAGEL AG",
              "address": "BAUMLIMATTSTRASSE 12",
              "zipCode": "4313",
              "city": "MOHLIN",
              "countryCode": "CH",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [
              {
                "eoriNumber": null,
                "name": "KUEHNE + NAGEL AG",
                "address": "BAUMLIMATTSTRASSE 12",
                "zipCode": "4313",
                "city": "MOHLIN",
                "countryCode": "CH",
                "authorised": null,
                "id": null,
                "ownerShortName": null
              }
            ],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "ROTTERDAM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "0RP9FS1MA",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "PHARMACEUTICAL GOODS",
                "grossWeight": 2090,
                "numberOfOuterPackages": 21,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "CGMU5054595",
                    "numberOfPackages": 21,
                    "grossWeight": 2090
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "CMDUAKD0269376",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "movementReferenceNumbers": [
              "21BEN0000013965057"
            ],
            "partShipment": false,
            "movementType": "PIER_TO_PIER",
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "ANZCO FOODS LIMITED",
              "address": "5 ROBIN MANN PLACE",
              "zipCode": "8053",
              "city": "CHRISTCHURCH",
              "countryCode": "NZ",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "ANZCO FOODS EU BV",
              "address": "MARKT 32",
              "zipCode": "9700",
              "city": "OUDENAARDE",
              "countryCode": "BE",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [
              {
                "eoriNumber": null,
                "name": "ANZCO FOODS EU BV",
                "address": "MARKT 32",
                "zipCode": "9700",
                "city": "OUDENAARDE",
                "countryCode": "BE",
                "authorised": null,
                "id": null,
                "ownerShortName": null
              }
            ],
            "placeOfOrigin": null,
            "placeOfDestination": {
              "name": "Hamburg",
              "locationUnCode": "DEHAM",
              "countryUnCode": "DE",
              "euLocation": true
            },
            "placeOfDelivery": "ROTTERDAM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "0RP9FS1MA",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "FROZEN NEW ZEALAND LAMB",
                "grossWeight": 11436,
                "numberOfOuterPackages": 1016,
                "outerPackageType": {
                  "code": "CT",
                  "name": "Carton",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "CGMU3040294",
                    "numberOfPackages": 1016,
                    "grossWeight": 11436
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "CMDUAKD0269480",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "movementReferenceNumbers": [
              "21BEN0000013964721"
            ],
            "partShipment": false,
            "movementType": "PIER_TO_PIER",
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": {
              "rate": 1.1805,
              "currency": {
                "unCode": "USD",
                "description": "U.S.A.-dollar"
              }
            },
            "consignor": {
              "eoriNumber": null,
              "name": "AFFCO NEW ZEALAND LIMITED",
              "address": "GREAT SOUTH ROAD",
              "zipCode": "3204",
              "city": "HOROTIU",
              "countryCode": "NZ",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "FN GLOBAL MEAT B.V.",
              "address": "HELIOTROOPRING 700",
              "zipCode": "3316 KG",
              "city": "DORDRECHT",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [
              {
                "eoriNumber": null,
                "name": "FN GLOBAL MEAT B.V.",
                "address": "HELIOTROOPRING 700",
                "zipCode": "3316 KG",
                "city": "DORDRECHT",
                "countryCode": "NL",
                "authorised": null,
                "id": null,
                "ownerShortName": null
              }
            ],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "ROTTERDAM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "0RP9FS1MA",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "CHILLED LAMB CUTS",
                "grossWeight": 11711.04,
                "numberOfOuterPackages": 1075,
                "outerPackageType": {
                  "code": "CT",
                  "name": "Carton",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "CGMU3044345",
                    "numberOfPackages": 1075,
                    "grossWeight": 11711.04
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "CMDUAKD0269495",
            "portOfLoading": {
              "name": "Tauranga",
              "locationUnCode": "NZTRG",
              "countryUnCode": "NZ",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-08-20T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "movementReferenceNumbers": [
              "21BEN0000014836173"
            ],
            "partShipment": false,
            "movementType": "PIER_TO_PIER",
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "AFFCO NEW ZEALAND LIMITED",
              "address": "GREAT SOUTH ROAD",
              "zipCode": "3204",
              "city": "HOROTIU",
              "countryCode": "NZ",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "FN GLOBAL MEAT B.V.",
              "address": "HELIOTROOPRING 700",
              "zipCode": "3316 KG",
              "city": "DORDRECHT",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [
              {
                "eoriNumber": null,
                "name": "FN GLOBAL MEAT B.V.",
                "address": "HELIOTROOPRING 700",
                "zipCode": "3316 KG",
                "city": "DORDRECHT",
                "countryCode": "NL",
                "authorised": null,
                "id": null,
                "ownerShortName": null
              }
            ],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "ROTTERDAM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "0RP9FS1MA",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "Fresh or chilled boneless cuts of sheep",
                "grossWeight": 12185,
                "numberOfOuterPackages": 1075,
                "outerPackageType": {
                  "code": "CT",
                  "name": "Carton",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "0204",
                  "description": "Meat of sheep or goats, fresh, chilled or frozen"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "CGMU3077652",
                    "numberOfPackages": 1075,
                    "grossWeight": 12185
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "CMDUAKD0269534",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "movementReferenceNumbers": [
              "21BEN0000014108381"
            ],
            "partShipment": false,
            "movementType": "PIER_TO_PIER",
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": {
              "rate": 1.1805,
              "currency": {
                "unCode": "USD",
                "description": "U.S.A.-dollar"
              }
            },
            "consignor": {
              "eoriNumber": null,
              "name": "NEW ZEALAND VAN LINES LTD",
              "address": "17 HALWYN DRIVE HORNBY",
              "zipCode": "8042",
              "city": "CHRISTCHURCH",
              "countryCode": "NZ",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "PICKFORDS",
              "address": "UNIT 10 PICKFORDS PARK LAXCON CLOSE",
              "zipCode": "NW10 0TG",
              "city": "LONDON",
              "countryCode": "GB",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [
              {
                "eoriNumber": null,
                "name": "PICKFORDS",
                "address": "UNIT 10 PICKFORDS PARK LAXCON CLOSE",
                "zipCode": "NW10 0TG",
                "city": "LONDON",
                "countryCode": "GB",
                "authorised": null,
                "id": null,
                "ownerShortName": null
              }
            ],
            "placeOfOrigin": null,
            "placeOfDestination": {
              "name": "Southampton",
              "locationUnCode": "GBSOU",
              "countryUnCode": "GB",
              "euLocation": false
            },
            "placeOfDelivery": "ROTTERDAM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "0RP9FS1MA",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "Motor cars and other motor vehicles principally designed for",
                "grossWeight": 5200,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "8703",
                  "description": "Motor cars and other motor vehicles principally designed for the transport of persons (other than those of heading|8702), including station wagons and racing cars"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "SEGU1669073",
                    "numberOfPackages": 1,
                    "grossWeight": 5200
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "CMDUAKD0269719",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "movementReferenceNumbers": [
              "21BEN0000013964938"
            ],
            "partShipment": false,
            "movementType": "PIER_TO_PIER",
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "JF HILLEBRAND NEW ZEALAND LIMITED",
              "address": "26-30 PROSFORD STREET",
              "zipCode": "2022",
              "city": "AUCKLAND",
              "countryCode": "NZ",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "JF HILLEBRAND SCANDINAVIA A/S",
              "address": "SMAKKEDALEN 4",
              "zipCode": "2820",
              "city": "GENTOFTE",
              "countryCode": "DK",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [
              {
                "eoriNumber": null,
                "name": "JF HILLEBRAND SCANDINAVIA A/S",
                "address": "SMAKKEDALEN 4",
                "zipCode": "2820",
                "city": "GENTOFTE",
                "countryCode": "DK",
                "authorised": null,
                "id": null,
                "ownerShortName": null
              }
            ],
            "placeOfOrigin": null,
            "placeOfDestination": {
              "name": "København",
              "locationUnCode": "DKCPH",
              "countryUnCode": "DK",
              "euLocation": true
            },
            "placeOfDelivery": "ROTTERDAM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "0RP9FS1MA",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "Wine of fresh grapes, including fortified wines ; grape must",
                "grossWeight": 24000,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "FE",
                  "name": "Flexitank",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "2204",
                  "description": "Wine of fresh grapes, including fortified wines; grape must other than that of heading|2009"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "TEMU0992508",
                    "numberOfPackages": 1,
                    "grossWeight": 24000
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 2,
                "description": "Wine of fresh grapes, including fortified wines ; grape must",
                "grossWeight": 24000,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "FE",
                  "name": "Flexitank",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "2204",
                  "description": "Wine of fresh grapes, including fortified wines; grape must other than that of heading|2009"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "TEMU2264815",
                    "numberOfPackages": 1,
                    "grossWeight": 24000
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 3,
                "description": "Wine of fresh grapes, including fortified wines ; grape must",
                "grossWeight": 24000,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "FE",
                  "name": "Flexitank",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "2204",
                  "description": "Wine of fresh grapes, including fortified wines; grape must other than that of heading|2009"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "TLLU2767615",
                    "numberOfPackages": 1,
                    "grossWeight": 24000
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 4,
                "description": "Wine of fresh grapes, including fortified wines ; grape must",
                "grossWeight": 24000,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "FE",
                  "name": "Flexitank",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "2204",
                  "description": "Wine of fresh grapes, including fortified wines; grape must other than that of heading|2009"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "TLLU3229300",
                    "numberOfPackages": 1,
                    "grossWeight": 24000
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "CMDUAKD0269746",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "movementReferenceNumbers": [
              "21BEN0000013964799"
            ],
            "partShipment": false,
            "movementType": "PIER_TO_PIER",
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "JF HILLEBRAND NEW ZEALAND LIMITED",
              "address": "26-30 PROSFORD STREET",
              "zipCode": "2022",
              "city": "AUCKLAND",
              "countryCode": "NZ",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "JF HILLEBRAND SCANDINAVIA A/S",
              "address": "SMAKKEDALEN 4",
              "zipCode": "2820",
              "city": "GENTOFTE",
              "countryCode": "DK",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [
              {
                "eoriNumber": null,
                "name": "JF HILLEBRAND SCANDINAVIA A/S",
                "address": "SMAKKEDALEN 4",
                "zipCode": "2820",
                "city": "GENTOFTE",
                "countryCode": "DK",
                "authorised": null,
                "id": null,
                "ownerShortName": null
              }
            ],
            "placeOfOrigin": null,
            "placeOfDestination": {
              "name": "København",
              "locationUnCode": "DKCPH",
              "countryUnCode": "DK",
              "euLocation": true
            },
            "placeOfDelivery": "ROTTERDAM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "0RP9FS1MA",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "Wine of fresh grapes, including fortified wines ; grape must",
                "grossWeight": 24000,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "FE",
                  "name": "Flexitank",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "2204",
                  "description": "Wine of fresh grapes, including fortified wines; grape must other than that of heading|2009"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "GLDU3525518",
                    "numberOfPackages": 1,
                    "grossWeight": 24000
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "CMDUAKD0269792",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "movementReferenceNumbers": [
              "21BEN0000014108159"
            ],
            "partShipment": false,
            "movementType": "PIER_TO_PIER",
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "PYRAMID LINES",
              "address": "144 WESTNEY ROAD",
              "zipCode": ".",
              "city": "AUCKLAND",
              "countryCode": "NZ",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "CEVA ULUSLARARASI TASIMACILIK LTD.",
              "address": "UNALAN MAHALLESI LIBADIYE CADDESI",
              "zipCode": ".",
              "city": "USKUDAR",
              "countryCode": "TR",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [
              {
                "eoriNumber": null,
                "name": "CEVA ULUSLARARASI TASIMACILIK LTD.",
                "address": "UNALAN MAHALLESI LIBADIYE CADDESI",
                "zipCode": ".",
                "city": "USKUDAR",
                "countryCode": "TR",
                "authorised": null,
                "id": null,
                "ownerShortName": null
              }
            ],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "ROTTERDAM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "0RP9FS1MA",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "MEDICAL APPLIANCES",
                "grossWeight": 1954,
                "numberOfOuterPackages": 11,
                "outerPackageType": {
                  "code": "CT",
                  "name": "Carton",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "CMAU3012545",
                    "numberOfPackages": 11,
                    "grossWeight": 1954
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "CMDUAKD0269794",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "movementReferenceNumbers": [
              "21BEN0000014145197"
            ],
            "partShipment": false,
            "movementType": "PIER_TO_PIER",
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "PYRAMID LINES",
              "address": "144 WESTNEY ROAD",
              "zipCode": ".",
              "city": "AUCKLAND",
              "countryCode": "NZ",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "CEVA FREIGHT GERMANY GMBH",
              "address": "MUSEUMSTR 4",
              "zipCode": "28195",
              "city": "BREMEN",
              "countryCode": "DE",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [
              {
                "eoriNumber": null,
                "name": "CEVA FREIGHT GERMANY GMBH",
                "address": "MUSEUMSTR 4",
                "zipCode": "28195",
                "city": "BREMEN",
                "countryCode": "DE",
                "authorised": null,
                "id": null,
                "ownerShortName": null
              }
            ],
            "placeOfOrigin": null,
            "placeOfDestination": {
              "name": "Hamburg",
              "locationUnCode": "DEHAM",
              "countryUnCode": "DE",
              "euLocation": true
            },
            "placeOfDelivery": "ROTTERDAM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "0RP9FS1MA",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "MEDICAL APPLIANCES",
                "grossWeight": 3792,
                "numberOfOuterPackages": 35,
                "outerPackageType": {
                  "code": "CT",
                  "name": "Carton",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "TRHU6500975",
                    "numberOfPackages": 35,
                    "grossWeight": 3792
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "CMDUAKD0269864",
            "portOfLoading": {
              "name": "Tauranga",
              "locationUnCode": "NZTRG",
              "countryUnCode": "NZ",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-08-20T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "movementReferenceNumbers": [
              "21BEN0000014887447"
            ],
            "partShipment": false,
            "movementType": "PIER_TO_PIER",
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "CONROY REMOVALS",
              "address": "7 AIRPARK DRIVE",
              "zipCode": "1701",
              "city": "MANGERE",
              "countryCode": "NZ",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "KHZ INTERNATIONAL MOVERS B.V.",
              "address": "A VAN LEEUWENHOEKWEG 50",
              "zipCode": "2401 AN",
              "city": "ALPHEN AAN DEN RIJN",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [
              {
                "eoriNumber": null,
                "name": "KHZ INTERNATIONAL MOVERS B.V.",
                "address": "A VAN LEEUWENHOEKWEG 50",
                "zipCode": "2401 AN",
                "city": "ALPHEN AAN DEN RIJN",
                "countryCode": "NL",
                "authorised": null,
                "id": null,
                "ownerShortName": null
              }
            ],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "ROTTERDAM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "0RP9FS1MA",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "USED PERSONAL & HOUSEHOLD GOODS",
                "grossWeight": 8300,
                "numberOfOuterPackages": 609,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "TCNU2628368",
                    "numberOfPackages": 609,
                    "grossWeight": 8300
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "CMDUAKD0269982",
            "portOfLoading": {
              "name": "Tauranga",
              "locationUnCode": "NZTRG",
              "countryUnCode": "NZ",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-08-20T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "movementReferenceNumbers": [
              "21BEN0000014835998"
            ],
            "partShipment": false,
            "movementType": "PIER_TO_PIER",
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "CEVA LOGISTICS NZ LTD",
              "address": "144 WESTNEY ROAD",
              "zipCode": ".",
              "city": "AUCKLAND",
              "countryCode": "NZ",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "CEVA FREIGHT GERMANY GMBH",
              "address": "LUFTFRACHTZENTRUM, HAUS D 610/4",
              "zipCode": "70629",
              "city": "STUTTGART",
              "countryCode": "DE",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [
              {
                "eoriNumber": null,
                "name": "CEVA FREIGHT GERMANY GMBH",
                "address": "LUFTFRACHTZENTRUM, HAUS D 610/4",
                "zipCode": "70629",
                "city": "STUTTGART",
                "countryCode": "DE",
                "authorised": null,
                "id": null,
                "ownerShortName": null
              }
            ],
            "placeOfOrigin": null,
            "placeOfDestination": {
              "name": "Hamburg",
              "locationUnCode": "DEHAM",
              "countryUnCode": "DE",
              "euLocation": true
            },
            "placeOfDelivery": "ROTTERDAM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "0RP9FS1MA",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "MEDICAL APPLIANCES",
                "grossWeight": 3782,
                "numberOfOuterPackages": 40,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "FSCU7138984",
                    "numberOfPackages": 40,
                    "grossWeight": 3782
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "CMDUGSR0121013",
            "portOfLoading": {
              "name": "Kingston",
              "locationUnCode": "JMKIN",
              "countryUnCode": "JM",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-11T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": "PIER_TO_PIER",
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "CMA CGM SURINAME NV",
              "address": "VAN 'T HOGERHUYSSTRAAT # 15",
              "zipCode": ".",
              "city": "PARAMARIBO",
              "countryCode": "SR",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "PROGECO HOLLAND BV",
              "address": "DROOGDOKWEG 71",
              "zipCode": "3089 JN",
              "city": "ROTTERDAM",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [
              {
                "eoriNumber": null,
                "name": "PROGECO HOLLAND BV",
                "address": "DROOGDOKWEG 71",
                "zipCode": "3089 JN",
                "city": "ROTTERDAM",
                "countryCode": "NL",
                "authorised": null,
                "id": null,
                "ownerShortName": null
              }
            ],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "ROTTERDAM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "0RP9FS1MA",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "used Clip on Gensets \"not in working condition\"",
                "grossWeight": 1000,
                "numberOfOuterPackages": 4,
                "outerPackageType": {
                  "code": "PP",
                  "name": "Piece",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": {
                  "name": "DIESEL FUEL Diesel fuel.",
                  "unCode": "1202",
                  "hazardClass": "3",
                  "packingGroup": null,
                  "flashPoint": null
                },
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "DFSU2090590",
                    "numberOfPackages": 4,
                    "grossWeight": 1000
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "CMDUHAT0101288",
            "portOfLoading": {
              "name": "Kingston",
              "locationUnCode": "JMKIN",
              "countryUnCode": "JM",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-11T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": "PIER_TO_PIER",
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "PRODUITS DES ILES SA",
              "address": "ACUL DU NORD ST",
              "zipCode": ".",
              "city": "VILLE DU CAP-HAITIEN",
              "countryCode": "HT",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "CRAFTING MARKETS BV",
              "address": "RADARWEG 32A",
              "zipCode": "1042 AA",
              "city": "AMSTERDAM",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [
              {
                "eoriNumber": null,
                "name": "C STEINWEG HANDELSVEEM BV",
                "address": "SEXTANTWEG 8",
                "zipCode": "1042 AH",
                "city": "AMSTERDAM",
                "countryCode": "NL",
                "authorised": null,
                "id": null,
                "ownerShortName": null
              }
            ],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "NLAMS",
            "onCarriageDetails": null,
            "agentVoyageNumber": "0RP9FS1MA",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "Cocoa beans, whole or broken, raw or roasted",
                "grossWeight": 12483,
                "numberOfOuterPackages": 178,
                "outerPackageType": {
                  "code": "BG",
                  "name": "Bags",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "1801",
                  "description": "Cocoa beans, whole or broken, raw or roasted"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "CARU3715217",
                    "numberOfPackages": 178,
                    "grossWeight": 12483
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "CMDUHAT0101289",
            "portOfLoading": {
              "name": "Kingston",
              "locationUnCode": "JMKIN",
              "countryUnCode": "JM",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-11T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": "PIER_TO_PIER",
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "PRODUITS DES ILES SA",
              "address": "ACUL DU NORD ST",
              "zipCode": ".",
              "city": "VILLE DU CAP-HAITIEN",
              "countryCode": "HT",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "TRADIN ORGANIC COCOA BV",
              "address": "STATIONSPLEIN 61 65",
              "zipCode": "1012 AB",
              "city": "AMSTERDAM",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [
              {
                "eoriNumber": null,
                "name": "TRADIN ORGANIC COCOA BV",
                "address": "STATIONSPLEIN 61 65",
                "zipCode": "1012 AB",
                "city": "AMSTERDAM",
                "countryCode": "NL",
                "authorised": null,
                "id": null,
                "ownerShortName": null
              }
            ],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "NLAMS",
            "onCarriageDetails": null,
            "agentVoyageNumber": "0RP9FS1MA",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "Cocoa beans, whole or broken, raw or roasted",
                "grossWeight": 25106,
                "numberOfOuterPackages": 358,
                "outerPackageType": {
                  "code": "BG",
                  "name": "Bags",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "1801",
                  "description": "Cocoa beans, whole or broken, raw or roasted"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "CMAU7183844",
                    "numberOfPackages": 358,
                    "grossWeight": 25106
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "CMDUHAT0101312",
            "portOfLoading": {
              "name": "Kingston",
              "locationUnCode": "JMKIN",
              "countryUnCode": "JM",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-11T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": "PIER_TO_PIER",
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "HAITI RECYCLING",
              "address": "ROUTE NATIONALE 1",
              "zipCode": ".",
              "city": "PORT-AU-PRINCE",
              "countryCode": "HT",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "AMI TRADING INC USA",
              "address": "1875 CORAL WAY",
              "zipCode": "33145",
              "city": "MIAMI",
              "countryCode": "US",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [
              {
                "eoriNumber": null,
                "name": "AMI TRADING INC USA",
                "address": "1875 CORAL WAY",
                "zipCode": "33145",
                "city": "MIAMI",
                "countryCode": "US",
                "authorised": null,
                "id": null,
                "ownerShortName": null
              }
            ],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "ROTTERDAM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "0RP9FS1MA",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "STAINLESS STEEL SCRAP",
                "grossWeight": 20616,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "TCLU5161773",
                    "numberOfPackages": 1,
                    "grossWeight": 20616
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "CMDUMBE0353890",
            "portOfLoading": {
              "name": "Brisbane",
              "locationUnCode": "AUBNE",
              "countryUnCode": "AU",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-08-08T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "movementReferenceNumbers": [
              "21BEN0000013972639"
            ],
            "partShipment": false,
            "movementType": "PIER_TO_PIER",
            "customsStatus": null,
            "customsProcess": "SEA_IN_SEA_OUT",
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "SEAWAY AGENCIES",
              "address": "LEVEL 8 60 CITY ROAD",
              "zipCode": "3006",
              "city": "MT VICTORIA",
              "countryCode": "AU",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "BULKHAUL LIMITED",
              "address": "BRIGNELL ROAD",
              "zipCode": "TS2 1PS",
              "city": "MIDDLESBOROUGH",
              "countryCode": "GB",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [
              {
                "eoriNumber": null,
                "name": "BULKHAUL LTD",
                "address": "BRIGNELL ROAD",
                "zipCode": "TS2 1PS",
                "city": "MIDDLESBOROUGH",
                "countryCode": "GB",
                "authorised": null,
                "id": null,
                "ownerShortName": null
              }
            ],
            "placeOfOrigin": null,
            "placeOfDestination": {
              "name": "Teesport",
              "locationUnCode": "GBTEE",
              "countryUnCode": "GB",
              "euLocation": false
            },
            "placeOfDelivery": "ROTTERDAM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "0RP9FS1MA",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "USED COOKING OIL (UCO) ENTIRELY OF VEGETABLE ORIGIN",
                "grossWeight": 25500,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "TK",
                  "name": "Tank, rectangular",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "BAFU8890024",
                    "numberOfPackages": 1,
                    "grossWeight": 25500
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 2,
                "description": "USED COOKING OIL (UCO) ENTIRELY OF VEGETABLE ORIGIN",
                "grossWeight": 25700,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "TK",
                  "name": "Tank, rectangular",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "BAFU8890370",
                    "numberOfPackages": 1,
                    "grossWeight": 25700
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 3,
                "description": "USED COOKING OIL (UCO) ENTIRELY OF VEGETABLE ORIGIN",
                "grossWeight": 25800,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "TK",
                  "name": "Tank, rectangular",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "BLKU2604655",
                    "numberOfPackages": 1,
                    "grossWeight": 25800
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 4,
                "description": "USED COOKING OIL (UCO) ENTIRELY OF VEGETABLE ORIGIN",
                "grossWeight": 25000,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "TK",
                  "name": "Tank, rectangular",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "BLKU2520467",
                    "numberOfPackages": 1,
                    "grossWeight": 25000
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 5,
                "description": "USED COOKING OIL (UCO) ENTIRELY OF VEGETABLE ORIGIN",
                "grossWeight": 25150,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "TK",
                  "name": "Tank, rectangular",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "BLKU2585468",
                    "numberOfPackages": 1,
                    "grossWeight": 25150
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 6,
                "description": "USED COOKING OIL (UCO) ENTIRELY OF VEGETABLE ORIGIN",
                "grossWeight": 25150,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "TK",
                  "name": "Tank, rectangular",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "BRKU1254471",
                    "numberOfPackages": 1,
                    "grossWeight": 25150
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "CMDUNMA0107916",
            "portOfLoading": {
              "name": "Nouméa",
              "locationUnCode": "NCNOU",
              "countryUnCode": "NC",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-08-04T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "movementReferenceNumbers": [
              "21BEN0000013807412"
            ],
            "partShipment": false,
            "movementType": "PIER_TO_PIER",
            "customsStatus": null,
            "customsProcess": "SEA_IN_SEA_OUT",
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "AGS NEW CALEDONIA",
              "address": "8 RUE CHAMPOLLION DUCOS",
              "zipCode": "98801",
              "city": "NOUMEA",
              "countryCode": "NC",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "AGS BREST",
              "address": "ZA CROAS AR NEZIC",
              "zipCode": "29200",
              "city": "ST THONAN",
              "countryCode": "FR",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [
              {
                "eoriNumber": null,
                "name": "AGS BREST",
                "address": "ZA CROAS AR NEZIC",
                "zipCode": "29200",
                "city": "ST THONAN",
                "countryCode": "FR",
                "authorised": null,
                "id": null,
                "ownerShortName": null
              }
            ],
            "placeOfOrigin": null,
            "placeOfDestination": {
              "name": "Brest",
              "locationUnCode": "FRBES",
              "countryUnCode": "FR",
              "euLocation": true
            },
            "placeOfDelivery": "ROTTERDAM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "0RP9FS1MA",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "personal prop.blg to natural persons transfer their residenc",
                "grossWeight": 1750,
                "numberOfOuterPackages": 105,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "9905",
                  "description": "Certain goods, as provided for in Council Regulation (EC) No 1186/2009 (Import and Export), Personal property belonging to natural persons transferring their normal place of residence"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "CMAU6284059",
                    "numberOfPackages": 105,
                    "grossWeight": 1750
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 2,
                "description": "personal prop.blg to natural persons transfer their residenc",
                "grossWeight": 416,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CS",
                  "name": "Case",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "9905",
                  "description": "Certain goods, as provided for in Council Regulation (EC) No 1186/2009 (Import and Export), Personal property belonging to natural persons transferring their normal place of residence"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "CMAU6284059",
                    "numberOfPackages": 1,
                    "grossWeight": 416
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 3,
                "description": "personal prop.blg to natural persons transfer their residenc",
                "grossWeight": 522,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CS",
                  "name": "Case",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "9905",
                  "description": "Certain goods, as provided for in Council Regulation (EC) No 1186/2009 (Import and Export), Personal property belonging to natural persons transferring their normal place of residence"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "CMAU6284059",
                    "numberOfPackages": 1,
                    "grossWeight": 522
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 4,
                "description": "personal prop.blg to natural persons transfer their residenc",
                "grossWeight": 1400,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CS",
                  "name": "Case",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "9905",
                  "description": "Certain goods, as provided for in Council Regulation (EC) No 1186/2009 (Import and Export), Personal property belonging to natural persons transferring their normal place of residence"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "CMAU6284059",
                    "numberOfPackages": 1,
                    "grossWeight": 1400
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 5,
                "description": "personal prop.blg to natural persons transfer their residenc",
                "grossWeight": 416,
                "numberOfOuterPackages": 7,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "9905",
                  "description": "Certain goods, as provided for in Council Regulation (EC) No 1186/2009 (Import and Export), Personal property belonging to natural persons transferring their normal place of residence"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "CMAU6284059",
                    "numberOfPackages": 7,
                    "grossWeight": 416
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 6,
                "description": "personal prop.blg to natural persons transfer their residenc",
                "grossWeight": 834,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CS",
                  "name": "Case",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "9905",
                  "description": "Certain goods, as provided for in Council Regulation (EC) No 1186/2009 (Import and Export), Personal property belonging to natural persons transferring their normal place of residence"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "CMAU6284059",
                    "numberOfPackages": 1,
                    "grossWeight": 834
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 7,
                "description": "personal prop.blg to natural persons transfer their residenc",
                "grossWeight": 1700,
                "numberOfOuterPackages": 141,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "9905",
                  "description": "Certain goods, as provided for in Council Regulation (EC) No 1186/2009 (Import and Export), Personal property belonging to natural persons transferring their normal place of residence"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "CMAU6284059",
                    "numberOfPackages": 141,
                    "grossWeight": 1700
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 8,
                "description": "personal prop.blg to natural persons transfer their residenc",
                "grossWeight": 210,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "9905",
                  "description": "Certain goods, as provided for in Council Regulation (EC) No 1186/2009 (Import and Export), Personal property belonging to natural persons transferring their normal place of residence"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "CMAU6284059",
                    "numberOfPackages": 1,
                    "grossWeight": 210
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "CMDUNMA0110054",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "movementReferenceNumbers": [
              "21BEN0000013268650"
            ],
            "partShipment": false,
            "movementType": "PIER_TO_PIER",
            "customsStatus": null,
            "customsProcess": "SEA_IN_SEA_OUT",
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": {
              "rate": 1.1805,
              "currency": {
                "unCode": "USD",
                "description": "U.S.A.-dollar"
              }
            },
            "consignor": {
              "eoriNumber": null,
              "name": "TERMCOTANK SA",
              "address": "5 RUE PEDRO MEYLAN",
              "zipCode": "1208",
              "city": "GENEVA",
              "countryCode": "CH",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "VOLFRA UAB",
              "address": "LIEPU STR 24 9",
              "zipCode": "92113",
              "city": "KLAIPEDA",
              "countryCode": "LT",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [
              {
                "eoriNumber": null,
                "name": "VOLFRA UAB",
                "address": "LIEPU STR 24 9",
                "zipCode": "92113",
                "city": "KLAIPEDA",
                "countryCode": "LT",
                "authorised": null,
                "id": null,
                "ownerShortName": null
              }
            ],
            "placeOfOrigin": null,
            "placeOfDestination": {
              "name": "Riga",
              "locationUnCode": "LVRIX",
              "countryUnCode": "LV",
              "euLocation": true
            },
            "placeOfDelivery": "ROTTERDAM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "0RP9FS1MA",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "Containers, incl. containers for the transport of fluids, sp",
                "grossWeight": 1,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "8609",
                  "description": "Containers (including containers for the transport of fluids) specially designed and equipped for carriage by one or more modes of transport"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "DTOU0099559",
                    "numberOfPackages": 1,
                    "grossWeight": 1
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 2,
                "description": "Containers, incl. containers for the transport of fluids, sp",
                "grossWeight": 1,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "8609",
                  "description": "Containers (including containers for the transport of fluids) specially designed and equipped for carriage by one or more modes of transport"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "LBOU0120095",
                    "numberOfPackages": 1,
                    "grossWeight": 1
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 3,
                "description": "Containers, incl. containers for the transport of fluids, sp",
                "grossWeight": 1,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "8609",
                  "description": "Containers (including containers for the transport of fluids) specially designed and equipped for carriage by one or more modes of transport"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "LBOU0120475",
                    "numberOfPackages": 1,
                    "grossWeight": 1
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "CMDUNMA0110061",
            "portOfLoading": {
              "name": "Nouméa",
              "locationUnCode": "NCNOU",
              "countryUnCode": "NC",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-08-04T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "movementReferenceNumbers": [
              "21BEN0000013807399"
            ],
            "partShipment": false,
            "movementType": "PIER_TO_PIER",
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "ACT NOUMEA",
              "address": "14 RUE ALEXANDRE BABO",
              "zipCode": "98846",
              "city": "NOUMEA",
              "countryCode": "NC",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "EIJKELKAMP SONICSAMPDRILL",
              "address": "UITMAAT 8",
              "zipCode": "6987 ER",
              "city": "GIESBEEK",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [
              {
                "eoriNumber": null,
                "name": "ROTRA AIR & OCEAN BV",
                "address": "KLOMPENMAKERSTRAAT 23",
                "zipCode": "3194 DD",
                "city": "HOOGVLIET",
                "countryCode": "NL",
                "authorised": null,
                "id": null,
                "ownerShortName": null
              }
            ],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "ROTTERDAM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "0RP9FS1MA",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "1 drill and 3 bundles of metal tubes",
                "grossWeight": 18900,
                "numberOfOuterPackages": 4,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "CMAU7896104",
                    "numberOfPackages": 4,
                    "grossWeight": 18900
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 2,
                "description": "1 workshop container",
                "grossWeight": 3540,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "LCRU2024025",
                    "numberOfPackages": 1,
                    "grossWeight": 3540
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 3,
                "description": "9 pallets containing drilling equipment",
                "grossWeight": 7840,
                "numberOfOuterPackages": 9,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "FCIU2736053",
                    "numberOfPackages": 9,
                    "grossWeight": 7840
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "CMDUNMA0110062",
            "portOfLoading": {
              "name": "Nouméa",
              "locationUnCode": "NCNOU",
              "countryUnCode": "NC",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-08-04T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "movementReferenceNumbers": [
              "21BEN0000013807356"
            ],
            "partShipment": false,
            "movementType": "PIER_TO_PIER",
            "customsStatus": null,
            "customsProcess": "SEA_IN_SEA_OUT",
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "COLAS NEW CALEDONIA",
              "address": "16 AVENUE BAIE DE KOUTIO",
              "zipCode": "98846",
              "city": "NOUMEA",
              "countryCode": "NC",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "VOLFRA UAB",
              "address": "LIEPU STR 24 9",
              "zipCode": "92113",
              "city": "KLAIPEDA",
              "countryCode": "LT",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [
              {
                "eoriNumber": null,
                "name": "VOLFRA UAB",
                "address": "LIEPU STR 24 9",
                "zipCode": "92113",
                "city": "KLAIPEDA",
                "countryCode": "LT",
                "authorised": null,
                "id": null,
                "ownerShortName": null
              }
            ],
            "placeOfOrigin": null,
            "placeOfDestination": {
              "name": "Klaipeda",
              "locationUnCode": "LTKLJ",
              "countryUnCode": "LT",
              "euLocation": true
            },
            "placeOfDelivery": "ROTTERDAM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "0RP9FS1MA",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "Containers, incl. containers for the transport of fluids, sp",
                "grossWeight": 1,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "8609",
                  "description": "Containers (including containers for the transport of fluids) specially designed and equipped for carriage by one or more modes of transport"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "DTOU0064486",
                    "numberOfPackages": 1,
                    "grossWeight": 1
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 2,
                "description": "Containers, incl. containers for the transport of fluids, sp",
                "grossWeight": 1,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "8609",
                  "description": "Containers (including containers for the transport of fluids) specially designed and equipped for carriage by one or more modes of transport"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "DTOU0084142",
                    "numberOfPackages": 1,
                    "grossWeight": 1
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 3,
                "description": "Containers, incl. containers for the transport of fluids, sp",
                "grossWeight": 1,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "8609",
                  "description": "Containers (including containers for the transport of fluids) specially designed and equipped for carriage by one or more modes of transport"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "DTOU0095677",
                    "numberOfPackages": 1,
                    "grossWeight": 1
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 4,
                "description": "Containers, incl. containers for the transport of fluids, sp",
                "grossWeight": 1,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "8609",
                  "description": "Containers (including containers for the transport of fluids) specially designed and equipped for carriage by one or more modes of transport"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "DTOU0059237",
                    "numberOfPackages": 1,
                    "grossWeight": 1
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 5,
                "description": "Containers, incl. containers for the transport of fluids, sp",
                "grossWeight": 1,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "8609",
                  "description": "Containers (including containers for the transport of fluids) specially designed and equipped for carriage by one or more modes of transport"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "DTOU0084918",
                    "numberOfPackages": 1,
                    "grossWeight": 1
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 6,
                "description": "Containers, incl. containers for the transport of fluids, sp",
                "grossWeight": 1,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "8609",
                  "description": "Containers (including containers for the transport of fluids) specially designed and equipped for carriage by one or more modes of transport"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "DTOU0097746",
                    "numberOfPackages": 1,
                    "grossWeight": 1
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 7,
                "description": "Containers, incl. containers for the transport of fluids, sp",
                "grossWeight": 1,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "8609",
                  "description": "Containers (including containers for the transport of fluids) specially designed and equipped for carriage by one or more modes of transport"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "DTOU0059068",
                    "numberOfPackages": 1,
                    "grossWeight": 1
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 8,
                "description": "Containers, incl. containers for the transport of fluids, sp",
                "grossWeight": 1,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "8609",
                  "description": "Containers (including containers for the transport of fluids) specially designed and equipped for carriage by one or more modes of transport"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "DTOU0072183",
                    "numberOfPackages": 1,
                    "grossWeight": 1
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 9,
                "description": "Containers, incl. containers for the transport of fluids, sp",
                "grossWeight": 1,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "8609",
                  "description": "Containers (including containers for the transport of fluids) specially designed and equipped for carriage by one or more modes of transport"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "LBOU0112905",
                    "numberOfPackages": 1,
                    "grossWeight": 1
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 10,
                "description": "Containers, incl. containers for the transport of fluids, sp",
                "grossWeight": 1,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "8609",
                  "description": "Containers (including containers for the transport of fluids) specially designed and equipped for carriage by one or more modes of transport"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "LBOU0114235",
                    "numberOfPackages": 1,
                    "grossWeight": 1
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "CMDUNMA0110071",
            "portOfLoading": {
              "name": "Nouméa",
              "locationUnCode": "NCNOU",
              "countryUnCode": "NC",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-08-04T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": "PIER_TO_PIER",
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "CMA CGM NOUMEA",
              "address": "14 AVENUE JAMES COOK",
              "zipCode": "98805",
              "city": "NOUMEA",
              "countryCode": "NC",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "CMA CGM SEINE",
              "address": "SEINE MARINE LTD",
              "zipCode": "9934 CK",
              "city": "ROTTERDAM",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [
              {
                "eoriNumber": null,
                "name": "CMA CGM SEINE",
                "address": "SEINE MARINE LTD",
                "zipCode": "9934 CK",
                "city": "ROTTERDAM",
                "countryCode": "NL",
                "authorised": null,
                "id": null,
                "ownerShortName": null
              }
            ],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "ROTTERDAM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "0RP9FS1MA",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "Containers, incl. containers for the transport of fluids, sp",
                "grossWeight": 1,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "8609",
                  "description": "Containers (including containers for the transport of fluids) specially designed and equipped for carriage by one or more modes of transport"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "TEMU2406226",
                    "numberOfPackages": 1,
                    "grossWeight": 1
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 2,
                "description": "Containers, incl. containers for the transport of fluids, sp",
                "grossWeight": 1,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "8609",
                  "description": "Containers (including containers for the transport of fluids) specially designed and equipped for carriage by one or more modes of transport"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "FCIU3752151",
                    "numberOfPackages": 1,
                    "grossWeight": 1
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 3,
                "description": "Containers, incl. containers for the transport of fluids, sp",
                "grossWeight": 1,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "8609",
                  "description": "Containers (including containers for the transport of fluids) specially designed and equipped for carriage by one or more modes of transport"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "HJMU2110191",
                    "numberOfPackages": 1,
                    "grossWeight": 1
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 4,
                "description": "Containers, incl. containers for the transport of fluids, sp",
                "grossWeight": 1,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "8609",
                  "description": "Containers (including containers for the transport of fluids) specially designed and equipped for carriage by one or more modes of transport"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "TEMU4245434",
                    "numberOfPackages": 1,
                    "grossWeight": 1
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 5,
                "description": "Containers, incl. containers for the transport of fluids, sp",
                "grossWeight": 1,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "8609",
                  "description": "Containers (including containers for the transport of fluids) specially designed and equipped for carriage by one or more modes of transport"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "FCIU4635566",
                    "numberOfPackages": 1,
                    "grossWeight": 1
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 6,
                "description": "Containers, incl. containers for the transport of fluids, sp",
                "grossWeight": 1,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "8609",
                  "description": "Containers (including containers for the transport of fluids) specially designed and equipped for carriage by one or more modes of transport"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "TEMU2855269",
                    "numberOfPackages": 1,
                    "grossWeight": 1
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 7,
                "description": "Containers, incl. containers for the transport of fluids, sp",
                "grossWeight": 1,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "8609",
                  "description": "Containers (including containers for the transport of fluids) specially designed and equipped for carriage by one or more modes of transport"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "FCIU3214963",
                    "numberOfPackages": 1,
                    "grossWeight": 1
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 8,
                "description": "Containers, incl. containers for the transport of fluids, sp",
                "grossWeight": 1,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "8609",
                  "description": "Containers (including containers for the transport of fluids) specially designed and equipped for carriage by one or more modes of transport"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "TCLU2474563",
                    "numberOfPackages": 1,
                    "grossWeight": 1
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 9,
                "description": "Containers, incl. containers for the transport of fluids, sp",
                "grossWeight": 1,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "8609",
                  "description": "Containers (including containers for the transport of fluids) specially designed and equipped for carriage by one or more modes of transport"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "CAIU3874233",
                    "numberOfPackages": 1,
                    "grossWeight": 1
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 10,
                "description": "Containers, incl. containers for the transport of fluids, sp",
                "grossWeight": 1,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "8609",
                  "description": "Containers (including containers for the transport of fluids) specially designed and equipped for carriage by one or more modes of transport"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "APZU3778582",
                    "numberOfPackages": 1,
                    "grossWeight": 1
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 11,
                "description": "Containers, incl. containers for the transport of fluids, sp",
                "grossWeight": 1,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "8609",
                  "description": "Containers (including containers for the transport of fluids) specially designed and equipped for carriage by one or more modes of transport"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "TEMU3311022",
                    "numberOfPackages": 1,
                    "grossWeight": 1
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 12,
                "description": "Containers, incl. containers for the transport of fluids, sp",
                "grossWeight": 1,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "8609",
                  "description": "Containers (including containers for the transport of fluids) specially designed and equipped for carriage by one or more modes of transport"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "FCIU3426896",
                    "numberOfPackages": 1,
                    "grossWeight": 1
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 13,
                "description": "Containers, incl. containers for the transport of fluids, sp",
                "grossWeight": 1,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "8609",
                  "description": "Containers (including containers for the transport of fluids) specially designed and equipped for carriage by one or more modes of transport"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "SEGU1750821",
                    "numberOfPackages": 1,
                    "grossWeight": 1
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 14,
                "description": "Containers, incl. containers for the transport of fluids, sp",
                "grossWeight": 1,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "8609",
                  "description": "Containers (including containers for the transport of fluids) specially designed and equipped for carriage by one or more modes of transport"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "TGCU2064132",
                    "numberOfPackages": 1,
                    "grossWeight": 1
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 15,
                "description": "Containers, incl. containers for the transport of fluids, sp",
                "grossWeight": 1,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "8609",
                  "description": "Containers (including containers for the transport of fluids) specially designed and equipped for carriage by one or more modes of transport"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "APZU3454599",
                    "numberOfPackages": 1,
                    "grossWeight": 1
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 16,
                "description": "Containers, incl. containers for the transport of fluids, sp",
                "grossWeight": 1,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "8609",
                  "description": "Containers (including containers for the transport of fluids) specially designed and equipped for carriage by one or more modes of transport"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "APZU3828683",
                    "numberOfPackages": 1,
                    "grossWeight": 1
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 17,
                "description": "Containers, incl. containers for the transport of fluids, sp",
                "grossWeight": 1,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "8609",
                  "description": "Containers (including containers for the transport of fluids) specially designed and equipped for carriage by one or more modes of transport"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "CAIU3393773",
                    "numberOfPackages": 1,
                    "grossWeight": 1
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 18,
                "description": "Containers, incl. containers for the transport of fluids, sp",
                "grossWeight": 1,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "8609",
                  "description": "Containers (including containers for the transport of fluids) specially designed and equipped for carriage by one or more modes of transport"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "CMAU0747310",
                    "numberOfPackages": 1,
                    "grossWeight": 1
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 19,
                "description": "Containers, incl. containers for the transport of fluids, sp",
                "grossWeight": 1,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "8609",
                  "description": "Containers (including containers for the transport of fluids) specially designed and equipped for carriage by one or more modes of transport"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "CMAU0804385",
                    "numberOfPackages": 1,
                    "grossWeight": 1
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 20,
                "description": "Containers, incl. containers for the transport of fluids, sp",
                "grossWeight": 1,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "8609",
                  "description": "Containers (including containers for the transport of fluids) specially designed and equipped for carriage by one or more modes of transport"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "CMAU0942419",
                    "numberOfPackages": 1,
                    "grossWeight": 1
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 21,
                "description": "Containers, incl. containers for the transport of fluids, sp",
                "grossWeight": 1,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "8609",
                  "description": "Containers (including containers for the transport of fluids) specially designed and equipped for carriage by one or more modes of transport"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "FCIU3264943",
                    "numberOfPackages": 1,
                    "grossWeight": 1
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 22,
                "description": "Containers, incl. containers for the transport of fluids, sp",
                "grossWeight": 1,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "8609",
                  "description": "Containers (including containers for the transport of fluids) specially designed and equipped for carriage by one or more modes of transport"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "MAGU2273884",
                    "numberOfPackages": 1,
                    "grossWeight": 1
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 23,
                "description": "Containers, incl. containers for the transport of fluids, sp",
                "grossWeight": 1,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "8609",
                  "description": "Containers (including containers for the transport of fluids) specially designed and equipped for carriage by one or more modes of transport"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "TCKU1065943",
                    "numberOfPackages": 1,
                    "grossWeight": 1
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 24,
                "description": "Containers, incl. containers for the transport of fluids, sp",
                "grossWeight": 1,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "8609",
                  "description": "Containers (including containers for the transport of fluids) specially designed and equipped for carriage by one or more modes of transport"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "TCKU1075005",
                    "numberOfPackages": 1,
                    "grossWeight": 1
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 25,
                "description": "Containers, incl. containers for the transport of fluids, sp",
                "grossWeight": 1,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "8609",
                  "description": "Containers (including containers for the transport of fluids) specially designed and equipped for carriage by one or more modes of transport"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "TCLU3722089",
                    "numberOfPackages": 1,
                    "grossWeight": 1
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 26,
                "description": "Containers, incl. containers for the transport of fluids, sp",
                "grossWeight": 1,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "8609",
                  "description": "Containers (including containers for the transport of fluids) specially designed and equipped for carriage by one or more modes of transport"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "TEMU0035867",
                    "numberOfPackages": 1,
                    "grossWeight": 1
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 27,
                "description": "Containers, incl. containers for the transport of fluids, sp",
                "grossWeight": 1,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "8609",
                  "description": "Containers (including containers for the transport of fluids) specially designed and equipped for carriage by one or more modes of transport"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "TEMU1022416",
                    "numberOfPackages": 1,
                    "grossWeight": 1
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 28,
                "description": "Containers, incl. containers for the transport of fluids, sp",
                "grossWeight": 1,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "8609",
                  "description": "Containers (including containers for the transport of fluids) specially designed and equipped for carriage by one or more modes of transport"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "TEMU2385397",
                    "numberOfPackages": 1,
                    "grossWeight": 1
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 29,
                "description": "Containers, incl. containers for the transport of fluids, sp",
                "grossWeight": 1,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "8609",
                  "description": "Containers (including containers for the transport of fluids) specially designed and equipped for carriage by one or more modes of transport"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "TEMU2523454",
                    "numberOfPackages": 1,
                    "grossWeight": 1
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 30,
                "description": "Containers, incl. containers for the transport of fluids, sp",
                "grossWeight": 1,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "8609",
                  "description": "Containers (including containers for the transport of fluids) specially designed and equipped for carriage by one or more modes of transport"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "TEMU2552750",
                    "numberOfPackages": 1,
                    "grossWeight": 1
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 31,
                "description": "Containers, incl. containers for the transport of fluids, sp",
                "grossWeight": 1,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "8609",
                  "description": "Containers (including containers for the transport of fluids) specially designed and equipped for carriage by one or more modes of transport"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "TGHU1694650",
                    "numberOfPackages": 1,
                    "grossWeight": 1
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 32,
                "description": "Containers, incl. containers for the transport of fluids, sp",
                "grossWeight": 1,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "8609",
                  "description": "Containers (including containers for the transport of fluids) specially designed and equipped for carriage by one or more modes of transport"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "TRHU1071013",
                    "numberOfPackages": 1,
                    "grossWeight": 1
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 33,
                "description": "Containers, incl. containers for the transport of fluids, sp",
                "grossWeight": 1,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "8609",
                  "description": "Containers (including containers for the transport of fluids) specially designed and equipped for carriage by one or more modes of transport"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "TRHU1083971",
                    "numberOfPackages": 1,
                    "grossWeight": 1
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "CMDUNPL0105940",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "movementReferenceNumbers": [
              "21BEN0000013964812"
            ],
            "partShipment": false,
            "movementType": "PIER_TO_PIER",
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": {
              "rate": 1.1805,
              "currency": {
                "unCode": "USD",
                "description": "U.S.A.-dollar"
              }
            },
            "consignor": {
              "eoriNumber": null,
              "name": "NATURAL WATERS OF VITI PTE LTD",
              "address": "LOT 1 NAIKABULA ROAD,",
              "zipCode": ".",
              "city": "LAUTOKA",
              "countryCode": "FJ",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "B & S GLOBAL TRANSIT CENTER B V",
              "address": "SNELLIUSSTRAAT 12",
              "zipCode": "3316 GV",
              "city": "DORDRECHT",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [
              {
                "eoriNumber": null,
                "name": "B & S GLOBAL TRANSIT CENTER B V",
                "address": "SNELLIUSSTRAAT 12",
                "zipCode": "3316 GV",
                "city": "DORDRECHT",
                "countryCode": "NL",
                "authorised": null,
                "id": null,
                "ownerShortName": null
              }
            ],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "ROTTERDAM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "0RP9FS1MA",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "Waters, including mineral waters and aerated waters, contain",
                "grossWeight": 18890,
                "numberOfOuterPackages": 1484,
                "outerPackageType": {
                  "code": "CS",
                  "name": "Case",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "2202",
                  "description": "Waters, including mineral waters and aerated waters, containing added sugar or other sweetening matter or flavoured, and other non-alcoholic beverages, not including fruit or vegetable juices of heading|2009"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "DFSU3116410",
                    "numberOfPackages": 1484,
                    "grossWeight": 18890
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "CMDUPPT0112894",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "movementReferenceNumbers": [
              "21BEN0000012796210"
            ],
            "partShipment": false,
            "movementType": "PIER_TO_PIER",
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "JEAN LEFEVRE POLYNESIE",
              "address": "BP 380622",
              "zipCode": "98703",
              "city": "PUNAAUIA",
              "countryCode": "PF",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "TRANSPORT JOOSEN",
              "address": "ANTWERPSEBAAN 50",
              "zipCode": "2040",
              "city": "ANTWERPEN",
              "countryCode": "BE",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [
              {
                "eoriNumber": null,
                "name": "TRANSPORT JOOSEN",
                "address": "ANTWERPSEBAAN 50",
                "zipCode": "2040",
                "city": "ANTWERPEN",
                "countryCode": "BE",
                "authorised": null,
                "id": null,
                "ownerShortName": null
              }
            ],
            "placeOfOrigin": null,
            "placeOfDestination": {
              "name": "Antwerpen",
              "locationUnCode": "BEANR",
              "countryUnCode": "BE",
              "euLocation": true
            },
            "placeOfDelivery": "ROTTERDAM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "0RP9FS1MA",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "Containers, incl. containers for the transport of fluids, sp",
                "grossWeight": 1,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "8609",
                  "description": "Containers (including containers for the transport of fluids) specially designed and equipped for carriage by one or more modes of transport"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "DTOU0069908",
                    "numberOfPackages": 1,
                    "grossWeight": 1
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 2,
                "description": "Containers, incl. containers for the transport of fluids, sp",
                "grossWeight": 1,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "8609",
                  "description": "Containers (including containers for the transport of fluids) specially designed and equipped for carriage by one or more modes of transport"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "DTOU0082601",
                    "numberOfPackages": 1,
                    "grossWeight": 1
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 3,
                "description": "Containers, incl. containers for the transport of fluids, sp",
                "grossWeight": 1,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "8609",
                  "description": "Containers (including containers for the transport of fluids) specially designed and equipped for carriage by one or more modes of transport"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "DTOU0084625",
                    "numberOfPackages": 1,
                    "grossWeight": 1
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "CMDUPPT0113496",
            "portOfLoading": {
              "name": "Zeebrugge",
              "locationUnCode": "BEZEE",
              "countryUnCode": "BE",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "movementReferenceNumbers": [
              "21BEN0000012796228"
            ],
            "partShipment": false,
            "movementType": "PIER_TO_PIER",
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "LOGISTIQUE ET TRANSIT POLYNESIE",
              "address": "IMMEUBLE LE CAILL FARE UTE",
              "zipCode": "98713",
              "city": "PAPEETE",
              "countryCode": "PF",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "DSV AIR & SEA NEDERLAND B.V",
              "address": "DONKER DUYVISWEG 70",
              "zipCode": "3316 BL",
              "city": "DORDRECHT",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [
              {
                "eoriNumber": null,
                "name": "DSV AIR & SEA NEDERLAND B.V",
                "address": "DONKER DUYVISWEG 70",
                "zipCode": "3316 BL",
                "city": "DORDRECHT",
                "countryCode": "NL",
                "authorised": null,
                "id": null,
                "ownerShortName": null
              }
            ],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "ROTTERDAM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "0RP9FS1MA",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "COMPRESSOR MODULE, GEN COMPRESSOR ACC. CRATE",
                "grossWeight": 377,
                "numberOfOuterPackages": 2,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "CMAU0527017",
                    "numberOfPackages": 2,
                    "grossWeight": 377
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "CMDUPPT0113576",
            "portOfLoading": {
              "name": "Papeete",
              "locationUnCode": "PFPPT",
              "countryUnCode": "PF",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-07-28T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "movementReferenceNumbers": [
              "21BEN0000013411426"
            ],
            "partShipment": false,
            "movementType": "PIER_TO_PIER",
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "JEAN LEFEVRE POLYNESIE",
              "address": "BP 380622",
              "zipCode": "98703",
              "city": "PUNAAUIA",
              "countryCode": "PF",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "TRANSPORT JOOSEN",
              "address": "ANTWERPSEBAAN 50",
              "zipCode": "2040",
              "city": "ANTWERPEN",
              "countryCode": "BE",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [
              {
                "eoriNumber": null,
                "name": "TRANSPORT JOOSEN",
                "address": "ANTWERPSEBAAN 50",
                "zipCode": "2040",
                "city": "ANTWERPEN",
                "countryCode": "BE",
                "authorised": null,
                "id": null,
                "ownerShortName": null
              }
            ],
            "placeOfOrigin": null,
            "placeOfDestination": {
              "name": "Antwerpen",
              "locationUnCode": "BEANR",
              "countryUnCode": "BE",
              "euLocation": true
            },
            "placeOfDelivery": "ROTTERDAM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "0RP9FS1MA",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "Containers, incl. containers for the transport of fluids, sp",
                "grossWeight": 1,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "8609",
                  "description": "Containers (including containers for the transport of fluids) specially designed and equipped for carriage by one or more modes of transport"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "DTOU0023527",
                    "numberOfPackages": 1,
                    "grossWeight": 1
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 2,
                "description": "Containers, incl. containers for the transport of fluids, sp",
                "grossWeight": 1,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "8609",
                  "description": "Containers (including containers for the transport of fluids) specially designed and equipped for carriage by one or more modes of transport"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "DTOU0067607",
                    "numberOfPackages": 1,
                    "grossWeight": 1
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 3,
                "description": "Containers, incl. containers for the transport of fluids, sp",
                "grossWeight": 1,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "8609",
                  "description": "Containers (including containers for the transport of fluids) specially designed and equipped for carriage by one or more modes of transport"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "DTOU0076635",
                    "numberOfPackages": 1,
                    "grossWeight": 1
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 4,
                "description": "Containers, incl. containers for the transport of fluids, sp",
                "grossWeight": 1,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "8609",
                  "description": "Containers (including containers for the transport of fluids) specially designed and equipped for carriage by one or more modes of transport"
                },
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "DTOU0006432",
                    "numberOfPackages": 1,
                    "grossWeight": 1
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "CMDUPSP0163519",
            "portOfLoading": {
              "name": "Kingston",
              "locationUnCode": "JMKIN",
              "countryUnCode": "JM",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-11T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": "PIER_TO_PIER",
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "GULF SHIPPING LIMITED",
              "address": "LLOYD VOISIN BUILDING",
              "zipCode": ".",
              "city": "PORT-OF-SPAIN",
              "countryCode": "TT",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "SLAVENBURG & HUYSER BV",
              "address": "VASTELAND 38J",
              "zipCode": "3011 BM",
              "city": "ROTTERDAM",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [
              {
                "eoriNumber": null,
                "name": "SLAVENBURG & HUYSER BV",
                "address": "VASTELAND 38J",
                "zipCode": "3011 BM",
                "city": "ROTTERDAM",
                "countryCode": "NL",
                "authorised": null,
                "id": null,
                "ownerShortName": null
              }
            ],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "ROTTERDAM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "0RP9FS1MA",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "PERSONAL EFFECTS CONSISTING OF SHOES,CLOTHES,BOOKS, KITCHENWARE,DISHES,FIBREBOARD",
                "grossWeight": 4717,
                "numberOfOuterPackages": 120,
                "outerPackageType": {
                  "code": "4G",
                  "name": "Box, fibreboard",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "SEGU4903328",
                    "numberOfPackages": 120,
                    "grossWeight": 4717
                  }
                ],
                "producedDocuments": []
              }
            ]
          }
        ],
        "receivedMrns": {},
        "discharges": [
          {
            "number": "TCNU2628368",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T05:48:00Z",
            "stowageLocation": "0060112",
            "grossWeight": 12000,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "CMAU7636160",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T05:50:00Z",
            "stowageLocation": "0060012",
            "grossWeight": 25700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "APHU6889720",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T05:52:00Z",
            "stowageLocation": "0060110",
            "grossWeight": 24370,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "LBOU0112905",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T05:54:00Z",
            "stowageLocation": "0050312",
            "grossWeight": 3000,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "25K1",
              "name": "20'x8'x>8'6\" Bulk container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "LBOU0114235",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T05:54:00Z",
            "stowageLocation": "0070312",
            "grossWeight": 3000,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "25K1",
              "name": "20'x8'x>8'6\" Bulk container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "DTOU0095677",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T05:56:00Z",
            "stowageLocation": "0050310",
            "grossWeight": 3000,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22T0",
              "name": "20'x8'x8'6\" Tank Container Test pressure 0,45 bar"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "DTOU0072183",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T05:58:00Z",
            "stowageLocation": "0050308",
            "grossWeight": 3000,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22T0",
              "name": "20'x8'x8'6\" Tank Container Test pressure 0,45 bar"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "DTOU0084918",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T05:58:00Z",
            "stowageLocation": "0070308",
            "grossWeight": 3000,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22T0",
              "name": "20'x8'x8'6\" Tank Container Test pressure 0,45 bar"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "DTOU0059068",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T05:56:00Z",
            "stowageLocation": "0070310",
            "grossWeight": 3000,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22T0",
              "name": "20'x8'x8'6\" Tank Container Test pressure 0,45 bar"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "DTOU0084142",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T06:01:00Z",
            "stowageLocation": "0070306",
            "grossWeight": 3000,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22T0",
              "name": "20'x8'x8'6\" Tank Container Test pressure 0,45 bar"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "DTOU0059237",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T06:01:00Z",
            "stowageLocation": "0050306",
            "grossWeight": 3000,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22T0",
              "name": "20'x8'x8'6\" Tank Container Test pressure 0,45 bar"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "DTOU0064486",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T06:03:00Z",
            "stowageLocation": "0070304",
            "grossWeight": 3000,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22T0",
              "name": "20'x8'x8'6\" Tank Container Test pressure 0,45 bar"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "DTOU0097746",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T06:03:00Z",
            "stowageLocation": "0050304",
            "grossWeight": 3000,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22T0",
              "name": "20'x8'x8'6\" Tank Container Test pressure 0,45 bar"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "BMOU9628622",
            "portOfLoading": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T06:17:00Z",
            "stowageLocation": "0101186",
            "grossWeight": 4700,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TEMU9577489",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T06:19:00Z",
            "stowageLocation": "0100986",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TTNU8962035",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T06:20:00Z",
            "stowageLocation": "0100786",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TEMU9574345",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T06:21:00Z",
            "stowageLocation": "0100586",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TTNU8950836",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T06:23:00Z",
            "stowageLocation": "0100386",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "SEKU9060998",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T06:27:00Z",
            "stowageLocation": "0100186",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "BMOU9620658",
            "portOfLoading": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T06:28:00Z",
            "stowageLocation": "0100286",
            "grossWeight": 4700,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "SEKU9060391",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T06:30:00Z",
            "stowageLocation": "0100486",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "SEKU9060806",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T06:32:00Z",
            "stowageLocation": "0100686",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "SEGU9406047",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T06:33:00Z",
            "stowageLocation": "0100886",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TTNU8950610",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T06:35:00Z",
            "stowageLocation": "0101086",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TTNU8960942",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T06:39:00Z",
            "stowageLocation": "0101286",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "SEGU9413302",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T06:41:00Z",
            "stowageLocation": "0101184",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "BMOU9614660",
            "portOfLoading": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T06:42:00Z",
            "stowageLocation": "0100984",
            "grossWeight": 4700,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "SEGU9423316",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T06:45:00Z",
            "stowageLocation": "0100584",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TTNU8962890",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T06:44:00Z",
            "stowageLocation": "0100784",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TTNU8956562",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T06:47:00Z",
            "stowageLocation": "0100384",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TTNU8963751",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T06:48:00Z",
            "stowageLocation": "0100184",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TEMU9575280",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T06:50:00Z",
            "stowageLocation": "0100284",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "BMOU9620663",
            "portOfLoading": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T06:52:00Z",
            "stowageLocation": "0100484",
            "grossWeight": 4700,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "SEGU9411906",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T06:55:00Z",
            "stowageLocation": "0100884",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TTNU8950327",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T06:54:00Z",
            "stowageLocation": "0100684",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "SEGU9413323",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T06:57:00Z",
            "stowageLocation": "0101084",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TTNU8957358",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T06:59:00Z",
            "stowageLocation": "0101284",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "SEGU9423086",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T07:00:00Z",
            "stowageLocation": "0101182",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "BMOU9623066",
            "portOfLoading": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T07:02:00Z",
            "stowageLocation": "0101282",
            "grossWeight": 4700,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "BMOU9622244",
            "portOfLoading": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T07:05:00Z",
            "stowageLocation": "0100782",
            "grossWeight": 4700,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TTNU8956602",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T07:04:00Z",
            "stowageLocation": "0100982",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TTNU8961255",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T07:07:00Z",
            "stowageLocation": "0100582",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TTNU8963999",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T07:08:00Z",
            "stowageLocation": "0100382",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TTNU8960027",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T07:11:00Z",
            "stowageLocation": "0100282",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TTNU8963371",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T07:10:00Z",
            "stowageLocation": "0100182",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "BMOU9628812",
            "portOfLoading": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T07:13:00Z",
            "stowageLocation": "0100482",
            "grossWeight": 4700,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "SEGU9405138",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T07:15:00Z",
            "stowageLocation": "0100682",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "SZLU9222742",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T07:16:00Z",
            "stowageLocation": "0100882",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TTNU8962544",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T07:18:00Z",
            "stowageLocation": "0101082",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "SEKU9011144",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T08:08:00Z",
            "stowageLocation": "0180186",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TEMU9576728",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T08:11:00Z",
            "stowageLocation": "0180184",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "SZLU9225649",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T08:10:00Z",
            "stowageLocation": "0180286",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TTNU8956789",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T08:13:00Z",
            "stowageLocation": "0180284",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "CAIU5659733",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T08:15:00Z",
            "stowageLocation": "0180182",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "SZLU9225736",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T08:17:00Z",
            "stowageLocation": "0180282",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "SEGU4903328",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T08:31:00Z",
            "stowageLocation": "0180908",
            "grossWeight": 8547,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TEMU9578269",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T08:33:00Z",
            "stowageLocation": "0180110",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "SEGU9414741",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T08:35:00Z",
            "stowageLocation": "0180010",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "CMAU4885050",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T08:37:00Z",
            "stowageLocation": "0180210",
            "grossWeight": 9051,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TCLU5161773",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T08:43:00Z",
            "stowageLocation": "0180906",
            "grossWeight": 24456,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "BMOU4045469",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T08:41:00Z",
            "stowageLocation": "0181010",
            "grossWeight": 8200,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "CAIU5658362",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T08:45:00Z",
            "stowageLocation": "0180108",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TTNU8961337",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T08:47:00Z",
            "stowageLocation": "0180008",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TCNU5361892",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T08:48:00Z",
            "stowageLocation": "0180208",
            "grossWeight": 9651,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TCLU1523848",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T08:52:00Z",
            "stowageLocation": "0181008",
            "grossWeight": 10615,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "CMAU7183844",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T08:54:00Z",
            "stowageLocation": "0180904",
            "grossWeight": 28806,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TEMU9577318",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T08:56:00Z",
            "stowageLocation": "0180106",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "BMOU9622856",
            "portOfLoading": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T08:59:00Z",
            "stowageLocation": "0180006",
            "grossWeight": 4700,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "BEAU4044853",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T09:01:00Z",
            "stowageLocation": "0180206",
            "grossWeight": 9807,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "FSCU7138984",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T09:09:00Z",
            "stowageLocation": "0180910",
            "grossWeight": 7482,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TTNU8952470",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T09:12:00Z",
            "stowageLocation": "0180104",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TEMU9577560",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T09:14:00Z",
            "stowageLocation": "0180004",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "ECMU9516720",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T09:16:00Z",
            "stowageLocation": "0180204",
            "grossWeight": 10812,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "SEGU9414675",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T09:24:00Z",
            "stowageLocation": "0180102",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TTNU8952989",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T09:26:00Z",
            "stowageLocation": "0180002",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "SEKU4336018",
            "portOfLoading": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T10:03:00Z",
            "stowageLocation": "0180202",
            "grossWeight": 23223,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "CARU3715217",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T10:16:00Z",
            "stowageLocation": "0030984",
            "grossWeight": 14683,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "DFSU2090590",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T10:19:00Z",
            "stowageLocation": "0030982",
            "grossWeight": 6700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "APHU6768271",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T10:43:00Z",
            "stowageLocation": "0100912",
            "grossWeight": 9621,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "BMOU9601175",
            "portOfLoading": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T10:45:00Z",
            "stowageLocation": "0100512",
            "grossWeight": 4700,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TEMU9577410",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T10:46:00Z",
            "stowageLocation": "0100412",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "SEGU9415265",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T10:48:00Z",
            "stowageLocation": "0100612",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TCNU8563990",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T10:50:00Z",
            "stowageLocation": "0101012",
            "grossWeight": 11812,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "APHU7060862",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T10:56:00Z",
            "stowageLocation": "0100910",
            "grossWeight": 9561,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "CMAU6284059",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T10:56:00Z",
            "stowageLocation": "0260906",
            "grossWeight": 12680,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "BMOU9621382",
            "portOfLoading": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T10:59:00Z",
            "stowageLocation": "0100710",
            "grossWeight": 4700,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "BMOU9624437",
            "portOfLoading": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T11:00:00Z",
            "stowageLocation": "0100510",
            "grossWeight": 4700,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "SEKU9064545",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T11:02:00Z",
            "stowageLocation": "0100310",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "CMAU7896104",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T11:02:00Z",
            "stowageLocation": "0260904",
            "grossWeight": 22600,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "BMOU9616299",
            "portOfLoading": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T11:03:00Z",
            "stowageLocation": "0100110",
            "grossWeight": 4700,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TTNU8950477",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T11:05:00Z",
            "stowageLocation": "0100010",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "CMAU0747310",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T11:07:00Z",
            "stowageLocation": "0270704",
            "grossWeight": 2200,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TGHU1694650",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T11:07:00Z",
            "stowageLocation": "0250704",
            "grossWeight": 2200,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TEMU1022416",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T11:09:00Z",
            "stowageLocation": "0250504",
            "grossWeight": 2200,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TCKU1075005",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T11:09:00Z",
            "stowageLocation": "0270504",
            "grossWeight": 2200,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TTNU8959279",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T11:11:00Z",
            "stowageLocation": "0100210",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TCLU3722089",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T11:11:00Z",
            "stowageLocation": "0270304",
            "grossWeight": 2200,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "APZU3828683",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T11:11:00Z",
            "stowageLocation": "0250304",
            "grossWeight": 2200,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TTNU8662969",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T11:12:00Z",
            "stowageLocation": "0100410",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "SZLU9222871",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T11:14:00Z",
            "stowageLocation": "0100610",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "MAGU2273884",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T11:17:00Z",
            "stowageLocation": "0250004",
            "grossWeight": 2200,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TEMU4245434",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T11:17:00Z",
            "stowageLocation": "0270004",
            "grossWeight": 2200,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "HJMU2110191",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T11:14:00Z",
            "stowageLocation": "0250104",
            "grossWeight": 2200,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TEMU3311022",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T11:14:00Z",
            "stowageLocation": "0270104",
            "grossWeight": 2200,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "SZLU9224149",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T11:17:00Z",
            "stowageLocation": "0100810",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "APHU6358338",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T11:19:00Z",
            "stowageLocation": "0101010",
            "grossWeight": 9621,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TEMU2385397",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T11:19:00Z",
            "stowageLocation": "0250204",
            "grossWeight": 2200,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TEMU2552750",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T11:19:00Z",
            "stowageLocation": "0270204",
            "grossWeight": 2200,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "BMOU9619662",
            "portOfLoading": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T11:21:00Z",
            "stowageLocation": "0100708",
            "grossWeight": 4700,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "CMAU0942419",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T11:21:00Z",
            "stowageLocation": "0270404",
            "grossWeight": 2200,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TGCU2064132",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T11:21:00Z",
            "stowageLocation": "0250404",
            "grossWeight": 2200,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "BMOU9602037",
            "portOfLoading": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T11:22:00Z",
            "stowageLocation": "0100508",
            "grossWeight": 4700,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "SEKU9060405",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T11:24:00Z",
            "stowageLocation": "0100308",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "BMOU9614110",
            "portOfLoading": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T11:26:00Z",
            "stowageLocation": "0100108",
            "grossWeight": 4700,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TEMU2855269",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T11:26:00Z",
            "stowageLocation": "0250604",
            "grossWeight": 2200,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "SEGU1750821",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T11:26:00Z",
            "stowageLocation": "0270604",
            "grossWeight": 2200,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "APZU3778582",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T11:28:00Z",
            "stowageLocation": "0270804",
            "grossWeight": 2200,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "CAIU3874233",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T11:28:00Z",
            "stowageLocation": "0250804",
            "grossWeight": 2200,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TEMU2523454",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T11:30:00Z",
            "stowageLocation": "0270702",
            "grossWeight": 2200,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "APZU3454599",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T11:30:00Z",
            "stowageLocation": "0250702",
            "grossWeight": 2200,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "SZLU9225485",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T11:30:00Z",
            "stowageLocation": "0100008",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "SZLU9222721",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T11:32:00Z",
            "stowageLocation": "0100208",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TRHU1083971",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T11:32:00Z",
            "stowageLocation": "0250502",
            "grossWeight": 2200,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "LCRU2024025",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T11:32:00Z",
            "stowageLocation": "0270502",
            "grossWeight": 5720,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "FCIU2736053",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T11:35:00Z",
            "stowageLocation": "0270302",
            "grossWeight": 10060,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "SZLU9226970",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T11:35:00Z",
            "stowageLocation": "0100408",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "CAIU5660288",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T11:37:00Z",
            "stowageLocation": "0100608",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "BMOU9601405",
            "portOfLoading": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T11:39:00Z",
            "stowageLocation": "0100808",
            "grossWeight": 4700,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "FCIU3752151",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T11:38:00Z",
            "stowageLocation": "0270102",
            "grossWeight": 2200,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TEMU0035867",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T11:41:00Z",
            "stowageLocation": "0250002",
            "grossWeight": 2200,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "BMOU9615332",
            "portOfLoading": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T11:41:00Z",
            "stowageLocation": "0100706",
            "grossWeight": 4700,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TEMU2406226",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T11:41:00Z",
            "stowageLocation": "0270002",
            "grossWeight": 2200,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TRHU1071013",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T11:43:00Z",
            "stowageLocation": "0250202",
            "grossWeight": 2200,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TCKU1065943",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T11:43:00Z",
            "stowageLocation": "0270202",
            "grossWeight": 2200,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "BMOU9603795",
            "portOfLoading": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T11:42:00Z",
            "stowageLocation": "0100506",
            "grossWeight": 4700,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "BMOU9614655",
            "portOfLoading": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T11:44:00Z",
            "stowageLocation": "0100306",
            "grossWeight": 4700,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TTNU8954826",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T11:46:00Z",
            "stowageLocation": "0100106",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "BMOU9600116",
            "portOfLoading": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T11:48:00Z",
            "stowageLocation": "0100006",
            "grossWeight": 4700,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "SEGU9412348",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T11:50:00Z",
            "stowageLocation": "0100206",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "BMOU9624381",
            "portOfLoading": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T11:52:00Z",
            "stowageLocation": "0100406",
            "grossWeight": 4700,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "CMAU0804385",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T11:52:00Z",
            "stowageLocation": "0270402",
            "grossWeight": 2200,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "FCIU3264943",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T11:52:00Z",
            "stowageLocation": "0250402",
            "grossWeight": 2200,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "CAIU3393773",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T11:55:00Z",
            "stowageLocation": "0270602",
            "grossWeight": 2200,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "FCIU3426896",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T11:55:00Z",
            "stowageLocation": "0250602",
            "grossWeight": 2200,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "BMOU9627040",
            "portOfLoading": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T11:54:00Z",
            "stowageLocation": "0100606",
            "grossWeight": 4700,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "BMOU9603809",
            "portOfLoading": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T11:56:00Z",
            "stowageLocation": "0100806",
            "grossWeight": 4700,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "FCIU3214963",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T11:58:00Z",
            "stowageLocation": "0270802",
            "grossWeight": 2200,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TCLU2474563",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T11:58:00Z",
            "stowageLocation": "0250802",
            "grossWeight": 2200,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "SEGU9406704",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T11:58:00Z",
            "stowageLocation": "0100704",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "BMOU9625829",
            "portOfLoading": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T11:59:00Z",
            "stowageLocation": "0100104",
            "grossWeight": 4700,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TTNU8961131",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T12:01:00Z",
            "stowageLocation": "0100004",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "BMOU9630337",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T12:03:00Z",
            "stowageLocation": "0100204",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "CAIU5658969",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T12:05:00Z",
            "stowageLocation": "0100804",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "BMOU9615590",
            "portOfLoading": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T12:07:00Z",
            "stowageLocation": "0100102",
            "grossWeight": 4700,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "SEKU9060982",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T12:10:00Z",
            "stowageLocation": "0100002",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TEMU9578654",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T12:12:00Z",
            "stowageLocation": "0100202",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "APZU4866625",
            "portOfLoading": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T12:13:00Z",
            "stowageLocation": "0220912",
            "grossWeight": 25590,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "42G1",
              "name": "40'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TLLU2756061",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T12:15:00Z",
            "stowageLocation": "0110504",
            "grossWeight": 26230,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "CAIU3468445",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T12:15:00Z",
            "stowageLocation": "0090504",
            "grossWeight": 26200,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "GLDU3525518",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T12:17:00Z",
            "stowageLocation": "0110304",
            "grossWeight": 26185,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TTNU1159356",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T12:17:00Z",
            "stowageLocation": "0090304",
            "grossWeight": 16200,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "DFSU3116410",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T12:20:00Z",
            "stowageLocation": "0110404",
            "grossWeight": 20991,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "APZU2132730",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T12:20:00Z",
            "stowageLocation": "0090404",
            "grossWeight": 25820,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TLLU2760082",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T12:22:00Z",
            "stowageLocation": "0110604",
            "grossWeight": 26000,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "CMAU3010901",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T12:22:00Z",
            "stowageLocation": "0090604",
            "grossWeight": 25630,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TRHU3089623",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T12:27:00Z",
            "stowageLocation": "0110402",
            "grossWeight": 25940,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "CMAU1698631",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T12:25:00Z",
            "stowageLocation": "0110302",
            "grossWeight": 16000,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "CMAU3199190",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T12:25:00Z",
            "stowageLocation": "0090302",
            "grossWeight": 26100,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TLLU2770795",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T12:27:00Z",
            "stowageLocation": "0090402",
            "grossWeight": 26060,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "DTOU0067607",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T12:34:00Z",
            "stowageLocation": "0110908",
            "grossWeight": 2630,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22T0",
              "name": "20'x8'x8'6\" Tank Container Test pressure 0,45 bar"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TEMU2264815",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T12:39:00Z",
            "stowageLocation": "0110502",
            "grossWeight": 26160,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "DTOU0076635",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T12:37:00Z",
            "stowageLocation": "0111008",
            "grossWeight": 2630,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22T0",
              "name": "20'x8'x8'6\" Tank Container Test pressure 0,45 bar"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "DTOU0006432",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T12:43:00Z",
            "stowageLocation": "0110602",
            "grossWeight": 2630,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22T0",
              "name": "20'x8'x8'6\" Tank Container Test pressure 0,45 bar"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "BLKU2585468",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T12:56:00Z",
            "stowageLocation": "0230604",
            "grossWeight": 25150,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22K2",
              "name": "20 x 8,6 Liquid tank dangerous gds 2.65-10 bar"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "BAFU8890370",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T12:56:00Z",
            "stowageLocation": "0210604",
            "grossWeight": 25700,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22T6",
              "name": "20'x8'x8'6\" Tank Container Test pressure 6 bar"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "BLKU2520467",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T13:13:00Z",
            "stowageLocation": "0210602",
            "grossWeight": 25000,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22T6",
              "name": "20'x8'x8'6\" Tank Container Test pressure 6 bar"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "BRKU1254471",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T13:13:00Z",
            "stowageLocation": "0230602",
            "grossWeight": 25150,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22K2",
              "name": "20 x 8,6 Liquid tank dangerous gds 2.65-10 bar"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TCNU5147371",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T13:22:00Z",
            "stowageLocation": "0220402",
            "grossWeight": 28080,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "CGMU5177579",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T16:08:00Z",
            "stowageLocation": "0301190",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "SZLU9225171",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T16:09:00Z",
            "stowageLocation": "0300990",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "SZLU9225269",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T16:11:00Z",
            "stowageLocation": "0300790",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "SEKU9014781",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T16:13:00Z",
            "stowageLocation": "0300890",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "BMOU9628520",
            "portOfLoading": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T16:14:00Z",
            "stowageLocation": "0301090",
            "grossWeight": 4700,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "SEGU9416240",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T16:27:00Z",
            "stowageLocation": "0301290",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "BMOU9615461",
            "portOfLoading": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T16:28:00Z",
            "stowageLocation": "0301188",
            "grossWeight": 4700,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "SEGU9416560",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T16:30:00Z",
            "stowageLocation": "0300988",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TTNU8958014",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T16:31:00Z",
            "stowageLocation": "0300788",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "CGMU5401760",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T16:33:00Z",
            "stowageLocation": "0300588",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TTNU8962863",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T16:34:00Z",
            "stowageLocation": "0300688",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TTNU8703815",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T16:36:00Z",
            "stowageLocation": "0300888",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "CGMU5224963",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T16:37:00Z",
            "stowageLocation": "0301088",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TTNU8964090",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T16:39:00Z",
            "stowageLocation": "0301288",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "BMOU9630980",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T16:41:00Z",
            "stowageLocation": "0301186",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "SEGU9414736",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T16:42:00Z",
            "stowageLocation": "0300986",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "BMOU9623343",
            "portOfLoading": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T16:44:00Z",
            "stowageLocation": "0300786",
            "grossWeight": 4700,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TRIU8611040",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T16:45:00Z",
            "stowageLocation": "0300586",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "BMOU9630949",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T16:47:00Z",
            "stowageLocation": "0300686",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "SEGU9413703",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T16:48:00Z",
            "stowageLocation": "0300886",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TRIU8691410",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T16:50:00Z",
            "stowageLocation": "0301086",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "CAIU5661509",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T16:52:00Z",
            "stowageLocation": "0301286",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "BMOU9621567",
            "portOfLoading": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T16:53:00Z",
            "stowageLocation": "0301184",
            "grossWeight": 4700,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "SEGU9416641",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T16:55:00Z",
            "stowageLocation": "0300584",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TEMU9577088",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T16:57:00Z",
            "stowageLocation": "0301284",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "CMAU3012545",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T16:58:00Z",
            "stowageLocation": "0290984",
            "grossWeight": 4054,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "CMAU1867794",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T16:58:00Z",
            "stowageLocation": "0310984",
            "grossWeight": 4840,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "CMAU0527017",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T17:00:00Z",
            "stowageLocation": "0310784",
            "grossWeight": 5000,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "SEGU1669073",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T17:00:00Z",
            "stowageLocation": "0290784",
            "grossWeight": 3540,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "APZU2126949",
            "portOfLoading": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T17:02:00Z",
            "stowageLocation": "0290684",
            "grossWeight": 4380,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "CGMU3044345",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T17:02:00Z",
            "stowageLocation": "0310684",
            "grossWeight": 14591,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22R1",
              "name": "20'x8'x8'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "CMAU0866155",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T17:04:00Z",
            "stowageLocation": "0310884",
            "grossWeight": 25510,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "CMAU2007420",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T17:04:00Z",
            "stowageLocation": "0290884",
            "grossWeight": 6220,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "DTOU0084625",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T17:06:00Z",
            "stowageLocation": "0291084",
            "grossWeight": 2200,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22T0",
              "name": "20'x8'x8'6\" Tank Container Test pressure 0,45 bar"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "DTOU0069908",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T17:06:00Z",
            "stowageLocation": "0311084",
            "grossWeight": 2200,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22T0",
              "name": "20'x8'x8'6\" Tank Container Test pressure 0,45 bar"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "DTOU0099559",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T17:11:00Z",
            "stowageLocation": "0291282",
            "grossWeight": 3800,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22T0",
              "name": "20'x8'x8'6\" Tank Container Test pressure 0,45 bar"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TLLU3229300",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T17:13:00Z",
            "stowageLocation": "0291182",
            "grossWeight": 26160,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "CMAU0993355",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T17:11:00Z",
            "stowageLocation": "0311282",
            "grossWeight": 25920,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TLLU2767615",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T17:13:00Z",
            "stowageLocation": "0311182",
            "grossWeight": 26160,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TRHU3865727",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T17:47:00Z",
            "stowageLocation": "0290982",
            "grossWeight": 26160,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TEMU0992508",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T17:47:00Z",
            "stowageLocation": "0310982",
            "grossWeight": 26160,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "CMAU3254425",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T17:49:00Z",
            "stowageLocation": "0290782",
            "grossWeight": 26160,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "CMAU0884797",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T17:49:00Z",
            "stowageLocation": "0310782",
            "grossWeight": 15900,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "CGMU3040294",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T17:52:00Z",
            "stowageLocation": "0310582",
            "grossWeight": 14315,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22R1",
              "name": "20'x8'x8'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "XINU1282208",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T17:52:00Z",
            "stowageLocation": "0290582",
            "grossWeight": 15900,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TCLU3941929",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T17:54:00Z",
            "stowageLocation": "0290682",
            "grossWeight": 16500,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TRIU6625430",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T17:54:00Z",
            "stowageLocation": "0310682",
            "grossWeight": 15223,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22R1",
              "name": "20'x8'x8'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "CRXU3398494",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T17:56:00Z",
            "stowageLocation": "0290882",
            "grossWeight": 16700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "APZU3598533",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T17:56:00Z",
            "stowageLocation": "0310882",
            "grossWeight": 16200,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "LBOU0120475",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T17:58:00Z",
            "stowageLocation": "0311082",
            "grossWeight": 8000,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "25K1",
              "name": "20'x8'x>8'6\" Bulk container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "LBOU0120095",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T17:58:00Z",
            "stowageLocation": "0291082",
            "grossWeight": 8000,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "25K1",
              "name": "20'x8'x>8'6\" Bulk container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "SZLU9224220",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T18:17:00Z",
            "stowageLocation": "0300912",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "SEGU9416404",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T18:19:00Z",
            "stowageLocation": "0300712",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "BMOU9628999",
            "portOfLoading": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T18:21:00Z",
            "stowageLocation": "0300812",
            "grossWeight": 4700,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "BMOU9620508",
            "portOfLoading": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T18:23:00Z",
            "stowageLocation": "0301012",
            "grossWeight": 4700,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TTNU8950775",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T18:25:00Z",
            "stowageLocation": "0300910",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "CMAU7361252",
            "portOfLoading": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T18:29:00Z",
            "stowageLocation": "0300510",
            "grossWeight": 9870,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "BMOU9618223",
            "portOfLoading": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T18:27:00Z",
            "stowageLocation": "0300710",
            "grossWeight": 4700,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TCNU2383476",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T18:31:00Z",
            "stowageLocation": "0300310",
            "grossWeight": 9451,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TRHU6500975",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T18:33:00Z",
            "stowageLocation": "0300410",
            "grossWeight": 7495,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "BMOU9623317",
            "portOfLoading": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T18:34:00Z",
            "stowageLocation": "0300610",
            "grossWeight": 4700,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "SEKU9061124",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T18:37:00Z",
            "stowageLocation": "0300810",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TTNU8953630",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T18:39:00Z",
            "stowageLocation": "0301010",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "SEKU9065156",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T18:40:00Z",
            "stowageLocation": "0300908",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "BMOU9602356",
            "portOfLoading": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T18:42:00Z",
            "stowageLocation": "0300708",
            "grossWeight": 4700,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "FSCU7144544",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T18:44:00Z",
            "stowageLocation": "0300508",
            "grossWeight": 10540,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TCLU6358480",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T18:46:00Z",
            "stowageLocation": "0300308",
            "grossWeight": 10550,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TCNU7544660",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T18:48:00Z",
            "stowageLocation": "0300408",
            "grossWeight": 10520,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TGHU8650870",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T18:50:00Z",
            "stowageLocation": "0300608",
            "grossWeight": 9957,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "SEKU9060935",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T18:52:00Z",
            "stowageLocation": "0300808",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "BMOU9616128",
            "portOfLoading": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T18:54:00Z",
            "stowageLocation": "0301008",
            "grossWeight": 4700,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "SEGU9415460",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T18:56:00Z",
            "stowageLocation": "0300906",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TTNU8951318",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T18:58:00Z",
            "stowageLocation": "0300706",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "CMAU7487575",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T19:56:00Z",
            "stowageLocation": "0300506",
            "grossWeight": 10410,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "CMAU7326586",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T19:58:00Z",
            "stowageLocation": "0300306",
            "grossWeight": 10410,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TLLU4241316",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T20:00:00Z",
            "stowageLocation": "0300406",
            "grossWeight": 17650,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TCNU4827820",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T20:02:00Z",
            "stowageLocation": "0300606",
            "grossWeight": 10610,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "SEKU9060473",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T20:04:00Z",
            "stowageLocation": "0300806",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "BMOU9629594",
            "portOfLoading": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T20:06:00Z",
            "stowageLocation": "0301006",
            "grossWeight": 4700,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "SEGU6358449",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T20:08:00Z",
            "stowageLocation": "0300504",
            "grossWeight": 25400,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "AXIU1652252",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T20:10:00Z",
            "stowageLocation": "0300304",
            "grossWeight": 28998,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TCNU4229177",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T20:12:00Z",
            "stowageLocation": "0300404",
            "grossWeight": 23125,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "SEGU4863251",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T20:14:00Z",
            "stowageLocation": "0300604",
            "grossWeight": 26174,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "CMAU4685772",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T20:16:00Z",
            "stowageLocation": "0300502",
            "grossWeight": 24500,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "SEKU4317698",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T20:18:00Z",
            "stowageLocation": "0300302",
            "grossWeight": 25600,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TLLU4352794",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T20:20:00Z",
            "stowageLocation": "0300402",
            "grossWeight": 28340,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "APHU6629450",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T20:22:00Z",
            "stowageLocation": "0300602",
            "grossWeight": 28868,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "CMAU3254153",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T20:24:00Z",
            "stowageLocation": "0310704",
            "grossWeight": 25810,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TRHU1053642",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T20:27:00Z",
            "stowageLocation": "0310804",
            "grossWeight": 26160,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "CMAU3146591",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T20:27:00Z",
            "stowageLocation": "0290804",
            "grossWeight": 26080,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "CMAU2080951",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T20:35:00Z",
            "stowageLocation": "0290904",
            "grossWeight": 9770,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TGBU2170212",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T20:40:00Z",
            "stowageLocation": "0291004",
            "grossWeight": 25860,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "DTOU0023527",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T20:52:00Z",
            "stowageLocation": "0371288",
            "grossWeight": 2630,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22T0",
              "name": "20'x8'x8'6\" Tank Container Test pressure 0,45 bar"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "FCIU4635566",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T20:54:00Z",
            "stowageLocation": "0371286",
            "grossWeight": 2200,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "BLKU2604655",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T20:58:00Z",
            "stowageLocation": "0371284",
            "grossWeight": 25800,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22K2",
              "name": "20 x 8,6 Liquid tank dangerous gds 2.65-10 bar"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "BAFU8890024",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T21:13:00Z",
            "stowageLocation": "0371282",
            "grossWeight": 25500,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22T6",
              "name": "20'x8'x8'6\" Tank Container Test pressure 6 bar"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "SEKU9010549",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T21:59:00Z",
            "stowageLocation": "0401190",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "CAIU5660020",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T22:02:00Z",
            "stowageLocation": "0400990",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TTNU8954106",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T22:05:00Z",
            "stowageLocation": "0400590",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "SEGU9411932",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T22:04:00Z",
            "stowageLocation": "0400790",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "CGMU5478119",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T22:07:00Z",
            "stowageLocation": "0400390",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TEMU9575763",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T22:09:00Z",
            "stowageLocation": "0400190",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "SEKU9010976",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T22:11:00Z",
            "stowageLocation": "0400290",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TTNU8954678",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T22:14:00Z",
            "stowageLocation": "0400490",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TEMU9579157",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T22:16:00Z",
            "stowageLocation": "0400690",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TEMU9573919",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T22:18:00Z",
            "stowageLocation": "0400890",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TTNU8950815",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T22:20:00Z",
            "stowageLocation": "0401090",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "BMOU9625937",
            "portOfLoading": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T22:23:00Z",
            "stowageLocation": "0401290",
            "grossWeight": 4700,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TTNU8953748",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T22:25:00Z",
            "stowageLocation": "0401188",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "CGMU5117008",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T22:26:00Z",
            "stowageLocation": "0400988",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TTNU8963330",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T22:28:00Z",
            "stowageLocation": "0400788",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "CGMU5531070",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T22:29:00Z",
            "stowageLocation": "0400588",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "BMOU9627930",
            "portOfLoading": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T22:32:00Z",
            "stowageLocation": "0400388",
            "grossWeight": 4700,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "CGMU5327869",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T22:33:00Z",
            "stowageLocation": "0400188",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TGHU9941437",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T22:35:00Z",
            "stowageLocation": "0400288",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "BMOU9628140",
            "portOfLoading": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T22:37:00Z",
            "stowageLocation": "0400488",
            "grossWeight": 4700,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TTNU8951914",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T22:39:00Z",
            "stowageLocation": "0400688",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TEMU9580245",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T22:41:00Z",
            "stowageLocation": "0400888",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TTNU8650783",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T22:43:00Z",
            "stowageLocation": "0401088",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "SEGU9415326",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T22:45:00Z",
            "stowageLocation": "0401288",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "CAIU5660420",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T22:47:00Z",
            "stowageLocation": "0401186",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TTNU8954488",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T22:49:00Z",
            "stowageLocation": "0400986",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "BMOU9601025",
            "portOfLoading": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T22:50:00Z",
            "stowageLocation": "0400786",
            "grossWeight": 4700,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "SZLU9226959",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T22:52:00Z",
            "stowageLocation": "0400586",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TEMU9580271",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T22:54:00Z",
            "stowageLocation": "0400386",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "SEKU9014708",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T22:56:00Z",
            "stowageLocation": "0400186",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "BMOU9628875",
            "portOfLoading": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T22:58:00Z",
            "stowageLocation": "0400286",
            "grossWeight": 4700,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "CGMU5008178",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T23:00:00Z",
            "stowageLocation": "0400486",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "CAIU5660230",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T23:02:00Z",
            "stowageLocation": "0400686",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "SEGU9412380",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T23:04:00Z",
            "stowageLocation": "0400886",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "SEGU9416615",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T23:06:00Z",
            "stowageLocation": "0401086",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TEMU9577154",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T23:08:00Z",
            "stowageLocation": "0401286",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "SEKU9014159",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T23:09:00Z",
            "stowageLocation": "0401184",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "SEGU9406448",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T23:11:00Z",
            "stowageLocation": "0400984",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "SZLU9224405",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T23:13:00Z",
            "stowageLocation": "0400784",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "CAIU5659040",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T23:14:00Z",
            "stowageLocation": "0400584",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "BMOU9630300",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T23:16:00Z",
            "stowageLocation": "0400384",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "SZLU9226917",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T23:18:00Z",
            "stowageLocation": "0400184",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "SEGU9413508",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T23:20:00Z",
            "stowageLocation": "0400284",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "SEKU9014930",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T23:22:00Z",
            "stowageLocation": "0400484",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "BMOU9625198",
            "portOfLoading": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T23:24:00Z",
            "stowageLocation": "0400684",
            "grossWeight": 4700,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "SEKU9010404",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T23:26:00Z",
            "stowageLocation": "0400884",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "CRSU6115620",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T23:28:00Z",
            "stowageLocation": "0401084",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "BMOU9628685",
            "portOfLoading": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T23:30:00Z",
            "stowageLocation": "0401284",
            "grossWeight": 4700,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "CGMU3077652",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T23:34:00Z",
            "stowageLocation": "0411282",
            "grossWeight": 15065,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22R1",
              "name": "20'x8'x8'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "DTOU0082601",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T23:34:00Z",
            "stowageLocation": "0391282",
            "grossWeight": 2200,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22T0",
              "name": "20'x8'x8'6\" Tank Container Test pressure 0,45 bar"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "BMOU9603769",
            "portOfLoading": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T23:36:00Z",
            "stowageLocation": "0401182",
            "grossWeight": 4700,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TTNU8951581",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T23:41:00Z",
            "stowageLocation": "0400982",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "SEKU9013851",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T23:43:00Z",
            "stowageLocation": "0400782",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "BMOU9601473",
            "portOfLoading": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T23:45:00Z",
            "stowageLocation": "0400582",
            "grossWeight": 4700,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "SZLU9225037",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T23:47:00Z",
            "stowageLocation": "0400382",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TTNU8956011",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T23:49:00Z",
            "stowageLocation": "0400182",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "CGMU6550727",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T23:51:00Z",
            "stowageLocation": "0400282",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TTNU8956958",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T23:53:00Z",
            "stowageLocation": "0400482",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TEMU9579882",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T23:55:00Z",
            "stowageLocation": "0400682",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TEMU9578398",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-26T00:04:00Z",
            "stowageLocation": "0400882",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "BMOU9623174",
            "portOfLoading": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-26T00:06:00Z",
            "stowageLocation": "0401082",
            "grossWeight": 4700,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "CAIU5660781",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-26T00:10:00Z",
            "stowageLocation": "0400980",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "BMOU9624761",
            "portOfLoading": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-26T00:12:00Z",
            "stowageLocation": "0400780",
            "grossWeight": 4700,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "CXRU1577517",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-26T00:15:00Z",
            "stowageLocation": "0400580",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "BMOU9616811",
            "portOfLoading": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-26T00:17:00Z",
            "stowageLocation": "0400380",
            "grossWeight": 4700,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "CGMU5012141",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-26T00:18:00Z",
            "stowageLocation": "0400180",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "BMOU9628279",
            "portOfLoading": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-26T00:20:00Z",
            "stowageLocation": "0400280",
            "grossWeight": 4700,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TTNU8955479",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-26T00:22:00Z",
            "stowageLocation": "0400480",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "CGMU5054595",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-26T00:24:00Z",
            "stowageLocation": "0400680",
            "grossWeight": 6870,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "SZLU9223667",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-26T00:26:00Z",
            "stowageLocation": "0400880",
            "grossWeight": 4700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "BMOU9621438",
            "portOfLoading": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-26T00:28:00Z",
            "stowageLocation": "0401080",
            "grossWeight": 4700,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          }
        ],
        "bulkDischarges": [],
        "consignmentDifferences": [],
        "clearedManifestIds": [
          "PFPPT",
          "AUBNE"
        ],
        "inspectionUpdates": [
          {
            "consignmentNumber": "CMDUNMA0110061",
            "containerNumber": "CMAU7896104",
            "physicalInspectionLocation": null,
            "status": null,
            "type": "scan",
            "remark": null,
            "processId": "cdba70c3-28d6-4ecf-8c92-cc89fcf45d84",
            "timestamp": "2021-09-21T11:07:07.857Z"
          },
          {
            "consignmentNumber": "CMDUNMA0110061",
            "containerNumber": "FCIU2736053",
            "physicalInspectionLocation": null,
            "status": null,
            "type": "scan",
            "remark": null,
            "processId": "cdba70c3-28d6-4ecf-8c92-cc89fcf45d84",
            "timestamp": "2021-09-21T11:07:07.857Z"
          },
          {
            "consignmentNumber": "CMDUNMA0110061",
            "containerNumber": "LCRU2024025",
            "physicalInspectionLocation": null,
            "status": null,
            "type": "scan",
            "remark": null,
            "processId": "cdba70c3-28d6-4ecf-8c92-cc89fcf45d84",
            "timestamp": "2021-09-21T11:07:07.857Z"
          },
          {
            "consignmentNumber": "CMDUGSR0121013",
            "containerNumber": "DFSU2090590",
            "physicalInspectionLocation": null,
            "status": null,
            "type": "scan",
            "remark": null,
            "processId": "65859587-8ae0-4579-a60e-6c14ef2450b5",
            "timestamp": "2021-09-22T14:04:00.610Z"
          },
          {
            "consignmentNumber": "CMDUHAT0101288",
            "containerNumber": "CARU3715217",
            "physicalInspectionLocation": null,
            "status": null,
            "type": "scan",
            "remark": null,
            "processId": "65859587-8ae0-4579-a60e-6c14ef2450b5",
            "timestamp": "2021-09-22T14:04:00.610Z"
          },
          {
            "consignmentNumber": "CMDUPPT0113496",
            "containerNumber": "CMAU0527017",
            "physicalInspectionLocation": null,
            "status": null,
            "type": "scan",
            "remark": null,
            "processId": "6298a385-0f04-4027-8d55-aa734606b700",
            "timestamp": "2021-09-23T06:32:44.648Z"
          },
          {
            "consignmentNumber": "CMDUNPL0105940",
            "containerNumber": "DFSU3116410",
            "physicalInspectionLocation": null,
            "status": null,
            "type": "scan",
            "remark": null,
            "processId": "6298a385-0f04-4027-8d55-aa734606b700",
            "timestamp": "2021-09-23T06:32:44.648Z"
          },
          {
            "consignmentNumber": "CMDUPPT0113496",
            "containerNumber": "CMAU0527017",
            "physicalInspectionLocation": null,
            "status": "deleted",
            "type": null,
            "remark": null,
            "processId": "6298a385-0f04-4027-8d55-aa734606b700",
            "timestamp": "2021-09-30T09:19:07.243Z"
          },
          {
            "consignmentNumber": "CMDUNPL0105940",
            "containerNumber": "DFSU3116410",
            "physicalInspectionLocation": null,
            "status": "deleted",
            "type": null,
            "remark": null,
            "processId": "6298a385-0f04-4027-8d55-aa734606b700",
            "timestamp": "2021-09-30T09:19:16.921Z"
          },
          {
            "consignmentNumber": "CMDUNMA0110061",
            "containerNumber": "CMAU7896104",
            "physicalInspectionLocation": null,
            "status": "deleted",
            "type": null,
            "remark": null,
            "processId": "cdba70c3-28d6-4ecf-8c92-cc89fcf45d84",
            "timestamp": "2021-09-30T09:23:15.891Z"
          },
          {
            "consignmentNumber": "CMDUNMA0110061",
            "containerNumber": "FCIU2736053",
            "physicalInspectionLocation": null,
            "status": "deleted",
            "type": null,
            "remark": null,
            "processId": "cdba70c3-28d6-4ecf-8c92-cc89fcf45d84",
            "timestamp": "2021-09-30T09:23:35.963Z"
          },
          {
            "consignmentNumber": "CMDUNMA0110061",
            "containerNumber": "LCRU2024025",
            "physicalInspectionLocation": null,
            "status": "deleted",
            "type": null,
            "remark": null,
            "processId": "cdba70c3-28d6-4ecf-8c92-cc89fcf45d84",
            "timestamp": "2021-09-30T09:23:56.870Z"
          }
        ],
        "declarations": [
          {
            "type": "SDT",
            "id": "AUBNE",
            "status": "ACCEPTED",
            "rejectReasons": null,
            "timeStamp": "2021-09-15T08:59:25.884Z"
          },
          {
            "type": "SDT",
            "id": "BEZEE",
            "status": "ACCEPTED",
            "rejectReasons": null,
            "timeStamp": "2021-09-15T08:59:25.884Z"
          },
          {
            "type": "SDT",
            "id": "JMKIN",
            "status": "ACCEPTED",
            "rejectReasons": null,
            "timeStamp": "2021-09-15T08:59:25.884Z"
          },
          {
            "type": "SDT",
            "id": "NCNOU",
            "status": "ACCEPTED",
            "rejectReasons": null,
            "timeStamp": "2021-09-15T08:59:25.884Z"
          },
          {
            "type": "SDT",
            "id": "NZTRG",
            "status": "ACCEPTED",
            "rejectReasons": null,
            "timeStamp": "2021-09-15T08:59:25.884Z"
          },
          {
            "type": "SDT",
            "id": "PFPPT",
            "status": "ACCEPTED",
            "rejectReasons": null,
            "timeStamp": "2021-09-15T12:00:50.578Z"
          },
          {
            "type": "SDT",
            "id": "NZTRG",
            "status": "ACCEPTED",
            "rejectReasons": null,
            "timeStamp": "2021-09-15T17:12:33.068Z"
          },
          {
            "type": "SDT",
            "id": "NZTRG",
            "status": "ACCEPTED",
            "rejectReasons": null,
            "timeStamp": "2021-09-15T17:12:56.511Z"
          },
          {
            "type": "SDT",
            "id": "NZTRG",
            "status": "ACCEPTED",
            "rejectReasons": null,
            "timeStamp": "2021-09-15T17:14:15.865Z"
          },
          {
            "type": "SDT",
            "id": "NZTRG",
            "status": "ACCEPTED",
            "rejectReasons": null,
            "timeStamp": "2021-09-15T17:14:33.351Z"
          },
          {
            "type": "SDT",
            "id": "JMKIN",
            "status": "ACCEPTED",
            "rejectReasons": null,
            "timeStamp": "2021-09-15T17:14:54.883Z"
          },
          {
            "type": "SDT",
            "id": "JMKIN",
            "status": "ACCEPTED",
            "rejectReasons": null,
            "timeStamp": "2021-09-15T17:15:10.188Z"
          },
          {
            "type": "SDT",
            "id": "AUBNE",
            "status": "ACCEPTED",
            "rejectReasons": null,
            "timeStamp": "2021-09-15T17:15:51.725Z"
          },
          {
            "type": "SDT",
            "id": "NCNOU",
            "status": "ACCEPTED",
            "rejectReasons": null,
            "timeStamp": "2021-09-15T17:16:35.118Z"
          },
          {
            "type": "SDT",
            "id": "BEZEE",
            "status": "ACCEPTED",
            "rejectReasons": null,
            "timeStamp": "2021-09-15T17:17:03.548Z"
          },
          {
            "type": "SDT",
            "id": "BEZEE",
            "status": "ACCEPTED",
            "rejectReasons": null,
            "timeStamp": "2021-09-15T17:17:11.249Z"
          },
          {
            "type": "SDT",
            "id": "NZTRG",
            "status": "ACCEPTED",
            "rejectReasons": null,
            "timeStamp": "2021-09-20T06:12:45.282Z"
          },
          {
            "type": "SDT",
            "id": "NZTRG",
            "status": "ACCEPTED",
            "rejectReasons": null,
            "timeStamp": "2021-09-20T06:20:01.488Z"
          },
          {
            "type": "SDT",
            "id": "NZTRG",
            "status": "ACCEPTED",
            "rejectReasons": null,
            "timeStamp": "2021-09-20T06:20:45.994Z"
          },
          {
            "type": "SDT",
            "id": "NZTRG",
            "status": "ACCEPTED",
            "rejectReasons": null,
            "timeStamp": "2021-09-20T06:22:09.168Z"
          },
          {
            "type": "SDT",
            "id": "BEZEE",
            "status": "REJECTED",
            "rejectReasons": "NO valid domain value, Consignment - Place of delivery, B/L number=CMDUAKD0269792",
            "timeStamp": "2021-09-20T07:00:22.786Z"
          },
          {
            "type": "SDT",
            "id": "BEZEE",
            "status": "REJECTED",
            "rejectReasons": "NO valid domain value, Consignment - Place of delivery, B/L number=CMDUAKD0269792",
            "timeStamp": "2021-09-20T07:25:19.252Z"
          },
          {
            "type": "SDT",
            "id": "BEZEE",
            "status": "REJECTED",
            "rejectReasons": "NO valid domain value, Consignment - Place of delivery, B/L number=CMDUAKD0269792",
            "timeStamp": "2021-09-20T07:25:37.501Z"
          },
          {
            "type": "SDT",
            "id": "BEZEE",
            "status": "REJECTED",
            "rejectReasons": "NO valid domain value, Consignment - Place of delivery, B/L number=CMDUAKD0269792",
            "timeStamp": "2021-09-20T07:26:02.732Z"
          },
          {
            "type": "SDT",
            "id": "BEZEE",
            "status": "REJECTED",
            "rejectReasons": "NO valid domain value, Consignment - Place of delivery, B/L number=CMDUAKD0269792",
            "timeStamp": "2021-09-20T07:26:38.471Z"
          },
          {
            "type": "SDT",
            "id": "BEZEE",
            "status": "REJECTED",
            "rejectReasons": "NO valid domain value, Consignment - Place of delivery, B/L number=CMDUAKD0269792",
            "timeStamp": "2021-09-20T07:27:11.906Z"
          },
          {
            "type": "SDT",
            "id": "BEZEE",
            "status": "REJECTED",
            "rejectReasons": "NO valid domain value, Consignment - Place of delivery, B/L number=CMDUAKD0269792",
            "timeStamp": "2021-09-21T15:20:33.596Z"
          },
          {
            "type": "SDT",
            "id": "BEZEE",
            "status": "REJECTED",
            "rejectReasons": "NO valid domain value, Consignment - Place of delivery, B/L number=CMDUAKD0269792",
            "timeStamp": "2021-09-21T15:20:44.110Z"
          },
          {
            "type": "SDT",
            "id": "BEZEE",
            "status": "REJECTED",
            "rejectReasons": "NO valid domain value, Consignment - Place of delivery, B/L number=CMDUAKD0269792",
            "timeStamp": "2021-09-22T07:53:01.932Z"
          },
          {
            "type": "SDT",
            "id": "BEZEE",
            "status": "REJECTED",
            "rejectReasons": "NO valid domain value, Consignment - Place of delivery, B/L number=CMDUAKD0269792",
            "timeStamp": "2021-09-22T07:53:32.851Z"
          },
          {
            "type": "SDT",
            "id": "JMKIN",
            "status": "ACCEPTED",
            "rejectReasons": null,
            "timeStamp": "2021-09-22T07:53:51.120Z"
          },
          {
            "type": "SDT",
            "id": "BEZEE",
            "status": "REJECTED",
            "rejectReasons": "NO valid domain value, Consignment - Place of delivery, B/L number=CMDUAKD0269792",
            "timeStamp": "2021-09-22T07:55:18.538Z"
          },
          {
            "type": "SDT",
            "id": "BEZEE",
            "status": "REJECTED",
            "rejectReasons": "NO valid domain value, Consignment - Place of delivery, B/L number=CMDUAKD0269792",
            "timeStamp": "2021-09-22T07:56:05.627Z"
          },
          {
            "type": "SDT",
            "id": "BEZEE",
            "status": "REJECTED",
            "rejectReasons": "NO valid domain value, Consignment - Place of delivery, B/L number=CMDUAKD0269792",
            "timeStamp": "2021-09-22T07:56:46.386Z"
          },
          {
            "type": "SDT",
            "id": "BEZEE",
            "status": "REJECTED",
            "rejectReasons": "NO valid domain value, Consignment - Place of delivery, B/L number=CMDUAKD0269792",
            "timeStamp": "2021-09-22T07:57:40.484Z"
          },
          {
            "type": "SDT",
            "id": "BEZEE",
            "status": "REJECTED",
            "rejectReasons": "NO valid domain value, Consignment - Place of delivery, B/L number=CMDUAKD0269792",
            "timeStamp": "2021-09-22T07:58:13.280Z"
          },
          {
            "type": "SDT",
            "id": "BEZEE",
            "status": "REJECTED",
            "rejectReasons": "NO valid domain value, Consignment - Place of delivery, B/L number=CMDUAKD0269792",
            "timeStamp": "2021-09-22T07:58:52.743Z"
          },
          {
            "type": "SDT",
            "id": "BEZEE",
            "status": "REJECTED",
            "rejectReasons": "NO valid domain value, Consignment - Place of delivery, B/L number=CMDUAKD0269792",
            "timeStamp": "2021-09-22T13:16:28.024Z"
          },
          {
            "type": "SDT",
            "id": "BEZEE",
            "status": "ACCEPTED",
            "rejectReasons": null,
            "timeStamp": "2021-09-22T13:18:28.351Z"
          },
          {
            "type": "SDT",
            "id": "BEZEE",
            "status": "ACCEPTED",
            "rejectReasons": null,
            "timeStamp": "2021-09-22T18:30:21.755Z"
          },
          {
            "type": "SDT",
            "id": "BEZEE",
            "status": "ACCEPTED",
            "rejectReasons": null,
            "timeStamp": "2021-09-22T18:35:01.107Z"
          },
          {
            "type": "SDT",
            "id": "BEZEE",
            "status": "ACCEPTED",
            "rejectReasons": null,
            "timeStamp": "2021-09-22T18:37:10.432Z"
          },
          {
            "type": "SDT",
            "id": "BEZEE",
            "status": "ACCEPTED",
            "rejectReasons": null,
            "timeStamp": "2021-09-22T18:37:18.666Z"
          },
          {
            "type": "SDT",
            "id": "BEZEE",
            "status": "ACCEPTED",
            "rejectReasons": null,
            "timeStamp": "2021-09-27T08:29:54.587Z"
          },
          {
            "type": "SDT",
            "id": "BEZEE",
            "status": "ACCEPTED",
            "rejectReasons": null,
            "timeStamp": "2021-09-27T08:32:28.214Z"
          },
          {
            "type": "SDT",
            "id": "BEZEE",
            "status": "ACCEPTED",
            "rejectReasons": null,
            "timeStamp": "2021-09-30T14:51:25.589Z"
          },
          {
            "type": "SDT",
            "id": "BEZEE",
            "status": "ACCEPTED",
            "rejectReasons": null,
            "timeStamp": "2021-09-30T14:52:04.263Z"
          },
          {
            "type": "SDT",
            "id": "BEZEE",
            "status": "ACCEPTED",
            "rejectReasons": null,
            "timeStamp": "2021-09-30T14:53:24.622Z"
          }
        ],
        "incomingMessages": [
          {
            "type": "IFTMCS",
            "id": "CMDUAKD0268683",
            "processId": "224b942987f041928a40639200d0f1e9",
            "timeStamp": "2021-09-15T08:59:25.884Z"
          },
          {
            "type": "IFTMCS",
            "id": "CMDUAKD0269043",
            "processId": "06d6c53e38f449f585affea366b49968",
            "timeStamp": "2021-09-15T08:59:25.884Z"
          },
          {
            "type": "IFTMCS",
            "id": "CMDUAKD0269360",
            "processId": "4c81a503fbb44ad391ff16dbf3ba55c0",
            "timeStamp": "2021-09-15T08:59:25.884Z"
          },
          {
            "type": "IFTMCS",
            "id": "CMDUNMA0110061",
            "processId": "7174f7b4f942492d87152963077d8066",
            "timeStamp": "2021-09-15T08:59:25.884Z"
          },
          {
            "type": "IFTMCS",
            "id": "CMDUPPT0113496",
            "processId": "e4d9afd8316643b38439f97604f4de90",
            "timeStamp": "2021-09-15T08:59:25.884Z"
          },
          {
            "type": "IFTMCS",
            "id": "CMDUHAT0101312",
            "processId": "4ad410319a9647adb62595a8be079502",
            "timeStamp": "2021-09-15T08:59:25.884Z"
          },
          {
            "type": "IFTMCS",
            "id": "CMDUAKD0269220",
            "processId": "ac7b3e479c8c41b9baad5cac1477ab12",
            "timeStamp": "2021-09-15T08:59:25.884Z"
          },
          {
            "type": "IFTMCS",
            "id": "CMDUHAT0101288",
            "processId": "36ee105f1d454d18b755609a258acced",
            "timeStamp": "2021-09-15T08:59:25.884Z"
          },
          {
            "type": "IFTMCS",
            "id": "CMDUAKD0268492",
            "processId": "3ac2f1cf0815432a83965266ae58f7e3",
            "timeStamp": "2021-09-15T08:59:25.884Z"
          },
          {
            "type": "IFTMCS",
            "id": "CMDUAKD0269154",
            "processId": "aaa09524d42343539d06893bf418f83b",
            "timeStamp": "2021-09-15T08:59:25.884Z"
          },
          {
            "type": "IFTMCS",
            "id": "CMDUAKD0268000",
            "processId": "9de5264f3a4748b598d79f720e2ec2a1",
            "timeStamp": "2021-09-15T08:59:25.884Z"
          },
          {
            "type": "IFTMCS",
            "id": "CMDUMBE0353890",
            "processId": "0be3aff16af04bb6b0b7adca9bceec0b",
            "timeStamp": "2021-09-15T08:59:25.884Z"
          },
          {
            "type": "IFTMCS",
            "id": "CMDUAKD0269053",
            "processId": "fb5b2fcbf20a4edd84bf678d903e09a1",
            "timeStamp": "2021-09-15T08:59:25.884Z"
          },
          {
            "type": "IFTMCS",
            "id": "CMDUGSR0121013",
            "processId": "36910c3ea5ea4e689b5edd47044a001e",
            "timeStamp": "2021-09-15T08:59:25.884Z"
          },
          {
            "type": "IFTMCS",
            "id": "CMDUNMA0110071",
            "processId": "dd6ea48d01d04938bf3f1dcc701e2bfa",
            "timeStamp": "2021-09-15T08:59:25.884Z"
          },
          {
            "type": "IFTMCS",
            "id": "CMDUAKD0269191",
            "processId": "aa3c0330f42c4aaf995ba68103b1730b",
            "timeStamp": "2021-09-15T08:59:25.884Z"
          },
          {
            "type": "IFTMCS",
            "id": "CMDUAKD0269982",
            "processId": "f62740433fed47d7918d3df6d24e844f",
            "timeStamp": "2021-09-15T08:59:25.884Z"
          },
          {
            "type": "IFTMCS",
            "id": "CMDUHAT0101289",
            "processId": "b9f0214386d64669b13bc610d7c780c2",
            "timeStamp": "2021-09-15T08:59:25.884Z"
          },
          {
            "type": "IFTMCS",
            "id": "CMDUAKD0269033",
            "processId": "7a82c91eae704109b497b2937add014a",
            "timeStamp": "2021-09-15T08:59:25.884Z"
          },
          {
            "type": "IFTMCS",
            "id": "CMDUPSP0163519",
            "processId": "b8f9f950cfb54715adc04e1110e72ebf",
            "timeStamp": "2021-09-15T08:59:25.884Z"
          },
          {
            "type": "IFTMCS",
            "id": "CMDUNAM4544685B",
            "processId": "7431db45b34a4d2bb9abd41eec8beed4",
            "timeStamp": "2021-09-15T08:59:25.884Z"
          },
          {
            "type": "IFTMCS",
            "id": "CMDUAKD0269864",
            "processId": "bbbaff1a60324e0ca209a7c02f1318be",
            "timeStamp": "2021-09-15T08:59:25.884Z"
          },
          {
            "type": "IFTMCS",
            "id": "CMDUAKD0269495",
            "processId": "8e7389cab2d04588beee745dbef8347f",
            "timeStamp": "2021-09-15T08:59:25.884Z"
          },
          {
            "type": "IFTMCS",
            "id": "CMDUNMA0110062",
            "processId": "d9e86340d7854467b1e7e0e0f25ba24d",
            "timeStamp": "2021-09-15T08:59:25.884Z"
          },
          {
            "type": "IFTMCS",
            "id": "CMDUNMA0107916",
            "processId": "258f679dcafe42f2a1ea001785902356",
            "timeStamp": "2021-09-15T08:59:25.884Z"
          },
          {
            "type": "IFTMCS",
            "id": "CMDUPPT0113576",
            "processId": "13d4d37452184004860afbd0e67cd7b4",
            "timeStamp": "2021-09-15T12:00:50.578Z"
          },
          {
            "type": "IFTMCS",
            "id": "CMDUAKD0269794",
            "processId": "7bd15589bf4c4410b4bf7fac166e1828",
            "timeStamp": "2021-09-20T07:00:22.786Z"
          },
          {
            "type": "IFTMCS",
            "id": "CMDUAKD0269376",
            "processId": "6d6a7055974849b4a14db598e989f062",
            "timeStamp": "2021-09-20T07:00:22.786Z"
          },
          {
            "type": "IFTMCS",
            "id": "CMDUAKD0268680",
            "processId": "713f96d88dfa4fa0a92ace2e4e514cb1",
            "timeStamp": "2021-09-20T07:00:22.786Z"
          },
          {
            "type": "IFTMCS",
            "id": "CMDUAKD0269719",
            "processId": "acba12fe5ddb4191b88d3a5a05d6409c",
            "timeStamp": "2021-09-20T07:00:22.786Z"
          },
          {
            "type": "IFTMCS",
            "id": "CMDUAKD0269746",
            "processId": "e4c3d136028749d2812abc5fd625e4c4",
            "timeStamp": "2021-09-20T07:00:22.786Z"
          },
          {
            "type": "IFTMCS",
            "id": "CMDUPPT0112894",
            "processId": "c641d64db1f348f981201e91658cea7d",
            "timeStamp": "2021-09-20T07:00:22.786Z"
          },
          {
            "type": "IFTMCS",
            "id": "CMDUAKD0269792",
            "processId": "c2895163ae2d41f1bd86525c5431efa8",
            "timeStamp": "2021-09-20T07:00:22.786Z"
          },
          {
            "type": "IFTMCS",
            "id": "CMDUAKD0268048",
            "processId": "8fdb1ab4b9c645758200e239eb547f97",
            "timeStamp": "2021-09-20T07:00:22.786Z"
          },
          {
            "type": "IFTMCS",
            "id": "CMDUAKD0267916",
            "processId": "a89b84e00f0c411ab092b209f2ba51ee",
            "timeStamp": "2021-09-21T15:20:33.596Z"
          },
          {
            "type": "IFTMCS",
            "id": "CMDUAKD0268821",
            "processId": "d6294131866340d2850fe8a01c05fe89",
            "timeStamp": "2021-09-21T15:20:44.110Z"
          },
          {
            "type": "IFTMCS",
            "id": "CMDUAKD0268811",
            "processId": "6588d523f52f4d54873eaa2f5b81300c",
            "timeStamp": "2021-09-21T15:20:44.110Z"
          },
          {
            "type": "IFTMCS",
            "id": "CMDUAKD0268014",
            "processId": "d7df38c14af04ebda48f57500e8dc6e3",
            "timeStamp": "2021-09-21T15:20:44.110Z"
          },
          {
            "type": "IFTMCS",
            "id": "CMDUAKD0268823",
            "processId": "0495dc09171843fbb3235ff862c267b7",
            "timeStamp": "2021-09-21T15:20:44.110Z"
          },
          {
            "type": "IFTMCS",
            "id": "CMDUAKD0268667",
            "processId": "b1b22814869e4b24b09a9c4ff37d1df7",
            "timeStamp": "2021-09-21T15:20:44.110Z"
          },
          {
            "type": "IFTMCS",
            "id": "CMDUAKD0268647",
            "processId": "f447503abbf6448bba9011332881a12a",
            "timeStamp": "2021-09-21T15:20:44.110Z"
          },
          {
            "type": "IFTMCS",
            "id": "CMDUAKD0268831",
            "processId": "4cea432f4cf941ad88d88add25640f23",
            "timeStamp": "2021-09-21T15:20:44.110Z"
          },
          {
            "type": "IFTMCS",
            "id": "CMDUAKD0268929",
            "processId": "c63d11330f394cd09b5f8bbcbd1b6c89",
            "timeStamp": "2021-09-21T15:20:44.110Z"
          },
          {
            "type": "IFTMCS",
            "id": "CMDUAKD0269480",
            "processId": "e2febf113707423b8281cec48300f5ca",
            "timeStamp": "2021-09-21T15:20:44.110Z"
          },
          {
            "type": "IFTMCS",
            "id": "CMDUAKD0268023",
            "processId": "2676c0d1265749148a6190f7659dd5a4",
            "timeStamp": "2021-09-21T15:20:44.110Z"
          },
          {
            "type": "IFTMCS",
            "id": "CMDUAKD0266831",
            "processId": "c4be6849d0644791b89b21ba9a57eba2",
            "timeStamp": "2021-09-21T15:20:44.110Z"
          },
          {
            "type": "IFTMCS",
            "id": "CMDUAKD0268015",
            "processId": "2f72eb6fef4c4205bbdc99cd52720f00",
            "timeStamp": "2021-09-21T15:20:44.110Z"
          },
          {
            "type": "IFTMCS",
            "id": "CMDUAKD0267890",
            "processId": "c085a01b7da341dfb618d649c6275728",
            "timeStamp": "2021-09-21T15:20:44.110Z"
          },
          {
            "type": "IFTMCS",
            "id": "CMDUNPL0105940",
            "processId": "54a22cf02af941ffa2f1b915317476fa",
            "timeStamp": "2021-09-21T15:20:44.110Z"
          },
          {
            "type": "IFTMCS",
            "id": "CMDUAKD0268044",
            "processId": "d673a51f910149a1914aded80c22c018",
            "timeStamp": "2021-09-21T15:20:44.110Z"
          },
          {
            "type": "IFTMCS",
            "id": "CMDUAKD0267922",
            "processId": "b83650555d8d401380181b00db12abd6",
            "timeStamp": "2021-09-21T15:20:44.110Z"
          },
          {
            "type": "IFTMCS",
            "id": "CMDUNMA0110054",
            "processId": "224beadc0e9d4911891353489c6f1f7e",
            "timeStamp": "2021-09-21T15:20:44.110Z"
          },
          {
            "type": "IFTMCS",
            "id": "CMDUAKD0268650",
            "processId": "f496bfb76d04484ab28c51f5801f5eea",
            "timeStamp": "2021-09-21T15:20:44.110Z"
          },
          {
            "type": "IFTMCS",
            "id": "CMDUAKD0269189",
            "processId": "b8d74a01fd304611955cc46ce258f343",
            "timeStamp": "2021-09-21T15:20:44.110Z"
          },
          {
            "type": "IFTMCS",
            "id": "CMDUAKD0268229",
            "processId": "bcedf875d74b4e99a699a342b9017b5a",
            "timeStamp": "2021-09-21T15:20:44.110Z"
          },
          {
            "type": "IFTMCS",
            "id": "CMDUAKD0268708",
            "processId": "9a75cfbc075a4313b5600cbc60573295",
            "timeStamp": "2021-09-21T15:20:44.110Z"
          },
          {
            "type": "IFTMCS",
            "id": "CMDUAKD0269163",
            "processId": "940a18646ffc49ca9bc112561947c961",
            "timeStamp": "2021-09-21T15:20:44.110Z"
          },
          {
            "type": "IFTMCS",
            "id": "CMDUAKD0268637",
            "processId": "f669164747294fbb978bb4ecd2769525",
            "timeStamp": "2021-09-22T07:53:01.932Z"
          },
          {
            "type": "IFTMCS",
            "id": "CMDUAKD0269534",
            "processId": "eddb10dd2cb44a4bbc445d6d88f3bf3f",
            "timeStamp": "2021-09-22T07:53:32.851Z"
          },
          {
            "type": "IFTMCS",
            "id": "CMDUAKD0268626",
            "processId": "f7a74303fb064d20af307e3d14ffeda8",
            "timeStamp": "2021-09-22T18:30:21.755Z"
          }
        ]
      },
      {
        "cargoDeclarant": {
          "fullName": "Broekman Shipping B.V.",
          "shortName": "BSDRTM",
          "portAuthorityId": "BROEKMAN SHIPPING",
          "emailAddress": "marine@broekmanlogistics.com",
          "address": "Waalhaven Z.z. 21",
          "city": "Rotterdam",
          "countryUnCode": "NL",
          "countryName": "Netherlands",
          "zipCode": "3089 JH",
          "phoneNumber": "+31-10-4873398",
          "faxNumber": null,
          "contact": "Frank Volker",
          "customsEORINumber": "NL800822274",
          "ean": null,
          "chamberOfCommerceNumber": "24194505",
          "scacCode": "BROE",
          "ensEnabled": true,
          "cargoImportEnabled": true,
          "cargoImportEnabledByDefault": true
        },
        "timestamp": "2021-09-28T16:35:31.140Z",
        "containers": [
          {
            "number": "MFTU2138021",
            "portOfLoading": {
              "name": "Tauranga",
              "locationUnCode": "NZTRG",
              "countryUnCode": "NZ",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-24T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": null,
              "name": "Marfret Compagnie Maritime",
              "scacCode": "MFUS",
              "smdgCode": "MCM",
              "cargoDeclarantShortName": null
            },
            "tareWeight": 2130.0,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": "NZMPI01295048",
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": "ROTTERDAM"
          }
        ],
        "consignments": [
          {
            "consignmentNumber": "MFUSBL21233169",
            "portOfLoading": {
              "name": "Tauranga",
              "locationUnCode": "NZTRG",
              "countryUnCode": "NZ",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-24T22:00:00Z",
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "movementReferenceNumbers": [
              "21BEN0000014137700"
            ],
            "partShipment": false,
            "movementType": "PIER_TO_PIER",
            "customsStatus": null,
            "customsProcess": "SEA_IN_SEA_OUT",
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "HEMISPHERE FREIGHT SERVICES",
              "address": "19 GARFIELD ST PARNELL",
              "zipCode": "1052",
              "city": "AUCKLAND",
              "countryCode": "NZ",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "STL LOGISTICS B.V.",
              "address": "ESCUDOSTRAAT 6C",
              "zipCode": "2991 XV",
              "city": "BARENDRECHT",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [
              {
                "eoriNumber": null,
                "name": "STL LOGISTICS B.V.",
                "address": "ESCUDOSTRAAT 6C",
                "zipCode": "2991 XV",
                "city": "BARENDRECHT",
                "countryCode": "NL",
                "authorised": null,
                "id": null,
                "ownerShortName": null
              }
            ],
            "placeOfOrigin": null,
            "placeOfDestination": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euLocation": true
            },
            "placeOfDelivery": "ROTTERDAM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "2119127N",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "HONEY HS CODE 040900",
                "grossWeight": 15336.33,
                "numberOfOuterPackages": 6684,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": {
                  "code": "0409",
                  "description": "Natural honey"
                },
                "dangerInformation": null,
                "marksAndNumbers": [
                  "NO MARKS",
                  "NO NUMBERS"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "MFTU2138021",
                    "numberOfPackages": 6684,
                    "grossWeight": 15336.33
                  }
                ],
                "producedDocuments": []
              }
            ]
          }
        ],
        "receivedMrns": {},
        "discharges": [
          {
            "number": "MFTU2138021",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "AMALIAH RWG DS 1",
              "terminalCode": "4284",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)"
            },
            "actualTimeOfHandling": "2021-09-25T20:24:00Z",
            "stowageLocation": "0290704",
            "grossWeight": 18208,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": null,
              "name": "Marfret Compagnie Maritime",
              "scacCode": "MFUS",
              "smdgCode": "MCM",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "BSDRTM",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          }
        ],
        "bulkDischarges": [],
        "consignmentDifferences": [],
        "clearedManifestIds": [
          "NZTRG"
        ],
        "inspectionUpdates": [],
        "declarations": [
          {
            "type": "SDT",
            "id": "NZTRG",
            "status": "ACCEPTED",
            "rejectReasons": null,
            "timeStamp": "2021-09-23T09:41:15.927Z"
          }
        ],
        "incomingMessages": [
          {
            "type": "IFTMCS",
            "id": "MFUSBL21233169",
            "processId": "98dc68c9c5534a31afbd12805dea559d",
            "timeStamp": "2021-09-23T09:41:15.927Z"
          }
        ]
      }
    ],
    "transitDeclarationMap": {},
    "loadingDeclarations": [],
    "paxDeclarationSummaries": [
      {
        "numberOfCrew": 21,
        "numberOfPassengers": 0,
        "numberOfCrewOtherShip": 0,
        "numberOfStowaways": 0,
        "searchAndRescue": null
      }
    ],
    "healthDeclarationSummary": {
      "amountRecoveredAndStillOnBoard": 0,
      "amountRecoveredAndEvacuated": 0,
      "amountIllAndStillOnBoard": 0,
      "amountIllAndEvacuated": 0,
      "amountDiedAndStillOnBoard": 0,
      "amountDiedAndEvacuated": 0,
      "amountDiedAndBuriedAtSea": 0,
      "healthDeclarationStatus": "APPROVED",
      "healthDeclarationRemarks": null
    },
    "etaPortAis": null,
    "ignoreEtaPortAis": false,
    "cancelled": false,
    "ataRegisteredByPa": true,
    "visitKnownAtPa": true,
    "dangerousGoodsEnabled": true,
    "cargoImportEnabled": true,
    "ataAcknowledged": true,
    "declarations": [
      {
        "type": "VISIT",
        "id": null,
        "status": "ACCEPTED",
        "rejectReasons": null,
        "timeStamp": "2021-07-28T12:06:34.088Z"
      },
      {
        "type": "WPCS",
        "id": null,
        "status": "DECLARED",
        "rejectReasons": null,
        "timeStamp": "2021-07-28T12:06:36.254Z"
      },
      {
        "type": "SECURITY",
        "id": null,
        "status": "ACCEPTED",
        "rejectReasons": null,
        "timeStamp": "2021-07-28T12:06:41.015Z"
      },
      {
        "type": "VISIT",
        "id": null,
        "status": "ACCEPTED",
        "rejectReasons": null,
        "timeStamp": "2021-09-15T07:27:05.643Z"
      },
      {
        "type": "WPCS",
        "id": null,
        "status": "DECLARED",
        "rejectReasons": null,
        "timeStamp": "2021-09-15T07:27:06.038Z"
      },
      {
        "type": "VISIT",
        "id": null,
        "status": "ACCEPTED",
        "rejectReasons": null,
        "timeStamp": "2021-09-15T11:59:50.871Z"
      },
      {
        "type": "WPCS",
        "id": null,
        "status": "DECLARED",
        "rejectReasons": null,
        "timeStamp": "2021-09-15T11:59:51.322Z"
      },
      {
        "type": "PAX",
        "id": null,
        "status": "ACCEPTED",
        "rejectReasons": null,
        "timeStamp": "2021-09-21T08:49:51.843Z"
      },
      {
        "type": "WASTE",
        "id": null,
        "status": "ACCEPTED",
        "rejectReasons": null,
        "timeStamp": "2021-09-21T08:51:57.118Z"
      },
      {
        "type": "WPCS",
        "id": null,
        "status": "DECLARED",
        "rejectReasons": null,
        "timeStamp": "2021-09-21T08:54:14.910Z"
      },
      {
        "type": "STO",
        "id": null,
        "status": "ACCEPTED",
        "rejectReasons": null,
        "timeStamp": "2021-09-23T08:50:01.965Z"
      },
      {
        "type": "HEALTH",
        "id": null,
        "status": "ACCEPTED",
        "rejectReasons": null,
        "timeStamp": "2021-09-24T07:32:29.490Z"
      },
      {
        "type": "DANGEROUS_GOODS",
        "id": null,
        "status": "ACCEPTED",
        "rejectReasons": null,
        "timeStamp": "2021-09-24T11:12:55.630Z"
      },
      {
        "type": "DANGEROUS_GOODS",
        "id": null,
        "status": "ACCEPTED",
        "rejectReasons": null,
        "timeStamp": "2021-09-24T11:13:44.874Z"
      },
      {
        "type": "VISIT",
        "id": null,
        "status": "ACCEPTED",
        "rejectReasons": null,
        "timeStamp": "2021-09-24T18:25:26.900Z"
      },
      {
        "type": "VISIT",
        "id": null,
        "status": "ACCEPTED",
        "rejectReasons": null,
        "timeStamp": "2021-09-24T18:26:39.988Z"
      },
      {
        "type": "WPCS",
        "id": null,
        "status": "DECLARED",
        "rejectReasons": null,
        "timeStamp": "2021-09-25T03:39:18.426Z"
      },
      {
        "type": "DANGEROUS_GOODS",
        "id": null,
        "status": "ACCEPTED",
        "rejectReasons": null,
        "timeStamp": "2021-09-25T06:30:33.259Z"
      },
      {
        "type": "VISIT",
        "id": null,
        "status": "ACCEPTED",
        "rejectReasons": null,
        "timeStamp": "2021-09-25T13:22:47.995Z"
      },
      {
        "type": "WPCS",
        "id": null,
        "status": "DECLARED",
        "rejectReasons": null,
        "timeStamp": "2021-09-25T13:22:48.112Z"
      },
      {
        "type": "VISIT",
        "id": null,
        "status": "ACCEPTED",
        "rejectReasons": null,
        "timeStamp": "2021-09-25T15:26:10.209Z"
      },
      {
        "type": "WPCS",
        "id": null,
        "status": "DECLARED",
        "rejectReasons": null,
        "timeStamp": "2021-09-25T15:26:10.296Z"
      },
      {
        "type": "VISIT",
        "id": null,
        "status": "ACCEPTED",
        "rejectReasons": null,
        "timeStamp": "2021-09-25T23:49:19.373Z"
      },
      {
        "type": "WPCS",
        "id": null,
        "status": "DECLARED",
        "rejectReasons": null,
        "timeStamp": "2021-09-25T23:49:19.448Z"
      },
      {
        "type": "VISIT",
        "id": null,
        "status": "ACCEPTED",
        "rejectReasons": null,
        "timeStamp": "2021-09-25T23:50:59.730Z"
      },
      {
        "type": "WPCS",
        "id": null,
        "status": "DECLARED",
        "rejectReasons": null,
        "timeStamp": "2021-09-25T23:50:59.766Z"
      },
      {
        "type": "VISIT",
        "id": null,
        "status": "ACCEPTED",
        "rejectReasons": null,
        "timeStamp": "2021-09-25T23:51:04.455Z"
      },
      {
        "type": "VISIT",
        "id": null,
        "status": "ACCEPTED",
        "rejectReasons": null,
        "timeStamp": "2021-09-26T00:11:36.768Z"
      },
      {
        "type": "WPCS",
        "id": null,
        "status": "DECLARED",
        "rejectReasons": null,
        "timeStamp": "2021-09-26T03:22:53.250Z"
      },
      {
        "type": "WPCS",
        "id": null,
        "status": "DECLARED",
        "rejectReasons": null,
        "timeStamp": "2021-09-26T05:43:41.592Z"
      }
    ],
    "uploads": {
      "WASTE": "47b146de-06ec-4b86-b43a-944b51b965fd",
      "STO": "565f89e5-1c9b-4711-9325-536863644299"
    },
    "additionalIncomingMovementInfo": {
      "etaPilotBoardingPlace": "2021-09-25T02:00:00Z",
      "plannedNumberOfTugboats": "ONE",
      "shifted": false,
      "etdChanged": false,
      "etaChanged": false,
      "entryDependencyRemarks": null
    },
    "berthVisitInfos": {
      "5589ebb3-69eb-4f2e-b979-39f728662ae3": <BerthVisitInfo>{
        "carrierInfo": {
          "eta": "2021-09-24T22:00:00Z"
        },
        "terminalInfo": {
          "rta": "2021-09-25T04:00:00Z",
          "etc": "2021-09-26T03:00:00Z",
          "ata": "2021-09-25T05:00:00Z",
          "atd": null,
          "firstLift": "2021-09-25T05:00:00Z",
          "lastLift": null
        },
        "harbourMasterInfo": {
          "orderStatus": "CONFIRMED",
          "eta": "2021-09-25T03:45:00Z",
          "etd": "2021-09-26T03:00:00Z",
          "pilotOnBoard": null,
          "rejectionReason": null,
          "remarks": null,
          "movementOnHold": false,
          "tidalWindowStatus": null,
          "pilotFromDistance": 'NOT_ACCEPTED'
        },
        "originalEditors": []
      }
    },
    "incomingMovementHarbourMasterInfo": {
      "orderStatus": "CONFIRMED",
      "pilotOnBoard": null,
      "rejectionReason": null,
      "remarks": null,
      "movementOnHold": false,
      "pilotFromDistance": 'ACCEPTED'
    },
    "incomingTidalWindowStatus": null,
    "lastMessageId": "f07f3eaf-f25c-4904-a8e8-5a68c6228317",
    "created": "2021-07-28T12:05:09.875Z",
    "updated": "2021-10-01T06:59:50.004Z",
    "berthVisitIdOfTransfer": null,
    "hasBeenTransferred": false,
    "previousMessageId": "e153a772-5fa3-4e1b-9296-bbc0021a315a",
    "transitDeclarations": [],
    "visitStatus": "DEPARTED",
    "allowedViewers": [
      {
        "fullName": "CMA-CGM (Holland) B.V.",
        "shortName": "CMACGMRHN",
        "portAuthorityId": "CMA-CGM",
        "emailAddress": "rtm.shipops@cma-cgm.com",
        "address": "Achterdijk 55",
        "city": "Rhoon",
        "countryUnCode": "NL",
        "countryName": "Netherlands",
        "zipCode": "3161 EB",
        "phoneNumber": "010-2998199",
        "faxNumber": "010-2998190",
        "contact": "Adrie Tiemens",
        "customsEORINumber": "NL806025463",
        "ean": null,
        "chamberOfCommerceNumber": "24276503",
        "scacCode": null,
        "ensEnabled": false,
        "cargoImportEnabled": true,
        "cargoImportEnabledByDefault": true
      },
      {
        "fullName": "Broekman Shipping B.V.",
        "shortName": "BSDRTM",
        "portAuthorityId": "BROEKMAN SHIPPING",
        "emailAddress": "marine@broekmanlogistics.com",
        "address": "Waalhaven Z.z. 21",
        "city": "Rotterdam",
        "countryUnCode": "NL",
        "countryName": "Netherlands",
        "zipCode": "3089 JH",
        "phoneNumber": "+31-10-4873398",
        "faxNumber": null,
        "contact": "Frank Volker",
        "customsEORINumber": "NL800822274",
        "ean": null,
        "chamberOfCommerceNumber": "24194505",
        "scacCode": "BROE",
        "ensEnabled": true,
        "cargoImportEnabled": true,
        "cargoImportEnabledByDefault": true
      },
      {
        "fullName": "CMA-CGM (Holland) B.V.",
        "shortName": "CMACGMRHN",
        "portAuthorityId": "CMA-CGM",
        "emailAddress": "rtm.shipops@cma-cgm.com",
        "address": "Achterdijk 55",
        "city": "Rhoon",
        "countryUnCode": "NL",
        "countryName": "Netherlands",
        "zipCode": "3161 EB",
        "phoneNumber": "010-2998199",
        "faxNumber": "010-2998190",
        "contact": "Marcel den Boogert",
        "customsEORINumber": "NL806025463",
        "ean": null,
        "chamberOfCommerceNumber": "24276503",
        "scacCode": null,
        "ensEnabled": false,
        "cargoImportEnabled": true,
        "cargoImportEnabledByDefault": true
      },
      {
        "fullName": "Havenbedrijf Rotterdam N.V.",
        "shortName": "HBRRTM",
        "portAuthorityId": "HbR",
        "emailAddress": "HCC@portofrotterdam.com",
        "address": "Wilhelminakade 909",
        "city": "Rotterdam",
        "countryUnCode": "NL",
        "countryName": "Netherlands",
        "zipCode": "3072 AP",
        "phoneNumber": "+31-10-2521195",
        "faxNumber": "+31-10-2521000",
        "contact": "Diana van Eijk",
        "customsEORINumber": "NL24354561",
        "ean": null,
        "chamberOfCommerceNumber": null,
        "scacCode": null,
        "ensEnabled": false,
        "cargoImportEnabled": false,
        "cargoImportEnabledByDefault": false
      }
    ],
    "nextMessageId": null
  },
  NLRTM21023672: {
    "crn": "NLRTM21023672",
    "copyOfVisit": "NLRTM21022779",
    "portOfCall": {
      "port": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM",
        "countryUnCode": "NL",
        "euPort": true
      },
      "portAuthority": {
        "fullName": "Havenbedrijf Rotterdam N.V.",
        "shortName": "HBRRTM",
        "portAuthorityId": "HbR",
        "emailAddress": "HCC@portofrotterdam.com",
        "address": "Wilhelminakade 909",
        "city": "Rotterdam",
        "countryUnCode": "NL",
        "countryName": "Netherlands",
        "zipCode": "3072 AP",
        "phoneNumber": "+31-10-2521195",
        "faxNumber": "+31-10-2521000",
        "contact": "Diana van Eijk",
        "customsEORINumber": "NL24354561",
        "ean": null,
        "chamberOfCommerceNumber": null,
        "scacCode": null,
        "ensEnabled": false,
        "cargoImportEnabled": false,
        "cargoImportEnabledByDefault": false
      },
      "customsOffice": {
        "name": "Rotterdam Haven/Kantoor Maasvlakte",
        "unCode": "NL000396"
      },
      "ataReported": true,
      "atdReported": true,
      "atdPortReported": true,
      "paDeclarationRequired": true,
      "swDeclarationRequired": true,
      "wasteEnabled": true,
      "dangerousGoodsEnabled": true,
      "harbourDuesEnabled": false,
      "orderNauticalServicesEnabled": true,
      "enableNotificationsToPa": false,
      "authoriseOrganisationsDisabled": false,
      "tugBoatsRequired": true,
      "portAuthorityClearance": true
    },
    "vessel": {
      "imoCode": "9226372",
      "name": "MAIKE D",
      "radioCallSign": "D5IL7",
      "motUnCode": "1511",
      "motName": "Full container ship/cellular vessel",
      "summerDeadWeight": 7944,
      "maxWidth": 18.94,
      "flagCode": "LBR",
      "flagCountryUnCode": "LR",
      "netTonnage": 3251,
      "grossTonnage": 6326,
      "registrationPlaceUnloCode": "LRMLW",
      "registrationPlaceName": "Monrovia",
      "registrationDate": "2000-11-03",
      "mmsiNumber": "636092635",
      "fullLength": 133.24,
      "emailAddress": null
    },
    "owner": {
      "fullName": "Unifeeder Netherlands B.V.",
      "shortName": "UNIFEEDER",
      "portAuthorityId": "UNIFEEDER",
      "emailAddress": "ops-bnl@unifeeder.com",
      "address": "Blaak 555",
      "city": "Rotterdam",
      "countryUnCode": "NL",
      "countryName": "Netherlands",
      "zipCode": "3011 GB",
      "phoneNumber": "0102179141",
      "faxNumber": null,
      "contact": "Adil Maniyar",
      "customsEORINumber": "DK11810543",
      "ean": "8713755262211",
      "chamberOfCommerceNumber": "24450473",
      "scacCode": null,
      "ensEnabled": true,
      "cargoImportEnabled": true,
      "cargoImportEnabledByDefault": true
    },
    "nextOwner": null,
    "declarant": {
      "fullName": "Unifeeder Netherlands B.V.",
      "shortName": "UNIFEEDER",
      "portAuthorityId": "UNIFEEDER",
      "emailAddress": "ops-bnl@unifeeder.com",
      "address": "Blaak 555",
      "city": "Rotterdam",
      "countryUnCode": "NL",
      "countryName": "Netherlands",
      "zipCode": "3011 GB",
      "phoneNumber": "0102179141",
      "faxNumber": null,
      "contact": "Adil Maniyar",
      "customsEORINumber": "DK11810543",
      "ean": "8713755262211",
      "chamberOfCommerceNumber": "24450473",
      "scacCode": null,
      "ensEnabled": true,
      "cargoImportEnabled": true,
      "cargoImportEnabledByDefault": true
    },
    "nextDeclarant": null,
    "financialDeclarant": {
      "fullName": "Unifeeder Netherlands B.V.",
      "shortName": "UNIFEEDER",
      "portAuthorityId": "UNIFEEDER",
      "emailAddress": "ops-bnl@unifeeder.com",
      "address": "Blaak 555",
      "city": "Rotterdam",
      "countryUnCode": "NL",
      "countryName": "Netherlands",
      "zipCode": "3011 GB",
      "phoneNumber": "0102179141",
      "faxNumber": null,
      "contact": "Adil Maniyar",
      "customsEORINumber": "DK11810543",
      "ean": "8713755262211",
      "chamberOfCommerceNumber": "24450473",
      "scacCode": null,
      "ensEnabled": true,
      "cargoImportEnabled": true,
      "cargoImportEnabledByDefault": true
    },
    "nominatedFinancialDeclarant": null,
    "orderNauticalServices": true,
    "orderIncomingMovement": true,
    "creditNumberHolderSameAsDeclarant": false,
    "declarantResponsibleForCustoms": false,
    "cargoDeclarants": [
      {
        "fullName": "Unifeeder Netherlands B.V.",
        "shortName": "UNIFEEDER",
        "portAuthorityId": "UNIFEEDER",
        "emailAddress": "ops-bnl@unifeeder.com",
        "address": "Blaak 555",
        "city": "Rotterdam",
        "countryUnCode": "NL",
        "countryName": "Netherlands",
        "zipCode": "3011 GB",
        "phoneNumber": "0102179141",
        "faxNumber": null,
        "contact": "J.F. Meeder",
        "customsEORINumber": "DK11810543",
        "ean": "8713755262211",
        "chamberOfCommerceNumber": "24450473",
        "scacCode": null,
        "ensEnabled": true,
        "cargoImportEnabled": true,
        "cargoImportEnabledByDefault": true
      },
      {
        "fullName": "Samskip Multimodal BV",
        "shortName": "GEESTRTM",
        "portAuthorityId": "SAMSKIP MCL",
        "emailAddress": "rsu@samskip.com",
        "address": "Waalhaven OZ 81",
        "city": "Rotterdam",
        "countryUnCode": "NL",
        "countryName": "Netherlands",
        "zipCode": "3087 BM",
        "phoneNumber": "+31-88-4001754",
        "faxNumber": "+31-10-4952629",
        "contact": "Andre van der Meer",
        "customsEORINumber": "NL001571734",
        "ean": "8713755262723",
        "chamberOfCommerceNumber": "24139799",
        "scacCode": "GEEL",
        "ensEnabled": true,
        "cargoImportEnabled": true,
        "cargoImportEnabledByDefault": true
      },
      {
        "fullName": "Samskip Benelux B.V.",
        "shortName": "SAMSKIP",
        "portAuthorityId": "SAMSKIP",
        "emailAddress": "charles.van.koert@samskip.com",
        "address": "Waalhaven O.Z. 81",
        "city": "Rotterdam",
        "countryUnCode": "NL",
        "countryName": "Netherlands",
        "zipCode": "3087 BM",
        "phoneNumber": "0884001131",
        "faxNumber": "0653906391",
        "contact": "Charles van Koert",
        "customsEORINumber": "NL818622106",
        "ean": null,
        "chamberOfCommerceNumber": "24243207",
        "scacCode": "SBDX",
        "ensEnabled": true,
        "cargoImportEnabled": true,
        "cargoImportEnabledByDefault": true
      },
      {
        "fullName": "DFDS logistics B.V.",
        "shortName": "DFDSCL",
        "portAuthorityId": "DFDS CONT-LINE",
        "emailAddress": "mbec@dfds.com",
        "address": "Burgemeester van Lierplein 57",
        "city": "Vlaardingen",
        "countryUnCode": "NL",
        "countryName": "Netherlands",
        "zipCode": "3134 ZB",
        "phoneNumber": "+31105032300",
        "faxNumber": null,
        "contact": "M.L. Bech",
        "customsEORINumber": "NL003189223",
        "ean": "8713755281502",
        "chamberOfCommerceNumber": "24277441",
        "scacCode": "DFDN",
        "ensEnabled": true,
        "cargoImportEnabled": true,
        "cargoImportEnabledByDefault": true
      },
      {
        "fullName": "Orient Overseas Container Line Limi",
        "shortName": "OOCLRTM",
        "portAuthorityId": "OOCL",
        "emailAddress": "danny.van.den.bosch@oocl.com",
        "address": "Weena Zuid 134",
        "city": "Rotterdam",
        "countryUnCode": "NL",
        "countryName": "Netherlands",
        "zipCode": "3012 NC",
        "phoneNumber": "0620405856",
        "faxNumber": null,
        "contact": "Danny van de Bosch",
        "customsEORINumber": "NL821606931",
        "ean": null,
        "chamberOfCommerceNumber": "50485601",
        "scacCode": null,
        "ensEnabled": false,
        "cargoImportEnabled": true,
        "cargoImportEnabledByDefault": true
      },
      {
        "fullName": "BG Freight Line B.V.",
        "shortName": "BGFREIGHT",
        "portAuthorityId": "BG FREIGHTL",
        "emailAddress": "rotterdam.captainsroom@bgfreightline.com",
        "address": "Den Hamweg 30",
        "city": "Rotterdam",
        "countryUnCode": "NL",
        "countryName": "Netherlands",
        "zipCode": "3008 JA",
        "phoneNumber": "+31-10-2311962",
        "faxNumber": "+31-10-4723808",
        "contact": "Kapaan F",
        "customsEORINumber": "NL006323017",
        "ean": null,
        "chamberOfCommerceNumber": "24145269",
        "scacCode": "BGFL",
        "ensEnabled": true,
        "cargoImportEnabled": true,
        "cargoImportEnabledByDefault": true
      },
      {
        "fullName": "Cross-Ocean B.V.",
        "shortName": "CROSSOCEANBV",
        "portAuthorityId": "CROSS OCEAN",
        "emailAddress": "gad@crossocean.nl",
        "address": "Weena 280",
        "city": "Rotterdam",
        "countryUnCode": "NL",
        "countryName": "Netherlands",
        "zipCode": "3012 NJ",
        "phoneNumber": "010-2248322",
        "faxNumber": null,
        "contact": "I.de Kramer",
        "customsEORINumber": "NL005140985",
        "ean": null,
        "chamberOfCommerceNumber": "24137366",
        "scacCode": null,
        "ensEnabled": true,
        "cargoImportEnabled": true,
        "cargoImportEnabledByDefault": true
      },
      {
        "fullName": "CMA-CGM (Holland) B.V.",
        "shortName": "CMACGMRHN",
        "portAuthorityId": "CMA-CGM",
        "emailAddress": "rtm.shipops@cma-cgm.com",
        "address": "Achterdijk 55",
        "city": "Rhoon",
        "countryUnCode": "NL",
        "countryName": "Netherlands",
        "zipCode": "3161 EB",
        "phoneNumber": "010-2998199",
        "faxNumber": "010-2998190",
        "contact": "Marcel den Boogert",
        "customsEORINumber": "NL806025463",
        "ean": null,
        "chamberOfCommerceNumber": "24276503",
        "scacCode": null,
        "ensEnabled": false,
        "cargoImportEnabled": true,
        "cargoImportEnabledByDefault": true
      },
      {
        "fullName": "Mann Lines B.V.",
        "shortName": "MANNLINES",
        "portAuthorityId": "MANN LINES",
        "emailAddress": "marcel.sparreboom@mannlines.nl",
        "address": "Veerhaven 7",
        "city": "Rotterdam",
        "countryUnCode": "NL",
        "countryName": "Netherlands",
        "zipCode": "3016 CJ",
        "phoneNumber": "+31-10-2999666",
        "faxNumber": "+31-10-2999665",
        "contact": "Marcel Sparreboom",
        "customsEORINumber": "NL818469298",
        "ean": null,
        "chamberOfCommerceNumber": "22065312",
        "scacCode": null,
        "ensEnabled": false,
        "cargoImportEnabled": true,
        "cargoImportEnabledByDefault": true
      },
      {
        "fullName": "X-Press Container Line (UK) LTD",
        "shortName": "XPRESSLINE",
        "portAuthorityId": "XCL",
        "emailAddress": "graham.thrall@x-pressfeeders.com",
        "address": "Singapurstraße 1",
        "city": "Hamburg",
        "countryUnCode": "DE",
        "countryName": "Germany",
        "zipCode": "20457",
        "phoneNumber": "+49-15209267339",
        "faxNumber": "+49-40369192511",
        "contact": "Mr. Graham Thrall",
        "customsEORINumber": "DE277403241692493",
        "ean": null,
        "chamberOfCommerceNumber": "2470077",
        "scacCode": "XCLS",
        "ensEnabled": true,
        "cargoImportEnabled": true,
        "cargoImportEnabledByDefault": true
      },
      {
        "fullName": "FESCO North West Europe B.V.",
        "shortName": "FESCO",
        "portAuthorityId": "Fesco lines bv",
        "emailAddress": "kprivalov@fesco.com",
        "address": "Hofhoek 20-48",
        "city": "Hofhoek 20-48Poortugaal",
        "countryUnCode": "NL",
        "countryName": "Netherlands",
        "zipCode": "3176 PD",
        "phoneNumber": "+31880066700",
        "faxNumber": null,
        "contact": "K.S. Privalov",
        "customsEORINumber": "NL818356054",
        "ean": null,
        "chamberOfCommerceNumber": "24419889",
        "scacCode": "FESO",
        "ensEnabled": false,
        "cargoImportEnabled": true,
        "cargoImportEnabledByDefault": true
      },
      {
        "fullName": "Containerships R dam B.V.",
        "shortName": "CONTAINERSHIPS",
        "portAuthorityId": "CONTAINER SH",
        "emailAddress": "Vesselplanners@containerships.nl",
        "address": "Den Hamweg 30",
        "city": "Rotterdam",
        "countryUnCode": "NL",
        "countryName": "Netherlands",
        "zipCode": "3089 KK",
        "phoneNumber": "+31-10-7980761",
        "faxNumber": "+31-10-4281971",
        "contact": "M. Aukes",
        "customsEORINumber": "NL006506938",
        "ean": null,
        "chamberOfCommerceNumber": "24148092.1",
        "scacCode": null,
        "ensEnabled": false,
        "cargoImportEnabled": true,
        "cargoImportEnabledByDefault": true
      }
    ],
    "shipOperators": [
      {
        "fullName": "Unifeeder Netherlands B.V.",
        "shortName": "UNIFEEDER",
        "portAuthorityId": "UNIFEEDER",
        "emailAddress": "ops-bnl@unifeeder.com",
        "address": "Blaak 555",
        "city": "Rotterdam",
        "countryUnCode": "NL",
        "countryName": "Netherlands",
        "zipCode": "3011 GB",
        "phoneNumber": "0102179141",
        "faxNumber": null,
        "contact": "Adil Maniyar",
        "customsEORINumber": "DK11810543",
        "ean": "8713755262211",
        "chamberOfCommerceNumber": "24450473",
        "scacCode": null,
        "ensEnabled": true,
        "cargoImportEnabled": true,
        "cargoImportEnabledByDefault": true
      },
      {
        "fullName": "Unifeeder Netherlands B.V.",
        "shortName": "UNIFEEDER",
        "portAuthorityId": "UNIFEEDER",
        "emailAddress": "ops-bnl@unifeeder.com",
        "address": "Blaak 555",
        "city": "Rotterdam",
        "countryUnCode": "NL",
        "countryName": "Netherlands",
        "zipCode": "3011 GB",
        "phoneNumber": "0102179141",
        "faxNumber": null,
        "contact": "Adil Maniyar",
        "customsEORINumber": "DK11810543",
        "ean": "8713755262211",
        "chamberOfCommerceNumber": "24450473",
        "scacCode": null,
        "ensEnabled": true,
        "cargoImportEnabled": true,
        "cargoImportEnabledByDefault": true
      },
      {
        "fullName": "Havenbedrijf Rotterdam N.V.",
        "shortName": "HBRRTM",
        "portAuthorityId": "HbR",
        "emailAddress": "HCC@portofrotterdam.com",
        "address": "Wilhelminakade 909",
        "city": "Rotterdam",
        "countryUnCode": "NL",
        "countryName": "Netherlands",
        "zipCode": "3072 AP",
        "phoneNumber": "+31-10-2521195",
        "faxNumber": "+31-10-2521000",
        "contact": "Diana van Eijk",
        "customsEORINumber": "NL24354561",
        "ean": null,
        "chamberOfCommerceNumber": null,
        "scacCode": null,
        "ensEnabled": false,
        "cargoImportEnabled": false,
        "cargoImportEnabledByDefault": false
      }
    ],
    "additionalViewers": [],
    "visitDeclaration": {
      "clientReferenceNumber": null,
      "vesselEmailAddress": null,
      "etaFirstEntryEu": "2022-10-05T06:15:00Z",
      "arrivalVoyage": {
        "voyageNumber": "357",
        "carrier": {
          "customsId": "DK11810543",
          "name": "Unifeeder A/S",
          "scacCode": "UFEE",
          "smdgCode": "UFE",
          "cargoDeclarantShortName": null
        }
      },
      "previousPorts": [
        {
          "id": "864786f6-54d8-41b0-b31e-bfab74579912",
          "arrival": "2022-10-04T08:15:00Z",
          "departure": "2022-10-05T03:15:00Z",
          "port": {
            "name": "London Gateway Port",
            "locationUnCode": "GBLGP",
            "countryUnCode": "GB",
            "euPort": false
          },
          "portFacilityVisits": [
            {
              "portFacility": {
                "code": "GBLGP-9999",
                "name": "UNKNOWN"
              },
              "arrivalDate": "2022-10-04",
              "departureDate": "2022-10-05",
              "securityLevel": "SL1",
              "additionalSecurityMeasures": null
            }
          ]
        },
        {
          "id": "39e359d0-df9a-4480-a76c-2daf70825215",
          "arrival": "2022-10-01T12:45:00Z",
          "departure": "2022-10-03T08:15:00Z",
          "port": {
            "name": "Grangemouth",
            "locationUnCode": "GBGRG",
            "countryUnCode": "GB",
            "euPort": false
          },
          "portFacilityVisits": [
            {
              "portFacility": {
                "code": "GBGRG-0001",
                "name": "Port of Grangemouth"
              },
              "arrivalDate": "2022-10-01",
              "departureDate": "2022-10-03",
              "securityLevel": "SL1",
              "additionalSecurityMeasures": null
            }
          ]
        },
        {
          "id": "cbc3839f-9e38-4cc4-b7d0-8b5a29f11069",
          "arrival": "2022-09-30T15:00:00Z",
          "departure": "2022-10-01T03:30:00Z",
          "port": {
            "name": "Teesport",
            "locationUnCode": "GBTEE",
            "countryUnCode": "GB",
            "euPort": false
          },
          "portFacilityVisits": [
            {
              "portFacility": {
                "code": "GBTEE-0001",
                "name": "PD Teesport"
              },
              "arrivalDate": "2022-09-30",
              "departureDate": "2022-10-01",
              "securityLevel": "SL1",
              "additionalSecurityMeasures": null
            }
          ]
        },
        {
          "id": "55087585-f262-40a7-9d09-ac7110a96851",
          "arrival": "2022-09-26T16:00:00Z",
          "departure": "2022-09-29T19:30:00Z",
          "port": {
            "name": "Rotterdam",
            "locationUnCode": "NLRTM",
            "countryUnCode": "NL",
            "euPort": true
          },
          "portFacilityVisits": [
            {
              "portFacility": {
                "code": "NLRTM-0071",
                "name": "Rotterdam: ECT Delta Terminal B.V. (47-1)"
              },
              "arrivalDate": "2022-09-29",
              "departureDate": "2022-09-29",
              "securityLevel": "SL1",
              "additionalSecurityMeasures": null
            },
            {
              "portFacility": {
                "code": "NLRTM-0510",
                "name": "Rotterdam: Feederwachtpalen Europahaven (52-70)"
              },
              "arrivalDate": "2022-09-28",
              "departureDate": "2022-09-29",
              "securityLevel": "SL1",
              "additionalSecurityMeasures": null
            },
            {
              "portFacility": {
                "code": "NLRTM-0467",
                "name": "Rotterdam: Rotterdam World Gateway (454-1)"
              },
              "arrivalDate": "2022-09-28",
              "departureDate": "2022-09-28",
              "securityLevel": "SL1",
              "additionalSecurityMeasures": null
            },
            {
              "portFacility": {
                "code": "NLRTM-0510",
                "name": "Rotterdam: Feederwachtpalen Europahaven (52-70)"
              },
              "arrivalDate": "2022-09-28",
              "departureDate": "2022-09-28",
              "securityLevel": "SL1",
              "additionalSecurityMeasures": null
            },
            {
              "portFacility": {
                "code": "NLRTM-0459",
                "name": "Rotterdam: ECT Euromax Terminal  (47-3)"
              },
              "arrivalDate": "2022-09-27",
              "departureDate": "2022-09-27",
              "securityLevel": "SL1",
              "additionalSecurityMeasures": null
            }
          ]
        },
        {
          "id": "9b499883-2168-4576-b650-67ddd1339a9a",
          "arrival": "2022-09-25T16:30:00Z",
          "departure": "2022-09-26T12:00:00Z",
          "port": {
            "name": "London Gateway Port",
            "locationUnCode": "GBLGP",
            "countryUnCode": "GB",
            "euPort": false
          },
          "portFacilityVisits": [
            {
              "portFacility": {
                "code": "GBLGP-9999",
                "name": "UNKNOWN"
              },
              "arrivalDate": "2022-09-25",
              "departureDate": "2022-09-26",
              "securityLevel": "SL1",
              "additionalSecurityMeasures": null
            }
          ]
        },
        {
          "id": "9dd2fd8c-0c5c-46da-ba5e-62234b906ac4",
          "arrival": "2022-09-21T18:30:00Z",
          "departure": "2022-09-24T14:00:00Z",
          "port": {
            "name": "Grangemouth",
            "locationUnCode": "GBGRG",
            "countryUnCode": "GB",
            "euPort": false
          },
          "portFacilityVisits": [
            {
              "portFacility": {
                "code": "GBGRG-0001",
                "name": "Port of Grangemouth"
              },
              "arrivalDate": "2022-09-21",
              "departureDate": "2022-09-24",
              "securityLevel": "SL1",
              "additionalSecurityMeasures": null
            }
          ]
        }
      ],
      "portVisit": {
        "pilotStation": {
          "code": "MC",
          "name": "Maascenter",
          "atSea": true
        },
        "entryPoint": {
          "code": "LL",
          "name": "Lage Licht",
          "atSea": true
        },
        "etaPort": "2022-10-05T10:30:00Z",
        "portEntry": {
          "origin": "SEA",
          "etaSeaBuoy": "2022-10-05T10:30:00Z",
          "etaPilotBoardingPlace": "2022-10-05T11:00:00Z",
          "requestedEtaPilotBoardingPlace": null,
          "earliestTimeOfPortEntry": null,
          "intention": "REQUEST_FOR_ENTRY",
          "baseForPlanning": "FIRST_BERTH",
          "entryDependency": null
        },
        "firstMovement": {
          "vesselDraft": 7,
          "vesselMasterName": "STOICESCU MIHAI",
          "numberOfCrew": 12,
          "numberOfPassengers": 0,
          "cargo": "CONTAINERIZED",
          "orderEmail": null,
          "orderSms": null,
          "pilotExemption": null,
          "offStandardBeam": null,
          "pilotService": {
            "required": true,
            "serviceProvider": {
              "name": "Loodswezen",
              "portAuthorityId": "LRR"
            },
            "remarks": null
          },
          "order": false,
          "stormPilotageInformation": {
            "heliSuitable": null,
            "hoisting": null,
            "remotePilotage": null,
            "imoLoaRegistered": null,
            "lowFreeboard": null,
            "remarks": null
          }
        },
        "passingThroughTugboats": null,
        "defectTypes": [],
        "defectTypeRemarks": null,
        "berthVisits": [
          {
            "id": "9e07e8d3-9af3-4027-ad0c-e49ed505baec",
            "berth": {
              "name": "AMAZONEH ECT DDE",
              "shortName": null,
              "code": "R8160",
              "terminalCode": "4810",
              "terminalName": "AMAZONEH ECT DDE",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V.",
              "berthGroupCode": "ECT DELTA",
              "buoy": false,
              "harbourDuesArea": true,
              "partyToNotify": null
            },
            "eta": "2022-10-05T12:30:00Z",
            "requestedEta": null,
            "ata": null,
            "etd": "2022-10-05T23:00:00Z",
            "requestedEtd": null,
            "atd": null,
            "mooring": "NO_PREFERENCE",
            "remarks": null,
            "visitPurposes": [
              "DISCHARGE",
              "LOADING"
            ],
            "bollardFrom": null,
            "bollardTo": null,
            "tugboatAtArrival": {
              "required": false,
              "serviceProvider": {
                "name": "Fairplay",
                "portAuthorityId": "FAIRPLAY"
              },
              "requiredQuantity": null,
              "remarks": null
            },
            "boatmenAtArrival": {
              "required": true,
              "serviceProvider": {
                "name": "KRVE",
                "portAuthorityId": "RVE"
              },
              "remarks": null
            },
            "boatmenAtDeparture": {
              "required": true,
              "serviceProvider": {
                "name": "KRVE",
                "portAuthorityId": "RVE"
              },
              "remarks": null
            },
            "tugboatAtDeparture": {
              "required": false,
              "serviceProvider": {
                "name": "Fairplay",
                "portAuthorityId": "FAIRPLAY"
              },
              "requiredQuantity": null,
              "remarks": null
            },
            "nextMovement": {
              "vesselDraft": 7,
              "vesselMasterName": "STOICESCU MIHAI",
              "numberOfCrew": 12,
              "numberOfPassengers": 0,
              "cargo": "CONTAINERIZED",
              "orderEmail": null,
              "orderSms": null,
              "pilotExemption": null,
              "offStandardBeam": null,
              "pilotService": {
                "required": true,
                "serviceProvider": {
                  "name": "Loodswezen",
                  "portAuthorityId": "LRR"
                },
                "remarks": null
              },
              "order": null,
              "stormPilotageInformation": null
            }
          },
          {
            "id": "bd281696-d202-4fce-afec-077306fe001b",
            "berth": {
              "name": "YANGTZEK EUROMAX",
              "shortName": null,
              "code": "R9840",
              "terminalCode": "74353661",
              "terminalName": "YANGTZEK EUROMAX",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V.",
              "berthGroupCode": "YANGTZEK EUROMAX",
              "buoy": false,
              "harbourDuesArea": true,
              "partyToNotify": null
            },
            "eta": "2022-10-06T00:00:00Z",
            "requestedEta": null,
            "ata": null,
            "etd": "2022-10-06T10:30:00Z",
            "requestedEtd": null,
            "atd": null,
            "mooring": "NO_PREFERENCE",
            "remarks": null,
            "visitPurposes": [
              "DISCHARGE",
              "LOADING"
            ],
            "bollardFrom": null,
            "bollardTo": null,
            "tugboatAtArrival": {
              "required": false,
              "serviceProvider": {
                "name": "Fairplay",
                "portAuthorityId": "FAIRPLAY"
              },
              "requiredQuantity": null,
              "remarks": null
            },
            "boatmenAtArrival": {
              "required": true,
              "serviceProvider": {
                "name": "KRVE",
                "portAuthorityId": "RVE"
              },
              "remarks": null
            },
            "boatmenAtDeparture": {
              "required": true,
              "serviceProvider": {
                "name": "KRVE",
                "portAuthorityId": "RVE"
              },
              "remarks": null
            },
            "tugboatAtDeparture": {
              "required": false,
              "serviceProvider": {
                "name": "Fairplay",
                "portAuthorityId": "FAIRPLAY"
              },
              "requiredQuantity": null,
              "remarks": null
            },
            "nextMovement": {
              "vesselDraft": 7,
              "vesselMasterName": "STOICESCU MIHAI",
              "numberOfCrew": 12,
              "numberOfPassengers": 0,
              "cargo": "CONTAINERIZED",
              "orderEmail": null,
              "orderSms": null,
              "pilotExemption": null,
              "offStandardBeam": null,
              "pilotService": {
                "required": true,
                "serviceProvider": {
                  "name": "Loodswezen",
                  "portAuthorityId": "LRR"
                },
                "remarks": null
              },
              "order": null,
              "stormPilotageInformation": null
            }
          },
          {
            "id": "f284acaa-e332-44b7-b5cd-4e127d0bbc58",
            "berth": {
              "name": "AMALIAH RWG DS 1",
              "shortName": null,
              "code": "R8962",
              "terminalCode": "8535",
              "terminalName": "AMALIAH RWG DS 1",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam World Gateway (RWG)",
              "berthGroupCode": "Rotterdam World Gateway B.V.",
              "buoy": false,
              "harbourDuesArea": true,
              "partyToNotify": null
            },
            "eta": "2022-10-06T11:30:00Z",
            "requestedEta": null,
            "ata": null,
            "etd": "2022-10-06T22:00:00Z",
            "requestedEtd": null,
            "atd": null,
            "mooring": "NO_PREFERENCE",
            "remarks": null,
            "visitPurposes": [
              "DISCHARGE",
              "LOADING"
            ],
            "bollardFrom": null,
            "bollardTo": null,
            "tugboatAtArrival": {
              "required": false,
              "serviceProvider": {
                "name": "Fairplay",
                "portAuthorityId": "FAIRPLAY"
              },
              "requiredQuantity": null,
              "remarks": null
            },
            "boatmenAtArrival": {
              "required": true,
              "serviceProvider": {
                "name": "KRVE",
                "portAuthorityId": "RVE"
              },
              "remarks": null
            },
            "boatmenAtDeparture": {
              "required": true,
              "serviceProvider": {
                "name": "KRVE",
                "portAuthorityId": "RVE"
              },
              "remarks": null
            },
            "tugboatAtDeparture": {
              "required": false,
              "serviceProvider": {
                "name": "Fairplay",
                "portAuthorityId": "FAIRPLAY"
              },
              "requiredQuantity": null,
              "remarks": null
            },
            "nextMovement": {
              "vesselDraft": 7,
              "vesselMasterName": "STOICESCU MIHAI",
              "numberOfCrew": 12,
              "numberOfPassengers": 0,
              "cargo": "CONTAINERIZED",
              "orderEmail": null,
              "orderSms": null,
              "pilotExemption": null,
              "offStandardBeam": null,
              "pilotService": {
                "required": true,
                "serviceProvider": {
                  "name": "Loodswezen",
                  "portAuthorityId": "LRR"
                },
                "remarks": null
              },
              "order": null,
              "stormPilotageInformation": {
                "heliSuitable": null,
                "hoisting": null,
                "remotePilotage": null,
                "imoLoaRegistered": null,
                "lowFreeboard": null,
                "remarks": null
              }
            }
          }
        ],
        "dangerousGoodsLoading": true,
        "dangerousGoodsDischarge": false,
        "dangerousGoodsTransit": false,
        "vesselInspectionRequired": false,
        "shipConfiguration": null,
        "exitPoint": {
          "code": "LL",
          "name": "Lage Licht",
          "atSea": true
        },
        "etdPort": "2022-10-07T00:00:00Z",
        "atdPort": null,
        "ataPort": null
      },
      "departureVoyage": {
        "voyageNumber": "357",
        "carrier": {
          "customsId": "DK11810543",
          "name": "Unifeeder A/S",
          "scacCode": "UFEE",
          "smdgCode": "UFE",
          "cargoDeclarantShortName": null
        }
      },
      "nextPorts": [
        {
          "id": "2557f8a5-36be-48e2-84ec-bf04bf6be22a",
          "arrival": "2022-10-07T15:45:00Z",
          "departure": "2022-10-08T11:45:00Z",
          "port": {
            "name": "Teesport",
            "locationUnCode": "GBTEE",
            "countryUnCode": "GB",
            "euPort": false
          },
          "customsOffice": null
        },
        {
          "id": "0be9b138-8ab0-4783-b452-64ac6a243867",
          "arrival": "2022-10-08T20:00:00Z",
          "departure": "2022-10-10T01:00:00Z",
          "port": {
            "name": "Grangemouth",
            "locationUnCode": "GBGRG",
            "countryUnCode": "GB",
            "euPort": false
          },
          "customsOffice": null
        },
        {
          "id": "e5b44980-b86d-4fc0-ac24-83fb46fc2e85",
          "arrival": "2022-10-11T01:00:00Z",
          "departure": "2022-10-11T11:00:00Z",
          "port": {
            "name": "London Gateway Port",
            "locationUnCode": "GBLGP",
            "countryUnCode": "GB",
            "euPort": false
          },
          "customsOffice": null
        },
        {
          "id": "e019bf69-891a-4a64-8beb-886c6a55831e",
          "arrival": "2022-10-11T18:30:00Z",
          "departure": "2022-10-13T09:00:00Z",
          "port": {
            "name": "Rotterdam",
            "locationUnCode": "NLRTM",
            "countryUnCode": "NL",
            "euPort": true
          },
          "customsOffice": {
            "name": "Rotterdam Haven/Kantoor Maasvlakte",
            "unCode": "NL000396"
          }
        }
      ]
    },
    "nextVisitDeclaration": null,
    "securityDeclaration": {
      "securityReportRequired": "YES",
      "companySecurityOfficer": {
        "name": "MARK DREVIN",
        "email": "operations@reederei-drevin.de",
        "phoneNumber": "+4947215512690"
      },
      "currentSecurityLevel": "SL1",
      "approvedSspDocumentOnBoard": true,
      "isscSecurityDocument": {
        "availability": "YES",
        "expiryDate": "2025-01-10",
        "reasonNoValidIsscOnBoard": null,
        "issuer": {
          "code": "112",
          "name": "DNV GL AS"
        }
      },
      "shipToShipActivities": [],
      "securityRelatedMatter": null
    },
    "wasteDeclaration": null,
    "wasteCollections": [],
    "shipStoresDeclaration": null,
    "dangerousGoodsDeclarations": [],
    "nextDangerousGoodsDeclaration": null,
    "importDeclarations": [],
    "transitDeclarationMap": {},
    "loadingDeclarations": [],
    "paxDeclarationSummaries": [],
    "healthDeclarationSummary": null,
    "etaPortAis": null,
    "ignoreEtaPortAis": false,
    "cancelled": false,
    "ataRegisteredByPa": false,
    "visitKnownAtPa": true,
    "dangerousGoodsEnabled": true,
    "cargoImportEnabled": true,
    "ataAcknowledged": false,
    "declarations": [
      {
        "type": "VISIT",
        "id": null,
        "status": "ACCEPTED",
        "rejectReasons": null,
        "timeStamp": "2022-09-29T15:12:40.090Z"
      },
      {
        "type": "WPCS",
        "id": null,
        "status": "DECLARED",
        "rejectReasons": null,
        "timeStamp": "2022-09-29T15:12:43.054Z"
      },
      {
        "type": "SECURITY",
        "id": null,
        "status": "ACCEPTED",
        "rejectReasons": null,
        "timeStamp": "2022-09-29T15:12:46.908Z"
      }
    ],
    "uploads": {},
    "additionalIncomingMovementInfo": {
      "etaPilotBoardingPlace": null,
      "plannedNumberOfTugboats": null,
      "shifted": false,
      "etdChanged": false,
      "etaChanged": false,
      "entryDependencyRemarks": null
    },
    "berthVisitInfos": {},
    "incomingMovementHarbourMasterInfo": {
      "orderStatus": null,
      "pilotOnBoard": null,
      "rejectionReason": null,
      "remarks": {
        "text": "remarks"
      },
      "movementOnHold": false
    },
    "incomingTidalWindowStatus": null,
    "lastMessageId": "4649c8b0-5fe0-4cc3-b6b4-ab7b51653139",
    "created": "2022-09-29T15:03:18.015Z",
    "updated": "2022-09-29T15:12:48.368Z",
    "berthVisitIdOfTransfer": null,
    "hasBeenTransferred": false,
    "transitDeclarations": [],
    "previousMessageId": "b747f8d5-4303-45ee-a0d4-1232fde26835",
    "nextMessageId": null,
    "allowedViewers": [
      {
        "fullName": "DFDS logistics B.V.",
        "shortName": "DFDSCL",
        "portAuthorityId": "DFDS CONT-LINE",
        "emailAddress": "mbec@dfds.com",
        "address": "Burgemeester van Lierplein 57",
        "city": "Vlaardingen",
        "countryUnCode": "NL",
        "countryName": "Netherlands",
        "zipCode": "3134 ZB",
        "phoneNumber": "+31105032300",
        "faxNumber": null,
        "contact": "M.L. Bech",
        "customsEORINumber": "NL003189223",
        "ean": "8713755281502",
        "chamberOfCommerceNumber": "24277441",
        "scacCode": "DFDN",
        "ensEnabled": true,
        "cargoImportEnabled": true,
        "cargoImportEnabledByDefault": true
      },
      {
        "fullName": "BG Freight Line B.V.",
        "shortName": "BGFREIGHT",
        "portAuthorityId": "BG FREIGHTL",
        "emailAddress": "rotterdam.captainsroom@bgfreightline.com",
        "address": "Den Hamweg 30",
        "city": "Rotterdam",
        "countryUnCode": "NL",
        "countryName": "Netherlands",
        "zipCode": "3008 JA",
        "phoneNumber": "+31-10-2311962",
        "faxNumber": "+31-10-4723808",
        "contact": "Kapaan F",
        "customsEORINumber": "NL006323017",
        "ean": null,
        "chamberOfCommerceNumber": "24145269",
        "scacCode": "BGFL",
        "ensEnabled": true,
        "cargoImportEnabled": true,
        "cargoImportEnabledByDefault": true
      },
      {
        "fullName": "Mann Lines B.V.",
        "shortName": "MANNLINES",
        "portAuthorityId": "MANN LINES",
        "emailAddress": "marcel.sparreboom@mannlines.nl",
        "address": "Veerhaven 7",
        "city": "Rotterdam",
        "countryUnCode": "NL",
        "countryName": "Netherlands",
        "zipCode": "3016 CJ",
        "phoneNumber": "+31-10-2999666",
        "faxNumber": "+31-10-2999665",
        "contact": "Marcel Sparreboom",
        "customsEORINumber": "NL818469298",
        "ean": null,
        "chamberOfCommerceNumber": "22065312",
        "scacCode": null,
        "ensEnabled": false,
        "cargoImportEnabled": true,
        "cargoImportEnabledByDefault": true
      },
      {
        "fullName": "Samskip Multimodal BV",
        "shortName": "GEESTRTM",
        "portAuthorityId": "SAMSKIP MCL",
        "emailAddress": "rsu@samskip.com",
        "address": "Waalhaven OZ 81",
        "city": "Rotterdam",
        "countryUnCode": "NL",
        "countryName": "Netherlands",
        "zipCode": "3087 BM",
        "phoneNumber": "+31-88-4001754",
        "faxNumber": "+31-10-4952629",
        "contact": "Andre van der Meer",
        "customsEORINumber": "NL001571734",
        "ean": "8713755262723",
        "chamberOfCommerceNumber": "24139799",
        "scacCode": "GEEL",
        "ensEnabled": true,
        "cargoImportEnabled": true,
        "cargoImportEnabledByDefault": true
      },
      {
        "fullName": "Havenbedrijf Rotterdam N.V.",
        "shortName": "HBRRTM",
        "portAuthorityId": "HbR",
        "emailAddress": "HCC@portofrotterdam.com",
        "address": "Wilhelminakade 909",
        "city": "Rotterdam",
        "countryUnCode": "NL",
        "countryName": "Netherlands",
        "zipCode": "3072 AP",
        "phoneNumber": "+31-10-2521195",
        "faxNumber": "+31-10-2521000",
        "contact": "Diana van Eijk",
        "customsEORINumber": "NL24354561",
        "ean": null,
        "chamberOfCommerceNumber": null,
        "scacCode": null,
        "ensEnabled": false,
        "cargoImportEnabled": false,
        "cargoImportEnabledByDefault": false
      },
      {
        "fullName": "X-Press Container Line (UK) LTD",
        "shortName": "XPRESSLINE",
        "portAuthorityId": "XCL",
        "emailAddress": "graham.thrall@x-pressfeeders.com",
        "address": "Singapurstraße 1",
        "city": "Hamburg",
        "countryUnCode": "DE",
        "countryName": "Germany",
        "zipCode": "20457",
        "phoneNumber": "+49-15209267339",
        "faxNumber": "+49-40369192511",
        "contact": "Mr. Graham Thrall",
        "customsEORINumber": "DE277403241692493",
        "ean": null,
        "chamberOfCommerceNumber": "2470077",
        "scacCode": "XCLS",
        "ensEnabled": true,
        "cargoImportEnabled": true,
        "cargoImportEnabledByDefault": true
      },
      {
        "fullName": "Unifeeder Netherlands B.V.",
        "shortName": "UNIFEEDER",
        "portAuthorityId": "UNIFEEDER",
        "emailAddress": "ops-bnl@unifeeder.com",
        "address": "Blaak 555",
        "city": "Rotterdam",
        "countryUnCode": "NL",
        "countryName": "Netherlands",
        "zipCode": "3011 GB",
        "phoneNumber": "0102179141",
        "faxNumber": null,
        "contact": "Adil Maniyar",
        "customsEORINumber": "DK11810543",
        "ean": "8713755262211",
        "chamberOfCommerceNumber": "24450473",
        "scacCode": null,
        "ensEnabled": true,
        "cargoImportEnabled": true,
        "cargoImportEnabledByDefault": true
      },
      {
        "fullName": "Samskip Benelux B.V.",
        "shortName": "SAMSKIP",
        "portAuthorityId": "SAMSKIP",
        "emailAddress": "charles.van.koert@samskip.com",
        "address": "Waalhaven O.Z. 81",
        "city": "Rotterdam",
        "countryUnCode": "NL",
        "countryName": "Netherlands",
        "zipCode": "3087 BM",
        "phoneNumber": "0884001131",
        "faxNumber": "0653906391",
        "contact": "Charles van Koert",
        "customsEORINumber": "NL818622106",
        "ean": null,
        "chamberOfCommerceNumber": "24243207",
        "scacCode": "SBDX",
        "ensEnabled": true,
        "cargoImportEnabled": true,
        "cargoImportEnabledByDefault": true
      },
      {
        "fullName": "Orient Overseas Container Line Limi",
        "shortName": "OOCLRTM",
        "portAuthorityId": "OOCL",
        "emailAddress": "danny.van.den.bosch@oocl.com",
        "address": "Weena Zuid 134",
        "city": "Rotterdam",
        "countryUnCode": "NL",
        "countryName": "Netherlands",
        "zipCode": "3012 NC",
        "phoneNumber": "0620405856",
        "faxNumber": null,
        "contact": "Danny van de Bosch",
        "customsEORINumber": "NL821606931",
        "ean": null,
        "chamberOfCommerceNumber": "50485601",
        "scacCode": null,
        "ensEnabled": false,
        "cargoImportEnabled": true,
        "cargoImportEnabledByDefault": true
      },
      {
        "fullName": "Cross-Ocean B.V.",
        "shortName": "CROSSOCEANBV",
        "portAuthorityId": "CROSS OCEAN",
        "emailAddress": "gad@crossocean.nl",
        "address": "Weena 280",
        "city": "Rotterdam",
        "countryUnCode": "NL",
        "countryName": "Netherlands",
        "zipCode": "3012 NJ",
        "phoneNumber": "010-2248322",
        "faxNumber": null,
        "contact": "I.de Kramer",
        "customsEORINumber": "NL005140985",
        "ean": null,
        "chamberOfCommerceNumber": "24137366",
        "scacCode": null,
        "ensEnabled": true,
        "cargoImportEnabled": true,
        "cargoImportEnabledByDefault": true
      },
      {
        "fullName": "Unifeeder Netherlands B.V.",
        "shortName": "UNIFEEDER",
        "portAuthorityId": "UNIFEEDER",
        "emailAddress": "ops-bnl@unifeeder.com",
        "address": "Blaak 555",
        "city": "Rotterdam",
        "countryUnCode": "NL",
        "countryName": "Netherlands",
        "zipCode": "3011 GB",
        "phoneNumber": "0102179141",
        "faxNumber": null,
        "contact": "J.F. Meeder",
        "customsEORINumber": "DK11810543",
        "ean": "8713755262211",
        "chamberOfCommerceNumber": "24450473",
        "scacCode": null,
        "ensEnabled": true,
        "cargoImportEnabled": true,
        "cargoImportEnabledByDefault": true
      },
      {
        "fullName": "CMA-CGM (Holland) B.V.",
        "shortName": "CMACGMRHN",
        "portAuthorityId": "CMA-CGM",
        "emailAddress": "rtm.shipops@cma-cgm.com",
        "address": "Achterdijk 55",
        "city": "Rhoon",
        "countryUnCode": "NL",
        "countryName": "Netherlands",
        "zipCode": "3161 EB",
        "phoneNumber": "010-2998199",
        "faxNumber": "010-2998190",
        "contact": "Marcel den Boogert",
        "customsEORINumber": "NL806025463",
        "ean": null,
        "chamberOfCommerceNumber": "24276503",
        "scacCode": null,
        "ensEnabled": false,
        "cargoImportEnabled": true,
        "cargoImportEnabledByDefault": true
      },
      {
        "fullName": "FESCO North West Europe B.V.",
        "shortName": "FESCO",
        "portAuthorityId": "Fesco lines bv",
        "emailAddress": "kprivalov@fesco.com",
        "address": "Hofhoek 20-48",
        "city": "Hofhoek 20-48Poortugaal",
        "countryUnCode": "NL",
        "countryName": "Netherlands",
        "zipCode": "3176 PD",
        "phoneNumber": "+31880066700",
        "faxNumber": null,
        "contact": "K.S. Privalov",
        "customsEORINumber": "NL818356054",
        "ean": null,
        "chamberOfCommerceNumber": "24419889",
        "scacCode": "FESO",
        "ensEnabled": false,
        "cargoImportEnabled": true,
        "cargoImportEnabledByDefault": true
      },
      {
        "fullName": "Containerships R dam B.V.",
        "shortName": "CONTAINERSHIPS",
        "portAuthorityId": "CONTAINER SH",
        "emailAddress": "Vesselplanners@containerships.nl",
        "address": "Den Hamweg 30",
        "city": "Rotterdam",
        "countryUnCode": "NL",
        "countryName": "Netherlands",
        "zipCode": "3089 KK",
        "phoneNumber": "+31-10-7980761",
        "faxNumber": "+31-10-4281971",
        "contact": "M. Aukes",
        "customsEORINumber": "NL006506938",
        "ean": null,
        "chamberOfCommerceNumber": "24148092.1",
        "scacCode": null,
        "ensEnabled": false,
        "cargoImportEnabled": true,
        "cargoImportEnabledByDefault": true
      }
    ],
    "clearances": {
      "sspi": {
        "departureBerthVisitId": null,
        "arrivalBerthVisitId": null,
        "status": "APPROVED",
        "attentionPoints": [
          {
            "type": "SECURITY_MATTERS_MISSING",
            "remarks": "Andere matters"
          },
          {
            "type": "SECURITY_LEVEL_DIFFERS",
            "remarks": "SL2"
          }
        ],
        "remarks": "Komt zo niet naar binnen!",
        "notRequired": false
      },
      "emas": {
        "status": "APPROVED_WITH_REMARKS",
        "attentionPoints": [
          {
            "type": "NOTIFICATION_INCORRECT",
            "remarks": "Verkeerde emas melding"
          }
        ]
      },
      "mdoh": {
        "status": "APPROVED_WITH_REMARKS",
        "attentionPoints": [
          {
            "type": "HEALTH_MEASURES_IMPOSED",
            "remarks": "Remark: Verkeerde been uit bed ;-)"
          }
        ],
        "remarks": "Mr. de Markies"
      },
      "emgs": {
        "status": "APPROVED_WITH_REMARKS",
        "attentionPoints": [
          {
            "type": "DG_NOTIFICATION_NOT_RECEIVED",
            "remarks": "DG melding niet opgestuurd"
          }
        ]
      },
      "ekhList": [
        {
          "departureBerthVisitId": null,
          "arrivalBerthVisitId": "9e07e8d3-9af3-4027-ad0c-e49ed505baec",
          "status": "REJECTED",
          "attentionPoints": [
            {
              "type": "VERTICAL_TIDE",
              "remarks": "Te diep!"
            }
          ],
          "remarks": "Bert en Ernie",
          "channelBound": false,
          "tideRestricted": false
        }
      ]
    },
    "visitStatus": "EXPECTED"
  },
  NLRTM21022940: {
    "crn": "NLRTM21022940",
    "copyOfVisit": "NLRTM21022426",
    "portOfCall": {
      "port": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM",
        "countryUnCode": "NL",
        "euPort": true
      },
      "portAuthority": {
        "fullName": "Havenbedrijf Rotterdam N.V.",
        "shortName": "HBRRTM",
        "portAuthorityId": "HbR",
        "emailAddress": "HCC@portofrotterdam.com",
        "address": "Wilhelminakade 909",
        "city": "Rotterdam",
        "countryUnCode": "NL",
        "countryName": "Netherlands",
        "zipCode": "3072 AP",
        "phoneNumber": "+31-10-2521195",
        "faxNumber": "+31-10-2521000",
        "contact": "Diana van Eijk",
        "customsEORINumber": "NL24354561",
        "ean": null,
        "chamberOfCommerceNumber": null,
        "scacCode": null,
        "ensEnabled": false,
        "cargoImportEnabled": false,
        "cargoImportEnabledByDefault": false
      },
      "customsOffice": {
        "name": "Rotterdam Haven/Kantoor Maasvlakte",
        "unCode": "NL000396"
      },
      "ataReported": true,
      "atdReported": true,
      "atdPortReported": true,
      "paDeclarationRequired": true,
      "swDeclarationRequired": true,
      "wasteEnabled": true,
      "dangerousGoodsEnabled": true,
      "harbourDuesEnabled": false,
      "orderNauticalServicesEnabled": true,
      "enableNotificationsToPa": false,
      "authoriseOrganisationsDisabled": false,
      "tugBoatsRequired": true,
      "portAuthorityClearance": true
    },
    "vessel": {
      "imoCode": "8711837",
      "name": "ENERGIZER",
      "radioCallSign": "PHAS",
      "motUnCode": "1511",
      "motName": "Full container ship/cellular vessel",
      "summerDeadWeight": 9500,
      "maxWidth": 21.5,
      "flagCode": "NLD",
      "flagCountryUnCode": "NL",
      "netTonnage": 3838,
      "grossTonnage": 7642,
      "registrationPlaceUnloCode": "NLHAR",
      "registrationPlaceName": "Harlingen",
      "registrationDate": "2004-07-29",
      "mmsiNumber": "245088000",
      "fullLength": 134.65,
      "emailAddress": null
    },
    "owner": {
      "fullName": "Kuhlman Repko Shipping B.V.",
      "shortName": "KUHLMAN",
      "portAuthorityId": "KRSHIPPING",
      "emailAddress": "erno@krshipping.nl",
      "address": "Nieuwe Vissershaven 7",
      "city": "Harlingen",
      "countryUnCode": "NL",
      "countryName": "Netherlands",
      "zipCode": "8861 NX",
      "phoneNumber": "+31-517-412831",
      "faxNumber": null,
      "contact": "De heer Erno Leguijt",
      "customsEORINumber": "NL813141692",
      "ean": null,
      "chamberOfCommerceNumber": "01103873",
      "scacCode": null,
      "ensEnabled": false,
      "cargoImportEnabled": true,
      "cargoImportEnabledByDefault": true
    },
    "nextOwner": null,
    "declarant": {
      "fullName": "Kuhlman Repko Shipping B.V.",
      "shortName": "KUHLMAN",
      "portAuthorityId": "KRSHIPPING",
      "emailAddress": "erno@krshipping.nl",
      "address": "Nieuwe Vissershaven 7",
      "city": "Harlingen",
      "countryUnCode": "NL",
      "countryName": "Netherlands",
      "zipCode": "8861 NX",
      "phoneNumber": "+31-517-412831",
      "faxNumber": null,
      "contact": "De heer Erno Leguijt",
      "customsEORINumber": "NL813141692",
      "ean": null,
      "chamberOfCommerceNumber": "01103873",
      "scacCode": null,
      "ensEnabled": false,
      "cargoImportEnabled": true,
      "cargoImportEnabledByDefault": true
    },
    "nextDeclarant": null,
    "financialDeclarant": {
      "fullName": "Unifeeder Netherlands B.V.",
      "shortName": "UNIFEEDER",
      "portAuthorityId": "UNIFEEDER",
      "emailAddress": "ops-bnl@unifeeder.com",
      "address": "Blaak 555",
      "city": "Rotterdam",
      "countryUnCode": "NL",
      "countryName": "Netherlands",
      "zipCode": "3011 GB",
      "phoneNumber": "0102179141",
      "faxNumber": null,
      "contact": "Adil Maniyar",
      "customsEORINumber": "DK11810543",
      "ean": "8713755262211",
      "chamberOfCommerceNumber": "24450473",
      "scacCode": null,
      "ensEnabled": true,
      "cargoImportEnabled": true,
      "cargoImportEnabledByDefault": true
    },
    "nominatedFinancialDeclarant": null,
    "orderNauticalServices": true,
    "orderIncomingMovement": true,
    "creditNumberHolderSameAsDeclarant": false,
    "declarantResponsibleForCustoms": false,
    "cargoDeclarants": [
      {
        "fullName": "CMA-CGM (Holland) B.V.",
        "shortName": "CMACGMRHN",
        "portAuthorityId": "CMA-CGM",
        "emailAddress": "rtm.shipops@cma-cgm.com",
        "address": "Achterdijk 55",
        "city": "Rhoon",
        "countryUnCode": "NL",
        "countryName": "Netherlands",
        "zipCode": "3161 EB",
        "phoneNumber": "010-2998199",
        "faxNumber": "010-2998190",
        "contact": "Marcel den Boogert",
        "customsEORINumber": "NL806025463",
        "ean": null,
        "chamberOfCommerceNumber": "24276503",
        "scacCode": null,
        "ensEnabled": false,
        "cargoImportEnabled": true,
        "cargoImportEnabledByDefault": true
      },
      {
        "fullName": "Samskip Benelux B.V.",
        "shortName": "SAMSKIP",
        "portAuthorityId": "SAMSKIP",
        "emailAddress": "charles.van.koert@samskip.com",
        "address": "Waalhaven O.Z. 81",
        "city": "Rotterdam",
        "countryUnCode": "NL",
        "countryName": "Netherlands",
        "zipCode": "3087 BM",
        "phoneNumber": "0884001131",
        "faxNumber": "0653906391",
        "contact": "Charles van Koert",
        "customsEORINumber": "NL818622106",
        "ean": null,
        "chamberOfCommerceNumber": "24243207",
        "scacCode": "SBDX",
        "ensEnabled": true,
        "cargoImportEnabled": true,
        "cargoImportEnabledByDefault": true
      },
      {
        "fullName": "Orient Overseas Container Line Limi",
        "shortName": "OOCLRTM",
        "portAuthorityId": "OOCL",
        "emailAddress": "danny.van.den.bosch@oocl.com",
        "address": "Weena Zuid 134",
        "city": "Rotterdam",
        "countryUnCode": "NL",
        "countryName": "Netherlands",
        "zipCode": "3012 NC",
        "phoneNumber": "0620405856",
        "faxNumber": null,
        "contact": "Danny van de Bosch",
        "customsEORINumber": "NL821606931",
        "ean": null,
        "chamberOfCommerceNumber": "50485601",
        "scacCode": null,
        "ensEnabled": false,
        "cargoImportEnabled": true,
        "cargoImportEnabledByDefault": true
      },
      {
        "fullName": "Samskip Multimodal BV",
        "shortName": "GEESTRTM",
        "portAuthorityId": "SAMSKIP MCL",
        "emailAddress": "rsu@samskip.com",
        "address": "Waalhaven OZ 81",
        "city": "Rotterdam",
        "countryUnCode": "NL",
        "countryName": "Netherlands",
        "zipCode": "3087 BM",
        "phoneNumber": "+31-88-4001754",
        "faxNumber": "+31-10-4952629",
        "contact": "Andre van der Meer",
        "customsEORINumber": "NL001571734",
        "ean": "8713755262723",
        "chamberOfCommerceNumber": "24139799",
        "scacCode": "GEEL",
        "ensEnabled": true,
        "cargoImportEnabled": true,
        "cargoImportEnabledByDefault": true
      },
      {
        "fullName": "FESCO North West Europe B.V.",
        "shortName": "FESCO",
        "portAuthorityId": "Fesco lines bv",
        "emailAddress": "kprivalov@fesco.com",
        "address": "Hofhoek 20-48",
        "city": "Hofhoek 20-48Poortugaal",
        "countryUnCode": "NL",
        "countryName": "Netherlands",
        "zipCode": "3176 PD",
        "phoneNumber": "+31880066700",
        "faxNumber": null,
        "contact": "K.S. Privalov",
        "customsEORINumber": "NL818356054",
        "ean": null,
        "chamberOfCommerceNumber": "24419889",
        "scacCode": "FESO",
        "ensEnabled": false,
        "cargoImportEnabled": true,
        "cargoImportEnabledByDefault": true
      },
      {
        "fullName": "Unifeeder Netherlands B.V.",
        "shortName": "UNIFEEDER",
        "portAuthorityId": "UNIFEEDER",
        "emailAddress": "ops-bnl@unifeeder.com",
        "address": "Blaak 555",
        "city": "Rotterdam",
        "countryUnCode": "NL",
        "countryName": "Netherlands",
        "zipCode": "3011 GB",
        "phoneNumber": "0102179141",
        "faxNumber": null,
        "contact": "J.F. Meeder",
        "customsEORINumber": "DK11810543",
        "ean": "8713755262211",
        "chamberOfCommerceNumber": "24450473",
        "scacCode": null,
        "ensEnabled": true,
        "cargoImportEnabled": true,
        "cargoImportEnabledByDefault": true
      },
      {
        "fullName": "DFDS logistics B.V.",
        "shortName": "DFDSCL",
        "portAuthorityId": "DFDS CONT-LINE",
        "emailAddress": "mbec@dfds.com",
        "address": "Burgemeester van Lierplein 57",
        "city": "Vlaardingen",
        "countryUnCode": "NL",
        "countryName": "Netherlands",
        "zipCode": "3134 ZB",
        "phoneNumber": "+31105032300",
        "faxNumber": null,
        "contact": "M.L. Bech",
        "customsEORINumber": "NL003189223",
        "ean": "8713755281502",
        "chamberOfCommerceNumber": "24277441",
        "scacCode": "DFDN",
        "ensEnabled": true,
        "cargoImportEnabled": true,
        "cargoImportEnabledByDefault": true
      },
      {
        "fullName": "BG Freight Line B.V.",
        "shortName": "BGFREIGHT",
        "portAuthorityId": "BG FREIGHTL",
        "emailAddress": "rotterdam.captainsroom@bgfreightline.com",
        "address": "Den Hamweg 30",
        "city": "Rotterdam",
        "countryUnCode": "NL",
        "countryName": "Netherlands",
        "zipCode": "3008 JA",
        "phoneNumber": "+31-10-2311962",
        "faxNumber": "+31-10-4723808",
        "contact": "Kapaan F",
        "customsEORINumber": "NL006323017",
        "ean": null,
        "chamberOfCommerceNumber": "24145269",
        "scacCode": "BGFL",
        "ensEnabled": true,
        "cargoImportEnabled": true,
        "cargoImportEnabledByDefault": true
      },
      {
        "fullName": "Mann Lines B.V.",
        "shortName": "MANNLINES",
        "portAuthorityId": "MANN LINES",
        "emailAddress": "marcel.sparreboom@mannlines.nl",
        "address": "Veerhaven 7",
        "city": "Rotterdam",
        "countryUnCode": "NL",
        "countryName": "Netherlands",
        "zipCode": "3016 CJ",
        "phoneNumber": "+31-10-2999666",
        "faxNumber": "+31-10-2999665",
        "contact": "Marcel Sparreboom",
        "customsEORINumber": "NL818469298",
        "ean": null,
        "chamberOfCommerceNumber": "22065312",
        "scacCode": null,
        "ensEnabled": false,
        "cargoImportEnabled": true,
        "cargoImportEnabledByDefault": true
      },
      {
        "fullName": "Cross-Ocean B.V.",
        "shortName": "CROSSOCEANBV",
        "portAuthorityId": "CROSS OCEAN",
        "emailAddress": "gad@crossocean.nl",
        "address": "Weena 280",
        "city": "Rotterdam",
        "countryUnCode": "NL",
        "countryName": "Netherlands",
        "zipCode": "3012 NJ",
        "phoneNumber": "010-2248322",
        "faxNumber": null,
        "contact": "I.de Kramer",
        "customsEORINumber": "NL005140985",
        "ean": null,
        "chamberOfCommerceNumber": "24137366",
        "scacCode": null,
        "ensEnabled": true,
        "cargoImportEnabled": true,
        "cargoImportEnabledByDefault": true
      },
      {
        "fullName": "X-Press Container Line (UK) LTD",
        "shortName": "XPRESSLINE",
        "portAuthorityId": "XCL",
        "emailAddress": "graham.thrall@x-pressfeeders.com",
        "address": "Singapurstraße 1",
        "city": "Hamburg",
        "countryUnCode": "DE",
        "countryName": "Germany",
        "zipCode": "20457",
        "phoneNumber": "+49-15209267339",
        "faxNumber": "+49-40369192511",
        "contact": "Mr. Graham Thrall",
        "customsEORINumber": "DE277403241692493",
        "ean": null,
        "chamberOfCommerceNumber": "2470077",
        "scacCode": "XCLS",
        "ensEnabled": true,
        "cargoImportEnabled": true,
        "cargoImportEnabledByDefault": true
      },
      {
        "fullName": "Maersk Line Netherlands B.V.",
        "shortName": "MAERSKRTM",
        "portAuthorityId": "MAERSK LINE",
        "emailAddress": "marius.mersie@maersk.com",
        "address": "Boompjes 40",
        "city": "Rotterdam",
        "countryUnCode": "NL",
        "countryName": "Netherlands",
        "zipCode": "3000 DH",
        "phoneNumber": "06-53446270",
        "faxNumber": null,
        "contact": "Marius Mersie",
        "customsEORINumber": "NL005361771",
        "ean": "8713755940089",
        "chamberOfCommerceNumber": "24139123",
        "scacCode": null,
        "ensEnabled": false,
        "cargoImportEnabled": true,
        "cargoImportEnabledByDefault": true
      },
      {
        "fullName": "Containerships R dam B.V.",
        "shortName": "CONTAINERSHIPS",
        "portAuthorityId": "CONTAINER SH",
        "emailAddress": "Vesselplanners@containerships.nl",
        "address": "Den Hamweg 30",
        "city": "Rotterdam",
        "countryUnCode": "NL",
        "countryName": "Netherlands",
        "zipCode": "3089 KK",
        "phoneNumber": "+31-10-7980761",
        "faxNumber": "+31-10-4281971",
        "contact": "M. Aukes",
        "customsEORINumber": "NL006506938",
        "ean": null,
        "chamberOfCommerceNumber": "24148092.1",
        "scacCode": null,
        "ensEnabled": false,
        "cargoImportEnabled": true,
        "cargoImportEnabledByDefault": true
      },
      {
        "fullName": "MacAndrews Netherlands BV",
        "shortName": "OPDRRTM",
        "portAuthorityId": "OPDR NETHERLANDS",
        "emailAddress": "rotterdam@frachtcontor.nl",
        "address": "Achterdijk 51-53",
        "city": "Rhoon",
        "countryUnCode": "NL",
        "countryName": "Netherlands",
        "zipCode": "3161 EB",
        "phoneNumber": "0107980724",
        "faxNumber": null,
        "contact": "Marco Aukes",
        "customsEORINumber": "NL006509964",
        "ean": "8713755912949",
        "chamberOfCommerceNumber": "24148490",
        "scacCode": null,
        "ensEnabled": true,
        "cargoImportEnabled": true,
        "cargoImportEnabledByDefault": true
      },
      {
        "fullName": "Kuhlman Repko Shipping B.V.",
        "shortName": "KUHLMAN",
        "portAuthorityId": "KRSHIPPING",
        "emailAddress": "erno@krshipping.nl",
        "address": "Nieuwe Vissershaven 7",
        "city": "Harlingen",
        "countryUnCode": "NL",
        "countryName": "Netherlands",
        "zipCode": "8861 NX",
        "phoneNumber": "+31-517-412831",
        "faxNumber": null,
        "contact": "De heer Erno Leguijt",
        "customsEORINumber": "NL813141692",
        "ean": null,
        "chamberOfCommerceNumber": "01103873",
        "scacCode": null,
        "ensEnabled": false,
        "cargoImportEnabled": true,
        "cargoImportEnabledByDefault": true
      }
    ],
    "shipOperators": [
      {
        "fullName": "Unifeeder Netherlands B.V.",
        "shortName": "UNIFEEDER",
        "portAuthorityId": "UNIFEEDER",
        "emailAddress": "ops-bnl@unifeeder.com",
        "address": "Blaak 555",
        "city": "Rotterdam",
        "countryUnCode": "NL",
        "countryName": "Netherlands",
        "zipCode": "3011 GB",
        "phoneNumber": "0102179141",
        "faxNumber": null,
        "contact": "Adil Maniyar",
        "customsEORINumber": "DK11810543",
        "ean": "8713755262211",
        "chamberOfCommerceNumber": "24450473",
        "scacCode": null,
        "ensEnabled": true,
        "cargoImportEnabled": true,
        "cargoImportEnabledByDefault": true
      },
      {
        "fullName": "Unifeeder Netherlands B.V.",
        "shortName": "UNIFEEDER",
        "portAuthorityId": "UNIFEEDER",
        "emailAddress": "ops-bnl@unifeeder.com",
        "address": "Blaak 555",
        "city": "Rotterdam",
        "countryUnCode": "NL",
        "countryName": "Netherlands",
        "zipCode": "3011 GB",
        "phoneNumber": "0102179141",
        "faxNumber": null,
        "contact": "Adil Maniyar",
        "customsEORINumber": "DK11810543",
        "ean": "8713755262211",
        "chamberOfCommerceNumber": "24450473",
        "scacCode": null,
        "ensEnabled": true,
        "cargoImportEnabled": true,
        "cargoImportEnabledByDefault": true
      },
      {
        "fullName": "Havenbedrijf Rotterdam N.V.",
        "shortName": "HBRRTM",
        "portAuthorityId": "HbR",
        "emailAddress": "HCC@portofrotterdam.com",
        "address": "Wilhelminakade 909",
        "city": "Rotterdam",
        "countryUnCode": "NL",
        "countryName": "Netherlands",
        "zipCode": "3072 AP",
        "phoneNumber": "+31-10-2521195",
        "faxNumber": "+31-10-2521000",
        "contact": "Diana van Eijk",
        "customsEORINumber": "NL24354561",
        "ean": null,
        "chamberOfCommerceNumber": null,
        "scacCode": null,
        "ensEnabled": false,
        "cargoImportEnabled": false,
        "cargoImportEnabledByDefault": false
      },
      {
        "fullName": "Kuhlman Repko Shipping B.V.",
        "shortName": "KUHLMAN",
        "portAuthorityId": "KRSHIPPING",
        "emailAddress": "erno@krshipping.nl",
        "address": "Nieuwe Vissershaven 7",
        "city": "Harlingen",
        "countryUnCode": "NL",
        "countryName": "Netherlands",
        "zipCode": "8861 NX",
        "phoneNumber": "+31-517-412831",
        "faxNumber": null,
        "contact": "De heer Erno Leguijt",
        "customsEORINumber": "NL813141692",
        "ean": null,
        "chamberOfCommerceNumber": "01103873",
        "scacCode": null,
        "ensEnabled": false,
        "cargoImportEnabled": true,
        "cargoImportEnabledByDefault": true
      },
      {
        "fullName": "Kuhlman Repko Shipping B.V.",
        "shortName": "KUHLMAN",
        "portAuthorityId": "KRSHIPPING",
        "emailAddress": "erno@krshipping.nl",
        "address": "Nieuwe Vissershaven 7",
        "city": "Harlingen",
        "countryUnCode": "NL",
        "countryName": "Netherlands",
        "zipCode": "8861 NX",
        "phoneNumber": "+31-517-412831",
        "faxNumber": null,
        "contact": "De heer Erno Leguijt",
        "customsEORINumber": "NL813141692",
        "ean": null,
        "chamberOfCommerceNumber": "01103873",
        "scacCode": null,
        "ensEnabled": false,
        "cargoImportEnabled": true,
        "cargoImportEnabledByDefault": true
      }
    ],
    "additionalViewers": [],
    "visitDeclaration": {
      "clientReferenceNumber": null,
      "vesselEmailAddress": null,
      "etaFirstEntryEu": "2021-09-25T01:00:00Z",
      "arrivalVoyage": {
        "voyageNumber": "349",
        "carrier": {
          "customsId": "DK11810543",
          "name": "Unifeeder A/S",
          "scacCode": "UFEE",
          "smdgCode": "UFE",
          "cargoDeclarantShortName": null
        }
      },
      "previousPorts": [
        {
          "id": "cdf45d80-c94a-4e08-9edf-3da9983607eb",
          "arrival": "2021-09-25T02:00:00Z",
          "departure": "2021-09-25T13:00:00Z",
          "port": {
            "name": "Fredrikstad",
            "locationUnCode": "NOFRK",
            "countryUnCode": "NO",
            "euPort": false
          },
          "portFacilityVisits": [
            {
              "portFacility": {
                "code": "NOFRK-0006",
                "name": "Øraterminalen"
              },
              "arrivalDate": "2021-09-25",
              "departureDate": "2021-09-25",
              "securityLevel": "SL1",
              "additionalSecurityMeasures": null
            }
          ]
        },
        {
          "id": "ff344352-ed2e-4508-bc3e-a6c40e0a9485",
          "arrival": "2021-09-24T13:00:00Z",
          "departure": "2021-09-25T00:00:00Z",
          "port": {
            "name": "Brevik",
            "locationUnCode": "NOBVK",
            "countryUnCode": "NO",
            "euPort": false
          },
          "portFacilityVisits": [
            {
              "portFacility": {
                "code": "NOBVK-9999",
                "name": "UNKNOWN"
              },
              "arrivalDate": "2021-09-24",
              "departureDate": "2021-09-25",
              "securityLevel": "SL1",
              "additionalSecurityMeasures": null
            }
          ]
        },
        {
          "id": "672af5ce-d5f0-4050-9007-b6a9af6a09d3",
          "arrival": "2021-09-23T14:00:00Z",
          "departure": "2021-09-24T10:00:00Z",
          "port": {
            "name": "Oslo",
            "locationUnCode": "NOOSL",
            "countryUnCode": "NO",
            "euPort": false
          },
          "portFacilityVisits": [
            {
              "portFacility": {
                "code": "NOOSL-0027",
                "name": "Sjursøya Syd Containerterminal"
              },
              "arrivalDate": "2021-09-23",
              "departureDate": "2021-09-24",
              "securityLevel": "SL1",
              "additionalSecurityMeasures": null
            }
          ]
        },
        {
          "id": "8cb1a73f-3524-4ab5-a012-13f68fb56e08",
          "arrival": "2021-09-19T22:30:00Z",
          "departure": "2021-09-22T07:30:00Z",
          "port": {
            "name": "Rotterdam",
            "locationUnCode": "NLRTM",
            "countryUnCode": "NL",
            "euPort": true
          },
          "portFacilityVisits": [
            {
              "portFacility": {
                "code": "NLRTM-0046",
                "name": "Rotterdam: RST Verzamelplan Noord- en Zuidzijde (29-1)"
              },
              "arrivalDate": "2021-09-21",
              "departureDate": "2021-09-22",
              "securityLevel": "SL1",
              "additionalSecurityMeasures": null
            },
            {
              "portFacility": {
                "code": "NLRTM-0071",
                "name": "Rotterdam: ECT Delta Terminal B.V. (47-1)"
              },
              "arrivalDate": "2021-09-21",
              "departureDate": "2021-09-21",
              "securityLevel": "SL1",
              "additionalSecurityMeasures": null
            },
            {
              "portFacility": {
                "code": "NLRTM-0510",
                "name": "Rotterdam: Feederwachtpalen Europahaven (52-70)"
              },
              "arrivalDate": "2021-09-21",
              "departureDate": "2021-09-21",
              "securityLevel": "SL1",
              "additionalSecurityMeasures": null
            },
            {
              "portFacility": {
                "code": "NLRTM-0459",
                "name": "Rotterdam: ECT Euromax Terminal  (47-3)"
              },
              "arrivalDate": "2021-09-20",
              "departureDate": "2021-09-21",
              "securityLevel": "SL1",
              "additionalSecurityMeasures": null
            },
            {
              "portFacility": {
                "code": "NLRTM-0046",
                "name": "Rotterdam: RST Verzamelplan Noord- en Zuidzijde (29-1)"
              },
              "arrivalDate": "2021-09-20",
              "departureDate": "2021-09-20",
              "securityLevel": "SL1",
              "additionalSecurityMeasures": null
            }
          ]
        },
        {
          "id": "4e50dbc9-5259-4010-8cf5-496de912c4a9",
          "arrival": "2021-09-18T06:00:00Z",
          "departure": "2021-09-18T13:00:00Z",
          "port": {
            "name": "Fredrikstad",
            "locationUnCode": "NOFRK",
            "countryUnCode": "NO",
            "euPort": false
          },
          "portFacilityVisits": [
            {
              "portFacility": {
                "code": "NOFRK-0006",
                "name": "Øraterminalen"
              },
              "arrivalDate": "2021-09-18",
              "departureDate": "2021-09-18",
              "securityLevel": "SL1",
              "additionalSecurityMeasures": null
            }
          ]
        },
        {
          "id": "b7f6d7d9-04ba-4d0c-b8da-fb9f863a99d1",
          "arrival": "2021-09-17T17:00:00Z",
          "departure": "2021-09-18T04:00:00Z",
          "port": {
            "name": "Brevik",
            "locationUnCode": "NOBVK",
            "countryUnCode": "NO",
            "euPort": false
          },
          "portFacilityVisits": [
            {
              "portFacility": {
                "code": "NOBVK-9999",
                "name": "UNKNOWN"
              },
              "arrivalDate": "2021-09-17",
              "departureDate": "2021-09-18",
              "securityLevel": "SL1",
              "additionalSecurityMeasures": null
            }
          ]
        }
      ],
      "portVisit": {
        "pilotStation": {
          "code": "MC",
          "name": "Maascenter",
          "atSea": true
        },
        "entryPoint": {
          "code": "LL",
          "name": "Lage Licht",
          "atSea": true
        },
        "etaPort": "2021-09-26T22:30:00Z",
        "portEntry": {
          "origin": "SEA",
          "etaSeaBuoy": "2021-09-26T22:30:00Z",
          "etaPilotBoardingPlace": "2021-09-26T22:45:00Z",
          "requestedEtaPilotBoardingPlace": "2021-09-26T22:30:00Z",
          "earliestTimeOfPortEntry": null,
          "intention": "REQUEST_FOR_ENTRY",
          "baseForPlanning": "FIRST_BERTH",
          "entryDependency": null
        },
        "firstMovement": {
          "vesselDraft": 6.4,
          "vesselMasterName": "De Hoogh Frederik Lodewijk",
          "numberOfCrew": 11,
          "numberOfPassengers": 0,
          "cargo": "CONTAINERIZED",
          "orderEmail": null,
          "orderSms": null,
          "pilotExemption": {
            "holderName": "Hoogh F.L. de",
            "number": "RM10042/1"
          },
          "offStandardBeam": null,
          "pilotService": {
            "required": false,
            "serviceProvider": null,
            "remarks": null
          },
          "order": true,
          "stormPilotageInformation": {
            "heliSuitable": null,
            "hoisting": null,
            "remotePilotage": null,
            "imoLoaRegistered": null,
            "lowFreeboard": null,
            "remarks": null
          }
        },
        "passingThroughTugboats": null,
        "defectTypes": [],
        "defectTypeRemarks": null,
        "berthVisits": [
          {
            "id": "8e62048f-51d3-46a4-962e-bf024aec32c4",
            "berth": {
              "name": "BEATRIXH RST ZZ",
              "shortName": null,
              "code": "R2758",
              "terminalCode": "4963",
              "terminalName": "BEATRIXH RST ZZ",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal",
              "berthGroupCode": "RST ZZ",
              "buoy": false,
              "harbourDuesArea": true,
              "partyToNotify": null
            },
            "eta": "2021-09-27T01:15:00Z",
            "requestedEta": null,
            "ata": "2021-09-27T01:12:41Z",
            "etd": "2021-09-27T13:00:00Z",
            "requestedEtd": "2021-09-27T13:00:00Z",
            "atd": "2021-09-27T13:48:02Z",
            "mooring": "PORT_SIDE",
            "remarks": null,
            "visitPurposes": [
              "CREW_MOVEMENT",
              "DISCHARGE"
            ],
            "bollardFrom": 47,
            "bollardTo": 55,
            "tugboatAtArrival": {
              "required": false,
              "serviceProvider": {
                "name": "Fairplay",
                "portAuthorityId": "FAIRPLAY"
              },
              "requiredQuantity": null,
              "remarks": null
            },
            "boatmenAtArrival": {
              "required": true,
              "serviceProvider": {
                "name": "KRVE",
                "portAuthorityId": "RVE"
              },
              "remarks": null
            },
            "boatmenAtDeparture": {
              "required": true,
              "serviceProvider": {
                "name": "KRVE",
                "portAuthorityId": "RVE"
              },
              "remarks": null
            },
            "tugboatAtDeparture": {
              "required": false,
              "serviceProvider": {
                "name": "Fairplay",
                "portAuthorityId": "FAIRPLAY"
              },
              "requiredQuantity": null,
              "remarks": null
            },
            "nextMovement": {
              "vesselDraft": 6.5,
              "vesselMasterName": "De Hoogh Frederik Lodewijk",
              "numberOfCrew": 11,
              "numberOfPassengers": 0,
              "cargo": "CONTAINERIZED",
              "orderEmail": null,
              "orderSms": null,
              "pilotExemption": {
                "holderName": "Hoogh F.L. de",
                "number": "RM10042/1"
              },
              "offStandardBeam": null,
              "pilotService": {
                "required": false,
                "serviceProvider": null,
                "remarks": null
              },
              "order": true,
              "stormPilotageInformation": {
                "heliSuitable": null,
                "hoisting": null,
                "remotePilotage": null,
                "imoLoaRegistered": null,
                "lowFreeboard": null,
                "remarks": null
              }
            }
          },
          {
            "id": "f82b3399-543c-4466-a119-f1cc9361180f",
            "berth": {
              "name": "AMAZONEH ECT DDE",
              "shortName": null,
              "code": "R8160",
              "terminalCode": "4810",
              "terminalName": "AMAZONEH ECT DDE",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V.",
              "berthGroupCode": "ECT DELTA",
              "buoy": false,
              "harbourDuesArea": true,
              "partyToNotify": null
            },
            "eta": "2021-09-27T16:00:00Z",
            "requestedEta": null,
            "ata": "2021-09-27T17:51:56Z",
            "etd": "2021-09-27T20:30:00Z",
            "requestedEtd": "2021-09-27T20:30:00Z",
            "atd": "2021-09-27T20:38:55Z",
            "mooring": "STARBOARD_SIDE",
            "remarks": null,
            "visitPurposes": [
              "DISCHARGE"
            ],
            "bollardFrom": 20,
            "bollardTo": 27,
            "tugboatAtArrival": {
              "required": false,
              "serviceProvider": {
                "name": "Fairplay",
                "portAuthorityId": "FAIRPLAY"
              },
              "requiredQuantity": null,
              "remarks": null
            },
            "boatmenAtArrival": {
              "required": true,
              "serviceProvider": {
                "name": "KRVE",
                "portAuthorityId": "RVE"
              },
              "remarks": null
            },
            "boatmenAtDeparture": {
              "required": true,
              "serviceProvider": {
                "name": "KRVE",
                "portAuthorityId": "RVE"
              },
              "remarks": null
            },
            "tugboatAtDeparture": {
              "required": false,
              "serviceProvider": {
                "name": "Fairplay",
                "portAuthorityId": "FAIRPLAY"
              },
              "requiredQuantity": null,
              "remarks": null
            },
            "nextMovement": {
              "vesselDraft": 6,
              "vesselMasterName": "De Hoogh Frederik Lodewijk",
              "numberOfCrew": 11,
              "numberOfPassengers": 0,
              "cargo": "CONTAINERIZED",
              "orderEmail": null,
              "orderSms": null,
              "pilotExemption": {
                "holderName": "Hoogh F.L. de",
                "number": "RM10042/1"
              },
              "offStandardBeam": null,
              "pilotService": {
                "required": false,
                "serviceProvider": null,
                "remarks": null
              },
              "order": true,
              "stormPilotageInformation": {
                "heliSuitable": null,
                "hoisting": null,
                "remotePilotage": null,
                "imoLoaRegistered": null,
                "lowFreeboard": null,
                "remarks": null
              }
            }
          },
          {
            "id": "4747cac9-791d-4476-8659-0f01ccea8b79",
            "berth": {
              "name": "BEERK ECT DBF",
              "shortName": null,
              "code": "R8173",
              "terminalCode": "79029727",
              "terminalName": "BEERK ECT DBF",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V.",
              "berthGroupCode": "ECT DELTA",
              "buoy": false,
              "harbourDuesArea": true,
              "partyToNotify": null
            },
            "eta": "2021-09-27T21:00:00Z",
            "requestedEta": null,
            "ata": "2021-09-27T21:04:31Z",
            "etd": "2021-09-27T22:45:00Z",
            "requestedEtd": "2021-09-27T22:45:00Z",
            "atd": null,
            "mooring": "NO_PREFERENCE",
            "remarks": null,
            "visitPurposes": [
              "AWAITING_ORDERS"
            ],
            "bollardFrom": 84,
            "bollardTo": 98,
            "tugboatAtArrival": {
              "required": false,
              "serviceProvider": {
                "name": "Fairplay",
                "portAuthorityId": "FAIRPLAY"
              },
              "requiredQuantity": null,
              "remarks": null
            },
            "boatmenAtArrival": {
              "required": true,
              "serviceProvider": {
                "name": "KRVE",
                "portAuthorityId": "RVE"
              },
              "remarks": null
            },
            "boatmenAtDeparture": {
              "required": true,
              "serviceProvider": {
                "name": "KRVE",
                "portAuthorityId": "RVE"
              },
              "remarks": null
            },
            "tugboatAtDeparture": {
              "required": false,
              "serviceProvider": {
                "name": "Fairplay",
                "portAuthorityId": "FAIRPLAY"
              },
              "requiredQuantity": null,
              "remarks": null
            },
            "nextMovement": {
              "vesselDraft": 6,
              "vesselMasterName": "De Hoogh Frederik Lodewijk",
              "numberOfCrew": 11,
              "numberOfPassengers": 0,
              "cargo": "CONTAINERIZED",
              "orderEmail": null,
              "orderSms": null,
              "pilotExemption": {
                "holderName": "Hoogh F.L. de",
                "number": "RM10042/1"
              },
              "offStandardBeam": null,
              "pilotService": {
                "required": false,
                "serviceProvider": null,
                "remarks": null
              },
              "order": true,
              "stormPilotageInformation": {
                "heliSuitable": null,
                "hoisting": null,
                "remotePilotage": null,
                "imoLoaRegistered": null,
                "lowFreeboard": null,
                "remarks": null
              }
            }
          },
          {
            "id": "721e958f-e286-43af-b5e1-e216bdcba2dc",
            "berth": {
              "name": "YANGTZEK EUROMAX",
              "shortName": null,
              "code": "R9832",
              "terminalCode": "74353661",
              "terminalName": "YANGTZEK EUROMAX",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V.",
              "berthGroupCode": "YANGTZEK EUROMAX",
              "buoy": false,
              "harbourDuesArea": true,
              "partyToNotify": null
            },
            "eta": "2021-09-27T23:15:00Z",
            "requestedEta": null,
            "ata": "2021-09-27T23:25:40Z",
            "etd": "2021-09-28T05:00:00Z",
            "requestedEtd": "2021-09-28T05:00:00Z",
            "atd": "2021-09-28T05:19:55Z",
            "mooring": "STARBOARD_SIDE",
            "remarks": null,
            "visitPurposes": [
              "DISCHARGE"
            ],
            "bollardFrom": 137,
            "bollardTo": 146,
            "tugboatAtArrival": {
              "required": false,
              "serviceProvider": {
                "name": "Fairplay",
                "portAuthorityId": "FAIRPLAY"
              },
              "requiredQuantity": null,
              "remarks": null
            },
            "boatmenAtArrival": {
              "required": true,
              "serviceProvider": {
                "name": "KRVE",
                "portAuthorityId": "RVE"
              },
              "remarks": null
            },
            "boatmenAtDeparture": {
              "required": true,
              "serviceProvider": {
                "name": "KRVE",
                "portAuthorityId": "RVE"
              },
              "remarks": null
            },
            "tugboatAtDeparture": {
              "required": false,
              "serviceProvider": {
                "name": "Fairplay",
                "portAuthorityId": "FAIRPLAY"
              },
              "requiredQuantity": null,
              "remarks": null
            },
            "nextMovement": {
              "vesselDraft": 4.7,
              "vesselMasterName": "De Hoogh Frederik Lodewijk",
              "numberOfCrew": 11,
              "numberOfPassengers": 0,
              "cargo": "CONTAINERIZED",
              "orderEmail": null,
              "orderSms": null,
              "pilotExemption": {
                "holderName": "Hoogh F.L. de",
                "number": "RM10042/1"
              },
              "offStandardBeam": null,
              "pilotService": {
                "required": false,
                "serviceProvider": null,
                "remarks": null
              },
              "order": true,
              "stormPilotageInformation": {
                "heliSuitable": null,
                "hoisting": null,
                "remotePilotage": null,
                "imoLoaRegistered": null,
                "lowFreeboard": null,
                "remarks": null
              }
            }
          },
          {
            "id": "a04be342-0daf-47ea-bf68-fa8f72354f39",
            "berth": {
              "name": "BEATRIXH RST NZ",
              "shortName": null,
              "code": "R2742",
              "terminalCode": "1237",
              "terminalName": "BEATRIXH RST NZ",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal",
              "berthGroupCode": "BEATRIXH RST NZ",
              "buoy": false,
              "harbourDuesArea": true,
              "partyToNotify": null
            },
            "eta": "2021-09-28T08:00:00Z",
            "requestedEta": null,
            "ata": "2021-09-28T07:43:35Z",
            "etd": "2021-09-28T17:00:00Z",
            "requestedEtd": "2021-09-28T17:00:00Z",
            "atd": "2021-09-28T17:16:14Z",
            "mooring": "STARBOARD_SIDE",
            "remarks": null,
            "visitPurposes": [
              "AWAITING_ORDERS"
            ],
            "bollardFrom": 23,
            "bollardTo": 31,
            "tugboatAtArrival": {
              "required": false,
              "serviceProvider": {
                "name": "Fairplay",
                "portAuthorityId": "FAIRPLAY"
              },
              "requiredQuantity": null,
              "remarks": null
            },
            "boatmenAtArrival": {
              "required": true,
              "serviceProvider": {
                "name": "KRVE",
                "portAuthorityId": "RVE"
              },
              "remarks": null
            },
            "boatmenAtDeparture": {
              "required": true,
              "serviceProvider": {
                "name": "KRVE",
                "portAuthorityId": "RVE"
              },
              "remarks": null
            },
            "tugboatAtDeparture": {
              "required": false,
              "serviceProvider": {
                "name": "Fairplay",
                "portAuthorityId": "FAIRPLAY"
              },
              "requiredQuantity": null,
              "remarks": null
            },
            "nextMovement": {
              "vesselDraft": 4.7,
              "vesselMasterName": "F.L. de Hoogh",
              "numberOfCrew": 11,
              "numberOfPassengers": 0,
              "cargo": "BALLAST_CONDITION",
              "orderEmail": null,
              "orderSms": null,
              "pilotExemption": null,
              "offStandardBeam": null,
              "pilotService": {
                "required": true,
                "serviceProvider": {
                  "name": "Loodswezen",
                  "portAuthorityId": "LRR"
                },
                "remarks": null
              },
              "order": true,
              "stormPilotageInformation": null
            }
          },
          {
            "id": "c9744597-b9c3-4b90-ac99-caeb8c00c043",
            "berth": {
              "name": "DORDR MERW2 BOLIER",
              "shortName": null,
              "code": "D9512",
              "terminalCode": "19388098",
              "terminalName": "DORDR MERW2 BOLIER",
              "organisationShortName": null,
              "organisationName": null,
              "berthGroupCode": "DORDR MERW2 BOLIER",
              "buoy": false,
              "harbourDuesArea": true,
              "partyToNotify": null
            },
            "eta": "2021-09-29T21:00:00Z",
            "requestedEta": null,
            "ata": "2021-09-28T21:20:15Z",
            "etd": "2021-10-05T11:00:00Z",
            "requestedEtd": null,
            "atd": null,
            "mooring": "STARBOARD_SIDE",
            "remarks": null,
            "visitPurposes": [
              "REPAIRS"
            ],
            "bollardFrom": null,
            "bollardTo": null,
            "tugboatAtArrival": {
              "required": true,
              "serviceProvider": {
                "name": "Fairplay",
                "portAuthorityId": "FAIRPLAY"
              },
              "requiredQuantity": "TWO",
              "remarks": "passage brug + afmeren"
            },
            "boatmenAtArrival": {
              "required": true,
              "serviceProvider": {
                "name": "KRVE",
                "portAuthorityId": "RVE"
              },
              "remarks": null
            },
            "boatmenAtDeparture": {
              "required": true,
              "serviceProvider": {
                "name": "KRVE",
                "portAuthorityId": "RVE"
              },
              "remarks": null
            },
            "tugboatAtDeparture": {
              "required": true,
              "serviceProvider": {
                "name": "Fairplay",
                "portAuthorityId": "FAIRPLAY"
              },
              "requiredQuantity": "TWO",
              "remarks": null
            },
            "nextMovement": {
              "vesselDraft": 4.7,
              "vesselMasterName": "F.L. de Hoogh",
              "numberOfCrew": 11,
              "numberOfPassengers": 0,
              "cargo": "BALLAST_CONDITION",
              "orderEmail": "vli@krshipping.nl",
              "orderSms": "+31118226760",
              "pilotExemption": null,
              "offStandardBeam": null,
              "pilotService": {
                "required": true,
                "serviceProvider": {
                  "name": "Loodswezen",
                  "portAuthorityId": "LRR"
                },
                "remarks": null
              },
              "order": null,
              "stormPilotageInformation": {
                "heliSuitable": false,
                "hoisting": null,
                "remotePilotage": false,
                "imoLoaRegistered": null,
                "lowFreeboard": null,
                "remarks": null
              }
            }
          }
        ],
        "dangerousGoodsLoading": false,
        "dangerousGoodsDischarge": true,
        "dangerousGoodsTransit": false,
        "vesselInspectionRequired": false,
        "shipConfiguration": null,
        "exitPoint": {
          "code": "LL",
          "name": "Lage Licht",
          "atSea": true
        },
        "etdPort": "2021-10-06T23:00:00Z",
        "atdPort": null,
        "ataPort": "2021-09-27T01:12:41Z"
      },
      "departureVoyage": {
        "voyageNumber": "349",
        "carrier": {
          "customsId": "DK11810543",
          "name": "Unifeeder A/S",
          "scacCode": "UFEE",
          "smdgCode": "UFE",
          "cargoDeclarantShortName": null
        }
      },
      "nextPorts": [
        {
          "id": "f617360d-29af-414d-9274-f78be5f4e92f",
          "arrival": "2021-10-08T00:00:00Z",
          "departure": "2021-10-08T05:00:00Z",
          "port": {
            "name": "Rotterdam",
            "locationUnCode": "NLRTM",
            "countryUnCode": "NL",
            "euPort": true
          },
          "customsOffice": {
            "name": "Rotterdam Haven/Kantoor Maasvlakte",
            "unCode": "NL000396"
          }
        }
      ]
    },
    "nextVisitDeclaration": null,
    "securityDeclaration": {
      "securityReportRequired": "YES",
      "companySecurityOfficer": {
        "name": "S.W.Postma",
        "email": "qhse@jrshipping.com",
        "phoneNumber": "31613323734"
      },
      "currentSecurityLevel": "SL1",
      "approvedSspDocumentOnBoard": true,
      "isscSecurityDocument": {
        "availability": "YES",
        "expiryDate": "2024-12-13",
        "reasonNoValidIsscOnBoard": null,
        "issuer": {
          "code": "5",
          "name": "Bureau Veritas"
        }
      },
      "shipToShipActivities": [],
      "securityRelatedMatter": null
    },
    "wasteDeclaration": {
      "portOfLastDelivery": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM",
        "countryUnCode": "NL",
        "euPort": true
      },
      "lastDeliveryDate": "2021-09-20",
      "wasteItems": [
        {
          "type": "SLUDGE",
          "berthIdForPickup": "8e62048f-51d3-46a4-962e-bf024aec32c4",
          "collector": {
            "fullName": "SWOC",
            "shortName": "SWOC",
            "portAuthorityId": "SWOC",
            "emailAddress": "info@shipswaste.nl",
            "address": "Chemieweg 10",
            "city": "Botlek RT",
            "countryUnCode": "NL",
            "countryName": "Netherlands",
            "zipCode": "3197 KC",
            "phoneNumber": "+31-10-2957154",
            "faxNumber": "+31-10-4162996",
            "contact": "Edwin Michels",
            "customsEORINumber": null,
            "ean": null,
            "chamberOfCommerceNumber": null,
            "scacCode": null,
            "ensEnabled": false,
            "cargoImportEnabled": false,
            "cargoImportEnabledByDefault": false
          },
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 10,
          "quantityToBeRetained": 0.2,
          "maxDedicatedCapacity": 25.7,
          "quantityToBeGenerated": 0.5,
          "portForRetainedWaste": {
            "name": "Rotterdam",
            "locationUnCode": "NLRTM",
            "countryUnCode": "NL",
            "euPort": true
          }
        },
        {
          "type": "FUEL_REMNANTS",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "BILGE_WATER",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0.2,
          "maxDedicatedCapacity": 14.7,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": {
            "name": "Rotterdam",
            "locationUnCode": "NLRTM",
            "countryUnCode": "NL",
            "euPort": true
          }
        },
        {
          "type": "USED_ENGINE_OIL",
          "berthIdForPickup": "8e62048f-51d3-46a4-962e-bf024aec32c4",
          "collector": {
            "fullName": "SWOC",
            "shortName": "SWOC",
            "portAuthorityId": "SWOC",
            "emailAddress": "info@shipswaste.nl",
            "address": "Chemieweg 10",
            "city": "Botlek RT",
            "countryUnCode": "NL",
            "countryName": "Netherlands",
            "zipCode": "3197 KC",
            "phoneNumber": "+31-10-2957154",
            "faxNumber": "+31-10-4162996",
            "contact": "Edwin Michels",
            "customsEORINumber": null,
            "ean": null,
            "chamberOfCommerceNumber": null,
            "scacCode": null,
            "ensEnabled": false,
            "cargoImportEnabled": false,
            "cargoImportEnabledByDefault": false
          },
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 3.1,
          "quantityToBeRetained": 0.5,
          "maxDedicatedCapacity": 31.02,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": {
            "name": "Rotterdam",
            "locationUnCode": "NLRTM",
            "countryUnCode": "NL",
            "euPort": true
          }
        },
        {
          "type": "FOOD_WASTE",
          "berthIdForPickup": "8e62048f-51d3-46a4-962e-bf024aec32c4",
          "collector": {
            "fullName": "Bek & Verburg Rotterdam",
            "shortName": "BEKVERBURG",
            "portAuthorityId": "BEK&VERBURG",
            "emailAddress": "scheepsafvalstoffen@bek-verburg.nl",
            "address": "Montrealweg 100",
            "city": "Botlek",
            "countryUnCode": "NL",
            "countryName": "Netherlands",
            "zipCode": "3197 KH",
            "phoneNumber": "+31-10-4287744",
            "faxNumber": "+31-10-4287749",
            "contact": "Kenny Baas",
            "customsEORINumber": null,
            "ean": null,
            "chamberOfCommerceNumber": null,
            "scacCode": null,
            "ensEnabled": false,
            "cargoImportEnabled": false,
            "cargoImportEnabledByDefault": false
          },
          "specification": null,
          "quantityOfLastDelivery": 0.1,
          "quantityToBeDelivered": 0.2,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "PLASTIC_CLEAN",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "PLASTIC_CONTAMINATED",
          "berthIdForPickup": "8e62048f-51d3-46a4-962e-bf024aec32c4",
          "collector": {
            "fullName": "Bek & Verburg Rotterdam",
            "shortName": "BEKVERBURG",
            "portAuthorityId": "BEK&VERBURG",
            "emailAddress": "scheepsafvalstoffen@bek-verburg.nl",
            "address": "Montrealweg 100",
            "city": "Botlek",
            "countryUnCode": "NL",
            "countryName": "Netherlands",
            "zipCode": "3197 KH",
            "phoneNumber": "+31-10-4287744",
            "faxNumber": "+31-10-4287749",
            "contact": "Kenny Baas",
            "customsEORINumber": null,
            "ean": null,
            "chamberOfCommerceNumber": null,
            "scacCode": null,
            "ensEnabled": false,
            "cargoImportEnabled": false,
            "cargoImportEnabledByDefault": false
          },
          "specification": null,
          "quantityOfLastDelivery": 0.3,
          "quantityToBeDelivered": 0.2,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "SMALL_DANGEROUS_WASTE",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "OTHER_DOMESTIC_WASTE",
          "berthIdForPickup": "8e62048f-51d3-46a4-962e-bf024aec32c4",
          "collector": {
            "fullName": "Bek & Verburg Rotterdam",
            "shortName": "BEKVERBURG",
            "portAuthorityId": "BEK&VERBURG",
            "emailAddress": "scheepsafvalstoffen@bek-verburg.nl",
            "address": "Montrealweg 100",
            "city": "Botlek",
            "countryUnCode": "NL",
            "countryName": "Netherlands",
            "zipCode": "3197 KH",
            "phoneNumber": "+31-10-4287744",
            "faxNumber": "+31-10-4287749",
            "contact": "Kenny Baas",
            "customsEORINumber": null,
            "ean": null,
            "chamberOfCommerceNumber": null,
            "scacCode": null,
            "ensEnabled": false,
            "cargoImportEnabled": false,
            "cargoImportEnabledByDefault": false
          },
          "specification": "Other domestic",
          "quantityOfLastDelivery": 0.4,
          "quantityToBeDelivered": 0.25,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "INTERNATIONAL_CATERING_WASTE",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "PAPER_OR_CARTON",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0.2,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "RAGS",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "GLASS",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "METAL_OR_STEELWIRES",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "BOTTLES",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "CROCKERY",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "INCINERATOR_ASHES",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "ANIMAL_CARCASSES",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "EWASTE_WHOLE_DEVICES",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "EWASTE_DEVICES",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "PYROTECHNICS",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "BATTERIES",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "FIRE_EXTINGUISHERS",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "CAR_TYRES_OR_RUBBER",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "COOLING_LIQUIDS",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "PROPELLER_SHAFT_GREASE",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "FUMIGATION_PRODUCTS",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "FILTER_OR_RAGS_BAGS",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0.035,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "WOOD",
          "berthIdForPickup": "8e62048f-51d3-46a4-962e-bf024aec32c4",
          "collector": {
            "fullName": "Bek & Verburg Rotterdam",
            "shortName": "BEKVERBURG",
            "portAuthorityId": "BEK&VERBURG",
            "emailAddress": "scheepsafvalstoffen@bek-verburg.nl",
            "address": "Montrealweg 100",
            "city": "Botlek",
            "countryUnCode": "NL",
            "countryName": "Netherlands",
            "zipCode": "3197 KH",
            "phoneNumber": "+31-10-4287744",
            "faxNumber": "+31-10-4287749",
            "contact": "Kenny Baas",
            "customsEORINumber": null,
            "ean": null,
            "chamberOfCommerceNumber": null,
            "scacCode": null,
            "ensEnabled": false,
            "cargoImportEnabled": false,
            "cargoImportEnabledByDefault": false
          },
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 2,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "OIL_CONTAMINATED_DRUMS",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 2,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "FISHING_GEAR",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "COOKING_OIL",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "OPERATIONAL_WASTE",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0.02,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "SEWAGE_WASTE_BLACK",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0.5,
          "maxDedicatedCapacity": 8.5,
          "quantityToBeGenerated": 0.5,
          "portForRetainedWaste": {
            "name": "Rotterdam",
            "locationUnCode": "NLRTM",
            "countryUnCode": "NL",
            "euPort": true
          }
        },
        {
          "type": "SEWAGE_WASTE_GREY",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0.5,
          "maxDedicatedCapacity": 2.5,
          "quantityToBeGenerated": 0.3,
          "portForRetainedWaste": {
            "name": "Rotterdam",
            "locationUnCode": "NLRTM",
            "countryUnCode": "NL",
            "euPort": true
          }
        },
        {
          "type": "DIRTY_BALLASTWATER",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "WASHWATER_OIL",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "OILY_WASTE_FROM_CLEANING",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "OTHER_OILY_WASTE",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "CARGO_RESIDUES_OR_WASHWATER",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "DIRTY_BALLASTWATER_CHEMICALS",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "OTHER_CARGO_RESIDUES",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "WASHWATER_DRY_CARGO_HME",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "WASHWATER_DRY_CARGO_NON_HME",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "DRY_CARGO_RESIDUES_HME",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "DRY_CARGO_RESIDUES_NON_HME",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "OTHER_CARGO_RESIDUES_NON_TANKERS",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        }
      ]
    },
    "wasteCollections": [],
    "shipStoresDeclaration": {
      "items": {
        "SPIRITS": 4,
        "BEER": 80,
        "WINE": 0,
        "OTHER": 0,
        "CIGARETTES": 3400,
        "CIGARS": 0,
        "TOBACCO": 0,
        "FUEL_OIL": 145000,
        "LUBRICATING_OIL": 10800,
        "MEAT": 35,
        "NARCOTICS": 0.027974,
        "FIRE_ARMS": 0
      }
    },
    "dangerousGoodsDeclarations": [
      {
        "dangerousGoods": {
          "goods": [
            {
              "type": "containerGood",
              "id": "36fd1392-710c-4684-832a-671d456617d0",
              "goodData": {
                "type": "containerGood",
                "name": "ALUMINIUM SMELTING BY-PRODUCTS",
                "unCode": "3170",
                "hazardClass": "4.3",
                "subsidiaryRisk": null,
                "emsFireCode": "F-G",
                "emsSpillageCode": "S-P",
                "properties": "Grey powder or lumps with some metallic inclusions. Contact with water may cause heating with possible evolution of flammable and toxic gases such as hydrogen and ammonia. This entry includes e.g. aluminium dross, aluminium skimmings, spent cathodes, spent potliner and aluminium salt slags.",
                "segregationGroup": "SGG15",
                "packingGroup": "MINOR_DANGER",
                "goodCode": "102343",
                "gdsCode": null,
                "stowageType": "container"
              },
              "flashPoint": null,
              "segregationInformation": null,
              "remarks": null,
              "allowingIncompleteRadioactivity": false,
              "radioactive": false,
              "radioactivity": null
            },
            {
              "type": "containerGood",
              "id": "98f433a4-21c3-45c3-b299-136907911190",
              "goodData": {
                "type": "containerGood",
                "name": "ALUMINIUM SMELTING BY-PRODUCTS",
                "unCode": "3170",
                "hazardClass": "4.3",
                "subsidiaryRisk": null,
                "emsFireCode": "F-G",
                "emsSpillageCode": "S-P",
                "properties": "Grey powder or lumps with some metallic inclusions. Contact with water may cause heating with possible evolution of flammable and toxic gases such as hydrogen and ammonia. This entry includes e.g. aluminium dross, aluminium skimmings, spent cathodes, spent potliner and aluminium salt slags.",
                "segregationGroup": "SGG15",
                "packingGroup": "MINOR_DANGER",
                "goodCode": "102343",
                "gdsCode": null,
                "stowageType": "container"
              },
              "flashPoint": null,
              "segregationInformation": null,
              "remarks": null,
              "allowingIncompleteRadioactivity": false,
              "radioactive": false,
              "radioactivity": null
            },
            {
              "type": "containerGood",
              "id": "d5da08e3-b16e-4b9d-ad4d-25dac9dcbb5b",
              "goodData": {
                "type": "containerGood",
                "name": "ALUMINIUM SMELTING BY-PRODUCTS",
                "unCode": "3170",
                "hazardClass": "4.3",
                "subsidiaryRisk": null,
                "emsFireCode": "F-G",
                "emsSpillageCode": "S-P",
                "properties": "Grey powder or lumps with some metallic inclusions. Contact with water may cause heating with possible evolution of flammable and toxic gases such as hydrogen and ammonia. This entry includes e.g. aluminium dross, aluminium skimmings, spent cathodes, spent potliner and aluminium salt slags.",
                "segregationGroup": "SGG15",
                "packingGroup": "MINOR_DANGER",
                "goodCode": "102343",
                "gdsCode": null,
                "stowageType": "container"
              },
              "flashPoint": null,
              "segregationInformation": null,
              "remarks": null,
              "allowingIncompleteRadioactivity": false,
              "radioactive": false,
              "radioactivity": null
            },
            {
              "type": "containerGood",
              "id": "a8dc50c8-426e-4c66-96aa-7229b6a444bc",
              "goodData": {
                "type": "containerGood",
                "name": "ALUMINIUM SMELTING BY-PRODUCTS",
                "unCode": "3170",
                "hazardClass": "4.3",
                "subsidiaryRisk": null,
                "emsFireCode": "F-G",
                "emsSpillageCode": "S-P",
                "properties": "Grey powder or lumps with some metallic inclusions. Contact with water may cause heating with possible evolution of flammable and toxic gases such as hydrogen and ammonia. This entry includes e.g. aluminium dross, aluminium skimmings, spent cathodes, spent potliner and aluminium salt slags.",
                "segregationGroup": "SGG15",
                "packingGroup": "MINOR_DANGER",
                "goodCode": "102343",
                "gdsCode": null,
                "stowageType": "container"
              },
              "flashPoint": null,
              "segregationInformation": null,
              "remarks": null,
              "allowingIncompleteRadioactivity": false,
              "radioactive": false,
              "radioactivity": null
            },
            {
              "type": "containerGood",
              "id": "90ff34c3-3aef-4bd3-a205-611246a45979",
              "goodData": {
                "type": "containerGood",
                "name": "ALUMINIUM SMELTING BY-PRODUCTS",
                "unCode": "3170",
                "hazardClass": "4.3",
                "subsidiaryRisk": null,
                "emsFireCode": "F-G",
                "emsSpillageCode": "S-P",
                "properties": "Grey powder or lumps with some metallic inclusions. Contact with water may cause heating with possible evolution of flammable and toxic gases such as hydrogen and ammonia. This entry includes e.g. aluminium dross, aluminium skimmings, spent cathodes, spent potliner and aluminium salt slags.",
                "segregationGroup": "SGG15",
                "packingGroup": "MINOR_DANGER",
                "goodCode": "102343",
                "gdsCode": null,
                "stowageType": "container"
              },
              "flashPoint": null,
              "segregationInformation": null,
              "remarks": null,
              "allowingIncompleteRadioactivity": false,
              "radioactive": false,
              "radioactivity": null
            },
            {
              "type": "containerGood",
              "id": "b70aed63-fdd8-4b50-99fa-ac45fbed071c",
              "goodData": {
                "type": "containerGood",
                "name": "ALUMINIUM SMELTING BY-PRODUCTS",
                "unCode": "3170",
                "hazardClass": "4.3",
                "subsidiaryRisk": null,
                "emsFireCode": "F-G",
                "emsSpillageCode": "S-P",
                "properties": "Grey powder or lumps with some metallic inclusions. Contact with water may cause heating with possible evolution of flammable and toxic gases such as hydrogen and ammonia. This entry includes e.g. aluminium dross, aluminium skimmings, spent cathodes, spent potliner and aluminium salt slags.",
                "segregationGroup": "SGG15",
                "packingGroup": "MINOR_DANGER",
                "goodCode": "102343",
                "gdsCode": null,
                "stowageType": "container"
              },
              "flashPoint": null,
              "segregationInformation": null,
              "remarks": null,
              "allowingIncompleteRadioactivity": false,
              "radioactive": false,
              "radioactivity": null
            },
            {
              "type": "containerGood",
              "id": "595c62b3-1984-41d6-b053-fb124d912475",
              "goodData": {
                "type": "containerGood",
                "name": "ALUMINIUM SMELTING BY-PRODUCTS",
                "unCode": "3170",
                "hazardClass": "4.3",
                "subsidiaryRisk": null,
                "emsFireCode": "F-G",
                "emsSpillageCode": "S-P",
                "properties": "Grey powder or lumps with some metallic inclusions. Contact with water may cause heating with possible evolution of flammable and toxic gases such as hydrogen and ammonia. This entry includes e.g. aluminium dross, aluminium skimmings, spent cathodes, spent potliner and aluminium salt slags.",
                "segregationGroup": "SGG15",
                "packingGroup": "MINOR_DANGER",
                "goodCode": "102343",
                "gdsCode": null,
                "stowageType": "container"
              },
              "flashPoint": null,
              "segregationInformation": null,
              "remarks": null,
              "allowingIncompleteRadioactivity": false,
              "radioactive": false,
              "radioactivity": null
            },
            {
              "type": "containerGood",
              "id": "2c3304ac-65ae-4330-ad01-6eeb5a8ef910",
              "goodData": {
                "type": "containerGood",
                "name": "ALUMINIUM SMELTING BY-PRODUCTS",
                "unCode": "3170",
                "hazardClass": "4.3",
                "subsidiaryRisk": null,
                "emsFireCode": "F-G",
                "emsSpillageCode": "S-P",
                "properties": "Grey powder or lumps with some metallic inclusions. Contact with water may cause heating with possible evolution of flammable and toxic gases such as hydrogen and ammonia. This entry includes e.g. aluminium dross, aluminium skimmings, spent cathodes, spent potliner and aluminium salt slags.",
                "segregationGroup": "SGG15",
                "packingGroup": "MINOR_DANGER",
                "goodCode": "102343",
                "gdsCode": null,
                "stowageType": "container"
              },
              "flashPoint": null,
              "segregationInformation": null,
              "remarks": null,
              "allowingIncompleteRadioactivity": false,
              "radioactive": false,
              "radioactivity": null
            },
            {
              "type": "containerGood",
              "id": "3c4bee7d-f123-4ac8-878d-3a56c24574af",
              "goodData": {
                "type": "containerGood",
                "name": "ALUMINIUM SMELTING BY-PRODUCTS",
                "unCode": "3170",
                "hazardClass": "4.3",
                "subsidiaryRisk": null,
                "emsFireCode": "F-G",
                "emsSpillageCode": "S-P",
                "properties": "Grey powder or lumps with some metallic inclusions. Contact with water may cause heating with possible evolution of flammable and toxic gases such as hydrogen and ammonia. This entry includes e.g. aluminium dross, aluminium skimmings, spent cathodes, spent potliner and aluminium salt slags.",
                "segregationGroup": "SGG15",
                "packingGroup": "MINOR_DANGER",
                "goodCode": "102343",
                "gdsCode": null,
                "stowageType": "container"
              },
              "flashPoint": null,
              "segregationInformation": null,
              "remarks": null,
              "allowingIncompleteRadioactivity": false,
              "radioactive": false,
              "radioactivity": null
            },
            {
              "type": "containerGood",
              "id": "1810bd92-87d8-42f1-aad0-863dbb1f739a",
              "goodData": {
                "type": "containerGood",
                "name": "ALUMINIUM SMELTING BY-PRODUCTS",
                "unCode": "3170",
                "hazardClass": "4.3",
                "subsidiaryRisk": null,
                "emsFireCode": "F-G",
                "emsSpillageCode": "S-P",
                "properties": "Grey powder or lumps with some metallic inclusions. Contact with water may cause heating with possible evolution of flammable and toxic gases such as hydrogen and ammonia. This entry includes e.g. aluminium dross, aluminium skimmings, spent cathodes, spent potliner and aluminium salt slags.",
                "segregationGroup": "SGG15",
                "packingGroup": "MINOR_DANGER",
                "goodCode": "102343",
                "gdsCode": null,
                "stowageType": "container"
              },
              "flashPoint": null,
              "segregationInformation": null,
              "remarks": null,
              "allowingIncompleteRadioactivity": false,
              "radioactive": false,
              "radioactivity": null
            },
            {
              "type": "containerGood",
              "id": "ea360c12-c2ab-4fcb-bb80-a541fcfcd664",
              "goodData": {
                "type": "containerGood",
                "name": "MOTOR SPIRIT",
                "unCode": "1203",
                "hazardClass": "3",
                "subsidiaryRisk": null,
                "emsFireCode": "F-E",
                "emsSpillageCode": "S-E",
                "properties": "Immiscible with water.",
                "segregationGroup": null,
                "packingGroup": "MEDIUM_DANGER",
                "goodCode": "100549",
                "gdsCode": null,
                "stowageType": "container"
              },
              "flashPoint": -48,
              "segregationInformation": null,
              "remarks": null,
              "allowingIncompleteRadioactivity": false,
              "radioactive": false,
              "radioactivity": null
            },
            {
              "type": "containerGood",
              "id": "16b2404d-4ae8-42d7-a594-f0a014013f06",
              "goodData": {
                "type": "containerGood",
                "name": "METHACRYLIC ACID, STABILIZED",
                "unCode": "2531",
                "hazardClass": "8",
                "subsidiaryRisk": null,
                "emsFireCode": "F-A",
                "emsSpillageCode": "S-B",
                "properties": "Colourless, combustible liquid with a specific odour. Miscible with water. Polymerizes readily above its melting point (15°C), thereby generating heat and possible risk of explosion; should therefore be properly stabilized. Cooling below melting point (15°C) followed by subsequent reheating can release uninhibited monomer that readily polymerizes. Decomposes when heated, evolving toxic gases. Causes burns to skin, eyes and mucous membranes.",
                "segregationGroup": "SGG1",
                "packingGroup": "MEDIUM_DANGER",
                "goodCode": "101686",
                "gdsCode": null,
                "stowageType": "container"
              },
              "flashPoint": 48,
              "segregationInformation": null,
              "remarks": null,
              "allowingIncompleteRadioactivity": false,
              "radioactive": false,
              "radioactivity": null
            },
            {
              "type": "containerGood",
              "id": "14d0e87b-d371-4b2f-966f-3fb524d3b08f",
              "goodData": {
                "type": "containerGood",
                "name": "SULPHURIC ACID",
                "unCode": "1830",
                "hazardClass": "8",
                "subsidiaryRisk": null,
                "emsFireCode": "F-A",
                "emsSpillageCode": "S-B",
                "properties": "Colourless, oily liquid, mixture over 1.41 up to 1.84 relative density. In the presence of moisture, highly corrosive to most metals. Causes burns to skin, eyes and mucous membranes.",
                "segregationGroup": "SGG1a",
                "packingGroup": "MEDIUM_DANGER",
                "goodCode": "101142",
                "gdsCode": null,
                "stowageType": "container"
              },
              "flashPoint": null,
              "segregationInformation": null,
              "remarks": null,
              "allowingIncompleteRadioactivity": false,
              "radioactive": false,
              "radioactivity": null
            },
            {
              "type": "containerGood",
              "id": "323a2ae2-5991-4bb4-ad4f-7d1be5132d36",
              "goodData": {
                "type": "containerGood",
                "name": "SULPHURIC ACID",
                "unCode": "1830",
                "hazardClass": "8",
                "subsidiaryRisk": null,
                "emsFireCode": "F-A",
                "emsSpillageCode": "S-B",
                "properties": "Colourless, oily liquid, mixture over 1.41 up to 1.84 relative density. In the presence of moisture, highly corrosive to most metals. Causes burns to skin, eyes and mucous membranes.",
                "segregationGroup": "SGG1a",
                "packingGroup": "MEDIUM_DANGER",
                "goodCode": "101142",
                "gdsCode": null,
                "stowageType": "container"
              },
              "flashPoint": null,
              "segregationInformation": null,
              "remarks": null,
              "allowingIncompleteRadioactivity": false,
              "radioactive": false,
              "radioactivity": null
            },
            {
              "type": "containerGood",
              "id": "fc0e8cd5-48cc-4272-9718-73e402dbde26",
              "goodData": {
                "type": "containerGood",
                "name": "BATTERIES, NICKEL-METAL HYDRIDE",
                "unCode": "3496",
                "hazardClass": "9",
                "subsidiaryRisk": null,
                "emsFireCode": "F-A",
                "emsSpillageCode": "S-I",
                "properties": "Nickel-metal hydride button cells or nickel-metal hydride cells or batteries packed with or contained in equipment are not subject to the provisions of this Code.",
                "segregationGroup": null,
                "packingGroup": null,
                "goodCode": "102802",
                "gdsCode": null,
                "stowageType": "container"
              },
              "flashPoint": null,
              "segregationInformation": null,
              "remarks": null,
              "allowingIncompleteRadioactivity": false,
              "radioactive": false,
              "radioactivity": null
            },
            {
              "type": "containerGood",
              "id": "59431399-4738-4603-aacb-91d5c7edc909",
              "goodData": {
                "type": "containerGood",
                "name": "SULPHURIC ACID",
                "unCode": "1830",
                "hazardClass": "8",
                "subsidiaryRisk": null,
                "emsFireCode": "F-A",
                "emsSpillageCode": "S-B",
                "properties": "Colourless, oily liquid, mixture over 1.41 up to 1.84 relative density. In the presence of moisture, highly corrosive to most metals. Causes burns to skin, eyes and mucous membranes.",
                "segregationGroup": "SGG1a",
                "packingGroup": "MEDIUM_DANGER",
                "goodCode": "101142",
                "gdsCode": null,
                "stowageType": "container"
              },
              "flashPoint": null,
              "segregationInformation": null,
              "remarks": null,
              "allowingIncompleteRadioactivity": false,
              "radioactive": false,
              "radioactivity": null
            },
            {
              "type": "containerGood",
              "id": "4eadf56d-6ec6-45b5-873a-0783164f1da9",
              "goodData": {
                "type": "containerGood",
                "name": "SULPHURIC ACID",
                "unCode": "1830",
                "hazardClass": "8",
                "subsidiaryRisk": null,
                "emsFireCode": "F-A",
                "emsSpillageCode": "S-B",
                "properties": "Colourless, oily liquid, mixture over 1.41 up to 1.84 relative density. In the presence of moisture, highly corrosive to most metals. Causes burns to skin, eyes and mucous membranes.",
                "segregationGroup": "SGG1a",
                "packingGroup": "MEDIUM_DANGER",
                "goodCode": "101142",
                "gdsCode": null,
                "stowageType": "container"
              },
              "flashPoint": null,
              "segregationInformation": null,
              "remarks": null,
              "allowingIncompleteRadioactivity": false,
              "radioactive": false,
              "radioactivity": null
            },
            {
              "type": "containerGood",
              "id": "1d036bdf-24ff-4235-88b0-0e654d14567b",
              "goodData": {
                "type": "containerGood",
                "name": "SULPHURIC ACID",
                "unCode": "1830",
                "hazardClass": "8",
                "subsidiaryRisk": null,
                "emsFireCode": "F-A",
                "emsSpillageCode": "S-B",
                "properties": "Colourless, oily liquid, mixture over 1.41 up to 1.84 relative density. In the presence of moisture, highly corrosive to most metals. Causes burns to skin, eyes and mucous membranes.",
                "segregationGroup": "SGG1a",
                "packingGroup": "MEDIUM_DANGER",
                "goodCode": "101142",
                "gdsCode": null,
                "stowageType": "container"
              },
              "flashPoint": null,
              "segregationInformation": null,
              "remarks": null,
              "allowingIncompleteRadioactivity": false,
              "radioactive": false,
              "radioactivity": null
            },
            {
              "type": "containerGood",
              "id": "6d2c4046-2cb0-48b6-9b6d-6546e706c81c",
              "goodData": {
                "type": "containerGood",
                "name": "ENVIRONMENTALLY HAZARDOUS SUBSTANCE, SOLID, N.O.S.",
                "unCode": "3077",
                "hazardClass": "9",
                "subsidiaryRisk": null,
                "emsFireCode": "F-A",
                "emsSpillageCode": "S-F",
                "properties": null,
                "segregationGroup": null,
                "packingGroup": "MINOR_DANGER",
                "goodCode": "102193",
                "gdsCode": null,
                "stowageType": "container"
              },
              "flashPoint": null,
              "segregationInformation": null,
              "remarks": null,
              "allowingIncompleteRadioactivity": false,
              "radioactive": false,
              "radioactivity": null
            },
            {
              "type": "containerGood",
              "id": "cceedf36-571f-46d8-93ed-c5cb51129300",
              "goodData": {
                "type": "containerGood",
                "name": "ISOPROPANOL",
                "unCode": "1219",
                "hazardClass": "3",
                "subsidiaryRisk": null,
                "emsFireCode": "F-E",
                "emsSpillageCode": "S-D",
                "properties": "Colourless, mobile liquid. Flashpoint: 12°C c.c. Explosive limits: 2% to 12%. Miscible with water.",
                "segregationGroup": null,
                "packingGroup": "MEDIUM_DANGER",
                "goodCode": "100562",
                "gdsCode": null,
                "stowageType": "container"
              },
              "flashPoint": 12,
              "segregationInformation": null,
              "remarks": null,
              "allowingIncompleteRadioactivity": false,
              "radioactive": false,
              "radioactivity": null
            },
            {
              "type": "containerGood",
              "id": "9c098687-e33b-424d-ab05-22fff8052488",
              "goodData": {
                "type": "containerGood",
                "name": "ISOPROPANOL",
                "unCode": "1219",
                "hazardClass": "3",
                "subsidiaryRisk": null,
                "emsFireCode": "F-E",
                "emsSpillageCode": "S-D",
                "properties": "Colourless, mobile liquid. Flashpoint: 12°C c.c. Explosive limits: 2% to 12%. Miscible with water.",
                "segregationGroup": null,
                "packingGroup": "MEDIUM_DANGER",
                "goodCode": "100562",
                "gdsCode": null,
                "stowageType": "container"
              },
              "flashPoint": 12,
              "segregationInformation": null,
              "remarks": null,
              "allowingIncompleteRadioactivity": false,
              "radioactive": false,
              "radioactivity": null
            },
            {
              "type": "containerGood",
              "id": "95f87313-cac4-40f7-8542-c507c05f3540",
              "goodData": {
                "type": "containerGood",
                "name": "ACETIC ANHYDRIDE",
                "unCode": "1715",
                "hazardClass": "8",
                "subsidiaryRisk": "3",
                "emsFireCode": "F-E",
                "emsSpillageCode": "S-C",
                "properties": "Colourless, flammable liquid with an irritating odour. Flashpoint: 54°C c.c. Immiscible with water. In the presence of moisture, corrosive to most metals. Vapour irritates mucous membranes.",
                "segregationGroup": "SGG1",
                "packingGroup": "MEDIUM_DANGER",
                "goodCode": "101016",
                "gdsCode": null,
                "stowageType": "container"
              },
              "flashPoint": 49,
              "segregationInformation": null,
              "remarks": null,
              "allowingIncompleteRadioactivity": false,
              "radioactive": false,
              "radioactivity": null
            },
            {
              "type": "containerGood",
              "id": "71e10dfe-908e-42d9-b8b7-2d2f83dddd63",
              "goodData": {
                "type": "containerGood",
                "name": "METHYL METHACRYLATE, MONOMER, STABILIZED",
                "unCode": "1247",
                "hazardClass": "3",
                "subsidiaryRisk": null,
                "emsFireCode": "F-E",
                "emsSpillageCode": "S-D",
                "properties": "Colourless, volatile liquid. Flashpoint: 8°C c.c. Explosive limits: 1.5% to 11.6%. Immiscible with water. Irritating to skin, eyes and mucous membranes.",
                "segregationGroup": null,
                "packingGroup": "MEDIUM_DANGER",
                "goodCode": "100585",
                "gdsCode": null,
                "stowageType": "container"
              },
              "flashPoint": 10,
              "segregationInformation": null,
              "remarks": null,
              "allowingIncompleteRadioactivity": false,
              "radioactive": false,
              "radioactivity": null
            },
            {
              "type": "containerGood",
              "id": "d25bbdb7-906d-4652-bfc0-b41f9c682669",
              "goodData": {
                "type": "containerGood",
                "name": "BUTANOLS",
                "unCode": "1120",
                "hazardClass": "3",
                "subsidiaryRisk": null,
                "emsFireCode": "F-E",
                "emsSpillageCode": "S-D",
                "properties": "Colourless liquids with a disagreeable odour. Explosive limits: normal-BUTANOL 1.4% to 11.2%. secondary-BUTANOL 1.7% to 9.8%. tertiary-BUTANOL 2.4% to 8%. tertiary-BUTANOL solidifies at about 25°C. normal-BUTANOL is immiscible with water. secondary-BUTANOL is immiscible with water. tertiary-BUTANOL is miscible with water. Irritating to skin, eyes and mucous membranes.",
                "segregationGroup": null,
                "packingGroup": "MINOR_DANGER",
                "goodCode": "100469",
                "gdsCode": null,
                "stowageType": "container"
              },
              "flashPoint": 29,
              "segregationInformation": null,
              "remarks": null,
              "allowingIncompleteRadioactivity": false,
              "radioactive": false,
              "radioactivity": null
            },
            {
              "type": "containerGood",
              "id": "2f49962d-937c-4555-aa93-bac09fdeb121",
              "goodData": {
                "type": "containerGood",
                "name": "BUTANOLS",
                "unCode": "1120",
                "hazardClass": "3",
                "subsidiaryRisk": null,
                "emsFireCode": "F-E",
                "emsSpillageCode": "S-D",
                "properties": "Colourless liquids with a disagreeable odour. Explosive limits: normal-BUTANOL 1.4% to 11.2%. secondary-BUTANOL 1.7% to 9.8%. tertiary-BUTANOL 2.4% to 8%. tertiary-BUTANOL solidifies at about 25°C. normal-BUTANOL is immiscible with water. secondary-BUTANOL is immiscible with water. tertiary-BUTANOL is miscible with water. Irritating to skin, eyes and mucous membranes.",
                "segregationGroup": null,
                "packingGroup": "MINOR_DANGER",
                "goodCode": "100469",
                "gdsCode": null,
                "stowageType": "container"
              },
              "flashPoint": 29,
              "segregationInformation": null,
              "remarks": null,
              "allowingIncompleteRadioactivity": false,
              "radioactive": false,
              "radioactivity": null
            },
            {
              "type": "containerGood",
              "id": "6b1d9f91-ab19-4bf3-990f-3f31bbf6b241",
              "goodData": {
                "type": "containerGood",
                "name": "ISOBUTANOL",
                "unCode": "1212",
                "hazardClass": "3",
                "subsidiaryRisk": null,
                "emsFireCode": "F-E",
                "emsSpillageCode": "S-D",
                "properties": "Colourless liquid with a sweet odour. Flashpoint: 28°C c.c. Explosive limits: 1.2% to 10.9%. Partially miscible with water.",
                "segregationGroup": null,
                "packingGroup": "MINOR_DANGER",
                "goodCode": "100557",
                "gdsCode": null,
                "stowageType": "container"
              },
              "flashPoint": 27,
              "segregationInformation": null,
              "remarks": null,
              "allowingIncompleteRadioactivity": false,
              "radioactive": false,
              "radioactivity": null
            },
            {
              "type": "containerGood",
              "id": "224f4c2c-9151-4aa6-844b-0a4a8da8dd26",
              "goodData": {
                "type": "containerGood",
                "name": "ISOBUTANOL",
                "unCode": "1212",
                "hazardClass": "3",
                "subsidiaryRisk": null,
                "emsFireCode": "F-E",
                "emsSpillageCode": "S-D",
                "properties": "Colourless liquid with a sweet odour. Flashpoint: 28°C c.c. Explosive limits: 1.2% to 10.9%. Partially miscible with water.",
                "segregationGroup": null,
                "packingGroup": "MINOR_DANGER",
                "goodCode": "100557",
                "gdsCode": null,
                "stowageType": "container"
              },
              "flashPoint": 27,
              "segregationInformation": null,
              "remarks": null,
              "allowingIncompleteRadioactivity": false,
              "radioactive": false,
              "radioactivity": null
            },
            {
              "type": "containerGood",
              "id": "741fa0d4-fed0-4ee0-aa6c-c3491031c545",
              "goodData": {
                "type": "containerGood",
                "name": "ENVIRONMENTALLY HAZARDOUS SUBSTANCE, LIQUID, N.O.S.",
                "unCode": "3082",
                "hazardClass": "9",
                "subsidiaryRisk": null,
                "emsFireCode": "F-A",
                "emsSpillageCode": "S-F",
                "properties": null,
                "segregationGroup": null,
                "packingGroup": "MINOR_DANGER",
                "goodCode": "102197",
                "gdsCode": null,
                "stowageType": "container"
              },
              "flashPoint": 144,
              "segregationInformation": null,
              "remarks": null,
              "allowingIncompleteRadioactivity": false,
              "radioactive": false,
              "radioactivity": null
            },
            {
              "type": "containerGood",
              "id": "e35e6669-1245-43fe-bbf8-04dddd39e757",
              "goodData": {
                "type": "containerGood",
                "name": "TRIMETHYLHEXAMETHYLENEDIAMINES",
                "unCode": "2327",
                "hazardClass": "8",
                "subsidiaryRisk": null,
                "emsFireCode": "F-A",
                "emsSpillageCode": "S-B",
                "properties": "Colourless, slightly hygroscopic, combustible liquids. Miscible with water. Irritating to skin, eyes and mucous membranes.",
                "segregationGroup": null,
                "packingGroup": "MINOR_DANGER",
                "goodCode": "101499",
                "gdsCode": null,
                "stowageType": "container"
              },
              "flashPoint": null,
              "segregationInformation": null,
              "remarks": null,
              "allowingIncompleteRadioactivity": false,
              "radioactive": false,
              "radioactivity": null
            }
          ],
          "stowageAtArrival": [
            {
              "type": "container",
              "stowageNumber": "BNSU2140139",
              "goodId": "36fd1392-710c-4684-832a-671d456617d0",
              "portOfLoading": {
                "name": "Brevik",
                "locationUnCode": "NOBVK",
                "countryUnCode": "NO",
                "euPort": false
              },
              "portOfDischarge": {
                "name": "Rotterdam",
                "locationUnCode": "NLRTM",
                "countryUnCode": "NL",
                "euPort": true
              },
              "weight": 21900,
              "trailer": false,
              "uncleanTankContainer": false,
              "position": "0070686",
              "numberOfOuterPackages": 1,
              "outerPackageType": {
                "code": "PK",
                "name": "Package",
                "bulk": false
              },
              "numberOfInnerPackages": null,
              "innerPackageType": null,
              "transportInLimitedQuantity": false,
              "netWeight": 19620,
              "netExplosiveMass": null,
              "goodIdIsMissing": null
            },
            {
              "type": "container",
              "stowageNumber": "BNSU3057380",
              "goodId": "98f433a4-21c3-45c3-b299-136907911190",
              "portOfLoading": {
                "name": "Brevik",
                "locationUnCode": "NOBVK",
                "countryUnCode": "NO",
                "euPort": false
              },
              "portOfDischarge": {
                "name": "Rotterdam",
                "locationUnCode": "NLRTM",
                "countryUnCode": "NL",
                "euPort": true
              },
              "weight": 18865,
              "trailer": false,
              "uncleanTankContainer": false,
              "position": "0070388",
              "numberOfOuterPackages": 1,
              "outerPackageType": {
                "code": "PK",
                "name": "Package",
                "bulk": false
              },
              "numberOfInnerPackages": null,
              "innerPackageType": null,
              "transportInLimitedQuantity": false,
              "netWeight": 16680,
              "netExplosiveMass": null,
              "goodIdIsMissing": null
            },
            {
              "type": "container",
              "stowageNumber": "TDTU6768227",
              "goodId": "d5da08e3-b16e-4b9d-ad4d-25dac9dcbb5b",
              "portOfLoading": {
                "name": "Brevik",
                "locationUnCode": "NOBVK",
                "countryUnCode": "NO",
                "euPort": false
              },
              "portOfDischarge": {
                "name": "Rotterdam",
                "locationUnCode": "NLRTM",
                "countryUnCode": "NL",
                "euPort": true
              },
              "weight": 20640,
              "trailer": false,
              "uncleanTankContainer": false,
              "position": "0070586",
              "numberOfOuterPackages": 1,
              "outerPackageType": {
                "code": "PK",
                "name": "Package",
                "bulk": false
              },
              "numberOfInnerPackages": null,
              "innerPackageType": null,
              "transportInLimitedQuantity": false,
              "netWeight": 18320,
              "netExplosiveMass": null,
              "goodIdIsMissing": null
            },
            {
              "type": "container",
              "stowageNumber": "TTNU2741910",
              "goodId": "a8dc50c8-426e-4c66-96aa-7229b6a444bc",
              "portOfLoading": {
                "name": "Brevik",
                "locationUnCode": "NOBVK",
                "countryUnCode": "NO",
                "euPort": false
              },
              "portOfDischarge": {
                "name": "Rotterdam",
                "locationUnCode": "NLRTM",
                "countryUnCode": "NL",
                "euPort": true
              },
              "weight": 19010,
              "trailer": false,
              "uncleanTankContainer": false,
              "position": "0070286",
              "numberOfOuterPackages": 1,
              "outerPackageType": {
                "code": "PK",
                "name": "Package",
                "bulk": false
              },
              "numberOfInnerPackages": null,
              "innerPackageType": null,
              "transportInLimitedQuantity": false,
              "netWeight": 16780,
              "netExplosiveMass": null,
              "goodIdIsMissing": null
            },
            {
              "type": "container",
              "stowageNumber": "BNSU3865747",
              "goodId": "90ff34c3-3aef-4bd3-a205-611246a45979",
              "portOfLoading": {
                "name": "Brevik",
                "locationUnCode": "NOBVK",
                "countryUnCode": "NO",
                "euPort": false
              },
              "portOfDischarge": {
                "name": "Rotterdam",
                "locationUnCode": "NLRTM",
                "countryUnCode": "NL",
                "euPort": true
              },
              "weight": 18910,
              "trailer": false,
              "uncleanTankContainer": false,
              "position": "0070288",
              "numberOfOuterPackages": 1,
              "outerPackageType": {
                "code": "PK",
                "name": "Package",
                "bulk": false
              },
              "numberOfInnerPackages": null,
              "innerPackageType": null,
              "transportInLimitedQuantity": false,
              "netWeight": 16680,
              "netExplosiveMass": null,
              "goodIdIsMissing": null
            },
            {
              "type": "container",
              "stowageNumber": "TDTU7834200",
              "goodId": "b70aed63-fdd8-4b50-99fa-ac45fbed071c",
              "portOfLoading": {
                "name": "Brevik",
                "locationUnCode": "NOBVK",
                "countryUnCode": "NO",
                "euPort": false
              },
              "portOfDischarge": {
                "name": "Rotterdam",
                "locationUnCode": "NLRTM",
                "countryUnCode": "NL",
                "euPort": true
              },
              "weight": 18850,
              "trailer": false,
              "uncleanTankContainer": false,
              "position": "0070588",
              "numberOfOuterPackages": 1,
              "outerPackageType": {
                "code": "PK",
                "name": "Package",
                "bulk": false
              },
              "numberOfInnerPackages": null,
              "innerPackageType": null,
              "transportInLimitedQuantity": false,
              "netWeight": 16520,
              "netExplosiveMass": null,
              "goodIdIsMissing": null
            },
            {
              "type": "container",
              "stowageNumber": "BNSU3260674",
              "goodId": "595c62b3-1984-41d6-b053-fb124d912475",
              "portOfLoading": {
                "name": "Brevik",
                "locationUnCode": "NOBVK",
                "countryUnCode": "NO",
                "euPort": false
              },
              "portOfDischarge": {
                "name": "Rotterdam",
                "locationUnCode": "NLRTM",
                "countryUnCode": "NL",
                "euPort": true
              },
              "weight": 18420,
              "trailer": false,
              "uncleanTankContainer": false,
              "position": "0070488",
              "numberOfOuterPackages": 1,
              "outerPackageType": {
                "code": "PK",
                "name": "Package",
                "bulk": false
              },
              "numberOfInnerPackages": null,
              "innerPackageType": null,
              "transportInLimitedQuantity": false,
              "netWeight": 16200,
              "netExplosiveMass": null,
              "goodIdIsMissing": null
            },
            {
              "type": "container",
              "stowageNumber": "BNSU3840158",
              "goodId": "2c3304ac-65ae-4330-ad01-6eeb5a8ef910",
              "portOfLoading": {
                "name": "Brevik",
                "locationUnCode": "NOBVK",
                "countryUnCode": "NO",
                "euPort": false
              },
              "portOfDischarge": {
                "name": "Rotterdam",
                "locationUnCode": "NLRTM",
                "countryUnCode": "NL",
                "euPort": true
              },
              "weight": 18110,
              "trailer": false,
              "uncleanTankContainer": false,
              "position": "0070688",
              "numberOfOuterPackages": 1,
              "outerPackageType": {
                "code": "PK",
                "name": "Package",
                "bulk": false
              },
              "numberOfInnerPackages": null,
              "innerPackageType": null,
              "transportInLimitedQuantity": false,
              "netWeight": 15880,
              "netExplosiveMass": null,
              "goodIdIsMissing": null
            },
            {
              "type": "container",
              "stowageNumber": "BNSU9943913",
              "goodId": "3c4bee7d-f123-4ac8-878d-3a56c24574af",
              "portOfLoading": {
                "name": "Brevik",
                "locationUnCode": "NOBVK",
                "countryUnCode": "NO",
                "euPort": false
              },
              "portOfDischarge": {
                "name": "Rotterdam",
                "locationUnCode": "NLRTM",
                "countryUnCode": "NL",
                "euPort": true
              },
              "weight": 19290,
              "trailer": false,
              "uncleanTankContainer": false,
              "position": "0070486",
              "numberOfOuterPackages": 1,
              "outerPackageType": {
                "code": "PK",
                "name": "Package",
                "bulk": false
              },
              "numberOfInnerPackages": null,
              "innerPackageType": null,
              "transportInLimitedQuantity": false,
              "netWeight": 17160,
              "netExplosiveMass": null,
              "goodIdIsMissing": null
            },
            {
              "type": "container",
              "stowageNumber": "BNSU2034271",
              "goodId": "1810bd92-87d8-42f1-aad0-863dbb1f739a",
              "portOfLoading": {
                "name": "Brevik",
                "locationUnCode": "NOBVK",
                "countryUnCode": "NO",
                "euPort": false
              },
              "portOfDischarge": {
                "name": "Rotterdam",
                "locationUnCode": "NLRTM",
                "countryUnCode": "NL",
                "euPort": true
              },
              "weight": 19230,
              "trailer": false,
              "uncleanTankContainer": false,
              "position": "0070386",
              "numberOfOuterPackages": 1,
              "outerPackageType": {
                "code": "PK",
                "name": "Package",
                "bulk": false
              },
              "numberOfInnerPackages": null,
              "innerPackageType": null,
              "transportInLimitedQuantity": false,
              "netWeight": 17080,
              "netExplosiveMass": null,
              "goodIdIsMissing": null
            },
            {
              "type": "container",
              "stowageNumber": "TWSU2717376",
              "goodId": "ea360c12-c2ab-4fcb-bb80-a541fcfcd664",
              "portOfLoading": {
                "name": "Brevik",
                "locationUnCode": "NOBVK",
                "countryUnCode": "NO",
                "euPort": false
              },
              "portOfDischarge": {
                "name": "Rotterdam",
                "locationUnCode": "NLRTM",
                "countryUnCode": "NL",
                "euPort": true
              },
              "weight": 1,
              "trailer": false,
              "uncleanTankContainer": false,
              "position": "0230286",
              "numberOfOuterPackages": 1,
              "outerPackageType": {
                "code": "TK",
                "name": "Tank, rectangular",
                "bulk": false
              },
              "numberOfInnerPackages": null,
              "innerPackageType": null,
              "transportInLimitedQuantity": false,
              "netWeight": 1,
              "netExplosiveMass": null,
              "goodIdIsMissing": null
            },
            {
              "type": "container",
              "stowageNumber": "MCMU6300309",
              "goodId": "16b2404d-4ae8-42d7-a594-f0a014013f06",
              "portOfLoading": {
                "name": "Brevik",
                "locationUnCode": "NOBVK",
                "countryUnCode": "NO",
                "euPort": false
              },
              "portOfDischarge": {
                "name": "Rotterdam",
                "locationUnCode": "NLRTM",
                "countryUnCode": "NL",
                "euPort": true
              },
              "weight": 1,
              "trailer": false,
              "uncleanTankContainer": false,
              "position": "0230284",
              "numberOfOuterPackages": 1,
              "outerPackageType": {
                "code": "TK",
                "name": "Tank, rectangular",
                "bulk": false
              },
              "numberOfInnerPackages": null,
              "innerPackageType": null,
              "transportInLimitedQuantity": false,
              "netWeight": 1,
              "netExplosiveMass": null,
              "goodIdIsMissing": null
            },
            {
              "type": "container",
              "stowageNumber": "SANU7518325",
              "goodId": "14d0e87b-d371-4b2f-966f-3fb524d3b08f",
              "portOfLoading": {
                "name": "Fredrikstad",
                "locationUnCode": "NOFRK",
                "countryUnCode": "NO",
                "euPort": false
              },
              "portOfDischarge": {
                "name": "Rotterdam",
                "locationUnCode": "NLRTM",
                "countryUnCode": "NL",
                "euPort": true
              },
              "weight": 30120,
              "trailer": false,
              "uncleanTankContainer": false,
              "position": "0160186",
              "numberOfOuterPackages": 18,
              "outerPackageType": {
                "code": "WA",
                "name": "Intermediate bulk container",
                "bulk": false
              },
              "numberOfInnerPackages": null,
              "innerPackageType": null,
              "transportInLimitedQuantity": false,
              "netWeight": 25920,
              "netExplosiveMass": null,
              "goodIdIsMissing": null
            },
            {
              "type": "container",
              "stowageNumber": "CLXU4512419",
              "goodId": "323a2ae2-5991-4bb4-ad4f-7d1be5132d36",
              "portOfLoading": {
                "name": "Fredrikstad",
                "locationUnCode": "NOFRK",
                "countryUnCode": "NO",
                "euPort": false
              },
              "portOfDischarge": {
                "name": "Rotterdam",
                "locationUnCode": "NLRTM",
                "countryUnCode": "NL",
                "euPort": true
              },
              "weight": 30180,
              "trailer": false,
              "uncleanTankContainer": false,
              "position": "0160286",
              "numberOfOuterPackages": 18,
              "outerPackageType": {
                "code": "WA",
                "name": "Intermediate bulk container",
                "bulk": false
              },
              "numberOfInnerPackages": null,
              "innerPackageType": null,
              "transportInLimitedQuantity": false,
              "netWeight": 25920,
              "netExplosiveMass": null,
              "goodIdIsMissing": null
            },
            {
              "type": "container",
              "stowageNumber": "SANU7979498",
              "goodId": "fc0e8cd5-48cc-4272-9718-73e402dbde26",
              "portOfLoading": {
                "name": "Fredrikstad",
                "locationUnCode": "NOFRK",
                "countryUnCode": "NO",
                "euPort": false
              },
              "portOfDischarge": {
                "name": "Rotterdam",
                "locationUnCode": "NLRTM",
                "countryUnCode": "NL",
                "euPort": true
              },
              "weight": 16243,
              "trailer": false,
              "uncleanTankContainer": false,
              "position": "0160388",
              "numberOfOuterPackages": 33,
              "outerPackageType": {
                "code": "PX",
                "name": "Pallet",
                "bulk": false
              },
              "numberOfInnerPackages": null,
              "innerPackageType": null,
              "transportInLimitedQuantity": false,
              "netWeight": 14758,
              "netExplosiveMass": null,
              "goodIdIsMissing": null
            },
            {
              "type": "container",
              "stowageNumber": "PVDU1043049",
              "goodId": "59431399-4738-4603-aacb-91d5c7edc909",
              "portOfLoading": {
                "name": "Fredrikstad",
                "locationUnCode": "NOFRK",
                "countryUnCode": "NO",
                "euPort": false
              },
              "portOfDischarge": {
                "name": "Rotterdam",
                "locationUnCode": "NLRTM",
                "countryUnCode": "NL",
                "euPort": true
              },
              "weight": 30100,
              "trailer": false,
              "uncleanTankContainer": false,
              "position": "0160484",
              "numberOfOuterPackages": 18,
              "outerPackageType": {
                "code": "WA",
                "name": "Intermediate bulk container",
                "bulk": false
              },
              "numberOfInnerPackages": null,
              "innerPackageType": null,
              "transportInLimitedQuantity": false,
              "netWeight": 25920,
              "netExplosiveMass": null,
              "goodIdIsMissing": null
            },
            {
              "type": "container",
              "stowageNumber": "TLLU1636333",
              "goodId": "4eadf56d-6ec6-45b5-873a-0783164f1da9",
              "portOfLoading": {
                "name": "Fredrikstad",
                "locationUnCode": "NOFRK",
                "countryUnCode": "NO",
                "euPort": false
              },
              "portOfDischarge": {
                "name": "Rotterdam",
                "locationUnCode": "NLRTM",
                "countryUnCode": "NL",
                "euPort": true
              },
              "weight": 30200,
              "trailer": false,
              "uncleanTankContainer": false,
              "position": "0160384",
              "numberOfOuterPackages": 18,
              "outerPackageType": {
                "code": "WA",
                "name": "Intermediate bulk container",
                "bulk": false
              },
              "numberOfInnerPackages": null,
              "innerPackageType": null,
              "transportInLimitedQuantity": false,
              "netWeight": 25920,
              "netExplosiveMass": null,
              "goodIdIsMissing": null
            },
            {
              "type": "container",
              "stowageNumber": "SANU7997341",
              "goodId": "1d036bdf-24ff-4235-88b0-0e654d14567b",
              "portOfLoading": {
                "name": "Fredrikstad",
                "locationUnCode": "NOFRK",
                "countryUnCode": "NO",
                "euPort": false
              },
              "portOfDischarge": {
                "name": "Rotterdam",
                "locationUnCode": "NLRTM",
                "countryUnCode": "NL",
                "euPort": true
              },
              "weight": 30240,
              "trailer": false,
              "uncleanTankContainer": false,
              "position": "0160184",
              "numberOfOuterPackages": 18,
              "outerPackageType": {
                "code": "WA",
                "name": "Intermediate bulk container",
                "bulk": false
              },
              "numberOfInnerPackages": null,
              "innerPackageType": null,
              "transportInLimitedQuantity": false,
              "netWeight": 25920,
              "netExplosiveMass": null,
              "goodIdIsMissing": null
            },
            {
              "type": "container",
              "stowageNumber": "DRYU2445174",
              "goodId": "6d2c4046-2cb0-48b6-9b6d-6546e706c81c",
              "portOfLoading": {
                "name": "Oslo",
                "locationUnCode": "NOOSL",
                "countryUnCode": "NO",
                "euPort": false
              },
              "portOfDischarge": {
                "name": "Rotterdam",
                "locationUnCode": "NLRTM",
                "countryUnCode": "NL",
                "euPort": true
              },
              "weight": 25925,
              "trailer": false,
              "uncleanTankContainer": false,
              "position": "0250004",
              "numberOfOuterPackages": 50,
              "outerPackageType": {
                "code": "BG",
                "name": "Bag",
                "bulk": false
              },
              "numberOfInnerPackages": null,
              "innerPackageType": null,
              "transportInLimitedQuantity": false,
              "netWeight": 25000,
              "netExplosiveMass": null,
              "goodIdIsMissing": null
            },
            {
              "type": "container",
              "stowageNumber": "PCVU3502250",
              "goodId": "cceedf36-571f-46d8-93ed-c5cb51129300",
              "portOfLoading": {
                "name": "Oslo",
                "locationUnCode": "NOOSL",
                "countryUnCode": "NO",
                "euPort": false
              },
              "portOfDischarge": {
                "name": "Rotterdam",
                "locationUnCode": "NLRTM",
                "countryUnCode": "NL",
                "euPort": true
              },
              "weight": 1,
              "trailer": false,
              "uncleanTankContainer": false,
              "position": "0010488",
              "numberOfOuterPackages": 1,
              "outerPackageType": {
                "code": "TK",
                "name": "Tank, rectangular",
                "bulk": false
              },
              "numberOfInnerPackages": null,
              "innerPackageType": null,
              "transportInLimitedQuantity": false,
              "netWeight": 1,
              "netExplosiveMass": null,
              "goodIdIsMissing": null
            },
            {
              "type": "container",
              "stowageNumber": "INGU7351977",
              "goodId": "9c098687-e33b-424d-ab05-22fff8052488",
              "portOfLoading": {
                "name": "Oslo",
                "locationUnCode": "NOOSL",
                "countryUnCode": "NO",
                "euPort": false
              },
              "portOfDischarge": {
                "name": "Rotterdam",
                "locationUnCode": "NLRTM",
                "countryUnCode": "NL",
                "euPort": true
              },
              "weight": 1,
              "trailer": false,
              "uncleanTankContainer": false,
              "position": "0010486",
              "numberOfOuterPackages": 1,
              "outerPackageType": {
                "code": "TK",
                "name": "Tank, rectangular",
                "bulk": false
              },
              "numberOfInnerPackages": null,
              "innerPackageType": null,
              "transportInLimitedQuantity": false,
              "netWeight": 1,
              "netExplosiveMass": null,
              "goodIdIsMissing": null
            },
            {
              "type": "container",
              "stowageNumber": "GMCU1260911",
              "goodId": "95f87313-cac4-40f7-8542-c507c05f3540",
              "portOfLoading": {
                "name": "Oslo",
                "locationUnCode": "NOOSL",
                "countryUnCode": "NO",
                "euPort": false
              },
              "portOfDischarge": {
                "name": "Rotterdam",
                "locationUnCode": "NLRTM",
                "countryUnCode": "NL",
                "euPort": true
              },
              "weight": 1,
              "trailer": false,
              "uncleanTankContainer": false,
              "position": "0010308",
              "numberOfOuterPackages": 1,
              "outerPackageType": {
                "code": "TK",
                "name": "Tank, rectangular",
                "bulk": false
              },
              "numberOfInnerPackages": null,
              "innerPackageType": null,
              "transportInLimitedQuantity": false,
              "netWeight": 1,
              "netExplosiveMass": null,
              "goodIdIsMissing": null
            },
            {
              "type": "container",
              "stowageNumber": "EXFU5627098",
              "goodId": "71e10dfe-908e-42d9-b8b7-2d2f83dddd63",
              "portOfLoading": {
                "name": "Oslo",
                "locationUnCode": "NOOSL",
                "countryUnCode": "NO",
                "euPort": false
              },
              "portOfDischarge": {
                "name": "Rotterdam",
                "locationUnCode": "NLRTM",
                "countryUnCode": "NL",
                "euPort": true
              },
              "weight": 1,
              "trailer": false,
              "uncleanTankContainer": false,
              "position": "0030886",
              "numberOfOuterPackages": 1,
              "outerPackageType": {
                "code": "TK",
                "name": "Tank, rectangular",
                "bulk": false
              },
              "numberOfInnerPackages": null,
              "innerPackageType": null,
              "transportInLimitedQuantity": false,
              "netWeight": 1,
              "netExplosiveMass": null,
              "goodIdIsMissing": null
            },
            {
              "type": "container",
              "stowageNumber": "DHIU1845442",
              "goodId": "d25bbdb7-906d-4652-bfc0-b41f9c682669",
              "portOfLoading": {
                "name": "Oslo",
                "locationUnCode": "NOOSL",
                "countryUnCode": "NO",
                "euPort": false
              },
              "portOfDischarge": {
                "name": "Rotterdam",
                "locationUnCode": "NLRTM",
                "countryUnCode": "NL",
                "euPort": true
              },
              "weight": 1,
              "trailer": false,
              "uncleanTankContainer": false,
              "position": "0010288",
              "numberOfOuterPackages": 1,
              "outerPackageType": {
                "code": "TK",
                "name": "Tank, rectangular",
                "bulk": false
              },
              "numberOfInnerPackages": null,
              "innerPackageType": null,
              "transportInLimitedQuantity": false,
              "netWeight": 1,
              "netExplosiveMass": null,
              "goodIdIsMissing": null
            },
            {
              "type": "container",
              "stowageNumber": "UTTU3511480",
              "goodId": "2f49962d-937c-4555-aa93-bac09fdeb121",
              "portOfLoading": {
                "name": "Oslo",
                "locationUnCode": "NOOSL",
                "countryUnCode": "NO",
                "euPort": false
              },
              "portOfDischarge": {
                "name": "Rotterdam",
                "locationUnCode": "NLRTM",
                "countryUnCode": "NL",
                "euPort": true
              },
              "weight": 1,
              "trailer": false,
              "uncleanTankContainer": false,
              "position": "0010188",
              "numberOfOuterPackages": 1,
              "outerPackageType": {
                "code": "TK",
                "name": "Tank, rectangular",
                "bulk": false
              },
              "numberOfInnerPackages": null,
              "innerPackageType": null,
              "transportInLimitedQuantity": false,
              "netWeight": 1,
              "netExplosiveMass": null,
              "goodIdIsMissing": null
            },
            {
              "type": "container",
              "stowageNumber": "MEBU1261684",
              "goodId": "6b1d9f91-ab19-4bf3-990f-3f31bbf6b241",
              "portOfLoading": {
                "name": "Oslo",
                "locationUnCode": "NOOSL",
                "countryUnCode": "NO",
                "euPort": false
              },
              "portOfDischarge": {
                "name": "Rotterdam",
                "locationUnCode": "NLRTM",
                "countryUnCode": "NL",
                "euPort": true
              },
              "weight": 1,
              "trailer": false,
              "uncleanTankContainer": false,
              "position": "0010206",
              "numberOfOuterPackages": 1,
              "outerPackageType": {
                "code": "TK",
                "name": "Tank, rectangular",
                "bulk": false
              },
              "numberOfInnerPackages": null,
              "innerPackageType": null,
              "transportInLimitedQuantity": false,
              "netWeight": 1,
              "netExplosiveMass": null,
              "goodIdIsMissing": null
            },
            {
              "type": "container",
              "stowageNumber": "MEBU1264214",
              "goodId": "224f4c2c-9151-4aa6-844b-0a4a8da8dd26",
              "portOfLoading": {
                "name": "Oslo",
                "locationUnCode": "NOOSL",
                "countryUnCode": "NO",
                "euPort": false
              },
              "portOfDischarge": {
                "name": "Rotterdam",
                "locationUnCode": "NLRTM",
                "countryUnCode": "NL",
                "euPort": true
              },
              "weight": 1,
              "trailer": false,
              "uncleanTankContainer": false,
              "position": "0010106",
              "numberOfOuterPackages": 1,
              "outerPackageType": {
                "code": "TK",
                "name": "Tank, rectangular",
                "bulk": false
              },
              "numberOfInnerPackages": null,
              "innerPackageType": null,
              "transportInLimitedQuantity": false,
              "netWeight": 1,
              "netExplosiveMass": null,
              "goodIdIsMissing": null
            },
            {
              "type": "container",
              "stowageNumber": "UTTU2511886",
              "goodId": "741fa0d4-fed0-4ee0-aa6c-c3491031c545",
              "portOfLoading": {
                "name": "Oslo",
                "locationUnCode": "NOOSL",
                "countryUnCode": "NO",
                "euPort": false
              },
              "portOfDischarge": {
                "name": "Rotterdam",
                "locationUnCode": "NLRTM",
                "countryUnCode": "NL",
                "euPort": true
              },
              "weight": 1,
              "trailer": false,
              "uncleanTankContainer": false,
              "position": "0030308",
              "numberOfOuterPackages": 1,
              "outerPackageType": {
                "code": "TK",
                "name": "Tank, rectangular",
                "bulk": false
              },
              "numberOfInnerPackages": null,
              "innerPackageType": null,
              "transportInLimitedQuantity": false,
              "netWeight": 1,
              "netExplosiveMass": null,
              "goodIdIsMissing": null
            },
            {
              "type": "container",
              "stowageNumber": "NYKU3788111",
              "goodId": "e35e6669-1245-43fe-bbf8-04dddd39e757",
              "portOfLoading": {
                "name": "Oslo",
                "locationUnCode": "NOOSL",
                "countryUnCode": "NO",
                "euPort": false
              },
              "portOfDischarge": {
                "name": "Rotterdam",
                "locationUnCode": "NLRTM",
                "countryUnCode": "NL",
                "euPort": true
              },
              "weight": 3.18,
              "trailer": false,
              "uncleanTankContainer": false,
              "position": "0110184",
              "numberOfOuterPackages": 18,
              "outerPackageType": {
                "code": "DR",
                "name": "Drum",
                "bulk": false
              },
              "numberOfInnerPackages": null,
              "innerPackageType": null,
              "transportInLimitedQuantity": false,
              "netWeight": 2.7,
              "netExplosiveMass": null,
              "goodIdIsMissing": null
            }
          ],
          "handlings": [
            {
              "type": "discharge",
              "berthVisitId": "8e62048f-51d3-46a4-962e-bf024aec32c4",
              "stowages": [
                {
                  "stowageNumber": "TDTU6768227",
                  "type": "container",
                  "emptied": true,
                  "amount": null
                },
                {
                  "stowageNumber": "SANU7518325",
                  "type": "container",
                  "emptied": true,
                  "amount": null
                },
                {
                  "stowageNumber": "BNSU3057380",
                  "type": "container",
                  "emptied": true,
                  "amount": null
                },
                {
                  "stowageNumber": "PCVU3502250",
                  "type": "container",
                  "emptied": true,
                  "amount": null
                },
                {
                  "stowageNumber": "BNSU2034271",
                  "type": "container",
                  "emptied": true,
                  "amount": null
                },
                {
                  "stowageNumber": "BNSU3840158",
                  "type": "container",
                  "emptied": true,
                  "amount": null
                },
                {
                  "stowageNumber": "MCMU6300309",
                  "type": "container",
                  "emptied": true,
                  "amount": null
                },
                {
                  "stowageNumber": "TWSU2717376",
                  "type": "container",
                  "emptied": true,
                  "amount": null
                },
                {
                  "stowageNumber": "UTTU3511480",
                  "type": "container",
                  "emptied": true,
                  "amount": null
                },
                {
                  "stowageNumber": "SANU7997341",
                  "type": "container",
                  "emptied": true,
                  "amount": null
                },
                {
                  "stowageNumber": "BNSU2140139",
                  "type": "container",
                  "emptied": true,
                  "amount": null
                },
                {
                  "stowageNumber": "SANU7979498",
                  "type": "container",
                  "emptied": true,
                  "amount": null
                },
                {
                  "stowageNumber": "DHIU1845442",
                  "type": "container",
                  "emptied": true,
                  "amount": null
                },
                {
                  "stowageNumber": "EXFU5627098",
                  "type": "container",
                  "emptied": true,
                  "amount": null
                },
                {
                  "stowageNumber": "BNSU3865747",
                  "type": "container",
                  "emptied": true,
                  "amount": null
                },
                {
                  "stowageNumber": "UTTU2511886",
                  "type": "container",
                  "emptied": true,
                  "amount": null
                },
                {
                  "stowageNumber": "GMCU1260911",
                  "type": "container",
                  "emptied": true,
                  "amount": null
                },
                {
                  "stowageNumber": "PVDU1043049",
                  "type": "container",
                  "emptied": true,
                  "amount": null
                },
                {
                  "stowageNumber": "CLXU4512419",
                  "type": "container",
                  "emptied": true,
                  "amount": null
                },
                {
                  "stowageNumber": "BNSU3260674",
                  "type": "container",
                  "emptied": true,
                  "amount": null
                },
                {
                  "stowageNumber": "TLLU1636333",
                  "type": "container",
                  "emptied": true,
                  "amount": null
                },
                {
                  "stowageNumber": "TTNU2741910",
                  "type": "container",
                  "emptied": true,
                  "amount": null
                },
                {
                  "stowageNumber": "BNSU9943913",
                  "type": "container",
                  "emptied": true,
                  "amount": null
                },
                {
                  "stowageNumber": "TDTU7834200",
                  "type": "container",
                  "emptied": true,
                  "amount": null
                },
                {
                  "stowageNumber": "NYKU3788111",
                  "type": "container",
                  "emptied": true,
                  "amount": null
                },
                {
                  "stowageNumber": "INGU7351977",
                  "type": "container",
                  "emptied": true,
                  "amount": null
                },
                {
                  "stowageNumber": "MEBU1264214",
                  "type": "container",
                  "emptied": true,
                  "amount": null
                },
                {
                  "stowageNumber": "MEBU1261684",
                  "type": "container",
                  "emptied": true,
                  "amount": null
                },
                {
                  "stowageNumber": "DRYU2445174",
                  "type": "container",
                  "emptied": true,
                  "amount": null
                }
              ],
              "shipToShip": false,
              "keepInert": null
            }
          ]
        },
        "cargoDeclarant": {
          "fullName": "Unifeeder Netherlands B.V.",
          "shortName": "UNIFEEDER",
          "portAuthorityId": "UNIFEEDER",
          "emailAddress": "ops-bnl@unifeeder.com",
          "address": "Blaak 555",
          "city": "Rotterdam",
          "countryUnCode": "NL",
          "countryName": "Netherlands",
          "zipCode": "3011 GB",
          "phoneNumber": "0102179141",
          "faxNumber": null,
          "contact": "J.F. Meeder",
          "customsEORINumber": "DK11810543",
          "ean": "8713755262211",
          "chamberOfCommerceNumber": "24450473",
          "scacCode": null,
          "ensEnabled": true,
          "cargoImportEnabled": true,
          "cargoImportEnabledByDefault": true
        },
        "visitDeclarant": {
          "fullName": "Unifeeder Netherlands B.V.",
          "shortName": "UNIFEEDER",
          "portAuthorityId": "UNIFEEDER",
          "emailAddress": "ops-bnl@unifeeder.com",
          "address": "Blaak 555",
          "city": "Rotterdam",
          "countryUnCode": "NL",
          "countryName": "Netherlands",
          "zipCode": "3011 GB",
          "phoneNumber": "0102179141",
          "faxNumber": null,
          "contact": "Adil Maniyar",
          "customsEORINumber": "DK11810543",
          "ean": "8713755262211",
          "chamberOfCommerceNumber": "24450473",
          "scacCode": null,
          "ensEnabled": true,
          "cargoImportEnabled": true,
          "cargoImportEnabledByDefault": true
        },
        "visitOwner": {
          "fullName": "Unifeeder Netherlands B.V.",
          "shortName": "UNIFEEDER",
          "portAuthorityId": "UNIFEEDER",
          "emailAddress": "ops-bnl@unifeeder.com",
          "address": "Blaak 555",
          "city": "Rotterdam",
          "countryUnCode": "NL",
          "countryName": "Netherlands",
          "zipCode": "3011 GB",
          "phoneNumber": "0102179141",
          "faxNumber": null,
          "contact": "Adil Maniyar",
          "customsEORINumber": "DK11810543",
          "ean": "8713755262211",
          "chamberOfCommerceNumber": "24450473",
          "scacCode": null,
          "ensEnabled": true,
          "cargoImportEnabled": true,
          "cargoImportEnabledByDefault": true
        },
        "upload": null,
        "completed": true,
        "declared": true,
        "declaredByVisitDeclarant": true,
        "processIds": [
          "cab80116c0f045aaa241aad9056ba65c"
        ]
      },
      {
        "dangerousGoods": {
          "goods": [],
          "stowageAtArrival": [],
          "handlings": []
        },
        "cargoDeclarant": {
          "fullName": "Kuhlman Repko Shipping B.V.",
          "shortName": "KUHLMAN",
          "portAuthorityId": "KRSHIPPING",
          "emailAddress": "erno@krshipping.nl",
          "address": "Nieuwe Vissershaven 7",
          "city": "Harlingen",
          "countryUnCode": "NL",
          "countryName": "Netherlands",
          "zipCode": "8861 NX",
          "phoneNumber": "+31-517-412831",
          "faxNumber": null,
          "contact": "De heer Erno Leguijt",
          "customsEORINumber": "NL813141692",
          "ean": null,
          "chamberOfCommerceNumber": "01103873",
          "scacCode": null,
          "ensEnabled": false,
          "cargoImportEnabled": true,
          "cargoImportEnabledByDefault": true
        },
        "visitDeclarant": {
          "fullName": "Kuhlman Repko Shipping B.V.",
          "shortName": "KUHLMAN",
          "portAuthorityId": "KRSHIPPING",
          "emailAddress": "erno@krshipping.nl",
          "address": "Nieuwe Vissershaven 7",
          "city": "Harlingen",
          "countryUnCode": "NL",
          "countryName": "Netherlands",
          "zipCode": "8861 NX",
          "phoneNumber": "+31-517-412831",
          "faxNumber": null,
          "contact": "De heer Erno Leguijt",
          "customsEORINumber": "NL813141692",
          "ean": null,
          "chamberOfCommerceNumber": "01103873",
          "scacCode": null,
          "ensEnabled": false,
          "cargoImportEnabled": true,
          "cargoImportEnabledByDefault": true
        },
        "visitOwner": {
          "fullName": "Kuhlman Repko Shipping B.V.",
          "shortName": "KUHLMAN",
          "portAuthorityId": "KRSHIPPING",
          "emailAddress": "erno@krshipping.nl",
          "address": "Nieuwe Vissershaven 7",
          "city": "Harlingen",
          "countryUnCode": "NL",
          "countryName": "Netherlands",
          "zipCode": "8861 NX",
          "phoneNumber": "+31-517-412831",
          "faxNumber": null,
          "contact": "De heer Erno Leguijt",
          "customsEORINumber": "NL813141692",
          "ean": null,
          "chamberOfCommerceNumber": "01103873",
          "scacCode": null,
          "ensEnabled": false,
          "cargoImportEnabled": true,
          "cargoImportEnabledByDefault": true
        },
        "upload": null,
        "completed": false,
        "declared": false,
        "declaredByVisitDeclarant": false,
        "processIds": []
      }
    ],
    "nextDangerousGoodsDeclaration": null,
    "importDeclarations": [
      {
        "cargoDeclarant": {
          "fullName": "Unifeeder Netherlands B.V.",
          "shortName": "UNIFEEDER",
          "portAuthorityId": "UNIFEEDER",
          "emailAddress": "ops-bnl@unifeeder.com",
          "address": "Blaak 555",
          "city": "Rotterdam",
          "countryUnCode": "NL",
          "countryName": "Netherlands",
          "zipCode": "3011 GB",
          "phoneNumber": "0102179141",
          "faxNumber": null,
          "contact": "J.F. Meeder",
          "customsEORINumber": "DK11810543",
          "ean": "8713755262211",
          "chamberOfCommerceNumber": "24450473",
          "scacCode": null,
          "ensEnabled": true,
          "cargoImportEnabled": true,
          "cargoImportEnabledByDefault": true
        },
        "timestamp": "2021-09-28T05:04:52.793Z",
        "containers": [
          {
            "number": "APZU4586394",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "42GP",
              "name": "42GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "APZU4659572",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "42GP",
              "name": "42GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "BEAU4026340",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45GP",
              "name": "45GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "BHCU3032411",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "4810",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22GP",
              "name": "22GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "BMOU2900554",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "4810",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22GP",
              "name": "22GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "BMOU5481019",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45GP",
              "name": "45GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "BMOU9612457",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "4810",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45RE",
              "name": "45RE"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "BSIU2841077",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22GP",
              "name": "22GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "BSIU9402796",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45GP",
              "name": "45GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "CAIU2127927",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "4810",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22GP",
              "name": "22GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "CAIU3194529",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "4810",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22GP",
              "name": "22GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "CAIU7413522",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45GP",
              "name": "45GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "CBHU5546275",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22GP",
              "name": "22GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "CBHU5604577",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22GP",
              "name": "22GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "CBHU7053874",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45GP",
              "name": "45GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "CCLU7776770",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45GP",
              "name": "45GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "CCLU8609335",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45RE",
              "name": "45RE"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "CMAU3015863",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "4810",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22GP",
              "name": "22GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "CNCU1504873",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22GP",
              "name": "22GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "CNEU4551330",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEG1",
              "name": "45 ft 9.6 PW"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "CRSU1193066",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "4810",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22GP",
              "name": "22GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "CRSU9217159",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45GP",
              "name": "45GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "CSLU1351867",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22GP",
              "name": "22GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "CSLU2246064",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22GP",
              "name": "22GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "CSLU6334590",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45GP",
              "name": "45GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "CSNU1386177",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22GP",
              "name": "22GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "CSNU1705931",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22GP",
              "name": "22GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "CSNU1933947",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22GP",
              "name": "22GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "CSNU6252078",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45GP",
              "name": "45GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "CSNU6959374",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45GP",
              "name": "45GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "CXDU1212509",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22GP",
              "name": "22GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "CXRU1499271",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45RE",
              "name": "45RE"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "DFSU1010239",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22GP",
              "name": "22GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "DFSU1734466",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22GP",
              "name": "22GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "DFSU2943250",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "4810",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22GP",
              "name": "22GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "DFSU4283746",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "42GP",
              "name": "42GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "DRYU2445174",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22GP",
              "name": "22GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "DRYU9428245",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45GP",
              "name": "45GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "EGHU1013664",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "42GP",
              "name": "42GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "EGHU1057750",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "42GP",
              "name": "42GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "EGHU3542570",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22GP",
              "name": "22GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "EGHU9110125",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45GP",
              "name": "45GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "EGHU9249430",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45GP",
              "name": "45GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "EGHU9608653",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45GP",
              "name": "45GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "EGHU9611430",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45GP",
              "name": "45GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "EGHU9666813",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45GP",
              "name": "45GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "EGHU9715271",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45GP",
              "name": "45GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "EGHU9788961",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45GP",
              "name": "45GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "EGHU9847175",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45GP",
              "name": "45GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "EGSU3044565",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22GP",
              "name": "22GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "EGSU9126824",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45GP",
              "name": "45GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "EISU2194760",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22GP",
              "name": "22GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "EISU8295588",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45GP",
              "name": "45GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "EISU8511429",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45GP",
              "name": "45GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "EISU8520992",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45GP",
              "name": "45GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "EISU9132688",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45GP",
              "name": "45GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "EISU9428237",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45GP",
              "name": "45GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "EITU0191338",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22GP",
              "name": "22GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "EITU1141389",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45GP",
              "name": "45GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "EITU1391393",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45GP",
              "name": "45GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "EITU1491474",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45GP",
              "name": "45GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "EITU1680333",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45GP",
              "name": "45GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "EITU9236304",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45GP",
              "name": "45GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "EITU9395760",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45GP",
              "name": "45GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "EITU9403253",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45GP",
              "name": "45GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "EMCU1449056",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "42GP",
              "name": "42GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "EMCU3911203",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22GP",
              "name": "22GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "ESPU2045111",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22GP",
              "name": "22GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "EXFU8784110",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "BDT5",
              "name": "BDT5"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "EXFU8784125",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "BMT5",
              "name": "BMT5"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "EXFU8784130",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "BDT5",
              "name": "BDT5"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "FCIU2945135",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "4810",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22GP",
              "name": "22GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "FCIU4383335",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "4810",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22GP",
              "name": "22GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "FCIU5718623",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22GP",
              "name": "22GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "FCIU6559953",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "4810",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22GP",
              "name": "22GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "GAOU6416589",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45GP",
              "name": "45GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "GLDU5724381",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "4810",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22GP",
              "name": "22GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "HLBU3006650",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "4810",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22GP",
              "name": "22GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "HLBU9187319",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "4810",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45RE",
              "name": "45RE"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "HLBU9263342",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "4810",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45RE",
              "name": "45RE"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "HLBU9429583",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "4810",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45RE",
              "name": "45RE"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "HLXU1178323",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "4810",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22GP",
              "name": "22GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "HLXU1266532",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "4810",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22GP",
              "name": "22GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "HLXU3538115",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "4810",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22GP",
              "name": "22GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "HLXU5215430",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "4810",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "42GP",
              "name": "42GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "KKTU8226701",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "4810",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22GP",
              "name": "22GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "KKTU8250605",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "4810",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22GP",
              "name": "22GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "MEDU5750504",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "4810",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22GP",
              "name": "22GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "MEDU6013116",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "4810",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22GP",
              "name": "22GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "MEDU9616751",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "4810",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45RE",
              "name": "45RE"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "MEDU9702818",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "4810",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45RE",
              "name": "45RE"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "MEDU9714758",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "4810",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45RE",
              "name": "45RE"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "MOAU6751434",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "4810",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22GP",
              "name": "22GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "MSCU7388810",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "4810",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45RE",
              "name": "45RE"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "MSDU1009980",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "4810",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22GP",
              "name": "22GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "NYKU3548933",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "4810",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22GP",
              "name": "22GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "NYKU3723569",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "4810",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22GP",
              "name": "22GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "NYKU3828493",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "4810",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22GP",
              "name": "22GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "NYKU9711837",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "4810",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22GP",
              "name": "22GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "NYKU9740521",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "4810",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22GP",
              "name": "22GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "NYKU9785948",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "4810",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22GP",
              "name": "22GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "OERU4038765",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45RE",
              "name": "45RE"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "OOCU6411524",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45GP",
              "name": "45GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "OOLU6089121",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45RE",
              "name": "45RE"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "SEGU5048752",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45GP",
              "name": "45GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "SZLU9372803",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45RE",
              "name": "45RE"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "SZLU9683678",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45RE",
              "name": "45RE"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TCKU2765812",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "4810",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22GP",
              "name": "22GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TCKU2823987",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "4810",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22GP",
              "name": "22GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TCKU6059645",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45GP",
              "name": "45GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TCLU4429115",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "42GP",
              "name": "42GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TCLU4801095",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEG1",
              "name": "45 ft 9.6 PW"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TCLU4834993",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEG1",
              "name": "45 ft 9.6 PW"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TCLU7562008",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "4810",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22GP",
              "name": "22GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TCLU9383892",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "4810",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "42GP",
              "name": "42GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TCNU2139958",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45GP",
              "name": "45GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TCNU3210789",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45GP",
              "name": "45GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TCNU3369106",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45GP",
              "name": "45GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TCNU4999970",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45GP",
              "name": "45GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TCNU5842028",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45GP",
              "name": "45GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TEMU1281145",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "4810",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22GP",
              "name": "22GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TEMU2887179",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "4810",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22GP",
              "name": "22GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TEMU4293509",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "4810",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22GP",
              "name": "22GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TEMU5293400",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "4810",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22GP",
              "name": "22GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TEMU9123948",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45RE",
              "name": "45RE"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TEMU9125848",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45RE",
              "name": "45RE"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TGBU4444225",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45GP",
              "name": "45GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TGBU4923716",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45GP",
              "name": "45GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TGBU5046859",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45GP",
              "name": "45GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TGBU5059434",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45GP",
              "name": "45GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TGBU9275576",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45GP",
              "name": "45GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TGBU9914830",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45GP",
              "name": "45GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TGCU5081215",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45GP",
              "name": "45GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TGHU0639370",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "4810",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22GP",
              "name": "22GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TGHU0960945",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "4810",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22GP",
              "name": "22GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TGHU1467933",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22GP",
              "name": "22GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TGHU1467980",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "4810",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22GP",
              "name": "22GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TGHU5168416",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "42GP",
              "name": "42GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TLLU1683572",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEG1",
              "name": "45 ft 9.6 PW"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TLLU1698150",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEG1",
              "name": "45 ft 9.6 PW"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TLLU5417185",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45GP",
              "name": "45GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TRHU1733486",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "4810",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22GP",
              "name": "22GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TRHU4649091",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45GP",
              "name": "45GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TRHU7466387",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45GP",
              "name": "45GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TRHU7472440",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45GP",
              "name": "45GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TRIU8217119",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "4810",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45RE",
              "name": "45RE"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TRIU8940278",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "4810",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45RE",
              "name": "45RE"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TTNU8339560",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "4810",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45RE",
              "name": "45RE"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TTNU8453362",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "4810",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45RE",
              "name": "45RE"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TXGU5587007",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45GP",
              "name": "45GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "UACU3375991",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "4810",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22GP",
              "name": "22GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "UACU8252747",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "4810",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "42GP",
              "name": "42GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "UETU2504893",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22GP",
              "name": "22GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "UETU5215338",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45GP",
              "name": "45GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "UETU5531655",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45GP",
              "name": "45GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "VODU2510242",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "24TN",
              "name": "24TN"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "VODU2510406",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "24TN",
              "name": "24TN"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "VODU2510509",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "24TN",
              "name": "24TN"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "VODU2510520",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "24TN",
              "name": "24TN"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "VODU2510664",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "24TN",
              "name": "24TN"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "VODU2510730",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "24TN",
              "name": "24TN"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "VODU2511043",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "24TN",
              "name": "24TN"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "VODU2511130",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "24TN",
              "name": "24TN"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "VODU2511212",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "24TN",
              "name": "24TN"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "VODU2511260",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "24TN",
              "name": "24TN"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "VODU2511300",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "24TN",
              "name": "24TN"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "VODU2512034",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "24TN",
              "name": "24TN"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "VODU2513030",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "24TN",
              "name": "24TN"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "VODU9251246",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22TN",
              "name": "22TN"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "VODU9308151",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "EMT6",
              "name": "25ft tank container 400 kPa"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "YMMU1008723",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "4810",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22GP",
              "name": "22GP"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          }
        ],
        "consignments": [
          {
            "consignmentNumber": "UFEE6176428A",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": "SEA_IN_SEA_OUT",
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "GREENSHIP NORWAY AS",
              "address": "ALFBJERCKES VEI 10",
              "zipCode": "N-0582",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "EVERGREEN SHIPPING AGENCY (EUROPE)",
              "address": "PORTCITY II HAVENNUMMER 2235",
              "zipCode": "3089 JH",
              "city": "ROTTERDAM",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euLocation": false
            },
            "placeOfDestination": {
              "name": "Xinfeng",
              "locationUnCode": "CNXAB",
              "countryUnCode": "CN",
              "euLocation": false
            },
            "placeOfDelivery": null,
            "onCarriageDetails": null,
            "agentVoyageNumber": "00617300D",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "SEAWEED EXTRACT",
                "grossWeight": 26620,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "EITU0191338"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "EITU0191338",
                    "numberOfPackages": 1,
                    "grossWeight": 26620
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 2,
                "description": "SEAWEED EXTRACT",
                "grossWeight": 26640,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "DFSU1010239"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "DFSU1010239",
                    "numberOfPackages": 1,
                    "grossWeight": 26640
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "UFEE6205281A",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "4810",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": "SEA_IN_SEA_OUT",
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "HEVOLD SHIPPING AS",
              "address": "GROENLANDSLEIRET 31",
              "zipCode": "0190",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "HAPAG LLOYD NEDERLAND BV",
              "address": "WAALHAVEN O.Z. 79",
              "zipCode": "3087BM",
              "city": "ROTTERDAM",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euLocation": false
            },
            "placeOfDestination": {
              "name": "Cartagena",
              "locationUnCode": "COCTG",
              "countryUnCode": "CO",
              "euLocation": false
            },
            "placeOfDelivery": null,
            "onCarriageDetails": null,
            "agentVoyageNumber": "00617300D",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "MARGARINE",
                "grossWeight": 28268,
                "numberOfOuterPackages": 2880,
                "outerPackageType": {
                  "code": "CT",
                  "name": "Carton",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "HLBU9187319"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "HLBU9187319",
                    "numberOfPackages": 2880,
                    "grossWeight": 28268
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "UFEE6215182A",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": "SEA_IN_SEA_OUT",
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "COSCO SHIPPING LINES (NORWAY) A/S",
              "address": "POSTBOKS 1006, SENTRUM",
              "zipCode": "0104",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "COSCO SHIPPING LINES (NETHERLANDS)",
              "address": "PO BOX 362",
              "zipCode": "3000 AJ",
              "city": "ROTTERDAM",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euLocation": false
            },
            "placeOfDestination": {
              "name": "Shanghai Hongqiao International Apt",
              "locationUnCode": "CNSHA",
              "countryUnCode": "CN",
              "euLocation": false
            },
            "placeOfDelivery": null,
            "onCarriageDetails": null,
            "agentVoyageNumber": "00617300D",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "LIGNIN",
                "grossWeight": 22817.5,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "TRHU4649091"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "TRHU4649091",
                    "numberOfPackages": 1,
                    "grossWeight": 22817.5
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 2,
                "description": "LIGNIN",
                "grossWeight": 22917.5,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "CSLU6334590"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "CSLU6334590",
                    "numberOfPackages": 1,
                    "grossWeight": 22917.5
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 3,
                "description": "LIGNIN",
                "grossWeight": 22817.5,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "TCNU3210789"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "TCNU3210789",
                    "numberOfPackages": 1,
                    "grossWeight": 22817.5
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 4,
                "description": "LIGNIN",
                "grossWeight": 22837.5,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "CSNU6252078"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "CSNU6252078",
                    "numberOfPackages": 1,
                    "grossWeight": 22837.5
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 5,
                "description": "LIGNIN",
                "grossWeight": 22917.5,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "CCLU7776770"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "CCLU7776770",
                    "numberOfPackages": 1,
                    "grossWeight": 22917.5
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "UFEE6221065A",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": "SEA_IN_SEA_OUT",
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "GREENSHIP NORWAY AS",
              "address": "ALFBJERCKES VEI 10",
              "zipCode": "N-0582",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "EVERGREEN SHIPPING AGENCY (EUROPE)",
              "address": "PORTCITY II HAVENNUMMER 2235",
              "zipCode": "3089 JH",
              "city": "ROTTERDAM",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euLocation": false
            },
            "placeOfDestination": {
              "name": "Kaohsiung",
              "locationUnCode": "TWKHH",
              "countryUnCode": "TW",
              "euLocation": false
            },
            "placeOfDelivery": null,
            "onCarriageDetails": null,
            "agentVoyageNumber": "00617300D",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "CELLULOSE",
                "grossWeight": 24000,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "EITU1680333"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "EITU1680333",
                    "numberOfPackages": 1,
                    "grossWeight": 24000
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 2,
                "description": "CELLULOSE",
                "grossWeight": 24000,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "EISU8520992"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "EISU8520992",
                    "numberOfPackages": 1,
                    "grossWeight": 24000
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 3,
                "description": "CELLULOSE",
                "grossWeight": 24000,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "EGHU9715271"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "EGHU9715271",
                    "numberOfPackages": 1,
                    "grossWeight": 24000
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 4,
                "description": "CELLULOSE",
                "grossWeight": 24000,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "TGBU5046859"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "TGBU5046859",
                    "numberOfPackages": 1,
                    "grossWeight": 24000
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "UFEE6222913A",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "4810",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "CMA-CGM NORWAY",
              "address": "HOFFSVEIEN 1A",
              "zipCode": "0275",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "CMA (HOLLAND) B.V.",
              "address": "RIVIERWEG 1",
              "zipCode": "3161",
              "city": "RHOON",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euLocation": false
            },
            "placeOfDestination": null,
            "placeOfDelivery": null,
            "onCarriageDetails": null,
            "agentVoyageNumber": "00617300D",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "POLYETHYLENE WITH A SPECIFIC G",
                "grossWeight": 17500,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "BG",
                  "name": "Bags",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "CMAU3015863"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "CMAU3015863",
                    "numberOfPackages": 1,
                    "grossWeight": 17500
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "UFEE6233662A",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": "SEA_IN_SEA_OUT",
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "GREENSHIP NORWAY AS",
              "address": "ALFBJERCKES VEI 10",
              "zipCode": "N-0582",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "EVERGREEN SHIPPING AGENCY (EUROPE)",
              "address": "PORTCITY II HAVENNUMMER 2235",
              "zipCode": "3089 JH",
              "city": "ROTTERDAM",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euLocation": false
            },
            "placeOfDestination": {
              "name": "Jakarta, Java",
              "locationUnCode": "IDJKT",
              "countryUnCode": "ID",
              "euLocation": false
            },
            "placeOfDelivery": null,
            "onCarriageDetails": null,
            "agentVoyageNumber": "00617300D",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "LIGNIN",
                "grossWeight": 25104,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "TLLU5417185"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "TLLU5417185",
                    "numberOfPackages": 1,
                    "grossWeight": 25104
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "UFEE6237971A",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": "SEA_IN_SEA_OUT",
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "GREENSHIP NORWAY AS",
              "address": "ALFBJERCKES VEI 10",
              "zipCode": "N-0582",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "EVERGREEN SHIPPING AGENCY (EUROPE)",
              "address": "PORTCITY II HAVENNUMMER 2235",
              "zipCode": "3089 JH",
              "city": "ROTTERDAM",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euLocation": false
            },
            "placeOfDestination": {
              "name": "Laem Chabang",
              "locationUnCode": "THLCH",
              "countryUnCode": "TH",
              "euLocation": false
            },
            "placeOfDelivery": null,
            "onCarriageDetails": null,
            "agentVoyageNumber": "00617300D",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "LIGNIN",
                "grossWeight": 23017.5,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "EISU8511429"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "EISU8511429",
                    "numberOfPackages": 1,
                    "grossWeight": 23017.5
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 2,
                "description": "LIGNIN",
                "grossWeight": 23017.5,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "EGHU9608653"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "EGHU9608653",
                    "numberOfPackages": 1,
                    "grossWeight": 23017.5
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "UFEE6239742A",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": "SEA_IN_SEA_OUT",
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "GREENSHIP NORWAY AS",
              "address": "ALFBJERCKES VEI 10",
              "zipCode": "N-0582",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "EVERGREEN SHIPPING AGENCY (EUROPE)",
              "address": "PORTCITY II HAVENNUMMER 2235",
              "zipCode": "3089 JH",
              "city": "ROTTERDAM",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euLocation": false
            },
            "placeOfDestination": {
              "name": "Lianyungang",
              "locationUnCode": "CNLYG",
              "countryUnCode": "CN",
              "euLocation": false
            },
            "placeOfDelivery": null,
            "onCarriageDetails": null,
            "agentVoyageNumber": "00617300D",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "LIGNIN",
                "grossWeight": 23017.5,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "TGCU5081215"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "TGCU5081215",
                    "numberOfPackages": 1,
                    "grossWeight": 23017.5
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "UFEE6242520A",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": "SEA_IN_SEA_OUT",
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "GREENSHIP NORWAY AS",
              "address": "ALFBJERCKES VEI 10",
              "zipCode": "N-0582",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "EVERGREEN SHIPPING AGENCY (EUROPE)",
              "address": "PORTCITY II HAVENNUMMER 2235",
              "zipCode": "3089 JH",
              "city": "ROTTERDAM",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euLocation": false
            },
            "placeOfDestination": {
              "name": "Poti",
              "locationUnCode": "GEPTI",
              "countryUnCode": "GE",
              "euLocation": false
            },
            "placeOfDelivery": null,
            "onCarriageDetails": null,
            "agentVoyageNumber": "00617300D",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "LIGNIN",
                "grossWeight": 25056,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "TGBU5059434"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "TGBU5059434",
                    "numberOfPackages": 1,
                    "grossWeight": 25056
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 2,
                "description": "LIGNIN",
                "grossWeight": 25056,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "EISU8295588"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "EISU8295588",
                    "numberOfPackages": 1,
                    "grossWeight": 25056
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "UFEE6258569A",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": "SEA_IN_SEA_OUT",
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "CMA-CGM NORWAY",
              "address": "HOFFSVEIEN 1A",
              "zipCode": "0275",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "CMA (HOLLAND) B.V.",
              "address": "RIVIERWEG 1",
              "zipCode": "3161",
              "city": "RHOON",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euLocation": false
            },
            "placeOfDestination": {
              "name": "Norfolk",
              "locationUnCode": "USORF",
              "countryUnCode": "US",
              "euLocation": false
            },
            "placeOfDelivery": null,
            "onCarriageDetails": null,
            "agentVoyageNumber": "00617300D",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "RESIDUAL LYES FROM THE MANUFAC",
                "grossWeight": 24000,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "LT",
                  "name": "Lot",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "BEAU4026340"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "BEAU4026340",
                    "numberOfPackages": 1,
                    "grossWeight": 24000
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 2,
                "description": "RESIDUAL LYES FROM THE MANUFAC",
                "grossWeight": 24000,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "LT",
                  "name": "Lot",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "TCNU5842028"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "TCNU5842028",
                    "numberOfPackages": 1,
                    "grossWeight": 24000
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 3,
                "description": "RESIDUAL LYES FROM THE MANUFAC",
                "grossWeight": 24000,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "LT",
                  "name": "Lot",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "SEGU5048752"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "SEGU5048752",
                    "numberOfPackages": 1,
                    "grossWeight": 24000
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 4,
                "description": "RESIDUAL LYES FROM THE MANUFAC",
                "grossWeight": 24000,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "LT",
                  "name": "Lot",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "CAIU7413522"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "CAIU7413522",
                    "numberOfPackages": 1,
                    "grossWeight": 24000
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "UFEE6258866A",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": "SEA_IN_SEA_OUT",
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "GREENSHIP NORWAY AS",
              "address": "ALFBJERCKES VEI 10",
              "zipCode": "N-0582",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "EVERGREEN SHIPPING AGENCY (EUROPE)",
              "address": "PORTCITY II HAVENNUMMER 2235",
              "zipCode": "3089 JH",
              "city": "ROTTERDAM",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euLocation": false
            },
            "placeOfDestination": {
              "name": "Xinfeng",
              "locationUnCode": "CNXAB",
              "countryUnCode": "CN",
              "euLocation": false
            },
            "placeOfDelivery": null,
            "onCarriageDetails": null,
            "agentVoyageNumber": "00617300D",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "LIGNIN",
                "grossWeight": 23017.5,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "TRHU7472440"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "TRHU7472440",
                    "numberOfPackages": 1,
                    "grossWeight": 23017.5
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 2,
                "description": "LIGNIN",
                "grossWeight": 23017.5,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "EGHU9611430"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "EGHU9611430",
                    "numberOfPackages": 1,
                    "grossWeight": 23017.5
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 3,
                "description": "LIGNIN",
                "grossWeight": 23017.5,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "TRHU7466387"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "TRHU7466387",
                    "numberOfPackages": 1,
                    "grossWeight": 23017.5
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "UFEE6260038A",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "4810",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": "SEA_IN_SEA_OUT",
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "HEVOLD SHIPPING AS",
              "address": "GROENLANDSLEIRET 31",
              "zipCode": "0190",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "HAPAG LLOYD NEDERLAND BV",
              "address": "WAALHAVEN O.Z. 79",
              "zipCode": "3087BM",
              "city": "ROTTERDAM",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euLocation": false
            },
            "placeOfDestination": {
              "name": "Singapore",
              "locationUnCode": "SGSIN",
              "countryUnCode": "SG",
              "euLocation": false
            },
            "placeOfDelivery": null,
            "onCarriageDetails": null,
            "agentVoyageNumber": "00617300D",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "DIPCAL 25KG",
                "grossWeight": 24456,
                "numberOfOuterPackages": 960,
                "outerPackageType": {
                  "code": "BG",
                  "name": "Bags",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "CRSU1193066"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "CRSU1193066",
                    "numberOfPackages": 960,
                    "grossWeight": 24456
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 2,
                "description": "DIPCAL 25KG",
                "grossWeight": 24456,
                "numberOfOuterPackages": 960,
                "outerPackageType": {
                  "code": "BG",
                  "name": "Bags",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "FCIU2945135"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "FCIU2945135",
                    "numberOfPackages": 960,
                    "grossWeight": 24456
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 3,
                "description": "DIPCAL 25KG",
                "grossWeight": 24456,
                "numberOfOuterPackages": 960,
                "outerPackageType": {
                  "code": "BG",
                  "name": "Bags",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "HLXU3538115"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "HLXU3538115",
                    "numberOfPackages": 960,
                    "grossWeight": 24456
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 4,
                "description": "DIPCAL 25KG",
                "grossWeight": 24456,
                "numberOfOuterPackages": 960,
                "outerPackageType": {
                  "code": "BG",
                  "name": "Bags",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "HLXU1178323"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "HLXU1178323",
                    "numberOfPackages": 960,
                    "grossWeight": 24456
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "UFEE6260161A",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "4810",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": "SEA_IN_SEA_OUT",
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "HEVOLD SHIPPING AS",
              "address": "GROENLANDSLEIRET 31",
              "zipCode": "0190",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "HAPAG LLOYD NEDERLAND BV",
              "address": "WAALHAVEN O.Z. 79",
              "zipCode": "3087BM",
              "city": "ROTTERDAM",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euLocation": false
            },
            "placeOfDestination": {
              "name": "Singapore",
              "locationUnCode": "SGSIN",
              "countryUnCode": "SG",
              "euLocation": false
            },
            "placeOfDelivery": null,
            "onCarriageDetails": null,
            "agentVoyageNumber": "00617300D",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "DIPCAL 25KG",
                "grossWeight": 24456,
                "numberOfOuterPackages": 960,
                "outerPackageType": {
                  "code": "BG",
                  "name": "Bags",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "BHCU3032411"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "BHCU3032411",
                    "numberOfPackages": 960,
                    "grossWeight": 24456
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 2,
                "description": "DIPCAL 25KG",
                "grossWeight": 24456,
                "numberOfOuterPackages": 960,
                "outerPackageType": {
                  "code": "BG",
                  "name": "Bags",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "FCIU6559953"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "FCIU6559953",
                    "numberOfPackages": 960,
                    "grossWeight": 24456
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 3,
                "description": "DIPCAL 25KG",
                "grossWeight": 24456,
                "numberOfOuterPackages": 960,
                "outerPackageType": {
                  "code": "BG",
                  "name": "Bags",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "GLDU5724381"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "GLDU5724381",
                    "numberOfPackages": 960,
                    "grossWeight": 24456
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 4,
                "description": "DIPCAL 25KG",
                "grossWeight": 24456,
                "numberOfOuterPackages": 960,
                "outerPackageType": {
                  "code": "BG",
                  "name": "Bags",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "TCKU2765812"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "TCKU2765812",
                    "numberOfPackages": 960,
                    "grossWeight": 24456
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "UFEE6260163A",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "4810",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": "SEA_IN_SEA_OUT",
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "HEVOLD SHIPPING AS",
              "address": "GROENLANDSLEIRET 31",
              "zipCode": "0190",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "HAPAG LLOYD NEDERLAND BV",
              "address": "WAALHAVEN O.Z. 79",
              "zipCode": "3087BM",
              "city": "ROTTERDAM",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euLocation": false
            },
            "placeOfDestination": {
              "name": "Singapore",
              "locationUnCode": "SGSIN",
              "countryUnCode": "SG",
              "euLocation": false
            },
            "placeOfDelivery": null,
            "onCarriageDetails": null,
            "agentVoyageNumber": "00617300D",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "DIPCAL 25KG",
                "grossWeight": 24456,
                "numberOfOuterPackages": 960,
                "outerPackageType": {
                  "code": "BG",
                  "name": "Bags",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "HLBU3006650"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "HLBU3006650",
                    "numberOfPackages": 960,
                    "grossWeight": 24456
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 2,
                "description": "DIPCAL 25KG",
                "grossWeight": 24456,
                "numberOfOuterPackages": 960,
                "outerPackageType": {
                  "code": "BG",
                  "name": "Bags",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "TGHU0639370"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "TGHU0639370",
                    "numberOfPackages": 960,
                    "grossWeight": 24456
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 3,
                "description": "DIPCAL 25KG",
                "grossWeight": 24456,
                "numberOfOuterPackages": 960,
                "outerPackageType": {
                  "code": "BG",
                  "name": "Bags",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "BMOU2900554"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "BMOU2900554",
                    "numberOfPackages": 960,
                    "grossWeight": 24456
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 4,
                "description": "DIPCAL 25KG",
                "grossWeight": 24456,
                "numberOfOuterPackages": 960,
                "outerPackageType": {
                  "code": "BG",
                  "name": "Bags",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "TGHU0960945"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "TGHU0960945",
                    "numberOfPackages": 960,
                    "grossWeight": 24456
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "UFEE6260984A",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": "SEA_IN_SEA_OUT",
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "GREENSHIP NORWAY AS",
              "address": "ALFBJERCKES VEI 10",
              "zipCode": "N-0582",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "EVERGREEN SHIPPING AGENCY (EUROPE)",
              "address": "PORTCITY II HAVENNUMMER 2235",
              "zipCode": "3089 JH",
              "city": "ROTTERDAM",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euLocation": false
            },
            "placeOfDestination": {
              "name": "Hong Kong",
              "locationUnCode": "HKHKG",
              "countryUnCode": "HK",
              "euLocation": false
            },
            "placeOfDelivery": null,
            "onCarriageDetails": null,
            "agentVoyageNumber": "00617300D",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "LIGNIN",
                "grossWeight": 23017.5,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "EGHU9788961"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "EGHU9788961",
                    "numberOfPackages": 1,
                    "grossWeight": 23017.5
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "UFEE6261364A",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": "SEA_IN_SEA_OUT",
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "GREENSHIP NORWAY AS",
              "address": "ALFBJERCKES VEI 10",
              "zipCode": "N-0582",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "EVERGREEN SHIPPING AGENCY (EUROPE)",
              "address": "PORTCITY II HAVENNUMMER 2235",
              "zipCode": "3089 JH",
              "city": "ROTTERDAM",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euLocation": false
            },
            "placeOfDestination": {
              "name": "Ho Chi Minh City",
              "locationUnCode": "VNSGN",
              "countryUnCode": "VN",
              "euLocation": false
            },
            "placeOfDelivery": null,
            "onCarriageDetails": null,
            "agentVoyageNumber": "00617300D",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "PAPER AND PAPERBOARD",
                "grossWeight": 24680,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "TCNU4999970"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "TCNU4999970",
                    "numberOfPackages": 1,
                    "grossWeight": 24680
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "UFEE6262328A",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": "SEA_IN_SEA_OUT",
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "CMA-CGM NORWAY",
              "address": "HOFFSVEIEN 1A",
              "zipCode": "0275",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "CMA (HOLLAND) B.V.",
              "address": "RIVIERWEG 1",
              "zipCode": "3161",
              "city": "RHOON",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euLocation": false
            },
            "placeOfDestination": {
              "name": "Norfolk",
              "locationUnCode": "USORF",
              "countryUnCode": "US",
              "euLocation": false
            },
            "placeOfDelivery": null,
            "onCarriageDetails": null,
            "agentVoyageNumber": "00617300D",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "RESIDUAL LYES FROM THE MANUFAC",
                "grossWeight": 22500,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "LT",
                  "name": "Lot",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "UETU5531655"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "UETU5531655",
                    "numberOfPackages": 1,
                    "grossWeight": 22500
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 2,
                "description": "RESIDUAL LYES FROM THE MANUFAC",
                "grossWeight": 22500,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "LT",
                  "name": "Lot",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "CRSU9217159"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "CRSU9217159",
                    "numberOfPackages": 1,
                    "grossWeight": 22500
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "UFEE6263569A",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": "SEA_IN_SEA_OUT",
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "CMA-CGM NORWAY",
              "address": "HOFFSVEIEN 1A",
              "zipCode": "0275",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "CMA (HOLLAND) B.V.",
              "address": "RIVIERWEG 1",
              "zipCode": "3161",
              "city": "RHOON",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euLocation": false
            },
            "placeOfDestination": {
              "name": "Shanghai Hongqiao International Apt",
              "locationUnCode": "CNSHA",
              "countryUnCode": "CN",
              "euLocation": false
            },
            "placeOfDelivery": null,
            "onCarriageDetails": null,
            "agentVoyageNumber": "00617300D",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "POLYETHYLENE WITH A SPECIFIC G",
                "grossWeight": 25368.76,
                "numberOfOuterPackages": 990,
                "outerPackageType": {
                  "code": "BG",
                  "name": "Bags",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "TCLU4429115"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "TCLU4429115",
                    "numberOfPackages": 990,
                    "grossWeight": 25368.76
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 2,
                "description": "POLYETHYLENE WITH A SPECIFIC G",
                "grossWeight": 25368.76,
                "numberOfOuterPackages": 990,
                "outerPackageType": {
                  "code": "BG",
                  "name": "Bags",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "DFSU4283746"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "DFSU4283746",
                    "numberOfPackages": 990,
                    "grossWeight": 25368.76
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 3,
                "description": "POLYETHYLENE WITH A SPECIFIC G",
                "grossWeight": 25368.76,
                "numberOfOuterPackages": 990,
                "outerPackageType": {
                  "code": "BG",
                  "name": "Bags",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "APZU4586394"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "APZU4586394",
                    "numberOfPackages": 990,
                    "grossWeight": 25368.76
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 4,
                "description": "POLYETHYLENE WITH A SPECIFIC G",
                "grossWeight": 25368.76,
                "numberOfOuterPackages": 990,
                "outerPackageType": {
                  "code": "BG",
                  "name": "Bags",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "TGHU5168416"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "TGHU5168416",
                    "numberOfPackages": 990,
                    "grossWeight": 25368.76
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 5,
                "description": "POLYETHYLENE WITH A SPECIFIC G",
                "grossWeight": 25368.76,
                "numberOfOuterPackages": 990,
                "outerPackageType": {
                  "code": "BG",
                  "name": "Bags",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "APZU4659572"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "APZU4659572",
                    "numberOfPackages": 990,
                    "grossWeight": 25368.76
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "UFEE6265442A",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "4810",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": "SEA_IN_SEA_OUT",
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "MSC NORWAY A/S",
              "address": "THUNES VEI 2",
              "zipCode": "0274",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "MSC NEDERLAND BV",
              "address": "WESTBLAAK 218",
              "zipCode": "3012 KP",
              "city": "ROTTERDAM",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euLocation": false
            },
            "placeOfDestination": {
              "name": "Ashdod",
              "locationUnCode": "ILASH",
              "countryUnCode": "IL",
              "euLocation": false
            },
            "placeOfDelivery": null,
            "onCarriageDetails": null,
            "agentVoyageNumber": "00617300D",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "FROZEN FISH",
                "grossWeight": 28000,
                "numberOfOuterPackages": 1356,
                "outerPackageType": {
                  "code": "CT",
                  "name": "Carton",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "TTNU8339560"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "TTNU8339560",
                    "numberOfPackages": 1356,
                    "grossWeight": 28000
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "UFEE6271262A",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "4810",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": "SEA_IN_SEA_OUT",
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "HEVOLD SHIPPING AS",
              "address": "GROENLANDSLEIRET 31",
              "zipCode": "0190",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "HAPAG LLOYD NEDERLAND BV",
              "address": "WAALHAVEN O.Z. 79",
              "zipCode": "3087BM",
              "city": "ROTTERDAM",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euLocation": false
            },
            "placeOfDestination": {
              "name": "Singapore",
              "locationUnCode": "SGSIN",
              "countryUnCode": "SG",
              "euLocation": false
            },
            "placeOfDelivery": null,
            "onCarriageDetails": null,
            "agentVoyageNumber": "00617300D",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "CHEMICALS/FERTILIZERS-NON HAZ",
                "grossWeight": 24456,
                "numberOfOuterPackages": 960,
                "outerPackageType": {
                  "code": "BG",
                  "name": "Bags",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "HLXU1266532"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "HLXU1266532",
                    "numberOfPackages": 960,
                    "grossWeight": 24456
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 2,
                "description": "CHEMICALS/FERTILIZERS-NON HAZ",
                "grossWeight": 24456,
                "numberOfOuterPackages": 960,
                "outerPackageType": {
                  "code": "BG",
                  "name": "Bags",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "UACU3375991"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "UACU3375991",
                    "numberOfPackages": 960,
                    "grossWeight": 24456
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "UFEE6274240A",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": "SEA_IN_SEA_OUT",
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "GREENSHIP NORWAY AS",
              "address": "ALFBJERCKES VEI 10",
              "zipCode": "N-0582",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "EVERGREEN SHIPPING AGENCY (EUROPE)",
              "address": "PORTCITY II HAVENNUMMER 2235",
              "zipCode": "3089 JH",
              "city": "ROTTERDAM",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euLocation": false
            },
            "placeOfDestination": {
              "name": "Port Klang (Pelabuhan Klang)",
              "locationUnCode": "MYPKG",
              "countryUnCode": "MY",
              "euLocation": false
            },
            "placeOfDelivery": null,
            "onCarriageDetails": null,
            "agentVoyageNumber": "00617300D",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "WASTE PAPER",
                "grossWeight": 24500,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "TCNU2139958"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "TCNU2139958",
                    "numberOfPackages": 1,
                    "grossWeight": 24500
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 2,
                "description": "WASTE PAPER",
                "grossWeight": 24000,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "EGHU9110125"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "EGHU9110125",
                    "numberOfPackages": 1,
                    "grossWeight": 24000
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 3,
                "description": "WASTE PAPER",
                "grossWeight": 25440,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "EITU9236304"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "EITU9236304",
                    "numberOfPackages": 1,
                    "grossWeight": 25440
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 4,
                "description": "WASTE PAPER",
                "grossWeight": 25100,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "GAOU6416589"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "GAOU6416589",
                    "numberOfPackages": 1,
                    "grossWeight": 25100
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 5,
                "description": "WASTE PAPER",
                "grossWeight": 26500,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "EITU9395760"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "EITU9395760",
                    "numberOfPackages": 1,
                    "grossWeight": 26500
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 6,
                "description": "WASTE PAPER",
                "grossWeight": 25200,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "TXGU5587007"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "TXGU5587007",
                    "numberOfPackages": 1,
                    "grossWeight": 25200
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 7,
                "description": "WASTE PAPER",
                "grossWeight": 26160,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "EGHU9666813"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "EGHU9666813",
                    "numberOfPackages": 1,
                    "grossWeight": 26160
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 8,
                "description": "WASTE PAPER",
                "grossWeight": 25960,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "DRYU9428245"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "DRYU9428245",
                    "numberOfPackages": 1,
                    "grossWeight": 25960
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 9,
                "description": "WASTE PAPER",
                "grossWeight": 26500,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "EITU1491474"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "EITU1491474",
                    "numberOfPackages": 1,
                    "grossWeight": 26500
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 10,
                "description": "WASTE PAPER",
                "grossWeight": 26400,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "EISU9428237"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "EISU9428237",
                    "numberOfPackages": 1,
                    "grossWeight": 26400
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "UFEE6274613A",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": "SEA_IN_SEA_OUT",
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "COSCO SHIPPING LINES (NORWAY) A/S",
              "address": "POSTBOKS 1006, SENTRUM",
              "zipCode": "0104",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "COSCO SHIPPING LINES (NETHERLANDS)",
              "address": "PO BOX 362",
              "zipCode": "3000 AJ",
              "city": "ROTTERDAM",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euLocation": false
            },
            "placeOfDestination": {
              "name": "Ningbo Lishe International Apt",
              "locationUnCode": "CNNGB",
              "countryUnCode": "CN",
              "euLocation": false
            },
            "placeOfDelivery": null,
            "onCarriageDetails": null,
            "agentVoyageNumber": "00617300D",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "FISH",
                "grossWeight": 23290,
                "numberOfOuterPackages": 936,
                "outerPackageType": {
                  "code": "UN",
                  "name": "Unit",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "TEMU9125848"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "TEMU9125848",
                    "numberOfPackages": 936,
                    "grossWeight": 23290
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "UFEE6274779A",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": "SEA_IN_SEA_OUT",
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "COSCO SHIPPING LINES (NORWAY) A/S",
              "address": "POSTBOKS 1006, SENTRUM",
              "zipCode": "0104",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "COSCO SHIPPING LINES (NETHERLANDS)",
              "address": "PO BOX 362",
              "zipCode": "3000 AJ",
              "city": "ROTTERDAM",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euLocation": false
            },
            "placeOfDestination": {
              "name": "Ningbo Lishe International Apt",
              "locationUnCode": "CNNGB",
              "countryUnCode": "CN",
              "euLocation": false
            },
            "placeOfDelivery": null,
            "onCarriageDetails": null,
            "agentVoyageNumber": "00617300D",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "FISH",
                "grossWeight": 22855,
                "numberOfOuterPackages": 936,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "OOLU6089121"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "OOLU6089121",
                    "numberOfPackages": 936,
                    "grossWeight": 22855
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "UFEE6280368A",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "4810",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": "SEA_IN_SEA_OUT",
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "HEVOLD SHIPPING AS",
              "address": "GROENLANDSLEIRET 31",
              "zipCode": "0190",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "HAPAG LLOYD NEDERLAND BV",
              "address": "WAALHAVEN O.Z. 79",
              "zipCode": "3087BM",
              "city": "ROTTERDAM",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euLocation": false
            },
            "placeOfDestination": {
              "name": "New York",
              "locationUnCode": "USNYC",
              "countryUnCode": "US",
              "euLocation": false
            },
            "placeOfDelivery": null,
            "onCarriageDetails": null,
            "agentVoyageNumber": "00617300D",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "EUROVANILLIN SUPREME FC",
                "grossWeight": 18588.13,
                "numberOfOuterPackages": 46,
                "outerPackageType": {
                  "code": "BG",
                  "name": "Bags",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "HLXU5215430"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "HLXU5215430",
                    "numberOfPackages": 46,
                    "grossWeight": 18588.13
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "UFEE6280370A",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "4810",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": "SEA_IN_SEA_OUT",
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "HEVOLD SHIPPING AS",
              "address": "GROENLANDSLEIRET 31",
              "zipCode": "0190",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "HAPAG LLOYD NEDERLAND BV",
              "address": "WAALHAVEN O.Z. 79",
              "zipCode": "3087BM",
              "city": "ROTTERDAM",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euLocation": false
            },
            "placeOfDestination": {
              "name": "New York",
              "locationUnCode": "USNYC",
              "countryUnCode": "US",
              "euLocation": false
            },
            "placeOfDelivery": null,
            "onCarriageDetails": null,
            "agentVoyageNumber": "00617300D",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "EUROVANILLIN SUPREME FC",
                "grossWeight": 18624.92,
                "numberOfOuterPackages": 46,
                "outerPackageType": {
                  "code": "BG",
                  "name": "Bags",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "TCLU9383892"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "TCLU9383892",
                    "numberOfPackages": 46,
                    "grossWeight": 18624.92
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "UFEE6280399A",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "4810",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": "SEA_IN_SEA_OUT",
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "HEVOLD SHIPPING AS",
              "address": "GROENLANDSLEIRET 31",
              "zipCode": "0190",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "HAPAG LLOYD NEDERLAND BV",
              "address": "WAALHAVEN O.Z. 79",
              "zipCode": "3087BM",
              "city": "ROTTERDAM",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euLocation": false
            },
            "placeOfDestination": {
              "name": "New York",
              "locationUnCode": "USNYC",
              "countryUnCode": "US",
              "euLocation": false
            },
            "placeOfDelivery": null,
            "onCarriageDetails": null,
            "agentVoyageNumber": "00617300D",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "EUROVANILLIN SUPREME FC",
                "grossWeight": 18653.54,
                "numberOfOuterPackages": 46,
                "outerPackageType": {
                  "code": "BG",
                  "name": "Bags",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "UACU8252747"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "UACU8252747",
                    "numberOfPackages": 46,
                    "grossWeight": 18653.54
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "UFEE6280672A",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "4810",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": "SEA_IN_SEA_OUT",
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "MSC NORWAY A/S",
              "address": "THUNES VEI 2",
              "zipCode": "0274",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "MSC NEDERLAND BV",
              "address": "WESTBLAAK 218",
              "zipCode": "3012 KP",
              "city": "ROTTERDAM",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euLocation": false
            },
            "placeOfDestination": {
              "name": "Port Said West",
              "locationUnCode": "EGPSW",
              "countryUnCode": "EG",
              "euLocation": false
            },
            "placeOfDelivery": null,
            "onCarriageDetails": null,
            "agentVoyageNumber": "00617300D",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "FISH, FROZEN, EXCLUDING FISH FILLETS AND O",
                "grossWeight": 25000,
                "numberOfOuterPackages": 946,
                "outerPackageType": {
                  "code": "CT",
                  "name": "Carton",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "BMOU9612457"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "BMOU9612457",
                    "numberOfPackages": 946,
                    "grossWeight": 25000
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "UFEE6282042A",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": "SEA_IN_SEA_OUT",
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "GREENSHIP NORWAY AS",
              "address": "ALFBJERCKES VEI 10",
              "zipCode": "N-0582",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "EVERGREEN SHIPPING AGENCY (EUROPE)",
              "address": "PORTCITY II HAVENNUMMER 2235",
              "zipCode": "3089 JH",
              "city": "ROTTERDAM",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euLocation": false
            },
            "placeOfDestination": {
              "name": "Ningbo Lishe International Apt",
              "locationUnCode": "CNNGB",
              "countryUnCode": "CN",
              "euLocation": false
            },
            "placeOfDelivery": null,
            "onCarriageDetails": null,
            "agentVoyageNumber": "00617300D",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "LIGNIN",
                "grossWeight": 23017.5,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "EISU9132688"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "EISU9132688",
                    "numberOfPackages": 1,
                    "grossWeight": 23017.5
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 2,
                "description": "LIGNIN",
                "grossWeight": 23017.5,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "TCNU3369106"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "TCNU3369106",
                    "numberOfPackages": 1,
                    "grossWeight": 23017.5
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "UFEE6282106A",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": "SEA_IN_SEA_OUT",
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "GREENSHIP NORWAY AS",
              "address": "ALFBJERCKES VEI 10",
              "zipCode": "N-0582",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "EVERGREEN SHIPPING AGENCY (EUROPE)",
              "address": "PORTCITY II HAVENNUMMER 2235",
              "zipCode": "3089 JH",
              "city": "ROTTERDAM",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euLocation": false
            },
            "placeOfDestination": {
              "name": "Ho Chi Minh City",
              "locationUnCode": "VNSGN",
              "countryUnCode": "VN",
              "euLocation": false
            },
            "placeOfDelivery": null,
            "onCarriageDetails": null,
            "agentVoyageNumber": "00617300D",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "CHEMICALS, NON-HAZ AND VACUUMS",
                "grossWeight": 6309,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "EMCU3911203"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "EMCU3911203",
                    "numberOfPackages": 1,
                    "grossWeight": 6309
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "UFEE6282569A",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": "SEA_IN_SEA_OUT",
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "GREENSHIP NORWAY AS",
              "address": "ALFBJERCKES VEI 10",
              "zipCode": "N-0582",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "EVERGREEN SHIPPING AGENCY (EUROPE)",
              "address": "PORTCITY II HAVENNUMMER 2235",
              "zipCode": "3089 JH",
              "city": "ROTTERDAM",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euLocation": false
            },
            "placeOfDestination": {
              "name": "Nhava Sheva (Jawaharlal Nehru)",
              "locationUnCode": "INNSA",
              "countryUnCode": "IN",
              "euLocation": false
            },
            "placeOfDelivery": null,
            "onCarriageDetails": null,
            "agentVoyageNumber": "00617300D",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "STAINLESS STEEL SCRAP ZURICK AS PR ISRI",
                "grossWeight": 27650,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "TGHU1467933"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "TGHU1467933",
                    "numberOfPackages": 1,
                    "grossWeight": 27650
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 2,
                "description": "STAINLESS STEEL SCRAP ZURICK AS PR ISRI",
                "grossWeight": 27660,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "EGSU3044565"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "EGSU3044565",
                    "numberOfPackages": 1,
                    "grossWeight": 27660
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 3,
                "description": "STAINLESS STEEL SCRAP ZURICK AS PR ISRI",
                "grossWeight": 27700,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "EGHU3542570"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "EGHU3542570",
                    "numberOfPackages": 1,
                    "grossWeight": 27700
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 4,
                "description": "STAINLESS STEEL SCRAP ZURICK AS PR ISRI",
                "grossWeight": 27685,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "EISU2194760"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "EISU2194760",
                    "numberOfPackages": 1,
                    "grossWeight": 27685
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "UFEE6283876A",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": "SEA_IN_SEA_OUT",
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "GREENSHIP NORWAY AS",
              "address": "ALFBJERCKES VEI 10",
              "zipCode": "N-0582",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "EVERGREEN SHIPPING AGENCY (EUROPE)",
              "address": "PORTCITY II HAVENNUMMER 2235",
              "zipCode": "3089 JH",
              "city": "ROTTERDAM",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euLocation": false
            },
            "placeOfDestination": {
              "name": "Shanghai Hongqiao International Apt",
              "locationUnCode": "CNSHA",
              "countryUnCode": "CN",
              "euLocation": false
            },
            "placeOfDelivery": null,
            "onCarriageDetails": null,
            "agentVoyageNumber": "00617300D",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "LIGNIN",
                "grossWeight": 23017.5,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "EGHU9847175"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "EGHU9847175",
                    "numberOfPackages": 1,
                    "grossWeight": 23017.5
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "UFEE6284765A",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "TANK MANAGEMENT AS",
              "address": "BEKKELAGSKAIA 2",
              "zipCode": "01934",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "TANK MANAGEMENT AS",
              "address": "BEKKELAGSKAIA 2",
              "zipCode": "01934",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euLocation": false
            },
            "placeOfDestination": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euLocation": true
            },
            "placeOfDelivery": null,
            "onCarriageDetails": null,
            "agentVoyageNumber": "00617300D",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "EMPTY CONTAINER",
                "grossWeight": 1,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "VODU9251246"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "VODU9251246",
                    "numberOfPackages": 1,
                    "grossWeight": 1
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "UFEE6285314A",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": "SEA_IN_SEA_OUT",
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "GREENSHIP NORWAY AS",
              "address": "ALFBJERCKES VEI 10",
              "zipCode": "N-0582",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "EVERGREEN SHIPPING AGENCY (EUROPE)",
              "address": "PORTCITY II HAVENNUMMER 2235",
              "zipCode": "3089 JH",
              "city": "ROTTERDAM",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euLocation": false
            },
            "placeOfDestination": {
              "name": "New York",
              "locationUnCode": "USNYC",
              "countryUnCode": "US",
              "euLocation": false
            },
            "placeOfDelivery": null,
            "onCarriageDetails": null,
            "agentVoyageNumber": "00617300D",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "HOSES AND WATER TANKS",
                "grossWeight": 5129,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "TCKU6059645"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "TCKU6059645",
                    "numberOfPackages": 1,
                    "grossWeight": 5129
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 2,
                "description": "HOSES AND WATER TANKS",
                "grossWeight": 5329,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "EGHU9249430"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "EGHU9249430",
                    "numberOfPackages": 1,
                    "grossWeight": 5329
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 3,
                "description": "HOSES AND WATER TANKS",
                "grossWeight": 5329,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "BMOU5481019"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "BMOU5481019",
                    "numberOfPackages": 1,
                    "grossWeight": 5329
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 4,
                "description": "HOSES AND WATER TANKS",
                "grossWeight": 4846,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "TGBU9914830"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "TGBU9914830",
                    "numberOfPackages": 1,
                    "grossWeight": 4846
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "UFEE6285359A",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": "SEA_IN_SEA_OUT",
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "GREENSHIP NORWAY AS",
              "address": "ALFBJERCKES VEI 10",
              "zipCode": "N-0582",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "EVERGREEN SHIPPING AGENCY (EUROPE)",
              "address": "PORTCITY II HAVENNUMMER 2235",
              "zipCode": "3089 JH",
              "city": "ROTTERDAM",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euLocation": false
            },
            "placeOfDestination": {
              "name": "Port Klang (Pelabuhan Klang)",
              "locationUnCode": "MYPKG",
              "countryUnCode": "MY",
              "euLocation": false
            },
            "placeOfDelivery": null,
            "onCarriageDetails": null,
            "agentVoyageNumber": "00617300D",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "CHEMICAL, HAZ",
                "grossWeight": 25925,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": {
                  "name": null,
                  "unCode": "3077",
                  "hazardClass": "9",
                  "packingGroup": null,
                  "flashPoint": null
                },
                "marksAndNumbers": [
                  "DRYU2445174"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "DRYU2445174",
                    "numberOfPackages": 1,
                    "grossWeight": 25925
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "UFEE6286398A",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "4810",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": "SEA_IN_SEA_OUT",
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "MSC NORWAY A/S",
              "address": "THUNES VEI 2",
              "zipCode": "0274",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "MSC NEDERLAND BV",
              "address": "WESTBLAAK 218",
              "zipCode": "3012 KP",
              "city": "ROTTERDAM",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euLocation": false
            },
            "placeOfDestination": {
              "name": "Ad Dammam",
              "locationUnCode": "SADMM",
              "countryUnCode": "SA",
              "euLocation": false
            },
            "placeOfDelivery": null,
            "onCarriageDetails": null,
            "agentVoyageNumber": "00617300D",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "FROZEN FISH",
                "grossWeight": 19100,
                "numberOfOuterPackages": 3804,
                "outerPackageType": {
                  "code": "CT",
                  "name": "Carton",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "MEDU9616751"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "MEDU9616751",
                    "numberOfPackages": 3804,
                    "grossWeight": 19100
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "UFEE6288108A",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "4810",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": "SEA_IN_SEA_OUT",
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "MSC NORWAY A/S",
              "address": "THUNES VEI 2",
              "zipCode": "0274",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "MSC NEDERLAND BV",
              "address": "WESTBLAAK 218",
              "zipCode": "3012 KP",
              "city": "ROTTERDAM",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euLocation": false
            },
            "placeOfDestination": {
              "name": "Las Palmas de Gran Canaria",
              "locationUnCode": "ESLPA",
              "countryUnCode": "ES",
              "euLocation": true
            },
            "placeOfDelivery": null,
            "onCarriageDetails": null,
            "agentVoyageNumber": "00617300D",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "FROZEN FISH",
                "grossWeight": 28000,
                "numberOfOuterPackages": 936,
                "outerPackageType": {
                  "code": "CT",
                  "name": "Carton",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "TTNU8453362"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "TTNU8453362",
                    "numberOfPackages": 936,
                    "grossWeight": 28000
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "UFEE6288630A",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "4810",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": "SEA_IN_SEA_OUT",
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "MSC NORWAY A/S",
              "address": "THUNES VEI 2",
              "zipCode": "0274",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "MSC NEDERLAND BV",
              "address": "WESTBLAAK 218",
              "zipCode": "3012 KP",
              "city": "ROTTERDAM",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euLocation": false
            },
            "placeOfDestination": {
              "name": "Nhava Sheva (Jawaharlal Nehru)",
              "locationUnCode": "INNSA",
              "countryUnCode": "IN",
              "euLocation": false
            },
            "placeOfDelivery": null,
            "onCarriageDetails": null,
            "agentVoyageNumber": "00617300D",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "SHREDDED ALUMINIUM SCRAP",
                "grossWeight": 20280,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "TGHU1467980"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "TGHU1467980",
                    "numberOfPackages": 1,
                    "grossWeight": 20280
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 2,
                "description": "SHREDDED ALUMINIUM SCRAP",
                "grossWeight": 21070,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "MEDU6013116"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "MEDU6013116",
                    "numberOfPackages": 1,
                    "grossWeight": 21070
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 3,
                "description": "SHREDDED ALUMINIUM SCRAP",
                "grossWeight": 20290,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "MSDU1009980"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "MSDU1009980",
                    "numberOfPackages": 1,
                    "grossWeight": 20290
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 4,
                "description": "SHREDDED ALUMINIUM SCRAP",
                "grossWeight": 21370,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "CAIU2127927"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "CAIU2127927",
                    "numberOfPackages": 1,
                    "grossWeight": 21370
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 5,
                "description": "SHREDDED ALUMINIUM SCRAP",
                "grossWeight": 21070,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "MEDU5750504"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "MEDU5750504",
                    "numberOfPackages": 1,
                    "grossWeight": 21070
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "UFEE6290569A",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": "SEA_IN_SEA_OUT",
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "COSCO SHIPPING LINES (NORWAY) A/S",
              "address": "POSTBOKS 1006, SENTRUM",
              "zipCode": "0104",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "COSCO SHIPPING LINES (NETHERLANDS)",
              "address": "PO BOX 362",
              "zipCode": "3000 AJ",
              "city": "ROTTERDAM",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euLocation": false
            },
            "placeOfDestination": {
              "name": "Bà Ria",
              "locationUnCode": "VN7BR",
              "countryUnCode": "VN",
              "euLocation": false
            },
            "placeOfDelivery": null,
            "onCarriageDetails": null,
            "agentVoyageNumber": "00617300D",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "FERTILIZERS",
                "grossWeight": 27835,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "CBHU5604577"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "CBHU5604577",
                    "numberOfPackages": 1,
                    "grossWeight": 27835
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 2,
                "description": "FERTILIZERS",
                "grossWeight": 27675,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "CSNU1705931"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "CSNU1705931",
                    "numberOfPackages": 1,
                    "grossWeight": 27675
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 3,
                "description": "FERTILIZERS",
                "grossWeight": 27765,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "CSNU1386177"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "CSNU1386177",
                    "numberOfPackages": 1,
                    "grossWeight": 27765
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 4,
                "description": "FERTILIZERS",
                "grossWeight": 27835,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "CSLU1351867"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "CSLU1351867",
                    "numberOfPackages": 1,
                    "grossWeight": 27835
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 5,
                "description": "FERTILIZERS",
                "grossWeight": 27795,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "DFSU1734466"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "DFSU1734466",
                    "numberOfPackages": 1,
                    "grossWeight": 27795
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 6,
                "description": "FERTILIZERS",
                "grossWeight": 27785,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "BSIU2841077"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "BSIU2841077",
                    "numberOfPackages": 1,
                    "grossWeight": 27785
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 7,
                "description": "FERTILIZERS",
                "grossWeight": 27625,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "CXDU1212509"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "CXDU1212509",
                    "numberOfPackages": 1,
                    "grossWeight": 27625
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 8,
                "description": "FERTILIZERS",
                "grossWeight": 27805,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "UETU2504893"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "UETU2504893",
                    "numberOfPackages": 1,
                    "grossWeight": 27805
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 9,
                "description": "FERTILIZERS",
                "grossWeight": 27885,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "CSNU1933947"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "CSNU1933947",
                    "numberOfPackages": 1,
                    "grossWeight": 27885
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 10,
                "description": "FERTILIZERS",
                "grossWeight": 27845,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "CSLU2246064"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "CSLU2246064",
                    "numberOfPackages": 1,
                    "grossWeight": 27845
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 11,
                "description": "FERTILIZERS",
                "grossWeight": 27725,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "CBHU5546275"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "CBHU5546275",
                    "numberOfPackages": 1,
                    "grossWeight": 27725
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "UFEE6294120A",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": "SEA_IN_SEA_OUT",
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "COSCO SHIPPING LINES (NORWAY) A/S",
              "address": "POSTBOKS 1006, SENTRUM",
              "zipCode": "0104",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "COSCO SHIPPING LINES (NETHERLANDS)",
              "address": "PO BOX 362",
              "zipCode": "3000 AJ",
              "city": "ROTTERDAM",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euLocation": false
            },
            "placeOfDestination": {
              "name": "El Iskandariya (Alexandria)",
              "locationUnCode": "EGALY",
              "countryUnCode": "EG",
              "euLocation": false
            },
            "placeOfDelivery": null,
            "onCarriageDetails": null,
            "agentVoyageNumber": "00617300D",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "PAPER REELS",
                "grossWeight": 26500,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "BSIU9402796"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "BSIU9402796",
                    "numberOfPackages": 1,
                    "grossWeight": 26500
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 2,
                "description": "PAPER REELS",
                "grossWeight": 26500,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "UETU5215338"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "UETU5215338",
                    "numberOfPackages": 1,
                    "grossWeight": 26500
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 3,
                "description": "PAPER REELS",
                "grossWeight": 26500,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "CSNU6959374"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "CSNU6959374",
                    "numberOfPackages": 1,
                    "grossWeight": 26500
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 4,
                "description": "PAPER REELS",
                "grossWeight": 26500,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "OOCU6411524"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "OOCU6411524",
                    "numberOfPackages": 1,
                    "grossWeight": 26500
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 5,
                "description": "PAPER REELS",
                "grossWeight": 26500,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "CBHU7053874"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "CBHU7053874",
                    "numberOfPackages": 1,
                    "grossWeight": 26500
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 6,
                "description": "PAPER REELS",
                "grossWeight": 26500,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "TGBU4923716"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "TGBU4923716",
                    "numberOfPackages": 1,
                    "grossWeight": 26500
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "UFEE6295572A",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": "SEA_IN_SEA_OUT",
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "GREENSHIP NORWAY AS",
              "address": "ALFBJERCKES VEI 10",
              "zipCode": "N-0582",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "EVERGREEN SHIPPING AGENCY (EUROPE)",
              "address": "PORTCITY II HAVENNUMMER 2235",
              "zipCode": "3089 JH",
              "city": "ROTTERDAM",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euLocation": false
            },
            "placeOfDestination": {
              "name": "Surabaya",
              "locationUnCode": "IDSUB",
              "countryUnCode": "ID",
              "euLocation": false
            },
            "placeOfDelivery": null,
            "onCarriageDetails": null,
            "agentVoyageNumber": "00617300D",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "WASTE PAPER",
                "grossWeight": 25300,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "EITU9403253"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "EITU9403253",
                    "numberOfPackages": 1,
                    "grossWeight": 25300
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 2,
                "description": "WASTE PAPER",
                "grossWeight": 24560,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "TGBU4444225"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "TGBU4444225",
                    "numberOfPackages": 1,
                    "grossWeight": 24560
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 3,
                "description": "WASTE PAPER",
                "grossWeight": 25920,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "EGSU9126824"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "EGSU9126824",
                    "numberOfPackages": 1,
                    "grossWeight": 25920
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 4,
                "description": "WASTE PAPER",
                "grossWeight": 24640,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "EITU1141389"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "EITU1141389",
                    "numberOfPackages": 1,
                    "grossWeight": 24640
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 5,
                "description": "WASTE PAPER",
                "grossWeight": 23500,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "EITU1391393"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "EITU1391393",
                    "numberOfPackages": 1,
                    "grossWeight": 23500
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "UFEE6295927A",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "4810",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": "SEA_IN_SEA_OUT",
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "MSC NORWAY A/S",
              "address": "THUNES VEI 2",
              "zipCode": "0274",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "MSC NEDERLAND BV",
              "address": "WESTBLAAK 218",
              "zipCode": "3012 KP",
              "city": "ROTTERDAM",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euLocation": false
            },
            "placeOfDestination": {
              "name": "Ashdod",
              "locationUnCode": "ILASH",
              "countryUnCode": "IL",
              "euLocation": false
            },
            "placeOfDelivery": null,
            "onCarriageDetails": null,
            "agentVoyageNumber": "00617300D",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "FROZEN FISH",
                "grossWeight": 23300,
                "numberOfOuterPackages": 897,
                "outerPackageType": {
                  "code": "CT",
                  "name": "Carton",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "MSCU7388810"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "MSCU7388810",
                    "numberOfPackages": 897,
                    "grossWeight": 23300
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "UFEE6296844A",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": "SEA_IN_SEA_OUT",
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "COSCO SHIPPING LINES (NORWAY) A/S",
              "address": "POSTBOKS 1006, SENTRUM",
              "zipCode": "0104",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "COSCO SHIPPING LINES (NETHERLANDS)",
              "address": "PO BOX 362",
              "zipCode": "3000 AJ",
              "city": "ROTTERDAM",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euLocation": false
            },
            "placeOfDestination": {
              "name": "Shanghai Hongqiao International Apt",
              "locationUnCode": "CNSHA",
              "countryUnCode": "CN",
              "euLocation": false
            },
            "placeOfDelivery": null,
            "onCarriageDetails": null,
            "agentVoyageNumber": "00617300D",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "FISH",
                "grossWeight": 25500,
                "numberOfOuterPackages": 1200,
                "outerPackageType": {
                  "code": "UN",
                  "name": "Unit",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "OERU4038765"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "OERU4038765",
                    "numberOfPackages": 1200,
                    "grossWeight": 25500
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 2,
                "description": "FISH",
                "grossWeight": 25500,
                "numberOfOuterPackages": 1200,
                "outerPackageType": {
                  "code": "UN",
                  "name": "Unit",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "TEMU9123948"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "TEMU9123948",
                    "numberOfPackages": 1200,
                    "grossWeight": 25500
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 3,
                "description": "FISH",
                "grossWeight": 25500,
                "numberOfOuterPackages": 1200,
                "outerPackageType": {
                  "code": "UN",
                  "name": "Unit",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "CCLU8609335"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "CCLU8609335",
                    "numberOfPackages": 1200,
                    "grossWeight": 25500
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 4,
                "description": "FISH",
                "grossWeight": 25500,
                "numberOfOuterPackages": 1200,
                "outerPackageType": {
                  "code": "UN",
                  "name": "Unit",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "CXRU1499271"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "CXRU1499271",
                    "numberOfPackages": 1200,
                    "grossWeight": 25500
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "UFEE6303229A",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "4810",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": "SEA_IN_SEA_OUT",
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "MSC NORWAY A/S",
              "address": "THUNES VEI 2",
              "zipCode": "0274",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "MSC NEDERLAND BV",
              "address": "WESTBLAAK 218",
              "zipCode": "3012 KP",
              "city": "ROTTERDAM",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euLocation": false
            },
            "placeOfDestination": {
              "name": "Ashdod",
              "locationUnCode": "ILASH",
              "countryUnCode": "IL",
              "euLocation": false
            },
            "placeOfDelivery": null,
            "onCarriageDetails": null,
            "agentVoyageNumber": "00617300D",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "FROZEN FISH",
                "grossWeight": 22500,
                "numberOfOuterPackages": 1416,
                "outerPackageType": {
                  "code": "CT",
                  "name": "Carton",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "MEDU9702818"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "MEDU9702818",
                    "numberOfPackages": 1416,
                    "grossWeight": 22500
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "UFEE6303272A",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "4810",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": "SEA_IN_SEA_OUT",
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "MSC NORWAY A/S",
              "address": "THUNES VEI 2",
              "zipCode": "0274",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "MSC NEDERLAND BV",
              "address": "WESTBLAAK 218",
              "zipCode": "3012 KP",
              "city": "ROTTERDAM",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euLocation": false
            },
            "placeOfDestination": {
              "name": "Ashdod",
              "locationUnCode": "ILASH",
              "countryUnCode": "IL",
              "euLocation": false
            },
            "placeOfDelivery": null,
            "onCarriageDetails": null,
            "agentVoyageNumber": "00617300D",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "FROZEN FISH",
                "grossWeight": 28000,
                "numberOfOuterPackages": 913,
                "outerPackageType": {
                  "code": "CT",
                  "name": "Carton",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "TRIU8217119"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "TRIU8217119",
                    "numberOfPackages": 913,
                    "grossWeight": 28000
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "UFEE6303274A",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "4810",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": "SEA_IN_SEA_OUT",
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "MSC NORWAY A/S",
              "address": "THUNES VEI 2",
              "zipCode": "0274",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "MSC NEDERLAND BV",
              "address": "WESTBLAAK 218",
              "zipCode": "3012 KP",
              "city": "ROTTERDAM",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euLocation": false
            },
            "placeOfDestination": {
              "name": "Ashdod",
              "locationUnCode": "ILASH",
              "countryUnCode": "IL",
              "euLocation": false
            },
            "placeOfDelivery": null,
            "onCarriageDetails": null,
            "agentVoyageNumber": "00617300D",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "FROZEN FISH",
                "grossWeight": 28000,
                "numberOfOuterPackages": 1322,
                "outerPackageType": {
                  "code": "CT",
                  "name": "Carton",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "MEDU9714758"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "MEDU9714758",
                    "numberOfPackages": 1322,
                    "grossWeight": 28000
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "UFEE6303726A",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": "SEA_IN_SEA_OUT",
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "COSCO SHIPPING LINES (NORWAY) A/S",
              "address": "POSTBOKS 1006, SENTRUM",
              "zipCode": "0104",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "COSCO SHIPPING LINES (NETHERLANDS)",
              "address": "PO BOX 362",
              "zipCode": "3000 AJ",
              "city": "ROTTERDAM",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euLocation": false
            },
            "placeOfDestination": {
              "name": "Shanghai Hongqiao International Apt",
              "locationUnCode": "CNSHA",
              "countryUnCode": "CN",
              "euLocation": false
            },
            "placeOfDelivery": null,
            "onCarriageDetails": null,
            "agentVoyageNumber": "00617300D",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "CELLULOSE",
                "grossWeight": 20000,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "TGBU9275576"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "TGBU9275576",
                    "numberOfPackages": 1,
                    "grossWeight": 20000
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "UFEE6303899A",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": "SEA_IN_SEA_OUT",
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "CMA-CGM NORWAY",
              "address": "HOFFSVEIEN 1A",
              "zipCode": "0275",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "CMA (HOLLAND) B.V.",
              "address": "RIVIERWEG 1",
              "zipCode": "3161",
              "city": "RHOON",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euLocation": false
            },
            "placeOfDestination": {
              "name": "New York",
              "locationUnCode": "USNYC",
              "countryUnCode": "US",
              "euLocation": false
            },
            "placeOfDelivery": null,
            "onCarriageDetails": null,
            "agentVoyageNumber": "00617300D",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "CERAMIC ARTICLES OF PORCELAIN",
                "grossWeight": 9990,
                "numberOfOuterPackages": 20,
                "outerPackageType": {
                  "code": "PX",
                  "name": "Pallet",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "ESPU2045111"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "ESPU2045111",
                    "numberOfPackages": 20,
                    "grossWeight": 9990
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "UFEE6308714A",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": "SEA_IN_SEA_OUT",
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "COSCO SHIPPING LINES (NORWAY) A/S",
              "address": "POSTBOKS 1006, SENTRUM",
              "zipCode": "0104",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "COSCO SHIPPING LINES (NETHERLANDS)",
              "address": "PO BOX 362",
              "zipCode": "3000 AJ",
              "city": "ROTTERDAM",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euLocation": false
            },
            "placeOfDestination": {
              "name": "Shanghai Hongqiao International Apt",
              "locationUnCode": "CNSHA",
              "countryUnCode": "CN",
              "euLocation": false
            },
            "placeOfDelivery": null,
            "onCarriageDetails": null,
            "agentVoyageNumber": "00617300D",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "SPAREPARTS",
                "grossWeight": 5506,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "FCIU5718623"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "FCIU5718623",
                    "numberOfPackages": 1,
                    "grossWeight": 5506
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "UFEE6308871A",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "4810",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "HEVOLD SHIPPING AS",
              "address": "GROENLANDSLEIRET 31",
              "zipCode": "0190",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "HAPAG LLOYD NEDERLAND BV",
              "address": "WAALHAVEN O.Z. 79",
              "zipCode": "3087BM",
              "city": "ROTTERDAM",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euLocation": false
            },
            "placeOfDestination": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euLocation": true
            },
            "placeOfDelivery": null,
            "onCarriageDetails": null,
            "agentVoyageNumber": "00617300D",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "EMPTY CONTAINER",
                "grossWeight": 1,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "HLBU9429583"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "HLBU9429583",
                    "numberOfPackages": 1,
                    "grossWeight": 1
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 2,
                "description": "EMPTY CONTAINER",
                "grossWeight": 1,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "HLBU9263342"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "HLBU9263342",
                    "numberOfPackages": 1,
                    "grossWeight": 1
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "UFEE6309920A",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "TANK MANAGEMENT AS",
              "address": "BEKKELAGSKAIA 2",
              "zipCode": "01934",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "TANK MANAGEMENT AS",
              "address": "BEKKELAGSKAIA 2",
              "zipCode": "01934",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euLocation": false
            },
            "placeOfDestination": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euLocation": true
            },
            "placeOfDelivery": null,
            "onCarriageDetails": null,
            "agentVoyageNumber": "00617300D",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "EMPTY CONTAINER",
                "grossWeight": 1,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "EXFU8784125"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "EXFU8784125",
                    "numberOfPackages": 1,
                    "grossWeight": 1
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "UFEE6310460A",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "TANK MANAGEMENT AS",
              "address": "BEKKELAGSKAIA 2",
              "zipCode": "01934",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "TANK MANAGEMENT AS",
              "address": "BEKKELAGSKAIA 2",
              "zipCode": "01934",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euLocation": false
            },
            "placeOfDestination": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euLocation": true
            },
            "placeOfDelivery": null,
            "onCarriageDetails": null,
            "agentVoyageNumber": "00617300D",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "EMPTY CONTAINER",
                "grossWeight": 1,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "EXFU8784110"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "EXFU8784110",
                    "numberOfPackages": 1,
                    "grossWeight": 1
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "UFEE6310463A",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "TANK MANAGEMENT AS",
              "address": "BEKKELAGSKAIA 2",
              "zipCode": "01934",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "TANK MANAGEMENT AS",
              "address": "BEKKELAGSKAIA 2",
              "zipCode": "01934",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euLocation": false
            },
            "placeOfDestination": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euLocation": true
            },
            "placeOfDelivery": null,
            "onCarriageDetails": null,
            "agentVoyageNumber": "00617300D",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "EMPTY CONTAINER",
                "grossWeight": 1,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "VODU2511260"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "VODU2511260",
                    "numberOfPackages": 1,
                    "grossWeight": 1
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "UFEE6310864A",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "TANK MANAGEMENT AS",
              "address": "BEKKELAGSKAIA 2",
              "zipCode": "01934",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "TANK MANAGEMENT AS",
              "address": "BEKKELAGSKAIA 2",
              "zipCode": "01934",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euLocation": false
            },
            "placeOfDestination": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euLocation": true
            },
            "placeOfDelivery": null,
            "onCarriageDetails": null,
            "agentVoyageNumber": "00617300D",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "EMPTY CONTAINER",
                "grossWeight": 1,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "VODU2513030"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "VODU2513030",
                    "numberOfPackages": 1,
                    "grossWeight": 1
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "UFEE6311343A",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "TANK MANAGEMENT AS",
              "address": "BEKKELAGSKAIA 2",
              "zipCode": "01934",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "TANK MANAGEMENT AS",
              "address": "BEKKELAGSKAIA 2",
              "zipCode": "01934",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euLocation": false
            },
            "placeOfDestination": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euLocation": true
            },
            "placeOfDelivery": null,
            "onCarriageDetails": null,
            "agentVoyageNumber": "00617300D",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "EMPTY CONTAINER",
                "grossWeight": 1,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "VODU2510730"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "VODU2510730",
                    "numberOfPackages": 1,
                    "grossWeight": 1
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "UFEE6311512A",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "TANK MANAGEMENT AS",
              "address": "BEKKELAGSKAIA 2",
              "zipCode": "01934",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "TANK MANAGEMENT AS",
              "address": "BEKKELAGSKAIA 2",
              "zipCode": "01934",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euLocation": false
            },
            "placeOfDestination": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euLocation": true
            },
            "placeOfDelivery": null,
            "onCarriageDetails": null,
            "agentVoyageNumber": "00617300D",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "EMPTY CONTAINER",
                "grossWeight": 1,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "VODU2511300"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "VODU2511300",
                    "numberOfPackages": 1,
                    "grossWeight": 1
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "UFEE6311727A",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "4810",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "OCEAN NETWORK EXPRESS (EUROPE) LTD.",
              "address": "FOERSTA LANGGATAN 28B",
              "zipCode": "40032",
              "city": "GOTHENBURG",
              "countryCode": "SE",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "OCEAN NETWORK EXPRESS(EUROPE)",
              "address": "AERT VAN NESSTRAAT 45",
              "zipCode": "3012",
              "city": "ROTTERDAM",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euLocation": false
            },
            "placeOfDestination": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euLocation": true
            },
            "placeOfDelivery": null,
            "onCarriageDetails": null,
            "agentVoyageNumber": "00617300D",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "EMPTY CONTAINER",
                "grossWeight": 1,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "TRHU1733486"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "TRHU1733486",
                    "numberOfPackages": 1,
                    "grossWeight": 1
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 2,
                "description": "EMPTY CONTAINER",
                "grossWeight": 1,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "CAIU3194529"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "CAIU3194529",
                    "numberOfPackages": 1,
                    "grossWeight": 1
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 3,
                "description": "EMPTY CONTAINER",
                "grossWeight": 1,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "DFSU2943250"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "DFSU2943250",
                    "numberOfPackages": 1,
                    "grossWeight": 1
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 4,
                "description": "EMPTY CONTAINER",
                "grossWeight": 1,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "FCIU4383335"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "FCIU4383335",
                    "numberOfPackages": 1,
                    "grossWeight": 1
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 5,
                "description": "EMPTY CONTAINER",
                "grossWeight": 1,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "KKTU8226701"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "KKTU8226701",
                    "numberOfPackages": 1,
                    "grossWeight": 1
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 6,
                "description": "EMPTY CONTAINER",
                "grossWeight": 1,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "KKTU8250605"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "KKTU8250605",
                    "numberOfPackages": 1,
                    "grossWeight": 1
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 7,
                "description": "EMPTY CONTAINER",
                "grossWeight": 1,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "MOAU6751434"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "MOAU6751434",
                    "numberOfPackages": 1,
                    "grossWeight": 1
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 8,
                "description": "EMPTY CONTAINER",
                "grossWeight": 1,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "NYKU3548933"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "NYKU3548933",
                    "numberOfPackages": 1,
                    "grossWeight": 1
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 9,
                "description": "EMPTY CONTAINER",
                "grossWeight": 1,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "NYKU3723569"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "NYKU3723569",
                    "numberOfPackages": 1,
                    "grossWeight": 1
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 10,
                "description": "EMPTY CONTAINER",
                "grossWeight": 1,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "NYKU3828493"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "NYKU3828493",
                    "numberOfPackages": 1,
                    "grossWeight": 1
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 11,
                "description": "EMPTY CONTAINER",
                "grossWeight": 1,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "NYKU9711837"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "NYKU9711837",
                    "numberOfPackages": 1,
                    "grossWeight": 1
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 12,
                "description": "EMPTY CONTAINER",
                "grossWeight": 1,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "NYKU9740521"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "NYKU9740521",
                    "numberOfPackages": 1,
                    "grossWeight": 1
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 13,
                "description": "EMPTY CONTAINER",
                "grossWeight": 1,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "NYKU9785948"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "NYKU9785948",
                    "numberOfPackages": 1,
                    "grossWeight": 1
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 14,
                "description": "EMPTY CONTAINER",
                "grossWeight": 1,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "TCKU2823987"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "TCKU2823987",
                    "numberOfPackages": 1,
                    "grossWeight": 1
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 15,
                "description": "EMPTY CONTAINER",
                "grossWeight": 1,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "TCLU7562008"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "TCLU7562008",
                    "numberOfPackages": 1,
                    "grossWeight": 1
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 16,
                "description": "EMPTY CONTAINER",
                "grossWeight": 1,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "TEMU2887179"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "TEMU2887179",
                    "numberOfPackages": 1,
                    "grossWeight": 1
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 17,
                "description": "EMPTY CONTAINER",
                "grossWeight": 1,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "TEMU4293509"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "TEMU4293509",
                    "numberOfPackages": 1,
                    "grossWeight": 1
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 18,
                "description": "EMPTY CONTAINER",
                "grossWeight": 1,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "TEMU5293400"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "TEMU5293400",
                    "numberOfPackages": 1,
                    "grossWeight": 1
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "UFEE6312893A",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "GREENSHIP NORWAY AS",
              "address": "ALFBJERCKES VEI 10",
              "zipCode": "N-0582",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "EVERGREEN SHIPPING AGENCY (EUROPE)",
              "address": "PORTCITY II HAVENNUMMER 2235",
              "zipCode": "3089 JH",
              "city": "ROTTERDAM",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euLocation": false
            },
            "placeOfDestination": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euLocation": true
            },
            "placeOfDelivery": null,
            "onCarriageDetails": null,
            "agentVoyageNumber": "00617300D",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "EMPTY CONTAINER",
                "grossWeight": 1,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "EGHU1057750"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "EGHU1057750",
                    "numberOfPackages": 1,
                    "grossWeight": 1
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 2,
                "description": "EMPTY CONTAINER",
                "grossWeight": 1,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "EGHU1013664"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "EGHU1013664",
                    "numberOfPackages": 1,
                    "grossWeight": 1
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 3,
                "description": "EMPTY CONTAINER",
                "grossWeight": 1,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "EMCU1449056"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "EMCU1449056",
                    "numberOfPackages": 1,
                    "grossWeight": 1
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "UFEE6313201A",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "TANK MANAGEMENT AS",
              "address": "BEKKELAGSKAIA 2",
              "zipCode": "01934",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "TANK MANAGEMENT AS",
              "address": "BEKKELAGSKAIA 2",
              "zipCode": "01934",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euLocation": false
            },
            "placeOfDestination": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euLocation": true
            },
            "placeOfDelivery": null,
            "onCarriageDetails": null,
            "agentVoyageNumber": "00617300D",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "EMPTY CONTAINER",
                "grossWeight": 1,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "VODU2510664"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "VODU2510664",
                    "numberOfPackages": 1,
                    "grossWeight": 1
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 2,
                "description": "EMPTY CONTAINER",
                "grossWeight": 1,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "VODU2511130"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "VODU2511130",
                    "numberOfPackages": 1,
                    "grossWeight": 1
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 3,
                "description": "EMPTY CONTAINER",
                "grossWeight": 1,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "VODU2510242"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "VODU2510242",
                    "numberOfPackages": 1,
                    "grossWeight": 1
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 4,
                "description": "EMPTY CONTAINER",
                "grossWeight": 1,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "VODU2510509"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "VODU2510509",
                    "numberOfPackages": 1,
                    "grossWeight": 1
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 5,
                "description": "EMPTY CONTAINER",
                "grossWeight": 1,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "VODU2510520"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "VODU2510520",
                    "numberOfPackages": 1,
                    "grossWeight": 1
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 6,
                "description": "EMPTY CONTAINER",
                "grossWeight": 1,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "VODU2510406"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "VODU2510406",
                    "numberOfPackages": 1,
                    "grossWeight": 1
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "UFEE6313495A",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "TANK MANAGEMENT AS",
              "address": "BEKKELAGSKAIA 2",
              "zipCode": "01934",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "TANK MANAGEMENT AS",
              "address": "BEKKELAGSKAIA 2",
              "zipCode": "01934",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euLocation": false
            },
            "placeOfDestination": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euLocation": true
            },
            "placeOfDelivery": null,
            "onCarriageDetails": null,
            "agentVoyageNumber": "00617300D",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "EMPTY CONTAINER",
                "grossWeight": 1,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "EXFU8784130"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "EXFU8784130",
                    "numberOfPackages": 1,
                    "grossWeight": 1
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "UFEE6315479A",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "4810",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": "SEA_IN_SEA_OUT",
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "HAAKULL SHIPPING",
              "address": "POSTBOKS 1567 VIKA",
              "zipCode": "N-0118",
              "city": "OSLO 1",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "YANG MING NETHERLANDS B V",
              "address": "POSTBUS 1158",
              "zipCode": "3000 BD",
              "city": "ROTTERDAM",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euLocation": false
            },
            "placeOfDestination": {
              "name": "Ningbo Lishe International Apt",
              "locationUnCode": "CNNGB",
              "countryUnCode": "CN",
              "euLocation": false
            },
            "placeOfDelivery": null,
            "onCarriageDetails": null,
            "agentVoyageNumber": "00617300D",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "COPPER WASTE AND SCRAP",
                "grossWeight": 18080,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "LT",
                  "name": "Lot",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "YMMU1008723"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "YMMU1008723",
                    "numberOfPackages": 1,
                    "grossWeight": 18080
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 2,
                "description": "COPPER WASTE AND SCRAP",
                "grossWeight": 18080,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "LT",
                  "name": "Lot",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "TEMU1281145"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "TEMU1281145",
                    "numberOfPackages": 1,
                    "grossWeight": 18080
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "UFEE6316367A",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "TANK MANAGEMENT AS",
              "address": "BEKKELAGSKAIA 2",
              "zipCode": "01934",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "TANK MANAGEMENT AS",
              "address": "BEKKELAGSKAIA 2",
              "zipCode": "01934",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euLocation": false
            },
            "placeOfDestination": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euLocation": true
            },
            "placeOfDelivery": null,
            "onCarriageDetails": null,
            "agentVoyageNumber": "00617300D",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "EMPTY CONTAINER",
                "grossWeight": 1,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "VODU2511212"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "VODU2511212",
                    "numberOfPackages": 1,
                    "grossWeight": 1
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "UFEE6316425A",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "TANK MANAGEMENT AS",
              "address": "BEKKELAGSKAIA 2",
              "zipCode": "01934",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "TANK MANAGEMENT AS",
              "address": "BEKKELAGSKAIA 2",
              "zipCode": "01934",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euLocation": false
            },
            "placeOfDestination": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euLocation": true
            },
            "placeOfDelivery": null,
            "onCarriageDetails": null,
            "agentVoyageNumber": "00617300D",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "EMPTY CONTAINER",
                "grossWeight": 1,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "VODU2512034"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "VODU2512034",
                    "numberOfPackages": 1,
                    "grossWeight": 1
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "UFEE6316985A",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "UNIFEEDER NORWAY AS",
              "address": "P.O. BOX. 8",
              "zipCode": "0137",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "UNIFEEDER NETHERLANDS",
              "address": "POSTBUS 21470",
              "zipCode": "3012",
              "city": "ROTTERDAM",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euLocation": true
            },
            "placeOfDelivery": null,
            "onCarriageDetails": null,
            "agentVoyageNumber": "00617300D",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "EMPTY CONTAINER",
                "grossWeight": 1,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "CNEU4551330"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "CNEU4551330",
                    "numberOfPackages": 1,
                    "grossWeight": 1
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 2,
                "description": "EMPTY CONTAINER",
                "grossWeight": 1,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "TCLU4834993"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "TCLU4834993",
                    "numberOfPackages": 1,
                    "grossWeight": 1
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "UFEE6317390A",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "TANK MANAGEMENT AS",
              "address": "BEKKELAGSKAIA 2",
              "zipCode": "01934",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "TANK MANAGEMENT AS",
              "address": "BEKKELAGSKAIA 2",
              "zipCode": "01934",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euLocation": false
            },
            "placeOfDestination": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euLocation": true
            },
            "placeOfDelivery": null,
            "onCarriageDetails": null,
            "agentVoyageNumber": "00617300D",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "EMPTY CONTAINER",
                "grossWeight": 1,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "VODU2511043"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "VODU2511043",
                    "numberOfPackages": 1,
                    "grossWeight": 1
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "UFEE6317399A",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "CMA-CGM NORWAY",
              "address": "HOFFSVEIEN 1A",
              "zipCode": "0275",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "CMA (HOLLAND) B.V.",
              "address": "RIVIERWEG 1",
              "zipCode": "3161",
              "city": "RHOON",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euLocation": false
            },
            "placeOfDestination": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euLocation": true
            },
            "placeOfDelivery": null,
            "onCarriageDetails": null,
            "agentVoyageNumber": "00617300D",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "EMPTY CONTAINER",
                "grossWeight": 1,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "CNCU1504873"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "CNCU1504873",
                    "numberOfPackages": 1,
                    "grossWeight": 1
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "UFEE6319955A",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "TANK MANAGEMENT AS",
              "address": "BEKKELAGSKAIA 2",
              "zipCode": "01934",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "TANK MANAGEMENT AS",
              "address": "BEKKELAGSKAIA 2",
              "zipCode": "01934",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euLocation": false
            },
            "placeOfDestination": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euLocation": true
            },
            "placeOfDelivery": null,
            "onCarriageDetails": null,
            "agentVoyageNumber": "00617300D",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "EMPTY CONTAINER",
                "grossWeight": 1,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "VODU9308151"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "VODU9308151",
                    "numberOfPackages": 1,
                    "grossWeight": 1
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "UFEE6320372A",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "CMA-CGM NORWAY",
              "address": "HOFFSVEIEN 1A",
              "zipCode": "0275",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "CMA (HOLLAND) B.V.",
              "address": "RIVIERWEG 1",
              "zipCode": "3161",
              "city": "RHOON",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euLocation": false
            },
            "placeOfDestination": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euLocation": true
            },
            "placeOfDelivery": null,
            "onCarriageDetails": null,
            "agentVoyageNumber": "00617300D",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "EMPTY CONTAINER",
                "grossWeight": 1,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "TLLU1683572"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "TLLU1683572",
                    "numberOfPackages": 1,
                    "grossWeight": 1
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 2,
                "description": "EMPTY CONTAINER",
                "grossWeight": 1,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "TLLU1698150"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "TLLU1698150",
                    "numberOfPackages": 1,
                    "grossWeight": 1
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 3,
                "description": "EMPTY CONTAINER",
                "grossWeight": 1,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "TCLU4801095"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "TCLU4801095",
                    "numberOfPackages": 1,
                    "grossWeight": 1
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "UFEE6322760A",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "4810",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": "SEA_IN_SEA_OUT",
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "OCEAN NETWORK EXPRESS (EUROPE) LTD.",
              "address": "FOERSTA LANGGATAN 28B",
              "zipCode": "40032",
              "city": "GOTHENBURG",
              "countryCode": "SE",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "OCEAN NETWORK EXPRESS(EUROPE)",
              "address": "AERT VAN NESSTRAAT 45",
              "zipCode": "3012",
              "city": "ROTTERDAM",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euLocation": false
            },
            "placeOfDestination": {
              "name": "Laem Chabang",
              "locationUnCode": "THLCH",
              "countryUnCode": "TH",
              "euLocation": false
            },
            "placeOfDelivery": null,
            "onCarriageDetails": null,
            "agentVoyageNumber": "00617300D",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "FISH WHOLE FROZEN, OTHER",
                "grossWeight": 23870,
                "numberOfOuterPackages": 954,
                "outerPackageType": {
                  "code": "CT",
                  "name": "Carton",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "TRIU8940278"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "TRIU8940278",
                    "numberOfPackages": 954,
                    "grossWeight": 23870
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "UFEE6323177A",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-26T22:00:00Z",
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "COSCO SHIPPING LINES (NORWAY) A/S",
              "address": "POSTBOKS 1006, SENTRUM",
              "zipCode": "0104",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "COSCO SHIPPING LINES (NETHERLANDS)",
              "address": "PO BOX 362",
              "zipCode": "3000 AJ",
              "city": "ROTTERDAM",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euLocation": false
            },
            "placeOfDestination": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euLocation": true
            },
            "placeOfDelivery": null,
            "onCarriageDetails": null,
            "agentVoyageNumber": "00617300D",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "EMPTY CONTAINER",
                "grossWeight": 1,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "SZLU9683678"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "SZLU9683678",
                    "numberOfPackages": 1,
                    "grossWeight": 1
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 2,
                "description": "EMPTY CONTAINER",
                "grossWeight": 1,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "SZLU9372803"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "SZLU9372803",
                    "numberOfPackages": 1,
                    "grossWeight": 1
                  }
                ],
                "producedDocuments": []
              }
            ]
          }
        ],
        "receivedMrns": {},
        "discharges": [
          {
            "number": "RVWI1111111",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T03:43:00Z",
            "stowageLocation": null,
            "grossWeight": 1000,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "AMKD",
              "name": "23'tank 9.0ft extra breed"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": "lenp",
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "LENP1111111",
            "portOfLoading": null,
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T03:43:00Z",
            "stowageLocation": null,
            "grossWeight": null,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "AMKD",
              "name": "23'tank 9.0ft extra breed"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": "lenp",
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "EXFU8784110",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T03:46:00Z",
            "stowageLocation": null,
            "grossWeight": null,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "AMKD",
              "name": "23'tank 9.0ft extra breed"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "EXFU8784130",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T05:33:00Z",
            "stowageLocation": null,
            "grossWeight": null,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "AMKD",
              "name": "23'tank 9.0ft extra breed"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "VODU2510730",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T08:46:00Z",
            "stowageLocation": null,
            "grossWeight": null,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "24TN",
              "name": "24TN"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "VODU2510242",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T08:49:00Z",
            "stowageLocation": null,
            "grossWeight": null,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "24TN",
              "name": "24TN"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "VODU2510520",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T08:52:00Z",
            "stowageLocation": null,
            "grossWeight": null,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "24TN",
              "name": "24TN"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "VODU2511212",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T08:57:00Z",
            "stowageLocation": null,
            "grossWeight": null,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "24TN",
              "name": "24TN"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "VODU2511130",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T09:00:00Z",
            "stowageLocation": null,
            "grossWeight": null,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "24TN",
              "name": "24TN"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "VODU2510664",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T10:18:00Z",
            "stowageLocation": null,
            "grossWeight": null,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "24TN",
              "name": "24TN"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "VODU2511300",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T10:26:00Z",
            "stowageLocation": null,
            "grossWeight": null,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "24TN",
              "name": "24TN"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "VODU2511043",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T10:38:00Z",
            "stowageLocation": null,
            "grossWeight": null,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "24TN",
              "name": "24TN"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "VODU2512034",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T10:40:00Z",
            "stowageLocation": null,
            "grossWeight": null,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "24TN",
              "name": "24TN"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "VODU2513030",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T10:43:00Z",
            "stowageLocation": null,
            "grossWeight": null,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "24TN",
              "name": "24TN"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "VODU2510406",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T10:48:00Z",
            "stowageLocation": null,
            "grossWeight": null,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "24TN",
              "name": "24TN"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "VODU2510509",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T10:55:00Z",
            "stowageLocation": null,
            "grossWeight": null,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "24TN",
              "name": "24TN"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "VODU9308151",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T11:12:00Z",
            "stowageLocation": null,
            "grossWeight": null,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "EMT6",
              "name": "25ft tank container 400 kPa"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TLLU1683572",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T11:16:00Z",
            "stowageLocation": null,
            "grossWeight": null,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEG1",
              "name": "45 ft 9.6 PW"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TCLU4801095",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T11:35:00Z",
            "stowageLocation": null,
            "grossWeight": null,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEG1",
              "name": "45 ft 9.6 PW"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TLLU1698150",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T11:53:00Z",
            "stowageLocation": null,
            "grossWeight": null,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEG1",
              "name": "45 ft 9.6 PW"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "CNEU4551330",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T12:04:00Z",
            "stowageLocation": null,
            "grossWeight": null,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEG1",
              "name": "45 ft 9.6 PW"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TCLU4834993",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T12:10:00Z",
            "stowageLocation": null,
            "grossWeight": null,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEG1",
              "name": "45 ft 9.6 PW"
            },
            "containerOperator": {
              "customsId": "DK11810543",
              "name": "Unifeeder A/S",
              "scacCode": "UFEE",
              "smdgCode": "UFE",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "HLBU9263342",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "19387239",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-27T16:47:00Z",
            "stowageLocation": "3001880",
            "grossWeight": 4200,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "DE2742004",
              "name": "Hapag-Lloyd Container Line",
              "scacCode": "HLCU",
              "smdgCode": "HLC",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "HLBU9429583",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "19387239",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-27T16:45:00Z",
            "stowageLocation": "3003880",
            "grossWeight": 4200,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "DE2742004",
              "name": "Hapag-Lloyd Container Line",
              "scacCode": "HLCU",
              "smdgCode": "HLC",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "KKTU8226701",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "19387239",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-27T16:53:00Z",
            "stowageLocation": "3105860",
            "grossWeight": 2300,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "NL857649115",
              "name": "ONE (Ocean Network Express)",
              "scacCode": "ONEY",
              "smdgCode": "ONE",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "NYKU3828493",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "19387239",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-27T16:56:00Z",
            "stowageLocation": "3103860",
            "grossWeight": 2300,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "NL857649115",
              "name": "ONE (Ocean Network Express)",
              "scacCode": "ONEY",
              "smdgCode": "ONE",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TRHU1733486",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "19387239",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-27T16:56:00Z",
            "stowageLocation": "2905860",
            "grossWeight": 2300,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "NL857649115",
              "name": "ONE (Ocean Network Express)",
              "scacCode": "ONEY",
              "smdgCode": "ONE",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "CAIU3194529",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "19387239",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-27T16:53:00Z",
            "stowageLocation": "2903860",
            "grossWeight": 2300,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "NL857649115",
              "name": "ONE (Ocean Network Express)",
              "scacCode": "ONEY",
              "smdgCode": "ONE",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "FCIU4383335",
            "portOfLoading": {
              "name": "NOOSC",
              "locationUnCode": "NO888",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "19387239",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-27T16:59:00Z",
            "stowageLocation": "2901860",
            "grossWeight": 2300,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "NL857649115",
              "name": "ONE (Ocean Network Express)",
              "scacCode": "ONEY",
              "smdgCode": "ONE",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "NYKU3723569",
            "portOfLoading": {
              "name": "NOOSC",
              "locationUnCode": "NO888",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "19387239",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-27T16:59:00Z",
            "stowageLocation": "3101860",
            "grossWeight": 2300,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "NL857649115",
              "name": "ONE (Ocean Network Express)",
              "scacCode": "ONEY",
              "smdgCode": "ONE",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "KKTU8250605",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "19387239",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-27T17:08:00Z",
            "stowageLocation": "2903840",
            "grossWeight": 2300,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "NL857649115",
              "name": "ONE (Ocean Network Express)",
              "scacCode": "ONEY",
              "smdgCode": "ONE",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "NYKU9785948",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "19387239",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-27T17:03:00Z",
            "stowageLocation": "3105840",
            "grossWeight": 2300,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "NL857649115",
              "name": "ONE (Ocean Network Express)",
              "scacCode": "ONEY",
              "smdgCode": "ONE",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TCLU7562008",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "19387239",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-27T17:03:00Z",
            "stowageLocation": "2905840",
            "grossWeight": 2300,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "NL857649115",
              "name": "ONE (Ocean Network Express)",
              "scacCode": "ONEY",
              "smdgCode": "ONE",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TEMU2887179",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "19387239",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-27T17:08:00Z",
            "stowageLocation": "3103840",
            "grossWeight": 2300,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "NL857649115",
              "name": "ONE (Ocean Network Express)",
              "scacCode": "ONEY",
              "smdgCode": "ONE",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "DFSU2943250",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "19387239",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-27T17:57:00Z",
            "stowageLocation": "3101840",
            "grossWeight": 2300,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "NL857649115",
              "name": "ONE (Ocean Network Express)",
              "scacCode": "ONEY",
              "smdgCode": "ONE",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TEMU4293509",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "19387239",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-27T17:58:00Z",
            "stowageLocation": "2901840",
            "grossWeight": 2300,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "NL857649115",
              "name": "ONE (Ocean Network Express)",
              "scacCode": "ONEY",
              "smdgCode": "ONE",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "MOAU6751434",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "19387239",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-27T18:03:00Z",
            "stowageLocation": "3105820",
            "grossWeight": 2300,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "NL857649115",
              "name": "ONE (Ocean Network Express)",
              "scacCode": "ONEY",
              "smdgCode": "ONE",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "NYKU3548933",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "19387239",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-27T18:09:00Z",
            "stowageLocation": "3101820",
            "grossWeight": 2300,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "NL857649115",
              "name": "ONE (Ocean Network Express)",
              "scacCode": "ONEY",
              "smdgCode": "ONE",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "NYKU9711837",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "19387239",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-27T18:09:00Z",
            "stowageLocation": "2901820",
            "grossWeight": 2300,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "NL857649115",
              "name": "ONE (Ocean Network Express)",
              "scacCode": "ONEY",
              "smdgCode": "ONE",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "NYKU9740521",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "19387239",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-27T18:06:00Z",
            "stowageLocation": "3103820",
            "grossWeight": 2300,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "NL857649115",
              "name": "ONE (Ocean Network Express)",
              "scacCode": "ONEY",
              "smdgCode": "ONE",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TCKU2823987",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "19387239",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-27T18:06:00Z",
            "stowageLocation": "2903820",
            "grossWeight": 2300,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "NL857649115",
              "name": "ONE (Ocean Network Express)",
              "scacCode": "ONEY",
              "smdgCode": "ONE",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TEMU5293400",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "19387239",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-27T18:03:00Z",
            "stowageLocation": "2905820",
            "grossWeight": 2300,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "NL857649115",
              "name": "ONE (Ocean Network Express)",
              "scacCode": "ONEY",
              "smdgCode": "ONE",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "BMOU9612457",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "19387239",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-27T18:22:00Z",
            "stowageLocation": "2207860",
            "grossWeight": 27500,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "NL822486210",
              "name": "MSC , S.A.",
              "scacCode": "MSCU",
              "smdgCode": "MSC",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "MSCU7388810",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "19387239",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-27T18:38:00Z",
            "stowageLocation": "2205860",
            "grossWeight": 27800,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "NL822486210",
              "name": "MSC , S.A.",
              "scacCode": "MSCU",
              "smdgCode": "MSC",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TRIU8217119",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "19387239",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-27T18:41:00Z",
            "stowageLocation": "2203860",
            "grossWeight": 28100,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "NL822486210",
              "name": "MSC , S.A.",
              "scacCode": "MSCU",
              "smdgCode": "MSC",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "MEDU9702818",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "19387239",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-27T18:43:00Z",
            "stowageLocation": "2206860",
            "grossWeight": 27000,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "NL822486210",
              "name": "MSC , S.A.",
              "scacCode": "MSCU",
              "smdgCode": "MSC",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "HLBU9187319",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "19387239",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-27T18:51:00Z",
            "stowageLocation": "2203840",
            "grossWeight": 32598,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "DE2742004",
              "name": "Hapag-Lloyd Container Line",
              "scacCode": "HLCU",
              "smdgCode": "HLC",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TRIU8940278",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "19387239",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-27T18:51:00Z",
            "stowageLocation": "2206840",
            "grossWeight": 28500,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "NL857649115",
              "name": "ONE (Ocean Network Express)",
              "scacCode": "ONEY",
              "smdgCode": "ONE",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TTNU8339560",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "19387239",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-27T18:48:00Z",
            "stowageLocation": "2207840",
            "grossWeight": 28500,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "NL822486210",
              "name": "MSC , S.A.",
              "scacCode": "MSCU",
              "smdgCode": "MSC",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TTNU8453362",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "19387239",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-27T18:49:00Z",
            "stowageLocation": "2205840",
            "grossWeight": 28500,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "NL822486210",
              "name": "MSC , S.A.",
              "scacCode": "MSCU",
              "smdgCode": "MSC",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "MEDU9616751",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "19387239",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-27T18:45:00Z",
            "stowageLocation": "2208860",
            "grossWeight": 23600,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "NL822486210",
              "name": "MSC , S.A.",
              "scacCode": "MSCU",
              "smdgCode": "MSC",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "MEDU9714758",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "19387239",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-27T18:53:00Z",
            "stowageLocation": "2208840",
            "grossWeight": 28200,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "NL822486210",
              "name": "MSC , S.A.",
              "scacCode": "MSCU",
              "smdgCode": "MSC",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "UACU8252747",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "19387239",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-27T19:01:00Z",
            "stowageLocation": "2201040",
            "grossWeight": 22747,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "42G1",
              "name": "40'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "DE2742004",
              "name": "Hapag-Lloyd Container Line",
              "scacCode": "HLCU",
              "smdgCode": "HLC",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "HLXU5215430",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "19387239",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-27T19:04:00Z",
            "stowageLocation": "2200040",
            "grossWeight": 22721,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "42G1",
              "name": "40'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "DE2742004",
              "name": "Hapag-Lloyd Container Line",
              "scacCode": "HLCU",
              "smdgCode": "HLC",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "FCIU6559953",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "19387239",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-27T19:10:00Z",
            "stowageLocation": "2105040",
            "grossWeight": 26556,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "DE2742004",
              "name": "Hapag-Lloyd Container Line",
              "scacCode": "HLCU",
              "smdgCode": "HLC",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "GLDU5724381",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "19387239",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-27T19:13:00Z",
            "stowageLocation": "2103040",
            "grossWeight": 26641,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "DE2742004",
              "name": "Hapag-Lloyd Container Line",
              "scacCode": "HLCU",
              "smdgCode": "HLC",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "HLBU3006650",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "19387239",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-27T19:17:00Z",
            "stowageLocation": "2106040",
            "grossWeight": 26656,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "DE2742004",
              "name": "Hapag-Lloyd Container Line",
              "scacCode": "HLCU",
              "smdgCode": "HLC",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TCLU9383892",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "19387239",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-27T19:06:00Z",
            "stowageLocation": "2202040",
            "grossWeight": 22548,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "42G1",
              "name": "40'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "DE2742004",
              "name": "Hapag-Lloyd Container Line",
              "scacCode": "HLCU",
              "smdgCode": "HLC",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TGHU0639370",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "19387239",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-27T19:15:00Z",
            "stowageLocation": "2104040",
            "grossWeight": 26706,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "DE2742004",
              "name": "Hapag-Lloyd Container Line",
              "scacCode": "HLCU",
              "smdgCode": "HLC",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "CRSU1193066",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "19387239",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-27T19:21:00Z",
            "stowageLocation": "2103020",
            "grossWeight": 26666,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "DE2742004",
              "name": "Hapag-Lloyd Container Line",
              "scacCode": "HLCU",
              "smdgCode": "HLC",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "FCIU2945135",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "19387239",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-27T19:19:00Z",
            "stowageLocation": "2105020",
            "grossWeight": 26676,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "DE2742004",
              "name": "Hapag-Lloyd Container Line",
              "scacCode": "HLCU",
              "smdgCode": "HLC",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "MEDU5750504",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "19387239",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-27T19:27:00Z",
            "stowageLocation": "2102020",
            "grossWeight": 23350,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "NL822486210",
              "name": "MSC , S.A.",
              "scacCode": "MSCU",
              "smdgCode": "MSC",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "MEDU6013116",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "19387239",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-27T19:25:00Z",
            "stowageLocation": "2100020",
            "grossWeight": 23350,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "NL822486210",
              "name": "MSC , S.A.",
              "scacCode": "MSCU",
              "smdgCode": "MSC",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TGHU1467980",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "19387239",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-27T19:23:00Z",
            "stowageLocation": "2101020",
            "grossWeight": 23100,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "NL822486210",
              "name": "MSC , S.A.",
              "scacCode": "MSCU",
              "smdgCode": "MSC",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "BMOU2900554",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "19387239",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-27T19:35:00Z",
            "stowageLocation": "2303040",
            "grossWeight": 26676,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "DE2742004",
              "name": "Hapag-Lloyd Container Line",
              "scacCode": "HLCU",
              "smdgCode": "HLC",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "CMAU3015863",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "19387239",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-27T19:37:00Z",
            "stowageLocation": null,
            "grossWeight": 20000,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "HLXU1266532",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "19387239",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-27T19:30:00Z",
            "stowageLocation": "2106020",
            "grossWeight": 26807,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "DE2742004",
              "name": "Hapag-Lloyd Container Line",
              "scacCode": "HLCU",
              "smdgCode": "HLC",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "HLXU3538115",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "19387239",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-27T19:29:00Z",
            "stowageLocation": "2104020",
            "grossWeight": 26746,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "DE2742004",
              "name": "Hapag-Lloyd Container Line",
              "scacCode": "HLCU",
              "smdgCode": "HLC",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "YMMU1008723",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "19387239",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-27T19:33:00Z",
            "stowageLocation": "2305040",
            "grossWeight": 28760,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "NL805314696",
              "name": "Yang Ming Marine Transport Corp.",
              "scacCode": "YMLU",
              "smdgCode": "YML",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TGHU0960945",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "19387239",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-27T19:39:00Z",
            "stowageLocation": "2304040",
            "grossWeight": 26656,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "DE2742004",
              "name": "Hapag-Lloyd Container Line",
              "scacCode": "HLCU",
              "smdgCode": "HLC",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "HLXU1178323",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "19387239",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-27T19:41:00Z",
            "stowageLocation": "2305020",
            "grossWeight": 26806,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "DE2742004",
              "name": "Hapag-Lloyd Container Line",
              "scacCode": "HLCU",
              "smdgCode": "HLC",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TCKU2765812",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "19387239",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-27T19:54:00Z",
            "stowageLocation": "2306020",
            "grossWeight": 26686,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "DE2742004",
              "name": "Hapag-Lloyd Container Line",
              "scacCode": "HLCU",
              "smdgCode": "HLC",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "MSDU1009980",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "19387239",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-27T19:48:00Z",
            "stowageLocation": "2300020",
            "grossWeight": 23250,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "NL822486210",
              "name": "MSC , S.A.",
              "scacCode": "MSCU",
              "smdgCode": "MSC",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "UACU3375991",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "19387239",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-27T19:45:00Z",
            "stowageLocation": "2303020",
            "grossWeight": 26686,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "DE2742004",
              "name": "Hapag-Lloyd Container Line",
              "scacCode": "HLCU",
              "smdgCode": "HLC",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "CAIU2127927",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "19387239",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-27T19:50:00Z",
            "stowageLocation": "2302020",
            "grossWeight": 23650,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "NL822486210",
              "name": "MSC , S.A.",
              "scacCode": "MSCU",
              "smdgCode": "MSC",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "BHCU3032411",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "19387239",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-27T19:52:00Z",
            "stowageLocation": "2304020",
            "grossWeight": 26706,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "DE2742004",
              "name": "Hapag-Lloyd Container Line",
              "scacCode": "HLCU",
              "smdgCode": "HLC",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TEMU1281145",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "AMAZONEH ECT DDE",
              "terminalCode": "19387239",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ECT Delta Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-27T19:46:00Z",
            "stowageLocation": "2301020",
            "grossWeight": 28240,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "NL805314696",
              "name": "Yang Ming Marine Transport Corp.",
              "scacCode": "YMLU",
              "smdgCode": "YML",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TGBU5046859",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-28T00:11:00Z",
            "stowageLocation": "0160588",
            "grossWeight": 27890,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "DE7560192",
              "name": "Evergreen Marine Corp. Ltd.",
              "scacCode": "EGLV",
              "smdgCode": "EVG",
              "cargoDeclarantShortName": "EVG"
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "EGHU9611430",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-28T00:19:00Z",
            "stowageLocation": "0160586",
            "grossWeight": 26768,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "DE7560192",
              "name": "Evergreen Marine Corp. Ltd.",
              "scacCode": "EGLV",
              "smdgCode": "EVG",
              "cargoDeclarantShortName": "EVG"
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "EGHU9715271",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-28T00:12:00Z",
            "stowageLocation": "0160688",
            "grossWeight": 27750,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "DE7560192",
              "name": "Evergreen Marine Corp. Ltd.",
              "scacCode": "EGLV",
              "smdgCode": "EVG",
              "cargoDeclarantShortName": "EVG"
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "EISU8520992",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-28T00:16:00Z",
            "stowageLocation": "0160786",
            "grossWeight": 27750,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "DE7560192",
              "name": "Evergreen Marine Corp. Ltd.",
              "scacCode": "EGLV",
              "smdgCode": "EVG",
              "cargoDeclarantShortName": "EVG"
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "EITU1680333",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-28T00:18:00Z",
            "stowageLocation": "0160686",
            "grossWeight": 27900,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "DE7560192",
              "name": "Evergreen Marine Corp. Ltd.",
              "scacCode": "EGLV",
              "smdgCode": "EVG",
              "cargoDeclarantShortName": "EVG"
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "SZLU9372803",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-28T00:14:00Z",
            "stowageLocation": "0160888",
            "grossWeight": 4200,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "DE7759959",
              "name": "Cosco Container Lines Co. Ltd",
              "scacCode": "COSU",
              "smdgCode": "COS",
              "cargoDeclarantShortName": "CROSSOCEANBV"
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "SZLU9683678",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-28T00:11:00Z",
            "stowageLocation": "0160788",
            "grossWeight": 4200,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "DE7759959",
              "name": "Cosco Container Lines Co. Ltd",
              "scacCode": "COSU",
              "smdgCode": "COS",
              "cargoDeclarantShortName": "CROSSOCEANBV"
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "SEGU5048752",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-28T00:24:00Z",
            "stowageLocation": "0160684",
            "grossWeight": 28358,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "OOLU6089121",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-28T00:28:00Z",
            "stowageLocation": "0160884",
            "grossWeight": 27500,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "DE7759959",
              "name": "Cosco Container Lines Co. Ltd",
              "scacCode": "COSU",
              "smdgCode": "COS",
              "cargoDeclarantShortName": "CROSSOCEANBV"
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TEMU9125848",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-28T00:22:00Z",
            "stowageLocation": "0160784",
            "grossWeight": 28000,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "DE7759959",
              "name": "Cosco Container Lines Co. Ltd",
              "scacCode": "COSU",
              "smdgCode": "COS",
              "cargoDeclarantShortName": "CROSSOCEANBV"
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "EGHU9249430",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-28T00:32:00Z",
            "stowageLocation": "0060106",
            "grossWeight": 9079,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "DE7560192",
              "name": "Evergreen Marine Corp. Ltd.",
              "scacCode": "EGLV",
              "smdgCode": "EVG",
              "cargoDeclarantShortName": "EVG"
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "EGSU9126824",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-28T00:39:00Z",
            "stowageLocation": "0060104",
            "grossWeight": 29820,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "DE7560192",
              "name": "Evergreen Marine Corp. Ltd.",
              "scacCode": "EGLV",
              "smdgCode": "EVG",
              "cargoDeclarantShortName": "EVG"
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "EMCU1449056",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-28T00:36:00Z",
            "stowageLocation": "0060108",
            "grossWeight": 4200,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "42G1",
              "name": "40'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "DE7560192",
              "name": "Evergreen Marine Corp. Ltd.",
              "scacCode": "EGLV",
              "smdgCode": "EVG",
              "cargoDeclarantShortName": "EVG"
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TRHU7466387",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-28T00:21:00Z",
            "stowageLocation": "0160886",
            "grossWeight": 26718,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "DE7560192",
              "name": "Evergreen Marine Corp. Ltd.",
              "scacCode": "EGLV",
              "smdgCode": "EVG",
              "cargoDeclarantShortName": "EVG"
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TRHU7472440",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-28T00:26:00Z",
            "stowageLocation": "0160584",
            "grossWeight": 26718,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "DE7560192",
              "name": "Evergreen Marine Corp. Ltd.",
              "scacCode": "EGLV",
              "smdgCode": "EVG",
              "cargoDeclarantShortName": "EVG"
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "APZU4659572",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-28T00:31:00Z",
            "stowageLocation": "0060508",
            "grossWeight": 29069,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "42G1",
              "name": "40'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "BMOU5481019",
            "portOfLoading": {
              "name": "NOOSC",
              "locationUnCode": "NO888",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-28T00:49:00Z",
            "stowageLocation": "0060206",
            "grossWeight": 9179,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "DE7560192",
              "name": "Evergreen Marine Corp. Ltd.",
              "scacCode": "EGLV",
              "smdgCode": "EVG",
              "cargoDeclarantShortName": "EVG"
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "EISU9428237",
            "portOfLoading": {
              "name": "NOOSC",
              "locationUnCode": "NO888",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-28T00:53:00Z",
            "stowageLocation": "0060004",
            "grossWeight": 30300,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "DE7560192",
              "name": "Evergreen Marine Corp. Ltd.",
              "scacCode": "EGLV",
              "smdgCode": "EVG",
              "cargoDeclarantShortName": "EVG"
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "EITU1391393",
            "portOfLoading": {
              "name": "NOOSC",
              "locationUnCode": "NO888",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-28T00:47:00Z",
            "stowageLocation": "0060006",
            "grossWeight": 27375,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "DE7560192",
              "name": "Evergreen Marine Corp. Ltd.",
              "scacCode": "EGLV",
              "smdgCode": "EVG",
              "cargoDeclarantShortName": "EVG"
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "EITU1491474",
            "portOfLoading": {
              "name": "NOOSC",
              "locationUnCode": "NO888",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-28T00:41:00Z",
            "stowageLocation": "0060102",
            "grossWeight": 30350,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "DE7560192",
              "name": "Evergreen Marine Corp. Ltd.",
              "scacCode": "EGLV",
              "smdgCode": "EVG",
              "cargoDeclarantShortName": "EVG"
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "EITU9395760",
            "portOfLoading": {
              "name": "NOOSC",
              "locationUnCode": "NO888",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-28T00:57:00Z",
            "stowageLocation": "0060202",
            "grossWeight": 30250,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "DE7560192",
              "name": "Evergreen Marine Corp. Ltd.",
              "scacCode": "EGLV",
              "smdgCode": "EVG",
              "cargoDeclarantShortName": "EVG"
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TCKU6059645",
            "portOfLoading": {
              "name": "NOOSC",
              "locationUnCode": "NO888",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-28T00:43:00Z",
            "stowageLocation": "0060008",
            "grossWeight": 8959,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "DE7560192",
              "name": "Evergreen Marine Corp. Ltd.",
              "scacCode": "EGLV",
              "smdgCode": "EVG",
              "cargoDeclarantShortName": "EVG"
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TGBU9914830",
            "portOfLoading": {
              "name": "NOOSC",
              "locationUnCode": "NO888",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-28T00:45:00Z",
            "stowageLocation": "0060208",
            "grossWeight": 8546,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "DE7560192",
              "name": "Evergreen Marine Corp. Ltd.",
              "scacCode": "EGLV",
              "smdgCode": "EVG",
              "cargoDeclarantShortName": "EVG"
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "CBHU5546275",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-28T01:01:00Z",
            "stowageLocation": "0050308",
            "grossWeight": 29945,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "DE7759959",
              "name": "Cosco Container Lines Co. Ltd",
              "scacCode": "COSU",
              "smdgCode": "COS",
              "cargoDeclarantShortName": "CROSSOCEANBV"
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "OOCU6411524",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-28T00:55:00Z",
            "stowageLocation": "0060002",
            "grossWeight": 32184,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "DE3626288",
              "name": "Diamond Line GmbH ( Cosco Europe)",
              "scacCode": "COEU",
              "smdgCode": "COE",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TGBU4923716",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-28T00:51:00Z",
            "stowageLocation": "0060204",
            "grossWeight": 32162,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "DE3626288",
              "name": "Diamond Line GmbH ( Cosco Europe)",
              "scacCode": "COEU",
              "smdgCode": "COE",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "CSLU2246064",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-28T01:07:00Z",
            "stowageLocation": "0070406",
            "grossWeight": 30030,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "DE7759959",
              "name": "Cosco Container Lines Co. Ltd",
              "scacCode": "COSU",
              "smdgCode": "COS",
              "cargoDeclarantShortName": "CROSSOCEANBV"
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "CSNU1933947",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-28T01:05:00Z",
            "stowageLocation": "0050406",
            "grossWeight": 30070,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "DE7759959",
              "name": "Cosco Container Lines Co. Ltd",
              "scacCode": "COSU",
              "smdgCode": "COS",
              "cargoDeclarantShortName": "CROSSOCEANBV"
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "CXDU1212509",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-28T01:02:00Z",
            "stowageLocation": "0050306",
            "grossWeight": 29875,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "DE7759959",
              "name": "Cosco Container Lines Co. Ltd",
              "scacCode": "COSU",
              "smdgCode": "COS",
              "cargoDeclarantShortName": "CROSSOCEANBV"
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "BSIU2841077",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-28T01:16:00Z",
            "stowageLocation": "0070402",
            "grossWeight": 29935,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "DE7759959",
              "name": "Cosco Container Lines Co. Ltd",
              "scacCode": "COSU",
              "smdgCode": "COS",
              "cargoDeclarantShortName": "CROSSOCEANBV"
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "CBHU5604577",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-28T01:18:00Z",
            "stowageLocation": "0070506",
            "grossWeight": 30055,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "DE7759959",
              "name": "Cosco Container Lines Co. Ltd",
              "scacCode": "COSU",
              "smdgCode": "COS",
              "cargoDeclarantShortName": "CROSSOCEANBV"
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "CSLU1351867",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-28T01:13:00Z",
            "stowageLocation": "0070404",
            "grossWeight": 30020,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "DE7759959",
              "name": "Cosco Container Lines Co. Ltd",
              "scacCode": "COSU",
              "smdgCode": "COS",
              "cargoDeclarantShortName": "CROSSOCEANBV"
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "CSNU1386177",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-28T01:09:00Z",
            "stowageLocation": "0070306",
            "grossWeight": 29950,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "DE7759959",
              "name": "Cosco Container Lines Co. Ltd",
              "scacCode": "COSU",
              "smdgCode": "COS",
              "cargoDeclarantShortName": "CROSSOCEANBV"
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "CSNU1705931",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-28T01:19:00Z",
            "stowageLocation": "0070302",
            "grossWeight": 29860,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "DE7759959",
              "name": "Cosco Container Lines Co. Ltd",
              "scacCode": "COSU",
              "smdgCode": "COS",
              "cargoDeclarantShortName": "CROSSOCEANBV"
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "UETU2504893",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-28T01:11:00Z",
            "stowageLocation": "0070304",
            "grossWeight": 29905,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "DE7759959",
              "name": "Cosco Container Lines Co. Ltd",
              "scacCode": "COSU",
              "smdgCode": "COS",
              "cargoDeclarantShortName": "CROSSOCEANBV"
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "DFSU1734466",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-28T02:16:00Z",
            "stowageLocation": "0070606",
            "grossWeight": 29955,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "DE7759959",
              "name": "Cosco Container Lines Co. Ltd",
              "scacCode": "COSU",
              "smdgCode": "COS",
              "cargoDeclarantShortName": "CROSSOCEANBV"
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "EGHU9847175",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-28T02:20:00Z",
            "stowageLocation": "0160506",
            "grossWeight": 26768,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "DE7560192",
              "name": "Evergreen Marine Corp. Ltd.",
              "scacCode": "EGLV",
              "smdgCode": "EVG",
              "cargoDeclarantShortName": "EVG"
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TGBU5059434",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-28T02:30:00Z",
            "stowageLocation": "0160606",
            "grossWeight": 28946,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "DE7560192",
              "name": "Evergreen Marine Corp. Ltd.",
              "scacCode": "EGLV",
              "smdgCode": "EVG",
              "cargoDeclarantShortName": "EVG"
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "CAIU7413522",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-28T02:26:00Z",
            "stowageLocation": "0160006",
            "grossWeight": 28278,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "BEAU4026340",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-28T02:29:00Z",
            "stowageLocation": "0160406",
            "grossWeight": 28378,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "EGHU9608653",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-28T02:37:00Z",
            "stowageLocation": "0160104",
            "grossWeight": 26768,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "DE7560192",
              "name": "Evergreen Marine Corp. Ltd.",
              "scacCode": "EGLV",
              "smdgCode": "EVG",
              "cargoDeclarantShortName": "EVG"
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "EGHU9788961",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-28T02:27:00Z",
            "stowageLocation": "0160206",
            "grossWeight": 26768,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "DE7560192",
              "name": "Evergreen Marine Corp. Ltd.",
              "scacCode": "EGLV",
              "smdgCode": "EVG",
              "cargoDeclarantShortName": "EVG"
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "EISU8295588",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-28T02:35:00Z",
            "stowageLocation": "0160304",
            "grossWeight": 28756,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "DE7560192",
              "name": "Evergreen Marine Corp. Ltd.",
              "scacCode": "EGLV",
              "smdgCode": "EVG",
              "cargoDeclarantShortName": "EVG"
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "EISU8511429",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-28T02:24:00Z",
            "stowageLocation": "0160106",
            "grossWeight": 26768,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "DE7560192",
              "name": "Evergreen Marine Corp. Ltd.",
              "scacCode": "EGLV",
              "smdgCode": "EVG",
              "cargoDeclarantShortName": "EVG"
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TGBU9275576",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-28T02:40:00Z",
            "stowageLocation": "0160204",
            "grossWeight": 28756,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "DE7759959",
              "name": "Cosco Container Lines Co. Ltd",
              "scacCode": "COSU",
              "smdgCode": "COS",
              "cargoDeclarantShortName": "CROSSOCEANBV"
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TCNU5842028",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-28T02:33:00Z",
            "stowageLocation": "0160504",
            "grossWeight": 28508,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TLLU5417185",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-28T02:38:00Z",
            "stowageLocation": "0160004",
            "grossWeight": 28804,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "DE7560192",
              "name": "Evergreen Marine Corp. Ltd.",
              "scacCode": "EGLV",
              "smdgCode": "EVG",
              "cargoDeclarantShortName": "EVG"
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TCNU3369106",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-28T02:22:00Z",
            "stowageLocation": "0160306",
            "grossWeight": 26718,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "DE7560192",
              "name": "Evergreen Marine Corp. Ltd.",
              "scacCode": "EGLV",
              "smdgCode": "EVG",
              "cargoDeclarantShortName": "EVG"
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TCNU3210789",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-28T02:45:00Z",
            "stowageLocation": "0160604",
            "grossWeight": 26718,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "DE7759959",
              "name": "Cosco Container Lines Co. Ltd",
              "scacCode": "COSU",
              "smdgCode": "COS",
              "cargoDeclarantShortName": "CROSSOCEANBV"
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TRHU4649091",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-28T02:47:00Z",
            "stowageLocation": "0160502",
            "grossWeight": 26718,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "DE7759959",
              "name": "Cosco Container Lines Co. Ltd",
              "scacCode": "COSU",
              "smdgCode": "COS",
              "cargoDeclarantShortName": "CROSSOCEANBV"
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "CRSU9217159",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-28T02:57:00Z",
            "stowageLocation": "0160302",
            "grossWeight": 26878,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "CSLU6334590",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-28T02:59:00Z",
            "stowageLocation": "0160002",
            "grossWeight": 26818,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "DE7759959",
              "name": "Cosco Container Lines Co. Ltd",
              "scacCode": "COSU",
              "smdgCode": "COS",
              "cargoDeclarantShortName": "CROSSOCEANBV"
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "CSNU6252078",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-28T02:49:00Z",
            "stowageLocation": "0160102",
            "grossWeight": 26738,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "DE7759959",
              "name": "Cosco Container Lines Co. Ltd",
              "scacCode": "COSU",
              "smdgCode": "COS",
              "cargoDeclarantShortName": "CROSSOCEANBV"
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "UETU5531655",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-28T02:52:00Z",
            "stowageLocation": "0160202",
            "grossWeight": 26868,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "EISU9132688",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-28T02:42:00Z",
            "stowageLocation": "0160404",
            "grossWeight": 26783,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "DE7560192",
              "name": "Evergreen Marine Corp. Ltd.",
              "scacCode": "EGLV",
              "smdgCode": "EVG",
              "cargoDeclarantShortName": "EVG"
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TGCU5081215",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-28T02:55:00Z",
            "stowageLocation": "0160602",
            "grossWeight": 26908,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "DE7560192",
              "name": "Evergreen Marine Corp. Ltd.",
              "scacCode": "EGLV",
              "smdgCode": "EVG",
              "cargoDeclarantShortName": "EVG"
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "CCLU7776770",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-28T03:01:00Z",
            "stowageLocation": "0160402",
            "grossWeight": 26818,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "DE7759959",
              "name": "Cosco Container Lines Co. Ltd",
              "scacCode": "COSU",
              "smdgCode": "COS",
              "cargoDeclarantShortName": "CROSSOCEANBV"
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "CXRU1499271",
            "portOfLoading": {
              "name": "NOOSC",
              "locationUnCode": "NO888",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-28T03:05:00Z",
            "stowageLocation": "0260386",
            "grossWeight": 30070,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "DE7759959",
              "name": "Cosco Container Lines Co. Ltd",
              "scacCode": "COSU",
              "smdgCode": "COS",
              "cargoDeclarantShortName": "CROSSOCEANBV"
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "CBHU7053874",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-28T03:17:00Z",
            "stowageLocation": "0260184",
            "grossWeight": 32456,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "DE3626288",
              "name": "Diamond Line GmbH ( Cosco Europe)",
              "scacCode": "COEU",
              "smdgCode": "COE",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "OERU4038765",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-28T03:11:00Z",
            "stowageLocation": "0260286",
            "grossWeight": 30000,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "DE7759959",
              "name": "Cosco Container Lines Co. Ltd",
              "scacCode": "COSU",
              "smdgCode": "COS",
              "cargoDeclarantShortName": "CROSSOCEANBV"
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "UETU5215338",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-28T03:15:00Z",
            "stowageLocation": "0260384",
            "grossWeight": 32284,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "DE3626288",
              "name": "Diamond Line GmbH ( Cosco Europe)",
              "scacCode": "COEU",
              "smdgCode": "COE",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "EGHU1013664",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-28T03:03:00Z",
            "stowageLocation": "0260388",
            "grossWeight": 4200,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "42G1",
              "name": "40'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "DE7560192",
              "name": "Evergreen Marine Corp. Ltd.",
              "scacCode": "EGLV",
              "smdgCode": "EVG",
              "cargoDeclarantShortName": "EVG"
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "EGHU1057750",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-28T03:08:00Z",
            "stowageLocation": "0260188",
            "grossWeight": 4200,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "42G1",
              "name": "40'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "DE7560192",
              "name": "Evergreen Marine Corp. Ltd.",
              "scacCode": "EGLV",
              "smdgCode": "EVG",
              "cargoDeclarantShortName": "EVG"
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TEMU9123948",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-28T03:13:00Z",
            "stowageLocation": "0260486",
            "grossWeight": 30210,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "DE7759959",
              "name": "Cosco Container Lines Co. Ltd",
              "scacCode": "COSU",
              "smdgCode": "COS",
              "cargoDeclarantShortName": "CROSSOCEANBV"
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "CSNU6959374",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-28T03:19:00Z",
            "stowageLocation": "0260284",
            "grossWeight": 32494,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "DE3626288",
              "name": "Diamond Line GmbH ( Cosco Europe)",
              "scacCode": "COEU",
              "smdgCode": "COE",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "CCLU8609335",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-28T03:09:00Z",
            "stowageLocation": "0260186",
            "grossWeight": 30220,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45R1",
              "name": "40'x8'x9'6\" Refrigerated Container"
            },
            "containerOperator": {
              "customsId": "DE7759959",
              "name": "Cosco Container Lines Co. Ltd",
              "scacCode": "COSU",
              "smdgCode": "COS",
              "cargoDeclarantShortName": "CROSSOCEANBV"
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "BSIU9402796",
            "portOfLoading": {
              "name": "NOOSC",
              "locationUnCode": "NO888",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-28T03:21:00Z",
            "stowageLocation": "0260484",
            "grossWeight": 32242,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "DE3626288",
              "name": "Diamond Line GmbH ( Cosco Europe)",
              "scacCode": "COEU",
              "smdgCode": "COE",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TGHU5168416",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-28T03:27:00Z",
            "stowageLocation": "0300284",
            "grossWeight": 29089,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "42G1",
              "name": "40'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "GAOU6416589",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-28T03:37:00Z",
            "stowageLocation": "0260106",
            "grossWeight": 28800,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "DE7560192",
              "name": "Evergreen Marine Corp. Ltd.",
              "scacCode": "EGLV",
              "smdgCode": "EVG",
              "cargoDeclarantShortName": "EVG"
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "DFSU4283746",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-28T03:32:00Z",
            "stowageLocation": "0300282",
            "grossWeight": 29069,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "42G1",
              "name": "40'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TCLU4429115",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-28T03:35:00Z",
            "stowageLocation": "0300482",
            "grossWeight": 29029,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "42G1",
              "name": "40'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "APZU4586394",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-28T03:30:00Z",
            "stowageLocation": "0300484",
            "grossWeight": 29069,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "42G1",
              "name": "40'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TXGU5587007",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-28T03:39:00Z",
            "stowageLocation": "0260006",
            "grossWeight": 28900,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "DE7560192",
              "name": "Evergreen Marine Corp. Ltd.",
              "scacCode": "EGLV",
              "smdgCode": "EVG",
              "cargoDeclarantShortName": "EVG"
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "EGHU9666813",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-28T03:42:00Z",
            "stowageLocation": "0260206",
            "grossWeight": 28910,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "DE7560192",
              "name": "Evergreen Marine Corp. Ltd.",
              "scacCode": "EGLV",
              "smdgCode": "EVG",
              "cargoDeclarantShortName": "EVG"
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "EITU9236304",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-28T03:45:00Z",
            "stowageLocation": "0260306",
            "grossWeight": 29110,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "DE7560192",
              "name": "Evergreen Marine Corp. Ltd.",
              "scacCode": "EGLV",
              "smdgCode": "EVG",
              "cargoDeclarantShortName": "EVG"
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "EGHU9110125",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-28T03:44:00Z",
            "stowageLocation": "0260506",
            "grossWeight": 27820,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "DE7560192",
              "name": "Evergreen Marine Corp. Ltd.",
              "scacCode": "EGLV",
              "smdgCode": "EVG",
              "cargoDeclarantShortName": "EVG"
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "ESPU2045111",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-28T04:18:00Z",
            "stowageLocation": "0270004",
            "grossWeight": 12270,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "DRYU2445174",
            "portOfLoading": {
              "name": "NOOSC",
              "locationUnCode": "NO888",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-28T04:20:00Z",
            "stowageLocation": "0250004",
            "grossWeight": 28125,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "DE7560192",
              "name": "Evergreen Marine Corp. Ltd.",
              "scacCode": "EGLV",
              "smdgCode": "EVG",
              "cargoDeclarantShortName": "EVG"
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "FCIU5718623",
            "portOfLoading": {
              "name": "NOOSC",
              "locationUnCode": "NO888",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-28T04:26:00Z",
            "stowageLocation": "0250104",
            "grossWeight": 7686,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "DE7759959",
              "name": "Cosco Container Lines Co. Ltd",
              "scacCode": "COSU",
              "smdgCode": "COS",
              "cargoDeclarantShortName": "CROSSOCEANBV"
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "EGSU3044565",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-28T04:35:00Z",
            "stowageLocation": "0250202",
            "grossWeight": 29850,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "DE7560192",
              "name": "Evergreen Marine Corp. Ltd.",
              "scacCode": "EGLV",
              "smdgCode": "EVG",
              "cargoDeclarantShortName": "EVG"
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "EGHU3542570",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-28T04:33:00Z",
            "stowageLocation": "0250002",
            "grossWeight": 30050,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "DE7560192",
              "name": "Evergreen Marine Corp. Ltd.",
              "scacCode": "EGLV",
              "smdgCode": "EVG",
              "cargoDeclarantShortName": "EVG"
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TGHU1467933",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-28T04:29:00Z",
            "stowageLocation": "0250102",
            "grossWeight": 29850,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "DE7560192",
              "name": "Evergreen Marine Corp. Ltd.",
              "scacCode": "EGLV",
              "smdgCode": "EVG",
              "cargoDeclarantShortName": "EVG"
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "EMCU3911203",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-28T04:28:00Z",
            "stowageLocation": "0250204",
            "grossWeight": 8499,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "DE7560192",
              "name": "Evergreen Marine Corp. Ltd.",
              "scacCode": "EGLV",
              "smdgCode": "EVG",
              "cargoDeclarantShortName": "EVG"
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "EISU2194760",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-28T04:56:00Z",
            "stowageLocation": "0270002",
            "grossWeight": 29950,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "DE7560192",
              "name": "Evergreen Marine Corp. Ltd.",
              "scacCode": "EGLV",
              "smdgCode": "EVG",
              "cargoDeclarantShortName": "EVG"
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TCNU2139958",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-28T04:46:00Z",
            "stowageLocation": "0260606",
            "grossWeight": 28200,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "DE7560192",
              "name": "Evergreen Marine Corp. Ltd.",
              "scacCode": "EGLV",
              "smdgCode": "EVG",
              "cargoDeclarantShortName": "EVG"
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TCNU4999970",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-28T04:50:00Z",
            "stowageLocation": "0260604",
            "grossWeight": 28380,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "DE7560192",
              "name": "Evergreen Marine Corp. Ltd.",
              "scacCode": "EGLV",
              "smdgCode": "EVG",
              "cargoDeclarantShortName": "EVG"
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TGBU4444225",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-28T04:44:00Z",
            "stowageLocation": "0260406",
            "grossWeight": 28260,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "DE7560192",
              "name": "Evergreen Marine Corp. Ltd.",
              "scacCode": "EGLV",
              "smdgCode": "EVG",
              "cargoDeclarantShortName": "EVG"
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "EITU0191338",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-28T04:54:00Z",
            "stowageLocation": "0270204",
            "grossWeight": 28865,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "DE7560192",
              "name": "Evergreen Marine Corp. Ltd.",
              "scacCode": "EGLV",
              "smdgCode": "EVG",
              "cargoDeclarantShortName": "EVG"
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "EITU1141389",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-28T04:41:00Z",
            "stowageLocation": "0260504",
            "grossWeight": 28540,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "DE7560192",
              "name": "Evergreen Marine Corp. Ltd.",
              "scacCode": "EGLV",
              "smdgCode": "EVG",
              "cargoDeclarantShortName": "EVG"
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "EITU9403253",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-28T04:48:00Z",
            "stowageLocation": "0260404",
            "grossWeight": 29050,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "DE7560192",
              "name": "Evergreen Marine Corp. Ltd.",
              "scacCode": "EGLV",
              "smdgCode": "EVG",
              "cargoDeclarantShortName": "EVG"
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "DFSU1010239",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-28T04:52:00Z",
            "stowageLocation": "0270104",
            "grossWeight": 28825,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "DE7560192",
              "name": "Evergreen Marine Corp. Ltd.",
              "scacCode": "EGLV",
              "smdgCode": "EVG",
              "cargoDeclarantShortName": "EVG"
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "DRYU9428245",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "YANGTZEK EUROMAX",
              "terminalCode": "74353661",
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax Terminal B.V."
            },
            "actualTimeOfHandling": "2021-09-28T04:43:00Z",
            "stowageLocation": "0260304",
            "grossWeight": 29800,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "DE7560192",
              "name": "Evergreen Marine Corp. Ltd.",
              "scacCode": "EGLV",
              "smdgCode": "EVG",
              "cargoDeclarantShortName": "EVG"
            },
            "cargoAgentShortName": "UNIFEEDER",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          }
        ],
        "bulkDischarges": [],
        "consignmentDifferences": [],
        "clearedManifestIds": [],
        "inspectionUpdates": [],
        "declarations": [
          {
            "type": "SDT",
            "id": "NOOSL",
            "status": "ACCEPTED",
            "rejectReasons": null,
            "timeStamp": "2021-09-24T08:19:45.690Z"
          },
          {
            "type": "SDT",
            "id": "NOOSL",
            "status": "ACCEPTED",
            "rejectReasons": null,
            "timeStamp": "2021-09-24T08:19:56.237Z"
          },
          {
            "type": "SDT",
            "id": "NOOSL",
            "status": "ACCEPTED",
            "rejectReasons": null,
            "timeStamp": "2021-09-24T08:20:42.876Z"
          },
          {
            "type": "SDT",
            "id": "NOBVK",
            "status": "ACCEPTED",
            "rejectReasons": null,
            "timeStamp": "2021-09-27T07:09:06.203Z"
          },
          {
            "type": "SDT",
            "id": "NOFRK",
            "status": "ACCEPTED",
            "rejectReasons": null,
            "timeStamp": "2021-09-27T07:09:06.203Z"
          },
          {
            "type": "SDT",
            "id": "NOBVK",
            "status": "ACCEPTED",
            "rejectReasons": null,
            "timeStamp": "2021-09-27T09:27:25.715Z"
          },
          {
            "type": "SDT",
            "id": "NOFRK",
            "status": "ACCEPTED",
            "rejectReasons": null,
            "timeStamp": "2021-09-27T09:27:25.715Z"
          },
          {
            "type": "SDT",
            "id": "NOOSL",
            "status": "ACCEPTED",
            "rejectReasons": null,
            "timeStamp": "2021-09-27T09:27:25.715Z"
          }
        ],
        "incomingMessages": [
          {
            "type": "IFTMCS",
            "id": "UFEE6311343A",
            "processId": "28fa2360a76f446faba7c16571b503d3",
            "timeStamp": "2021-09-24T08:19:45.690Z"
          },
          {
            "type": "IFTMCS",
            "id": "UFEE6176428A",
            "processId": "829e2e3735be48ce8d667d2d5130be40",
            "timeStamp": "2021-09-24T08:19:56.237Z"
          },
          {
            "type": "IFTMCS",
            "id": "UFEE6316367A",
            "processId": "45903e9e1a194ebdb12fd05992c20296",
            "timeStamp": "2021-09-24T08:19:56.237Z"
          },
          {
            "type": "IFTMCS",
            "id": "UFEE6317390A",
            "processId": "be0658d01e5849a89f17366623bc876f",
            "timeStamp": "2021-09-24T08:19:56.237Z"
          },
          {
            "type": "IFTMCS",
            "id": "UFEE6294120A",
            "processId": "40ce3de75a854817bc2b0b9e65e900b7",
            "timeStamp": "2021-09-24T08:19:56.237Z"
          },
          {
            "type": "IFTMCS",
            "id": "UFEE6310460A",
            "processId": "6d8628c28a794e3e9f00843597c9d5f0",
            "timeStamp": "2021-09-24T08:19:56.237Z"
          },
          {
            "type": "IFTMCS",
            "id": "UFEE6261364A",
            "processId": "521d784311df4a7eb8d733905c56fe9c",
            "timeStamp": "2021-09-24T08:19:56.237Z"
          },
          {
            "type": "IFTMCS",
            "id": "UFEE6285359A",
            "processId": "10d27091999d4d40962ee73a7f223f3b",
            "timeStamp": "2021-09-24T08:19:56.237Z"
          },
          {
            "type": "IFTMCS",
            "id": "UFEE6316425A",
            "processId": "e35a33b8d3a4410097ca7d2fee115d61",
            "timeStamp": "2021-09-24T08:19:56.237Z"
          },
          {
            "type": "IFTMCS",
            "id": "UFEE6310463A",
            "processId": "d46b5deef5404e7cb2f00af67b24d5e6",
            "timeStamp": "2021-09-24T08:19:56.237Z"
          },
          {
            "type": "IFTMCS",
            "id": "UFEE6282106A",
            "processId": "1e4e698c0ea945c186e2926a2feb26e8",
            "timeStamp": "2021-09-24T08:19:56.237Z"
          },
          {
            "type": "IFTMCS",
            "id": "UFEE6282569A",
            "processId": "1e5b773d3c5a4c258448c448887c0c0a",
            "timeStamp": "2021-09-24T08:19:56.237Z"
          },
          {
            "type": "IFTMCS",
            "id": "UFEE6308714A",
            "processId": "8e788d9ebb264a3ea12c7051a06eb158",
            "timeStamp": "2021-09-24T08:19:56.237Z"
          },
          {
            "type": "IFTMCS",
            "id": "UFEE6295572A",
            "processId": "ffbf908a010645aea509a71f262083c3",
            "timeStamp": "2021-09-24T08:19:56.237Z"
          },
          {
            "type": "IFTMCS",
            "id": "UFEE6309920A",
            "processId": "923a3c1b0c8e4445b71b4695965a61a6",
            "timeStamp": "2021-09-24T08:19:56.237Z"
          },
          {
            "type": "IFTMCS",
            "id": "UFEE6311727A",
            "processId": "8aead96761b64ff587ed732c6b84d182",
            "timeStamp": "2021-09-24T08:19:56.237Z"
          },
          {
            "type": "IFTMCS",
            "id": "UFEE6274240A",
            "processId": "68cc4169d8864921b7a94719a2156830",
            "timeStamp": "2021-09-24T08:19:56.237Z"
          },
          {
            "type": "IFTMCS",
            "id": "UFEE6310864A",
            "processId": "7579113c23d241aab5bd6aa363827a62",
            "timeStamp": "2021-09-24T08:19:56.237Z"
          },
          {
            "type": "IFTMCS",
            "id": "UFEE6316985A",
            "processId": "f34f59a478be4cdfb47a4accc137126f",
            "timeStamp": "2021-09-24T08:19:56.237Z"
          },
          {
            "type": "IFTMCS",
            "id": "UFEE6303899A",
            "processId": "64dc58fc918841df8f8a99648fa98c78",
            "timeStamp": "2021-09-24T08:19:56.237Z"
          },
          {
            "type": "IFTMCS",
            "id": "UFEE6312893A",
            "processId": "3bab41c4334b4a1e9a1de65b9049ef33",
            "timeStamp": "2021-09-24T08:19:56.237Z"
          },
          {
            "type": "IFTMCS",
            "id": "UFEE6296844A",
            "processId": "04e14f1198944723b2298aa08864904d",
            "timeStamp": "2021-09-24T08:19:56.237Z"
          },
          {
            "type": "IFTMCS",
            "id": "UFEE6308871A",
            "processId": "37e169edbce44c7b89f1a9bccdd5b67d",
            "timeStamp": "2021-09-24T08:19:56.237Z"
          },
          {
            "type": "IFTMCS",
            "id": "UFEE6313495A",
            "processId": "7c9fde99596c4941b20e95f37df6bef2",
            "timeStamp": "2021-09-24T08:19:56.237Z"
          },
          {
            "type": "IFTMCS",
            "id": "UFEE6284765A",
            "processId": "f88a4e1b7d094aab844e101109dfeb0d",
            "timeStamp": "2021-09-24T08:20:42.876Z"
          },
          {
            "type": "IFTMCS",
            "id": "UFEE6311512A",
            "processId": "f3769208f5c649929b0b26cff9536e7d",
            "timeStamp": "2021-09-24T08:20:42.876Z"
          },
          {
            "type": "IFTMCS",
            "id": "UFEE6285314A",
            "processId": "ec6f2268073e41aeaf224ae4256f96da",
            "timeStamp": "2021-09-24T08:20:42.876Z"
          },
          {
            "type": "IFTMCS",
            "id": "UFEE6313201A",
            "processId": "704651c4e39941f292f95c68c9697c49",
            "timeStamp": "2021-09-24T08:20:42.876Z"
          },
          {
            "type": "IFTMCS",
            "id": "UFEE6303272A",
            "processId": "4f78a912256d439289ead318c367d1d4",
            "timeStamp": "2021-09-27T07:09:06.203Z"
          },
          {
            "type": "IFTMCS",
            "id": "UFEE6239742A",
            "processId": "9700758ea4f74e2b8ebad001665eef86",
            "timeStamp": "2021-09-27T07:09:06.203Z"
          },
          {
            "type": "IFTMCS",
            "id": "UFEE6303229A",
            "processId": "43e71a87073c4dfeb6a4201e1b8c20d9",
            "timeStamp": "2021-09-27T07:09:06.203Z"
          },
          {
            "type": "IFTMCS",
            "id": "UFEE6260984A",
            "processId": "ded4ba8a56f04ddcaf3bcc12a87b7e17",
            "timeStamp": "2021-09-27T07:09:06.203Z"
          },
          {
            "type": "IFTMCS",
            "id": "UFEE6215182A",
            "processId": "c9a915592fcc4910962a101f126d328b",
            "timeStamp": "2021-09-27T07:09:06.203Z"
          },
          {
            "type": "IFTMCS",
            "id": "UFEE6280672A",
            "processId": "e322af690cfc48bc801bbf8b830374be",
            "timeStamp": "2021-09-27T07:09:06.203Z"
          },
          {
            "type": "IFTMCS",
            "id": "UFEE6282042A",
            "processId": "7be8aba141f54ad38f74299bf4626b6a",
            "timeStamp": "2021-09-27T07:09:06.203Z"
          },
          {
            "type": "IFTMCS",
            "id": "UFEE6280370A",
            "processId": "275c5b49b5af49c3831d481bac542ef8",
            "timeStamp": "2021-09-27T07:09:06.203Z"
          },
          {
            "type": "IFTMCS",
            "id": "UFEE6303274A",
            "processId": "cf12b1426f0b414baec1e083fb9b7a67",
            "timeStamp": "2021-09-27T07:09:06.203Z"
          },
          {
            "type": "IFTMCS",
            "id": "UFEE6205281A",
            "processId": "12cfc45dbd5242f7ad31977bd14bb9e4",
            "timeStamp": "2021-09-27T07:09:06.203Z"
          },
          {
            "type": "IFTMCS",
            "id": "UFEE6274779A",
            "processId": "264ab559288b4f589a278b6296f49af0",
            "timeStamp": "2021-09-27T07:09:06.203Z"
          },
          {
            "type": "IFTMCS",
            "id": "UFEE6271262A",
            "processId": "9748af1975024f968ee95a588c5ef006",
            "timeStamp": "2021-09-27T07:09:06.203Z"
          },
          {
            "type": "IFTMCS",
            "id": "UFEE6288630A",
            "processId": "d63f3ad69a314b4fad986a8bbb1f1a6d",
            "timeStamp": "2021-09-27T07:09:06.203Z"
          },
          {
            "type": "IFTMCS",
            "id": "UFEE6263569A",
            "processId": "f9d51f57bf9d4b43b84433795c765310",
            "timeStamp": "2021-09-27T07:09:06.203Z"
          },
          {
            "type": "IFTMCS",
            "id": "UFEE6290569A",
            "processId": "7852e216f47640a699c39c2a2c38c6cd",
            "timeStamp": "2021-09-27T07:09:06.203Z"
          },
          {
            "type": "IFTMCS",
            "id": "UFEE6260161A",
            "processId": "ddb9ca00d28f48769ffc4080d2a9b170",
            "timeStamp": "2021-09-27T07:09:06.203Z"
          },
          {
            "type": "IFTMCS",
            "id": "UFEE6317399A",
            "processId": "ca92c177a22945dfa30a7fb77ab52d7d",
            "timeStamp": "2021-09-27T07:09:06.203Z"
          },
          {
            "type": "IFTMCS",
            "id": "UFEE6265442A",
            "processId": "902b1d9d5dc34fa5a2c2dc728c3f4f30",
            "timeStamp": "2021-09-27T07:09:06.203Z"
          },
          {
            "type": "IFTMCS",
            "id": "UFEE6242520A",
            "processId": "0719fc3a230b4b4d9034e2c220df6f3c",
            "timeStamp": "2021-09-27T07:09:06.203Z"
          },
          {
            "type": "IFTMCS",
            "id": "UFEE6258569A",
            "processId": "9304bec5a2754d798667d901fb1af35a",
            "timeStamp": "2021-09-27T07:09:06.203Z"
          },
          {
            "type": "IFTMCS",
            "id": "UFEE6286398A",
            "processId": "78e4223d0ac8429dbebeaadc6e05e789",
            "timeStamp": "2021-09-27T07:09:06.203Z"
          },
          {
            "type": "IFTMCS",
            "id": "UFEE6303726A",
            "processId": "7d60113132f04ca49239782114f60e60",
            "timeStamp": "2021-09-27T07:09:06.203Z"
          },
          {
            "type": "IFTMCS",
            "id": "UFEE6260038A",
            "processId": "918d9cb2e69d4947a7124c5caa19ba65",
            "timeStamp": "2021-09-27T07:09:06.203Z"
          },
          {
            "type": "IFTMCS",
            "id": "UFEE6258866A",
            "processId": "ce5339d9ef6b4cdfbb223a4839ed901b",
            "timeStamp": "2021-09-27T07:09:06.203Z"
          },
          {
            "type": "IFTMCS",
            "id": "UFEE6233662A",
            "processId": "a87477bf27014010931da19f8dcb8e0a",
            "timeStamp": "2021-09-27T07:09:06.203Z"
          },
          {
            "type": "IFTMCS",
            "id": "UFEE6222913A",
            "processId": "d16a650167d245769360ced5b69841a3",
            "timeStamp": "2021-09-27T07:09:06.203Z"
          },
          {
            "type": "IFTMCS",
            "id": "UFEE6315479A",
            "processId": "1cbc2974d98a47b69abf483e1fad2f83",
            "timeStamp": "2021-09-27T07:09:06.203Z"
          },
          {
            "type": "IFTMCS",
            "id": "UFEE6280399A",
            "processId": "dce60659c8104835ab85eaa2333744e3",
            "timeStamp": "2021-09-27T07:09:06.203Z"
          },
          {
            "type": "IFTMCS",
            "id": "UFEE6295927A",
            "processId": "7bebb22b52114708a18f2d3b3f8e38de",
            "timeStamp": "2021-09-27T07:09:06.203Z"
          },
          {
            "type": "IFTMCS",
            "id": "UFEE6260163A",
            "processId": "2404f689a7454cde9b5e6c4b7c308c73",
            "timeStamp": "2021-09-27T07:09:06.203Z"
          },
          {
            "type": "IFTMCS",
            "id": "UFEE6322760A",
            "processId": "61125529975e4de785f9c54da8e7cd1d",
            "timeStamp": "2021-09-27T07:09:06.203Z"
          },
          {
            "type": "IFTMCS",
            "id": "UFEE6283876A",
            "processId": "7acebf891e91491a95f5f8b14826a143",
            "timeStamp": "2021-09-27T07:09:06.203Z"
          },
          {
            "type": "IFTMCS",
            "id": "UFEE6262328A",
            "processId": "171d39c6c0094369aa2e1777d7d6314e",
            "timeStamp": "2021-09-27T07:09:06.203Z"
          },
          {
            "type": "IFTMCS",
            "id": "UFEE6320372A",
            "processId": "e9c94dfd977c480fa42f522cc5489e62",
            "timeStamp": "2021-09-27T07:09:06.203Z"
          },
          {
            "type": "IFTMCS",
            "id": "UFEE6237971A",
            "processId": "4544fa2990b04a33bc16f1bf433d37c6",
            "timeStamp": "2021-09-27T07:09:06.203Z"
          },
          {
            "type": "IFTMCS",
            "id": "UFEE6221065A",
            "processId": "fab4a1ab31a140fea51c7ced714a7cf9",
            "timeStamp": "2021-09-27T07:09:06.203Z"
          },
          {
            "type": "IFTMCS",
            "id": "UFEE6288108A",
            "processId": "fcd04e68773d423ba738625161952b1a",
            "timeStamp": "2021-09-27T07:09:06.203Z"
          },
          {
            "type": "IFTMCS",
            "id": "UFEE6274613A",
            "processId": "9ee964d27e1b4d91ae2e298a75f6e449",
            "timeStamp": "2021-09-27T07:09:06.203Z"
          },
          {
            "type": "IFTMCS",
            "id": "UFEE6323177A",
            "processId": "d7cc30e4627c4ae49515bed1b0283c8a",
            "timeStamp": "2021-09-27T07:09:06.203Z"
          },
          {
            "type": "IFTMCS",
            "id": "UFEE6280368A",
            "processId": "44f705f71d8446efbed9266431ee5fd3",
            "timeStamp": "2021-09-27T07:09:06.203Z"
          },
          {
            "type": "IFTMCS",
            "id": "UFEE6319955A",
            "processId": "c47bf8b5e60f4702a8d47ec0bbe0e984",
            "timeStamp": "2021-09-27T07:09:06.203Z"
          }
        ]
      },
      {
        "cargoDeclarant": {
          "fullName": "DFDS logistics B.V.",
          "shortName": "DFDSCL",
          "portAuthorityId": "DFDS CONT-LINE",
          "emailAddress": "mbec@dfds.com",
          "address": "Burgemeester van Lierplein 57",
          "city": "Vlaardingen",
          "countryUnCode": "NL",
          "countryName": "Netherlands",
          "zipCode": "3134 ZB",
          "phoneNumber": "+31105032300",
          "faxNumber": null,
          "contact": "M.L. Bech",
          "customsEORINumber": "NL003189223",
          "ean": "8713755281502",
          "chamberOfCommerceNumber": "24277441",
          "scacCode": "DFDN",
          "ensEnabled": true,
          "cargoImportEnabled": true,
          "cargoImportEnabledByDefault": true
        },
        "timestamp": "2021-09-28T05:04:52.793Z",
        "containers": [
          {
            "number": "ABEU2333652",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22K4",
              "name": "20'x8'x8'6\" Tank Container"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "tareWeight": 3000,
            "bookingReference": "DFDN0056907749",
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": {
              "modeOfTransport": "ROAD_TRANSPORT",
              "vessel": null,
              "radioCallSign": null,
              "voyageNumber": null,
              "plannedDeparture": null
            },
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "BNSU2034271",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "tareWeight": 2000,
            "bookingReference": "DFDN0056843651",
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": {
              "modeOfTransport": "ROAD_TRANSPORT",
              "vessel": null,
              "radioCallSign": null,
              "voyageNumber": null,
              "plannedDeparture": null
            },
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "BNSU2140139",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "tareWeight": 2000,
            "bookingReference": "DFDN0056843638",
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": {
              "modeOfTransport": "ROAD_TRANSPORT",
              "vessel": null,
              "radioCallSign": null,
              "voyageNumber": null,
              "plannedDeparture": null
            },
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "BNSU3057380",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "tareWeight": 2000,
            "bookingReference": "DFDN0056843639",
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": {
              "modeOfTransport": "ROAD_TRANSPORT",
              "vessel": null,
              "radioCallSign": null,
              "voyageNumber": null,
              "plannedDeparture": null
            },
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "BNSU3260674",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "tareWeight": 2000,
            "bookingReference": "DFDN0056843647",
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": {
              "modeOfTransport": "ROAD_TRANSPORT",
              "vessel": null,
              "radioCallSign": null,
              "voyageNumber": null,
              "plannedDeparture": null
            },
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "BNSU3840158",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "tareWeight": 2000,
            "bookingReference": "DFDN0056843646",
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": {
              "modeOfTransport": "ROAD_TRANSPORT",
              "vessel": null,
              "radioCallSign": null,
              "voyageNumber": null,
              "plannedDeparture": null
            },
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "BNSU3865747",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "tareWeight": 2000,
            "bookingReference": "DFDN0056843644",
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": {
              "modeOfTransport": "ROAD_TRANSPORT",
              "vessel": null,
              "radioCallSign": null,
              "voyageNumber": null,
              "plannedDeparture": null
            },
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "BNSU9943913",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "tareWeight": 2000,
            "bookingReference": "DFDN0056843649",
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": {
              "modeOfTransport": "ROAD_TRANSPORT",
              "vessel": null,
              "radioCallSign": null,
              "voyageNumber": null,
              "plannedDeparture": null
            },
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "BTEU2840397",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "EDKD",
              "name": "25'x8'x9'6\" Palletwide Tank Container"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "tareWeight": 3000,
            "bookingReference": "DFDN0056911180",
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": {
              "modeOfTransport": "ROAD_TRANSPORT",
              "vessel": null,
              "radioCallSign": null,
              "voyageNumber": null,
              "plannedDeparture": null
            },
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "BTEU2840592",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "EDKM",
              "name": "20'x8'x9' Tank Container"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "tareWeight": 3000,
            "bookingReference": "DFDN0056911178",
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": {
              "modeOfTransport": "ROAD_TRANSPORT",
              "vessel": null,
              "radioCallSign": null,
              "voyageNumber": null,
              "plannedDeparture": null
            },
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "BTEU3420315",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "3DBA",
              "name": "30'x8'x8'6\" Bulk Container"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "tareWeight": 3000,
            "bookingReference": "DFDN0056908589",
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": {
              "modeOfTransport": "ROAD_TRANSPORT",
              "vessel": null,
              "radioCallSign": null,
              "voyageNumber": null,
              "plannedDeparture": null
            },
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "BTEU3426289",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "3DBA",
              "name": "30'x8'x8'6\" Bulk Container"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "tareWeight": 3000,
            "bookingReference": "DFDN0056907751",
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": {
              "modeOfTransport": "ROAD_TRANSPORT",
              "vessel": null,
              "radioCallSign": null,
              "voyageNumber": null,
              "plannedDeparture": null
            },
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "BTEU3909435",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "3EBA",
              "name": "30'x8'x9'6\" BU High Cube bulkcontainer"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "tareWeight": 3000,
            "bookingReference": "DFDN0056876546",
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": {
              "modeOfTransport": "ROAD_TRANSPORT",
              "vessel": null,
              "radioCallSign": null,
              "voyageNumber": null,
              "plannedDeparture": null
            },
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "CLXU4516055",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEG1",
              "name": "45 ft 9.6 PW"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "tareWeight": 3000,
            "bookingReference": "DFDN0056943167",
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": {
              "modeOfTransport": "ROAD_TRANSPORT",
              "vessel": null,
              "radioCallSign": null,
              "voyageNumber": null,
              "plannedDeparture": null
            },
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "CLXU4516836",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEG1",
              "name": "45 ft 9.6 PW"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "tareWeight": 3000,
            "bookingReference": "DFDN0056943237",
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": {
              "modeOfTransport": "ROAD_TRANSPORT",
              "vessel": null,
              "radioCallSign": null,
              "voyageNumber": null,
              "plannedDeparture": null
            },
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "CNEU4530153",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEG1",
              "name": "45 ft 9.6 PW"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "tareWeight": 3000,
            "bookingReference": "DFDN0056943239",
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": {
              "modeOfTransport": "ROAD_TRANSPORT",
              "vessel": null,
              "radioCallSign": null,
              "voyageNumber": null,
              "plannedDeparture": null
            },
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "CNEU4535242",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEG1",
              "name": "45 ft 9.6 PW"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "tareWeight": 3000,
            "bookingReference": "DFDN0056943221",
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": {
              "modeOfTransport": "ROAD_TRANSPORT",
              "vessel": null,
              "radioCallSign": null,
              "voyageNumber": null,
              "plannedDeparture": null
            },
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "CNEU4583997",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEG1",
              "name": "45 ft 9.6 PW"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "tareWeight": 3000,
            "bookingReference": "DFDN0056943174",
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": {
              "modeOfTransport": "ROAD_TRANSPORT",
              "vessel": null,
              "radioCallSign": null,
              "voyageNumber": null,
              "plannedDeparture": null
            },
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "CRTU0600767",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEG1",
              "name": "45 ft 9.6 PW"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "tareWeight": 4790,
            "bookingReference": "DFDN0056769275",
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": {
              "modeOfTransport": "ROAD_TRANSPORT",
              "vessel": null,
              "radioCallSign": null,
              "voyageNumber": null,
              "plannedDeparture": null
            },
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "CRXU0653699",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEG1",
              "name": "45 ft 9.6 PW"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "tareWeight": 3000,
            "bookingReference": "DFDN0056943226",
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": {
              "modeOfTransport": "ROAD_TRANSPORT",
              "vessel": null,
              "radioCallSign": null,
              "voyageNumber": null,
              "plannedDeparture": null
            },
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "DHBU3170321",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "3MBJ",
              "name": "30'x>8'x9'6\" Open Top Container"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "tareWeight": 3000,
            "bookingReference": "DFDN0056876795",
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": {
              "modeOfTransport": "ROAD_TRANSPORT",
              "vessel": null,
              "radioCallSign": null,
              "voyageNumber": null,
              "plannedDeparture": null
            },
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "DHBU3181373",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "3MGY",
              "name": "30'x8'x9'6\" Bulk unit"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "tareWeight": 3000,
            "bookingReference": "DFDN0056911072",
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": {
              "modeOfTransport": "ROAD_TRANSPORT",
              "vessel": null,
              "radioCallSign": null,
              "voyageNumber": null,
              "plannedDeparture": null
            },
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "DHDU1255474",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22T1",
              "name": "20'x8'x8'6\" Tank Container Test pressure 1,5 bar"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "tareWeight": 3000,
            "bookingReference": "DFDN0056940774",
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": {
              "modeOfTransport": "ROAD_TRANSPORT",
              "vessel": null,
              "radioCallSign": null,
              "voyageNumber": null,
              "plannedDeparture": null
            },
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "DHIU1845442",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "EMT5",
              "name": "25ft tank container 400 kPa"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "tareWeight": 3000,
            "bookingReference": "DFDN0056908315",
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": {
              "modeOfTransport": "ROAD_TRANSPORT",
              "vessel": null,
              "radioCallSign": null,
              "voyageNumber": null,
              "plannedDeparture": null
            },
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "EXFU5627098",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22K2",
              "name": "20 x 8,6 Liquid tank dangerous gds 2.65-10 bar"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "tareWeight": 3000,
            "bookingReference": "DFDN0056886818",
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": {
              "modeOfTransport": "ROAD_TRANSPORT",
              "vessel": null,
              "radioCallSign": null,
              "voyageNumber": null,
              "plannedDeparture": null
            },
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "EXFU8779432",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "AMKD",
              "name": "23'tank 9.0ft extra breed"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "tareWeight": 3000,
            "bookingReference": "DFDN0056940777",
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": {
              "modeOfTransport": "ROAD_TRANSPORT",
              "vessel": null,
              "radioCallSign": null,
              "voyageNumber": null,
              "plannedDeparture": null
            },
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "GMCU1260911",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22T1",
              "name": "20'x8'x8'6\" Tank Container Test pressure 1,5 bar"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "tareWeight": 3000,
            "bookingReference": "DFDN0056860292",
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": {
              "modeOfTransport": "ROAD_TRANSPORT",
              "vessel": null,
              "radioCallSign": null,
              "voyageNumber": null,
              "plannedDeparture": null
            },
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "INGU7351977",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "EMT6",
              "name": "25ft tank container 400 kPa"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "tareWeight": 3000,
            "bookingReference": "DFDN0056849207",
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": {
              "modeOfTransport": "ROAD_TRANSPORT",
              "vessel": null,
              "radioCallSign": null,
              "voyageNumber": null,
              "plannedDeparture": null
            },
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "LAFU3220164",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-24T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "EDKA",
              "name": "20'x8'x9' Tank Container Tare 2500 kg"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "tareWeight": 3000,
            "bookingReference": "DFDN0056858939",
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": {
              "modeOfTransport": "ROAD_TRANSPORT",
              "vessel": null,
              "radioCallSign": null,
              "voyageNumber": null,
              "plannedDeparture": null
            },
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "LYSU0455294",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "LNRB",
              "name": "45'x8'x9'6\" Thermal Container"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "tareWeight": 3000,
            "bookingReference": "DFDN0056942146",
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": {
              "modeOfTransport": "ROAD_TRANSPORT",
              "vessel": null,
              "radioCallSign": null,
              "voyageNumber": null,
              "plannedDeparture": null
            },
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "LYSU5453680",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEG1",
              "name": "45 ft 9.6 PW"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "tareWeight": 3000,
            "bookingReference": "DFDN0056943227",
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": {
              "modeOfTransport": "ROAD_TRANSPORT",
              "vessel": null,
              "radioCallSign": null,
              "voyageNumber": null,
              "plannedDeparture": null
            },
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "LYSU5454393",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEG1",
              "name": "45 ft 9.6 PW"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "tareWeight": 3000,
            "bookingReference": "DFDN0056942068",
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": {
              "modeOfTransport": "ROAD_TRANSPORT",
              "vessel": null,
              "radioCallSign": null,
              "voyageNumber": null,
              "plannedDeparture": null
            },
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "LYSU5454428",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEG1",
              "name": "45 ft 9.6 PW"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "tareWeight": 3000,
            "bookingReference": "DFDN0056943235",
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": {
              "modeOfTransport": "ROAD_TRANSPORT",
              "vessel": null,
              "radioCallSign": null,
              "voyageNumber": null,
              "plannedDeparture": null
            },
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "LYSU5455785",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEG1",
              "name": "45 ft 9.6 PW"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "tareWeight": 4280,
            "bookingReference": "DFDN0056610514",
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": {
              "modeOfTransport": "ROAD_TRANSPORT",
              "vessel": null,
              "radioCallSign": null,
              "voyageNumber": null,
              "plannedDeparture": null
            },
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "LYSU5456077",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEG3",
              "name": "LEG3"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "tareWeight": 4320,
            "bookingReference": "DFDN0056933676",
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": {
              "modeOfTransport": "ROAD_TRANSPORT",
              "vessel": null,
              "radioCallSign": null,
              "voyageNumber": null,
              "plannedDeparture": null
            },
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "LYSU5456502",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEG3",
              "name": "LEG3"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "tareWeight": 3000,
            "bookingReference": "DFDN0056943169",
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": {
              "modeOfTransport": "ROAD_TRANSPORT",
              "vessel": null,
              "radioCallSign": null,
              "voyageNumber": null,
              "plannedDeparture": null
            },
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "LYSU5458444",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEG1",
              "name": "45 ft 9.6 PW"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "tareWeight": 3000,
            "bookingReference": "DFDN0056943173",
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": {
              "modeOfTransport": "ROAD_TRANSPORT",
              "vessel": null,
              "radioCallSign": null,
              "voyageNumber": null,
              "plannedDeparture": null
            },
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "LYSU5458716",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEG1",
              "name": "45 ft 9.6 PW"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "tareWeight": 3000,
            "bookingReference": "DFDN0056943178",
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": {
              "modeOfTransport": "ROAD_TRANSPORT",
              "vessel": null,
              "radioCallSign": null,
              "voyageNumber": null,
              "plannedDeparture": null
            },
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "LYSU5458927",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEG1",
              "name": "45 ft 9.6 PW"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "tareWeight": 3000,
            "bookingReference": "DFDN0056943161",
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": {
              "modeOfTransport": "ROAD_TRANSPORT",
              "vessel": null,
              "radioCallSign": null,
              "voyageNumber": null,
              "plannedDeparture": null
            },
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "LYSU5459775",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEG1",
              "name": "45 ft 9.6 PW"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "tareWeight": 3000,
            "bookingReference": "DFDN0056943186",
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": {
              "modeOfTransport": "ROAD_TRANSPORT",
              "vessel": null,
              "radioCallSign": null,
              "voyageNumber": null,
              "plannedDeparture": null
            },
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "LYSU5460924",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEG1",
              "name": "45 ft 9.6 PW"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "tareWeight": 3000,
            "bookingReference": "DFDN0056943187",
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": {
              "modeOfTransport": "ROAD_TRANSPORT",
              "vessel": null,
              "radioCallSign": null,
              "voyageNumber": null,
              "plannedDeparture": null
            },
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "LYSU5462130",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEG1",
              "name": "45 ft 9.6 PW"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "tareWeight": 3000,
            "bookingReference": "DFDN0056943232",
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": {
              "modeOfTransport": "ROAD_TRANSPORT",
              "vessel": null,
              "radioCallSign": null,
              "voyageNumber": null,
              "plannedDeparture": null
            },
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "LYSU9960614",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "4EP3",
              "name": "4EP3"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "tareWeight": 3000,
            "bookingReference": "DFDN0056943262",
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": {
              "modeOfTransport": "ROAD_TRANSPORT",
              "vessel": null,
              "radioCallSign": null,
              "voyageNumber": null,
              "plannedDeparture": null
            },
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "LYSU9960682",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "4EP3",
              "name": "4EP3"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "tareWeight": 3000,
            "bookingReference": "DFDN0056943263",
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": {
              "modeOfTransport": "ROAD_TRANSPORT",
              "vessel": null,
              "radioCallSign": null,
              "voyageNumber": null,
              "plannedDeparture": null
            },
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "MEBU1261684",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22T1",
              "name": "20'x8'x8'6\" Tank Container Test pressure 1,5 bar"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "tareWeight": 3000,
            "bookingReference": "DFDN0056909583",
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": {
              "modeOfTransport": "ROAD_TRANSPORT",
              "vessel": null,
              "radioCallSign": null,
              "voyageNumber": null,
              "plannedDeparture": null
            },
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "MEBU1264214",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22K2",
              "name": "20 x 8,6 Liquid tank dangerous gds 2.65-10 bar"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "tareWeight": 3000,
            "bookingReference": "DFDN0056909589",
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": {
              "modeOfTransport": "ROAD_TRANSPORT",
              "vessel": null,
              "radioCallSign": null,
              "voyageNumber": null,
              "plannedDeparture": null
            },
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "NFLU4500640",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "LNR1",
              "name": "LNR1"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "tareWeight": 3000,
            "bookingReference": "DFDN0056943255",
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": {
              "modeOfTransport": "ROAD_TRANSPORT",
              "vessel": null,
              "radioCallSign": null,
              "voyageNumber": null,
              "plannedDeparture": null
            },
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "NFLU4500701",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "LNR1",
              "name": "LNR1"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "tareWeight": 3000,
            "bookingReference": "DFDN0056942104",
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": {
              "modeOfTransport": "ROAD_TRANSPORT",
              "vessel": null,
              "radioCallSign": null,
              "voyageNumber": null,
              "plannedDeparture": null
            },
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "PCVU3502250",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "EMT6",
              "name": "25ft tank container 400 kPa"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "tareWeight": 3000,
            "bookingReference": "DFDN0056849210",
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": {
              "modeOfTransport": "ROAD_TRANSPORT",
              "vessel": null,
              "radioCallSign": null,
              "voyageNumber": null,
              "plannedDeparture": null
            },
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "PVDU1029708",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEG1",
              "name": "45 ft 9.6 PW"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "tareWeight": 4320,
            "bookingReference": "DFDN0056866148",
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": {
              "modeOfTransport": "ROAD_TRANSPORT",
              "vessel": null,
              "radioCallSign": null,
              "voyageNumber": null,
              "plannedDeparture": null
            },
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "PVDU1033098",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEG1",
              "name": "45 ft 9.6 PW"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "tareWeight": 4320,
            "bookingReference": "DFDN0056866126",
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": {
              "modeOfTransport": "ROAD_TRANSPORT",
              "vessel": null,
              "radioCallSign": null,
              "voyageNumber": null,
              "plannedDeparture": null
            },
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "PVDU1035254",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEG1",
              "name": "45 ft 9.6 PW"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "tareWeight": 3000,
            "bookingReference": "DFDN0056943233",
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": {
              "modeOfTransport": "ROAD_TRANSPORT",
              "vessel": null,
              "radioCallSign": null,
              "voyageNumber": null,
              "plannedDeparture": null
            },
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "PVDU1035819",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEG1",
              "name": "45 ft 9.6 PW"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "tareWeight": 3000,
            "bookingReference": "DFDN0056943219",
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": {
              "modeOfTransport": "ROAD_TRANSPORT",
              "vessel": null,
              "radioCallSign": null,
              "voyageNumber": null,
              "plannedDeparture": null
            },
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "PVDU1043584",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEG1",
              "name": "45 ft 9.6 PW"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "tareWeight": 3000,
            "bookingReference": "DFDN0056943283",
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": {
              "modeOfTransport": "ROAD_TRANSPORT",
              "vessel": null,
              "radioCallSign": null,
              "voyageNumber": null,
              "plannedDeparture": null
            },
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "PVDU1074760",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEG1",
              "name": "45 ft 9.6 PW"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "tareWeight": 4320,
            "bookingReference": "DFDN0056866111",
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": {
              "modeOfTransport": "ROAD_TRANSPORT",
              "vessel": null,
              "radioCallSign": null,
              "voyageNumber": null,
              "plannedDeparture": null
            },
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "PVDU3004550",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "LNR1",
              "name": "LNR1"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "tareWeight": 3000,
            "bookingReference": "DFDN0056943224",
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": {
              "modeOfTransport": "ROAD_TRANSPORT",
              "vessel": null,
              "radioCallSign": null,
              "voyageNumber": null,
              "plannedDeparture": null
            },
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "PVDU3016567",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "LNR1",
              "name": "LNR1"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "tareWeight": 3000,
            "bookingReference": "DFDN0056943253",
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": {
              "modeOfTransport": "ROAD_TRANSPORT",
              "vessel": null,
              "radioCallSign": null,
              "voyageNumber": null,
              "plannedDeparture": null
            },
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "RCLU1310727",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-24T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "EMT5",
              "name": "25ft tank container 400 kPa"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "tareWeight": 3000,
            "bookingReference": "DFDN0056907744",
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": {
              "modeOfTransport": "ROAD_TRANSPORT",
              "vessel": null,
              "radioCallSign": null,
              "voyageNumber": null,
              "plannedDeparture": null
            },
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "SNTU7501419",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22K2",
              "name": "20 x 8,6 Liquid tank dangerous gds 2.65-10 bar"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "tareWeight": 3000,
            "bookingReference": "DFDN0056914280",
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": {
              "modeOfTransport": "ROAD_TRANSPORT",
              "vessel": null,
              "radioCallSign": null,
              "voyageNumber": null,
              "plannedDeparture": null
            },
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TCLU4830160",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEG1",
              "name": "45 ft 9.6 PW"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "tareWeight": 3000,
            "bookingReference": "DFDN0056943303",
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": {
              "modeOfTransport": "ROAD_TRANSPORT",
              "vessel": null,
              "radioCallSign": null,
              "voyageNumber": null,
              "plannedDeparture": null
            },
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TCLU4838772",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEG1",
              "name": "45 ft 9.6 PW"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "tareWeight": 3000,
            "bookingReference": "DFDN0056943550",
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": {
              "modeOfTransport": "ROAD_TRANSPORT",
              "vessel": null,
              "radioCallSign": null,
              "voyageNumber": null,
              "plannedDeparture": null
            },
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TDTU6768227",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "tareWeight": 2000,
            "bookingReference": "DFDN0056843640",
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": {
              "modeOfTransport": "ROAD_TRANSPORT",
              "vessel": null,
              "radioCallSign": null,
              "voyageNumber": null,
              "plannedDeparture": null
            },
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TDTU7834200",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "tareWeight": 2000,
            "bookingReference": "DFDN0056843645",
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": {
              "modeOfTransport": "ROAD_TRANSPORT",
              "vessel": null,
              "radioCallSign": null,
              "voyageNumber": null,
              "plannedDeparture": null
            },
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TLLU1578427",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEG1",
              "name": "45 ft 9.6 PW"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "tareWeight": 4500,
            "bookingReference": "DFDN0056610495",
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": {
              "modeOfTransport": "ROAD_TRANSPORT",
              "vessel": null,
              "radioCallSign": null,
              "voyageNumber": null,
              "plannedDeparture": null
            },
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TTNU2741910",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "tareWeight": 2000,
            "bookingReference": "DFDN0056843641",
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": {
              "modeOfTransport": "ROAD_TRANSPORT",
              "vessel": null,
              "radioCallSign": null,
              "voyageNumber": null,
              "plannedDeparture": null
            },
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TWSU2717376",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "EMKD",
              "name": "25ft Tank Container"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "tareWeight": 3000,
            "bookingReference": "DFDN0056914808",
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": {
              "modeOfTransport": "ROAD_TRANSPORT",
              "vessel": null,
              "radioCallSign": null,
              "voyageNumber": null,
              "plannedDeparture": null
            },
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "UBBU0555156",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "3DB0",
              "name": "3DB0"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "tareWeight": 3200,
            "bookingReference": "DFDN0056876794",
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": {
              "modeOfTransport": "ROAD_TRANSPORT",
              "vessel": null,
              "radioCallSign": null,
              "voyageNumber": null,
              "plannedDeparture": null
            },
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "UBBU1165337",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "3DB0",
              "name": "3DB0"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "tareWeight": 3000,
            "bookingReference": "DFDN0056876788",
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": {
              "modeOfTransport": "ROAD_TRANSPORT",
              "vessel": null,
              "radioCallSign": null,
              "voyageNumber": null,
              "plannedDeparture": null
            },
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "UBBU1166170",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "3MB0",
              "name": "3MB0"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "tareWeight": 3000,
            "bookingReference": "DFDN0056876789",
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": {
              "modeOfTransport": "ROAD_TRANSPORT",
              "vessel": null,
              "radioCallSign": null,
              "voyageNumber": null,
              "plannedDeparture": null
            },
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "UBBU2260998",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "3MBJ",
              "name": "30'x>8'x9'6\" Open Top Container"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "tareWeight": 3000,
            "bookingReference": "DFDN0056876791",
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": {
              "modeOfTransport": "ROAD_TRANSPORT",
              "vessel": null,
              "radioCallSign": null,
              "voyageNumber": null,
              "plannedDeparture": null
            },
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "UBBU3363645",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "3MBJ",
              "name": "30'x>8'x9'6\" Open Top Container"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "tareWeight": 3000,
            "bookingReference": "DFDN0056911069",
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": {
              "modeOfTransport": "ROAD_TRANSPORT",
              "vessel": null,
              "radioCallSign": null,
              "voyageNumber": null,
              "plannedDeparture": null
            },
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "UBBU3363795",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "3DB0",
              "name": "3DB0"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "tareWeight": 3000,
            "bookingReference": "DFDN0056876793",
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": {
              "modeOfTransport": "ROAD_TRANSPORT",
              "vessel": null,
              "radioCallSign": null,
              "voyageNumber": null,
              "plannedDeparture": null
            },
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "UBBU3603160",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "3DB0",
              "name": "3DB0"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "tareWeight": 2630,
            "bookingReference": "DFDN0056911070",
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": {
              "modeOfTransport": "ROAD_TRANSPORT",
              "vessel": null,
              "radioCallSign": null,
              "voyageNumber": null,
              "plannedDeparture": null
            },
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "UBBU4902398",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "3DB0",
              "name": "3DB0"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "tareWeight": 3000,
            "bookingReference": "DFDN0056911074",
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": {
              "modeOfTransport": "ROAD_TRANSPORT",
              "vessel": null,
              "radioCallSign": null,
              "voyageNumber": null,
              "plannedDeparture": null
            },
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "UTCU4591313",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22K2",
              "name": "20 x 8,6 Liquid tank dangerous gds 2.65-10 bar"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "tareWeight": 3000,
            "bookingReference": "DFDN0056914281",
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": {
              "modeOfTransport": "ROAD_TRANSPORT",
              "vessel": null,
              "radioCallSign": null,
              "voyageNumber": null,
              "plannedDeparture": null
            },
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "UTTU2511886",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22K2",
              "name": "20 x 8,6 Liquid tank dangerous gds 2.65-10 bar"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "tareWeight": 3000,
            "bookingReference": "DFDN0056940780",
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": {
              "modeOfTransport": "ROAD_TRANSPORT",
              "vessel": null,
              "radioCallSign": null,
              "voyageNumber": null,
              "plannedDeparture": null
            },
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "UTTU3110810",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "AMT4",
              "name": "AMT4"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "tareWeight": 3000,
            "bookingReference": "DFDN0056904859",
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": {
              "modeOfTransport": "ROAD_TRANSPORT",
              "vessel": null,
              "radioCallSign": null,
              "voyageNumber": null,
              "plannedDeparture": null
            },
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "UTTU3511480",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "EMT5",
              "name": "25ft tank container 400 kPa"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "tareWeight": 3000,
            "bookingReference": "DFDN0056908319",
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": {
              "modeOfTransport": "ROAD_TRANSPORT",
              "vessel": null,
              "radioCallSign": null,
              "voyageNumber": null,
              "plannedDeparture": null
            },
            "initialDemurrage": null,
            "redeliveryAddress": null
          }
        ],
        "consignments": [
          {
            "consignmentNumber": "DFDN56610495RTM",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": "GOODS_FROM_EVA_COUNTRIES",
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "DFDS Logistics AS Cont Velocity V",
              "address": "Drammensveien",
              "zipCode": "0283",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "DFDS Logistics AS Cont Velocity V",
              "address": "Drammensveien",
              "zipCode": "0283",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "RTM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "BVKRTM26712021",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "Polymers",
                "grossWeight": 28188,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "TLLU1578427"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "TLLU1578427",
                    "numberOfPackages": 20,
                    "grossWeight": 28188
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "DFDN56610514RTM",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": "GOODS_FROM_EVA_COUNTRIES",
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "DFDS Logistics AS Cont Velocity V",
              "address": "Drammensveien",
              "zipCode": "0283",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "DFDS Logistics AS Cont Velocity V",
              "address": "Drammensveien",
              "zipCode": "0283",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "RTM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "BVKRTM26712021",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "Polymers",
                "grossWeight": 28188,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "LYSU5455785"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "LYSU5455785",
                    "numberOfPackages": 20,
                    "grossWeight": 28188
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "DFDN56769275RTM",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": "GOODS_FROM_EVA_COUNTRIES",
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "DFDS Logistics AS Cont Velocity V",
              "address": "Drammensveien",
              "zipCode": "0283",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "DFDS Logistics AS Cont Velocity V",
              "address": "Drammensveien",
              "zipCode": "0283",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "RTM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "OSLRTM26612021",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "Waste paper Oslo O.C.C. 1.05",
                "grossWeight": 26220,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "CRTU0600767"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "CRTU0600767",
                    "numberOfPackages": 29,
                    "grossWeight": 26220
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "DFDN56843638RTM",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": "GOODS_FROM_EVA_COUNTRIES",
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "DFDS Logistics AS Cont Velocity V",
              "address": "Drammensveien",
              "zipCode": "0283",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "DFDS Logistics AS Cont Velocity V",
              "address": "Drammensveien",
              "zipCode": "0283",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "RTM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "BVKRTM26712021",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "ALUMINIUM SMELTING BY-PRODUCTS",
                "grossWeight": 19620,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": {
                  "name": "ALUMINIUM SMELTING BY-PRODUCTS",
                  "unCode": "3170",
                  "hazardClass": "4.3",
                  "packingGroup": null,
                  "flashPoint": null
                },
                "marksAndNumbers": [
                  "BNSU2140139"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "BNSU2140139",
                    "numberOfPackages": 1,
                    "grossWeight": 19620
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "DFDN56843639RTM",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": "GOODS_FROM_EVA_COUNTRIES",
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "DFDS Logistics AS Cont Velocity V",
              "address": "Drammensveien",
              "zipCode": "0283",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "DFDS Logistics AS Cont Velocity V",
              "address": "Drammensveien",
              "zipCode": "0283",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "RTM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "BVKRTM26712021",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "ALUMINIUM SMELTING BY-PRODUCTS",
                "grossWeight": 16680,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": {
                  "name": "ALUMINIUM SMELTING BY-PRODUCTS",
                  "unCode": "3170",
                  "hazardClass": "4.3",
                  "packingGroup": null,
                  "flashPoint": null
                },
                "marksAndNumbers": [
                  "BNSU3057380"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "BNSU3057380",
                    "numberOfPackages": 1,
                    "grossWeight": 16680
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "DFDN56843640RTM",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": "GOODS_FROM_EVA_COUNTRIES",
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "DFDS Logistics AS Cont Velocity V",
              "address": "Drammensveien",
              "zipCode": "0283",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "DFDS Logistics AS Cont Velocity V",
              "address": "Drammensveien",
              "zipCode": "0283",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "RTM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "BVKRTM26712021",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "ALUMINIUM SMELTING BY-PRODUCTS",
                "grossWeight": 18320,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": {
                  "name": "ALUMINIUM SMELTING BY-PRODUCTS",
                  "unCode": "3170",
                  "hazardClass": "4.3",
                  "packingGroup": null,
                  "flashPoint": null
                },
                "marksAndNumbers": [
                  "TDTU6768227"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "TDTU6768227",
                    "numberOfPackages": 1,
                    "grossWeight": 18320
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "DFDN56843641RTM",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": "GOODS_FROM_EVA_COUNTRIES",
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "DFDS Logistics AS Cont Velocity V",
              "address": "Drammensveien",
              "zipCode": "0283",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "DFDS Logistics AS Cont Velocity V",
              "address": "Drammensveien",
              "zipCode": "0283",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "RTM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "BVKRTM26712021",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "ALUMINIUM SMELTING BY-PRODUCTS",
                "grossWeight": 16780,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": {
                  "name": "ALUMINIUM SMELTING BY-PRODUCTS",
                  "unCode": "3170",
                  "hazardClass": "4.3",
                  "packingGroup": null,
                  "flashPoint": null
                },
                "marksAndNumbers": [
                  "TTNU2741910"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "TTNU2741910",
                    "numberOfPackages": 1,
                    "grossWeight": 16780
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "DFDN56843644RTM",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": "GOODS_FROM_EVA_COUNTRIES",
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "DFDS Logistics AS Cont Velocity V",
              "address": "Drammensveien",
              "zipCode": "0283",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "DFDS Logistics AS Cont Velocity V",
              "address": "Drammensveien",
              "zipCode": "0283",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "RTM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "BVKRTM26712021",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "ALUMINIUM SMELTING BY-PRODUCTS",
                "grossWeight": 16680,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": {
                  "name": "ALUMINIUM SMELTING BY-PRODUCTS",
                  "unCode": "3170",
                  "hazardClass": "4.3",
                  "packingGroup": null,
                  "flashPoint": null
                },
                "marksAndNumbers": [
                  "BNSU3865747"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "BNSU3865747",
                    "numberOfPackages": 1,
                    "grossWeight": 16680
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "DFDN56843645RTM",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": "GOODS_FROM_EVA_COUNTRIES",
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "DFDS Logistics AS Cont Velocity V",
              "address": "Drammensveien",
              "zipCode": "0283",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "DFDS Logistics AS Cont Velocity V",
              "address": "Drammensveien",
              "zipCode": "0283",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "RTM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "BVKRTM26712021",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "ALUMINIUM SMELTING BY-PRODUCTS",
                "grossWeight": 16520,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": {
                  "name": "ALUMINIUM SMELTING BY-PRODUCTS",
                  "unCode": "3170",
                  "hazardClass": "4.3",
                  "packingGroup": null,
                  "flashPoint": null
                },
                "marksAndNumbers": [
                  "TDTU7834200"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "TDTU7834200",
                    "numberOfPackages": 1,
                    "grossWeight": 16520
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "DFDN56843646RTM",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": "GOODS_FROM_EVA_COUNTRIES",
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "DFDS Logistics AS Cont Velocity V",
              "address": "Drammensveien",
              "zipCode": "0283",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "DFDS Logistics AS Cont Velocity V",
              "address": "Drammensveien",
              "zipCode": "0283",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "RTM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "BVKRTM26712021",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "ALUMINIUM SMELTING BY-PRODUCTS",
                "grossWeight": 15880,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": {
                  "name": "ALUMINIUM SMELTING BY-PRODUCTS",
                  "unCode": "3170",
                  "hazardClass": "4.3",
                  "packingGroup": null,
                  "flashPoint": null
                },
                "marksAndNumbers": [
                  "BNSU3840158"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "BNSU3840158",
                    "numberOfPackages": 1,
                    "grossWeight": 15880
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "DFDN56843647RTM",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": "GOODS_FROM_EVA_COUNTRIES",
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "DFDS Logistics AS Cont Velocity V",
              "address": "Drammensveien",
              "zipCode": "0283",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "DFDS Logistics AS Cont Velocity V",
              "address": "Drammensveien",
              "zipCode": "0283",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "RTM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "BVKRTM26712021",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "ALUMINIUM SMELTING BY-PRODUCTS",
                "grossWeight": 16200,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": {
                  "name": "ALUMINIUM SMELTING BY-PRODUCTS",
                  "unCode": "3170",
                  "hazardClass": "4.3",
                  "packingGroup": null,
                  "flashPoint": null
                },
                "marksAndNumbers": [
                  "BNSU3260674"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "BNSU3260674",
                    "numberOfPackages": 1,
                    "grossWeight": 16200
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "DFDN56843649RTM",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": "GOODS_FROM_EVA_COUNTRIES",
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "DFDS Logistics AS Cont Velocity V",
              "address": "Drammensveien",
              "zipCode": "0283",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "DFDS Logistics AS Cont Velocity V",
              "address": "Drammensveien",
              "zipCode": "0283",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "RTM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "BVKRTM26712021",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "ALUMINIUM SMELTING BY-PRODUCTS",
                "grossWeight": 17160,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": {
                  "name": "ALUMINIUM SMELTING BY-PRODUCTS",
                  "unCode": "3170",
                  "hazardClass": "4.3",
                  "packingGroup": null,
                  "flashPoint": null
                },
                "marksAndNumbers": [
                  "BNSU9943913"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "BNSU9943913",
                    "numberOfPackages": 1,
                    "grossWeight": 17160
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "DFDN56843651RTM",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": "GOODS_FROM_EVA_COUNTRIES",
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "DFDS Logistics AS Cont Velocity V",
              "address": "Drammensveien",
              "zipCode": "0283",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "DFDS Logistics AS Cont Velocity V",
              "address": "Drammensveien",
              "zipCode": "0283",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "RTM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "BVKRTM26712021",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "ALUMINIUM SMELTING BY-PRODUCTS",
                "grossWeight": 17080,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": {
                  "name": "ALUMINIUM SMELTING BY-PRODUCTS",
                  "unCode": "3170",
                  "hazardClass": "4.3",
                  "packingGroup": null,
                  "flashPoint": null
                },
                "marksAndNumbers": [
                  "BNSU2034271"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "BNSU2034271",
                    "numberOfPackages": 1,
                    "grossWeight": 17080
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "DFDN56849207RTM",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "Haanpaa International AS",
              "address": "Titangata",
              "zipCode": "1630",
              "city": "GAMLE FREDRIKSTAD",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "Haanpaa International AS",
              "address": "Titangata",
              "zipCode": "1630",
              "city": "GAMLE FREDRIKSTAD",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "RTM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "OSLRTM26612021",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "Empty Container",
                "grossWeight": 3000,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "INGU7351977"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "INGU7351977",
                    "numberOfPackages": 1,
                    "grossWeight": 3000
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "DFDN56849210RTM",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "Haanpaa International AS",
              "address": "Titangata",
              "zipCode": "1630",
              "city": "GAMLE FREDRIKSTAD",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "Haanpaa International AS",
              "address": "Titangata",
              "zipCode": "1630",
              "city": "GAMLE FREDRIKSTAD",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "RTM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "OSLRTM26612021",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "Empty Container",
                "grossWeight": 3000,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "PCVU3502250"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "PCVU3502250",
                    "numberOfPackages": 1,
                    "grossWeight": 3000
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "DFDN56858939RTM",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-24T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "Van den Bosch Transporten B.V.",
              "address": "P.O Box 1",
              "zipCode": "5469 ZG",
              "city": "ERP",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "Van den Bosch Transporten B.V.",
              "address": "P.O Box 1",
              "zipCode": "5469 ZG",
              "city": "ERP",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "RTM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "FRKRTM26812021",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "Empty Container",
                "grossWeight": 3000,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "LAFU3220164"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "LAFU3220164",
                    "numberOfPackages": 1,
                    "grossWeight": 3000
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "DFDN56860292RTM",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "Haanpaa International AS",
              "address": "Titangata",
              "zipCode": "1630",
              "city": "GAMLE FREDRIKSTAD",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "Haanpaa International AS",
              "address": "Titangata",
              "zipCode": "1630",
              "city": "GAMLE FREDRIKSTAD",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "RTM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "OSLRTM26612021",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "Empty Container",
                "grossWeight": 3000,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "GMCU1260911"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "GMCU1260911",
                    "numberOfPackages": 1,
                    "grossWeight": 3000
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "DFDN56866111RTM",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": "GOODS_FROM_EVA_COUNTRIES",
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "DFDS Logistics AS Cont Velocity V",
              "address": "Drammensveien",
              "zipCode": "0283",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "DFDS Logistics AS Cont Velocity V",
              "address": "Drammensveien",
              "zipCode": "0283",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "RTM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "OSLRTM26612021",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "Waste paper Oslo O.C.C. 1.05",
                "grossWeight": 26780,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "PVDU1074760"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "PVDU1074760",
                    "numberOfPackages": 29,
                    "grossWeight": 26780
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "DFDN56866126RTM",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": "GOODS_FROM_EVA_COUNTRIES",
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "DFDS Logistics AS Cont Velocity V",
              "address": "Drammensveien",
              "zipCode": "0283",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "DFDS Logistics AS Cont Velocity V",
              "address": "Drammensveien",
              "zipCode": "0283",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "RTM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "OSLRTM26612021",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "Waste paper Oslo O.C.C. 1.05",
                "grossWeight": 25820,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "PVDU1033098"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "PVDU1033098",
                    "numberOfPackages": 27,
                    "grossWeight": 25820
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "DFDN56866148RTM",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": "GOODS_FROM_EVA_COUNTRIES",
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "DFDS Logistics AS Cont Velocity V",
              "address": "Drammensveien",
              "zipCode": "0283",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "DFDS Logistics AS Cont Velocity V",
              "address": "Drammensveien",
              "zipCode": "0283",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "RTM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "OSLRTM26612021",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "Waste paper Oslo O.C.C. 1.05",
                "grossWeight": 26480,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "PVDU1029708"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "PVDU1029708",
                    "numberOfPackages": 26,
                    "grossWeight": 26480
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "DFDN56876546RTM",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "Entrans AS",
              "address": "Ringsveien",
              "zipCode": "1368",
              "city": "STABEKK",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "Bruhn Spedition NV",
              "address": "St. Pietersvliet 3",
              "zipCode": "2000",
              "city": "ANTWERPEN",
              "countryCode": "BE",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "RTM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "OSLRTM26612021",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "Empty Container",
                "grossWeight": 3000,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "BTEU3909435"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "BTEU3909435",
                    "numberOfPackages": 1,
                    "grossWeight": 3000
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "DFDN56876788RTM",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": "GOODS_FROM_EVA_COUNTRIES",
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "Den Hartogh Dry Bulk Logistics Ltd",
              "address": "Beacon Way",
              "zipCode": "HU3 4AE",
              "city": "HULL",
              "countryCode": "GB",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "INEOS c/o DEN HARTOGH",
              "address": "Rotterdam Shortsea Terminal Zuid",
              "zipCode": "3085",
              "city": "Rotterdam",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "RTM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "BVKRTM26712021",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "Bulkpet",
                "grossWeight": 26760,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "UBBU1165337"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "UBBU1165337",
                    "numberOfPackages": 1,
                    "grossWeight": 26760
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "DFDN56876789RTM",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": "GOODS_FROM_EVA_COUNTRIES",
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "Den Hartogh Dry Bulk Logistics Ltd",
              "address": "Beacon Way",
              "zipCode": "HU3 4AE",
              "city": "HULL",
              "countryCode": "GB",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "INEOS c/o DEN HARTOGH",
              "address": "Rotterdam Shortsea Terminal Zuid",
              "zipCode": "3085",
              "city": "Rotterdam",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "RTM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "BVKRTM26712021",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "Bulkpet",
                "grossWeight": 24440,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "UBBU1166170"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "UBBU1166170",
                    "numberOfPackages": 1,
                    "grossWeight": 24440
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "DFDN56876791RTM",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": "GOODS_FROM_EVA_COUNTRIES",
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "Den Hartogh Dry Bulk Logistics Ltd",
              "address": "Beacon Way",
              "zipCode": "HU3 4AE",
              "city": "HULL",
              "countryCode": "GB",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "INEOS c/o DEN HARTOGH",
              "address": "Rotterdam Shortsea Terminal Zuid",
              "zipCode": "3085",
              "city": "Rotterdam",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "RTM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "BVKRTM26712021",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "Bulkpet",
                "grossWeight": 26920,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "UBBU2260998"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "UBBU2260998",
                    "numberOfPackages": 1,
                    "grossWeight": 26920
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "DFDN56876793RTM",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": "GOODS_FROM_EVA_COUNTRIES",
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "Den Hartogh Dry Bulk Logistics Ltd",
              "address": "Beacon Way",
              "zipCode": "HU3 4AE",
              "city": "HULL",
              "countryCode": "GB",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "INEOS c/o DEN HARTOGH",
              "address": "Rotterdam Shortsea Terminal Zuid",
              "zipCode": "3085",
              "city": "Rotterdam",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "RTM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "BVKRTM26712021",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "Bulkpet",
                "grossWeight": 24060,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "UBBU3363795"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "UBBU3363795",
                    "numberOfPackages": 1,
                    "grossWeight": 24060
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "DFDN56876794RTM",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": "GOODS_FROM_EVA_COUNTRIES",
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "Den Hartogh Dry Bulk Logistics Ltd",
              "address": "Beacon Way",
              "zipCode": "HU3 4AE",
              "city": "HULL",
              "countryCode": "GB",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "INEOS c/o DEN HARTOGH",
              "address": "Rotterdam Shortsea Terminal Zuid",
              "zipCode": "3085",
              "city": "Rotterdam",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "RTM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "BVKRTM26712021",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "Bulkpet",
                "grossWeight": 26360,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "UBBU0555156"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "UBBU0555156",
                    "numberOfPackages": 1,
                    "grossWeight": 26360
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "DFDN56876795RTM",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": "GOODS_FROM_EVA_COUNTRIES",
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "Den Hartogh Dry Bulk Logistics Ltd",
              "address": "Beacon Way",
              "zipCode": "HU3 4AE",
              "city": "HULL",
              "countryCode": "GB",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "INEOS c/o DEN HARTOGH",
              "address": "Rotterdam Shortsea Terminal Zuid",
              "zipCode": "3085",
              "city": "Rotterdam",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "RTM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "BVKRTM26712021",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "Bulkpet",
                "grossWeight": 24040,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "DHBU3170321"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "DHBU3170321",
                    "numberOfPackages": 1,
                    "grossWeight": 24040
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "DFDN56886818RTM",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "TankOne BV",
              "address": "Sluisjesdijk 111",
              "zipCode": "3087",
              "city": "AE ROTTERDAM",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "TankOne BV",
              "address": "Sluisjesdijk 111",
              "zipCode": "3087",
              "city": "AE ROTTERDAM",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "RTM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "OSLRTM26612021",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "Empty Container",
                "grossWeight": 3000,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "EXFU5627098"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "EXFU5627098",
                    "numberOfPackages": 1,
                    "grossWeight": 3000
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "DFDN56904859RTM",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "Den Hartogh Liquid Logistics B.V.",
              "address": "P. O. Box 59087",
              "zipCode": "3008 PB",
              "city": "ROTTERDAM",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "Den Hartogh Liquid Logistics B.V.",
              "address": "P. O. Box 59087",
              "zipCode": "3008 PB",
              "city": "ROTTERDAM",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "RTM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "OSLRTM26612021",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "Empty Container",
                "grossWeight": 3000,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "UTTU3110810"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "UTTU3110810",
                    "numberOfPackages": 1,
                    "grossWeight": 3000
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "DFDN56907744RTM",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-24T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "RMI CHEMICAL LOGISTICS BV",
              "address": "Westgeulstraat",
              "zipCode": "3190 AC",
              "city": "HOOGVLIET",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "RMI CHEMICAL LOGISTICS BV",
              "address": "Westgeulstraat",
              "zipCode": "3190 AC",
              "city": "HOOGVLIET",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "RTM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "FRKRTM26812021",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "Empty Container",
                "grossWeight": 3000,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "RCLU1310727"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "RCLU1310727",
                    "numberOfPackages": 1,
                    "grossWeight": 3000
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "DFDN56907749RTM",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "Van den Bosch Transporten B.V.",
              "address": "P.O Box 1",
              "zipCode": "5469 ZG",
              "city": "ERP",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "Van den Bosch Transporten B.V.",
              "address": "P.O Box 1",
              "zipCode": "5469 ZG",
              "city": "ERP",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "RTM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "OSLRTM26612021",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "Empty Container",
                "grossWeight": 3000,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "ABEU2333652"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "ABEU2333652",
                    "numberOfPackages": 1,
                    "grossWeight": 3000
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "DFDN56907751RTM",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "Entrans AS",
              "address": "Ringsveien",
              "zipCode": "1368",
              "city": "STABEKK",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "Bruhn Spedition NV",
              "address": "St. Pietersvliet 3",
              "zipCode": "2000",
              "city": "ANTWERPEN",
              "countryCode": "BE",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "RTM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "OSLRTM26612021",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "Empty Container",
                "grossWeight": 3000,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "BTEU3426289"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "BTEU3426289",
                    "numberOfPackages": 1,
                    "grossWeight": 3000
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "DFDN56908315RTM",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "Den Hartogh Liquid Logistics B.V.",
              "address": "P. O. Box 59087",
              "zipCode": "3008 PB",
              "city": "ROTTERDAM",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "Den Hartogh Liquid Logistics B.V.",
              "address": "P. O. Box 59087",
              "zipCode": "3008 PB",
              "city": "ROTTERDAM",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "RTM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "OSLRTM26612021",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "Empty Container",
                "grossWeight": 3000,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "DHIU1845442"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "DHIU1845442",
                    "numberOfPackages": 1,
                    "grossWeight": 3000
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "DFDN56908319RTM",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "Den Hartogh Liquid Logistics B.V.",
              "address": "P. O. Box 59087",
              "zipCode": "3008 PB",
              "city": "ROTTERDAM",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "Den Hartogh Liquid Logistics B.V.",
              "address": "P. O. Box 59087",
              "zipCode": "3008 PB",
              "city": "ROTTERDAM",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "RTM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "OSLRTM26612021",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "Empty Container",
                "grossWeight": 3000,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "UTTU3511480"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "UTTU3511480",
                    "numberOfPackages": 1,
                    "grossWeight": 3000
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "DFDN56908589RTM",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "Entrans AS",
              "address": "Ringsveien",
              "zipCode": "1368",
              "city": "STABEKK",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "Bruhn Spedition NV",
              "address": "St. Pietersvliet 3",
              "zipCode": "2000",
              "city": "ANTWERPEN",
              "countryCode": "BE",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "RTM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "OSLRTM26612021",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "Empty Container",
                "grossWeight": 3000,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "BTEU3420315"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "BTEU3420315",
                    "numberOfPackages": 1,
                    "grossWeight": 3000
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "DFDN56909583RTM",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "Entrans AS",
              "address": "Ringsveien",
              "zipCode": "1368",
              "city": "STABEKK",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "Entrans AS",
              "address": "Ringsveien",
              "zipCode": "1368",
              "city": "STABEKK",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "RTM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "OSLRTM26612021",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "Empty Container",
                "grossWeight": 3000,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "MEBU1261684"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "MEBU1261684",
                    "numberOfPackages": 1,
                    "grossWeight": 3000
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "DFDN56909589RTM",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "Entrans AS",
              "address": "Ringsveien",
              "zipCode": "1368",
              "city": "STABEKK",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "Entrans AS",
              "address": "Ringsveien",
              "zipCode": "1368",
              "city": "STABEKK",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "RTM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "OSLRTM26612021",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "Empty Container",
                "grossWeight": 3000,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "MEBU1264214"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "MEBU1264214",
                    "numberOfPackages": 1,
                    "grossWeight": 3000
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "DFDN56911069RTM",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": "GOODS_FROM_EVA_COUNTRIES",
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "Den Hartogh Dry Bulk Logistics Ltd",
              "address": "Beacon Way",
              "zipCode": "HU3 4AE",
              "city": "HULL",
              "countryCode": "GB",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "INEOS c/o DEN HARTOGH",
              "address": "Rotterdam Shortsea Terminal Zuid",
              "zipCode": "3085",
              "city": "Rotterdam",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "RTM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "BVKRTM26712021",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "bulkpet",
                "grossWeight": 26240,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "UBBU3363645"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "UBBU3363645",
                    "numberOfPackages": 1,
                    "grossWeight": 26240
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "DFDN56911070RTM",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": "GOODS_FROM_EVA_COUNTRIES",
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "Den Hartogh Dry Bulk Logistics Ltd",
              "address": "Beacon Way",
              "zipCode": "HU3 4AE",
              "city": "HULL",
              "countryCode": "GB",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "INEOS c/o DEN HARTOGH",
              "address": "Rotterdam Shortsea Terminal Zuid",
              "zipCode": "3085",
              "city": "Rotterdam",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "RTM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "BVKRTM26712021",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "bulkpet",
                "grossWeight": 25720,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "UBBU3603160"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "UBBU3603160",
                    "numberOfPackages": 1,
                    "grossWeight": 25720
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "DFDN56911072RTM",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": "GOODS_FROM_EVA_COUNTRIES",
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "Den Hartogh Dry Bulk Logistics Ltd",
              "address": "Beacon Way",
              "zipCode": "HU3 4AE",
              "city": "HULL",
              "countryCode": "GB",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "INEOS c/o DEN HARTOGH",
              "address": "Rotterdam Shortsea Terminal Zuid",
              "zipCode": "3085",
              "city": "Rotterdam",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "RTM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "BVKRTM26712021",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "bulkpet",
                "grossWeight": 22600,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "DHBU3181373"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "DHBU3181373",
                    "numberOfPackages": 1,
                    "grossWeight": 22600
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "DFDN56911074RTM",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": "GOODS_FROM_EVA_COUNTRIES",
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "Den Hartogh Dry Bulk Logistics Ltd",
              "address": "Beacon Way",
              "zipCode": "HU3 4AE",
              "city": "HULL",
              "countryCode": "GB",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "INEOS c/o DEN HARTOGH",
              "address": "Rotterdam Shortsea Terminal Zuid",
              "zipCode": "3085",
              "city": "Rotterdam",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "RTM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "BVKRTM26712021",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "bulkpet",
                "grossWeight": 25020,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "UBBU4902398"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "UBBU4902398",
                    "numberOfPackages": 1,
                    "grossWeight": 25020
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "DFDN56911178RTM",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "Entrans AS",
              "address": "Ringsveien",
              "zipCode": "1368",
              "city": "STABEKK",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "Bruhn Spedition NV",
              "address": "St. Pietersvliet 3",
              "zipCode": "2000",
              "city": "ANTWERPEN",
              "countryCode": "BE",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "RTM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "OSLRTM26612021",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "Empty Container",
                "grossWeight": 3000,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "BTEU2840592"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "BTEU2840592",
                    "numberOfPackages": 1,
                    "grossWeight": 3000
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "DFDN56911180RTM",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "Entrans AS",
              "address": "Ringsveien",
              "zipCode": "1368",
              "city": "STABEKK",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "Bruhn Spedition NV",
              "address": "St. Pietersvliet 3",
              "zipCode": "2000",
              "city": "ANTWERPEN",
              "countryCode": "BE",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "RTM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "OSLRTM26612021",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "Empty Container",
                "grossWeight": 3000,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "BTEU2840397"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "BTEU2840397",
                    "numberOfPackages": 1,
                    "grossWeight": 3000
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "DFDN56914280RTM",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "INEOS Bamble AS",
              "address": "Att. Accounting Dept.",
              "zipCode": "3962",
              "city": "STATHELLE",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "INEOS Bamble AS",
              "address": "Att. Accounting Dept.",
              "zipCode": "3962",
              "city": "STATHELLE",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "RTM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "BVKRTM26712021",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "Empty Container",
                "grossWeight": 3000,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "SNTU7501419"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "SNTU7501419",
                    "numberOfPackages": 1,
                    "grossWeight": 3000
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "DFDN56914281RTM",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "INEOS Bamble AS",
              "address": "Att. Accounting Dept.",
              "zipCode": "3962",
              "city": "STATHELLE",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "INEOS Bamble AS",
              "address": "Att. Accounting Dept.",
              "zipCode": "3962",
              "city": "STATHELLE",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "RTM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "BVKRTM26712021",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "Empty Container",
                "grossWeight": 3000,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "UTCU4591313"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "UTCU4591313",
                    "numberOfPackages": 1,
                    "grossWeight": 3000
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "DFDN56914808RTM",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "Entrans AS",
              "address": "Ringsveien",
              "zipCode": "1368",
              "city": "STABEKK",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "Baltic Sea Cargo Sp. Z o.o.",
              "address": "Ul Biala",
              "zipCode": "00-895",
              "city": "WARSZAWA",
              "countryCode": "PL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "RTM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "BVKRTM26712021",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "Empty Container",
                "grossWeight": 3000,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "TWSU2717376"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "TWSU2717376",
                    "numberOfPackages": 1,
                    "grossWeight": 3000
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "DFDN56933676RTM",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": "GOODS_FROM_EVA_COUNTRIES",
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "DFDS Logistics AS Cont Velocity V",
              "address": "Drammensveien",
              "zipCode": "0283",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "DFDS Logistics AS Cont Velocity V",
              "address": "Drammensveien",
              "zipCode": "0283",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "RTM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "BVKRTM26712021",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "Polymers",
                "grossWeight": 26239,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "LYSU5456077"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "LYSU5456077",
                    "numberOfPackages": 19,
                    "grossWeight": 26239
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "DFDN56940774RTM",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "Den Hartogh Liquid Logistics B.V.",
              "address": "P. O. Box 59087",
              "zipCode": "3008 PB",
              "city": "ROTTERDAM",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "Den Hartogh Liquid Logistics B.V.",
              "address": "P. O. Box 59087",
              "zipCode": "3008 PB",
              "city": "ROTTERDAM",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "RTM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "OSLRTM26612021",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "Empty Container",
                "grossWeight": 3000,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "DHDU1255474"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "DHDU1255474",
                    "numberOfPackages": 1,
                    "grossWeight": 3000
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "DFDN56940777RTM",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "Den Hartogh Liquid Logistics B.V.",
              "address": "P. O. Box 59087",
              "zipCode": "3008 PB",
              "city": "ROTTERDAM",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "Den Hartogh Liquid Logistics B.V.",
              "address": "P. O. Box 59087",
              "zipCode": "3008 PB",
              "city": "ROTTERDAM",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "RTM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "OSLRTM26612021",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "Empty Container",
                "grossWeight": 3000,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "EXFU8779432"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "EXFU8779432",
                    "numberOfPackages": 1,
                    "grossWeight": 3000
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "DFDN56940780RTM",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "Den Hartogh Liquid Logistics B.V.",
              "address": "P. O. Box 59087",
              "zipCode": "3008 PB",
              "city": "ROTTERDAM",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "Den Hartogh Liquid Logistics B.V.",
              "address": "P. O. Box 59087",
              "zipCode": "3008 PB",
              "city": "ROTTERDAM",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "RTM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "OSLRTM26612021",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "Empty Container",
                "grossWeight": 3000,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "UTTU2511886"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "UTTU2511886",
                    "numberOfPackages": 1,
                    "grossWeight": 3000
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "DFDN56942068RTM",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "DFDS Logistics AS Cont Velocity V",
              "address": "Drammensveien",
              "zipCode": "0283",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "DFDS Logistics AS Cont Velocity V",
              "address": "Drammensveien",
              "zipCode": "0283",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "RTM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "BVKRTM26712021",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "Empty Container",
                "grossWeight": 3000,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "LYSU5454393"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "LYSU5454393",
                    "numberOfPackages": 1,
                    "grossWeight": 3000
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "DFDN56942104RTM",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "DFDS Logistics AS Cont Velocity V",
              "address": "Drammensveien",
              "zipCode": "0283",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "DFDS Logistics AS Cont Velocity V",
              "address": "Drammensveien",
              "zipCode": "0283",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "RTM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "BVKRTM26712021",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "Empty Container",
                "grossWeight": 3000,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "NFLU4500701"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "NFLU4500701",
                    "numberOfPackages": 1,
                    "grossWeight": 3000
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "DFDN56942146RTM",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-23T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "DFDS Logistics AS Cont Velocity V",
              "address": "Drammensveien",
              "zipCode": "0283",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "DFDS Logistics AS Cont Velocity V",
              "address": "Drammensveien",
              "zipCode": "0283",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "RTM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "BVKRTM26712021",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "Empty Container",
                "grossWeight": 3000,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "LYSU0455294"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "LYSU0455294",
                    "numberOfPackages": 1,
                    "grossWeight": 3000
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "DFDN56943161RTM",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "DFDS Logistics AS Cont Velocity V",
              "address": "Drammensveien",
              "zipCode": "0283",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "DFDS Logistics AS Cont Velocity V",
              "address": "Drammensveien",
              "zipCode": "0283",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "RTM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "OSLRTM26612021",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "Empty Container",
                "grossWeight": 3000,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "LYSU5458927"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "LYSU5458927",
                    "numberOfPackages": 1,
                    "grossWeight": 3000
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "DFDN56943167RTM",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "DFDS Logistics AS Cont Velocity V",
              "address": "Drammensveien",
              "zipCode": "0283",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "DFDS Logistics AS Cont Velocity V",
              "address": "Drammensveien",
              "zipCode": "0283",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "RTM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "OSLRTM26612021",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "Empty Container",
                "grossWeight": 3000,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "CLXU4516055"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "CLXU4516055",
                    "numberOfPackages": 1,
                    "grossWeight": 3000
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "DFDN56943169RTM",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "DFDS Logistics AS Cont Velocity V",
              "address": "Drammensveien",
              "zipCode": "0283",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "DFDS Logistics AS Cont Velocity V",
              "address": "Drammensveien",
              "zipCode": "0283",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "RTM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "OSLRTM26612021",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "Empty Container",
                "grossWeight": 3000,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "LYSU5456502"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "LYSU5456502",
                    "numberOfPackages": 1,
                    "grossWeight": 3000
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "DFDN56943173RTM",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "DFDS Logistics AS Cont Velocity V",
              "address": "Drammensveien",
              "zipCode": "0283",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "DFDS Logistics AS Cont Velocity V",
              "address": "Drammensveien",
              "zipCode": "0283",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "RTM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "OSLRTM26612021",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "Empty Container",
                "grossWeight": 3000,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "LYSU5458444"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "LYSU5458444",
                    "numberOfPackages": 1,
                    "grossWeight": 3000
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "DFDN56943174RTM",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "DFDS Logistics AS Cont Velocity V",
              "address": "Drammensveien",
              "zipCode": "0283",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "DFDS Logistics AS Cont Velocity V",
              "address": "Drammensveien",
              "zipCode": "0283",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "RTM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "OSLRTM26612021",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "Empty Container",
                "grossWeight": 3000,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "CNEU4583997"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "CNEU4583997",
                    "numberOfPackages": 1,
                    "grossWeight": 3000
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "DFDN56943178RTM",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "DFDS Logistics AS Cont Velocity V",
              "address": "Drammensveien",
              "zipCode": "0283",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "DFDS Logistics AS Cont Velocity V",
              "address": "Drammensveien",
              "zipCode": "0283",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "RTM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "OSLRTM26612021",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "Empty Container",
                "grossWeight": 3000,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "LYSU5458716"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "LYSU5458716",
                    "numberOfPackages": 1,
                    "grossWeight": 3000
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "DFDN56943186RTM",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "DFDS Logistics AS Cont Velocity V",
              "address": "Drammensveien",
              "zipCode": "0283",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "DFDS Logistics AS Cont Velocity V",
              "address": "Drammensveien",
              "zipCode": "0283",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "RTM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "OSLRTM26612021",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "Empty Container",
                "grossWeight": 3000,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "LYSU5459775"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "LYSU5459775",
                    "numberOfPackages": 1,
                    "grossWeight": 3000
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "DFDN56943187RTM",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "DFDS Logistics AS Cont Velocity V",
              "address": "Drammensveien",
              "zipCode": "0283",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "DFDS Logistics AS Cont Velocity V",
              "address": "Drammensveien",
              "zipCode": "0283",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "RTM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "OSLRTM26612021",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "Empty Container",
                "grossWeight": 3000,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "LYSU5460924"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "LYSU5460924",
                    "numberOfPackages": 1,
                    "grossWeight": 3000
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "DFDN56943219RTM",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "DFDS Logistics AS Cont Velocity V",
              "address": "Drammensveien",
              "zipCode": "0283",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "DFDS Logistics AS Cont Velocity V",
              "address": "Drammensveien",
              "zipCode": "0283",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "RTM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "OSLRTM26612021",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "Empty Container",
                "grossWeight": 3000,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "PVDU1035819"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "PVDU1035819",
                    "numberOfPackages": 1,
                    "grossWeight": 3000
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "DFDN56943221RTM",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "DFDS Logistics AS Cont Velocity V",
              "address": "Drammensveien",
              "zipCode": "0283",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "DFDS Logistics AS Cont Velocity V",
              "address": "Drammensveien",
              "zipCode": "0283",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "RTM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "OSLRTM26612021",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "Empty Container",
                "grossWeight": 3000,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "CNEU4535242"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "CNEU4535242",
                    "numberOfPackages": 1,
                    "grossWeight": 3000
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "DFDN56943224RTM",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "DFDS Logistics AS Cont Velocity V",
              "address": "Drammensveien",
              "zipCode": "0283",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "DFDS Logistics AS Cont Velocity V",
              "address": "Drammensveien",
              "zipCode": "0283",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "RTM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "OSLRTM26612021",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "Empty Container",
                "grossWeight": 3000,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "PVDU3004550"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "PVDU3004550",
                    "numberOfPackages": 1,
                    "grossWeight": 3000
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "DFDN56943226RTM",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "DFDS Logistics AS Cont Velocity V",
              "address": "Drammensveien",
              "zipCode": "0283",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "DFDS Logistics AS Cont Velocity V",
              "address": "Drammensveien",
              "zipCode": "0283",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "RTM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "OSLRTM26612021",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "Empty Container",
                "grossWeight": 3000,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "CRXU0653699"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "CRXU0653699",
                    "numberOfPackages": 1,
                    "grossWeight": 3000
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "DFDN56943227RTM",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "DFDS Logistics AS Cont Velocity V",
              "address": "Drammensveien",
              "zipCode": "0283",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "DFDS Logistics AS Cont Velocity V",
              "address": "Drammensveien",
              "zipCode": "0283",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "RTM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "OSLRTM26612021",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "Empty Container",
                "grossWeight": 3000,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "LYSU5453680"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "LYSU5453680",
                    "numberOfPackages": 1,
                    "grossWeight": 3000
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "DFDN56943232RTM",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "DFDS Logistics AS Cont Velocity V",
              "address": "Drammensveien",
              "zipCode": "0283",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "DFDS Logistics AS Cont Velocity V",
              "address": "Drammensveien",
              "zipCode": "0283",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "RTM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "OSLRTM26612021",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "Empty Container",
                "grossWeight": 3000,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "LYSU5462130"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "LYSU5462130",
                    "numberOfPackages": 1,
                    "grossWeight": 3000
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "DFDN56943233RTM",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "DFDS Logistics AS Cont Velocity V",
              "address": "Drammensveien",
              "zipCode": "0283",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "DFDS Logistics AS Cont Velocity V",
              "address": "Drammensveien",
              "zipCode": "0283",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "RTM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "OSLRTM26612021",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "Empty Container",
                "grossWeight": 3000,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "PVDU1035254"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "PVDU1035254",
                    "numberOfPackages": 1,
                    "grossWeight": 3000
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "DFDN56943235RTM",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "DFDS Logistics AS Cont Velocity V",
              "address": "Drammensveien",
              "zipCode": "0283",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "DFDS Logistics AS Cont Velocity V",
              "address": "Drammensveien",
              "zipCode": "0283",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "RTM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "OSLRTM26612021",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "Empty Container",
                "grossWeight": 3000,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "LYSU5454428"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "LYSU5454428",
                    "numberOfPackages": 1,
                    "grossWeight": 3000
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "DFDN56943237RTM",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "DFDS Logistics AS Cont Velocity V",
              "address": "Drammensveien",
              "zipCode": "0283",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "DFDS Logistics AS Cont Velocity V",
              "address": "Drammensveien",
              "zipCode": "0283",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "RTM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "OSLRTM26612021",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "Empty Container",
                "grossWeight": 3000,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "CLXU4516836"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "CLXU4516836",
                    "numberOfPackages": 1,
                    "grossWeight": 3000
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "DFDN56943239RTM",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "DFDS Logistics AS Cont Velocity V",
              "address": "Drammensveien",
              "zipCode": "0283",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "DFDS Logistics AS Cont Velocity V",
              "address": "Drammensveien",
              "zipCode": "0283",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "RTM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "OSLRTM26612021",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "Empty Container",
                "grossWeight": 3000,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "CNEU4530153"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "CNEU4530153",
                    "numberOfPackages": 1,
                    "grossWeight": 3000
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "DFDN56943253RTM",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "DFDS Logistics AS Cont Velocity V",
              "address": "Drammensveien",
              "zipCode": "0283",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "DFDS Logistics AS Cont Velocity V",
              "address": "Drammensveien",
              "zipCode": "0283",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "RTM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "OSLRTM26612021",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "Empty Container",
                "grossWeight": 3000,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "PVDU3016567"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "PVDU3016567",
                    "numberOfPackages": 1,
                    "grossWeight": 3000
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "DFDN56943255RTM",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "DFDS Logistics AS Cont Velocity V",
              "address": "Drammensveien",
              "zipCode": "0283",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "DFDS Logistics AS Cont Velocity V",
              "address": "Drammensveien",
              "zipCode": "0283",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "RTM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "OSLRTM26612021",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "Empty Container",
                "grossWeight": 3000,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "NFLU4500640"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "NFLU4500640",
                    "numberOfPackages": 1,
                    "grossWeight": 3000
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "DFDN56943262RTM",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "DFDS Logistics AS Cont Velocity V",
              "address": "Drammensveien",
              "zipCode": "0283",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "DFDS Logistics AS Cont Velocity V",
              "address": "Drammensveien",
              "zipCode": "0283",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "RTM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "OSLRTM26612021",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "Empty Container",
                "grossWeight": 3000,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "LYSU9960614"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "LYSU9960614",
                    "numberOfPackages": 1,
                    "grossWeight": 3000
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "DFDN56943263RTM",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "DFDS Logistics AS Cont Velocity V",
              "address": "Drammensveien",
              "zipCode": "0283",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "DFDS Logistics AS Cont Velocity V",
              "address": "Drammensveien",
              "zipCode": "0283",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "RTM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "OSLRTM26612021",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "Empty Container",
                "grossWeight": 3000,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "LYSU9960682"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "LYSU9960682",
                    "numberOfPackages": 1,
                    "grossWeight": 3000
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "DFDN56943283RTM",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "DFDS Logistics AS Cont Velocity V",
              "address": "Drammensveien",
              "zipCode": "0283",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "DFDS Logistics AS Cont Velocity V",
              "address": "Drammensveien",
              "zipCode": "0283",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "RTM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "OSLRTM26612021",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "Empty Container",
                "grossWeight": 3000,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "PVDU1043584"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "PVDU1043584",
                    "numberOfPackages": 1,
                    "grossWeight": 3000
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "DFDN56943303RTM",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "DFDS Logistics AS Cont Velocity V",
              "address": "Drammensveien",
              "zipCode": "0283",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "DFDS Logistics AS Cont Velocity V",
              "address": "Drammensveien",
              "zipCode": "0283",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "RTM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "OSLRTM26612021",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "Empty Container",
                "grossWeight": 3000,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "TCLU4830160"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "TCLU4830160",
                    "numberOfPackages": 1,
                    "grossWeight": 3000
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "DFDN56943550RTM",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "DFDS Logistics AS Cont Velocity V",
              "address": "Drammensveien",
              "zipCode": "0283",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "DFDS Logistics AS Cont Velocity V",
              "address": "Drammensveien",
              "zipCode": "0283",
              "city": "OSLO",
              "countryCode": "NO",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "RTM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "OSLRTM26612021",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "Empty Container",
                "grossWeight": 3000,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [
                  "TCLU4838772"
                ],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "TCLU4838772",
                    "numberOfPackages": 1,
                    "grossWeight": 3000
                  }
                ],
                "producedDocuments": []
              }
            ]
          }
        ],
        "receivedMrns": {},
        "discharges": [
          {
            "number": "LYSU0455294",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T02:13:00Z",
            "stowageLocation": null,
            "grossWeight": null,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "LNRB",
              "name": "45'x8'x9'6\" Thermal Container"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "DFDSCL",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "LYSU5454393",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T02:17:00Z",
            "stowageLocation": null,
            "grossWeight": null,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEG1",
              "name": "45 ft 9.6 PW"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "DFDSCL",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "PVDU1035819",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T02:22:00Z",
            "stowageLocation": null,
            "grossWeight": null,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEG1",
              "name": "45 ft 9.6 PW"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "DFDSCL",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "UTCU4591313",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T02:56:00Z",
            "stowageLocation": null,
            "grossWeight": null,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22K2",
              "name": "20 x 8,6 Liquid tank dangerous gds 2.65-10 bar"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "DFDSCL",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "SNTU7501419",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T02:58:00Z",
            "stowageLocation": null,
            "grossWeight": null,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22K2",
              "name": "20 x 8,6 Liquid tank dangerous gds 2.65-10 bar"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "DFDSCL",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "BNSU3840158",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T03:00:00Z",
            "stowageLocation": null,
            "grossWeight": 18110,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "DFDSCL",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "BNSU3260674",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T03:02:00Z",
            "stowageLocation": null,
            "grossWeight": 18420,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "DFDSCL",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "BNSU3865747",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T03:06:00Z",
            "stowageLocation": null,
            "grossWeight": 18910,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "DFDSCL",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "BNSU3057380",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T03:09:00Z",
            "stowageLocation": null,
            "grossWeight": 18865,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "DFDSCL",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TDTU7834200",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T03:11:00Z",
            "stowageLocation": null,
            "grossWeight": 18850,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "DFDSCL",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "EXFU8779432",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T03:20:00Z",
            "stowageLocation": null,
            "grossWeight": null,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "AMKD",
              "name": "23'tank 9.0ft extra breed"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "DFDSCL",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "PCVU3502250",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T03:22:00Z",
            "stowageLocation": null,
            "grossWeight": null,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "EMT6",
              "name": "25ft tank container 400 kPa"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "DFDSCL",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "DHIU1845442",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T03:25:00Z",
            "stowageLocation": null,
            "grossWeight": null,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "EMKD",
              "name": "25ft Tank Container"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "DFDSCL",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "UTTU3511480",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T03:41:00Z",
            "stowageLocation": null,
            "grossWeight": null,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "EMT5",
              "name": "25ft tank container 400 kPa"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "DFDSCL",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "BTEU2840397",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T03:50:00Z",
            "stowageLocation": null,
            "grossWeight": null,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "EDKD",
              "name": "25'x8'x9'6\" Palletwide Tank Container"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "DFDSCL",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "UBBU3603160",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T03:56:00Z",
            "stowageLocation": null,
            "grossWeight": 28720,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "3MBJ",
              "name": "30'x>8'x9'6\" Open Top Container"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "DFDSCL",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "DHBU3181373",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T03:58:00Z",
            "stowageLocation": null,
            "grossWeight": 25600,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "3MGY",
              "name": "30'x8'x9'6\" Bulk unit"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "DFDSCL",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "UBBU4902398",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T04:01:00Z",
            "stowageLocation": null,
            "grossWeight": 28020,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "3DB0",
              "name": "3DB0"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "DFDSCL",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "UBBU3363645",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T04:05:00Z",
            "stowageLocation": null,
            "grossWeight": 29240,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "3MBJ",
              "name": "30'x>8'x9'6\" Open Top Container"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "DFDSCL",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "UBBU1165337",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T04:09:00Z",
            "stowageLocation": null,
            "grossWeight": 29760,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "3MBJ",
              "name": "30'x>8'x9'6\" Open Top Container"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "DFDSCL",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "CNEU4535242",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T04:11:00Z",
            "stowageLocation": null,
            "grossWeight": null,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEG1",
              "name": "45 ft 9.6 PW"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "DFDSCL",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "LYSU5458927",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T04:15:00Z",
            "stowageLocation": null,
            "grossWeight": null,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEG1",
              "name": "45 ft 9.6 PW"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "DFDSCL",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TCLU4838772",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T04:18:00Z",
            "stowageLocation": null,
            "grossWeight": null,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEG1",
              "name": "45 ft 9.6 PW"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "DFDSCL",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "LYSU5458716",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T04:20:00Z",
            "stowageLocation": null,
            "grossWeight": null,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEG1",
              "name": "45 ft 9.6 PW"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "DFDSCL",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "LYSU5454428",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T04:24:00Z",
            "stowageLocation": null,
            "grossWeight": null,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEG1",
              "name": "45 ft 9.6 PW"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "DFDSCL",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "LYSU5462130",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T04:27:00Z",
            "stowageLocation": null,
            "grossWeight": null,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEG1",
              "name": "45 ft 9.6 PW"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "DFDSCL",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "BTEU3909435",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T04:30:00Z",
            "stowageLocation": null,
            "grossWeight": null,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "3EBA",
              "name": "30'x8'x9'6\" BU High Cube bulkcontainer"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "DFDSCL",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "CLXU4516836",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T04:32:00Z",
            "stowageLocation": null,
            "grossWeight": null,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEG1",
              "name": "45 ft 9.6 PW"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "DFDSCL",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "PVDU3004550",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T04:37:00Z",
            "stowageLocation": null,
            "grossWeight": null,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "LNR1",
              "name": "LNR1"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "DFDSCL",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "PVDU3016567",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T05:08:00Z",
            "stowageLocation": null,
            "grossWeight": null,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "LNR1",
              "name": "LNR1"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "DFDSCL",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "NFLU4500640",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T05:11:00Z",
            "stowageLocation": null,
            "grossWeight": null,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "LNR1",
              "name": "LNR1"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "DFDSCL",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "CLXU4516055",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T05:14:00Z",
            "stowageLocation": null,
            "grossWeight": null,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEG1",
              "name": "45 ft 9.6 PW"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "DFDSCL",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "LYSU5455785",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T05:18:00Z",
            "stowageLocation": null,
            "grossWeight": 32468,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEG1",
              "name": "45 ft 9.6 PW"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "DFDSCL",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "BTEU3426289",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T05:21:00Z",
            "stowageLocation": null,
            "grossWeight": null,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "3DBA",
              "name": "30'x8'x8'6\" Bulk Container"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "DFDSCL",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "UTTU3110810",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T05:24:00Z",
            "stowageLocation": null,
            "grossWeight": null,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "AMT4",
              "name": "AMT4"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "DFDSCL",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "EXFU5627098",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T05:26:00Z",
            "stowageLocation": null,
            "grossWeight": null,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22K2",
              "name": "20 x 8,6 Liquid tank dangerous gds 2.65-10 bar"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "DFDSCL",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "BTEU2840592",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T05:28:00Z",
            "stowageLocation": null,
            "grossWeight": null,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "EDKM",
              "name": "20'x8'x9' Tank Container"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "DFDSCL",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "INGU7351977",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T05:30:00Z",
            "stowageLocation": null,
            "grossWeight": null,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "EMT6",
              "name": "25ft tank container 400 kPa"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "DFDSCL",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "BNSU2140139",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T05:37:00Z",
            "stowageLocation": null,
            "grossWeight": 21900,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "DFDSCL",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "BNSU9943913",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T05:40:00Z",
            "stowageLocation": null,
            "grossWeight": 19290,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "DFDSCL",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TTNU2741910",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T05:42:00Z",
            "stowageLocation": null,
            "grossWeight": 19010,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "DFDSCL",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "BNSU2034271",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T05:47:00Z",
            "stowageLocation": null,
            "grossWeight": 19230,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "DFDSCL",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TDTU6768227",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T05:49:00Z",
            "stowageLocation": null,
            "grossWeight": 20640,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "DFDSCL",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TLLU1578427",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T05:50:00Z",
            "stowageLocation": null,
            "grossWeight": 32468,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEG1",
              "name": "45 ft 9.6 PW"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "DFDSCL",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "DHBU3170321",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T05:53:00Z",
            "stowageLocation": null,
            "grossWeight": 27040,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "3MBJ",
              "name": "30'x>8'x9'6\" Open Top Container"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "DFDSCL",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "UBBU3363795",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T05:56:00Z",
            "stowageLocation": null,
            "grossWeight": 27060,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "3DB0",
              "name": "3DB0"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "DFDSCL",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "UBBU2260998",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T06:02:00Z",
            "stowageLocation": null,
            "grossWeight": 29920,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "3MBJ",
              "name": "30'x>8'x9'6\" Open Top Container"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "DFDSCL",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "UBBU0555156",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T06:05:00Z",
            "stowageLocation": null,
            "grossWeight": 29360,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "3DB0",
              "name": "3DB0"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "DFDSCL",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "UBBU1166170",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T06:07:00Z",
            "stowageLocation": null,
            "grossWeight": 27440,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "3MB0",
              "name": "3MB0"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "DFDSCL",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "BTEU3420315",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T06:41:00Z",
            "stowageLocation": null,
            "grossWeight": null,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "3DBA",
              "name": "30'x8'x8'6\" Bulk Container"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "DFDSCL",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "ABEU2333652",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T06:48:00Z",
            "stowageLocation": null,
            "grossWeight": null,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22K4",
              "name": "20'x8'x8'6\" Tank Container"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "DFDSCL",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "LYSU5456077",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T07:16:00Z",
            "stowageLocation": null,
            "grossWeight": 30560,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEG3",
              "name": "LEG3"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "DFDSCL",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "LYSU5459775",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T07:27:00Z",
            "stowageLocation": null,
            "grossWeight": null,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEG1",
              "name": "45 ft 9.6 PW"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "DFDSCL",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "PVDU1043584",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T07:29:00Z",
            "stowageLocation": null,
            "grossWeight": null,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEG1",
              "name": "45 ft 9.6 PW"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "DFDSCL",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "LYSU5456502",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T07:31:00Z",
            "stowageLocation": null,
            "grossWeight": null,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEG3",
              "name": "LEG3"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "DFDSCL",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "PVDU1035254",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T07:33:00Z",
            "stowageLocation": null,
            "grossWeight": null,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEG1",
              "name": "45 ft 9.6 PW"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "DFDSCL",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "LYSU5458444",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T07:35:00Z",
            "stowageLocation": null,
            "grossWeight": null,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEG1",
              "name": "45 ft 9.6 PW"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "DFDSCL",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "CNEU4530153",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T07:37:00Z",
            "stowageLocation": null,
            "grossWeight": null,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEG1",
              "name": "45 ft 9.6 PW"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "DFDSCL",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "LYSU5460924",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T07:39:00Z",
            "stowageLocation": null,
            "grossWeight": null,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEG1",
              "name": "45 ft 9.6 PW"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "DFDSCL",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "CRTU0600767",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T08:20:00Z",
            "stowageLocation": null,
            "grossWeight": 31010,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEG1",
              "name": "45 ft 9.6 PW"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "DFDSCL",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "PVDU1029708",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T08:29:00Z",
            "stowageLocation": null,
            "grossWeight": 30740,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEG1",
              "name": "45 ft 9.6 PW"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "DFDSCL",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "PVDU1033098",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T08:31:00Z",
            "stowageLocation": null,
            "grossWeight": 30080,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEG1",
              "name": "45 ft 9.6 PW"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "DFDSCL",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "PVDU1074760",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T08:40:00Z",
            "stowageLocation": null,
            "grossWeight": 31100,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEG1",
              "name": "45 ft 9.6 PW"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "DFDSCL",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "GMCU1260911",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T08:55:00Z",
            "stowageLocation": null,
            "grossWeight": null,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "2CT0",
              "name": "2CT0"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "DFDSCL",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "UTTU2511886",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T10:20:00Z",
            "stowageLocation": null,
            "grossWeight": null,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22K2",
              "name": "20 x 8,6 Liquid tank dangerous gds 2.65-10 bar"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "DFDSCL",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "MEBU1261684",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T10:23:00Z",
            "stowageLocation": null,
            "grossWeight": null,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22T1",
              "name": "20'x8'x8'6\" Tank Container Test pressure 1,5 bar"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "DFDSCL",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "MEBU1264214",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T10:28:00Z",
            "stowageLocation": null,
            "grossWeight": null,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22K2",
              "name": "20 x 8,6 Liquid tank dangerous gds 2.65-10 bar"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "DFDSCL",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "DHDU1255474",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T10:36:00Z",
            "stowageLocation": null,
            "grossWeight": null,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "2070",
              "name": "20'x8'x8'0\" Tank Container Test pressure 0,45 bar"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "DFDSCL",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TWSU2717376",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T11:03:00Z",
            "stowageLocation": null,
            "grossWeight": null,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "EMKD",
              "name": "25ft Tank Container"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "DFDSCL",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "RCLU1310727",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T11:05:00Z",
            "stowageLocation": null,
            "grossWeight": null,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "EMT5",
              "name": "25ft tank container 400 kPa"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "DFDSCL",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "LAFU3220164",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T11:07:00Z",
            "stowageLocation": null,
            "grossWeight": null,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "EDKA",
              "name": "20'x8'x9' Tank Container Tare 2500 kg"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "DFDSCL",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "CRXU0653699",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T11:20:00Z",
            "stowageLocation": null,
            "grossWeight": null,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEG1",
              "name": "45 ft 9.6 PW"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "DFDSCL",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "CNEU4583997",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T11:24:00Z",
            "stowageLocation": null,
            "grossWeight": null,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEG1",
              "name": "45 ft 9.6 PW"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "DFDSCL",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": true,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "LYSU9960614",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T11:33:00Z",
            "stowageLocation": null,
            "grossWeight": null,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "4EP3",
              "name": "4EP3"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "DFDSCL",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "LYSU9960682",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T11:38:00Z",
            "stowageLocation": null,
            "grossWeight": null,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "4EP3",
              "name": "4EP3"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "DFDSCL",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TCLU4830160",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T11:40:00Z",
            "stowageLocation": null,
            "grossWeight": null,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEG1",
              "name": "45 ft 9.6 PW"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "DFDSCL",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "LYSU5453680",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T11:44:00Z",
            "stowageLocation": null,
            "grossWeight": null,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEG1",
              "name": "45 ft 9.6 PW"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "DFDSCL",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "NFLU4500701",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T11:48:00Z",
            "stowageLocation": null,
            "grossWeight": null,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "LNR1",
              "name": "LNR1"
            },
            "containerOperator": {
              "customsId": "NL806067470",
              "name": "DFDS Container Line B.V.",
              "scacCode": "DFDN",
              "smdgCode": "DFD",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "DFDSCL",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          }
        ],
        "bulkDischarges": [],
        "consignmentDifferences": [],
        "clearedManifestIds": [
          "NOFRK",
          "NOOSL"
        ],
        "inspectionUpdates": [],
        "declarations": [
          {
            "type": "SDT",
            "id": "NOOSL",
            "status": "ACCEPTED",
            "rejectReasons": null,
            "timeStamp": "2021-09-24T13:11:26.136Z"
          },
          {
            "type": "SDT",
            "id": "NOOSL",
            "status": "ACCEPTED",
            "rejectReasons": null,
            "timeStamp": "2021-09-24T13:11:39.344Z"
          },
          {
            "type": "SDT",
            "id": "NOOSL",
            "status": "REJECTED",
            "rejectReasons": "NO valid domain value, Container size and type, ERROR: 4001 Tag: 704",
            "timeStamp": "2021-09-24T13:11:50.116Z"
          },
          {
            "type": "SDT",
            "id": "NOOSL",
            "status": "REJECTED",
            "rejectReasons": "NO valid domain value, Container size and type, ERROR: 4001 Tag: 704; NO valid domain value, Container size and type, ERROR: 4001 Tag: 704",
            "timeStamp": "2021-09-24T13:12:38.101Z"
          },
          {
            "type": "SDT",
            "id": "NOOSL",
            "status": "REJECTED",
            "rejectReasons": "NO valid domain value, Container size and type, ERROR: 4001 Tag: 704; NO valid domain value, Container size and type, ERROR: 4001 Tag: 704",
            "timeStamp": "2021-09-24T13:13:04.498Z"
          },
          {
            "type": "SDT",
            "id": "NOOSL",
            "status": "REJECTED",
            "rejectReasons": "NO valid domain value, Container size and type, ERROR: 4001 Tag: 704; NO valid domain value, Container size and type, ERROR: 4001 Tag: 704",
            "timeStamp": "2021-09-24T13:13:37.666Z"
          },
          {
            "type": "SDT",
            "id": "NOOSL",
            "status": "ACCEPTED",
            "rejectReasons": null,
            "timeStamp": "2021-09-24T13:55:51.590Z"
          },
          {
            "type": "SDT",
            "id": "NOBVK",
            "status": "ACCEPTED",
            "rejectReasons": null,
            "timeStamp": "2021-09-26T06:58:20.938Z"
          },
          {
            "type": "SDT",
            "id": "NOBVK",
            "status": "ACCEPTED",
            "rejectReasons": null,
            "timeStamp": "2021-09-26T06:58:32.717Z"
          },
          {
            "type": "SDT",
            "id": "NOBVK",
            "status": "ACCEPTED",
            "rejectReasons": null,
            "timeStamp": "2021-09-26T06:58:54.180Z"
          },
          {
            "type": "SDT",
            "id": "NOBVK",
            "status": "ACCEPTED",
            "rejectReasons": null,
            "timeStamp": "2021-09-26T06:59:27.515Z"
          },
          {
            "type": "SDT",
            "id": "NOBVK",
            "status": "ACCEPTED",
            "rejectReasons": null,
            "timeStamp": "2021-09-26T07:00:02.508Z"
          },
          {
            "type": "SDT",
            "id": "NOFRK",
            "status": "ACCEPTED",
            "rejectReasons": null,
            "timeStamp": "2021-09-26T07:01:15.832Z"
          },
          {
            "type": "SDT",
            "id": "NOBVK",
            "status": "ACCEPTED",
            "rejectReasons": null,
            "timeStamp": "2021-09-26T07:50:16.692Z"
          },
          {
            "type": "SDT",
            "id": "NOBVK",
            "status": "ACCEPTED",
            "rejectReasons": null,
            "timeStamp": "2021-09-26T07:51:08.862Z"
          },
          {
            "type": "SDT",
            "id": "NOBVK",
            "status": "ACCEPTED",
            "rejectReasons": null,
            "timeStamp": "2021-09-26T07:57:58.229Z"
          }
        ],
        "incomingMessages": [
          {
            "type": "IFTMCS",
            "id": "DFDN56769275RTM",
            "processId": "fbc7845505334741a8ba686887bad919",
            "timeStamp": "2021-09-24T13:11:26.136Z"
          },
          {
            "type": "IFTMCS",
            "id": "DFDN56849207RTM",
            "processId": "a9716016dcc249168967377dcd103f73",
            "timeStamp": "2021-09-24T13:11:39.344Z"
          },
          {
            "type": "IFTMCS",
            "id": "DFDN56860292RTM",
            "processId": "6e984707105f49118ee9f1f518a8ff92",
            "timeStamp": "2021-09-24T13:11:39.344Z"
          },
          {
            "type": "IFTMCS",
            "id": "DFDN56849210RTM",
            "processId": "3bea0cd283474e94bc70e9193b8ce75c",
            "timeStamp": "2021-09-24T13:11:39.344Z"
          },
          {
            "type": "IFTMCS",
            "id": "DFDN56866126RTM",
            "processId": "652064cf6edc4faaa0621263341046c6",
            "timeStamp": "2021-09-24T13:11:39.344Z"
          },
          {
            "type": "IFTMCS",
            "id": "DFDN56866111RTM",
            "processId": "11bf3cd56acf44068a4b5d5d4188ee25",
            "timeStamp": "2021-09-24T13:11:39.344Z"
          },
          {
            "type": "IFTMCS",
            "id": "DFDN56907749RTM",
            "processId": "308a8fc2b2704f809836fa024ad16fad",
            "timeStamp": "2021-09-24T13:11:50.116Z"
          },
          {
            "type": "IFTMCS",
            "id": "DFDN56876546RTM",
            "processId": "d344f9ab855f48beb2cb6dbd32bdf5e9",
            "timeStamp": "2021-09-24T13:11:50.116Z"
          },
          {
            "type": "IFTMCS",
            "id": "DFDN56866148RTM",
            "processId": "e292290e4a81454ea4c680e10d385e49",
            "timeStamp": "2021-09-24T13:11:50.116Z"
          },
          {
            "type": "IFTMCS",
            "id": "DFDN56904859RTM",
            "processId": "5f4a9ff4ca5a4dbdbfd53e4672fe1d67",
            "timeStamp": "2021-09-24T13:11:50.116Z"
          },
          {
            "type": "IFTMCS",
            "id": "DFDN56886818RTM",
            "processId": "d6682268fac04f9da448dab89694fef8",
            "timeStamp": "2021-09-24T13:11:50.116Z"
          },
          {
            "type": "IFTMCS",
            "id": "DFDN56907751RTM",
            "processId": "1ad665f10d864186a4086615edec21c0",
            "timeStamp": "2021-09-24T13:12:01.041Z"
          },
          {
            "type": "IFTMCS",
            "id": "DFDN56908315RTM",
            "processId": "8b931e96f3bd4bd4aeb057f4469d4bc4",
            "timeStamp": "2021-09-24T13:12:01.041Z"
          },
          {
            "type": "IFTMCS",
            "id": "DFDN56908319RTM",
            "processId": "23c396fe571b447aa5cb7ae58622a600",
            "timeStamp": "2021-09-24T13:12:01.041Z"
          },
          {
            "type": "IFTMCS",
            "id": "DFDN56908589RTM",
            "processId": "1a0e7b45800b43dfa527745c2520a904",
            "timeStamp": "2021-09-24T13:12:38.101Z"
          },
          {
            "type": "IFTMCS",
            "id": "DFDN56940780RTM",
            "processId": "e10f6aa635fc416c900e339a28083bf3",
            "timeStamp": "2021-09-24T13:12:48.391Z"
          },
          {
            "type": "IFTMCS",
            "id": "DFDN56943219RTM",
            "processId": "cd77b59b203549d3a796921b6d3f9f0e",
            "timeStamp": "2021-09-24T13:12:48.391Z"
          },
          {
            "type": "IFTMCS",
            "id": "DFDN56940774RTM",
            "processId": "4ac11ade754449338ffaf5e60e5be526",
            "timeStamp": "2021-09-24T13:12:48.391Z"
          },
          {
            "type": "IFTMCS",
            "id": "DFDN56911180RTM",
            "processId": "71fa407c0f46426a8b92c005a00f3fd1",
            "timeStamp": "2021-09-24T13:12:48.391Z"
          },
          {
            "type": "IFTMCS",
            "id": "DFDN56943174RTM",
            "processId": "04d6f32cb9604f0898c107f0f6c46f43",
            "timeStamp": "2021-09-24T13:12:48.391Z"
          },
          {
            "type": "IFTMCS",
            "id": "DFDN56940777RTM",
            "processId": "00c8464ffc754bbebc73f72b34d5455c",
            "timeStamp": "2021-09-24T13:12:48.391Z"
          },
          {
            "type": "IFTMCS",
            "id": "DFDN56943161RTM",
            "processId": "8879502a12994214bc1c27926cd244b0",
            "timeStamp": "2021-09-24T13:12:48.391Z"
          },
          {
            "type": "IFTMCS",
            "id": "DFDN56943186RTM",
            "processId": "bae132d4b4654bd9a0ff9ca7d9f5691c",
            "timeStamp": "2021-09-24T13:12:48.391Z"
          },
          {
            "type": "IFTMCS",
            "id": "DFDN56943178RTM",
            "processId": "bbe116953ad9407bb72109f1047c85ed",
            "timeStamp": "2021-09-24T13:12:48.391Z"
          },
          {
            "type": "IFTMCS",
            "id": "DFDN56943221RTM",
            "processId": "3e063b1fd034487ab904742a2a79a108",
            "timeStamp": "2021-09-24T13:12:48.391Z"
          },
          {
            "type": "IFTMCS",
            "id": "DFDN56943169RTM",
            "processId": "fbbd8cdb75e14fd0a1449a62eb053660",
            "timeStamp": "2021-09-24T13:12:48.391Z"
          },
          {
            "type": "IFTMCS",
            "id": "DFDN56909583RTM",
            "processId": "4f27954b840b45aa9550c446c22fb4e2",
            "timeStamp": "2021-09-24T13:12:48.391Z"
          },
          {
            "type": "IFTMCS",
            "id": "DFDN56909589RTM",
            "processId": "1ed97ea9fb474a21a366658c3b0f1f4f",
            "timeStamp": "2021-09-24T13:12:48.391Z"
          },
          {
            "type": "IFTMCS",
            "id": "DFDN56943167RTM",
            "processId": "be84e07e30be4e0fa3bd4a1fcdbd1614",
            "timeStamp": "2021-09-24T13:12:48.391Z"
          },
          {
            "type": "IFTMCS",
            "id": "DFDN56943173RTM",
            "processId": "224dc90ecc1144e4b626768512c25dd1",
            "timeStamp": "2021-09-24T13:12:48.391Z"
          },
          {
            "type": "IFTMCS",
            "id": "DFDN56911178RTM",
            "processId": "7c5d19fce1b3463c959620616f279f45",
            "timeStamp": "2021-09-24T13:12:48.391Z"
          },
          {
            "type": "IFTMCS",
            "id": "DFDN56943224RTM",
            "processId": "dd4cf1d5803c4d19aa6d5b4d41353fed",
            "timeStamp": "2021-09-24T13:13:04.498Z"
          },
          {
            "type": "IFTMCS",
            "id": "DFDN56943187RTM",
            "processId": "3f6209185e52468aaa0641cb94a754f0",
            "timeStamp": "2021-09-24T13:13:04.498Z"
          },
          {
            "type": "IFTMCS",
            "id": "DFDN56943227RTM",
            "processId": "0510508a79dc4cd894238c7815006915",
            "timeStamp": "2021-09-24T13:13:04.498Z"
          },
          {
            "type": "IFTMCS",
            "id": "DFDN56943232RTM",
            "processId": "c4f59cb03b344f5cbc112a3cad35e647",
            "timeStamp": "2021-09-24T13:13:04.498Z"
          },
          {
            "type": "IFTMCS",
            "id": "DFDN56943235RTM",
            "processId": "661fc236fb0341d2b79fb5787f2ad543",
            "timeStamp": "2021-09-24T13:13:04.498Z"
          },
          {
            "type": "IFTMCS",
            "id": "DFDN56943226RTM",
            "processId": "639554a9d03f4db08fd7ff4739a0a074",
            "timeStamp": "2021-09-24T13:13:04.498Z"
          },
          {
            "type": "IFTMCS",
            "id": "DFDN56943233RTM",
            "processId": "adbc2726686247eea108851170f0305f",
            "timeStamp": "2021-09-24T13:13:04.498Z"
          },
          {
            "type": "IFTMCS",
            "id": "DFDN56943239RTM",
            "processId": "238631104209402a8e1d78ad83768c91",
            "timeStamp": "2021-09-24T13:13:15.774Z"
          },
          {
            "type": "IFTMCS",
            "id": "DFDN56943253RTM",
            "processId": "406c825ee11247c9996b330ca085fc57",
            "timeStamp": "2021-09-24T13:13:15.774Z"
          },
          {
            "type": "IFTMCS",
            "id": "DFDN56943255RTM",
            "processId": "12a3d99b55d84ec3b365392bb2334229",
            "timeStamp": "2021-09-24T13:13:15.774Z"
          },
          {
            "type": "IFTMCS",
            "id": "DFDN56943237RTM",
            "processId": "ef8a81dfd5c04babbc21bf1876eb24db",
            "timeStamp": "2021-09-24T13:13:15.774Z"
          },
          {
            "type": "IFTMCS",
            "id": "DFDN56943263RTM",
            "processId": "9f9c5278fda049b88f2e81a2fcb0abe5",
            "timeStamp": "2021-09-24T13:13:25.964Z"
          },
          {
            "type": "IFTMCS",
            "id": "DFDN56943262RTM",
            "processId": "245b974cedba48118032ca36f8b46189",
            "timeStamp": "2021-09-24T13:13:25.964Z"
          },
          {
            "type": "IFTMCS",
            "id": "DFDN56943283RTM",
            "processId": "688bf7e49bab42528d6ed57f84032499",
            "timeStamp": "2021-09-24T13:13:37.666Z"
          },
          {
            "type": "IFTMCS",
            "id": "DFDN56943550RTM",
            "processId": "166f37bf2abe474a9ac72029a2561bc6",
            "timeStamp": "2021-09-24T13:13:37.666Z"
          },
          {
            "type": "IFTMCS",
            "id": "DFDN56943303RTM",
            "processId": "fadc69545c3d48299c181a01acc8d0d3",
            "timeStamp": "2021-09-24T13:13:37.666Z"
          },
          {
            "type": "IFTMCS",
            "id": "DFDN56610495RTM",
            "processId": "8b667bff02b84075ad3177ce8dbbc8f4",
            "timeStamp": "2021-09-26T06:58:20.938Z"
          },
          {
            "type": "IFTMCS",
            "id": "DFDN56843640RTM",
            "processId": "4d515547677b49efa0fe4226c87fe382",
            "timeStamp": "2021-09-26T06:58:32.717Z"
          },
          {
            "type": "IFTMCS",
            "id": "DFDN56843638RTM",
            "processId": "19c7e56092d2421e90d17df09c8bd045",
            "timeStamp": "2021-09-26T06:58:32.717Z"
          },
          {
            "type": "IFTMCS",
            "id": "DFDN56610514RTM",
            "processId": "dd24feab98724ea49ae31f80fe6a4e0c",
            "timeStamp": "2021-09-26T06:58:32.717Z"
          },
          {
            "type": "IFTMCS",
            "id": "DFDN56843639RTM",
            "processId": "7ef22af93b19418fb033a1e2f23748de",
            "timeStamp": "2021-09-26T06:58:32.717Z"
          },
          {
            "type": "IFTMCS",
            "id": "DFDN56843641RTM",
            "processId": "ab445b311a054aa3b73a26fa1ebe865f",
            "timeStamp": "2021-09-26T06:58:32.717Z"
          },
          {
            "type": "IFTMCS",
            "id": "DFDN56843646RTM",
            "processId": "048cd2a344a9465185ac4f6d557a1e7e",
            "timeStamp": "2021-09-26T06:58:43.749Z"
          },
          {
            "type": "IFTMCS",
            "id": "DFDN56843645RTM",
            "processId": "ded3429327dd4df4a9e49ac79d4c45b8",
            "timeStamp": "2021-09-26T06:58:43.749Z"
          },
          {
            "type": "IFTMCS",
            "id": "DFDN56843647RTM",
            "processId": "ddc55b2f46464deeba090a53fd0e8da6",
            "timeStamp": "2021-09-26T06:58:43.749Z"
          },
          {
            "type": "IFTMCS",
            "id": "DFDN56843644RTM",
            "processId": "037b8a52e6a24fc5b9535572ae12eef5",
            "timeStamp": "2021-09-26T06:58:43.749Z"
          },
          {
            "type": "IFTMCS",
            "id": "DFDN56843649RTM",
            "processId": "03fc75322ffd4e08a6ddcddafd9397d7",
            "timeStamp": "2021-09-26T06:58:43.749Z"
          },
          {
            "type": "IFTMCS",
            "id": "DFDN56876789RTM",
            "processId": "c8af5d4ffaf4435db413bb7194866cbd",
            "timeStamp": "2021-09-26T06:58:54.180Z"
          },
          {
            "type": "IFTMCS",
            "id": "DFDN56876788RTM",
            "processId": "ff30bde40b2a41b198d956414f5a550b",
            "timeStamp": "2021-09-26T06:58:54.180Z"
          },
          {
            "type": "IFTMCS",
            "id": "DFDN56876791RTM",
            "processId": "78cd959d8f494c108db4610ba92cd19d",
            "timeStamp": "2021-09-26T06:58:54.180Z"
          },
          {
            "type": "IFTMCS",
            "id": "DFDN56843651RTM",
            "processId": "8adf8aed81774bb58600c585f9ec6c23",
            "timeStamp": "2021-09-26T06:58:54.180Z"
          },
          {
            "type": "IFTMCS",
            "id": "DFDN56911070RTM",
            "processId": "f81d40e9b90b41e2b69f985992ee2e53",
            "timeStamp": "2021-09-26T06:59:04.461Z"
          },
          {
            "type": "IFTMCS",
            "id": "DFDN56911069RTM",
            "processId": "e0e15f32d6174ab18db49884df881bba",
            "timeStamp": "2021-09-26T06:59:04.461Z"
          },
          {
            "type": "IFTMCS",
            "id": "DFDN56876794RTM",
            "processId": "fe5765fff817468190276f6f2e83515f",
            "timeStamp": "2021-09-26T06:59:04.461Z"
          },
          {
            "type": "IFTMCS",
            "id": "DFDN56876793RTM",
            "processId": "b459c8d998864e0793342d715db55864",
            "timeStamp": "2021-09-26T06:59:04.461Z"
          },
          {
            "type": "IFTMCS",
            "id": "DFDN56876795RTM",
            "processId": "35b0788d8b2449879a640d3f9c6d94d5",
            "timeStamp": "2021-09-26T06:59:04.461Z"
          },
          {
            "type": "IFTMCS",
            "id": "DFDN56914808RTM",
            "processId": "7091e2a968b947ef971047584acc98ae",
            "timeStamp": "2021-09-26T06:59:16.358Z"
          },
          {
            "type": "IFTMCS",
            "id": "DFDN56911074RTM",
            "processId": "daab8a8841ac42b2b364a07dd9e28467",
            "timeStamp": "2021-09-26T06:59:16.358Z"
          },
          {
            "type": "IFTMCS",
            "id": "DFDN56933676RTM",
            "processId": "35d7cb1de54042e894b6f9108a1c6a0c",
            "timeStamp": "2021-09-26T06:59:16.358Z"
          },
          {
            "type": "IFTMCS",
            "id": "DFDN56942068RTM",
            "processId": "254d3de3c2ba4fa09a36ec62ba7eb263",
            "timeStamp": "2021-09-26T06:59:27.515Z"
          },
          {
            "type": "IFTMCS",
            "id": "DFDN56942146RTM",
            "processId": "367adb030a78429194b4240161164fc4",
            "timeStamp": "2021-09-26T06:59:27.515Z"
          },
          {
            "type": "IFTMCS",
            "id": "DFDN56942104RTM",
            "processId": "e5705240bf7d44a0a6627eb3e0243cb7",
            "timeStamp": "2021-09-26T07:00:02.508Z"
          },
          {
            "type": "IFTMCS",
            "id": "DFDN56911072RTM",
            "processId": "f947ad43e5d440b190fc79280ba4d100",
            "timeStamp": "2021-09-26T07:00:02.508Z"
          },
          {
            "type": "IFTMCS",
            "id": "DFDN56907744RTM",
            "processId": "da65144e09dc4d56949d8eda5d7e8ef5",
            "timeStamp": "2021-09-26T07:01:15.832Z"
          },
          {
            "type": "IFTMCS",
            "id": "DFDN56858939RTM",
            "processId": "2dba31ba5e3c4b46a5df3442c02acb99",
            "timeStamp": "2021-09-26T07:01:15.832Z"
          },
          {
            "type": "IFTMCS",
            "id": "DFDN56914281RTM",
            "processId": "f6c490994f964e2e910165d836b03bba",
            "timeStamp": "2021-09-26T07:57:58.229Z"
          },
          {
            "type": "IFTMCS",
            "id": "DFDN56914280RTM",
            "processId": "18d1c4e76ebc47478be449d75b5545a3",
            "timeStamp": "2021-09-26T07:57:58.229Z"
          }
        ]
      },
      {
        "cargoDeclarant": {
          "fullName": "Samskip Multimodal BV",
          "shortName": "GEESTRTM",
          "portAuthorityId": "SAMSKIP MCL",
          "emailAddress": "rsu@samskip.com",
          "address": "Waalhaven OZ 81",
          "city": "Rotterdam",
          "countryUnCode": "NL",
          "countryName": "Netherlands",
          "zipCode": "3087 BM",
          "phoneNumber": "+31-88-4001754",
          "faxNumber": "+31-10-4952629",
          "contact": "Andre van der Meer",
          "customsEORINumber": "NL001571734",
          "ean": "8713755262723",
          "chamberOfCommerceNumber": "24139799",
          "scacCode": "GEEL",
          "ensEnabled": true,
          "cargoImportEnabled": true,
          "cargoImportEnabledByDefault": true
        },
        "timestamp": "2021-09-28T16:36:05.100Z",
        "containers": [
          {
            "number": "ABEU4910687",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "3MNL",
              "name": "30'x8,6'x9' Tank Bulk Container"
            },
            "containerOperator": {
              "customsId": "NL001571734",
              "name": "Samskip Multimodal BV",
              "scacCode": "GEEL",
              "smdgCode": "SML",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "CAIU3030000",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "NL001571734",
              "name": "Samskip Multimodal BV",
              "scacCode": "GEEL",
              "smdgCode": "SML",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "CLXU4512419",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-25T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEG1",
              "name": "45 ft 9.6 PW"
            },
            "containerOperator": {
              "customsId": "NL001571734",
              "name": "Samskip Multimodal BV",
              "scacCode": "GEEL",
              "smdgCode": "SML",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "CNEU4569900",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEG1",
              "name": "45 ft 9.6 PW"
            },
            "containerOperator": {
              "customsId": "NL001571734",
              "name": "Samskip Multimodal BV",
              "scacCode": "GEEL",
              "smdgCode": "SML",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "CNEU4607131",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEGB",
              "name": "45 Foot High Cube General Purpose / Dry Container"
            },
            "containerOperator": {
              "customsId": "NL001571734",
              "name": "Samskip Multimodal BV",
              "scacCode": "GEEL",
              "smdgCode": "SML",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "COCU1004355",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-24T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "LNR1",
              "name": "LNR1"
            },
            "containerOperator": {
              "customsId": "NL001571734",
              "name": "Samskip Multimodal BV",
              "scacCode": "GEEL",
              "smdgCode": "SML",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": 22.0,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "FOTU5436904",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "EMKD",
              "name": "25ft Tank Container"
            },
            "containerOperator": {
              "customsId": "NL001571734",
              "name": "Samskip Multimodal BV",
              "scacCode": "GEEL",
              "smdgCode": "SML",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "FOTU6426069",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "2CT2",
              "name": "2CT2"
            },
            "containerOperator": {
              "customsId": "NL001571734",
              "name": "Samskip Multimodal BV",
              "scacCode": "GEEL",
              "smdgCode": "SML",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "FOTU8410267",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "2CT0",
              "name": "2CT0"
            },
            "containerOperator": {
              "customsId": "NL001571734",
              "name": "Samskip Multimodal BV",
              "scacCode": "GEEL",
              "smdgCode": "SML",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "FOTU8428034",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "2CT0",
              "name": "2CT0"
            },
            "containerOperator": {
              "customsId": "NL001571734",
              "name": "Samskip Multimodal BV",
              "scacCode": "GEEL",
              "smdgCode": "SML",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "FOTU8428311",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "2MKD",
              "name": "20'x8'x9' Tank Container"
            },
            "containerOperator": {
              "customsId": "NL001571734",
              "name": "Samskip Multimodal BV",
              "scacCode": "GEEL",
              "smdgCode": "SML",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "HOYU9619044",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22K2",
              "name": "20 x 8,6 Liquid tank dangerous gds 2.65-10 bar"
            },
            "containerOperator": {
              "customsId": "NL001571734",
              "name": "Samskip Multimodal BV",
              "scacCode": "GEEL",
              "smdgCode": "SML",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "LAFU3219137",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "EMKD",
              "name": "25ft Tank Container"
            },
            "containerOperator": {
              "customsId": "NL001571734",
              "name": "Samskip Multimodal BV",
              "scacCode": "GEEL",
              "smdgCode": "SML",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "MCMU6300309",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-24T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "AMKD",
              "name": "23'tank 9.0ft extra breed"
            },
            "containerOperator": {
              "customsId": "NL001571734",
              "name": "Samskip Multimodal BV",
              "scacCode": "GEEL",
              "smdgCode": "SML",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "MOAU6609688",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "NL001571734",
              "name": "Samskip Multimodal BV",
              "scacCode": "GEEL",
              "smdgCode": "SML",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "MRSU3810357",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "NL001571734",
              "name": "Samskip Multimodal BV",
              "scacCode": "GEEL",
              "smdgCode": "SML",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "NYKU3788111",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "NL001571734",
              "name": "Samskip Multimodal BV",
              "scacCode": "GEEL",
              "smdgCode": "SML",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "PCVU3500827",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "AMKD",
              "name": "23'tank 9.0ft extra breed"
            },
            "containerOperator": {
              "customsId": "NL001571734",
              "name": "Samskip Multimodal BV",
              "scacCode": "GEEL",
              "smdgCode": "SML",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "PVDU1043049",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-25T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEG1",
              "name": "45 ft 9.6 PW"
            },
            "containerOperator": {
              "customsId": "NL001571734",
              "name": "Samskip Multimodal BV",
              "scacCode": "GEEL",
              "smdgCode": "SML",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "PVDU1058291",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEG1",
              "name": "45 ft 9.6 PW"
            },
            "containerOperator": {
              "customsId": "NL001571734",
              "name": "Samskip Multimodal BV",
              "scacCode": "GEEL",
              "smdgCode": "SML",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "PVDU1070297",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEG1",
              "name": "45 ft 9.6 PW"
            },
            "containerOperator": {
              "customsId": "NL001571734",
              "name": "Samskip Multimodal BV",
              "scacCode": "GEEL",
              "smdgCode": "SML",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "PVDU1071708",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEG1",
              "name": "45 ft 9.6 PW"
            },
            "containerOperator": {
              "customsId": "NL001571734",
              "name": "Samskip Multimodal BV",
              "scacCode": "GEEL",
              "smdgCode": "SML",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "PVDU1129604",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEGB",
              "name": "45 Foot High Cube General Purpose / Dry Container"
            },
            "containerOperator": {
              "customsId": "NL001571734",
              "name": "Samskip Multimodal BV",
              "scacCode": "GEEL",
              "smdgCode": "SML",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "SANU7504804",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEG1",
              "name": "45 ft 9.6 PW"
            },
            "containerOperator": {
              "customsId": "NL001571734",
              "name": "Samskip Multimodal BV",
              "scacCode": "GEEL",
              "smdgCode": "SML",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "SANU7511418",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEG1",
              "name": "45 ft 9.6 PW"
            },
            "containerOperator": {
              "customsId": "NL001571734",
              "name": "Samskip Multimodal BV",
              "scacCode": "GEEL",
              "smdgCode": "SML",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "SANU7511573",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEG1",
              "name": "45 ft 9.6 PW"
            },
            "containerOperator": {
              "customsId": "NL001571734",
              "name": "Samskip Multimodal BV",
              "scacCode": "GEEL",
              "smdgCode": "SML",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "SANU7517057",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEG1",
              "name": "45 ft 9.6 PW"
            },
            "containerOperator": {
              "customsId": "NL001571734",
              "name": "Samskip Multimodal BV",
              "scacCode": "GEEL",
              "smdgCode": "SML",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "SANU7518325",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-25T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEG1",
              "name": "45 ft 9.6 PW"
            },
            "containerOperator": {
              "customsId": "NL001571734",
              "name": "Samskip Multimodal BV",
              "scacCode": "GEEL",
              "smdgCode": "SML",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "SANU7524061",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEG1",
              "name": "45 ft 9.6 PW"
            },
            "containerOperator": {
              "customsId": "NL001571734",
              "name": "Samskip Multimodal BV",
              "scacCode": "GEEL",
              "smdgCode": "SML",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "SANU7524502",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEG1",
              "name": "45 ft 9.6 PW"
            },
            "containerOperator": {
              "customsId": "NL001571734",
              "name": "Samskip Multimodal BV",
              "scacCode": "GEEL",
              "smdgCode": "SML",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "SANU7525406",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEG1",
              "name": "45 ft 9.6 PW"
            },
            "containerOperator": {
              "customsId": "NL001571734",
              "name": "Samskip Multimodal BV",
              "scacCode": "GEEL",
              "smdgCode": "SML",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "SANU7526059",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEG1",
              "name": "45 ft 9.6 PW"
            },
            "containerOperator": {
              "customsId": "NL001571734",
              "name": "Samskip Multimodal BV",
              "scacCode": "GEEL",
              "smdgCode": "SML",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "SANU7526742",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEG1",
              "name": "45 ft 9.6 PW"
            },
            "containerOperator": {
              "customsId": "NL001571734",
              "name": "Samskip Multimodal BV",
              "scacCode": "GEEL",
              "smdgCode": "SML",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "SANU7526948",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEG1",
              "name": "45 ft 9.6 PW"
            },
            "containerOperator": {
              "customsId": "NL001571734",
              "name": "Samskip Multimodal BV",
              "scacCode": "GEEL",
              "smdgCode": "SML",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "SANU7700200",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-25T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEV1",
              "name": "45'x9'x9,6' High Cube Ventilated Container"
            },
            "containerOperator": {
              "customsId": "NL001571734",
              "name": "Samskip Multimodal BV",
              "scacCode": "GEEL",
              "smdgCode": "SML",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "SANU7979498",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-25T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEG1",
              "name": "45 ft 9.6 PW"
            },
            "containerOperator": {
              "customsId": "NL001571734",
              "name": "Samskip Multimodal BV",
              "scacCode": "GEEL",
              "smdgCode": "SML",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "SANU7989557",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEG1",
              "name": "45 ft 9.6 PW"
            },
            "containerOperator": {
              "customsId": "NL001571734",
              "name": "Samskip Multimodal BV",
              "scacCode": "GEEL",
              "smdgCode": "SML",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "SANU7997341",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-25T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEG1",
              "name": "45 ft 9.6 PW"
            },
            "containerOperator": {
              "customsId": "NL001571734",
              "name": "Samskip Multimodal BV",
              "scacCode": "GEEL",
              "smdgCode": "SML",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "SANU8000351",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-24T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "4EP3",
              "name": "4EP3"
            },
            "containerOperator": {
              "customsId": "NL001571734",
              "name": "Samskip Multimodal BV",
              "scacCode": "GEEL",
              "smdgCode": "SML",
              "cargoDeclarantShortName": null
            },
            "tareWeight": 5350.0,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "SANU8000428",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-24T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "4EP3",
              "name": "4EP3"
            },
            "containerOperator": {
              "customsId": "NL001571734",
              "name": "Samskip Multimodal BV",
              "scacCode": "GEEL",
              "smdgCode": "SML",
              "cargoDeclarantShortName": null
            },
            "tareWeight": 5350.0,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "SANU8001105",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-24T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "4EP3",
              "name": "4EP3"
            },
            "containerOperator": {
              "customsId": "NL001571734",
              "name": "Samskip Multimodal BV",
              "scacCode": "GEEL",
              "smdgCode": "SML",
              "cargoDeclarantShortName": null
            },
            "tareWeight": 5350.0,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "SANU8001609",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-24T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "4EP3",
              "name": "4EP3"
            },
            "containerOperator": {
              "customsId": "NL001571734",
              "name": "Samskip Multimodal BV",
              "scacCode": "GEEL",
              "smdgCode": "SML",
              "cargoDeclarantShortName": null
            },
            "tareWeight": 5350.0,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "SANU8480287",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-24T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "4EPG",
              "name": null
            },
            "containerOperator": {
              "customsId": "NL001571734",
              "name": "Samskip Multimodal BV",
              "scacCode": "GEEL",
              "smdgCode": "SML",
              "cargoDeclarantShortName": null
            },
            "tareWeight": 5200.0,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "SANU8480311",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-24T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "4EPG",
              "name": null
            },
            "containerOperator": {
              "customsId": "NL001571734",
              "name": "Samskip Multimodal BV",
              "scacCode": "GEEL",
              "smdgCode": "SML",
              "cargoDeclarantShortName": null
            },
            "tareWeight": 5200.0,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TCLU2424109",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "NL001571734",
              "name": "Samskip Multimodal BV",
              "scacCode": "GEEL",
              "smdgCode": "SML",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TCLU4837082",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEG1",
              "name": "45 ft 9.6 PW"
            },
            "containerOperator": {
              "customsId": "NL001571734",
              "name": "Samskip Multimodal BV",
              "scacCode": "GEEL",
              "smdgCode": "SML",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TLLU1584287",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEG1",
              "name": "45 ft 9.6 PW"
            },
            "containerOperator": {
              "customsId": "NL001571734",
              "name": "Samskip Multimodal BV",
              "scacCode": "GEEL",
              "smdgCode": "SML",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TLLU1590510",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEG1",
              "name": "45 ft 9.6 PW"
            },
            "containerOperator": {
              "customsId": "NL001571734",
              "name": "Samskip Multimodal BV",
              "scacCode": "GEEL",
              "smdgCode": "SML",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TLLU1636333",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-25T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEG1",
              "name": "45 ft 9.6 PW"
            },
            "containerOperator": {
              "customsId": "NL001571734",
              "name": "Samskip Multimodal BV",
              "scacCode": "GEEL",
              "smdgCode": "SML",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TLLU1646898",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEG1",
              "name": "45 ft 9.6 PW"
            },
            "containerOperator": {
              "customsId": "NL001571734",
              "name": "Samskip Multimodal BV",
              "scacCode": "GEEL",
              "smdgCode": "SML",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TLLU1653433",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-25T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEG1",
              "name": "45 ft 9.6 PW"
            },
            "containerOperator": {
              "customsId": "NL001571734",
              "name": "Samskip Multimodal BV",
              "scacCode": "GEEL",
              "smdgCode": "SML",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TLLU1658029",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEG1",
              "name": "45 ft 9.6 PW"
            },
            "containerOperator": {
              "customsId": "NL001571734",
              "name": "Samskip Multimodal BV",
              "scacCode": "GEEL",
              "smdgCode": "SML",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TLLU1661300",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-25T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEG1",
              "name": "45 ft 9.6 PW"
            },
            "containerOperator": {
              "customsId": "NL001571734",
              "name": "Samskip Multimodal BV",
              "scacCode": "GEEL",
              "smdgCode": "SML",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "TRHU2746873",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "NL001571734",
              "name": "Samskip Multimodal BV",
              "scacCode": "GEEL",
              "smdgCode": "SML",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "SANU7529850",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEG1",
              "name": "45 ft 9.6 PW"
            },
            "containerOperator": {
              "customsId": "NL001571734",
              "name": "Samskip Multimodal BV",
              "scacCode": "GEEL",
              "smdgCode": "SML",
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": "2100225403",
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          }
        ],
        "consignments": [
          {
            "consignmentNumber": "GEEL2100206331",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "SMURFIT KAPPA ROERMOND PA",
              "address": "MIJNHEERKENSWEG",
              "zipCode": "6041",
              "city": "ROERMOND",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "SMURFIT KAPPA ROERMOND PA",
              "address": "MIJNHEERKENSWEG",
              "zipCode": "6041",
              "city": "ROERMOND",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "ROERMOND",
            "onCarriageDetails": null,
            "agentVoyageNumber": "617300",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "WASTE PAPER",
                "grossWeight": 25500.0,
                "numberOfOuterPackages": 66,
                "outerPackageType": {
                  "code": "PX",
                  "name": "Pallet",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "PVDU1071708",
                    "numberOfPackages": 66,
                    "grossWeight": 25500.0
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "GEEL2100207928",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-24T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "BOEHRINGER INGELHEIM",
              "address": "BINGER STRASSE",
              "zipCode": "55218",
              "city": "INGELHEIM AM RHEIN",
              "countryCode": "DE",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "BOEHRINGER INGELHEIM",
              "address": "BINGER STRASSE",
              "zipCode": "55218",
              "city": "INGELHEIM AM RHEIN",
              "countryCode": "DE",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "INGELHEIM AM RHEIN",
            "onCarriageDetails": null,
            "agentVoyageNumber": "617300",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "PCS METFORMIN HCI W/SILICA",
                "grossWeight": 20304.0,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": 22.0,
                "maximumTemperature": 22.0,
                "placements": [
                  {
                    "equipmentNumber": "COCU1004355",
                    "numberOfPackages": 1,
                    "grossWeight": 20304.0
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "GEEL2100210830",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-25T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": "SEA_IN_SEA_OUT",
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "BORREGAARD AS",
              "address": "P/A SAMSKIP MULTIMODAL",
              "zipCode": "3088",
              "city": "ROTTERDAM",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "LISBOA",
              "address": "AVENIDA INFANTE DOM HENRI",
              "zipCode": "1399-",
              "city": "LISBOA",
              "countryCode": "PT",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "LISBOA",
            "onCarriageDetails": null,
            "agentVoyageNumber": "617300",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "BORRESPERSE CA-DA",
                "grossWeight": 25575.0,
                "numberOfOuterPackages": 20,
                "outerPackageType": {
                  "code": "PX",
                  "name": "Pallet",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "TLLU1653433",
                    "numberOfPackages": 20,
                    "grossWeight": 25575.0
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "GEEL2100213681",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "NIJMAN/ZEETANK INT.TANKTR",
              "address": "P/A SAMSKIP MULTIMODAL",
              "zipCode": "3088",
              "city": "ROTTERDAM",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "RST SOUTH CT",
              "address": "REEWEG",
              "zipCode": "3089",
              "city": "ROTTERDAM",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "ROTTERDAM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "617300",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "CYREZ 963K",
                "grossWeight": 29440.0,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "TY",
                  "name": "Tank, cylindrical",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "PCVU3500827",
                    "numberOfPackages": 1,
                    "grossWeight": 29440.0
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "GEEL2100214097",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "SMURFIT KAPPA ROERMOND PA",
              "address": "MIJNHEERKENSWEG",
              "zipCode": "6041",
              "city": "ROERMOND",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "SMURFIT KAPPA ROERMOND PA",
              "address": "MIJNHEERKENSWEG",
              "zipCode": "6041",
              "city": "ROERMOND",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "ROERMOND",
            "onCarriageDetails": null,
            "agentVoyageNumber": "617300",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "WASTE PAPER",
                "grossWeight": 25500.0,
                "numberOfOuterPackages": 65,
                "outerPackageType": {
                  "code": "PX",
                  "name": "Pallet",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "TLLU1658029",
                    "numberOfPackages": 65,
                    "grossWeight": 25500.0
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "GEEL2100216129",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-25T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "OQEMA",
              "address": "EKKERSRIJT",
              "zipCode": "5692",
              "city": "SON",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "OQEMA",
              "address": "EKKERSRIJT",
              "zipCode": "5692",
              "city": "SON",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "SON",
            "onCarriageDetails": null,
            "agentVoyageNumber": "617300",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "SULPHURIC ACID WITH MORE THAN 51% A",
                "grossWeight": 25920.0,
                "numberOfOuterPackages": 18,
                "outerPackageType": {
                  "code": "AA",
                  "name": "Intermediate bulk container, rigid",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": {
                  "name": "SULPHURIC ACID",
                  "unCode": "1830",
                  "hazardClass": "80",
                  "packingGroup": null,
                  "flashPoint": null
                },
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "PVDU1043049",
                    "numberOfPackages": 18,
                    "grossWeight": 25920.0
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "GEEL2100216131",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-25T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "OQEMA",
              "address": "EKKERSRIJT",
              "zipCode": "5692",
              "city": "SON",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "OQEMA",
              "address": "EKKERSRIJT",
              "zipCode": "5692",
              "city": "SON",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "SON",
            "onCarriageDetails": null,
            "agentVoyageNumber": "617300",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "SULPHURIC ACID WITH MORETHAN 51% A",
                "grossWeight": 25920.0,
                "numberOfOuterPackages": 18,
                "outerPackageType": {
                  "code": "AA",
                  "name": "Intermediate bulk container, rigid",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": {
                  "name": "SULPHURIC ACID",
                  "unCode": "1830",
                  "hazardClass": "80",
                  "packingGroup": null,
                  "flashPoint": null
                },
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "TLLU1636333",
                    "numberOfPackages": 18,
                    "grossWeight": 25920.0
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "GEEL2100216134",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-25T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "OQEMA",
              "address": "EKKERSRIJT",
              "zipCode": "5692",
              "city": "SON",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "OQEMA",
              "address": "EKKERSRIJT",
              "zipCode": "5692",
              "city": "SON",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "SON",
            "onCarriageDetails": null,
            "agentVoyageNumber": "617300",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "SULPHURIC ACID WITH MORE THAN 51% A",
                "grossWeight": 25920.0,
                "numberOfOuterPackages": 18,
                "outerPackageType": {
                  "code": "AA",
                  "name": "Intermediate bulk container, rigid",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": {
                  "name": "SULPHURIC ACID",
                  "unCode": "1830",
                  "hazardClass": "80",
                  "packingGroup": null,
                  "flashPoint": null
                },
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "SANU7997341",
                    "numberOfPackages": 18,
                    "grossWeight": 25920.0
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "GEEL2100216135",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-25T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "OQEMA",
              "address": "EKKERSRIJT",
              "zipCode": "5692",
              "city": "SON",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "OQEMA",
              "address": "EKKERSRIJT",
              "zipCode": "5692",
              "city": "SON",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "SON",
            "onCarriageDetails": null,
            "agentVoyageNumber": "617300",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "SULPHURIC ACID WITH MORE THAN 51% A",
                "grossWeight": 25920.0,
                "numberOfOuterPackages": 18,
                "outerPackageType": {
                  "code": "AA",
                  "name": "Intermediate bulk container, rigid",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": {
                  "name": "SULPHURIC ACID",
                  "unCode": "1830",
                  "hazardClass": "80",
                  "packingGroup": null,
                  "flashPoint": null
                },
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "SANU7518325",
                    "numberOfPackages": 18,
                    "grossWeight": 25920.0
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "GEEL2100216136",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-25T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "OQEMA",
              "address": "EKKERSRIJT",
              "zipCode": "5692",
              "city": "SON",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "OQEMA",
              "address": "EKKERSRIJT",
              "zipCode": "5692",
              "city": "SON",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "SON",
            "onCarriageDetails": null,
            "agentVoyageNumber": "617300",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "SULPHURIC ACID WITH MORE THAN 51% A",
                "grossWeight": 25920.0,
                "numberOfOuterPackages": 18,
                "outerPackageType": {
                  "code": "AA",
                  "name": "Intermediate bulk container, rigid",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": {
                  "name": "SULPHURIC ACID",
                  "unCode": "1830",
                  "hazardClass": "80",
                  "packingGroup": null,
                  "flashPoint": null
                },
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "CLXU4512419",
                    "numberOfPackages": 18,
                    "grossWeight": 25920.0
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "GEEL2100218069",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "SMURFIT KAPPA ROERMOND PA",
              "address": "MIJNHEERKENSWEG",
              "zipCode": "6041",
              "city": "ROERMOND",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "SMURFIT KAPPA ROERMOND PA",
              "address": "MIJNHEERKENSWEG",
              "zipCode": "6041",
              "city": "ROERMOND",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "ROERMOND",
            "onCarriageDetails": null,
            "agentVoyageNumber": "617300",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "WASTE PAPER",
                "grossWeight": 25500.0,
                "numberOfOuterPackages": 66,
                "outerPackageType": {
                  "code": "PX",
                  "name": "Pallet",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "SANU7525406",
                    "numberOfPackages": 66,
                    "grossWeight": 25500.0
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "GEEL2100218071",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "SMURFIT KAPPA ROERMOND PA",
              "address": "MIJNHEERKENSWEG",
              "zipCode": "6041",
              "city": "ROERMOND",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "SMURFIT KAPPA ROERMOND PA",
              "address": "MIJNHEERKENSWEG",
              "zipCode": "6041",
              "city": "ROERMOND",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "ROERMOND",
            "onCarriageDetails": null,
            "agentVoyageNumber": "617300",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "WASTE PAPER",
                "grossWeight": 27240.0,
                "numberOfOuterPackages": 29,
                "outerPackageType": {
                  "code": "PX",
                  "name": "Pallet",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "TLLU1584287",
                    "numberOfPackages": 29,
                    "grossWeight": 27240.0
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "GEEL2100218075",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "SMURFIT KAPPA ROERMOND PA",
              "address": "MIJNHEERKENSWEG",
              "zipCode": "6041",
              "city": "ROERMOND",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "SMURFIT KAPPA ROERMOND PA",
              "address": "MIJNHEERKENSWEG",
              "zipCode": "6041",
              "city": "ROERMOND",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "ROERMOND",
            "onCarriageDetails": null,
            "agentVoyageNumber": "617300",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "WASTE PAPER",
                "grossWeight": 25500.0,
                "numberOfOuterPackages": 65,
                "outerPackageType": {
                  "code": "PX",
                  "name": "Pallet",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "SANU7989557",
                    "numberOfPackages": 65,
                    "grossWeight": 25500.0
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "GEEL2100218077",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "SMURFIT KAPPA ROERMOND PA",
              "address": "MIJNHEERKENSWEG",
              "zipCode": "6041",
              "city": "ROERMOND",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "SMURFIT KAPPA ROERMOND PA",
              "address": "MIJNHEERKENSWEG",
              "zipCode": "6041",
              "city": "ROERMOND",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "ROERMOND",
            "onCarriageDetails": null,
            "agentVoyageNumber": "617300",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "WASTE PAPER",
                "grossWeight": 25500.0,
                "numberOfOuterPackages": 65,
                "outerPackageType": {
                  "code": "PX",
                  "name": "Pallet",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "CNEU4607131",
                    "numberOfPackages": 65,
                    "grossWeight": 25500.0
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "GEEL2100218078",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "SMURFIT KAPPA ROERMOND PA",
              "address": "MIJNHEERKENSWEG",
              "zipCode": "6041",
              "city": "ROERMOND",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "SMURFIT KAPPA ROERMOND PA",
              "address": "MIJNHEERKENSWEG",
              "zipCode": "6041",
              "city": "ROERMOND",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "ROERMOND",
            "onCarriageDetails": null,
            "agentVoyageNumber": "617300",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "WASTE PAPER",
                "grossWeight": 25500.0,
                "numberOfOuterPackages": 65,
                "outerPackageType": {
                  "code": "PX",
                  "name": "Pallet",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "SANU7504804",
                    "numberOfPackages": 65,
                    "grossWeight": 25500.0
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "GEEL2100218095",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "PEETERS RECYCLING B.V.",
              "address": "HEYDWEG",
              "zipCode": "6071",
              "city": "SWALMEN",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "PEETERS RECYCLING B.V.",
              "address": "HEYDWEG",
              "zipCode": "6071",
              "city": "SWALMEN",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "SWALMEN",
            "onCarriageDetails": null,
            "agentVoyageNumber": "617300",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "WASTE PAPER",
                "grossWeight": 28060.0,
                "numberOfOuterPackages": 29,
                "outerPackageType": {
                  "code": "PX",
                  "name": "Pallet",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "TCLU4837082",
                    "numberOfPackages": 29,
                    "grossWeight": 28060.0
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "GEEL2100218105",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "SOLIDUS SOLUTIOND BOARD B",
              "address": "INDUSTRIEWEG - WEST",
              "zipCode": "9665",
              "city": "OUDE PEKELA",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "SOLIDUS SOLUTIOND BOARD B",
              "address": "INDUSTRIEWEG - WEST",
              "zipCode": "9665",
              "city": "OUDE PEKELA",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "OUDE PEKELA",
            "onCarriageDetails": null,
            "agentVoyageNumber": "617300",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "WASTE PAPER",
                "grossWeight": 27980.0,
                "numberOfOuterPackages": 27,
                "outerPackageType": {
                  "code": "PX",
                  "name": "Pallet",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "PVDU1129604",
                    "numberOfPackages": 27,
                    "grossWeight": 27980.0
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "GEEL2100218106",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "SOLIDUS SOLUTIOND BOARD B",
              "address": "INDUSTRIEWEG - WEST",
              "zipCode": "9665",
              "city": "OUDE PEKELA",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "SOLIDUS SOLUTIOND BOARD B",
              "address": "INDUSTRIEWEG - WEST",
              "zipCode": "9665",
              "city": "OUDE PEKELA",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "OUDE PEKELA",
            "onCarriageDetails": null,
            "agentVoyageNumber": "617300",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "WASTE PAPER",
                "grossWeight": 28000.0,
                "numberOfOuterPackages": 28,
                "outerPackageType": {
                  "code": "PX",
                  "name": "Pallet",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "PVDU1070297",
                    "numberOfPackages": 28,
                    "grossWeight": 28000.0
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "GEEL2100218107",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "SOLIDUS SOLUTIOND BOARD B",
              "address": "INDUSTRIEWEG - WEST",
              "zipCode": "9665",
              "city": "OUDE PEKELA",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "SOLIDUS SOLUTIOND BOARD B",
              "address": "INDUSTRIEWEG - WEST",
              "zipCode": "9665",
              "city": "OUDE PEKELA",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "OUDE PEKELA",
            "onCarriageDetails": null,
            "agentVoyageNumber": "617300",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "WASTE PAPER",
                "grossWeight": 27900.0,
                "numberOfOuterPackages": 28,
                "outerPackageType": {
                  "code": "PX",
                  "name": "Pallet",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "TLLU1646898",
                    "numberOfPackages": 28,
                    "grossWeight": 27900.0
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "GEEL2100218110",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "SOLIDUS SOLUTIOND BOARD B",
              "address": "INDUSTRIEWEG - WEST",
              "zipCode": "9665",
              "city": "OUDE PEKELA",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "SOLIDUS SOLUTIOND BOARD B",
              "address": "INDUSTRIEWEG - WEST",
              "zipCode": "9665",
              "city": "OUDE PEKELA",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "OUDE PEKELA",
            "onCarriageDetails": null,
            "agentVoyageNumber": "617300",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "WASTE PAPER",
                "grossWeight": 27700.0,
                "numberOfOuterPackages": 28,
                "outerPackageType": {
                  "code": "PX",
                  "name": "Pallet",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "PVDU1058291",
                    "numberOfPackages": 28,
                    "grossWeight": 27700.0
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "GEEL2100218112",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "SOLIDUS SOLUTIOND BOARD B",
              "address": "INDUSTRIEWEG - WEST",
              "zipCode": "9665",
              "city": "OUDE PEKELA",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "SOLIDUS SOLUTIOND BOARD B",
              "address": "INDUSTRIEWEG - WEST",
              "zipCode": "9665",
              "city": "OUDE PEKELA",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "OUDE PEKELA",
            "onCarriageDetails": null,
            "agentVoyageNumber": "617300",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "WASTE PAPER",
                "grossWeight": 27300.0,
                "numberOfOuterPackages": 25,
                "outerPackageType": {
                  "code": "PX",
                  "name": "Pallet",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "SANU7526059",
                    "numberOfPackages": 25,
                    "grossWeight": 27300.0
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "GEEL2100218114",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "SOLIDUS SOLUTIOND BOARD B",
              "address": "INDUSTRIEWEG -WEST",
              "zipCode": "9665",
              "city": "OUDE PEKELA",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "SOLIDUS SOLUTIOND BOARD B",
              "address": "INDUSTRIEWEG - WEST",
              "zipCode": "9665",
              "city": "OUDE PEKELA",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "OUDE PEKELA",
            "onCarriageDetails": null,
            "agentVoyageNumber": "617300",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "WASTE PAPER",
                "grossWeight": 26940.0,
                "numberOfOuterPackages": 26,
                "outerPackageType": {
                  "code": "PX",
                  "name": "Pallet",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "SANU7511573",
                    "numberOfPackages": 26,
                    "grossWeight": 26940.0
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "GEEL2100218133",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "SOLIDUS SOLUTIOND BOARDB",
              "address": "INDUSTRIEWEG - WEST",
              "zipCode": "9665",
              "city": "OUDE PEKELA",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "SOLIDUS SOLUTIOND BOARD B",
              "address": "INDUSTRIEWEG - WEST",
              "zipCode": "9665",
              "city": "OUDE PEKELA",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "OUDE PEKELA",
            "onCarriageDetails": null,
            "agentVoyageNumber": "617300",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "WASTE PAPER",
                "grossWeight": 27880.0,
                "numberOfOuterPackages": 27,
                "outerPackageType": {
                  "code": "PX",
                  "name": "Pallet",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "SANU7526948",
                    "numberOfPackages": 27,
                    "grossWeight": 27880.0
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "GEEL2100218140",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "SOLIDUS SOLUTIOND BOARD B",
              "address": "INDUSTRIEWEG - WEST",
              "zipCode": "9665",
              "city": "OUDE PEKELA",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "SOLIDUS SOLUTIOND BOARD B",
              "address": "INDUSTRIEWEG - WEST",
              "zipCode": "9665",
              "city": "OUDEPEKELA",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "OUDE PEKELA",
            "onCarriageDetails": null,
            "agentVoyageNumber": "617300",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "WASTE PAPER",
                "grossWeight": 27440.0,
                "numberOfOuterPackages": 27,
                "outerPackageType": {
                  "code": "PX",
                  "name": "Pallet",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "SANU7524502",
                    "numberOfPackages": 27,
                    "grossWeight": 27440.0
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "GEEL2100222148",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-25T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "ACCUREC RECYCLING GMBH",
              "address": "WIEHAGEN",
              "zipCode": "45472",
              "city": "MuLHEIM AN DER RUHR",
              "countryCode": "DE",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "ACCUREC RECYCLING GMBH",
              "address": "WIEHAGEN",
              "zipCode": "45472",
              "city": "MuLHEIM AN DER RUHR",
              "countryCode": "DE",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "MuLHEIM AN DER RUHR",
            "onCarriageDetails": null,
            "agentVoyageNumber": "617300",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "BATTERIES, NICKEL-METAL HYDRIDE WAS",
                "grossWeight": 16243.0,
                "numberOfOuterPackages": 33,
                "outerPackageType": {
                  "code": "PX",
                  "name": "Pallet",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": {
                  "name": "BATTERIES, NICKEL-METAL HYDRIDE",
                  "unCode": "3496",
                  "hazardClass": "90",
                  "packingGroup": null,
                  "flashPoint": null
                },
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "SANU7979498",
                    "numberOfPackages": 33,
                    "grossWeight": 16243.0
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "GEEL2100223864",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "PEUTE",
              "address": "VEERPLAAT",
              "zipCode": "3313",
              "city": "DORDRECHT",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "PEUTE",
              "address": "VEERPLAAT",
              "zipCode": "3313",
              "city": "DORDRECHT",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "DORDRECHT",
            "onCarriageDetails": null,
            "agentVoyageNumber": "617300",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "KARTONS23580",
                "grossWeight": 23180.0,
                "numberOfOuterPackages": 48,
                "outerPackageType": {
                  "code": "PX",
                  "name": "Pallet",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "SANU7517057",
                    "numberOfPackages": 48,
                    "grossWeight": 23180.0
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "GEEL2100224161",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "PEETERS RECYCLING B.V.",
              "address": "HEYDWEG",
              "zipCode": "6071",
              "city": "SWALMEN",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "PEETERS RECYCLING B.V.",
              "address": "HEYDWEG",
              "zipCode": "6071",
              "city": "SWALMEN",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "SWALMEN",
            "onCarriageDetails": null,
            "agentVoyageNumber": "617300",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "WASTE PAPER",
                "grossWeight": 27500.0,
                "numberOfOuterPackages": 28,
                "outerPackageType": {
                  "code": "PX",
                  "name": "Pallet",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "SANU7526742",
                    "numberOfPackages": 28,
                    "grossWeight": 27500.0
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "GEEL2100224176",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "PEETERS RECYCLING B.V.",
              "address": "HEYDWEG",
              "zipCode": "6071",
              "city": "SWALMEN",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "PEETERS RECYCLING B.V.",
              "address": "HEYDWEG",
              "zipCode": "6071",
              "city": "SWALMEN",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "SWALMEN",
            "onCarriageDetails": null,
            "agentVoyageNumber": "617300",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "WASTE PAPER",
                "grossWeight": 27240.0,
                "numberOfOuterPackages": 28,
                "outerPackageType": {
                  "code": "PX",
                  "name": "Pallet",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "TLLU1590510",
                    "numberOfPackages": 28,
                    "grossWeight": 27240.0
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "GEEL2100224226",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "LOCATIE WAGENAARS (NEHN23",
              "address": "INDUSTRIEWEG",
              "zipCode": "5281",
              "city": "BOXTEL",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "LOCATIE WAGENAARS (NEHN23",
              "address": "INDUSTRIEWEG",
              "zipCode": "5281",
              "city": "BOXTEL",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "BOXTEL",
            "onCarriageDetails": null,
            "agentVoyageNumber": "617300",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "WASTE PAPER",
                "grossWeight": 27640.0,
                "numberOfOuterPackages": 26,
                "outerPackageType": {
                  "code": "PX",
                  "name": "Pallet",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "SANU7524061",
                    "numberOfPackages": 26,
                    "grossWeight": 27640.0
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "GEEL2100224997",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "H&S FOODTRANS",
              "address": "P/A SAMSKIP MULTIMODAL",
              "zipCode": "3088",
              "city": "ROTTERDAM",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "RST SOUTH CT",
              "address": "REEWEG",
              "zipCode": "3089",
              "city": "ROTTERDAM",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "ROTTERDAM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "617300",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "EMPTY CONTAINER",
                "grossWeight": 4251.0,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "FOTU8428311",
                    "numberOfPackages": 1,
                    "grossWeight": 4251.0
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "GEEL2100225000",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "H&S FOODTRANS",
              "address": "P/A SAMSKIP MULTIMODAL",
              "zipCode": "3088",
              "city": "ROTTERDAM",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "RST SOUTH CT",
              "address": "REEWEG",
              "zipCode": "3089",
              "city": "ROTTERDAM",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "ROTTERDAM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "617300",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "EMPTY CONTAINER",
                "grossWeight": 4251.0,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "FOTU6426069",
                    "numberOfPackages": 1,
                    "grossWeight": 4251.0
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "GEEL2100225001",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "H&S FOODTRANS",
              "address": "P/A SAMSKIP MULTIMODAL",
              "zipCode": "3088",
              "city": "ROTTERDAM",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "RST SOUTH CT",
              "address": "REEWEG",
              "zipCode": "3089",
              "city": "ROTTERDAM",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "ROTTERDAM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "617300",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "EMPTY CONTAINER",
                "grossWeight": 4251.0,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "FOTU8428034",
                    "numberOfPackages": 1,
                    "grossWeight": 4251.0
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "GEEL2100225461",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": "SEA_IN_SEA_OUT",
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "SAMSKIP BENELUX BV",
              "address": "P/A SAMSKIP MULTIMODAL",
              "zipCode": "3088",
              "city": "ROTTERDAM",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "RST SOUTH CT",
              "address": "REEWEG",
              "zipCode": "3089",
              "city": "ROTTERDAM",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "ROTTERDAM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "617300",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "MORTAR",
                "grossWeight": 24626.0,
                "numberOfOuterPackages": 21,
                "outerPackageType": {
                  "code": "PX",
                  "name": "Pallet",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "TCLU2424109",
                    "numberOfPackages": 21,
                    "grossWeight": 24626.0
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "GEEL2100225485",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": "SEA_IN_SEA_OUT",
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "SAMSKIP BENELUX BV",
              "address": "P/A SAMSKIP MULTIMODAL",
              "zipCode": "3088",
              "city": "ROTTERDAM",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "RST SOUTH CT",
              "address": "REEWEG",
              "zipCode": "3089",
              "city": "ROTTERDAM",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "ROTTERDAM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "617300",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "TRIMETHYLHEXAMETHYLENEDIAMINES MAPE",
                "grossWeight": 23.0,
                "numberOfOuterPackages": 3,
                "outerPackageType": {
                  "code": "PX",
                  "name": "Pallet",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": {
                  "name": "TRIMETHYLHEXAMETHYLENEDIAMINES",
                  "unCode": "2327",
                  "hazardClass": "80",
                  "packingGroup": null,
                  "flashPoint": null
                },
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "NYKU3788111",
                    "numberOfPackages": 3,
                    "grossWeight": 23.0
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 2,
                "description": "FASADEMORTEL SI GRA M/FIBER 25 KG",
                "grossWeight": 1200.0,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "PX",
                  "name": "Pallet",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "NYKU3788111",
                    "numberOfPackages": 1,
                    "grossWeight": 1200.0
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 3,
                "description": "MAPSEIL 100 0.31 LITER",
                "grossWeight": 24.0,
                "numberOfOuterPackages": 2,
                "outerPackageType": {
                  "code": "PX",
                  "name": "Pallet",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "NYKU3788111",
                    "numberOfPackages": 2,
                    "grossWeight": 24.0
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 4,
                "description": "MAPSEIL AC 1100.31 LITER",
                "grossWeight": 36.0,
                "numberOfOuterPackages": 3,
                "outerPackageType": {
                  "code": "PX",
                  "name": "Pallet",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "NYKU3788111",
                    "numberOfPackages": 3,
                    "grossWeight": 36.0
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 5,
                "description": "MPEFLEX AC4 WHITE /NOR BOXES 12X310",
                "grossWeight": 36.0,
                "numberOfOuterPackages": 3,
                "outerPackageType": {
                  "code": "PX",
                  "name": "Pallet",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "NYKU3788111",
                    "numberOfPackages": 3,
                    "grossWeight": 36.0
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 6,
                "description": "PLAINTOP SMOOTH & REPAIR (RASA E RI",
                "grossWeight": 6000.0,
                "numberOfOuterPackages": 6,
                "outerPackageType": {
                  "code": "PX",
                  "name": "Pallet",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "NYKU3788111",
                    "numberOfPackages": 6,
                    "grossWeight": 6000.0
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 7,
                "description": "PRIMER E10 5KG",
                "grossWeight": 50.0,
                "numberOfOuterPackages": 10,
                "outerPackageType": {
                  "code": "PK",
                  "name": "Package",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "NYKU3788111",
                    "numberOfPackages": 10,
                    "grossWeight": 50.0
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 8,
                "description": "PRIMER ECO 1 KG",
                "grossWeight": 30.0,
                "numberOfOuterPackages": 5,
                "outerPackageType": {
                  "code": "PX",
                  "name": "Pallet",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "NYKU3788111",
                    "numberOfPackages": 5,
                    "grossWeight": 30.0
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 9,
                "description": "UNIPLAN ECO 20 KG",
                "grossWeight": 9600.0,
                "numberOfOuterPackages": 10,
                "outerPackageType": {
                  "code": "PX",
                  "name": "Pallet",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "NYKU3788111",
                    "numberOfPackages": 10,
                    "grossWeight": 9600.0
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 10,
                "description": "UNIPLAN ECO LC 20 KG",
                "grossWeight": 2880.0,
                "numberOfOuterPackages": 3,
                "outerPackageType": {
                  "code": "PX",
                  "name": "Pallet",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "NYKU3788111",
                    "numberOfPackages": 3,
                    "grossWeight": 2880.0
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "GEEL2100225486",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": "SEA_IN_SEA_OUT",
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "SAMSKIP BENELUX BV",
              "address": "P/A SAMSKIP MULTIMODAL",
              "zipCode": "3088",
              "city": "ROTTERDAM",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "RST SOUTH CT",
              "address": "REEWEG",
              "zipCode": "3089",
              "city": "ROTTERDAM",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "ROTTERDAM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "617300",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "MORTAR",
                "grossWeight": 24040.0,
                "numberOfOuterPackages": 20,
                "outerPackageType": {
                  "code": "PX",
                  "name": "Pallet",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "TRHU2746873",
                    "numberOfPackages": 20,
                    "grossWeight": 24040.0
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "GEEL2100225487",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": "SEA_IN_SEA_OUT",
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "SAMSKIP BENELUX BV",
              "address": "P/A SAMSKIP MULTIMODAL",
              "zipCode": "3088",
              "city": "ROTTERDAM",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "RST SOUTH CT",
              "address": "REEWEG",
              "zipCode": "3089",
              "city": "ROTTERDAM",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "ROTTERDAM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "617300",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "MORTAR",
                "grossWeight": 24040.0,
                "numberOfOuterPackages": 20,
                "outerPackageType": {
                  "code": "PX",
                  "name": "Pallet",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "MOAU6609688",
                    "numberOfPackages": 20,
                    "grossWeight": 24040.0
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "GEEL2100225488",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": "SEA_IN_SEA_OUT",
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "SAMSKIP BENELUX BV",
              "address": "P/A SAMSKIP MULTIMODAL",
              "zipCode": "3088",
              "city": "ROTTERDAM",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "RST SOUTH CT",
              "address": "REEWEG",
              "zipCode": "3089",
              "city": "ROTTERDAM",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "ROTTERDAM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "617300",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "MORTAR",
                "grossWeight": 24040.0,
                "numberOfOuterPackages": 20,
                "outerPackageType": {
                  "code": "PX",
                  "name": "Pallet",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "CAIU3030000",
                    "numberOfPackages": 20,
                    "grossWeight": 24040.0
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "GEEL2100225494",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": "SEA_IN_SEA_OUT",
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "SAMSKIP BENELUX BV",
              "address": "P/A SAMSKIP MULTIMODAL",
              "zipCode": "3088",
              "city": "ROTTERDAM",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "RST SOUTH CT",
              "address": "REEWEG",
              "zipCode": "3089",
              "city": "ROTTERDAM",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "ROTTERDAM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "617300",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "MORTAR",
                "grossWeight": 22674.0,
                "numberOfOuterPackages": 20,
                "outerPackageType": {
                  "code": "PX",
                  "name": "Pallet",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "MRSU3810357",
                    "numberOfPackages": 20,
                    "grossWeight": 22674.0
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "GEEL2100225670",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "PEUTE",
              "address": "VEERPLAAT",
              "zipCode": "3313",
              "city": "DORDRECHT",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "PEUTE",
              "address": "VEERPLAAT",
              "zipCode": "3313",
              "city": "DORDRECHT",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "DORDRECHT",
            "onCarriageDetails": null,
            "agentVoyageNumber": "617300",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "RECOVERED PAPER",
                "grossWeight": 25380.0,
                "numberOfOuterPackages": 40,
                "outerPackageType": {
                  "code": "PX",
                  "name": "Pallet",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "SANU7511418",
                    "numberOfPackages": 40,
                    "grossWeight": 25380.0
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "GEEL2100226085",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-25T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "SE TYLOSE GMBH &AMP; CO.",
              "address": "RHEINGAUSTR",
              "zipCode": "65203",
              "city": "WIESBADEN",
              "countryCode": "DE",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "SE TYLOSE GMBH &AMP; CO.",
              "address": "RHEINGAUSTR",
              "zipCode": "65203",
              "city": "WIESBADEN",
              "countryCode": "DE",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "WIESBADEN",
            "onCarriageDetails": null,
            "agentVoyageNumber": "617300",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "SPRUCE SULPHITE PULP",
                "grossWeight": 26630.0,
                "numberOfOuterPackages": 56,
                "outerPackageType": {
                  "code": "PX",
                  "name": "Pallet",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "TLLU1661300",
                    "numberOfPackages": 56,
                    "grossWeight": 26630.0
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "GEEL2100226516",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "H&S FOODTRANS",
              "address": "P/A SAMSKIP MULTIMODAL",
              "zipCode": "3088",
              "city": "ROTTERDAM",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "RST SOUTH CT",
              "address": "REEWEG",
              "zipCode": "3089",
              "city": "ROTTERDAM",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "ROTTERDAM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "617300",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "EMPTY CONTAINER",
                "grossWeight": 4251.0,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "FOTU8410267",
                    "numberOfPackages": 1,
                    "grossWeight": 4251.0
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "GEEL2100227369",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "HOYER GMBH",
              "address": "P/A SAMSKIP MULTIMODAL",
              "zipCode": "3088",
              "city": "ROTTERDAM",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "RST SOUTH CT",
              "address": "REEWEG",
              "zipCode": "3089",
              "city": "ROTTERDAM",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "ROTTERDAM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "617300",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "CYMEL C-303 LF",
                "grossWeight": 19000.0,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "TY",
                  "name": "Tank, cylindrical",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "HOYU9619044",
                    "numberOfPackages": 1,
                    "grossWeight": 19000.0
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "GEEL2100227464",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "CONWELL AS",
              "address": "P/A SAMSKIP MULTIMODAL",
              "zipCode": "3088",
              "city": "ROTTERDAM",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "RST SOUTH CT",
              "address": "REEWEG",
              "zipCode": "3089",
              "city": "ROTTERDAM",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "ROTTERDAM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "617300",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "EMPTY CONTAINER",
                "grossWeight": 3361.0,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "ABEU4910687",
                    "numberOfPackages": 1,
                    "grossWeight": 3361.0
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "GEEL2100227883",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "SUMITOMO CHEMICAL EUROPE",
              "address": "WOLUWELAAN",
              "zipCode": "1830",
              "city": "MACHELEN",
              "countryCode": "BE",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "SUMITOMO CHEMICAL EUROPE",
              "address": "WOLUWELAAN",
              "zipCode": "1830",
              "city": "MACHELEN",
              "countryCode": "BE",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "MACHELEN",
            "onCarriageDetails": null,
            "agentVoyageNumber": "617300",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "F127220 PENACOLITE R-2170 (IBC 1250",
                "grossWeight": 23490.0,
                "numberOfOuterPackages": 18,
                "outerPackageType": {
                  "code": "AA",
                  "name": "Intermediate bulk container, rigid",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "CNEU4569900",
                    "numberOfPackages": 18,
                    "grossWeight": 23490.0
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "GEEL2100228512",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "CONWELL AS",
              "address": "P/A SAMSKIP MULTIMODAL",
              "zipCode": "3088",
              "city": "ROTTERDAM",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "RST SOUTH CT",
              "address": "REEWEG",
              "zipCode": "3089",
              "city": "ROTTERDAM",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "ROTTERDAM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "617300",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "EMPTY CONTAINER",
                "grossWeight": 4241.0,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "LAFU3219137",
                    "numberOfPackages": 1,
                    "grossWeight": 4241.0
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "GEEL2100229030",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "H&S FOODTRANS",
              "address": "P/A SAMSKIP MULTIMODAL",
              "zipCode": "3088",
              "city": "ROTTERDAM",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "RST SOUTH CT",
              "address": "REEWEG",
              "zipCode": "3089",
              "city": "ROTTERDAM",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "ROTTERDAM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "617300",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "EMPTY CONTAINER",
                "grossWeight": 3271.0,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "FOTU5436904",
                    "numberOfPackages": 1,
                    "grossWeight": 3271.0
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "GEEL2100229784",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-24T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "KUBE & KUBENZ I.S.LOG. KG",
              "address": "P/A SAMSKIP MULTIMODAL",
              "zipCode": "3088",
              "city": "ROTTERDAM",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "RST SOUTH CT",
              "address": "REEWEG",
              "zipCode": "3089",
              "city": "ROTTERDAM",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "ROTTERDAM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "617300",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "EMPTY CONTAINER",
                "grossWeight": 4401.0,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "MCMU6300309",
                    "numberOfPackages": 1,
                    "grossWeight": 4401.0
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "GEEL2100229878",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-25T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": "SEA_IN_SEA_OUT",
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "ANDERSEN & MOERK AS",
              "address": "P/A SAMSKIP MULTIMODAL",
              "zipCode": "3088",
              "city": "ROTTERDAM",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "WESTLAND HORTICULTURE",
              "address": "GRANVILLE ROAD",
              "zipCode": "BT70",
              "city": "DUNGANNON",
              "countryCode": "GB",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euLocation": false
            },
            "placeOfDestination": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euLocation": true
            },
            "placeOfDelivery": "DUNGANNON",
            "onCarriageDetails": null,
            "agentVoyageNumber": "617300",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "POTASSIUM SULFAT I BIG BAG",
                "grossWeight": 25220.0,
                "numberOfOuterPackages": 24,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "SANU7700200",
                    "numberOfPackages": 24,
                    "grossWeight": 25220.0
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "GEEL9731518001",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-24T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "SAMSKIP MCL BV.",
              "address": "WAALHAVEN O.Z. 81",
              "zipCode": "3088",
              "city": "ROTTERDAM",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "consignee": {
              "eoriNumber": null,
              "name": "ROTTERDAM RST ZZ",
              "address": "P/A SAMSKIP MULTIMODAL",
              "zipCode": "3088",
              "city": "ROTTERDAM",
              "countryCode": "NL",
              "authorised": null,
              "id": null,
              "ownerShortName": null
            },
            "partiesToNotify": [],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": "ROTTERDAM",
            "onCarriageDetails": null,
            "agentVoyageNumber": "617300",
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "EMPTY CONTAINER",
                "grossWeight": 5350.0,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "SANU8000351",
                    "numberOfPackages": 1,
                    "grossWeight": 5350.0
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 2,
                "description": "EMPTY CONTAINER",
                "grossWeight": 5350.0,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "SANU8001105",
                    "numberOfPackages": 1,
                    "grossWeight": 5350.0
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 3,
                "description": "EMPTY CONTAINER",
                "grossWeight": 5200.0,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "SANU8480311",
                    "numberOfPackages": 1,
                    "grossWeight": 5200.0
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 4,
                "description": "EMPTY CONTAINER",
                "grossWeight": 5350.0,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "SANU8000428",
                    "numberOfPackages": 1,
                    "grossWeight": 5350.0
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 5,
                "description": "EMPTY CONTAINER",
                "grossWeight": 5200.0,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "SANU8480287",
                    "numberOfPackages": 1,
                    "grossWeight": 5200.0
                  }
                ],
                "producedDocuments": []
              },
              {
                "itemNumber": 6,
                "description": "EMPTY CONTAINER",
                "grossWeight": 5350.0,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "SANU8001609",
                    "numberOfPackages": 1,
                    "grossWeight": 5350.0
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "GEEL2100225403",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-09-22T22:00:00Z",
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": "SEA_IN_SEA_OUT",
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "Samskip Multimodal bv",
              "address": "Waalhaven O.z. 81",
              "zipCode": "3087BM",
              "city": "Rotterdam",
              "countryCode": "NL",
              "authorised": null,
              "id": "eb876bc9-35ee-4218-8673-937e9c90a2ee",
              "ownerShortName": "GEESTRTM"
            },
            "consignee": null,
            "partiesToNotify": [
              {
                "eoriNumber": null,
                "name": "Samskip Multimodal bv",
                "address": "Waalhaven O.z. 81",
                "zipCode": "3087BM",
                "city": "Rotterdam",
                "countryCode": "NL",
                "authorised": null,
                "id": "eb876bc9-35ee-4218-8673-937e9c90a2ee",
                "ownerShortName": "GEESTRTM"
              }
            ],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": null,
            "onCarriageDetails": null,
            "agentVoyageNumber": null,
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "empty kegs",
                "grossWeight": 10454,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": 1584,
                "innerPackageType": {
                  "code": "KG",
                  "name": "Keg",
                  "bulk": false
                },
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "SANU7529850",
                    "numberOfPackages": 1584,
                    "grossWeight": 10454
                  }
                ],
                "producedDocuments": []
              }
            ]
          }
        ],
        "receivedMrns": {},
        "discharges": [
          {
            "number": "SANU7979498",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T02:27:00Z",
            "stowageLocation": null,
            "grossWeight": 20343,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEG1",
              "name": "45 ft 9.6 PW"
            },
            "containerOperator": {
              "customsId": "NL001571734",
              "name": "Samskip Multimodal BV",
              "scacCode": "GEEL",
              "smdgCode": "SML",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "GEESTRTM",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TLLU1661300",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T02:29:00Z",
            "stowageLocation": null,
            "grossWeight": 30910,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEG1",
              "name": "45 ft 9.6 PW"
            },
            "containerOperator": {
              "customsId": "NL001571734",
              "name": "Samskip Multimodal BV",
              "scacCode": "GEEL",
              "smdgCode": "SML",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "GEESTRTM",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "CLXU4512419",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T02:33:00Z",
            "stowageLocation": null,
            "grossWeight": 30180,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEG1",
              "name": "45 ft 9.6 PW"
            },
            "containerOperator": {
              "customsId": "NL001571734",
              "name": "Samskip Multimodal BV",
              "scacCode": "GEEL",
              "smdgCode": "SML",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "GEESTRTM",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "SANU7518325",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T02:37:00Z",
            "stowageLocation": null,
            "grossWeight": 30120,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEG1",
              "name": "45 ft 9.6 PW"
            },
            "containerOperator": {
              "customsId": "NL001571734",
              "name": "Samskip Multimodal BV",
              "scacCode": "GEEL",
              "smdgCode": "SML",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "GEESTRTM",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TLLU1653433",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T02:41:00Z",
            "stowageLocation": null,
            "grossWeight": 29855,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEG1",
              "name": "45 ft 9.6 PW"
            },
            "containerOperator": {
              "customsId": "NL001571734",
              "name": "Samskip Multimodal BV",
              "scacCode": "GEEL",
              "smdgCode": "SML",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "GEESTRTM",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "PVDU1043049",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T02:45:00Z",
            "stowageLocation": null,
            "grossWeight": 30180,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEG1",
              "name": "45 ft 9.6 PW"
            },
            "containerOperator": {
              "customsId": "NL001571734",
              "name": "Samskip Multimodal BV",
              "scacCode": "GEEL",
              "smdgCode": "SML",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "GEESTRTM",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "SANU7700200",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T02:47:00Z",
            "stowageLocation": null,
            "grossWeight": 29960,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEV1",
              "name": "45'x9'x9,6' High Cube Ventilated Container"
            },
            "containerOperator": {
              "customsId": "NL001571734",
              "name": "Samskip Multimodal BV",
              "scacCode": "GEEL",
              "smdgCode": "SML",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "GEESTRTM",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "SANU7997341",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T02:49:00Z",
            "stowageLocation": null,
            "grossWeight": 30240,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEG1",
              "name": "45 ft 9.6 PW"
            },
            "containerOperator": {
              "customsId": "NL001571734",
              "name": "Samskip Multimodal BV",
              "scacCode": "GEEL",
              "smdgCode": "SML",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "GEESTRTM",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TLLU1636333",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T02:50:00Z",
            "stowageLocation": null,
            "grossWeight": 30200,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEG1",
              "name": "45 ft 9.6 PW"
            },
            "containerOperator": {
              "customsId": "NL001571734",
              "name": "Samskip Multimodal BV",
              "scacCode": "GEEL",
              "smdgCode": "SML",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "GEESTRTM",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "FOTU5436904",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T03:15:00Z",
            "stowageLocation": null,
            "grossWeight": null,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "2MT5",
              "name": "20' ft Tank"
            },
            "containerOperator": {
              "customsId": "NL001571734",
              "name": "Samskip Multimodal BV",
              "scacCode": "GEEL",
              "smdgCode": "SML",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "GEESTRTM",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": true,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "FOTU8410267",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T03:17:00Z",
            "stowageLocation": null,
            "grossWeight": null,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "2MK5",
              "name": null
            },
            "containerOperator": {
              "customsId": "NL001571734",
              "name": "Samskip Multimodal BV",
              "scacCode": "GEEL",
              "smdgCode": "SML",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "GEESTRTM",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": true,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "FOTU8428311",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T03:53:00Z",
            "stowageLocation": null,
            "grossWeight": null,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "2MKD",
              "name": "20'x8'x9' Tank Container"
            },
            "containerOperator": {
              "customsId": "NL001571734",
              "name": "Samskip Multimodal BV",
              "scacCode": "GEEL",
              "smdgCode": "SML",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "GEESTRTM",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": true,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "SANU7529850",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T05:04:00Z",
            "stowageLocation": null,
            "grossWeight": 14654,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEG1",
              "name": "45 ft 9.6 PW"
            },
            "containerOperator": {
              "customsId": "NL001571734",
              "name": "Samskip Multimodal BV",
              "scacCode": "GEEL",
              "smdgCode": "SML",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "GEESTRTM",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "PCVU3500827",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T05:32:00Z",
            "stowageLocation": null,
            "grossWeight": 33880,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "EMT0",
              "name": "EMT0"
            },
            "containerOperator": {
              "customsId": "NL001571734",
              "name": "Samskip Multimodal BV",
              "scacCode": "GEEL",
              "smdgCode": "SML",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "GEESTRTM",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": true,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "LAFU3219137",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T05:35:00Z",
            "stowageLocation": null,
            "grossWeight": null,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "EDKJ",
              "name": "20'x8'x9' Tank Container Tare 2500 kg"
            },
            "containerOperator": {
              "customsId": "NL001571734",
              "name": "Samskip Multimodal BV",
              "scacCode": "GEEL",
              "smdgCode": "SML",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "GEESTRTM",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": true,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "SANU8000351",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T06:10:00Z",
            "stowageLocation": null,
            "grossWeight": null,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "4EP3",
              "name": "4EP3"
            },
            "containerOperator": {
              "customsId": "NL001571734",
              "name": "Samskip Multimodal BV",
              "scacCode": "GEEL",
              "smdgCode": "SML",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "GEESTRTM",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "SANU8480311",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T06:10:00Z",
            "stowageLocation": null,
            "grossWeight": null,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "4EP3",
              "name": "4EP3"
            },
            "containerOperator": {
              "customsId": "NL001571734",
              "name": "Samskip Multimodal BV",
              "scacCode": "GEEL",
              "smdgCode": "SML",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "GEESTRTM",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "SANU8000428",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T06:10:00Z",
            "stowageLocation": null,
            "grossWeight": null,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "4EP3",
              "name": "4EP3"
            },
            "containerOperator": {
              "customsId": "NL001571734",
              "name": "Samskip Multimodal BV",
              "scacCode": "GEEL",
              "smdgCode": "SML",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "GEESTRTM",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "SANU8001609",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T06:10:00Z",
            "stowageLocation": null,
            "grossWeight": null,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "4EP3",
              "name": "4EP3"
            },
            "containerOperator": {
              "customsId": "NL001571734",
              "name": "Samskip Multimodal BV",
              "scacCode": "GEEL",
              "smdgCode": "SML",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "GEESTRTM",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "SANU8480287",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T06:10:00Z",
            "stowageLocation": null,
            "grossWeight": null,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "4EP3",
              "name": "4EP3"
            },
            "containerOperator": {
              "customsId": "NL001571734",
              "name": "Samskip Multimodal BV",
              "scacCode": "GEEL",
              "smdgCode": "SML",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "GEESTRTM",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "SANU8001105",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T06:10:00Z",
            "stowageLocation": null,
            "grossWeight": null,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "4EP3",
              "name": "4EP3"
            },
            "containerOperator": {
              "customsId": "NL001571734",
              "name": "Samskip Multimodal BV",
              "scacCode": "GEEL",
              "smdgCode": "SML",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "GEESTRTM",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "SANU7517057",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T06:13:00Z",
            "stowageLocation": null,
            "grossWeight": 27380,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEG1",
              "name": "45 ft 9.6 PW"
            },
            "containerOperator": {
              "customsId": "NL001571734",
              "name": "Samskip Multimodal BV",
              "scacCode": "GEEL",
              "smdgCode": "SML",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "GEESTRTM",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TLLU1658029",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T06:17:00Z",
            "stowageLocation": null,
            "grossWeight": 29700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEG1",
              "name": "45 ft 9.6 PW"
            },
            "containerOperator": {
              "customsId": "NL001571734",
              "name": "Samskip Multimodal BV",
              "scacCode": "GEEL",
              "smdgCode": "SML",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "GEESTRTM",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "SANU7525406",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T06:21:00Z",
            "stowageLocation": null,
            "grossWeight": 29700,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEG1",
              "name": "45 ft 9.6 PW"
            },
            "containerOperator": {
              "customsId": "NL001571734",
              "name": "Samskip Multimodal BV",
              "scacCode": "GEEL",
              "smdgCode": "SML",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "GEESTRTM",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "CNEU4569900",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T06:24:00Z",
            "stowageLocation": null,
            "grossWeight": 27770,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEG1",
              "name": "45 ft 9.6 PW"
            },
            "containerOperator": {
              "customsId": "NL001571734",
              "name": "Samskip Multimodal BV",
              "scacCode": "GEEL",
              "smdgCode": "SML",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "GEESTRTM",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "SANU7511573",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T06:35:00Z",
            "stowageLocation": null,
            "grossWeight": 31140,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEG1",
              "name": "45 ft 9.6 PW"
            },
            "containerOperator": {
              "customsId": "NL001571734",
              "name": "Samskip Multimodal BV",
              "scacCode": "GEEL",
              "smdgCode": "SML",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "GEESTRTM",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "SANU7524061",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T06:38:00Z",
            "stowageLocation": null,
            "grossWeight": 31840,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEG1",
              "name": "45 ft 9.6 PW"
            },
            "containerOperator": {
              "customsId": "NL001571734",
              "name": "Samskip Multimodal BV",
              "scacCode": "GEEL",
              "smdgCode": "SML",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "GEESTRTM",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "FOTU6426069",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T06:44:00Z",
            "stowageLocation": null,
            "grossWeight": null,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "2MKD",
              "name": "20'x8'x9' Tank Container"
            },
            "containerOperator": {
              "customsId": "NL001571734",
              "name": "Samskip Multimodal BV",
              "scacCode": "GEEL",
              "smdgCode": "SML",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "GEESTRTM",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": true,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "NYKU3788111",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T06:51:00Z",
            "stowageLocation": null,
            "grossWeight": 22642,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "NL001571734",
              "name": "Samskip Multimodal BV",
              "scacCode": "GEEL",
              "smdgCode": "SML",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "GEESTRTM",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": true,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "CAIU3030000",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T06:54:00Z",
            "stowageLocation": null,
            "grossWeight": 26240,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "NL001571734",
              "name": "Samskip Multimodal BV",
              "scacCode": "GEEL",
              "smdgCode": "SML",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "GEESTRTM",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": true,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "ABEU4910687",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T06:56:00Z",
            "stowageLocation": null,
            "grossWeight": null,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "3MNL",
              "name": "30'x8,6'x9' Tank Bulk Container"
            },
            "containerOperator": {
              "customsId": "NL001571734",
              "name": "Samskip Multimodal BV",
              "scacCode": "GEEL",
              "smdgCode": "SML",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "GEESTRTM",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": true,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "SANU7526948",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T07:02:00Z",
            "stowageLocation": null,
            "grossWeight": 32080,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEG1",
              "name": "45 ft 9.6 PW"
            },
            "containerOperator": {
              "customsId": "NL001571734",
              "name": "Samskip Multimodal BV",
              "scacCode": "GEEL",
              "smdgCode": "SML",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "GEESTRTM",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TLLU1584287",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T07:04:00Z",
            "stowageLocation": null,
            "grossWeight": 31440,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEG1",
              "name": "45 ft 9.6 PW"
            },
            "containerOperator": {
              "customsId": "NL001571734",
              "name": "Samskip Multimodal BV",
              "scacCode": "GEEL",
              "smdgCode": "SML",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "GEESTRTM",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "SANU7524502",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T07:06:00Z",
            "stowageLocation": null,
            "grossWeight": 31650,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEG1",
              "name": "45 ft 9.6 PW"
            },
            "containerOperator": {
              "customsId": "NL001571734",
              "name": "Samskip Multimodal BV",
              "scacCode": "GEEL",
              "smdgCode": "SML",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "GEESTRTM",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "PVDU1129604",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T07:08:00Z",
            "stowageLocation": null,
            "grossWeight": 32260,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEGB",
              "name": "45 Foot High Cube General Purpose / Dry Container"
            },
            "containerOperator": {
              "customsId": "NL001571734",
              "name": "Samskip Multimodal BV",
              "scacCode": "GEEL",
              "smdgCode": "SML",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "GEESTRTM",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "SANU7526742",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T07:09:00Z",
            "stowageLocation": null,
            "grossWeight": 31780,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEG1",
              "name": "45 ft 9.6 PW"
            },
            "containerOperator": {
              "customsId": "NL001571734",
              "name": "Samskip Multimodal BV",
              "scacCode": "GEEL",
              "smdgCode": "SML",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "GEESTRTM",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TCLU4837082",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T07:14:00Z",
            "stowageLocation": null,
            "grossWeight": 32340,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEG1",
              "name": "45 ft 9.6 PW"
            },
            "containerOperator": {
              "customsId": "NL001571734",
              "name": "Samskip Multimodal BV",
              "scacCode": "GEEL",
              "smdgCode": "SML",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "GEESTRTM",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TLLU1590510",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T07:52:00Z",
            "stowageLocation": null,
            "grossWeight": 31520,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEG1",
              "name": "45 ft 9.6 PW"
            },
            "containerOperator": {
              "customsId": "NL001571734",
              "name": "Samskip Multimodal BV",
              "scacCode": "GEEL",
              "smdgCode": "SML",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "GEESTRTM",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TLLU1646898",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T07:54:00Z",
            "stowageLocation": null,
            "grossWeight": 32180,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEG1",
              "name": "45 ft 9.6 PW"
            },
            "containerOperator": {
              "customsId": "NL001571734",
              "name": "Samskip Multimodal BV",
              "scacCode": "GEEL",
              "smdgCode": "SML",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "GEESTRTM",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "PVDU1071708",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T07:56:00Z",
            "stowageLocation": null,
            "grossWeight": 29780,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEG1",
              "name": "45 ft 9.6 PW"
            },
            "containerOperator": {
              "customsId": "NL001571734",
              "name": "Samskip Multimodal BV",
              "scacCode": "GEEL",
              "smdgCode": "SML",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "GEESTRTM",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "SANU7989557",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T08:00:00Z",
            "stowageLocation": null,
            "grossWeight": 29730,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEG1",
              "name": "45 ft 9.6 PW"
            },
            "containerOperator": {
              "customsId": "NL001571734",
              "name": "Samskip Multimodal BV",
              "scacCode": "GEEL",
              "smdgCode": "SML",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "GEESTRTM",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "SANU7511418",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T08:03:00Z",
            "stowageLocation": null,
            "grossWeight": 29660,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEG1",
              "name": "45 ft 9.6 PW"
            },
            "containerOperator": {
              "customsId": "NL001571734",
              "name": "Samskip Multimodal BV",
              "scacCode": "GEEL",
              "smdgCode": "SML",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "GEESTRTM",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "SANU7504804",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T08:09:00Z",
            "stowageLocation": null,
            "grossWeight": 29780,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEG1",
              "name": "45 ft 9.6 PW"
            },
            "containerOperator": {
              "customsId": "NL001571734",
              "name": "Samskip Multimodal BV",
              "scacCode": "GEEL",
              "smdgCode": "SML",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "GEESTRTM",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "SANU7526059",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T08:11:00Z",
            "stowageLocation": null,
            "grossWeight": 31500,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEG1",
              "name": "45 ft 9.6 PW"
            },
            "containerOperator": {
              "customsId": "NL001571734",
              "name": "Samskip Multimodal BV",
              "scacCode": "GEEL",
              "smdgCode": "SML",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "GEESTRTM",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "MRSU3810357",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T08:17:00Z",
            "stowageLocation": null,
            "grossWeight": 26394,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "45G1",
              "name": "40'x8'x9'6\" High Cube GP Container"
            },
            "containerOperator": {
              "customsId": "NL001571734",
              "name": "Samskip Multimodal BV",
              "scacCode": "GEEL",
              "smdgCode": "SML",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "GEESTRTM",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": true,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "CNEU4607131",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T08:25:00Z",
            "stowageLocation": null,
            "grossWeight": 29880,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEGB",
              "name": "45 Foot High Cube General Purpose / Dry Container"
            },
            "containerOperator": {
              "customsId": "NL001571734",
              "name": "Samskip Multimodal BV",
              "scacCode": "GEEL",
              "smdgCode": "SML",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "GEESTRTM",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "PVDU1070297",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T08:27:00Z",
            "stowageLocation": null,
            "grossWeight": 32280,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEG1",
              "name": "45 ft 9.6 PW"
            },
            "containerOperator": {
              "customsId": "NL001571734",
              "name": "Samskip Multimodal BV",
              "scacCode": "GEEL",
              "smdgCode": "SML",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "GEESTRTM",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "PVDU1058291",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T08:34:00Z",
            "stowageLocation": null,
            "grossWeight": 31960,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "LEG1",
              "name": "45 ft 9.6 PW"
            },
            "containerOperator": {
              "customsId": "NL001571734",
              "name": "Samskip Multimodal BV",
              "scacCode": "GEEL",
              "smdgCode": "SML",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "GEESTRTM",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "FOTU8428034",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T08:51:00Z",
            "stowageLocation": null,
            "grossWeight": null,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "2MKD",
              "name": "20'x8'x9' Tank Container"
            },
            "containerOperator": {
              "customsId": "NL001571734",
              "name": "Samskip Multimodal BV",
              "scacCode": "GEEL",
              "smdgCode": "SML",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "GEESTRTM",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": true,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "HOYU9619044",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T10:50:00Z",
            "stowageLocation": null,
            "grossWeight": 23170,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22K2",
              "name": "20 x 8,6 Liquid tank dangerous gds 2.65-10 bar"
            },
            "containerOperator": {
              "customsId": "NL001571734",
              "name": "Samskip Multimodal BV",
              "scacCode": "GEEL",
              "smdgCode": "SML",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "GEESTRTM",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": true,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TRHU2746873",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T10:52:00Z",
            "stowageLocation": null,
            "grossWeight": 26720,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "NL001571734",
              "name": "Samskip Multimodal BV",
              "scacCode": "GEEL",
              "smdgCode": "SML",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "GEESTRTM",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": true,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "MOAU6609688",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T10:57:00Z",
            "stowageLocation": null,
            "grossWeight": 26810,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "NL001571734",
              "name": "Samskip Multimodal BV",
              "scacCode": "GEEL",
              "smdgCode": "SML",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "GEESTRTM",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": true,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "TCLU2424109",
            "portOfLoading": {
              "name": "Oslo",
              "locationUnCode": "NOOSL",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T10:59:00Z",
            "stowageLocation": null,
            "grossWeight": 26826,
            "transportStatus": "TRANSHIPMENT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22G1",
              "name": "20'x8'x8'6\" General Purpose Container"
            },
            "containerOperator": {
              "customsId": "NL001571734",
              "name": "Samskip Multimodal BV",
              "scacCode": "GEEL",
              "smdgCode": "SML",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "GEESTRTM",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": true,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "MCMU6300309",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T11:09:00Z",
            "stowageLocation": null,
            "grossWeight": null,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22T6",
              "name": "20'x8'x8'6\" Tank Container Test pressure 6 bar"
            },
            "containerOperator": {
              "customsId": "NL001571734",
              "name": "Samskip Multimodal BV",
              "scacCode": "GEEL",
              "smdgCode": "SML",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "GEESTRTM",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": true,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          },
          {
            "number": "COCU1004355",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T12:07:00Z",
            "stowageLocation": null,
            "grossWeight": 26064,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "LNR1",
              "name": "LNR1"
            },
            "containerOperator": {
              "customsId": "NL001571734",
              "name": "Samskip Multimodal BV",
              "scacCode": "GEEL",
              "smdgCode": "SML",
              "cargoDeclarantShortName": null
            },
            "cargoAgentShortName": "GEESTRTM",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          }
        ],
        "bulkDischarges": [],
        "consignmentDifferences": [],
        "clearedManifestIds": [
          "NOBVK"
        ],
        "inspectionUpdates": [],
        "declarations": [
          {
            "type": "SDT",
            "id": "NOFRK",
            "status": "ACCEPTED",
            "rejectReasons": null,
            "timeStamp": "2021-09-26T08:28:07.686Z"
          },
          {
            "type": "SDT",
            "id": "NOBVK",
            "status": "ACCEPTED",
            "rejectReasons": null,
            "timeStamp": "2021-09-26T08:28:18.280Z"
          },
          {
            "type": "SDT",
            "id": "NOFRK",
            "status": "ACCEPTED",
            "rejectReasons": null,
            "timeStamp": "2021-09-26T08:28:18.280Z"
          },
          {
            "type": "SDT",
            "id": "NOOSL",
            "status": "ACCEPTED",
            "rejectReasons": null,
            "timeStamp": "2021-09-26T08:28:18.280Z"
          },
          {
            "type": "SDT",
            "id": "NOOSL",
            "status": "ACCEPTED",
            "rejectReasons": null,
            "timeStamp": "2021-09-26T18:34:36.770Z"
          }
        ],
        "incomingMessages": [
          {
            "type": "IFTMCS",
            "id": "GEEL2100216135",
            "processId": "9b309e3be978404e8292d302704a0a38",
            "timeStamp": "2021-09-26T08:28:07.686Z"
          },
          {
            "type": "IFTMCS",
            "id": "GEEL2100225001",
            "processId": "edad91b9a67f4a25a75ecc8713e64026",
            "timeStamp": "2021-09-26T08:28:18.280Z"
          },
          {
            "type": "IFTMCS",
            "id": "GEEL2100218075",
            "processId": "937052f7069249249a3ad4e31690511a",
            "timeStamp": "2021-09-26T08:28:18.280Z"
          },
          {
            "type": "IFTMCS",
            "id": "GEEL2100216136",
            "processId": "742d440d655b40b2ad5bd30bf3674f21",
            "timeStamp": "2021-09-26T08:28:18.280Z"
          },
          {
            "type": "IFTMCS",
            "id": "GEEL2100224226",
            "processId": "97ac99a883c448bd98f2b6c77f38e7dc",
            "timeStamp": "2021-09-26T08:28:18.280Z"
          },
          {
            "type": "IFTMCS",
            "id": "GEEL9731518001",
            "processId": "35bb834bf0634e5988bafd8523d31421",
            "timeStamp": "2021-09-26T08:28:18.280Z"
          },
          {
            "type": "IFTMCS",
            "id": "GEEL2100225461",
            "processId": "998c380bec7343d095beec8e4bfe52a9",
            "timeStamp": "2021-09-26T08:28:18.280Z"
          },
          {
            "type": "IFTMCS",
            "id": "GEEL2100225494",
            "processId": "b5066b102425419d995035c9d8ced4fb",
            "timeStamp": "2021-09-26T08:28:18.280Z"
          },
          {
            "type": "IFTMCS",
            "id": "GEEL2100225487",
            "processId": "c8f6d4b493fa4ea193f24473401a8702",
            "timeStamp": "2021-09-26T08:28:18.280Z"
          },
          {
            "type": "IFTMCS",
            "id": "GEEL2100216134",
            "processId": "c9fc130fe18e44ec9ecacdc6463e0cec",
            "timeStamp": "2021-09-26T08:28:18.280Z"
          },
          {
            "type": "IFTMCS",
            "id": "GEEL2100218095",
            "processId": "92438dcf8a2647a9a723210a63741619",
            "timeStamp": "2021-09-26T08:28:18.280Z"
          },
          {
            "type": "IFTMCS",
            "id": "GEEL2100214097",
            "processId": "2c64e186217d45ce99de91c1ba934040",
            "timeStamp": "2021-09-26T08:28:18.280Z"
          },
          {
            "type": "IFTMCS",
            "id": "GEEL2100228512",
            "processId": "e35ba7a1abe344a5baf32849b71e87c2",
            "timeStamp": "2021-09-26T08:28:18.280Z"
          },
          {
            "type": "IFTMCS",
            "id": "GEEL2100218071",
            "processId": "921a3f05bcca405e9eadd4ef034ba72f",
            "timeStamp": "2021-09-26T08:28:18.280Z"
          },
          {
            "type": "IFTMCS",
            "id": "GEEL2100224161",
            "processId": "0258755548f3468f9e65e50173ce75f5",
            "timeStamp": "2021-09-26T08:28:18.280Z"
          },
          {
            "type": "IFTMCS",
            "id": "GEEL2100216129",
            "processId": "f8498234fd6f47d982c8569d7086a510",
            "timeStamp": "2021-09-26T08:28:18.280Z"
          },
          {
            "type": "IFTMCS",
            "id": "GEEL2100218110",
            "processId": "be6689f1682147409df1ca95b81ebd5b",
            "timeStamp": "2021-09-26T08:28:18.280Z"
          },
          {
            "type": "IFTMCS",
            "id": "GEEL2100223864",
            "processId": "a376c317aefd4e6fa433f709cc24734b",
            "timeStamp": "2021-09-26T08:28:18.280Z"
          },
          {
            "type": "IFTMCS",
            "id": "GEEL2100224997",
            "processId": "d8f98299bccf4ebab5b228173d52d71d",
            "timeStamp": "2021-09-26T08:28:18.280Z"
          },
          {
            "type": "IFTMCS",
            "id": "GEEL2100225486",
            "processId": "56c452daa18d4986a32dd7fcc094691b",
            "timeStamp": "2021-09-26T08:28:18.280Z"
          },
          {
            "type": "IFTMCS",
            "id": "GEEL2100218077",
            "processId": "c17a53d4288e4025b04ceeac1fc0d0b6",
            "timeStamp": "2021-09-26T08:28:18.280Z"
          },
          {
            "type": "IFTMCS",
            "id": "GEEL2100229030",
            "processId": "083632f98c4f4654bb8f18b8f12d1a4a",
            "timeStamp": "2021-09-26T08:28:18.280Z"
          },
          {
            "type": "IFTMCS",
            "id": "GEEL2100216131",
            "processId": "f0d6299597294b82b1fd6aad3b3b6956",
            "timeStamp": "2021-09-26T08:28:18.280Z"
          },
          {
            "type": "IFTMCS",
            "id": "GEEL2100229878",
            "processId": "72dfc288d52a475b9f856e55a3f28a4f",
            "timeStamp": "2021-09-26T08:28:18.280Z"
          },
          {
            "type": "IFTMCS",
            "id": "GEEL2100218114",
            "processId": "739a4a223ce843e2a4fd087526d2d5b6",
            "timeStamp": "2021-09-26T08:28:18.280Z"
          },
          {
            "type": "IFTMCS",
            "id": "GEEL2100227369",
            "processId": "bc2b8ace4bdb43ab92c1058bc387cab1",
            "timeStamp": "2021-09-26T08:28:18.280Z"
          },
          {
            "type": "IFTMCS",
            "id": "GEEL2100218069",
            "processId": "4d9df19b93534245911d442dd44c8d63",
            "timeStamp": "2021-09-26T08:28:18.280Z"
          },
          {
            "type": "IFTMCS",
            "id": "GEEL2100229784",
            "processId": "85af98d3bb254bf5855d8d6937919c53",
            "timeStamp": "2021-09-26T08:28:18.280Z"
          },
          {
            "type": "IFTMCS",
            "id": "GEEL2100218112",
            "processId": "323c78f9934e4af783ba6acc6d3e88a9",
            "timeStamp": "2021-09-26T08:28:18.280Z"
          },
          {
            "type": "IFTMCS",
            "id": "GEEL2100224176",
            "processId": "3c93be3b17b2478abd0dc1223aef9cae",
            "timeStamp": "2021-09-26T08:28:18.280Z"
          },
          {
            "type": "IFTMCS",
            "id": "GEEL2100227883",
            "processId": "e70acc6461934b0b9cc34731cd270e26",
            "timeStamp": "2021-09-26T08:28:18.280Z"
          },
          {
            "type": "IFTMCS",
            "id": "GEEL2100218107",
            "processId": "6553011148ea42d58a84dcceb914b0b9",
            "timeStamp": "2021-09-26T08:28:18.280Z"
          },
          {
            "type": "IFTMCS",
            "id": "GEEL2100222148",
            "processId": "ab085b3ca19c4406b53d1acfdd185265",
            "timeStamp": "2021-09-26T08:28:18.280Z"
          },
          {
            "type": "IFTMCS",
            "id": "GEEL2100227464",
            "processId": "ca8cb3fc7d6d4a0f880fdf338ceec75e",
            "timeStamp": "2021-09-26T08:28:18.280Z"
          },
          {
            "type": "IFTMCS",
            "id": "GEEL2100206331",
            "processId": "e1c6d70af4db46a49b938ddd8b3e2ac7",
            "timeStamp": "2021-09-26T08:28:18.280Z"
          },
          {
            "type": "IFTMCS",
            "id": "GEEL2100218133",
            "processId": "e50bee2e291c48f9aa4df0fd0a83430b",
            "timeStamp": "2021-09-26T08:28:18.280Z"
          },
          {
            "type": "IFTMCS",
            "id": "GEEL2100225000",
            "processId": "6130f64ff04447cf8dce2473e4a7ea97",
            "timeStamp": "2021-09-26T08:28:18.280Z"
          },
          {
            "type": "IFTMCS",
            "id": "GEEL2100218140",
            "processId": "57807499bbf546b4ab684bea896d1fcb",
            "timeStamp": "2021-09-26T08:28:18.280Z"
          },
          {
            "type": "IFTMCS",
            "id": "GEEL2100218105",
            "processId": "e2ef7a6e6a19460fa2c82cef7ddb7201",
            "timeStamp": "2021-09-26T08:28:18.280Z"
          },
          {
            "type": "IFTMCS",
            "id": "GEEL2100213681",
            "processId": "ad5a6a5b7d774335b92eaef19d675c51",
            "timeStamp": "2021-09-26T08:28:18.280Z"
          },
          {
            "type": "IFTMCS",
            "id": "GEEL2100218078",
            "processId": "7c75400f715249d7b19a4e1ffde25acf",
            "timeStamp": "2021-09-26T08:28:18.280Z"
          },
          {
            "type": "IFTMCS",
            "id": "GEEL2100226085",
            "processId": "9ad410a0e6474cb6bd5fdf5254fbcfa2",
            "timeStamp": "2021-09-26T08:28:18.280Z"
          },
          {
            "type": "IFTMCS",
            "id": "GEEL2100226516",
            "processId": "b4df72abca6a46a485142db4dcb24943",
            "timeStamp": "2021-09-26T08:28:18.280Z"
          },
          {
            "type": "IFTMCS",
            "id": "GEEL2100210830",
            "processId": "9f3f9cb756d547d4b543a8fb534a8b4b",
            "timeStamp": "2021-09-26T08:28:18.280Z"
          },
          {
            "type": "IFTMCS",
            "id": "GEEL2100225670",
            "processId": "9411d14ead8d47d791275419fa27695b",
            "timeStamp": "2021-09-26T08:28:18.280Z"
          },
          {
            "type": "IFTMCS",
            "id": "GEEL2100207928",
            "processId": "6ce2eadb27f7433093a87f8a638b5af8",
            "timeStamp": "2021-09-26T08:28:18.280Z"
          },
          {
            "type": "IFTMCS",
            "id": "GEEL2100218106",
            "processId": "20038175077844f8af738971c25fb8f4",
            "timeStamp": "2021-09-26T08:28:18.280Z"
          },
          {
            "type": "IFTMCS",
            "id": "GEEL2100225485",
            "processId": "1aea2cc3b29a434aa7b5ae5fc7188103",
            "timeStamp": "2021-09-26T08:28:18.280Z"
          },
          {
            "type": "IFTMCS",
            "id": "GEEL2100225488",
            "processId": "310cd1fb8277499e9b032d1de562883c",
            "timeStamp": "2021-09-26T08:28:18.280Z"
          }
        ]
      },
      {
        "cargoDeclarant": {
          "fullName": "CMA-CGM (Holland) B.V.",
          "shortName": "CMACGMRHN",
          "portAuthorityId": "CMA-CGM",
          "emailAddress": "rtm.shipops@cma-cgm.com",
          "address": "Achterdijk 55",
          "city": "Rhoon",
          "countryUnCode": "NL",
          "countryName": "Netherlands",
          "zipCode": "3161 EB",
          "phoneNumber": "010-2998199",
          "faxNumber": "010-2998190",
          "contact": "Marcel den Boogert",
          "customsEORINumber": "NL806025463",
          "ean": null,
          "chamberOfCommerceNumber": "24276503",
          "scacCode": null,
          "ensEnabled": false,
          "cargoImportEnabled": true,
          "cargoImportEnabledByDefault": true
        },
        "timestamp": "2021-09-28T05:04:52.793Z",
        "containers": [],
        "consignments": [],
        "receivedMrns": {},
        "discharges": [
          {
            "number": "CNCU1504873",
            "portOfLoading": {
              "name": "Brevik",
              "locationUnCode": "NOBVK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "terminal": {
              "terminalName": "BEATRIXH RST ZZ",
              "terminalCode": "4963",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal"
            },
            "actualTimeOfHandling": "2021-09-27T02:54:00Z",
            "stowageLocation": null,
            "grossWeight": null,
            "transportStatus": "IMPORT",
            "type": "CONTAINER",
            "sizeType": {
              "code": "22GP",
              "name": "22GP"
            },
            "containerOperator": {
              "customsId": "FR56202442200486",
              "name": "CMA-CGM",
              "scacCode": "CMDU",
              "smdgCode": "CMA",
              "cargoDeclarantShortName": "CMACGMRHN"
            },
            "cargoAgentShortName": "CMACGMRHN",
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": true,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null
          }
        ],
        "bulkDischarges": [],
        "consignmentDifferences": [],
        "clearedManifestIds": [],
        "inspectionUpdates": [],
        "declarations": [],
        "incomingMessages": []
      }
    ],
    "transitDeclarationMap": {},
    "loadingDeclarations": [],
    "paxDeclarationSummaries": [
      {
        "numberOfCrew": 14,
        "numberOfPassengers": 0,
        "numberOfCrewOtherShip": 0,
        "numberOfStowaways": 0,
        "searchAndRescue": null
      }
    ],
    "healthDeclarationSummary": {
      "amountRecoveredAndStillOnBoard": 0,
      "amountRecoveredAndEvacuated": 0,
      "amountIllAndStillOnBoard": 0,
      "amountIllAndEvacuated": 0,
      "amountDiedAndStillOnBoard": 0,
      "amountDiedAndEvacuated": 0,
      "amountDiedAndBuriedAtSea": 0,
      "healthDeclarationStatus": "APPROVED",
      "healthDeclarationRemarks": null
    },
    "etaPortAis": null,
    "ignoreEtaPortAis": false,
    "cancelled": false,
    "ataRegisteredByPa": true,
    "visitKnownAtPa": true,
    "dangerousGoodsEnabled": true,
    "cargoImportEnabled": true,
    "ataAcknowledged": true,
    "declarations": [
      {
        "type": "VISIT",
        "id": null,
        "status": "ACCEPTED",
        "rejectReasons": null,
        "timeStamp": "2021-09-21T15:03:38.988Z"
      },
      {
        "type": "WPCS",
        "id": null,
        "status": "DECLARED",
        "rejectReasons": null,
        "timeStamp": "2021-09-21T15:03:41.311Z"
      },
      {
        "type": "SECURITY",
        "id": null,
        "status": "ACCEPTED",
        "rejectReasons": null,
        "timeStamp": "2021-09-21T15:03:47.758Z"
      },
      {
        "type": "VISIT",
        "id": null,
        "status": "ACCEPTED",
        "rejectReasons": null,
        "timeStamp": "2021-09-25T12:22:22.392Z"
      },
      {
        "type": "WPCS",
        "id": null,
        "status": "DECLARED",
        "rejectReasons": null,
        "timeStamp": "2021-09-25T12:22:22.527Z"
      },
      {
        "type": "VISIT",
        "id": null,
        "status": "ACCEPTED",
        "rejectReasons": null,
        "timeStamp": "2021-09-25T12:22:48.105Z"
      },
      {
        "type": "WPCS",
        "id": null,
        "status": "DECLARED",
        "rejectReasons": null,
        "timeStamp": "2021-09-25T12:22:48.253Z"
      },
      {
        "type": "VISIT",
        "id": null,
        "status": "ACCEPTED",
        "rejectReasons": null,
        "timeStamp": "2021-09-25T12:24:15.757Z"
      },
      {
        "type": "WPCS",
        "id": null,
        "status": "DECLARED",
        "rejectReasons": null,
        "timeStamp": "2021-09-25T12:24:15.856Z"
      },
      {
        "type": "VISIT",
        "id": null,
        "status": "ACCEPTED",
        "rejectReasons": null,
        "timeStamp": "2021-09-25T12:59:09.370Z"
      },
      {
        "type": "WPCS",
        "id": null,
        "status": "DECLARED",
        "rejectReasons": null,
        "timeStamp": "2021-09-25T12:59:09.515Z"
      },
      {
        "type": "SECURITY",
        "id": null,
        "status": "ACCEPTED",
        "rejectReasons": null,
        "timeStamp": "2021-09-25T13:00:09.838Z"
      },
      {
        "type": "PAX",
        "id": null,
        "status": "ACCEPTED",
        "rejectReasons": null,
        "timeStamp": "2021-09-25T13:01:19.602Z"
      },
      {
        "type": "WASTE",
        "id": null,
        "status": "ACCEPTED",
        "rejectReasons": null,
        "timeStamp": "2021-09-25T13:01:49.404Z"
      },
      {
        "type": "VISIT",
        "id": null,
        "status": "ACCEPTED",
        "rejectReasons": null,
        "timeStamp": "2021-09-25T22:52:15.767Z"
      },
      {
        "type": "WPCS",
        "id": null,
        "status": "DECLARED",
        "rejectReasons": null,
        "timeStamp": "2021-09-25T22:52:15.924Z"
      },
      {
        "type": "VISIT",
        "id": null,
        "status": "ACCEPTED",
        "rejectReasons": null,
        "timeStamp": "2021-09-26T14:15:45.530Z"
      },
      {
        "type": "WPCS",
        "id": null,
        "status": "DECLARED",
        "rejectReasons": null,
        "timeStamp": "2021-09-26T14:15:45.664Z"
      },
      {
        "type": "STO",
        "id": null,
        "status": "ACCEPTED",
        "rejectReasons": null,
        "timeStamp": "2021-09-26T14:27:44.689Z"
      },
      {
        "type": "VISIT",
        "id": null,
        "status": "ACCEPTED",
        "rejectReasons": null,
        "timeStamp": "2021-09-26T14:37:48.387Z"
      },
      {
        "type": "HEALTH",
        "id": null,
        "status": "ACCEPTED",
        "rejectReasons": null,
        "timeStamp": "2021-09-26T15:16:50.237Z"
      },
      {
        "type": "WPCS",
        "id": null,
        "status": "DECLARED",
        "rejectReasons": null,
        "timeStamp": "2021-09-27T01:12:44.965Z"
      },
      {
        "type": "VISIT",
        "id": null,
        "status": "ACCEPTED",
        "rejectReasons": null,
        "timeStamp": "2021-09-27T10:29:15.104Z"
      },
      {
        "type": "WPCS",
        "id": null,
        "status": "DECLARED",
        "rejectReasons": null,
        "timeStamp": "2021-09-27T10:29:15.359Z"
      },
      {
        "type": "VISIT",
        "id": null,
        "status": "ACCEPTED",
        "rejectReasons": null,
        "timeStamp": "2021-09-27T10:43:14.161Z"
      },
      {
        "type": "WPCS",
        "id": null,
        "status": "DECLARED",
        "rejectReasons": null,
        "timeStamp": "2021-09-27T10:43:14.285Z"
      },
      {
        "type": "VISIT",
        "id": null,
        "status": "ACCEPTED",
        "rejectReasons": null,
        "timeStamp": "2021-09-27T12:26:18.563Z"
      },
      {
        "type": "WPCS",
        "id": null,
        "status": "DECLARED",
        "rejectReasons": null,
        "timeStamp": "2021-09-27T12:26:18.674Z"
      },
      {
        "type": "VISIT",
        "id": null,
        "status": "ACCEPTED",
        "rejectReasons": null,
        "timeStamp": "2021-09-27T12:57:18.562Z"
      },
      {
        "type": "WPCS",
        "id": null,
        "status": "DECLARED",
        "rejectReasons": null,
        "timeStamp": "2021-09-27T12:57:18.663Z"
      },
      {
        "type": "WPCS",
        "id": null,
        "status": "DECLARED",
        "rejectReasons": null,
        "timeStamp": "2021-09-27T13:48:15.947Z"
      },
      {
        "type": "WPCS",
        "id": null,
        "status": "DECLARED",
        "rejectReasons": null,
        "timeStamp": "2021-09-27T17:52:10.262Z"
      },
      {
        "type": "VISIT",
        "id": null,
        "status": "ACCEPTED",
        "rejectReasons": null,
        "timeStamp": "2021-09-27T18:03:36.247Z"
      },
      {
        "type": "WPCS",
        "id": null,
        "status": "DECLARED",
        "rejectReasons": null,
        "timeStamp": "2021-09-27T18:03:36.345Z"
      },
      {
        "type": "VISIT",
        "id": null,
        "status": "ACCEPTED",
        "rejectReasons": null,
        "timeStamp": "2021-09-27T18:30:50.653Z"
      },
      {
        "type": "WPCS",
        "id": null,
        "status": "DECLARED",
        "rejectReasons": null,
        "timeStamp": "2021-09-27T18:30:50.750Z"
      },
      {
        "type": "VISIT",
        "id": null,
        "status": "ACCEPTED",
        "rejectReasons": null,
        "timeStamp": "2021-09-27T18:51:17.002Z"
      },
      {
        "type": "WPCS",
        "id": null,
        "status": "DECLARED",
        "rejectReasons": null,
        "timeStamp": "2021-09-27T18:51:17.122Z"
      },
      {
        "type": "VISIT",
        "id": null,
        "status": "ACCEPTED",
        "rejectReasons": null,
        "timeStamp": "2021-09-27T19:18:23.464Z"
      },
      {
        "type": "VISIT",
        "id": null,
        "status": "ACCEPTED",
        "rejectReasons": null,
        "timeStamp": "2021-09-27T19:35:06.529Z"
      },
      {
        "type": "WPCS",
        "id": null,
        "status": "DECLARED",
        "rejectReasons": null,
        "timeStamp": "2021-09-27T19:35:06.672Z"
      },
      {
        "type": "VISIT",
        "id": null,
        "status": "ACCEPTED",
        "rejectReasons": null,
        "timeStamp": "2021-09-27T20:18:38.510Z"
      },
      {
        "type": "WPCS",
        "id": null,
        "status": "DECLARED",
        "rejectReasons": null,
        "timeStamp": "2021-09-27T20:18:38.631Z"
      },
      {
        "type": "WPCS",
        "id": null,
        "status": "DECLARED",
        "rejectReasons": null,
        "timeStamp": "2021-09-27T20:39:07.086Z"
      },
      {
        "type": "WPCS",
        "id": null,
        "status": "DECLARED",
        "rejectReasons": null,
        "timeStamp": "2021-09-27T21:04:40.116Z"
      },
      {
        "type": "WPCS",
        "id": null,
        "status": "DECLARED",
        "rejectReasons": null,
        "timeStamp": "2021-09-27T23:25:51.045Z"
      },
      {
        "type": "VISIT",
        "id": null,
        "status": "ACCEPTED",
        "rejectReasons": null,
        "timeStamp": "2021-09-28T02:20:19.769Z"
      },
      {
        "type": "WPCS",
        "id": null,
        "status": "DECLARED",
        "rejectReasons": null,
        "timeStamp": "2021-09-28T02:20:19.833Z"
      },
      {
        "type": "VISIT",
        "id": null,
        "status": "ACCEPTED",
        "rejectReasons": null,
        "timeStamp": "2021-09-28T02:24:05.083Z"
      },
      {
        "type": "WPCS",
        "id": null,
        "status": "DECLARED",
        "rejectReasons": null,
        "timeStamp": "2021-09-28T02:24:05.223Z"
      },
      {
        "type": "VISIT",
        "id": null,
        "status": "ACCEPTED",
        "rejectReasons": null,
        "timeStamp": "2021-09-28T03:03:57.564Z"
      },
      {
        "type": "WPCS",
        "id": null,
        "status": "DECLARED",
        "rejectReasons": null,
        "timeStamp": "2021-09-28T03:03:57.665Z"
      },
      {
        "type": "VISIT",
        "id": null,
        "status": "ACCEPTED",
        "rejectReasons": null,
        "timeStamp": "2021-09-28T04:55:46.496Z"
      },
      {
        "type": "VISIT",
        "id": null,
        "status": "ACCEPTED",
        "rejectReasons": null,
        "timeStamp": "2021-09-28T04:56:06.855Z"
      },
      {
        "type": "VISIT",
        "id": null,
        "status": "ACCEPTED",
        "rejectReasons": null,
        "timeStamp": "2021-09-28T04:57:12.803Z"
      },
      {
        "type": "WPCS",
        "id": null,
        "status": "DECLARED",
        "rejectReasons": null,
        "timeStamp": "2021-09-28T05:20:02.457Z"
      },
      {
        "type": "WPCS",
        "id": null,
        "status": "DECLARED",
        "rejectReasons": null,
        "timeStamp": "2021-09-28T07:43:38.279Z"
      },
      {
        "type": "VISIT",
        "id": null,
        "status": "ACCEPTED",
        "rejectReasons": null,
        "timeStamp": "2021-09-28T08:21:04.591Z"
      },
      {
        "type": "WPCS",
        "id": null,
        "status": "DECLARED",
        "rejectReasons": null,
        "timeStamp": "2021-09-28T08:40:14.035Z"
      },
      {
        "type": "VISIT",
        "id": null,
        "status": "ACCEPTED",
        "rejectReasons": null,
        "timeStamp": "2021-09-28T09:17:30.937Z"
      },
      {
        "type": "WPCS",
        "id": null,
        "status": "DECLARED",
        "rejectReasons": null,
        "timeStamp": "2021-09-28T09:17:31.058Z"
      },
      {
        "type": "VISIT",
        "id": null,
        "status": "ACCEPTED",
        "rejectReasons": null,
        "timeStamp": "2021-09-28T09:33:58.466Z"
      },
      {
        "type": "VISIT",
        "id": null,
        "status": "ACCEPTED",
        "rejectReasons": null,
        "timeStamp": "2021-09-28T11:29:32.936Z"
      },
      {
        "type": "WPCS",
        "id": null,
        "status": "DECLARED",
        "rejectReasons": null,
        "timeStamp": "2021-09-28T11:29:33.050Z"
      },
      {
        "type": "VISIT",
        "id": null,
        "status": "ACCEPTED",
        "rejectReasons": null,
        "timeStamp": "2021-09-28T11:38:19.928Z"
      },
      {
        "type": "WPCS",
        "id": null,
        "status": "DECLARED",
        "rejectReasons": null,
        "timeStamp": "2021-09-28T11:38:20.204Z"
      },
      {
        "type": "WPCS",
        "id": null,
        "status": "DECLARED",
        "rejectReasons": null,
        "timeStamp": "2021-09-28T17:16:27.882Z"
      },
      {
        "type": "WPCS",
        "id": null,
        "status": "DECLARED",
        "rejectReasons": null,
        "timeStamp": "2021-09-28T21:20:23.810Z"
      },
      {
        "type": "VISIT",
        "id": null,
        "status": "ACCEPTED",
        "rejectReasons": null,
        "timeStamp": "2021-09-30T14:02:31.431Z"
      },
      {
        "type": "WPCS",
        "id": null,
        "status": "DECLARED",
        "rejectReasons": null,
        "timeStamp": "2021-09-30T14:02:31.582Z"
      },
      {
        "type": "WPCS",
        "id": null,
        "status": "DECLARED",
        "rejectReasons": null,
        "timeStamp": "2021-09-30T15:31:49.246Z"
      },
      {
        "type": "WPCS",
        "id": null,
        "status": "DECLARED",
        "rejectReasons": null,
        "timeStamp": "2021-10-01T08:27:48.484Z"
      },
      {
        "type": "VISIT",
        "id": null,
        "status": "ACCEPTED",
        "rejectReasons": null,
        "timeStamp": "2021-10-01T11:59:33.062Z"
      }
    ],
    "uploads": {
      "WASTE": "c92bf8f2-a697-4b81-bc83-3012ee81a433"
    },
    "additionalIncomingMovementInfo": {
      "etaPilotBoardingPlace": "2021-09-26T22:45:00Z",
      "plannedNumberOfTugboats": "PILOT_DETERMINES",
      "shifted": false,
      "etdChanged": false,
      "etaChanged": false,
      "entryDependencyRemarks": null
    },
    "berthVisitInfos": {
      "721e958f-e286-43af-b5e1-e216bdcba2dc": <BerthVisitInfo>{
        "carrierInfo": {
          "eta": "2021-09-27T22:00:00Z"
        },
        "terminalInfo": null,
        "harbourMasterInfo": {
          "orderStatus": "CONFIRMED",
          "etd": "2021-09-28T05:00:00Z",
          "pilotOnBoard": null,
          "rejectionReason": null,
          "remarks": "BOODSCHAP!!!",
          "movementOnHold": false,
          "tidalWindowStatus": null
        },
        "originalEditors": [
          "UNIFEEDER",
          "UNIFEEDER"
        ]
      },
      "4747cac9-791d-4476-8659-0f01ccea8b79": <BerthVisitInfo>{
        "carrierInfo": null,
        "terminalInfo": null,
        "harbourMasterInfo": {
          "orderStatus": "CONFIRMED",
          "etd": "2021-09-27T22:45:00Z",
          "pilotOnBoard": null,
          "rejectionReason": null,
          "remarks": null,
          "movementOnHold": false,
          "tidalWindowStatus": null
        },
        "originalEditors": [
          "UNIFEEDER",
          "UNIFEEDER"
        ]
      },
      "8e62048f-51d3-46a4-962e-bf024aec32c4": <BerthVisitInfo>{
        "carrierInfo": {
          "eta": "2021-09-27T22:00:00Z"
        },
        "terminalInfo": null,
        "harbourMasterInfo": {
          "orderStatus": "CONFIRMED",
          "eta": "2021-09-27T01:15:00Z",
          "etd": "2021-09-27T13:00:00Z",
          "pilotOnBoard": null,
          "rejectionReason": null,
          "remarks": "BOODSCHAP!!!",
          "movementOnHold": false,
          "tidalWindowStatus": null
        },
        "originalEditors": [
          "UNIFEEDER",
          "UNIFEEDER"
        ]
      },
      "f82b3399-543c-4466-a119-f1cc9361180f": <BerthVisitInfo>{
        "carrierInfo": {
          "eta": "2021-09-27T22:00:00Z"
        },
        "terminalInfo": null,
        "harbourMasterInfo": {
          "orderStatus": "CONFIRMED",
          "etd": "2021-09-27T20:30:00Z",
          "pilotOnBoard": null,
          "rejectionReason": null,
          "remarks": null,
          "movementOnHold": false,
          "tidalWindowStatus": null
        },
        "originalEditors": [
          "UNIFEEDER",
          "UNIFEEDER"
        ]
      },
      "a04be342-0daf-47ea-bf68-fa8f72354f39": <BerthVisitInfo>{
        "carrierInfo": null,
        "terminalInfo": null,
        "harbourMasterInfo": {
          "orderStatus": "CONFIRMED",
          "etd": "2021-09-28T17:00:00Z",
          "pilotOnBoard": null,
          "rejectionReason": null,
          "remarks": null,
          "movementOnHold": false,
          "tidalWindowStatus": null
        },
        "originalEditors": []
      }
    },
    "incomingMovementHarbourMasterInfo": {
      "orderStatus": "CONFIRMED",
      "pilotOnBoard": null,
      "rejectionReason": null,
      "remarks": null,
      "movementOnHold": false
    },
    "incomingTidalWindowStatus": null,
    "lastMessageId": "a2526b8f-af00-4ede-97a8-45c66f9ee388",
    "created": "2021-09-21T14:58:37.391Z",
    "updated": "2021-10-01T11:59:35.790Z",
    "berthVisitIdOfTransfer": "a04be342-0daf-47ea-bf68-fa8f72354f39",
    "hasBeenTransferred": true,
    "transitDeclarations": [],
    "previousMessageId": "dae4d774-2442-4458-ba26-1c360e10dc01",
    "nextMessageId": null,
    "allowedViewers": [
      {
        "fullName": "DFDS logistics B.V.",
        "shortName": "DFDSCL",
        "portAuthorityId": "DFDS CONT-LINE",
        "emailAddress": "mbec@dfds.com",
        "address": "Burgemeester van Lierplein 57",
        "city": "Vlaardingen",
        "countryUnCode": "NL",
        "countryName": "Netherlands",
        "zipCode": "3134 ZB",
        "phoneNumber": "+31105032300",
        "faxNumber": null,
        "contact": "M.L. Bech",
        "customsEORINumber": "NL003189223",
        "ean": "8713755281502",
        "chamberOfCommerceNumber": "24277441",
        "scacCode": "DFDN",
        "ensEnabled": true,
        "cargoImportEnabled": true,
        "cargoImportEnabledByDefault": true
      },
      {
        "fullName": "BG Freight Line B.V.",
        "shortName": "BGFREIGHT",
        "portAuthorityId": "BG FREIGHTL",
        "emailAddress": "rotterdam.captainsroom@bgfreightline.com",
        "address": "Den Hamweg 30",
        "city": "Rotterdam",
        "countryUnCode": "NL",
        "countryName": "Netherlands",
        "zipCode": "3008 JA",
        "phoneNumber": "+31-10-2311962",
        "faxNumber": "+31-10-4723808",
        "contact": "Kapaan F",
        "customsEORINumber": "NL006323017",
        "ean": null,
        "chamberOfCommerceNumber": "24145269",
        "scacCode": "BGFL",
        "ensEnabled": true,
        "cargoImportEnabled": true,
        "cargoImportEnabledByDefault": true
      },
      {
        "fullName": "Mann Lines B.V.",
        "shortName": "MANNLINES",
        "portAuthorityId": "MANN LINES",
        "emailAddress": "marcel.sparreboom@mannlines.nl",
        "address": "Veerhaven 7",
        "city": "Rotterdam",
        "countryUnCode": "NL",
        "countryName": "Netherlands",
        "zipCode": "3016 CJ",
        "phoneNumber": "+31-10-2999666",
        "faxNumber": "+31-10-2999665",
        "contact": "Marcel Sparreboom",
        "customsEORINumber": "NL818469298",
        "ean": null,
        "chamberOfCommerceNumber": "22065312",
        "scacCode": null,
        "ensEnabled": false,
        "cargoImportEnabled": true,
        "cargoImportEnabledByDefault": true
      },
      {
        "fullName": "Samskip Multimodal BV",
        "shortName": "GEESTRTM",
        "portAuthorityId": "SAMSKIP MCL",
        "emailAddress": "rsu@samskip.com",
        "address": "Waalhaven OZ 81",
        "city": "Rotterdam",
        "countryUnCode": "NL",
        "countryName": "Netherlands",
        "zipCode": "3087 BM",
        "phoneNumber": "+31-88-4001754",
        "faxNumber": "+31-10-4952629",
        "contact": "Andre van der Meer",
        "customsEORINumber": "NL001571734",
        "ean": "8713755262723",
        "chamberOfCommerceNumber": "24139799",
        "scacCode": "GEEL",
        "ensEnabled": true,
        "cargoImportEnabled": true,
        "cargoImportEnabledByDefault": true
      },
      {
        "fullName": "MacAndrews Netherlands BV",
        "shortName": "OPDRRTM",
        "portAuthorityId": "OPDR NETHERLANDS",
        "emailAddress": "rotterdam@frachtcontor.nl",
        "address": "Achterdijk 51-53",
        "city": "Rhoon",
        "countryUnCode": "NL",
        "countryName": "Netherlands",
        "zipCode": "3161 EB",
        "phoneNumber": "0107980724",
        "faxNumber": null,
        "contact": "Marco Aukes",
        "customsEORINumber": "NL006509964",
        "ean": "8713755912949",
        "chamberOfCommerceNumber": "24148490",
        "scacCode": null,
        "ensEnabled": true,
        "cargoImportEnabled": true,
        "cargoImportEnabledByDefault": true
      },
      {
        "fullName": "Havenbedrijf Rotterdam N.V.",
        "shortName": "HBRRTM",
        "portAuthorityId": "HbR",
        "emailAddress": "HCC@portofrotterdam.com",
        "address": "Wilhelminakade 909",
        "city": "Rotterdam",
        "countryUnCode": "NL",
        "countryName": "Netherlands",
        "zipCode": "3072 AP",
        "phoneNumber": "+31-10-2521195",
        "faxNumber": "+31-10-2521000",
        "contact": "Diana van Eijk",
        "customsEORINumber": "NL24354561",
        "ean": null,
        "chamberOfCommerceNumber": null,
        "scacCode": null,
        "ensEnabled": false,
        "cargoImportEnabled": false,
        "cargoImportEnabledByDefault": false
      },
      {
        "fullName": "Maersk Line Netherlands B.V.",
        "shortName": "MAERSKRTM",
        "portAuthorityId": "MAERSK LINE",
        "emailAddress": "marius.mersie@maersk.com",
        "address": "Boompjes 40",
        "city": "Rotterdam",
        "countryUnCode": "NL",
        "countryName": "Netherlands",
        "zipCode": "3000 DH",
        "phoneNumber": "06-53446270",
        "faxNumber": null,
        "contact": "Marius Mersie",
        "customsEORINumber": "NL005361771",
        "ean": "8713755940089",
        "chamberOfCommerceNumber": "24139123",
        "scacCode": null,
        "ensEnabled": false,
        "cargoImportEnabled": true,
        "cargoImportEnabledByDefault": true
      },
      {
        "fullName": "Kuhlman Repko Shipping B.V.",
        "shortName": "KUHLMAN",
        "portAuthorityId": "KRSHIPPING",
        "emailAddress": "erno@krshipping.nl",
        "address": "Nieuwe Vissershaven 7",
        "city": "Harlingen",
        "countryUnCode": "NL",
        "countryName": "Netherlands",
        "zipCode": "8861 NX",
        "phoneNumber": "+31-517-412831",
        "faxNumber": null,
        "contact": "De heer Erno Leguijt",
        "customsEORINumber": "NL813141692",
        "ean": null,
        "chamberOfCommerceNumber": "01103873",
        "scacCode": null,
        "ensEnabled": false,
        "cargoImportEnabled": true,
        "cargoImportEnabledByDefault": true
      },
      {
        "fullName": "X-Press Container Line (UK) LTD",
        "shortName": "XPRESSLINE",
        "portAuthorityId": "XCL",
        "emailAddress": "graham.thrall@x-pressfeeders.com",
        "address": "Singapurstraße 1",
        "city": "Hamburg",
        "countryUnCode": "DE",
        "countryName": "Germany",
        "zipCode": "20457",
        "phoneNumber": "+49-15209267339",
        "faxNumber": "+49-40369192511",
        "contact": "Mr. Graham Thrall",
        "customsEORINumber": "DE277403241692493",
        "ean": null,
        "chamberOfCommerceNumber": "2470077",
        "scacCode": "XCLS",
        "ensEnabled": true,
        "cargoImportEnabled": true,
        "cargoImportEnabledByDefault": true
      },
      {
        "fullName": "Unifeeder Netherlands B.V.",
        "shortName": "UNIFEEDER",
        "portAuthorityId": "UNIFEEDER",
        "emailAddress": "ops-bnl@unifeeder.com",
        "address": "Blaak 555",
        "city": "Rotterdam",
        "countryUnCode": "NL",
        "countryName": "Netherlands",
        "zipCode": "3011 GB",
        "phoneNumber": "0102179141",
        "faxNumber": null,
        "contact": "Adil Maniyar",
        "customsEORINumber": "DK11810543",
        "ean": "8713755262211",
        "chamberOfCommerceNumber": "24450473",
        "scacCode": null,
        "ensEnabled": true,
        "cargoImportEnabled": true,
        "cargoImportEnabledByDefault": true
      },
      {
        "fullName": "Samskip Benelux B.V.",
        "shortName": "SAMSKIP",
        "portAuthorityId": "SAMSKIP",
        "emailAddress": "charles.van.koert@samskip.com",
        "address": "Waalhaven O.Z. 81",
        "city": "Rotterdam",
        "countryUnCode": "NL",
        "countryName": "Netherlands",
        "zipCode": "3087 BM",
        "phoneNumber": "0884001131",
        "faxNumber": "0653906391",
        "contact": "Charles van Koert",
        "customsEORINumber": "NL818622106",
        "ean": null,
        "chamberOfCommerceNumber": "24243207",
        "scacCode": "SBDX",
        "ensEnabled": true,
        "cargoImportEnabled": true,
        "cargoImportEnabledByDefault": true
      },
      {
        "fullName": "Orient Overseas Container Line Limi",
        "shortName": "OOCLRTM",
        "portAuthorityId": "OOCL",
        "emailAddress": "danny.van.den.bosch@oocl.com",
        "address": "Weena Zuid 134",
        "city": "Rotterdam",
        "countryUnCode": "NL",
        "countryName": "Netherlands",
        "zipCode": "3012 NC",
        "phoneNumber": "0620405856",
        "faxNumber": null,
        "contact": "Danny van de Bosch",
        "customsEORINumber": "NL821606931",
        "ean": null,
        "chamberOfCommerceNumber": "50485601",
        "scacCode": null,
        "ensEnabled": false,
        "cargoImportEnabled": true,
        "cargoImportEnabledByDefault": true
      },
      {
        "fullName": "Cross-Ocean B.V.",
        "shortName": "CROSSOCEANBV",
        "portAuthorityId": "CROSS OCEAN",
        "emailAddress": "gad@crossocean.nl",
        "address": "Weena 280",
        "city": "Rotterdam",
        "countryUnCode": "NL",
        "countryName": "Netherlands",
        "zipCode": "3012 NJ",
        "phoneNumber": "010-2248322",
        "faxNumber": null,
        "contact": "I.de Kramer",
        "customsEORINumber": "NL005140985",
        "ean": null,
        "chamberOfCommerceNumber": "24137366",
        "scacCode": null,
        "ensEnabled": true,
        "cargoImportEnabled": true,
        "cargoImportEnabledByDefault": true
      },
      {
        "fullName": "CMA-CGM (Holland) B.V.",
        "shortName": "CMACGMRHN",
        "portAuthorityId": "CMA-CGM",
        "emailAddress": "rtm.shipops@cma-cgm.com",
        "address": "Achterdijk 55",
        "city": "Rhoon",
        "countryUnCode": "NL",
        "countryName": "Netherlands",
        "zipCode": "3161 EB",
        "phoneNumber": "010-2998199",
        "faxNumber": "010-2998190",
        "contact": "Marcel den Boogert",
        "customsEORINumber": "NL806025463",
        "ean": null,
        "chamberOfCommerceNumber": "24276503",
        "scacCode": null,
        "ensEnabled": false,
        "cargoImportEnabled": true,
        "cargoImportEnabledByDefault": true
      },
      {
        "fullName": "Unifeeder Netherlands B.V.",
        "shortName": "UNIFEEDER",
        "portAuthorityId": "UNIFEEDER",
        "emailAddress": "ops-bnl@unifeeder.com",
        "address": "Blaak 555",
        "city": "Rotterdam",
        "countryUnCode": "NL",
        "countryName": "Netherlands",
        "zipCode": "3011 GB",
        "phoneNumber": "0102179141",
        "faxNumber": null,
        "contact": "J.F. Meeder",
        "customsEORINumber": "DK11810543",
        "ean": "8713755262211",
        "chamberOfCommerceNumber": "24450473",
        "scacCode": null,
        "ensEnabled": true,
        "cargoImportEnabled": true,
        "cargoImportEnabledByDefault": true
      },
      {
        "fullName": "FESCO North West Europe B.V.",
        "shortName": "FESCO",
        "portAuthorityId": "Fesco lines bv",
        "emailAddress": "kprivalov@fesco.com",
        "address": "Hofhoek 20-48",
        "city": "Hofhoek 20-48Poortugaal",
        "countryUnCode": "NL",
        "countryName": "Netherlands",
        "zipCode": "3176 PD",
        "phoneNumber": "+31880066700",
        "faxNumber": null,
        "contact": "K.S. Privalov",
        "customsEORINumber": "NL818356054",
        "ean": null,
        "chamberOfCommerceNumber": "24419889",
        "scacCode": "FESO",
        "ensEnabled": false,
        "cargoImportEnabled": true,
        "cargoImportEnabledByDefault": true
      },
      {
        "fullName": "Containerships R dam B.V.",
        "shortName": "CONTAINERSHIPS",
        "portAuthorityId": "CONTAINER SH",
        "emailAddress": "Vesselplanners@containerships.nl",
        "address": "Den Hamweg 30",
        "city": "Rotterdam",
        "countryUnCode": "NL",
        "countryName": "Netherlands",
        "zipCode": "3089 KK",
        "phoneNumber": "+31-10-7980761",
        "faxNumber": "+31-10-4281971",
        "contact": "M. Aukes",
        "customsEORINumber": "NL006506938",
        "ean": null,
        "chamberOfCommerceNumber": "24148092.1",
        "scacCode": null,
        "ensEnabled": false,
        "cargoImportEnabled": true,
        "cargoImportEnabledByDefault": true
      }
    ],
    "visitStatus": "ARRIVED"
  },
  NLDZL21003271: {
    "crn": "NLDZL21003271",
    "copyOfVisit": null,
    "portOfCall": {
      "port": {
        "name": "Delfzijl",
        "locationUnCode": "NLDZL",
        "countryUnCode": "NL",
        "euPort": true
      },
      "portAuthority": {
        "fullName": "Groningen Seaports NV",
        "shortName": "GSP",
        "portAuthorityId": "000000",
        "emailAddress": "operations@groningen-seaports.com",
        "address": "Handelskade Oost 1",
        "city": "Delfzijl",
        "countryUnCode": "NL",
        "countryName": "Netherlands",
        "zipCode": "9930 PA",
        "phoneNumber": "0596-640400",
        "faxNumber": "0596-630464",
        "contact": "Dhr. Ronald Smits",
        "customsEORINumber": "NL000000050",
        "ean": null,
        "chamberOfCommerceNumber": null,
        "scacCode": null,
        "ensEnabled": false,
        "cargoImportEnabled": false,
        "cargoImportEnabledByDefault": false
      },
      "customsOffice": {
        "name": "Groningen/Kantoor Eemshaven",
        "unCode": "NL000282"
      },
      "ataReported": true,
      "atdReported": true,
      "atdPortReported": false,
      "paDeclarationRequired": true,
      "swDeclarationRequired": true,
      "wasteEnabled": true,
      "dangerousGoodsEnabled": true,
      "harbourDuesEnabled": false,
      "orderNauticalServicesEnabled": false,
      "enableNotificationsToPa": false,
      "authoriseOrganisationsDisabled": false,
      "tugBoatsRequired": true
    },
    "vessel": {
      "imoCode": "9643659",
      "name": "BOMMEL",
      "radioCallSign": "PCCM",
      "motUnCode": "1602",
      "motName": "Tug, with tow",
      "summerDeadWeight": 0,
      "maxWidth": 9.3,
      "flagCode": "NLD",
      "flagCountryUnCode": "NL",
      "netTonnage": 52,
      "grossTonnage": 174,
      "registrationPlaceUnloCode": "NLZWI",
      "registrationPlaceName": "Zwijndrecht",
      "registrationDate": "2013-02-18",
      "mmsiNumber": "244850205",
      "fullLength": 24.9,
      "emailAddress": null
    },
    "owner": {
      "fullName": "Wagenborg Agencies B.V.",
      "shortName": "WAGENBORG",
      "portAuthorityId": "92415",
      "emailAddress": "agency.amsterdam@wagenborg.com",
      "address": "Marktstraat 10",
      "city": "Delfzijl",
      "countryUnCode": "NL",
      "countryName": "Netherlands",
      "zipCode": "9934 CK",
      "phoneNumber": "0596636956",
      "faxNumber": null,
      "contact": "Loes Jaspers Focks",
      "customsEORINumber": "NL001221401",
      "ean": null,
      "chamberOfCommerceNumber": "02304700",
      "scacCode": null,
      "ensEnabled": true,
      "cargoImportEnabled": true,
      "cargoImportEnabledByDefault": true
    },
    "nextOwner": null,
    "declarant": {
      "fullName": "Wagenborg Agencies B.V.",
      "shortName": "SO1",
      "portAuthorityId": "92415",
      "emailAddress": "agency.amsterdam@wagenborg.com",
      "address": "Marktstraat 10",
      "city": "Delfzijl",
      "countryUnCode": "NL",
      "countryName": "Netherlands",
      "zipCode": "9934 CK",
      "phoneNumber": "0596636956",
      "faxNumber": null,
      "contact": "Loes Jaspers Focks",
      "customsEORINumber": "NL001221401",
      "ean": null,
      "chamberOfCommerceNumber": "02304700",
      "scacCode": null,
      "ensEnabled": true,
      "cargoImportEnabled": true,
      "cargoImportEnabledByDefault": true
    },
    "nextDeclarant": null,
    "financialDeclarant": {
      "fullName": "Wagenborg Agencies B.V.",
      "shortName": "WAGENBORG",
      "portAuthorityId": "92415",
      "emailAddress": "agency.amsterdam@wagenborg.com",
      "address": "Marktstraat 10",
      "city": "Delfzijl",
      "countryUnCode": "NL",
      "countryName": "Netherlands",
      "zipCode": "9934 CK",
      "phoneNumber": "0596636956",
      "faxNumber": null,
      "contact": "Loes Jaspers Focks",
      "customsEORINumber": "NL001221401",
      "ean": null,
      "chamberOfCommerceNumber": "02304700",
      "scacCode": null,
      "ensEnabled": true,
      "cargoImportEnabled": true,
      "cargoImportEnabledByDefault": true
    },
    "nominatedFinancialDeclarant": null,
    "orderNauticalServices": false,
    "orderIncomingMovement": false,
    "creditNumberHolderSameAsDeclarant": false,
    "declarantResponsibleForCustoms": false,
    "cargoDeclarants": [
      {
        "fullName": "Wagenborg Agencies B.V.",
        "shortName": "WAGENBORG",
        "portAuthorityId": "92415",
        "emailAddress": "agency.amsterdam@wagenborg.com",
        "address": "Marktstraat 10",
        "city": "Delfzijl",
        "countryUnCode": "NL",
        "countryName": "Netherlands",
        "zipCode": "9934 CK",
        "phoneNumber": "0596636956",
        "faxNumber": null,
        "contact": "Loes Jaspers Focks",
        "customsEORINumber": "NL001221401",
        "ean": null,
        "chamberOfCommerceNumber": "02304700",
        "scacCode": null,
        "ensEnabled": true,
        "cargoImportEnabled": true,
        "cargoImportEnabledByDefault": true
      }
    ],
    "shipOperators": [
      {
        "fullName": "Wagenborg Agencies B.V.",
        "shortName": "WAGENBORG",
        "portAuthorityId": "92415",
        "emailAddress": "agency.amsterdam@wagenborg.com",
        "address": "Marktstraat 10",
        "city": "Delfzijl",
        "countryUnCode": "NL",
        "countryName": "Netherlands",
        "zipCode": "9934 CK",
        "phoneNumber": "0596636956",
        "faxNumber": null,
        "contact": "Loes Jaspers Focks",
        "customsEORINumber": "NL001221401",
        "ean": null,
        "chamberOfCommerceNumber": "02304700",
        "scacCode": null,
        "ensEnabled": true,
        "cargoImportEnabled": true,
        "cargoImportEnabledByDefault": true
      },
      {
        "fullName": "Wagenborg Agencies B.V.",
        "shortName": "WAGENBORG",
        "portAuthorityId": "92415",
        "emailAddress": "agency.amsterdam@wagenborg.com",
        "address": "Marktstraat 10",
        "city": "Delfzijl",
        "countryUnCode": "NL",
        "countryName": "Netherlands",
        "zipCode": "9934 CK",
        "phoneNumber": "0596636956",
        "faxNumber": null,
        "contact": "Loes Jaspers Focks",
        "customsEORINumber": "NL001221401",
        "ean": null,
        "chamberOfCommerceNumber": "02304700",
        "scacCode": null,
        "ensEnabled": true,
        "cargoImportEnabled": true,
        "cargoImportEnabledByDefault": true
      },
      {
        "fullName": "Groningen Seaports NV",
        "shortName": "GSP",
        "portAuthorityId": "000000",
        "emailAddress": "operations@groningen-seaports.com",
        "address": "Handelskade Oost 1",
        "city": "Delfzijl",
        "countryUnCode": "NL",
        "countryName": "Netherlands",
        "zipCode": "9930 PA",
        "phoneNumber": "0596-640400",
        "faxNumber": "0596-630464",
        "contact": "Dhr. Ronald Smits",
        "customsEORINumber": "NL000000050",
        "ean": null,
        "chamberOfCommerceNumber": null,
        "scacCode": null,
        "ensEnabled": false,
        "cargoImportEnabled": false,
        "cargoImportEnabledByDefault": false
      }
    ],
    "additionalViewers": [],
    "visitDeclaration": {
      "clientReferenceNumber": null,
      "vesselEmailAddress": null,
      "etaFirstEntryEu": null,
      "arrivalVoyage": {
        "voyageNumber": null,
        "carrier": {
          "customsId": "NL001221759",
          "name": "Wagenborg Shipping B.V.",
          "scacCode": "WGBG",
          "smdgCode": null,
          "cargoDeclarantShortName": null
        }
      },
      "previousPorts": [
        {
          "id": "16b75b83-da10-437d-827d-9018fc7a30d9",
          "arrival": null,
          "departure": "2021-08-10T10:00:00Z",
          "port": {
            "name": "Eemshaven",
            "locationUnCode": "NLEEM",
            "countryUnCode": "NL",
            "euPort": true
          },
          "portFacilityVisits": []
        }
      ],
      "portVisit": {
        "pilotStation": null,
        "entryPoint": {
          "code": "DZL-E",
          "name": "Delfzijl Breakwaters",
          "atSea": null
        },
        "etaPort": "2021-08-13T09:00:00Z",
        "portEntry": null,
        "firstMovement": {
          "vesselDraft": 2.6,
          "vesselMasterName": "x",
          "numberOfCrew": 2,
          "numberOfPassengers": 0,
          "cargo": "BALLAST_CONDITION",
          "orderEmail": null,
          "orderSms": null,
          "pilotExemption": null,
          "offStandardBeam": null,
          "pilotService": {
            "required": false,
            "serviceProvider": null,
            "remarks": null
          },
          "order": null,
          "stormPilotageInformation": null
        },
        "passingThroughTugboats": null,
        "defectTypes": [],
        "defectTypeRemarks": null,
        "berthVisits": [
          {
            "id": "99d024ff-4d54-4cb1-865b-44e26f954bc3",
            "berth": {
              "name": "EEM-EH DRST NOORD",
              "shortName": null,
              "code": "EEH20",
              "terminalCode": "EEH20",
              "terminalName": "EEM-EH DRST NOORD",
              "organisationShortName": null,
              "organisationName": null,
              "berthGroupCode": "EEH20",
              "buoy": false,
              "harbourDuesArea": true,
              "partyToNotify": null
            },
            "eta": "2021-08-13T10:00:00Z",
            "requestedEta": null,
            "ata": "2021-08-13T09:24:07Z",
            "etd": null,
            "requestedEtd": null,
            "atd": "2021-08-13T09:31:41Z",
            "mooring": "NO_PREFERENCE",
            "remarks": null,
            "visitPurposes": [
              "AWAITING_ORDERS"
            ],
            "bollardFrom": null,
            "bollardTo": null,
            "tugboatAtArrival": {
              "required": false,
              "serviceProvider": {
                "name": "Wagenborg Sleepdienst BV",
                "portAuthorityId": "WAGENS"
              },
              "requiredQuantity": null,
              "remarks": null
            },
            "boatmenAtArrival": {
              "required": false,
              "serviceProvider": null,
              "remarks": null
            },
            "boatmenAtDeparture": {
              "required": false,
              "serviceProvider": null,
              "remarks": null
            },
            "tugboatAtDeparture": {
              "required": false,
              "serviceProvider": {
                "name": "Wagenborg Sleepdienst BV",
                "portAuthorityId": "WAGENS"
              },
              "requiredQuantity": null,
              "remarks": null
            },
            "nextMovement": {
              "vesselDraft": 2.6,
              "vesselMasterName": "x",
              "numberOfCrew": 2,
              "numberOfPassengers": 0,
              "cargo": "BALLAST_CONDITION",
              "orderEmail": null,
              "orderSms": null,
              "pilotExemption": null,
              "offStandardBeam": null,
              "pilotService": {
                "required": false,
                "serviceProvider": null,
                "remarks": null
              },
              "order": null,
              "stormPilotageInformation": null
            }
          }
        ],
        "dangerousGoodsLoading": false,
        "dangerousGoodsDischarge": false,
        "dangerousGoodsTransit": false,
        "vesselInspectionRequired": false,
        "shipConfiguration": null,
        "exitPoint": {
          "code": "DZL-E",
          "name": "Delfzijl Breakwaters",
          "atSea": null
        },
        "etdPort": "2021-08-16T13:00:00Z",
        "atdPort": "2021-08-13T09:46:41Z",
        "ataPort": "2021-08-13T09:24:07Z"
      },
      "departureVoyage": {
        "voyageNumber": null,
        "carrier": {
          "customsId": "NL001221759",
          "name": "Wagenborg Shipping B.V.",
          "scacCode": "WGBG",
          "smdgCode": null,
          "cargoDeclarantShortName": null
        }
      },
      "nextPorts": [
        {
          "id": "32eaf528-39ca-4976-aca5-ce7f2cbf7f41",
          "arrival": "2021-08-21T09:00:00Z",
          "departure": null,
          "port": {
            "name": "Eemshaven",
            "locationUnCode": "NLEEM",
            "countryUnCode": "NL",
            "euPort": true
          },
          "customsOffice": null
        }
      ]
    },
    "nextVisitDeclaration": null,
    "securityDeclaration": {
      "securityReportRequired": "NO",
      "companySecurityOfficer": null,
      "currentSecurityLevel": null,
      "approvedSspDocumentOnBoard": null,
      "isscSecurityDocument": null,
      "shipToShipActivities": [],
      "securityRelatedMatter": null
    },
    "wasteDeclaration": {
      "portOfLastDelivery": null,
      "lastDeliveryDate": null,
      "wasteItems": [
        {
          "type": "SLUDGE",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "FUEL_REMNANTS",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "BILGE_WATER",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "USED_ENGINE_OIL",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "FOOD_WASTE",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "PLASTIC_CLEAN",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "PLASTIC_CONTAMINATED",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "SMALL_DANGEROUS_WASTE",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "OTHER_DOMESTIC_WASTE",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "INTERNATIONAL_CATERING_WASTE",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "PAPER_OR_CARTON",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "RAGS",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "GLASS",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "METAL_OR_STEELWIRES",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "BOTTLES",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "CROCKERY",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "INCINERATOR_ASHES",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "ANIMAL_CARCASSES",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "EWASTE_WHOLE_DEVICES",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "EWASTE_DEVICES",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "PYROTECHNICS",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "BATTERIES",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "FIRE_EXTINGUISHERS",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "CAR_TYRES_OR_RUBBER",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "COOLING_LIQUIDS",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "PROPELLER_SHAFT_GREASE",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "FUMIGATION_PRODUCTS",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "FILTER_OR_RAGS_BAGS",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "WOOD",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "OIL_CONTAMINATED_DRUMS",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "FISHING_GEAR",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "COOKING_OIL",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "OPERATIONAL_WASTE",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "SEWAGE_WASTE_BLACK",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "SEWAGE_WASTE_GREY",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "DIRTY_BALLASTWATER",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "WASHWATER_OIL",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "OILY_WASTE_FROM_CLEANING",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "OTHER_OILY_WASTE",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "CARGO_RESIDUES_OR_WASHWATER",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "DIRTY_BALLASTWATER_CHEMICALS",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "OTHER_CARGO_RESIDUES",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "WASHWATER_DRY_CARGO_HME",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "WASHWATER_DRY_CARGO_NON_HME",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "DRY_CARGO_RESIDUES_HME",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "DRY_CARGO_RESIDUES_NON_HME",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "OTHER_CARGO_RESIDUES_NON_TANKERS",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        }
      ]
    },
    "wasteCollections": [],
    "shipStoresDeclaration": {
      "items": {
        "SPIRITS": 0,
        "BEER": 0,
        "WINE": 0,
        "OTHER": 0,
        "CIGARETTES": 0,
        "CIGARS": 0,
        "TOBACCO": 0,
        "FUEL_OIL": 51800,
        "LUBRICATING_OIL": 671,
        "MEAT": 22,
        "NARCOTICS": 0,
        "FIRE_ARMS": 0
      }
    },
    "dangerousGoodsDeclarations": [],
    "nextDangerousGoodsDeclaration": null,
    "importDeclarations": [],
    "transitDeclarationMap": {},
    "loadingDeclarations": [],
    "paxDeclarationSummaries": [
      {
        "numberOfCrew": 4,
        "numberOfPassengers": 0,
        "numberOfCrewOtherShip": 0,
        "numberOfStowaways": 0,
        "searchAndRescue": null
      }
    ],
    "healthDeclarationSummary": null,
    "etaPortAis": null,
    "ignoreEtaPortAis": false,
    "cancelled": false,
    "ataRegisteredByPa": true,
    "visitKnownAtPa": true,
    "dangerousGoodsEnabled": true,
    "cargoImportEnabled": true,
    "ataAcknowledged": true,
    "declarations": [
      {
        "type": "VISIT",
        "id": null,
        "status": "ACCEPTED",
        "rejectReasons": null,
        "timeStamp": "2021-08-09T13:27:18.531Z"
      },
      {
        "type": "WPCS",
        "id": null,
        "status": "DECLARED",
        "rejectReasons": null,
        "timeStamp": "2021-08-09T13:27:49.290Z"
      },
      {
        "type": "VISIT",
        "id": null,
        "status": "ACCEPTED",
        "rejectReasons": null,
        "timeStamp": "2021-08-10T18:46:30.702Z"
      },
      {
        "type": "WPCS",
        "id": null,
        "status": "DECLARED",
        "rejectReasons": null,
        "timeStamp": "2021-08-10T18:46:30.781Z"
      },
      {
        "type": "VISIT",
        "id": null,
        "status": "ACCEPTED",
        "rejectReasons": null,
        "timeStamp": "2021-08-12T13:57:56.431Z"
      },
      {
        "type": "WPCS",
        "id": null,
        "status": "DECLARED",
        "rejectReasons": null,
        "timeStamp": "2021-08-12T13:57:56.569Z"
      },
      {
        "type": "SECURITY",
        "id": null,
        "status": "ACCEPTED",
        "rejectReasons": null,
        "timeStamp": "2021-08-12T14:04:03.928Z"
      },
      {
        "type": "WASTE",
        "id": null,
        "status": "ACCEPTED",
        "rejectReasons": null,
        "timeStamp": "2021-08-12T14:04:09.452Z"
      },
      {
        "type": "PAX",
        "id": null,
        "status": "ACCEPTED",
        "rejectReasons": null,
        "timeStamp": "2021-08-12T14:05:47.299Z"
      },
      {
        "type": "STO",
        "id": null,
        "status": "ACCEPTED",
        "rejectReasons": null,
        "timeStamp": "2021-08-12T14:06:15.233Z"
      },
      {
        "type": "VISIT",
        "id": null,
        "status": "ACCEPTED",
        "rejectReasons": null,
        "timeStamp": "2021-08-13T08:42:12.348Z"
      },
      {
        "type": "WPCS",
        "id": null,
        "status": "DECLARED",
        "rejectReasons": null,
        "timeStamp": "2021-08-13T08:42:12.528Z"
      },
      {
        "type": "WPCS",
        "id": null,
        "status": "DECLARED",
        "rejectReasons": null,
        "timeStamp": "2021-08-13T09:24:12.103Z"
      },
      {
        "type": "WPCS",
        "id": null,
        "status": "DECLARED",
        "rejectReasons": null,
        "timeStamp": "2021-08-13T09:31:50.394Z"
      }
    ],
    "uploads": {
      "STO": "5657ed9c-1248-477c-b270-6bed87d1ff06"
    },
    "additionalIncomingMovementInfo": null,
    "berthVisitInfos": {},
    "incomingMovementHarbourMasterInfo": null,
    "incomingTidalWindowStatus": null,
    "lastMessageId": "750621db-fbe4-42e4-8ffa-ced07e751e51",
    "created": "2021-08-09T13:19:49.553Z",
    "updated": "2021-08-13T09:31:50.394Z",
    "berthVisitIdOfTransfer": null,
    "hasBeenTransferred": false,
    "allowedViewers": [
      {
        "fullName": "Groningen Seaports NV",
        "shortName": "GSP",
        "portAuthorityId": "000000",
        "emailAddress": "operations@groningen-seaports.com",
        "address": "Handelskade Oost 1",
        "city": "Delfzijl",
        "countryUnCode": "NL",
        "countryName": "Netherlands",
        "zipCode": "9930 PA",
        "phoneNumber": "0596-640400",
        "faxNumber": "0596-630464",
        "contact": "Dhr. Ronald Smits",
        "customsEORINumber": "NL000000050",
        "ean": null,
        "chamberOfCommerceNumber": null,
        "scacCode": null,
        "ensEnabled": false,
        "cargoImportEnabled": false,
        "cargoImportEnabledByDefault": false
      },
      {
        "fullName": "Wagenborg Agencies B.V.",
        "shortName": "WAGENBORG",
        "portAuthorityId": "92415",
        "emailAddress": "agency.amsterdam@wagenborg.com",
        "address": "Marktstraat 10",
        "city": "Delfzijl",
        "countryUnCode": "NL",
        "countryName": "Netherlands",
        "zipCode": "9934 CK",
        "phoneNumber": "0596636956",
        "faxNumber": null,
        "contact": "Loes Jaspers Focks",
        "customsEORINumber": "NL001221401",
        "ean": null,
        "chamberOfCommerceNumber": "02304700",
        "scacCode": null,
        "ensEnabled": true,
        "cargoImportEnabled": true,
        "cargoImportEnabledByDefault": true
      }
    ],
    "previousMessageId": "a3aebe16-cd01-4fdd-826b-d23549457cb1",
    "nextMessageId": null,
    "transitDeclarations": [],
    "visitStatus": "DEPARTED"
  },
  NLDZL21003738: {
    "crn": "NLDZL21003738",
    "copyOfVisit": "NLDZL21003568",
    "portOfCall": {
      "port": {
        "name": "Delfzijl",
        "locationUnCode": "NLDZL",
        "countryUnCode": "NL",
        "euPort": true
      },
      "portAuthority": {
        "fullName": "Groningen Seaports NV",
        "shortName": "GSP",
        "portAuthorityId": "000000",
        "emailAddress": "operations@groningen-seaports.com",
        "address": "Handelskade Oost 1",
        "city": "Delfzijl",
        "countryUnCode": "NL",
        "countryName": "Netherlands",
        "zipCode": "9930 PA",
        "phoneNumber": "0596-640400",
        "faxNumber": "0596-630464",
        "contact": "Dhr. Ronald Smits",
        "customsEORINumber": "NL000000050",
        "ean": null,
        "chamberOfCommerceNumber": null,
        "scacCode": null,
        "ensEnabled": false,
        "cargoImportEnabled": false,
        "cargoImportEnabledByDefault": false
      },
      "customsOffice": {
        "name": "Groningen/Kantoor Eemshaven",
        "unCode": "NL000282"
      },
      "ataReported": true,
      "atdReported": true,
      "atdPortReported": false,
      "paDeclarationRequired": true,
      "swDeclarationRequired": true,
      "wasteEnabled": true,
      "dangerousGoodsEnabled": true,
      "harbourDuesEnabled": false,
      "orderNauticalServicesEnabled": false,
      "enableNotificationsToPa": false,
      "authoriseOrganisationsDisabled": false,
      "tugBoatsRequired": true
    },
    "vessel": {
      "imoCode": "9423671",
      "name": "HEYN",
      "radioCallSign": "PDFP",
      "motUnCode": "150X",
      "motName": "General cargo vessel",
      "summerDeadWeight": 3500,
      "maxWidth": 12.58,
      "flagCode": "NLD",
      "flagCountryUnCode": "NL",
      "netTonnage": 1053,
      "grossTonnage": 2528,
      "registrationPlaceUnloCode": "NLAMS",
      "registrationPlaceName": "Amsterdam",
      "registrationDate": "2008-12-19",
      "mmsiNumber": "244130642",
      "fullLength": 89.99,
      "emailAddress": null
    },
    "owner": {
      "fullName": "Wagenborg Agencies B.V.",
      "shortName": "WAGENBORG",
      "portAuthorityId": "92415",
      "emailAddress": "agency.amsterdam@wagenborg.com",
      "address": "Marktstraat 10",
      "city": "Delfzijl",
      "countryUnCode": "NL",
      "countryName": "Netherlands",
      "zipCode": "9934 CK",
      "phoneNumber": "0596636956",
      "faxNumber": null,
      "contact": "Maico Bakker",
      "customsEORINumber": "NL001221401",
      "ean": null,
      "chamberOfCommerceNumber": "02304700",
      "scacCode": null,
      "ensEnabled": true,
      "cargoImportEnabled": true,
      "cargoImportEnabledByDefault": true
    },
    "nextOwner": null,
    "declarant": {
      "fullName": "Wagenborg Agencies B.V.",
      "shortName": "WAGENBORG",
      "portAuthorityId": "92415",
      "emailAddress": "agency.amsterdam@wagenborg.com",
      "address": "Marktstraat 10",
      "city": "Delfzijl",
      "countryUnCode": "NL",
      "countryName": "Netherlands",
      "zipCode": "9934 CK",
      "phoneNumber": "0596636956",
      "faxNumber": null,
      "contact": "Maico Bakker",
      "customsEORINumber": "NL001221401",
      "ean": null,
      "chamberOfCommerceNumber": "02304700",
      "scacCode": null,
      "ensEnabled": true,
      "cargoImportEnabled": true,
      "cargoImportEnabledByDefault": true
    },
    "nextDeclarant": null,
    "financialDeclarant": {
      "fullName": "Wagenborg Agencies B.V.",
      "shortName": "WAGENBORG",
      "portAuthorityId": "92415",
      "emailAddress": "agency.amsterdam@wagenborg.com",
      "address": "Marktstraat 10",
      "city": "Delfzijl",
      "countryUnCode": "NL",
      "countryName": "Netherlands",
      "zipCode": "9934 CK",
      "phoneNumber": "0596636956",
      "faxNumber": null,
      "contact": "Maico Bakker",
      "customsEORINumber": "NL001221401",
      "ean": null,
      "chamberOfCommerceNumber": "02304700",
      "scacCode": null,
      "ensEnabled": true,
      "cargoImportEnabled": true,
      "cargoImportEnabledByDefault": true
    },
    "nominatedFinancialDeclarant": null,
    "orderNauticalServices": false,
    "orderIncomingMovement": false,
    "creditNumberHolderSameAsDeclarant": false,
    "declarantResponsibleForCustoms": false,
    "cargoDeclarants": [
      {
        "fullName": "Wagenborg Agencies B.V.",
        "shortName": "WAGENBORG",
        "portAuthorityId": "92415",
        "emailAddress": "agency.amsterdam@wagenborg.com",
        "address": "Marktstraat 10",
        "city": "Delfzijl",
        "countryUnCode": "NL",
        "countryName": "Netherlands",
        "zipCode": "9934 CK",
        "phoneNumber": "0596636956",
        "faxNumber": null,
        "contact": "Peter Zweepe",
        "customsEORINumber": "NL001221401",
        "ean": null,
        "chamberOfCommerceNumber": "02304700",
        "scacCode": null,
        "ensEnabled": true,
        "cargoImportEnabled": true,
        "cargoImportEnabledByDefault": true
      }
    ],
    "shipOperators": [
      {
        "fullName": "Wagenborg Agencies B.V.",
        "shortName": "WAGENBORG",
        "portAuthorityId": "92415",
        "emailAddress": "agency.amsterdam@wagenborg.com",
        "address": "Marktstraat 10",
        "city": "Delfzijl",
        "countryUnCode": "NL",
        "countryName": "Netherlands",
        "zipCode": "9934 CK",
        "phoneNumber": "0596636956",
        "faxNumber": null,
        "contact": "Maico Bakker",
        "customsEORINumber": "NL001221401",
        "ean": null,
        "chamberOfCommerceNumber": "02304700",
        "scacCode": null,
        "ensEnabled": true,
        "cargoImportEnabled": true,
        "cargoImportEnabledByDefault": true
      },
      {
        "fullName": "Wagenborg Agencies B.V.",
        "shortName": "WAGENBORG",
        "portAuthorityId": "92415",
        "emailAddress": "agency.amsterdam@wagenborg.com",
        "address": "Marktstraat 10",
        "city": "Delfzijl",
        "countryUnCode": "NL",
        "countryName": "Netherlands",
        "zipCode": "9934 CK",
        "phoneNumber": "0596636956",
        "faxNumber": null,
        "contact": "Maico Bakker",
        "customsEORINumber": "NL001221401",
        "ean": null,
        "chamberOfCommerceNumber": "02304700",
        "scacCode": null,
        "ensEnabled": true,
        "cargoImportEnabled": true,
        "cargoImportEnabledByDefault": true
      },
      {
        "fullName": "Groningen Seaports NV",
        "shortName": "GSP",
        "portAuthorityId": "000000",
        "emailAddress": "operations@groningen-seaports.com",
        "address": "Handelskade Oost 1",
        "city": "Delfzijl",
        "countryUnCode": "NL",
        "countryName": "Netherlands",
        "zipCode": "9930 PA",
        "phoneNumber": "0596-640400",
        "faxNumber": "0596-630464",
        "contact": "Dhr. Ronald Smits",
        "customsEORINumber": "NL000000050",
        "ean": null,
        "chamberOfCommerceNumber": null,
        "scacCode": null,
        "ensEnabled": false,
        "cargoImportEnabled": false,
        "cargoImportEnabledByDefault": false
      }
    ],
    "additionalViewers": [],
    "visitDeclaration": {
      "clientReferenceNumber": null,
      "vesselEmailAddress": null,
      "etaFirstEntryEu": null,
      "arrivalVoyage": {
        "voyageNumber": null,
        "carrier": {
          "customsId": "NL001221759",
          "name": "Wagenborg Shipping B.V.",
          "scacCode": "WGBG",
          "smdgCode": null,
          "cargoDeclarantShortName": null
        }
      },
      "previousPorts": [
        {
          "id": "2f5a98a7-6940-409d-bbdb-af2fc27a5ce4",
          "arrival": "2021-08-24T10:00:00Z",
          "departure": "2021-08-25T10:00:00Z",
          "port": {
            "name": "Delfzijl",
            "locationUnCode": "NLDZL",
            "countryUnCode": "NL",
            "euPort": true
          },
          "portFacilityVisits": [
            {
              "portFacility": {
                "code": "NLDZL-9999",
                "name": "UNKNOWN"
              },
              "arrivalDate": "2021-08-24",
              "departureDate": "2021-08-25",
              "securityLevel": "SL1",
              "additionalSecurityMeasures": null
            }
          ]
        },
        {
          "id": "ef102230-1eb9-4ac8-9b11-420ff546a359",
          "arrival": "2021-08-21T10:00:00Z",
          "departure": "2021-08-23T10:00:00Z",
          "port": {
            "name": "Hvide Sande",
            "locationUnCode": "DKHVS",
            "countryUnCode": "DK",
            "euPort": true
          },
          "portFacilityVisits": [
            {
              "portFacility": {
                "code": "DKHVS-0005",
                "name": "Kaj 11"
              },
              "arrivalDate": "2021-08-21",
              "departureDate": "2021-08-23",
              "securityLevel": "SL1",
              "additionalSecurityMeasures": null
            }
          ]
        },
        {
          "id": "7d203f62-fd2b-4752-882a-9bc29d5ca749",
          "arrival": "2021-08-19T10:00:00Z",
          "departure": "2021-08-20T10:00:00Z",
          "port": {
            "name": "Hamburg",
            "locationUnCode": "DEHAM",
            "countryUnCode": "DE",
            "euPort": true
          },
          "portFacilityVisits": [
            {
              "portFacility": {
                "code": "DEHAM-0037",
                "name": "Hamburg / ADM HAMBURG AG - SILO HAMBURG"
              },
              "arrivalDate": "2021-08-19",
              "departureDate": "2021-08-20",
              "securityLevel": "SL1",
              "additionalSecurityMeasures": null
            }
          ]
        },
        {
          "id": "76b1cdb1-7e52-4302-8635-ad448cc792fe",
          "arrival": "2021-08-17T10:00:00Z",
          "departure": "2021-08-18T10:00:00Z",
          "port": {
            "name": "Delfzijl",
            "locationUnCode": "NLDZL",
            "countryUnCode": "NL",
            "euPort": true
          },
          "portFacilityVisits": [
            {
              "portFacility": {
                "code": "NLDZL-0018",
                "name": "Delfzijl: Wijnne & Barends Terminal Delfzijl B.V. , Locatie Handelshaven"
              },
              "arrivalDate": "2021-08-17",
              "departureDate": "2021-08-18",
              "securityLevel": "SL1",
              "additionalSecurityMeasures": null
            }
          ]
        },
        {
          "id": "e4fa8864-6a78-4115-8ce4-a6ea4d330dd1",
          "arrival": "2021-08-09T10:00:00Z",
          "departure": "2021-08-15T10:00:00Z",
          "port": {
            "name": "Szczecin",
            "locationUnCode": "PLSZZ",
            "countryUnCode": "PL",
            "euPort": true
          },
          "portFacilityVisits": [
            {
              "portFacility": {
                "code": "PLSZZ-0028",
                "name": "Alfa Terminal Szczecin"
              },
              "arrivalDate": "2021-08-09",
              "departureDate": "2021-08-15",
              "securityLevel": "SL1",
              "additionalSecurityMeasures": null
            }
          ]
        },
        {
          "id": "9b0686a5-03e3-4eca-9879-f4c1657c9999",
          "arrival": "2021-08-03T10:00:00Z",
          "departure": "2021-08-09T10:00:00Z",
          "port": {
            "name": "Helsingborg",
            "locationUnCode": "SEHEL",
            "countryUnCode": "SE",
            "euPort": true
          },
          "portFacilityVisits": [
            {
              "portFacility": {
                "code": "SEHEL-9999",
                "name": "UNKNOWN"
              },
              "arrivalDate": "2021-08-03",
              "departureDate": "2021-08-09",
              "securityLevel": "SL1",
              "additionalSecurityMeasures": null
            }
          ]
        },
        {
          "id": "e64d6811-644b-4d25-a38c-8265720dafb2",
          "arrival": "2021-07-26T10:00:00Z",
          "departure": "2021-07-31T10:00:00Z",
          "port": {
            "name": "Vlissingen",
            "locationUnCode": "NLVLI",
            "countryUnCode": "NL",
            "euPort": true
          },
          "portFacilityVisits": [
            {
              "portFacility": {
                "code": "NLVLI-0010",
                "name": "Vlissingen: Finsa"
              },
              "arrivalDate": "2021-07-26",
              "departureDate": "2021-07-31",
              "securityLevel": "SL1",
              "additionalSecurityMeasures": null
            }
          ]
        },
        {
          "id": "5cac73bb-a20e-4cbd-a212-b81186ba35aa",
          "arrival": "2021-07-19T10:00:00Z",
          "departure": "2021-07-22T10:00:00Z",
          "port": {
            "name": "Villagarcía de Arosa",
            "locationUnCode": "ESVIL",
            "countryUnCode": "ES",
            "euPort": true
          },
          "portFacilityVisits": [
            {
              "portFacility": {
                "code": "ESVIL-0001",
                "name": "Bergé Marítima,S.A.."
              },
              "arrivalDate": "2021-07-19",
              "departureDate": "2021-07-22",
              "securityLevel": "SL1",
              "additionalSecurityMeasures": null
            }
          ]
        },
        {
          "id": "9f5de2bb-1d74-4aaa-8ac2-893d47ba9cd9",
          "arrival": "2021-07-17T10:00:00Z",
          "departure": "2021-07-19T10:00:00Z",
          "port": {
            "name": "Corcubión",
            "locationUnCode": "ESCCN",
            "countryUnCode": "ES",
            "euPort": true
          },
          "portFacilityVisits": [
            {
              "portFacility": {
                "code": "ESCCN-9999",
                "name": "UNKNOWN"
              },
              "arrivalDate": "2021-07-17",
              "departureDate": "2021-07-19",
              "securityLevel": "SL1",
              "additionalSecurityMeasures": null
            }
          ]
        },
        {
          "id": "1c6e07cc-f439-4b0e-b168-e2e63711a46a",
          "arrival": "2021-07-12T10:00:00Z",
          "departure": "2021-07-12T10:00:00Z",
          "port": {
            "name": "Amsterdam",
            "locationUnCode": "NLAMS",
            "countryUnCode": "NL",
            "euPort": true
          },
          "portFacilityVisits": [
            {
              "portFacility": {
                "code": "NLAMS-0062",
                "name": "Amsterdam: Afrikahaven Coal terminal ASD 58HF1"
              },
              "arrivalDate": "2021-07-12",
              "departureDate": "2021-07-12",
              "securityLevel": "SL1",
              "additionalSecurityMeasures": null
            }
          ]
        },
        {
          "id": "8c73a42d-b4d1-408d-b690-e82c4ae1b692",
          "arrival": null,
          "departure": null,
          "port": {
            "name": "Erith",
            "locationUnCode": "GBERI",
            "countryUnCode": "GB",
            "euPort": false
          },
          "portFacilityVisits": []
        }
      ],
      "portVisit": {
        "pilotStation": null,
        "entryPoint": {
          "code": "DZL-E",
          "name": "Delfzijl Breakwaters",
          "atSea": null
        },
        "etaPort": "2021-09-01T18:00:00Z",
        "portEntry": null,
        "firstMovement": {
          "vesselDraft": 5.6,
          "vesselMasterName": "H",
          "numberOfCrew": 6,
          "numberOfPassengers": 0,
          "cargo": "BREAKBULK",
          "orderEmail": null,
          "orderSms": null,
          "pilotExemption": null,
          "offStandardBeam": null,
          "pilotService": {
            "required": true,
            "serviceProvider": {
              "name": "Nederlands Loodswezen B.V. North",
              "portAuthorityId": "LWN"
            },
            "remarks": null
          },
          "order": null,
          "stormPilotageInformation": null
        },
        "passingThroughTugboats": null,
        "defectTypes": [],
        "defectTypeRemarks": null,
        "berthVisits": [
          {
            "id": "b1363973-d8a5-4153-9f5e-0e913ff806f7",
            "berth": {
              "name": "DZL-HANDELSKADE OOST",
              "shortName": null,
              "code": "DHH80",
              "terminalCode": "DHH80",
              "terminalName": "DZL-HANDELSKADE OOST",
              "organisationShortName": null,
              "organisationName": null,
              "berthGroupCode": "DHH80",
              "buoy": false,
              "harbourDuesArea": true,
              "partyToNotify": null
            },
            "eta": "2021-09-01T19:00:00Z",
            "requestedEta": null,
            "ata": "2021-09-01T18:40:12Z",
            "etd": "2021-09-04T07:00:00Z",
            "requestedEtd": null,
            "atd": "2021-09-04T07:00:36Z",
            "mooring": "NO_PREFERENCE",
            "remarks": null,
            "visitPurposes": [
              "DISCHARGE"
            ],
            "bollardFrom": null,
            "bollardTo": null,
            "tugboatAtArrival": {
              "required": true,
              "serviceProvider": {
                "name": "Wagenborg Sleepdienst BV",
                "portAuthorityId": "WAGENS"
              },
              "requiredQuantity": "TWO",
              "remarks": "Waterstraat + TBC"
            },
            "boatmenAtArrival": {
              "required": true,
              "serviceProvider": {
                "name": "Bootliedenvereniging Delfzijl/Eemsh",
                "portAuthorityId": "BVDE"
              },
              "remarks": null
            },
            "boatmenAtDeparture": {
              "required": true,
              "serviceProvider": {
                "name": "Bootliedenvereniging Delfzijl/Eemsh",
                "portAuthorityId": "BVDE"
              },
              "remarks": null
            },
            "tugboatAtDeparture": {
              "required": true,
              "serviceProvider": {
                "name": "Wagenborg Sleepdienst BV",
                "portAuthorityId": "WAGENS"
              },
              "requiredQuantity": "TWO",
              "remarks": null
            },
            "nextMovement": {
              "vesselDraft": 3.5,
              "vesselMasterName": "J G Versteegt",
              "numberOfCrew": 8,
              "numberOfPassengers": 0,
              "cargo": "BALLAST_CONDITION",
              "orderEmail": null,
              "orderSms": null,
              "pilotExemption": null,
              "offStandardBeam": null,
              "pilotService": {
                "required": true,
                "serviceProvider": {
                  "name": "Nederlands Loodswezen B.V. North",
                  "portAuthorityId": "LWN"
                },
                "remarks": null
              },
              "order": null,
              "stormPilotageInformation": null
            }
          },
          {
            "id": "8353118f-12c4-4f6d-aff4-dac268a8f857",
            "berth": {
              "name": "DZL-OEK WERF NS",
              "shortName": null,
              "code": "DOE10",
              "terminalCode": "DOE10",
              "terminalName": "DZL-OEK WERF NS",
              "organisationShortName": null,
              "organisationName": null,
              "berthGroupCode": "DOE10",
              "buoy": false,
              "harbourDuesArea": true,
              "partyToNotify": null
            },
            "eta": "2021-09-04T08:00:00Z",
            "requestedEta": null,
            "ata": "2021-09-04T09:05:09Z",
            "etd": null,
            "requestedEtd": null,
            "atd": null,
            "mooring": "NO_PREFERENCE",
            "remarks": null,
            "visitPurposes": [
              "REPAIRS"
            ],
            "bollardFrom": null,
            "bollardTo": null,
            "tugboatAtArrival": {
              "required": true,
              "serviceProvider": {
                "name": "Wagenborg Sleepdienst BV",
                "portAuthorityId": "WAGENS"
              },
              "requiredQuantity": "TWO",
              "remarks": null
            },
            "boatmenAtArrival": {
              "required": true,
              "serviceProvider": {
                "name": "Bootliedenvereniging Delfzijl/Eemsh",
                "portAuthorityId": "BVDE"
              },
              "remarks": null
            },
            "boatmenAtDeparture": {
              "required": false,
              "serviceProvider": null,
              "remarks": null
            },
            "tugboatAtDeparture": {
              "required": false,
              "serviceProvider": {
                "name": "Wagenborg Sleepdienst BV",
                "portAuthorityId": "WAGENS"
              },
              "requiredQuantity": null,
              "remarks": null
            },
            "nextMovement": {
              "vesselDraft": 3.5,
              "vesselMasterName": "J G Versteegt",
              "numberOfCrew": 8,
              "numberOfPassengers": 0,
              "cargo": "BALLAST_CONDITION",
              "orderEmail": null,
              "orderSms": null,
              "pilotExemption": null,
              "offStandardBeam": null,
              "pilotService": {
                "required": false,
                "serviceProvider": null,
                "remarks": null
              },
              "order": null,
              "stormPilotageInformation": null
            }
          }
        ],
        "dangerousGoodsLoading": false,
        "dangerousGoodsDischarge": false,
        "dangerousGoodsTransit": false,
        "vesselInspectionRequired": false,
        "shipConfiguration": null,
        "exitPoint": {
          "code": "DZL-E",
          "name": "Delfzijl Breakwaters",
          "atSea": null
        },
        "etdPort": "2021-10-15T10:00:00Z",
        "atdPort": null,
        "ataPort": "2021-09-01T18:40:12Z"
      },
      "departureVoyage": {
        "voyageNumber": null,
        "carrier": {
          "customsId": "NL001221759",
          "name": "Wagenborg Shipping B.V.",
          "scacCode": "WGBG",
          "smdgCode": null,
          "cargoDeclarantShortName": null
        }
      },
      "nextPorts": [
        {
          "id": "076443e1-62fb-4c21-94d9-21dcf6c1e102",
          "arrival": "2021-10-17T18:00:00Z",
          "departure": null,
          "port": {
            "name": "Stockviksverken",
            "locationUnCode": "SESTK",
            "countryUnCode": "SE",
            "euPort": true
          },
          "customsOffice": null
        }
      ]
    },
    "nextVisitDeclaration": null,
    "securityDeclaration": {
      "securityReportRequired": "YES",
      "companySecurityOfficer": {
        "name": "R. Tuil",
        "email": "rob.tuil@wagenborg.com",
        "phoneNumber": "31596636911"
      },
      "currentSecurityLevel": "SL1",
      "approvedSspDocumentOnBoard": true,
      "isscSecurityDocument": {
        "availability": "YES",
        "expiryDate": "2023-03-29",
        "reasonNoValidIsscOnBoard": null,
        "issuer": {
          "code": "5",
          "name": "Bureau Veritas"
        }
      },
      "shipToShipActivities": [],
      "securityRelatedMatter": null
    },
    "wasteDeclaration": {
      "portOfLastDelivery": {
        "name": "Hvide Sande",
        "locationUnCode": "DKHVS",
        "countryUnCode": "DK",
        "euPort": true
      },
      "lastDeliveryDate": "2021-08-23",
      "wasteItems": [
        {
          "type": "SLUDGE",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 3.7,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0.3,
          "maxDedicatedCapacity": 11.526,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": {
            "name": "Stockviksverken",
            "locationUnCode": "SESTK",
            "countryUnCode": "SE",
            "euPort": true
          }
        },
        {
          "type": "FUEL_REMNANTS",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "BILGE_WATER",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "USED_ENGINE_OIL",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "FOOD_WASTE",
          "berthIdForPickup": "b1363973-d8a5-4153-9f5e-0e913ff806f7",
          "collector": {
            "fullName": "Renewi",
            "shortName": "GANSEWINKEL",
            "portAuthorityId": "GANSEWINKEL",
            "emailAddress": "info@gansewinkel.nl",
            "address": "Kreekweg 80",
            "city": "Vlaardingen",
            "countryUnCode": "NL",
            "countryName": "Netherlands",
            "zipCode": "3133 AZ",
            "phoneNumber": "+31-800-0130",
            "faxNumber": "+31-800-0130",
            "contact": "Helen de Hon",
            "customsEORINumber": null,
            "ean": null,
            "chamberOfCommerceNumber": null,
            "scacCode": null,
            "ensEnabled": false,
            "cargoImportEnabled": false,
            "cargoImportEnabledByDefault": false
          },
          "specification": null,
          "quantityOfLastDelivery": 0.3,
          "quantityToBeDelivered": 0.3,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "PLASTIC_CLEAN",
          "berthIdForPickup": "b1363973-d8a5-4153-9f5e-0e913ff806f7",
          "collector": {
            "fullName": "Renewi",
            "shortName": "GANSEWINKEL",
            "portAuthorityId": "GANSEWINKEL",
            "emailAddress": "info@gansewinkel.nl",
            "address": "Kreekweg 80",
            "city": "Vlaardingen",
            "countryUnCode": "NL",
            "countryName": "Netherlands",
            "zipCode": "3133 AZ",
            "phoneNumber": "+31-800-0130",
            "faxNumber": "+31-800-0130",
            "contact": "Helen de Hon",
            "customsEORINumber": null,
            "ean": null,
            "chamberOfCommerceNumber": null,
            "scacCode": null,
            "ensEnabled": false,
            "cargoImportEnabled": false,
            "cargoImportEnabledByDefault": false
          },
          "specification": null,
          "quantityOfLastDelivery": 0.2,
          "quantityToBeDelivered": 0.2,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "PLASTIC_CONTAMINATED",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "SMALL_DANGEROUS_WASTE",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "OTHER_DOMESTIC_WASTE",
          "berthIdForPickup": "b1363973-d8a5-4153-9f5e-0e913ff806f7",
          "collector": {
            "fullName": "Renewi",
            "shortName": "GANSEWINKEL",
            "portAuthorityId": "GANSEWINKEL",
            "emailAddress": "info@gansewinkel.nl",
            "address": "Kreekweg 80",
            "city": "Vlaardingen",
            "countryUnCode": "NL",
            "countryName": "Netherlands",
            "zipCode": "3133 AZ",
            "phoneNumber": "+31-800-0130",
            "faxNumber": "+31-800-0130",
            "contact": "Helen de Hon",
            "customsEORINumber": null,
            "ean": null,
            "chamberOfCommerceNumber": null,
            "scacCode": null,
            "ensEnabled": false,
            "cargoImportEnabled": false,
            "cargoImportEnabledByDefault": false
          },
          "specification": "d",
          "quantityOfLastDelivery": 0.2,
          "quantityToBeDelivered": 0.1,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "INTERNATIONAL_CATERING_WASTE",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "PAPER_OR_CARTON",
          "berthIdForPickup": "b1363973-d8a5-4153-9f5e-0e913ff806f7",
          "collector": {
            "fullName": "Renewi",
            "shortName": "GANSEWINKEL",
            "portAuthorityId": "GANSEWINKEL",
            "emailAddress": "info@gansewinkel.nl",
            "address": "Kreekweg 80",
            "city": "Vlaardingen",
            "countryUnCode": "NL",
            "countryName": "Netherlands",
            "zipCode": "3133 AZ",
            "phoneNumber": "+31-800-0130",
            "faxNumber": "+31-800-0130",
            "contact": "Helen de Hon",
            "customsEORINumber": null,
            "ean": null,
            "chamberOfCommerceNumber": null,
            "scacCode": null,
            "ensEnabled": false,
            "cargoImportEnabled": false,
            "cargoImportEnabledByDefault": false
          },
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0.3,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "RAGS",
          "berthIdForPickup": "b1363973-d8a5-4153-9f5e-0e913ff806f7",
          "collector": {
            "fullName": "Renewi",
            "shortName": "GANSEWINKEL",
            "portAuthorityId": "GANSEWINKEL",
            "emailAddress": "info@gansewinkel.nl",
            "address": "Kreekweg 80",
            "city": "Vlaardingen",
            "countryUnCode": "NL",
            "countryName": "Netherlands",
            "zipCode": "3133 AZ",
            "phoneNumber": "+31-800-0130",
            "faxNumber": "+31-800-0130",
            "contact": "Helen de Hon",
            "customsEORINumber": null,
            "ean": null,
            "chamberOfCommerceNumber": null,
            "scacCode": null,
            "ensEnabled": false,
            "cargoImportEnabled": false,
            "cargoImportEnabledByDefault": false
          },
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0.1,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "GLASS",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "METAL_OR_STEELWIRES",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "BOTTLES",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "CROCKERY",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "INCINERATOR_ASHES",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "ANIMAL_CARCASSES",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "EWASTE_WHOLE_DEVICES",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "EWASTE_DEVICES",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "PYROTECHNICS",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "BATTERIES",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "FIRE_EXTINGUISHERS",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "CAR_TYRES_OR_RUBBER",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "COOLING_LIQUIDS",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "PROPELLER_SHAFT_GREASE",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "FUMIGATION_PRODUCTS",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "FILTER_OR_RAGS_BAGS",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "WOOD",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "OIL_CONTAMINATED_DRUMS",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "FISHING_GEAR",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "COOKING_OIL",
          "berthIdForPickup": "b1363973-d8a5-4153-9f5e-0e913ff806f7",
          "collector": {
            "fullName": "Renewi",
            "shortName": "GANSEWINKEL",
            "portAuthorityId": "GANSEWINKEL",
            "emailAddress": "info@gansewinkel.nl",
            "address": "Kreekweg 80",
            "city": "Vlaardingen",
            "countryUnCode": "NL",
            "countryName": "Netherlands",
            "zipCode": "3133 AZ",
            "phoneNumber": "+31-800-0130",
            "faxNumber": "+31-800-0130",
            "contact": "Helen de Hon",
            "customsEORINumber": null,
            "ean": null,
            "chamberOfCommerceNumber": null,
            "scacCode": null,
            "ensEnabled": false,
            "cargoImportEnabled": false,
            "cargoImportEnabledByDefault": false
          },
          "specification": null,
          "quantityOfLastDelivery": 0.005,
          "quantityToBeDelivered": 0.1,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "OPERATIONAL_WASTE",
          "berthIdForPickup": "b1363973-d8a5-4153-9f5e-0e913ff806f7",
          "collector": {
            "fullName": "Renewi",
            "shortName": "GANSEWINKEL",
            "portAuthorityId": "GANSEWINKEL",
            "emailAddress": "info@gansewinkel.nl",
            "address": "Kreekweg 80",
            "city": "Vlaardingen",
            "countryUnCode": "NL",
            "countryName": "Netherlands",
            "zipCode": "3133 AZ",
            "phoneNumber": "+31-800-0130",
            "faxNumber": "+31-800-0130",
            "contact": "Helen de Hon",
            "customsEORINumber": null,
            "ean": null,
            "chamberOfCommerceNumber": null,
            "scacCode": null,
            "ensEnabled": false,
            "cargoImportEnabled": false,
            "cargoImportEnabledByDefault": false
          },
          "specification": null,
          "quantityOfLastDelivery": 0.1,
          "quantityToBeDelivered": 0.2,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "SEWAGE_WASTE_BLACK",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "SEWAGE_WASTE_GREY",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 1,
          "maxDedicatedCapacity": 7.24,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": {
            "name": "Stockviksverken",
            "locationUnCode": "SESTK",
            "countryUnCode": "SE",
            "euPort": true
          }
        },
        {
          "type": "DIRTY_BALLASTWATER",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "WASHWATER_OIL",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "OILY_WASTE_FROM_CLEANING",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "OTHER_OILY_WASTE",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "CARGO_RESIDUES_OR_WASHWATER",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "DIRTY_BALLASTWATER_CHEMICALS",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "OTHER_CARGO_RESIDUES",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "WASHWATER_DRY_CARGO_HME",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "WASHWATER_DRY_CARGO_NON_HME",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "DRY_CARGO_RESIDUES_HME",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "DRY_CARGO_RESIDUES_NON_HME",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "OTHER_CARGO_RESIDUES_NON_TANKERS",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        }
      ]
    },
    "wasteCollections": [],
    "shipStoresDeclaration": {
      "items": {
        "SPIRITS": 20,
        "BEER": 39.6,
        "WINE": 0,
        "OTHER": 0,
        "CIGARETTES": 2000,
        "CIGARS": 0,
        "TOBACCO": 0.4,
        "FUEL_OIL": 65000,
        "LUBRICATING_OIL": 1200,
        "MEAT": 60,
        "NARCOTICS": 0,
        "FIRE_ARMS": 0
      }
    },
    "dangerousGoodsDeclarations": [],
    "nextDangerousGoodsDeclaration": null,
    "importDeclarations": [
      {
        "cargoDeclarant": {
          "fullName": "Wagenborg Agencies B.V.",
          "shortName": "WAGENBORG",
          "portAuthorityId": "92415",
          "emailAddress": "agency.amsterdam@wagenborg.com",
          "address": "Marktstraat 10",
          "city": "Delfzijl",
          "countryUnCode": "NL",
          "countryName": "Netherlands",
          "zipCode": "9934 CK",
          "phoneNumber": "0596636956",
          "faxNumber": null,
          "contact": "Peter Zweepe",
          "customsEORINumber": "NL001221401",
          "ean": null,
          "chamberOfCommerceNumber": "02304700",
          "scacCode": null,
          "ensEnabled": true,
          "cargoImportEnabled": true,
          "cargoImportEnabledByDefault": true
        },
        "timestamp": "2021-08-29T09:20:54.726Z",
        "containers": [],
        "consignments": [
          {
            "consignmentNumber": "21WBA13475",
            "portOfLoading": {
              "name": "Delfzijl",
              "locationUnCode": "NLDZL",
              "countryUnCode": "NL",
              "euPort": true
            },
            "portOfDischarge": {
              "name": "Delfzijl",
              "locationUnCode": "NLDZL",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-08-25T10:00:00Z",
            "terminal": {
              "terminalName": "DZL-HANDELSKADE OOST",
              "terminalCode": "DHH80",
              "organisationShortName": null,
              "organisationName": null
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": "EU_COMMUNITY_GOODS",
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "Nouryon Industrial chemicals bv",
              "address": "Oosterhorn 4",
              "zipCode": "9936HD",
              "city": "Delfzijl",
              "countryCode": "NL",
              "authorised": null,
              "id": "482d9269-aad4-49b1-9403-cee83bf17de4",
              "ownerShortName": "WAGENBORG"
            },
            "consignee": null,
            "partiesToNotify": [
              {
                "eoriNumber": null,
                "name": "Nouryon Industrial chemicals bv",
                "address": "Oosterhorn 4",
                "zipCode": "9936HD",
                "city": "Delfzijl",
                "countryCode": "NL",
                "authorised": null,
                "id": "482d9269-aad4-49b1-9403-cee83bf17de4",
                "ownerShortName": "WAGENBORG"
              }
            ],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": null,
            "onCarriageDetails": null,
            "agentVoyageNumber": null,
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "Salt in bulk",
                "grossWeight": 3441000,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "VO",
                  "name": "Bulk, solid, large particles",
                  "bulk": true
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [],
                "producedDocuments": []
              }
            ]
          }
        ],
        "receivedMrns": {},
        "discharges": [],
        "bulkDischarges": [],
        "consignmentDifferences": [],
        "clearedManifestIds": [],
        "inspectionUpdates": [],
        "declarations": [
          {
            "type": "SDT",
            "id": "NLDZL",
            "status": "ACCEPTED",
            "rejectReasons": null,
            "timeStamp": "2021-08-29T09:03:39.145Z"
          },
          {
            "type": "SDT",
            "id": "NLDZL",
            "status": "ACCEPTED",
            "rejectReasons": null,
            "timeStamp": "2021-08-29T09:20:18.674Z"
          }
        ],
        "incomingMessages": []
      }
    ],
    "transitDeclarationMap": {},
    "loadingDeclarations": [],
    "paxDeclarationSummaries": [
      {
        "numberOfCrew": 8,
        "numberOfPassengers": 0,
        "numberOfCrewOtherShip": 0,
        "numberOfStowaways": 0,
        "searchAndRescue": null
      }
    ],
    "healthDeclarationSummary": null,
    "etaPortAis": null,
    "ignoreEtaPortAis": false,
    "cancelled": false,
    "ataRegisteredByPa": true,
    "visitKnownAtPa": true,
    "dangerousGoodsEnabled": true,
    "cargoImportEnabled": true,
    "ataAcknowledged": true,
    "declarations": [
      {
        "type": "VISIT",
        "id": null,
        "status": "ACCEPTED",
        "rejectReasons": null,
        "timeStamp": "2021-08-29T08:56:36.996Z"
      },
      {
        "type": "WPCS",
        "id": null,
        "status": "DECLARED",
        "rejectReasons": null,
        "timeStamp": "2021-08-29T08:56:58.625Z"
      },
      {
        "type": "STO",
        "id": null,
        "status": "ACCEPTED",
        "rejectReasons": null,
        "timeStamp": "2021-08-30T07:21:03.092Z"
      },
      {
        "type": "SECURITY",
        "id": null,
        "status": "ACCEPTED",
        "rejectReasons": null,
        "timeStamp": "2021-08-30T08:56:55.703Z"
      },
      {
        "type": "WPCS",
        "id": null,
        "status": "DECLARED",
        "rejectReasons": null,
        "timeStamp": "2021-08-30T08:56:55.819Z"
      },
      {
        "type": "PAX",
        "id": null,
        "status": "ACCEPTED",
        "rejectReasons": null,
        "timeStamp": "2021-08-30T08:57:04.821Z"
      },
      {
        "type": "WASTE",
        "id": null,
        "status": "ACCEPTED",
        "rejectReasons": null,
        "timeStamp": "2021-08-30T08:57:51.301Z"
      },
      {
        "type": "VISIT",
        "id": null,
        "status": "ACCEPTED",
        "rejectReasons": null,
        "timeStamp": "2021-08-31T18:17:11.822Z"
      },
      {
        "type": "WPCS",
        "id": null,
        "status": "DECLARED",
        "rejectReasons": null,
        "timeStamp": "2021-08-31T18:17:11.960Z"
      },
      {
        "type": "VISIT",
        "id": null,
        "status": "ACCEPTED",
        "rejectReasons": null,
        "timeStamp": "2021-09-01T06:28:22.451Z"
      },
      {
        "type": "WPCS",
        "id": null,
        "status": "DECLARED",
        "rejectReasons": null,
        "timeStamp": "2021-09-01T06:28:22.554Z"
      },
      {
        "type": "WPCS",
        "id": null,
        "status": "DECLARED",
        "rejectReasons": null,
        "timeStamp": "2021-09-01T18:40:19.189Z"
      },
      {
        "type": "VISIT",
        "id": null,
        "status": "ACCEPTED",
        "rejectReasons": null,
        "timeStamp": "2021-09-03T08:21:21.202Z"
      },
      {
        "type": "WPCS",
        "id": null,
        "status": "DECLARED",
        "rejectReasons": null,
        "timeStamp": "2021-09-03T08:21:21.377Z"
      },
      {
        "type": "VISIT",
        "id": null,
        "status": "ACCEPTED",
        "rejectReasons": null,
        "timeStamp": "2021-09-03T08:24:17.718Z"
      },
      {
        "type": "WPCS",
        "id": null,
        "status": "DECLARED",
        "rejectReasons": null,
        "timeStamp": "2021-09-03T08:24:17.843Z"
      },
      {
        "type": "VISIT",
        "id": null,
        "status": "ACCEPTED",
        "rejectReasons": null,
        "timeStamp": "2021-09-03T08:27:17.094Z"
      },
      {
        "type": "WPCS",
        "id": null,
        "status": "DECLARED",
        "rejectReasons": null,
        "timeStamp": "2021-09-03T08:27:17.207Z"
      },
      {
        "type": "WPCS",
        "id": null,
        "status": "DECLARED",
        "rejectReasons": null,
        "timeStamp": "2021-09-04T07:11:47.260Z"
      },
      {
        "type": "WPCS",
        "id": null,
        "status": "DECLARED",
        "rejectReasons": null,
        "timeStamp": "2021-09-04T09:05:21.845Z"
      },
      {
        "type": "VISIT",
        "id": null,
        "status": "ACCEPTED",
        "rejectReasons": null,
        "timeStamp": "2021-09-25T11:20:05.877Z"
      },
      {
        "type": "WPCS",
        "id": null,
        "status": "DECLARED",
        "rejectReasons": null,
        "timeStamp": "2021-09-25T11:20:05.976Z"
      },
      {
        "type": "VISIT",
        "id": null,
        "status": "ACCEPTED",
        "rejectReasons": null,
        "timeStamp": "2021-09-30T08:08:56.398Z"
      },
      {
        "type": "WPCS",
        "id": null,
        "status": "DECLARED",
        "rejectReasons": null,
        "timeStamp": "2021-09-30T08:08:56.539Z"
      }
    ],
    "uploads": {
      "STO": "1f67f310-4b17-480c-bd1c-5dc2875e04ce",
      "SECURITY": "55020085-3158-4617-b6c7-ddf849a3c468",
      "WASTE": "46d178ae-485d-4ce3-b15c-f523f4d88a27"
    },
    "additionalIncomingMovementInfo": null,
    "berthVisitInfos": {},
    "incomingMovementHarbourMasterInfo": null,
    "incomingTidalWindowStatus": null,
    "lastMessageId": "37925b5e-80a9-47df-bf5c-818b496a69b6",
    "created": "2021-08-29T08:53:36.190Z",
    "updated": "2021-09-30T08:09:17.861Z",
    "berthVisitIdOfTransfer": null,
    "hasBeenTransferred": false,
    "allowedViewers": [
      {
        "fullName": "Wagenborg Agencies B.V.",
        "shortName": "WAGENBORG",
        "portAuthorityId": "92415",
        "emailAddress": "agency.amsterdam@wagenborg.com",
        "address": "Marktstraat 10",
        "city": "Delfzijl",
        "countryUnCode": "NL",
        "countryName": "Netherlands",
        "zipCode": "9934 CK",
        "phoneNumber": "0596636956",
        "faxNumber": null,
        "contact": "Peter Zweepe",
        "customsEORINumber": "NL001221401",
        "ean": null,
        "chamberOfCommerceNumber": "02304700",
        "scacCode": null,
        "ensEnabled": true,
        "cargoImportEnabled": true,
        "cargoImportEnabledByDefault": true
      },
      {
        "fullName": "Groningen Seaports NV",
        "shortName": "GSP",
        "portAuthorityId": "000000",
        "emailAddress": "operations@groningen-seaports.com",
        "address": "Handelskade Oost 1",
        "city": "Delfzijl",
        "countryUnCode": "NL",
        "countryName": "Netherlands",
        "zipCode": "9930 PA",
        "phoneNumber": "0596-640400",
        "faxNumber": "0596-630464",
        "contact": "Dhr. Ronald Smits",
        "customsEORINumber": "NL000000050",
        "ean": null,
        "chamberOfCommerceNumber": null,
        "scacCode": null,
        "ensEnabled": false,
        "cargoImportEnabled": false,
        "cargoImportEnabledByDefault": false
      },
      {
        "fullName": "Wagenborg Agencies B.V.",
        "shortName": "WAGENBORG",
        "portAuthorityId": "92415",
        "emailAddress": "agency.amsterdam@wagenborg.com",
        "address": "Marktstraat 10",
        "city": "Delfzijl",
        "countryUnCode": "NL",
        "countryName": "Netherlands",
        "zipCode": "9934 CK",
        "phoneNumber": "0596636956",
        "faxNumber": null,
        "contact": "Maico Bakker",
        "customsEORINumber": "NL001221401",
        "ean": null,
        "chamberOfCommerceNumber": "02304700",
        "scacCode": null,
        "ensEnabled": true,
        "cargoImportEnabled": true,
        "cargoImportEnabledByDefault": true
      }
    ],
    "previousMessageId": "809b7937-e5b0-4bba-8ad9-3434dfe294e3",
    "nextMessageId": null,
    "transitDeclarations": [],
    "visitStatus": "ARRIVED"
  },
  NLHAR21000684: {
    "crn": "NLHAR21000684",
    "copyOfVisit": null,
    "portOfCall": {
      "port": {
        "name": "Harlingen",
        "locationUnCode": "NLHAR",
        "countryUnCode": "NL",
        "euPort": true
      },
      "portAuthority": {
        "fullName": "Havenbedrijf Harlingen",
        "shortName": "HBHHAR",
        "portAuthorityId": "NLHARHBH",
        "emailAddress": "d.klinkenberg@portofharlingen.nl",
        "address": "Waddenpromenade 9-3",
        "city": "Harlingen",
        "countryUnCode": "NL",
        "countryName": "Netherlands",
        "zipCode": "8861 NT",
        "phoneNumber": "0517-723333",
        "faxNumber": null,
        "contact": "Dirk Klinkenberg",
        "customsEORINumber": "NL001608836",
        "ean": null,
        "chamberOfCommerceNumber": null,
        "scacCode": null,
        "ensEnabled": false,
        "cargoImportEnabled": false,
        "cargoImportEnabledByDefault": false
      },
      "customsOffice": {
        "name": "Groningen/Kantoor Eemshaven",
        "unCode": "NL000282"
      },
      "ataReported": true,
      "atdReported": true,
      "atdPortReported": false,
      "paDeclarationRequired": true,
      "swDeclarationRequired": true,
      "wasteEnabled": true,
      "dangerousGoodsEnabled": true,
      "harbourDuesEnabled": false,
      "orderNauticalServicesEnabled": false,
      "enableNotificationsToPa": false,
      "authoriseOrganisationsDisabled": false,
      "tugBoatsRequired": true
    },
    "vessel": {
      "imoCode": "9364150",
      "name": "SINE BRES",
      "radioCallSign": "V2HC3",
      "motUnCode": "150X",
      "motName": "General cargo vessel",
      "summerDeadWeight": 3750,
      "maxWidth": 12.6,
      "flagCode": "ATG",
      "flagCountryUnCode": "AG",
      "netTonnage": 1195,
      "grossTonnage": 2658,
      "registrationPlaceUnloCode": "AGSJO",
      "registrationPlaceName": "Saint John's",
      "registrationDate": "2006-10-26",
      "mmsiNumber": "304225000",
      "fullLength": 87.9,
      "emailAddress": null
    },
    "owner": {
      "fullName": "Nesta Shipping",
      "shortName": "NESTA",
      "portAuthorityId": "NEST",
      "emailAddress": "nathan@nesta.nl",
      "address": "Korte Lijnbaan 13",
      "city": "Harlingen",
      "countryUnCode": "NL",
      "countryName": "Netherlands",
      "zipCode": "8861 NS",
      "phoneNumber": "+31-517-433302",
      "faxNumber": "+31-517-433031",
      "contact": "Nathan van der Pol",
      "customsEORINumber": "NL820831165",
      "ean": null,
      "chamberOfCommerceNumber": "01087782",
      "scacCode": null,
      "ensEnabled": false,
      "cargoImportEnabled": true,
      "cargoImportEnabledByDefault": true
    },
    "nextOwner": null,
    "declarant": {
      "fullName": "Nesta Shipping",
      "shortName": "NESTA",
      "portAuthorityId": "NEST",
      "emailAddress": "nathan@nesta.nl",
      "address": "Korte Lijnbaan 13",
      "city": "Harlingen",
      "countryUnCode": "NL",
      "countryName": "Netherlands",
      "zipCode": "8861 NS",
      "phoneNumber": "+31-517-433302",
      "faxNumber": "+31-517-433031",
      "contact": "Nathan van der Pol",
      "customsEORINumber": "NL820831165",
      "ean": null,
      "chamberOfCommerceNumber": "01087782",
      "scacCode": null,
      "ensEnabled": false,
      "cargoImportEnabled": true,
      "cargoImportEnabledByDefault": true
    },
    "nextDeclarant": null,
    "financialDeclarant": {
      "fullName": "Nesta Shipping",
      "shortName": "NESTA",
      "portAuthorityId": "NEST",
      "emailAddress": "nathan@nesta.nl",
      "address": "Korte Lijnbaan 13",
      "city": "Harlingen",
      "countryUnCode": "NL",
      "countryName": "Netherlands",
      "zipCode": "8861 NS",
      "phoneNumber": "+31-517-433302",
      "faxNumber": "+31-517-433031",
      "contact": "Nathan van der Pol",
      "customsEORINumber": "NL820831165",
      "ean": null,
      "chamberOfCommerceNumber": "01087782",
      "scacCode": null,
      "ensEnabled": false,
      "cargoImportEnabled": true,
      "cargoImportEnabledByDefault": true
    },
    "nominatedFinancialDeclarant": null,
    "orderNauticalServices": false,
    "orderIncomingMovement": false,
    "creditNumberHolderSameAsDeclarant": false,
    "declarantResponsibleForCustoms": false,
    "cargoDeclarants": [
      {
        "fullName": "Nesta Shipping",
        "shortName": "NESTA",
        "portAuthorityId": "NEST",
        "emailAddress": "nathan@nesta.nl",
        "address": "Korte Lijnbaan 13",
        "city": "Harlingen",
        "countryUnCode": "NL",
        "countryName": "Netherlands",
        "zipCode": "8861 NS",
        "phoneNumber": "+31-517-433302",
        "faxNumber": "+31-517-433031",
        "contact": "Nathan van der Pol",
        "customsEORINumber": "NL820831165",
        "ean": null,
        "chamberOfCommerceNumber": "01087782",
        "scacCode": null,
        "ensEnabled": false,
        "cargoImportEnabled": true,
        "cargoImportEnabledByDefault": true
      }
    ],
    "shipOperators": [
      {
        "fullName": "Nesta Shipping",
        "shortName": "NESTA",
        "portAuthorityId": "NEST",
        "emailAddress": "nathan@nesta.nl",
        "address": "Korte Lijnbaan 13",
        "city": "Harlingen",
        "countryUnCode": "NL",
        "countryName": "Netherlands",
        "zipCode": "8861 NS",
        "phoneNumber": "+31-517-433302",
        "faxNumber": "+31-517-433031",
        "contact": "Nathan van der Pol",
        "customsEORINumber": "NL820831165",
        "ean": null,
        "chamberOfCommerceNumber": "01087782",
        "scacCode": null,
        "ensEnabled": false,
        "cargoImportEnabled": true,
        "cargoImportEnabledByDefault": true
      },
      {
        "fullName": "Nesta Shipping",
        "shortName": "NESTA",
        "portAuthorityId": "NEST",
        "emailAddress": "nathan@nesta.nl",
        "address": "Korte Lijnbaan 13",
        "city": "Harlingen",
        "countryUnCode": "NL",
        "countryName": "Netherlands",
        "zipCode": "8861 NS",
        "phoneNumber": "+31-517-433302",
        "faxNumber": "+31-517-433031",
        "contact": "Nathan van der Pol",
        "customsEORINumber": "NL820831165",
        "ean": null,
        "chamberOfCommerceNumber": "01087782",
        "scacCode": null,
        "ensEnabled": false,
        "cargoImportEnabled": true,
        "cargoImportEnabledByDefault": true
      },
      {
        "fullName": "Havenbedrijf Harlingen",
        "shortName": "HBHHAR",
        "portAuthorityId": "NLHARHBH",
        "emailAddress": "d.klinkenberg@portofharlingen.nl",
        "address": "Waddenpromenade 9-3",
        "city": "Harlingen",
        "countryUnCode": "NL",
        "countryName": "Netherlands",
        "zipCode": "8861 NT",
        "phoneNumber": "0517-723333",
        "faxNumber": null,
        "contact": "Dirk Klinkenberg",
        "customsEORINumber": "NL001608836",
        "ean": null,
        "chamberOfCommerceNumber": null,
        "scacCode": null,
        "ensEnabled": false,
        "cargoImportEnabled": false,
        "cargoImportEnabledByDefault": false
      }
    ],
    "additionalViewers": [],
    "visitDeclaration": {
      "clientReferenceNumber": null,
      "vesselEmailAddress": null,
      "etaFirstEntryEu": null,
      "arrivalVoyage": {
        "voyageNumber": "Sine Bres",
        "carrier": {
          "customsId": "NL820831165",
          "name": null,
          "scacCode": null,
          "smdgCode": null,
          "cargoDeclarantShortName": null
        }
      },
      "previousPorts": [
        {
          "id": "87683699-db88-475e-b2e6-76ed7681e876",
          "arrival": "2021-09-14T10:00:00Z",
          "departure": "2021-09-15T10:00:00Z",
          "port": {
            "name": "Brest",
            "locationUnCode": "FRBES",
            "countryUnCode": "FR",
            "euPort": true
          },
          "portFacilityVisits": [
            {
              "portFacility": {
                "code": "FRBES-0002",
                "name": "Brest - réparation navale"
              },
              "arrivalDate": "2021-09-14",
              "departureDate": "2021-09-15",
              "securityLevel": "SL1",
              "additionalSecurityMeasures": "NO"
            }
          ]
        },
        {
          "id": "6a6c4f21-bccc-43ab-93f9-bd027a92e8b3",
          "arrival": "2021-09-07T10:00:00Z",
          "departure": "2021-09-10T10:00:00Z",
          "port": {
            "name": "Szczecin",
            "locationUnCode": "PLSZZ",
            "countryUnCode": "PL",
            "euPort": true
          },
          "portFacilityVisits": [
            {
              "portFacility": {
                "code": "PLSZZ-0003",
                "name": "BULK CARGO"
              },
              "arrivalDate": "2021-09-07",
              "departureDate": "2021-09-10",
              "securityLevel": "SL1",
              "additionalSecurityMeasures": "NO"
            }
          ]
        },
        {
          "id": "3a417dfc-b65e-4c34-ae72-03a90ca63b97",
          "arrival": "2021-09-05T10:00:00Z",
          "departure": "2021-09-06T10:00:00Z",
          "port": {
            "name": "Rostock",
            "locationUnCode": "DERSK",
            "countryUnCode": "DE",
            "euPort": true
          },
          "portFacilityVisits": [
            {
              "portFacility": {
                "code": "DERSK-0016",
                "name": "Rostock / Pier II-IV"
              },
              "arrivalDate": "2021-09-05",
              "departureDate": "2021-09-06",
              "securityLevel": "SL1",
              "additionalSecurityMeasures": "NO"
            }
          ]
        },
        {
          "id": "de04e886-dc1a-40c2-93fd-6255ff1774da",
          "arrival": "2021-09-02T10:00:00Z",
          "departure": "2021-09-03T10:00:00Z",
          "port": {
            "name": "Vlaardingen",
            "locationUnCode": "NLVLA",
            "countryUnCode": "NL",
            "euPort": true
          },
          "portFacilityVisits": [
            {
              "portFacility": {
                "code": "NLVLA-0001",
                "name": "Vlaardingen: Hooymeijer Port Terminals B.V. (126-1)"
              },
              "arrivalDate": "2021-09-02",
              "departureDate": "2021-09-03",
              "securityLevel": "SL1",
              "additionalSecurityMeasures": "NO"
            }
          ]
        },
        {
          "id": "b9cd716b-1a96-4095-9dcb-fc2b75ace2ce",
          "arrival": "2021-08-31T10:00:00Z",
          "departure": "2021-09-01T10:00:00Z",
          "port": {
            "name": "Dunkerque",
            "locationUnCode": "FRDKK",
            "countryUnCode": "FR",
            "euPort": true
          },
          "portFacilityVisits": [
            {
              "portFacility": {
                "code": "FRDKK-0023",
                "name": "Dunkerque - STE"
              },
              "arrivalDate": "2021-08-31",
              "departureDate": "2021-09-01",
              "securityLevel": "SL1",
              "additionalSecurityMeasures": "NO"
            }
          ]
        },
        {
          "id": "82557e09-d705-47ef-8145-59fa055f2dc5",
          "arrival": "2021-08-29T10:00:00Z",
          "departure": "2021-08-30T10:00:00Z",
          "port": {
            "name": "Vlissingen",
            "locationUnCode": "NLVLI",
            "countryUnCode": "NL",
            "euPort": true
          },
          "portFacilityVisits": [
            {
              "portFacility": {
                "code": "NLVLI-0029",
                "name": "Vlissingen: VZT"
              },
              "arrivalDate": "2021-08-29",
              "departureDate": "2021-08-30",
              "securityLevel": "SL1",
              "additionalSecurityMeasures": "NO"
            }
          ]
        },
        {
          "id": "51f874ee-27ad-4426-9fbc-9b566606847c",
          "arrival": "2021-08-17T10:00:00Z",
          "departure": "2021-08-20T10:00:00Z",
          "port": {
            "name": "Tarragona",
            "locationUnCode": "ESTAR",
            "countryUnCode": "ES",
            "euPort": true
          },
          "portFacilityVisits": [
            {
              "portFacility": {
                "code": "ESTAR-0005",
                "name": "TERMINAL DP World Tarragona S.A"
              },
              "arrivalDate": "2021-08-17",
              "departureDate": "2021-08-20",
              "securityLevel": "SL1",
              "additionalSecurityMeasures": "NO"
            }
          ]
        },
        {
          "id": "3028243c-3e97-408f-b9f3-2d7262f6c334",
          "arrival": "2021-08-16T10:00:00Z",
          "departure": "2021-08-16T10:00:00Z",
          "port": {
            "name": "Alicante",
            "locationUnCode": "ESALC",
            "countryUnCode": "ES",
            "euPort": true
          },
          "portFacilityVisits": [
            {
              "portFacility": {
                "code": "ESALC-0042",
                "name": "Terminal Multipropósito de TMS"
              },
              "arrivalDate": "2021-08-16",
              "departureDate": "2021-08-16",
              "securityLevel": "SL1",
              "additionalSecurityMeasures": "NO"
            }
          ]
        },
        {
          "id": "9798345b-77bf-4a22-8989-37dc0cb09e18",
          "arrival": "2021-08-14T10:00:00Z",
          "departure": "2021-08-15T10:00:00Z",
          "port": {
            "name": "Almería",
            "locationUnCode": "ESLEI",
            "countryUnCode": "ES",
            "euPort": true
          },
          "portFacilityVisits": [
            {
              "portFacility": {
                "code": "ESLEI-0001",
                "name": "Puerto de Almería"
              },
              "arrivalDate": "2021-08-14",
              "departureDate": "2021-08-15",
              "securityLevel": "SL1",
              "additionalSecurityMeasures": "NO"
            }
          ]
        },
        {
          "id": "4d4d9823-54e4-41db-97a6-2d135f9fae8f",
          "arrival": "2021-08-10T10:00:00Z",
          "departure": "2021-08-11T10:00:00Z",
          "port": {
            "name": "Agadir",
            "locationUnCode": "MAAGA",
            "countryUnCode": "MA",
            "euPort": false
          },
          "portFacilityVisits": [
            {
              "portFacility": {
                "code": "MAAGA-0001",
                "name": "PORT"
              },
              "arrivalDate": "2021-08-10",
              "departureDate": "2021-08-11",
              "securityLevel": "SL1",
              "additionalSecurityMeasures": "NO"
            }
          ]
        }
      ],
      "portVisit": {
        "pilotStation": null,
        "entryPoint": {
          "code": "BS",
          "name": "Blauwe Slenk",
          "atSea": null
        },
        "etaPort": "2021-09-19T05:00:00Z",
        "portEntry": null,
        "firstMovement": {
          "vesselDraft": 4,
          "vesselMasterName": "V. Znamenskiy",
          "numberOfCrew": 7,
          "numberOfPassengers": 0,
          "cargo": "BALLAST_CONDITION",
          "orderEmail": null,
          "orderSms": null,
          "pilotExemption": null,
          "offStandardBeam": null,
          "pilotService": {
            "required": true,
            "serviceProvider": {
              "name": "Nederlands Loodswezen B.V. North",
              "portAuthorityId": "LOODSNORTH"
            },
            "remarks": null
          },
          "order": null,
          "stormPilotageInformation": null
        },
        "passingThroughTugboats": null,
        "defectTypes": [],
        "defectTypeRemarks": null,
        "berthVisits": [
          {
            "id": "b33f9fb7-5bf6-4ff5-b9ad-19f4b85b7582",
            "berth": {
              "name": "HARLINGEN NIEUWE INDUSTRIEHAVEN KORTE LIJNBAANKADE",
              "shortName": null,
              "code": "612",
              "terminalCode": "00612",
              "terminalName": "HARLINGEN NIEUWE INDUSTRIEHAVEN KORTE LIJNBAANKADE",
              "organisationShortName": null,
              "organisationName": null,
              "berthGroupCode": "NIEUWE INDUSTRIEHAVEN LIJNBAANKADE",
              "buoy": false,
              "harbourDuesArea": false,
              "partyToNotify": null
            },
            "eta": "2021-09-19T06:00:00Z",
            "requestedEta": null,
            "ata": "2021-09-20T12:49:07Z",
            "etd": null,
            "requestedEtd": null,
            "atd": null,
            "mooring": "NO_PREFERENCE",
            "remarks": null,
            "visitPurposes": [
              "REPAIRS"
            ],
            "bollardFrom": null,
            "bollardTo": null,
            "tugboatAtArrival": {
              "required": false,
              "serviceProvider": {
                "name": "A. Tuinman & Zn.",
                "portAuthorityId": "TUINTOW"
              },
              "requiredQuantity": null,
              "remarks": null
            },
            "boatmenAtArrival": {
              "required": false,
              "serviceProvider": null,
              "remarks": null
            },
            "boatmenAtDeparture": {
              "required": false,
              "serviceProvider": null,
              "remarks": null
            },
            "tugboatAtDeparture": {
              "required": false,
              "serviceProvider": {
                "name": "A. Tuinman & Zn.",
                "portAuthorityId": "TUINTOW"
              },
              "requiredQuantity": null,
              "remarks": null
            },
            "nextMovement": {
              "vesselDraft": 4,
              "vesselMasterName": "V. Znamenskiy",
              "numberOfCrew": 7,
              "numberOfPassengers": 0,
              "cargo": "BALLAST_CONDITION",
              "orderEmail": null,
              "orderSms": null,
              "pilotExemption": null,
              "offStandardBeam": null,
              "pilotService": {
                "required": true,
                "serviceProvider": {
                  "name": "Nederlands Loodswezen B.V. North",
                  "portAuthorityId": "LOODSNORTH"
                },
                "remarks": null
              },
              "order": null,
              "stormPilotageInformation": null
            }
          },
          {
            "id": "132da058-b6ab-483b-b864-4b4fdbbaf881",
            "berth": {
              "name": "HARLINGEN INDUSTRIEHAVEN WERF SCHEPENLIFT",
              "shortName": null,
              "code": "630",
              "terminalCode": "00630",
              "terminalName": "HARLINGEN INDUSTRIEHAVEN WERF SCHEPENLIFT",
              "organisationShortName": null,
              "organisationName": null,
              "berthGroupCode": "HARLINGEN INDUSTRIEHAVEN WERF DROOG",
              "buoy": false,
              "harbourDuesArea": false,
              "partyToNotify": null
            },
            "eta": null,
            "requestedEta": null,
            "ata": "2021-09-29T04:08:29Z",
            "etd": null,
            "requestedEtd": null,
            "atd": null,
            "mooring": "NO_PREFERENCE",
            "remarks": null,
            "visitPurposes": [
              "REPAIRS"
            ],
            "bollardFrom": null,
            "bollardTo": null,
            "tugboatAtArrival": {
              "required": false,
              "serviceProvider": {
                "name": "A. Tuinman & Zn.",
                "portAuthorityId": "TUINTOW"
              },
              "requiredQuantity": null,
              "remarks": null
            },
            "boatmenAtArrival": {
              "required": false,
              "serviceProvider": null,
              "remarks": null
            },
            "boatmenAtDeparture": {
              "required": false,
              "serviceProvider": null,
              "remarks": null
            },
            "tugboatAtDeparture": {
              "required": false,
              "serviceProvider": {
                "name": "A. Tuinman & Zn.",
                "portAuthorityId": "TUINTOW"
              },
              "requiredQuantity": null,
              "remarks": null
            },
            "nextMovement": {
              "vesselDraft": 4,
              "vesselMasterName": "V. Znamenskiy",
              "numberOfCrew": 7,
              "numberOfPassengers": 0,
              "cargo": "BALLAST_CONDITION",
              "orderEmail": null,
              "orderSms": null,
              "pilotExemption": null,
              "offStandardBeam": null,
              "pilotService": {
                "required": true,
                "serviceProvider": {
                  "name": "Nederlands Loodswezen B.V. North",
                  "portAuthorityId": "LOODSNORTH"
                },
                "remarks": null
              },
              "order": null,
              "stormPilotageInformation": null
            }
          }
        ],
        "dangerousGoodsLoading": false,
        "dangerousGoodsDischarge": false,
        "dangerousGoodsTransit": false,
        "vesselInspectionRequired": false,
        "shipConfiguration": null,
        "exitPoint": {
          "code": "BS",
          "name": "Blauwe Slenk",
          "atSea": null
        },
        "etdPort": "2021-09-30T10:00:00Z",
        "atdPort": null,
        "ataPort": "2021-09-20T12:49:07Z"
      },
      "departureVoyage": {
        "voyageNumber": "Sine Bres",
        "carrier": {
          "customsId": "NL820831165",
          "name": null,
          "scacCode": null,
          "smdgCode": null,
          "cargoDeclarantShortName": null
        }
      },
      "nextPorts": [
        {
          "id": "5887ff12-e79b-4fc8-ab73-de385d4aed0d",
          "arrival": "2021-09-30T14:00:00Z",
          "departure": null,
          "port": {
            "name": "North Sea",
            "locationUnCode": "XZZNO",
            "countryUnCode": "NL",
            "euPort": true
          },
          "customsOffice": null
        }
      ]
    },
    "nextVisitDeclaration": null,
    "securityDeclaration": {
      "securityReportRequired": "YES",
      "companySecurityOfficer": {
        "name": "Mr. Andre Ludwig",
        "email": "a.ludwig@bremer-lloyd.com",
        "phoneNumber": "494213392811"
      },
      "currentSecurityLevel": "SL1",
      "approvedSspDocumentOnBoard": true,
      "isscSecurityDocument": {
        "availability": "YES",
        "expiryDate": "2023-09-20",
        "reasonNoValidIsscOnBoard": null,
        "issuer": {
          "code": "20",
          "name": "Lloyd's Register"
        }
      },
      "shipToShipActivities": [],
      "securityRelatedMatter": null
    },
    "wasteDeclaration": {
      "portOfLastDelivery": {
        "name": "Brest",
        "locationUnCode": "FRBES",
        "countryUnCode": "FR",
        "euPort": true
      },
      "lastDeliveryDate": "2021-09-15",
      "wasteItems": [
        {
          "type": "SLUDGE",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0.15,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0.65,
          "maxDedicatedCapacity": 6.4,
          "quantityToBeGenerated": 0.05,
          "portForRetainedWaste": {
            "name": "Karlstad",
            "locationUnCode": "SEKSD",
            "countryUnCode": "SE",
            "euPort": true
          }
        },
        {
          "type": "FUEL_REMNANTS",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "BILGE_WATER",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "USED_ENGINE_OIL",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "FOOD_WASTE",
          "berthIdForPickup": "b33f9fb7-5bf6-4ff5-b9ad-19f4b85b7582",
          "collector": {
            "fullName": "Visser Recycling",
            "shortName": "VISSERREC",
            "portAuthorityId": "VISSER",
            "emailAddress": "notificaties@portbase.com",
            "address": "Kelvinstraat 30",
            "city": "Harlingen",
            "countryUnCode": "NL",
            "countryName": "Netherlands",
            "zipCode": "8861 ND",
            "phoneNumber": "+31-517-418552",
            "faxNumber": "+31-517-418552",
            "contact": "Service Desk",
            "customsEORINumber": null,
            "ean": null,
            "chamberOfCommerceNumber": null,
            "scacCode": null,
            "ensEnabled": false,
            "cargoImportEnabled": false,
            "cargoImportEnabledByDefault": false
          },
          "specification": null,
          "quantityOfLastDelivery": 0.02,
          "quantityToBeDelivered": 0.05,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "PLASTIC_CLEAN",
          "berthIdForPickup": "b33f9fb7-5bf6-4ff5-b9ad-19f4b85b7582",
          "collector": {
            "fullName": "Visser Recycling",
            "shortName": "VISSERREC",
            "portAuthorityId": "VISSER",
            "emailAddress": "notificaties@portbase.com",
            "address": "Kelvinstraat 30",
            "city": "Harlingen",
            "countryUnCode": "NL",
            "countryName": "Netherlands",
            "zipCode": "8861 ND",
            "phoneNumber": "+31-517-418552",
            "faxNumber": "+31-517-418552",
            "contact": "Service Desk",
            "customsEORINumber": null,
            "ean": null,
            "chamberOfCommerceNumber": null,
            "scacCode": null,
            "ensEnabled": false,
            "cargoImportEnabled": false,
            "cargoImportEnabledByDefault": false
          },
          "specification": null,
          "quantityOfLastDelivery": 0.1,
          "quantityToBeDelivered": 0.24,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "PLASTIC_CONTAMINATED",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "SMALL_DANGEROUS_WASTE",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "OTHER_DOMESTIC_WASTE",
          "berthIdForPickup": "b33f9fb7-5bf6-4ff5-b9ad-19f4b85b7582",
          "collector": {
            "fullName": "Visser Recycling",
            "shortName": "VISSERREC",
            "portAuthorityId": "VISSER",
            "emailAddress": "notificaties@portbase.com",
            "address": "Kelvinstraat 30",
            "city": "Harlingen",
            "countryUnCode": "NL",
            "countryName": "Netherlands",
            "zipCode": "8861 ND",
            "phoneNumber": "+31-517-418552",
            "faxNumber": "+31-517-418552",
            "contact": "Service Desk",
            "customsEORINumber": null,
            "ean": null,
            "chamberOfCommerceNumber": null,
            "scacCode": null,
            "ensEnabled": false,
            "cargoImportEnabled": false,
            "cargoImportEnabledByDefault": false
          },
          "specification": "X",
          "quantityOfLastDelivery": 0.2,
          "quantityToBeDelivered": 0.24,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "INTERNATIONAL_CATERING_WASTE",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "PAPER_OR_CARTON",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "RAGS",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "GLASS",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "METAL_OR_STEELWIRES",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "BOTTLES",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "CROCKERY",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "INCINERATOR_ASHES",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "ANIMAL_CARCASSES",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "EWASTE_WHOLE_DEVICES",
          "berthIdForPickup": "b33f9fb7-5bf6-4ff5-b9ad-19f4b85b7582",
          "collector": {
            "fullName": "Visser Recycling",
            "shortName": "VISSERREC",
            "portAuthorityId": "VISSER",
            "emailAddress": "notificaties@portbase.com",
            "address": "Kelvinstraat 30",
            "city": "Harlingen",
            "countryUnCode": "NL",
            "countryName": "Netherlands",
            "zipCode": "8861 ND",
            "phoneNumber": "+31-517-418552",
            "faxNumber": "+31-517-418552",
            "contact": "Service Desk",
            "customsEORINumber": null,
            "ean": null,
            "chamberOfCommerceNumber": null,
            "scacCode": null,
            "ensEnabled": false,
            "cargoImportEnabled": false,
            "cargoImportEnabledByDefault": false
          },
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0.3,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "EWASTE_DEVICES",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "PYROTECHNICS",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "BATTERIES",
          "berthIdForPickup": "b33f9fb7-5bf6-4ff5-b9ad-19f4b85b7582",
          "collector": {
            "fullName": "Visser Recycling",
            "shortName": "VISSERREC",
            "portAuthorityId": "VISSER",
            "emailAddress": "notificaties@portbase.com",
            "address": "Kelvinstraat 30",
            "city": "Harlingen",
            "countryUnCode": "NL",
            "countryName": "Netherlands",
            "zipCode": "8861 ND",
            "phoneNumber": "+31-517-418552",
            "faxNumber": "+31-517-418552",
            "contact": "Service Desk",
            "customsEORINumber": null,
            "ean": null,
            "chamberOfCommerceNumber": null,
            "scacCode": null,
            "ensEnabled": false,
            "cargoImportEnabled": false,
            "cargoImportEnabledByDefault": false
          },
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0.001,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "FIRE_EXTINGUISHERS",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "CAR_TYRES_OR_RUBBER",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "COOLING_LIQUIDS",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "PROPELLER_SHAFT_GREASE",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "FUMIGATION_PRODUCTS",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "FILTER_OR_RAGS_BAGS",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "WOOD",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "OIL_CONTAMINATED_DRUMS",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "FISHING_GEAR",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "COOKING_OIL",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "OPERATIONAL_WASTE",
          "berthIdForPickup": "b33f9fb7-5bf6-4ff5-b9ad-19f4b85b7582",
          "collector": {
            "fullName": "Visser Recycling",
            "shortName": "VISSERREC",
            "portAuthorityId": "VISSER",
            "emailAddress": "notificaties@portbase.com",
            "address": "Kelvinstraat 30",
            "city": "Harlingen",
            "countryUnCode": "NL",
            "countryName": "Netherlands",
            "zipCode": "8861 ND",
            "phoneNumber": "+31-517-418552",
            "faxNumber": "+31-517-418552",
            "contact": "Service Desk",
            "customsEORINumber": null,
            "ean": null,
            "chamberOfCommerceNumber": null,
            "scacCode": null,
            "ensEnabled": false,
            "cargoImportEnabled": false,
            "cargoImportEnabledByDefault": false
          },
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0.05,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "SEWAGE_WASTE_BLACK",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "SEWAGE_WASTE_GREY",
          "berthIdForPickup": "b33f9fb7-5bf6-4ff5-b9ad-19f4b85b7582",
          "collector": {
            "fullName": "Visser Recycling",
            "shortName": "VISSERREC",
            "portAuthorityId": "VISSER",
            "emailAddress": "notificaties@portbase.com",
            "address": "Kelvinstraat 30",
            "city": "Harlingen",
            "countryUnCode": "NL",
            "countryName": "Netherlands",
            "zipCode": "8861 ND",
            "phoneNumber": "+31-517-418552",
            "faxNumber": "+31-517-418552",
            "contact": "Service Desk",
            "customsEORINumber": null,
            "ean": null,
            "chamberOfCommerceNumber": null,
            "scacCode": null,
            "ensEnabled": false,
            "cargoImportEnabled": false,
            "cargoImportEnabledByDefault": false
          },
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 1,
          "quantityToBeRetained": 1,
          "maxDedicatedCapacity": 5.1,
          "quantityToBeGenerated": 1,
          "portForRetainedWaste": {
            "name": "North Sea",
            "locationUnCode": "XZZNO",
            "countryUnCode": "NL",
            "euPort": true
          }
        },
        {
          "type": "DIRTY_BALLASTWATER",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "WASHWATER_OIL",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "OILY_WASTE_FROM_CLEANING",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "OTHER_OILY_WASTE",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "CARGO_RESIDUES_OR_WASHWATER",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "DIRTY_BALLASTWATER_CHEMICALS",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "OTHER_CARGO_RESIDUES",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "WASHWATER_DRY_CARGO_HME",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "WASHWATER_DRY_CARGO_NON_HME",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "DRY_CARGO_RESIDUES_HME",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "DRY_CARGO_RESIDUES_NON_HME",
          "berthIdForPickup": "b33f9fb7-5bf6-4ff5-b9ad-19f4b85b7582",
          "collector": {
            "fullName": "Visser Recycling",
            "shortName": "VISSERREC",
            "portAuthorityId": "VISSER",
            "emailAddress": "notificaties@portbase.com",
            "address": "Kelvinstraat 30",
            "city": "Harlingen",
            "countryUnCode": "NL",
            "countryName": "Netherlands",
            "zipCode": "8861 ND",
            "phoneNumber": "+31-517-418552",
            "faxNumber": "+31-517-418552",
            "contact": "Service Desk",
            "customsEORINumber": null,
            "ean": null,
            "chamberOfCommerceNumber": null,
            "scacCode": null,
            "ensEnabled": false,
            "cargoImportEnabled": false,
            "cargoImportEnabledByDefault": false
          },
          "specification": "X",
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0.1,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "OTHER_CARGO_RESIDUES_NON_TANKERS",
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        }
      ]
    },
    "wasteCollections": [],
    "shipStoresDeclaration": {
      "items": {
        "SPIRITS": 2,
        "BEER": 23.76,
        "WINE": 4.5,
        "OTHER": 0,
        "CIGARETTES": 3800,
        "CIGARS": 0,
        "TOBACCO": 0,
        "FUEL_OIL": 29681,
        "LUBRICATING_OIL": 1750,
        "MEAT": 80,
        "NARCOTICS": 0,
        "FIRE_ARMS": 0
      }
    },
    "dangerousGoodsDeclarations": [],
    "nextDangerousGoodsDeclaration": null,
    "importDeclarations": [],
    "transitDeclarationMap": {},
    "loadingDeclarations": [],
    "paxDeclarationSummaries": [
      {
        "numberOfCrew": 7,
        "numberOfPassengers": 0,
        "numberOfCrewOtherShip": 0,
        "numberOfStowaways": 0,
        "searchAndRescue": null
      }
    ],
    "healthDeclarationSummary": null,
    "etaPortAis": null,
    "ignoreEtaPortAis": false,
    "cancelled": false,
    "ataRegisteredByPa": true,
    "visitKnownAtPa": true,
    "dangerousGoodsEnabled": true,
    "cargoImportEnabled": true,
    "ataAcknowledged": true,
    "declarations": [
      {
        "type": "VISIT",
        "id": null,
        "status": "ACCEPTED",
        "rejectReasons": null,
        "timeStamp": "2021-09-17T09:11:41.148Z"
      },
      {
        "type": "WPCS",
        "id": null,
        "status": "DECLARED",
        "rejectReasons": null,
        "timeStamp": "2021-09-17T09:33:09.899Z"
      },
      {
        "type": "SECURITY",
        "id": null,
        "status": "ACCEPTED",
        "rejectReasons": null,
        "timeStamp": "2021-09-17T09:52:59.639Z"
      },
      {
        "type": "WPCS",
        "id": null,
        "status": "DECLARED",
        "rejectReasons": null,
        "timeStamp": "2021-09-17T09:52:59.835Z"
      },
      {
        "type": "PAX",
        "id": null,
        "status": "ACCEPTED",
        "rejectReasons": null,
        "timeStamp": "2021-09-17T09:53:04.925Z"
      },
      {
        "type": "WASTE",
        "id": null,
        "status": "ACCEPTED",
        "rejectReasons": null,
        "timeStamp": "2021-09-17T09:53:29.483Z"
      },
      {
        "type": "STO",
        "id": null,
        "status": "ACCEPTED",
        "rejectReasons": null,
        "timeStamp": "2021-09-17T09:54:11.454Z"
      },
      {
        "type": "WPCS",
        "id": null,
        "status": "DECLARED",
        "rejectReasons": null,
        "timeStamp": "2021-09-19T05:41:40.724Z"
      },
      {
        "type": "WPCS",
        "id": null,
        "status": "DECLARED",
        "rejectReasons": null,
        "timeStamp": "2021-09-20T12:50:03.508Z"
      },
      {
        "type": "VISIT",
        "id": null,
        "status": "ACCEPTED",
        "rejectReasons": null,
        "timeStamp": "2021-09-20T14:25:25.644Z"
      },
      {
        "type": "WPCS",
        "id": null,
        "status": "DECLARED",
        "rejectReasons": null,
        "timeStamp": "2021-09-20T14:25:25.823Z"
      },
      {
        "type": "WPCS",
        "id": null,
        "status": "DECLARED",
        "rejectReasons": null,
        "timeStamp": "2021-09-21T13:26:20.507Z"
      },
      {
        "type": "WPCS",
        "id": null,
        "status": "DECLARED",
        "rejectReasons": null,
        "timeStamp": "2021-09-22T12:18:57.433Z"
      },
      {
        "type": "WPCS",
        "id": null,
        "status": "DECLARED",
        "rejectReasons": null,
        "timeStamp": "2021-09-29T04:09:08.096Z"
      }
    ],
    "uploads": {
      "SECURITY": "8e5cd15e-254b-4860-8f4b-f4015d9beda8",
      "WASTE": "5e663e6e-1e8d-4024-8e3d-21674f81646c",
      "STO": "a2dda350-20a9-4688-aca5-683d57985395"
    },
    "additionalIncomingMovementInfo": null,
    "berthVisitInfos": {},
    "incomingMovementHarbourMasterInfo": null,
    "incomingTidalWindowStatus": null,
    "lastMessageId": "11fe5a3f-759c-430f-b5a9-60110c0854d8",
    "created": "2021-09-17T08:45:57.393Z",
    "updated": "2021-09-29T04:09:08.096Z",
    "berthVisitIdOfTransfer": null,
    "hasBeenTransferred": false,
    "visitStatus": "ARRIVED",
    "previousMessageId": "f748c7c6-0516-4da8-a457-b733b7d4fe63",
    "nextMessageId": null,
    "allowedViewers": [
      {
        "fullName": "Havenbedrijf Harlingen",
        "shortName": "HBHHAR",
        "portAuthorityId": "NLHARHBH",
        "emailAddress": "d.klinkenberg@portofharlingen.nl",
        "address": "Waddenpromenade 9-3",
        "city": "Harlingen",
        "countryUnCode": "NL",
        "countryName": "Netherlands",
        "zipCode": "8861 NT",
        "phoneNumber": "0517-723333",
        "faxNumber": null,
        "contact": "Dirk Klinkenberg",
        "customsEORINumber": "NL001608836",
        "ean": null,
        "chamberOfCommerceNumber": null,
        "scacCode": null,
        "ensEnabled": false,
        "cargoImportEnabled": false,
        "cargoImportEnabledByDefault": false
      },
      {
        "fullName": "Nesta Shipping",
        "shortName": "NESTA",
        "portAuthorityId": "NEST",
        "emailAddress": "nathan@nesta.nl",
        "address": "Korte Lijnbaan 13",
        "city": "Harlingen",
        "countryUnCode": "NL",
        "countryName": "Netherlands",
        "zipCode": "8861 NS",
        "phoneNumber": "+31-517-433302",
        "faxNumber": "+31-517-433031",
        "contact": "Nathan van der Pol",
        "customsEORINumber": "NL820831165",
        "ean": null,
        "chamberOfCommerceNumber": "01087782",
        "scacCode": null,
        "ensEnabled": false,
        "cargoImportEnabled": true,
        "cargoImportEnabledByDefault": true
      }
    ],
    "transitDeclarations": []
  },
  NLWTE21000010: {
    "crn": "NLWTE21000010",
    "copyOfVisit": "NLWTE21000009",
    "portOfCall": {
      "port": {"name": "West-Terschelling", "locationUnCode": "NLWTE", "countryUnCode": "NL", "euPort": true},
      "portAuthority": {
        "fullName": "Havenbedrijf Harlingen",
        "shortName": "HBHHAR",
        "portAuthorityId": "NLHARHBH",
        "emailAddress": "d.klinkenberg@portofharlingen.nl",
        "address": "Waddenpromenade 9-3",
        "city": "Harlingen",
        "countryUnCode": "NL",
        "countryName": "Netherlands",
        "zipCode": "8861 NT",
        "phoneNumber": "0517-723333",
        "faxNumber": null,
        "contact": "Dirk Klinkenberg",
        "customsEORINumber": "NL001608836",
        "ean": null,
        "chamberOfCommerceNumber": null,
        "scacCode": null,
        "ensEnabled": false,
        "cargoImportEnabled": false,
        "cargoImportEnabledByDefault": false
      },
      "customsOffice": {"name": "Groningen/Kantoor Eemshaven", "unCode": "NL000282"},
      "ataReported": false,
      "atdReported": false,
      "atdPortReported": false,
      "paDeclarationRequired": true,
      "swDeclarationRequired": true,
      "wasteEnabled": true,
      "dangerousGoodsEnabled": true,
      "harbourDuesEnabled": false,
      "orderNauticalServicesEnabled": false,
      "enableNotificationsToPa": false,
      "authoriseOrganisationsDisabled": false,
      "tugBoatsRequired": false
    },
    "vessel": {
      "imoCode": "7904827",
      "name": "FRIENDSHIP",
      "radioCallSign": "HO5089",
      "motUnCode": "1603",
      "motName": "Salvage vessel",
      "summerDeadWeight": 180,
      "maxWidth": 9.02,
      "flagCode": "PAN",
      "flagCountryUnCode": "PA",
      "netTonnage": 141,
      "grossTonnage": 383,
      "registrationPlaceUnloCode": "PAPTY",
      "registrationPlaceName": "Panamá, Ciudad de",
      "registrationDate": "1980-04-25",
      "mmsiNumber": "372906000",
      "fullLength": 39.81,
      "emailAddress": null
    },
    "owner": {
      "fullName": "Kuhlman Repko Shipping B.V.",
      "shortName": "KUHLMAN",
      "portAuthorityId": "KUHL",
      "emailAddress": "MARTIN@KRSHIPPING.NL",
      "address": "Nieuwe Vissershaven 7",
      "city": "Harlingen",
      "countryUnCode": "NL",
      "countryName": "Netherlands",
      "zipCode": "8861 NX",
      "phoneNumber": "+31-517-412831",
      "faxNumber": null,
      "contact": "Martin de Boer",
      "customsEORINumber": "NL813141692",
      "ean": null,
      "chamberOfCommerceNumber": "01103873",
      "scacCode": null,
      "ensEnabled": false,
      "cargoImportEnabled": true,
      "cargoImportEnabledByDefault": true
    },
    "nextOwner": null,
    "declarant": {
      "fullName": "Kuhlman Repko Shipping B.V.",
      "shortName": "KUHLMAN",
      "portAuthorityId": "KUHL",
      "emailAddress": "MARTIN@KRSHIPPING.NL",
      "address": "Nieuwe Vissershaven 7",
      "city": "Harlingen",
      "countryUnCode": "NL",
      "countryName": "Netherlands",
      "zipCode": "8861 NX",
      "phoneNumber": "+31-517-412831",
      "faxNumber": null,
      "contact": "Martin de Boer",
      "customsEORINumber": "NL813141692",
      "ean": null,
      "chamberOfCommerceNumber": "01103873",
      "scacCode": null,
      "ensEnabled": false,
      "cargoImportEnabled": true,
      "cargoImportEnabledByDefault": true
    },
    "nextDeclarant": null,
    "financialDeclarant": {
      "fullName": "Kuhlman Repko Shipping B.V.",
      "shortName": "KUHLMAN",
      "portAuthorityId": "KUHL",
      "emailAddress": "MARTIN@KRSHIPPING.NL",
      "address": "Nieuwe Vissershaven 7",
      "city": "Harlingen",
      "countryUnCode": "NL",
      "countryName": "Netherlands",
      "zipCode": "8861 NX",
      "phoneNumber": "+31-517-412831",
      "faxNumber": null,
      "contact": "Martin de Boer",
      "customsEORINumber": "NL813141692",
      "ean": null,
      "chamberOfCommerceNumber": "01103873",
      "scacCode": null,
      "ensEnabled": false,
      "cargoImportEnabled": true,
      "cargoImportEnabledByDefault": true
    },
    "nominatedFinancialDeclarant": null,
    "orderNauticalServices": false,
    "orderIncomingMovement": false,
    "creditNumberHolderSameAsDeclarant": false,
    "declarantResponsibleForCustoms": false,
    "cargoDeclarants": [{
      "fullName": "Kuhlman Repko Shipping B.V.",
      "shortName": "KUHLMAN",
      "portAuthorityId": "KUHL",
      "emailAddress": "erno@krshipping.nl",
      "address": "Nieuwe Vissershaven 7",
      "city": "Harlingen",
      "countryUnCode": "NL",
      "countryName": "Netherlands",
      "zipCode": "8861 NX",
      "phoneNumber": "+31-517-412831",
      "faxNumber": null,
      "contact": "De heer Erno Leguijt",
      "customsEORINumber": "NL813141692",
      "ean": null,
      "chamberOfCommerceNumber": "01103873",
      "scacCode": null,
      "ensEnabled": false,
      "cargoImportEnabled": true,
      "cargoImportEnabledByDefault": true
    }],
    "shipOperators": [{
      "fullName": "Kuhlman Repko Shipping B.V.",
      "shortName": "KUHLMAN",
      "portAuthorityId": "KUHL",
      "emailAddress": "MARTIN@KRSHIPPING.NL",
      "address": "Nieuwe Vissershaven 7",
      "city": "Harlingen",
      "countryUnCode": "NL",
      "countryName": "Netherlands",
      "zipCode": "8861 NX",
      "phoneNumber": "+31-517-412831",
      "faxNumber": null,
      "contact": "Martin de Boer",
      "customsEORINumber": "NL813141692",
      "ean": null,
      "chamberOfCommerceNumber": "01103873",
      "scacCode": null,
      "ensEnabled": false,
      "cargoImportEnabled": true,
      "cargoImportEnabledByDefault": true
    }, {
      "fullName": "Kuhlman Repko Shipping B.V.",
      "shortName": "KUHLMAN",
      "portAuthorityId": "KUHL",
      "emailAddress": "MARTIN@KRSHIPPING.NL",
      "address": "Nieuwe Vissershaven 7",
      "city": "Harlingen",
      "countryUnCode": "NL",
      "countryName": "Netherlands",
      "zipCode": "8861 NX",
      "phoneNumber": "+31-517-412831",
      "faxNumber": null,
      "contact": "Martin de Boer",
      "customsEORINumber": "NL813141692",
      "ean": null,
      "chamberOfCommerceNumber": "01103873",
      "scacCode": null,
      "ensEnabled": false,
      "cargoImportEnabled": true,
      "cargoImportEnabledByDefault": true
    }, {
      "fullName": "Havenbedrijf Harlingen",
      "shortName": "HBHHAR",
      "portAuthorityId": "NLHARHBH",
      "emailAddress": "d.klinkenberg@portofharlingen.nl",
      "address": "Waddenpromenade 9-3",
      "city": "Harlingen",
      "countryUnCode": "NL",
      "countryName": "Netherlands",
      "zipCode": "8861 NT",
      "phoneNumber": "0517-723333",
      "faxNumber": null,
      "contact": "Dirk Klinkenberg",
      "customsEORINumber": "NL001608836",
      "ean": null,
      "chamberOfCommerceNumber": null,
      "scacCode": null,
      "ensEnabled": false,
      "cargoImportEnabled": false,
      "cargoImportEnabledByDefault": false
    }],
    "additionalViewers": [],
    "visitDeclaration": {
      "clientReferenceNumber": "Reis 357",
      "vesselEmailAddress": null,
      "etaFirstEntryEu": null,
      "arrivalVoyage": {
        "voyageNumber": "Reis 357",
        "carrier": {
          "customsId": "NL823169789",
          "name": null,
          "scacCode": null,
          "smdgCode": null,
          "cargoDeclarantShortName": null
        }
      },
      "previousPorts": [{
        "id": "21db791c-ad2d-4307-807a-9ef14b5626cc",
        "arrival": null,
        "departure": "2021-07-20T03:00:00Z",
        "port": {"name": "West-Terschelling", "locationUnCode": "NLWTE", "countryUnCode": "NL", "euPort": true},
        "portFacilityVisits": []
      }, {
        "id": "a1b69c64-2e2c-4416-852b-0ef037229ad6",
        "arrival": null,
        "departure": null,
        "port": {"name": "West-Terschelling", "locationUnCode": "NLWTE", "countryUnCode": "NL", "euPort": true},
        "portFacilityVisits": []
      }, {
        "id": "c1d6575f-3014-4bc4-bd28-d8cf5e48a426",
        "arrival": null,
        "departure": null,
        "port": {"name": "West-Terschelling", "locationUnCode": "NLWTE", "countryUnCode": "NL", "euPort": true},
        "portFacilityVisits": []
      }, {
        "id": "88f26f7a-70cc-4c68-8944-b075ff81233d",
        "arrival": null,
        "departure": null,
        "port": {"name": "West-Terschelling", "locationUnCode": "NLWTE", "countryUnCode": "NL", "euPort": true},
        "portFacilityVisits": []
      }, {
        "id": "4ec27f89-0bb5-444b-a1bc-5b6c636f7b03",
        "arrival": null,
        "departure": null,
        "port": {"name": "West-Terschelling", "locationUnCode": "NLWTE", "countryUnCode": "NL", "euPort": true},
        "portFacilityVisits": []
      }, {
        "id": "5658c725-3be1-4ba8-96e4-e4cb38aa3444",
        "arrival": null,
        "departure": null,
        "port": {"name": "West-Terschelling", "locationUnCode": "NLWTE", "countryUnCode": "NL", "euPort": true},
        "portFacilityVisits": []
      }, {
        "id": "00cd2471-d0d8-4c6b-8469-8d94f10776ea",
        "arrival": null,
        "departure": null,
        "port": {"name": "West-Terschelling", "locationUnCode": "NLWTE", "countryUnCode": "NL", "euPort": true},
        "portFacilityVisits": []
      }, {
        "id": "913eb0d9-2c61-42fa-825f-aa4fd05d8b68",
        "arrival": null,
        "departure": null,
        "port": {"name": "West-Terschelling", "locationUnCode": "NLWTE", "countryUnCode": "NL", "euPort": true},
        "portFacilityVisits": []
      }, {
        "id": "f6d33164-195c-4977-8b8d-9d9a691e44bf",
        "arrival": null,
        "departure": null,
        "port": {"name": "West-Terschelling", "locationUnCode": "NLWTE", "countryUnCode": "NL", "euPort": true},
        "portFacilityVisits": []
      }, {
        "id": "e295803f-da80-4943-9bba-9090369279fd",
        "arrival": null,
        "departure": null,
        "port": {"name": "West-Terschelling", "locationUnCode": "NLWTE", "countryUnCode": "NL", "euPort": true},
        "portFacilityVisits": []
      }],
      "portVisit": {
        "pilotStation": null,
        "entryPoint": {"code": "SEH", "name": "SEA", "atSea": null},
        "etaPort": "2021-07-21T04:00:00Z",
        "portEntry": null,
        "firstMovement": {
          "vesselDraft": 4.5,
          "vesselMasterName": "T J Bakker",
          "numberOfCrew": 5,
          "numberOfPassengers": 0,
          "cargo": "GENERAL_CARGO",
          "orderEmail": null,
          "orderSms": null,
          "pilotExemption": null,
          "offStandardBeam": {"value": 9, "remarks": null},
          "pilotService": {"required": false, "serviceProvider": null, "remarks": null},
          "order": null,
          "stormPilotageInformation": null
        },
        "passingThroughTugboats": null,
        "defectTypes": [],
        "defectTypeRemarks": null,
        "berthVisits": [{
          "id": "6c775436-7648-469d-8e50-0785c75200d2",
          "berth": {
            "name": "WERKHAVEN, BETONNINGSTERREIN",
            "shortName": null,
            "code": "3021",
            "terminalCode": "WTEBTTRN",
            "terminalName": "WERKHAVEN, BETONNINGSTERREIN",
            "organisationShortName": null,
            "organisationName": null,
            "berthGroupCode": null,
            "buoy": false,
            "harbourDuesArea": true,
            "partyToNotify": null
          },
          "eta": null,
          "requestedEta": null,
          "ata": "2021-07-21T03:29:12Z",
          "etd": "2021-08-19T08:00:00Z",
          "requestedEtd": null,
          "atd": null,
          "mooring": "NO_PREFERENCE",
          "remarks": null,
          "visitPurposes": ["AWAITING_ORDERS", "DISCHARGE"],
          "bollardFrom": null,
          "bollardTo": null,
          "tugboatAtArrival": {"required": false, "serviceProvider": null, "requiredQuantity": null, "remarks": null},
          "boatmenAtArrival": {"required": false, "serviceProvider": null, "remarks": null},
          "boatmenAtDeparture": {"required": false, "serviceProvider": null, "remarks": null},
          "tugboatAtDeparture": {"required": false, "serviceProvider": null, "requiredQuantity": null, "remarks": null},
          "nextMovement": {
            "vesselDraft": 4.5,
            "vesselMasterName": "T J Bakker",
            "numberOfCrew": 5,
            "numberOfPassengers": 0,
            "cargo": "BALLAST_CONDITION",
            "orderEmail": null,
            "orderSms": null,
            "pilotExemption": null,
            "offStandardBeam": {"value": 9, "remarks": null},
            "pilotService": {"required": false, "serviceProvider": null, "remarks": null},
            "order": null,
            "stormPilotageInformation": null
          }
        }],
        "dangerousGoodsLoading": false,
        "dangerousGoodsDischarge": false,
        "dangerousGoodsTransit": false,
        "vesselInspectionRequired": false,
        "shipConfiguration": null,
        "exitPoint": {"code": "SEH", "name": "SEA", "atSea": null},
        "etdPort": "2021-08-19T08:30:00Z",
        "atdPort": null,
        "ataPort": "2021-07-21T03:29:12Z"
      },
      "departureVoyage": {
        "voyageNumber": "Reis 358",
        "carrier": {
          "customsId": "NL823169789",
          "name": null,
          "scacCode": null,
          "smdgCode": null,
          "cargoDeclarantShortName": null
        }
      },
      "nextPorts": [{
        "id": "9d48912f-c083-4cdb-bcf1-a76c25660736",
        "arrival": "2021-08-19T16:00:00Z",
        "departure": null,
        "port": {"name": "North Sea", "locationUnCode": "XZZNO", "countryUnCode": "NL", "euPort": true},
        "customsOffice": null
      }]
    },
    "nextVisitDeclaration": null,
    "securityDeclaration": {
      "securityReportRequired": "NO",
      "companySecurityOfficer": null,
      "currentSecurityLevel": null,
      "approvedSspDocumentOnBoard": null,
      "isscSecurityDocument": null,
      "shipToShipActivities": [],
      "securityRelatedMatter": null
    },
    "wasteDeclaration": null,
    "wasteCollections": [],
    "shipStoresDeclaration": null,
    "dangerousGoodsDeclarations": [],
    "nextDangerousGoodsDeclaration": null,
    "importDeclarations": [],
    "transitDeclarationMap": {},
    "loadingDeclarations": [],
    "paxDeclarationSummaries": [],
    "healthDeclarationSummary": null,
    "etaPortAis": null,
    "ignoreEtaPortAis": false,
    "cancelled": false,
    "ataRegisteredByPa": true,
    "visitKnownAtPa": true,
    "dangerousGoodsEnabled": true,
    "cargoImportEnabled": true,
    "ataAcknowledged": true,
    "declarations": [{
      "type": "VISIT",
      "id": null,
      "status": "REJECTED",
      "rejectReasons": null,
      "timeStamp": "2021-07-20T20:14:46.639Z"
    }, {
      "type": "PAX",
      "id": null,
      "status": "ACCEPTED",
      "rejectReasons": null,
      "timeStamp": "2021-07-20T20:17:14.183Z"
    }, {
      "type": "STO",
      "id": null,
      "status": "REJECTED",
      "rejectReasons": "Visit_ID is NOT known for reported declaration",
      "timeStamp": "2021-07-20T20:18:05.876Z"
    }, {
      "type": "WPCS",
      "id": null,
      "status": "DECLARED",
      "rejectReasons": null,
      "timeStamp": "2021-07-20T20:36:19.050Z"
    }, {
      "type": "STO",
      "id": null,
      "status": "ACCEPTED",
      "rejectReasons": null,
      "timeStamp": "2021-07-20T20:40:43.820Z"
    }, {
      "type": "WPCS",
      "id": null,
      "status": "DECLARED",
      "rejectReasons": null,
      "timeStamp": "2021-07-21T03:29:23.760Z"
    }, {
      "type": "SECURITY",
      "id": null,
      "status": "DECLARED",
      "rejectReasons": null,
      "timeStamp": "2021-07-21T06:38:12.911Z"
    }
    ],
    "uploads": {"STO": "a2c27568-5718-4f28-8e46-ffb32ae19616"},
    "additionalIncomingMovementInfo": null,
    "berthVisitInfos": {},
    "incomingMovementHarbourMasterInfo": null,
    "incomingTidalWindowStatus": null,
    "lastMessageId": "b7cdb40f-ccfa-427b-9749-5b09340291de",
    "created": "2021-07-20T20:13:11.464Z",
    "updated": "2021-08-24T11:32:19.822Z",
    "berthVisitIdOfTransfer": null,
    "hasBeenTransferred": false,
    "visitStatus": "ARRIVED",
    "previousMessageId": "05053ce0-a688-4709-90a0-c04f3d9b6174",
    "nextMessageId": null,
    "allowedViewers": [{
      "fullName": "Kuhlman Repko Shipping B.V.",
      "shortName": "KUHLMAN",
      "portAuthorityId": "KUHL",
      "emailAddress": "erno@krshipping.nl",
      "address": "Nieuwe Vissershaven 7",
      "city": "Harlingen",
      "countryUnCode": "NL",
      "countryName": "Netherlands",
      "zipCode": "8861 NX",
      "phoneNumber": "+31-517-412831",
      "faxNumber": null,
      "contact": "De heer Erno Leguijt",
      "customsEORINumber": "NL813141692",
      "ean": null,
      "chamberOfCommerceNumber": "01103873",
      "scacCode": null,
      "ensEnabled": false,
      "cargoImportEnabled": true,
      "cargoImportEnabledByDefault": true
    }, {
      "fullName": "Kuhlman Repko Shipping B.V.",
      "shortName": "KUHLMAN",
      "portAuthorityId": "KUHL",
      "emailAddress": "MARTIN@KRSHIPPING.NL",
      "address": "Nieuwe Vissershaven 7",
      "city": "Harlingen",
      "countryUnCode": "NL",
      "countryName": "Netherlands",
      "zipCode": "8861 NX",
      "phoneNumber": "+31-517-412831",
      "faxNumber": null,
      "contact": "Martin de Boer",
      "customsEORINumber": "NL813141692",
      "ean": null,
      "chamberOfCommerceNumber": "01103873",
      "scacCode": null,
      "ensEnabled": false,
      "cargoImportEnabled": true,
      "cargoImportEnabledByDefault": true
    }, {
      "fullName": "Havenbedrijf Harlingen",
      "shortName": "HBHHAR",
      "portAuthorityId": "NLHARHBH",
      "emailAddress": "d.klinkenberg@portofharlingen.nl",
      "address": "Waddenpromenade 9-3",
      "city": "Harlingen",
      "countryUnCode": "NL",
      "countryName": "Netherlands",
      "zipCode": "8861 NT",
      "phoneNumber": "0517-723333",
      "faxNumber": null,
      "contact": "Dirk Klinkenberg",
      "customsEORINumber": "NL001608836",
      "ean": null,
      "chamberOfCommerceNumber": null,
      "scacCode": null,
      "ensEnabled": false,
      "cargoImportEnabled": false,
      "cargoImportEnabledByDefault": false
    }],
    "transitDeclarations": []
  },
  NLRTM21016508: {
    "crn": "NLRTM21016508",
    "copyOfVisit": null,
    "portOfCall": {
      "port": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM",
        "countryUnCode": "NL",
        "euPort": true
      },
      "portAuthority": {
        "fullName": "Havenbedrijf Rotterdam N.V.",
        "shortName": "HBRRTM",
        "portAuthorityId": "HbR",
        "emailAddress": "HCC@portofrotterdam.com",
        "address": "Wilhelminakade 909",
        "city": "Rotterdam",
        "countryUnCode": "NL",
        "countryName": "Netherlands",
        "zipCode": "3072 AP",
        "phoneNumber": "+31-10-2521195",
        "faxNumber": "+31-10-2521000",
        "contact": "Diana van Eijk",
        "customsEORINumber": "NL24354561",
        "ean": null,
        "chamberOfCommerceNumber": null,
        "scacCode": null,
        "ensEnabled": false,
        "cargoImportEnabled": false,
        "cargoImportEnabledByDefault": false
      },
      "customsOffice": {
        "name": "Rotterdam Haven/Kantoor Maasvlakte",
        "unCode": "NL000396"
      },
      "ataReported": true,
      "atdReported": true,
      "atdPortReported": true,
      "paDeclarationRequired": true,
      "swDeclarationRequired": true,
      "wasteEnabled": true,
      "dangerousGoodsEnabled": true,
      "harbourDuesEnabled": false,
      "orderNauticalServicesEnabled": true,
      "enableNotificationsToPa": false,
      "authoriseOrganisationsDisabled": false,
      "tugBoatsRequired": true,
      "portAuthorityClearance": true
    },
    "vessel": {
      "imoCode": "9199256",
      "name": "THESEUS",
      "radioCallSign": "V2OU",
      "motUnCode": "150X",
      "motName": "General cargo vessel",
      "summerDeadWeight": 2500,
      "maxWidth": 11.68,
      "flagCode": "ATG",
      "flagCountryUnCode": "AG",
      "netTonnage": 1081,
      "grossTonnage": 1846,
      "registrationPlaceUnloCode": "AGSJO",
      "registrationPlaceName": "Saint John's",
      "registrationDate": "2000-08-30",
      "mmsiNumber": "304011027",
      "fullLength": 89.75,
      "emailAddress": "Theseus@fleet-sunship.de"
    },
    "owner": {
      "fullName": "Wilson Agency B.V.",
      "shortName": "SO1",
      "portAuthorityId": "WILSON",
      "emailAddress": "rotterdam@wilsonagency.nl",
      "address": "Directiekade 15",
      "city": "Rotterdam",
      "countryUnCode": "NL",
      "countryName": "Netherlands",
      "zipCode": "3089 JA",
      "phoneNumber": "+31-10-2952882",
      "faxNumber": "+31-10-4722401",
      "contact": "Stefan Borghouts",
      "customsEORINumber": "NL812363760",
      "ean": null,
      "chamberOfCommerceNumber": "24351698",
      "scacCode": "WLSN",
      "ensEnabled": true,
      "cargoImportEnabled": true,
      "cargoImportEnabledByDefault": true
    },
    "nextOwner": null,
    "declarant": {
      "fullName": "Wilson Agency B.V.",
      "shortName": "SO1",
      "portAuthorityId": "WILSON",
      "emailAddress": "rotterdam@wilsonagency.nl",
      "address": "Directiekade 15",
      "city": "Rotterdam",
      "countryUnCode": "NL",
      "countryName": "Netherlands",
      "zipCode": "3089 JA",
      "phoneNumber": "+31-10-2952882",
      "faxNumber": "+31-10-4722401",
      "contact": "Stefan Borghouts",
      "customsEORINumber": "NL812363760",
      "ean": null,
      "chamberOfCommerceNumber": "24351698",
      "scacCode": "WLSN",
      "ensEnabled": true,
      "cargoImportEnabled": true,
      "cargoImportEnabledByDefault": true
    },
    "nextDeclarant": null,
    "financialDeclarant": {
      "fullName": "Wilson Agency B.V.",
      "shortName": "WILSONAGENCY",
      "portAuthorityId": "WILSON",
      "emailAddress": "rotterdam@wilsonagency.nl",
      "address": "Directiekade 15",
      "city": "Rotterdam",
      "countryUnCode": "NL",
      "countryName": "Netherlands",
      "zipCode": "3089 JA",
      "phoneNumber": "+31-10-2952882",
      "faxNumber": "+31-10-4722401",
      "contact": "Stefan Borghouts",
      "customsEORINumber": "NL812363760",
      "ean": null,
      "chamberOfCommerceNumber": "24351698",
      "scacCode": "WLSN",
      "ensEnabled": true,
      "cargoImportEnabled": true,
      "cargoImportEnabledByDefault": true
    },
    "nominatedFinancialDeclarant": null,
    "orderNauticalServices": true,
    "orderIncomingMovement": true,
    "creditNumberHolderSameAsDeclarant": false,
    "declarantResponsibleForCustoms": false,
    "cargoDeclarants": [
      {
        "fullName": "Wilson Agency B.V.",
        "shortName": "WILSONAGENCY",
        "portAuthorityId": "WILSON",
        "emailAddress": "rotterdam@wilsonagency.nl",
        "address": "Directiekade 15",
        "city": "Rotterdam",
        "countryUnCode": "NL",
        "countryName": "Netherlands",
        "zipCode": "3089 JA",
        "phoneNumber": "+31-10-2952882",
        "faxNumber": "+31-10-4722401",
        "contact": "Stefan Borghouts",
        "customsEORINumber": "NL812363760",
        "ean": null,
        "chamberOfCommerceNumber": "24351698",
        "scacCode": "WLSN",
        "ensEnabled": true,
        "cargoImportEnabled": true,
        "cargoImportEnabledByDefault": true
      }
    ],
    "shipOperators": [
      {
        "fullName": "Wilson Agency B.V.",
        "shortName": "WILSONAGENCY",
        "portAuthorityId": "WILSON",
        "emailAddress": "rotterdam@wilsonagency.nl",
        "address": "Directiekade 15",
        "city": "Rotterdam",
        "countryUnCode": "NL",
        "countryName": "Netherlands",
        "zipCode": "3089 JA",
        "phoneNumber": "+31-10-2952882",
        "faxNumber": "+31-10-4722401",
        "contact": "Stefan Borghouts",
        "customsEORINumber": "NL812363760",
        "ean": null,
        "chamberOfCommerceNumber": "24351698",
        "scacCode": "WLSN",
        "ensEnabled": true,
        "cargoImportEnabled": true,
        "cargoImportEnabledByDefault": true
      },
      {
        "fullName": "Wilson Agency B.V.",
        "shortName": "WILSONAGENCY",
        "portAuthorityId": "WILSON",
        "emailAddress": "rotterdam@wilsonagency.nl",
        "address": "Directiekade 15",
        "city": "Rotterdam",
        "countryUnCode": "NL",
        "countryName": "Netherlands",
        "zipCode": "3089 JA",
        "phoneNumber": "+31-10-2952882",
        "faxNumber": "+31-10-4722401",
        "contact": "Stefan Borghouts",
        "customsEORINumber": "NL812363760",
        "ean": null,
        "chamberOfCommerceNumber": "24351698",
        "scacCode": "WLSN",
        "ensEnabled": true,
        "cargoImportEnabled": true,
        "cargoImportEnabledByDefault": true
      },
      {
        "fullName": "Havenbedrijf Rotterdam N.V.",
        "shortName": "HBRRTM",
        "portAuthorityId": "HbR",
        "emailAddress": "HCC@portofrotterdam.com",
        "address": "Wilhelminakade 909",
        "city": "Rotterdam",
        "countryUnCode": "NL",
        "countryName": "Netherlands",
        "zipCode": "3072 AP",
        "phoneNumber": "+31-10-2521195",
        "faxNumber": "+31-10-2521000",
        "contact": "Diana van Eijk",
        "customsEORINumber": "NL24354561",
        "ean": null,
        "chamberOfCommerceNumber": null,
        "scacCode": null,
        "ensEnabled": false,
        "cargoImportEnabled": false,
        "cargoImportEnabledByDefault": false
      }
    ],
    "additionalViewers": [],
    "visitDeclaration": {
      "clientReferenceNumber": "THES456 / 12527",
      "vesselEmailAddress": "Theseus@fleet-sunship.de",
      "etaFirstEntryEu": "2021-07-12T03:00:00Z",
      "arrivalVoyage": {
        "voyageNumber": "THES456 / 12527",
        "carrier": {
          "customsId": "NL823371888",
          "name": "Wilson Eurocarriers A.S.",
          "scacCode": "WECN",
          "smdgCode": null,
          "cargoDeclarantShortName": null
        }
      },
      "previousPorts": [
        {
          "id": "8037601f-056f-487a-994e-f1a5b0953c27",
          "arrival": "2021-07-09T10:00:00Z",
          "departure": "2021-07-09T10:00:00Z",
          "port": {
            "name": "Fredrikstad",
            "locationUnCode": "NOFRK",
            "countryUnCode": "NO",
            "euPort": false
          },
          "portFacilityVisits": [
            {
              "portFacility": {
                "code": "NOFRK-9999",
                "name": "UNKNOWN"
              },
              "arrivalDate": "2021-07-09",
              "departureDate": "2021-07-09",
              "securityLevel": "SL1",
              "additionalSecurityMeasures": null
            }
          ]
        },
        {
          "id": "654c39f3-3210-4e5b-bd52-155917fda6ab",
          "arrival": "2021-07-07T10:00:00Z",
          "departure": "2021-07-09T10:00:00Z",
          "port": {
            "name": "Horten",
            "locationUnCode": "NOHOR",
            "countryUnCode": "NO",
            "euPort": false
          },
          "portFacilityVisits": [
            {
              "portFacility": {
                "code": "NOHOR-9999",
                "name": "UNKNOWN"
              },
              "arrivalDate": "2021-07-07",
              "departureDate": "2021-07-09",
              "securityLevel": "SL1",
              "additionalSecurityMeasures": null
            }
          ]
        },
        {
          "id": "ab57af9b-db49-419d-a714-01f28d947174",
          "arrival": "2021-07-02T10:00:00Z",
          "departure": "2021-07-05T10:00:00Z",
          "port": {
            "name": "Duisburg",
            "locationUnCode": "DEDUI",
            "countryUnCode": "DE",
            "euPort": true
          },
          "portFacilityVisits": [
            {
              "portFacility": {
                "code": "DEDUI-9999",
                "name": "UNKNOWN"
              },
              "arrivalDate": "2021-07-02",
              "departureDate": "2021-07-05",
              "securityLevel": "SL1",
              "additionalSecurityMeasures": "no"
            }
          ]
        },
        {
          "id": "9e540b37-a05a-43ab-b2e8-ad4e8fe3a442",
          "arrival": "2021-06-30T10:00:00Z",
          "departure": "2021-07-01T10:00:00Z",
          "port": {
            "name": "Dunkerque",
            "locationUnCode": "FRDKK",
            "countryUnCode": "FR",
            "euPort": true
          },
          "portFacilityVisits": [
            {
              "portFacility": {
                "code": "FRDKK-9999",
                "name": "UNKNOWN"
              },
              "arrivalDate": "2021-06-30",
              "departureDate": "2021-07-01",
              "securityLevel": "SL1",
              "additionalSecurityMeasures": "no"
            }
          ]
        },
        {
          "id": "6e92c083-249f-4aa9-b6ca-fe49c8fefb5f",
          "arrival": "2021-06-28T10:00:00Z",
          "departure": "2021-06-29T10:00:00Z",
          "port": {
            "name": "Moerdijk",
            "locationUnCode": "NLMOE",
            "countryUnCode": "NL",
            "euPort": true
          },
          "portFacilityVisits": [
            {
              "portFacility": {
                "code": "NLMOE-0001",
                "name": "Moerdijk: B&S Natuursteen B.V."
              },
              "arrivalDate": "2021-06-28",
              "departureDate": "2021-06-29",
              "securityLevel": "SL1",
              "additionalSecurityMeasures": "no"
            }
          ]
        },
        {
          "id": "1f31cef0-f48e-429a-89b1-b402146aab49",
          "arrival": "2021-06-20T10:00:00Z",
          "departure": "2021-06-23T10:00:00Z",
          "port": {
            "name": "Mo i Rana",
            "locationUnCode": "NOMQN",
            "countryUnCode": "NO",
            "euPort": false
          },
          "portFacilityVisits": [
            {
              "portFacility": {
                "code": "NOMQN-0001",
                "name": "Bulkterminalen"
              },
              "arrivalDate": "2021-06-20",
              "departureDate": "2021-06-23",
              "securityLevel": "SL1",
              "additionalSecurityMeasures": "no"
            }
          ]
        },
        {
          "id": "b9ef1189-2156-475e-a7da-4c0fcda0af71",
          "arrival": "2021-06-17T10:00:00Z",
          "departure": "2021-06-19T10:00:00Z",
          "port": {
            "name": "Kristiansund",
            "locationUnCode": "NOKSU",
            "countryUnCode": "NO",
            "euPort": false
          },
          "portFacilityVisits": [
            {
              "portFacility": {
                "code": "NOKSU-9999",
                "name": "UNKNOWN"
              },
              "arrivalDate": "2021-06-17",
              "departureDate": "2021-06-19",
              "securityLevel": "SL1",
              "additionalSecurityMeasures": "no"
            }
          ]
        },
        {
          "id": "068e110d-c5ba-44b8-ab05-92ac9dbc18cf",
          "arrival": "2021-06-13T10:00:00Z",
          "departure": "2021-06-13T10:00:00Z",
          "port": {
            "name": "Tananger",
            "locationUnCode": "NOTAE",
            "countryUnCode": "NO",
            "euPort": false
          },
          "portFacilityVisits": [
            {
              "portFacility": {
                "code": "NOTAE-9999",
                "name": "UNKNOWN"
              },
              "arrivalDate": "2021-06-13",
              "departureDate": "2021-06-13",
              "securityLevel": "SL1",
              "additionalSecurityMeasures": "no"
            }
          ]
        },
        {
          "id": "711af3f4-4368-417f-948e-85a8fd4c7a3e",
          "arrival": "2021-06-11T10:00:00Z",
          "departure": "2021-06-13T10:00:00Z",
          "port": {
            "name": "Stavanger",
            "locationUnCode": "NOSVG",
            "countryUnCode": "NO",
            "euPort": false
          },
          "portFacilityVisits": [
            {
              "portFacility": {
                "code": "NOSVG-0018",
                "name": "Skretting Stavanger"
              },
              "arrivalDate": "2021-06-11",
              "departureDate": "2021-06-13",
              "securityLevel": "SL1",
              "additionalSecurityMeasures": "no"
            }
          ]
        },
        {
          "id": "67aa52c1-88fd-4773-9921-e78bd4a3d35c",
          "arrival": "2021-06-10T10:00:00Z",
          "departure": "2021-06-10T10:00:00Z",
          "port": {
            "name": "Kristiansund",
            "locationUnCode": "NOKSU",
            "countryUnCode": "NO",
            "euPort": false
          },
          "portFacilityVisits": [
            {
              "portFacility": {
                "code": "NOKSU-0030",
                "name": "Averøy Industripark"
              },
              "arrivalDate": "2021-06-10",
              "departureDate": "2021-06-10",
              "securityLevel": "SL1",
              "additionalSecurityMeasures": "no"
            }
          ]
        },
        {
          "id": "02549355-108d-4bac-b5b4-17568afeb614",
          "arrival": "2021-06-08T10:00:00Z",
          "departure": "2021-06-09T10:00:00Z",
          "port": {
            "name": "Ålesund",
            "locationUnCode": "NOAES",
            "countryUnCode": "NO",
            "euPort": false
          },
          "portFacilityVisits": [
            {
              "portFacility": {
                "code": "NOAES-0029",
                "name": "Vedde AS"
              },
              "arrivalDate": "2021-06-08",
              "departureDate": "2021-06-09",
              "securityLevel": "SL1",
              "additionalSecurityMeasures": "no"
            }
          ]
        },
        {
          "id": "e58f7690-d3b7-4118-8b61-187fffe10154",
          "arrival": "2021-06-07T10:00:00Z",
          "departure": "2021-06-08T10:00:00Z",
          "port": {
            "name": "Kristiansund",
            "locationUnCode": "NOKSU",
            "countryUnCode": "NO",
            "euPort": false
          },
          "portFacilityVisits": [
            {
              "portFacility": {
                "code": "NOKSU-0030",
                "name": "Averøy Industripark"
              },
              "arrivalDate": "2021-06-07",
              "departureDate": "2021-06-08",
              "securityLevel": "SL1",
              "additionalSecurityMeasures": "no"
            }
          ]
        }
      ],
      "portVisit": {
        "pilotStation": {
          "code": "MC",
          "name": "Maascenter",
          "atSea": true
        },
        "entryPoint": {
          "code": "LL",
          "name": "Lage Licht",
          "atSea": true
        },
        "etaPort": "2021-07-12T03:00:00Z",
        "portEntry": {
          "origin": "SEA",
          "etaSeaBuoy": "2021-07-12T03:00:00Z",
          "etaPilotBoardingPlace": "2021-07-11T21:00:00Z",
          "requestedEtaPilotBoardingPlace": "2021-07-11T21:00:00Z",
          "earliestTimeOfPortEntry": null,
          "intention": "REQUEST_FOR_ENTRY",
          "baseForPlanning": "PILOT_BOARDING_PLACE",
          "entryDependency": null
        },
        "firstMovement": {
          "vesselDraft": 4.7,
          "vesselMasterName": "Dmitriii Basistyi",
          "numberOfCrew": 7,
          "numberOfPassengers": 0,
          "cargo": "BREAKBULK",
          "orderEmail": null,
          "orderSms": null,
          "pilotExemption": {
            "holderName": "Basistyi, D.",
            "number": "RM10678/1"
          },
          "offStandardBeam": null,
          "pilotService": {
            "required": false,
            "serviceProvider": null,
            "remarks": null
          },
          "order": true,
          "stormPilotageInformation": {
            "heliSuitable": null,
            "hoisting": null,
            "remotePilotage": null,
            "imoLoaRegistered": null,
            "lowFreeboard": null,
            "remarks": null
          }
        },
        "passingThroughTugboats": null,
        "defectTypes": [],
        "defectTypeRemarks": null,
        "berthVisits": [
          {
            "id": "05186de6-210e-459f-b3eb-337abe3e854e",
            "berth": {
              "name": "FRISOH STEINWEG OR",
              "code": "R2722",
              "terminalCode": "1238",
              "terminalName": "FRISOH STEINWEG OR",
              "organisationShortName": "STEINWEG",
              "organisationName": "Steinweg B.V.",
              "berthGroupCode": "STEINWEG STAD",
              "buoy": false,
              "harbourDuesArea": true,
              "partyToNotify": null
            },
            "eta": "2021-07-11T23:25:00Z",
            "requestedEta": null,
            "ata": "2021-07-11T23:25:00Z",
            "etd": null,
            "requestedEtd": null,
            "atd": "2021-07-12T07:08:03Z",
            "mooring": "NO_PREFERENCE",
            "remarks": null,
            "visitPurposes": [
              "DISCHARGE"
            ],
            "bollardFrom": null,
            "bollardTo": null,
            "tugboatAtArrival": {
              "required": false,
              "serviceProvider": {
                "name": "Boluda",
                "portAuthorityId": "BOLUDA"
              },
              "requiredQuantity": null,
              "remarks": null
            },
            "boatmenAtArrival": {
              "required": true,
              "serviceProvider": {
                "name": "KRVE",
                "portAuthorityId": "RVE"
              },
              "remarks": null
            },
            "boatmenAtDeparture": null,
            "tugboatAtDeparture": null,
            "nextMovement": {
              "vesselDraft": null,
              "vesselMasterName": null,
              "numberOfCrew": null,
              "numberOfPassengers": null,
              "cargo": null,
              "orderEmail": null,
              "orderSms": null,
              "pilotExemption": null,
              "offStandardBeam": null,
              "pilotService": null,
              "order": null,
              "stormPilotageInformation": null
            }
          },
          {
            "id": "dd1f5ffa-81fa-3912-aff4-07c538ce37b1",
            "berth": {
              "name": "MERWEH V UDEN",
              "code": "R386",
              "terminalCode": "4908",
              "terminalName": "MERWEH V UDEN",
              "organisationShortName": "VANUDENSTEVE",
              "organisationName": "Van Uden Stevedoring B.V.",
              "berthGroupCode": "MERWH V UDEN",
              "buoy": false,
              "harbourDuesArea": true,
              "partyToNotify": null
            },
            "eta": "2021-07-12T08:07:43Z",
            "requestedEta": null,
            "ata": "2021-07-12T09:38:40Z",
            "etd": "2021-07-12T13:00:00Z",
            "requestedEtd": "2021-07-12T13:00:00Z",
            "atd": "2021-07-12T13:46:34Z",
            "mooring": "NO_PREFERENCE",
            "remarks": null,
            "visitPurposes": [
              "DISCHARGE"
            ],
            "bollardFrom": null,
            "bollardTo": null,
            "tugboatAtArrival": {
              "required": false,
              "serviceProvider": {
                "name": "Boluda",
                "portAuthorityId": "BOLUDA"
              },
              "requiredQuantity": null,
              "remarks": null
            },
            "boatmenAtArrival": {
              "required": true,
              "serviceProvider": {
                "name": "KRVE",
                "portAuthorityId": "RVE"
              },
              "remarks": null
            },
            "boatmenAtDeparture": {
              "required": false,
              "serviceProvider": null,
              "remarks": null
            },
            "tugboatAtDeparture": {
              "required": false,
              "serviceProvider": {
                "name": "Boluda",
                "portAuthorityId": "BOLUDA"
              },
              "requiredQuantity": null,
              "remarks": null
            },
            "nextMovement": {
              "vesselDraft": 3.8,
              "vesselMasterName": "Dmitriii Basistyi",
              "numberOfCrew": 7,
              "numberOfPassengers": 0,
              "cargo": "BALLAST_CONDITION",
              "orderEmail": null,
              "orderSms": null,
              "pilotExemption": {
                "holderName": "Basistyi, D.",
                "number": "RM10678/1"
              },
              "offStandardBeam": null,
              "pilotService": {
                "required": false,
                "serviceProvider": null,
                "remarks": null
              },
              "order": true,
              "stormPilotageInformation": {
                "heliSuitable": null,
                "hoisting": null,
                "remotePilotage": null,
                "imoLoaRegistered": null,
                "lowFreeboard": null,
                "remarks": null
              }
            }
          },
          {
            "id": "9e088ad1-06c0-4089-a8ab-e28894a54ce1",
            "berth": {
              "name": "MERWEH V UDEN",
              "code": "R385",
              "terminalCode": "4908",
              "terminalName": "MERWEH V UDEN",
              "organisationShortName": "VANUDENSTEVE",
              "organisationName": "Van Uden Stevedoring B.V.",
              "berthGroupCode": "MERWH V UDEN",
              "buoy": false,
              "harbourDuesArea": true,
              "partyToNotify": null
            },
            "eta": "2021-07-12T13:15:00Z",
            "requestedEta": null,
            "ata": "2021-07-12T13:46:52Z",
            "etd": "2021-07-12T16:00:00Z",
            "requestedEtd": "2021-07-12T16:00:00Z",
            "atd": "2021-07-12T15:52:15Z",
            "mooring": "NO_PREFERENCE",
            "remarks": null,
            "visitPurposes": [
              "AWAITING_ORDERS"
            ],
            "bollardFrom": 14,
            "bollardTo": 19,
            "tugboatAtArrival": {
              "required": false,
              "serviceProvider": {
                "name": "Boluda",
                "portAuthorityId": "BOLUDA"
              },
              "requiredQuantity": null,
              "remarks": null
            },
            "boatmenAtArrival": {
              "required": false,
              "serviceProvider": null,
              "remarks": null
            },
            "boatmenAtDeparture": {
              "required": false,
              "serviceProvider": null,
              "remarks": null
            },
            "tugboatAtDeparture": {
              "required": false,
              "serviceProvider": {
                "name": "Boluda",
                "portAuthorityId": "BOLUDA"
              },
              "requiredQuantity": null,
              "remarks": null
            },
            "nextMovement": {
              "vesselDraft": 3.8,
              "vesselMasterName": "Dmitriii Basistyi",
              "numberOfCrew": 7,
              "numberOfPassengers": 0,
              "cargo": "BALLAST_CONDITION",
              "orderEmail": null,
              "orderSms": null,
              "pilotExemption": {
                "holderName": "Basistyi, D.",
                "number": "RM10678/1"
              },
              "offStandardBeam": null,
              "pilotService": {
                "required": false,
                "serviceProvider": null,
                "remarks": null
              },
              "order": true,
              "stormPilotageInformation": {
                "heliSuitable": null,
                "hoisting": null,
                "remotePilotage": null,
                "imoLoaRegistered": null,
                "lowFreeboard": null,
                "remarks": null
              }
            }
          }
        ],
        "dangerousGoodsLoading": false,
        "dangerousGoodsDischarge": true,
        "dangerousGoodsTransit": false,
        "vesselInspectionRequired": false,
        "shipConfiguration": null,
        "exitPoint": {
          "code": "LL",
          "name": "Lage Licht",
          "atSea": true
        },
        "etdPort": "2021-07-12T21:45:00Z",
        "atdPort": null,
        "ataPort": "2021-07-11T23:25:00Z"
      },
      "departureVoyage": {
        "voyageNumber": "THES456 / 12527",
        "carrier": {
          "customsId": "NL823371888",
          "name": "Wilson Eurocarriers A.S.",
          "scacCode": "WECN",
          "smdgCode": null,
          "cargoDeclarantShortName": null
        }
      },
      "nextPorts": [
        {
          "id": "948d0413-ec9e-49c6-98d5-4d516f0c2cfe",
          "arrival": "2021-07-14T10:00:00Z",
          "departure": null,
          "port": {
            "name": "Bremen",
            "locationUnCode": "DEBRE",
            "countryUnCode": "DE",
            "euPort": true
          },
          "customsOffice": null
        }
      ]
    },
    "nextVisitDeclaration": null,
    "securityDeclaration": {
      "securityReportRequired": "YES",
      "companySecurityOfficer": {
        "name": "CAPT. OLAF BORUCKI",
        "email": "borucki@sunship.de",
        "phoneNumber": "+491724403606"
      },
      "currentSecurityLevel": "SL1",
      "approvedSspDocumentOnBoard": true,
      "isscSecurityDocument": {
        "availability": "YES",
        "expiryDate": "2024-06-18",
        "reasonNoValidIsscOnBoard": null,
        "issuer": {
          "code": "5",
          "name": "Bureau Veritas"
        }
      },
      "shipToShipActivities": [],
      "securityRelatedMatter": "no"
    },
    "wasteDeclaration": {
      "portOfLastDelivery": {
        "name": "Moerdijk",
        "locationUnCode": "NLMOE",
        "countryUnCode": "NL",
        "euPort": true
      },
      "lastDeliveryDate": "2021-06-29",
      "wasteItems": [
        {
          "type": "SLUDGE",
          "ssn": {
            "code": "102",
            "description": "Oily Residues (Sludge)",
            "marpolCategory": "Annex I",
            "specificationRequired": false
          },
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 1,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0.83,
          "maxDedicatedCapacity": 4.53,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": {
            "name": "Hamburg",
            "locationUnCode": "DEHAM",
            "countryUnCode": "DE",
            "euPort": true
          }
        },
        {
          "type": "FUEL_REMNANTS",
          "ssn": {
            "code": "102",
            "description": "Oily Residues (Sludge)",
            "marpolCategory": "Annex I",
            "specificationRequired": false
          },
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "BILGE_WATER",
          "ssn": {
            "code": "102",
            "description": "Oily Residues (Sludge)",
            "marpolCategory": "Annex I",
            "specificationRequired": false
          },
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0.06,
          "maxDedicatedCapacity": 2.46,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": {
            "name": "Hamburg",
            "locationUnCode": "DEHAM",
            "countryUnCode": "DE",
            "euPort": true
          }
        },
        {
          "type": "USED_ENGINE_OIL",
          "ssn": {
            "code": "102",
            "description": "Oily Residues (Sludge)",
            "marpolCategory": "Annex I",
            "specificationRequired": false
          },
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 1.5,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 1.53,
          "maxDedicatedCapacity": 3.82,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": {
            "name": "Hamburg",
            "locationUnCode": "DEHAM",
            "countryUnCode": "DE",
            "euPort": true
          }
        },
        {
          "type": "FOOD_WASTE",
          "ssn": {
            "code": "102",
            "description": "Oily Residues (Sludge)",
            "marpolCategory": "Annex I",
            "specificationRequired": false
          },
          "berthIdForPickup": "616c9047-e998-32d9-90cf-8ec8b626ec29",
          "collector": {
            "fullName": "Bek & Verburg Rotterdam",
            "shortName": "BEKVERBURG",
            "portAuthorityId": "BEK&VERBURG",
            "emailAddress": "scheepsafvalstoffen@bek-verburg.nl",
            "address": "Montrealweg 100",
            "city": "Botlek",
            "countryUnCode": "NL",
            "countryName": "Netherlands",
            "zipCode": "3197 KH",
            "phoneNumber": "+31-10-4287744",
            "faxNumber": "+31-10-4287749",
            "contact": "Kenny Baas",
            "customsEORINumber": null,
            "ean": null,
            "chamberOfCommerceNumber": null,
            "scacCode": null,
            "ensEnabled": false,
            "cargoImportEnabled": false,
            "cargoImportEnabledByDefault": false
          },
          "specification": null,
          "quantityOfLastDelivery": 0.1,
          "quantityToBeDelivered": 0.1,
          "quantityToBeRetained": 0.02,
          "maxDedicatedCapacity": 0.12,
          "quantityToBeGenerated": 0.01,
          "portForRetainedWaste": {
            "name": "Rotterdam",
            "locationUnCode": "NLRTM",
            "countryUnCode": "NL",
            "euPort": true
          }
        },
        {
          "type": "PLASTIC_CLEAN",
          "ssn": {
            "code": "102",
            "description": "Oily Residues (Sludge)",
            "marpolCategory": "Annex I",
            "specificationRequired": false
          },
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "PLASTIC_CONTAMINATED",
          "ssn": {
            "code": "102",
            "description": "Oily Residues (Sludge)",
            "marpolCategory": "Annex I",
            "specificationRequired": false
          },
          "berthIdForPickup": "616c9047-e998-32d9-90cf-8ec8b626ec29",
          "collector": {
            "fullName": "Bek & Verburg Rotterdam",
            "shortName": "BEKVERBURG",
            "portAuthorityId": "BEK&VERBURG",
            "emailAddress": "scheepsafvalstoffen@bek-verburg.nl",
            "address": "Montrealweg 100",
            "city": "Botlek",
            "countryUnCode": "NL",
            "countryName": "Netherlands",
            "zipCode": "3197 KH",
            "phoneNumber": "+31-10-4287744",
            "faxNumber": "+31-10-4287749",
            "contact": "Kenny Baas",
            "customsEORINumber": null,
            "ean": null,
            "chamberOfCommerceNumber": null,
            "scacCode": null,
            "ensEnabled": false,
            "cargoImportEnabled": false,
            "cargoImportEnabledByDefault": false
          },
          "specification": null,
          "quantityOfLastDelivery": 0.1,
          "quantityToBeDelivered": 0.01,
          "quantityToBeRetained": 0.03,
          "maxDedicatedCapacity": 0.12,
          "quantityToBeGenerated": 0.01,
          "portForRetainedWaste": {
            "name": "Rotterdam",
            "locationUnCode": "NLRTM",
            "countryUnCode": "NL",
            "euPort": true
          }
        },
        {
          "type": "SMALL_DANGEROUS_WASTE",
          "ssn": {
            "code": "102",
            "description": "Oily Residues (Sludge)",
            "marpolCategory": "Annex I",
            "specificationRequired": false
          },
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "OTHER_DOMESTIC_WASTE",
          "ssn": {
            "code": "102",
            "description": "Oily Residues (Sludge)",
            "marpolCategory": "Annex I",
            "specificationRequired": false
          },
          "berthIdForPickup": "616c9047-e998-32d9-90cf-8ec8b626ec29",
          "collector": {
            "fullName": "Bek & Verburg Rotterdam",
            "shortName": "BEKVERBURG",
            "portAuthorityId": "BEK&VERBURG",
            "emailAddress": "scheepsafvalstoffen@bek-verburg.nl",
            "address": "Montrealweg 100",
            "city": "Botlek",
            "countryUnCode": "NL",
            "countryName": "Netherlands",
            "zipCode": "3197 KH",
            "phoneNumber": "+31-10-4287744",
            "faxNumber": "+31-10-4287749",
            "contact": "Kenny Baas",
            "customsEORINumber": null,
            "ean": null,
            "chamberOfCommerceNumber": null,
            "scacCode": null,
            "ensEnabled": false,
            "cargoImportEnabled": false,
            "cargoImportEnabledByDefault": false
          },
          "specification": "other",
          "quantityOfLastDelivery": 0.3,
          "quantityToBeDelivered": 0.3,
          "quantityToBeRetained": 0.03,
          "maxDedicatedCapacity": 0.36,
          "quantityToBeGenerated": 0.01,
          "portForRetainedWaste": {
            "name": "Rotterdam",
            "locationUnCode": "NLRTM",
            "countryUnCode": "NL",
            "euPort": true
          }
        },
        {
          "type": "INTERNATIONAL_CATERING_WASTE",
          "ssn": {
            "code": "102",
            "description": "Oily Residues (Sludge)",
            "marpolCategory": "Annex I",
            "specificationRequired": false
          },
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "PAPER_OR_CARTON",
          "ssn": {
            "code": "102",
            "description": "Oily Residues (Sludge)",
            "marpolCategory": "Annex I",
            "specificationRequired": false
          },
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "RAGS",
          "ssn": {
            "code": "102",
            "description": "Oily Residues (Sludge)",
            "marpolCategory": "Annex I",
            "specificationRequired": false
          },
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "GLASS",
          "ssn": {
            "code": "102",
            "description": "Oily Residues (Sludge)",
            "marpolCategory": "Annex I",
            "specificationRequired": false
          },
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "METAL_OR_STEELWIRES",
          "ssn": {
            "code": "102",
            "description": "Oily Residues (Sludge)",
            "marpolCategory": "Annex I",
            "specificationRequired": false
          },
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "BOTTLES",
          "ssn": {
            "code": "102",
            "description": "Oily Residues (Sludge)",
            "marpolCategory": "Annex I",
            "specificationRequired": false
          },
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "CROCKERY",
          "ssn": {
            "code": "102",
            "description": "Oily Residues (Sludge)",
            "marpolCategory": "Annex I",
            "specificationRequired": false
          },
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "INCINERATOR_ASHES",
          "ssn": {
            "code": "102",
            "description": "Oily Residues (Sludge)",
            "marpolCategory": "Annex I",
            "specificationRequired": false
          },
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "ANIMAL_CARCASSES",
          "ssn": {
            "code": "102",
            "description": "Oily Residues (Sludge)",
            "marpolCategory": "Annex I",
            "specificationRequired": false
          },
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "EWASTE_WHOLE_DEVICES",
          "ssn": {
            "code": "102",
            "description": "Oily Residues (Sludge)",
            "marpolCategory": "Annex I",
            "specificationRequired": false
          },
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0.025,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "EWASTE_DEVICES",
          "ssn": {
            "code": "102",
            "description": "Oily Residues (Sludge)",
            "marpolCategory": "Annex I",
            "specificationRequired": false
          },
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "PYROTECHNICS",
          "ssn": {
            "code": "102",
            "description": "Oily Residues (Sludge)",
            "marpolCategory": "Annex I",
            "specificationRequired": false
          },
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "BATTERIES",
          "ssn": {
            "code": "102",
            "description": "Oily Residues (Sludge)",
            "marpolCategory": "Annex I",
            "specificationRequired": false
          },
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "FIRE_EXTINGUISHERS",
          "ssn": {
            "code": "102",
            "description": "Oily Residues (Sludge)",
            "marpolCategory": "Annex I",
            "specificationRequired": false
          },
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "CAR_TYRES_OR_RUBBER",
          "ssn": {
            "code": "102",
            "description": "Oily Residues (Sludge)",
            "marpolCategory": "Annex I",
            "specificationRequired": false
          },
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "COOLING_LIQUIDS",
          "ssn": {
            "code": "102",
            "description": "Oily Residues (Sludge)",
            "marpolCategory": "Annex I",
            "specificationRequired": false
          },
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "PROPELLER_SHAFT_GREASE",
          "ssn": {
            "code": "102",
            "description": "Oily Residues (Sludge)",
            "marpolCategory": "Annex I",
            "specificationRequired": false
          },
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "FUMIGATION_PRODUCTS",
          "ssn": {
            "code": "102",
            "description": "Oily Residues (Sludge)",
            "marpolCategory": "Annex I",
            "specificationRequired": false
          },
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "FILTER_OR_RAGS_BAGS",
          "ssn": {
            "code": "102",
            "description": "Oily Residues (Sludge)",
            "marpolCategory": "Annex I",
            "specificationRequired": false
          },
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "WOOD",
          "ssn": {
            "code": "102",
            "description": "Oily Residues (Sludge)",
            "marpolCategory": "Annex I",
            "specificationRequired": false
          },
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "OIL_CONTAMINATED_DRUMS",
          "ssn": {
            "code": "102",
            "description": "Oily Residues (Sludge)",
            "marpolCategory": "Annex I",
            "specificationRequired": false
          },
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "FISHING_GEAR",
          "ssn": {
            "code": "102",
            "description": "Oily Residues (Sludge)",
            "marpolCategory": "Annex I",
            "specificationRequired": false
          },
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "COOKING_OIL",
          "ssn": {
            "code": "102",
            "description": "Oily Residues (Sludge)",
            "marpolCategory": "Annex I",
            "specificationRequired": false
          },
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0.005,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "OPERATIONAL_WASTE",
          "ssn": {
            "code": "102",
            "description": "Oily Residues (Sludge)",
            "marpolCategory": "Annex I",
            "specificationRequired": false
          },
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0.35,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "SEWAGE_WASTE_BLACK",
          "ssn": {
            "code": "102",
            "description": "Oily Residues (Sludge)",
            "marpolCategory": "Annex I",
            "specificationRequired": false
          },
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "SEWAGE_WASTE_GREY",
          "ssn": {
            "code": "102",
            "description": "Oily Residues (Sludge)",
            "marpolCategory": "Annex I",
            "specificationRequired": false
          },
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 1.2,
          "maxDedicatedCapacity": 8.93,
          "quantityToBeGenerated": 2,
          "portForRetainedWaste": {
            "name": "Hamburg",
            "locationUnCode": "DEHAM",
            "countryUnCode": "DE",
            "euPort": true
          }
        },
        {
          "type": "DIRTY_BALLASTWATER",
          "ssn": {
            "code": "102",
            "description": "Oily Residues (Sludge)",
            "marpolCategory": "Annex I",
            "specificationRequired": false
          },
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "WASHWATER_OIL",
          "ssn": {
            "code": "102",
            "description": "Oily Residues (Sludge)",
            "marpolCategory": "Annex I",
            "specificationRequired": false
          },
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "OILY_WASTE_FROM_CLEANING",
          "ssn": {
            "code": "102",
            "description": "Oily Residues (Sludge)",
            "marpolCategory": "Annex I",
            "specificationRequired": false
          },
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "OTHER_OILY_WASTE",
          "ssn": {
            "code": "102",
            "description": "Oily Residues (Sludge)",
            "marpolCategory": "Annex I",
            "specificationRequired": false
          },
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "CARGO_RESIDUES_OR_WASHWATER",
          "ssn": {
            "code": "102",
            "description": "Oily Residues (Sludge)",
            "marpolCategory": "Annex I",
            "specificationRequired": false
          },
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "DIRTY_BALLASTWATER_CHEMICALS",
          "ssn": {
            "code": "102",
            "description": "Oily Residues (Sludge)",
            "marpolCategory": "Annex I",
            "specificationRequired": false
          },
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "OTHER_CARGO_RESIDUES",
          "ssn": {
            "code": "102",
            "description": "Oily Residues (Sludge)",
            "marpolCategory": "Annex I",
            "specificationRequired": false
          },
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "WASHWATER_DRY_CARGO_HME",
          "ssn": {
            "code": "102",
            "description": "Oily Residues (Sludge)",
            "marpolCategory": "Annex I",
            "specificationRequired": false
          },
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "WASHWATER_DRY_CARGO_NON_HME",
          "ssn": {
            "code": "102",
            "description": "Oily Residues (Sludge)",
            "marpolCategory": "Annex I",
            "specificationRequired": false
          },
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "DRY_CARGO_RESIDUES_HME",
          "ssn": {
            "code": "102",
            "description": "Oily Residues (Sludge)",
            "marpolCategory": "Annex I",
            "specificationRequired": false
          },
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "DRY_CARGO_RESIDUES_NON_HME",
          "ssn": {
            "code": "102",
            "description": "Oily Residues (Sludge)",
            "marpolCategory": "Annex I",
            "specificationRequired": false
          },
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        },
        {
          "type": "OTHER_CARGO_RESIDUES_NON_TANKERS",
          "ssn": {
            "code": "102",
            "description": "Oily Residues (Sludge)",
            "marpolCategory": "Annex I",
            "specificationRequired": false
          },
          "berthIdForPickup": null,
          "collector": null,
          "specification": null,
          "quantityOfLastDelivery": 0,
          "quantityToBeDelivered": 0,
          "quantityToBeRetained": 0,
          "maxDedicatedCapacity": 0,
          "quantityToBeGenerated": 0,
          "portForRetainedWaste": null
        }
      ]
    },
    "wasteCollections": [],
    "shipStoresDeclaration": {
      "items": {
        "SPIRITS": 0,
        "BEER": 0,
        "WINE": 0,
        "OTHER": 0,
        "CIGARETTES": 10000,
        "CIGARS": 0,
        "TOBACCO": 0,
        "FUEL_OIL": 29,
        "LUBRICATING_OIL": 3400,
        "MEAT": 15,
        "NARCOTICS": 0,
        "FIRE_ARMS": 0
      }
    },
    "dangerousGoodsDeclarations": [
      {
        "dangerousGoods": {
          "goods": [
            {
              "type": "containerGood",
              "id": "6b1d4191-b557-40cd-aee7-5c0b0dfdc1d0",
              "goodData": {
                "type": "containerGood",
                "name": "Aluminium Smelting By-Products Or Aluminium Remelting By-Products",
                "unCode": "3170",
                "hazardClass": "4.3",
                "subsidiaryRisk": null,
                "emsFireCode": "F-G",
                "emsSpillageCode": "S-P",
                "properties": "Grey powder or lumps with some metallic inclusions. Contact with water may cause heating with possible evolution of flammable and toxic gases such as hydrogen and ammonia. This entry includes e.g. aluminium dross, aluminium skimmings, spent cathodes, spent potliner and aluminium salt slags.",
                "segregationGroup": "SGG15",
                "packingGroup": "MINOR_DANGER",
                "goodCode": "102343",
                "gdsCode": null,
                "stowageType": "container"
              },
              "flashPoint": null,
              "segregationInformation": null,
              "remarks": null,
              "allowingIncompleteRadioactivity": false,
              "radioactive": false,
              "radioactivity": null
            },
            {
              "type": "containerGood",
              "id": "036f6a8e-0d86-4609-9f97-d496549433c0",
              "goodData": {
                "type": "containerGood",
                "name": "Aluminium Smelting By-Products Or Aluminium Remelting By-Products",
                "unCode": "3170",
                "hazardClass": "4.3",
                "subsidiaryRisk": null,
                "emsFireCode": "F-G",
                "emsSpillageCode": "S-P",
                "properties": "Grey powder or lumps with some metallic inclusions. Contact with water may cause heating with possible evolution of flammable and toxic gases such as hydrogen and ammonia. This entry includes e.g. aluminium dross, aluminium skimmings, spent cathodes, spent potliner and aluminium salt slags.",
                "segregationGroup": "SGG15",
                "packingGroup": "MINOR_DANGER",
                "goodCode": "102343",
                "gdsCode": null,
                "stowageType": "container"
              },
              "flashPoint": null,
              "segregationInformation": null,
              "remarks": null,
              "allowingIncompleteRadioactivity": false,
              "radioactive": false,
              "radioactivity": null
            },
            {
              "type": "containerGood",
              "id": "ceef41bb-1296-417d-92c4-6dcfa0321d4d",
              "goodData": {
                "type": "containerGood",
                "name": "Aluminium Smelting By-Products Or Aluminium Remelting By-Products",
                "unCode": "3170",
                "hazardClass": "4.3",
                "subsidiaryRisk": null,
                "emsFireCode": "F-G",
                "emsSpillageCode": "S-P",
                "properties": "Grey powder or lumps with some metallic inclusions. Contact with water may cause heating with possible evolution of flammable and toxic gases such as hydrogen and ammonia. This entry includes e.g. aluminium dross, aluminium skimmings, spent cathodes, spent potliner and aluminium salt slags.",
                "segregationGroup": "SGG15",
                "packingGroup": "MINOR_DANGER",
                "goodCode": "102343",
                "gdsCode": null,
                "stowageType": "container"
              },
              "flashPoint": null,
              "segregationInformation": null,
              "remarks": null,
              "allowingIncompleteRadioactivity": false,
              "radioactive": false,
              "radioactivity": null
            },
            {
              "type": "containerGood",
              "id": "0b46bf29-fe19-4701-9170-8bff52f7e645",
              "goodData": {
                "type": "containerGood",
                "name": "Aluminium Smelting By-Products Or Aluminium Remelting By-Products",
                "unCode": "3170",
                "hazardClass": "4.3",
                "subsidiaryRisk": null,
                "emsFireCode": "F-G",
                "emsSpillageCode": "S-P",
                "properties": "Grey powder or lumps with some metallic inclusions. Contact with water may cause heating with possible evolution of flammable and toxic gases such as hydrogen and ammonia. This entry includes e.g. aluminium dross, aluminium skimmings, spent cathodes, spent potliner and aluminium salt slags.",
                "segregationGroup": "SGG15",
                "packingGroup": "MINOR_DANGER",
                "goodCode": "102343",
                "gdsCode": null,
                "stowageType": "container"
              },
              "flashPoint": null,
              "segregationInformation": null,
              "remarks": null,
              "allowingIncompleteRadioactivity": false,
              "radioactive": false,
              "radioactivity": null
            },
            {
              "type": "containerGood",
              "id": "756c0997-5d32-42d8-a8f6-4f4063af2eed",
              "goodData": {
                "type": "containerGood",
                "name": "Aluminium Smelting By-Products Or Aluminium Remelting By-Products",
                "unCode": "3170",
                "hazardClass": "4.3",
                "subsidiaryRisk": null,
                "emsFireCode": "F-G",
                "emsSpillageCode": "S-P",
                "properties": "Grey powder or lumps with some metallic inclusions. Contact with water may cause heating with possible evolution of flammable and toxic gases such as hydrogen and ammonia. This entry includes e.g. aluminium dross, aluminium skimmings, spent cathodes, spent potliner and aluminium salt slags.",
                "segregationGroup": "SGG15",
                "packingGroup": "MINOR_DANGER",
                "goodCode": "102343",
                "gdsCode": null,
                "stowageType": "container"
              },
              "flashPoint": null,
              "segregationInformation": null,
              "remarks": null,
              "allowingIncompleteRadioactivity": false,
              "radioactive": false,
              "radioactivity": null
            },
            {
              "type": "containerGood",
              "id": "735e0fbe-7fdd-4a0b-886b-8865a8bd46cd",
              "goodData": {
                "type": "containerGood",
                "name": "Aluminium Smelting By-Products Or Aluminium Remelting By-Products",
                "unCode": "3170",
                "hazardClass": "4.3",
                "subsidiaryRisk": null,
                "emsFireCode": "F-G",
                "emsSpillageCode": "S-P",
                "properties": "Grey powder or lumps with some metallic inclusions. Contact with water may cause heating with possible evolution of flammable and toxic gases such as hydrogen and ammonia. This entry includes e.g. aluminium dross, aluminium skimmings, spent cathodes, spent potliner and aluminium salt slags.",
                "segregationGroup": "SGG15",
                "packingGroup": "MINOR_DANGER",
                "goodCode": "102343",
                "gdsCode": null,
                "stowageType": "container"
              },
              "flashPoint": null,
              "segregationInformation": null,
              "remarks": null,
              "allowingIncompleteRadioactivity": false,
              "radioactive": false,
              "radioactivity": null
            },
            {
              "type": "containerGood",
              "id": "b306d7fe-6f80-4aa0-93a4-53f2c71f5a05",
              "goodData": {
                "type": "containerGood",
                "name": "Aluminium Smelting By-Products Or Aluminium Remelting By-Products",
                "unCode": "3170",
                "hazardClass": "4.3",
                "subsidiaryRisk": null,
                "emsFireCode": "F-G",
                "emsSpillageCode": "S-P",
                "properties": "Grey powder or lumps with some metallic inclusions. Contact with water may cause heating with possible evolution of flammable and toxic gases such as hydrogen and ammonia. This entry includes e.g. aluminium dross, aluminium skimmings, spent cathodes, spent potliner and aluminium salt slags.",
                "segregationGroup": "SGG15",
                "packingGroup": "MINOR_DANGER",
                "goodCode": "102343",
                "gdsCode": null,
                "stowageType": "container"
              },
              "flashPoint": null,
              "segregationInformation": null,
              "remarks": null,
              "allowingIncompleteRadioactivity": false,
              "radioactive": false,
              "radioactivity": null
            },
            {
              "type": "containerGood",
              "id": "977561cd-4f83-4c6e-bb64-3905369058d3",
              "goodData": {
                "type": "containerGood",
                "name": "Aluminium Smelting By-Products Or Aluminium Remelting By-Products",
                "unCode": "3170",
                "hazardClass": "4.3",
                "subsidiaryRisk": null,
                "emsFireCode": "F-G",
                "emsSpillageCode": "S-P",
                "properties": "Grey powder or lumps with some metallic inclusions. Contact with water may cause heating with possible evolution of flammable and toxic gases such as hydrogen and ammonia. This entry includes e.g. aluminium dross, aluminium skimmings, spent cathodes, spent potliner and aluminium salt slags.",
                "segregationGroup": "SGG15",
                "packingGroup": "MINOR_DANGER",
                "goodCode": "102343",
                "gdsCode": null,
                "stowageType": "container"
              },
              "flashPoint": null,
              "segregationInformation": null,
              "remarks": null,
              "allowingIncompleteRadioactivity": false,
              "radioactive": false,
              "radioactivity": null
            },
            {
              "type": "containerGood",
              "id": "48f90402-8d0e-4e1d-b54f-0eb537b7d406",
              "goodData": {
                "type": "containerGood",
                "name": "Aluminium Smelting By-Products Or Aluminium Remelting By-Products",
                "unCode": "3170",
                "hazardClass": "4.3",
                "subsidiaryRisk": null,
                "emsFireCode": "F-G",
                "emsSpillageCode": "S-P",
                "properties": "Grey powder or lumps with some metallic inclusions. Contact with water may cause heating with possible evolution of flammable and toxic gases such as hydrogen and ammonia. This entry includes e.g. aluminium dross, aluminium skimmings, spent cathodes, spent potliner and aluminium salt slags.",
                "segregationGroup": "SGG15",
                "packingGroup": "MINOR_DANGER",
                "goodCode": "102343",
                "gdsCode": null,
                "stowageType": "container"
              },
              "flashPoint": null,
              "segregationInformation": null,
              "remarks": null,
              "allowingIncompleteRadioactivity": false,
              "radioactive": false,
              "radioactivity": null
            },
            {
              "type": "containerGood",
              "id": "f07d1318-150d-4343-830c-65f800ce21bc",
              "goodData": {
                "type": "containerGood",
                "name": "Aluminium Smelting By-Products Or Aluminium Remelting By-Products",
                "unCode": "3170",
                "hazardClass": "4.3",
                "subsidiaryRisk": null,
                "emsFireCode": "F-G",
                "emsSpillageCode": "S-P",
                "properties": "Grey powder or lumps with some metallic inclusions. Contact with water may cause heating with possible evolution of flammable and toxic gases such as hydrogen and ammonia. This entry includes e.g. aluminium dross, aluminium skimmings, spent cathodes, spent potliner and aluminium salt slags.",
                "segregationGroup": "SGG15",
                "packingGroup": "MINOR_DANGER",
                "goodCode": "102343",
                "gdsCode": null,
                "stowageType": "container"
              },
              "flashPoint": null,
              "segregationInformation": null,
              "remarks": null,
              "allowingIncompleteRadioactivity": false,
              "radioactive": false,
              "radioactivity": null
            },
            {
              "type": "containerGood",
              "id": "a1fe2beb-914f-4133-9dea-5aa52a57b826",
              "goodData": {
                "type": "containerGood",
                "name": "Aluminium Smelting By-Products Or Aluminium Remelting By-Products",
                "unCode": "3170",
                "hazardClass": "4.3",
                "subsidiaryRisk": null,
                "emsFireCode": "F-G",
                "emsSpillageCode": "S-P",
                "properties": "Grey powder or lumps with some metallic inclusions. Contact with water may cause heating with possible evolution of flammable and toxic gases such as hydrogen and ammonia. This entry includes e.g. aluminium dross, aluminium skimmings, spent cathodes, spent potliner and aluminium salt slags.",
                "segregationGroup": "SGG15",
                "packingGroup": "MINOR_DANGER",
                "goodCode": "102343",
                "gdsCode": null,
                "stowageType": "container"
              },
              "flashPoint": null,
              "segregationInformation": null,
              "remarks": null,
              "allowingIncompleteRadioactivity": false,
              "radioactive": false,
              "radioactivity": null
            },
            {
              "type": "containerGood",
              "id": "6b4ed1de-49bc-4953-bb2f-6b4063c7b32a",
              "goodData": {
                "type": "containerGood",
                "name": "Aluminium Smelting By-Products Or Aluminium Remelting By-Products",
                "unCode": "3170",
                "hazardClass": "4.3",
                "subsidiaryRisk": null,
                "emsFireCode": "F-G",
                "emsSpillageCode": "S-P",
                "properties": "Grey powder or lumps with some metallic inclusions. Contact with water may cause heating with possible evolution of flammable and toxic gases such as hydrogen and ammonia. This entry includes e.g. aluminium dross, aluminium skimmings, spent cathodes, spent potliner and aluminium salt slags.",
                "segregationGroup": "SGG15",
                "packingGroup": "MINOR_DANGER",
                "goodCode": "102343",
                "gdsCode": null,
                "stowageType": "container"
              },
              "flashPoint": null,
              "segregationInformation": null,
              "remarks": null,
              "allowingIncompleteRadioactivity": false,
              "radioactive": false,
              "radioactivity": null
            },
            {
              "type": "containerGood",
              "id": "db9b83f4-4c54-4e6e-9b85-f22930e623b4",
              "goodData": {
                "type": "containerGood",
                "name": "Aluminium Smelting By-Products Or Aluminium Remelting By-Products",
                "unCode": "3170",
                "hazardClass": "4.3",
                "subsidiaryRisk": null,
                "emsFireCode": "F-G",
                "emsSpillageCode": "S-P",
                "properties": "Grey powder or lumps with some metallic inclusions. Contact with water may cause heating with possible evolution of flammable and toxic gases such as hydrogen and ammonia. This entry includes e.g. aluminium dross, aluminium skimmings, spent cathodes, spent potliner and aluminium salt slags.",
                "segregationGroup": "SGG15",
                "packingGroup": "MINOR_DANGER",
                "goodCode": "102343",
                "gdsCode": null,
                "stowageType": "container"
              },
              "flashPoint": null,
              "segregationInformation": null,
              "remarks": null,
              "allowingIncompleteRadioactivity": false,
              "radioactive": false,
              "radioactivity": null
            },
            {
              "type": "containerGood",
              "id": "e9ccd842-b97e-459e-a748-59827c70ec2b",
              "goodData": {
                "type": "containerGood",
                "name": "Aluminium Smelting By-Products Or Aluminium Remelting By-Products",
                "unCode": "3170",
                "hazardClass": "4.3",
                "subsidiaryRisk": null,
                "emsFireCode": "F-G",
                "emsSpillageCode": "S-P",
                "properties": "Grey powder or lumps with some metallic inclusions. Contact with water may cause heating with possible evolution of flammable and toxic gases such as hydrogen and ammonia. This entry includes e.g. aluminium dross, aluminium skimmings, spent cathodes, spent potliner and aluminium salt slags.",
                "segregationGroup": "SGG15",
                "packingGroup": "MINOR_DANGER",
                "goodCode": "102343",
                "gdsCode": null,
                "stowageType": "container"
              },
              "flashPoint": null,
              "segregationInformation": null,
              "remarks": null,
              "allowingIncompleteRadioactivity": false,
              "radioactive": false,
              "radioactivity": null
            },
            {
              "type": "containerGood",
              "id": "506bab04-e6c5-47a4-8235-c5e37981261c",
              "goodData": {
                "type": "containerGood",
                "name": "Aluminium Smelting By-Products Or Aluminium Remelting By-Products",
                "unCode": "3170",
                "hazardClass": "4.3",
                "subsidiaryRisk": null,
                "emsFireCode": "F-G",
                "emsSpillageCode": "S-P",
                "properties": "Grey powder or lumps with some metallic inclusions. Contact with water may cause heating with possible evolution of flammable and toxic gases such as hydrogen and ammonia. This entry includes e.g. aluminium dross, aluminium skimmings, spent cathodes, spent potliner and aluminium salt slags.",
                "segregationGroup": "SGG15",
                "packingGroup": "MINOR_DANGER",
                "goodCode": "102343",
                "gdsCode": null,
                "stowageType": "container"
              },
              "flashPoint": null,
              "segregationInformation": null,
              "remarks": null,
              "allowingIncompleteRadioactivity": false,
              "radioactive": false,
              "radioactivity": null
            },
            {
              "type": "containerGood",
              "id": "b3258a55-386a-419f-8989-b58ed02da79e",
              "goodData": {
                "type": "containerGood",
                "name": "Aluminium Smelting By-Products Or Aluminium Remelting By-Products",
                "unCode": "3170",
                "hazardClass": "4.3",
                "subsidiaryRisk": null,
                "emsFireCode": "F-G",
                "emsSpillageCode": "S-P",
                "properties": "Grey powder or lumps with some metallic inclusions. Contact with water may cause heating with possible evolution of flammable and toxic gases such as hydrogen and ammonia. This entry includes e.g. aluminium dross, aluminium skimmings, spent cathodes, spent potliner and aluminium salt slags.",
                "segregationGroup": "SGG15",
                "packingGroup": "MINOR_DANGER",
                "goodCode": "102343",
                "gdsCode": null,
                "stowageType": "container"
              },
              "flashPoint": null,
              "segregationInformation": null,
              "remarks": null,
              "allowingIncompleteRadioactivity": false,
              "radioactive": false,
              "radioactivity": null
            }
          ],
          "stowageAtArrival": [
            {
              "type": "container",
              "stowageNumber": "ALCU 4000247",
              "goodId": "6b1d4191-b557-40cd-aee7-5c0b0dfdc1d0",
              "portOfLoading": {
                "name": "Horten",
                "locationUnCode": "NOHOR",
                "countryUnCode": "NO",
                "euPort": false
              },
              "portOfDischarge": {
                "name": "Rotterdam",
                "locationUnCode": "NLRTM",
                "countryUnCode": "NL",
                "euPort": true
              },
              "weight": 3700,
              "trailer": false,
              "uncleanTankContainer": false,
              "position": "dek 000.00.01",
              "numberOfOuterPackages": 1,
              "outerPackageType": {
                "code": "CN",
                "name": "Container, not otherwise specified as transport equipment",
                "bulk": false
              },
              "numberOfInnerPackages": null,
              "innerPackageType": null,
              "transportInLimitedQuantity": false,
              "netWeight": null,
              "netExplosiveMass": null,
              "goodIdIsMissing": null
            },
            {
              "type": "container",
              "stowageNumber": "ALCU 4000057",
              "goodId": "036f6a8e-0d86-4609-9f97-d496549433c0",
              "portOfLoading": {
                "name": "Horten",
                "locationUnCode": "NOHOR",
                "countryUnCode": "NO",
                "euPort": false
              },
              "portOfDischarge": {
                "name": "Rotterdam",
                "locationUnCode": "NLRTM",
                "countryUnCode": "NL",
                "euPort": true
              },
              "weight": 3700,
              "trailer": false,
              "uncleanTankContainer": false,
              "position": "dek 000.00.02",
              "numberOfOuterPackages": 1,
              "outerPackageType": {
                "code": "CN",
                "name": "Container, not otherwise specified as transport equipment",
                "bulk": false
              },
              "numberOfInnerPackages": null,
              "innerPackageType": null,
              "transportInLimitedQuantity": false,
              "netWeight": null,
              "netExplosiveMass": null,
              "goodIdIsMissing": null
            },
            {
              "type": "container",
              "stowageNumber": "ALCU 4000421",
              "goodId": "ceef41bb-1296-417d-92c4-6dcfa0321d4d",
              "portOfLoading": {
                "name": "Horten",
                "locationUnCode": "NOHOR",
                "countryUnCode": "NO",
                "euPort": false
              },
              "portOfDischarge": {
                "name": "Rotterdam",
                "locationUnCode": "NLRTM",
                "countryUnCode": "NL",
                "euPort": true
              },
              "weight": 3700,
              "trailer": false,
              "uncleanTankContainer": false,
              "position": "dek 000.00.03",
              "numberOfOuterPackages": 1,
              "outerPackageType": {
                "code": "CN",
                "name": "Container, not otherwise specified as transport equipment",
                "bulk": false
              },
              "numberOfInnerPackages": null,
              "innerPackageType": null,
              "transportInLimitedQuantity": false,
              "netWeight": null,
              "netExplosiveMass": null,
              "goodIdIsMissing": null
            },
            {
              "type": "container",
              "stowageNumber": "ALCU 4000397",
              "goodId": "0b46bf29-fe19-4701-9170-8bff52f7e645",
              "portOfLoading": {
                "name": "Horten",
                "locationUnCode": "NOHOR",
                "countryUnCode": "NO",
                "euPort": false
              },
              "portOfDischarge": {
                "name": "Rotterdam",
                "locationUnCode": "NLRTM",
                "countryUnCode": "NL",
                "euPort": true
              },
              "weight": 3700,
              "trailer": false,
              "uncleanTankContainer": false,
              "position": "dek 000.00.04",
              "numberOfOuterPackages": 1,
              "outerPackageType": {
                "code": "CN",
                "name": "Container, not otherwise specified as transport equipment",
                "bulk": false
              },
              "numberOfInnerPackages": null,
              "innerPackageType": null,
              "transportInLimitedQuantity": false,
              "netWeight": null,
              "netExplosiveMass": null,
              "goodIdIsMissing": null
            },
            {
              "type": "container",
              "stowageNumber": "ALCU 3000387",
              "goodId": "756c0997-5d32-42d8-a8f6-4f4063af2eed",
              "portOfLoading": {
                "name": "Horten",
                "locationUnCode": "NOHOR",
                "countryUnCode": "NO",
                "euPort": false
              },
              "portOfDischarge": {
                "name": "Rotterdam",
                "locationUnCode": "NLRTM",
                "countryUnCode": "NL",
                "euPort": true
              },
              "weight": 3700,
              "trailer": false,
              "uncleanTankContainer": false,
              "position": "dek 000.00.05",
              "numberOfOuterPackages": 1,
              "outerPackageType": {
                "code": "CN",
                "name": "Container, not otherwise specified as transport equipment",
                "bulk": false
              },
              "numberOfInnerPackages": null,
              "innerPackageType": null,
              "transportInLimitedQuantity": false,
              "netWeight": null,
              "netExplosiveMass": null,
              "goodIdIsMissing": null
            },
            {
              "type": "container",
              "stowageNumber": "ALCU 4000479",
              "goodId": "735e0fbe-7fdd-4a0b-886b-8865a8bd46cd",
              "portOfLoading": {
                "name": "Horten",
                "locationUnCode": "NOHOR",
                "countryUnCode": "NO",
                "euPort": false
              },
              "portOfDischarge": {
                "name": "Rotterdam",
                "locationUnCode": "NLRTM",
                "countryUnCode": "NL",
                "euPort": true
              },
              "weight": 3700,
              "trailer": false,
              "uncleanTankContainer": false,
              "position": "dek 000.00.06",
              "numberOfOuterPackages": 1,
              "outerPackageType": {
                "code": "CN",
                "name": "Container, not otherwise specified as transport equipment",
                "bulk": false
              },
              "numberOfInnerPackages": null,
              "innerPackageType": null,
              "transportInLimitedQuantity": false,
              "netWeight": null,
              "netExplosiveMass": null,
              "goodIdIsMissing": null
            },
            {
              "type": "container",
              "stowageNumber": "ALCU 3000094",
              "goodId": "b306d7fe-6f80-4aa0-93a4-53f2c71f5a05",
              "portOfLoading": {
                "name": "Horten",
                "locationUnCode": "NOHOR",
                "countryUnCode": "NO",
                "euPort": false
              },
              "portOfDischarge": {
                "name": "Rotterdam",
                "locationUnCode": "NLRTM",
                "countryUnCode": "NL",
                "euPort": true
              },
              "weight": 3700,
              "trailer": false,
              "uncleanTankContainer": false,
              "position": "dek 000.00.07",
              "numberOfOuterPackages": 1,
              "outerPackageType": {
                "code": "CN",
                "name": "Container, not otherwise specified as transport equipment",
                "bulk": false
              },
              "numberOfInnerPackages": null,
              "innerPackageType": null,
              "transportInLimitedQuantity": false,
              "netWeight": null,
              "netExplosiveMass": null,
              "goodIdIsMissing": null
            },
            {
              "type": "container",
              "stowageNumber": "ALCU 3000047",
              "goodId": "977561cd-4f83-4c6e-bb64-3905369058d3",
              "portOfLoading": {
                "name": "Horten",
                "locationUnCode": "NOHOR",
                "countryUnCode": "NO",
                "euPort": false
              },
              "portOfDischarge": {
                "name": "Rotterdam",
                "locationUnCode": "NLRTM",
                "countryUnCode": "NL",
                "euPort": true
              },
              "weight": 3700,
              "trailer": false,
              "uncleanTankContainer": false,
              "position": "dek 000.00.08",
              "numberOfOuterPackages": 1,
              "outerPackageType": {
                "code": "CN",
                "name": "Container, not otherwise specified as transport equipment",
                "bulk": false
              },
              "numberOfInnerPackages": null,
              "innerPackageType": null,
              "transportInLimitedQuantity": false,
              "netWeight": null,
              "netExplosiveMass": null,
              "goodIdIsMissing": null
            },
            {
              "type": "container",
              "stowageNumber": "ALCU 3000279",
              "goodId": "48f90402-8d0e-4e1d-b54f-0eb537b7d406",
              "portOfLoading": {
                "name": "Horten",
                "locationUnCode": "NOHOR",
                "countryUnCode": "NO",
                "euPort": false
              },
              "portOfDischarge": {
                "name": "Rotterdam",
                "locationUnCode": "NLRTM",
                "countryUnCode": "NL",
                "euPort": true
              },
              "weight": 3700,
              "trailer": false,
              "uncleanTankContainer": false,
              "position": "dek 000.00.09",
              "numberOfOuterPackages": 1,
              "outerPackageType": {
                "code": "CN",
                "name": "Container, not otherwise specified as transport equipment",
                "bulk": false
              },
              "numberOfInnerPackages": null,
              "innerPackageType": null,
              "transportInLimitedQuantity": false,
              "netWeight": null,
              "netExplosiveMass": null,
              "goodIdIsMissing": null
            },
            {
              "type": "container",
              "stowageNumber": "ALCU 3000068",
              "goodId": "f07d1318-150d-4343-830c-65f800ce21bc",
              "portOfLoading": {
                "name": "Horten",
                "locationUnCode": "NOHOR",
                "countryUnCode": "NO",
                "euPort": false
              },
              "portOfDischarge": {
                "name": "Rotterdam",
                "locationUnCode": "NLRTM",
                "countryUnCode": "NL",
                "euPort": true
              },
              "weight": 3700,
              "trailer": false,
              "uncleanTankContainer": false,
              "position": "dek 000.00.10",
              "numberOfOuterPackages": 1,
              "outerPackageType": {
                "code": "CN",
                "name": "Container, not otherwise specified as transport equipment",
                "bulk": false
              },
              "numberOfInnerPackages": null,
              "innerPackageType": null,
              "transportInLimitedQuantity": false,
              "netWeight": null,
              "netExplosiveMass": null,
              "goodIdIsMissing": null
            },
            {
              "type": "container",
              "stowageNumber": "ALCU 4000170",
              "goodId": "a1fe2beb-914f-4133-9dea-5aa52a57b826",
              "portOfLoading": {
                "name": "Horten",
                "locationUnCode": "NOHOR",
                "countryUnCode": "NO",
                "euPort": false
              },
              "portOfDischarge": {
                "name": "Rotterdam",
                "locationUnCode": "NLRTM",
                "countryUnCode": "NL",
                "euPort": true
              },
              "weight": 3700,
              "trailer": false,
              "uncleanTankContainer": false,
              "position": "dek 000.00.11",
              "numberOfOuterPackages": 1,
              "outerPackageType": {
                "code": "CN",
                "name": "Container, not otherwise specified as transport equipment",
                "bulk": false
              },
              "numberOfInnerPackages": null,
              "innerPackageType": null,
              "transportInLimitedQuantity": false,
              "netWeight": null,
              "netExplosiveMass": null,
              "goodIdIsMissing": null
            },
            {
              "type": "container",
              "stowageNumber": "ALCU 3000330",
              "goodId": "6b4ed1de-49bc-4953-bb2f-6b4063c7b32a",
              "portOfLoading": {
                "name": "Horten",
                "locationUnCode": "NOHOR",
                "countryUnCode": "NO",
                "euPort": false
              },
              "portOfDischarge": {
                "name": "Rotterdam",
                "locationUnCode": "NLRTM",
                "countryUnCode": "NL",
                "euPort": true
              },
              "weight": 3700,
              "trailer": false,
              "uncleanTankContainer": false,
              "position": "dek 000.00.12",
              "numberOfOuterPackages": 1,
              "outerPackageType": {
                "code": "CN",
                "name": "Container, not otherwise specified as transport equipment",
                "bulk": false
              },
              "numberOfInnerPackages": null,
              "innerPackageType": null,
              "transportInLimitedQuantity": false,
              "netWeight": null,
              "netExplosiveMass": null,
              "goodIdIsMissing": null
            },
            {
              "type": "container",
              "stowageNumber": "ALCU 4000118",
              "goodId": "db9b83f4-4c54-4e6e-9b85-f22930e623b4",
              "portOfLoading": {
                "name": "Horten",
                "locationUnCode": "NOHOR",
                "countryUnCode": "NO",
                "euPort": false
              },
              "portOfDischarge": {
                "name": "Rotterdam",
                "locationUnCode": "NLRTM",
                "countryUnCode": "NL",
                "euPort": true
              },
              "weight": 3700,
              "trailer": false,
              "uncleanTankContainer": false,
              "position": "dek 000.00.12",
              "numberOfOuterPackages": 1,
              "outerPackageType": {
                "code": "CN",
                "name": "Container, not otherwise specified as transport equipment",
                "bulk": false
              },
              "numberOfInnerPackages": null,
              "innerPackageType": null,
              "transportInLimitedQuantity": false,
              "netWeight": null,
              "netExplosiveMass": null,
              "goodIdIsMissing": null
            },
            {
              "type": "container",
              "stowageNumber": "ALCU 3000010",
              "goodId": "e9ccd842-b97e-459e-a748-59827c70ec2b",
              "portOfLoading": {
                "name": "Horten",
                "locationUnCode": "NOHOR",
                "countryUnCode": "NO",
                "euPort": false
              },
              "portOfDischarge": {
                "name": "Rotterdam",
                "locationUnCode": "NLRTM",
                "countryUnCode": "NL",
                "euPort": true
              },
              "weight": 3700,
              "trailer": false,
              "uncleanTankContainer": false,
              "position": "dek 000.00.12",
              "numberOfOuterPackages": 1,
              "outerPackageType": {
                "code": "CN",
                "name": "Container, not otherwise specified as transport equipment",
                "bulk": false
              },
              "numberOfInnerPackages": null,
              "innerPackageType": null,
              "transportInLimitedQuantity": false,
              "netWeight": null,
              "netExplosiveMass": null,
              "goodIdIsMissing": null
            },
            {
              "type": "container",
              "stowageNumber": "ALCU 3000324",
              "goodId": "506bab04-e6c5-47a4-8235-c5e37981261c",
              "portOfLoading": {
                "name": "Horten",
                "locationUnCode": "NOHOR",
                "countryUnCode": "NO",
                "euPort": false
              },
              "portOfDischarge": {
                "name": "Rotterdam",
                "locationUnCode": "NLRTM",
                "countryUnCode": "NL",
                "euPort": true
              },
              "weight": 3700,
              "trailer": false,
              "uncleanTankContainer": false,
              "position": "dek 000.00.12",
              "numberOfOuterPackages": 1,
              "outerPackageType": {
                "code": "CN",
                "name": "Container, not otherwise specified as transport equipment",
                "bulk": false
              },
              "numberOfInnerPackages": null,
              "innerPackageType": null,
              "transportInLimitedQuantity": false,
              "netWeight": null,
              "netExplosiveMass": null,
              "goodIdIsMissing": null
            },
            {
              "type": "container",
              "stowageNumber": "ALCU 3000026",
              "goodId": "b3258a55-386a-419f-8989-b58ed02da79e",
              "portOfLoading": {
                "name": "Horten",
                "locationUnCode": "NOHOR",
                "countryUnCode": "NO",
                "euPort": false
              },
              "portOfDischarge": {
                "name": "Rotterdam",
                "locationUnCode": "NLRTM",
                "countryUnCode": "NL",
                "euPort": true
              },
              "weight": 3700,
              "trailer": false,
              "uncleanTankContainer": false,
              "position": "dek 000.00.12",
              "numberOfOuterPackages": 1,
              "outerPackageType": {
                "code": "CN",
                "name": "Container, not otherwise specified as transport equipment",
                "bulk": false
              },
              "numberOfInnerPackages": null,
              "innerPackageType": null,
              "transportInLimitedQuantity": false,
              "netWeight": null,
              "netExplosiveMass": null,
              "goodIdIsMissing": null
            }
          ],
          "handlings": []
        },
        "cargoDeclarant": {
          "fullName": "Wilson Agency B.V.",
          "shortName": "WILSONAGENCY",
          "portAuthorityId": "WILSON",
          "emailAddress": "rotterdam@wilsonagency.nl",
          "address": "Directiekade 15",
          "city": "Rotterdam",
          "countryUnCode": "NL",
          "countryName": "Netherlands",
          "zipCode": "3089 JA",
          "phoneNumber": "+31-10-2952882",
          "faxNumber": "+31-10-4722401",
          "contact": "Stefan Borghouts",
          "customsEORINumber": "NL812363760",
          "ean": null,
          "chamberOfCommerceNumber": "24351698",
          "scacCode": "WLSN",
          "ensEnabled": true,
          "cargoImportEnabled": true,
          "cargoImportEnabledByDefault": true
        },
        "visitDeclarant": {
          "fullName": "Wilson Agency B.V.",
          "shortName": "WILSONAGENCY",
          "portAuthorityId": "WILSON",
          "emailAddress": "rotterdam@wilsonagency.nl",
          "address": "Directiekade 15",
          "city": "Rotterdam",
          "countryUnCode": "NL",
          "countryName": "Netherlands",
          "zipCode": "3089 JA",
          "phoneNumber": "+31-10-2952882",
          "faxNumber": "+31-10-4722401",
          "contact": "Stefan Borghouts",
          "customsEORINumber": "NL812363760",
          "ean": null,
          "chamberOfCommerceNumber": "24351698",
          "scacCode": "WLSN",
          "ensEnabled": true,
          "cargoImportEnabled": true,
          "cargoImportEnabledByDefault": true
        },
        "visitOwner": {
          "fullName": "Wilson Agency B.V.",
          "shortName": "WILSONAGENCY",
          "portAuthorityId": "WILSON",
          "emailAddress": "rotterdam@wilsonagency.nl",
          "address": "Directiekade 15",
          "city": "Rotterdam",
          "countryUnCode": "NL",
          "countryName": "Netherlands",
          "zipCode": "3089 JA",
          "phoneNumber": "+31-10-2952882",
          "faxNumber": "+31-10-4722401",
          "contact": "Stefan Borghouts",
          "customsEORINumber": "NL812363760",
          "ean": null,
          "chamberOfCommerceNumber": "24351698",
          "scacCode": "WLSN",
          "ensEnabled": true,
          "cargoImportEnabled": true,
          "cargoImportEnabledByDefault": true
        },
        "upload": "96c9bb55-e7d8-4b9c-bdb7-02614fa22e04",
        "completed": true,
        "declared": true,
        "declaredByVisitDeclarant": true,
        "processIds": []
      }
    ],
    "nextDangerousGoodsDeclaration": null,
    "importDeclarations": [
      {
        "cargoDeclarant": {
          "fullName": "Wilson Agency B.V.",
          "shortName": "WILSONAGENCY",
          "portAuthorityId": "WILSON",
          "emailAddress": "rotterdam@wilsonagency.nl",
          "address": "Directiekade 15",
          "city": "Rotterdam",
          "countryUnCode": "NL",
          "countryName": "Netherlands",
          "zipCode": "3089 JA",
          "phoneNumber": "+31-10-2952882",
          "faxNumber": "+31-10-4722401",
          "contact": "Stefan Borghouts",
          "customsEORINumber": "NL812363760",
          "ean": null,
          "chamberOfCommerceNumber": "24351698",
          "scacCode": "WLSN",
          "ensEnabled": true,
          "cargoImportEnabled": true,
          "cargoImportEnabledByDefault": true
        },
        "timestamp": "2021-07-12T16:36:02.497Z",
        "containers": [
          {
            "number": "ALCU3000094",
            "portOfLoading": {
              "name": "Horten",
              "locationUnCode": "NOHOR",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-07-09T10:00:00Z",
            "terminal": {
              "terminalName": "FRISOH STEINWEG OR",
              "terminalCode": "1238",
              "organisationShortName": "STEINWEG",
              "organisationName": "Steinweg B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22T0",
              "name": "20'x8'x8'6\" Tank Container Test pressure 0,45 bar"
            },
            "containerOperator": {
              "customsId": "NL823371888",
              "name": "Wilson Eurocarriers A.S.",
              "scacCode": "WECN",
              "smdgCode": null,
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "ALCU3000387",
            "portOfLoading": {
              "name": "Horten",
              "locationUnCode": "NOHOR",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-07-09T10:00:00Z",
            "terminal": {
              "terminalName": "FRISOH STEINWEG OR",
              "terminalCode": "1238",
              "organisationShortName": "STEINWEG",
              "organisationName": "Steinweg B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22T0",
              "name": "20'x8'x8'6\" Tank Container Test pressure 0,45 bar"
            },
            "containerOperator": {
              "customsId": "NL823371888",
              "name": "Wilson Eurocarriers A.S.",
              "scacCode": "WECN",
              "smdgCode": null,
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "ALCU4000057",
            "portOfLoading": {
              "name": "Horten",
              "locationUnCode": "NOHOR",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-07-09T10:00:00Z",
            "terminal": {
              "terminalName": "FRISOH STEINWEG OR",
              "terminalCode": "1238",
              "organisationShortName": "STEINWEG",
              "organisationName": "Steinweg B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22T0",
              "name": "20'x8'x8'6\" Tank Container Test pressure 0,45 bar"
            },
            "containerOperator": {
              "customsId": "NL812363760",
              "name": "Wilson Agency B.V.",
              "scacCode": "WLSN",
              "smdgCode": null,
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "ALCU4000247",
            "portOfLoading": {
              "name": "Horten",
              "locationUnCode": "NOHOR",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-07-09T10:00:00Z",
            "terminal": {
              "terminalName": "FRISOH STEINWEG OR",
              "terminalCode": "1238",
              "organisationShortName": "STEINWEG",
              "organisationName": "Steinweg B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22T0",
              "name": "20'x8'x8'6\" Tank Container Test pressure 0,45 bar"
            },
            "containerOperator": {
              "customsId": "NL812363760",
              "name": "Wilson Agency B.V.",
              "scacCode": "WLSN",
              "smdgCode": null,
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "ALCU4000397",
            "portOfLoading": {
              "name": "Horten",
              "locationUnCode": "NOHOR",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-07-09T10:00:00Z",
            "terminal": {
              "terminalName": "FRISOH STEINWEG OR",
              "terminalCode": "1238",
              "organisationShortName": "STEINWEG",
              "organisationName": "Steinweg B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22T0",
              "name": "20'x8'x8'6\" Tank Container Test pressure 0,45 bar"
            },
            "containerOperator": {
              "customsId": "NL823371888",
              "name": "Wilson Eurocarriers A.S.",
              "scacCode": "WECN",
              "smdgCode": null,
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "ALCU4000421",
            "portOfLoading": {
              "name": "Horten",
              "locationUnCode": "NOHOR",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-07-09T10:00:00Z",
            "terminal": {
              "terminalName": "FRISOH STEINWEG OR",
              "terminalCode": "1238",
              "organisationShortName": "STEINWEG",
              "organisationName": "Steinweg B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22T0",
              "name": "20'x8'x8'6\" Tank Container Test pressure 0,45 bar"
            },
            "containerOperator": {
              "customsId": "NL823371888",
              "name": "Wilson Eurocarriers A.S.",
              "scacCode": "WECN",
              "smdgCode": null,
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "ALCU4000479",
            "portOfLoading": {
              "name": "Horten",
              "locationUnCode": "NOHOR",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-07-09T10:00:00Z",
            "terminal": {
              "terminalName": "FRISOH STEINWEG OR",
              "terminalCode": "1238",
              "organisationShortName": "STEINWEG",
              "organisationName": "Steinweg B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22T0",
              "name": "20'x8'x8'6\" Tank Container Test pressure 0,45 bar"
            },
            "containerOperator": {
              "customsId": "NL823371888",
              "name": "Wilson Eurocarriers A.S.",
              "scacCode": "WECN",
              "smdgCode": null,
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "ALCU3000010",
            "portOfLoading": {
              "name": "Horten",
              "locationUnCode": "NOHOR",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-07-09T10:00:00Z",
            "terminal": {
              "terminalName": "FRISOH STEINWEG OR",
              "terminalCode": "1238",
              "organisationShortName": "STEINWEG",
              "organisationName": "Steinweg B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22T0",
              "name": "20'x8'x8'6\" Tank Container Test pressure 0,45 bar"
            },
            "containerOperator": {
              "customsId": "NL823371888",
              "name": "Wilson Eurocarriers A.S.",
              "scacCode": "WECN",
              "smdgCode": null,
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "ALCU3000026",
            "portOfLoading": {
              "name": "Horten",
              "locationUnCode": "NOHOR",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-07-09T10:00:00Z",
            "terminal": {
              "terminalName": "FRISOH STEINWEG OR",
              "terminalCode": "1238",
              "organisationShortName": "STEINWEG",
              "organisationName": "Steinweg B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22T0",
              "name": "20'x8'x8'6\" Tank Container Test pressure 0,45 bar"
            },
            "containerOperator": {
              "customsId": "NL823371888",
              "name": "Wilson Eurocarriers A.S.",
              "scacCode": "WECN",
              "smdgCode": null,
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "ALCU3000068",
            "portOfLoading": {
              "name": "Horten",
              "locationUnCode": "NOHOR",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-07-09T10:00:00Z",
            "terminal": {
              "terminalName": "FRISOH STEINWEG OR",
              "terminalCode": "1238",
              "organisationShortName": "STEINWEG",
              "organisationName": "Steinweg B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22T0",
              "name": "20'x8'x8'6\" Tank Container Test pressure 0,45 bar"
            },
            "containerOperator": {
              "customsId": "NL823371888",
              "name": "Wilson Eurocarriers A.S.",
              "scacCode": "WECN",
              "smdgCode": null,
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "ALCU3000279",
            "portOfLoading": {
              "name": "Horten",
              "locationUnCode": "NOHOR",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-07-09T10:00:00Z",
            "terminal": {
              "terminalName": "FRISOH STEINWEG OR",
              "terminalCode": "1238",
              "organisationShortName": "STEINWEG",
              "organisationName": "Steinweg B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22T0",
              "name": "20'x8'x8'6\" Tank Container Test pressure 0,45 bar"
            },
            "containerOperator": {
              "customsId": "NL823371888",
              "name": "Wilson Eurocarriers A.S.",
              "scacCode": "WECN",
              "smdgCode": null,
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "ALCU3000324",
            "portOfLoading": {
              "name": "Horten",
              "locationUnCode": "NOHOR",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-07-09T10:00:00Z",
            "terminal": {
              "terminalName": "FRISOH STEINWEG OR",
              "terminalCode": "1238",
              "organisationShortName": "STEINWEG",
              "organisationName": "Steinweg B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22T0",
              "name": "20'x8'x8'6\" Tank Container Test pressure 0,45 bar"
            },
            "containerOperator": {
              "customsId": "NL823371888",
              "name": "Wilson Eurocarriers A.S.",
              "scacCode": "WECN",
              "smdgCode": null,
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "ALCU3000330",
            "portOfLoading": {
              "name": "Horten",
              "locationUnCode": "NOHOR",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-07-09T10:00:00Z",
            "terminal": {
              "terminalName": "FRISOH STEINWEG OR",
              "terminalCode": "1238",
              "organisationShortName": "STEINWEG",
              "organisationName": "Steinweg B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22T0",
              "name": "20'x8'x8'6\" Tank Container Test pressure 0,45 bar"
            },
            "containerOperator": {
              "customsId": "NL823371888",
              "name": "Wilson Eurocarriers A.S.",
              "scacCode": "WECN",
              "smdgCode": null,
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "ALCU33000047",
            "portOfLoading": {
              "name": "Horten",
              "locationUnCode": "NOHOR",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-07-09T10:00:00Z",
            "terminal": {
              "terminalName": "FRISOH STEINWEG OR",
              "terminalCode": "1238",
              "organisationShortName": "STEINWEG",
              "organisationName": "Steinweg B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22T0",
              "name": "20'x8'x8'6\" Tank Container Test pressure 0,45 bar"
            },
            "containerOperator": {
              "customsId": "NL823371888",
              "name": "Wilson Eurocarriers A.S.",
              "scacCode": "WECN",
              "smdgCode": null,
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "ALCU4000118",
            "portOfLoading": {
              "name": "Horten",
              "locationUnCode": "NOHOR",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-07-09T10:00:00Z",
            "terminal": {
              "terminalName": "FRISOH STEINWEG OR",
              "terminalCode": "1238",
              "organisationShortName": "STEINWEG",
              "organisationName": "Steinweg B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22T0",
              "name": "20'x8'x8'6\" Tank Container Test pressure 0,45 bar"
            },
            "containerOperator": {
              "customsId": "NL823371888",
              "name": "Wilson Eurocarriers A.S.",
              "scacCode": "WECN",
              "smdgCode": null,
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          },
          {
            "number": "ALCU4000170",
            "portOfLoading": {
              "name": "Horten",
              "locationUnCode": "NOHOR",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-07-09T10:00:00Z",
            "terminal": {
              "terminalName": "FRISOH STEINWEG OR",
              "terminalCode": "1238",
              "organisationShortName": "STEINWEG",
              "organisationName": "Steinweg B.V."
            },
            "type": "CONTAINER",
            "sizeType": {
              "code": "22T0",
              "name": "20'x8'x8'6\" Tank Container Test pressure 0,45 bar"
            },
            "containerOperator": {
              "customsId": "NL823371888",
              "name": "Wilson Eurocarriers A.S.",
              "scacCode": "WECN",
              "smdgCode": null,
              "cargoDeclarantShortName": null
            },
            "tareWeight": null,
            "bookingReference": null,
            "shipperSealNumber": null,
            "carrierSealNumber": null,
            "customsSealNumber": null,
            "shippersOwned": false,
            "empty": false,
            "temperature": null,
            "overlength": null,
            "overheight": null,
            "overwidth": null,
            "oversizeRemarks": null,
            "onCarriageDetails": null,
            "initialDemurrage": null,
            "redeliveryAddress": null
          }
        ],
        "consignments": [
          {
            "consignmentNumber": "WLSN21016508A1",
            "portOfLoading": {
              "name": "Horten",
              "locationUnCode": "NOHOR",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-07-09T10:00:00Z",
            "terminal": {
              "terminalName": "FRISOH STEINWEG OR",
              "terminalCode": "1238",
              "organisationShortName": "STEINWEG",
              "organisationName": "Steinweg B.V."
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "Alcoa Fjardall sf",
              "address": "Hraun 1",
              "zipCode": "730",
              "city": "Reydarfjordur",
              "countryCode": "IS",
              "id": "a78d08e5-5897-4830-a45a-acc1f01c7263"
            },
            "consignee": {
              "eoriNumber": null,
              "name": "Wilson Agency B.V.",
              "address": "Directiekade 15",
              "zipCode": "3089 JA",
              "city": "Rotterdam",
              "countryCode": "NL",
              "id": "fe24d4ec-9f72-45b7-a575-4907161f883e"
            },
            "partiesToNotify": [
              {
                "eoriNumber": null,
                "name": "Alcoa C/O Steinweg",
                "address": ".",
                "zipCode": ".",
                "city": "Rotterdam",
                "countryCode": "NL",
                "id": "f2ab7445-1c1a-4752-82ef-f04a997dbe4e"
              }
            ],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": null,
            "onCarriageDetails": null,
            "agentVoyageNumber": null,
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "Empty uncleaned and residue last contained",
                "grossWeight": 59200,
                "numberOfOuterPackages": 16,
                "outerPackageType": {
                  "code": "CN",
                  "name": "Container",
                  "bulk": false
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [
                  {
                    "equipmentNumber": "ALCU4000247",
                    "numberOfPackages": 1,
                    "grossWeight": 3700
                  },
                  {
                    "equipmentNumber": "ALCU4000057",
                    "numberOfPackages": 1,
                    "grossWeight": 3700
                  },
                  {
                    "equipmentNumber": "ALCU4000421",
                    "numberOfPackages": 1,
                    "grossWeight": 3700
                  },
                  {
                    "equipmentNumber": "ALCU4000397",
                    "numberOfPackages": 1,
                    "grossWeight": 3700
                  },
                  {
                    "equipmentNumber": "ALCU3000387",
                    "numberOfPackages": 1,
                    "grossWeight": 3700
                  },
                  {
                    "equipmentNumber": "ALCU4000479",
                    "numberOfPackages": 1,
                    "grossWeight": 3700
                  },
                  {
                    "equipmentNumber": "ALCU3000094",
                    "numberOfPackages": 1,
                    "grossWeight": 3700
                  },
                  {
                    "equipmentNumber": "ALCU33000047",
                    "numberOfPackages": 1,
                    "grossWeight": 3700
                  },
                  {
                    "equipmentNumber": "ALCU3000279",
                    "numberOfPackages": 1,
                    "grossWeight": 3700
                  },
                  {
                    "equipmentNumber": "ALCU3000068",
                    "numberOfPackages": 1,
                    "grossWeight": 3700
                  },
                  {
                    "equipmentNumber": "ALCU4000170",
                    "numberOfPackages": 1,
                    "grossWeight": 3700
                  },
                  {
                    "equipmentNumber": "ALCU3000330",
                    "numberOfPackages": 1,
                    "grossWeight": 3700
                  },
                  {
                    "equipmentNumber": "ALCU4000118",
                    "numberOfPackages": 1,
                    "grossWeight": 3700
                  },
                  {
                    "equipmentNumber": "ALCU3000010",
                    "numberOfPackages": 1,
                    "grossWeight": 3700
                  },
                  {
                    "equipmentNumber": "ALCU3000324",
                    "numberOfPackages": 1,
                    "grossWeight": 3700
                  },
                  {
                    "equipmentNumber": "ALCU3000026",
                    "numberOfPackages": 1,
                    "grossWeight": 3700
                  }
                ],
                "producedDocuments": []
              }
            ]
          },
          {
            "consignmentNumber": "WLSN21016508A2",
            "portOfLoading": {
              "name": "Fredrikstad",
              "locationUnCode": "NOFRK",
              "countryUnCode": "NO",
              "euPort": false
            },
            "portOfDischarge": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euPort": true
            },
            "actualDeparture": "2021-07-09T10:00:00Z",
            "terminal": {
              "terminalName": "MERWEH V UDEN",
              "terminalCode": "4908",
              "organisationShortName": "VANUDENSTEVE",
              "organisationName": "Van Uden Stevedoring B.V."
            },
            "movementReferenceNumbers": [],
            "partShipment": false,
            "movementType": null,
            "customsStatus": null,
            "customsProcess": null,
            "warehouseLicense": null,
            "bulkAuthorisations": [],
            "exchangeRate": null,
            "consignor": {
              "eoriNumber": null,
              "name": "Kronos Titan AS",
              "address": "Postbox 1415",
              "zipCode": "N-1602",
              "city": "Fredrikstad",
              "countryCode": "NO",
              "id": "e3c83f77-9af7-4f0b-9535-5fc153d6ad59"
            },
            "consignee": {
              "eoriNumber": null,
              "name": "GUD Logport Duisburg",
              "address": "Gaterweg 210",
              "zipCode": "47229",
              "city": "Duisburg",
              "countryCode": "DE",
              "id": "e8e969c1-c880-42d3-8fdf-db220149a06e"
            },
            "partiesToNotify": [
              {
                "eoriNumber": null,
                "name": "Lanxess Deutschland GmbH",
                "address": "Kennedyplatz 1",
                "zipCode": "50569",
                "city": "Köln",
                "countryCode": "DE",
                "id": "829a723e-6e1c-4242-8484-20fea5d80992"
              }
            ],
            "placeOfOrigin": null,
            "placeOfDestination": null,
            "placeOfDelivery": null,
            "onCarriageDetails": null,
            "agentVoyageNumber": null,
            "goodsItems": [
              {
                "itemNumber": 1,
                "description": "Ironsulphate (Quickfloc ferrous sulphate)",
                "grossWeight": 2232700,
                "numberOfOuterPackages": 1,
                "outerPackageType": {
                  "code": "VO",
                  "name": "Bulk, solid, large particles",
                  "bulk": true
                },
                "netWeight": null,
                "numberOfInnerPackages": null,
                "innerPackageType": null,
                "classification": null,
                "dangerInformation": null,
                "marksAndNumbers": [],
                "minimumTemperature": null,
                "maximumTemperature": null,
                "placements": [],
                "producedDocuments": []
              }
            ]
          }
        ],
        "receivedMrns": {},
        "discharges": [],
        "bulkDischarges": [],
        "consignmentDifferences": [],
        "clearedManifestIds": [
          "NOFRK"
        ],
        "inspectionUpdates": [],
        "declarations": [
          {
            "type": "SDT",
            "id": "NOHOR",
            "status": "ACCEPTED",
            "rejectReasons": null,
            "timeStamp": "2021-07-09T14:00:41.275Z"
          },
          {
            "type": "SDT",
            "id": "NOHOR",
            "status": "ACCEPTED",
            "rejectReasons": null,
            "timeStamp": "2021-07-09T14:06:47.378Z"
          },
          {
            "type": "SDT",
            "id": "NOHOR",
            "status": "ACCEPTED",
            "rejectReasons": null,
            "timeStamp": "2021-07-12T07:25:07.938Z"
          },
          {
            "type": "SDT",
            "id": "NOFRK",
            "status": "ACCEPTED",
            "rejectReasons": null,
            "timeStamp": "2021-07-12T08:09:49.971Z"
          },
          {
            "type": "SDT",
            "id": "NOHOR",
            "status": "ACCEPTED",
            "rejectReasons": null,
            "timeStamp": "2021-07-12T08:09:49.971Z"
          }
        ],
        "incomingMessages": []
      }
    ],
    "loadingDeclarations": [],
    "paxDeclarationSummaries": [
      {
        "numberOfCrew": 7,
        "numberOfPassengers": 0,
        "numberOfCrewOtherShip": 0,
        "numberOfStowaways": 0,
        "searchAndRescue": null
      }
    ],
    "healthDeclarationSummary": null,
    "etaPortAis": null,
    "ignoreEtaPortAis": false,
    "cancelled": false,
    "ataRegisteredByPa": true,
    "visitKnownAtPa": true,
    "dangerousGoodsEnabled": true,
    "cargoImportEnabled": true,
    "ataAcknowledged": true,
    "declarations": [
      {
        "type": "VISIT",
        "id": null,
        "status": "ACCEPTED",
        "rejectReasons": null,
        "timeStamp": "2021-07-09T12:11:14.847Z"
      },
      {
        "type": "WPCS",
        "id": null,
        "status": "DECLARED",
        "rejectReasons": null,
        "timeStamp": "2021-07-09T12:11:17.288Z"
      },
      {
        "type": "SECURITY",
        "id": null,
        "status": "ACCEPTED",
        "rejectReasons": null,
        "timeStamp": "2021-07-09T12:11:18.684Z"
      },
      {
        "type": "PAX",
        "id": null,
        "status": "ACCEPTED",
        "rejectReasons": null,
        "timeStamp": "2021-07-09T12:11:26.141Z"
      },
      {
        "type": "WASTE",
        "id": null,
        "status": "ACCEPTED",
        "rejectReasons": null,
        "timeStamp": "2021-07-09T12:14:25.987Z"
      },
      {
        "type": "STO",
        "id": null,
        "status": "ACCEPTED",
        "rejectReasons": null,
        "timeStamp": "2021-07-09T12:14:31.150Z"
      },
      {
        "type": "VISIT",
        "id": null,
        "status": "ACCEPTED",
        "rejectReasons": null,
        "timeStamp": "2021-07-09T12:57:32.858Z"
      },
      {
        "type": "VISIT",
        "id": null,
        "status": "ACCEPTED",
        "rejectReasons": null,
        "timeStamp": "2021-07-09T13:31:42.215Z"
      },
      {
        "type": "WPCS",
        "id": null,
        "status": "DECLARED",
        "rejectReasons": null,
        "timeStamp": "2021-07-09T13:31:42.301Z"
      },
      {
        "type": "VISIT",
        "id": null,
        "status": "ACCEPTED",
        "rejectReasons": null,
        "timeStamp": "2021-07-09T13:51:09.356Z"
      },
      {
        "type": "WPCS",
        "id": null,
        "status": "DECLARED",
        "rejectReasons": null,
        "timeStamp": "2021-07-09T13:51:09.678Z"
      },
      {
        "type": "SECURITY",
        "id": null,
        "status": "ACCEPTED",
        "rejectReasons": null,
        "timeStamp": "2021-07-09T13:51:14.054Z"
      },
      {
        "type": "VISIT",
        "id": null,
        "status": "ACCEPTED",
        "rejectReasons": null,
        "timeStamp": "2021-07-09T13:54:10.346Z"
      },
      {
        "type": "DANGEROUS_GOODS",
        "id": null,
        "status": "ACCEPTED",
        "rejectReasons": null,
        "timeStamp": "2021-07-09T14:12:53.953Z"
      },
      {
        "type": "VISIT",
        "id": null,
        "status": "ACCEPTED",
        "rejectReasons": null,
        "timeStamp": "2021-07-11T07:00:21.164Z"
      },
      {
        "type": "VISIT",
        "id": null,
        "status": "ACCEPTED",
        "rejectReasons": null,
        "timeStamp": "2021-07-11T18:09:24.005Z"
      },
      {
        "type": "WPCS",
        "id": null,
        "status": "DECLARED",
        "rejectReasons": null,
        "timeStamp": "2021-07-11T18:09:24.104Z"
      },
      {
        "type": "WPCS",
        "id": null,
        "status": "DECLARED",
        "rejectReasons": null,
        "timeStamp": "2021-07-11T18:56:12.705Z"
      },
      {
        "type": "VISIT",
        "id": null,
        "status": "ACCEPTED",
        "rejectReasons": null,
        "timeStamp": "2021-07-11T20:54:48.954Z"
      },
      {
        "type": "VISIT",
        "id": null,
        "status": "ACCEPTED",
        "rejectReasons": null,
        "timeStamp": "2021-07-11T20:56:04.428Z"
      },
      {
        "type": "WPCS",
        "id": null,
        "status": "DECLARED",
        "rejectReasons": null,
        "timeStamp": "2021-07-11T23:25:02.136Z"
      },
      {
        "type": "WPCS",
        "id": null,
        "status": "DECLARED",
        "rejectReasons": null,
        "timeStamp": "2021-07-12T07:08:13.463Z"
      },
      {
        "type": "WPCS",
        "id": null,
        "status": "DECLARED",
        "rejectReasons": null,
        "timeStamp": "2021-07-12T08:07:50.695Z"
      },
      {
        "type": "WASTE",
        "id": null,
        "status": "ACCEPTED",
        "rejectReasons": null,
        "timeStamp": "2021-07-12T08:08:36.902Z"
      },
      {
        "type": "VISIT",
        "id": null,
        "status": "ACCEPTED",
        "rejectReasons": null,
        "timeStamp": "2021-07-12T08:08:53.764Z"
      },
      {
        "type": "WPCS",
        "id": null,
        "status": "DECLARED",
        "rejectReasons": null,
        "timeStamp": "2021-07-12T08:08:53.914Z"
      },
      {
        "type": "SECURITY",
        "id": null,
        "status": "ACCEPTED",
        "rejectReasons": null,
        "timeStamp": "2021-07-12T08:09:35.866Z"
      },
      {
        "type": "WPCS",
        "id": null,
        "status": "DECLARED",
        "rejectReasons": null,
        "timeStamp": "2021-07-12T08:09:35.952Z"
      },
      {
        "type": "VISIT",
        "id": null,
        "status": "ACCEPTED",
        "rejectReasons": null,
        "timeStamp": "2021-07-12T08:51:47.910Z"
      },
      {
        "type": "WPCS",
        "id": null,
        "status": "DECLARED",
        "rejectReasons": null,
        "timeStamp": "2021-07-12T08:51:48.198Z"
      },
      {
        "type": "WPCS",
        "id": null,
        "status": "DECLARED",
        "rejectReasons": null,
        "timeStamp": "2021-07-12T09:38:47.755Z"
      },
      {
        "type": "VISIT",
        "id": null,
        "status": "ACCEPTED",
        "rejectReasons": null,
        "timeStamp": "2021-07-12T12:32:42.672Z"
      },
      {
        "type": "WPCS",
        "id": null,
        "status": "DECLARED",
        "rejectReasons": null,
        "timeStamp": "2021-07-12T12:32:42.769Z"
      },
      {
        "type": "WPCS",
        "id": null,
        "status": "DECLARED",
        "rejectReasons": null,
        "timeStamp": "2021-07-12T13:46:42.162Z"
      },
      {
        "type": "WPCS",
        "id": null,
        "status": "DECLARED",
        "rejectReasons": null,
        "timeStamp": "2021-07-12T13:47:06.135Z"
      },
      {
        "type": "VISIT",
        "id": null,
        "status": "ACCEPTED",
        "rejectReasons": null,
        "timeStamp": "2021-07-12T15:25:48.766Z"
      },
      {
        "type": "WPCS",
        "id": null,
        "status": "DECLARED",
        "rejectReasons": null,
        "timeStamp": "2021-07-12T15:25:48.932Z"
      },
      {
        "type": "WPCS",
        "id": null,
        "status": "DECLARED",
        "rejectReasons": null,
        "timeStamp": "2021-07-12T15:52:19.338Z"
      },
      {
        "type": "WPCS",
        "id": null,
        "status": "DECLARED",
        "rejectReasons": null,
        "timeStamp": "2021-07-12T23:56:16.641Z"
      },
      {
        "type": "WPCS",
        "id": null,
        "status": "DECLARED",
        "rejectReasons": null,
        "timeStamp": "2021-07-13T07:34:56.857Z"
      }
    ],
    "uploads": {
      "SECURITY": "31fa5209-7bee-4f24-97be-074ef07a4b69",
      "WASTE": "1778f74a-ec7c-44a4-a247-3a87ef27489d",
      "STO": "bf142d3e-3168-4abd-919b-b5512ea433ea"
    },
    "additionalIncomingMovementInfo": {
      "etaPilotBoardingPlace": "2021-07-11T21:00:00Z",
      "plannedNumberOfTugboats": null,
      "shifted": false,
      "etdChanged": false,
      "etaChanged": false,
      "entryDependencyRemarks": null
    },
    "berthVisitInfos": {
      "dd1f5ffa-81fa-3912-aff4-07c538ce37b1": <BerthVisitInfo>{
        "carrierInfo": null,
        "terminalInfo": null,
        "harbourMasterInfo": {
          "orderStatus": "CONFIRMED",
          "eta": "2021-07-11T23:25:00Z",
          "etd": "2021-07-12T13:00:00Z",
          "pilotOnBoard": null,
          "rejectionReason": null,
          "remarks": null,
          "movementOnHold": false,
          "tidalWindowStatus": null
        },
        "originalEditors": []
      },
      "9e088ad1-06c0-4089-a8ab-e28894a54ce1": <BerthVisitInfo>{
        "carrierInfo": null,
        "terminalInfo": null,
        "harbourMasterinfo": {
          "orderStatus": "CONFIRMED",
          "etd": "2021-07-12T16:00:00Z",
          "pilotOnBoard": null,
          "rejectionReason": null,
          "remarks": null,
          "movementOnHold": false,
          "tidalWindowStatus": null
        },
        "originalEditors": []
      }
    },
    "incomingMovementHarbourMasterInfo": {
      "orderStatus": "CONFIRMED",
      "pilotOnBoard": null,
      "rejectionReason": null,
      "remarks": {
        "text": "paalnr's aub"
      },
      "movementOnHold": false
    },
    "incomingTidalWindowStatus": null,
    "lastMessageId": "d394051e-6da3-4f14-9f83-b56928265d1a",
    "created": "2021-07-09T12:06:51.258Z",
    "updated": "2021-07-13T07:34:59.014Z",
    "berthVisitIdOfTransfer": null,
    "hasBeenTransferred": false,
    "allowedViewers": [
      {
        "fullName": "Wilson Agency B.V.",
        "shortName": "WILSONAGENCY",
        "portAuthorityId": "WILSON",
        "emailAddress": "rotterdam@wilsonagency.nl",
        "address": "Directiekade 15",
        "city": "Rotterdam",
        "countryUnCode": "NL",
        "countryName": "Netherlands",
        "zipCode": "3089 JA",
        "phoneNumber": "+31-10-2952882",
        "faxNumber": "+31-10-4722401",
        "contact": "Stefan Borghouts",
        "customsEORINumber": "NL812363760",
        "ean": null,
        "chamberOfCommerceNumber": "24351698",
        "scacCode": "WLSN",
        "ensEnabled": true,
        "cargoImportEnabled": true,
        "cargoImportEnabledByDefault": true
      },
      {
        "fullName": "Havenbedrijf Rotterdam N.V.",
        "shortName": "HBRRTM",
        "portAuthorityId": "HbR",
        "emailAddress": "HCC@portofrotterdam.com",
        "address": "Wilhelminakade 909",
        "city": "Rotterdam",
        "countryUnCode": "NL",
        "countryName": "Netherlands",
        "zipCode": "3072 AP",
        "phoneNumber": "+31-10-2521195",
        "faxNumber": "+31-10-2521000",
        "contact": "Diana van Eijk",
        "customsEORINumber": "NL24354561",
        "ean": null,
        "chamberOfCommerceNumber": null,
        "scacCode": null,
        "ensEnabled": false,
        "cargoImportEnabled": false,
        "cargoImportEnabledByDefault": false
      }
    ],
    "previousMessageId": "3a3b8356-3092-4eb1-a3e8-8d273f7548ad",
    "nextMessageId": null,
    "visitStatus": "DEPARTED"
  },
  NLRTM58016507: {
    'crn': 'NLRTM58016507',
    'cargoImportEnabled': true,
    'orderIncomingMovement': true,
    'updated': '2020-09-10T01:36:00.896Z',
    'importDeclarations': [],
    "loadingDeclarations": [],
    "incomingTidalWindowStatus": null,
    'berthVisitInfos': null,
    'visitDeclaration': {
      'portVisit': {
        'etaPort': '2018-11-09T10:00:00Z',
        'firstMovement': null,
        "portEntry":
          {
            "earliestTimeOfPortEntry": "2021-11-26T04:00:00Z",
            "etaPilotBoardingPlace": "2021-11-28T04:00:00Z",
            "requestedEtaPilotBoardingPlace": "2021-11-28T04:00:00Z",
            "baseForPlanning": "PILOT_BOARDING_PLACE",
            "intention": "REQUEST_FOR_ENTRY",
            "origin": "SEA",
            "etaSeaBuoy": "2021-11-08T06:00:00Z",
            "entryDependency": {
              "vesselName": "Lenski",
              "vesselImoCode": "2342556",
              "dependencyType": "EXCHANGE",
              "orderStatus": "ORDERED",
              "autoOrder": true
            }
          },
        "berthVisits": [{
          'id': '1abbb8789fe94b5c8adad25550a1a6b1',
          'terminal': {
            'terminalCode': '9898',
            'terminalName': 'RST DS01',
            'organisationShortName': 'RST',
            'organisationName': 'ATM'
          },
          'stevedore': {
            'shortName': 'RST',
            'fullName': 'ATM',
            "location": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM"
            },
            "quaysForPlanning": ["RST"]
          },
          'vesselServiceCode': null,
          'vesselServiceName': null,
          'requestedEta': '2019-02-10T06:30:00Z',
          'requestedEtd': '2019-02-12T06:30:00Z',
          'discharge': 30,
          'load': 0,
          'bollardFrom': 3,
          'bollardTo': 10,
          'nextMovement': {},
          'visitPurposes': [],
          'terminalPlanningEnabled': true
        }, {
          'id': '1abbb8789fe94b5c8adad25550a2a6b1',
          'terminal': {
            'terminalCode': '4810',
            'terminalName': 'AMAZONEH ECT DDE',
            'organisationShortName': 'ECTDELTA',
            'organisationName': 'ECT Delta'
          },
          'stevedore': {
            'shortName': 'ECTDELTA',
            'fullName': 'ECT Delta',
            "location": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM"
            },
            "quaysForPlanning": ["DDE", "DDN"]
          },
          'vesselServiceCode': null,
          'vesselServiceName': null,
          'requestedEta': '2019-01-06T06:30:00Z',
          'requestedEtd': '2019-01-07T06:30:00Z',
          'eta': '2019-01-06T12:30:00Z',
          'etd': '2019-01-07T02:30:00Z',
          'discharge': 200,
          'load': 0,
          'bollardFrom': 2,
          'bollardTo': 4,
          'nextMovement': {},
          'visitPurposes': [],
          'terminalPlanningEnabled': true
        }, {
          'id': '1abbb8789fe94b5c8adad25550a3a6b1',
          'terminal': {
            'terminalCode': "4284",
            'terminalName': "AMALIAH RWG DS 1",
            'organisationShortName': 'RWG',
            'organisationName': 'Rotterdam Water Guys'
          },
          'stevedore': {
            'shortName': 'RWG',
            'fullName': 'Rotterdam Water Guys',
            "location": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM"
            },
            "quaysForPlanning": ["RWG"]
          },
          'vesselServiceCode': null,
          'vesselServiceName': null,
          'requestedEta': '2019-01-05T06:30:00Z',
          'requestedEtd': '2019-01-05T10:30:00Z',
          'eta': '2019-01-05T06:30:00Z',
          'etd': '2019-01-05T10:30:00Z',
          'discharge': 50,
          'load': 0,
          'bollardFrom': 4,
          'bollardTo': 10,
          'nextMovement': {},
          'visitPurposes': [],
          'terminalPlanningEnabled': true
        }, {
          'id': '1abbb8789fe94b5c8adad25550a0a6b1',
          'terminal': null,
          'stevedore': {
            'shortName': 'ECTDELTA',
            'fullName': 'ATM',
            "location": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM"
            },
            "quaysForPlanning": ["DDE", "DDN"]
          },
          'vesselServiceCode': 'FEED',
          'vesselServiceName': 'Feed Me ECT1',
          'requestedEta': '2019-02-14T14:10:00Z',
          'requestedEtd': '2019-02-15T14:10:00Z',
          'eta': null,
          'etd': null,
          'discharge': 250,
          'load': 50,
          'bollardFrom': null,
          'bollardTo': null,
          'nextMovement': {},
          'visitPurposes': [],
          'terminalPlanningEnabled': true
        }],
        'pilotStation': {
          'code': 'AL',
          'name': 'ACHTERLAND'
        },
        'entryPoint': {
          'code': 'LL',
          'name': 'Lage Licht'
        },
        'passingThroughTugboats': null,
        'defectTypes': [
          'DEN',
          'DWE',
          'TOW'
        ],
        'dangerousGoodsLoading': false,
        'dangerousGoodsDischarge': false,
        'dangerousGoodsTransit': false,
        'possibleAnchorage': true,
        'vesselInspectionRequired': true,
        'shipConfiguration': 'DOUBLE_HULL_TANKER',
        'exitPoint': {
          'code': 'OM',
          'name': 'Oude Maas',
          'atSea': false
        },
        'etdPort': '2018-11-13T11:00:00Z',
        'atdPort': null
      },
      'clientReferenceNumber': '7',
      'etaFirstEntryEu': '2018-11-07T11:00:00Z',
      'arrivalVoyage': {
        'voyageNumber': '1234567',
        'carrier': {
          'customsId': 'NL100000897',
          'name': 'Carrier 1',
          'scacCode': 'CARA',
          'smdgCode': 'CAR'
        }
      },
      'previousPorts': [],
      'departureVoyage': {
        'voyageNumber': '1234568',
        'carrier': {
          'customsId': '12345',
          'name': null,
          'scacCode': null,
          'smdgCode': null
        }
      },
      'nextPorts': []
    },
    'dangerousGoodsDeclarations': [],
    'dangerousGoodsEnabled': false,
    'copyOfVisit': null,
    'etaPortAis': '2018-11-08T10:22:00Z',
    'portOfCall': {
      'port': {
        'name': 'Rotterdam',
        'locationUnCode': 'NLRTM',
        'countryUnCode': 'NL',
        'euPort': true
      },
      'portAuthority': {
        'fullName': 'Havenbedrijf Rotterdam N.V.',
        'portAuthorityId': 'HbR',
        'shortName': 'HBRRTM',
        'emailAddress': 'HCC@portofrotterdam.com',
        'address': 'Wilhelminakade 909',
        'city': 'Rotterdam',
        'countryUnCode': 'NL',
        'countryName': 'Netherlands',
        'zipCode': '3072 AP',
        'phoneNumber': '+31-10-2521195',
        'faxNumber': '+31-10-2521000',
        'contact': 'Diana van Eijk',
        'customsEORINumber': 'NL24354561',
        'ean': null
      },
      'customsOffice': {
        'name': 'Rotterdam Haven/Kantoor Maasvlakte',
        'unCode': 'NL000396'
      },
      'ataReported': true,
      'atdReported': true,
      'atdPortReported': true,
      'paDeclarationRequired': true,
      'swDeclarationRequired': true,
      'wasteEnabled': true,
      'dangerousGoodsEnabled': true,
      'harbourDuesEnabled': false,
      'orderNauticalServicesEnabled': true,
      'seaBuoy': {
        'name': 'Maascenter',
        'code': 'MC'
      }
    },
    'vessel': {
      'imoCode': '8758457',
      'name': 'DAVE BLACKBURN',
      'radioCallSign': 'WB6043',
      'motUnCode': '1729',
      'motName': 'Passenger ship',
      'summerDeadWeight': 0,
      'maxWidth': 15.84,
      'manoeuvringSpeed': null,
      'flagCountryUnCode': 'US',
      'netTonnage': 1338,
      'grossTonnage': 1338,
      'registrationPlaceUnloCode': 'USMSY',
      'registrationPlaceName': 'New Orleans',
      'registrationDate': '1944-02-01',
      'mmsiNumber': null,
      'helispotPresent': false,
      'fullLength': 0,
      'flagCode': 'USA'
    },
    'owner': {
      'fullName': 'Ship Operator 1',
      'portAuthorityId': 'SO1_R',
      'shortName': 'SO1',
      'emailAddress': 'pietjepuk@yoyo.nl',
      'address': 'Ship Operatorlaan 1',
      'city': 'Ship Operatorstad',
      'countryUnCode': 'NL',
      'countryName': 'Netherlands',
      'zipCode': '1111SO',
      'phoneNumber': '0102522288',
      'faxNumber': null,
      'contact': 'test centrum',
      'customsEORINumber': 'NL123456789',
      'cargoImportEnabled': true
    },
    'declarant': {
      'fullName': 'Ship Operator 1',
      'portAuthorityId': 'SO1_R',
      'shortName': 'SO1',
      'emailAddress': 'pietjepuk@yoyo.nl',
      'address': 'Officelaan 1',
      'city': 'Officestad',
      'countryUnCode': 'NL',
      'countryName': 'Netherlands',
      'zipCode': '1111SO',
      'phoneNumber': '0102522288',
      'faxNumber': null,
      'contact': 'test centrum',
      'customsEORINumber': 'NL123456789',
      'cargoImportEnabled': true
    },
    'financialDeclarant': {
      'fullName': 'Ship Operator 1',
      'portAuthorityId': 'SO1_R',
      'shortName': 'SO1',
      'emailAddress': 'pietjepuk@yoyo.nl',
      'address': 'Ship Operatorlaan 1',
      'city': 'Ship Operatorstad',
      'countryUnCode': 'NL',
      'countryName': 'Netherlands',
      'zipCode': '1111SO',
      'phoneNumber': '0102522288',
      'faxNumber': null,
      'contact': 'test centrum',
      'customsEORINumber': 'NL123456789'
    },
    'nominatedFinancialDeclarant': null,
    'creditNumberHolderSameAsDeclarant': true,
    'cargoDeclarants': [],
    'allowedViewers': [
      {
        'fullName': 'Ship Operator 1',
        'portAuthorityId': 'SO1_R',
        'shortName': 'SO1',
        'emailAddress': 'pietjepuk@yoyo.nl',
        'address': 'Ship Operatorlaan 1',
        'city': 'Ship Operatorstad',
        'countryUnCode': 'NL',
        'countryName': 'Netherlands',
        'zipCode': '1111SO',
        'phoneNumber': '0102522288',
        'faxNumber': null,
        'contact': 'test centrum',
        'customsEORINumber': 'NL123456789',
        'cargoImportEnabled': true
      },
      {
        'fullName': 'Ship Operator 1',
        'portAuthorityId': 'SO1_R',
        'shortName': 'SO1',
        'emailAddress': 'pietjepuk@yoyo.nl',
        'address': 'Ship Operatorlaan 1',
        'city': 'Ship Operatorstad',
        'countryUnCode': 'NL',
        'countryName': 'Netherlands',
        'zipCode': '1111SO',
        'phoneNumber': '0102522288',
        'faxNumber': null,
        'contact': 'test centrum',
        'customsEORINumber': 'NL123456789',
        'cargoImportEnabled': true
      }
    ],
    'shipOperators': [
      {
        'fullName': 'Back Office 1',
        'portAuthorityId': 'BO1_R',
        'shortName': 'BO1',
        'emailAddress': 'pietjepuk@yoyo.nl',
        'address': 'Ship Operatorlaan 1',
        'city': 'Ship Operatorstad',
        'countryUnCode': 'NL',
        'countryName': 'Netherlands',
        'zipCode': '1111SO',
        'phoneNumber': '0102522288',
        'faxNumber': null,
        'contact': 'test centrum',
        'customsEORINumber': 'NL123456789',
        'cargoImportEnabled': true
      },
      {
        'fullName': 'Ship Operator 1',
        'portAuthorityId': 'SO1_R',
        'shortName': 'SO1',
        'emailAddress': 'pietjepuk@yoyo.nl',
        'address': 'Ship Operatorlaan 1',
        'city': 'Ship Operatorstad',
        'countryUnCode': 'NL',
        'countryName': 'Netherlands',
        'zipCode': '1111SO',
        'phoneNumber': '0102522288',
        'faxNumber': null,
        'contact': 'test centrum',
        'customsEORINumber': 'NL123456789',
        'cargoImportEnabled': true
      }
    ],
    'additionalViewers': [],
    'nextVisitDeclaration': [],
    'securityDeclaration': null,
    "wasteDeclaration": null,
    "shipStoresDeclaration": null,
    "paxDeclaration": null,
    "uploads": {},
    "cancelled": false,
    "planned": true,
    "ataRegisteredByPa": false,
    "healthDeclarationSummary": null,
    "declarations": [],
    'visitStatus': 'EXPECTED',
    'expected': true,
  },
  NLRTM58016508: {
    "crn": "NLRTM58016508",
    "cargoImportEnabled": true,
    "orderIncomingMovement": true,
    "updated": "2020-09-10T01:36:00.896Z",
    "importDeclarations": [],
    "loadingDeclarations": [],
    "incomingTidalWindowStatus": null,
    "berthVisitInfos": null,
    "visitDeclaration": {
      "portVisit": {
        "etaPort": "2018-11-09T10:00:00Z",
        "firstMovement": null,
        "portEntry": {
          "earliestTimeOfPortEntry": "2021-11-26T04:00:00Z",
          "etaPilotBoardingPlace": "2021-11-28T04:00:00Z",
          "requestedEtaPilotBoardingPlace": "2021-11-28T04:00:00Z",
          "baseForPlanning": "PILOT_BOARDING_PLACE",
          "intention": "REQUEST_FOR_ENTRY",
          "origin": "SEA",
          "etaSeaBuoy": "2021-11-08T06:00:00Z",
          "entryDependency": {
            "vesselName": "Lenski",
            "vesselImoCode": "2342556",
            "dependencyType": "EXCHANGE",
            "orderStatus": "ORDERED",
            "autoOrder": true
          }
        },
        "berthVisits": [
          {
            "id": "1abbb8789fe94b5c8adad25550a1a6b1",
            "callId": "1",
            "terminal": null,
            "stevedore": {
              "shortName": "ECTDELTA",
              "fullName": "ATM",
              "location": {
                "name": "Rotterdam",
                "locationUnCode": "NLRTM"
              },
              "quaysForPlanning": ["DDE", "DDN"]
            },
            "vesselServiceCode": null,
            "vesselServiceName": null,
            "eta": "2019-01-02T06:30:00Z",
            "etd": "2019-01-03T06:30:00Z",
            "discharge": 30,
            "load": 0,
            "bollardFrom": 3,
            "bollardTo": 10,
            "nextMovement": {},
            "visitPurposes": [],
            "terminalPlanningEnabled": true
          },
          {
            "id": "1bbbb8789fe94b5c8adad25550a2a6b1",
            "terminal": {
              "terminalCode": "4810",
              "terminalName": "AMAZONEH ECT DDE",
              "organisationShortName": "ECTDELTA",
              "organisationName": "ATM"
            },
            "stevedore": {
              "shortName": "ECTDELTA",
              "fullName": "ATM",
              "location": {
                "name": "Rotterdam",
                "locationUnCode": "NLRTM"
              },
              "quaysForPlanning": ["DDE", "DDN"]
            },
            "vesselServiceCode": null,
            "vesselServiceName": null,
            "eta": "2019-01-06T12:30:00Z",
            "etd": "2019-01-07T02:30:00Z",
            "discharge": 200,
            "load": 0,
            "bollardFrom": 2,
            "bollardTo": 4,
            "nextMovement": {},
            "visitPurposes": [],
            "terminalPlanningEnabled": true
          },
          {
            "id": "1cccc8789fe94b5c8adad25550a3a6b1",
            "callId": "2",
            "terminal": {
              "terminalCode": "4284",
              "terminalName": "AMALIAH RWG DS 1",
              "organisationShortName": "RWG",
              "organisationName": "Rotterdam Water Guys"
            },
            "stevedore": {
              "shortName": "RWG",
              "fullName": "Rotterdam Water Guys",
              "location": {
                "name": "Rotterdam",
                "locationUnCode": "NLRTM"
              },
              "quaysForPlanning": ["RWG"]
            },
            "vesselServiceCode": null,
            "vesselServiceName": null,
            "eta": "2019-01-08T06:30:00Z",
            "etd": "2019-01-08T10:30:00Z",
            "discharge": 50,
            "load": 0,
            "bollardFrom": 4,
            "bollardTo": 10,
            "nextMovement": {},
            "visitPurposes": [],
            "terminalPlanningEnabled": true
          },
          {
            "id": "1dddd8789fe94b5c8adad25550a0a6b1",
            "callId": "3",
            "terminal": null,
            "stevedore": {
              "shortName": "ECTDELTA",
              "fullName": "ATM",
              "location": {
                "name": "Rotterdam",
                "locationUnCode": "NLRTM"
              },
              "quaysForPlanning": ["DDE", "DDN"]
            },
            "vesselServiceCode": "FEED",
            "vesselServiceName": "Feed Me ECT1",
            "eta": "2019-01-14T14:00:00Z",
            "etd": "2019-01-15T14:30:00Z",
            "discharge": 250,
            "load": 50,
            "bollardFrom": null,
            "bollardTo": null,
            "nextMovement": {},
            "visitPurposes": [],
            "terminalPlanningEnabled": true
          },
          {
            "id": "1eeee8789fe94b5c8adad25550a0a6b1",
            "callId": "4",
            "terminal": {
              "organisationShortName": "EUROMAX",
              "organisationName": "Euromax",
              "terminalCode": "EUROMAX DDE",
              "terminalName": "Euromax DDE Terminal"
            },
            "stevedore": {
              "shortName": "EUROMAX",
              "fullName": "Euromax",
              "location": {
                "name": "Rotterdam",
                "locationUnCode": "NLRTM"
              },
              "quaysForPlanning": ["EMX"]
            },
            "berth": {
              "name": "HOLLAND AMERIKAKADE",
              "code": "R1243",
              "terminalCode": "52090469",
              "terminalName": "HOLLAND AMERIKAKADE",
              "organisationShortName": "EUROMAX",
              "berthGroupCode": "HOLLAND AMERIKAKADE",
              "buoy": false,
              "partyToNotify": null
            },
            "vesselServiceCode": "FEED",
            "vesselServiceName": "Feed Me ECT1",
            "eta": "2019-01-14T15:00:00Z",
            "etd": "2019-01-15T15:45:00Z",
            "mooringOrientation": "PORT_SIDE",
            "discharge": 50,
            "load": 0,
            "bollardFrom": 5,
            "bollardTo": 10,
            "nextMovement": {},
            "visitPurposes": [],
            "terminalPlanningEnabled": true
          },
          {
            "id": "1ffff8789fe94b5c8adad25550a0a6b1",
            "callId": "5",
            "terminal": {
              "organisationShortName": "EUROMAX",
              "organisationName": "ECT Euromax Terminal"
            },
            "stevedore": {
              "shortName": "EUROMAX",
              "fullName": "ECT Euromax Terminal",
              "location": {
                "name": "Rotterdam",
                "locationUnCode": "NLRTM"
              },
              "quaysForPlanning": ["EUROMAX"]
            },
            "vesselServiceCode": "FEED",
            "vesselServiceName": "Feed Me ECT1",
            "eta": "2019-01-16T14:00:00Z",
            "etd": "2019-01-17T14:45:00Z",
            "mooringOrientation": "PORT_SIDE",
            "discharge": 50,
            "load": 0,
            "bollardFrom": 5,
            "bollardTo": 10,
            "nextMovement": {},
            "visitPurposes": [],
            "terminalPlanningEnabled": true
          },
          {
            "id": "1dddd8789fe94b5c8adad25550a0a6b1",
            "callId": "6",
            "terminal": null,
            "stevedore": {
              "shortName": "ECTDELTA",
              "fullName": "ATM",
              "location": {
                "name": "Rotterdam",
                "locationUnCode": "NLRTM"
              },
              "quaysForPlanning": ["DDE", "DDN"]
            },
            "vesselServiceCode": "FEED",
            "vesselServiceName": "Feed Me ECT1",
            "eta": "2019-01-14T14:00:00Z",
            "etd": "2019-01-15T14:30:00Z",
            "discharge": 250,
            "load": 50,
            "bollardFrom": null,
            "bollardTo": null,
            "nextMovement": {},
            "visitPurposes": [],
            "terminalPlanningEnabled": true,
            "operatorRemarks": "Terminal planning with quarters inside range"
          }
        ],
        "pilotStation": {
          "code": "AL",
          "name": "ACHTERLAND"
        },
        "entryPoint": {
          "code": "LL",
          "name": "Lage Licht"
        },
        "passingThroughTugboats": null,
        "defectTypes": ["DEN",
          "DWE",
          "TOW"],
        "dangerousGoodsLoading": false,
        "dangerousGoodsDischarge": false,
        "dangerousGoodsTransit": false,
        "possibleAnchorage": true,
        "vesselInspectionRequired": true,
        "shipConfiguration": "DOUBLE_HULL_TANKER",
        "exitPoint": null,
        "etdPort": null,
        "atdPort": null
      },
      "clientReferenceNumber": "7",
      "etaFirstEntryEu": "2018-11-07T11:00:00Z",
      "arrivalVoyage": {
        "voyageNumber": "1234567",
        "carrier": {
          "customsId": "NL100000897",
          "name": "Carrier 1",
          "scacCode": "CARA",
          "smdgCode": "CAR"
        }
      },
      "previousPorts": [],
      "departureVoyage": {
        "voyageNumber": "1234568",
        "carrier": {
          "customsId": "12345",
          "name": null,
          "scacCode": null,
          "smdgCode": null
        }
      },
      "nextPorts": []
    },
    "dangerousGoodsDeclarations": [],
    "dangerousGoodsEnabled": false,
    "copyOfVisit": null,
    "etaPortAis": "2018-11-08T10:22:00Z",
    "portOfCall": {
      "port": {
        "name": "Rotterdam",
        "locationUnCode": "NLRTM",
        "countryUnCode": "NL",
        "euPort": true
      },
      "portAuthority": {
        "fullName": "Havenbedrijf Rotterdam N.V.",
        "portAuthorityId": "HbR",
        "shortName": "HBRRTM",
        "emailAddress": "HCC@portofrotterdam.com",
        "address": "Wilhelminakade 909",
        "city": "Rotterdam",
        "countryUnCode": "NL",
        "countryName": "Netherlands",
        "zipCode": "3072 AP",
        "phoneNumber": "+31-10-2521195",
        "faxNumber": "+31-10-2521000",
        "contact": "Diana van Eijk",
        "customsEORINumber": "NL24354561",
        "ean": null
      },
      "customsOffice": {
        "name": "Rotterdam Haven/Kantoor Maasvlakte",
        "unCode": "NL000396"
      },
      "ataReported": true,
      "atdReported": true,
      "atdPortReported": true,
      "paDeclarationRequired": true,
      "swDeclarationRequired": true,
      "wasteEnabled": true,
      "dangerousGoodsEnabled": true,
      "harbourDuesEnabled": false,
      "orderNauticalServicesEnabled": true,
      "portAuthorityClearance": true,
      "seaBuoy": {
        "name": "Maascenter",
        "code": "MC"
      }
    },
    "vessel": {
      "imoCode": "8758457",
      "name": "DAVE BLACKBURN",
      "radioCallSign": "WB6043",
      "motUnCode": "1729",
      "motName": "Passenger ship",
      "summerDeadWeight": 0,
      "maxWidth": 15.84,
      "manoeuvringSpeed": null,
      "flagCountryUnCode": "US",
      "netTonnage": 1338,
      "grossTonnage": 1338,
      "registrationPlaceUnloCode": "USMSY",
      "registrationPlaceName": "New Orleans",
      "registrationDate": "1944-02-01",
      "mmsiNumber": null,
      "helispotPresent": false,
      "fullLength": 0,
      "flagCode": "USA"
    },
    "owner": {
      "fullName": "Ship Operator 1",
      "portAuthorityId": "SO1_R",
      "shortName": "SO1",
      "emailAddress": "pietjepuk@yoyo.nl",
      "address": "Ship Operatorlaan 1",
      "city": "Ship Operatorstad",
      "countryUnCode": "NL",
      "countryName": "Netherlands",
      "zipCode": "1111SO",
      "phoneNumber": "0102522288",
      "faxNumber": null,
      "contact": "test centrum",
      "customsEORINumber": "NL123456789",
      "cargoImportEnabled": true
    },
    "declarant": {
      "fullName": "Ship Operator 1",
      "portAuthorityId": "SO1_R",
      "shortName": "SO1",
      "emailAddress": "pietjepuk@yoyo.nl",
      "address": "Officelaan 1",
      "city": "Officestad",
      "countryUnCode": "NL",
      "countryName": "Netherlands",
      "zipCode": "1111SO",
      "phoneNumber": "0102522288",
      "faxNumber": null,
      "contact": "test centrum",
      "customsEORINumber": "NL123456789",
      "cargoImportEnabled": true
    },
    "financialDeclarant": {
      "fullName": "Ship Operator 1",
      "portAuthorityId": "SO1_R",
      "shortName": "SO1",
      "emailAddress": "pietjepuk@yoyo.nl",
      "address": "Ship Operatorlaan 1",
      "city": "Ship Operatorstad",
      "countryUnCode": "NL",
      "countryName": "Netherlands",
      "zipCode": "1111SO",
      "phoneNumber": "0102522288",
      "faxNumber": null,
      "contact": "test centrum",
      "customsEORINumber": "NL123456789"
    },
    "nominatedFinancialDeclarant": null,
    "creditNumberHolderSameAsDeclarant": true,
    "cargoDeclarants": [],
    "allowedViewers": [
      {
        "fullName": "Ship Operator 1",
        "portAuthorityId": "SO1_R",
        "shortName": "SO1",
        "emailAddress": "pietjepuk@yoyo.nl",
        "address": "Ship Operatorlaan 1",
        "city": "Ship Operatorstad",
        "countryUnCode": "NL",
        "countryName": "Netherlands",
        "zipCode": "1111SO",
        "phoneNumber": "0102522288",
        "faxNumber": null,
        "contact": "test centrum",
        "customsEORINumber": "NL123456789",
        "cargoImportEnabled": true
      },
      {
        "fullName": "Ship Operator 1",
        "portAuthorityId": "SO1_R",
        "shortName": "SO1",
        "emailAddress": "pietjepuk@yoyo.nl",
        "address": "Ship Operatorlaan 1",
        "city": "Ship Operatorstad",
        "countryUnCode": "NL",
        "countryName": "Netherlands",
        "zipCode": "1111SO",
        "phoneNumber": "0102522288",
        "faxNumber": null,
        "contact": "test centrum",
        "customsEORINumber": "NL123456789",
        "cargoImportEnabled": true
      }
    ],
    "shipOperators": [
      {
        "fullName": "Back Office 1",
        "portAuthorityId": "BO1_R",
        "shortName": "BO1",
        "emailAddress": "pietjepuk@yoyo.nl",
        "address": "Ship Operatorlaan 1",
        "city": "Ship Operatorstad",
        "countryUnCode": "NL",
        "countryName": "Netherlands",
        "zipCode": "1111SO",
        "phoneNumber": "0102522288",
        "faxNumber": null,
        "contact": "test centrum",
        "customsEORINumber": "NL123456789",
        "cargoImportEnabled": true
      },
      {
        "fullName": "Ship Operator 1",
        "portAuthorityId": "SO1_R",
        "shortName": "SO1",
        "emailAddress": "pietjepuk@yoyo.nl",
        "address": "Ship Operatorlaan 1",
        "city": "Ship Operatorstad",
        "countryUnCode": "NL",
        "countryName": "Netherlands",
        "zipCode": "1111SO",
        "phoneNumber": "0102522288",
        "faxNumber": null,
        "contact": "test centrum",
        "customsEORINumber": "NL123456789",
        "cargoImportEnabled": true
      }
    ],
    "additionalViewers": [],
    "nextVisitDeclaration": [],
    "securityDeclaration": null,
    "wasteDeclaration": null,
    "shipStoresDeclaration": null,
    "paxDeclaration": null,
    "terminalPlanningEnabled": true,
    "terminalVisits": {
      "TerminalVisit-1": {
        "id": "TerminalVisit-1",
        "crn": "NLRTM58016508",
        "organisationShortName": "ECTDELTA",
        "visitId": "1",
        "status": {
          "status": "DECLARED",
          "cancelled": false,
          "acknowledged": false,
          "acceptedByAgent": false
        }
      },
      "TerminalVisit-2": {
        "id": "TerminalVisit-2",
        "crn": "NLRTM58016508",
        "organisationShortName": "RWG",
        "visitId": "2",
        "status": {
          "status": "ACCEPTED",
          "cancelled": false,
          "acknowledged": true,
          "acceptedByAgent": true
        },
        "info": {
          "eta": "2019-01-08T06:30:00Z",
          "rta": "2019-01-08T06:35:00Z",
          "etc": "2019-01-08T10:10:00Z",
          "etd": "2019-01-08T10:30:00Z",
          "mooringOrientation": "PORTSIDE",
          "bollardAft": 5,
          "bollardFore": 10,
          "expectedDischarge": 50,
          "expectedLoad": 0
        }
      },
      "TerminalVisit-3": {
        "id": "TerminalVisit-3",
        "crn": "NLRTM58016508",
        "organisationShortName": "ECTDELTA",
        "visitId": "3",
        "status": {
          "status": "ACCEPTED",
          "cancelled": false,
          "acknowledged": true,
          "acceptedByAgent": false
        },
        "info": {
          "eta": "2019-01-06T06:30:00Z",
          "etd": "2019-01-05T10:30:00Z",
          "mooringOrientation": "PORTSIDE",
          "bollardAft": 10,
          "bollardFore": 5,
          "expectedDischarge": 50,
          "expectedLoad": 0
        }
      },
      "TerminalVisit-4": {
        "id": "TerminalVisit-4",
        "crn": "NLRTM58016508",
        "organisationShortName": "EUROMAX",
        "visitId": "4",
        "status": {
          "status": "REJECTED",
          "cancelled": false,
          "acknowledged": true,
          "acceptedByAgent": false,
          "rejectReasons": ["Blocked because your ship is ugly!", "And it's too large!"]
        },
        "info": {
          "eta": "2019-01-14T15:00:00Z",
          "etd": "2019-01-15T15:45:00Z",
          "mooringOrientation": "PORTSIDE",
          "bollardAft": 10,
          "bollardFore": 5,
          "expectedDischarge": 50,
          "expectedLoad": 0
        }
      },
      "TerminalVisit-5": {
        "id": "TerminalVisit-5",
        "crn": "NLRTM58016508",
        "organisationShortName": "EUROMAX",
        "visitId": "5",
        "status": {
          "status": "REJECTED",
          "cancelled": true,
          "acknowledged": true,
          "acceptedByAgent": false,
          "rejectReasons": ["Blocked because your ship is ugly!", "And it's too large!"]
        },
        "info": null
      },
      "TerminalVisit-6": {
        "id": "TerminalVisit-6",
        "crn": "NLRTM58016508",
        "organisationShortName": "ECTDELTA",
        "visitId": "6",
        "status": {
          "status": "ACCEPTED",
          "cancelled": false,
          "acknowledged": true,
          "acceptedByAgent": false
        },
        "info": {
          "eta": "2019-01-14T14:05:00Z",
          "etd": "2019-01-15T14:44:00Z",
          "mooringOrientation": "PORTSIDE",
          "bollardAft": 10,
          "bollardFore": 5,
          "expectedDischarge": 250,
          "expectedLoad": 50
        }
      },
    },
    "uploads": {},
    "cancelled": false,
    "planned": true,
    "ataRegisteredByPa": false,
    "healthDeclarationSummary": null,
    "declarations": [
      {
        "type": "TERMINAL_PLANNING",
        "status": "DECLARED",
        "rejectReasons": null,
        "timeStamp": "2018-12-24T08:31:08.960Z"
      },
      {
        "type": "TERMINAL_PLANNING",
        "status": "REJECTED",
        "rejectReasons": "ECT Delta: No you cannot come here!",
        "timeStamp": "2018-12-24T10:31:08.960Z"
      }
    ],
    "visitStatus": "EXPECTED",
    "expected": true
  },
  NLRTM58016509: {
    'crn': 'NLRTM58016509',
    'cargoImportEnabled': true,
    'orderIncomingMovement': true,
    'updated': '2020-09-10T01:36:00.896Z',
    'passages': [{"code": "PPA", 'ata': '2021-11-12T11:00:00Z'}, {
      "code": "LEFT_PREVIOUS_PORT",
      'ata': '2021-11-14T07:00:00Z'
    }],
    'importDeclarations': [{
      'cargoDeclarant': {
        'fullName': 'Cargo agent 1',
        'portAuthorityId': 'CHA1_R',
        'shortName': 'CHA1',
        'emailAddress': 'd.reijnders@portbase.com',
        'address': 'Cargo Operatorlaan 1',
        'city': 'Cargo Operatorstad',
        'countryUnCode': 'NL',
        'countryName': 'Netherlands',
        'zipCode': '1111SO',
        'phoneNumber': '0102522288',
        'faxNumber': null,
        'contact': 'TC',
        'customsEORINumber': 'NL123456789',
        'cargoImportEnabled': true
      },
      'consignments': [
        {
          'consignmentNumber': '12335',
          'portOfLoading': {'name': 'Barcelona', 'locationUnCode': 'ESBCN', 'countryUnCode': 'ES', 'euPort': true},
          'portOfDischarge': {'name': 'Rotterdam', 'locationUnCode': 'NLRTM', 'countryUnCode': 'NL', 'euPort': true},
          'actualDeparture': '2018-11-08T11:00:00Z',
          'declarations': [],
          'partiesToNotify': [],
          'goodsItems': [{
            'placements': [{
              'equipmentNumber': 'ABCD1234567',
              'numberOfPackages': '1',
              'grossWeight': 1000
            }],
            'producedDocuments': [{'type': 'N703', 'id': '209021', 'description': 'Live animalsss'}],
            'marksAndNumbers': ['90210'],
            'itemNumber': 1,
            'description': 'Cows and other animals like pigs and even more stuff like donkeys',
            'classification': {'code': '0100', 'description': 'Live animals'},
            'minimumTemperature': 10,
            'maximumTemperature': 25,
            'grossWeight': 1000,
            'numberOfOuterPackages': '1',
            'outerPackageType': {'code': 'BK', 'name': 'Basket '}
          }],
          'partShipment': false,
          'movementType': 'DOOR_TO_DOOR',
          'customsProcess': 'SEA_IN_SEA_OUT',
          'movementReferenceNumbers': ['219820198'],
          'consignor': {
            'id': 'test',
            'name': 'Crazy Pete',
            'eoriNumber': 'eoriForParty',
            'zipCode': 'party321'
          },
          'consignee': {'eoriNumber': '209201', 'name': 'Pickle Rick'},
          'customsStatus': 'EU_COMMUNITY_GOODS',
          'terminal': {
            'terminalName': 'HOLLAND AMERIKAKADE',
            'terminalCode': '52090469',
            'organisationShortName': 'ECTDELTA'
          },
          'bulkAuthorisations': []
        },
        {
          'consignmentNumber': '12334',
          'portOfLoading': {'name': 'Barcelona', 'locationUnCode': 'ESBCN', 'countryUnCode': 'ES', 'euPort': true},
          'portOfDischarge': {'name': 'Rotterdam', 'locationUnCode': 'NLRTM', 'countryUnCode': 'NL', 'euPort': true},
          'actualDeparture': '2018-11-08T11:00:00Z',
          'declarations': [],
          'partiesToNotify': [],
          'goodsItems': [{
            'placements': [{
              'equipmentNumber': 'ABCD1234567',
              'numberOfPackages': '1',
              'grossWeight': 1000
            }],
            'producedDocuments': [{'type': 'N703', 'id': '209021', 'description': 'Live animalsss'}],
            'marksAndNumbers': ['90210'],
            'itemNumber': 1,
            'description': 'Cows and other animals like pigs and even more stuff like donkeys',
            'classification': {'code': '0100', 'description': 'Live animals'},
            'minimumTemperature': 10,
            'maximumTemperature': 25,
            'grossWeight': 1000,
            'numberOfOuterPackages': '1',
            'outerPackageType': {'code': 'BK', 'name': 'Basket '}
          }],
          'partShipment': false,
          'movementType': 'DOOR_TO_DOOR',
          'customsProcess': 'SEA_IN_SEA_OUT',
          'movementReferenceNumbers': ['219820198'],
          'consignor': {
            'id': 'test',
            'name': 'Crazy Pete',
            'address': 'partyStreet 123',
            'city': 'Party Ville',
            'countryCode': 'FR',
            'eoriNumber': 'eoriForParty',
            'zipCode': 'party321'
          },
          'consignee': {'eoriNumber': '209201', 'name': 'Pickle Rick'},
          'customsStatus': 'EU_COMMUNITY_GOODS',
          'terminal': {
            'terminalName': 'HOLLAND AMERIKAKADE',
            'terminalCode': '52090469',
            'organisationShortName': 'ECTDELTA'
          },
          'bulkAuthorisations': []
        },
        {
          'consignmentNumber': '12336',
          'portOfLoading': {'name': 'Barcelona', 'locationUnCode': 'ESBCN', 'countryUnCode': 'ES', 'euPort': true},
          'portOfDischarge': {'name': 'Rotterdam', 'locationUnCode': 'NLRTM', 'countryUnCode': 'NL', 'euPort': true},
          'actualDeparture': '2018-11-08T11:00:00Z',
          'declarations': [],
          'partiesToNotify': [],
          'goodsItems': [{
            'placements': [{
              'equipmentNumber': 'ABCD1234567',
              'numberOfPackages': '1',
              'grossWeight': 1000
            }],
            'producedDocuments': [{'type': 'N703', 'id': '209021', 'description': 'Live animalsss'}],
            'marksAndNumbers': ['90210'],
            'itemNumber': 1,
            'description': 'Cows and other animals like pigs and even more stuff like donkeys',
            'classification': {'code': '0100', 'description': 'Live animals'},
            'minimumTemperature': 10,
            'maximumTemperature': null,
            'grossWeight': 1000,
            'numberOfOuterPackages': '1',
            'outerPackageType': {'code': 'BK', 'name': 'Basket '}
          }],
          'partShipment': false,
          'movementType': 'DOOR_TO_DOOR',
          'customsProcess': 'MILITARY',
          'movementReferenceNumbers': ['219820198'],
          'consignor': {
            'id': 'test',
            'name': 'Crazy Pete',
            'address': 'partyStreet 123',
            'city': 'Party Ville',
            'countryCode': 'FR',
            'eoriNumber': 'eoriForParty',
            'zipCode': 'party321'
          },
          'consignee': {'eoriNumber': '209201', 'name': 'Pickle Rick'},
          'customsStatus': 'EU_COMMUNITY_GOODS',
          'terminal': {
            'terminalName': 'HOLLAND AMERIKAKADE',
            'terminalCode': '52090469',
            'organisationShortName': 'ECTDELTA'
          },
          'bulkAuthorisations': []
        },
        {
          'consignmentNumber': '12337',
          'portOfLoading': {'name': 'Barcelona', 'locationUnCode': 'ESBCN', 'countryUnCode': 'ES', 'euPort': true},
          'portOfDischarge': {'name': 'Rotterdam', 'locationUnCode': 'NLRTM', 'countryUnCode': 'NL', 'euPort': true},
          'actualDeparture': '2018-11-08T11:00:00Z',
          'declarations': [],
          'partiesToNotify': [],
          'goodsItems': [{
            'placements': [{
              'equipmentNumber': 'ABCD1234765',
              'numberOfPackages': '1',
              'grossWeight': 1000
            }],
            'producedDocuments': [{'type': 'N703', 'id': '209021', 'description': 'Live animalsss'}],
            'marksAndNumbers': ['90210'],
            'itemNumber': 1,
            'description': 'Cows and other animals like pigs and even more stuff like donkeys',
            'classification': {'code': '0100', 'description': 'Live animals'},
            'minimumTemperature': null,
            'maximumTemperature': null,
            'grossWeight': 913497,
            'numberOfOuterPackages': '1',
            'outerPackageType': {'code': 'BK', 'name': 'Basket '}
          }],
          'partShipment': false,
          'movementType': 'DOOR_TO_DOOR',
          'customsProcess': null,
          'movementReferenceNumbers': ['219820198'],
          'consignor': {
            'id': 'test',
            'name': 'Crazy Pete',
            'address': 'partyStreet 123',
            'city': 'Party Ville',
            'countryCode': 'FR',
            'eoriNumber': 'eoriForParty',
            'zipCode': 'party321'
          },
          'consignee': {'eoriNumber': '209201', 'name': 'Pickle Rick'},
          'customsStatus': 'EU_COMMUNITY_GOODS_IN_TRANSHIPMENT',
          'terminal': {
            'terminalName': 'HOLLAND AMERIKAKADE',
            'terminalCode': '52090469',
            'organisationShortName': 'ECTDELTA'
          },
          'bulkAuthorisations': []
        },
        {
          'consignmentNumber': '12338',
          'portOfLoading': {'name': 'Barcelona', 'locationUnCode': 'ESBCN', 'countryUnCode': 'ES', 'euPort': true},
          'portOfDischarge': {'name': 'Rotterdam', 'locationUnCode': 'NLRTM', 'countryUnCode': 'NL', 'euPort': true},
          'actualDeparture': '2018-11-08T11:00:00Z',
          'declarations': [],
          'partiesToNotify': [],
          'goodsItems': [{
            'placements': [{
              'equipmentNumber': 'ABCD1234567',
              'numberOfPackages': '1',
              'grossWeight': 1000
            }],
            'producedDocuments': [{'type': 'N703', 'id': '209021', 'description': 'Live animalsss'}],
            'marksAndNumbers': ['90210'],
            'itemNumber': 1,
            'description': 'Cows and other animals like pigs and even more stuff like donkeys',
            'classification': {'code': '0100', 'description': 'Live animals'},
            'minimumTemperature': 10,
            'maximumTemperature': 25,
            'grossWeight': 1000,
            'numberOfOuterPackages': '1',
            'outerPackageType': {'code': 'BK', 'name': 'Basket '}
          }],
          "partShipment": false,
          "movementType": "DOOR_TO_DOOR",
          "customsProcess": "EMPTY_RETURN_PACKAGING",
          "movementReferenceNumbers": ["219820198"],
          "consignor": {
            "id": "test",
            "name": "Crazy Pete",
            "address": "partyStreet 123",
            "city": "Party Ville",
            "countryCode": "FR",
            "eoriNumber": "eoriForParty",
            "zipCode": "party321"
          },
          "consignee": {"eoriNumber": "209201", "name": "Pickle Rick"},
          "customsStatus": "EU_COMMUNITY_GOODS",
          "terminal": {
            "terminalName": "HOLLAND AMERIKAKADE",
            "terminalCode": "52090469",
            "organisationShortName": "ECTDELTA"
          },
          "bulkAuthorisations": []
        },
        {
          "consignmentNumber": "WECC2163RTM1069",
          "portOfLoading": {"name": "Barcelona", "locationUnCode": "ESBCN", "countryUnCode": "ES", "euPort": true},
          "portOfDischarge": {"name": "Rotterdam", "locationUnCode": "NLRTM", "countryUnCode": "NL", "euPort": true},
          "actualDeparture": "2018-11-08T11:00:00Z",
          "declarations": [],
          "partiesToNotify": [],
          "goodsItems": [{
            "placements": [{
              "equipmentNumber": "ABCD1234567",
              "numberOfPackages": "1",
              "grossWeight": 1000
            }],
            'producedDocuments': [{'type': 'N703', 'id': '209021', 'description': 'Live animalsss'}],
            'marksAndNumbers': ['90210'],
            'itemNumber': 1,
            'description': 'Cows and other animals like pigs and even more stuff like donkeys',
            'classification': {'code': '0100', 'description': 'Live animals'},
            'minimumTemperature': 10,
            'maximumTemperature': 25,
            'grossWeight': 1000,
            'numberOfOuterPackages': '1',
            'outerPackageType': {'code': 'BK', 'name': 'Basket '}
          }],
          'partShipment': false,
          'movementType': 'DOOR_TO_DOOR',
          'customsProcess': 'SEA_IN_SEA_OUT',
          'movementReferenceNumbers': ['219820198'],
          'consignor': {
            'id': 'test',
            'name': 'Crazy Pete',
            'address': 'partyStreet 123',
            'city': 'Party Ville',
            'countryCode': 'FR',
            'eoriNumber': 'eoriForParty',
            'zipCode': 'party321'
          },
          'consignee': {'eoriNumber': '209201', 'name': 'Pickle Rick'},
          'customsStatus': 'EU_COMMUNITY_GOODS',
          'terminal': {
            'terminalName': 'HOLLAND AMERIKAKADE',
            'terminalCode': '52090469',
            'organisationShortName': 'ECTDELTA'
          },
          'bulkAuthorisations': []
        },
        {
          'consignmentNumber': '12340',
          'portOfLoading': {'name': 'Barcelona', 'locationUnCode': 'ESBCN', 'countryUnCode': 'ES', 'euPort': true},
          'portOfDischarge': {'name': 'Rotterdam', 'locationUnCode': 'NLRTM', 'countryUnCode': 'NL', 'euPort': true},
          'actualDeparture': '2018-11-08T11:00:00Z',
          'declarations': [],
          'partiesToNotify': [],
          'goodsItems': [{
            'placements': [{
              'equipmentNumber': 'ABCD1234567',
              'numberOfPackages': '1',
              'grossWeight': 1000
            }],
            'producedDocuments': [{'type': 'N703', 'id': '209021', 'description': 'Live animalsss'}],
            'marksAndNumbers': ['90210'],
            'itemNumber': 1,
            'description': 'Cows and other animals like pigs and even more stuff like donkeys',
            'classification': {'code': '0100', 'description': 'Live animals'},
            'minimumTemperature': 10,
            'maximumTemperature': 25,
            'grossWeight': 1000,
            'numberOfOuterPackages': '1',
            'outerPackageType': {'code': 'BK', 'name': 'Basket '}
          }],
          'partShipment': false,
          'movementType': 'DOOR_TO_DOOR',
          'customsProcess': 'SEA_IN_SEA_OUT',
          'movementReferenceNumbers': ['219820198'],
          'consignor': {
            'id': 'test',
            'name': 'Crazy Pete',
            'address': 'partyStreet 123',
            'city': 'Party Ville',
            'countryCode': 'FR',
            'eoriNumber': 'eoriForParty',
            'zipCode': 'party321'
          },
          'consignee': {'eoriNumber': '209201', 'name': 'Pickle Rick'},
          'customsStatus': 'EU_COMMUNITY_GOODS',
          'terminal': {
            'terminalName': 'HOLLAND AMERIKAKADE',
            'terminalCode': '52090469',
            'organisationShortName': 'ECTDELTA'
          },
          'bulkAuthorisations': []
        }],
      'containers': [
        {
          'number': 'ABCD1234567',
          'portOfLoading': {'name': 'Barcelona', 'locationUnCode': 'ESBCN', 'countryUnCode': 'ES', 'euPort': true},
          'portOfDischarge': {'name': 'Rotterdam', 'locationUnCode': 'NLRTM', 'countryUnCode': 'NL', 'euPort': true},
          'actualDeparture': '2018-11-08T11:00:00Z',
          'terminal': {
            'terminalName': 'HOLLAND AMERIKAKADE',
            'terminalCode': '52090469',
            'organisationShortName': 'ECTDELTA'
          },
          "empty": false,
          "redeliveryAddress": "japio",
          "shippersOwned": false,
          "type": "CONTAINER",
          "sizeType": {"code": "SIZE1", "name": "Big size"},
          "containerOperator": {
            "name": "Carrier 1",
            "customsId": "carrier1Id",
            "scacCode": "scac1",
            "smdgCode": "smdg1"
          }
        },
        {
          'number': 'ABCD1234765',
          'portOfLoading': {'name': 'Barcelona', 'locationUnCode': 'ESBCN', 'countryUnCode': 'ES', 'euPort': true},
          'portOfDischarge': {'name': 'Rotterdam', 'locationUnCode': 'NLRTM', 'countryUnCode': 'NL', 'euPort': true},
          'actualDeparture': '2018-11-08T11:00:00Z',
          'terminal': {
            'terminalName': 'HOLLAND AMERIKAKADE',
            'terminalCode': '52090469',
            'organisationShortName': 'ECTDELTA'
          },
          "empty": false,
          "redeliveryAddress": "pieterke",
          "shippersOwned": false,
          "type": "CONTAINER",
          "sizeType": {"code": "SIZE1", "name": "Big size"},
          "containerOperator": {
            "name": "Carrier 1",
            "customsId": "carrier1Id",
            "scacCode": "scac1",
            "smdgCode": "smdg1"
          }
        },
        {
          "number": "ABCD1234570",
          "portOfLoading": {"name": "Barcelona", "locationUnCode": "ESBCN", "countryUnCode": "ES", "euPort": true},
          "portOfDischarge": {"name": "Rotterdam", "locationUnCode": "NLRTM", "countryUnCode": "NL", "euPort": true},
          "actualDeparture": "2018-11-08T11:00:00Z",
          "terminal": {"terminalName": "PET 3 B 61", "terminalCode": "47644472"},
          "empty": true,
          "redeliveryAddress": "japio",
          "shippersOwned": false,
          "type": "CONTAINER",
          "sizeType": {"code": "SIZE1", "name": "Big size"},
          "containerOperator": {
            "name": "Carrier 1",
            "customsId": "carrier1Id",
            "scacCode": "scac1",
            "smdgCode": "smdg1"
          }
        }],
      'discharges': [
        {
          'number': 'ABCD1234567',
          'portOfLoading': {'name': 'Barcelona', 'locationUnCode': 'ESBCN', 'countryUnCode': 'ES', 'euPort': true},
          'terminal': {
            'terminalName': 'HOLLAND AMERIKAKADE',
            'terminalCode': '52090462',
            'organisationShortName': 'ECTDELTA'
          },
          'type': 'TRAILER',
          'sizeType': {'code': 'SIZ3', 'name': 'Big size3'},
          'actualTimeOfHandling': '2020-11-08T11:00:00Z',
          'cargoAgentShortName': 'CHA1',
          'containerOperator': {
            'name': 'Carrier 2',
            'customsId': 'carrier2Id',
            'scacCode': 'scac2',
            'smdgCode': 'smdg2'
          },
          'bookingReference': 'hondenvriend',
          'empty': true,
          'tareWeight': '22',
        },
        <DischargeResult>{
          'number': 'ABCD1234568',
          'portOfLoading': {'name': 'Barcelona', 'locationUnCode': 'ESBCN', 'countryUnCode': 'ES', 'euPort': true},
          'terminal': {'terminalCode': '52090469', 'terminalName': 'yomama', 'organisationShortName': 'ECTDELTA'},
          'empty': false,
          'shippersOwned': false,
          'type': 'CONTAINER',
          'sizeType': {'code': 'SIZE1', 'name': 'Big size'},
          'containerOperator': {
            'name': 'Carrier 1',
            'customsId': 'carrier1Id',
            'scacCode': 'scac1',
            'smdgCode': 'smdg1'
          },
          temperature: 10,
          actualTimeOfHandling: '2018-11-08T11:00:00Z',
          grossWeight: 100
        },
        <DischargeResult>{
          'number': 'ABCD1234569',
          'portOfLoading': {'name': 'Barcelona', 'locationUnCode': 'ESBCN', 'countryUnCode': 'ES', 'euPort': true},
          'terminal': {'terminalCode': '52090469', 'terminalName': 'yomama', 'organisationShortName': 'ECTDELTA'},
          'empty': true,
          'shippersOwned': false,
          'type': 'CONTAINER',
          'sizeType': {'code': 'SIZE1', 'name': 'Big size'},
          'containerOperator': {
            'name': 'Carrier 1',
            'customsId': 'carrier1Id',
            'scacCode': 'scac1',
            'smdgCode': 'smdg1'
          },
          temperature: null,
          actualTimeOfHandling: '2018-11-08T11:00:00Z',
          grossWeight: 50
        }
      ],
      "inspectionUpdates": <InspectionUpdate[]>[
        {
          consignmentNumber: "WECC2163RTM1069",
          containerNumber: "ABCD1234567",
          physicalInspectionLocation: "inspection_outlet",
          status: null,
          type: "physical",
          timestamp: "2020-09-10T01:37:00.896Z"
        },
        {
          consignmentNumber: "WECC2163RTM1069",
          containerNumber: "ABCD1234567",
          physicalInspectionLocation: null,
          status: null,
          type: "scan",
          timestamp: "2020-09-11T01:37:00.896Z"
        },
        {
          consignmentNumber: "WECC2163RTM1069",
          containerNumber: "ABCD1234567",
          physicalInspectionLocation: null,
          status: "released",
          type: null,
          timestamp: "2020-09-12T01:37:00.896Z"
        },
        {
          consignmentNumber: "12338",
          containerNumber: "ABCD1234567",
          physicalInspectionLocation: null,
          status: null,
          type: "scan",
          timestamp: "2020-09-11T01:37:00.896Z"
        },
        {
          consignmentNumber: "12334",
          containerNumber: null,
          physicalInspectionLocation: null,
          status: null,
          type: "scan",
          timestamp: "2020-09-11T01:37:00.896Z"
        },
        {
          consignmentNumber: "12337",
          containerNumber: "ABCD1234567",
          physicalInspectionLocation: null,
          status: null,
          type: "scan",
          timestamp: "2020-09-11T01:37:00.896Z"
        },
        {
          consignmentNumber: "12337",
          containerNumber: "ABCD1234567",
          physicalInspectionLocation: null,
          status: "deleted",
          type: null,
          timestamp: "2020-09-11T01:37:00.896Z"
        },
      ],
      'bulkDischarges': [
        {
          'consignmentNumber': '12334',
          'itemNumber': 1,
          'grossWeight': 1234
        }
      ],
      'declarations': [
        {
          friendlyId: '27BGHtgoeOkTJlI8f8Sxq4',
          id: 'some random id',
          rejectReasons: null,
          status: 'REJECTED',
          timeStamp: '2020-09-10T01:37:00.896Z',
          type: 'ENS'
        },
        {
          friendlyId: '27BGHtgoeOkTJlI8f8Sxq2',
          id: 'ESBCN',
          rejectReasons: null,
          status: 'REJECTED',
          timeStamp: '2020-09-10T01:37:00.896Z',
          type: 'SDT'
        },
        {
          friendlyId: '27BGHtgoeOkTJlI8f8Sxq2',
          id: 'ESBCN',
          rejectReasons: null,
          status: 'ACCEPTED',
          timeStamp: '2020-09-10T01:37:00.896Z',
          type: 'SDT'
        },
        {
          friendlyId: '27BGHtgoeOkTJlI8f8Sxq2',
          id: 'ESLUL',
          rejectReasons: null,
          status: 'REJECTED',
          timeStamp: '2020-09-10T01:37:00.896Z',
          type: 'SDT'
        },
        {
          friendlyId: '27BGHtgoeOkTJlI8f8Sxq6',
          id: '52090469',
          rejectReasons: null,
          status: 'DECLARED',
          timeStamp: '2020-09-10T01:37:00.896Z',
          type: 'COPRAR'
        },
        {
          friendlyId: '27BGHtgoeOkTJlI8f8Sxq7',
          id: '52090469',
          rejectReasons: null,
          status: 'DECLARED',
          timeStamp: '2020-09-10T01:37:00.897Z',
          type: 'COPRAR'
        }
      ],
      'consignmentDifferences': <ConsignmentDifference[]>[{
        'consignmentNumber': '12334',
        'timestamp': '2018-11-08T11:00:00Z',
        'portOfLoadingUnCode': 'ESBCN',
        'itemDifferences': [
          {'itemNumber': 1, 'remainingPackages': 1, 'remainingWeight': 1000}
        ]
      }],
      clearedManifestIds: [],
      incomingMessages: <IncomingMessage[]>[{
        id: '12334',
        processId: 'dummy',
        type: 'IFTMCS',
        timeStamp: '2020-09-10T01:37:00.897Z'
      }]
    }],
    "loadingDeclarations": [{
      "cargoDeclarant": {
        "fullName": "Cargo agent 1",
        "portAuthorityId": "CHA1_R",
        "shortName": "CHA1",
        "emailAddress": "d.reijnders@portbase.com",
        "address": "Cargo Operatorlaan 1",
        "city": "Cargo Operatorstad",
        "countryUnCode": "NL",
        "countryName": "Netherlands",
        "zipCode": "1111SO",
        "phoneNumber": "0102522288",
        "faxNumber": null,
        "contact": "TC",
        "customsEORINumber": "NL123456789",
        "cargoImportEnabled": true
      },
      "loadings": [{
        "number": "TRIU8135113",
        "portOfDischarge": null,
        "terminal": {
          "terminalName": "ECTDELTA",
          "terminalCode": "ECTDELTA",
          "organisationShortName": "ECTDELTA",
          "organisationName": "ECTDELTA"
        },
        "actualTimeOfHandling": "2021-03-17T23:50:00Z",
        "stowageLocation": null,
        "grossWeight": null,
        "transportStatus": "EXPORT",
        "agentVoyageNumber": "NL110A",
        "type": null,
        "sizeType": null,
        "containerOperator": {
          "customsId": null,
          "name": null,
          "scacCode": "MSCU",
          "smdgCode": null
        },
        "cargoAgentShortName": "MSCRTM",
        "tareWeight": null,
        "bookingReference": "459IHA0407839"
      }, {
        "number": "TRLU1709718",
        "portOfDischarge": null,
        "terminal": {
          "terminalName": "APMRTM",
          "terminalCode": "APMRTM",
          "organisationShortName": "APMRTM",
          "organisationName": "APMRTM"
        },
        "actualTimeOfHandling": "2021-03-17T23:51:00Z",
        "stowageLocation": null,
        "grossWeight": null,
        "transportStatus": "EXPORT",
        "agentVoyageNumber": "NL110A",
        "type": null,
        "sizeType": null,
        "containerOperator": {
          "customsId": null,
          "name": null,
          "scacCode": "MSCU",
          "smdgCode": null
        },
        "cargoAgentShortName": "MSCRTM",
        "tareWeight": null,
        "bookingReference": "459IHA0407839"
      }, {
        "number": "TTNU8461687",
        "portOfDischarge": null,
        "terminal": {
          "terminalName": "ECTDELTA",
          "terminalCode": "ECTDELTA",
          "organisationShortName": "ECTDELTA",
          "organisationName": "ECTDELTA"
        },
        "actualTimeOfHandling": "2021-03-17T23:54:00Z",
        "stowageLocation": null,
        "grossWeight": null,
        "transportStatus": "EXPORT",
        "agentVoyageNumber": "NL110A",
        "type": null,
        "sizeType": null,
        "containerOperator": {
          "customsId": null,
          "name": null,
          "scacCode": "MSCU",
          "smdgCode": null
        },
        "cargoAgentShortName": "MSCRTM",
        "tareWeight": null,
        "bookingReference": "459IHA0407839"
      }]
    }],
    "incomingTidalWindowStatus": {
      tidalShip: true,
      remarks: 'let op dat je niet vastloopt',
      tidalWindows: [
        {
          start: '2018-11-09T15:00:00Z',
          end: '2018-11-09T17:00:00Z'
        },
      ],
      tidalWindowType: 'ACTUAL'
    },
    'berthVisitInfos': {
      '1abbb8789fe94b5c8adad25550a0a6b1':
        <BerthVisitInfo>{
          'harbourMasterInfo': {
            'etd': '2018-11-09T15:00:00Z',
            tidalWindowStatus: {
              tidalShip: true,
              remarks: 'let op dat je niet vastloopt',
              tidalWindows: [
                {
                  start: '2018-11-09T15:00:00Z',
                  end: '2018-11-09T17:00:00Z'
                },
              ],
              tidalWindowType: 'ASTRO'
            }
          }
        },
      "abb42b0d775d44fca7e2d392d16ff340": <BerthVisitInfo>{
        'harbourMasterInfo': {
          "etd": "2018-11-09T23:09:00Z",
          "movementOnHold": true
        }
      }
    },
    'visitDeclaration': {
      'portVisit': {
        'etaPort': '2018-11-09T10:00:00Z',
        'firstMovement': {
          'vesselDraft': 6.0,
          'vesselMasterName': 'Henk',
          'numberOfCrew': 20,
          'numberOfPassengers': 6,
          'cargo': 'OUT_OF_GAUGE',
          'pilotExemption': {
            'holderName': '',
            'number': '16'
          },
          'offStandardBeam': {
            'value': 4.5,
            'remarks': ''
          },
          'pilotService': {
            'required': false
          }
        },
        "portEntry":
          {
            "earliestTimeOfPortEntry": "2021-11-26T04:00:00Z",
            "etaPilotBoardingPlace": "2021-11-28T04:00:00Z",
            "requestedEtaPilotBoardingPlace": "2021-11-28T04:00:00Z",
            "baseForPlanning": "PILOT_BOARDING_PLACE",
            "intention": "REQUEST_FOR_ENTRY",
            "origin": "SEA",
            "etaSeaBuoy": "2021-11-08T06:00:00Z",
            "entryDependency": {
              "vesselName": "Lenski",
              "vesselImoCode": "2342556",
              "dependencyType": "EXCHANGE",
              "orderStatus": "ORDERED",
              "autoOrder": true
            }
          },
        "berthVisits": [
          {
            'id': '1abbb8789fe94b5c8adad25550a0a6b1',
            'berth': {
              'name': 'HOLLAND AMERIKAKADE',
              'code': 'R1243',
              'terminalCode': '52090469',
              'terminalName': 'HOLLAND AMERIKAKADE',
              'organisationShortName': 'ECTDELTA',
              'berthGroupCode': 'HOLLAND AMERIKAKADE',
              'buoy': false,
              'partyToNotify': null
            },
            'terminal': {
              'terminalCode': '52090469',
              'terminalName': 'HOLLAND AMERIKAKADE',
              'organisationShortName': 'ECTDELTA',
              'organisationName': 'ECT Delta Terminal B.V.'
            },
            'stevedore': {
              'shortName': 'ECTDELTA',
              'fullName': 'ECT Delta Terminal B.V.',
              "location": {
                "name": "Rotterdam",
                "locationUnCode": "NLRTM"
              },
              "quaysForPlanning": ["DDE", "DDN"]
            },
            'eta': '2018-11-09T11:00:00Z',
            'ata': null,
            'etd': '2018-11-10T22:00:00Z',
            'atd': null,
            'mooring': 'PORT_SIDE',
            'remarks': null,
            'terminalPlanningEnabled': true,
            'visitPurposes': [
              'CARGO_TANK_CLEANING',
              'CUSTOMS_CLEARANCE',
              'DISPOSAL_OF_WASTE',
              'LAID_UP',
              'LOADING'
            ],
            'bollardFrom': null,
            'bollardTo': null,
            'tugboatAtArrival': {
              'required': true,
              'serviceProvider': {
                'name': 'Gepke',
                'portAuthorityId': 'VERSCHOOR'
              },
              'requiredQuantity': 'SIX',
              'remarks': null
            },
            'boatmenAtArrival': {
              'required': true,
              'serviceProvider': {
                'name': 'V.i.o.s.',
                'portAuthorityId': 'VIOS'
              },
              'remarks': null
            },
            'boatmenAtDeparture': {
              'required': true,
              'serviceProvider': {
                'name': 'V.i.o.s.',
                'portAuthorityId': 'VIOS'
              },
              'remarks': null
            },
            'tugboatAtDeparture': {
              'required': true,
              'serviceProvider': {
                'name': 'Gepke',
                'portAuthorityId': 'VERSCHOOR'
              },
              'requiredQuantity': 'SIX',
              'remarks': null
            },
            'nextMovement': {
              'order': true,
              'vesselDraft': 7.0,
              'vesselMasterName': 'Piet',
              'numberOfCrew': 21,
              'numberOfPassengers': 7,
              'cargo': 'OUT_OF_GAUGE',
              'pilotExemption': {
                'holderName': '',
                'number': '16'
              },
              'offStandardBeam': {
                'value': 4.5,
                'remarks': ''
              },
              'pilotService': null
            }
          },
          {
            "id": "abb42b0d775d44fca7e2d392d16ff340",
            "berth": {
              "name": "PET 3 B 61",
              "code": "R61",
              "terminalCode": "47644472",
              "terminalName": "PET 3 B 61",
              "berthGroupCode": "PET 3 B 61",
              "buoy": true,
              "partyToNotify": null
            },
            'terminal': {
              "terminalCode": "47644472",
              "terminalName": "PET 3 B 61",
              'organisationShortName': 'ECTDELTA',
              'organisationName': 'ECT Delta Terminal B.V.'
            },
            'stevedore': {
              'shortName': 'ECTDELTA',
              'fullName': 'ECT Delta Terminal B.V.',
              "location": {
                "name": "Rotterdam",
                "locationUnCode": "NLRTM"
              },
              "quaysForPlanning": ["DDE", "DDN"]
            },
            "eta": "2018-11-11T11:00:00Z",
            "ata": null,
            "etd": "2018-11-11T22:00:00Z",
            "atd": null,
            "requestedEtd": "2018-11-11T22:00:00Z",
            "mooring": "STARBOARD_SIDE",
            "remarks": null,
            'terminalPlanningEnabled': true,
            "visitPurposes": [
              "CREW_MOVEMENT",
              "LOADING",
              "REFUGE"
            ],
            'bollardFrom': 16.2,
            'bollardTo': 20.2,
            'tugboatAtArrival': {
              'required': false,
              'serviceProvider': {
                'name': 'Gepke',
                'portAuthorityId': 'VERSCHOOR'
              },
              'requiredQuantity': null,
              'remarks': null
            },
            'boatmenAtArrival': {
              'required': true,
              'serviceProvider': {
                'name': 'V.i.o.s.',
                'portAuthorityId': 'VIOS'
              },
              'remarks': null
            },
            'boatmenAtDeparture': null,
            'tugboatAtDeparture': {
              'required': false,
              'serviceProvider': {
                'name': 'Gepke met oude naam',
                'portAuthorityId': 'VERSCHOOR'
              },
              'requiredQuantity': null,
              'remarks': null
            },
            'nextMovement': {
              'vesselDraft': 12.5,
              'vesselMasterName': 'Henk',
              'numberOfCrew': 20,
              'numberOfPassengers': 6,
              'cargo': 'BALLAST_CONDITION',
              'pilotExemption': {
                'holderName': 'Exemption holder 1',
                'number': '12345'
              },
              'offStandardBeam': {
                'value': 12.8,
                'remarks': 'No remarks'
              },
              'pilotService': null
            }
          }
        ],
        'pilotStation': {
          'code': 'AL',
          'name': 'ACHTERLAND'
        },
        'entryPoint': {
          'code': 'LL',
          'name': 'Lage Licht'
        },
        'passingThroughTugboats': null,
        'defectTypes': [
          'DEN',
          'DWE',
          'TOW'
        ],
        'dangerousGoodsLoading': true,
        'dangerousGoodsDischarge': false,
        'dangerousGoodsTransit': true,
        'possibleAnchorage': true,
        'vesselInspectionRequired': true,
        'shipConfiguration': 'DOUBLE_HULL_TANKER',
        'exitPoint': {
          'code': 'OM',
          'name': 'Oude Maas',
          'atSea': false
        },
        'etdPort': '2018-11-13T11:00:00Z',
        'atdPort': null
      },
      'clientReferenceNumber': '7',
      'etaFirstEntryEu': '2018-11-07T11:00:00Z',
      'arrivalVoyage': {
        'voyageNumber': '1234567',
        'carrier': {
          'customsId': 'NL100000897',
          'name': 'Carrier 1',
          'scacCode': 'CARA',
          'smdgCode': 'CAR'
        }
      },
      'previousPorts': [
        {
          "id": "08c4f047-05a1-499e-9e7d-b5165d0d37b1",
          "arrival": "2021-09-26T10:00:00Z",
          "departure": "2021-09-27T10:00:00Z",
          "port": {
            "name": "Le Havre",
            "locationUnCode": "FRLEH",
            "countryUnCode": "FR",
            "euPort": true
          },
          "portFacilityVisits": []
        },
        {
          "id": "a124f350-8c24-462f-96c9-9d70521ed25a",
          "arrival": "2021-09-24T10:00:00Z",
          "departure": "2021-09-25T10:00:00Z",
          "port": {
            "name": "Southampton",
            "locationUnCode": "GBSOU",
            "countryUnCode": "GB",
            "euPort": false
          },
          "portFacilityVisits": [
            {
              "portFacility": {
                "code": "GBSOU-0001",
                "name": "Port of Southampton"
              },
              "arrivalDate": null,
              "departureDate": null,
              "securityLevel": "SL1",
              "additionalSecurityMeasures": null
            }
          ]
        },
        {
          "id": "af98fa10-c5ef-45ac-956e-fef7ae5b6b28",
          "arrival": null,
          "departure": null,
          "port": {
            "name": "Barcelona",
            "locationUnCode": "ESBCN",
            "countryUnCode": "ES",
            "euPort": true
          },
          "portFacilityVisits": [
            {
              "portFacility": {
                "code": "ESBCN-0042",
                "name": "TERMINAL DE CONTENEDORES DE BARCELONA, S.L (TCB)"
              },
              "arrivalDate": null,
              "departureDate": null,
              "securityLevel": "SL1",
              "additionalSecurityMeasures": null
            }
          ]
        },
        {
          "id": "28adb252-4d9d-475b-9fc4-35ac597c9b6c",
          "arrival": null,
          "departure": null,
          "port": {
            "name": "Genova",
            "locationUnCode": "ITGOA",
            "countryUnCode": "IT",
            "euPort": true
          },
          "portFacilityVisits": [
            {
              "portFacility": {
                "code": "ITGOA-0015",
                "name": "Terminal Saar - Ponte Paleocapa"
              },
              "arrivalDate": null,
              "departureDate": null,
              "securityLevel": "SL1",
              "additionalSecurityMeasures": null
            }
          ]
        },
        {
          "id": "54ba4767-1961-4e35-8b3e-7c5b33d8b857",
          "arrival": null,
          "departure": null,
          "port": {
            "name": "New York",
            "locationUnCode": "USNYC",
            "countryUnCode": "US",
            "euPort": false
          },
          "portFacilityVisits": [
            {
              "portFacility": {
                "code": "USNYC-0001",
                "name": "New York Port Area"
              },
              "arrivalDate": null,
              "departureDate": null,
              "securityLevel": "SL1",
              "additionalSecurityMeasures": null
            }
          ]
        },
        {
          "id": "23725500-0ef5-47b3-bbac-9331247f71d5",
          "arrival": null,
          "departure": null,
          "port": {
            "name": "Savannah",
            "locationUnCode": "USSAV",
            "countryUnCode": "US",
            "euPort": false
          },
          "portFacilityVisits": [
            {
              "portFacility": {
                "code": "USSAV-0001",
                "name": "Savannah Port Area"
              },
              "arrivalDate": null,
              "departureDate": null,
              "securityLevel": "SL1",
              "additionalSecurityMeasures": null
            }
          ]
        },
        {
          "id": "7a64dacc-22a8-4f8b-a7b9-8b254567ba50",
          "arrival": null,
          "departure": null,
          "port": {
            "name": "Norfolk",
            "locationUnCode": "USORF",
            "countryUnCode": "US",
            "euPort": false
          },
          "portFacilityVisits": [
            {
              "portFacility": {
                "code": "USORF-0001",
                "name": "Hampton Roads"
              },
              "arrivalDate": null,
              "departureDate": null,
              "securityLevel": "SL1",
              "additionalSecurityMeasures": null
            }
          ]
        },
        {
          "id": "a4ec5aaf-9409-43cb-806e-7baebb41ef67",
          "arrival": null,
          "departure": null,
          "port": {
            "name": "New York",
            "locationUnCode": "USNYC",
            "countryUnCode": "US",
            "euPort": false
          },
          "portFacilityVisits": [
            {
              "portFacility": {
                "code": "USNYC-0001",
                "name": "New York Port Area"
              },
              "arrivalDate": null,
              "departureDate": null,
              "securityLevel": "SL1",
              "additionalSecurityMeasures": null
            }
          ]
        },
        {
          "id": "acb875f1-c90d-4e7f-a9f4-03a4c4c069f4",
          "arrival": null,
          "departure": null,
          "port": {
            "name": "Suez Canal",
            "locationUnCode": "EGSCN",
            "countryUnCode": "EG",
            "euPort": false
          },
          "portFacilityVisits": []
        },
        {
          "id": "d74537de-903d-4033-b62a-622dce6f156c",
          "arrival": null,
          "departure": null,
          "port": {
            "name": "Colombo",
            "locationUnCode": "LKCMB",
            "countryUnCode": "LK",
            "euPort": false
          },
          "portFacilityVisits": [
            {
              "portFacility": {
                "code": "LKCMB-0001",
                "name": "Port of Colombo"
              },
              "arrivalDate": null,
              "departureDate": null,
              "securityLevel": "SL1",
              "additionalSecurityMeasures": null
            }
          ]
        },
        {
          "id": "f09f8154-27df-4910-a405-8e0e13d559a4",
          "arrival": null,
          "departure": null,
          "port": {
            "name": "Savannah",
            "locationUnCode": "USSAV",
            "countryUnCode": "US",
            "euPort": false
          },
          "portFacilityVisits": []
        },
        {
          "id": "c98e8c45-bffd-497f-865a-c0d588390b5e",
          "arrival": null,
          "departure": null,
          "port": {
            "name": "Charleston",
            "locationUnCode": "USCHS",
            "countryUnCode": "US",
            "euPort": false
          },
          "portFacilityVisits": []
        },
        {
          "id": "87becfbf-c5d3-4557-bb08-8a760544862a",
          "arrival": null,
          "departure": null,
          "port": {
            "name": "New York",
            "locationUnCode": "USNYC",
            "countryUnCode": "US",
            "euPort": false
          },
          "portFacilityVisits": []
        },
        {
          "id": "15a991c2-97fc-4d3d-a5b4-a674231f2a1c",
          "arrival": null,
          "departure": null,
          "port": {
            "name": "Savannah",
            "locationUnCode": "USSAV",
            "countryUnCode": "US",
            "euPort": false
          },
          "portFacilityVisits": []
        },
        {
          "id": "9bc2db6a-d857-497d-8c46-affb8059f4a6",
          "arrival": null,
          "departure": null,
          "port": {
            "name": "Charleston",
            "locationUnCode": "USCHS",
            "countryUnCode": "US",
            "euPort": false
          },
          "portFacilityVisits": []
        },
        {
          "id": "19e4b869-13e2-4583-b367-c088253bb54a",
          "arrival": null,
          "departure": null,
          "port": {
            "name": "New York",
            "locationUnCode": "USNYC",
            "countryUnCode": "US",
            "euPort": false
          },
          "portFacilityVisits": []
        },
        {
          "id": "c38d1c1c-bbcd-4b2c-a9dc-64555083e029",
          "arrival": null,
          "departure": null,
          "port": {
            "name": "Hamburg",
            "locationUnCode": "DEHAM",
            "countryUnCode": "DE",
            "euPort": true
          },
          "portFacilityVisits": []
        },
        {
          "id": "eadd62ab-0f60-48a5-b947-c27e9f177ac6",
          "arrival": null,
          "departure": null,
          "port": {
            "name": "Le Havre",
            "locationUnCode": "FRLEH",
            "countryUnCode": "FR",
            "euPort": true
          },
          "portFacilityVisits": []
        },
        {
          "id": "6029981d-c8a1-4c8f-bd23-93fd53a6d6a3",
          "arrival": null,
          "departure": null,
          "port": {
            "name": "London Gateway Port",
            "locationUnCode": "GBLGP",
            "countryUnCode": "GB",
            "euPort": false
          },
          "portFacilityVisits": []
        },
        {
          "id": "f20c8d14-0c04-45f3-ab19-a9bf4cda441a",
          "arrival": null,
          "departure": null,
          "port": {
            "name": "Cartagena",
            "locationUnCode": "COCTG",
            "countryUnCode": "CO",
            "euPort": false
          },
          "portFacilityVisits": []
        },
        {
          "id": "2e7667ba-f8c5-41ce-922c-11c0ed892302",
          "arrival": null,
          "departure": null,
          "port": {
            "name": "Buenaventura",
            "locationUnCode": "COBUN",
            "countryUnCode": "CO",
            "euPort": false
          },
          "portFacilityVisits": []
        },
        {
          "id": "20c5e407-1c6c-4d6b-97bc-4c31a6b99dae",
          "arrival": null,
          "departure": null,
          "port": {
            "name": "Posorja",
            "locationUnCode": "ECPSJ",
            "countryUnCode": "EC",
            "euPort": false
          },
          "portFacilityVisits": []
        },
        {
          "id": "3d8c3d03-814b-45bc-acfb-b30494942d38",
          "arrival": null,
          "departure": null,
          "port": {
            "name": "Taiping",
            "locationUnCode": "CNTAP",
            "countryUnCode": "CN",
            "euPort": false
          },
          "portFacilityVisits": []
        },
        {
          "id": "03cf8d23-c54c-40fa-81f3-6f1b633aef3e",
          "arrival": null,
          "departure": null,
          "port": {
            "name": "DA CHAN BAY",
            "locationUnCode": "CNDCB",
            "countryUnCode": "CN",
            "euPort": false
          },
          "portFacilityVisits": []
        },
        {
          "id": "4da990d0-dff3-4718-a039-9aeaa5f03cf6",
          "arrival": null,
          "departure": null,
          "port": {
            "name": "Charleston",
            "locationUnCode": "USCHS",
            "countryUnCode": "US",
            "euPort": false
          },
          "portFacilityVisits": []
        },
        {
          "id": "162c330e-c0cb-4c79-8e59-bc461f62be4d",
          "arrival": null,
          "departure": null,
          "port": {
            "name": "Singapore",
            "locationUnCode": "SGSIN",
            "countryUnCode": "SG",
            "euPort": false
          },
          "portFacilityVisits": []
        },
        {
          "id": "2ba1b829-71f1-40f8-824d-b5aef5fb982c",
          "arrival": null,
          "departure": null,
          "port": {
            "name": "Laem Chabang",
            "locationUnCode": "THLCH",
            "countryUnCode": "TH",
            "euPort": false
          },
          "portFacilityVisits": []
        },
        {
          "id": "dc58700a-a5e8-44d4-8aaf-5d5c204d9123",
          "arrival": null,
          "departure": null,
          "port": {
            "name": "Tanjung Pelepas",
            "locationUnCode": "MYTPP",
            "countryUnCode": "MY",
            "euPort": false
          },
          "portFacilityVisits": []
        },
        {
          "id": "f16a4ff7-0219-4b0d-be0b-b77836433eae",
          "arrival": null,
          "departure": null,
          "port": {
            "name": "Algeciras",
            "locationUnCode": "ESALG",
            "countryUnCode": "ES",
            "euPort": true
          },
          "portFacilityVisits": []
        },
        {
          "id": "f8593799-64b8-4150-a1ab-c1e6091b8624",
          "arrival": null,
          "departure": null,
          "port": {
            "name": "Genova",
            "locationUnCode": "ITGOA",
            "countryUnCode": "IT",
            "euPort": true
          },
          "portFacilityVisits": []
        },
        {
          "id": "9ff3cae2-0d2f-40e4-b207-48395a9118a6",
          "arrival": null,
          "departure": null,
          "port": {
            "name": "Livorno",
            "locationUnCode": "ITLIV",
            "countryUnCode": "IT",
            "euPort": true
          },
          "portFacilityVisits": []
        },
        {
          "id": "2bb9c0ba-0d5f-4f8f-980b-6a28d555ca9b",
          "arrival": null,
          "departure": null,
          "port": {
            "name": "Valencia",
            "locationUnCode": "ESVLC",
            "countryUnCode": "ES",
            "euPort": true
          },
          "portFacilityVisits": []
        },
        {
          "id": "79dfc007-3210-424c-b2ed-c248363b48f2",
          "arrival": null,
          "departure": null,
          "port": {
            "name": "Algeciras",
            "locationUnCode": "ESALG",
            "countryUnCode": "ES",
            "euPort": true
          },
          "portFacilityVisits": []
        },
        {
          "id": "d7d05e4f-ea6f-4dfd-bb2a-4ced158b327f",
          "arrival": null,
          "departure": null,
          "port": {
            "name": "New Orleans",
            "locationUnCode": "USMSY",
            "countryUnCode": "US",
            "euPort": false
          },
          "portFacilityVisits": []
        },
        {
          "id": "9df698c8-dbd1-4989-8274-8e493aaba7e5",
          "arrival": null,
          "departure": null,
          "port": {
            "name": "Houston",
            "locationUnCode": "USHOU",
            "countryUnCode": "US",
            "euPort": false
          },
          "portFacilityVisits": []
        },
        {
          "id": "37628b60-1d98-4772-9c79-2692f13c976d",
          "arrival": null,
          "departure": null,
          "port": {
            "name": "Norfolk",
            "locationUnCode": "USORF",
            "countryUnCode": "US",
            "euPort": false
          },
          "portFacilityVisits": []
        },
        {
          "id": "e8a69b49-c116-417b-bd8a-bd9017e1ac6b",
          "arrival": null,
          "departure": null,
          "port": {
            "name": "Savannah",
            "locationUnCode": "USSAV",
            "countryUnCode": "US",
            "euPort": false
          },
          "portFacilityVisits": []
        },
        {
          "id": "d7005145-5105-4196-8304-11b4996cbf76",
          "arrival": null,
          "departure": null,
          "port": {
            "name": "Bremerhaven",
            "locationUnCode": "DEBRV",
            "countryUnCode": "DE",
            "euPort": true
          },
          "portFacilityVisits": []
        },
        {
          "id": "7521d1ce-59cb-4345-a6cb-99f5981f234c",
          "arrival": null,
          "departure": null,
          "port": {
            "name": "Oakland",
            "locationUnCode": "USOAK",
            "countryUnCode": "US",
            "euPort": false
          },
          "portFacilityVisits": []
        },
        {
          "id": "cfc52118-41f5-4a92-96a5-1c09883d2257",
          "arrival": null,
          "departure": null,
          "port": {
            "name": "Philadelphia",
            "locationUnCode": "USPHL",
            "countryUnCode": "US",
            "euPort": false
          },
          "portFacilityVisits": []
        },
        {
          'id': '7239cd11943846a78869f7260f0fc219',
          'arrival': '2018-11-07T11:00:00Z',
          'departure': '2018-11-08T11:00:00Z',
          'port': {
            'name': 'Barcelona',
            'locationUnCode': 'ESBCN',
            'countryUnCode': 'ES',
            'euPort': true
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'ESBCN-0001',
                'name': 'Barcelona, Spain  AUTOTERMINAL, S.A.'
              },
              'arrivalDate': '2018-11-07',
              'departureDate': '2018-11-08',
              'securityLevel': 'SL1',
              'additionalSecurityMeasures': 'Measures 1'
            }
          ]
        },
        {
          'id': '39af59bb1dd344339f84d12c4533bd03',
          'arrival': '2018-11-06T11:00:00Z',
          'departure': '2018-11-07T11:00:00Z',
          'port': {
            'name': 'Tunisi',
            'locationUnCode': 'ITTUN',
            'countryUnCode': 'IT',
            'euPort': true
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'ITTUN-9999',
                'name': 'UNKNOWN'
              },
              'arrivalDate': '2018-11-06',
              'departureDate': '2018-11-07',
              'securityLevel': 'SL2',
              'additionalSecurityMeasures': 'Measures 2'
            }
          ]
        },
        {
          'id': '8a49e9d4d39647188bb8bd3d57dd4fa7',
          'arrival': '2018-11-05T11:00:00Z',
          'departure': '2018-11-06T11:00:00Z',
          'port': {
            'name': 'Barcelona',
            'locationUnCode': 'ESBCN',
            'countryUnCode': 'ES',
            'euPort': true
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'ESBCN-0003',
                'name': 'Barcelona, Spain  CENTRO DE CONTROL'
              },
              'arrivalDate': '2018-11-05',
              'departureDate': '2018-11-06',
              'securityLevel': 'SL3',
              'additionalSecurityMeasures': 'Measures 3'
            }
          ]
        },
        {
          'id': '2f4ae45bc63643de9fe7678f8021940d',
          'arrival': '2018-11-04T11:00:00Z',
          'departure': '2018-11-05T11:00:00Z',
          'port': {
            'name': 'Tunisi',
            'locationUnCode': 'ITTUN',
            'countryUnCode': 'IT',
            'euPort': true
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'ITTUN-9999',
                'name': 'UNKNOWN'
              },
              'arrivalDate': '2018-11-04',
              'departureDate': '2018-11-05',
              'securityLevel': 'SL1',
              'additionalSecurityMeasures': 'Measures 4'
            }
          ]
        },
        {
          'id': 'bb63bcf604c44adc8aada67c61a367c2',
          'arrival': '2018-11-03T11:00:00Z',
          'departure': '2018-11-04T11:00:00Z',
          'port': {
            'name': 'Barcelona',
            'locationUnCode': 'ESBCN',
            'countryUnCode': 'ES',
            'euPort': true
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'ESBCN-0005',
                'name': 'Barcelona, Spain  COMPAÑÍA LOGISTICA DE HIDROCARBUROS (CLH)'
              },
              'arrivalDate': '2018-11-03',
              'departureDate': '2018-11-04',
              'securityLevel': 'SL2',
              'additionalSecurityMeasures': 'Measures 5'
            }
          ]
        },
        {
          'id': '24a4865f6a494d2abc9c8d477705ad5c',
          'arrival': '2018-11-02T11:00:00Z',
          'departure': '2018-11-03T11:00:00Z',
          'port': {
            'name': 'Tunisi',
            'locationUnCode': 'ITTUN',
            'countryUnCode': 'IT',
            'euPort': true
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'ITTUN-9999',
                'name': 'UNKNOWN'
              },
              'arrivalDate': '2018-11-02',
              'departureDate': '2018-11-03',
              'securityLevel': 'SL3',
              'additionalSecurityMeasures': 'Measures 6'
            }
          ]
        },
        {
          'id': 'e4f33384c9f94439855c194b8d5ededb',
          'arrival': '2018-11-01T11:00:00Z',
          'departure': '2018-11-02T11:00:00Z',
          'port': {
            'name': 'Reykjavik',
            'locationUnCode': 'ISREY',
            'countryUnCode': 'IS',
            'euPort': true
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'ISREY-0007',
                'name': 'Reykjavik'
              },
              'arrivalDate': '2018-11-01',
              'departureDate': '2018-11-02',
              'securityLevel': 'SL1',
              'additionalSecurityMeasures': 'Measures 7'
            }
          ]
        },
        {
          'id': 'a51f713703c94ce885487c6645a1c76e',
          'arrival': '2018-10-31T11:00:00Z',
          'departure': '2018-11-01T11:00:00Z',
          'port': {
            'name': 'Tunisi',
            'locationUnCode': 'ITTUN',
            'countryUnCode': 'IT',
            'euPort': true
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'ITTUN-9999',
                'name': 'UNKNOWN'
              },
              'arrivalDate': '2018-10-31',
              'departureDate': '2018-11-01',
              'securityLevel': 'SL2',
              'additionalSecurityMeasures': 'Measures 8'
            }
          ]
        },
        {
          'id': '0ffbad66973446f4b354c1f3d29a294d',
          'arrival': '2018-10-30T11:00:00Z',
          'departure': '2018-10-31T11:00:00Z',
          'port': {
            'name': 'Barcelona',
            'locationUnCode': 'ESBCN',
            'countryUnCode': 'ES',
            'euPort': true
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'ESBCN-0009',
                'name': 'Barcelona, Spain  DECAL ESPAÑA S.A.'
              },
              'arrivalDate': '2018-10-30',
              'departureDate': '2018-10-31',
              'securityLevel': 'SL3',
              'additionalSecurityMeasures': 'Measures 9'
            }
          ]
        },
        {
          'id': '2fe3987117624e4e982eadb5fcf6b30f',
          'arrival': '2018-10-29T11:00:00Z',
          'departure': '2018-10-30T11:00:00Z',
          'port': {
            'name': 'Tunisi',
            'locationUnCode': 'ITTUN',
            'countryUnCode': 'IT',
            'euPort': true
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'ITTUN-9999',
                'name': 'UNKNOWN'
              },
              'arrivalDate': '2018-10-29',
              'departureDate': '2018-10-30',
              'securityLevel': 'SL2',
              'additionalSecurityMeasures': 'Measures 10'
            }
          ]
        },
        {
          'id': '14b77c47a3b24a6bb058d9998f89f213',
          'arrival': '2018-10-28T11:00:00Z',
          'departure': '2018-10-29T11:00:00Z',
          'port': {
            'name': 'Barcelona',
            'locationUnCode': 'ESBCN',
            'countryUnCode': 'ES',
            'euPort': true
          },
          'portFacilityVisits': []
        },
        {
          'id': '62ba903b912543b18438f132d2409263',
          'arrival': '2018-10-27T11:00:00Z',
          'departure': '2018-10-28T11:00:00Z',
          'port': {
            'name': 'Tunisi',
            'locationUnCode': 'ITTUN',
            'countryUnCode': 'IT',
            'euPort': true
          },
          'portFacilityVisits': []
        }
      ],
      'departureVoyage': {
        'voyageNumber': '1234568',
        'carrier': {
          'customsId': '12345',
          'name': null,
          'scacCode': null,
          'smdgCode': null
        }
      },
      'nextPorts': [
        {
          'id': 'f94034d2d4624e778775625184f22091',
          'arrival': '2018-11-14T11:00:00Z',
          'departure': '2018-11-15T11:00:00Z',
          'port': {
            'name': 'Antwerpen',
            'locationUnCode': 'BEANR',
            'countryUnCode': 'BE',
            'euPort': true
          },
          'customsOffice': null
        },
        {
          'id': 'd8d6ca9c2fa44747b4d8f111a75e11c7',
          'arrival': '2018-11-22T11:00:00Z',
          'departure': '2018-11-23T11:00:00Z',
          'port': {
            'name': 'Taranto',
            'locationUnCode': 'ITTAR',
            'countryUnCode': 'IT',
            'euPort': true
          },
          'customsOffice': {
            'name': 'S.O.T. AEROPORTO DELLO STRETTO',
            'unCode': 'IT087101'
          }
        },
        {
          'id': 'f4e5100bad6d4aa4a2cff0412bf963e0',
          'arrival': '2018-11-24T11:00:00Z',
          'departure': '2018-11-27T11:00:00Z',
          'port': {
            'name': 'El Iskandariya (= Alexandria)',
            'locationUnCode': 'EGALY',
            'countryUnCode': 'EG',
            'euPort': false
          },
          'customsOffice': null
        }
      ]
    },
    'dangerousGoodsDeclarations': [
      {
        dangerousGoods: {
          'goods': [
            <Chemical>{
              'id': '01P',
              'goodData': <ChemicalData>{
                'name': 'JATROPHA OIL',
                'stowageType': 'tank',
                'pollutionCategory': 'Y',
                'goodCode': '5004970300',
                'type': 'chemical',
                'hazardClass': 'P',
                'specOpRequirements': '15.19.6,16.2.6',
                'prewashRequired': true
              },
              'viscosity': 1,
              'heatingOrder': 722,
              'meltingPoint': 100,
              'criticalTemperature': 111,
              'flashPoint': 123,
              'remarks': 'Toxic stuff',
              'type': 'chemical'
            },
            <ContainerGood>{
              'id': '04P',
              'goodData': <ContainerGoodData>{
                'type': 'containerGood',
                'id': '12345',
                'name': 'AEROSOLS',
                'unCode': '1950',
                'hazardClass': '2.3',
                'subsidiaryRisk': 'See SP63',
                'emsFireCode': 'F-D',
                'emsSpillageCode': 'S-U',
                'properties': null,
                'goodCode': '1950000000',
                'gdsCode': null,
                'segregationGroup': null,
                'stowageType': 'container',
                'packingGroup': 'GREAT_DANGER',
              },
              'type': 'containerGood',
              'flashPoint': 30,
              'radioactive': false,
            },
            <ContainerGood>{
              'id': '20f7f356-9b32-4be5-a560-f8e163760a2f',
              'goodData': <ContainerGoodData>{
                'type': 'containerGood',
                'id': '20f7f356-9b32-4be5-a560-f8e163760a2f',
                'name': 'AEROSOLS',
                'unCode': '1950',
                'hazardClass': '2.3',
                'subsidiaryRisk': 'See SP63',
                'emsFireCode': 'F-D',
                'emsSpillageCode': 'S-U',
                'properties': null,
                'goodCode': '1950000000',
                'gdsCode': null,
                'segregationGroup': null,
                'stowageType': 'container',
                'packingGroup': 'GREAT_DANGER',
              },
              'type': 'containerGood',
              'flashPoint': 30,
              'radioactive': false,
            },
            <Solid>{
              "id": "hg",
              "type": "solid",
              'goodData': <SolidData>{
                "stowageType" : "hold",
                "unCode" : null,
                "gdsCode" : null,
                "name" : "COPPER GRANULES",
                "id" : "hg",
                "type" : "solid",
                "goodCode" : "403935",
                "hazardClass" : "C"
              }
            }
          ],
          'stowageAtArrival': [
            {
              'stowageNumber': '01P',
              'goodId': '01P',
              'tankStatus': 'NOT_EMPTY',
              'type': 'tank',
              'consignmentReference': 'NL322345',
              'weight': 1000,
              'portOfDischarge': {
                'name': 'Rotterdam',
                'locationUnCode': 'NLRTM',
                'countryUnCode': 'NL',
                'euPort': true
              },
              'portOfLoading': {
                'name': 'Barcelona',
                'locationUnCode': 'ESBCN',
                'countryUnCode': 'ES',
                'euPort': true
              }
            },
            {
              'stowageNumber': '03P',
              'tankStatus': 'EMPTY',
              'type': 'tank',
              'weight': 0
            }, {
              'stowageNumber': 'H1',
              'type': 'hold',
              'weight': 110,
              'goodId': 'hg',
              'portOfDischarge': {
                'name': 'Rotterdam',
                'locationUnCode': 'NLRTM',
                'countryUnCode': 'NL',
                'euPort': true
              },
              'portOfLoading': {
                'name': 'Barcelona',
                'locationUnCode': 'ESBCN',
                'countryUnCode': 'ES',
                'euPort': true
              },
              'fumigated': true,
              'fumigation': {
                'fumigant': 'PHOSPHINE',
                'fumigationDate': '2023-07-31',
                'details': {
                  'type': 'sleeves',
                  'used': 100
                },
                'fumigator': 'ANTICIMEX'
              }
            }
            , {
              'stowageNumber': '04P',
              'goodId': '04P',
              'tankStatus': 'NOT_EMPTY',
              'type': 'tank',
              'consignmentReference': 'aaaa',
              'weight': 2000,
              'portOfDischarge': {
                'name': 'Rotterdam',
                'locationUnCode': 'NLRTM',
                'countryUnCode': 'NL',
                'euPort': true
              },
              'portOfLoading': {
                'name': 'Barcelona',
                'locationUnCode': 'ESBCN',
                'countryUnCode': 'ES',
                'euPort': true
              }
            }, {
              'type': 'container',
              'tankStatus': 'NOT_EMPTY',
              'trailer': false,
              'fumigated': false,
              'uncleanTankContainer': true,
              'transportInLimitedQuantity': false,
              'weight': 500,
              'stowageNumber': 'CONT1234567',
              'goodId': '20f7f356-9b32-4be5-a560-f8e163760a2f',
              'portOfLoading': {
                'name': 'Barcelona',
                'locationUnCode': 'ESBCN',
                'countryUnCode': 'ES',
                'euPort': true
              },
              'portOfDischarge': {
                'name': 'Rotterdam',
                'locationUnCode': 'NLRTM',
                'countryUnCode': 'NL',
                'euPort': true
              },
              'position': '0000001'
            }, {
              'type': 'container',
              'tankStatus': 'NOT_EMPTY',
              'trailer': true,
              'fumigated': false,
              'transportInLimitedQuantity': false,
              'weight': 500,
              'stowageNumber': '31-65-XY',
              'goodId': '20f7f356-9b32-4be5-a560-f8e163760a2f',
              'portOfLoading': {
                'name': 'Barcelona',
                'locationUnCode': 'ESBCN',
                'countryUnCode': 'ES',
                'euPort': true
              },
              'portOfDischarge': {
                'name': 'Rotterdam',
                'locationUnCode': 'NLRTM',
                'countryUnCode': 'NL',
                'euPort': true
              },
              'position': '0000002'
            }
          ],
          'handlings': [
            {
              'type': 'loading',
              'berthVisitId': '1abbb8789fe94b5c8adad25550a0a6b1',
              'stowages': [
                {
                  'stowageNumber': 'YYYY1234567',
                  'position': '124',
                  'type': 'container',
                  'goodId': '12345',
                  'portOfDischarge': {
                    'name': 'Rotterdam',
                    'locationUnCode': 'NLRTM',
                    'countryUnCode': 'NL',
                    'euPort': true
                  },
                  'emptied': true
                }
              ],
              'shipToShip': false
            },
            {
              'type': 'loading',
              'berthVisitId': '1abbb8789fe94b5c8adad25550a0a6b1',
              'stowages': [
                {
                  'stowageNumber': 'XXXX1234567',
                  'position': '123',
                  'type': 'container',
                  'goodId': '12345',
                  'weight': 1000,
                  'portOfDischarge': {
                    'name': 'Rotterdam',
                    'locationUnCode': 'NLRTM',
                    'countryUnCode': 'NL',
                    'euPort': true
                  },
                  'emptied': true
                }
              ],
              'shipToShip': false
            },
            {
              'type': 'discharge',
              'berthVisitId': '1abbb8789fe94b5c8adad25550a0a6b1',
              'stowages': [
                {
                  'stowageNumber': 'CONT1234567',
                  'type': 'container',
                  'emptied': true
                }
              ],
              'shipToShip': false
            },
            {
              'type': 'discharge',
              'berthVisitId': '1abbb8789fe94b5c8adad25550a0a6b1',
              'stowages': [
                {
                  'stowageNumber': '04P',
                  'type': 'tank',
                  'emptied': true,
                  'availableBulk': 2000
                }
              ],
              'shipToShip': false,
            },
            {
              'type': 'discharge',
              'berthVisitId': 'c1324bb553b2451d907e63e48d74c3e1',
              'stowages': [
                {
                  'stowageNumber': 'XXXX1234567',
                  'type': 'container',
                  'emptied': true
                }
              ],
              'shipToShip': false
            },
            {
              'type': 'discharge',
              'berthVisitId': 'c1324bb553b2451d907e63e48d74c3e1',
              'stowages': [
                {
                  'stowageNumber': '01P',
                  'type': 'tank',
                  'emptied': true,
                  'availableBulk': 1000
                }],
              'shipToShip': false
            }]
        },
        cargoDeclarant: {
          'fullName': 'Ship Operator 1',
          'portAuthorityId': 'SO1_R',
          'shortName': 'SO1',
          'emailAddress': 'd.reijnders@portbase.com',
          'address': 'Ship Operatorlaan 1',
          'city': 'Ship Operatorstad',
          'countryUnCode': 'NL',
          'countryName': 'Netherlands',
          'zipCode': '1111SO',
          'phoneNumber': '0102522288',
          'faxNumber': null,
          'contact': 'TC',
          'customsEORINumber': 'NL123456789'
        },
        visitDeclarant: {
          'fullName': 'Back Office 1',
          'portAuthorityId': 'BO1_R',
          'shortName': 'BO1',
          'emailAddress': 'pietjepuk@yoyo.nl',
          'address': 'Back Officelaan 1',
          'city': 'Back Officestad',
          'countryUnCode': 'NL',
          'countryName': 'Netherlands',
          'zipCode': '1111SO',
          'phoneNumber': '0102522288',
          'faxNumber': null,
          'contact': 'test centrum',
          'customsEORINumber': 'NL123456789'
        },
        visitOwner: {
          'fullName': 'Ship Operator 1',
          'portAuthorityId': 'SO1_R',
          'shortName': 'SO1',
          'emailAddress': 'd.reijnders@portbase.com',
          'address': 'Ship Operatorlaan 1',
          'city': 'Ship Operatorstad',
          'countryUnCode': 'NL',
          'countryName': 'Netherlands',
          'zipCode': '1111SO',
          'phoneNumber': '0102522288',
          'faxNumber': null,
          'contact': 'TC',
          'customsEORINumber': 'NL123456789'
        }
      }],
    'dangerousGoodsEnabled': true,
    'copyOfVisit': null,
    'etaPortAis': '2018-11-08T10:22:00Z',
    'portOfCall': {
      'port': {
        'name': 'Rotterdam',
        'locationUnCode': 'NLRTM',
        'countryUnCode': 'NL',
        'euPort': true
      },
      'portAuthority': {
        'fullName': 'Havenbedrijf Rotterdam N.V.',
        'portAuthorityId': 'HbR',
        'shortName': 'HBRRTM',
        'emailAddress': 'HCC@portofrotterdam.com',
        'address': 'Wilhelminakade 909',
        'city': 'Rotterdam',
        'countryUnCode': 'NL',
        'countryName': 'Netherlands',
        'zipCode': '3072 AP',
        'phoneNumber': '+31-10-2521195',
        'faxNumber': '+31-10-2521000',
        'contact': 'Diana van Eijk',
        'customsEORINumber': 'NL24354561',
        'ean': null
      },
      'customsOffice': {
        'name': 'Rotterdam Haven/Kantoor Maasvlakte',
        'unCode': 'NL000396'
      },
      'ataReported': true,
      'atdReported': true,
      'atdPortReported': true,
      'paDeclarationRequired': true,
      'swDeclarationRequired': true,
      'wasteEnabled': true,
      'dangerousGoodsEnabled': true,
      'harbourDuesEnabled': false,
      'orderNauticalServicesEnabled': true,
      'seaBuoy': {
        'name': 'Maascenter',
        'code': 'MC'
      }
    },
    'vessel': {
      'imoCode': '8758457',
      'name': 'DAVE BLACKBURN',
      'radioCallSign': 'WB6043',
      'motUnCode': '1729',
      'motName': 'Passenger ship',
      'summerDeadWeight': 0,
      'maxWidth': 15.84,
      'manoeuvringSpeed': null,
      'flagCountryUnCode': 'US',
      'netTonnage': 1338,
      'grossTonnage': 1338,
      'registrationPlaceUnloCode': 'USMSY',
      'registrationPlaceName': 'New Orleans',
      'registrationDate': '1944-02-01',
      'mmsiNumber': null,
      'helispotPresent': false,
      'fullLength': 0,
      'flagCode': 'USA'
    },
    'owner': {
      'fullName': 'Ship Operator 1',
      'portAuthorityId': 'SO1_R',
      'shortName': 'SO1',
      'emailAddress': 'pietjepuk@yoyo.nl',
      'address': 'Ship Operatorlaan 1',
      'city': 'Ship Operatorstad',
      'countryUnCode': 'NL',
      'countryName': 'Netherlands',
      'zipCode': '1111SO',
      'phoneNumber': '0102522288',
      'faxNumber': null,
      'contact': 'test centrum',
      'customsEORINumber': 'NL123456789',
      'cargoImportEnabled': true
    },
    'declarant': {
      'fullName': 'Ship Operator 1',
      'portAuthorityId': 'SO1_R',
      'shortName': 'SO1',
      'emailAddress': 'pietjepuk@yoyo.nl',
      'address': 'Officelaan 1',
      'city': 'Officestad',
      'countryUnCode': 'NL',
      'countryName': 'Netherlands',
      'zipCode': '1111SO',
      'phoneNumber': '0102522288',
      'faxNumber': null,
      'contact': 'test centrum',
      'customsEORINumber': 'NL123456789',
      'cargoImportEnabled': true
    },
    'financialDeclarant': {
      'fullName': 'Ship Operator 1',
      'portAuthorityId': 'SO1_R',
      'shortName': 'SO1',
      'emailAddress': 'pietjepuk@yoyo.nl',
      'address': 'Ship Operatorlaan 1',
      'city': 'Ship Operatorstad',
      'countryUnCode': 'NL',
      'countryName': 'Netherlands',
      'zipCode': '1111SO',
      'phoneNumber': '0102522288',
      'faxNumber': null,
      'contact': 'test centrum',
      'customsEORINumber': 'NL123456789'
    },
    'nominatedFinancialDeclarant': null,
    'creditNumberHolderSameAsDeclarant': true,
    'cargoDeclarants': [
      {
        'fullName': 'Cargo agent 1',
        'portAuthorityId': 'CHA1_R',
        'shortName': 'CHA1',
        'emailAddress': 'd.reijnders@portbase.com',
        'address': 'Cargo Operatorlaan 1',
        'city': 'Cargo Operatorstad',
        'countryUnCode': 'NL',
        'countryName': 'Netherlands',
        'zipCode': '1111SO',
        'phoneNumber': '0102522288',
        'faxNumber': null,
        'contact': 'TC',
        'customsEORINumber': 'NL123456789',
        'cargoImportEnabled': true
      },
      {
        'fullName': 'Ship Operator 1',
        'portAuthorityId': 'SO1_R',
        'shortName': 'SO1',
        'emailAddress': 'd.reijnders@portbase.com',
        'address': 'Ship Operatorlaan 1',
        'city': 'Ship Operatorstad',
        'countryUnCode': 'NL',
        'countryName': 'Netherlands',
        'zipCode': '1111SO',
        'phoneNumber': '0102522288',
        'faxNumber': null,
        'contact': 'TC',
        'customsEORINumber': 'NL123456789',
        'cargoImportEnabled': true
      }
    ],
    'allowedViewers': [
      {
        'fullName': 'Ship Operator 1',
        'portAuthorityId': 'SO1_R',
        'shortName': 'SO1',
        'emailAddress': 'pietjepuk@yoyo.nl',
        'address': 'Ship Operatorlaan 1',
        'city': 'Ship Operatorstad',
        'countryUnCode': 'NL',
        'countryName': 'Netherlands',
        'zipCode': '1111SO',
        'phoneNumber': '0102522288',
        'faxNumber': null,
        'contact': 'test centrum',
        'customsEORINumber': 'NL123456789',
        'cargoImportEnabled': true
      },
      {
        'fullName': 'Ship Operator 1',
        'portAuthorityId': 'SO1_R',
        'shortName': 'SO1',
        'emailAddress': 'pietjepuk@yoyo.nl',
        'address': 'Ship Operatorlaan 1',
        'city': 'Ship Operatorstad',
        'countryUnCode': 'NL',
        'countryName': 'Netherlands',
        'zipCode': '1111SO',
        'phoneNumber': '0102522288',
        'faxNumber': null,
        'contact': 'test centrum',
        'customsEORINumber': 'NL123456789',
        'cargoImportEnabled': true
      }
    ],
    'shipOperators': [
      {
        'fullName': 'Back Office 1',
        'portAuthorityId': 'BO1_R',
        'shortName': 'BO1',
        'emailAddress': 'pietjepuk@yoyo.nl',
        'address': 'Ship Operatorlaan 1',
        'city': 'Ship Operatorstad',
        'countryUnCode': 'NL',
        'countryName': 'Netherlands',
        'zipCode': '1111SO',
        'phoneNumber': '0102522288',
        'faxNumber': null,
        'contact': 'test centrum',
        'customsEORINumber': 'NL123456789',
        'cargoImportEnabled': true
      },
      {
        'fullName': 'Ship Operator 1',
        'portAuthorityId': 'SO1_R',
        'shortName': 'SO1',
        'emailAddress': 'pietjepuk@yoyo.nl',
        'address': 'Ship Operatorlaan 1',
        'city': 'Ship Operatorstad',
        'countryUnCode': 'NL',
        'countryName': 'Netherlands',
        'zipCode': '1111SO',
        'phoneNumber': '0102522288',
        'faxNumber': null,
        'contact': 'test centrum',
        'customsEORINumber': 'NL123456789',
        'cargoImportEnabled': true
      }
    ],
    'additionalViewers': [],
    'nextVisitDeclaration': [],
    'securityDeclaration': {
      'securityReportRequired': 'YES',
      'companySecurityOfficer': {
        'name': 'Jansen',
        'email': 'Jansen@hotmail.com',
        'phoneNumber': '123456789'
      },
      'currentSecurityLevel': 'SL1',
      'approvedSspDocumentOnBoard': false,
      'isscSecurityDocument': {
        'availability': 'YES',
        'expiryDate': '2017-01-01',
        'reasonNoValidIsscOnBoard': 'ik ben een gevaarlijke piraat',
        'issuer': {
          'code': '1',
          'name': 'AMERICAN BUREAU'
        }
      },
      'shipToShipActivities': [
        {
          'location': 'Ergens 1',
          'type': 'CARGO_TANK_CLEANING',
          'startDate': '2018-11-07',
          'endDate': '2018-11-08',
          'compliantWithSsp': true,
          'securityMeasures': 'Measures 1'
        },
        {
          'location': 'Ergens 2',
          'type': 'CHANGING_CREW',
          'startDate': '2018-11-06',
          'endDate': '2018-11-07',
          'compliantWithSsp': false,
          'securityMeasures': 'Measures 2'
        },
        {
          'location': 'Ergens 3',
          'type': 'DE_GASSING',
          'startDate': '2018-11-05',
          'endDate': '2018-11-06',
          'compliantWithSsp': true,
          'securityMeasures': 'Measures 3'
        },
        {
          'location': 'Ergens 4',
          'type': 'CARGO_TANK_CLEANING',
          'startDate': '2018-11-04',
          'endDate': '2018-11-05',
          'compliantWithSsp': false,
          'securityMeasures': 'Measures 4'
        },
        {
          'location': 'Ergens 5',
          'type': 'CHANGING_CREW',
          'startDate': '2018-11-03',
          'endDate': '2018-11-04',
          'compliantWithSsp': true,
          'securityMeasures': 'Measures 5'
        },
        {
          'location': 'Ergens 6',
          'type': 'DE_GASSING',
          'startDate': '2018-11-02',
          'endDate': '2018-11-03',
          'compliantWithSsp': false,
          'securityMeasures': 'Measures 6'
        },
        {
          'location': 'Ergens 7',
          'type': 'CARGO_TANK_CLEANING',
          'startDate': '2018-11-01',
          'endDate': '2018-11-02',
          'compliantWithSsp': true,
          'securityMeasures': 'Measures 7'
        },
        {
          'location': 'Ergens 8',
          'type': 'CHANGING_CREW',
          'startDate': '2018-10-31',
          'endDate': '2018-11-01',
          'compliantWithSsp': false,
          'securityMeasures': 'Measures 8'
        },
        {
          'location': 'Ergens 9',
          'type': 'DE_GASSING',
          'startDate': '2018-10-30',
          'endDate': '2018-10-31',
          'compliantWithSsp': true,
          'securityMeasures': 'Measures 9'
        }
      ],
      'securityRelatedMatter': 'Any security related matter to report'
    },
    "wasteDeclaration": null,
    "shipStoresDeclaration": null,
    "paxDeclaration": null,
    "uploads": {},
    "cancelled": false,
    "planned": false,
    "ataRegisteredByPa": false,
    "healthDeclarationSummary": {
      "amountRecoveredAndEvacuated": 3,
      "amountDiedAndStillOnBoard": 1,
      "healthDeclarationStatus": "APPROVED_MEASURES_IMPOSED",
      "healthDeclarationRemarks": "Too many ill people"
    },
    "declarations": [

      {
        "type": "HEALTH",
        "status": "ACCEPTED",
        "rejectReasons": null,
        "timeStamp": "2018-12-20T11:12:40.997Z"

      }, {
        "type": "VISIT",
        "status": "ACCEPTED",
        "rejectReasons": null,
        "timeStamp": "2018-12-20T11:12:40.997Z"

      },
      {
        'type': 'WASTE',
        'status': 'ACCEPTED',
        'rejectReasons': null,
        'timeStamp': '2018-12-20T11:12:40.997Z'

      },
      {
        'type': 'WPCS',
        'status': 'DECLARED',
        'rejectReasons': null,
        'timeStamp': '2018-12-20T11:12:48.060Z'
      },
      {
        'type': 'SECURITY',
        'status': 'ACCEPTED',
        'rejectReasons': null,
        'timeStamp': '2018-12-20T11:13:13.063Z'
      },
      {
        'type': 'VISIT',
        'status': 'ACCEPTED',
        'rejectReasons': null,
        'timeStamp': '2018-12-24T10:31:08.960Z'
      },
      {
        'type': 'PAX',
        'status': 'ACCEPTED',
        'rejectReasons': null,
        'timeStamp': '2018-12-24T10:31:30.960Z'
      },
      {
        'type': 'STO',
        'status': 'ACCEPTED',
        'rejectReasons': null,
        'timeStamp': '2018-12-24T10:32:08.960Z'
      }
    ],
    'visitStatus': 'EXPECTED',
    'expected': true,
  },
  NLRTM_DANGEROUS_GOODS: {
    'crn': 'NLRTM_DANGEROUS_GOODS',
    'cargoImportEnabled': true,
    'orderIncomingMovement': true,
    'updated': '2020-09-10T01:36:00.896Z',
    'importDeclarations': [{
      'cargoDeclarant': {
        'fullName': 'Cargo agent 1',
        'portAuthorityId': 'CHA1_R',
        'shortName': 'CHA1',
        'emailAddress': 'd.reijnders@portbase.com',
        'address': 'Cargo Operatorlaan 1',
        'city': 'Cargo Operatorstad',
        'countryUnCode': 'NL',
        'countryName': 'Netherlands',
        'zipCode': '1111SO',
        'phoneNumber': '0102522288',
        'faxNumber': null,
        'contact': 'TC',
        'customsEORINumber': 'NL123456789',
        'cargoImportEnabled': true
      },
      'consignments': [
        {
          'consignmentNumber': '12335',
          'portOfLoading': {'name': 'Barcelona', 'locationUnCode': 'ESBCN', 'countryUnCode': 'ES', 'euPort': true},
          'portOfDischarge': {'name': 'Rotterdam', 'locationUnCode': 'NLRTM', 'countryUnCode': 'NL', 'euPort': true},
          'actualDeparture': '2018-11-08T11:00:00Z',
          'declarations': [],
          'partiesToNotify': [],
          'goodsItems': [{
            'placements': [{
              'equipmentNumber': 'ABCD1234567',
              'numberOfPackages': '1',
              'grossWeight': 1000
            }],
            'producedDocuments': [{'type': 'N703', 'id': '209021', 'description': 'Live animalsss'}],
            'marksAndNumbers': ['90210'],
            'itemNumber': 1,
            'description': 'Cows and other animals like pigs and even more stuff like donkeys',
            'classification': {'code': '0100', 'description': 'Live animals'},
            'minimumTemperature': 10,
            'maximumTemperature': 25,
            'grossWeight': 1000,
            'numberOfOuterPackages': '1',
            'outerPackageType': {'code': 'BK', 'name': 'Basket '}
          }],
          'partShipment': false,
          'movementType': 'DOOR_TO_DOOR',
          'customsProcess': 'SEA_IN_SEA_OUT',
          'movementReferenceNumbers': ['219820198'],
          'consignor': {
            'id': 'test',
            'name': 'Crazy Pete',
            'eoriNumber': 'eoriForParty',
            'zipCode': 'party321'
          },
          'consignee': {'eoriNumber': '209201', 'name': 'Pickle Rick'},
          'customsStatus': 'EU_COMMUNITY_GOODS',
          'terminal': {
            'terminalName': 'HOLLAND AMERIKAKADE',
            'terminalCode': '52090469',
            'organisationShortName': 'ECTDELTA'
          },
          'bulkAuthorisations': []
        },
        {
          'consignmentNumber': '12334',
          'portOfLoading': {'name': 'Barcelona', 'locationUnCode': 'ESBCN', 'countryUnCode': 'ES', 'euPort': true},
          'portOfDischarge': {'name': 'Rotterdam', 'locationUnCode': 'NLRTM', 'countryUnCode': 'NL', 'euPort': true},
          'actualDeparture': '2018-11-08T11:00:00Z',
          'declarations': [],
          'partiesToNotify': [],
          'goodsItems': [{
            'placements': [{
              'equipmentNumber': 'ABCD1234567',
              'numberOfPackages': '1',
              'grossWeight': 1000
            }],
            'producedDocuments': [{'type': 'N703', 'id': '209021', 'description': 'Live animalsss'}],
            'marksAndNumbers': ['90210'],
            'itemNumber': 1,
            'description': 'Cows and other animals like pigs and even more stuff like donkeys',
            'classification': {'code': '0100', 'description': 'Live animals'},
            'minimumTemperature': 10,
            'maximumTemperature': 25,
            'grossWeight': 1000,
            'numberOfOuterPackages': '1',
            'outerPackageType': {'code': 'BK', 'name': 'Basket '}
          }],
          'partShipment': false,
          'movementType': 'DOOR_TO_DOOR',
          'customsProcess': 'SEA_IN_SEA_OUT',
          'movementReferenceNumbers': ['219820198'],
          'consignor': {
            'id': 'test',
            'name': 'Crazy Pete',
            'address': 'partyStreet 123',
            'city': 'Party Ville',
            'countryCode': 'FR',
            'eoriNumber': 'eoriForParty',
            'zipCode': 'party321'
          },
          'consignee': {'eoriNumber': '209201', 'name': 'Pickle Rick'},
          'customsStatus': 'EU_COMMUNITY_GOODS',
          'terminal': {
            'terminalName': 'HOLLAND AMERIKAKADE',
            'terminalCode': '52090469',
            'organisationShortName': 'ECTDELTA'
          },
          'bulkAuthorisations': []
        },
        {
          'consignmentNumber': '12336',
          'portOfLoading': {'name': 'Barcelona', 'locationUnCode': 'ESBCN', 'countryUnCode': 'ES', 'euPort': true},
          'portOfDischarge': {'name': 'Rotterdam', 'locationUnCode': 'NLRTM', 'countryUnCode': 'NL', 'euPort': true},
          'actualDeparture': '2018-11-08T11:00:00Z',
          'declarations': [],
          'partiesToNotify': [],
          'goodsItems': [{
            'placements': [{
              'equipmentNumber': 'ABCD1234567',
              'numberOfPackages': '1',
              'grossWeight': 1000
            }],
            'producedDocuments': [{'type': 'N703', 'id': '209021', 'description': 'Live animalsss'}],
            'marksAndNumbers': ['90210'],
            'itemNumber': 1,
            'description': 'Cows and other animals like pigs and even more stuff like donkeys',
            'classification': {'code': '0100', 'description': 'Live animals'},
            'minimumTemperature': 10,
            'maximumTemperature': null,
            'grossWeight': 1000,
            'numberOfOuterPackages': '1',
            'outerPackageType': {'code': 'BK', 'name': 'Basket '}
          }],
          'partShipment': false,
          'movementType': 'DOOR_TO_DOOR',
          'customsProcess': 'MILITARY',
          'movementReferenceNumbers': ['219820198'],
          'consignor': {
            'id': 'test',
            'name': 'Crazy Pete',
            'address': 'partyStreet 123',
            'city': 'Party Ville',
            'countryCode': 'FR',
            'eoriNumber': 'eoriForParty',
            'zipCode': 'party321'
          },
          'consignee': {'eoriNumber': '209201', 'name': 'Pickle Rick'},
          'customsStatus': 'EU_COMMUNITY_GOODS',
          'terminal': {
            'terminalName': 'HOLLAND AMERIKAKADE',
            'terminalCode': '52090469',
            'organisationShortName': 'ECTDELTA'
          },
          'bulkAuthorisations': []
        },
        {
          'consignmentNumber': '12337',
          'portOfLoading': {'name': 'Barcelona', 'locationUnCode': 'ESBCN', 'countryUnCode': 'ES', 'euPort': true},
          'portOfDischarge': {'name': 'Rotterdam', 'locationUnCode': 'NLRTM', 'countryUnCode': 'NL', 'euPort': true},
          'actualDeparture': '2018-11-08T11:00:00Z',
          'declarations': [],
          'partiesToNotify': [],
          'goodsItems': [{
            'placements': [{
              'equipmentNumber': 'ABCD1234765',
              'numberOfPackages': '1',
              'grossWeight': 1000
            }],
            'producedDocuments': [{'type': 'N703', 'id': '209021', 'description': 'Live animalsss'}],
            'marksAndNumbers': ['90210'],
            'itemNumber': 1,
            'description': 'Cows and other animals like pigs and even more stuff like donkeys',
            'classification': {'code': '0100', 'description': 'Live animals'},
            'minimumTemperature': null,
            'maximumTemperature': null,
            'grossWeight': 913497,
            'numberOfOuterPackages': '1',
            'outerPackageType': {'code': 'BK', 'name': 'Basket '}
          }],
          'partShipment': false,
          'movementType': 'DOOR_TO_DOOR',
          'customsProcess': null,
          'movementReferenceNumbers': ['219820198'],
          'consignor': {
            'id': 'test',
            'name': 'Crazy Pete',
            'address': 'partyStreet 123',
            'city': 'Party Ville',
            'countryCode': 'FR',
            'eoriNumber': 'eoriForParty',
            'zipCode': 'party321'
          },
          'consignee': {'eoriNumber': '209201', 'name': 'Pickle Rick'},
          'customsStatus': 'EU_COMMUNITY_GOODS_IN_TRANSHIPMENT',
          'terminal': {
            'terminalName': 'HOLLAND AMERIKAKADE',
            'terminalCode': '52090469',
            'organisationShortName': 'ECTDELTA'
          },
          'bulkAuthorisations': []
        },
        {
          'consignmentNumber': '12338',
          'portOfLoading': {'name': 'Barcelona', 'locationUnCode': 'ESBCN', 'countryUnCode': 'ES', 'euPort': true},
          'portOfDischarge': {'name': 'Rotterdam', 'locationUnCode': 'NLRTM', 'countryUnCode': 'NL', 'euPort': true},
          'actualDeparture': '2018-11-08T11:00:00Z',
          'declarations': [],
          'partiesToNotify': [],
          'goodsItems': [{
            'placements': [{
              'equipmentNumber': 'ABCD1234567',
              'numberOfPackages': '1',
              'grossWeight': 1000
            }],
            'producedDocuments': [{'type': 'N703', 'id': '209021', 'description': 'Live animalsss'}],
            'marksAndNumbers': ['90210'],
            'itemNumber': 1,
            'description': 'Cows and other animals like pigs and even more stuff like donkeys',
            'classification': {'code': '0100', 'description': 'Live animals'},
            'minimumTemperature': 10,
            'maximumTemperature': 25,
            'grossWeight': 1000,
            'numberOfOuterPackages': '1',
            'outerPackageType': {'code': 'BK', 'name': 'Basket '}
          }],
          "partShipment": false,
          "movementType": "DOOR_TO_DOOR",
          "customsProcess": "EMPTY_RETURN_PACKAGING",
          "movementReferenceNumbers": ["219820198"],
          "consignor": {
            "id": "test",
            "name": "Crazy Pete",
            "address": "partyStreet 123",
            "city": "Party Ville",
            "countryCode": "FR",
            "eoriNumber": "eoriForParty",
            "zipCode": "party321"
          },
          "consignee": {"eoriNumber": "209201", "name": "Pickle Rick"},
          "customsStatus": "EU_COMMUNITY_GOODS",
          "terminal": {
            "terminalName": "HOLLAND AMERIKAKADE",
            "terminalCode": "52090469",
            "organisationShortName": "ECTDELTA"
          },
          "bulkAuthorisations": []
        },
        {
          "consignmentNumber": "WECC2163RTM1069",
          "portOfLoading": {"name": "Barcelona", "locationUnCode": "ESBCN", "countryUnCode": "ES", "euPort": true},
          "portOfDischarge": {"name": "Rotterdam", "locationUnCode": "NLRTM", "countryUnCode": "NL", "euPort": true},
          "actualDeparture": "2018-11-08T11:00:00Z",
          "declarations": [],
          "partiesToNotify": [],
          "goodsItems": [{
            "placements": [{
              "equipmentNumber": "ABCD1234567",
              "numberOfPackages": "1",
              "grossWeight": 1000
            }],
            'producedDocuments': [{'type': 'N703', 'id': '209021', 'description': 'Live animalsss'}],
            'marksAndNumbers': ['90210'],
            'itemNumber': 1,
            'description': 'Cows and other animals like pigs and even more stuff like donkeys',
            'classification': {'code': '0100', 'description': 'Live animals'},
            'minimumTemperature': 10,
            'maximumTemperature': 25,
            'grossWeight': 1000,
            'numberOfOuterPackages': '1',
            'outerPackageType': {'code': 'BK', 'name': 'Basket '}
          }],
          'partShipment': false,
          'movementType': 'DOOR_TO_DOOR',
          'customsProcess': 'SEA_IN_SEA_OUT',
          'movementReferenceNumbers': ['219820198'],
          'consignor': {
            'id': 'test',
            'name': 'Crazy Pete',
            'address': 'partyStreet 123',
            'city': 'Party Ville',
            'countryCode': 'FR',
            'eoriNumber': 'eoriForParty',
            'zipCode': 'party321'
          },
          'consignee': {'eoriNumber': '209201', 'name': 'Pickle Rick'},
          'customsStatus': 'EU_COMMUNITY_GOODS',
          'terminal': {
            'terminalName': 'HOLLAND AMERIKAKADE',
            'terminalCode': '52090469',
            'organisationShortName': 'ECTDELTA'
          },
          'bulkAuthorisations': []
        },
        {
          'consignmentNumber': '12340',
          'portOfLoading': {'name': 'Barcelona', 'locationUnCode': 'ESBCN', 'countryUnCode': 'ES', 'euPort': true},
          'portOfDischarge': {'name': 'Rotterdam', 'locationUnCode': 'NLRTM', 'countryUnCode': 'NL', 'euPort': true},
          'actualDeparture': '2018-11-08T11:00:00Z',
          'declarations': [],
          'partiesToNotify': [],
          'goodsItems': [{
            'placements': [{
              'equipmentNumber': 'ABCD1234567',
              'numberOfPackages': '1',
              'grossWeight': 1000
            }],
            'producedDocuments': [{'type': 'N703', 'id': '209021', 'description': 'Live animalsss'}],
            'marksAndNumbers': ['90210'],
            'itemNumber': 1,
            'description': 'Cows and other animals like pigs and even more stuff like donkeys',
            'classification': {'code': '0100', 'description': 'Live animals'},
            'minimumTemperature': 10,
            'maximumTemperature': 25,
            'grossWeight': 1000,
            'numberOfOuterPackages': '1',
            'outerPackageType': {'code': 'BK', 'name': 'Basket '}
          }],
          'partShipment': false,
          'movementType': 'DOOR_TO_DOOR',
          'customsProcess': 'SEA_IN_SEA_OUT',
          'movementReferenceNumbers': ['219820198'],
          'consignor': {
            'id': 'test',
            'name': 'Crazy Pete',
            'address': 'partyStreet 123',
            'city': 'Party Ville',
            'countryCode': 'FR',
            'eoriNumber': 'eoriForParty',
            'zipCode': 'party321'
          },
          'consignee': {'eoriNumber': '209201', 'name': 'Pickle Rick'},
          'customsStatus': 'EU_COMMUNITY_GOODS',
          'terminal': {
            'terminalName': 'HOLLAND AMERIKAKADE',
            'terminalCode': '52090469',
            'organisationShortName': 'ECTDELTA'
          },
          'bulkAuthorisations': []
        }],
      'containers': [
        {
          'number': 'ABCD1234567',
          'portOfLoading': {'name': 'Barcelona', 'locationUnCode': 'ESBCN', 'countryUnCode': 'ES', 'euPort': true},
          'portOfDischarge': {'name': 'Rotterdam', 'locationUnCode': 'NLRTM', 'countryUnCode': 'NL', 'euPort': true},
          'actualDeparture': '2018-11-08T11:00:00Z',
          'terminal': {
            'terminalName': 'HOLLAND AMERIKAKADE',
            'terminalCode': '52090469',
            'organisationShortName': 'ECTDELTA'
          },
          "empty": false,
          "redeliveryAddress": "japio",
          "shippersOwned": false,
          "type": "CONTAINER",
          "sizeType": {"code": "SIZE1", "name": "Big size"},
          "containerOperator": {
            "name": "Carrier 1",
            "customsId": "carrier1Id",
            "scacCode": "scac1",
            "smdgCode": "smdg1"
          }
        },
        {
          'number': 'ABCD1234765',
          'portOfLoading': {'name': 'Barcelona', 'locationUnCode': 'ESBCN', 'countryUnCode': 'ES', 'euPort': true},
          'portOfDischarge': {'name': 'Rotterdam', 'locationUnCode': 'NLRTM', 'countryUnCode': 'NL', 'euPort': true},
          'actualDeparture': '2018-11-08T11:00:00Z',
          'terminal': {
            'terminalName': 'HOLLAND AMERIKAKADE',
            'terminalCode': '52090469',
            'organisationShortName': 'ECTDELTA'
          },
          "empty": false,
          "redeliveryAddress": "pieterke",
          "shippersOwned": false,
          "type": "CONTAINER",
          "sizeType": {"code": "SIZE1", "name": "Big size"},
          "containerOperator": {
            "name": "Carrier 1",
            "customsId": "carrier1Id",
            "scacCode": "scac1",
            "smdgCode": "smdg1"
          }
        },
        {
          "number": "ABCD1234570",
          "portOfLoading": {"name": "Barcelona", "locationUnCode": "ESBCN", "countryUnCode": "ES", "euPort": true},
          "portOfDischarge": {"name": "Rotterdam", "locationUnCode": "NLRTM", "countryUnCode": "NL", "euPort": true},
          "actualDeparture": "2018-11-08T11:00:00Z",
          "terminal": {"terminalName": "PET 3 B 61", "terminalCode": "47644472"},
          "empty": true,
          "redeliveryAddress": "japio",
          "shippersOwned": false,
          "type": "CONTAINER",
          "sizeType": {"code": "SIZE1", "name": "Big size"},
          "containerOperator": {
            "name": "Carrier 1",
            "customsId": "carrier1Id",
            "scacCode": "scac1",
            "smdgCode": "smdg1"
          }
        }],
      'discharges': [
        {
          'number': 'ABCD1234567',
          'portOfLoading': {'name': 'Barcelona', 'locationUnCode': 'ESBCN', 'countryUnCode': 'ES', 'euPort': true},
          'terminal': {
            'terminalName': 'HOLLAND AMERIKAKADE',
            'terminalCode': '52090462',
            'organisationShortName': 'ECTDELTA'
          },
          'type': 'TRAILER',
          'sizeType': {'code': 'SIZ3', 'name': 'Big size3'},
          'actualTimeOfHandling': '2020-11-08T11:00:00Z',
          'cargoAgentShortName': 'CHA1',
          'containerOperator': {
            'name': 'Carrier 2',
            'customsId': 'carrier2Id',
            'scacCode': 'scac2',
            'smdgCode': 'smdg2'
          },
          'bookingReference': 'hondenvriend',
          'empty': true,
          'tareWeight': '22',
        },
        <DischargeResult>{
          'number': 'ABCD1234568',
          'portOfLoading': {'name': 'Barcelona', 'locationUnCode': 'ESBCN', 'countryUnCode': 'ES', 'euPort': true},
          'terminal': {'terminalCode': '52090469', 'terminalName': 'yomama', 'organisationShortName': 'ECTDELTA'},
          'empty': false,
          'shippersOwned': false,
          'type': 'CONTAINER',
          'sizeType': {'code': 'SIZE1', 'name': 'Big size'},
          'containerOperator': {
            'name': 'Carrier 1',
            'customsId': 'carrier1Id',
            'scacCode': 'scac1',
            'smdgCode': 'smdg1'
          },
          temperature: 10,
          actualTimeOfHandling: '2018-11-08T11:00:00Z',
          grossWeight: 100
        },
        <DischargeResult>{
          'number': 'ABCD1234569',
          'portOfLoading': {'name': 'Barcelona', 'locationUnCode': 'ESBCN', 'countryUnCode': 'ES', 'euPort': true},
          'terminal': {'terminalCode': '52090469', 'terminalName': 'yomama', 'organisationShortName': 'ECTDELTA'},
          'empty': true,
          'shippersOwned': false,
          'type': 'CONTAINER',
          'sizeType': {'code': 'SIZE1', 'name': 'Big size'},
          'containerOperator': {
            'name': 'Carrier 1',
            'customsId': 'carrier1Id',
            'scacCode': 'scac1',
            'smdgCode': 'smdg1'
          },
          temperature: null,
          actualTimeOfHandling: '2018-11-08T11:00:00Z',
          grossWeight: 50
        }
      ],
      "inspectionUpdates": <InspectionUpdate[]>[
        {
          consignmentNumber: "WECC2163RTM1069",
          containerNumber: "ABCD1234567",
          physicalInspectionLocation: "inspection_outlet",
          status: null,
          type: "physical",
          timestamp: "2020-09-10T01:37:00.896Z"
        },
        {
          consignmentNumber: "WECC2163RTM1069",
          containerNumber: "ABCD1234567",
          physicalInspectionLocation: null,
          status: null,
          type: "scan",
          timestamp: "2020-09-11T01:37:00.896Z"
        },
        {
          consignmentNumber: "WECC2163RTM1069",
          containerNumber: "ABCD1234567",
          physicalInspectionLocation: null,
          status: "released",
          type: null,
          timestamp: "2020-09-12T01:37:00.896Z"
        },
        {
          consignmentNumber: "12338",
          containerNumber: "ABCD1234567",
          physicalInspectionLocation: null,
          status: null,
          type: "scan",
          timestamp: "2020-09-11T01:37:00.896Z"
        },
        {
          consignmentNumber: "12334",
          containerNumber: null,
          physicalInspectionLocation: null,
          status: null,
          type: "scan",
          timestamp: "2020-09-11T01:37:00.896Z"
        },
        {
          consignmentNumber: "12337",
          containerNumber: "ABCD1234567",
          physicalInspectionLocation: null,
          status: null,
          type: "scan",
          timestamp: "2020-09-11T01:37:00.896Z"
        },
        {
          consignmentNumber: "12337",
          containerNumber: "ABCD1234567",
          physicalInspectionLocation: null,
          status: "deleted",
          type: null,
          timestamp: "2020-09-11T01:37:00.896Z"
        },
      ],
      'bulkDischarges': [
        {
          'consignmentNumber': '12334',
          'itemNumber': 1,
          'grossWeight': 1234
        }
      ],
      'declarations': [
        {
          friendlyId: '27BGHtgoeOkTJlI8f8Sxq4',
          id: 'some random id',
          rejectReasons: null,
          status: 'REJECTED',
          timeStamp: '2020-09-10T01:37:00.896Z',
          type: 'ENS'
        },
        {
          friendlyId: '27BGHtgoeOkTJlI8f8Sxq2',
          id: 'ESBCN',
          rejectReasons: null,
          status: 'REJECTED',
          timeStamp: '2020-09-10T01:37:00.896Z',
          type: 'SDT'
        },
        {
          friendlyId: '27BGHtgoeOkTJlI8f8Sxq2',
          id: 'ESBCN',
          rejectReasons: null,
          status: 'ACCEPTED',
          timeStamp: '2020-09-10T01:37:00.896Z',
          type: 'SDT'
        },
        {
          friendlyId: '27BGHtgoeOkTJlI8f8Sxq2',
          id: 'ESLUL',
          rejectReasons: null,
          status: 'REJECTED',
          timeStamp: '2020-09-10T01:37:00.896Z',
          type: 'SDT'
        },
        {
          friendlyId: '27BGHtgoeOkTJlI8f8Sxq6',
          id: '52090469',
          rejectReasons: null,
          status: 'DECLARED',
          timeStamp: '2020-09-10T01:37:00.896Z',
          type: 'COPRAR'
        },
        {
          friendlyId: '27BGHtgoeOkTJlI8f8Sxq7',
          id: '52090469',
          rejectReasons: null,
          status: 'DECLARED',
          timeStamp: '2020-09-10T01:37:00.897Z',
          type: 'COPRAR'
        }
      ],
      'consignmentDifferences': <ConsignmentDifference[]>[{
        'consignmentNumber': '12334',
        'timestamp': '2018-11-08T11:00:00Z',
        'portOfLoadingUnCode': 'ESBCN',
        'itemDifferences': [
          {'itemNumber': 1, 'remainingPackages': 1, 'remainingWeight': 1000}
        ]
      }],
      clearedManifestIds: [],
      incomingMessages: <IncomingMessage[]>[{
        id: '12334',
        processId: 'dummy',
        type: 'IFTMCS',
        timeStamp: '2020-09-10T01:37:00.897Z'
      }]
    }],
    "loadingDeclarations": [{
      "cargoDeclarant": {
        "fullName": "Cargo agent 1",
        "portAuthorityId": "CHA1_R",
        "shortName": "CHA1",
        "emailAddress": "d.reijnders@portbase.com",
        "address": "Cargo Operatorlaan 1",
        "city": "Cargo Operatorstad",
        "countryUnCode": "NL",
        "countryName": "Netherlands",
        "zipCode": "1111SO",
        "phoneNumber": "0102522288",
        "faxNumber": null,
        "contact": "TC",
        "customsEORINumber": "NL123456789",
        "cargoImportEnabled": true
      },
      "loadings": [{
        "number": "TRIU8135113",
        "portOfDischarge": null,
        "terminal": {
          "terminalName": "ECTDELTA",
          "terminalCode": "ECTDELTA",
          "organisationShortName": "ECTDELTA",
          "organisationName": "ECTDELTA"
        },
        "actualTimeOfHandling": "2021-03-17T23:50:00Z",
        "stowageLocation": null,
        "grossWeight": null,
        "transportStatus": "EXPORT",
        "agentVoyageNumber": "NL110A",
        "type": null,
        "sizeType": null,
        "containerOperator": {
          "customsId": null,
          "name": null,
          "scacCode": "MSCU",
          "smdgCode": null
        },
        "cargoAgentShortName": "MSCRTM",
        "tareWeight": null,
        "bookingReference": "459IHA0407839"
      }, {
        "number": "TRLU1709718",
        "portOfDischarge": null,
        "terminal": {
          "terminalName": "APMRTM",
          "terminalCode": "APMRTM",
          "organisationShortName": "APMRTM",
          "organisationName": "APMRTM"
        },
        "actualTimeOfHandling": "2021-03-17T23:51:00Z",
        "stowageLocation": null,
        "grossWeight": null,
        "transportStatus": "EXPORT",
        "agentVoyageNumber": "NL110A",
        "type": null,
        "sizeType": null,
        "containerOperator": {
          "customsId": null,
          "name": null,
          "scacCode": "MSCU",
          "smdgCode": null
        },
        "cargoAgentShortName": "MSCRTM",
        "tareWeight": null,
        "bookingReference": "459IHA0407839"
      }, {
        "number": "TTNU8461687",
        "portOfDischarge": null,
        "terminal": {
          "terminalName": "ECTDELTA",
          "terminalCode": "ECTDELTA",
          "organisationShortName": "ECTDELTA",
          "organisationName": "ECTDELTA"
        },
        "actualTimeOfHandling": "2021-03-17T23:54:00Z",
        "stowageLocation": null,
        "grossWeight": null,
        "transportStatus": "EXPORT",
        "agentVoyageNumber": "NL110A",
        "type": null,
        "sizeType": null,
        "containerOperator": {
          "customsId": null,
          "name": null,
          "scacCode": "MSCU",
          "smdgCode": null
        },
        "cargoAgentShortName": "MSCRTM",
        "tareWeight": null,
        "bookingReference": "459IHA0407839"
      }]
    }],
    "incomingTidalWindowStatus": {
      tidalShip: true,
      remarks: 'let op dat je niet vastloopt',
      tidalWindows: [
        {
          start: '2018-11-09T15:00:00Z',
          end: '2018-11-09T17:00:00Z'
        },
      ],
      tidalWindowType: 'ACTUAL'
    },
    'berthVisitInfos': {
      '1abbb8789fe94b5c8adad25550a0a6b1':
        <BerthVisitInfo>{
          'harbourMasterInfo': {
            'etd': '2018-11-09T15:00:00Z',
            tidalWindowStatus: {
              tidalShip: true,
              remarks: 'let op dat je niet vastloopt',
              tidalWindows: [
                {
                  start: '2018-11-09T15:00:00Z',
                  end: '2018-11-09T17:00:00Z'
                },
              ],
              tidalWindowType: 'ASTRO'
            }
          }
        },
      "abb42b0d775d44fca7e2d392d16ff340": <BerthVisitInfo>{
        'harbourMasterInfo': {
          "etd": "2018-11-09T23:09:00Z",
          "movementOnHold": true,
          "vesselDraught": 12.12
        }
      }
    },
    'incomingMovementHarbourMasterInfo': {
      'vesselDraught': 6.66
    },
    'visitDeclaration': {
      'portVisit': {
        'etaPort': '2018-11-09T10:00:00Z',
        'firstMovement': {
          'vesselDraft': 6.0,
          'vesselMasterName': 'Henk',
          'numberOfCrew': 20,
          'numberOfPassengers': 6,
          'cargo': 'OUT_OF_GAUGE',
          'pilotExemption': {
            'holderName': '',
            'number': '16'
          },
          'offStandardBeam': {
            'value': 4.5,
            'remarks': ''
          },
          'pilotService': {
            'required': false
          }
        },
        "portEntry":
          {
            "earliestTimeOfPortEntry": "2021-11-26T04:00:00Z",
            "etaPilotBoardingPlace": "2021-11-28T04:00:00Z",
            "requestedEtaPilotBoardingPlace": "2021-11-28T04:00:00Z",
            "baseForPlanning": "PILOT_BOARDING_PLACE",
            "intention": "REQUEST_FOR_ENTRY",
            "origin": "SEA",
            "etaSeaBuoy": "2021-11-08T06:00:00Z",
            "entryDependency": {
              "vesselName": "Lenski",
              "vesselImoCode": "2342556",
              "dependencyType": "EXCHANGE",
              "orderStatus": "ORDERED",
              "autoOrder": true
            }
          },
        "berthVisits": [
          {
            'id': '1abbb8789fe94b5c8adad25550a0a6b1',
            'berth': {
              'name': 'HOLLAND AMERIKAKADE',
              'code': 'R1243',
              'terminalCode': '52090469',
              'terminalName': 'HOLLAND AMERIKAKADE',
              'organisationShortName': 'ECTDELTA',
              'berthGroupCode': 'HOLLAND AMERIKAKADE',
              'buoy': false,
              'partyToNotify': null
            },
            'eta': '2018-11-09T11:00:00Z',
            'ata': null,
            'etd': '2018-11-10T22:00:00Z',
            'atd': null,
            'mooring': 'PORT_SIDE',
            'remarks': null,
            'visitPurposes': [
              'CARGO_TANK_CLEANING',
              'CUSTOMS_CLEARANCE',
              'DISPOSAL_OF_WASTE',
              'LAID_UP',
              'LOADING'
            ],
            'bollardFrom': null,
            'bollardTo': null,
            'tugboatAtArrival': {
              'required': true,
              'serviceProvider': {
                'name': 'Gepke',
                'portAuthorityId': 'VERSCHOOR'
              },
              'requiredQuantity': 'SIX',
              'remarks': null
            },
            'boatmenAtArrival': {
              'required': true,
              'serviceProvider': {
                'name': 'V.i.o.s.',
                'portAuthorityId': 'VIOS'
              },
              'remarks': null
            },
            'boatmenAtDeparture': {
              'required': true,
              'serviceProvider': {
                'name': 'V.i.o.s.',
                'portAuthorityId': 'VIOS'
              },
              'remarks': null
            },
            'tugboatAtDeparture': {
              'required': true,
              'serviceProvider': {
                'name': 'Gepke',
                'portAuthorityId': 'VERSCHOOR'
              },
              'requiredQuantity': 'SIX',
              'remarks': null
            },
            'nextMovement': {
              'order': true,
              'vesselDraft': 7.0,
              'vesselMasterName': 'Piet',
              'numberOfCrew': 21,
              'numberOfPassengers': 7,
              'cargo': 'OUT_OF_GAUGE',
              'pilotExemption': {
                'holderName': '',
                'number': '16'
              },
              'offStandardBeam': {
                'value': 4.5,
                'remarks': ''
              },
              'pilotService': null
            }
          },
          {
            "id": "abb42b0d775d44fca7e2d392d16ff340",
            "berth": {
              "name": "PET 3 B 61",
              "code": "R61",
              "terminalCode": "47644472",
              "terminalName": "PET 3 B 61",
              "berthGroupCode": "PET 3 B 61",
              "buoy": true,
              "partyToNotify": null
            },
            "eta": "2018-11-11T11:00:00Z",
            "ata": null,
            "etd": "2018-11-11T22:00:00Z",
            "atd": null,
            "requestedEtd": "2018-11-11T22:00:00Z",
            "mooring": "STARBOARD_SIDE",
            "remarks": null,
            "visitPurposes": [
              "CREW_MOVEMENT",
              "LOADING",
              "REFUGE"
            ],
            'bollardFrom': 16.2,
            'bollardTo': 20.2,
            'tugboatAtArrival': {
              'required': false,
              'serviceProvider': {
                'name': 'Gepke',
                'portAuthorityId': 'VERSCHOOR'
              },
              'requiredQuantity': null,
              'remarks': null
            },
            'boatmenAtArrival': {
              'required': true,
              'serviceProvider': {
                'name': 'V.i.o.s.',
                'portAuthorityId': 'VIOS'
              },
              'remarks': null
            },
            'boatmenAtDeparture': null,
            'tugboatAtDeparture': {
              'required': false,
              'serviceProvider': {
                'name': 'Gepke met oude naam',
                'portAuthorityId': 'VERSCHOOR'
              },
              'requiredQuantity': null,
              'remarks': null
            },
            'nextMovement': {
              'vesselDraft': 12.5,
              'vesselMasterName': 'Henk',
              'numberOfCrew': 20,
              'numberOfPassengers': 6,
              'cargo': 'BALLAST_CONDITION',
              'pilotExemption': {
                'holderName': 'Exemption holder 1',
                'number': '12345'
              },
              'offStandardBeam': {
                'value': 12.8,
                'remarks': 'No remarks'
              },
              'pilotService': null
            }
          }
        ],
        'pilotStation': {
          'code': 'MC',
          'name': 'Maascenter',
          'atSea': true
        },
        'entryPoint': {
          'code': 'LL',
          'name': 'Lage Licht'
        },
        'passingThroughTugboats': null,
        'defectTypes': [
          'DEN',
          'DWE',
          'TOW'
        ],
        'dangerousGoodsLoading': true,
        'dangerousGoodsDischarge': false,
        'dangerousGoodsTransit': true,
        'possibleAnchorage': true,
        'vesselInspectionRequired': true,
        'shipConfiguration': 'DOUBLE_HULL_TANKER',
        'exitPoint': {
          'code': 'OM',
          'name': 'Oude Maas',
          'atSea': false
        },
        'etdPort': '2018-11-13T11:00:00Z',
        'atdPort': null
      },
      'clientReferenceNumber': '7',
      'etaFirstEntryEu': '2018-11-07T11:00:00Z',
      'arrivalVoyage': {
        'voyageNumber': '1234567',
        'carrier': {
          'customsId': 'NL100000897',
          'name': 'Carrier 1',
          'scacCode': 'CARA',
          'smdgCode': 'CAR'
        }
      },
      'previousPorts': [
        {
          "id": "08c4f047-05a1-499e-9e7d-b5165d0d37b1",
          "arrival": "2021-09-26T10:00:00Z",
          "departure": "2021-09-27T10:00:00Z",
          "port": {
            "name": "Le Havre",
            "locationUnCode": "FRLEH",
            "countryUnCode": "FR",
            "euPort": true
          },
          "portFacilityVisits": []
        },
        {
          "id": "a124f350-8c24-462f-96c9-9d70521ed25a",
          "arrival": "2021-09-24T10:00:00Z",
          "departure": "2021-09-25T10:00:00Z",
          "port": {
            "name": "Southampton",
            "locationUnCode": "GBSOU",
            "countryUnCode": "GB",
            "euPort": false
          },
          "portFacilityVisits": [
            {
              "portFacility": {
                "code": "GBSOU-0001",
                "name": "Port of Southampton"
              },
              "arrivalDate": null,
              "departureDate": null,
              "securityLevel": "SL1",
              "additionalSecurityMeasures": null
            }
          ]
        },
        {
          "id": "af98fa10-c5ef-45ac-956e-fef7ae5b6b28",
          "arrival": null,
          "departure": null,
          "port": {
            "name": "Barcelona",
            "locationUnCode": "ESBCN",
            "countryUnCode": "ES",
            "euPort": true
          },
          "portFacilityVisits": [
            {
              "portFacility": {
                "code": "ESBCN-0042",
                "name": "TERMINAL DE CONTENEDORES DE BARCELONA, S.L (TCB)"
              },
              "arrivalDate": null,
              "departureDate": null,
              "securityLevel": "SL1",
              "additionalSecurityMeasures": null
            }
          ]
        },
        {
          "id": "28adb252-4d9d-475b-9fc4-35ac597c9b6c",
          "arrival": null,
          "departure": null,
          "port": {
            "name": "Genova",
            "locationUnCode": "ITGOA",
            "countryUnCode": "IT",
            "euPort": true
          },
          "portFacilityVisits": [
            {
              "portFacility": {
                "code": "ITGOA-0015",
                "name": "Terminal Saar - Ponte Paleocapa"
              },
              "arrivalDate": null,
              "departureDate": null,
              "securityLevel": "SL1",
              "additionalSecurityMeasures": null
            }
          ]
        },
        {
          "id": "54ba4767-1961-4e35-8b3e-7c5b33d8b857",
          "arrival": null,
          "departure": null,
          "port": {
            "name": "New York",
            "locationUnCode": "USNYC",
            "countryUnCode": "US",
            "euPort": false
          },
          "portFacilityVisits": [
            {
              "portFacility": {
                "code": "USNYC-0001",
                "name": "New York Port Area"
              },
              "arrivalDate": null,
              "departureDate": null,
              "securityLevel": "SL1",
              "additionalSecurityMeasures": null
            }
          ]
        },
        {
          "id": "23725500-0ef5-47b3-bbac-9331247f71d5",
          "arrival": null,
          "departure": null,
          "port": {
            "name": "Savannah",
            "locationUnCode": "USSAV",
            "countryUnCode": "US",
            "euPort": false
          },
          "portFacilityVisits": [
            {
              "portFacility": {
                "code": "USSAV-0001",
                "name": "Savannah Port Area"
              },
              "arrivalDate": null,
              "departureDate": null,
              "securityLevel": "SL1",
              "additionalSecurityMeasures": null
            }
          ]
        },
        {
          "id": "7a64dacc-22a8-4f8b-a7b9-8b254567ba50",
          "arrival": null,
          "departure": null,
          "port": {
            "name": "Norfolk",
            "locationUnCode": "USORF",
            "countryUnCode": "US",
            "euPort": false
          },
          "portFacilityVisits": [
            {
              "portFacility": {
                "code": "USORF-0001",
                "name": "Hampton Roads"
              },
              "arrivalDate": null,
              "departureDate": null,
              "securityLevel": "SL1",
              "additionalSecurityMeasures": null
            }
          ]
        },
        {
          "id": "a4ec5aaf-9409-43cb-806e-7baebb41ef67",
          "arrival": null,
          "departure": null,
          "port": {
            "name": "New York",
            "locationUnCode": "USNYC",
            "countryUnCode": "US",
            "euPort": false
          },
          "portFacilityVisits": [
            {
              "portFacility": {
                "code": "USNYC-0001",
                "name": "New York Port Area"
              },
              "arrivalDate": null,
              "departureDate": null,
              "securityLevel": "SL1",
              "additionalSecurityMeasures": null
            }
          ]
        },
        {
          "id": "acb875f1-c90d-4e7f-a9f4-03a4c4c069f4",
          "arrival": null,
          "departure": null,
          "port": {
            "name": "Suez Canal",
            "locationUnCode": "EGSCN",
            "countryUnCode": "EG",
            "euPort": false
          },
          "portFacilityVisits": []
        },
        {
          "id": "d74537de-903d-4033-b62a-622dce6f156c",
          "arrival": null,
          "departure": null,
          "port": {
            "name": "Colombo",
            "locationUnCode": "LKCMB",
            "countryUnCode": "LK",
            "euPort": false
          },
          "portFacilityVisits": [
            {
              "portFacility": {
                "code": "LKCMB-0001",
                "name": "Port of Colombo"
              },
              "arrivalDate": null,
              "departureDate": null,
              "securityLevel": "SL1",
              "additionalSecurityMeasures": null
            }
          ]
        },
        {
          "id": "f09f8154-27df-4910-a405-8e0e13d559a4",
          "arrival": null,
          "departure": null,
          "port": {
            "name": "Savannah",
            "locationUnCode": "USSAV",
            "countryUnCode": "US",
            "euPort": false
          },
          "portFacilityVisits": []
        },
        {
          "id": "c98e8c45-bffd-497f-865a-c0d588390b5e",
          "arrival": null,
          "departure": null,
          "port": {
            "name": "Charleston",
            "locationUnCode": "USCHS",
            "countryUnCode": "US",
            "euPort": false
          },
          "portFacilityVisits": []
        },
        {
          "id": "87becfbf-c5d3-4557-bb08-8a760544862a",
          "arrival": null,
          "departure": null,
          "port": {
            "name": "New York",
            "locationUnCode": "USNYC",
            "countryUnCode": "US",
            "euPort": false
          },
          "portFacilityVisits": []
        },
        {
          "id": "15a991c2-97fc-4d3d-a5b4-a674231f2a1c",
          "arrival": null,
          "departure": null,
          "port": {
            "name": "Savannah",
            "locationUnCode": "USSAV",
            "countryUnCode": "US",
            "euPort": false
          },
          "portFacilityVisits": []
        },
        {
          "id": "9bc2db6a-d857-497d-8c46-affb8059f4a6",
          "arrival": null,
          "departure": null,
          "port": {
            "name": "Charleston",
            "locationUnCode": "USCHS",
            "countryUnCode": "US",
            "euPort": false
          },
          "portFacilityVisits": []
        },
        {
          "id": "19e4b869-13e2-4583-b367-c088253bb54a",
          "arrival": null,
          "departure": null,
          "port": {
            "name": "New York",
            "locationUnCode": "USNYC",
            "countryUnCode": "US",
            "euPort": false
          },
          "portFacilityVisits": []
        },
        {
          "id": "c38d1c1c-bbcd-4b2c-a9dc-64555083e029",
          "arrival": null,
          "departure": null,
          "port": {
            "name": "Hamburg",
            "locationUnCode": "DEHAM",
            "countryUnCode": "DE",
            "euPort": true
          },
          "portFacilityVisits": []
        },
        {
          "id": "eadd62ab-0f60-48a5-b947-c27e9f177ac6",
          "arrival": null,
          "departure": null,
          "port": {
            "name": "Le Havre",
            "locationUnCode": "FRLEH",
            "countryUnCode": "FR",
            "euPort": true
          },
          "portFacilityVisits": []
        },
        {
          "id": "6029981d-c8a1-4c8f-bd23-93fd53a6d6a3",
          "arrival": null,
          "departure": null,
          "port": {
            "name": "London Gateway Port",
            "locationUnCode": "GBLGP",
            "countryUnCode": "GB",
            "euPort": false
          },
          "portFacilityVisits": []
        },
        {
          "id": "f20c8d14-0c04-45f3-ab19-a9bf4cda441a",
          "arrival": null,
          "departure": null,
          "port": {
            "name": "Cartagena",
            "locationUnCode": "COCTG",
            "countryUnCode": "CO",
            "euPort": false
          },
          "portFacilityVisits": []
        },
        {
          "id": "2e7667ba-f8c5-41ce-922c-11c0ed892302",
          "arrival": null,
          "departure": null,
          "port": {
            "name": "Buenaventura",
            "locationUnCode": "COBUN",
            "countryUnCode": "CO",
            "euPort": false
          },
          "portFacilityVisits": []
        },
        {
          "id": "20c5e407-1c6c-4d6b-97bc-4c31a6b99dae",
          "arrival": null,
          "departure": null,
          "port": {
            "name": "Posorja",
            "locationUnCode": "ECPSJ",
            "countryUnCode": "EC",
            "euPort": false
          },
          "portFacilityVisits": []
        },
        {
          "id": "3d8c3d03-814b-45bc-acfb-b30494942d38",
          "arrival": null,
          "departure": null,
          "port": {
            "name": "Taiping",
            "locationUnCode": "CNTAP",
            "countryUnCode": "CN",
            "euPort": false
          },
          "portFacilityVisits": []
        },
        {
          "id": "03cf8d23-c54c-40fa-81f3-6f1b633aef3e",
          "arrival": null,
          "departure": null,
          "port": {
            "name": "DA CHAN BAY",
            "locationUnCode": "CNDCB",
            "countryUnCode": "CN",
            "euPort": false
          },
          "portFacilityVisits": []
        },
        {
          "id": "4da990d0-dff3-4718-a039-9aeaa5f03cf6",
          "arrival": null,
          "departure": null,
          "port": {
            "name": "Charleston",
            "locationUnCode": "USCHS",
            "countryUnCode": "US",
            "euPort": false
          },
          "portFacilityVisits": []
        },
        {
          "id": "162c330e-c0cb-4c79-8e59-bc461f62be4d",
          "arrival": null,
          "departure": null,
          "port": {
            "name": "Singapore",
            "locationUnCode": "SGSIN",
            "countryUnCode": "SG",
            "euPort": false
          },
          "portFacilityVisits": []
        },
        {
          "id": "2ba1b829-71f1-40f8-824d-b5aef5fb982c",
          "arrival": null,
          "departure": null,
          "port": {
            "name": "Laem Chabang",
            "locationUnCode": "THLCH",
            "countryUnCode": "TH",
            "euPort": false
          },
          "portFacilityVisits": []
        },
        {
          "id": "dc58700a-a5e8-44d4-8aaf-5d5c204d9123",
          "arrival": null,
          "departure": null,
          "port": {
            "name": "Tanjung Pelepas",
            "locationUnCode": "MYTPP",
            "countryUnCode": "MY",
            "euPort": false
          },
          "portFacilityVisits": []
        },
        {
          "id": "f16a4ff7-0219-4b0d-be0b-b77836433eae",
          "arrival": null,
          "departure": null,
          "port": {
            "name": "Algeciras",
            "locationUnCode": "ESALG",
            "countryUnCode": "ES",
            "euPort": true
          },
          "portFacilityVisits": []
        },
        {
          "id": "f8593799-64b8-4150-a1ab-c1e6091b8624",
          "arrival": null,
          "departure": null,
          "port": {
            "name": "Genova",
            "locationUnCode": "ITGOA",
            "countryUnCode": "IT",
            "euPort": true
          },
          "portFacilityVisits": []
        },
        {
          "id": "9ff3cae2-0d2f-40e4-b207-48395a9118a6",
          "arrival": null,
          "departure": null,
          "port": {
            "name": "Livorno",
            "locationUnCode": "ITLIV",
            "countryUnCode": "IT",
            "euPort": true
          },
          "portFacilityVisits": []
        },
        {
          "id": "2bb9c0ba-0d5f-4f8f-980b-6a28d555ca9b",
          "arrival": null,
          "departure": null,
          "port": {
            "name": "Valencia",
            "locationUnCode": "ESVLC",
            "countryUnCode": "ES",
            "euPort": true
          },
          "portFacilityVisits": []
        },
        {
          "id": "79dfc007-3210-424c-b2ed-c248363b48f2",
          "arrival": null,
          "departure": null,
          "port": {
            "name": "Algeciras",
            "locationUnCode": "ESALG",
            "countryUnCode": "ES",
            "euPort": true
          },
          "portFacilityVisits": []
        },
        {
          "id": "d7d05e4f-ea6f-4dfd-bb2a-4ced158b327f",
          "arrival": null,
          "departure": null,
          "port": {
            "name": "New Orleans",
            "locationUnCode": "USMSY",
            "countryUnCode": "US",
            "euPort": false
          },
          "portFacilityVisits": []
        },
        {
          "id": "9df698c8-dbd1-4989-8274-8e493aaba7e5",
          "arrival": null,
          "departure": null,
          "port": {
            "name": "Houston",
            "locationUnCode": "USHOU",
            "countryUnCode": "US",
            "euPort": false
          },
          "portFacilityVisits": []
        },
        {
          "id": "37628b60-1d98-4772-9c79-2692f13c976d",
          "arrival": null,
          "departure": null,
          "port": {
            "name": "Norfolk",
            "locationUnCode": "USORF",
            "countryUnCode": "US",
            "euPort": false
          },
          "portFacilityVisits": []
        },
        {
          "id": "e8a69b49-c116-417b-bd8a-bd9017e1ac6b",
          "arrival": null,
          "departure": null,
          "port": {
            "name": "Savannah",
            "locationUnCode": "USSAV",
            "countryUnCode": "US",
            "euPort": false
          },
          "portFacilityVisits": []
        },
        {
          "id": "d7005145-5105-4196-8304-11b4996cbf76",
          "arrival": null,
          "departure": null,
          "port": {
            "name": "Bremerhaven",
            "locationUnCode": "DEBRV",
            "countryUnCode": "DE",
            "euPort": true
          },
          "portFacilityVisits": []
        },
        {
          "id": "7521d1ce-59cb-4345-a6cb-99f5981f234c",
          "arrival": null,
          "departure": null,
          "port": {
            "name": "Oakland",
            "locationUnCode": "USOAK",
            "countryUnCode": "US",
            "euPort": false
          },
          "portFacilityVisits": []
        },
        {
          "id": "cfc52118-41f5-4a92-96a5-1c09883d2257",
          "arrival": null,
          "departure": null,
          "port": {
            "name": "Philadelphia",
            "locationUnCode": "USPHL",
            "countryUnCode": "US",
            "euPort": false
          },
          "portFacilityVisits": []
        },
        {
          'id': '7239cd11943846a78869f7260f0fc219',
          'arrival': '2018-11-07T11:00:00Z',
          'departure': '2018-11-08T11:00:00Z',
          'port': {
            'name': 'Barcelona',
            'locationUnCode': 'ESBCN',
            'countryUnCode': 'ES',
            'euPort': true
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'ESBCN-0001',
                'name': 'Barcelona, Spain  AUTOTERMINAL, S.A.'
              },
              'arrivalDate': '2018-11-07',
              'departureDate': '2018-11-08',
              'securityLevel': 'SL1',
              'additionalSecurityMeasures': 'Measures 1'
            }
          ]
        },
        {
          'id': '39af59bb1dd344339f84d12c4533bd03',
          'arrival': '2018-11-06T11:00:00Z',
          'departure': '2018-11-07T11:00:00Z',
          'port': {
            'name': 'Tunisi',
            'locationUnCode': 'ITTUN',
            'countryUnCode': 'IT',
            'euPort': true
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'ITTUN-9999',
                'name': 'UNKNOWN'
              },
              'arrivalDate': '2018-11-06',
              'departureDate': '2018-11-07',
              'securityLevel': 'SL2',
              'additionalSecurityMeasures': 'Measures 2'
            }
          ]
        },
        {
          'id': '8a49e9d4d39647188bb8bd3d57dd4fa7',
          'arrival': '2018-11-05T11:00:00Z',
          'departure': '2018-11-06T11:00:00Z',
          'port': {
            'name': 'Barcelona',
            'locationUnCode': 'ESBCN',
            'countryUnCode': 'ES',
            'euPort': true
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'ESBCN-0003',
                'name': 'Barcelona, Spain  CENTRO DE CONTROL'
              },
              'arrivalDate': '2018-11-05',
              'departureDate': '2018-11-06',
              'securityLevel': 'SL3',
              'additionalSecurityMeasures': 'Measures 3'
            }
          ]
        },
        {
          'id': '2f4ae45bc63643de9fe7678f8021940d',
          'arrival': '2018-11-04T11:00:00Z',
          'departure': '2018-11-05T11:00:00Z',
          'port': {
            'name': 'Tunisi',
            'locationUnCode': 'ITTUN',
            'countryUnCode': 'IT',
            'euPort': true
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'ITTUN-9999',
                'name': 'UNKNOWN'
              },
              'arrivalDate': '2018-11-04',
              'departureDate': '2018-11-05',
              'securityLevel': 'SL1',
              'additionalSecurityMeasures': 'Measures 4'
            }
          ]
        },
        {
          'id': 'bb63bcf604c44adc8aada67c61a367c2',
          'arrival': '2018-11-03T11:00:00Z',
          'departure': '2018-11-04T11:00:00Z',
          'port': {
            'name': 'Barcelona',
            'locationUnCode': 'ESBCN',
            'countryUnCode': 'ES',
            'euPort': true
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'ESBCN-0005',
                'name': 'Barcelona, Spain  COMPAÑÍA LOGISTICA DE HIDROCARBUROS (CLH)'
              },
              'arrivalDate': '2018-11-03',
              'departureDate': '2018-11-04',
              'securityLevel': 'SL2',
              'additionalSecurityMeasures': 'Measures 5'
            }
          ]
        },
        {
          'id': '24a4865f6a494d2abc9c8d477705ad5c',
          'arrival': '2018-11-02T11:00:00Z',
          'departure': '2018-11-03T11:00:00Z',
          'port': {
            'name': 'Tunisi',
            'locationUnCode': 'ITTUN',
            'countryUnCode': 'IT',
            'euPort': true
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'ITTUN-9999',
                'name': 'UNKNOWN'
              },
              'arrivalDate': '2018-11-02',
              'departureDate': '2018-11-03',
              'securityLevel': 'SL3',
              'additionalSecurityMeasures': 'Measures 6'
            }
          ]
        },
        {
          'id': 'e4f33384c9f94439855c194b8d5ededb',
          'arrival': '2018-11-01T11:00:00Z',
          'departure': '2018-11-02T11:00:00Z',
          'port': {
            'name': 'Reykjavik',
            'locationUnCode': 'ISREY',
            'countryUnCode': 'IS',
            'euPort': true
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'ISREY-0007',
                'name': 'Reykjavik'
              },
              'arrivalDate': '2018-11-01',
              'departureDate': '2018-11-02',
              'securityLevel': 'SL1',
              'additionalSecurityMeasures': 'Measures 7'
            }
          ]
        },
        {
          'id': 'a51f713703c94ce885487c6645a1c76e',
          'arrival': '2018-10-31T11:00:00Z',
          'departure': '2018-11-01T11:00:00Z',
          'port': {
            'name': 'Tunisi',
            'locationUnCode': 'ITTUN',
            'countryUnCode': 'IT',
            'euPort': true
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'ITTUN-9999',
                'name': 'UNKNOWN'
              },
              'arrivalDate': '2018-10-31',
              'departureDate': '2018-11-01',
              'securityLevel': 'SL2',
              'additionalSecurityMeasures': 'Measures 8'
            }
          ]
        },
        {
          'id': '0ffbad66973446f4b354c1f3d29a294d',
          'arrival': '2018-10-30T11:00:00Z',
          'departure': '2018-10-31T11:00:00Z',
          'port': {
            'name': 'Barcelona',
            'locationUnCode': 'ESBCN',
            'countryUnCode': 'ES',
            'euPort': true
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'ESBCN-0009',
                'name': 'Barcelona, Spain  DECAL ESPAÑA S.A.'
              },
              'arrivalDate': '2018-10-30',
              'departureDate': '2018-10-31',
              'securityLevel': 'SL3',
              'additionalSecurityMeasures': 'Measures 9'
            }
          ]
        },
        {
          'id': '2fe3987117624e4e982eadb5fcf6b30f',
          'arrival': '2018-10-29T11:00:00Z',
          'departure': '2018-10-30T11:00:00Z',
          'port': {
            'name': 'Tunisi',
            'locationUnCode': 'ITTUN',
            'countryUnCode': 'IT',
            'euPort': true
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'ITTUN-9999',
                'name': 'UNKNOWN'
              },
              'arrivalDate': '2018-10-29',
              'departureDate': '2018-10-30',
              'securityLevel': 'SL2',
              'additionalSecurityMeasures': 'Measures 10'
            }
          ]
        },
        {
          'id': '14b77c47a3b24a6bb058d9998f89f213',
          'arrival': '2018-10-28T11:00:00Z',
          'departure': '2018-10-29T11:00:00Z',
          'port': {
            'name': 'Barcelona',
            'locationUnCode': 'ESBCN',
            'countryUnCode': 'ES',
            'euPort': true
          },
          'portFacilityVisits': []
        },
        {
          'id': '62ba903b912543b18438f132d2409263',
          'arrival': '2018-10-27T11:00:00Z',
          'departure': '2018-10-28T11:00:00Z',
          'port': {
            'name': 'Tunisi',
            'locationUnCode': 'ITTUN',
            'countryUnCode': 'IT',
            'euPort': true
          },
          'portFacilityVisits': []
        }
      ],
      'departureVoyage': {
        'voyageNumber': '1234568',
        'carrier': {
          'customsId': '12345',
          'name': null,
          'scacCode': null,
          'smdgCode': null
        }
      },
      'nextPorts': [
        {
          'id': 'f94034d2d4624e778775625184f22091',
          'arrival': '2018-11-14T11:00:00Z',
          'departure': '2018-11-15T11:00:00Z',
          'port': {
            'name': 'Antwerpen',
            'locationUnCode': 'BEANR',
            'countryUnCode': 'BE',
            'euPort': true
          },
          'customsOffice': null
        },
        {
          'id': 'd8d6ca9c2fa44747b4d8f111a75e11c7',
          'arrival': '2018-11-22T11:00:00Z',
          'departure': '2018-11-23T11:00:00Z',
          'port': {
            'name': 'Taranto',
            'locationUnCode': 'ITTAR',
            'countryUnCode': 'IT',
            'euPort': true
          },
          'customsOffice': {
            'name': 'S.O.T. AEROPORTO DELLO STRETTO',
            'unCode': 'IT087101'
          }
        },
        {
          'id': 'f4e5100bad6d4aa4a2cff0412bf963e0',
          'arrival': '2018-11-24T11:00:00Z',
          'departure': '2018-11-27T11:00:00Z',
          'port': {
            'name': 'El Iskandariya (= Alexandria)',
            'locationUnCode': 'EGALY',
            'countryUnCode': 'EG',
            'euPort': false
          },
          'customsOffice': null
        }
      ]
    },
    'dangerousGoodsDeclarations': [
      {
        dangerousGoods: {
          'goods': [
            <Chemical>{
              'id': '01P',
              'goodData': <ChemicalData>{
                'name': 'JATROPHA OIL',
                'stowageType': 'tank',
                'pollutionCategory': 'Y',
                'goodCode': '5004970300',
                'type': 'chemical',
                'hazardClass': 'P',
                'specOpRequirements': '15.19.6,16.2.6'
              },
              'viscosity': 1,
              'heatingOrder': 722,
              'meltingPoint': 100,
              'criticalTemperature': 111,
              'flashPoint': 123,
              'remarks': 'Toxic stuff',
              'type': 'chemical'
            },
            <ContainerGood>{
              'id': '04P',
              'goodData': <ContainerGoodData>{
                'type': 'containerGood',
                'id': '12345',
                'name': 'AEROSOLS',
                'unCode': '1950',
                'hazardClass': '2.3',
                'subsidiaryRisk': 'See SP63',
                'emsFireCode': 'F-D',
                'emsSpillageCode': 'S-U',
                'properties': null,
                'goodCode': '1950000000',
                'gdsCode': null,
                'segregationGroup': null,
                'stowageType': 'container',
                'packingGroup': 'GREAT_DANGER',
              },
              'type': 'containerGood',
              'flashPoint': 30,
              'radioactive': false,
            },
            <ContainerGood>{
              'id': '20f7f356-9b32-4be5-a560-f8e163760a2f',
              'goodData': <ContainerGoodData>{
                'type': 'containerGood',
                'id': '20f7f356-9b32-4be5-a560-f8e163760a2f',
                'name': 'AEROSOLS',
                'unCode': '1950',
                'hazardClass': '2.3',
                'subsidiaryRisk': 'See SP63',
                'emsFireCode': 'F-D',
                'emsSpillageCode': 'S-U',
                'properties': null,
                'goodCode': '1950000000',
                'gdsCode': null,
                'segregationGroup': null,
                'stowageType': 'container',
                'packingGroup': 'GREAT_DANGER',
              },
              'type': 'containerGood',
              'flashPoint': 30,
              'radioactive': false,
            }
          ],
          'stowageAtArrival': [
            {
              'stowageNumber': '01P',
              'goodId': '01P',
              'tankStatus': 'NOT_EMPTY',
              'type': 'tank',
              'consignmentReference': 'NL322345',
              'weight': 1000,
              'portOfDischarge': {
                'name': 'Rotterdam',
                'locationUnCode': 'NLRTM',
                'countryUnCode': 'NL',
                'euPort': true
              },
              'portOfLoading': {
                'name': 'Barcelona',
                'locationUnCode': 'ESBCN',
                'countryUnCode': 'ES',
                'euPort': true
              }
            },
            {
              'stowageNumber': '03P',
              'tankStatus': 'EMPTY',
              'type': 'tank',
              'weight': 0
            }, {
              'stowageNumber': '04P',
              'goodId': '04P',
              'tankStatus': 'NOT_EMPTY',
              'type': 'tank',
              'consignmentReference': 'aaaa',
              'weight': 2000,
              'portOfDischarge': {
                'name': 'Rotterdam',
                'locationUnCode': 'NLRTM',
                'countryUnCode': 'NL',
                'euPort': true
              },
              'portOfLoading': {
                'name': 'Barcelona',
                'locationUnCode': 'ESBCN',
                'countryUnCode': 'ES',
                'euPort': true
              }
            }, {
              'type': 'container',
              'tankStatus': 'NOT_EMPTY',
              'trailer': false,
              'fumigated': false,
              'uncleanTankContainer': true,
              'transportInLimitedQuantity': false,
              'weight': 500,
              'stowageNumber': 'CONT1234567',
              'goodId': '20f7f356-9b32-4be5-a560-f8e163760a2f',
              'portOfLoading': {
                'name': 'Barcelona',
                'locationUnCode': 'ESBCN',
                'countryUnCode': 'ES',
                'euPort': true
              },
              'portOfDischarge': {
                'name': 'Rotterdam',
                'locationUnCode': 'NLRTM',
                'countryUnCode': 'NL',
                'euPort': true
              },
              'position': '0000001'
            }, {
              'type': 'container',
              'tankStatus': 'NOT_EMPTY',
              'trailer': true,
              'fumigated': false,
              'transportInLimitedQuantity': false,
              'numberOfOuterPackages': 1,
              'weight': 500,
              'stowageNumber': '31-65-XY',
              'goodId': '20f7f356-9b32-4be5-a560-f8e163760a2f',
              'portOfLoading': {
                'name': 'Barcelona',
                'locationUnCode': 'ESBCN',
                'countryUnCode': 'ES',
                'euPort': true
              },
              'portOfDischarge': {
                'name': 'Rotterdam',
                'locationUnCode': 'NLRTM',
                'countryUnCode': 'NL',
                'euPort': true
              },
              'position': '0000002'
            }
          ],
          'handlings': [
            {
              'type': 'loading',
              'berthVisitId': '1abbb8789fe94b5c8adad25550a0a6b1',
              'stowages': [
                {
                  'stowageNumber': 'YYYY1234567',
                  'position': '124',
                  'type': 'container',
                  'goodId': '12345',
                  'weight': 42,
                  'uncleanTankContainer': false,
                  'numberOfOuterPackages': 1,
                  'transportInLimitedQuantity': true,
                  'goodData': {
                    'name': 'Container(container) - Thiocarbamate Pesticide, Liquid, Toxic',
                    'unCode': '3006',
                    'hazardClass': '6.1',
                    'packingGroup': 'GREAT_DANGER',
                  },
                  'portOfDischarge': {
                    'name': 'Rotterdam',
                    'locationUnCode': 'NLRTM',
                    'countryUnCode': 'NL',
                    'euPort': true
                  },
                  'emptied': true
                }
              ],
              'shipToShip': false
            },
            {
              'type': 'loading',
              'berthVisitId': '1abbb8789fe94b5c8adad25550a0a6b1',
              'stowages': [
                {
                  'stowageNumber': 'XXXX1234567',
                  'position': '123',
                  'type': 'container',
                  'goodId': '12345',
                  'weight': 1000,
                  'uncleanTankContainer': false,
                  'numberOfOuterPackages': 1,
                  'transportInLimitedQuantity': true,
                  'goodData': {
                    'name': 'Grey powder. Liable to ignite spontaneously in air. Forms explosive mixtures with oxidizing substances.',
                    'unCode': '2546',
                    'hazardClass': '4.2',
                    'packingGroup': 'MINOR_DANGER',
                  },
                  'portOfDischarge': {
                    'name': 'Rotterdam',
                    'locationUnCode': 'NLRTM',
                    'countryUnCode': 'NL',
                    'euPort': true
                  },
                  'emptied': true
                }
              ],
              'shipToShip': false
            },
            {
              'type': 'discharge',
              'berthVisitId': '1abbb8789fe94b5c8adad25550a0a6b1',
              'stowages': [
                {
                  'stowageNumber': 'CONT1234567',
                  'type': 'container',
                  'emptied': true
                }
              ],
              'shipToShip': false
            },
            {
              'type': 'discharge',
              'berthVisitId': '1abbb8789fe94b5c8adad25550a0a6b1',
              'stowages': [
                {
                  'stowageNumber': '04P',
                  'type': 'tank',
                  'emptied': true,
                  'availableBulk': 2000
                }
              ],
              'shipToShip': false,
            },
            {
              'type': 'discharge',
              'berthVisitId': 'c1324bb553b2451d907e63e48d74c3e1',
              'stowages': [
                {
                  'stowageNumber': 'XXXX1234567',
                  'type': 'container',
                  'emptied': true
                }
              ],
              'shipToShip': false
            },
            {
              'type': 'discharge',
              'berthVisitId': 'c1324bb553b2451d907e63e48d74c3e1',
              'stowages': [
                {
                  'stowageNumber': '01P',
                  'type': 'tank',
                  'emptied': true,
                  'availableBulk': 1000
                }],
              'shipToShip': false
            }]
        },
        cargoDeclarant: {
          'fullName': 'Ship Operator 1',
          'portAuthorityId': 'SO1_R',
          'shortName': 'SO1',
          'emailAddress': 'd.reijnders@portbase.com',
          'address': 'Ship Operatorlaan 1',
          'city': 'Ship Operatorstad',
          'countryUnCode': 'NL',
          'countryName': 'Netherlands',
          'zipCode': '1111SO',
          'phoneNumber': '0102522288',
          'faxNumber': null,
          'contact': 'TC',
          'customsEORINumber': 'NL123456789'
        },
        visitDeclarant: {
          'fullName': 'Back Office 1',
          'portAuthorityId': 'BO1_R',
          'shortName': 'BO1',
          'emailAddress': 'pietjepuk@yoyo.nl',
          'address': 'Back Officelaan 1',
          'city': 'Back Officestad',
          'countryUnCode': 'NL',
          'countryName': 'Netherlands',
          'zipCode': '1111SO',
          'phoneNumber': '0102522288',
          'faxNumber': null,
          'contact': 'test centrum',
          'customsEORINumber': 'NL123456789'
        },
        visitOwner: {
          'fullName': 'Ship Operator 1',
          'portAuthorityId': 'SO1_R',
          'shortName': 'SO1',
          'emailAddress': 'd.reijnders@portbase.com',
          'address': 'Ship Operatorlaan 1',
          'city': 'Ship Operatorstad',
          'countryUnCode': 'NL',
          'countryName': 'Netherlands',
          'zipCode': '1111SO',
          'phoneNumber': '0102522288',
          'faxNumber': null,
          'contact': 'TC',
          'customsEORINumber': 'NL123456789'
        }
      }],
    'dangerousGoodsEnabled': true,
    'copyOfVisit': null,
    'etaPortAis': '2018-11-08T10:22:00Z',
    'portOfCall': {
      'port': {
        'name': 'Rotterdam',
        'locationUnCode': 'NLRTM',
        'countryUnCode': 'NL',
        'euPort': true
      },
      'portAuthority': {
        'fullName': 'Havenbedrijf Rotterdam N.V.',
        'portAuthorityId': 'HbR',
        'shortName': 'HBRRTM',
        'emailAddress': 'HCC@portofrotterdam.com',
        'address': 'Wilhelminakade 909',
        'city': 'Rotterdam',
        'countryUnCode': 'NL',
        'countryName': 'Netherlands',
        'zipCode': '3072 AP',
        'phoneNumber': '+31-10-2521195',
        'faxNumber': '+31-10-2521000',
        'contact': 'Diana van Eijk',
        'customsEORINumber': 'NL24354561',
        'ean': null
      },
      'customsOffice': {
        'name': 'Rotterdam Haven/Kantoor Maasvlakte',
        'unCode': 'NL000396'
      },
      'ataReported': true,
      'atdReported': true,
      'atdPortReported': true,
      'paDeclarationRequired': true,
      'swDeclarationRequired': true,
      'wasteEnabled': true,
      'dangerousGoodsEnabled': true,
      'harbourDuesEnabled': false,
      'orderNauticalServicesEnabled': true,
      'seaBuoy': {
        'name': 'Maascenter',
        'code': 'MC'
      }
    },
    'vessel': {
      'imoCode': '8758457',
      'name': 'DANGEROUS DAVE',
      'radioCallSign': 'WB6043',
      'motUnCode': '1729',
      'motName': 'Passenger ship',
      'summerDeadWeight': 0,
      'maxWidth': 15.84,
      'manoeuvringSpeed': null,
      'flagCountryUnCode': 'US',
      'netTonnage': 1338,
      'grossTonnage': 1338,
      'registrationPlaceUnloCode': 'USMSY',
      'registrationPlaceName': 'New Orleans',
      'registrationDate': '1944-02-01',
      'mmsiNumber': null,
      'helispotPresent': false,
      'fullLength': 0,
      'flagCode': 'USA'
    },
    'owner': {
      'fullName': 'Ship Operator 1',
      'portAuthorityId': 'SO1_R',
      'shortName': 'SO1',
      'emailAddress': 'pietjepuk@yoyo.nl',
      'address': 'Ship Operatorlaan 1',
      'city': 'Ship Operatorstad',
      'countryUnCode': 'NL',
      'countryName': 'Netherlands',
      'zipCode': '1111SO',
      'phoneNumber': '0102522288',
      'faxNumber': null,
      'contact': 'test centrum',
      'customsEORINumber': 'NL123456789',
      'cargoImportEnabled': true
    },
    'declarant': {
      'fullName': 'Ship Operator 1',
      'portAuthorityId': 'SO1_R',
      'shortName': 'SO1',
      'emailAddress': 'pietjepuk@yoyo.nl',
      'address': 'Officelaan 1',
      'city': 'Officestad',
      'countryUnCode': 'NL',
      'countryName': 'Netherlands',
      'zipCode': '1111SO',
      'phoneNumber': '0102522288',
      'faxNumber': null,
      'contact': 'test centrum',
      'customsEORINumber': 'NL123456789',
      'cargoImportEnabled': true
    },
    'financialDeclarant': {
      'fullName': 'Ship Operator 1',
      'portAuthorityId': 'SO1_R',
      'shortName': 'SO1',
      'emailAddress': 'pietjepuk@yoyo.nl',
      'address': 'Ship Operatorlaan 1',
      'city': 'Ship Operatorstad',
      'countryUnCode': 'NL',
      'countryName': 'Netherlands',
      'zipCode': '1111SO',
      'phoneNumber': '0102522288',
      'faxNumber': null,
      'contact': 'test centrum',
      'customsEORINumber': 'NL123456789'
    },
    'nominatedFinancialDeclarant': null,
    'creditNumberHolderSameAsDeclarant': true,
    'cargoDeclarants': [
      {
        'fullName': 'Cargo agent 1',
        'portAuthorityId': 'CHA1_R',
        'shortName': 'CHA1',
        'emailAddress': 'd.reijnders@portbase.com',
        'address': 'Cargo Operatorlaan 1',
        'city': 'Cargo Operatorstad',
        'countryUnCode': 'NL',
        'countryName': 'Netherlands',
        'zipCode': '1111SO',
        'phoneNumber': '0102522288',
        'faxNumber': null,
        'contact': 'TC',
        'customsEORINumber': 'NL123456789',
        'cargoImportEnabled': true
      },
      {
        'fullName': 'Ship Operator 1',
        'portAuthorityId': 'SO1_R',
        'shortName': 'SO1',
        'emailAddress': 'd.reijnders@portbase.com',
        'address': 'Ship Operatorlaan 1',
        'city': 'Ship Operatorstad',
        'countryUnCode': 'NL',
        'countryName': 'Netherlands',
        'zipCode': '1111SO',
        'phoneNumber': '0102522288',
        'faxNumber': null,
        'contact': 'TC',
        'customsEORINumber': 'NL123456789',
        'cargoImportEnabled': true
      }
    ],
    'allowedViewers': [
      {
        'fullName': 'Ship Operator 1',
        'portAuthorityId': 'SO1_R',
        'shortName': 'SO1',
        'emailAddress': 'pietjepuk@yoyo.nl',
        'address': 'Ship Operatorlaan 1',
        'city': 'Ship Operatorstad',
        'countryUnCode': 'NL',
        'countryName': 'Netherlands',
        'zipCode': '1111SO',
        'phoneNumber': '0102522288',
        'faxNumber': null,
        'contact': 'test centrum',
        'customsEORINumber': 'NL123456789',
        'cargoImportEnabled': true
      },
      {
        'fullName': 'Ship Operator 1',
        'portAuthorityId': 'SO1_R',
        'shortName': 'SO1',
        'emailAddress': 'pietjepuk@yoyo.nl',
        'address': 'Ship Operatorlaan 1',
        'city': 'Ship Operatorstad',
        'countryUnCode': 'NL',
        'countryName': 'Netherlands',
        'zipCode': '1111SO',
        'phoneNumber': '0102522288',
        'faxNumber': null,
        'contact': 'test centrum',
        'customsEORINumber': 'NL123456789',
        'cargoImportEnabled': true
      }
    ],
    'shipOperators': [
      {
        'fullName': 'Back Office 1',
        'portAuthorityId': 'BO1_R',
        'shortName': 'BO1',
        'emailAddress': 'pietjepuk@yoyo.nl',
        'address': 'Ship Operatorlaan 1',
        'city': 'Ship Operatorstad',
        'countryUnCode': 'NL',
        'countryName': 'Netherlands',
        'zipCode': '1111SO',
        'phoneNumber': '0102522288',
        'faxNumber': null,
        'contact': 'test centrum',
        'customsEORINumber': 'NL123456789',
        'cargoImportEnabled': true
      },
      {
        'fullName': 'Ship Operator 1',
        'portAuthorityId': 'SO1_R',
        'shortName': 'SO1',
        'emailAddress': 'pietjepuk@yoyo.nl',
        'address': 'Ship Operatorlaan 1',
        'city': 'Ship Operatorstad',
        'countryUnCode': 'NL',
        'countryName': 'Netherlands',
        'zipCode': '1111SO',
        'phoneNumber': '0102522288',
        'faxNumber': null,
        'contact': 'test centrum',
        'customsEORINumber': 'NL123456789',
        'cargoImportEnabled': true
      }
    ],
    'additionalViewers': [],
    'nextVisitDeclaration': [],
    'securityDeclaration': {
      'securityReportRequired': 'YES',
      'companySecurityOfficer': {
        'name': 'Jansen',
        'email': 'Jansen@hotmail.com',
        'phoneNumber': '123456789'
      },
      'currentSecurityLevel': 'SL1',
      'approvedSspDocumentOnBoard': false,
      'isscSecurityDocument': {
        'availability': 'YES',
        'expiryDate': '2017-01-01',
        'reasonNoValidIsscOnBoard': 'ik ben een gevaarlijke piraat',
        'issuer': {
          'code': '1',
          'name': 'AMERICAN BUREAU'
        }
      },
      'shipToShipActivities': [
        {
          'location': 'Ergens 1',
          'type': 'CARGO_TANK_CLEANING',
          'startDate': '2018-11-07',
          'endDate': '2018-11-08',
          'compliantWithSsp': true,
          'securityMeasures': 'Measures 1'
        },
        {
          'location': 'Ergens 2',
          'type': 'CHANGING_CREW',
          'startDate': '2018-11-06',
          'endDate': '2018-11-07',
          'compliantWithSsp': false,
          'securityMeasures': 'Measures 2'
        },
        {
          'location': 'Ergens 3',
          'type': 'DE_GASSING',
          'startDate': '2018-11-05',
          'endDate': '2018-11-06',
          'compliantWithSsp': true,
          'securityMeasures': 'Measures 3'
        },
        {
          'location': 'Ergens 4',
          'type': 'CARGO_TANK_CLEANING',
          'startDate': '2018-11-04',
          'endDate': '2018-11-05',
          'compliantWithSsp': false,
          'securityMeasures': 'Measures 4'
        },
        {
          'location': 'Ergens 5',
          'type': 'CHANGING_CREW',
          'startDate': '2018-11-03',
          'endDate': '2018-11-04',
          'compliantWithSsp': true,
          'securityMeasures': 'Measures 5'
        },
        {
          'location': 'Ergens 6',
          'type': 'DE_GASSING',
          'startDate': '2018-11-02',
          'endDate': '2018-11-03',
          'compliantWithSsp': false,
          'securityMeasures': 'Measures 6'
        },
        {
          'location': 'Ergens 7',
          'type': 'CARGO_TANK_CLEANING',
          'startDate': '2018-11-01',
          'endDate': '2018-11-02',
          'compliantWithSsp': true,
          'securityMeasures': 'Measures 7'
        },
        {
          'location': 'Ergens 8',
          'type': 'CHANGING_CREW',
          'startDate': '2018-10-31',
          'endDate': '2018-11-01',
          'compliantWithSsp': false,
          'securityMeasures': 'Measures 8'
        },
        {
          'location': 'Ergens 9',
          'type': 'DE_GASSING',
          'startDate': '2018-10-30',
          'endDate': '2018-10-31',
          'compliantWithSsp': true,
          'securityMeasures': 'Measures 9'
        }
      ],
      'securityRelatedMatter': 'Any security related matter to report'
    },
    "wasteDeclaration": null,
    "shipStoresDeclaration": null,
    "paxDeclaration": null,
    "uploads": {},
    "cancelled": false,
    "ataRegisteredByPa": false,
    "healthDeclarationSummary": {
      "amountRecoveredAndEvacuated": 3,
      "amountDiedAndStillOnBoard": 1,
      "healthDeclarationStatus": "APPROVED_MEASURES_IMPOSED",
      "healthDeclarationRemarks": "Too many ill people"
    },
    "declarations": [

      {
        "type": "HEALTH",
        "status": "ACCEPTED",
        "rejectReasons": null,
        "timeStamp": "2018-12-20T11:12:40.997Z"

      }, {
        "type": "VISIT",
        "status": "ACCEPTED",
        "rejectReasons": null,
        "timeStamp": "2018-12-20T11:12:40.997Z"

      },
      {
        'type': 'WASTE',
        'status': 'ACCEPTED',
        'rejectReasons': null,
        'timeStamp': '2018-12-20T11:12:40.997Z'

      },
      {
        'type': 'WPCS',
        'status': 'DECLARED',
        'rejectReasons': null,
        'timeStamp': '2018-12-20T11:12:48.060Z'
      },
      {
        'type': 'SECURITY',
        'status': 'ACCEPTED',
        'rejectReasons': null,
        'timeStamp': '2018-12-20T11:13:13.063Z'
      },
      {
        'type': 'VISIT',
        'status': 'ACCEPTED',
        'rejectReasons': null,
        'timeStamp': '2018-12-24T10:31:08.960Z'
      },
      {
        'type': 'PAX',
        'status': 'ACCEPTED',
        'rejectReasons': null,
        'timeStamp': '2018-12-24T10:31:30.960Z'
      },
      {
        'type': 'STO',
        'status': 'ACCEPTED',
        'rejectReasons': null,
        'timeStamp': '2018-12-24T10:32:08.960Z'
      }
    ],
    'visitStatus': 'EXPECTED',
    'expected': true,
  },
  NLRTM18000001: {
    'crn': 'NLRTM18000001',
    'copyOfVisit': null,
    'additionalViewers': [],
    'portOfCall': {
      'port': {
        'name': 'Rotterdam',
        'locationUnCode': 'NLRTM',
        'countryUnCode': 'NL',
        'euPort': true
      },
      'portAuthority': {
        'shortName': 'HBRRTM',
        'fullName': 'Havenbedrijf Rotterdam N.V.',
        'emailAddress': 'HCC@portofrotterdam.com',
        'phoneNumber': '+31-10-2521195',
        'faxNumber': '+31-10-2521000',
        'address': 'Wilhelminakade 909',
        'zipCode': '3072 AP',
        'city': 'Rotterdam',
        'countryUnCode': 'NL',
        'countryName': 'Netherlands',
        'customsEORINumber': 'NL24354561',
        'contact': 'Diana van Eijk',
        'portAuthorityId': 'HbR'
      },
      'customsOffice': {
        'name': 'Rotterdam Haven/Kantoor Maasvlakte',
        'unCode': 'NL000396'
      },
      'ataReported': true,
      'atdReported': true,
      'atdPortReported': true,
      'paDeclarationRequired': true,
      'swDeclarationRequired': true,
      'wasteEnabled': true,
      'dangerousGoodsEnabled': true,
      'harbourDuesEnabled': false,
      'orderNauticalServicesEnabled': true,
      'seaBuoy': {
        'name': 'Maascenter',
        'code': 'MC'
      }
    },
    'vessel': {
      'imoCode': '8758457',
      'name': 'DAVE BLACKBURN',
      'radioCallSign': 'WB6043',
      'motUnCode': '1729',
      'motName': 'Passenger ship',
      'summerDeadWeight': 0,
      'maxWidth': 15.84,
      'manoeuvringSpeed': null,
      'flagCountryUnCode': 'US',
      'netTonnage': 1338,
      'grossTonnage': 1338,
      'registrationPlaceUnloCode': 'USMSY',
      'registrationPlaceName': 'New Orleans',
      'registrationDate': '1944-02-01',
      'mmsiNumber': null,
      'helispotPresent': false,
      'fullLength': 127,
      'flagCode': 'USA'
    },
    'owner': {
      'fullName': 'Evergreen Shipping Agency (NL) B.V.',
      'portAuthorityId': 'SO1_R',
      'shortName': 'SO1',
      'emailAddress': 'pietjepuk@yoyo.nl',
      'address': 'Ship Operatorlaan 1',
      'city': 'Ship Operatorstad',
      'countryUnCode': 'NL',
      'countryName': 'Netherlands',
      'zipCode': '1111SO',
      'phoneNumber': '0102522288',
      'faxNumber': null,
      'contact': 'test centrum',
      'customsEORINumber': 'NL123456789'
    },
    'declarant': {
      'fullName': 'S5 North Europe BV',
      'portAuthorityId': 'BO1_R',
      'shortName': 'BO1',
      'emailAddress': 'pietjepuk@yoyo.nl',
      'address': 'Back Officelaan 1',
      'city': 'Back Officestad',
      'countryUnCode': 'NL',
      'countryName': 'Netherlands',
      'zipCode': '1111SO',
      'phoneNumber': '0102522288',
      'faxNumber': null,
      'contact': 'test centrum',
      'customsEORINumber': 'NL123456789'
    },
    'financialDeclarant': {
      'fullName': 'Ship Operator 1',
      'portAuthorityId': 'SO1_R',
      'shortName': 'SO1',
      'emailAddress': 'pietjepuk@yoyo.nl',
      'address': 'Ship Operatorlaan 1',
      'city': 'Ship Operatorstad',
      'countryUnCode': 'NL',
      'countryName': 'Netherlands',
      'zipCode': '1111SO',
      'phoneNumber': '0102522288',
      'faxNumber': null,
      'contact': 'test centrum',
      'customsEORINumber': 'NL123456789'
    },
    'nominatedFinancialDeclarant': null,
    'orderNauticalServices': true,
    'creditNumberHolderSameAsDeclarant': true,
    'cargoDeclarants': [
      {
        'fullName': 'Ship Operator 1',
        'portAuthorityId': 'SO1_R',
        'shortName': 'SO1',
        'emailAddress': 'd.reijnders@portbase.com',
        'address': 'Ship Operatorlaan 1',
        'city': 'Ship Operatorstad',
        'countryUnCode': 'NL',
        'countryName': 'Netherlands',
        'zipCode': '1111SO',
        'phoneNumber': '0102522288',
        'faxNumber': null,
        'contact': 'TC',
        'customsEORINumber': 'NL123456789'
      }
    ],
    'allowedViewers': [
      {
        'fullName': 'Ship Operator 1',
        'portAuthorityId': 'SO1_R',
        'shortName': 'SO1',
        'emailAddress': 'pietjepuk@yoyo.nl',
        'address': 'Ship Operatorlaan 1',
        'city': 'Ship Operatorstad',
        'countryUnCode': 'NL',
        'countryName': 'Netherlands',
        'zipCode': '1111SO',
        'phoneNumber': '0102522288',
        'faxNumber': null,
        'contact': 'test centrum',
        'customsEORINumber': 'NL123456789'
      },
      {
        'fullName': 'Ship Operator 1',
        'portAuthorityId': 'SO1_R',
        'shortName': 'SO1',
        'emailAddress': 'pietjepuk@yoyo.nl',
        'address': 'Ship Operatorlaan 1',
        'city': 'Ship Operatorstad',
        'countryUnCode': 'NL',
        'countryName': 'Netherlands',
        'zipCode': '1111SO',
        'phoneNumber': '0102522288',
        'faxNumber': null,
        'contact': 'test centrum',
        'customsEORINumber': 'NL123456789'
      }
    ],
    'visitDeclaration': {
      'clientReferenceNumber': '7',
      'etaFirstEntryEu': '2018-11-08T11:00:00Z',
      'arrivalVoyage': {
        'voyageNumber': '1234567',
        'carrier': {
          'customsId': 'NL100000897',
          'name': 'Carrier 1',
          'scacCode': 'CARA',
          'smdgCode': 'CAR'
        }
      },
      'previousPorts': [
        {
          'id': '7239cd11943846a78869f7260f0fc219',
          'arrival': '2018-11-07T11:00:00Z',
          'departure': '2018-11-08T11:00:00Z',
          'port': {
            'name': 'Barcelona',
            'locationUnCode': 'ESBCN',
            'countryUnCode': 'ES',
            'euPort': true
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'ESBCN-0001',
                'name': 'Barcelona, Spain  AUTOTERMINAL, S.A.'
              },
              'arrivalDate': '2018-11-07',
              'departureDate': '2018-11-08',
              'securityLevel': 'SL1',
              'additionalSecurityMeasures': 'Measures 1'
            }
          ]
        },
        {
          'id': '39af59bb1dd344339f84d12c4533bd03',
          'arrival': '2018-11-06T11:00:00Z',
          'departure': '2018-11-07T11:00:00Z',
          'port': {
            'name': 'Tunisi',
            'locationUnCode': 'ITTUN',
            'countryUnCode': 'IT',
            'euPort': true
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'ITTUN-9999',
                'name': 'UNKNOWN'
              },
              'arrivalDate': '2018-11-06',
              'departureDate': '2018-11-07',
              'securityLevel': 'SL2',
              'additionalSecurityMeasures': 'Measures 2'
            }
          ]
        },
        {
          'id': '8a49e9d4d39647188bb8bd3d57dd4fa7',
          'arrival': '2018-11-05T11:00:00Z',
          'departure': '2018-11-06T11:00:00Z',
          'port': {
            'name': 'Barcelona',
            'locationUnCode': 'ESBCN',
            'countryUnCode': 'ES',
            'euPort': true
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'ESBCN-0003',
                'name': 'Barcelona, Spain  CENTRO DE CONTROL'
              },
              'arrivalDate': '2018-11-05',
              'departureDate': '2018-11-06',
              'securityLevel': 'SL3',
              'additionalSecurityMeasures': 'Measures 3'
            }
          ]
        },
        {
          'id': '2f4ae45bc63643de9fe7678f8021940d',
          'arrival': '2018-11-04T11:00:00Z',
          'departure': '2018-11-05T11:00:00Z',
          'port': {
            'name': 'Tunisi',
            'locationUnCode': 'ITTUN',
            'countryUnCode': 'IT',
            'euPort': true
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'ITTUN-9999',
                'name': 'UNKNOWN'
              },
              'arrivalDate': '2018-11-04',
              'departureDate': '2018-11-05',
              'securityLevel': 'SL1',
              'additionalSecurityMeasures': 'Measures 4'
            }
          ]
        },
        {
          'id': 'bb63bcf604c44adc8aada67c61a367c2',
          'arrival': '2018-11-03T11:00:00Z',
          'departure': '2018-11-04T11:00:00Z',
          'port': {
            'name': 'Barcelona',
            'locationUnCode': 'ESBCN',
            'countryUnCode': 'ES',
            'euPort': true
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'ESBCN-0005',
                'name': 'Barcelona, Spain  COMPAÑÍA LOGISTICA DE HIDROCARBUROS (CLH)'
              },
              'arrivalDate': '2018-11-03',
              'departureDate': '2018-11-04',
              'securityLevel': 'SL2',
              'additionalSecurityMeasures': 'Measures 5'
            }
          ]
        },
        {
          'id': '24a4865f6a494d2abc9c8d477705ad5c',
          'arrival': '2018-11-02T11:00:00Z',
          'departure': '2018-11-03T11:00:00Z',
          'port': {
            'name': 'Tunisi',
            'locationUnCode': 'ITTUN',
            'countryUnCode': 'IT',
            'euPort': true
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'ITTUN-9999',
                'name': 'UNKNOWN'
              },
              'arrivalDate': '2018-11-02',
              'departureDate': '2018-11-03',
              'securityLevel': 'SL3',
              'additionalSecurityMeasures': 'Measures 6'
            }
          ]
        },
        {
          'id': 'e4f33384c9f94439855c194b8d5ededb',
          'arrival': '2018-11-01T11:00:00Z',
          'departure': '2018-11-02T11:00:00Z',
          'port': {
            'name': 'Barcelona',
            'locationUnCode': 'ESBCN',
            'countryUnCode': 'ES',
            'euPort': true
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'ESBCN-0007',
                'name': 'Barcelona, Spain  CORPORACIÓN DE PRÁCTICOS DEL PUERTO DE BARCELONA, S.L.P.'
              },
              'arrivalDate': '2018-11-01',
              'departureDate': '2018-11-02',
              'securityLevel': 'SL1',
              'additionalSecurityMeasures': 'Measures 7'
            }
          ]
        }
      ],
      'portVisit': {
        'intention': 'REQUEST_FOR_ENTRY',
        'pilotStation': {
          'code': 'MC',
          'name': 'Maascenter',
          'atSea': true
        },
        'entryPoint': {
          'code': 'LL',
          'name': 'Lage Licht'
        },
        'etaPort': '2018-11-09T11:00:00Z',
        'firstMovement': {
          'vesselDraft': 6.0,
          'vesselMasterName': 'Henk',
          'numberOfCrew': 20,
          'numberOfPassengers': 6,
          'cargo': 'OUT_OF_GAUGE',
          'pilotExemption': {
            'holderName': '',
            'number': '16'
          },
          'offStandardBeam': {
            'value': 4.5,
            'remarks': ''
          },
          'pilotService': {
            'required': false
          }
        },
        'passingThroughTugboats': null,
        'defectTypes': [],
        'berthVisits': [
          {
            'id': '1abbb8789fe94b5c8adad25550a0a6b1',
            'berth': {
              'name': 'HOLLAND AMERIKAKADE',
              'code': 'R1243',
              'terminalCode': '52090469',
              'berthGroupCode': 'HOLLAND AMERIKAKADE',
              'buoy': false,
              'partyToNotify': null
            },
            'eta': '2018-11-09T11:00:00Z',
            'ata': null,
            'etd': '2018-11-09T12:00:00Z',
            'requestedEtd': '2018-11-09T12:00:00Z',
            'atd': null,
            'mooring': null,
            'remarks': null,
            'visitPurposes': [
              'CARGO_TANK_CLEANING',
              'CUSTOMS_CLEARANCE',
              'DISPOSAL_OF_WASTE',
              'LAID_UP',
              'LOADING'
            ],
            'bollardFrom': null,
            'bollardTo': null,
            'tugboatAtArrival': {
              'required': true,
              'serviceProvider': {
                'name': 'Gepke',
                'portAuthorityId': null
              },
              'requiredQuantity': 'SIX',
              'remarks': null
            },
            'boatmenAtArrival': {
              'required': true,
              'serviceProvider': {
                'name': 'V.i.o.s.',
                'portAuthorityId': 'VIOS'
              },
              'remarks': null
            },
            'boatmenAtDeparture': {
              'required': true,
              'serviceProvider': {
                'name': 'V.i.o.s.',
                'portAuthorityId': 'VIOS'
              },
              'remarks': null
            },
            'tugboatAtDeparture': {
              'required': true,
              'serviceProvider': {
                'name': 'Gepke',
                'portAuthorityId': 'VERSCHOOR'
              },
              'requiredQuantity': 'SIX',
              'remarks': null
            },
            'nextMovement': {
              'vesselDraft': 7.0,
              'vesselMasterName': 'Piet',
              'numberOfCrew': 21,
              'numberOfPassengers': 7,
              'cargo': 'OUT_OF_GAUGE',
              'pilotExemption': {
                'holderName': '',
                'number': '16'
              },
              'offStandardBeam': {
                'value': 4.5,
                'remarks': ''
              },
              'pilotService': null,
              'order': true
            }
          },
          {
            'id': 'abb42b0d775d44fca7e2d392d16ff340',
            'berth': {
              'name': 'PET 3 B 61',
              'code': 'R61',
              'terminalCode': '47644472',
              'berthGroupCode': 'PET 3 B 61',
              'buoy': true,
              'partyToNotify': null
            },
            'eta': '2018-11-11T11:00:00Z',
            'ata': null,
            'etd': '2018-11-11T22:00:00Z',
            'atd': null,
            'mooring': 'STARBOARD_SIDE',
            'remarks': null,
            'visitPurposes': [
              'CREW_MOVEMENT',
              'LOADING',
              'REFUGE'
            ],
            'bollardFrom': 16.2,
            'bollardTo': 20.2,
            'tugboatAtArrival': {
              'required': false,
              'serviceProvider': {
                'name': 'Gepke',
                'portAuthorityId': 'VERSCHOOR'
              },
              'requiredQuantity': null,
              'remarks': null
            },
            'boatmenAtArrival': {
              'required': true,
              'serviceProvider': {
                'name': 'V.i.o.s.',
                'portAuthorityId': 'VIOS'
              },
              'remarks': null
            },
            'boatmenAtDeparture': null,
            'tugboatAtDeparture': {
              'required': false,
              'serviceProvider': {
                'name': 'Smit havensleepdienst B.V.',
                'portAuthorityId': 'SMIT'
              },
              'requiredQuantity': null,
              'remarks': null
            },
            'nextMovement': {
              'vesselDraft': 12.5,
              'vesselMasterName': 'Henk',
              'numberOfCrew': 20,
              'numberOfPassengers': 6,
              'cargo': 'BALLAST_CONDITION',
              'pilotExemption': {
                'holderName': 'Exemption holder 1',
                'number': '12345'
              },
              'offStandardBeam': {
                'value': 12.8,
                'remarks': 'No remarks'
              },
              'pilotService': null,
              'order': true
            }
          }
        ],
        'dangerousGoodsLoading': false,
        'dangerousGoodsDischarge': false,
        'dangerousGoodsTransit': true,
        'possibleAnchorage': true,
        'vesselInspectionRequired': true,
        'shipConfiguration': 'DOUBLE_HULL_TANKER',
        'exitPoint': {
          'code': 'OM',
          'name': 'Oude Maas',
          'atSea': false
        },
        'etdPort': '2018-11-13T11:00:00Z',
        'atdPort': null
      },
      'departureVoyage': {
        'voyageNumber': '1234568',
        'carrier': {
          'customsId': '12345',
          'name': null,
          'scacCode': null,
          'smdgCode': null
        }
      },
      'nextPorts': [
        {
          'id': 'f94034d2d4624e778775625184f22091',
          'arrival': '2018-11-14T11:00:00Z',
          'departure': '2018-11-15T11:00:00Z',
          'port': {
            'name': 'Antwerpen',
            'locationUnCode': 'BEANR',
            'countryUnCode': 'BE',
            'euPort': true
          },
          'customsOffice': null
        },
        {
          'id': 'd8d6ca9c2fa44747b4d8f111a75e11c7',
          'arrival': '2018-11-22T11:00:00Z',
          'departure': '2018-11-23T11:00:00Z',
          'port': {
            'name': 'Taranto',
            'locationUnCode': 'ITTAR',
            'countryUnCode': 'IT',
            'euPort': true
          },
          'customsOffice': {
            'name': 'S.O.T. AEROPORTO DELLO STRETTO',
            'unCode': 'IT087101'
          }
        },
        {
          'id': 'f4e5100bad6d4aa4a2cff0412bf963e0',
          'arrival': '2018-11-24T11:00:00Z',
          'departure': '2018-11-27T11:00:00Z',
          'port': {
            'name': 'El Iskandariya (= Alexandria)',
            'locationUnCode': 'EGALY',
            'countryUnCode': 'EG',
            'euPort': false
          },
          'customsOffice': null
        }
      ]
    },
    'nextVisitDeclaration': [],
    'securityDeclaration': {
      'securityReportRequired': 'YES',
      'companySecurityOfficer': {
        'name': 'Jansen',
        'email': 'Jansen@hotmail.com',
        'phoneNumber': '123456789'
      },
      'currentSecurityLevel': 'SL1',
      'approvedSspDocumentOnBoard': false,
      'isscSecurityDocument': {
        'availability': 'YES',
        'expiryDate': '2017-01-01',
        'reasonNoValidIsscOnBoard': 'ik ben een gevaarlijke piraat',
        'issuer': {
          'code': '1',
          'name': 'AMERICAN BUREAU'
        }
      },
      'shipToShipActivities': [
        {
          'location': 'Ergens 1',
          'type': 'CARGO_TANK_CLEANING',
          'startDate': '2018-11-07',
          'endDate': '2018-11-08',
          'compliantWithSsp': true,
          'securityMeasures': 'Measures 1'
        },
        {
          'location': 'Ergens 2',
          'type': 'CHANGING_CREW',
          'startDate': '2018-11-06',
          'endDate': '2018-11-07',
          'compliantWithSsp': false,
          'securityMeasures': 'Measures 2'
        },
        {
          'location': 'Ergens 3',
          'type': 'DE_GASSING',
          'startDate': '2018-11-05',
          'endDate': '2018-11-06',
          'compliantWithSsp': true,
          'securityMeasures': 'Measures 3'
        },
        {
          'location': 'Ergens 4',
          'type': 'CARGO_TANK_CLEANING',
          'startDate': '2018-11-04',
          'endDate': '2018-11-05',
          'compliantWithSsp': false,
          'securityMeasures': 'Measures 4'
        },
        {
          'location': 'Ergens 5',
          'type': 'CHANGING_CREW',
          'startDate': '2018-11-03',
          'endDate': '2018-11-04',
          'compliantWithSsp': true,
          'securityMeasures': 'Measures 5'
        },
        {
          'location': 'Ergens 6',
          'type': 'DE_GASSING',
          'startDate': '2018-11-02',
          'endDate': '2018-11-03',
          'compliantWithSsp': false,
          'securityMeasures': 'Measures 6'
        },
        {
          'location': 'Ergens 7',
          'type': 'CARGO_TANK_CLEANING',
          'startDate': '2018-11-01',
          'endDate': '2018-11-02',
          'compliantWithSsp': true,
          'securityMeasures': 'Measures 7'
        },
        {
          'location': 'Ergens 8',
          'type': 'CHANGING_CREW',
          'startDate': '2018-10-31',
          'endDate': '2018-11-01',
          'compliantWithSsp': false,
          'securityMeasures': 'Measures 8'
        },
        {
          'location': 'Ergens 9',
          'type': 'DE_GASSING',
          'startDate': '2018-10-30',
          'endDate': '2018-10-31',
          'compliantWithSsp': true,
          'securityMeasures': 'Measures 9'
        }
      ],
      'securityRelatedMatter': 'Any security related matter to report'
    },
    'wasteDeclaration': null,
    'shipStoresDeclaration': null,
    'paxDeclarationSummaries': [{
      'numberOfCrew': '5',
      'numberOfPassengers': '2',
      'numberOfCrewOtherShip': '1',
      'numberOfStowaways': '1',
      'searchAndRescue': false
    }, {
      'numberOfCrew': '5',
      'numberOfPassengers': '2',
      'numberOfCrewOtherShip': '1',
      'numberOfStowaways': '1',
      'searchAndRescue': true
    }],
    'uploads': {
      'WASTE': 'fa7df3d7-29ce-4800-87b1-0d2a47624b5f',
      'STO': 'fa7df3d7-29ce-4900-87b1-0d2a47624b5f'
    },
    'cancelled': false,
    'ataRegisteredByPa': false,
    'declarations': [
      {
        'type': 'VISIT',
        'status': 'DECLARED',
        'rejectReasons': null,
        'timeStamp': '2018-12-20T11:12:40.997Z'

      },
      {
        'type': 'PAX',
        'status': 'ACCEPTED',
        'timeStamp': '2018-12-20T11:12:48.060Z'
      },
      {
        'type': 'PAX',
        'status': 'REJECTED',
        'timeStamp': '2018-12-20T11:12:48.060Z'
      },
      {
        'type': 'VISIT',
        'status': 'REJECTED',
        'rejectReasons': 'No, no, no....',
        'timeStamp': '2018-12-24T10:31:08.960Z'
      },
      {
        'type': 'WASTE',
        'status': 'REJECTED',
        'rejectReasons': 'because... too much pollution',
        'timeStamp': '2018-12-24T10:31:08.960Z'
      }
    ],
    'berthVisitInfos': {
      '1abbb8789fe94b5c8adad25550a0a6b1': <BerthVisitInfo>{
        'harbourMasterInfo': {
          'etd': '2018-11-09T15:00:00Z'
        }
      },
      'c1324bb553b2451d907e63e48d74c3e1': <BerthVisitInfo>{
        'harbourMasterInfo': {
          'rejectionReason': 'we have no ships to spare'
        }
      },
      'abb42b0d775d44fca7e2d392d16ff340': <BerthVisitInfo>{
        'harbourMasterInfo': {
          'remarks': 'Hey doe jij s ff normaal met je bestelling',
          'movementOnHold': true
        }
      }
    },
    'visitStatus': 'EXPECTED',
    'expected': true,
    'dangerousGoodsDeclaration': {
      'cargoAtArrival': [{
        'good': {
          'goodData': {
            'name': 'JATROPHA OIL',
            'marpolAnnex': 'II',
            'shipType': '2(k)',
            'shipTypeGas': null,
            'pollutionCategory': 'Y',
            'goodCode': '5004970300',
            'type': 'chemical',
            'hazardCategory': 'P',
            'tankType': '2G',
            'tankVents': 'Open',
            'tankEnvironmentalControl': 'No',
            'temperatureClass': '-',
            'apparatusGroup': '-',
            'flashpoint': 'Yes',
            'gauging': 'O',
            'vapourDetection': 'No',
            'fireProtection': 'ABC',
            'emergencyEquipment': 'No',
            'specOpRequirements': '15.19.6,16.2.6',
            'legislationId': '7691',
            'unCode': null,
            'tankTypeCRequired': null,
            'vapourControl': null,
            'mfagTableNumber': null
          },
          'viscosity': 1,
          'heatingOrder': 722,
          'meltingPoint': 100,
          'criticalTemperature': 111,
          'flashPoint': 123,
          'remarks': 'Toxic stuff'
        },
        'stowages': [
          {
            'position': '01P',
            'tankStatus': 'NOT_EMPTY',
            'type': 'tank',
            'consignmentReference': 'NL322345',
            'weight': 2245.204,
            'portOfDischarge': {
              'name': 'Antwerpen',
              'locationUnCode': 'BEANR',
              'countryUnCode': 'BE',
              'euPort': true
            },
            'portOfLoading': {
              'name': 'Barcelona',
              'locationUnCode': 'ESBCN',
              'countryUnCode': 'ES',
              'euPort': true
            }
          }, {
            'position': '02P',
            'tankStatus': 'NOT_EMPTY',
            'type': 'tank',
            'consignmentReference': 'NL322345',
            'weight': 2245.204,
            'portOfDischarge': {
              'name': 'Rotterdam',
              'locationUnCode': 'NLRTM',
              'countryUnCode': 'NL',
              'euPort': true
            },
            'portOfLoading': {
              'name': 'Barcelona',
              'locationUnCode': 'ESBCN',
              'countryUnCode': 'ES',
              'euPort': true
            }
          }
        ]

      }],
      'handlings': [],
      'emptyTanksAtArrival': []

    }
  },
  NLRTM19508992: {
    'crn': 'NLRTM19508992',
    'additionalViewers': [],
    'copyOfVisit': null,
    'portOfCall': {
      'port': {
        'name': 'Rotterdam',
        'locationUnCode': 'NLRTM',
        'countryUnCode': 'NL',
        'euPort': true
      },
      'portAuthority': {
        'fullName': 'Havenbedrijf Rotterdam N.V.',
        'portAuthorityId': 'HbR',
        'shortName': 'HBRRTM',
        'emailAddress': 'HCC@portofrotterdam.com',
        'address': 'Wilhelminakade 909',
        'city': 'Rotterdam',
        'countryUnCode': 'NL',
        'countryName': 'Netherlands',
        'zipCode': '3072 AP',
        'phoneNumber': '+31-10-2521195',
        'faxNumber': '+31-10-2521000',
        'contact': 'Diana van Eijk',
        'customsEORINumber': 'NL24354561',
        'ean': null
      },
      'customsOffice': {
        'name': 'Rotterdam Haven/Kantoor Maasvlakte',
        'unCode': 'NL000396'
      },
      'ataReported': true,
      'atdReported': true,
      'atdPortReported': true,
      'paDeclarationRequired': true,
      'swDeclarationRequired': true,
      'wasteEnabled': true,
      'dangerousGoodsEnabled': true,
      'harbourDuesEnabled': false,
      'orderNauticalServicesEnabled': true,
      'seaBuoy': {
        'name': 'Maascenter',
        'code': 'MC'
      }
    },
    'vessel': {
      'imoCode': '8758457',
      'name': 'DAVE BLACKBURN',
      'radioCallSign': 'WB6043',
      'motUnCode': '1729',
      'motName': 'Passenger ship',
      'summerDeadWeight': 0,
      'maxWidth': 15.84,
      'manoeuvringSpeed': null,
      'flagCountryUnCode': 'US',
      'netTonnage': 1338,
      'grossTonnage': 1338,
      'registrationPlaceUnloCode': 'USMSY',
      'registrationPlaceName': 'New Orleans',
      'registrationDate': '1944-02-01',
      'mmsiNumber': null,
      'helispotPresent': false,
      'fullLength': 110,
      'flagCode': 'USA'
    },
    'owner': {
      'fullName': 'Ship Operator 1',
      'portAuthorityId': 'SO1_R',
      'shortName': 'SO1',
      'emailAddress': 'pietjepuk@yoyo.nl',
      'address': 'Ship Operatorlaan 1',
      'city': 'Ship Operatorstad',
      'countryUnCode': 'NL',
      'countryName': 'Netherlands',
      'zipCode': '1111SO',
      'phoneNumber': '0102522288',
      'faxNumber': null,
      'contact': 'test centrum',
      'customsEORINumber': 'NL123456789'
    },
    'declarant': {
      'fullName': 'Ship Operator 1',
      'portAuthorityId': 'SO1_R',
      'shortName': 'BO1',
      'emailAddress': 'pietjepuk@yoyo.nl',
      'address': 'Ship Operatorlaan 1',
      'city': 'Ship Operatorstad',
      'countryUnCode': 'NL',
      'countryName': 'Netherlands',
      'zipCode': '1111SO',
      'phoneNumber': '0102522288',
      'faxNumber': null,
      'contact': 'test centrum',
      'customsEORINumber': 'NL123456789'
    },
    'financialDeclarant': {
      'fullName': 'Ship Operator 1',
      'portAuthorityId': 'SO1_R',
      'shortName': 'SO1',
      'emailAddress': 'pietjepuk@yoyo.nl',
      'address': 'Ship Operatorlaan 1',
      'city': 'Ship Operatorstad',
      'countryUnCode': 'NL',
      'countryName': 'Netherlands',
      'zipCode': '1111SO',
      'phoneNumber': '0102522288',
      'faxNumber': null,
      'contact': 'test centrum',
      'customsEORINumber': 'NL123456789'
    },
    'nominatedFinancialDeclarant': null,
    'orderNauticalServices': true,
    'creditNumberHolderSameAsDeclarant': true,
    'cargoDeclarants': [
      {
        'fullName': 'Ship Operator 1',
        'portAuthorityId': 'SO1_R',
        'shortName': 'SO1',
        'emailAddress': 'd.reijnders@portbase.com',
        'address': 'Ship Operatorlaan 1',
        'city': 'Ship Operatorstad',
        'countryUnCode': 'NL',
        'countryName': 'Netherlands',
        'zipCode': '1111SO',
        'phoneNumber': '0102522288',
        'faxNumber': null,
        'contact': 'TC',
        'customsEORINumber': 'NL123456789'
      }
    ],
    'allowedViewers': [
      {
        'fullName': 'Ship Operator 1',
        'portAuthorityId': 'SO1_R',
        'shortName': 'SO1',
        'emailAddress': 'pietjepuk@yoyo.nl',
        'address': 'Ship Operatorlaan 1',
        'city': 'Ship Operatorstad',
        'countryUnCode': 'NL',
        'countryName': 'Netherlands',
        'zipCode': '1111SO',
        'phoneNumber': '0102522288',
        'faxNumber': null,
        'contact': 'test centrum',
        'customsEORINumber': 'NL123456789'
      },
      {
        'fullName': 'Ship Operator 1',
        'portAuthorityId': 'SO1_R',
        'shortName': 'SO1',
        'emailAddress': 'pietjepuk@yoyo.nl',
        'address': 'Ship Operatorlaan 1',
        'city': 'Ship Operatorstad',
        'countryUnCode': 'NL',
        'countryName': 'Netherlands',
        'zipCode': '1111SO',
        'phoneNumber': '0102522288',
        'faxNumber': null,
        'contact': 'test centrum',
        'customsEORINumber': 'NL123456789'
      }
    ],
    'visitDeclaration': {
      'portVisit': {
        'pilotStation': {
          'code': 'AL',
          'name': 'ACHTERLAND'
        },
        'etaPort': '2018-11-12T11:00:00Z',
        'passingThroughTugboats': null,
        'berthVisits': [
          {
            'id': '1abbb8789fe94b5c8adad25550a0a6b1',
            'nextMovement': {}
          }
        ],
        'firstMovement': {},
        'etdPort': null,
        'atdPort': null,
      },
      'arrivalVoyage': {},
      'departureVoyage': {},
      'previousPorts': [],
      'nextPorts': [],
    },
    'nextVisitDeclaration': [],
    'securityDeclaration': null,
    'wasteDeclaration': null,
    'shipStoresDeclaration': null,
    'paxDeclarationSummaries': null,
    'uploads': {},
    'cancelled': false,
    'ataRegisteredByPa': false,
    'declarations': [
      {
        'type': 'VISIT',
        'status': 'ACCEPTED',
        'rejectReasons': null,
        'timeStamp': '2018-12-20T11:12:40.997Z'

      },
      {
        'type': 'PAX',
        'status': 'REJECTED',
        'timeStamp': '2018-12-20T11:12:48.060Z'
      },
      {
        'type': 'WASTE',
        'status': 'ACCEPTED',
        'timeStamp': '2018-12-24T10:31:08.960Z'
      },
      {
        'type': 'STO',
        'status': 'ACCEPTED',
        'timeStamp': '2018-12-24T10:31:08.960Z'
      }
    ],
    'berthVisitInfos': null,
    'visitStatus': 'EXPECTED',
    'expected': true
  },
  passingThroughVisit: {
    'crn': 'passingThroughVisit',
    'additionalViewers': [],
    'copyOfVisit': 'NLSCE19000049',
    'portOfCall': {
      'port': {
        'name': 'Scheveningen',
        'locationUnCode': 'NLSCE',
        'countryUnCode': 'NL',
        'euPort': true
      },
      'portAuthority': {
        'shortName': 'HBSSCE',
        'fullName': 'Havenbedrijf Scheveningen',
        'emailAddress': 'roger.reubzaet@denhaag.nl',
        'phoneNumber': '070-3527711',
        'faxNumber': '070-3522151',
        'address': 'Visafslagweg 1',
        'zipCode': '2583 DM',
        'city': 'Den Haag',
        'countryUnCode': 'NL',
        'countryName': 'Netherlands',
        'customsEORINumber': null,
        'contact': 'Roger Reubzaet',
        'portAuthorityId': 'NLSCEHBS'
      },
      'customsOffice': {
        'name': 'Amsterdam/Kantoor Amsterdam',
        'unCode': 'NL000854'
      },
      'ataReported': true,
      'atdReported': true,
      'atdPortReported': false,
      'paDeclarationRequired': true,
      'swDeclarationRequired': true,
      'wasteEnabled': false,
      'dangerousGoodsEnabled': false,
      'harbourDuesEnabled': false,
      'orderNauticalServicesEnabled': false
    },
    'vessel': {
      'imoCode': '9046497',
      'name': 'ZIRFAEA',
      'radioCallSign': 'PBZV',
      'motUnCode': '176X',
      'motName': 'Research and education ship',
      'summerDeadWeight': 1000,
      'maxWidth': 11.5,
      'manoeuvringSpeed': null,
      'flagCountryUnCode': 'NL',
      'netTonnage': 378,
      'grossTonnage': 1261,
      'registrationPlaceUnloCode': 'NLRYS',
      'registrationPlaceName': 'Rijswijk',
      'registrationDate': '1993-05-07',
      'mmsiNumber': '246096000',
      'helispotPresent': false,
      'fullLength': 63,
      'flagCode': 'NLD'
    },
    'owner': {
      'fullName': 'Wilhelmsen Ships Service B.V.',
      'portAuthorityId': '801',
      'shortName': 'WILHELMSEN',
      'emailAddress': 'wss.rotterdam.tankerops@wilhelmsen.com',
      'address': 'Willem Barentzstraat 50',
      'city': 'Rotterdam',
      'countryUnCode': 'NL',
      'countryName': 'Netherlands',
      'zipCode': '3165 AB',
      'phoneNumber': '010-4877980',
      'faxNumber': '010-4879680',
      'contact': 'Nick van der Kroft',
      'customsEORINumber': 'NL001318032'
    },
    'declarant': {
      'fullName': 'Wilhelmsen Ships Service B.V.',
      'portAuthorityId': '801',
      'shortName': 'BO1',
      'emailAddress': 'wss.rotterdam.tankerops@wilhelmsen.com',
      'address': 'Willem Barentzstraat 50',
      'city': 'Rotterdam',
      'countryUnCode': 'NL',
      'countryName': 'Netherlands',
      'zipCode': '3165 AB',
      'phoneNumber': '010-4877980',
      'faxNumber': '010-4879680',
      'contact': 'Nick van der Kroft',
      'customsEORINumber': 'NL001318032'
    },
    'financialDeclarant': {
      'fullName': 'Wilhelmsen Ships Service B.V.',
      'portAuthorityId': '801',
      'shortName': 'WILHELMSEN',
      'emailAddress': 'wss.rotterdam.tankerops@wilhelmsen.com',
      'address': 'Willem Barentzstraat 50',
      'city': 'Rotterdam',
      'countryUnCode': 'NL',
      'countryName': 'Netherlands',
      'zipCode': '3165 AB',
      'phoneNumber': '010-4877980',
      'faxNumber': '010-4879680',
      'contact': 'Nick van der Kroft',
      'customsEORINumber': 'NL001318032'
    },
    'nominatedFinancialDeclarant': null,
    'orderNauticalServices': false,
    'creditNumberHolderSameAsDeclarant': false,
    'cargoDeclarants': [
      {
        'fullName': 'Wilhelmsen Ships Service B.V.',
        'portAuthorityId': '801',
        'shortName': 'WILHELMSEN',
        'emailAddress': 'jeroen.coers@wilhelmsen.com',
        'address': 'Willem Barentzstraat 50',
        'city': 'Rotterdam',
        'countryUnCode': 'NL',
        'countryName': 'Netherlands',
        'zipCode': '3165 AB',
        'phoneNumber': '010-4877980',
        'faxNumber': '010-4879680',
        'contact': 'Jeroen Coers',
        'customsEORINumber': 'NL001318032'
      }
    ],
    'allowedViewers': [
      {
        'fullName': 'Wilhelmsen Ships Service B.V.',
        'portAuthorityId': '801',
        'shortName': 'WILHELMSEN',
        'emailAddress': 'wss.rotterdam.tankerops@wilhelmsen.com',
        'address': 'Willem Barentzstraat 50',
        'city': 'Rotterdam',
        'countryUnCode': 'NL',
        'countryName': 'Netherlands',
        'zipCode': '3165 AB',
        'phoneNumber': '010-4877980',
        'faxNumber': '010-4879680',
        'contact': 'Nick van der Kroft',
        'customsEORINumber': 'NL001318032'
      },
      {
        'fullName': 'Wilhelmsen Ships Service B.V.',
        'portAuthorityId': '801',
        'shortName': 'WILHELMSEN',
        'emailAddress': 'wss.rotterdam.tankerops@wilhelmsen.com',
        'address': 'Willem Barentzstraat 50',
        'city': 'Rotterdam',
        'countryUnCode': 'NL',
        'countryName': 'Netherlands',
        'zipCode': '3165 AB',
        'phoneNumber': '010-4877980',
        'faxNumber': '010-4879680',
        'contact': 'Nick van der Kroft',
        'customsEORINumber': 'NL001318032'
      }
    ],
    'visitDeclaration': {
      'clientReferenceNumber': null,
      'etaFirstEntryEu': null,
      'arrivalVoyage': {
        'voyageNumber': null,
        'carrier': null
      },
      'previousPorts': [{
        'id': '7239cd11943846a78869f7260f0fc219',
        'arrival': '2018-11-07T11:00:00Z',
        'departure': '2018-11-08T11:00:00Z',
        'port': {
          'name': 'Barcelona',
          'locationUnCode': 'ESBCN',
          'countryUnCode': 'ES',
          'euPort': true
        },
        'portFacilityVisits': []
      }],
      'portVisit': {
        'pilotStation': null,
        'entryPoint': {
          'code': 'SCHNZ',
          'name': 'Zee'
        },
        'etaPort': '2019-04-18T12:00:00Z',
        'firstMovement': null,
        'passingThroughTugboats': null,
        'defectTypes': [],
        'berthVisits': [],
        'dangerousGoodsLoading': null,
        'dangerousGoodsDischarge': null,
        'dangerousGoodsTransit': null,
        'possibleAnchorage': null,
        'vesselInspectionRequired': null,
        'shipConfiguration': null,
        'exitPoint': null,
        'etdPort': null,
        'atdPort': null,
        'ataPort': null
      },
      'departureVoyage': {
        'voyageNumber': null,
        'carrier': null
      },
      'nextPorts': [{
        'id': '7239cd11943846a78869f7260f0fc219',
        'arrival': '2018-11-07T11:00:00Z',
        'departure': '2018-11-08T11:00:00Z',
        'port': {
          'name': 'Barcelona',
          'locationUnCode': 'ESBCN',
          'countryUnCode': 'ES',
          'euPort': true
        }
      }]
    },
    'nextVisitDeclaration': [],
    'securityDeclaration': null,
    'wasteDeclaration': null,
    'shipStoresDeclaration': null,
    'paxDeclarationSummaries': [],
    'etaPortAis': null,
    'ignoreEtaPortAis': false,
    'cancelled': false,
    'ataRegisteredByPa': false,
    'visitKnownAtPa': false,
    'declarations': [],
    'uploads': {},
    'berthVisitInfos': {},
    'visitStatus': 'EXPECTED'
  },
  NLRTM19500009: {
    'crn': 'NLRTM19500009',
    'additionalViewers': [],
    'copyOfVisit': null,
    'portOfCall': {
      'port': {
        'name': 'Rotterdam',
        'locationUnCode': 'NLRTM',
        'countryUnCode': 'NL',
        'euPort': true
      },
      'portAuthority': {
        'shortName': 'HBRRTM',
        'fullName': 'Havenbedrijf Rotterdam N.V.',
        'emailAddress': 'HCC@portofrotterdam.com',
        'phoneNumber': '+31-10-2521195',
        'faxNumber': '+31-10-2521000',
        'address': 'Wilhelminakade 909',
        'zipCode': '3072 AP',
        'city': 'Rotterdam',
        'countryUnCode': 'NL',
        'countryName': 'Netherlands',
        'customsEORINumber': 'NL24354561',
        'contact': 'Diana van Eijk',
        'portAuthorityId': 'HbR'
      },
      'customsOffice': {
        'name': 'Rotterdam Haven/Kantoor Maasvlakte',
        'unCode': 'NL000396'
      },
      'ataReported': true,
      'atdReported': true,
      'atdPortReported': true,
      'paDeclarationRequired': true,
      'swDeclarationRequired': true,
      'wasteEnabled': true,
      'dangerousGoodsEnabled': true,
      'harbourDuesEnabled': false,
      'orderNauticalServicesEnabled': true,
      'seaBuoy': {
        'name': 'Maascenter',
        'code': 'MC'
      }
    },
    'vessel': {
      'imoCode': '8758457',
      'name': 'DAVE BLACKBURN',
      'radioCallSign': 'WB6043',
      'motUnCode': '1729',
      'motName': 'Passenger ship',
      'summerDeadWeight': 0,
      'maxWidth': 15.84,
      'manoeuvringSpeed': null,
      'flagCountryUnCode': 'US',
      'netTonnage': 1338,
      'grossTonnage': 1338,
      'registrationPlaceUnloCode': 'USMSY',
      'registrationPlaceName': 'New Orleans',
      'registrationDate': '1944-02-01',
      'mmsiNumber': null,
      'helispotPresent': false,
      'fullLength': 110,
      'flagCode': 'USA'
    },
    'owner': {
      'fullName': 'Ship Operator 1',
      'portAuthorityId': 'SO1_R',
      'shortName': 'SO1',
      'emailAddress': 'pietjepuk@yoyo.nl',
      'address': 'Ship Operatorlaan 1',
      'city': 'Ship Operatorstad',
      'countryUnCode': 'NL',
      'countryName': 'Netherlands',
      'zipCode': '1111SO',
      'phoneNumber': '0102522288',
      'faxNumber': null,
      'contact': 'test centrum',
      'customsEORINumber': 'NL123456789'
    },
    'declarant': {
      'fullName': 'Back Office 1',
      'portAuthorityId': 'BO1_R',
      'shortName': 'BO1',
      'emailAddress': 'pietjepuk@yoyo.nl',
      'address': 'Back Officelaan 1',
      'city': 'Back Officestad',
      'countryUnCode': 'NL',
      'countryName': 'Netherlands',
      'zipCode': '1111SO',
      'phoneNumber': '0102522288',
      'faxNumber': null,
      'contact': 'test centrum',
      'customsEORINumber': 'NL123456789'
    },
    'financialDeclarant': {
      'fullName': 'Ship Operator 1',
      'portAuthorityId': 'SO1_R',
      'shortName': 'SO1',
      'emailAddress': 'pietjepuk@yoyo.nl',
      'address': 'Ship Operatorlaan 1',
      'city': 'Ship Operatorstad',
      'countryUnCode': 'NL',
      'countryName': 'Netherlands',
      'zipCode': '1111SO',
      'phoneNumber': '0102522288',
      'faxNumber': null,
      'contact': 'test centrum',
      'customsEORINumber': 'NL123456789'
    },
    'nominatedFinancialDeclarant': null,
    'orderNauticalServices': true,
    'creditNumberHolderSameAsDeclarant': true,
    'cargoDeclarants': [
      {
        'fullName': 'Ship Operator 1',
        'portAuthorityId': 'SO1_R',
        'shortName': 'SO1',
        'emailAddress': 'd.reijnders@portbase.com',
        'address': 'Ship Operatorlaan 1',
        'city': 'Ship Operatorstad',
        'countryUnCode': 'NL',
        'countryName': 'Netherlands',
        'zipCode': '1111SO',
        'phoneNumber': '0102522288',
        'faxNumber': null,
        'contact': 'TC',
        'customsEORINumber': 'NL123456789'
      }
    ],
    'allowedViewers': [
      {
        'fullName': 'Ship Operator 1',
        'portAuthorityId': 'SO1_R',
        'shortName': 'SO1',
        'emailAddress': 'pietjepuk@yoyo.nl',
        'address': 'Ship Operatorlaan 1',
        'city': 'Ship Operatorstad',
        'countryUnCode': 'NL',
        'countryName': 'Netherlands',
        'zipCode': '1111SO',
        'phoneNumber': '0102522288',
        'faxNumber': null,
        'contact': 'test centrum',
        'customsEORINumber': 'NL123456789'
      },
      {
        'fullName': 'Ship Operator 1',
        'portAuthorityId': 'SO1_R',
        'shortName': 'SO1',
        'emailAddress': 'pietjepuk@yoyo.nl',
        'address': 'Ship Operatorlaan 1',
        'city': 'Ship Operatorstad',
        'countryUnCode': 'NL',
        'countryName': 'Netherlands',
        'zipCode': '1111SO',
        'phoneNumber': '0102522288',
        'faxNumber': null,
        'contact': 'test centrum',
        'customsEORINumber': 'NL123456789'
      }
    ],
    'visitDeclaration': {
      'clientReferenceNumber': '7',
      'etaFirstEntryEu': '2018-11-08T11:00:00Z',
      'arrivalVoyage': {
        'voyageNumber': '1234567',
        'carrier': {
          'customsId': 'NL100000897',
          'name': 'Carrier 1',
          'scacCode': 'CARA',
          'smdgCode': 'CAR'
        }
      },
      'previousPorts': [
        {
          'id': '7239cd11943846a78869f7260f0fc219',
          'arrival': '2018-11-07T11:00:00Z',
          'departure': '2018-11-08T11:00:00Z',
          'port': {
            'name': 'Barcelona',
            'locationUnCode': 'ESBCN',
            'countryUnCode': 'ES',
            'euPort': true
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'ESBCN-0001',
                'name': 'Barcelona, Spain  AUTOTERMINAL, S.A.'
              },
              'arrivalDate': '2018-11-07',
              'departureDate': '2018-11-08',
              'securityLevel': 'SL1',
              'additionalSecurityMeasures': 'Measures 1'
            }
          ]
        },
        {
          'id': '39af59bb1dd344339f84d12c4533bd03',
          'arrival': '2018-11-06T11:00:00Z',
          'departure': '2018-11-07T11:00:00Z',
          'port': {
            'name': 'Tunisi',
            'locationUnCode': 'ITTUN',
            'countryUnCode': 'IT',
            'euPort': true
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'ITTUN-9999',
                'name': 'UNKNOWN'
              },
              'arrivalDate': '2018-11-06',
              'departureDate': '2018-11-07',
              'securityLevel': 'SL2',
              'additionalSecurityMeasures': 'Measures 2'
            }
          ]
        },
        {
          'id': '8a49e9d4d39647188bb8bd3d57dd4fa7',
          'arrival': '2018-11-05T11:00:00Z',
          'departure': '2018-11-06T11:00:00Z',
          'port': {
            'name': 'Barcelona',
            'locationUnCode': 'ESBCN',
            'countryUnCode': 'ES',
            'euPort': true
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'ESBCN-0003',
                'name': 'Barcelona, Spain  CENTRO DE CONTROL'
              },
              'arrivalDate': '2018-11-05',
              'departureDate': '2018-11-06',
              'securityLevel': 'SL3',
              'additionalSecurityMeasures': 'Measures 3'
            }
          ]
        },
        {
          'id': '2f4ae45bc63643de9fe7678f8021940d',
          'arrival': '2018-11-04T11:00:00Z',
          'departure': '2018-11-05T11:00:00Z',
          'port': {
            'name': 'Tunisi',
            'locationUnCode': 'ITTUN',
            'countryUnCode': 'IT',
            'euPort': true
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'ITTUN-9999',
                'name': 'UNKNOWN'
              },
              'arrivalDate': '2018-11-04',
              'departureDate': '2018-11-05',
              'securityLevel': 'SL1',
              'additionalSecurityMeasures': 'Measures 4'
            }
          ]
        },
        {
          'id': 'bb63bcf604c44adc8aada67c61a367c2',
          'arrival': '2018-11-03T11:00:00Z',
          'departure': '2018-11-04T11:00:00Z',
          'port': {
            'name': 'Barcelona',
            'locationUnCode': 'ESBCN',
            'countryUnCode': 'ES',
            'euPort': true
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'ESBCN-0005',
                'name': 'Barcelona, Spain  COMPAÑÍA LOGISTICA DE HIDROCARBUROS (CLH)'
              },
              'arrivalDate': '2018-11-03',
              'departureDate': '2018-11-04',
              'securityLevel': 'SL2',
              'additionalSecurityMeasures': 'Measures 5'
            }
          ]
        },
        {
          'id': '24a4865f6a494d2abc9c8d477705ad5c',
          'arrival': '2018-11-02T11:00:00Z',
          'departure': '2018-11-03T11:00:00Z',
          'port': {
            'name': 'Tunisi',
            'locationUnCode': 'ITTUN',
            'countryUnCode': 'IT',
            'euPort': true
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'ITTUN-9999',
                'name': 'UNKNOWN'
              },
              'arrivalDate': '2018-11-02',
              'departureDate': '2018-11-03',
              'securityLevel': 'SL3',
              'additionalSecurityMeasures': 'Measures 6'
            }
          ]
        },
        {
          'id': 'e4f33384c9f94439855c194b8d5ededb',
          'arrival': '2018-11-01T11:00:00Z',
          'departure': '2018-11-02T11:00:00Z',
          'port': {
            'name': 'Barcelona',
            'locationUnCode': 'ESBCN',
            'countryUnCode': 'ES',
            'euPort': true
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'ESBCN-0007',
                'name': 'Barcelona, Spain  CORPORACIÓN DE PRÁCTICOS DEL PUERTO DE BARCELONA, S.L.P.'
              },
              'arrivalDate': '2018-11-01',
              'departureDate': '2018-11-02',
              'securityLevel': 'SL1',
              'additionalSecurityMeasures': 'Measures 7'
            }
          ]
        },
        {
          'id': 'a51f713703c94ce885487c6645a1c76e',
          'arrival': '2018-10-31T11:00:00Z',
          'departure': '2018-11-01T11:00:00Z',
          'port': {
            'name': 'Tunisi',
            'locationUnCode': 'ITTUN',
            'countryUnCode': 'IT',
            'euPort': true
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'ITTUN-9999',
                'name': 'UNKNOWN'
              },
              'arrivalDate': '2018-10-31',
              'departureDate': '2018-11-01',
              'securityLevel': 'SL2',
              'additionalSecurityMeasures': 'Measures 8'
            }
          ]
        },
        {
          'id': '0ffbad66973446f4b354c1f3d29a294d',
          'arrival': '2018-10-30T11:00:00Z',
          'departure': '2018-10-31T11:00:00Z',
          'port': {
            'name': 'Barcelona',
            'locationUnCode': 'ESBCN',
            'countryUnCode': 'ES',
            'euPort': true
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'ESBCN-0009',
                'name': 'Barcelona, Spain  DECAL ESPAÑA S.A.'
              },
              'arrivalDate': '2018-10-30',
              'departureDate': '2018-10-31',
              'securityLevel': 'SL3',
              'additionalSecurityMeasures': 'Measures 9'
            }
          ]
        },
        {
          'id': '2fe3987117624e4e982eadb5fcf6b30f',
          'arrival': '2018-10-29T11:00:00Z',
          'departure': '2018-10-30T11:00:00Z',
          'port': {
            'name': 'Tunisi',
            'locationUnCode': 'ITTUN',
            'countryUnCode': 'IT',
            'euPort': true
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'ITTUN-9999',
                'name': 'UNKNOWN'
              },
              'arrivalDate': '2018-10-29',
              'departureDate': '2018-10-30',
              'securityLevel': 'SL2',
              'additionalSecurityMeasures': 'Measures 10'
            }
          ]
        },
        {
          'id': '14b77c47a3b24a6bb058d9998f89f213',
          'arrival': '2018-10-28T11:00:00Z',
          'departure': '2018-10-29T11:00:00Z',
          'port': {
            'name': 'Barcelona',
            'locationUnCode': 'ESBCN',
            'countryUnCode': 'ES',
            'euPort': true
          },
          'portFacilityVisits': []
        },
        {
          'id': '62ba903b912543b18438f132d2409263',
          'arrival': '2018-10-27T11:00:00Z',
          'departure': '2018-10-28T11:00:00Z',
          'port': {
            'name': 'Tunisi',
            'locationUnCode': 'ITTUN',
            'countryUnCode': 'IT',
            'euPort': true
          },
          'portFacilityVisits': []
        }
      ],
      'portVisit': {
        'intention': 'REQUEST_FOR_ENTRY',
        'pilotStation': {
          'code': 'MC',
          'name': 'Maascenter',
          'atSea': true
        },
        'entryPoint': {
          'code': 'LL',
          'name': 'Lage Licht'
        },
        'etaPort': '2018-11-08T13:00:00Z',
        'firstMovement': {
          'vesselDraft': 6.0,
          'vesselMasterName': 'Henk',
          'numberOfCrew': 20,
          'numberOfPassengers': 6,
          'cargo': 'OUT_OF_GAUGE',
          'pilotExemption': {
            'holderName': '',
            'number': '16'
          },
          'offStandardBeam': {
            'value': 4.5,
            'remarks': ''
          },
          'pilotService': {
            'required': false
          }
        },
        'passingThroughTugboats': null,
        'defectTypes': [
          'DEN',
          'DWE',
          'TOW'
        ],
        'berthVisits': [
          {
            'id': '1abbb8789fe94b5c8adad25550a0a6b1',
            'berth': {
              'name': 'HOLLAND AMERIKAKADE',
              'code': 'R1243',
              'terminalCode': '52090469',
              'berthGroupCode': 'HOLLAND AMERIKAKADE',
              'buoy': false,
              'partyToNotify': null
            },
            'eta': '2018-11-09T11:00:00Z',
            'ata': '2018-11-09T11:00:00Z',
            'etd': '2018-11-09T15:00:00Z',
            'atd': null,
            'mooring': 'PORT_SIDE',
            'remarks': null,
            'visitPurposes': [
              'CARGO_TANK_CLEANING',
              'CUSTOMS_CLEARANCE',
              'DISPOSAL_OF_WASTE',
              'LAID_UP',
              'LOADING'
            ],
            'bollardFrom': null,
            'bollardTo': null,
            'tugboatAtArrival': {
              'required': true,
              'serviceProvider': {
                'name': 'Gepke',
                'portAuthorityId': 'VERSCHOOR'
              },
              'requiredQuantity': 'SIX',
              'remarks': null
            },
            'boatmenAtArrival': {
              'required': true,
              'serviceProvider': {
                'name': 'V.i.o.s.',
                'portAuthorityId': 'VIOS'
              },
              'remarks': null
            },
            'boatmenAtDeparture': {
              'required': true,
              'serviceProvider': {
                'name': 'V.i.o.s.',
                'portAuthorityId': 'VIOS'
              },
              'remarks': null
            },
            'tugboatAtDeparture': {
              'required': true,
              'serviceProvider': {
                'name': 'Gepke',
                'portAuthorityId': 'VERSCHOOR'
              },
              'requiredQuantity': 'SIX',
              'remarks': null
            },
            'nextMovement': {
              'vesselDraft': 7.0,
              'vesselMasterName': 'Piet',
              'numberOfCrew': 21,
              'numberOfPassengers': 7,
              'cargo': 'OUT_OF_GAUGE',
              'pilotExemption': {
                'holderName': '',
                'number': '16'
              },
              'offStandardBeam': {
                'value': 4.5,
                'remarks': ''
              },
              'pilotService': null
            }
          },
          {
            'id': 'c1324bb553b2451d907e63e48d74c3e1',
            'berth': {
              'name': 'DORDR PWA KADE',
              'code': 'D4358',
              'terminalCode': '51433242',
              'berthGroupCode': 'DORDR PWA KADE',
              'buoy': false,
              'partyToNotify': null
            },
            'eta': '2018-11-10T11:00:00Z',
            'ata': null,
            'etd': '2018-11-10T22:00:00Z',
            'atd': null,
            'mooring': 'ALONGSIDE_OTHER_SHIP',
            'remarks': null,
            'visitPurposes': [
              'DISCHARGE_CRUDE_OIL',
              'TAKING_SUPPLIES'
            ],
            'bollardFrom': null,
            'bollardTo': null,
            'tugboatAtArrival': {
              'required': false,
              'serviceProvider': {
                'name': 'Gepke',
                'portAuthorityId': 'VERSCHOOR'
              },
              'requiredQuantity': null,
              'remarks': null
            },
            'boatmenAtArrival': {
              'required': true,
              'serviceProvider': {
                'name': 'V.i.o.s.',
                'portAuthorityId': 'VIOS'
              },
              'remarks': null
            },
            'boatmenAtDeparture': null,
            'tugboatAtDeparture': {
              'required': true,
              'serviceProvider': {
                'name': 'Gepke',
                'portAuthorityId': 'VERSCHOOR'
              },
              'requiredQuantity': 'FIVE',
              'remarks': null
            },
            'nextMovement': {
              'vesselDraft': 8.0,
              'vesselMasterName': 'Henk',
              'numberOfCrew': 20,
              'numberOfPassengers': 6,
              'cargo': 'BALLAST_CONDITION',
              'pilotExemption': null,
              'offStandardBeam': {
                'value': 12.8,
                'remarks': 'No remarks'
              },
              'pilotService': {
                'required': true,
                'serviceProvider': {
                  'name': 'Pilot 1',
                  'portAuthorityId': 'PIL1'
                },
                'remarks': null
              }
            }
          },
          {
            'id': 'abb42b0d775d44fca7e2d392d16ff340',
            'berth': {
              'name': 'PET 3 B 61',
              'code': 'R61',
              'terminalCode': '47644472',
              'berthGroupCode': 'PET 3 B 61',
              'buoy': true,
              'partyToNotify': null
            },
            'eta': '2018-11-11T11:00:00Z',
            'ata': null,
            'etd': '2018-11-11T22:00:00Z',
            'atd': null,
            'mooring': 'STARBOARD_SIDE',
            'remarks': null,
            'visitPurposes': [
              'CREW_MOVEMENT',
              'LOADING',
              'REFUGE'
            ],
            'bollardFrom': 16.2,
            'bollardTo': 20.2,
            'tugboatAtArrival': {
              'required': false,
              'serviceProvider': {
                'name': 'Gepke',
                'portAuthorityId': 'VERSCHOOR'
              },
              'requiredQuantity': null,
              'remarks': null
            },
            'boatmenAtArrival': {
              'required': true,
              'serviceProvider': {
                'name': 'V.i.o.s.',
                'portAuthorityId': 'VIOS'
              },
              'remarks': null
            },
            'boatmenAtDeparture': null,
            'tugboatAtDeparture': {
              'required': false,
              'serviceProvider': {
                'name': 'Smit havensleepdienst B.V.',
                'portAuthorityId': 'SMIT'
              },
              'requiredQuantity': null,
              'remarks': null
            },
            'nextMovement': {
              'vesselDraft': 12.5,
              'vesselMasterName': 'Henk',
              'numberOfCrew': 20,
              'numberOfPassengers': 6,
              'cargo': 'BALLAST_CONDITION',
              'pilotExemption': {
                'holderName': 'Exemption holder 1',
                'number': '12345'
              },
              'offStandardBeam': {
                'value': 12.8,
                'remarks': 'No remarks'
              },
              'pilotService': null
            }
          }
        ],
        'dangerousGoodsLoading': true,
        'dangerousGoodsDischarge': false,
        'dangerousGoodsTransit': true,
        'possibleAnchorage': true,
        'vesselInspectionRequired': true,
        'shipConfiguration': 'DOUBLE_HULL_TANKER',
        'exitPoint': {
          'code': 'OM',
          'name': 'Oude Maas',
          'atSea': false
        },
        'etdPort': '2018-11-13T11:00:00Z',
        'atdPort': null
      },
      'departureVoyage': {
        'voyageNumber': '1234568',
        'carrier': {
          'customsId': '12345',
          'name': null,
          'scacCode': null,
          'smdgCode': null
        }
      },
      'nextPorts': [
        {
          'id': 'f94034d2d4624e778775625184f22091',
          'arrival': '2018-11-14T11:00:00Z',
          'departure': '2018-11-15T11:00:00Z',
          'port': {
            'name': 'Antwerpen',
            'locationUnCode': 'BEANR',
            'countryUnCode': 'BE',
            'euPort': true
          },
          'customsOffice': null
        },
        {
          'id': 'd8d6ca9c2fa44747b4d8f111a75e11c7',
          'arrival': '2018-11-22T11:00:00Z',
          'departure': '2018-11-23T11:00:00Z',
          'port': {
            'name': 'Taranto',
            'locationUnCode': 'ITTAR',
            'countryUnCode': 'IT',
            'euPort': true
          },
          'customsOffice': {
            'name': 'S.O.T. AEROPORTO DELLO STRETTO',
            'unCode': 'IT087101'
          }
        },
        {
          'id': 'f4e5100bad6d4aa4a2cff0412bf963e0',
          'arrival': '2018-11-24T11:00:00Z',
          'departure': '2018-11-27T11:00:00Z',
          'port': {
            'name': 'El Iskandariya (= Alexandria)',
            'locationUnCode': 'EGALY',
            'countryUnCode': 'EG',
            'euPort': false
          },
          'customsOffice': null
        }
      ]
    },
    'nextVisitDeclaration': [],
    'securityDeclaration': {
      'securityReportRequired': 'YES',
      'companySecurityOfficer': {
        'name': 'Jansen',
        'email': 'Jansen@hotmail.com',
        'phoneNumber': '123456789'
      },
      'currentSecurityLevel': 'SL1',
      'approvedSspDocumentOnBoard': false,
      'isscSecurityDocument': {
        'availability': 'YES',
        'expiryDate': '2017-01-01',
        'reasonNoValidIsscOnBoard': 'ik ben een gevaarlijke piraat',
        'issuer': {
          'code': '1',
          'name': 'AMERICAN BUREAU'
        }
      },
      'shipToShipActivities': [
        {
          'location': 'Ergens 1',
          'type': 'CARGO_TANK_CLEANING',
          'startDate': '2018-11-07',
          'endDate': '2018-11-08',
          'compliantWithSsp': true,
          'securityMeasures': 'Measures 1'
        },
        {
          'location': 'Ergens 2',
          'type': 'CHANGING_CREW',
          'startDate': '2018-11-06',
          'endDate': '2018-11-07',
          'compliantWithSsp': false,
          'securityMeasures': 'Measures 2'
        },
        {
          'location': 'Ergens 3',
          'type': 'DE_GASSING',
          'startDate': '2018-11-05',
          'endDate': '2018-11-06',
          'compliantWithSsp': true,
          'securityMeasures': 'Measures 3'
        },
        {
          'location': 'Ergens 4',
          'type': 'CARGO_TANK_CLEANING',
          'startDate': '2018-11-04',
          'endDate': '2018-11-05',
          'compliantWithSsp': false,
          'securityMeasures': 'Measures 4'
        },
        {
          'location': 'Ergens 5',
          'type': 'CHANGING_CREW',
          'startDate': '2018-11-03',
          'endDate': '2018-11-04',
          'compliantWithSsp': true,
          'securityMeasures': 'Measures 5'
        },
        {
          'location': 'Ergens 6',
          'type': 'DE_GASSING',
          'startDate': '2018-11-02',
          'endDate': '2018-11-03',
          'compliantWithSsp': false,
          'securityMeasures': 'Measures 6'
        },
        {
          'location': 'Ergens 7',
          'type': 'CARGO_TANK_CLEANING',
          'startDate': '2018-11-01',
          'endDate': '2018-11-02',
          'compliantWithSsp': true,
          'securityMeasures': 'Measures 7'
        },
        {
          'location': 'Ergens 8',
          'type': 'CHANGING_CREW',
          'startDate': '2018-10-31',
          'endDate': '2018-11-01',
          'compliantWithSsp': false,
          'securityMeasures': 'Measures 8'
        },
        {
          'location': 'Ergens 9',
          'type': 'DE_GASSING',
          'startDate': '2018-10-30',
          'endDate': '2018-10-31',
          'compliantWithSsp': true,
          'securityMeasures': 'Measures 9'
        }
      ],
      'securityRelatedMatter': 'Any security related matter to report'
    },
    'wasteDeclaration': null,
    'shipStoresDeclaration': null,
    'paxDeclarationSummaries': null,
    'uploads': {},
    'cancelled': false,
    'ataRegisteredByPa': false,
    'declarations': [
      {
        'type': 'VISIT',
        'status': 'ACCEPTED',
        'rejectReasons': null,
        'timeStamp': '2018-12-20T11:12:40.997Z'
      },
      {
        'type': 'WPCS',
        'status': 'DECLARED',
        'rejectReasons': null,
        'timeStamp': '2018-12-20T11:12:48.060Z'
      },
      {
        'type': 'SECURITY',
        'status': 'ACCEPTED',
        'rejectReasons': null,
        'timeStamp': '2018-12-20T11:13:13.063Z'
      },
      {
        'type': 'VISIT',
        'status': 'ACCEPTED',
        'rejectReasons': null,
        'timeStamp': '2018-12-24T10:31:08.960Z'
      }
    ],
    'berthVisitInfos': null,
    'visitStatus': 'ARRIVED',
    'expected': false
  },
  NLRTM_INCOMING: {
    'crn': 'NLRTM_INCOMING',
    'additionalIncomingMovementInfo': {
      'etaPilotBoardingPlace': '2021-11-28T04:00:00Z',
      'shifted': true,
      'plannedNumberOfTugboats': 'SIX'
    },
    'incomingMovementHarbourMasterInfo': {
      'remarks': {
        'text': 'dobber op met je skip'
      },
      'orderStatus': 'CONFIRMED'
    },
    'visitDeclaration': {
      'portVisit': {
        'portEntry':
          {
            'earliestTimeOfPortEntry': '2021-11-26T04:00:00Z',
            'etaPilotBoardingPlace': '2021-11-28T04:00:00Z',
            'requestedEtaPilotBoardingPlace': '2021-11-28T04:00:00Z',
            'baseForPlanning': 'PILOT_BOARDING_PLACE',
            'intention': 'REQUEST_FOR_ENTRY',
            'origin': 'SEA',
            'etaSeaBuoy': '2021-11-08T06:00:00Z',
            'entryDependency': {
              'vesselName': 'Lenski',
              'vesselImoCode': '2342556',
              'dependencyType': 'EXCHANGE',
              'orderStatus': 'ORDERED',
              'autoOrder': true
            }
          },
        'pilotStation': {
          'code': 'RV',
          'name': 'Rendez-vous',
          'atSea': true
        },
        'entryPoint': {'code': 'LL', 'name': 'Lage Licht'},
        'etaPort': '2020-11-08T06:00:00Z',
        'etdPort': '2021-11-30T06:00:00Z',
        'firstMovement': {
          'vesselDraft': 15,
          'vesselMasterName': 'Masterio',
          'numberOfCrew': 19,
          'numberOfPassengers': 2,
          'cargo': 'CONTAINERIZED',
          'pilotExemption': null,
          'offStandardBeam': null,
          'pilotService': {
            'required': true,
            'serviceProvider': {'name': 'Loodswezen', 'portAuthorityId': 'LRR'},
            'remarks': null
          },
          'order': true,
          'stormPilotageInformation': null
        },
        'passingThroughTugboats': null,
        'defectTypes': [],
        'berthVisits': [{
          'id': 'd4325816-ead8-404e-9fa6-3ab958c909b8',
          'berth': {
            'name': 'AMALIAH APM DSQ',
            'code': 'R8409',
            'terminalCode': '4254',
            'berthGroupCode': 'APMT',
            'buoy': false,
            'partyToNotify': null
          },
          'eta': null,
          'ata': null,
          'etd': null,
          'requestedEtd': null,
          'atd': null,
          'mooring': 'STARBOARD_SIDE',
          'remarks': null,
          'visitPurposes': ['DISCHARGE', 'LOADING'],
          'bollardFrom': null,
          'bollardTo': null,
          'tugboatAtArrival': {
            'required': true,
            'serviceProvider': {'name': 'Boluda', 'portAuthorityId': 'BOLUDA'},
            'requiredQuantity': 'PILOT_DETERMINES',
            'remarks': null
          },
          'boatmenAtArrival': {
            'required': true,
            'serviceProvider': {'name': 'KRVE', 'portAuthorityId': 'RVE'},
            'remarks': null
          },
          'boatmenAtDeparture': {
            'required': true,
            'serviceProvider': {'name': 'KRVE', 'portAuthorityId': 'RVE'},
            'remarks': null
          },
          'tugboatAtDeparture': {
            'required': true,
            'serviceProvider': {'name': 'Boluda', 'portAuthorityId': 'BOLUDA'},
            'requiredQuantity': 'ONE',
            'remarks': null
          },
          'nextMovement': {
            'vesselDraft': 15,
            'vesselMasterName': 'Masterio',
            'numberOfCrew': 19,
            'numberOfPassengers': 2,
            'cargo': 'CONTAINERIZED',
            'pilotExemption': null,
            'offStandardBeam': null,
            'pilotService': {
              'required': true,
              'serviceProvider': {'name': 'Loodswezen', 'portAuthorityId': 'LRR'},
              'remarks': null
            },
            'order': null,
            'stormPilotageInformation': null
          }
        }, {
          'id': '3d2a9c5e-9152-4957-8ece-cbced7e8b5c7',
          'berth': {
            'name': 'MISSISSIPPIH EMO PLAATS 4',
            'code': 'R8006',
            'terminalCode': '29632660',
            'berthGroupCode': 'MISSH EMO',
            'buoy': false,
            'partyToNotify': null
          },
          'eta': null,
          'ata': null,
          'etd': null,
          'requestedEtd': null,
          'atd': null,
          'mooring': 'STARBOARD_SIDE',
          'remarks': null,
          'visitPurposes': ['DISCHARGE', 'LOADING'],
          'bollardFrom': null,
          'bollardTo': null,
          'tugboatAtArrival': {
            'required': true,
            'serviceProvider': {'name': 'Boluda', 'portAuthorityId': 'BOLUDA'},
            'requiredQuantity': 'PILOT_DETERMINES',
            'remarks': null
          },
          'boatmenAtArrival': {
            'required': true,
            'serviceProvider': {'name': 'KRVE', 'portAuthorityId': 'RVE'},
            'remarks': null
          },
          'boatmenAtDeparture': {
            'required': true,
            'serviceProvider': {'name': 'KRVE', 'portAuthorityId': 'RVE'},
            'remarks': null
          },
          'tugboatAtDeparture': {
            'required': true,
            'serviceProvider': {'name': 'Boluda', 'portAuthorityId': 'BOLUDA'},
            'requiredQuantity': 'TWO',
            'remarks': null
          },
          'nextMovement': {
            'vesselDraft': 15,
            'vesselMasterName': 'Masterio',
            'numberOfCrew': 19,
            'numberOfPassengers': 2,
            'cargo': 'CONTAINERIZED',
            'pilotExemption': null,
            'offStandardBeam': null,
            'pilotService': {
              'required': true,
              'serviceProvider': {'name': 'Loodswezen', 'portAuthorityId': 'LRR'},
              'remarks': null
            },
            'order': null,
            'stormPilotageInformation': null
          }
        }],
        'dangerousGoodsLoading': false,
        'dangerousGoodsDischarge': false,
        'dangerousGoodsTransit': false,
        'possibleAnchorage': false,
        'vesselInspectionRequired': false,
        'shipConfiguration': null,
        'exitPoint': {'code': 'LL', 'name': 'Lage Licht', 'atSea': true},
        'atdPort': null,
        'ataPort': null
      },

      'clientReferenceNumber': 'TestMPopLWZ',
      'etaFirstEntryEu': null,
      'arrivalVoyage': {
        'voyageNumber': null,
        'carrier': {'customsId': 'NL806043386', 'name': 'Cross-Ocean C.V.', 'scacCode': 'CROC', 'smdgCode': null}
      },
      'previousPorts': [
        {
          'id': '7239cd11943846a78869f7260f0fc219',
          'arrival': '2018-11-07T11:00:00Z',
          'departure': '2018-11-08T01:00:00Z',
          'port': {
            'name': 'Barcelona',
            'locationUnCode': 'ESBCN',
            'countryUnCode': 'ES',
            'euPort': true
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'ESBCN-0001',
                'name': 'Barcelona, Spain  AUTOTERMINAL, S.A.'
              },
              'arrivalDate': '2018-11-07',
              'departureDate': '2018-11-08',
              'securityLevel': 'SL1',
              'additionalSecurityMeasures': 'Measures 1'
            }
          ]
        },
        {
          'id': '39af59bb1dd344339f84d12c4533bd03',
          'arrival': '2018-11-06T11:00:00Z',
          'departure': '2018-11-07T11:00:00Z',
          'port': {
            'name': 'Tunisi',
            'locationUnCode': 'ITTUN',
            'countryUnCode': 'IT',
            'euPort': true
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'ITTUN-9999',
                'name': 'UNKNOWN'
              },
              'arrivalDate': '2018-11-06',
              'departureDate': '2018-11-07',
              'securityLevel': 'SL2',
              'additionalSecurityMeasures': 'Measures 2'
            }
          ]
        },
        {
          'id': '8a49e9d4d39647188bb8bd3d57dd4fa7',
          'arrival': '2018-11-05T11:00:00Z',
          'departure': '2018-11-06T11:00:00Z',
          'port': {
            'name': 'Barcelona',
            'locationUnCode': 'ESBCN',
            'countryUnCode': 'ES',
            'euPort': true
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'ESBCN-0003',
                'name': 'Barcelona, Spain  CENTRO DE CONTROL'
              },
              'arrivalDate': '2018-11-05',
              'departureDate': '2018-11-06',
              'securityLevel': 'SL3',
              'additionalSecurityMeasures': 'Measures 3'
            }
          ]
        }
      ],
      'departureVoyage': {
        'voyageNumber': null,
        'carrier': {'customsId': 'NL806043386', 'name': 'Cross-Ocean C.V.', 'scacCode': 'CROC', 'smdgCode': null}
      },
      'nextPorts': [{
        'id': '03ed0e0d-18f5-4913-8102-37747fe49282',
        'arrival': '2021-12-09T11:00:00Z',
        'departure': null,
        'port': {'name': 'Bonaire', 'locationUnCode': 'BQBON', 'countryUnCode': 'BQ', 'euPort': false},
        'customsOffice': null
      }]
    },
    'nextVisitDeclaration': {
      'nextBerthVisits': [],
      'lastBerthVisitDeparture': {'nextMovement': {}},
      'exitPoint': {},
      'nextPorts': [
        {
          "id": "239423"
        }],
      'departureVoyage': {},
      'etdPort': {}
    },
    'additionalViewers': [],
    'copyOfVisit': 'NLRTM19950220',
    'portOfCall': {
      'port': {
        'name': 'Rotterdam',
        'locationUnCode': 'NLRTM',
        'countryUnCode': 'NL',
        'euPort': true
      },
      'portAuthority': {
        'shortName': 'HBRRTM',
        'fullName': 'Havenbedrijf Rotterdam N.V.',
        'emailAddress': 'HCC@portofrotterdam.com',
        'phoneNumber': '+31-10-2521195',
        'faxNumber': '+31-10-2521000',
        'address': 'Wilhelminakade 909',
        'zipCode': '3072 AP',
        'city': 'Rotterdam',
        'countryUnCode': 'NL',
        'countryName': 'Netherlands',
        'customsEORINumber': 'NL24354561',
        'contact': 'Diana van Eijk',
        'portAuthorityId': 'HbR'
      },
      'customsOffice': {
        'name': 'Rotterdam Haven/Kantoor Maasvlakte',
        'unCode': 'NL000396'
      },
      'ataReported': true,
      'atdReported': true,
      'atdPortReported': true,
      'paDeclarationRequired': true,
      'swDeclarationRequired': true,
      'wasteEnabled': true,
      'dangerousGoodsEnabled': true,
      'harbourDuesEnabled': false,
      'orderNauticalServicesEnabled': true,
      'seaBuoy': {
        'name': 'Maascenter',
        'code': 'MC'
      }
    },
    'vessel': {
      'imoCode': '8886917',
      'name': '3 BROTHERS',
      'radioCallSign': 'WDJ2190',
      'motUnCode': '175X',
      'motName': 'Fishing boat',
      'summerDeadWeight': 0,
      'maxWidth': 6.7,
      'flagCode': 'USA',
      'flagCountryUnCode': 'US',
      'netTonnage': 97,
      'grossTonnage': 121,
      'registrationPlaceUnloCode': 'USHNL',
      'registrationPlaceName': 'Honolulu',
      'registrationDate': '1976-01-01',
      'mmsiNumber': '367756070',
      'fullLength': 140
    },
    'owner': {
      'fullName': 'Portbase',
      'portAuthorityId': 'NLRTMPCS',
      'shortName': 'PORTINFOLINK',
      'emailAddress': 'l.paalvast@portbase.com',
      'address': 'Blaak 16',
      'city': 'Rotterdam',
      'countryUnCode': 'NL',
      'countryName': 'Netherlands',
      'zipCode': '3195 ND',
      'phoneNumber': '+31-10-2522222',
      'faxNumber': '+31-10-2522250',
      'contact': 'Len de Men',
      'customsEORINumber': 'NL154645225'
    },
    'declarant': {
      'fullName': 'Back Office 1',
      'portAuthorityId': 'BO1_R',
      'shortName': 'BO1',
      'emailAddress': 'pietjepuk@yoyo.nl',
      'address': 'Back Officelaan 1',
      'city': 'Back Officestad',
      'countryUnCode': 'NL',
      'countryName': 'Netherlands',
      'zipCode': '1111SO',
      'phoneNumber': '0102522288',
      'faxNumber': null,
      'contact': 'test centrum',
      'customsEORINumber': 'NL123456789'
    },
    "nextDeclarant": null,
    "financialDeclarant": {
      "fullName": "Portbase",
      "portAuthorityId": "NLRTMPCS",
      "shortName": "PORTINFOLINK",
      "emailAddress": "l.paalvast@portbase.com",
      "address": "Blaak 16",
      "city": "Rotterdam",
      "countryUnCode": "NL",
      "countryName": "Netherlands",
      "zipCode": "3195 ND",
      "phoneNumber": "+31-10-2522222",
      "faxNumber": "+31-10-2522250",
      "contact": "Len de Men",
      "customsEORINumber": "NL154645225"
    },
    'nominatedFinancialDeclarant': null,
    'orderNauticalServices': true,
    'creditNumberHolderSameAsDeclarant': false,
    'declarantResponsibleForCustoms': false,
    'cargoDeclarants': [{
      'fullName': 'Cross-Ocean B.V.',
      'portAuthorityId': 'CROSS OCEAN',
      'shortName': 'CROSSOCEANRTM',
      'emailAddress': 'info@portbase.com',
      'address': 'Conradstraat 38',
      'city': 'Rotterdam',
      'countryUnCode': 'NL',
      'countryName': 'Netherlands',
      'zipCode': '3013 AP',
      'phoneNumber': '010-2522222',
      'faxNumber': '010-2522250',
      'contact': 'Patrick van den Berg',
      'customsEORINumber': 'NL100001221'
    }],
    'allowedViewers': [
      {
        'fullName': 'Portbase',
        'portAuthorityId': 'NLRTMPCS',
        'shortName': 'PORTINFOLINK',
        'emailAddress': 'l.paalvast@portbase.com',
        'address': 'Blaak 16',
        'city': 'Rotterdam',
        'countryUnCode': 'NL',
        'countryName': 'Netherlands',
        'zipCode': '3195 ND',
        'phoneNumber': '+31-10-2522222',
        'faxNumber': '+31-10-2522250',
        'contact': 'Len de Men',
        'customsEORINumber': 'NL154645225'
      }, {
        'fullName': 'Portbase',
        'portAuthorityId': 'NLRTMPCS',
        'shortName': 'PORTINFOLINK',
        'emailAddress': 'l.paalvast@portbase.com',
        'address': 'Blaak 16',
        'city': 'Rotterdam',
        'countryUnCode': 'NL',
        'countryName': 'Netherlands',
        'zipCode': '3195 ND',
        'phoneNumber': '+31-10-2522222',
        'faxNumber': '+31-10-2522250',
        'contact': 'Len de Men',
        'customsEORINumber': 'NL154645225'
      }, {
        'fullName': 'Havenbedrijf Rotterdam N.V.',
        'portAuthorityId': 'HbR',
        'shortName': 'HBRRTM',
        'emailAddress': 'HCC@portofrotterdam.com',
        'address': 'Wilhelminakade 909',
        'city': 'Rotterdam',
        'countryUnCode': 'NL',
        'countryName': 'Netherlands',
        'zipCode': '3072 AP',
        'phoneNumber': '+31-10-2521195',
        'faxNumber': '+31-10-2521000',
        'contact': 'Diana van Eijk',
        'customsEORINumber': 'NL24354561'
      }],
    'securityDeclaration': null,
    'wasteDeclaration': null,
    'shipStoresDeclaration': null,
    'dangerousGoodsDeclarations': [],
    'paxDeclarationSummaries': [],
    'etaPortAis': null,
    'ignoreEtaPortAis': false,
    'cancelled': false,
    'ataRegisteredByPa': false,
    'visitKnownAtPa': false,
    'dangerousGoodsEnabled': false,
    'orderIncomingMovement': true,
    'sendToSwEnabled': true,
    'declarations': [
      {
        'type': 'VISIT',
        'status': 'ACCEPTED',
        'rejectReasons': null,
        'timeStamp': '2018-12-20T11:12:40.997Z'

      }],
    'uploads': {},
    'berthVisitInfos': {
      'd4325816-ead8-404e-9fa6-3ab958c909b8': <BerthVisitInfo>{
        'harbourMasterInfo': {
          'eta': '2021-11-28T06:00:00Z'
        }
      }
    },
    'visitStatus': 'EXPECTED',
    'allDangerousGoods': {
      'dangerousGoods': {'goods': [], 'stowageAtArrival': [], 'handlings': []},
      'cargoDeclarant': {
        'fullName': 'Portbase',
        'portAuthorityId': 'NLRTMPCS',
        'shortName': 'PORTINFOLINK',
        'emailAddress': 'l.paalvast@portbase.com',
        'address': 'Blaak 16',
        'city': 'Rotterdam',
        'countryUnCode': 'NL',
        'countryName': 'Netherlands',
        'zipCode': '3195 ND',
        'phoneNumber': '+31-10-2522222',
        'faxNumber': '+31-10-2522250',
        'contact': 'Len de Men',
        'customsEORINumber': 'NL154645225'
      },
      'visitDeclarant': {
        'fullName': 'Portbase',
        'portAuthorityId': 'NLRTMPCS',
        'shortName': 'PORTINFOLINK',
        'emailAddress': 'l.paalvast@portbase.com',
        'address': 'Blaak 16',
        'city': 'Rotterdam',
        'countryUnCode': 'NL',
        'countryName': 'Netherlands',
        'zipCode': '3195 ND',
        'phoneNumber': '+31-10-2522222',
        'faxNumber': '+31-10-2522250',
        'contact': 'Len de Men',
        'customsEORINumber': 'NL154645225'
      },
      'visitOwner': {
        'fullName': 'Portbase',
        'portAuthorityId': 'NLRTMPCS',
        'shortName': 'PORTINFOLINK',
        'emailAddress': 'l.paalvast@portbase.com',
        'address': 'Blaak 16',
        'city': 'Rotterdam',
        'countryUnCode': 'NL',
        'countryName': 'Netherlands',
        'zipCode': '3195 ND',
        'phoneNumber': '+31-10-2522222',
        'faxNumber': '+31-10-2522250',
        'contact': 'Len de Men',
        'customsEORINumber': 'NL154645225'
      },
      'upload': null,
      'completed': true,
      'verifiedByShipOperator': true
    }
  },
  NLRTM19511152: {
    'crn': 'NLRTM19511152',
    'additionalViewers': [],
    'copyOfVisit': null,
    'portOfCall': {
      'port': {
        'name': 'Rotterdam',
        'locationUnCode': 'NLRTM',
        'countryUnCode': 'NL',
        'euPort': true
      },
      'portAuthority': {
        'shortName': 'HBRRTM',
        'fullName': 'Havenbedrijf Rotterdam N.V.',
        'emailAddress': 'HCC@portofrotterdam.com',
        'phoneNumber': '+31-10-2521195',
        'faxNumber': '+31-10-2521000',
        'address': 'Wilhelminakade 909',
        'zipCode': '3072 AP',
        'city': 'Rotterdam',
        'countryUnCode': 'NL',
        'countryName': 'Netherlands',
        'customsEORINumber': 'NL24354561',
        'contact': 'Diana van Eijk',
        'portAuthorityId': 'HbR'
      },
      'customsOffice': {
        'name': 'Rotterdam Haven/Kantoor Maasvlakte',
        'unCode': 'NL000396'
      },
      'ataReported': true,
      'atdReported': true,
      'atdPortReported': true,
      'paDeclarationRequired': true,
      'swDeclarationRequired': true,
      'wasteEnabled': true,
      'dangerousGoodsEnabled': true,
      'harbourDuesEnabled': false,
      'orderNauticalServicesEnabled': true,
      'seaBuoy': {
        'name': 'Maascenter',
        'code': 'MC'
      }
    },
    'vessel': {
      'imoCode': '8505941',
      'name': 'MAJOR TOM',
      'radioCallSign': 'J8B3679',
      'motUnCode': '150X',
      'motName': 'General cargo vessel',
      'summerDeadWeight': 3810,
      'maxWidth': 12.91,
      'flagCode': 'VCT',
      'flagCountryUnCode': 'VC',
      'netTonnage': 1365,
      'grossTonnage': 2367,
      'registrationPlaceUnloCode': 'VCKTN',
      'registrationPlaceName': 'Kingstown',
      'registrationDate': '1985-11-14',
      'mmsiNumber': '376317000',
      'fullLength': 87.91
    },
    'owner': {
      'fullName': 'Vertom Agencies B.V.',
      'portAuthorityId': 'VERTOM',
      'shortName': 'VERTOM',
      'emailAddress': 'agency@vertom.nl',
      'address': 'Oever 7',
      'city': 'Rhoon',
      'countryUnCode': 'NL',
      'countryName': 'Netherlands',
      'zipCode': '3161 GR',
      'phoneNumber': '+31-10-2858475',
      'faxNumber': '+31-10-2859470',
      'contact': 'Caspar de Jong',
      'customsEORINumber': 'NL821478990'
    },
    'declarant': {
      'fullName': 'Vertom Agencies B.V.',
      'portAuthorityId': 'VERTOM',
      'shortName': 'VERTOM',
      'emailAddress': 'agency@vertom.nl',
      'address': 'Oever 7',
      'city': 'Rhoon',
      'countryUnCode': 'NL',
      'countryName': 'Netherlands',
      'zipCode': '3161 GR',
      'phoneNumber': '+31-10-2858475',
      'faxNumber': '+31-10-2859470',
      'contact': 'Caspar de Jong',
      'customsEORINumber': 'NL821478990'
    },
    'financialDeclarant': {
      'fullName': 'Vertom Agencies B.V.',
      'portAuthorityId': 'VERTOM',
      'shortName': 'VERTOM',
      'emailAddress': 'agency@vertom.nl',
      'address': 'Oever 7',
      'city': 'Rhoon',
      'countryUnCode': 'NL',
      'countryName': 'Netherlands',
      'zipCode': '3161 GR',
      'phoneNumber': '+31-10-2858475',
      'faxNumber': '+31-10-2859470',
      'contact': 'Caspar de Jong',
      'customsEORINumber': 'NL821478990'
    },
    'nominatedFinancialDeclarant': null,
    'orderNauticalServices': true,
    'creditNumberHolderSameAsDeclarant': false,
    'declarantResponsibleForCustoms': false,
    'cargoDeclarants': [
      {
        'fullName': 'Vertom Agencies B.V.',
        'portAuthorityId': 'VERTOM',
        'shortName': 'VERTOM',
        'emailAddress': 'agency@vertom.nl',
        'address': 'Oever 7',
        'city': 'Rhoon',
        'countryUnCode': 'NL',
        'countryName': 'Netherlands',
        'zipCode': '3161 GR',
        'phoneNumber': '+31-10-2858475',
        'faxNumber': '+31-10-2859470',
        'contact': 'Caspar de Jong',
        'customsEORINumber': 'NL821478990'
      }
    ],
    'allowedViewers': [
      {
        'fullName': 'Vertom Agencies B.V.',
        'portAuthorityId': 'VERTOM',
        'shortName': 'VERTOM',
        'emailAddress': 'agency@vertom.nl',
        'address': 'Oever 7',
        'city': 'Rhoon',
        'countryUnCode': 'NL',
        'countryName': 'Netherlands',
        'zipCode': '3161 GR',
        'phoneNumber': '+31-10-2858475',
        'faxNumber': '+31-10-2859470',
        'contact': 'Caspar de Jong',
        'customsEORINumber': 'NL821478990'
      },
      {
        'fullName': 'Vertom Agencies B.V.',
        'portAuthorityId': 'VERTOM',
        'shortName': 'VERTOM',
        'emailAddress': 'agency@vertom.nl',
        'address': 'Oever 7',
        'city': 'Rhoon',
        'countryUnCode': 'NL',
        'countryName': 'Netherlands',
        'zipCode': '3161 GR',
        'phoneNumber': '+31-10-2858475',
        'faxNumber': '+31-10-2859470',
        'contact': 'Caspar de Jong',
        'customsEORINumber': 'NL821478990'
      },
      {
        'fullName': 'Havenbedrijf Rotterdam N.V.',
        'portAuthorityId': 'HbR',
        'shortName': 'HBRRTM',
        'emailAddress': 'HCC@portofrotterdam.com',
        'address': 'Wilhelminakade 909',
        'city': 'Rotterdam',
        'countryUnCode': 'NL',
        'countryName': 'Netherlands',
        'zipCode': '3072 AP',
        'phoneNumber': '+31-10-2521195',
        'faxNumber': '+31-10-2521000',
        'contact': 'Diana van Eijk',
        'customsEORINumber': 'NL24354561'
      }
    ],
    'visitDeclaration': {
      'clientReferenceNumber': '20190585',
      'etaFirstEntryEu': null,
      'arrivalVoyage': {
        'voyageNumber': null,
        'carrier': {
          'customsId': 'DE667081035149086',
          'name': 'MST Mineralien Schiffahrt',
          'scacCode': 'MSTM',
          'smdgCode': null
        }
      },
      'previousPorts': [
        {
          'id': '732e735d-4de2-4065-9e65-1e2a59583d5b',
          'arrival': '2019-08-13T10:00:00Z',
          'departure': '2019-08-13T10:00:00Z',
          'port': {
            'name': 'Leer',
            'locationUnCode': 'DELEE',
            'countryUnCode': 'DE',
            'euPort': true
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'DELEE-0004',
                'name': 'Rhenus/Weco'
              },
              'arrivalDate': '2019-08-13',
              'departureDate': '2019-08-13',
              'securityLevel': 'SL1',
              'additionalSecurityMeasures': null
            }
          ]
        },
        {
          'id': '9bea1602-2665-4449-98ac-90c51d5296bd',
          'arrival': '2019-08-10T10:00:00Z',
          'departure': '2019-08-10T10:00:00Z',
          'port': {
            'name': 'Måløy',
            'locationUnCode': 'NOMAY',
            'countryUnCode': 'NO',
            'euPort': false
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'NOMAY-0002',
                'name': 'Brødrene Tennebø ANS'
              },
              'arrivalDate': '2019-08-10',
              'departureDate': '2019-08-10',
              'securityLevel': 'SL1',
              'additionalSecurityMeasures': null
            }
          ]
        },
        {
          'id': '2a946144-83a5-4cf7-a74f-bab52956bc34',
          'arrival': '2019-08-08T10:00:00Z',
          'departure': '2019-08-09T10:00:00Z',
          'port': {
            'name': 'Ramsvika',
            'locationUnCode': 'NORMV',
            'countryUnCode': 'NO',
            'euPort': false
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'NORMV-9999',
                'name': 'UNKNOWN'
              },
              'arrivalDate': '2019-08-08',
              'departureDate': '2019-08-09',
              'securityLevel': 'SL1',
              'additionalSecurityMeasures': null
            }
          ]
        },
        {
          'id': 'ab1d3eae-b74d-4fe3-8bfa-4a0f6cea34f1',
          'arrival': '2019-08-06T10:00:00Z',
          'departure': '2019-08-07T10:00:00Z',
          'port': {
            'name': 'Halsa Meløy',
            'locationUnCode': 'NOHSA',
            'countryUnCode': 'NO',
            'euPort': false
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'NOHSA-9999',
                'name': 'UNKNOWN'
              },
              'arrivalDate': '2019-08-06',
              'departureDate': '2019-08-07',
              'securityLevel': 'SL1',
              'additionalSecurityMeasures': null
            }
          ]
        },
        {
          'id': '33fe2231-248e-45cb-9993-99f125161d89',
          'arrival': '2019-08-03T10:00:00Z',
          'departure': '2019-08-05T10:00:00Z',
          'port': {
            'name': 'Florø',
            'locationUnCode': 'NOFRO',
            'countryUnCode': 'NO',
            'euPort': false
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'NOFRO-0001',
                'name': 'EWOS AS'
              },
              'arrivalDate': '2019-08-03',
              'departureDate': '2019-08-05',
              'securityLevel': 'SL1',
              'additionalSecurityMeasures': null
            }
          ]
        },
        {
          'id': 'b3bea284-0ca5-46a5-96f9-213ce123aa27',
          'arrival': '2019-08-02T10:00:00Z',
          'departure': '2019-08-02T10:00:00Z',
          'port': {
            'name': 'Egersund',
            'locationUnCode': 'NOEGE',
            'countryUnCode': 'NO',
            'euPort': false
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'NOEGE-9999',
                'name': 'UNKNOWN'
              },
              'arrivalDate': '2019-08-02',
              'departureDate': '2019-08-02',
              'securityLevel': 'SL1',
              'additionalSecurityMeasures': null
            }
          ]
        },
        {
          'id': 'a11ff89e-a5bd-4787-9a34-f6c9b04654ca',
          'arrival': '2019-07-30T10:00:00Z',
          'departure': '2019-08-01T10:00:00Z',
          'port': {
            'name': 'Esbjerg',
            'locationUnCode': 'DKEBJ',
            'countryUnCode': 'DK',
            'euPort': true
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'DKEBJ-0006',
                'name': 'Esbjerg - Kaj 069'
              },
              'arrivalDate': '2019-07-30',
              'departureDate': '2019-08-01',
              'securityLevel': 'SL1',
              'additionalSecurityMeasures': null
            }
          ]
        },
        {
          'id': '0a279a59-b18a-441e-ab97-d2ef919e2d18',
          'arrival': '2019-07-27T10:00:00Z',
          'departure': '2019-07-28T10:00:00Z',
          'port': {
            'name': 'Florø',
            'locationUnCode': 'NOFRO',
            'countryUnCode': 'NO',
            'euPort': false
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'NOFRO-0001',
                'name': 'EWOS AS'
              },
              'arrivalDate': '2019-07-27',
              'departureDate': '2019-07-28',
              'securityLevel': 'SL1',
              'additionalSecurityMeasures': null
            }
          ]
        },
        {
          'id': '1c65b4fd-dbec-4f6c-b97d-a77028f567c8',
          'arrival': '2019-07-17T10:00:00Z',
          'departure': '2019-07-23T10:00:00Z',
          'port': {
            'name': 'Delfzijl',
            'locationUnCode': 'NLDZL',
            'countryUnCode': 'NL',
            'euPort': true
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'NLDZL-0016',
                'name': 'Delfzijl: Wagenborg Stevedoring B.V. (HKO)'
              },
              'arrivalDate': '2019-07-17',
              'departureDate': '2019-07-23',
              'securityLevel': 'SL1',
              'additionalSecurityMeasures': null
            }
          ]
        },
        {
          'id': '4c99d09c-cc68-4113-8a6a-8ff6833fdcda',
          'arrival': '2019-07-16T10:00:00Z',
          'departure': '2019-07-17T10:00:00Z',
          'port': {
            'name': 'Leer',
            'locationUnCode': 'DELEE',
            'countryUnCode': 'DE',
            'euPort': true
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'DELEE-0004',
                'name': 'Rhenus/Weco'
              },
              'arrivalDate': '2019-07-16',
              'departureDate': '2019-07-17',
              'securityLevel': 'SL1',
              'additionalSecurityMeasures': null
            }
          ]
        }
      ],
      'portVisit': {
        'pilotStation': {
          'code': 'MC',
          'name': 'Maascenter',
          'atSea': true
        },
        'entryPoint': {
          'code': 'LL',
          'name': 'Lage Licht'
        },
        'etaPort': '2019-08-14T12:00:00Z',
        'firstMovement': {
          'vesselDraft': 3.8,
          'vesselMasterName': 'Valery Kireev',
          'numberOfCrew': 8,
          'numberOfPassengers': 1,
          'cargo': 'BALLAST_CONDITION',
          'pilotExemption': null,
          'offStandardBeam': null,
          'pilotService': {
            'required': true,
            'serviceProvider': {
              'name': 'Loodswezen',
              'portAuthorityId': 'LRR'
            },
            'remarks': null
          },
          'order': null
        },
        'passingThroughTugboats': null,
        'defectTypes': [],
        'berthVisits': [
          {
            'id': '26847537-f534-4121-9da0-1406a67f43b8',
            'berth': {
              'name': 'NM HBR PARKKADE',
              'code': 'R194',
              'terminalCode': '5394',
              'berthGroupCode': 'STAD HBR',
              'buoy': false,
              'partyToNotify': null
            },
            'eta': null,
            'ata': '2019-08-14T21:59:39Z',
            'etd': '2019-08-16T10:00:00Z',
            'requestedEtd': '2019-08-16T10:00:00Z',
            'atd': '2019-08-16T10:11:15Z',
            'mooring': 'NO_PREFERENCE',
            'remarks': null,
            'visitPurposes': [
              'AWAITING_ORDERS'
            ],
            'bollardFrom': null,
            'bollardTo': null,
            'tugboatAtArrival': {
              'required': false,
              'serviceProvider': {
                'name': 'Boluda',
                'portAuthorityId': 'BOLUDA'
              },
              'requiredQuantity': null,
              'remarks': null
            },
            'boatmenAtArrival': {
              'required': true,
              'serviceProvider': {
                'name': 'KRVE',
                'portAuthorityId': 'RVE'
              },
              'remarks': null
            },
            'boatmenAtDeparture': {
              'required': true,
              'serviceProvider': {
                'name': 'KRVE',
                'portAuthorityId': 'RVE'
              },
              'remarks': null
            },
            'tugboatAtDeparture': {
              'required': false,
              'serviceProvider': {
                'name': 'Boluda',
                'portAuthorityId': 'BOLUDA'
              },
              'requiredQuantity': null,
              'remarks': null
            },
            'nextMovement': {
              'vesselDraft': 3.8,
              'vesselMasterName': 'Valery Kireev',
              'numberOfCrew': 8,
              'numberOfPassengers': 1,
              'cargo': 'BALLAST_CONDITION',
              'pilotExemption': null,
              'offStandardBeam': null,
              'pilotService': {
                'required': true,
                'serviceProvider': {
                  'name': 'Loodswezen',
                  'portAuthorityId': 'LRR'
                },
                'remarks': null
              },
              'order': true
            }
          },
          {
            'id': '2cb32b7a-3b01-4110-9bcd-63915e3e84d9',
            'berth': {
              'name': 'BOTLEK EBS 9',
              'code': 'R4304',
              'terminalCode': '4698',
              'berthGroupCode': 'BOTLK EBS',
              'buoy': false,
              'partyToNotify': null
            },
            'eta': null,
            'ata': '2019-08-16T11:29:15Z',
            'etd': '2019-08-17T01:15:00Z',
            'requestedEtd': '2019-08-17T01:15:00Z',
            'atd': '2019-08-17T01:18:07Z',
            'mooring': 'STARBOARD_SIDE',
            'remarks': 'a/s imp. mv \'yulia\'',
            'visitPurposes': [
              'LOADING'
            ],
            'bollardFrom': null,
            'bollardTo': null,
            'tugboatAtArrival': {
              'required': false,
              'serviceProvider': {
                'name': 'Boluda',
                'portAuthorityId': 'BOLUDA'
              },
              'requiredQuantity': null,
              'remarks': null
            },
            'boatmenAtArrival': {
              'required': true,
              'serviceProvider': {
                'name': 'KRVE',
                'portAuthorityId': 'RVE'
              },
              'remarks': null
            },
            'boatmenAtDeparture': {
              'required': true,
              'serviceProvider': {
                'name': 'KRVE',
                'portAuthorityId': 'RVE'
              },
              'remarks': null
            },
            'tugboatAtDeparture': {
              'required': false,
              'serviceProvider': {
                'name': 'Boluda',
                'portAuthorityId': 'BOLUDA'
              },
              'requiredQuantity': null,
              'remarks': null
            },
            'nextMovement': {
              'vesselDraft': 5.2,
              'vesselMasterName': 'Valery Kireev',
              'numberOfCrew': 8,
              'numberOfPassengers': 1,
              'cargo': 'GENERAL_CARGO',
              'pilotExemption': null,
              'offStandardBeam': null,
              'pilotService': {
                'required': true,
                'serviceProvider': {
                  'name': 'Loodswezen',
                  'portAuthorityId': 'LRR'
                },
                'remarks': null
              },
              'order': true
            }
          }
        ],
        'dangerousGoodsLoading': false,
        'dangerousGoodsDischarge': false,
        'dangerousGoodsTransit': false,
        'possibleAnchorage': false,
        'vesselInspectionRequired': false,
        'shipConfiguration': null,
        'exitPoint': {'code': 'LL', 'name': 'Lage Licht', 'atSea': true},
        'etdPort': '2019-08-19T16:00:00Z',
        'atdPort': '2019-08-19T16:32:18Z',
        'ataPort': '2019-08-14T21:59:39Z'
      },
      'departureVoyage': {
        'voyageNumber': null,
        'carrier': {
          'customsId': 'DE667081035149086',
          'name': 'MST Mineralien Schiffahrt',
          'scacCode': 'MSTM',
          'smdgCode': null
        }
      },
      'nextPorts': [
        {
          'id': '3ef6a9ac-39cd-4155-9198-261e32c6f1ec',
          'arrival': '2019-08-20T10:00:00Z',
          'departure': '2019-08-21T10:00:00Z',
          'port': {
            'name': 'Norrköping',
            'locationUnCode': 'SENRK',
            'countryUnCode': 'SE',
            'euPort': true
          },
          'customsOffice': null
        }
      ]
    },
    'nextVisitDeclaration': [],
    'securityDeclaration': {
      'securityReportRequired': 'YES',
      'companySecurityOfficer': {
        'name': 'Berge Anne Gunn',
        'email': 'post@bergerederi.no',
        'phoneNumber': '4772448000'
      },
      'currentSecurityLevel': 'SL1',
      'approvedSspDocumentOnBoard': true,
      'isscSecurityDocument': {
        'availability': 'YES',
        'expiryDate': '2022-09-16',
        'reasonNoValidIsscOnBoard': null,
        'issuer': {
          'code': '80',
          'name': 'Other'
        }
      },
      'shipToShipActivities': [],
      'securityRelatedMatter': null
    },
    'wasteDeclaration': {
      'portOfLastDelivery': {
        'name': 'Leer',
        'locationUnCode': 'DELEE',
        'countryUnCode': 'DE',
        'euPort': true
      },
      'lastDeliveryDate': '2019-08-13',
      'wasteItems': [
        {
          'type': 'SLUDGE',
          "ssn": {
            "code": "102",
            "description": "Oily Residues (Sludge)",
            "marpolCategory": "Annex I",
            "specificationRequired": false
          },
          'berthIdForPickup': null,
          'collector': null,
          'specification': null,
          'quantityOfLastDelivery': 0,
          'quantityToBeDelivered': 0,
          'quantityToBeRetained': 3.1,
          'maxDedicatedCapacity': 4.5,
          'quantityToBeGenerated': 0.01,
          'portForRetainedWaste': {
            'name': 'Norrköping',
            'locationUnCode': 'SENRK',
            'countryUnCode': 'SE',
            'euPort': true
          }
        },
        {
          'type': 'FUEL_REMNANTS',
          "ssn": {
            "code": "102",
            "description": "Oily Residues (Sludge)",
            "marpolCategory": "Annex I",
            "specificationRequired": false
          },
          'berthIdForPickup': null,
          'collector': null,
          'specification': null,
          'quantityOfLastDelivery': 0,
          'quantityToBeDelivered': 0,
          'quantityToBeRetained': 0,
          'maxDedicatedCapacity': 0,
          'quantityToBeGenerated': 0,
          'portForRetainedWaste': null
        },
        {
          'type': 'BILGE_WATER',
          "ssn": {
            "code": "102",
            "description": "Oily Residues (Sludge)",
            "marpolCategory": "Annex I",
            "specificationRequired": false
          },
          'berthIdForPickup': null,
          'collector': null,
          'specification': null,
          'quantityOfLastDelivery': 0,
          'quantityToBeDelivered': 0,
          'quantityToBeRetained': 0,
          'maxDedicatedCapacity': 0,
          'quantityToBeGenerated': 0,
          'portForRetainedWaste': null
        },
        {
          'type': 'USED_ENGINE_OIL',
          "ssn": {
            "code": "102",
            "description": "Oily Residues (Sludge)",
            "marpolCategory": "Annex I",
            "specificationRequired": false
          },
          'berthIdForPickup': null,
          'collector': null,
          'specification': null,
          'quantityOfLastDelivery': 0,
          'quantityToBeDelivered': 0,
          'quantityToBeRetained': 1.6,
          'maxDedicatedCapacity': 8.9,
          'quantityToBeGenerated': 0.01,
          'portForRetainedWaste': {
            'name': 'Norrköping',
            'locationUnCode': 'SENRK',
            'countryUnCode': 'SE',
            'euPort': true
          }
        },
        {
          'type': 'FOOD_WASTE',
          "ssn": {
            "code": "102",
            "description": "Oily Residues (Sludge)",
            "marpolCategory": "Annex I",
            "specificationRequired": false
          },
          'berthIdForPickup': null,
          'collector': null,
          'specification': null,
          'quantityOfLastDelivery': 0,
          'quantityToBeDelivered': 0,
          'quantityToBeRetained': 0.02,
          'maxDedicatedCapacity': 0.24,
          'quantityToBeGenerated': 0.01,
          'portForRetainedWaste': {
            'name': 'Norrköping',
            'locationUnCode': 'SENRK',
            'countryUnCode': 'SE',
            'euPort': true
          }
        },
        {
          'type': 'PLASTIC_CLEAN',
          "ssn": {
            "code": "102",
            "description": "Oily Residues (Sludge)",
            "marpolCategory": "Annex I",
            "specificationRequired": false
          },
          'berthIdForPickup': null,
          'collector': null,
          'specification': null,
          'quantityOfLastDelivery': 0,
          'quantityToBeDelivered': 0,
          'quantityToBeRetained': 0.03,
          'maxDedicatedCapacity': 0.24,
          'quantityToBeGenerated': 0.01,
          'portForRetainedWaste': {
            'name': 'Norrköping',
            'locationUnCode': 'SENRK',
            'countryUnCode': 'SE',
            'euPort': true
          }
        },
        {
          'type': 'PLASTIC_CONTAMINATED',
          "ssn": {
            "code": "102",
            "description": "Oily Residues (Sludge)",
            "marpolCategory": "Annex I",
            "specificationRequired": false
          },
          'berthIdForPickup': null,
          'collector': null,
          'specification': null,
          'quantityOfLastDelivery': 0,
          'quantityToBeDelivered': 0,
          'quantityToBeRetained': 0,
          'maxDedicatedCapacity': 0,
          'quantityToBeGenerated': 0,
          'portForRetainedWaste': null
        },
        {
          'type': 'SMALL_DANGEROUS_WASTE',
          "ssn": {
            "code": "102",
            "description": "Oily Residues (Sludge)",
            "marpolCategory": "Annex I",
            "specificationRequired": false
          },
          'berthIdForPickup': null,
          'collector': null,
          'specification': null,
          'quantityOfLastDelivery': 0,
          'quantityToBeDelivered': 0,
          'quantityToBeRetained': 0,
          'maxDedicatedCapacity': 0,
          'quantityToBeGenerated': 0,
          'portForRetainedWaste': null
        },
        {
          'type': 'OTHER_DOMESTIC_WASTE',
          "ssn": {
            "code": "102",
            "description": "Oily Residues (Sludge)",
            "marpolCategory": "Annex I",
            "specificationRequired": false
          },
          'berthIdForPickup': null,
          'collector': null,
          'specification': '.',
          'quantityOfLastDelivery': 0,
          'quantityToBeDelivered': 0,
          'quantityToBeRetained': 0.1,
          'maxDedicatedCapacity': 0.24,
          'quantityToBeGenerated': 0.02,
          'portForRetainedWaste': {
            'name': 'Norrköping',
            'locationUnCode': 'SENRK',
            'countryUnCode': 'SE',
            'euPort': true
          }
        },
        {
          'type': 'INTERNATIONAL_CATERING_WASTE',
          "ssn": {
            "code": "102",
            "description": "Oily Residues (Sludge)",
            "marpolCategory": "Annex I",
            "specificationRequired": false
          },
          'berthIdForPickup': null,
          'collector': null,
          'specification': null,
          'quantityOfLastDelivery': 0,
          'quantityToBeDelivered': 0,
          'quantityToBeRetained': 0,
          'maxDedicatedCapacity': 0,
          'quantityToBeGenerated': 0,
          'portForRetainedWaste': null
        },
        {
          'type': 'PAPER_OR_CARTON',
          "ssn": {
            "code": "102",
            "description": "Oily Residues (Sludge)",
            "marpolCategory": "Annex I",
            "specificationRequired": false
          },
          'berthIdForPickup': null,
          'collector': null,
          'specification': null,
          'quantityOfLastDelivery': 0,
          'quantityToBeDelivered': 0,
          'quantityToBeRetained': 0,
          'maxDedicatedCapacity': 0,
          'quantityToBeGenerated': 0,
          'portForRetainedWaste': null
        },
        {
          'type': 'RAGS',
          "ssn": {
            "code": "102",
            "description": "Oily Residues (Sludge)",
            "marpolCategory": "Annex I",
            "specificationRequired": false
          },
          'berthIdForPickup': null,
          'collector': null,
          'specification': null,
          'quantityOfLastDelivery': 0,
          'quantityToBeDelivered': 0,
          'quantityToBeRetained': 0,
          'maxDedicatedCapacity': 0,
          'quantityToBeGenerated': 0,
          'portForRetainedWaste': null
        },
        {
          'type': 'GLASS',
          "ssn": {
            "code": "102",
            "description": "Oily Residues (Sludge)",
            "marpolCategory": "Annex I",
            "specificationRequired": false
          },
          'berthIdForPickup': null,
          'collector': null,
          'specification': null,
          'quantityOfLastDelivery': 0,
          'quantityToBeDelivered': 0,
          'quantityToBeRetained': 0,
          'maxDedicatedCapacity': 0,
          'quantityToBeGenerated': 0,
          'portForRetainedWaste': null
        },
        {
          'type': 'METAL_OR_STEELWIRES',
          "ssn": {
            "code": "102",
            "description": "Oily Residues (Sludge)",
            "marpolCategory": "Annex I",
            "specificationRequired": false
          },
          'berthIdForPickup': null,
          'collector': null,
          'specification': null,
          'quantityOfLastDelivery': 0,
          'quantityToBeDelivered': 0,
          'quantityToBeRetained': 0,
          'maxDedicatedCapacity': 0,
          'quantityToBeGenerated': 0,
          'portForRetainedWaste': null
        },
        {
          'type': 'BOTTLES',
          "ssn": {
            "code": "102",
            "description": "Oily Residues (Sludge)",
            "marpolCategory": "Annex I",
            "specificationRequired": false
          },
          'berthIdForPickup': null,
          'collector': null,
          'specification': null,
          'quantityOfLastDelivery': 0,
          'quantityToBeDelivered': 0,
          'quantityToBeRetained': 0,
          'maxDedicatedCapacity': 0,
          'quantityToBeGenerated': 0,
          'portForRetainedWaste': null
        },
        {
          'type': 'CROCKERY',
          "ssn": {
            "code": "102",
            "description": "Oily Residues (Sludge)",
            "marpolCategory": "Annex I",
            "specificationRequired": false
          },
          'berthIdForPickup': null,
          'collector': null,
          'specification': null,
          'quantityOfLastDelivery': 0,
          'quantityToBeDelivered': 0,
          'quantityToBeRetained': 0,
          'maxDedicatedCapacity': 0,
          'quantityToBeGenerated': 0,
          'portForRetainedWaste': null
        },
        {
          'type': 'INCINERATOR_ASHES',
          "ssn": {
            "code": "102",
            "description": "Oily Residues (Sludge)",
            "marpolCategory": "Annex I",
            "specificationRequired": false
          },
          'berthIdForPickup': null,
          'collector': null,
          'specification': null,
          'quantityOfLastDelivery': 0,
          'quantityToBeDelivered': 0,
          'quantityToBeRetained': 0,
          'maxDedicatedCapacity': 0,
          'quantityToBeGenerated': 0,
          'portForRetainedWaste': null
        },
        {
          'type': 'ANIMAL_CARCASSES',
          "ssn": {
            "code": "102",
            "description": "Oily Residues (Sludge)",
            "marpolCategory": "Annex I",
            "specificationRequired": false
          },
          'berthIdForPickup': null,
          'collector': null,
          'specification': null,
          'quantityOfLastDelivery': 0,
          'quantityToBeDelivered': 0,
          'quantityToBeRetained': 0,
          'maxDedicatedCapacity': 0,
          'quantityToBeGenerated': 0,
          'portForRetainedWaste': null
        },
        {
          'type': 'EWASTE_WHOLE_DEVICES',
          "ssn": {
            "code": "102",
            "description": "Oily Residues (Sludge)",
            "marpolCategory": "Annex I",
            "specificationRequired": false
          },
          'berthIdForPickup': null,
          'collector': null,
          'specification': null,
          'quantityOfLastDelivery': 0,
          'quantityToBeDelivered': 0,
          'quantityToBeRetained': 0,
          'maxDedicatedCapacity': 0,
          'quantityToBeGenerated': 0,
          'portForRetainedWaste': null
        },
        {
          'type': 'EWASTE_DEVICES',
          "ssn": {
            "code": "102",
            "description": "Oily Residues (Sludge)",
            "marpolCategory": "Annex I",
            "specificationRequired": false
          },
          'berthIdForPickup': null,
          'collector': null,
          'specification': null,
          'quantityOfLastDelivery': 0,
          'quantityToBeDelivered': 0,
          'quantityToBeRetained': 0,
          'maxDedicatedCapacity': 0,
          'quantityToBeGenerated': 0,
          'portForRetainedWaste': null
        },
        {
          'type': 'PYROTECHNICS',
          "ssn": {
            "code": "102",
            "description": "Oily Residues (Sludge)",
            "marpolCategory": "Annex I",
            "specificationRequired": false
          },
          'berthIdForPickup': null,
          'collector': null,
          'specification': null,
          'quantityOfLastDelivery': 0,
          'quantityToBeDelivered': 0,
          'quantityToBeRetained': 0,
          'maxDedicatedCapacity': 0,
          'quantityToBeGenerated': 0,
          'portForRetainedWaste': null
        },
        {
          'type': 'BATTERIES',
          "ssn": {
            "code": "102",
            "description": "Oily Residues (Sludge)",
            "marpolCategory": "Annex I",
            "specificationRequired": false
          },
          'berthIdForPickup': null,
          'collector': null,
          'specification': null,
          'quantityOfLastDelivery': 0,
          'quantityToBeDelivered': 0,
          'quantityToBeRetained': 0,
          'maxDedicatedCapacity': 0,
          'quantityToBeGenerated': 0,
          'portForRetainedWaste': null
        },
        {
          'type': 'FIRE_EXTINGUISHERS',
          "ssn": {
            "code": "102",
            "description": "Oily Residues (Sludge)",
            "marpolCategory": "Annex I",
            "specificationRequired": false
          },
          'berthIdForPickup': null,
          'collector': null,
          'specification': null,
          'quantityOfLastDelivery': 0,
          'quantityToBeDelivered': 0,
          'quantityToBeRetained': 0,
          'maxDedicatedCapacity': 0,
          'quantityToBeGenerated': 0,
          'portForRetainedWaste': null
        },
        {
          'type': 'CAR_TYRES_OR_RUBBER',
          "ssn": {
            "code": "102",
            "description": "Oily Residues (Sludge)",
            "marpolCategory": "Annex I",
            "specificationRequired": false
          },
          'berthIdForPickup': null,
          'collector': null,
          'specification': null,
          'quantityOfLastDelivery': 0,
          'quantityToBeDelivered': 0,
          'quantityToBeRetained': 0,
          'maxDedicatedCapacity': 0,
          'quantityToBeGenerated': 0,
          'portForRetainedWaste': null
        },
        {
          'type': 'COOLING_LIQUIDS',
          "ssn": {
            "code": "102",
            "description": "Oily Residues (Sludge)",
            "marpolCategory": "Annex I",
            "specificationRequired": false
          },
          'berthIdForPickup': null,
          'collector': null,
          'specification': null,
          'quantityOfLastDelivery': 0,
          'quantityToBeDelivered': 0,
          'quantityToBeRetained': 0,
          'maxDedicatedCapacity': 0,
          'quantityToBeGenerated': 0,
          'portForRetainedWaste': null
        },
        {
          'type': 'PROPELLER_SHAFT_GREASE',
          "ssn": {
            "code": "102",
            "description": "Oily Residues (Sludge)",
            "marpolCategory": "Annex I",
            "specificationRequired": false
          },
          'berthIdForPickup': null,
          'collector': null,
          'specification': null,
          'quantityOfLastDelivery': 0,
          'quantityToBeDelivered': 0,
          'quantityToBeRetained': 0,
          'maxDedicatedCapacity': 0,
          'quantityToBeGenerated': 0,
          'portForRetainedWaste': null
        },
        {
          'type': 'FUMIGATION_PRODUCTS',
          "ssn": {
            "code": "102",
            "description": "Oily Residues (Sludge)",
            "marpolCategory": "Annex I",
            "specificationRequired": false
          },
          'berthIdForPickup': null,
          'collector': null,
          'specification': null,
          'quantityOfLastDelivery': 0,
          'quantityToBeDelivered': 0,
          'quantityToBeRetained': 0,
          'maxDedicatedCapacity': 0,
          'quantityToBeGenerated': 0,
          'portForRetainedWaste': null
        },
        {
          'type': 'FILTER_OR_RAGS_BAGS',
          "ssn": {
            "code": "102",
            "description": "Oily Residues (Sludge)",
            "marpolCategory": "Annex I",
            "specificationRequired": false
          },
          'berthIdForPickup': null,
          'collector': null,
          'specification': null,
          'quantityOfLastDelivery': 0,
          'quantityToBeDelivered': 0,
          'quantityToBeRetained': 0,
          'maxDedicatedCapacity': 0,
          'quantityToBeGenerated': 0,
          'portForRetainedWaste': null
        },
        {
          'type': 'WOOD',
          "ssn": {
            "code": "102",
            "description": "Oily Residues (Sludge)",
            "marpolCategory": "Annex I",
            "specificationRequired": false
          },
          'berthIdForPickup': null,
          'collector': null,
          'specification': null,
          'quantityOfLastDelivery': 0,
          'quantityToBeDelivered': 0,
          'quantityToBeRetained': 0,
          'maxDedicatedCapacity': 0,
          'quantityToBeGenerated': 0,
          'portForRetainedWaste': null
        },
        {
          'type': 'OIL_CONTAMINATED_DRUMS',
          "ssn": {
            "code": "102",
            "description": "Oily Residues (Sludge)",
            "marpolCategory": "Annex I",
            "specificationRequired": false
          },
          'berthIdForPickup': null,
          'collector': null,
          'specification': null,
          'quantityOfLastDelivery': 0,
          'quantityToBeDelivered': 0,
          'quantityToBeRetained': 0,
          'maxDedicatedCapacity': 0,
          'quantityToBeGenerated': 0,
          'portForRetainedWaste': null
        },
        {
          'type': 'FISHING_GEAR',
          "ssn": {
            "code": "102",
            "description": "Oily Residues (Sludge)",
            "marpolCategory": "Annex I",
            "specificationRequired": false
          },
          'berthIdForPickup': null,
          'collector': null,
          'specification': null,
          'quantityOfLastDelivery': 0,
          'quantityToBeDelivered': 0,
          'quantityToBeRetained': 0,
          'maxDedicatedCapacity': 0,
          'quantityToBeGenerated': 0,
          'portForRetainedWaste': null
        },
        {
          'type': 'COOKING_OIL',
          "ssn": {
            "code": "102",
            "description": "Oily Residues (Sludge)",
            "marpolCategory": "Annex I",
            "specificationRequired": false
          },
          'berthIdForPickup': null,
          'collector': null,
          'specification': null,
          'quantityOfLastDelivery': 0,
          'quantityToBeDelivered': 0,
          'quantityToBeRetained': 0,
          'maxDedicatedCapacity': 0,
          'quantityToBeGenerated': 0,
          'portForRetainedWaste': null
        },
        {
          'type': 'OPERATIONAL_WASTE',
          "ssn": {
            "code": "102",
            "description": "Oily Residues (Sludge)",
            "marpolCategory": "Annex I",
            "specificationRequired": false
          },
          'berthIdForPickup': null,
          'collector': null,
          'specification': null,
          'quantityOfLastDelivery': 0,
          'quantityToBeDelivered': 0,
          'quantityToBeRetained': 0,
          'maxDedicatedCapacity': 0,
          'quantityToBeGenerated': 0,
          'portForRetainedWaste': null
        },
        {
          'type': 'SEWAGE_WASTE_BLACK',
          "ssn": {
            "code": "102",
            "description": "Oily Residues (Sludge)",
            "marpolCategory": "Annex I",
            "specificationRequired": false
          },
          'berthIdForPickup': null,
          'collector': null,
          'specification': null,
          'quantityOfLastDelivery': 0,
          'quantityToBeDelivered': 0,
          'quantityToBeRetained': 0,
          'maxDedicatedCapacity': 0,
          'quantityToBeGenerated': 0,
          'portForRetainedWaste': null
        },
        {
          'type': 'SEWAGE_WASTE_GREY',
          "ssn": {
            "code": "102",
            "description": "Oily Residues (Sludge)",
            "marpolCategory": "Annex I",
            "specificationRequired": false
          },
          'berthIdForPickup': null,
          'collector': null,
          'specification': null,
          'quantityOfLastDelivery': 0,
          'quantityToBeDelivered': 0,
          'quantityToBeRetained': 0.7,
          'maxDedicatedCapacity': 6.4,
          'quantityToBeGenerated': 0.2,
          'portForRetainedWaste': {
            'name': 'Norrköping',
            'locationUnCode': 'SENRK',
            'countryUnCode': 'SE',
            'euPort': true
          }
        },
        {
          'type': 'DIRTY_BALLASTWATER',
          "ssn": {
            "code": "102",
            "description": "Oily Residues (Sludge)",
            "marpolCategory": "Annex I",
            "specificationRequired": false
          },
          'berthIdForPickup': null,
          'collector': null,
          'specification': null,
          'quantityOfLastDelivery': 0,
          'quantityToBeDelivered': 0,
          'quantityToBeRetained': 0,
          'maxDedicatedCapacity': 0,
          'quantityToBeGenerated': 0,
          'portForRetainedWaste': null
        },
        {
          'type': 'WASHWATER_OIL',
          "ssn": {
            "code": "102",
            "description": "Oily Residues (Sludge)",
            "marpolCategory": "Annex I",
            "specificationRequired": false
          },
          'berthIdForPickup': null,
          'collector': null,
          'specification': null,
          'quantityOfLastDelivery': 0,
          'quantityToBeDelivered': 0,
          'quantityToBeRetained': 0,
          'maxDedicatedCapacity': 0,
          'quantityToBeGenerated': 0,
          'portForRetainedWaste': null
        },
        {
          'type': 'OILY_WASTE_FROM_CLEANING',
          "ssn": {
            "code": "102",
            "description": "Oily Residues (Sludge)",
            "marpolCategory": "Annex I",
            "specificationRequired": false
          },
          'berthIdForPickup': null,
          'collector': null,
          'specification': null,
          'quantityOfLastDelivery': 0,
          'quantityToBeDelivered': 0,
          'quantityToBeRetained': 0,
          'maxDedicatedCapacity': 0,
          'quantityToBeGenerated': 0,
          'portForRetainedWaste': null
        },
        {
          'type': 'OTHER_OILY_WASTE',
          "ssn": {
            "code": "102",
            "description": "Oily Residues (Sludge)",
            "marpolCategory": "Annex I",
            "specificationRequired": false
          },
          'berthIdForPickup': null,
          'collector': null,
          'specification': null,
          'quantityOfLastDelivery': 0,
          'quantityToBeDelivered': 0,
          'quantityToBeRetained': 0,
          'maxDedicatedCapacity': 0,
          'quantityToBeGenerated': 0,
          'portForRetainedWaste': null
        },
        {
          'type': 'CARGO_RESIDUES_OR_WASHWATER',
          "ssn": {
            "code": "102",
            "description": "Oily Residues (Sludge)",
            "marpolCategory": "Annex I",
            "specificationRequired": false
          },
          'berthIdForPickup': null,
          'collector': null,
          'specification': null,
          'quantityOfLastDelivery': 0,
          'quantityToBeDelivered': 0,
          'quantityToBeRetained': 0,
          'maxDedicatedCapacity': 0,
          'quantityToBeGenerated': 0,
          'portForRetainedWaste': null
        },
        {
          'type': 'DIRTY_BALLASTWATER_CHEMICALS',
          "ssn": {
            "code": "102",
            "description": "Oily Residues (Sludge)",
            "marpolCategory": "Annex I",
            "specificationRequired": false
          },
          'berthIdForPickup': null,
          'collector': null,
          'specification': null,
          'quantityOfLastDelivery': 0,
          'quantityToBeDelivered': 0,
          'quantityToBeRetained': 0,
          'maxDedicatedCapacity': 0,
          'quantityToBeGenerated': 0,
          'portForRetainedWaste': null
        },
        {
          'type': 'OTHER_CARGO_RESIDUES',
          "ssn": {
            "code": "102",
            "description": "Oily Residues (Sludge)",
            "marpolCategory": "Annex I",
            "specificationRequired": false
          },
          'berthIdForPickup': null,
          'collector': null,
          'specification': null,
          'quantityOfLastDelivery': 0,
          'quantityToBeDelivered': 0,
          'quantityToBeRetained': 0,
          'maxDedicatedCapacity': 0,
          'quantityToBeGenerated': 0,
          'portForRetainedWaste': null
        },
        {
          'type': 'WASHWATER_DRY_CARGO_HME',
          "ssn": {
            "code": "102",
            "description": "Oily Residues (Sludge)",
            "marpolCategory": "Annex I",
            "specificationRequired": false
          },
          'berthIdForPickup': null,
          'collector': null,
          'specification': null,
          'quantityOfLastDelivery': 0,
          'quantityToBeDelivered': 0,
          'quantityToBeRetained': 0,
          'maxDedicatedCapacity': 0,
          'quantityToBeGenerated': 0,
          'portForRetainedWaste': null
        },
        {
          'type': 'WASHWATER_DRY_CARGO_NON_HME',
          "ssn": {
            "code": "102",
            "description": "Oily Residues (Sludge)",
            "marpolCategory": "Annex I",
            "specificationRequired": false
          },
          'berthIdForPickup': null,
          'collector': null,
          'specification': null,
          'quantityOfLastDelivery': 0,
          'quantityToBeDelivered': 0,
          'quantityToBeRetained': 0,
          'maxDedicatedCapacity': 0,
          'quantityToBeGenerated': 0,
          'portForRetainedWaste': null
        },
        {
          'type': 'DRY_CARGO_RESIDUES_HME',
          "ssn": {
            "code": "102",
            "description": "Oily Residues (Sludge)",
            "marpolCategory": "Annex I",
            "specificationRequired": false
          },
          'berthIdForPickup': null,
          'collector': null,
          'specification': null,
          'quantityOfLastDelivery': 0,
          'quantityToBeDelivered': 0,
          'quantityToBeRetained': 0,
          'maxDedicatedCapacity': 0,
          'quantityToBeGenerated': 0,
          'portForRetainedWaste': null
        },
        {
          'type': 'DRY_CARGO_RESIDUES_NON_HME',
          "ssn": {
            "code": "102",
            "description": "Oily Residues (Sludge)",
            "marpolCategory": "Annex I",
            "specificationRequired": false
          },
          'berthIdForPickup': null,
          'collector': null,
          'specification': null,
          'quantityOfLastDelivery': 0,
          'quantityToBeDelivered': 0,
          'quantityToBeRetained': 0,
          'maxDedicatedCapacity': 0,
          'quantityToBeGenerated': 0,
          'portForRetainedWaste': null
        },
        {
          'type': 'OTHER_CARGO_RESIDUES_NON_TANKERS',
          "ssn": {
            "code": "102",
            "description": "Oily Residues (Sludge)",
            "marpolCategory": "Annex I",
            "specificationRequired": false
          },
          'berthIdForPickup': null,
          'collector': null,
          'specification': null,
          'quantityOfLastDelivery': 0,
          'quantityToBeDelivered': 0,
          'quantityToBeRetained': 0,
          'maxDedicatedCapacity': 0,
          'quantityToBeGenerated': 0,
          'portForRetainedWaste': null
        }
      ]
    },
    'shipStoresDeclaration': {
      'items': {
        'SPIRITS': 4,
        'BEER': 47.5,
        'WINE': 0,
        'OTHER': 0,
        'CIGARETTES': 8400,
        'CIGARS': 0,
        'TOBACCO': 0,
        'FUEL_OIL': 101,
        'LUBRICATING_OIL': 2720,
        'MEAT': 93,
        'NARCOTICS': 0,
        'FIRE_ARMS': 0
      }
    },
    'dangerousGoodsDeclarations': [],
    'paxDeclarationSummaries': [
      {
        'numberOfCrew': 8,
        'numberOfPassengers': 1,
        'numberOfCrewOtherShip': 0,
        'numberOfStowaways': 0,
        "searchAndRescue": null
      }
    ],
    'etaPortAis': null,
    'ignoreEtaPortAis': false,
    'cancelled': false,
    'ataRegisteredByPa': true,
    'visitKnownAtPa': true,
    'dangerousGoodsEnabled': false,
    'sendToSwEnabled': false,
    'declarations': [
      {
        'type': 'VISIT',
        'status': 'ACCEPTED',
        'rejectReasons': null,
        'timeStamp': '2019-08-12T14:38:59.550Z'
      },
      {
        'type': 'WPCS',
        'status': 'DECLARED',
        'rejectReasons': null,
        'timeStamp': '2019-08-12T14:39:12.488Z'
      },
      {
        'type': 'VISIT',
        'status': 'ACCEPTED',
        'rejectReasons': null,
        'timeStamp': '2019-08-13T10:41:17.535Z'
      },
      {
        'type': 'WPCS',
        'status': 'DECLARED',
        'rejectReasons': null,
        'timeStamp': '2019-08-13T10:41:17.621Z'
      },
      {
        'type': 'VISIT',
        'status': 'ACCEPTED',
        'rejectReasons': null,
        'timeStamp': '2019-08-13T11:57:27.693Z'
      },
      {
        'type': 'WPCS',
        'status': 'DECLARED',
        'rejectReasons': null,
        'timeStamp': '2019-08-13T11:57:27.828Z'
      },
      {
        'type': 'SECURITY',
        'status': 'ACCEPTED',
        'rejectReasons': null,
        'timeStamp': '2019-08-13T11:59:13.013Z'
      },
      {
        'type': 'WPCS',
        'status': 'DECLARED',
        'rejectReasons': null,
        'timeStamp': '2019-08-13T11:59:13.098Z'
      },
      {
        'type': 'PAX',
        'status': 'ACCEPTED',
        'rejectReasons': null,
        'timeStamp': '2019-08-13T12:00:19.624Z'
      },
      {
        'type': 'WASTE',
        'status': 'ACCEPTED',
        'rejectReasons': null,
        'timeStamp': '2019-08-13T12:00:49.983Z'
      },
      {
        'type': 'VISIT',
        'status': 'ACCEPTED',
        'rejectReasons': null,
        'timeStamp': '2019-08-13T12:01:42.551Z'
      },
      {
        'type': 'WPCS',
        'status': 'DECLARED',
        'rejectReasons': null,
        'timeStamp': '2019-08-13T12:01:42.738Z'
      },
      {
        'type': 'STO',
        'status': 'ACCEPTED',
        'rejectReasons': null,
        'timeStamp': '2019-08-14T17:10:58.201Z'
      },
      {
        'type': 'WPCS',
        'status': 'DECLARED',
        'rejectReasons': null,
        'timeStamp': '2019-08-14T21:59:49.250Z'
      },
      {
        'type': 'VISIT',
        'status': 'ACCEPTED',
        'rejectReasons': null,
        'timeStamp': '2019-08-16T07:27:46.872Z'
      },
      {
        'type': 'WPCS',
        'status': 'DECLARED',
        'rejectReasons': null,
        'timeStamp': '2019-08-16T07:27:46.999Z'
      },
      {
        'type': 'VISIT',
        'status': 'ACCEPTED',
        'rejectReasons': null,
        'timeStamp': '2019-08-16T07:29:26.496Z'
      },
      {
        'type': 'WPCS',
        'status': 'DECLARED',
        'rejectReasons': null,
        'timeStamp': '2019-08-16T07:29:26.644Z'
      },
      {
        'type': 'VISIT',
        'status': 'ACCEPTED',
        'rejectReasons': null,
        'timeStamp': '2019-08-16T07:30:42.098Z'
      },
      {
        'type': 'WPCS',
        'status': 'DECLARED',
        'rejectReasons': null,
        'timeStamp': '2019-08-16T10:11:25.907Z'
      },
      {
        'type': 'WPCS',
        'status': 'DECLARED',
        'rejectReasons': null,
        'timeStamp': '2019-08-16T11:29:25.142Z'
      },
      {
        'type': 'VISIT',
        'status': 'ACCEPTED',
        'rejectReasons': null,
        'timeStamp': '2019-08-16T23:06:48.787Z'
      },
      {
        'type': 'WPCS',
        'status': 'DECLARED',
        'rejectReasons': null,
        'timeStamp': '2019-08-16T23:06:48.930Z'
      },
      {
        'type': 'WPCS',
        'status': 'DECLARED',
        'rejectReasons': null,
        'timeStamp': '2019-08-17T01:18:10.714Z'
      },
      {
        'type': 'WPCS',
        'status': 'DECLARED',
        'rejectReasons': null,
        'timeStamp': '2019-08-17T04:32:24.443Z'
      }
    ],
    'uploads': {
      'SECURITY': '89bc6a99-370f-4d19-810c-d3b16f956f52',
      'WASTE': 'cb2386d9-b0b5-4842-a5c0-faf3e09e494d',
      'STO': '8ea3e85c-6078-4e4f-b676-991586122c94'
    },
    'berthVisitInfos': {
      '26847537-f534-4121-9da0-1406a67f43b8': <BerthVisitInfo>{
        'harbourMasterInfo': {
          'etd': '2019-08-16T10:00:00Z',
          'orderStatus': 'CONFIRMED',
          'rejectionReason': null,
          'remarks': null,
          'movementOnHold': false
        }
      },
      '2cb32b7a-3b01-4110-9bcd-63915e3e84d9': <BerthVisitInfo>{
        'harbourMasterInfo': {
          'etd': '2019-08-17T01:15:00Z',
          'rejectionReason': null,
          'remarks': null,
          'movementOnHold': false
        }
      }
    },
    'visitStatus': 'DEPARTED'
  },
  departedVisit: {
    'crn': 'departedVisit',
    'additionalViewers': [],
    'copyOfVisit': null,
    'portOfCall': {
      'port': {
        'name': 'Rotterdam',
        'locationUnCode': 'NLRTM',
        'countryUnCode': 'NL',
        'euPort': true
      },
      'portAuthority': {
        'shortName': 'HBRRTM',
        'fullName': 'Havenbedrijf Rotterdam N.V.',
        'emailAddress': 'HCC@portofrotterdam.com',
        'phoneNumber': '+31-10-2521195',
        'faxNumber': '+31-10-2521000',
        'address': 'Wilhelminakade 909',
        'zipCode': '3072 AP',
        'city': 'Rotterdam',
        'countryUnCode': 'NL',
        'countryName': 'Netherlands',
        'customsEORINumber': 'NL24354561',
        'contact': 'Diana van Eijk',
        'portAuthorityId': 'HbR'
      },
      'customsOffice': {
        'name': 'Rotterdam Haven/Kantoor Maasvlakte',
        'unCode': 'NL000396'
      },
      'ataReported': true,
      'atdReported': true,
      'atdPortReported': true,
      'paDeclarationRequired': true,
      'swDeclarationRequired': true,
      'wasteEnabled': true,
      'dangerousGoodsEnabled': true,
      'harbourDuesEnabled': false,
      'orderNauticalServicesEnabled': true,
      'seaBuoy': {
        'name': 'Maascenter',
        'code': 'MC'
      }
    },
    'vessel': {
      'imoCode': '8758457',
      'name': 'DAVE BLACKBURN',
      'radioCallSign': 'WB6043',
      'motUnCode': '1729',
      'motName': 'Passenger ship',
      'summerDeadWeight': 0,
      'maxWidth': 15.84,
      'manoeuvringSpeed': null,
      'flagCountryUnCode': 'US',
      'netTonnage': 1338,
      'grossTonnage': 1338,
      'registrationPlaceUnloCode': 'USMSY',
      'registrationPlaceName': 'New Orleans',
      'registrationDate': '1944-02-01',
      'mmsiNumber': null,
      'helispotPresent': false,
      'fullLength': 110,
      'flagCode': 'USA'
    },
    'owner': {
      'fullName': 'Ship Operator 1',
      'portAuthorityId': 'SO1_R',
      'shortName': 'SO1',
      'emailAddress': 'pietjepuk@yoyo.nl',
      'address': 'Ship Operatorlaan 1',
      'city': 'Ship Operatorstad',
      'countryUnCode': 'NL',
      'countryName': 'Netherlands',
      'zipCode': '1111SO',
      'phoneNumber': '0102522288',
      'faxNumber': null,
      'contact': 'test centrum',
      'customsEORINumber': 'NL123456789'
    },
    'declarant': {
      'fullName': 'Back Office 1',
      'portAuthorityId': 'BO1_R',
      'shortName': 'BO1',
      'emailAddress': 'pietjepuk@yoyo.nl',
      'address': 'Back Officelaan 1',
      'city': 'Back Officestad',
      'countryUnCode': 'NL',
      'countryName': 'Netherlands',
      'zipCode': '1111SO',
      'phoneNumber': '0102522288',
      'faxNumber': null,
      'contact': 'test centrum',
      'customsEORINumber': 'NL123456789'
    },
    'financialDeclarant': {
      'fullName': 'Ship Operator 1',
      'portAuthorityId': 'SO1_R',
      'shortName': 'SO1',
      'emailAddress': 'pietjepuk@yoyo.nl',
      'address': 'Ship Operatorlaan 1',
      'city': 'Ship Operatorstad',
      'countryUnCode': 'NL',
      'countryName': 'Netherlands',
      'zipCode': '1111SO',
      'phoneNumber': '0102522288',
      'faxNumber': null,
      'contact': 'test centrum',
      'customsEORINumber': 'NL123456789'
    },
    'nominatedFinancialDeclarant': null,
    'orderNauticalServices': true,
    'creditNumberHolderSameAsDeclarant': true,
    'cargoDeclarants': [
      {
        'fullName': 'Ship Operator 1',
        'portAuthorityId': 'SO1_R',
        'shortName': 'SO1',
        'emailAddress': 'd.reijnders@portbase.com',
        'address': 'Ship Operatorlaan 1',
        'city': 'Ship Operatorstad',
        'countryUnCode': 'NL',
        'countryName': 'Netherlands',
        'zipCode': '1111SO',
        'phoneNumber': '0102522288',
        'faxNumber': null,
        'contact': 'TC',
        'customsEORINumber': 'NL123456789'
      }
    ],
    'allowedViewers': [
      {
        'fullName': 'Ship Operator 1',
        'portAuthorityId': 'SO1_R',
        'shortName': 'SO1',
        'emailAddress': 'pietjepuk@yoyo.nl',
        'address': 'Ship Operatorlaan 1',
        'city': 'Ship Operatorstad',
        'countryUnCode': 'NL',
        'countryName': 'Netherlands',
        'zipCode': '1111SO',
        'phoneNumber': '0102522288',
        'faxNumber': null,
        'contact': 'test centrum',
        'customsEORINumber': 'NL123456789'
      },
      {
        'fullName': 'Ship Operator 1',
        'portAuthorityId': 'SO1_R',
        'shortName': 'SO1',
        'emailAddress': 'pietjepuk@yoyo.nl',
        'address': 'Ship Operatorlaan 1',
        'city': 'Ship Operatorstad',
        'countryUnCode': 'NL',
        'countryName': 'Netherlands',
        'zipCode': '1111SO',
        'phoneNumber': '0102522288',
        'faxNumber': null,
        'contact': 'test centrum',
        'customsEORINumber': 'NL123456789'
      }
    ],
    'visitDeclaration': {
      'clientReferenceNumber': '7',
      'etaFirstEntryEu': '2018-11-08T11:00:00Z',
      'arrivalVoyage': {
        'voyageNumber': '1234567',
        'carrier': {
          'customsId': 'NL100000897',
          'name': 'Carrier 1',
          'scacCode': 'CARA',
          'smdgCode': 'CAR'
        }
      },
      'previousPorts': [
        {
          'id': '7239cd11943846a78869f7260f0fc219',
          'arrival': '2018-11-07T11:00:00Z',
          'departure': '2018-11-08T10:00:00Z',
          'port': {
            'name': 'Barcelona',
            'locationUnCode': 'ESBCN',
            'countryUnCode': 'ES',
            'euPort': true
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'ESBCN-0001',
                'name': 'Barcelona, Spain  AUTOTERMINAL, S.A.'
              },
              'arrivalDate': '2018-11-07',
              'departureDate': '2018-11-08',
              'securityLevel': 'SL1',
              'additionalSecurityMeasures': 'Measures 1'
            }
          ]
        },
        {
          'id': '39af59bb1dd344339f84d12c4533bd03',
          'arrival': '2018-11-06T11:00:00Z',
          'departure': '2018-11-07T11:00:00Z',
          'port': {
            'name': 'Tunisi',
            'locationUnCode': 'ITTUN',
            'countryUnCode': 'IT',
            'euPort': true
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'ITTUN-9999',
                'name': 'UNKNOWN'
              },
              'arrivalDate': '2018-11-06',
              'departureDate': '2018-11-07',
              'securityLevel': 'SL2',
              'additionalSecurityMeasures': 'Measures 2'
            }
          ]
        },
        {
          'id': '8a49e9d4d39647188bb8bd3d57dd4fa7',
          'arrival': '2018-11-05T11:00:00Z',
          'departure': '2018-11-06T11:00:00Z',
          'port': {
            'name': 'Barcelona',
            'locationUnCode': 'ESBCN',
            'countryUnCode': 'ES',
            'euPort': true
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'ESBCN-0003',
                'name': 'Barcelona, Spain  CENTRO DE CONTROL'
              },
              'arrivalDate': '2018-11-05',
              'departureDate': '2018-11-06',
              'securityLevel': 'SL3',
              'additionalSecurityMeasures': 'Measures 3'
            }
          ]
        },
        {
          'id': '2f4ae45bc63643de9fe7678f8021940d',
          'arrival': '2018-11-04T11:00:00Z',
          'departure': '2018-11-05T11:00:00Z',
          'port': {
            'name': 'Tunisi',
            'locationUnCode': 'ITTUN',
            'countryUnCode': 'IT',
            'euPort': true
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'ITTUN-9999',
                'name': 'UNKNOWN'
              },
              'arrivalDate': '2018-11-04',
              'departureDate': '2018-11-05',
              'securityLevel': 'SL1',
              'additionalSecurityMeasures': 'Measures 4'
            }
          ]
        },
        {
          'id': 'bb63bcf604c44adc8aada67c61a367c2',
          'arrival': '2018-11-03T11:00:00Z',
          'departure': '2018-11-04T11:00:00Z',
          'port': {
            'name': 'Barcelona',
            'locationUnCode': 'ESBCN',
            'countryUnCode': 'ES',
            'euPort': true
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'ESBCN-0005',
                'name': 'Barcelona, Spain  COMPAÑÍA LOGISTICA DE HIDROCARBUROS (CLH)'
              },
              'arrivalDate': '2018-11-03',
              'departureDate': '2018-11-04',
              'securityLevel': 'SL2',
              'additionalSecurityMeasures': 'Measures 5'
            }
          ]
        },
        {
          'id': '24a4865f6a494d2abc9c8d477705ad5c',
          'arrival': '2018-11-02T11:00:00Z',
          'departure': '2018-11-03T11:00:00Z',
          'port': {
            'name': 'Tunisi',
            'locationUnCode': 'ITTUN',
            'countryUnCode': 'IT',
            'euPort': true
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'ITTUN-9999',
                'name': 'UNKNOWN'
              },
              'arrivalDate': '2018-11-02',
              'departureDate': '2018-11-03',
              'securityLevel': 'SL3',
              'additionalSecurityMeasures': 'Measures 6'
            }
          ]
        },
        {
          'id': 'e4f33384c9f94439855c194b8d5ededb',
          'arrival': '2018-11-01T11:00:00Z',
          'departure': '2018-11-02T11:00:00Z',
          'port': {
            'name': 'Barcelona',
            'locationUnCode': 'ESBCN',
            'countryUnCode': 'ES',
            'euPort': true
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'ESBCN-0007',
                'name': 'Barcelona, Spain  CORPORACIÓN DE PRÁCTICOS DEL PUERTO DE BARCELONA, S.L.P.'
              },
              'arrivalDate': '2018-11-01',
              'departureDate': '2018-11-02',
              'securityLevel': 'SL1',
              'additionalSecurityMeasures': 'Measures 7'
            }
          ]
        },
        {
          'id': 'a51f713703c94ce885487c6645a1c76e',
          'arrival': '2018-10-31T11:00:00Z',
          'departure': '2018-11-01T11:00:00Z',
          'port': {
            'name': 'Tunisi',
            'locationUnCode': 'ITTUN',
            'countryUnCode': 'IT',
            'euPort': true
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'ITTUN-9999',
                'name': 'UNKNOWN'
              },
              'arrivalDate': '2018-10-31',
              'departureDate': '2018-11-01',
              'securityLevel': 'SL2',
              'additionalSecurityMeasures': 'Measures 8'
            }
          ]
        },
        {
          'id': '0ffbad66973446f4b354c1f3d29a294d',
          'arrival': '2018-10-30T11:00:00Z',
          'departure': '2018-10-31T11:00:00Z',
          'port': {
            'name': 'Barcelona',
            'locationUnCode': 'ESBCN',
            'countryUnCode': 'ES',
            'euPort': true
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'ESBCN-0009',
                'name': 'Barcelona, Spain  DECAL ESPAÑA S.A.'
              },
              'arrivalDate': '2018-10-30',
              'departureDate': '2018-10-31',
              'securityLevel': 'SL3',
              'additionalSecurityMeasures': 'Measures 9'
            }
          ]
        },
        {
          'id': '2fe3987117624e4e982eadb5fcf6b30f',
          'arrival': '2018-10-29T11:00:00Z',
          'departure': '2018-10-30T11:00:00Z',
          'port': {
            'name': 'Tunisi',
            'locationUnCode': 'ITTUN',
            'countryUnCode': 'IT',
            'euPort': true
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'ITTUN-9999',
                'name': 'UNKNOWN'
              },
              'arrivalDate': '2018-10-29',
              'departureDate': '2018-10-30',
              'securityLevel': 'SL2',
              'additionalSecurityMeasures': 'Measures 10'
            }
          ]
        },
        {
          'id': '14b77c47a3b24a6bb058d9998f89f213',
          'arrival': '2018-10-28T11:00:00Z',
          'departure': '2018-10-29T11:00:00Z',
          'port': {
            'name': 'Barcelona',
            'locationUnCode': 'ESBCN',
            'countryUnCode': 'ES',
            'euPort': true
          },
          'portFacilityVisits': []
        },
        {
          'id': '62ba903b912543b18438f132d2409263',
          'arrival': '2018-10-27T11:00:00Z',
          'departure': '2018-10-28T11:00:00Z',
          'port': {
            'name': 'Tunisi',
            'locationUnCode': 'ITTUN',
            'countryUnCode': 'IT',
            'euPort': true
          },
          'portFacilityVisits': []
        }
      ],
      'portVisit': {
        'intention': 'REQUEST_FOR_ENTRY',
        'pilotStation': {
          'code': 'MC',
          'name': 'Maascenter',
          'atSea': true
        },
        'entryPoint': {
          'code': 'LL',
          'name': 'Lage Licht'
        },
        'etaPort': '2018-11-08T11:00:00Z',
        'firstMovement': {
          'vesselDraft': 6.0,
          'vesselMasterName': 'Henk',
          'numberOfCrew': 20,
          'numberOfPassengers': 6,
          'cargo': 'OUT_OF_GAUGE',
          'pilotExemption': {
            'holderName': '',
            'number': '16'
          },
          'offStandardBeam': {
            'value': 4.5,
            'remarks': ''
          },
          'pilotService': {
            'required': false
          }
        },
        'passingThroughTugboats': null,
        'defectTypes': [
          'DEN',
          'DWE',
          'TOW'
        ],
        'berthVisits': [
          {
            'id': '1abbb8789fe94b5c8adad25550a0a6b1',
            'berth': {
              'name': 'HOLLAND AMERIKAKADE',
              'code': 'R1243',
              'terminalCode': '52090469',
              'berthGroupCode': 'HOLLAND AMERIKAKADE',
              'buoy': false,
              'partyToNotify': null
            },
            'eta': '2018-11-09T11:00:00Z',
            'ata': null,
            'etd': '2018-11-09T22:00:00Z',
            'atd': null,
            'mooring': 'PORT_SIDE',
            'remarks': null,
            'visitPurposes': [
              'CARGO_TANK_CLEANING',
              'CUSTOMS_CLEARANCE',
              'DISPOSAL_OF_WASTE',
              'LAID_UP',
              'LOADING'
            ],
            'bollardFrom': null,
            'bollardTo': null,
            'tugboatAtArrival': {
              'required': true,
              'serviceProvider': {
                'name': 'Gepke',
                'portAuthorityId': 'VERSCHOOR'
              },
              'requiredQuantity': 'SIX',
              'remarks': null
            },
            'boatmenAtArrival': {
              'required': true,
              'serviceProvider': {
                'name': 'V.i.o.s.',
                'portAuthorityId': 'VIOS'
              },
              'remarks': null
            },
            'boatmenAtDeparture': {
              'required': true,
              'serviceProvider': {
                'name': 'V.i.o.s.',
                'portAuthorityId': 'VIOS'
              },
              'remarks': null
            },
            'tugboatAtDeparture': {
              'required': true,
              'serviceProvider': {
                'name': 'Gepke',
                'portAuthorityId': 'VERSCHOOR'
              },
              'requiredQuantity': 'SIX',
              'remarks': null
            },
            'nextMovement': {
              'vesselDraft': 7.0,
              'vesselMasterName': 'Piet',
              'numberOfCrew': 21,
              'numberOfPassengers': 7,
              'cargo': 'OUT_OF_GAUGE',
              'pilotExemption': {
                'holderName': '',
                'number': '16'
              },
              'offStandardBeam': {
                'value': 4.5,
                'remarks': ''
              },
              'pilotService': null
            }
          },
          {
            'id': 'c1324bb553b2451d907e63e48d74c3e1',
            'berth': {
              'name': 'DORDR PWA KADE',
              'code': 'D4358',
              'terminalCode': '51433242',
              'berthGroupCode': 'DORDR PWA KADE',
              'buoy': false,
              'partyToNotify': null
            },
            'eta': '2018-11-10T11:00:00Z',
            'ata': null,
            'etd': '2018-11-10T22:00:00Z',
            'atd': null,
            'mooring': null,
            'remarks': null,
            'visitPurposes': [
              'DISCHARGE_CRUDE_OIL',
              'TAKING_SUPPLIES'
            ],
            'bollardFrom': null,
            'bollardTo': null,
            'tugboatAtArrival': {
              'required': false,
              'serviceProvider': {
                'name': 'Gepke',
                'portAuthorityId': 'VERSCHOOR'
              },
              'requiredQuantity': null,
              'remarks': null
            },
            'boatmenAtArrival': {
              'required': true,
              'serviceProvider': {
                'name': 'V.i.o.s.',
                'portAuthorityId': 'VIOS'
              },
              'remarks': null
            },
            'boatmenAtDeparture': null,
            'tugboatAtDeparture': {
              'required': true,
              'serviceProvider': {
                'name': 'Gepke',
                'portAuthorityId': 'VERSCHOOR'
              },
              'requiredQuantity': 'FIVE',
              'remarks': null
            },
            'nextMovement': {
              'vesselDraft': 8.0,
              'vesselMasterName': 'Henk',
              'numberOfCrew': 20,
              'numberOfPassengers': 6,
              'cargo': 'BALLAST_CONDITION',
              'pilotExemption': null,
              'offStandardBeam': {
                'value': 12.8,
                'remarks': 'No remarks'
              },
              'pilotService': {
                'required': true,
                'serviceProvider': {
                  'name': 'Pilot 1',
                  'portAuthorityId': 'PIL1'
                },
                'remarks': null
              }
            }
          },
          {
            'id': 'abb42b0d775d44fca7e2d392d16ff340',
            'berth': {
              'name': 'PET 3 B 61',
              'code': 'R61',
              'terminalCode': '47644472',
              'berthGroupCode': 'PET 3 B 61',
              'buoy': true,
              'partyToNotify': null
            },
            'eta': '2018-11-11T11:00:00Z',
            'ata': '2018-11-11T11:13:00Z',
            'etd': '2018-11-11T22:00:00Z',
            'atd': '2018-11-11T21:43:00Z',
            'mooring': null,
            'remarks': null,
            'visitPurposes': [],
            'bollardFrom': 16.2,
            'bollardTo': 20.2,
            'tugboatAtArrival': {
              'required': false,
              'serviceProvider': {
                'name': 'Gepke',
                'portAuthorityId': 'VERSCHOOR'
              },
              'requiredQuantity': null,
              'remarks': null
            },
            'boatmenAtArrival': {
              'required': true,
              'serviceProvider': {
                'name': 'V.i.o.s.',
                'portAuthorityId': 'VIOS'
              },
              'remarks': null
            },
            'boatmenAtDeparture': null,
            'tugboatAtDeparture': {
              'required': false,
              'serviceProvider': {
                'name': 'Smit havensleepdienst B.V.',
                'portAuthorityId': 'SMIT'
              },
              'requiredQuantity': null,
              'remarks': null
            },
            'nextMovement': {
              'vesselDraft': 12.5,
              'vesselMasterName': 'Henk',
              'numberOfCrew': 20,
              'numberOfPassengers': 6,
              'cargo': 'BALLAST_CONDITION',
              'pilotExemption': {
                'holderName': 'Exemption holder 1',
                'number': '12345'
              },
              'offStandardBeam': {
                'value': 12.8,
                'remarks': 'No remarks'
              },
              'pilotService': null
            }
          }
        ],
        'dangerousGoodsLoading': true,
        'dangerousGoodsDischarge': false,
        'dangerousGoodsTransit': true,
        'possibleAnchorage': true,
        'vesselInspectionRequired': true,
        'shipConfiguration': 'DOUBLE_HULL_TANKER',
        'exitPoint': {
          'code': 'OM',
          'name': 'Oude Maas',
          'atSea': false
        },
        'etdPort': '2018-11-13T11:00:00Z',
        'atdPort': '2018-11-18T11:00:00Z'
      },
      'departureVoyage': {
        'voyageNumber': '1234568',
        'carrier': {
          'customsId': '12345',
          'name': null,
          'scacCode': null,
          'smdgCode': null
        }
      },
      'nextPorts': [
        {
          'id': 'f94034d2d4624e778775625184f22091',
          'arrival': '2018-11-14T11:00:00Z',
          'departure': '2018-11-15T11:00:00Z',
          'port': {
            'name': 'Antwerpen',
            'locationUnCode': 'BEANR',
            'countryUnCode': 'BE',
            'euPort': true
          },
          'customsOffice': null
        },
        {
          'id': 'd8d6ca9c2fa44747b4d8f111a75e11c7',
          'arrival': '2018-11-22T11:00:00Z',
          'departure': '2018-11-23T11:00:00Z',
          'port': {
            'name': 'Taranto',
            'locationUnCode': 'ITTAR',
            'countryUnCode': 'IT',
            'euPort': true
          },
          'customsOffice': {
            'name': 'S.O.T. AEROPORTO DELLO STRETTO',
            'unCode': 'IT087101'
          }
        },
        {
          'id': 'f4e5100bad6d4aa4a2cff0412bf963e0',
          'arrival': '2018-11-24T11:00:00Z',
          'departure': '2018-11-27T11:00:00Z',
          'port': {
            'name': 'El Iskandariya (= Alexandria)',
            'locationUnCode': 'EGALY',
            'countryUnCode': 'EG',
            'euPort': false
          },
          'customsOffice': null
        }
      ]
    },
    'nextVisitDeclaration': [],
    'securityDeclaration': {
      'securityReportRequired': 'YES',
      'companySecurityOfficer': {
        'name': 'Jansen',
        'email': 'Jansen@hotmail.com',
        'phoneNumber': '123456789'
      },
      'currentSecurityLevel': 'SL1',
      'approvedSspDocumentOnBoard': false,
      'isscSecurityDocument': {
        'availability': 'YES',
        'expiryDate': '2017-01-01',
        'reasonNoValidIsscOnBoard': 'ik ben een gevaarlijke piraat',
        'issuer': {
          'code': '1',
          'name': 'AMERICAN BUREAU'
        }
      },
      'shipToShipActivities': [
        {
          'location': 'Ergens 1',
          'type': 'CARGO_TANK_CLEANING',
          'startDate': '2018-11-07',
          'endDate': '2018-11-08',
          'compliantWithSsp': true,
          'securityMeasures': 'Measures 1'
        },
        {
          'location': 'Ergens 2',
          'type': 'CHANGING_CREW',
          'startDate': '2018-11-06',
          'endDate': '2018-11-07',
          'compliantWithSsp': false,
          'securityMeasures': 'Measures 2'
        },
        {
          'location': 'Ergens 3',
          'type': 'DE_GASSING',
          'startDate': '2018-11-05',
          'endDate': '2018-11-06',
          'compliantWithSsp': true,
          'securityMeasures': 'Measures 3'
        },
        {
          'location': 'Ergens 4',
          'type': 'CARGO_TANK_CLEANING',
          'startDate': '2018-11-04',
          'endDate': '2018-11-05',
          'compliantWithSsp': false,
          'securityMeasures': 'Measures 4'
        },
        {
          'location': 'Ergens 5',
          'type': 'CHANGING_CREW',
          'startDate': '2018-11-03',
          'endDate': '2018-11-04',
          'compliantWithSsp': true,
          'securityMeasures': 'Measures 5'
        },
        {
          'location': 'Ergens 6',
          'type': 'DE_GASSING',
          'startDate': '2018-11-02',
          'endDate': '2018-11-03',
          'compliantWithSsp': false,
          'securityMeasures': 'Measures 6'
        },
        {
          'location': 'Ergens 7',
          'type': 'CARGO_TANK_CLEANING',
          'startDate': '2018-11-01',
          'endDate': '2018-11-02',
          'compliantWithSsp': true,
          'securityMeasures': 'Measures 7'
        },
        {
          'location': 'Ergens 8',
          'type': 'CHANGING_CREW',
          'startDate': '2018-10-31',
          'endDate': '2018-11-01',
          'compliantWithSsp': false,
          'securityMeasures': 'Measures 8'
        },
        {
          'location': 'Ergens 9',
          'type': 'DE_GASSING',
          'startDate': '2018-10-30',
          'endDate': '2018-10-31',
          'compliantWithSsp': true,
          'securityMeasures': 'Measures 9'
        }
      ],
      'securityRelatedMatter': 'Any security related matter to report'
    },
    'wasteDeclaration': null,
    'shipStoresDeclaration': null,
    'paxDeclarationSummaries': null,
    'uploads': {},
    'cancelled': false,
    'ataRegisteredByPa': false,
    'declarations': [
      {
        'type': 'WPCS',
        'status': 'DECLARED',
        'rejectReasons': null,
        'timeStamp': '2018-12-20T11:12:48.060Z'
      },
      {
        'type': 'SECURITY',
        'status': 'ACCEPTED',
        'rejectReasons': null,
        'timeStamp': '2018-12-20T11:13:13.063Z'
      },
      {
        'type': 'VISIT',
        'status': 'ACCEPTED',
        'rejectReasons': null,
        'timeStamp': '2018-12-24T10:31:08.960Z'
      },
      {
        'type': 'STO',
        'status': 'ACCEPTED',
        'rejectReasons': null,
        'timeStamp': '2018-12-24T10:31:08.960Z'
      },
      {
        'type': 'WASTE',
        'status': 'ACCEPTED',
        'rejectReasons': null,
        'timeStamp': '2018-12-24T10:31:08.960Z'
      },
      {
        'type': 'PAX',
        'status': 'ACCEPTED',
        'rejectReasons': null,
        'timeStamp': '2018-12-24T10:31:08.960Z'
      }
    ],
    'berthVisitInfos': null,
    'visitStatus': 'DEPARTED',
    'expected': false,
    'importDeclarations': [
      {
        'cargoDeclarant': {
          'fullName': 'Ship Operator 1',
          'portAuthorityId': 'SO1_R',
          'shortName': 'SO1',
          'emailAddress': 'd.reijnders@portbase.com',
          'address': 'Ship Operatorlaan 1',
          'city': 'Ship Operatorstad',
          'countryUnCode': 'NL',
          'countryName': 'Netherlands',
          'zipCode': '1111SO',
          'phoneNumber': '0102522288',
          'faxNumber': null,
          'contact': 'TC',
          'customsEORINumber': 'NL123456789'
        },
        'consignments': [{
          'consignmentNumber': '12334',
          'portOfLoading': {'name': 'Barcelona', 'locationUnCode': 'ESBCN', 'countryUnCode': 'ES', 'euPort': true},
          'portOfDischarge': {'name': 'Rotterdam', 'locationUnCode': 'NLRTM', 'countryUnCode': 'NL', 'euPort': true},
          'actualDeparture': '2018-11-08T11:00:00Z',
          'declarations': [],
          'partiesToNotify': [],
          'goodsItems': [{
            'placements': [{
              'equipmentNumber': 'ABCD1234567',
              'numberOfPackages': '1',
              'grossWeight': 1000
            }],
            'producedDocuments': [{'type': 'N703', 'id': '209021', 'description': 'Live animalsss'}],
            'marksAndNumbers': ['90210'],
            'itemNumber': 1,
            'description': 'Cows and other animals like pigs and even more stuff like donkeys',
            'classification': {'code': '0100', 'description': 'Live animals'},
            'minimumTemperature': 10,
            'maximumTemperature': 25,
            'grossWeight': 1000,
            'numberOfOuterPackages': '1',
            'outerPackageType': {'code': 'BK', 'name': 'Basket '}
          }],
          'partShipment': false,
          'movementType': 'DOOR_TO_DOOR',
          'customsProcess': 'EMPTY_RETURN_PACKAGING',
          'movementReferenceNumbers': ['219820198'],
          'consignor': {
            'id': 'test',
            'name': 'Crazy Pete',
            'address': 'partyStreet 123',
            'city': 'Party Ville',
            'countryCode': 'FR',
            'eoriNumber': 'eoriForParty',
            'zipCode': 'party321'
          },
          'consignee': {'eoriNumber': '209201', 'name': 'Pickle Rick'},
          'customsStatus': 'EU_COMMUNITY_GOODS',
          'terminal': {
            'terminalName': 'HOLLAND AMERIKAKADE',
            'terminalCode': '52090469',
            'organisationShortName': 'ECTDELTA'
          }
        }],
        'containers': [{
          'number': 'ABCD1234567',
          'portOfLoading': {'name': 'Barcelona', 'locationUnCode': 'ESBCN', 'countryUnCode': 'ES', 'euPort': true},
          'portOfDischarge': {'name': 'Rotterdam', 'locationUnCode': 'NLRTM', 'countryUnCode': 'NL', 'euPort': true},
          'actualDeparture': '2018-11-08T11:00:00Z',
          'terminal': {
            'terminalName': 'HOLLAND AMERIKAKADE',
            'terminalCode': '52090469',
            'organisationShortName': 'ECTDELTA'
          },
          'empty': false,
          'shippersOwned': false,
          'type': 'CONTAINER',
          'sizeType': {'code': 'SIZE1', 'name': 'Big size'},
          'containerOperator': {
            'name': 'Carrier 1',
            'customsId': 'carrier1Id',
            'scacCode': 'scac1',
            'smdgCode': 'smdg1'
          },
          'dischargeResult': {
            'type': 'TRAILER',
            'sizeType': {'code': 'SIZ3', 'name': 'Big size3'},
            'containerOperator': {
              'name': 'Carrier 2',
              'customsId': 'carrier2Id',
              'scacCode': 'scac2',
              'smdgCode': 'smdg2'
            },
            'bookingReference': 'hondenvriend',
            'empty': true,
            'tareWeight': '22',
            'extra': true
          }
        }],
        'discharges': [
          {
            'number': 'ABCD1234567',
            'portOfLoading': {'name': 'Barcelona', 'locationUnCode': 'ESBCN', 'countryUnCode': 'ES', 'euPort': true},
            'terminal': {
              'terminalName': 'HOLLAND AMERIKAKADE',
              'terminalCode': '52090469',
              'organisationShortName': 'ECTDELTA'
            },
            'type': 'TRAILER',
            'sizeType': {'code': 'SIZ3', 'name': 'Big size3'},
            'containerOperator': {
              'name': 'Carrier 2',
              'customsId': 'carrier2Id',
              'scacCode': 'scac2',
              'smdgCode': 'smdg2'
            },
            'bookingReference': 'hondenvriend',
            'empty': true,
            'tareWeight': '22',
          },
          <DischargeResult>{
            'number': 'ABCD1234568',
            'portOfLoading': {'name': 'Barcelona', 'locationUnCode': 'ESBCN', 'countryUnCode': 'ES', 'euPort': true},
            'terminal': {'terminalCode': '52090469', 'terminalName': 'yomama', 'organisationShortName': 'ECTDELTA'},
            'empty': false,
            'shippersOwned': false,
            'type': 'CONTAINER',
            'sizeType': {'code': 'SIZE1', 'name': 'Big size'},
            'containerOperator': {
              'name': 'Carrier 1',
              'customsId': 'carrier1Id',
              'scacCode': 'scac1',
              'smdgCode': 'smdg1'
            },
            temperature: 10,
            actualTimeOfHandling: '2018-11-08T11:00:00Z',
            grossWeight: 100
          },
          <DischargeResult>{
            'number': 'ABCD1234569',
            'portOfLoading': {'name': 'Barcelona', 'locationUnCode': 'ESBCN', 'countryUnCode': 'ES', 'euPort': true},
            'terminal': {'terminalCode': '52090469', 'terminalName': 'yomama', 'organisationShortName': 'ECTDELTA'},
            'empty': true,
            'shippersOwned': false,
            'type': 'CONTAINER',
            'sizeType': {'code': 'SIZE1', 'name': 'Big size'},
            'containerOperator': {
              'name': 'Carrier 1',
              'customsId': 'carrier1Id',
              'scacCode': 'scac1',
              'smdgCode': 'smdg1'
            },
            temperature: null,
            actualTimeOfHandling: '2018-11-08T11:00:00Z',
            grossWeight: 50
          }
        ],
        'declarations': [
          {
            friendlyId: '27BGHtgoeOkTJlI8f8Sxql',
            id: 'ESBCN',
            rejectReasons: null,
            status: 'DECLARED',
            timeStamp: '2020-09-10T01:37:00.896Z',
            type: 'SDT'
          },
          {
            friendlyId: '27BGHtgoeOkTJlI8f8Sxq2',
            id: 'ESBCN',
            rejectReasons: null,
            status: 'DECLARED',
            timeStamp: '2020-09-10T01:37:00.896Z',
            type: 'SDT'
          },
          {
            friendlyId: '27BGHtgoeOkTJlI8f8Sxq3',
            id: '12334',
            rejectReasons: null,
            status: 'DECLARED',
            timeStamp: '2020-09-10T01:37:00.896Z',
            type: 'ENS'
          },
          {
            friendlyId: '27BGHtgoeOkTJlI8f8Sxq4',
            id: 'fake',
            rejectReasons: null,
            status: 'DECLARED',
            timeStamp: '2020-09-10T01:37:00.896Z',
            type: 'ENS'
          }],
        'consignmentDifferences': <ConsignmentDifference[]>[{
          'consignmentNumber': '12334',
          'timestamp': '2018-11-08T11:00:00Z',
          'portOfLoadingUnCode': 'ESBCN',
          'itemDifferences': [
            {'itemNumber': 1, 'remainingPackages': 1, 'remainingWeight': 1000}
          ]
        }],
        clearedManifestIds: [],
        incomingMessages: []
      }],
  },
  departedLaterVisit: {
    'crn': 'departedLaterVisit',
    'additionalViewers': [],
    'copyOfVisit': null,
    'portOfCall': {
      'port': {
        'name': 'Rotterdam',
        'locationUnCode': 'NLRTM',
        'countryUnCode': 'NL',
        'euPort': true
      },
      'portAuthority': {
        'shortName': 'HBRRTM',
        'fullName': 'Havenbedrijf Rotterdam N.V.',
        'emailAddress': 'HCC@portofrotterdam.com',
        'phoneNumber': '+31-10-2521195',
        'faxNumber': '+31-10-2521000',
        'address': 'Wilhelminakade 909',
        'zipCode': '3072 AP',
        'city': 'Rotterdam',
        'countryUnCode': 'NL',
        'countryName': 'Netherlands',
        'customsEORINumber': 'NL24354561',
        'contact': 'Diana van Eijk',
        'portAuthorityId': 'HbR'
      },
      'customsOffice': {
        'name': 'Rotterdam Haven/Kantoor Maasvlakte',
        'unCode': 'NL000396'
      },
      'ataReported': true,
      'atdReported': true,
      'atdPortReported': true,
      'paDeclarationRequired': true,
      'swDeclarationRequired': true,
      'wasteEnabled': true,
      'dangerousGoodsEnabled': true,
      'harbourDuesEnabled': false,
      'orderNauticalServicesEnabled': true,
      'seaBuoy': {
        'name': 'Maascenter',
        'code': 'MC'
      }
    },
    'vessel': {
      'imoCode': '8758457',
      'name': 'DAVE BLACKBURN',
      'radioCallSign': 'WB6043',
      'motUnCode': '1729',
      'motName': 'Passenger ship',
      'summerDeadWeight': 0,
      'maxWidth': 15.84,
      'manoeuvringSpeed': null,
      'flagCountryUnCode': 'US',
      'netTonnage': 1338,
      'grossTonnage': 1338,
      'registrationPlaceUnloCode': 'USMSY',
      'registrationPlaceName': 'New Orleans',
      'registrationDate': '1944-02-01',
      'mmsiNumber': null,
      'helispotPresent': false,
      'fullLength': 110,
      'flagCode': 'USA'
    },
    'owner': {
      'fullName': 'Ship Operator 1',
      'portAuthorityId': 'SO1_R',
      'shortName': 'SO1',
      'emailAddress': 'pietjepuk@yoyo.nl',
      'address': 'Ship Operatorlaan 1',
      'city': 'Ship Operatorstad',
      'countryUnCode': 'NL',
      'countryName': 'Netherlands',
      'zipCode': '1111SO',
      'phoneNumber': '0102522288',
      'faxNumber': null,
      'contact': 'test centrum',
      'customsEORINumber': 'NL123456789'
    },
    'declarant': {
      'fullName': 'Back Office 1',
      'portAuthorityId': 'BO1_R',
      'shortName': 'BO1',
      'emailAddress': 'pietjepuk@yoyo.nl',
      'address': 'Back Officelaan 1',
      'city': 'Back Officestad',
      'countryUnCode': 'NL',
      'countryName': 'Netherlands',
      'zipCode': '1111SO',
      'phoneNumber': '0102522288',
      'faxNumber': null,
      'contact': 'test centrum',
      'customsEORINumber': 'NL123456789'
    },
    'financialDeclarant': {
      'fullName': 'Ship Operator 1',
      'portAuthorityId': 'SO1_R',
      'shortName': 'SO1',
      'emailAddress': 'pietjepuk@yoyo.nl',
      'address': 'Ship Operatorlaan 1',
      'city': 'Ship Operatorstad',
      'countryUnCode': 'NL',
      'countryName': 'Netherlands',
      'zipCode': '1111SO',
      'phoneNumber': '0102522288',
      'faxNumber': null,
      'contact': 'test centrum',
      'customsEORINumber': 'NL123456789'
    },
    'nominatedFinancialDeclarant': null,
    'orderNauticalServices': true,
    'creditNumberHolderSameAsDeclarant': true,
    'cargoDeclarants': [
      {
        'fullName': 'Ship Operator 1',
        'portAuthorityId': 'SO1_R',
        'shortName': 'SO1',
        'emailAddress': 'd.reijnders@portbase.com',
        'address': 'Ship Operatorlaan 1',
        'city': 'Ship Operatorstad',
        'countryUnCode': 'NL',
        'countryName': 'Netherlands',
        'zipCode': '1111SO',
        'phoneNumber': '0102522288',
        'faxNumber': null,
        'contact': 'TC',
        'customsEORINumber': 'NL123456789'
      }
    ],
    'allowedViewers': [
      {
        'fullName': 'Ship Operator 1',
        'portAuthorityId': 'SO1_R',
        'shortName': 'SO1',
        'emailAddress': 'pietjepuk@yoyo.nl',
        'address': 'Ship Operatorlaan 1',
        'city': 'Ship Operatorstad',
        'countryUnCode': 'NL',
        'countryName': 'Netherlands',
        'zipCode': '1111SO',
        'phoneNumber': '0102522288',
        'faxNumber': null,
        'contact': 'test centrum',
        'customsEORINumber': 'NL123456789'
      },
      {
        'fullName': 'Ship Operator 1',
        'portAuthorityId': 'SO1_R',
        'shortName': 'SO1',
        'emailAddress': 'pietjepuk@yoyo.nl',
        'address': 'Ship Operatorlaan 1',
        'city': 'Ship Operatorstad',
        'countryUnCode': 'NL',
        'countryName': 'Netherlands',
        'zipCode': '1111SO',
        'phoneNumber': '0102522288',
        'faxNumber': null,
        'contact': 'test centrum',
        'customsEORINumber': 'NL123456789'
      }
    ],
    'visitDeclaration': {
      'clientReferenceNumber': '7',
      'etaFirstEntryEu': '2018-11-08T11:00:00Z',
      'arrivalVoyage': {
        'voyageNumber': '1234567',
        'carrier': {
          'customsId': 'NL100000897',
          'name': 'Carrier 1',
          'scacCode': 'CARA',
          'smdgCode': 'CAR'
        }
      },
      'previousPorts': [
        {
          'id': '7239cd11943846a78869f7260f0fc219',
          'arrival': '2018-11-07T11:00:00Z',
          'departure': '2018-11-08T11:00:00Z',
          'port': {
            'name': 'Barcelona',
            'locationUnCode': 'ESBCN',
            'countryUnCode': 'ES',
            'euPort': true
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'ESBCN-0001',
                'name': 'Barcelona, Spain  AUTOTERMINAL, S.A.'
              },
              'arrivalDate': '2018-11-07',
              'departureDate': '2018-11-08',
              'securityLevel': 'SL1',
              'additionalSecurityMeasures': 'Measures 1'
            }
          ]
        },
        {
          'id': '39af59bb1dd344339f84d12c4533bd03',
          'arrival': '2018-11-06T11:00:00Z',
          'departure': '2018-11-07T11:00:00Z',
          'port': {
            'name': 'Tunisi',
            'locationUnCode': 'ITTUN',
            'countryUnCode': 'IT',
            'euPort': true
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'ITTUN-9999',
                'name': 'UNKNOWN'
              },
              'arrivalDate': '2018-11-06',
              'departureDate': '2018-11-07',
              'securityLevel': 'SL2',
              'additionalSecurityMeasures': 'Measures 2'
            }
          ]
        },
        {
          'id': '8a49e9d4d39647188bb8bd3d57dd4fa7',
          'arrival': '2018-11-05T11:00:00Z',
          'departure': '2018-11-06T11:00:00Z',
          'port': {
            'name': 'Barcelona',
            'locationUnCode': 'ESBCN',
            'countryUnCode': 'ES',
            'euPort': true
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'ESBCN-0003',
                'name': 'Barcelona, Spain  CENTRO DE CONTROL'
              },
              'arrivalDate': '2018-11-05',
              'departureDate': '2018-11-06',
              'securityLevel': 'SL3',
              'additionalSecurityMeasures': 'Measures 3'
            }
          ]
        },
        {
          'id': '2f4ae45bc63643de9fe7678f8021940d',
          'arrival': '2018-11-04T11:00:00Z',
          'departure': '2018-11-05T11:00:00Z',
          'port': {
            'name': 'Tunisi',
            'locationUnCode': 'ITTUN',
            'countryUnCode': 'IT',
            'euPort': true
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'ITTUN-9999',
                'name': 'UNKNOWN'
              },
              'arrivalDate': '2018-11-04',
              'departureDate': '2018-11-05',
              'securityLevel': 'SL1',
              'additionalSecurityMeasures': 'Measures 4'
            }
          ]
        },
        {
          'id': 'bb63bcf604c44adc8aada67c61a367c2',
          'arrival': '2018-11-03T11:00:00Z',
          'departure': '2018-11-04T11:00:00Z',
          'port': {
            'name': 'Barcelona',
            'locationUnCode': 'ESBCN',
            'countryUnCode': 'ES',
            'euPort': true
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'ESBCN-0005',
                'name': 'Barcelona, Spain  COMPAÑÍA LOGISTICA DE HIDROCARBUROS (CLH)'
              },
              'arrivalDate': '2018-11-03',
              'departureDate': '2018-11-04',
              'securityLevel': 'SL2',
              'additionalSecurityMeasures': 'Measures 5'
            }
          ]
        },
        {
          'id': '24a4865f6a494d2abc9c8d477705ad5c',
          'arrival': '2018-11-02T11:00:00Z',
          'departure': '2018-11-03T11:00:00Z',
          'port': {
            'name': 'Tunisi',
            'locationUnCode': 'ITTUN',
            'countryUnCode': 'IT',
            'euPort': true
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'ITTUN-9999',
                'name': 'UNKNOWN'
              },
              'arrivalDate': '2018-11-02',
              'departureDate': '2018-11-03',
              'securityLevel': 'SL3',
              'additionalSecurityMeasures': 'Measures 6'
            }
          ]
        },
        {
          'id': 'e4f33384c9f94439855c194b8d5ededb',
          'arrival': '2018-11-01T11:00:00Z',
          'departure': '2018-11-02T11:00:00Z',
          'port': {
            'name': 'Barcelona',
            'locationUnCode': 'ESBCN',
            'countryUnCode': 'ES',
            'euPort': true
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'ESBCN-0007',
                'name': 'Barcelona, Spain  CORPORACIÓN DE PRÁCTICOS DEL PUERTO DE BARCELONA, S.L.P.'
              },
              'arrivalDate': '2018-11-01',
              'departureDate': '2018-11-02',
              'securityLevel': 'SL1',
              'additionalSecurityMeasures': 'Measures 7'
            }
          ]
        },
        {
          'id': 'a51f713703c94ce885487c6645a1c76e',
          'arrival': '2018-10-31T11:00:00Z',
          'departure': '2018-11-01T11:00:00Z',
          'port': {
            'name': 'Tunisi',
            'locationUnCode': 'ITTUN',
            'countryUnCode': 'IT',
            'euPort': true
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'ITTUN-9999',
                'name': 'UNKNOWN'
              },
              'arrivalDate': '2018-10-31',
              'departureDate': '2018-11-01',
              'securityLevel': 'SL2',
              'additionalSecurityMeasures': 'Measures 8'
            }
          ]
        },
        {
          'id': '0ffbad66973446f4b354c1f3d29a294d',
          'arrival': '2018-10-30T11:00:00Z',
          'departure': '2018-10-31T11:00:00Z',
          'port': {
            'name': 'Barcelona',
            'locationUnCode': 'ESBCN',
            'countryUnCode': 'ES',
            'euPort': true
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'ESBCN-0009',
                'name': 'Barcelona, Spain  DECAL ESPAÑA S.A.'
              },
              'arrivalDate': '2018-10-30',
              'departureDate': '2018-10-31',
              'securityLevel': 'SL3',
              'additionalSecurityMeasures': 'Measures 9'
            }
          ]
        },
        {
          'id': '2fe3987117624e4e982eadb5fcf6b30f',
          'arrival': '2018-10-29T11:00:00Z',
          'departure': '2018-10-30T11:00:00Z',
          'port': {
            'name': 'Tunisi',
            'locationUnCode': 'ITTUN',
            'countryUnCode': 'IT',
            'euPort': true
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'ITTUN-9999',
                'name': 'UNKNOWN'
              },
              'arrivalDate': '2018-10-29',
              'departureDate': '2018-10-30',
              'securityLevel': 'SL2',
              'additionalSecurityMeasures': 'Measures 10'
            }
          ]
        },
        {
          'id': '14b77c47a3b24a6bb058d9998f89f213',
          'arrival': '2018-10-28T11:00:00Z',
          'departure': '2018-10-29T11:00:00Z',
          'port': {
            'name': 'Barcelona',
            'locationUnCode': 'ESBCN',
            'countryUnCode': 'ES',
            'euPort': true
          },
          'portFacilityVisits': []
        },
        {
          'id': '62ba903b912543b18438f132d2409263',
          'arrival': '2018-10-27T11:00:00Z',
          'departure': '2018-10-28T11:00:00Z',
          'port': {
            'name': 'Tunisi',
            'locationUnCode': 'ITTUN',
            'countryUnCode': 'IT',
            'euPort': true
          },
          'portFacilityVisits': []
        }
      ],
      'portVisit': {
        'intention': 'REQUEST_FOR_ENTRY',
        'pilotStation': {
          'code': 'MC',
          'name': 'Maascenter',
          'atSea': true
        },
        'entryPoint': {
          'code': 'LL',
          'name': 'Lage Licht'
        },
        'etaPort': '2018-11-08T11:00:00Z',
        'firstMovement': {
          'vesselDraft': 6.0,
          'vesselMasterName': 'Henk',
          'numberOfCrew': 20,
          'numberOfPassengers': 6,
          'cargo': 'OUT_OF_GAUGE',
          'pilotExemption': {
            'holderName': '',
            'number': '16'
          },
          'offStandardBeam': {
            'value': 4.5,
            'remarks': ''
          },
          'pilotService': {
            'required': false
          }
        },
        'passingThroughTugboats': null,
        'defectTypes': [
          'DEN',
          'DWE',
          'TOW'
        ],
        'berthVisits': [
          {
            'id': '1abbb8789fe94b5c8adad25550a0a6b1',
            'berth': {
              'name': 'HOLLAND AMERIKAKADE',
              'code': 'R1243',
              'terminalCode': '52090469',
              'berthGroupCode': 'HOLLAND AMERIKAKADE',
              'buoy': false,
              'partyToNotify': null
            },
            'eta': '2018-11-09T11:00:00Z',
            'ata': null,
            'etd': '2018-11-09T22:00:00Z',
            'atd': null,
            'mooring': 'PORT_SIDE',
            'remarks': null,
            'visitPurposes': [
              'CARGO_TANK_CLEANING',
              'CUSTOMS_CLEARANCE',
              'DISPOSAL_OF_WASTE',
              'LAID_UP',
              'LOADING'
            ],
            'bollardFrom': null,
            'bollardTo': null,
            'tugboatAtArrival': {
              'required': true,
              'serviceProvider': {
                'name': 'Gepke',
                'portAuthorityId': 'VERSCHOOR'
              },
              'requiredQuantity': 'SIX',
              'remarks': null
            },
            'boatmenAtArrival': {
              'required': true,
              'serviceProvider': {
                'name': 'V.i.o.s.',
                'portAuthorityId': 'VIOS'
              },
              'remarks': null
            },
            'boatmenAtDeparture': {
              'required': true,
              'serviceProvider': {
                'name': 'V.i.o.s.',
                'portAuthorityId': 'VIOS'
              },
              'remarks': null
            },
            'tugboatAtDeparture': {
              'required': true,
              'serviceProvider': {
                'name': 'Gepke',
                'portAuthorityId': 'VERSCHOOR'
              },
              'requiredQuantity': 'SIX',
              'remarks': null
            },
            'nextMovement': {
              'vesselDraft': 7.0,
              'vesselMasterName': 'Piet',
              'numberOfCrew': 21,
              'numberOfPassengers': 7,
              'cargo': 'OUT_OF_GAUGE',
              'pilotExemption': {
                'holderName': '',
                'number': '16'
              },
              'offStandardBeam': {
                'value': 4.5,
                'remarks': ''
              },
              'pilotService': null
            }
          },
          {
            'id': 'c1324bb553b2451d907e63e48d74c3e1',
            'berth': {
              'name': 'DORDR PWA KADE',
              'code': 'D4358',
              'terminalCode': '51433242',
              'berthGroupCode': 'DORDR PWA KADE',
              'buoy': false,
              'partyToNotify': null
            },
            'eta': '2018-11-10T11:00:00Z',
            'ata': null,
            'etd': '2018-11-10T22:00:00Z',
            'atd': null,
            'mooring': 'ALONGSIDE_OTHER_SHIP',
            'remarks': null,
            'visitPurposes': [
              'DISCHARGE_CRUDE_OIL',
              'TAKING_SUPPLIES'
            ],
            'bollardFrom': null,
            'bollardTo': null,
            'tugboatAtArrival': {
              'required': false,
              'serviceProvider': {
                'name': 'Gepke',
                'portAuthorityId': 'VERSCHOOR'
              },
              'requiredQuantity': null,
              'remarks': null
            },
            'boatmenAtArrival': {
              'required': true,
              'serviceProvider': {
                'name': 'V.i.o.s.',
                'portAuthorityId': 'VIOS'
              },
              'remarks': null
            },
            'boatmenAtDeparture': null,
            'tugboatAtDeparture': {
              'required': true,
              'serviceProvider': {
                'name': 'Gepke',
                'portAuthorityId': 'VERSCHOOR'
              },
              'requiredQuantity': 'FIVE',
              'remarks': null
            },
            'nextMovement': {
              'vesselDraft': 8.0,
              'vesselMasterName': 'Henk',
              'numberOfCrew': 20,
              'numberOfPassengers': 6,
              'cargo': 'BALLAST_CONDITION',
              'pilotExemption': null,
              'offStandardBeam': {
                'value': 12.8,
                'remarks': 'No remarks'
              },
              'pilotService': {
                'required': true,
                'serviceProvider': {
                  'name': 'Pilot 1',
                  'portAuthorityId': 'PIL1'
                },
                'remarks': null
              }
            }
          },
          {
            'id': 'abb42b0d775d44fca7e2d392d16ff340',
            'berth': {
              'name': 'PET 3 B 61',
              'code': 'R61',
              'terminalCode': '47644472',
              'berthGroupCode': 'PET 3 B 61',
              'buoy': true,
              'partyToNotify': null
            },
            'eta': '2018-11-11T11:00:00Z',
            'ata': null,
            'etd': '2018-11-11T22:00:00Z',
            'atd': null,
            'mooring': 'STARBOARD_SIDE',
            'remarks': null,
            'visitPurposes': [
              'CREW_MOVEMENT',
              'LOADING',
              'REFUGE'
            ],
            'bollardFrom': 16.2,
            'bollardTo': 20.2,
            'tugboatAtArrival': {
              'required': false,
              'serviceProvider': {
                'name': 'Gepke',
                'portAuthorityId': 'VERSCHOOR'
              },
              'requiredQuantity': null,
              'remarks': null
            },
            'boatmenAtArrival': {
              'required': true,
              'serviceProvider': {
                'name': 'V.i.o.s.',
                'portAuthorityId': 'VIOS'
              },
              'remarks': null
            },
            'boatmenAtDeparture': null,
            'tugboatAtDeparture': {
              'required': false,
              'serviceProvider': {
                'name': 'Smit havensleepdienst B.V.',
                'portAuthorityId': 'SMIT'
              },
              'requiredQuantity': null,
              'remarks': null
            },
            'nextMovement': {
              'vesselDraft': 12.5,
              'vesselMasterName': 'Henk',
              'numberOfCrew': 20,
              'numberOfPassengers': 6,
              'cargo': 'BALLAST_CONDITION',
              'pilotExemption': {
                'holderName': 'Exemption holder 1',
                'number': '12345'
              },
              'offStandardBeam': {
                'value': 12.8,
                'remarks': 'No remarks'
              },
              'pilotService': null
            }
          }
        ],
        'dangerousGoodsLoading': true,
        'dangerousGoodsDischarge': false,
        'dangerousGoodsTransit': true,
        'possibleAnchorage': true,
        'vesselInspectionRequired': true,
        'shipConfiguration': 'DOUBLE_HULL_TANKER',
        'exitPoint': {
          'code': 'OM',
          'name': 'Oude Maas',
          'atSea': false
        },
        'etdPort': '2018-11-15T11:00:00Z',
        'atdPort': '2018-11-18T14:00:00Z'
      },
      'departureVoyage': {
        'voyageNumber': '1234568',
        'carrier': {
          'customsId': '12345',
          'name': null,
          'scacCode': null,
          'smdgCode': null
        }
      },
      'nextPorts': [
        {
          'id': 'f94034d2d4624e778775625184f22091',
          'arrival': '2018-11-14T11:00:00Z',
          'departure': '2018-11-15T11:00:00Z',
          'port': {
            'name': 'Antwerpen',
            'locationUnCode': 'BEANR',
            'countryUnCode': 'BE',
            'euPort': true
          },
          'customsOffice': null
        },
        {
          'id': 'd8d6ca9c2fa44747b4d8f111a75e11c7',
          'arrival': '2018-11-22T11:00:00Z',
          'departure': '2018-11-23T11:00:00Z',
          'port': {
            'name': 'Taranto',
            'locationUnCode': 'ITTAR',
            'countryUnCode': 'IT',
            'euPort': true
          },
          'customsOffice': {
            'name': 'S.O.T. AEROPORTO DELLO STRETTO',
            'unCode': 'IT087101'
          }
        },
        {
          'id': 'f4e5100bad6d4aa4a2cff0412bf963e0',
          'arrival': '2018-11-24T11:00:00Z',
          'departure': '2018-11-27T11:00:00Z',
          'port': {
            'name': 'El Iskandariya (= Alexandria)',
            'locationUnCode': 'EGALY',
            'countryUnCode': 'EG',
            'euPort': false
          },
          'customsOffice': null
        }
      ]
    },
    'nextVisitDeclaration': [],
    'securityDeclaration': {
      'securityReportRequired': 'YES',
      'companySecurityOfficer': {
        'name': 'Jansen',
        'email': 'Jansen@hotmail.com',
        'phoneNumber': '123456789'
      },
      'currentSecurityLevel': 'SL1',
      'approvedSspDocumentOnBoard': false,
      'isscSecurityDocument': {
        'availability': 'YES',
        'expiryDate': '2017-01-01',
        'reasonNoValidIsscOnBoard': 'ik ben een gevaarlijke piraat',
        'issuer': {
          'code': '1',
          'name': 'AMERICAN BUREAU'
        }
      },
      'shipToShipActivities': [
        {
          'location': 'Ergens 1',
          'type': 'CARGO_TANK_CLEANING',
          'startDate': '2018-11-07',
          'endDate': '2018-11-08',
          'compliantWithSsp': true,
          'securityMeasures': 'Measures 1'
        },
        {
          'location': 'Ergens 2',
          'type': 'CHANGING_CREW',
          'startDate': '2018-11-06',
          'endDate': '2018-11-07',
          'compliantWithSsp': false,
          'securityMeasures': 'Measures 2'
        },
        {
          'location': 'Ergens 3',
          'type': 'DE_GASSING',
          'startDate': '2018-11-05',
          'endDate': '2018-11-06',
          'compliantWithSsp': true,
          'securityMeasures': 'Measures 3'
        },
        {
          'location': 'Ergens 4',
          'type': 'CARGO_TANK_CLEANING',
          'startDate': '2018-11-04',
          'endDate': '2018-11-05',
          'compliantWithSsp': false,
          'securityMeasures': 'Measures 4'
        },
        {
          'location': 'Ergens 5',
          'type': 'CHANGING_CREW',
          'startDate': '2018-11-03',
          'endDate': '2018-11-04',
          'compliantWithSsp': true,
          'securityMeasures': 'Measures 5'
        },
        {
          'location': 'Ergens 6',
          'type': 'DE_GASSING',
          'startDate': '2018-11-02',
          'endDate': '2018-11-03',
          'compliantWithSsp': false,
          'securityMeasures': 'Measures 6'
        },
        {
          'location': 'Ergens 7',
          'type': 'CARGO_TANK_CLEANING',
          'startDate': '2018-11-01',
          'endDate': '2018-11-02',
          'compliantWithSsp': true,
          'securityMeasures': 'Measures 7'
        },
        {
          'location': 'Ergens 8',
          'type': 'CHANGING_CREW',
          'startDate': '2018-10-31',
          'endDate': '2018-11-01',
          'compliantWithSsp': false,
          'securityMeasures': 'Measures 8'
        },
        {
          'location': 'Ergens 9',
          'type': 'DE_GASSING',
          'startDate': '2018-10-30',
          'endDate': '2018-10-31',
          'compliantWithSsp': true,
          'securityMeasures': 'Measures 9'
        }
      ],
      'securityRelatedMatter': 'Any security related matter to report'
    },
    'wasteDeclaration': null,
    'shipStoresDeclaration': null,
    'paxDeclarationSummaries': null,
    'uploads': {},
    'cancelled': false,
    'ataRegisteredByPa': false,
    'declarations': [
      {
        'type': 'VISIT',
        'status': 'ACCEPTED',
        'rejectReasons': null,
        'timeStamp': '2018-12-20T11:12:40.997Z'
      },
      {
        'type': 'WPCS',
        'status': 'DECLARED',
        'rejectReasons': null,
        'timeStamp': '2018-12-20T11:12:48.060Z'
      },
      {
        'type': 'SECURITY',
        'status': 'ACCEPTED',
        'rejectReasons': null,
        'timeStamp': '2018-12-20T11:13:13.063Z'
      },
      {
        'type': 'VISIT',
        'status': 'ACCEPTED',
        'rejectReasons': null,
        'timeStamp': '2018-12-24T10:31:08.960Z'
      }
    ],
    'berthVisitInfos': null,
    'visitStatus': 'DEPARTED',
    'expected': false
  },
  cancelledVisit: {
    'crn': 'cancelledVisit',
    'additionalViewers': [],
    'copyOfVisit': null,
    'portOfCall': {
      'port': {
        'name': 'Rotterdam',
        'locationUnCode': 'NLRTM',
        'countryUnCode': 'NL',
        'euPort': true
      },
      'portAuthority': {
        'shortName': 'HBRRTM',
        'fullName': 'Havenbedrijf Rotterdam N.V.',
        'emailAddress': 'HCC@portofrotterdam.com',
        'phoneNumber': '+31-10-2521195',
        'faxNumber': '+31-10-2521000',
        'address': 'Wilhelminakade 909',
        'zipCode': '3072 AP',
        'city': 'Rotterdam',
        'countryUnCode': 'NL',
        'countryName': 'Netherlands',
        'customsEORINumber': 'NL24354561',
        'contact': 'Diana van Eijk',
        'portAuthorityId': 'HbR'
      },
      'customsOffice': {
        'name': 'Rotterdam Haven/Kantoor Maasvlakte',
        'unCode': 'NL000396'
      },
      'ataReported': true,
      'atdReported': true,
      'atdPortReported': true,
      'paDeclarationRequired': true,
      'swDeclarationRequired': true,
      'wasteEnabled': true,
      'dangerousGoodsEnabled': true,
      'harbourDuesEnabled': false,
      'orderNauticalServicesEnabled': true,
      'seaBuoy': {
        'name': 'Maascenter',
        'code': 'MC'
      }
    },
    'vessel': {
      'imoCode': '8758457',
      'name': 'DAVE BLACKBURN',
      'radioCallSign': 'WB6043',
      'motUnCode': '1729',
      'motName': 'Passenger ship',
      'summerDeadWeight': 0,
      'maxWidth': 15.84,
      'manoeuvringSpeed': null,
      'flagCountryUnCode': 'US',
      'netTonnage': 1338,
      'grossTonnage': 1338,
      'registrationPlaceUnloCode': 'USMSY',
      'registrationPlaceName': 'New Orleans',
      'registrationDate': '1944-02-01',
      'mmsiNumber': null,
      'helispotPresent': false,
      'fullLength': 110,
      'flagCode': 'USA'
    },
    'owner': {
      'fullName': 'Ship Operator 1',
      'portAuthorityId': 'SO1_R',
      'shortName': 'SO1',
      'emailAddress': 'pietjepuk@yoyo.nl',
      'address': 'Ship Operatorlaan 1',
      'city': 'Ship Operatorstad',
      'countryUnCode': 'NL',
      'countryName': 'Netherlands',
      'zipCode': '1111SO',
      'phoneNumber': '0102522288',
      'faxNumber': null,
      'contact': 'test centrum',
      'customsEORINumber': 'NL123456789'
    },
    'declarant': {
      'fullName': 'Back Office 1',
      'portAuthorityId': 'BO1_R',
      'shortName': 'BO1',
      'emailAddress': 'pietjepuk@yoyo.nl',
      'address': 'Back Officelaan 1',
      'city': 'Back Officestad',
      'countryUnCode': 'NL',
      'countryName': 'Netherlands',
      'zipCode': '1111SO',
      'phoneNumber': '0102522288',
      'faxNumber': null,
      'contact': 'test centrum',
      'customsEORINumber': 'NL123456789'
    },
    'financialDeclarant': {
      'fullName': 'Ship Operator 1',
      'portAuthorityId': 'SO1_R',
      'shortName': 'SO1',
      'emailAddress': 'pietjepuk@yoyo.nl',
      'address': 'Ship Operatorlaan 1',
      'city': 'Ship Operatorstad',
      'countryUnCode': 'NL',
      'countryName': 'Netherlands',
      'zipCode': '1111SO',
      'phoneNumber': '0102522288',
      'faxNumber': null,
      'contact': 'test centrum',
      'customsEORINumber': 'NL123456789'
    },
    'nominatedFinancialDeclarant': null,
    'orderNauticalServices': true,
    'creditNumberHolderSameAsDeclarant': true,
    'cargoDeclarants': [
      {
        'fullName': 'Ship Operator 1',
        'portAuthorityId': 'SO1_R',
        'shortName': 'SO1',
        'emailAddress': 'd.reijnders@portbase.com',
        'address': 'Ship Operatorlaan 1',
        'city': 'Ship Operatorstad',
        'countryUnCode': 'NL',
        'countryName': 'Netherlands',
        'zipCode': '1111SO',
        'phoneNumber': '0102522288',
        'faxNumber': null,
        'contact': 'TC',
        'customsEORINumber': 'NL123456789'
      }
    ],
    'allowedViewers': [
      {
        'fullName': 'Ship Operator 1',
        'portAuthorityId': 'SO1_R',
        'shortName': 'SO1',
        'emailAddress': 'pietjepuk@yoyo.nl',
        'address': 'Ship Operatorlaan 1',
        'city': 'Ship Operatorstad',
        'countryUnCode': 'NL',
        'countryName': 'Netherlands',
        'zipCode': '1111SO',
        'phoneNumber': '0102522288',
        'faxNumber': null,
        'contact': 'test centrum',
        'customsEORINumber': 'NL123456789'
      },
      {
        'fullName': 'Ship Operator 1',
        'portAuthorityId': 'SO1_R',
        'shortName': 'SO1',
        'emailAddress': 'pietjepuk@yoyo.nl',
        'address': 'Ship Operatorlaan 1',
        'city': 'Ship Operatorstad',
        'countryUnCode': 'NL',
        'countryName': 'Netherlands',
        'zipCode': '1111SO',
        'phoneNumber': '0102522288',
        'faxNumber': null,
        'contact': 'test centrum',
        'customsEORINumber': 'NL123456789'
      }
    ],
    'visitDeclaration': {
      'clientReferenceNumber': '7',
      'etaFirstEntryEu': '2018-11-08T11:00:00Z',
      'arrivalVoyage': {
        'voyageNumber': '1234567',
        'carrier': {
          'customsId': 'NL100000897',
          'name': 'Carrier 1',
          'scacCode': 'CARA',
          'smdgCode': 'CAR'
        }
      },
      'previousPorts': [
        {
          'id': '7239cd11943846a78869f7260f0fc219',
          'arrival': '2018-11-07T11:00:00Z',
          'departure': '2018-11-08T11:00:00Z',
          'port': {
            'name': 'Barcelona',
            'locationUnCode': 'ESBCN',
            'countryUnCode': 'ES',
            'euPort': true
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'ESBCN-0001',
                'name': 'Barcelona, Spain  AUTOTERMINAL, S.A.'
              },
              'arrivalDate': '2018-11-07',
              'departureDate': '2018-11-08',
              'securityLevel': 'SL1',
              'additionalSecurityMeasures': 'Measures 1'
            }
          ]
        },
        {
          'id': '39af59bb1dd344339f84d12c4533bd03',
          'arrival': '2018-11-06T11:00:00Z',
          'departure': '2018-11-07T11:00:00Z',
          'port': {
            'name': 'Tunisi',
            'locationUnCode': 'ITTUN',
            'countryUnCode': 'IT',
            'euPort': true
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'ITTUN-9999',
                'name': 'UNKNOWN'
              },
              'arrivalDate': '2018-11-06',
              'departureDate': '2018-11-07',
              'securityLevel': 'SL2',
              'additionalSecurityMeasures': 'Measures 2'
            }
          ]
        },
        {
          'id': '8a49e9d4d39647188bb8bd3d57dd4fa7',
          'arrival': '2018-11-05T11:00:00Z',
          'departure': '2018-11-06T11:00:00Z',
          'port': {
            'name': 'Barcelona',
            'locationUnCode': 'ESBCN',
            'countryUnCode': 'ES',
            'euPort': true
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'ESBCN-0003',
                'name': 'Barcelona, Spain  CENTRO DE CONTROL'
              },
              'arrivalDate': '2018-11-05',
              'departureDate': '2018-11-06',
              'securityLevel': 'SL3',
              'additionalSecurityMeasures': 'Measures 3'
            }
          ]
        },
        {
          'id': '2f4ae45bc63643de9fe7678f8021940d',
          'arrival': '2018-11-04T11:00:00Z',
          'departure': '2018-11-05T11:00:00Z',
          'port': {
            'name': 'Tunisi',
            'locationUnCode': 'ITTUN',
            'countryUnCode': 'IT',
            'euPort': true
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'ITTUN-9999',
                'name': 'UNKNOWN'
              },
              'arrivalDate': '2018-11-04',
              'departureDate': '2018-11-05',
              'securityLevel': 'SL1',
              'additionalSecurityMeasures': 'Measures 4'
            }
          ]
        },
        {
          'id': 'bb63bcf604c44adc8aada67c61a367c2',
          'arrival': '2018-11-03T11:00:00Z',
          'departure': '2018-11-04T11:00:00Z',
          'port': {
            'name': 'Barcelona',
            'locationUnCode': 'ESBCN',
            'countryUnCode': 'ES',
            'euPort': true
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'ESBCN-0005',
                'name': 'Barcelona, Spain  COMPAÑÍA LOGISTICA DE HIDROCARBUROS (CLH)'
              },
              'arrivalDate': '2018-11-03',
              'departureDate': '2018-11-04',
              'securityLevel': 'SL2',
              'additionalSecurityMeasures': 'Measures 5'
            }
          ]
        },
        {
          'id': '24a4865f6a494d2abc9c8d477705ad5c',
          'arrival': '2018-11-02T11:00:00Z',
          'departure': '2018-11-03T11:00:00Z',
          'port': {
            'name': 'Tunisi',
            'locationUnCode': 'ITTUN',
            'countryUnCode': 'IT',
            'euPort': true
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'ITTUN-9999',
                'name': 'UNKNOWN'
              },
              'arrivalDate': '2018-11-02',
              'departureDate': '2018-11-03',
              'securityLevel': 'SL3',
              'additionalSecurityMeasures': 'Measures 6'
            }
          ]
        },
        {
          'id': 'e4f33384c9f94439855c194b8d5ededb',
          'arrival': '2018-11-01T11:00:00Z',
          'departure': '2018-11-02T11:00:00Z',
          'port': {
            'name': 'Barcelona',
            'locationUnCode': 'ESBCN',
            'countryUnCode': 'ES',
            'euPort': true
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'ESBCN-0007',
                'name': 'Barcelona, Spain  CORPORACIÓN DE PRÁCTICOS DEL PUERTO DE BARCELONA, S.L.P.'
              },
              'arrivalDate': '2018-11-01',
              'departureDate': '2018-11-02',
              'securityLevel': 'SL1',
              'additionalSecurityMeasures': 'Measures 7'
            }
          ]
        },
        {
          'id': 'a51f713703c94ce885487c6645a1c76e',
          'arrival': '2018-10-31T11:00:00Z',
          'departure': '2018-11-01T11:00:00Z',
          'port': {
            'name': 'Tunisi',
            'locationUnCode': 'ITTUN',
            'countryUnCode': 'IT',
            'euPort': true
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'ITTUN-9999',
                'name': 'UNKNOWN'
              },
              'arrivalDate': '2018-10-31',
              'departureDate': '2018-11-01',
              'securityLevel': 'SL2',
              'additionalSecurityMeasures': 'Measures 8'
            }
          ]
        },
        {
          'id': '0ffbad66973446f4b354c1f3d29a294d',
          'arrival': '2018-10-30T11:00:00Z',
          'departure': '2018-10-31T11:00:00Z',
          'port': {
            'name': 'Barcelona',
            'locationUnCode': 'ESBCN',
            'countryUnCode': 'ES',
            'euPort': true
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'ESBCN-0009',
                'name': 'Barcelona, Spain  DECAL ESPAÑA S.A.'
              },
              'arrivalDate': '2018-10-30',
              'departureDate': '2018-10-31',
              'securityLevel': 'SL3',
              'additionalSecurityMeasures': 'Measures 9'
            }
          ]
        },
        {
          'id': '2fe3987117624e4e982eadb5fcf6b30f',
          'arrival': '2018-10-29T11:00:00Z',
          'departure': '2018-10-30T11:00:00Z',
          'port': {
            'name': 'Tunisi',
            'locationUnCode': 'ITTUN',
            'countryUnCode': 'IT',
            'euPort': true
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'ITTUN-9999',
                'name': 'UNKNOWN'
              },
              'arrivalDate': '2018-10-29',
              'departureDate': '2018-10-30',
              'securityLevel': 'SL2',
              'additionalSecurityMeasures': 'Measures 10'
            }
          ]
        },
        {
          'id': '14b77c47a3b24a6bb058d9998f89f213',
          'arrival': '2018-10-28T11:00:00Z',
          'departure': '2018-10-29T11:00:00Z',
          'port': {
            'name': 'Barcelona',
            'locationUnCode': 'ESBCN',
            'countryUnCode': 'ES',
            'euPort': true
          },
          'portFacilityVisits': []
        },
        {
          'id': '62ba903b912543b18438f132d2409263',
          'arrival': '2018-10-27T11:00:00Z',
          'departure': '2018-10-28T11:00:00Z',
          'port': {
            'name': 'Tunisi',
            'locationUnCode': 'ITTUN',
            'countryUnCode': 'IT',
            'euPort': true
          },
          'portFacilityVisits': []
        }
      ],
      'portVisit': {
        'intention': 'REQUEST_FOR_ENTRY',
        'pilotStation': {
          'code': 'AL',
          'name': 'ACHTERLAND'
        },
        'entryPoint': {
          'code': 'LL',
          'name': 'Lage Licht'
        },
        'etaPort': '2018-11-08T11:00:00Z',
        'firstMovement': {
          'vesselDraft': 6.0,
          'vesselMasterName': 'Henk',
          'numberOfCrew': 20,
          'numberOfPassengers': 6,
          'cargo': 'OUT_OF_GAUGE',
          'pilotExemption': {
            'holderName': '',
            'number': '16'
          },
          'offStandardBeam': {
            'value': 4.5,
            'remarks': ''
          },
          'pilotService': {
            'required': false
          }
        },
        'passingThroughTugboats': null,
        'defectTypes': [
          'DEN',
          'DWE',
          'TOW'
        ],
        'berthVisits': [
          {
            'id': '1abbb8789fe94b5c8adad25550a0a6b1',
            'berth': {
              'name': 'HOLLAND AMERIKAKADE',
              'code': 'R1243',
              'terminalCode': '52090469',
              'berthGroupCode': 'HOLLAND AMERIKAKADE',
              'buoy': false,
              'partyToNotify': null
            },
            'eta': '2018-11-09T11:00:00Z',
            'ata': null,
            'etd': '2018-11-09T22:00:00Z',
            'atd': null,
            'mooring': 'PORT_SIDE',
            'remarks': null,
            'visitPurposes': [
              'CARGO_TANK_CLEANING',
              'CUSTOMS_CLEARANCE',
              'DISPOSAL_OF_WASTE',
              'LAID_UP',
              'LOADING'
            ],
            'bollardFrom': null,
            'bollardTo': null,
            'tugboatAtArrival': {
              'required': true,
              'serviceProvider': {
                'name': 'Gepke',
                'portAuthorityId': 'VERSCHOOR'
              },
              'requiredQuantity': 'SIX',
              'remarks': null
            },
            'boatmenAtArrival': {
              'required': true,
              'serviceProvider': {
                'name': 'V.i.o.s.',
                'portAuthorityId': 'VIOS'
              },
              'remarks': null
            },
            'boatmenAtDeparture': {
              'required': true,
              'serviceProvider': {
                'name': 'V.i.o.s.',
                'portAuthorityId': 'VIOS'
              },
              'remarks': null
            },
            'tugboatAtDeparture': {
              'required': true,
              'serviceProvider': {
                'name': 'Gepke',
                'portAuthorityId': 'VERSCHOOR'
              },
              'requiredQuantity': 'SIX',
              'remarks': null
            },
            'nextMovement': {
              'vesselDraft': 7.0,
              'vesselMasterName': 'Piet',
              'numberOfCrew': 21,
              'numberOfPassengers': 7,
              'cargo': 'OUT_OF_GAUGE',
              'pilotExemption': {
                'holderName': '',
                'number': '16'
              },
              'offStandardBeam': {
                'value': 4.5,
                'remarks': ''
              },
              'pilotService': null
            }
          },
          {
            'id': 'c1324bb553b2451d907e63e48d74c3e1',
            'berth': {
              'name': 'DORDR PWA KADE',
              'code': 'D4358',
              'terminalCode': '51433242',
              'berthGroupCode': 'DORDR PWA KADE',
              'buoy': false,
              'partyToNotify': null
            },
            'eta': '2018-11-10T11:00:00Z',
            'ata': null,
            'etd': '2018-11-10T22:00:00Z',
            'atd': null,
            'mooring': 'ALONGSIDE_OTHER_SHIP',
            'remarks': null,
            'visitPurposes': [
              'DISCHARGE_CRUDE_OIL',
              'TAKING_SUPPLIES'
            ],
            'bollardFrom': null,
            'bollardTo': null,
            'tugboatAtArrival': {
              'required': false,
              'serviceProvider': {
                'name': 'Gepke',
                'portAuthorityId': 'VERSCHOOR'
              },
              'requiredQuantity': null,
              'remarks': null
            },
            'boatmenAtArrival': {
              'required': true,
              'serviceProvider': {
                'name': 'V.i.o.s.',
                'portAuthorityId': 'VIOS'
              },
              'remarks': null
            },
            'boatmenAtDeparture': null,
            'tugboatAtDeparture': {
              'required': true,
              'serviceProvider': {
                'name': 'Gepke',
                'portAuthorityId': 'VERSCHOOR'
              },
              'requiredQuantity': 'FIVE',
              'remarks': null
            },
            'nextMovement': {
              'vesselDraft': 8.0,
              'vesselMasterName': 'Henk',
              'numberOfCrew': 20,
              'numberOfPassengers': 6,
              'cargo': 'BALLAST_CONDITION',
              'pilotExemption': null,
              'offStandardBeam': {
                'value': 12.8,
                'remarks': 'No remarks'
              },
              'pilotService': {
                'required': true,
                'serviceProvider': {
                  'name': 'Pilot 1',
                  'portAuthorityId': 'PIL1'
                },
                'remarks': null
              }
            }
          },
          {
            'id': 'abb42b0d775d44fca7e2d392d16ff340',
            'berth': {
              'name': 'PET 3 B 61',
              'code': 'R61',
              'terminalCode': '47644472',
              'berthGroupCode': 'PET 3 B 61',
              'buoy': true,
              'partyToNotify': null
            },
            'eta': '2018-11-11T11:00:00Z',
            'ata': null,
            'etd': '2018-11-11T22:00:00Z',
            'atd': null,
            'mooring': 'STARBOARD_SIDE',
            'remarks': null,
            'visitPurposes': [
              'CREW_MOVEMENT',
              'LOADING',
              'REFUGE'
            ],
            'bollardFrom': 16.2,
            'bollardTo': 20.2,
            'tugboatAtArrival': {
              'required': false,
              'serviceProvider': {
                'name': 'Gepke',
                'portAuthorityId': 'VERSCHOOR'
              },
              'requiredQuantity': null,
              'remarks': null
            },
            'boatmenAtArrival': {
              'required': true,
              'serviceProvider': {
                'name': 'V.i.o.s.',
                'portAuthorityId': 'VIOS'
              },
              'remarks': null
            },
            'boatmenAtDeparture': null,
            'tugboatAtDeparture': {
              'required': false,
              'serviceProvider': {
                'name': 'Smit havensleepdienst B.V.',
                'portAuthorityId': 'SMIT'
              },
              'requiredQuantity': null,
              'remarks': null
            },
            'nextMovement': {
              'vesselDraft': 12.5,
              'vesselMasterName': 'Henk',
              'numberOfCrew': 20,
              'numberOfPassengers': 6,
              'cargo': 'BALLAST_CONDITION',
              'pilotExemption': {
                'holderName': 'Exemption holder 1',
                'number': '12345'
              },
              'offStandardBeam': {
                'value': 12.8,
                'remarks': 'No remarks'
              },
              'pilotService': null
            }
          }
        ],
        'dangerousGoodsLoading': true,
        'dangerousGoodsDischarge': false,
        'dangerousGoodsTransit': true,
        'possibleAnchorage': true,
        'vesselInspectionRequired': true,
        'shipConfiguration': 'DOUBLE_HULL_TANKER',
        'exitPoint': {
          'code': 'OM',
          'name': 'Oude Maas',
          'atSea': false
        },
        'etdPort': '2018-11-13T11:00:00Z',
        'atdPort': null
      },
      'departureVoyage': {
        'voyageNumber': '1234568',
        'carrier': {
          'customsId': '12345',
          'name': null,
          'scacCode': null,
          'smdgCode': null
        }
      },
      'nextPorts': [
        {
          'id': 'f94034d2d4624e778775625184f22091',
          'arrival': '2018-11-14T11:00:00Z',
          'departure': '2018-11-15T11:00:00Z',
          'port': {
            'name': 'Antwerpen',
            'locationUnCode': 'BEANR',
            'countryUnCode': 'BE',
            'euPort': true
          },
          'customsOffice': null
        },
        {
          'id': 'd8d6ca9c2fa44747b4d8f111a75e11c7',
          'arrival': '2018-11-22T11:00:00Z',
          'departure': '2018-11-23T11:00:00Z',
          'port': {
            'name': 'Taranto',
            'locationUnCode': 'ITTAR',
            'countryUnCode': 'IT',
            'euPort': true
          },
          'customsOffice': {
            'name': 'S.O.T. AEROPORTO DELLO STRETTO',
            'unCode': 'IT087101'
          }
        },
        {
          'id': 'f4e5100bad6d4aa4a2cff0412bf963e0',
          'arrival': '2018-11-24T11:00:00Z',
          'departure': '2018-11-27T11:00:00Z',
          'port': {
            'name': 'El Iskandariya (= Alexandria)',
            'locationUnCode': 'EGALY',
            'countryUnCode': 'EG',
            'euPort': false
          },
          'customsOffice': null
        }
      ]
    },
    'nextVisitDeclaration': [],
    'securityDeclaration': {
      'securityReportRequired': 'YES',
      'companySecurityOfficer': {
        'name': 'Jansen',
        'email': 'Jansen@hotmail.com',
        'phoneNumber': '123456789'
      },
      'currentSecurityLevel': 'SL1',
      'approvedSspDocumentOnBoard': false,
      'isscSecurityDocument': {
        'availability': 'YES',
        'expiryDate': '2017-01-01',
        'reasonNoValidIsscOnBoard': 'ik ben een gevaarlijke piraat',
        'issuer': {
          'code': '1',
          'name': 'AMERICAN BUREAU'
        }
      },
      'shipToShipActivities': [
        {
          'location': 'Ergens 1',
          'type': 'CARGO_TANK_CLEANING',
          'startDate': '2018-11-07',
          'endDate': '2018-11-08',
          'compliantWithSsp': true,
          'securityMeasures': 'Measures 1'
        },
        {
          'location': 'Ergens 2',
          'type': 'CHANGING_CREW',
          'startDate': '2018-11-06',
          'endDate': '2018-11-07',
          'compliantWithSsp': false,
          'securityMeasures': 'Measures 2'
        },
        {
          'location': 'Ergens 3',
          'type': 'DE_GASSING',
          'startDate': '2018-11-05',
          'endDate': '2018-11-06',
          'compliantWithSsp': true,
          'securityMeasures': 'Measures 3'
        },
        {
          'location': 'Ergens 4',
          'type': 'CARGO_TANK_CLEANING',
          'startDate': '2018-11-04',
          'endDate': '2018-11-05',
          'compliantWithSsp': false,
          'securityMeasures': 'Measures 4'
        },
        {
          'location': 'Ergens 5',
          'type': 'CHANGING_CREW',
          'startDate': '2018-11-03',
          'endDate': '2018-11-04',
          'compliantWithSsp': true,
          'securityMeasures': 'Measures 5'
        },
        {
          'location': 'Ergens 6',
          'type': 'DE_GASSING',
          'startDate': '2018-11-02',
          'endDate': '2018-11-03',
          'compliantWithSsp': false,
          'securityMeasures': 'Measures 6'
        },
        {
          'location': 'Ergens 7',
          'type': 'CARGO_TANK_CLEANING',
          'startDate': '2018-11-01',
          'endDate': '2018-11-02',
          'compliantWithSsp': true,
          'securityMeasures': 'Measures 7'
        },
        {
          'location': 'Ergens 8',
          'type': 'CHANGING_CREW',
          'startDate': '2018-10-31',
          'endDate': '2018-11-01',
          'compliantWithSsp': false,
          'securityMeasures': 'Measures 8'
        },
        {
          'location': 'Ergens 9',
          'type': 'DE_GASSING',
          'startDate': '2018-10-30',
          'endDate': '2018-10-31',
          'compliantWithSsp': true,
          'securityMeasures': 'Measures 9'
        }
      ],
      'securityRelatedMatter': 'Any security related matter to report'
    },
    'wasteDeclaration': null,
    'shipStoresDeclaration': null,
    'paxDeclarationSummaries': null,
    'uploads': {},
    'cancelled': true,
    'ataRegisteredByPa': false,
    'declarations': [
      {
        'type': 'VISIT',
        'status': 'ACCEPTED',
        'rejectReasons': null,
        'timeStamp': '2018-12-20T11:12:40.997Z'

      },
      {
        'type': 'WPCS',
        'status': 'DECLARED',
        'rejectReasons': null,
        'timeStamp': '2018-12-20T11:12:48.060Z'
      },
      {
        'type': 'SECURITY',
        'status': 'ACCEPTED',
        'rejectReasons': null,
        'timeStamp': '2018-12-20T11:13:13.063Z'
      },
      {
        'type': 'VISIT',
        'status': 'ACCEPTED',
        'rejectReasons': null,
        'timeStamp': '2018-12-24T10:31:08.960Z'
      }
    ],
    'berthVisitInfos': null,
    'expected': false
  },
  cancelledLaterVisit: {
    'crn': 'cancelledLaterVisit',
    'additionalViewers': [],
    'copyOfVisit': null,
    'etaPortAis': '2018-11-09T10:23:00Z',
    'portOfCall': {
      'port': {
        'name': 'Rotterdam',
        'locationUnCode': 'NLRTM',
        'countryUnCode': 'NL',
        'euPort': true
      },
      'portAuthority': {
        'shortName': 'HBRRTM',
        'fullName': 'Havenbedrijf Rotterdam N.V.',
        'emailAddress': 'HCC@portofrotterdam.com',
        'phoneNumber': '+31-10-2521195',
        'faxNumber': '+31-10-2521000',
        'address': 'Wilhelminakade 909',
        'zipCode': '3072 AP',
        'city': 'Rotterdam',
        'countryUnCode': 'NL',
        'countryName': 'Netherlands',
        'customsEORINumber': 'NL24354561',
        'contact': 'Diana van Eijk',
        'portAuthorityId': 'HbR'
      },
      'customsOffice': {
        'name': 'Rotterdam Haven/Kantoor Maasvlakte',
        'unCode': 'NL000396'
      },
      'ataReported': true,
      'atdReported': true,
      'atdPortReported': true,
      'paDeclarationRequired': true,
      'swDeclarationRequired': true,
      'wasteEnabled': true,
      'dangerousGoodsEnabled': true,
      'harbourDuesEnabled': false,
      'orderNauticalServicesEnabled': true,
      'seaBuoy': {
        'name': 'Maascenter',
        'code': 'MC'
      }
    },
    'vessel': {
      'imoCode': '8758457',
      'name': 'DAVE BLACKBURN',
      'radioCallSign': 'WB6043',
      'motUnCode': '1729',
      'motName': 'Passenger ship',
      'summerDeadWeight': 0,
      'maxWidth': 15.84,
      'manoeuvringSpeed': null,
      'flagCountryUnCode': 'US',
      'netTonnage': 1338,
      'grossTonnage': 1338,
      'registrationPlaceUnloCode': 'USMSY',
      'registrationPlaceName': 'New Orleans',
      'registrationDate': '1944-02-01',
      'mmsiNumber': null,
      'helispotPresent': false,
      'fullLength': 110,
      'flagCode': 'USA'
    },
    'owner': {
      'fullName': 'Ship Operator 1',
      'portAuthorityId': 'SO1_R',
      'shortName': 'SO1',
      'emailAddress': 'pietjepuk@yoyo.nl',
      'address': 'Ship Operatorlaan 1',
      'city': 'Ship Operatorstad',
      'countryUnCode': 'NL',
      'countryName': 'Netherlands',
      'zipCode': '1111SO',
      'phoneNumber': '0102522288',
      'faxNumber': null,
      'contact': 'test centrum',
      'customsEORINumber': 'NL123456789'
    },
    'declarant': {
      'fullName': 'Back Office 1',
      'portAuthorityId': 'BO1_R',
      'shortName': 'BO1',
      'emailAddress': 'pietjepuk@yoyo.nl',
      'address': 'Back Officelaan 1',
      'city': 'Back Officestad',
      'countryUnCode': 'NL',
      'countryName': 'Netherlands',
      'zipCode': '1111SO',
      'phoneNumber': '0102522288',
      'faxNumber': null,
      'contact': 'test centrum',
      'customsEORINumber': 'NL123456789'
    },
    'financialDeclarant': {
      'fullName': 'Ship Operator 1',
      'portAuthorityId': 'SO1_R',
      'shortName': 'SO1',
      'emailAddress': 'pietjepuk@yoyo.nl',
      'address': 'Ship Operatorlaan 1',
      'city': 'Ship Operatorstad',
      'countryUnCode': 'NL',
      'countryName': 'Netherlands',
      'zipCode': '1111SO',
      'phoneNumber': '0102522288',
      'faxNumber': null,
      'contact': 'test centrum',
      'customsEORINumber': 'NL123456789'
    },
    'nominatedFinancialDeclarant': null,
    'orderNauticalServices': true,
    'creditNumberHolderSameAsDeclarant': true,
    'cargoDeclarants': [
      {
        'fullName': 'Ship Operator 1',
        'portAuthorityId': 'SO1_R',
        'shortName': 'SO1',
        'emailAddress': 'd.reijnders@portbase.com',
        'address': 'Ship Operatorlaan 1',
        'city': 'Ship Operatorstad',
        'countryUnCode': 'NL',
        'countryName': 'Netherlands',
        'zipCode': '1111SO',
        'phoneNumber': '0102522288',
        'faxNumber': null,
        'contact': 'TC',
        'customsEORINumber': 'NL123456789'
      }
    ],
    'allowedViewers': [
      {
        'fullName': 'Ship Operator 1',
        'portAuthorityId': 'SO1_R',
        'shortName': 'SO1',
        'emailAddress': 'pietjepuk@yoyo.nl',
        'address': 'Ship Operatorlaan 1',
        'city': 'Ship Operatorstad',
        'countryUnCode': 'NL',
        'countryName': 'Netherlands',
        'zipCode': '1111SO',
        'phoneNumber': '0102522288',
        'faxNumber': null,
        'contact': 'test centrum',
        'customsEORINumber': 'NL123456789'
      },
      {
        'fullName': 'Ship Operator 1',
        'portAuthorityId': 'SO1_R',
        'shortName': 'SO1',
        'emailAddress': 'pietjepuk@yoyo.nl',
        'address': 'Ship Operatorlaan 1',
        'city': 'Ship Operatorstad',
        'countryUnCode': 'NL',
        'countryName': 'Netherlands',
        'zipCode': '1111SO',
        'phoneNumber': '0102522288',
        'faxNumber': null,
        'contact': 'test centrum',
        'customsEORINumber': 'NL123456789'
      }
    ],
    'visitDeclaration': {
      'clientReferenceNumber': '7',
      'etaFirstEntryEu': '2018-11-08T11:00:00Z',
      'arrivalVoyage': {
        'voyageNumber': '1234567',
        'carrier': {
          'customsId': 'NL100000897',
          'name': 'Carrier 1',
          'scacCode': 'CARA',
          'smdgCode': 'CAR'
        }
      },
      'previousPorts': [
        {
          'id': '7239cd11943846a78869f7260f0fc219',
          'arrival': '2018-11-07T11:00:00Z',
          'departure': '2018-11-08T11:00:00Z',
          'port': {
            'name': 'Barcelona',
            'locationUnCode': 'ESBCN',
            'countryUnCode': 'ES',
            'euPort': true
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'ESBCN-0001',
                'name': 'Barcelona, Spain  AUTOTERMINAL, S.A.'
              },
              'arrivalDate': '2018-11-07',
              'departureDate': '2018-11-08',
              'securityLevel': 'SL1',
              'additionalSecurityMeasures': 'Measures 1'
            }
          ]
        },
        {
          'id': '39af59bb1dd344339f84d12c4533bd03',
          'arrival': '2018-11-06T11:00:00Z',
          'departure': '2018-11-07T11:00:00Z',
          'port': {
            'name': 'Tunisi',
            'locationUnCode': 'ITTUN',
            'countryUnCode': 'IT',
            'euPort': true
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'ITTUN-9999',
                'name': 'UNKNOWN'
              },
              'arrivalDate': '2018-11-06',
              'departureDate': '2018-11-07',
              'securityLevel': 'SL2',
              'additionalSecurityMeasures': 'Measures 2'
            }
          ]
        },
        {
          'id': '8a49e9d4d39647188bb8bd3d57dd4fa7',
          'arrival': '2018-11-05T11:00:00Z',
          'departure': '2018-11-06T11:00:00Z',
          'port': {
            'name': 'Barcelona',
            'locationUnCode': 'ESBCN',
            'countryUnCode': 'ES',
            'euPort': true
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'ESBCN-0003',
                'name': 'Barcelona, Spain  CENTRO DE CONTROL'
              },
              'arrivalDate': '2018-11-05',
              'departureDate': '2018-11-06',
              'securityLevel': 'SL3',
              'additionalSecurityMeasures': 'Measures 3'
            }
          ]
        },
        {
          'id': '2f4ae45bc63643de9fe7678f8021940d',
          'arrival': '2018-11-04T11:00:00Z',
          'departure': '2018-11-05T11:00:00Z',
          'port': {
            'name': 'Tunisi',
            'locationUnCode': 'ITTUN',
            'countryUnCode': 'IT',
            'euPort': true
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'ITTUN-9999',
                'name': 'UNKNOWN'
              },
              'arrivalDate': '2018-11-04',
              'departureDate': '2018-11-05',
              'securityLevel': 'SL1',
              'additionalSecurityMeasures': 'Measures 4'
            }
          ]
        },
        {
          'id': 'bb63bcf604c44adc8aada67c61a367c2',
          'arrival': '2018-11-03T11:00:00Z',
          'departure': '2018-11-04T11:00:00Z',
          'port': {
            'name': 'Barcelona',
            'locationUnCode': 'ESBCN',
            'countryUnCode': 'ES',
            'euPort': true
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'ESBCN-0005',
                'name': 'Barcelona, Spain  COMPAÑÍA LOGISTICA DE HIDROCARBUROS (CLH)'
              },
              'arrivalDate': '2018-11-03',
              'departureDate': '2018-11-04',
              'securityLevel': 'SL2',
              'additionalSecurityMeasures': 'Measures 5'
            }
          ]
        },
        {
          'id': '24a4865f6a494d2abc9c8d477705ad5c',
          'arrival': '2018-11-02T11:00:00Z',
          'departure': '2018-11-03T11:00:00Z',
          'port': {
            'name': 'Tunisi',
            'locationUnCode': 'ITTUN',
            'countryUnCode': 'IT',
            'euPort': true
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'ITTUN-9999',
                'name': 'UNKNOWN'
              },
              'arrivalDate': '2018-11-02',
              'departureDate': '2018-11-03',
              'securityLevel': 'SL3',
              'additionalSecurityMeasures': 'Measures 6'
            }
          ]
        },
        {
          'id': 'e4f33384c9f94439855c194b8d5ededb',
          'arrival': '2018-11-01T11:00:00Z',
          'departure': '2018-11-02T11:00:00Z',
          'port': {
            'name': 'Barcelona',
            'locationUnCode': 'ESBCN',
            'countryUnCode': 'ES',
            'euPort': true
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'ESBCN-0007',
                'name': 'Barcelona, Spain  CORPORACIÓN DE PRÁCTICOS DEL PUERTO DE BARCELONA, S.L.P.'
              },
              'arrivalDate': '2018-11-01',
              'departureDate': '2018-11-02',
              'securityLevel': 'SL1',
              'additionalSecurityMeasures': 'Measures 7'
            }
          ]
        },
        {
          'id': 'a51f713703c94ce885487c6645a1c76e',
          'arrival': '2018-10-31T11:00:00Z',
          'departure': '2018-11-01T11:00:00Z',
          'port': {
            'name': 'Tunisi',
            'locationUnCode': 'ITTUN',
            'countryUnCode': 'IT',
            'euPort': true
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'ITTUN-9999',
                'name': 'UNKNOWN'
              },
              'arrivalDate': '2018-10-31',
              'departureDate': '2018-11-01',
              'securityLevel': 'SL2',
              'additionalSecurityMeasures': 'Measures 8'
            }
          ]
        },
        {
          'id': '0ffbad66973446f4b354c1f3d29a294d',
          'arrival': '2018-10-30T11:00:00Z',
          'departure': '2018-10-31T11:00:00Z',
          'port': {
            'name': 'Barcelona',
            'locationUnCode': 'ESBCN',
            'countryUnCode': 'ES',
            'euPort': true
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'ESBCN-0009',
                'name': 'Barcelona, Spain  DECAL ESPAÑA S.A.'
              },
              'arrivalDate': '2018-10-30',
              'departureDate': '2018-10-31',
              'securityLevel': 'SL3',
              'additionalSecurityMeasures': 'Measures 9'
            }
          ]
        },
        {
          'id': '2fe3987117624e4e982eadb5fcf6b30f',
          'arrival': '2018-10-29T11:00:00Z',
          'departure': '2018-10-30T11:00:00Z',
          'port': {
            'name': 'Tunisi',
            'locationUnCode': 'ITTUN',
            'countryUnCode': 'IT',
            'euPort': true
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'ITTUN-9999',
                'name': 'UNKNOWN'
              },
              'arrivalDate': '2018-10-29',
              'departureDate': '2018-10-30',
              'securityLevel': 'SL2',
              'additionalSecurityMeasures': 'Measures 10'
            }
          ]
        },
        {
          'id': '14b77c47a3b24a6bb058d9998f89f213',
          'arrival': '2018-10-28T11:00:00Z',
          'departure': '2018-10-29T11:00:00Z',
          'port': {
            'name': 'Barcelona',
            'locationUnCode': 'ESBCN',
            'countryUnCode': 'ES',
            'euPort': true
          },
          'portFacilityVisits': []
        },
        {
          'id': '62ba903b912543b18438f132d2409263',
          'arrival': '2018-10-27T11:00:00Z',
          'departure': '2018-10-28T11:00:00Z',
          'port': {
            'name': 'Tunisi',
            'locationUnCode': 'ITTUN',
            'countryUnCode': 'IT',
            'euPort': true
          },
          'portFacilityVisits': []
        }
      ],
      'portVisit': {
        'intention': 'REQUEST_FOR_ENTRY',
        'pilotStation': {
          'code': 'MC',
          'name': 'Maascenter',
          'atSea': true
        },
        'entryPoint': {
          'code': 'LL',
          'name': 'Lage Licht'
        },
        'etaPort': '2018-11-10T11:00:00Z',
        'firstMovement': {
          'vesselDraft': 6.0,
          'vesselMasterName': 'Henk',
          'numberOfCrew': 20,
          'numberOfPassengers': 6,
          'cargo': 'OUT_OF_GAUGE',
          'pilotExemption': {
            'holderName': '',
            'number': '16'
          },
          'offStandardBeam': {
            'value': 4.5,
            'remarks': ''
          },
          'pilotService': {
            'required': false
          }
        },
        'passingThroughTugboats': null,
        'defectTypes': [
          'DEN',
          'DWE',
          'TOW'
        ],
        'berthVisits': [
          {
            'id': '1abbb8789fe94b5c8adad25550a0a6b1',
            'berth': {
              'name': 'HOLLAND AMERIKAKADE',
              'code': 'R1243',
              'terminalCode': '52090469',
              'berthGroupCode': 'HOLLAND AMERIKAKADE',
              'buoy': false,
              'partyToNotify': null
            },
            'eta': '2018-11-09T11:00:00Z',
            'ata': null,
            'etd': '2018-11-09T22:00:00Z',
            'atd': null,
            'mooring': 'PORT_SIDE',
            'remarks': null,
            'visitPurposes': [
              'CARGO_TANK_CLEANING',
              'CUSTOMS_CLEARANCE',
              'DISPOSAL_OF_WASTE',
              'LAID_UP',
              'LOADING'
            ],
            'bollardFrom': null,
            'bollardTo': null,
            'tugboatAtArrival': {
              'required': true,
              'serviceProvider': {
                'name': 'Gepke',
                'portAuthorityId': 'VERSCHOOR'
              },
              'requiredQuantity': 'SIX',
              'remarks': null
            },
            'boatmenAtArrival': {
              'required': true,
              'serviceProvider': {
                'name': 'V.i.o.s.',
                'portAuthorityId': 'VIOS'
              },
              'remarks': null
            },
            'boatmenAtDeparture': {
              'required': true,
              'serviceProvider': {
                'name': 'V.i.o.s.',
                'portAuthorityId': 'VIOS'
              },
              'remarks': null
            },
            'tugboatAtDeparture': {
              'required': true,
              'serviceProvider': {
                'name': 'Gepke',
                'portAuthorityId': 'VERSCHOOR'
              },
              'requiredQuantity': 'SIX',
              'remarks': null
            },
            'nextMovement': {
              'vesselDraft': 7.0,
              'vesselMasterName': 'Piet',
              'numberOfCrew': 21,
              'numberOfPassengers': 7,
              'cargo': 'OUT_OF_GAUGE',
              'pilotExemption': {
                'holderName': '',
                'number': '16'
              },
              'offStandardBeam': {
                'value': 4.5,
                'remarks': ''
              },
              'pilotService': null
            }
          },
          {
            'id': 'c1324bb553b2451d907e63e48d74c3e1',
            'berth': {
              'name': 'DORDR PWA KADE',
              'code': 'D4358',
              'terminalCode': '51433242',
              'berthGroupCode': 'DORDR PWA KADE',
              'buoy': false,
              'partyToNotify': null
            },
            'eta': '2018-11-10T11:00:00Z',
            'ata': null,
            'etd': '2018-11-10T22:00:00Z',
            'atd': null,
            'mooring': 'ALONGSIDE_OTHER_SHIP',
            'remarks': null,
            'visitPurposes': [
              'DISCHARGE_CRUDE_OIL',
              'TAKING_SUPPLIES'
            ],
            'bollardFrom': null,
            'bollardTo': null,
            'tugboatAtArrival': {
              'required': false,
              'serviceProvider': {
                'name': 'Gepke',
                'portAuthorityId': 'VERSCHOOR'
              },
              'requiredQuantity': null,
              'remarks': null
            },
            'boatmenAtArrival': {
              'required': true,
              'serviceProvider': {
                'name': 'V.i.o.s.',
                'portAuthorityId': 'VIOS'
              },
              'remarks': null
            },
            'boatmenAtDeparture': null,
            'tugboatAtDeparture': {
              'required': true,
              'serviceProvider': {
                'name': 'Gepke',
                'portAuthorityId': 'VERSCHOOR'
              },
              'requiredQuantity': 'FIVE',
              'remarks': null
            },
            'nextMovement': {
              'vesselDraft': 8.0,
              'vesselMasterName': 'Henk',
              'numberOfCrew': 20,
              'numberOfPassengers': 6,
              'cargo': 'BALLAST_CONDITION',
              'pilotExemption': null,
              'offStandardBeam': {
                'value': 12.8,
                'remarks': 'No remarks'
              },
              'pilotService': {
                'required': true,
                'serviceProvider': {
                  'name': 'Pilot 1',
                  'portAuthorityId': 'PIL1'
                },
                'remarks': null
              }
            }
          },
          {
            'id': 'abb42b0d775d44fca7e2d392d16ff340',
            'berth': {
              'name': 'PET 3 B 61',
              'code': 'R61',
              'terminalCode': '47644472',
              'berthGroupCode': 'PET 3 B 61',
              'buoy': true,
              'partyToNotify': null
            },
            'eta': '2018-11-11T11:00:00Z',
            'ata': null,
            'etd': '2018-11-11T22:00:00Z',
            'atd': null,
            'mooring': 'STARBOARD_SIDE',
            'remarks': null,
            'visitPurposes': [
              'CREW_MOVEMENT',
              'LOADING',
              'REFUGE'
            ],
            'bollardFrom': 16.2,
            'bollardTo': 20.2,
            'tugboatAtArrival': {
              'required': false,
              'serviceProvider': {
                'name': 'Gepke',
                'portAuthorityId': 'VERSCHOOR'
              },
              'requiredQuantity': null,
              'remarks': null
            },
            'boatmenAtArrival': {
              'required': true,
              'serviceProvider': {
                'name': 'V.i.o.s.',
                'portAuthorityId': 'VIOS'
              },
              'remarks': null
            },
            'boatmenAtDeparture': null,
            'tugboatAtDeparture': {
              'required': false,
              'serviceProvider': {
                'name': 'Smit havensleepdienst B.V.',
                'portAuthorityId': 'SMIT'
              },
              'requiredQuantity': null,
              'remarks': null
            },
            'nextMovement': {
              'vesselDraft': 12.5,
              'vesselMasterName': 'Henk',
              'numberOfCrew': 20,
              'numberOfPassengers': 6,
              'cargo': 'BALLAST_CONDITION',
              'pilotExemption': {
                'holderName': 'Exemption holder 1',
                'number': '12345'
              },
              'offStandardBeam': {
                'value': 12.8,
                'remarks': 'No remarks'
              },
              'pilotService': null
            }
          }
        ],
        'dangerousGoodsLoading': true,
        'dangerousGoodsDischarge': false,
        'dangerousGoodsTransit': true,
        'possibleAnchorage': true,
        'vesselInspectionRequired': true,
        'shipConfiguration': 'DOUBLE_HULL_TANKER',
        'exitPoint': {
          'code': 'OM',
          'name': 'Oude Maas',
          'atSea': false
        },
        'etdPort': '2018-11-13T11:00:00Z',
        'atdPort': null
      },
      'departureVoyage': {
        'voyageNumber': '1234568',
        'carrier': {
          'customsId': '12345',
          'name': null,
          'scacCode': null,
          'smdgCode': null
        }
      },
      'nextPorts': [
        {
          'id': 'f94034d2d4624e778775625184f22091',
          'arrival': '2018-11-14T11:00:00Z',
          'departure': '2018-11-15T11:00:00Z',
          'port': {
            'name': 'Antwerpen',
            'locationUnCode': 'BEANR',
            'countryUnCode': 'BE',
            'euPort': true
          },
          'customsOffice': null
        },
        {
          'id': 'd8d6ca9c2fa44747b4d8f111a75e11c7',
          'arrival': '2018-11-22T11:00:00Z',
          'departure': '2018-11-23T11:00:00Z',
          'port': {
            'name': 'Taranto',
            'locationUnCode': 'ITTAR',
            'countryUnCode': 'IT',
            'euPort': true
          },
          'customsOffice': {
            'name': 'S.O.T. AEROPORTO DELLO STRETTO',
            'unCode': 'IT087101'
          }
        },
        {
          'id': 'f4e5100bad6d4aa4a2cff0412bf963e0',
          'arrival': '2018-11-24T11:00:00Z',
          'departure': '2018-11-27T11:00:00Z',
          'port': {
            'name': 'El Iskandariya (= Alexandria)',
            'locationUnCode': 'EGALY',
            'countryUnCode': 'EG',
            'euPort': false
          },
          'customsOffice': null
        }
      ]
    },
    'nextVisitDeclaration': [],
    'securityDeclaration': {
      'securityReportRequired': 'YES',
      'companySecurityOfficer': {
        'name': 'Jansen',
        'email': 'Jansen@hotmail.com',
        'phoneNumber': '123456789'
      },
      'currentSecurityLevel': 'SL1',
      'approvedSspDocumentOnBoard': false,
      'isscSecurityDocument': {
        'availability': 'YES',
        'expiryDate': '2017-01-01',
        'reasonNoValidIsscOnBoard': 'ik ben een gevaarlijke piraat',
        'issuer': {
          'code': '1',
          'name': 'AMERICAN BUREAU'
        }
      },
      'shipToShipActivities': [
        {
          'location': 'Ergens 1',
          'type': 'CARGO_TANK_CLEANING',
          'startDate': '2018-11-07',
          'endDate': '2018-11-08',
          'compliantWithSsp': true,
          'securityMeasures': 'Measures 1'
        },
        {
          'location': 'Ergens 2',
          'type': 'CHANGING_CREW',
          'startDate': '2018-11-06',
          'endDate': '2018-11-07',
          'compliantWithSsp': false,
          'securityMeasures': 'Measures 2'
        },
        {
          'location': 'Ergens 3',
          'type': 'DE_GASSING',
          'startDate': '2018-11-05',
          'endDate': '2018-11-06',
          'compliantWithSsp': true,
          'securityMeasures': 'Measures 3'
        },
        {
          'location': 'Ergens 4',
          'type': 'CARGO_TANK_CLEANING',
          'startDate': '2018-11-04',
          'endDate': '2018-11-05',
          'compliantWithSsp': false,
          'securityMeasures': 'Measures 4'
        },
        {
          'location': 'Ergens 5',
          'type': 'CHANGING_CREW',
          'startDate': '2018-11-03',
          'endDate': '2018-11-04',
          'compliantWithSsp': true,
          'securityMeasures': 'Measures 5'
        },
        {
          'location': 'Ergens 6',
          'type': 'DE_GASSING',
          'startDate': '2018-11-02',
          'endDate': '2018-11-03',
          'compliantWithSsp': false,
          'securityMeasures': 'Measures 6'
        },
        {
          'location': 'Ergens 7',
          'type': 'CARGO_TANK_CLEANING',
          'startDate': '2018-11-01',
          'endDate': '2018-11-02',
          'compliantWithSsp': true,
          'securityMeasures': 'Measures 7'
        },
        {
          'location': 'Ergens 8',
          'type': 'CHANGING_CREW',
          'startDate': '2018-10-31',
          'endDate': '2018-11-01',
          'compliantWithSsp': false,
          'securityMeasures': 'Measures 8'
        },
        {
          'location': 'Ergens 9',
          'type': 'DE_GASSING',
          'startDate': '2018-10-30',
          'endDate': '2018-10-31',
          'compliantWithSsp': true,
          'securityMeasures': 'Measures 9'
        }
      ],
      'securityRelatedMatter': 'Any security related matter to report'
    },
    'wasteDeclaration': null,
    'shipStoresDeclaration': null,
    'paxDeclarationSummaries': null,
    'uploads': {},
    'cancelled': true,
    'ataRegisteredByPa': false,
    'declarations': [
      {
        'type': 'VISIT',
        'status': 'ACCEPTED',
        'rejectReasons': null,
        'timeStamp': '2018-12-20T11:12:40.997Z'

      },
      {
        'type': 'WPCS',
        'status': 'DECLARED',
        'rejectReasons': null,
        'timeStamp': '2018-12-20T11:12:48.060Z'
      },
      {
        'type': 'SECURITY',
        'status': 'ACCEPTED',
        'rejectReasons': null,
        'timeStamp': '2018-12-20T11:13:13.063Z'
      },
      {
        'type': 'VISIT',
        'status': 'ACCEPTED',
        'rejectReasons': null,
        'timeStamp': '2018-12-24T10:31:08.960Z'
      }
    ],
    'berthVisitInfos': null,
    'expected': false
  },
  paxNota: {
    'crn': 'paxNota',
    'additionalViewers': [],
    'copyOfVisit': null,
    'etaPortAis': '2018-11-08T10:23:00Z',
    'portOfCall': {
      'port': {
        'name': 'Rotterdam',
        'locationUnCode': 'NLRTM',
        'countryUnCode': 'NL',
        'euPort': true
      },
      'portAuthority': {
        'shortName': 'HBRRTM',
        'fullName': 'Havenbedrijf Rotterdam N.V.',
        'emailAddress': 'HCC@portofrotterdam.com',
        'phoneNumber': '+31-10-2521195',
        'faxNumber': '+31-10-2521000',
        'address': 'Wilhelminakade 909',
        'zipCode': '3072 AP',
        'city': 'Rotterdam',
        'countryUnCode': 'NL',
        'countryName': 'Netherlands',
        'customsEORINumber': 'NL24354561',
        'contact': 'Diana van Eijk',
        'portAuthorityId': 'HbR'
      },
      'customsOffice': {
        'name': 'Rotterdam Haven/Kantoor Maasvlakte',
        'unCode': 'NL000396'
      },
      'ataReported': true,
      'atdReported': true,
      'atdPortReported': true,
      'paDeclarationRequired': true,
      'swDeclarationRequired': true,
      'wasteEnabled': true,
      'dangerousGoodsEnabled': true,
      'harbourDuesEnabled': false,
      'orderNauticalServicesEnabled': true,
      'seaBuoy': {
        'name': 'Maascenter',
        'code': 'MC'
      }
    },
    'vessel': {
      'imoCode': '8758457',
      'name': 'DAVE BLACKBURN',
      'radioCallSign': 'WB6043',
      'motUnCode': '1729',
      'motName': 'Passenger ship',
      'summerDeadWeight': 0,
      'maxWidth': 15.84,
      'manoeuvringSpeed': null,
      'flagCountryUnCode': 'US',
      'netTonnage': 1338,
      'grossTonnage': 1338,
      'registrationPlaceUnloCode': 'USMSY',
      'registrationPlaceName': 'New Orleans',
      'registrationDate': '1944-02-01',
      'mmsiNumber': null,
      'helispotPresent': false,
      'fullLength': 110,
      'flagCode': 'USA'
    },
    'owner': {
      'fullName': 'Ship Operator 1',
      'portAuthorityId': 'SO1_R',
      'shortName': 'SO1',
      'emailAddress': 'pietjepuk@yoyo.nl',
      'address': 'Ship Operatorlaan 1',
      'city': 'Ship Operatorstad',
      'countryUnCode': 'NL',
      'countryName': 'Netherlands',
      'zipCode': '1111SO',
      'phoneNumber': '0102522288',
      'faxNumber': null,
      'contact': 'test centrum',
      'customsEORINumber': 'NL123456789'
    },
    'declarant': {
      'fullName': 'Back Office 1',
      'portAuthorityId': 'BO1_R',
      'shortName': 'BO1',
      'emailAddress': 'pietjepuk@yoyo.nl',
      'address': 'Back Officelaan 1',
      'city': 'Back Officestad',
      'countryUnCode': 'NL',
      'countryName': 'Netherlands',
      'zipCode': '1111SO',
      'phoneNumber': '0102522288',
      'faxNumber': null,
      'contact': 'test centrum',
      'customsEORINumber': 'NL123456789'
    },
    'financialDeclarant': {
      'fullName': 'Ship Operator 1',
      'portAuthorityId': 'SO1_R',
      'shortName': 'SO1',
      'emailAddress': 'pietjepuk@yoyo.nl',
      'address': 'Ship Operatorlaan 1',
      'city': 'Ship Operatorstad',
      'countryUnCode': 'NL',
      'countryName': 'Netherlands',
      'zipCode': '1111SO',
      'phoneNumber': '0102522288',
      'faxNumber': null,
      'contact': 'test centrum',
      'customsEORINumber': 'NL123456789'
    },
    'nominatedFinancialDeclarant': null,
    'orderNauticalServices': true,
    'creditNumberHolderSameAsDeclarant': true,
    'cargoDeclarants': [
      {
        'fullName': 'Ship Operator 1',
        'portAuthorityId': 'SO1_R',
        'shortName': 'SO1',
        'emailAddress': 'd.reijnders@portbase.com',
        'address': 'Ship Operatorlaan 1',
        'city': 'Ship Operatorstad',
        'countryUnCode': 'NL',
        'countryName': 'Netherlands',
        'zipCode': '1111SO',
        'phoneNumber': '0102522288',
        'faxNumber': null,
        'contact': 'TC',
        'customsEORINumber': 'NL123456789'
      }
    ],
    'allowedViewers': [
      {
        'fullName': 'Ship Operator 1',
        'portAuthorityId': 'SO1_R',
        'shortName': 'SO1',
        'emailAddress': 'pietjepuk@yoyo.nl',
        'address': 'Ship Operatorlaan 1',
        'city': 'Ship Operatorstad',
        'countryUnCode': 'NL',
        'countryName': 'Netherlands',
        'zipCode': '1111SO',
        'phoneNumber': '0102522288',
        'faxNumber': null,
        'contact': 'test centrum',
        'customsEORINumber': 'NL123456789'
      },
      {
        'fullName': 'Ship Operator 1',
        'portAuthorityId': 'SO1_R',
        'shortName': 'SO1',
        'emailAddress': 'pietjepuk@yoyo.nl',
        'address': 'Ship Operatorlaan 1',
        'city': 'Ship Operatorstad',
        'countryUnCode': 'NL',
        'countryName': 'Netherlands',
        'zipCode': '1111SO',
        'phoneNumber': '0102522288',
        'faxNumber': null,
        'contact': 'test centrum',
        'customsEORINumber': 'NL123456789'
      }
    ],
    'visitDeclaration': {
      'clientReferenceNumber': '7',
      'etaFirstEntryEu': '2018-11-08T11:00:00Z',
      'arrivalVoyage': {
        'voyageNumber': '1234567',
        'carrier': {
          'customsId': 'NL100000897',
          'name': 'Carrier 1',
          'scacCode': 'CARA',
          'smdgCode': 'CAR'
        }
      },
      'previousPorts': [
        {
          'id': '7239cd11943846a78869f7260f0fc219',
          'arrival': '2018-11-07T11:00:00Z',
          'departure': '2018-11-08T11:00:00Z',
          'port': {
            'name': 'Barcelona',
            'locationUnCode': 'ESBCN',
            'countryUnCode': 'ES',
            'euPort': true
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'ESBCN-0001',
                'name': 'Barcelona, Spain  AUTOTERMINAL, S.A.'
              },
              'arrivalDate': '2018-11-07',
              'departureDate': '2018-11-08',
              'securityLevel': 'SL1',
              'additionalSecurityMeasures': 'Measures 1'
            }
          ]
        },
        {
          'id': '39af59bb1dd344339f84d12c4533bd03',
          'arrival': '2018-11-06T11:00:00Z',
          'departure': '2018-11-07T11:00:00Z',
          'port': {
            'name': 'Tunisi',
            'locationUnCode': 'ITTUN',
            'countryUnCode': 'IT',
            'euPort': true
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'ITTUN-9999',
                'name': 'UNKNOWN'
              },
              'arrivalDate': '2018-11-06',
              'departureDate': '2018-11-07',
              'securityLevel': 'SL2',
              'additionalSecurityMeasures': 'Measures 2'
            }
          ]
        },
        {
          'id': '8a49e9d4d39647188bb8bd3d57dd4fa7',
          'arrival': '2018-11-05T11:00:00Z',
          'departure': '2018-11-06T11:00:00Z',
          'port': {
            'name': 'Barcelona',
            'locationUnCode': 'ESBCN',
            'countryUnCode': 'ES',
            'euPort': true
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'ESBCN-0003',
                'name': 'Barcelona, Spain  CENTRO DE CONTROL'
              },
              'arrivalDate': '2018-11-05',
              'departureDate': '2018-11-06',
              'securityLevel': 'SL3',
              'additionalSecurityMeasures': 'Measures 3'
            }
          ]
        },
        {
          'id': '2f4ae45bc63643de9fe7678f8021940d',
          'arrival': '2018-11-04T11:00:00Z',
          'departure': '2018-11-05T11:00:00Z',
          'port': {
            'name': 'Tunisi',
            'locationUnCode': 'ITTUN',
            'countryUnCode': 'IT',
            'euPort': true
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'ITTUN-9999',
                'name': 'UNKNOWN'
              },
              'arrivalDate': '2018-11-04',
              'departureDate': '2018-11-05',
              'securityLevel': 'SL1',
              'additionalSecurityMeasures': 'Measures 4'
            }
          ]
        },
        {
          'id': 'bb63bcf604c44adc8aada67c61a367c2',
          'arrival': '2018-11-03T11:00:00Z',
          'departure': '2018-11-04T11:00:00Z',
          'port': {
            'name': 'Barcelona',
            'locationUnCode': 'ESBCN',
            'countryUnCode': 'ES',
            'euPort': true
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'ESBCN-0005',
                'name': 'Barcelona, Spain  COMPAÑÍA LOGISTICA DE HIDROCARBUROS (CLH)'
              },
              'arrivalDate': '2018-11-03',
              'departureDate': '2018-11-04',
              'securityLevel': 'SL2',
              'additionalSecurityMeasures': 'Measures 5'
            }
          ]
        },
        {
          'id': '24a4865f6a494d2abc9c8d477705ad5c',
          'arrival': '2018-11-02T11:00:00Z',
          'departure': '2018-11-03T11:00:00Z',
          'port': {
            'name': 'Tunisi',
            'locationUnCode': 'ITTUN',
            'countryUnCode': 'IT',
            'euPort': true
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'ITTUN-9999',
                'name': 'UNKNOWN'
              },
              'arrivalDate': '2018-11-02',
              'departureDate': '2018-11-03',
              'securityLevel': 'SL3',
              'additionalSecurityMeasures': 'Measures 6'
            }
          ]
        },
        {
          'id': 'e4f33384c9f94439855c194b8d5ededb',
          'arrival': '2018-11-01T11:00:00Z',
          'departure': '2018-11-02T11:00:00Z',
          'port': {
            'name': 'Barcelona',
            'locationUnCode': 'ESBCN',
            'countryUnCode': 'ES',
            'euPort': true
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'ESBCN-0007',
                'name': 'Barcelona, Spain  CORPORACIÓN DE PRÁCTICOS DEL PUERTO DE BARCELONA, S.L.P.'
              },
              'arrivalDate': '2018-11-01',
              'departureDate': '2018-11-02',
              'securityLevel': 'SL1',
              'additionalSecurityMeasures': 'Measures 7'
            }
          ]
        },
        {
          'id': 'a51f713703c94ce885487c6645a1c76e',
          'arrival': '2018-10-31T11:00:00Z',
          'departure': '2018-11-01T11:00:00Z',
          'port': {
            'name': 'Tunisi',
            'locationUnCode': 'ITTUN',
            'countryUnCode': 'IT',
            'euPort': true
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'ITTUN-9999',
                'name': 'UNKNOWN'
              },
              'arrivalDate': '2018-10-31',
              'departureDate': '2018-11-01',
              'securityLevel': 'SL2',
              'additionalSecurityMeasures': 'Measures 8'
            }
          ]
        },
        {
          'id': '0ffbad66973446f4b354c1f3d29a294d',
          'arrival': '2018-10-30T11:00:00Z',
          'departure': '2018-10-31T11:00:00Z',
          'port': {
            'name': 'Barcelona',
            'locationUnCode': 'ESBCN',
            'countryUnCode': 'ES',
            'euPort': true
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'ESBCN-0009',
                'name': 'Barcelona, Spain  DECAL ESPAÑA S.A.'
              },
              'arrivalDate': '2018-10-30',
              'departureDate': '2018-10-31',
              'securityLevel': 'SL3',
              'additionalSecurityMeasures': 'Measures 9'
            }
          ]
        },
        {
          'id': '2fe3987117624e4e982eadb5fcf6b30f',
          'arrival': '2018-10-29T11:00:00Z',
          'departure': '2018-10-30T11:00:00Z',
          'port': {
            'name': 'Tunisi',
            'locationUnCode': 'ITTUN',
            'countryUnCode': 'IT',
            'euPort': true
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'ITTUN-9999',
                'name': 'UNKNOWN'
              },
              'arrivalDate': '2018-10-29',
              'departureDate': '2018-10-30',
              'securityLevel': 'SL2',
              'additionalSecurityMeasures': 'Measures 10'
            }
          ]
        },
        {
          'id': '14b77c47a3b24a6bb058d9998f89f213',
          'arrival': '2018-10-28T11:00:00Z',
          'departure': '2018-10-29T11:00:00Z',
          'port': {
            'name': 'Barcelona',
            'locationUnCode': 'ESBCN',
            'countryUnCode': 'ES',
            'euPort': true
          },
          'portFacilityVisits': []
        },
        {
          'id': '62ba903b912543b18438f132d2409263',
          'arrival': '2018-10-27T11:00:00Z',
          'departure': '2018-10-28T11:00:00Z',
          'port': {
            'name': 'Tunisi',
            'locationUnCode': 'ITTUN',
            'countryUnCode': 'IT',
            'euPort': true
          },
          'portFacilityVisits': []
        }
      ],
      'portVisit': {
        'intention': 'REQUEST_FOR_ENTRY',
        'pilotStation': {
          'code': 'MC',
          'name': 'Maascenter',
          'atSea': true
        },
        'entryPoint': {
          'code': 'LL',
          'name': 'Lage Licht'
        },
        'etaPort': '2018-11-08T11:00:00Z',
        'firstMovement': {
          'vesselDraft': 6.0,
          'vesselMasterName': 'Henk',
          'numberOfCrew': 20,
          'numberOfPassengers': 6,
          'cargo': 'OUT_OF_GAUGE',
          'pilotExemption': {
            'holderName': '',
            'number': '16'
          },
          'offStandardBeam': {
            'value': 4.5,
            'remarks': ''
          },
          'pilotService': {
            'required': false
          }
        },
        'passingThroughTugboats': null,
        'defectTypes': [
          'DEN',
          'DWE',
          'TOW'
        ],
        'berthVisits': [
          {
            'id': '1abbb8789fe94b5c8adad25550a0a6b1',
            'berth': {
              'name': 'HOLLAND AMERIKAKADE',
              'code': 'R1243',
              'terminalCode': '52090469',
              'berthGroupCode': 'HOLLAND AMERIKAKADE',
              'buoy': false,
              'partyToNotify': null
            },
            'eta': '2018-11-09T11:00:00Z',
            'ata': '2018-11-09T11:15:00Z',
            'etd': '2018-11-10T22:00:00Z',
            'atd': null,
            'mooring': 'PORT_SIDE',
            'remarks': null,
            'visitPurposes': [
              'CARGO_TANK_CLEANING',
              'CUSTOMS_CLEARANCE',
              'DISPOSAL_OF_WASTE',
              'LAID_UP',
              'LOADING'
            ],
            'bollardFrom': null,
            'bollardTo': null,
            'tugboatAtArrival': {
              'required': true,
              'serviceProvider': {
                'name': 'Gepke',
                'portAuthorityId': 'VERSCHOOR'
              },
              'requiredQuantity': 'SIX',
              'remarks': null
            },
            'boatmenAtArrival': {
              'required': true,
              'serviceProvider': {
                'name': 'V.i.o.s.',
                'portAuthorityId': 'VIOS'
              },
              'remarks': null
            },
            'boatmenAtDeparture': {
              'required': true,
              'serviceProvider': {
                'name': 'V.i.o.s.',
                'portAuthorityId': 'VIOS'
              },
              'remarks': null
            },
            'tugboatAtDeparture': {
              'required': true,
              'serviceProvider': {
                'name': 'Gepke',
                'portAuthorityId': 'VERSCHOOR'
              },
              'requiredQuantity': 'SIX',
              'remarks': null
            },
            'nextMovement': {
              'vesselDraft': 7.0,
              'vesselMasterName': 'Piet',
              'numberOfCrew': 21,
              'numberOfPassengers': 7,
              'cargo': 'OUT_OF_GAUGE',
              'pilotExemption': {
                'holderName': '',
                'number': '16'
              },
              'offStandardBeam': {
                'value': 4.5,
                'remarks': ''
              },
              'pilotService': null
            }
          },
          {
            'id': 'c1324bb553b2451d907e63e48d74c3e1',
            'berth': {
              'name': 'DORDR PWA KADE',
              'code': 'D4358',
              'terminalCode': '51433242',
              'berthGroupCode': 'DORDR PWA KADE',
              'buoy': false,
              'partyToNotify': null
            },
            'eta': '2018-11-10T11:00:00Z',
            'ata': null,
            'etd': '2018-11-10T22:00:00Z',
            'atd': null,
            'mooring': 'ALONGSIDE_OTHER_SHIP',
            'remarks': null,
            'visitPurposes': [
              'DISCHARGE_CRUDE_OIL',
              'TAKING_SUPPLIES'
            ],
            'bollardFrom': null,
            'bollardTo': null,
            'tugboatAtArrival': {
              'required': false,
              'serviceProvider': {
                'name': 'Gepke',
                'portAuthorityId': 'VERSCHOOR'
              },
              'requiredQuantity': null,
              'remarks': null
            },
            'boatmenAtArrival': {
              'required': true,
              'serviceProvider': {
                'name': 'V.i.o.s.',
                'portAuthorityId': 'VIOS'
              },
              'remarks': null
            },
            'boatmenAtDeparture': null,
            'tugboatAtDeparture': {
              'required': true,
              'serviceProvider': {
                'name': 'Gepke',
                'portAuthorityId': 'VERSCHOOR'
              },
              'requiredQuantity': 'FIVE',
              'remarks': null
            },
            'nextMovement': {
              'vesselDraft': 8.0,
              'vesselMasterName': 'Henk',
              'numberOfCrew': 20,
              'numberOfPassengers': 6,
              'cargo': 'BALLAST_CONDITION',
              'pilotExemption': null,
              'offStandardBeam': {
                'value': 12.8,
                'remarks': 'No remarks'
              },
              'pilotService': {
                'required': true,
                'serviceProvider': {
                  'name': 'Pilot 1',
                  'portAuthorityId': 'PIL1'
                },
                'remarks': null
              }
            }
          },
          {
            'id': 'abb42b0d775d44fca7e2d392d16ff340',
            'berth': {
              'name': 'PET 3 B 61',
              'code': 'R61',
              'terminalCode': '47644472',
              'berthGroupCode': 'PET 3 B 61',
              'buoy': true,
              'partyToNotify': null
            },
            'eta': '2018-11-11T11:00:00Z',
            'ata': null,
            'etd': '2018-11-11T22:00:00Z',
            'atd': null,
            'mooring': 'STARBOARD_SIDE',
            'remarks': null,
            'visitPurposes': [
              'CREW_MOVEMENT',
              'LOADING',
              'REFUGE'
            ],
            'bollardFrom': 16.2,
            'bollardTo': 20.2,
            'tugboatAtArrival': {
              'required': false,
              'serviceProvider': {
                'name': 'Gepke',
                'portAuthorityId': 'VERSCHOOR'
              },
              'requiredQuantity': null,
              'remarks': null
            },
            'boatmenAtArrival': {
              'required': true,
              'serviceProvider': {
                'name': 'V.i.o.s.',
                'portAuthorityId': 'VIOS'
              },
              'remarks': null
            },
            'boatmenAtDeparture': null,
            'tugboatAtDeparture': {
              'required': false,
              'serviceProvider': {
                'name': 'Smit havensleepdienst B.V.',
                'portAuthorityId': 'SMIT'
              },
              'requiredQuantity': null,
              'remarks': null
            },
            'nextMovement': {
              'vesselDraft': 12.5,
              'vesselMasterName': 'Henk',
              'numberOfCrew': 20,
              'numberOfPassengers': 6,
              'cargo': 'BALLAST_CONDITION',
              'pilotExemption': {
                'holderName': 'Exemption holder 1',
                'number': '12345'
              },
              'offStandardBeam': {
                'value': 12.8,
                'remarks': 'No remarks'
              },
              'pilotService': null
            }
          }
        ],
        'dangerousGoodsLoading': true,
        'dangerousGoodsDischarge': false,
        'dangerousGoodsTransit': true,
        'possibleAnchorage': true,
        'vesselInspectionRequired': true,
        'shipConfiguration': 'DOUBLE_HULL_TANKER',
        'exitPoint': {
          'code': 'OM',
          'name': 'Oude Maas',
          'atSea': false
        },
        'etdPort': '2018-11-13T11:00:00Z',
        'atdPort': null
      },
      'departureVoyage': {
        'voyageNumber': '1234568',
        'carrier': {
          'customsId': '12345',
          'name': null,
          'scacCode': null,
          'smdgCode': null
        }
      },
      'nextPorts': [
        {
          'id': 'f94034d2d4624e778775625184f22091',
          'arrival': '2018-11-14T11:00:00Z',
          'departure': '2018-11-15T11:00:00Z',
          'port': {
            'name': 'Antwerpen',
            'locationUnCode': 'BEANR',
            'countryUnCode': 'BE',
            'euPort': true
          },
          'customsOffice': null
        },
        {
          'id': 'd8d6ca9c2fa44747b4d8f111a75e11c7',
          'arrival': '2018-11-22T11:00:00Z',
          'departure': '2018-11-23T11:00:00Z',
          'port': {
            'name': 'Taranto',
            'locationUnCode': 'ITTAR',
            'countryUnCode': 'IT',
            'euPort': true
          },
          'customsOffice': {
            'name': 'S.O.T. AEROPORTO DELLO STRETTO',
            'unCode': 'IT087101'
          }
        },
        {
          'id': 'f4e5100bad6d4aa4a2cff0412bf963e0',
          'arrival': '2018-11-24T11:00:00Z',
          'departure': '2018-11-27T11:00:00Z',
          'port': {
            'name': 'El Iskandariya (= Alexandria)',
            'locationUnCode': 'EGALY',
            'countryUnCode': 'EG',
            'euPort': false
          },
          'customsOffice': null
        }
      ]
    },
    'nextVisitDeclaration': [],
    'securityDeclaration': {
      'securityReportRequired': 'YES',
      'companySecurityOfficer': {
        'name': 'Jansen',
        'email': 'Jansen@hotmail.com',
        'phoneNumber': '123456789'
      },
      'currentSecurityLevel': 'SL1',
      'approvedSspDocumentOnBoard': false,
      'isscSecurityDocument': {
        'availability': 'YES',
        'expiryDate': '2017-01-01',
        'reasonNoValidIsscOnBoard': 'ik ben een gevaarlijke piraat',
        'issuer': {
          'code': '1',
          'name': 'AMERICAN BUREAU'
        }
      },
      'shipToShipActivities': [
        {
          'location': 'Ergens 1',
          'type': 'CARGO_TANK_CLEANING',
          'startDate': '2018-11-07',
          'endDate': '2018-11-08',
          'compliantWithSsp': true,
          'securityMeasures': 'Measures 1'
        },
        {
          'location': 'Ergens 2',
          'type': 'CHANGING_CREW',
          'startDate': '2018-11-06',
          'endDate': '2018-11-07',
          'compliantWithSsp': false,
          'securityMeasures': 'Measures 2'
        },
        {
          'location': 'Ergens 3',
          'type': 'DE_GASSING',
          'startDate': '2018-11-05',
          'endDate': '2018-11-06',
          'compliantWithSsp': true,
          'securityMeasures': 'Measures 3'
        },
        {
          'location': 'Ergens 4',
          'type': 'CARGO_TANK_CLEANING',
          'startDate': '2018-11-04',
          'endDate': '2018-11-05',
          'compliantWithSsp': false,
          'securityMeasures': 'Measures 4'
        },
        {
          'location': 'Ergens 5',
          'type': 'CHANGING_CREW',
          'startDate': '2018-11-03',
          'endDate': '2018-11-04',
          'compliantWithSsp': true,
          'securityMeasures': 'Measures 5'
        },
        {
          'location': 'Ergens 6',
          'type': 'DE_GASSING',
          'startDate': '2018-11-02',
          'endDate': '2018-11-03',
          'compliantWithSsp': false,
          'securityMeasures': 'Measures 6'
        },
        {
          'location': 'Ergens 7',
          'type': 'CARGO_TANK_CLEANING',
          'startDate': '2018-11-01',
          'endDate': '2018-11-02',
          'compliantWithSsp': true,
          'securityMeasures': 'Measures 7'
        },
        {
          'location': 'Ergens 8',
          'type': 'CHANGING_CREW',
          'startDate': '2018-10-31',
          'endDate': '2018-11-01',
          'compliantWithSsp': false,
          'securityMeasures': 'Measures 8'
        },
        {
          'location': 'Ergens 9',
          'type': 'DE_GASSING',
          'startDate': '2018-10-30',
          'endDate': '2018-10-31',
          'compliantWithSsp': true,
          'securityMeasures': 'Measures 9'
        }
      ],
      'securityRelatedMatter': 'Any security related matter to report'
    },
    'wasteDeclaration': null,
    'shipStoresDeclaration': null,
    'paxDeclaration': null,
    'uploads': {},
    'cancelled': false,
    'ataRegisteredByPa': false,
    'declarations': [
      {
        'type': 'VISIT',
        'status': 'ACCEPTED',
        'rejectReasons': null,
        'timeStamp': '2018-12-20T11:12:40.997Z'

      },
      {
        'type': 'WPCS',
        'status': 'DECLARED',
        'rejectReasons': null,
        'timeStamp': '2018-12-20T11:12:48.060Z'
      },
      {
        'type': 'SECURITY',
        'status': 'ACCEPTED',
        'rejectReasons': null,
        'timeStamp': '2018-12-20T11:13:13.063Z'
      },
      {
        'type': 'VISIT',
        'status': 'ACCEPTED',
        'rejectReasons': null,
        'timeStamp': '2018-12-24T10:31:08.960Z'
      },
      {
        'type': 'PAX',
        'status': 'ACCEPTED',
        'rejectReasons': null,
        'timeStamp': '2018-12-24T10:32:08.960Z'
      }
    ],
    'visitStatus': 'ARRIVED',
    'expected': false
  },
  departedFromFirstBerth: {
    'crn': 'departedFromFirstBerth',
    'previousMessageId': '12344',
    'nextMessageId': '12346',
    'updated': '2018-12-24T10:32:08.960Z',
    'additionalViewers': [],
    'copyOfVisit': null,
    'etaPortAis': '2018-11-08T10:23:00Z',
    'orderNauticalServices': true,
    'portOfCall': {
      'port': {
        'name': 'Rotterdam',
        'locationUnCode': 'NLRTM',
        'countryUnCode': 'NL',
        'euPort': true
      },
      'portAuthority': {
        'shortName': 'HBRRTM',
        'fullName': 'Havenbedrijf Rotterdam N.V.',
        'emailAddress': 'HCC@portofrotterdam.com',
        'phoneNumber': '+31-10-2521195',
        'faxNumber': '+31-10-2521000',
        'address': 'Wilhelminakade 909',
        'zipCode': '3072 AP',
        'city': 'Rotterdam',
        'countryUnCode': 'NL',
        'countryName': 'Netherlands',
        'customsEORINumber': 'NL24354561',
        'contact': 'Diana van Eijk',
        'portAuthorityId': 'HbR'
      },
      'customsOffice': {
        'name': 'Rotterdam Haven/Kantoor Maasvlakte',
        'unCode': 'NL000396'
      },
      'ataReported': true,
      'atdReported': true,
      'atdPortReported': true,
      'paDeclarationRequired': true,
      'swDeclarationRequired': true,
      'wasteEnabled': true,
      'dangerousGoodsEnabled': true,
      'harbourDuesEnabled': false,
      'orderNauticalServicesEnabled': true,
      'seaBuoy': {
        'name': 'Maascenter',
        'code': 'MC'
      }
    },
    'vessel': {
      'imoCode': '8758457',
      'name': 'DAVE BLACKBURN',
      'radioCallSign': 'WB6043',
      'motUnCode': '1729',
      'motName': 'Passenger ship',
      'summerDeadWeight': 0,
      'maxWidth': 15.84,
      'manoeuvringSpeed': null,
      'flagCountryUnCode': 'US',
      'netTonnage': 1338,
      'grossTonnage': 1338,
      'registrationPlaceUnloCode': 'USMSY',
      'registrationPlaceName': 'New Orleans',
      'registrationDate': '1944-02-01',
      'mmsiNumber': null,
      'helispotPresent': false,
      'fullLength': 110,
      'flagCode': 'USA'
    },
    'owner': {
      'fullName': 'Ship Operator 1',
      'portAuthorityId': 'SO1_R',
      'shortName': 'SO1',
      'emailAddress': 'pietjepuk@yoyo.nl',
      'address': 'Ship Operatorlaan 1',
      'city': 'Ship Operatorstad',
      'countryUnCode': 'NL',
      'countryName': 'Netherlands',
      'zipCode': '1111SO',
      'phoneNumber': '0102522288',
      'faxNumber': null,
      'contact': 'test centrum',
      'customsEORINumber': 'NL123456789'
    },
    'declarant': {
      'fullName': 'Back Office 1',
      'portAuthorityId': 'BO1_R',
      'shortName': 'BO1',
      'emailAddress': 'pietjepuk@yoyo.nl',
      'address': 'Back Officelaan 1',
      'city': 'Back Officestad',
      'countryUnCode': 'NL',
      'countryName': 'Netherlands',
      'zipCode': '1111SO',
      'phoneNumber': '0102522288',
      'faxNumber': null,
      'contact': 'test centrum',
      'customsEORINumber': 'NL123456789'
    },
    'financialDeclarant': {
      'fullName': 'Ship Operator 1',
      'portAuthorityId': 'SO1_R',
      'shortName': 'SO1',
      'emailAddress': 'pietjepuk@yoyo.nl',
      'address': 'Ship Operatorlaan 1',
      'city': 'Ship Operatorstad',
      'countryUnCode': 'NL',
      'countryName': 'Netherlands',
      'zipCode': '1111SO',
      'phoneNumber': '0102522288',
      'faxNumber': null,
      'contact': 'test centrum',
      'customsEORINumber': 'NL123456789'
    },
    'nominatedFinancialDeclarant': null,
    'creditNumberHolderSameAsDeclarant': true,
    'cargoDeclarants': [
      {
        'fullName': 'Ship Operator 1',
        'portAuthorityId': 'SO1_R',
        'shortName': 'SO1',
        'emailAddress': 'd.reijnders@portbase.com',
        'address': 'Ship Operatorlaan 1',
        'city': 'Ship Operatorstad',
        'countryUnCode': 'NL',
        'countryName': 'Netherlands',
        'zipCode': '1111SO',
        'phoneNumber': '0102522288',
        'faxNumber': null,
        'contact': 'TC',
        'customsEORINumber': 'NL123456789'
      }
    ],
    'allowedViewers': [
      {
        'fullName': 'Ship Operator 1',
        'portAuthorityId': 'SO1_R',
        'shortName': 'SO1',
        'emailAddress': 'pietjepuk@yoyo.nl',
        'address': 'Ship Operatorlaan 1',
        'city': 'Ship Operatorstad',
        'countryUnCode': 'NL',
        'countryName': 'Netherlands',
        'zipCode': '1111SO',
        'phoneNumber': '0102522288',
        'faxNumber': null,
        'contact': 'test centrum',
        'customsEORINumber': 'NL123456789'
      },
      {
        'fullName': 'Ship Operator 1',
        'portAuthorityId': 'SO1_R',
        'shortName': 'SO1',
        'emailAddress': 'pietjepuk@yoyo.nl',
        'address': 'Ship Operatorlaan 1',
        'city': 'Ship Operatorstad',
        'countryUnCode': 'NL',
        'countryName': 'Netherlands',
        'zipCode': '1111SO',
        'phoneNumber': '0102522288',
        'faxNumber': null,
        'contact': 'test centrum',
        'customsEORINumber': 'NL123456789'
      }
    ],
    'visitDeclaration': {
      'clientReferenceNumber': '7',
      'etaFirstEntryEu': '2018-11-08T11:00:00Z',
      'arrivalVoyage': {
        'voyageNumber': '1234567',
        'carrier': {
          'customsId': 'NL100000897',
          'name': 'Carrier 1',
          'scacCode': 'CARA',
          'smdgCode': 'CAR'
        }
      },
      'previousPorts': [
        {
          'id': '7239cd11943846a78869f7260f0fc219',
          'arrival': '2018-11-07T11:00:00Z',
          'departure': '2018-11-08T11:00:00Z',
          'port': {
            'name': 'Barcelona',
            'locationUnCode': 'ESBCN',
            'countryUnCode': 'ES',
            'euPort': true
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'ESBCN-0001',
                'name': 'Barcelona, Spain  AUTOTERMINAL, S.A.'
              },
              'arrivalDate': '2018-11-07',
              'departureDate': '2018-11-08',
              'securityLevel': 'SL1',
              'additionalSecurityMeasures': 'Measures 1'
            }
          ]
        },
        {
          'id': '39af59bb1dd344339f84d12c4533bd03',
          'arrival': '2018-11-06T11:00:00Z',
          'departure': '2018-11-07T11:00:00Z',
          'port': {
            'name': 'Tunisi',
            'locationUnCode': 'ITTUN',
            'countryUnCode': 'IT',
            'euPort': true
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'ITTUN-9999',
                'name': 'UNKNOWN'
              },
              'arrivalDate': '2018-11-06',
              'departureDate': '2018-11-07',
              'securityLevel': 'SL2',
              'additionalSecurityMeasures': 'Measures 2'
            }
          ]
        },
        {
          'id': '8a49e9d4d39647188bb8bd3d57dd4fa7',
          'arrival': '2018-11-05T11:00:00Z',
          'departure': '2018-11-06T11:00:00Z',
          'port': {
            'name': 'Barcelona',
            'locationUnCode': 'ESBCN',
            'countryUnCode': 'ES',
            'euPort': true
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'ESBCN-0003',
                'name': 'Barcelona, Spain  CENTRO DE CONTROL'
              },
              'arrivalDate': '2018-11-05',
              'departureDate': '2018-11-06',
              'securityLevel': 'SL3',
              'additionalSecurityMeasures': 'Measures 3'
            }
          ]
        },
        {
          'id': '2f4ae45bc63643de9fe7678f8021940d',
          'arrival': '2018-11-04T11:00:00Z',
          'departure': '2018-11-05T11:00:00Z',
          'port': {
            'name': 'Tunisi',
            'locationUnCode': 'ITTUN',
            'countryUnCode': 'IT',
            'euPort': true
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'ITTUN-9999',
                'name': 'UNKNOWN'
              },
              'arrivalDate': '2018-11-04',
              'departureDate': '2018-11-05',
              'securityLevel': 'SL1',
              'additionalSecurityMeasures': 'Measures 4'
            }
          ]
        },
        {
          'id': 'bb63bcf604c44adc8aada67c61a367c2',
          'arrival': '2018-11-03T11:00:00Z',
          'departure': '2018-11-04T11:00:00Z',
          'port': {
            'name': 'Barcelona',
            'locationUnCode': 'ESBCN',
            'countryUnCode': 'ES',
            'euPort': true
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'ESBCN-0005',
                'name': 'Barcelona, Spain  COMPAÑÍA LOGISTICA DE HIDROCARBUROS (CLH)'
              },
              'arrivalDate': '2018-11-03',
              'departureDate': '2018-11-04',
              'securityLevel': 'SL2',
              'additionalSecurityMeasures': 'Measures 5'
            }
          ]
        },
        {
          'id': '24a4865f6a494d2abc9c8d477705ad5c',
          'arrival': '2018-11-02T11:00:00Z',
          'departure': '2018-11-03T11:00:00Z',
          'port': {
            'name': 'Tunisi',
            'locationUnCode': 'ITTUN',
            'countryUnCode': 'IT',
            'euPort': true
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'ITTUN-9999',
                'name': 'UNKNOWN'
              },
              'arrivalDate': '2018-11-02',
              'departureDate': '2018-11-03',
              'securityLevel': 'SL3',
              'additionalSecurityMeasures': 'Measures 6'
            }
          ]
        },
        {
          'id': 'e4f33384c9f94439855c194b8d5ededb',
          'arrival': '2018-11-01T11:00:00Z',
          'departure': '2018-11-02T11:00:00Z',
          'port': {
            'name': 'Barcelona',
            'locationUnCode': 'ESBCN',
            'countryUnCode': 'ES',
            'euPort': true
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'ESBCN-0007',
                'name': 'Barcelona, Spain  CORPORACIÓN DE PRÁCTICOS DEL PUERTO DE BARCELONA, S.L.P.'
              },
              'arrivalDate': '2018-11-01',
              'departureDate': '2018-11-02',
              'securityLevel': 'SL1',
              'additionalSecurityMeasures': 'Measures 7'
            }
          ]
        },
        {
          'id': 'a51f713703c94ce885487c6645a1c76e',
          'arrival': '2018-10-31T11:00:00Z',
          'departure': '2018-11-01T11:00:00Z',
          'port': {
            'name': 'Tunisi',
            'locationUnCode': 'ITTUN',
            'countryUnCode': 'IT',
            'euPort': true
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'ITTUN-9999',
                'name': 'UNKNOWN'
              },
              'arrivalDate': '2018-10-31',
              'departureDate': '2018-11-01',
              'securityLevel': 'SL2',
              'additionalSecurityMeasures': 'Measures 8'
            }
          ]
        },
        {
          'id': '0ffbad66973446f4b354c1f3d29a294d',
          'arrival': '2018-10-30T11:00:00Z',
          'departure': '2018-10-31T11:00:00Z',
          'port': {
            'name': 'Barcelona',
            'locationUnCode': 'ESBCN',
            'countryUnCode': 'ES',
            'euPort': true
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'ESBCN-0009',
                'name': 'Barcelona, Spain  DECAL ESPAÑA S.A.'
              },
              'arrivalDate': '2018-10-30',
              'departureDate': '2018-10-31',
              'securityLevel': 'SL3',
              'additionalSecurityMeasures': 'Measures 9'
            }
          ]
        },
        {
          'id': '2fe3987117624e4e982eadb5fcf6b30f',
          'arrival': '2018-10-29T11:00:00Z',
          'departure': '2018-10-30T11:00:00Z',
          'port': {
            'name': 'Tunisi',
            'locationUnCode': 'ITTUN',
            'countryUnCode': 'IT',
            'euPort': true
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'ITTUN-9999',
                'name': 'UNKNOWN'
              },
              'arrivalDate': '2018-10-29',
              'departureDate': '2018-10-30',
              'securityLevel': 'SL2',
              'additionalSecurityMeasures': 'Measures 10'
            }
          ]
        },
        {
          'id': '14b77c47a3b24a6bb058d9998f89f213',
          'arrival': '2018-10-28T11:00:00Z',
          'departure': '2018-10-29T11:00:00Z',
          'port': {
            'name': 'Barcelona',
            'locationUnCode': 'ESBCN',
            'countryUnCode': 'ES',
            'euPort': true
          },
          'portFacilityVisits': []
        },
        {
          'id': '62ba903b912543b18438f132d2409263',
          'arrival': '2018-10-27T11:00:00Z',
          'departure': '2018-10-28T11:00:00Z',
          'port': {
            'name': 'Tunisi',
            'locationUnCode': 'ITTUN',
            'countryUnCode': 'IT',
            'euPort': true
          },
          'portFacilityVisits': []
        }
      ],
      'portVisit': {
        'intention': 'REQUEST_FOR_ENTRY',
        'pilotStation': {
          'code': 'MC',
          'name': 'Maascenter',
          'atSea': true
        },
        'entryPoint': {
          'code': 'LL',
          'name': 'Lage Licht'
        },
        'etaPort': '2018-11-08T11:00:00Z',
        'firstMovement': {
          'vesselDraft': 6.0,
          'vesselMasterName': 'Henk',
          'numberOfCrew': 20,
          'numberOfPassengers': 6,
          'cargo': 'OUT_OF_GAUGE',
          'pilotExemption': {
            'holderName': '',
            'number': '16'
          },
          'offStandardBeam': {
            'value': 4.5,
            'remarks': ''
          },
          'pilotService': {
            'required': false
          }
        },
        'passingThroughTugboats': null,
        'defectTypes': null,
        'berthVisits': [
          {
            'id': '1abbb8789fe94b5c8adad25550a0a6b1',
            'berth': {
              'name': 'HOLLAND AMERIKAKADE',
              'code': 'R1243',
              'terminalCode': '52090469',
              'berthGroupCode': 'HOLLAND AMERIKAKADE',
              'buoy': false,
              'partyToNotify': null
            },
            'eta': '2018-11-09T11:00:00Z',
            'ata': '2018-11-09T11:15:00Z',
            'etd': '2018-11-09T22:00:00Z',
            'requestedEtd': '2018-11-09T22:00:00Z',
            'atd': '2018-11-09T22:00:00Z',
            'mooring': 'PORT_SIDE',
            'remarks': null,
            'visitPurposes': [
              'CARGO_TANK_CLEANING',
              'CUSTOMS_CLEARANCE',
              'DISPOSAL_OF_WASTE',
              'LAID_UP',
              'LOADING'
            ],
            'bollardFrom': null,
            'bollardTo': null,
            'tugboatAtArrival': {
              'required': true,
              'serviceProvider': {
                'name': 'Gepke',
                'portAuthorityId': 'VERSCHOOR'
              },
              'requiredQuantity': 'SIX',
              'remarks': null
            },
            'boatmenAtArrival': {
              'required': true,
              'serviceProvider': {
                'name': 'V.i.o.s.',
                'portAuthorityId': 'VIOS'
              },
              'remarks': null
            },
            'boatmenAtDeparture': {
              'required': true,
              'serviceProvider': {
                'name': 'V.i.o.s.',
                'portAuthorityId': 'VIOS'
              },
              'remarks': null
            },
            'tugboatAtDeparture': {
              'required': true,
              'serviceProvider': {
                'name': 'Gepke',
                'portAuthorityId': 'VERSCHOOR'
              },
              'requiredQuantity': 'SIX',
              'remarks': null
            },
            'nextMovement': {
              'order': true,
              'vesselDraft': 7.0,
              'vesselMasterName': 'Piet',
              'numberOfCrew': 21,
              'numberOfPassengers': 7,
              'cargo': 'OUT_OF_GAUGE',
              'pilotExemption': {
                'holderName': '',
                'number': '16'
              },
              'offStandardBeam': {
                'value': 4.5,
                'remarks': ''
              },
              'pilotService': {
                'required': true,
                'serviceProvider': {
                  'name': 'Pilot 1',
                  'portAuthorityId': 'PIL1'
                },
                'remarks': null
              }
            }
          },
          {
            'id': 'c1324bb553b2451d907e63e48d74c3e1',
            'berth': {
              'name': 'DORDR PWA KADE',
              'code': 'D4358',
              'terminalCode': '51433242',
              'berthGroupCode': 'DORDR PWA KADE',
              'buoy': false,
              'partyToNotify': null
            },
            'eta': '2018-11-10T11:00:00Z',
            'ata': '2018-11-10T11:00:00Z',
            'etd': '2018-11-10T22:00:00Z',
            'atd': null,
            'mooring': 'ALONGSIDE_OTHER_SHIP',
            'remarks': null,
            'visitPurposes': [
              'DISCHARGE_CRUDE_OIL',
              'TAKING_SUPPLIES'
            ],
            'bollardFrom': null,
            'bollardTo': null,
            'tugboatAtArrival': {
              'required': false,
              'serviceProvider': {
                'name': 'Gepke',
                'portAuthorityId': 'VERSCHOOR'
              },
              'requiredQuantity': null,
              'remarks': null
            },
            'boatmenAtArrival': {
              'required': true,
              'serviceProvider': {
                'name': 'V.i.o.s.',
                'portAuthorityId': 'VIOS'
              },
              'remarks': null
            },
            'boatmenAtDeparture': null,
            'tugboatAtDeparture': {
              'required': true,
              'serviceProvider': {
                'name': 'Gepke',
                'portAuthorityId': 'VERSCHOOR'
              },
              'requiredQuantity': 'FIVE',
              'remarks': null
            },
            'nextMovement': {
              'vesselDraft': 8.0,
              'vesselMasterName': 'Henk',
              'numberOfCrew': 20,
              'numberOfPassengers': 6,
              'cargo': 'BALLAST_CONDITION',
              'pilotExemption': null,
              'offStandardBeam': {
                'value': 12.8,
                'remarks': 'No remarks'
              },
              'pilotService': {
                'required': true,
                'serviceProvider': {
                  'name': 'Pilot 1',
                  'portAuthorityId': 'PIL1'
                },
                'remarks': null
              }
            }
          },
          {
            'id': 'abb42b0d775d44fca7e2d392d16ff340',
            'berth': {
              'name': 'PET 3 B 61',
              'code': 'R61',
              'terminalCode': '47644472',
              'berthGroupCode': 'PET 3 B 61',
              'buoy': true,
              'partyToNotify': null
            },
            'eta': '2018-11-11T11:00:00Z',
            'ata': null,
            'etd': '2018-11-11T22:00:00Z',
            'atd': null,
            'mooring': 'STARBOARD_SIDE',
            'remarks': null,
            'visitPurposes': [
              'CREW_MOVEMENT',
              'LOADING',
              'REFUGE'
            ],
            'bollardFrom': 16.2,
            'bollardTo': 20.2,
            'tugboatAtArrival': {
              'required': false,
              'serviceProvider': {
                'name': 'Gepke',
                'portAuthorityId': 'VERSCHOOR'
              },
              'requiredQuantity': null,
              'remarks': null
            },
            'boatmenAtArrival': {
              'required': true,
              'serviceProvider': {
                'name': 'V.i.o.s.',
                'portAuthorityId': 'VIOS'
              },
              'remarks': null
            },
            'boatmenAtDeparture': {
              'required': true,
              'serviceProvider': {
                'name': 'V.I.O.S.',
                'portAuthorityId': 'VIOS'
              },
              'remarks': 'we hebben je nodig'
            },
            'tugboatAtDeparture': {
              'required': true,
              'serviceProvider': {
                'name': 'Stemat',
                'portAuthorityId': 'STEMAT'
              },
              'requiredQuantity': 'ONE',
              'remarks': null
            },
            'nextMovement': {
              'vesselDraft': 12.5,
              'vesselMasterName': 'Henk',
              'numberOfCrew': 20,
              'numberOfPassengers': 6,
              'cargo': 'BALLAST_CONDITION',
              'pilotExemption': {
                'holderName': 'Exemption holder 1',
                'number': '12345'
              },
              'offStandardBeam': {
                'value': 12.8,
                'remarks': 'No remarks'
              },
              'pilotService': {
                'required': true,
                'serviceProvider': {
                  'name': 'Pilot 1',
                  'portAuthorityId': 'PIL1'
                },
                'remarks': null
              }
            }
          }
        ],
        'dangerousGoodsLoading': true,
        'dangerousGoodsDischarge': false,
        'dangerousGoodsTransit': true,
        'possibleAnchorage': true,
        'vesselInspectionRequired': true,
        'shipConfiguration': 'DOUBLE_HULL_TANKER',
        'exitPoint': {
          'code': 'OM',
          'name': 'Oude Maas',
          'atSea': false
        },
        'etdPort': '2018-11-13T11:00:00Z',
        'atdPort': null
      },
      'departureVoyage': {
        'voyageNumber': '1234568',
        'carrier': {
          'customsId': '12345',
          'name': null,
          'scacCode': null,
          'smdgCode': null
        }
      },
      'nextPorts': [
        {
          'id': 'f94034d2d4624e778775625184f22091',
          'arrival': '2018-11-14T11:00:00Z',
          'departure': '2018-11-15T11:00:00Z',
          'port': {
            'name': 'Antwerpen',
            'locationUnCode': 'BEANR',
            'countryUnCode': 'BE',
            'euPort': true
          },
          'customsOffice': null
        },
        {
          'id': 'd8d6ca9c2fa44747b4d8f111a75e11c7',
          'arrival': '2018-11-22T11:00:00Z',
          'departure': '2018-11-23T11:00:00Z',
          'port': {
            'name': 'Taranto',
            'locationUnCode': 'ITTAR',
            'countryUnCode': 'IT',
            'euPort': true
          },
          'customsOffice': {
            'name': 'S.O.T. AEROPORTO DELLO STRETTO',
            'unCode': 'IT087101'
          }
        },
        {
          'id': 'f4e5100bad6d4aa4a2cff0412bf963e0',
          'arrival': '2018-11-24T11:00:00Z',
          'departure': '2018-11-27T11:00:00Z',
          'port': {
            'name': 'El Iskandariya (= Alexandria)',
            'locationUnCode': 'EGALY',
            'countryUnCode': 'EG',
            'euPort': false
          },
          'customsOffice': null
        }
      ]
    },
    'nextVisitDeclaration': [],
    'securityDeclaration': {
      'securityReportRequired': 'YES',
      'companySecurityOfficer': {
        'name': 'Jansen',
        'email': 'Jansen@hotmail.com',
        'phoneNumber': '123456789'
      },
      'currentSecurityLevel': 'SL1',
      'approvedSspDocumentOnBoard': false,
      'isscSecurityDocument': {
        'availability': 'YES',
        'expiryDate': '2017-01-01',
        'reasonNoValidIsscOnBoard': 'ik ben een gevaarlijke piraat',
        'issuer': {
          'code': '1',
          'name': 'AMERICAN BUREAU'
        }
      },
      'shipToShipActivities': [
        {
          'location': 'Ergens 1',
          'type': 'CARGO_TANK_CLEANING',
          'startDate': '2018-11-07',
          'endDate': '2018-11-08',
          'compliantWithSsp': true,
          'securityMeasures': 'Measures 1'
        },
        {
          'location': 'Ergens 2',
          'type': 'CHANGING_CREW',
          'startDate': '2018-11-06',
          'endDate': '2018-11-07',
          'compliantWithSsp': false,
          'securityMeasures': 'Measures 2'
        },
        {
          'location': 'Ergens 3',
          'type': 'DE_GASSING',
          'startDate': '2018-11-05',
          'endDate': '2018-11-06',
          'compliantWithSsp': true,
          'securityMeasures': 'Measures 3'
        },
        {
          'location': 'Ergens 4',
          'type': 'CARGO_TANK_CLEANING',
          'startDate': '2018-11-04',
          'endDate': '2018-11-05',
          'compliantWithSsp': false,
          'securityMeasures': 'Measures 4'
        },
        {
          'location': 'Ergens 5',
          'type': 'CHANGING_CREW',
          'startDate': '2018-11-03',
          'endDate': '2018-11-04',
          'compliantWithSsp': true,
          'securityMeasures': 'Measures 5'
        },
        {
          'location': 'Ergens 6',
          'type': 'DE_GASSING',
          'startDate': '2018-11-02',
          'endDate': '2018-11-03',
          'compliantWithSsp': false,
          'securityMeasures': 'Measures 6'
        },
        {
          'location': 'Ergens 7',
          'type': 'CARGO_TANK_CLEANING',
          'startDate': '2018-11-01',
          'endDate': '2018-11-02',
          'compliantWithSsp': true,
          'securityMeasures': 'Measures 7'
        },
        {
          'location': 'Ergens 8',
          'type': 'CHANGING_CREW',
          'startDate': '2018-10-31',
          'endDate': '2018-11-01',
          'compliantWithSsp': false,
          'securityMeasures': 'Measures 8'
        },
        {
          'location': 'Ergens 9',
          'type': 'DE_GASSING',
          'startDate': '2018-10-30',
          'endDate': '2018-10-31',
          'compliantWithSsp': true,
          'securityMeasures': 'Measures 9'
        }
      ],
      'securityRelatedMatter': 'Any security related matter to report'
    },
    'wasteDeclaration': null,
    'shipStoresDeclaration': null,
    'paxDeclaration': null,
    'uploads': {},
    'cancelled': false,
    'ataRegisteredByPa': false,
    'declarations': [
      {
        'type': 'VISIT',
        'status': 'ACCEPTED',
        'rejectReasons': null,
        'timeStamp': '2018-12-20T11:12:40.997Z'

      },
      {
        'type': 'WPCS',
        'status': 'DECLARED',
        'rejectReasons': null,
        'timeStamp': '2018-12-20T11:12:48.060Z'
      },
      {
        'type': 'SECURITY',
        'status': 'ACCEPTED',
        'rejectReasons': null,
        'timeStamp': '2018-12-20T11:13:13.063Z'
      },
      {
        'type': 'VISIT',
        'status': 'ACCEPTED',
        'rejectReasons': null,
        'timeStamp': '2018-12-24T10:31:08.960Z'
      },
      {
        'type': 'PAX',
        'status': 'ACCEPTED',
        'rejectReasons': null,
        'timeStamp': '2018-12-24T10:32:08.960Z'
      }
    ],
    'visitStatus': 'ARRIVED',
    'expected': false,
    'berthVisitInfos': <BerthVisitInfo>{
      '1abbb8789fe94b5c8adad25550a0a6b1':
        {
          'harbourMasterInfo': {
            'etd': '2018-11-09T22:30:00Z'
          }
        },
    },
  },
  NLRTM19500002: {
    'crn': 'NLRTM19500002',
    'additionalViewers': [],
    'copyOfVisit': null,
    'portOfCall': {
      'port': {
        'name': 'Rotterdam',
        'locationUnCode': 'NLRTM',
        'countryUnCode': 'NL',
        'euPort': true
      },
      'portAuthority': {
        'shortName': 'HBRRTM',
        'fullName': 'Havenbedrijf Rotterdam N.V.',
        'emailAddress': 'HCC@portofrotterdam.com',
        'phoneNumber': '+31-10-2521195',
        'faxNumber': '+31-10-2521000',
        'address': 'Wilhelminakade 909',
        'zipCode': '3072 AP',
        'city': 'Rotterdam',
        'countryUnCode': 'NL',
        'countryName': 'Netherlands',
        'customsEORINumber': 'NL24354561',
        'contact': 'Diana van Eijk',
        'portAuthorityId': 'HbR'
      },
      'customsOffice': {
        'name': 'Rotterdam Haven/Kantoor Maasvlakte',
        'unCode': 'NL000396'
      },
      'ataReported': true,
      'atdReported': true,
      'atdPortReported': true,
      'paDeclarationRequired': true,
      'swDeclarationRequired': true,
      'wasteEnabled': true,
      'dangerousGoodsEnabled': true,
      'harbourDuesEnabled': false,
      'orderNauticalServicesEnabled': true,
      'seaBuoy': {
        'name': 'Maascenter',
        'code': 'MC'
      }
    },
    'vessel': {
      'imoCode': '8758457',
      'name': 'DAVE BLACKBURN',
      'radioCallSign': 'WB6043',
      'motUnCode': '1729',
      'motName': 'Passenger ship',
      'summerDeadWeight': 0,
      'maxWidth': 15.84,
      'manoeuvringSpeed': null,
      'flagCountryUnCode': 'US',
      'netTonnage': 1338,
      'grossTonnage': 1338,
      'registrationPlaceUnloCode': 'USMSY',
      'registrationPlaceName': 'New Orleans',
      'registrationDate': '1944-02-01',
      'mmsiNumber': null,
      'helispotPresent': false,
      'fullLength': 110,
      'flagCode': 'USA'
    },
    'owner': {
      'fullName': 'Ship Operator 1',
      'portAuthorityId': 'SO1_R',
      'shortName': 'SO1',
      'emailAddress': 'pietjepuk@yoyo.nl',
      'address': 'Ship Operatorlaan 1',
      'city': 'Ship Operatorstad',
      'countryUnCode': 'NL',
      'countryName': 'Netherlands',
      'zipCode': '1111SO',
      'phoneNumber': '0102522288',
      'faxNumber': null,
      'contact': 'test centrum',
      'customsEORINumber': 'NL123456789'
    },
    'declarant': {
      'fullName': 'Back Office 1',
      'portAuthorityId': 'BO1_R',
      'shortName': 'BO1',
      'emailAddress': 'pietjepuk@yoyo.nl',
      'address': 'Back Officelaan 1',
      'city': 'Back Officestad',
      'countryUnCode': 'NL',
      'countryName': 'Netherlands',
      'zipCode': '1111SO',
      'phoneNumber': '0102522288',
      'faxNumber': null,
      'contact': 'test centrum',
      'customsEORINumber': 'NL123456789'
    },
    'financialDeclarant': {
      'fullName': 'Ship Operator 1',
      'portAuthorityId': 'SO1_R',
      'shortName': 'SO1',
      'emailAddress': 'pietjepuk@yoyo.nl',
      'address': 'Ship Operatorlaan 1',
      'city': 'Ship Operatorstad',
      'countryUnCode': 'NL',
      'countryName': 'Netherlands',
      'zipCode': '1111SO',
      'phoneNumber': '0102522288',
      'faxNumber': null,
      'contact': 'test centrum',
      'customsEORINumber': 'NL123456789'
    },
    'nominatedFinancialDeclarant': null,
    'orderNauticalServices': true,
    'creditNumberHolderSameAsDeclarant': true,
    'cargoDeclarants': [
      {
        'fullName': 'Ship Operator 1',
        'portAuthorityId': 'SO1_R',
        'shortName': 'SO1',
        'emailAddress': 'd.reijnders@portbase.com',
        'address': 'Ship Operatorlaan 1',
        'city': 'Ship Operatorstad',
        'countryUnCode': 'NL',
        'countryName': 'Netherlands',
        'zipCode': '1111SO',
        'phoneNumber': '0102522288',
        'faxNumber': null,
        'contact': 'TC',
        'customsEORINumber': 'NL123456789'
      }
    ],
    'allowedViewers': [
      {
        'fullName': 'Ship Operator 1',
        'portAuthorityId': 'SO1_R',
        'shortName': 'SO1',
        'emailAddress': 'pietjepuk@yoyo.nl',
        'address': 'Ship Operatorlaan 1',
        'city': 'Ship Operatorstad',
        'countryUnCode': 'NL',
        'countryName': 'Netherlands',
        'zipCode': '1111SO',
        'phoneNumber': '0102522288',
        'faxNumber': null,
        'contact': 'test centrum',
        'customsEORINumber': 'NL123456789'
      },
      {
        'fullName': 'Ship Operator 1',
        'portAuthorityId': 'SO1_R',
        'shortName': 'SO1',
        'emailAddress': 'pietjepuk@yoyo.nl',
        'address': 'Ship Operatorlaan 1',
        'city': 'Ship Operatorstad',
        'countryUnCode': 'NL',
        'countryName': 'Netherlands',
        'zipCode': '1111SO',
        'phoneNumber': '0102522288',
        'faxNumber': null,
        'contact': 'test centrum',
        'customsEORINumber': 'NL123456789'
      }
    ],
    'visitDeclaration': {
      'clientReferenceNumber': '7',
      'etaFirstEntryEu': '2018-11-08T11:00:00Z',
      'arrivalVoyage': {
        'voyageNumber': '1234567',
        'carrier': {
          'customsId': 'NL100000897',
          'name': 'Carrier 1',
          'scacCode': 'CARA',
          'smdgCode': 'CAR'
        }
      },
      'previousPorts': [
        {
          'id': '7239cd11943846a78869f7260f0fc219',
          'arrival': '2018-11-07T11:00:00Z',
          'departure': '2018-11-08T11:00:00Z',
          'port': {
            'name': 'Barcelona',
            'locationUnCode': 'ESBCN',
            'countryUnCode': 'ES',
            'euPort': true
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'ESBCN-0001',
                'name': 'Barcelona, Spain  AUTOTERMINAL, S.A.'
              },
              'arrivalDate': '2018-11-07',
              'departureDate': '2018-11-08',
              'securityLevel': 'SL1',
              'additionalSecurityMeasures': 'Measures 1'
            }
          ]
        },
        {
          'id': '39af59bb1dd344339f84d12c4533bd03',
          'arrival': '2018-11-06T11:00:00Z',
          'departure': '2018-11-07T11:00:00Z',
          'port': {
            'name': 'Tunisi',
            'locationUnCode': 'ITTUN',
            'countryUnCode': 'IT',
            'euPort': true
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'ITTUN-9999',
                'name': 'UNKNOWN'
              },
              'arrivalDate': '2018-11-06',
              'departureDate': '2018-11-07',
              'securityLevel': 'SL2',
              'additionalSecurityMeasures': 'Measures 2'
            }
          ]
        },
        {
          'id': '8a49e9d4d39647188bb8bd3d57dd4fa7',
          'arrival': '2018-11-05T11:00:00Z',
          'departure': '2018-11-06T11:00:00Z',
          'port': {
            'name': 'Barcelona',
            'locationUnCode': 'ESBCN',
            'countryUnCode': 'ES',
            'euPort': true
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'ESBCN-0003',
                'name': 'Barcelona, Spain  CENTRO DE CONTROL'
              },
              'arrivalDate': '2018-11-05',
              'departureDate': '2018-11-06',
              'securityLevel': 'SL3',
              'additionalSecurityMeasures': 'Measures 3'
            }
          ]
        },
        {
          'id': '2f4ae45bc63643de9fe7678f8021940d',
          'arrival': '2018-11-04T11:00:00Z',
          'departure': '2018-11-05T11:00:00Z',
          'port': {
            'name': 'Tunisi',
            'locationUnCode': 'ITTUN',
            'countryUnCode': 'IT',
            'euPort': true
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'ITTUN-9999',
                'name': 'UNKNOWN'
              },
              'arrivalDate': '2018-11-04',
              'departureDate': '2018-11-05',
              'securityLevel': 'SL1',
              'additionalSecurityMeasures': 'Measures 4'
            }
          ]
        },
        {
          'id': 'bb63bcf604c44adc8aada67c61a367c2',
          'arrival': '2018-11-03T11:00:00Z',
          'departure': '2018-11-04T11:00:00Z',
          'port': {
            'name': 'Barcelona',
            'locationUnCode': 'ESBCN',
            'countryUnCode': 'ES',
            'euPort': true
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'ESBCN-0005',
                'name': 'Barcelona, Spain  COMPAÑÍA LOGISTICA DE HIDROCARBUROS (CLH)'
              },
              'arrivalDate': '2018-11-03',
              'departureDate': '2018-11-04',
              'securityLevel': 'SL2',
              'additionalSecurityMeasures': 'Measures 5'
            }
          ]
        },
        {
          'id': '24a4865f6a494d2abc9c8d477705ad5c',
          'arrival': '2018-11-02T11:00:00Z',
          'departure': '2018-11-03T11:00:00Z',
          'port': {
            'name': 'Tunisi',
            'locationUnCode': 'ITTUN',
            'countryUnCode': 'IT',
            'euPort': true
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'ITTUN-9999',
                'name': 'UNKNOWN'
              },
              'arrivalDate': '2018-11-02',
              'departureDate': '2018-11-03',
              'securityLevel': 'SL3',
              'additionalSecurityMeasures': 'Measures 6'
            }
          ]
        },
        {
          'id': 'e4f33384c9f94439855c194b8d5ededb',
          'arrival': '2018-11-01T11:00:00Z',
          'departure': '2018-11-02T11:00:00Z',
          'port': {
            'name': 'Barcelona',
            'locationUnCode': 'ESBCN',
            'countryUnCode': 'ES',
            'euPort': true
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'ESBCN-0007',
                'name': 'Barcelona, Spain  CORPORACIÓN DE PRÁCTICOS DEL PUERTO DE BARCELONA, S.L.P.'
              },
              'arrivalDate': '2018-11-01',
              'departureDate': '2018-11-02',
              'securityLevel': 'SL1',
              'additionalSecurityMeasures': 'Measures 7'
            }
          ]
        },
        {
          'id': 'a51f713703c94ce885487c6645a1c76e',
          'arrival': '2018-10-31T11:00:00Z',
          'departure': '2018-11-01T11:00:00Z',
          'port': {
            'name': 'Tunisi',
            'locationUnCode': 'ITTUN',
            'countryUnCode': 'IT',
            'euPort': true
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'ITTUN-9999',
                'name': 'UNKNOWN'
              },
              'arrivalDate': '2018-10-31',
              'departureDate': '2018-11-01',
              'securityLevel': 'SL2',
              'additionalSecurityMeasures': 'Measures 8'
            }
          ]
        },
        {
          'id': '0ffbad66973446f4b354c1f3d29a294d',
          'arrival': '2018-10-30T11:00:00Z',
          'departure': '2018-10-31T11:00:00Z',
          'port': {
            'name': 'Barcelona',
            'locationUnCode': 'ESBCN',
            'countryUnCode': 'ES',
            'euPort': true
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'ESBCN-0009',
                'name': 'Barcelona, Spain  DECAL ESPAÑA S.A.'
              },
              'arrivalDate': '2018-10-30',
              'departureDate': '2018-10-31',
              'securityLevel': 'SL3',
              'additionalSecurityMeasures': 'Measures 9'
            }
          ]
        },
        {
          'id': '2fe3987117624e4e982eadb5fcf6b30f',
          'arrival': '2018-10-29T11:00:00Z',
          'departure': '2018-10-30T11:00:00Z',
          'port': {
            'name': 'Tunisi',
            'locationUnCode': 'ITTUN',
            'countryUnCode': 'IT',
            'euPort': true
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'ITTUN-9999',
                'name': 'UNKNOWN'
              },
              'arrivalDate': '2018-10-29',
              'departureDate': '2018-10-30',
              'securityLevel': 'SL2',
              'additionalSecurityMeasures': 'Measures 10'
            }
          ]
        },
        {
          'id': '14b77c47a3b24a6bb058d9998f89f213',
          'arrival': '2018-10-28T11:00:00Z',
          'departure': '2018-10-29T11:00:00Z',
          'port': {
            'name': 'Barcelona',
            'locationUnCode': 'ESBCN',
            'countryUnCode': 'ES',
            'euPort': true
          },
          'portFacilityVisits': []
        },
        {
          'id': '62ba903b912543b18438f132d2409263',
          'arrival': '2018-10-27T11:00:00Z',
          'departure': '2018-10-28T11:00:00Z',
          'port': {
            'name': 'Tunisi',
            'locationUnCode': 'ITTUN',
            'countryUnCode': 'IT',
            'euPort': true
          },
          'portFacilityVisits': []
        }
      ],
      'portVisit': {
        'intention': 'REQUEST_FOR_ENTRY',
        'pilotStation': {
          'code': 'MC',
          'name': 'Maascenter',
          'atSea': true
        },
        'entryPoint': {
          'code': 'LL',
          'name': 'Lage Licht'
        },
        'etaPort': '2018-11-08T11:00:00Z',
        'ataPort': '2018-11-08T11:00:00Z',
        'firstMovement': {
          'vesselDraft': 6.0,
          'vesselMasterName': 'Henk',
          'numberOfCrew': 20,
          'numberOfPassengers': 6,
          'cargo': 'OUT_OF_GAUGE',
          'pilotExemption': {
            'holderName': '',
            'number': '16'
          },
          'offStandardBeam': {
            'value': 4.5,
            'remarks': ''
          },
          'pilotService': {
            'required': false
          }
        },
        'passingThroughTugboats': null,
        'defectTypes': [
          'DEN',
          'DWE',
          'TOW'
        ],
        'berthVisits': [
          {
            'id': '1abbb8789fe94b5c8adad25550a0a6b1',
            'berth': {
              'name': 'HOLLAND AMERIKAKADE',
              'code': 'R1243',
              'terminalCode': '52090469',
              'berthGroupCode': 'HOLLAND AMERIKAKADE',
              'buoy': false,
              'partyToNotify': null
            },
            'eta': '2018-11-09T11:00:00Z',
            'ata': '2018-11-09T12:00:00Z',
            'etd': null,
            'atd': '2018-11-09T13:00:00Z',
            'mooring': 'PORT_SIDE',
            'remarks': null,
            'visitPurposes': [
              'CARGO_TANK_CLEANING',
              'CUSTOMS_CLEARANCE',
              'DISPOSAL_OF_WASTE',
              'LAID_UP',
              'LOADING'
            ],
            'bollardFrom': null,
            'bollardTo': null,
            'tugboatAtArrival': {
              'required': true,
              'serviceProvider': {
                'name': 'Gepke',
                'portAuthorityId': 'VERSCHOOR'
              },
              'requiredQuantity': 'SIX',
              'remarks': null
            },
            'boatmenAtArrival': {
              'required': true,
              'serviceProvider': {
                'name': 'V.i.o.s.',
                'portAuthorityId': 'VIOS'
              },
              'remarks': null
            },
            'boatmenAtDeparture': {
              'required': true,
              'serviceProvider': {
                'name': 'V.i.o.s.',
                'portAuthorityId': 'VIOS'
              },
              'remarks': null
            },
            'tugboatAtDeparture': {
              'required': true,
              'serviceProvider': {
                'name': 'Gepke',
                'portAuthorityId': 'VERSCHOOR'
              },
              'requiredQuantity': 'SIX',
              'remarks': null
            },
            'nextMovement': {
              'vesselDraft': 7.0,
              'vesselMasterName': 'Piet',
              'numberOfCrew': 21,
              'numberOfPassengers': 7,
              'cargo': 'OUT_OF_GAUGE',
              'pilotExemption': {
                'holderName': '',
                'number': '16'
              },
              'offStandardBeam': {
                'value': 4.5,
                'remarks': ''
              },
              'pilotService': null
            }
          },
          {
            'id': 'c1324bb553b2451d907e63e48d74c3e1',
            'berth': {
              'name': 'DORDR PWA KADE',
              'code': 'D4358',
              'terminalCode': '51433242',
              'berthGroupCode': 'DORDR PWA KADE',
              'buoy': false,
              'partyToNotify': null
            },
            'eta': '2018-11-10T11:00:00Z',
            'ata': '2018-11-10T11:00:00Z',
            'etd': '2018-11-10T22:00:00Z',
            'atd': '2018-11-10T22:00:00Z',
            'mooring': 'ALONGSIDE_OTHER_SHIP',
            'remarks': null,
            'visitPurposes': [
              'DISCHARGE_CRUDE_OIL',
              'TAKING_SUPPLIES'
            ],
            'bollardFrom': null,
            'bollardTo': null,
            'tugboatAtArrival': {
              'required': false,
              'serviceProvider': {
                'name': 'Gepke',
                'portAuthorityId': 'VERSCHOOR'
              },
              'requiredQuantity': null,
              'remarks': null
            },
            'boatmenAtArrival': {
              'required': true,
              'serviceProvider': {
                'name': 'V.i.o.s.',
                'portAuthorityId': 'VIOS'
              },
              'remarks': null
            },
            'boatmenAtDeparture': null,
            'tugboatAtDeparture': {
              'required': true,
              'serviceProvider': {
                'name': 'Gepke',
                'portAuthorityId': 'VERSCHOOR'
              },
              'requiredQuantity': 'FIVE',
              'remarks': null
            },
            'nextMovement': {
              'vesselDraft': 8.0,
              'vesselMasterName': 'Henk',
              'numberOfCrew': 20,
              'numberOfPassengers': 6,
              'cargo': 'BALLAST_CONDITION',
              'pilotExemption': null,
              'offStandardBeam': {
                'value': 12.8,
                'remarks': 'No remarks'
              },
              'pilotService': {
                'required': true,
                'serviceProvider': {
                  'name': 'Pilot 1',
                  'portAuthorityId': 'PIL1'
                },
                'remarks': null
              }
            }
          },
          {
            'id': 'abb42b0d775d44fca7e2d392d16ff340',
            'berth': {
              'name': 'PET 3 B 61',
              'code': 'R61',
              'terminalCode': '47644472',
              'berthGroupCode': 'PET 3 B 61',
              'buoy': true,
              'partyToNotify': null
            },
            'eta': '2018-11-11T11:00:00Z',
            'ata': '2018-11-11T11:00:00Z',
            'etd': '2018-11-11T22:00:00Z',
            'atd': '2018-11-11T22:00:00Z',
            'mooring': 'STARBOARD_SIDE',
            'remarks': null,
            'visitPurposes': [
              'CREW_MOVEMENT',
              'LOADING',
              'REFUGE'
            ],
            'bollardFrom': 16.2,
            'bollardTo': 20.2,
            'tugboatAtArrival': {
              'required': false,
              'serviceProvider': {
                'name': 'Gepke',
                'portAuthorityId': 'VERSCHOOR'
              },
              'requiredQuantity': null,
              'remarks': null
            },
            'boatmenAtArrival': {
              'required': true,
              'serviceProvider': {
                'name': 'V.i.o.s.',
                'portAuthorityId': 'VIOS'
              },
              'remarks': null
            },
            'boatmenAtDeparture': null,
            'tugboatAtDeparture': {
              'required': false,
              'serviceProvider': {
                'name': 'Smit havensleepdienst B.V.',
                'portAuthorityId': 'SMIT'
              },
              'requiredQuantity': null,
              'remarks': null
            },
            'nextMovement': {
              'vesselDraft': 12.5,
              'vesselMasterName': 'Henk',
              'numberOfCrew': 20,
              'numberOfPassengers': 6,
              'cargo': 'BALLAST_CONDITION',
              'pilotExemption': {
                'holderName': 'Exemption holder 1',
                'number': '12345'
              },
              'offStandardBeam': {
                'value': 12.8,
                'remarks': 'No remarks'
              },
              'pilotService': null
            }
          }
        ],
        'dangerousGoodsLoading': true,
        'dangerousGoodsDischarge': false,
        'dangerousGoodsTransit': true,
        'possibleAnchorage': true,
        'vesselInspectionRequired': true,
        'shipConfiguration': 'DOUBLE_HULL_TANKER',
        'exitPoint': {
          'code': 'OM',
          'name': 'Oude Maas',
          'atSea': false
        },
        'etdPort': '2018-11-13T11:00:00Z',
        'atdPort': null
      },
      'departureVoyage': {
        'voyageNumber': '1234568',
        'carrier': {
          'customsId': '12345',
          'name': null,
          'scacCode': null,
          'smdgCode': null
        }
      },
      'nextPorts': [
        {
          'id': 'f94034d2d4624e778775625184f22091',
          'arrival': '2018-11-14T11:00:00Z',
          'departure': '2018-11-15T11:00:00Z',
          'port': {
            'name': 'Antwerpen',
            'locationUnCode': 'BEANR',
            'countryUnCode': 'BE',
            'euPort': true
          },
          'customsOffice': null
        },
        {
          'id': 'd8d6ca9c2fa44747b4d8f111a75e11c7',
          'arrival': '2018-11-22T11:00:00Z',
          'departure': '2018-11-23T11:00:00Z',
          'port': {
            'name': 'Taranto',
            'locationUnCode': 'ITTAR',
            'countryUnCode': 'IT',
            'euPort': true
          },
          'customsOffice': {
            'name': 'S.O.T. AEROPORTO DELLO STRETTO',
            'unCode': 'IT087101'
          }
        },
        {
          'id': 'f4e5100bad6d4aa4a2cff0412bf963e0',
          'arrival': '2018-11-24T11:00:00Z',
          'departure': '2018-11-27T11:00:00Z',
          'port': {
            'name': 'El Iskandariya (= Alexandria)',
            'locationUnCode': 'EGALY',
            'countryUnCode': 'EG',
            'euPort': false
          },
          'customsOffice': null
        }
      ]
    },
    'nextVisitDeclaration': [],
    'securityDeclaration': {
      'securityReportRequired': 'YES',
      'companySecurityOfficer': {
        'name': 'Jansen',
        'email': 'Jansen@hotmail.com',
        'phoneNumber': '123456789'
      },
      'currentSecurityLevel': 'SL1',
      'approvedSspDocumentOnBoard': false,
      'isscSecurityDocument': {
        'availability': 'YES',
        'expiryDate': '2017-01-01',
        'reasonNoValidIsscOnBoard': 'ik ben een gevaarlijke piraat',
        'issuer': {
          'code': '1',
          'name': 'AMERICAN BUREAU'
        }
      },
      'shipToShipActivities': [
        {
          'location': 'Ergens 1',
          'type': 'CARGO_TANK_CLEANING',
          'startDate': '2018-11-07',
          'endDate': '2018-11-08',
          'compliantWithSsp': true,
          'securityMeasures': 'Measures 1'
        },
        {
          'location': 'Ergens 2',
          'type': 'CHANGING_CREW',
          'startDate': '2018-11-06',
          'endDate': '2018-11-07',
          'compliantWithSsp': false,
          'securityMeasures': 'Measures 2'
        },
        {
          'location': 'Ergens 3',
          'type': 'DE_GASSING',
          'startDate': '2018-11-05',
          'endDate': '2018-11-06',
          'compliantWithSsp': true,
          'securityMeasures': 'Measures 3'
        },
        {
          'location': 'Ergens 4',
          'type': 'CARGO_TANK_CLEANING',
          'startDate': '2018-11-04',
          'endDate': '2018-11-05',
          'compliantWithSsp': false,
          'securityMeasures': 'Measures 4'
        },
        {
          'location': 'Ergens 5',
          'type': 'CHANGING_CREW',
          'startDate': '2018-11-03',
          'endDate': '2018-11-04',
          'compliantWithSsp': true,
          'securityMeasures': 'Measures 5'
        },
        {
          'location': 'Ergens 6',
          'type': 'DE_GASSING',
          'startDate': '2018-11-02',
          'endDate': '2018-11-03',
          'compliantWithSsp': false,
          'securityMeasures': 'Measures 6'
        },
        {
          'location': 'Ergens 7',
          'type': 'CARGO_TANK_CLEANING',
          'startDate': '2018-11-01',
          'endDate': '2018-11-02',
          'compliantWithSsp': true,
          'securityMeasures': 'Measures 7'
        },
        {
          'location': 'Ergens 8',
          'type': 'CHANGING_CREW',
          'startDate': '2018-10-31',
          'endDate': '2018-11-01',
          'compliantWithSsp': false,
          'securityMeasures': 'Measures 8'
        },
        {
          'location': 'Ergens 9',
          'type': 'DE_GASSING',
          'startDate': '2018-10-30',
          'endDate': '2018-10-31',
          'compliantWithSsp': true,
          'securityMeasures': 'Measures 9'
        }
      ],
      'securityRelatedMatter': 'Any security related matter to report'
    },
    'wasteDeclaration': null,
    'shipStoresDeclaration': null,
    'paxDeclaration': null,
    'uploads': {},
    'cancelled': false,
    'ataRegisteredByPa': false,
    'declarations': [
      {
        'type': 'VISIT',
        'status': 'ACCEPTED',
        'rejectReasons': null,
        'timeStamp': '2018-12-20T11:12:40.997Z'
      },
      {
        'type': 'WPCS',
        'status': 'DECLARED',
        'rejectReasons': null,
        'timeStamp': '2018-12-20T11:12:48.060Z'
      },
      {
        'type': 'SECURITY',
        'status': 'ACCEPTED',
        'rejectReasons': null,
        'timeStamp': '2018-12-20T11:13:13.063Z'
      },
      {
        'type': 'VISIT',
        'status': 'ACCEPTED',
        'rejectReasons': null,
        'timeStamp': '2018-12-24T10:31:08.960Z'
      },
      {
        'type': 'STO',
        'status': 'REJECTED',
        'rejectReasons': null,
        'timeStamp': '2018-12-24T10:31:30.960Z'
      },
      {
        'type': 'STO',
        'status': 'ACCEPTED',
        'rejectReasons': null,
        'timeStamp': '2018-12-24T10:32:08.960Z'
      }
    ],
    'incomingMovementHarbourMasterInfo': {
      'pilotOnBoard': '2018-12-23T10:32:08.960Z'
    },
    'berthVisitInfos': {
      '1abbb8789fe94b5c8adad25550a0a6b1': <BerthVisitInfo>{
        'harbourMasterInfo': {
          'pilotOnBoard': '2018-12-24T10:32:08.960Z'
        }
      }
    },
    'visitStatus': 'ARRIVED',
    'expected': false
  },
  NLRTM19500004: {
    'crn': 'NLRTM19500004',
    'additionalViewers': [],
    'copyOfVisit': null,
    'etaPortAis': '2018-11-08T10:22:00Z',
    'portOfCall': {
      'port': {
        'name': 'Rotterdam',
        'locationUnCode': 'NLRTM',
        'countryUnCode': 'NL',
        'euPort': true
      },
      'portAuthority': {
        'shortName': 'HBRRTM',
        'fullName': 'Havenbedrijf Rotterdam N.V.',
        'emailAddress': 'HCC@portofrotterdam.com',
        'phoneNumber': '+31-10-2521195',
        'faxNumber': '+31-10-2521000',
        'address': 'Wilhelminakade 909',
        'zipCode': '3072 AP',
        'city': 'Rotterdam',
        'countryUnCode': 'NL',
        'countryName': 'Netherlands',
        'customsEORINumber': 'NL24354561',
        'contact': 'Diana van Eijk',
        'portAuthorityId': 'HbR'
      },
      'customsOffice': {
        'name': 'Rotterdam Haven/Kantoor Maasvlakte',
        'unCode': 'NL000396'
      },
      'ataReported': true,
      'atdReported': true,
      'atdPortReported': true,
      'paDeclarationRequired': true,
      'swDeclarationRequired': true,
      'wasteEnabled': true,
      'dangerousGoodsEnabled': true,
      'harbourDuesEnabled': false,
      'orderNauticalServicesEnabled': true,
      'seaBuoy': {
        'name': 'Maascenter',
        'code': 'MC'
      }
    },
    'vessel': {
      'imoCode': '8758457',
      'name': 'DAVE BLACKBURN',
      'radioCallSign': 'WB6043',
      'motUnCode': '1729',
      'motName': 'Passenger ship',
      'summerDeadWeight': 0,
      'maxWidth': 15.84,
      'manoeuvringSpeed': null,
      'flagCountryUnCode': 'US',
      'netTonnage': 1338,
      'grossTonnage': 1338,
      'registrationPlaceUnloCode': 'USMSY',
      'registrationPlaceName': 'New Orleans',
      'registrationDate': '1944-02-01',
      'mmsiNumber': null,
      'helispotPresent': false,
      'fullLength': 110,
      'flagCode': 'USA'
    },
    'owner': {
      'fullName': 'Ship Operator 1',
      'portAuthorityId': 'SO1_R',
      'shortName': 'SO1',
      'emailAddress': 'pietjepuk@yoyo.nl',
      'address': 'Ship Operatorlaan 1',
      'city': 'Ship Operatorstad',
      'countryUnCode': 'NL',
      'countryName': 'Netherlands',
      'zipCode': '1111SO',
      'phoneNumber': '0102522288',
      'faxNumber': null,
      'contact': 'test centrum',
      'customsEORINumber': 'NL123456789'
    },
    'declarant': {
      'fullName': 'Back Office 1',
      'portAuthorityId': 'BO1_R',
      'shortName': 'BO1',
      'emailAddress': 'pietjepuk@yoyo.nl',
      'address': 'Back Officelaan 1',
      'city': 'Back Officestad',
      'countryUnCode': 'NL',
      'countryName': 'Netherlands',
      'zipCode': '1111SO',
      'phoneNumber': '0102522288',
      'faxNumber': null,
      'contact': 'test centrum',
      'customsEORINumber': 'NL123456789'
    },
    'financialDeclarant': {
      'fullName': 'Ship Operator 1',
      'portAuthorityId': 'SO1_R',
      'shortName': 'SO1',
      'emailAddress': 'pietjepuk@yoyo.nl',
      'address': 'Ship Operatorlaan 1',
      'city': 'Ship Operatorstad',
      'countryUnCode': 'NL',
      'countryName': 'Netherlands',
      'zipCode': '1111SO',
      'phoneNumber': '0102522288',
      'faxNumber': null,
      'contact': 'test centrum',
      'customsEORINumber': 'NL123456789'
    },
    'nominatedFinancialDeclarant': null,
    'orderNauticalServices': true,
    'creditNumberHolderSameAsDeclarant': true,
    'cargoDeclarants': [
      {
        'fullName': 'Ship Operator 1',
        'portAuthorityId': 'SO1_R',
        'shortName': 'SO1',
        'emailAddress': 'd.reijnders@portbase.com',
        'address': 'Ship Operatorlaan 1',
        'city': 'Ship Operatorstad',
        'countryUnCode': 'NL',
        'countryName': 'Netherlands',
        'zipCode': '1111SO',
        'phoneNumber': '0102522288',
        'faxNumber': null,
        'contact': 'TC',
        'customsEORINumber': 'NL123456789'
      }
    ],
    'allowedViewers': [
      {
        'fullName': 'Ship Operator 1',
        'portAuthorityId': 'SO1_R',
        'shortName': 'SO1',
        'emailAddress': 'pietjepuk@yoyo.nl',
        'address': 'Ship Operatorlaan 1',
        'city': 'Ship Operatorstad',
        'countryUnCode': 'NL',
        'countryName': 'Netherlands',
        'zipCode': '1111SO',
        'phoneNumber': '0102522288',
        'faxNumber': null,
        'contact': 'test centrum',
        'customsEORINumber': 'NL123456789'
      },
      {
        'fullName': 'Ship Operator 1',
        'portAuthorityId': 'SO1_R',
        'shortName': 'SO1',
        'emailAddress': 'pietjepuk@yoyo.nl',
        'address': 'Ship Operatorlaan 1',
        'city': 'Ship Operatorstad',
        'countryUnCode': 'NL',
        'countryName': 'Netherlands',
        'zipCode': '1111SO',
        'phoneNumber': '0102522288',
        'faxNumber': null,
        'contact': 'test centrum',
        'customsEORINumber': 'NL123456789'
      }
    ],
    'visitDeclaration': {
      'clientReferenceNumber': '7',
      'etaFirstEntryEu': '2018-11-08T11:00:00Z',
      'arrivalVoyage': {
        'voyageNumber': '1234567',
        'carrier': {
          'customsId': 'NL100000897',
          'name': 'Carrier 1',
          'scacCode': 'CARA',
          'smdgCode': 'CAR'
        }
      },
      'previousPorts': [
        {
          'id': '7239cd11943846a78869f7260f0fc219',
          'arrival': '2018-11-07T11:00:00Z',
          'departure': '2018-11-08T01:00:00Z',
          'port': {
            'name': 'Barcelona',
            'locationUnCode': 'ESBCN',
            'countryUnCode': 'ES',
            'euPort': true
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'ESBCN-0001',
                'name': 'Barcelona, Spain  AUTOTERMINAL, S.A.'
              },
              'arrivalDate': '2018-11-07',
              'departureDate': '2018-11-08',
              'securityLevel': 'SL1',
              'additionalSecurityMeasures': 'Measures 1'
            }
          ]
        },
        {
          'id': '39af59bb1dd344339f84d12c4533bd03',
          'arrival': '2018-11-06T11:00:00Z',
          'departure': '2018-11-07T11:00:00Z',
          'port': {
            'name': 'Tunisi',
            'locationUnCode': 'ITTUN',
            'countryUnCode': 'IT',
            'euPort': true
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'ITTUN-9999',
                'name': 'UNKNOWN'
              },
              'arrivalDate': '2018-11-06',
              'departureDate': '2018-11-07',
              'securityLevel': 'SL2',
              'additionalSecurityMeasures': 'Measures 2'
            }
          ]
        },
        {
          'id': '8a49e9d4d39647188bb8bd3d57dd4fa7',
          'arrival': '2018-11-05T11:00:00Z',
          'departure': '2018-11-06T11:00:00Z',
          'port': {
            'name': 'Barcelona',
            'locationUnCode': 'ESBCN',
            'countryUnCode': 'ES',
            'euPort': true
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'ESBCN-0003',
                'name': 'Barcelona, Spain  CENTRO DE CONTROL'
              },
              'arrivalDate': '2018-11-05',
              'departureDate': '2018-11-06',
              'securityLevel': 'SL3',
              'additionalSecurityMeasures': 'Measures 3'
            }
          ]
        },
        {
          'id': '2f4ae45bc63643de9fe7678f8021940d',
          'arrival': '2018-11-04T11:00:00Z',
          'departure': '2018-11-05T11:00:00Z',
          'port': {
            'name': 'Tunisi',
            'locationUnCode': 'ITTUN',
            'countryUnCode': 'IT',
            'euPort': true
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'ITTUN-9999',
                'name': 'UNKNOWN'
              },
              'arrivalDate': '2018-11-04',
              'departureDate': '2018-11-05',
              'securityLevel': 'SL1',
              'additionalSecurityMeasures': 'Measures 4'
            }
          ]
        },
        {
          'id': 'bb63bcf604c44adc8aada67c61a367c2',
          'arrival': '2018-11-03T11:00:00Z',
          'departure': '2018-11-04T11:00:00Z',
          'port': {
            'name': 'Barcelona',
            'locationUnCode': 'ESBCN',
            'countryUnCode': 'ES',
            'euPort': true
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'ESBCN-0005',
                'name': 'Barcelona, Spain  COMPAÑÍA LOGISTICA DE HIDROCARBUROS (CLH)'
              },
              'arrivalDate': '2018-11-03',
              'departureDate': '2018-11-04',
              'securityLevel': 'SL2',
              'additionalSecurityMeasures': 'Measures 5'
            }
          ]
        },
        {
          'id': '24a4865f6a494d2abc9c8d477705ad5c',
          'arrival': '2018-11-02T11:00:00Z',
          'departure': '2018-11-03T11:00:00Z',
          'port': {
            'name': 'Tunisi',
            'locationUnCode': 'ITTUN',
            'countryUnCode': 'IT',
            'euPort': true
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'ITTUN-9999',
                'name': 'UNKNOWN'
              },
              'arrivalDate': '2018-11-02',
              'departureDate': '2018-11-03',
              'securityLevel': 'SL3',
              'additionalSecurityMeasures': 'Measures 6'
            }
          ]
        },
        {
          'id': 'e4f33384c9f94439855c194b8d5ededb',
          'arrival': '2018-11-01T11:00:00Z',
          'departure': '2018-11-02T11:00:00Z',
          'port': {
            'name': 'Barcelona',
            'locationUnCode': 'ESBCN',
            'countryUnCode': 'ES',
            'euPort': true
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'ESBCN-0007',
                'name': 'Barcelona, Spain  CORPORACIÓN DE PRÁCTICOS DEL PUERTO DE BARCELONA, S.L.P.'
              },
              'arrivalDate': '2018-11-01',
              'departureDate': '2018-11-02',
              'securityLevel': 'SL1',
              'additionalSecurityMeasures': 'Measures 7'
            }
          ]
        },
        {
          'id': 'a51f713703c94ce885487c6645a1c76e',
          'arrival': '2018-10-31T11:00:00Z',
          'departure': '2018-11-01T11:00:00Z',
          'port': {
            'name': 'Tunisi',
            'locationUnCode': 'ITTUN',
            'countryUnCode': 'IT',
            'euPort': true
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'ITTUN-9999',
                'name': 'UNKNOWN'
              },
              'arrivalDate': '2018-10-31',
              'departureDate': '2018-11-01',
              'securityLevel': 'SL2',
              'additionalSecurityMeasures': 'Measures 8'
            }
          ]
        },
        {
          'id': '0ffbad66973446f4b354c1f3d29a294d',
          'arrival': '2018-10-30T11:00:00Z',
          'departure': '2018-10-31T11:00:00Z',
          'port': {
            'name': 'Barcelona',
            'locationUnCode': 'ESBCN',
            'countryUnCode': 'ES',
            'euPort': true
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'ESBCN-0009',
                'name': 'Barcelona, Spain  DECAL ESPAÑA S.A.'
              },
              'arrivalDate': '2018-10-30',
              'departureDate': '2018-10-31',
              'securityLevel': 'SL3',
              'additionalSecurityMeasures': 'Measures 9'
            }
          ]
        },
        {
          'id': '2fe3987117624e4e982eadb5fcf6b30f',
          'arrival': '2018-10-29T11:00:00Z',
          'departure': '2018-10-30T11:00:00Z',
          'port': {
            'name': 'Tunisi',
            'locationUnCode': 'ITTUN',
            'countryUnCode': 'IT',
            'euPort': true
          },
          'portFacilityVisits': [
            {
              'portFacility': {
                'code': 'ITTUN-9999',
                'name': 'UNKNOWN'
              },
              'arrivalDate': '2018-10-29',
              'departureDate': '2018-10-30',
              'securityLevel': 'SL2',
              'additionalSecurityMeasures': 'Measures 10'
            }
          ]
        },
        {
          'id': '14b77c47a3b24a6bb058d9998f89f213',
          'arrival': '2018-10-28T11:00:00Z',
          'departure': '2018-10-29T11:00:00Z',
          'port': {
            'name': 'Barcelona',
            'locationUnCode': 'ESBCN',
            'countryUnCode': 'ES',
            'euPort': true
          },
          'portFacilityVisits': []
        },
        {
          'id': '62ba903b912543b18438f132d2409263',
          'arrival': '2018-10-27T11:00:00Z',
          'departure': '2018-10-28T11:00:00Z',
          'port': {
            'name': 'Tunisi',
            'locationUnCode': 'ITTUN',
            'countryUnCode': 'IT',
            'euPort': true
          },
          'portFacilityVisits': []
        }
      ],
      'portVisit': {
        'intention': 'REQUEST_FOR_ENTRY',
        'pilotStation': {
          'code': 'MC',
          'name': 'Maascenter',
          'atSea': true
        },
        'entryPoint': {
          'code': 'LL',
          'name': 'Lage Licht'
        },
        'etaPort': '2018-11-09T10:00:00Z',
        'firstMovement': {
          'vesselDraft': 6.0,
          'vesselMasterName': 'Henk',
          'numberOfCrew': 20,
          'numberOfPassengers': 6,
          'cargo': 'OUT_OF_GAUGE',
          'pilotExemption': {
            'holderName': '',
            'number': '16'
          },
          'offStandardBeam': {
            'value': 4.5,
            'remarks': ''
          },
          'pilotService': {
            'required': false
          }
        },
        'passingThroughTugboats': null,
        'defectTypes': [
          'DEN',
          'DWE',
          'TOW'
        ],
        'berthVisits': [
          {
            'id': '1abbb8789fe94b5c8adad25550a0a6b1',
            'berth': {
              'name': 'HOLLAND AMERIKAKADE',
              'code': 'R1243',
              'terminalCode': '52090469',
              'berthGroupCode': 'HOLLAND AMERIKAKADE',
              'buoy': false,
              'partyToNotify': null
            },
            'eta': '2018-11-09T11:00:00Z',
            'ata': '2018-11-09T11:00:00Z',
            'etd': '2018-11-09T22:00:00Z',
            'atd': '2018-11-09T22:00:00Z',
            'mooring': 'PORT_SIDE',
            'remarks': null,
            'visitPurposes': [
              'CARGO_TANK_CLEANING',
              'CUSTOMS_CLEARANCE',
              'DISPOSAL_OF_WASTE',
              'LAID_UP',
              'LOADING'
            ],
            'bollardFrom': null,
            'bollardTo': null,
            'tugboatAtArrival': {
              'required': true,
              'serviceProvider': {
                'name': 'Gepke',
                'portAuthorityId': 'VERSCHOOR'
              },
              'requiredQuantity': 'SIX',
              'remarks': null
            },
            'boatmenAtArrival': {
              'required': true,
              'serviceProvider': {
                'name': 'V.i.o.s.',
                'portAuthorityId': 'VIOS'
              },
              'remarks': null
            },
            'boatmenAtDeparture': {
              'required': true,
              'serviceProvider': {
                'name': 'V.i.o.s.',
                'portAuthorityId': 'VIOS'
              },
              'remarks': null
            },
            'tugboatAtDeparture': {
              'required': true,
              'serviceProvider': {
                'name': 'Gepke',
                'portAuthorityId': 'VERSCHOOR'
              },
              'requiredQuantity': 'SIX',
              'remarks': null
            },
            'nextMovement': {
              'vesselDraft': 7.0,
              'vesselMasterName': 'Piet',
              'numberOfCrew': 21,
              'numberOfPassengers': 7,
              'cargo': 'OUT_OF_GAUGE',
              'pilotExemption': {
                'holderName': '',
                'number': '16'
              },
              'offStandardBeam': {
                'value': 4.5,
                'remarks': ''
              },
              'pilotService': null
            }
          },
          {
            'id': 'c1324bb553b2451d907e63e48d74c3e1',
            'berth': {
              'name': 'DORDR PWA KADE',
              'code': 'D4358',
              'terminalCode': '51433242',
              'berthGroupCode': 'DORDR PWA KADE',
              'buoy': false,
              'partyToNotify': null
            },
            'eta': '2018-11-10T01:00:00Z',
            'ata': null,
            'etd': '2018-11-10T22:00:00Z',
            'atd': null,
            'mooring': 'ALONGSIDE_OTHER_SHIP',
            'remarks': null,
            'visitPurposes': [
              'DISCHARGE_CRUDE_OIL',
              'TAKING_SUPPLIES'
            ],
            'bollardFrom': null,
            'bollardTo': null,
            'tugboatAtArrival': {
              'required': false,
              'serviceProvider': {
                'name': 'Gepke',
                'portAuthorityId': 'VERSCHOOR'
              },
              'requiredQuantity': null,
              'remarks': null
            },
            'boatmenAtArrival': {
              'required': true,
              'serviceProvider': {
                'name': 'V.i.o.s.',
                'portAuthorityId': 'VIOS'
              },
              'remarks': null
            },
            'boatmenAtDeparture': null,
            'tugboatAtDeparture': {
              'required': true,
              'serviceProvider': {
                'name': 'Gepke',
                'portAuthorityId': 'VERSCHOOR'
              },
              'requiredQuantity': 'FIVE',
              'remarks': null
            },
            'nextMovement': {
              'vesselDraft': 8.0,
              'vesselMasterName': 'Henk',
              'numberOfCrew': 20,
              'numberOfPassengers': 6,
              'cargo': 'BALLAST_CONDITION',
              'pilotExemption': null,
              'offStandardBeam': {
                'value': 12.8,
                'remarks': 'No remarks'
              },
              'pilotService': {
                'required': true,
                'serviceProvider': {
                  'name': 'Pilot 1',
                  'portAuthorityId': 'PIL1'
                },
                'remarks': null
              }
            }
          },
          {
            'id': 'abb42b0d775d44fca7e2d392d16ff340',
            'berth': {
              'name': 'PET 3 B 61',
              'code': 'R61',
              'terminalCode': '47644472',
              'berthGroupCode': 'PET 3 B 61',
              'buoy': true,
              'partyToNotify': null
            },
            'eta': '2018-11-11T11:00:00Z',
            'ata': null,
            'etd': '2018-11-11T22:00:00Z',
            'atd': null,
            'mooring': 'STARBOARD_SIDE',
            'remarks': null,
            'visitPurposes': [
              'CREW_MOVEMENT',
              'LOADING',
              'REFUGE'
            ],
            'bollardFrom': 16.2,
            'bollardTo': 20.2,
            'tugboatAtArrival': {
              'required': false,
              'serviceProvider': {
                'name': 'Gepke',
                'portAuthorityId': 'VERSCHOOR'
              },
              'requiredQuantity': null,
              'remarks': null
            },
            'boatmenAtArrival': {
              'required': true,
              'serviceProvider': {
                'name': 'V.i.o.s.',
                'portAuthorityId': 'VIOS'
              },
              'remarks': null
            },
            'boatmenAtDeparture': null,
            'tugboatAtDeparture': {
              'required': false,
              'serviceProvider': {
                'name': 'Smit havensleepdienst B.V.',
                'portAuthorityId': 'SMIT'
              },
              'requiredQuantity': null,
              'remarks': null
            },
            'nextMovement': {
              'vesselDraft': 12.5,
              'vesselMasterName': 'Henk',
              'numberOfCrew': 20,
              'numberOfPassengers': 6,
              'cargo': 'BALLAST_CONDITION',
              'pilotExemption': {
                'holderName': 'Exemption holder 1',
                'number': '12345'
              },
              'offStandardBeam': {
                'value': 12.8,
                'remarks': 'No remarks'
              },
              'pilotService': null
            }
          }
        ],
        'dangerousGoodsLoading': true,
        'dangerousGoodsDischarge': false,
        'dangerousGoodsTransit': true,
        'possibleAnchorage': true,
        'vesselInspectionRequired': true,
        'shipConfiguration': 'DOUBLE_HULL_TANKER',
        'exitPoint': {
          'code': 'OM',
          'name': 'Oude Maas',
          'atSea': false
        },
        'etdPort': '2018-11-13T11:00:00Z',
        'atdPort': null
      },
      'departureVoyage': {
        'voyageNumber': '1234568',
        'carrier': {
          'customsId': '12345',
          'name': null,
          'scacCode': null,
          'smdgCode': null
        }
      },
      'nextPorts': [
        {
          'id': 'f94034d2d4624e778775625184f22091',
          'arrival': '2018-11-14T11:00:00Z',
          'departure': '2018-11-15T11:00:00Z',
          'port': {
            'name': 'Antwerpen',
            'locationUnCode': 'BEANR',
            'countryUnCode': 'BE',
            'euPort': true
          },
          'customsOffice': null
        },
        {
          'id': 'd8d6ca9c2fa44747b4d8f111a75e11c7',
          'arrival': '2018-11-22T11:00:00Z',
          'departure': '2018-11-23T11:00:00Z',
          'port': {
            'name': 'Taranto',
            'locationUnCode': 'ITTAR',
            'countryUnCode': 'IT',
            'euPort': true
          },
          'customsOffice': {
            'name': 'S.O.T. AEROPORTO DELLO STRETTO',
            'unCode': 'IT087101'
          }
        },
        {
          'id': 'f4e5100bad6d4aa4a2cff0412bf963e0',
          'arrival': '2018-11-24T11:00:00Z',
          'departure': '2018-11-27T11:00:00Z',
          'port': {
            'name': 'El Iskandariya (= Alexandria)',
            'locationUnCode': 'EGALY',
            'countryUnCode': 'EG',
            'euPort': false
          },
          'customsOffice': null
        }
      ]
    },
    'nextVisitDeclaration': [],
    'securityDeclaration': {
      'securityReportRequired': 'YES',
      'companySecurityOfficer': {
        'name': 'Jansen',
        'email': 'Jansen@hotmail.com',
        'phoneNumber': '123456789'
      },
      'currentSecurityLevel': 'SL1',
      'approvedSspDocumentOnBoard': false,
      'isscSecurityDocument': {
        'availability': 'YES',
        'expiryDate': '2017-01-01',
        'reasonNoValidIsscOnBoard': 'ik ben een gevaarlijke piraat',
        'issuer': {
          'code': '1',
          'name': 'AMERICAN BUREAU'
        }
      },
      'shipToShipActivities': [
        {
          'location': 'Ergens 1',
          'type': 'CARGO_TANK_CLEANING',
          'startDate': '2018-11-07',
          'endDate': '2018-11-08',
          'compliantWithSsp': true,
          'securityMeasures': 'Measures 1'
        },
        {
          'location': 'Ergens 2',
          'type': 'CHANGING_CREW',
          'startDate': '2018-11-06',
          'endDate': '2018-11-07',
          'compliantWithSsp': false,
          'securityMeasures': 'Measures 2'
        },
        {
          'location': 'Ergens 3',
          'type': 'DE_GASSING',
          'startDate': '2018-11-05',
          'endDate': '2018-11-06',
          'compliantWithSsp': true,
          'securityMeasures': 'Measures 3'
        },
        {
          'location': 'Ergens 4',
          'type': 'CARGO_TANK_CLEANING',
          'startDate': '2018-11-04',
          'endDate': '2018-11-05',
          'compliantWithSsp': false,
          'securityMeasures': 'Measures 4'
        },
        {
          'location': 'Ergens 5',
          'type': 'CHANGING_CREW',
          'startDate': '2018-11-03',
          'endDate': '2018-11-04',
          'compliantWithSsp': true,
          'securityMeasures': 'Measures 5'
        },
        {
          'location': 'Ergens 6',
          'type': 'DE_GASSING',
          'startDate': '2018-11-02',
          'endDate': '2018-11-03',
          'compliantWithSsp': false,
          'securityMeasures': 'Measures 6'
        },
        {
          'location': 'Ergens 7',
          'type': 'CARGO_TANK_CLEANING',
          'startDate': '2018-11-01',
          'endDate': '2018-11-02',
          'compliantWithSsp': true,
          'securityMeasures': 'Measures 7'
        },
        {
          'location': 'Ergens 8',
          'type': 'CHANGING_CREW',
          'startDate': '2018-10-31',
          'endDate': '2018-11-01',
          'compliantWithSsp': false,
          'securityMeasures': 'Measures 8'
        },
        {
          'location': 'Ergens 9',
          'type': 'DE_GASSING',
          'startDate': '2018-10-30',
          'endDate': '2018-10-31',
          'compliantWithSsp': true,
          'securityMeasures': 'Measures 9'
        }
      ],
      'securityRelatedMatter': 'Any security related matter to report'
    },
    'wasteDeclaration': null,
    'shipStoresDeclaration': null,
    'paxDeclaration': null,
    'uploads': {},
    'cancelled': false,
    'ataRegisteredByPa': false,
    'declarations': [
      {
        'type': 'VISIT',
        'status': 'ACCEPTED',
        'rejectReasons': null,
        'timeStamp': '2018-12-20T11:12:40.997Z'

      },
      {
        'type': 'WASTE',
        'status': 'ACCEPTED',
        'rejectReasons': null,
        'timeStamp': '2018-12-20T11:12:40.997Z'

      },
      {
        'type': 'WPCS',
        'status': 'DECLARED',
        'rejectReasons': null,
        'timeStamp': '2018-12-20T11:12:48.060Z'
      },
      {
        'type': 'SECURITY',
        'status': 'ACCEPTED',
        'rejectReasons': null,
        'timeStamp': '2018-12-20T11:13:13.063Z'
      },
      {
        'type': 'VISIT',
        'status': 'ACCEPTED',
        'rejectReasons': null,
        'timeStamp': '2018-12-24T10:31:08.960Z'
      },
      {
        'type': 'PAX',
        'status': 'ACCEPTED',
        'rejectReasons': null,
        'timeStamp': '2018-12-24T10:31:30.960Z'
      },
      {
        'type': 'STO',
        'status': 'ACCEPTED',
        'rejectReasons': null,
        'timeStamp': '2018-12-24T10:32:08.960Z'
      }
    ],
    'berthVisitInfos': {},
    'visitStatus': 'ARRIVED',
    'expected': false
  },
  NLVLI19015424: {
    'crn': 'NLVLI19015424',
    'additionalViewers': [],
    'copyOfVisit': 'NLVLI19015297',
    'portOfCall': {
      'port': {
        'name': 'Vlissingen',
        'locationUnCode': 'NLVLI',
        'countryUnCode': 'NL',
        'euPort': true
      },
      'portAuthority': {
        'fullName': 'Zeeland Seaports',
        'portAuthorityId': 'PORTXCS',
        'shortName': 'ZEELANDSEAPORTS',
        'emailAddress': 'port@zeeland-seaports.com',
        'address': 'Schelpenpad 2',
        'city': 'Terneuzen',
        'countryUnCode': 'NL',
        'countryName': 'Netherlands',
        'zipCode': '4531 PD',
        'phoneNumber': '+31-115-647400',
        'faxNumber': '+31-115-647500',
        'contact': 'Zeeland Seaports',
        'customsEORINumber': null,
        'ean': null
      },
      'customsOffice': {
        'name': 'Roosendaal/Kantoor Vlissingen',
        'unCode': 'NL000856'
      },
      'ataReported': false,
      'atdReported': false,
      'atdPortReported': false,
      'paDeclarationRequired': false,
      'swDeclarationRequired': false,
      'wasteEnabled': false,
      'dangerousGoodsEnabled': false,
      'harbourDuesEnabled': false,
      'orderNauticalServicesEnabled': false,
      'enableNotificationsToPa': false,
      'authoriseOrganisationsDisabled': false,
      'tugBoatsRequired': true
    },
    'vessel': {
      'imoCode': '9006112',
      'name': 'UNDINE',
      'radioCallSign': '9HA4383',
      'motUnCode': '1512',
      'motName': 'RoRo vessel',
      'summerDeadWeight': 7225,
      'maxWidth': 21,
      'flagCode': 'MLT',
      'flagCountryUnCode': 'MT',
      'netTonnage': 3557,
      'grossTonnage': 11854,
      'registrationPlaceUnloCode': 'MTMLA',
      'registrationPlaceName': 'Valletta',
      'registrationDate': '1991-12-20',
      'mmsiNumber': '249804000',
      'fullLength': 147.4
    },
    'owner': {
      'fullName': 'CLdN ro-ro S.A.',
      'portAuthorityId': 'CLDN',
      'shortName': 'CLDN',
      'emailAddress': 'Ben.WILLEMSE@croports.com',
      'address': 'Rue Schiller 3-7',
      'city': 'Luxembourg',
      'countryUnCode': 'LU',
      'countryName': 'Luxembourg',
      'zipCode': '2519',
      'phoneNumber': '0035-2264466261',
      'faxNumber': null,
      'contact': 'Ben Willemse',
      'customsEORINumber': 'LU22055614',
      'ean': null
    },
    'declarant': {
      'fullName': 'CLdN ro-ro S.A.',
      'portAuthorityId': 'CLDN',
      'shortName': 'CLDN',
      'emailAddress': 'Ben.WILLEMSE@croports.com',
      'address': 'Rue Schiller 3-7',
      'city': 'Luxembourg',
      'countryUnCode': 'LU',
      'countryName': 'Luxembourg',
      'zipCode': '2519',
      'phoneNumber': '0035-2264466261',
      'faxNumber': null,
      'contact': 'Ben Willemse',
      'customsEORINumber': 'LU22055614',
      'ean': null
    },
    'financialDeclarant': {
      'fullName': 'CLdN ro-ro S.A.',
      'portAuthorityId': 'CLDN',
      'shortName': 'CLDN',
      'emailAddress': 'Ben.WILLEMSE@croports.com',
      'address': 'Rue Schiller 3-7',
      'city': 'Luxembourg',
      'countryUnCode': 'LU',
      'countryName': 'Luxembourg',
      'zipCode': '2519',
      'phoneNumber': '0035-2264466261',
      'faxNumber': null,
      'contact': 'Ben Willemse',
      'customsEORINumber': 'LU22055614',
      'ean': null
    },
    'nominatedFinancialDeclarant': null,
    'orderNauticalServices': false,
    'creditNumberHolderSameAsDeclarant': false,
    'declarantResponsibleForCustoms': false,
    'cargoDeclarants': [
      {
        'fullName': 'CLdN ro-ro S.A.',
        'portAuthorityId': 'CLDN',
        'shortName': 'CLDN',
        'emailAddress': 'caroline.dubois@cldn.com',
        'address': 'Rue Schiller 3-7',
        'city': 'Luxembourg',
        'countryUnCode': 'LU',
        'countryName': 'Luxembourg',
        'zipCode': '2519',
        'phoneNumber': '0035-2264466261',
        'faxNumber': null,
        'contact': 'Caroline Dubois',
        'customsEORINumber': 'LU22055614',
        'ean': null
      }
    ],
    'allowedViewers': [
      {
        'fullName': 'CLdN ro-ro S.A.',
        'portAuthorityId': 'CLDN',
        'shortName': 'CLDN',
        'emailAddress': 'Ben.WILLEMSE@croports.com',
        'address': 'Rue Schiller 3-7',
        'city': 'Luxembourg',
        'countryUnCode': 'LU',
        'countryName': 'Luxembourg',
        'zipCode': '2519',
        'phoneNumber': '0035-2264466261',
        'faxNumber': null,
        'contact': 'Ben Willemse',
        'customsEORINumber': 'LU22055614',
        'ean': null
      },
      {
        'fullName': 'CLdN ro-ro S.A.',
        'portAuthorityId': 'CLDN',
        'shortName': 'CLDN',
        'emailAddress': 'Ben.WILLEMSE@croports.com',
        'address': 'Rue Schiller 3-7',
        'city': 'Luxembourg',
        'countryUnCode': 'LU',
        'countryName': 'Luxembourg',
        'zipCode': '2519',
        'phoneNumber': '0035-2264466261',
        'faxNumber': null,
        'contact': 'Ben Willemse',
        'customsEORINumber': 'LU22055614',
        'ean': null
      },
      {
        'fullName': 'Zeeland Seaports',
        'portAuthorityId': 'PORTXCS',
        'shortName': 'ZEELANDSEAPORTS',
        'emailAddress': 'port@zeeland-seaports.com',
        'address': 'Schelpenpad 2',
        'city': 'Terneuzen',
        'countryUnCode': 'NL',
        'countryName': 'Netherlands',
        'zipCode': '4531 PD',
        'phoneNumber': '+31-115-647400',
        'faxNumber': '+31-115-647500',
        'contact': 'Zeeland Seaports',
        'customsEORINumber': null,
        'ean': null
      }
    ],
    'visitDeclaration': {
      'clientReferenceNumber': 'BW',
      'etaFirstEntryEu': null,
      'arrivalVoyage': {
        'voyageNumber': '976337',
        'carrier': {
          'customsId': 'LU22055614',
          'name': 'CLdN ro-ro S.A.',
          'scacCode': 'CLDN',
          'smdgCode': null
        }
      },
      'previousPorts': [
        {
          'id': '7fb7fad6-9343-47eb-a061-f6b142b0b9e6',
          'arrival': '2019-10-28T15:00:00Z',
          'departure': '2019-10-28T21:00:00Z',
          'port': {
            'name': 'Dagenham',
            'locationUnCode': 'GBDAG',
            'countryUnCode': 'GB',
            'euPort': true
          },
          'portFacilityVisits': []
        }
      ],
      'portVisit': {
        'pilotStation': null,
        'entryPoint': {
          'code': 'BESEA0WEST00AWN',
          'name': 'WANDELAAR'
        },
        'etaPort': '2019-10-29T07:00:00Z',
        'firstMovement': {
          'vesselDraft': null,
          'vesselMasterName': 'Frank Delbauve',
          'numberOfCrew': 19,
          'numberOfPassengers': 0,
          'cargo': 'ROLL_ON_ROLL_OFF',
          'pilotExemption': null,
          'offStandardBeam': null,
          'pilotService': null,
          'order': null,
          'stormPilotageInformation': null
        },
        'passingThroughTugboats': null,
        'defectTypes': [],
        'berthVisits': [
          {
            'id': '447f758c-a9ab-4593-aac9-090816ae0cee',
            'berth': {
              'name': 'Vlis Cobelfret',
              'code': 'CBFRT',
              'terminalCode': 'CBFRT',
              'berthGroupCode': 'Vlis Cobelfret',
              'buoy': false,
              'partyToNotify': null
            },
            'eta': '2019-10-29T10:00:00Z',
            'ata': null,
            'etd': '2019-10-29T16:00:00Z',
            'requestedEtd': null,
            'atd': null,
            'mooring': null,
            'remarks': null,
            'visitPurposes': [
              'CUSTOMS_CLEARANCE',
              'DISCHARGE',
              'LOADING'
            ],
            'bollardFrom': null,
            'bollardTo': null,
            'tugboatAtArrival': null,
            'boatmenAtArrival': null,
            'boatmenAtDeparture': null,
            'tugboatAtDeparture': null,
            'nextMovement': {
              'vesselDraft': null,
              'vesselMasterName': 'FRANK DELBAUVE',
              'numberOfCrew': 19,
              'numberOfPassengers': 0,
              'cargo': 'ROLL_ON_ROLL_OFF',
              'pilotExemption': null,
              'offStandardBeam': null,
              'pilotService': null,
              'order': null,
              'stormPilotageInformation': null
            }
          }
        ],
        'dangerousGoodsLoading': null,
        'dangerousGoodsDischarge': null,
        'dangerousGoodsTransit': null,
        'possibleAnchorage': false,
        'vesselInspectionRequired': false,
        'shipConfiguration': null,
        'exitPoint': {
          'code': 'BESEA0WEST00AWN',
          'name': 'WANDELAAR',
          'atSea': false
        },
        'etdPort': '2019-10-29T19:00:00Z',
        'atdPort': null,
        'ataPort': null
      },
      'departureVoyage': {
        'voyageNumber': '976340',
        'carrier': {
          'customsId': 'LU22055614',
          'name': 'CLdN ro-ro S.A.',
          'scacCode': 'CLDN',
          'smdgCode': null
        }
      },
      'nextPorts': [
        {
          'id': '9caee9cd-eaf8-493d-a542-731f05d0ed1f',
          'arrival': '2019-10-30T03:00:00Z',
          'departure': '2019-10-30T09:00:00Z',
          'port': {
            'name': 'Dagenham',
            'locationUnCode': 'GBDAG',
            'countryUnCode': 'GB',
            'euPort': true
          },
          'customsOffice': null
        }
      ]
    },
    'nextVisitDeclaration': [],
    'securityDeclaration': null,
    'wasteDeclaration': null,
    'shipStoresDeclaration': null,
    'dangerousGoodsDeclarations': [],
    'paxDeclarationSummaries': [],
    'etaPortAis': null,
    'ignoreEtaPortAis': false,
    'cancelled': false,
    'ataRegisteredByPa': false,
    'visitKnownAtPa': false,
    'dangerousGoodsEnabled': false,
    'sendToSwEnabled': true,
    'declarations': [
      {
        'type': 'WPCS',
        'status': 'DECLARED',
        'rejectReasons': null,
        'timeStamp': '2019-10-28T02:03:59.018Z'
      },
      {
        'type': 'WPCS',
        'status': 'DECLARED',
        'rejectReasons': null,
        'timeStamp': '2019-10-28T02:04:14.520Z'
      }
    ],
    'uploads': {},
    'berthVisitInfos': {},
    'visitStatus': 'EXPECTED',
    'allDangerousGoods': {
      'dangerousGoods': {
        'goods': [],
        'stowageAtArrival': [],
        'handlings': []
      },
      'cargoDeclarant': {
        'fullName': 'CLdN ro-ro S.A.',
        'portAuthorityId': 'CLDN',
        'shortName': 'CLDN',
        'emailAddress': 'Ben.WILLEMSE@croports.com',
        'address': 'Rue Schiller 3-7',
        'city': 'Luxembourg',
        'countryUnCode': 'LU',
        'countryName': 'Luxembourg',
        'zipCode': '2519',
        'phoneNumber': '0035-2264466261',
        'faxNumber': null,
        'contact': 'Ben Willemse',
        'customsEORINumber': 'LU22055614',
        'ean': null
      },
      'visitDeclarant': {
        'fullName': 'CLdN ro-ro S.A.',
        'portAuthorityId': 'CLDN',
        'shortName': 'CLDN',
        'emailAddress': 'Ben.WILLEMSE@croports.com',
        'address': 'Rue Schiller 3-7',
        'city': 'Luxembourg',
        'countryUnCode': 'LU',
        'countryName': 'Luxembourg',
        'zipCode': '2519',
        'phoneNumber': '0035-2264466261',
        'faxNumber': null,
        'contact': 'Ben Willemse',
        'customsEORINumber': 'LU22055614',
        'ean': null
      },
      'visitOwner': {
        'fullName': 'CLdN ro-ro S.A.',
        'portAuthorityId': 'CLDN',
        'shortName': 'CLDN',
        'emailAddress': 'Ben.WILLEMSE@croports.com',
        'address': 'Rue Schiller 3-7',
        'city': 'Luxembourg',
        'countryUnCode': 'LU',
        'countryName': 'Luxembourg',
        'zipCode': '2519',
        'phoneNumber': '0035-2264466261',
        'faxNumber': null,
        'contact': 'Ben Willemse',
        'customsEORINumber': 'LU22055614',
        'ean': null
      },
      'upload': null,
      'completed': true,
      'verifiedByShipOperator': true
    }
  }
};
