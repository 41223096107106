<div *ngIf="consignmentProcess$ | async as consignmentProcess">
  <div class="ms-4" *ngFor="let reason of getRejectReasons(consignmentProcess.transhipment)">
    <app-status-alert type="danger">
      <div>{{reason}}</div>
    </app-status-alert>
  </div>
  <div class="row">
    <div class="col-auto ms-auto dropdown always-enabled mb-2">
      <div *ngIf="!consignmentProcess.transhipment.cancelled" class="btn-group me-2">
        <button class="btn btn-outline-danger" tabindex="-1" data-bs-toggle="modal"
                data-bs-target="#cancelTranshipment">Cancel</button>
      </div>
      <button *ngIf="appContext.isAdmin()" class="btn btn-secondary" type="button" id="adminHamburgerMenu" data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false">
        <!-- Hamburger menu icon -->
        <i class="fa fa-user-shield" aria-hidden="true"></i>
      </button>
      <div *ngIf="appContext.isAdmin()" class="dropdown-menu dropdown-menu-end" aria-labelledby="adminHamburgerMenu">
        <!-- Messages -->
        <h6 class="dropdown-header" style="font-size: 18px;">Messages</h6>
        <a href="#" (click)="$event.preventDefault(); openMessages('noa2sw')" class="dropdown-item">
          REN to Singlewindow
        </a>
      </div>
    </div>
  </div>

  <app-tab-panel>
    <app-tab-item [templateRef]="detailsRef">
      <span class="tab-item" [ngClass]="{'ng-invalid text-invalid' : !!consignmentProcess$['ngDetailsInvalid']}">Details</span>
      <ng-template #detailsRef>
        <div class="p-3 border border-top-0 rounded-bottom" [appValidator]="consignmentProcess$" validatorProperty="ngDetailsInvalid">
          <div class="row">
            <div class="col-md">
              <app-form-group>
                <label>B/L number</label>
                <input class="form-control" [(ngModel)]="consignmentProcess.consignmentMasterLevel.consignmentNumber" [disabled]="true">
              </app-form-group>
            </div>
            <div class="col-md">
              <app-form-group>
                <label>REN MRN</label>
                <input class="form-control" [(ngModel)]="consignmentProcess.transhipment.mrn" [disabled]="true">
              </app-form-group>
            </div>
          </div>
          <div class="row">
            <div class="col-md">
              <app-form-group>
                <label>Carrier</label>
                <input class="form-control" [(ngModel)]="consignmentProcess.consignmentMasterLevel.carrier.name" [disabled]="true">
              </app-form-group>
            </div>
            <div class="col-md">
              <app-form-group>
                <label>Terminal</label>
                <input class="form-control" [(ngModel)]="consignmentProcess.consignmentMasterLevel.dischargeTerminal.terminalName" [disabled]="true">
              </app-form-group>
            </div>
          </div>
        </div>
      </ng-template>
    </app-tab-item>
    <app-tab-item [templateRef]="goodsRef">
      <span class="tab-item" [ngClass]="{'ng-invalid text-invalid' : !!consignmentProcess$['ngGoodsInvalid']}">Goods</span>
      <ng-template #goodsRef>
        <div class="p-3 border border-top-0 rounded-bottom" [appValidator]="consignmentProcess$" validatorProperty="ngDetailsInvalid">
          <app-transhipment-goods [goodsItems]="consignmentProcess.consignmentMasterLevel.goodsItems"></app-transhipment-goods>
        </div>
      </ng-template>
    </app-tab-item>
    <app-tab-item [templateRef]="historyRef">
      <span class="tab-item">History</span>
      <ng-template #historyRef>
        <div class="p-3 border border-top-0 rounded-bottom">
          <app-transhipment-history [cargoDeclarantIamConnectedId]="summary.cargoDeclarantIamConnectedId"
                                    [consignmentNumber]="summary.consignmentNumber" [transhipment]="consignmentProcess.transhipment">
          </app-transhipment-history>
        </div>
      </ng-template>
    </app-tab-item>
  </app-tab-panel>

  <div id="cancelTranshipment" class="modal fade" tabindex="-1" role="dialog" #cancelModal>
    <div class="modal-dialog" role="document">
      <div
        class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Cancel transhipment</h5>
        </div>
        <div class="modal-body">
          <p>Are you sure that you want to cancel this declaration?</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">No
          </button>
          <button type="button" class="btn btn-primary" (click)="cancel(cancelModal)" data-bs-dismiss="modal">Yes
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
