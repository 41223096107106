<ng-container
  *ngIf="context.visit.visitDeclaration.portVisit.berthVisits.length > 0 ; then visitingPort else passingThrough">
</ng-container>
<ng-template #visitingPort>
  <div class="row declaration-block"
       *ngIf="areNauticalServicesApplicable(context.visit.portOfCall.port.locationUnCode)">
    <div class="col-md-4" *ngIf="hasPilots(context.visit.portOfCall.port.locationUnCode)">
      <app-edit-nautical-service [(ngModel)]="context.visit.visitDeclaration.portVisit.firstMovement.pilotService"
                                 type="Pilot" [serviceProviders]="pilotProvider" id="pilot-incoming-movement"
                                 [isFirstMovement]="true">
      </app-edit-nautical-service>
    </div>
    <div class="col-md-4" *ngIf="hasTugboats(context.visit.portOfCall.port.locationUnCode)">
      <app-edit-nautical-service [(ngModel)]="context.visit.visitDeclaration.portVisit.berthVisits[0].tugboatAtArrival"
                                 type="Tugboat" [serviceProviders]="tugboatProvider" id="tugboats-incoming-movement">
      </app-edit-nautical-service>
    </div>
    <div class="col-md-4" *ngIf="hasBoatmen(context.visit.portOfCall.port.locationUnCode)">
      <app-edit-nautical-service [(ngModel)]="context.visit.visitDeclaration.portVisit.berthVisits[0].boatmenAtArrival"
                                 type="Boatmen" [serviceProviders]="boatmenProvider" id="boatmen-incoming-movement">
      </app-edit-nautical-service>
    </div>
  </div>
</ng-template>

<ng-template #passingThrough>
  <div class="row declaration-block"
       *ngIf="areNauticalServicesApplicable(context.visit.portOfCall.port.locationUnCode)">
    <div class="col-md-4" *ngIf="hasPilots(context.visit.portOfCall.port.locationUnCode)">
      <app-edit-nautical-service [(ngModel)]="context.visit.visitDeclaration.portVisit.firstMovement.pilotService"
                                 type="Pilot" [serviceProviders]="pilotProvider" required id="pilot-passingThrough">
      </app-edit-nautical-service>
    </div>
    <div class="col-md-4" *ngIf="hasTugboats(context.visit.portOfCall.port.locationUnCode)">
      <app-edit-nautical-service [(ngModel)]="context.visit.visitDeclaration.portVisit.passingThroughTugboats" required
                                 type="Tugboat" [serviceProviders]="tugboatProvider" id="tugboats-passingThrough">
      </app-edit-nautical-service>
    </div>
    <div class="col-md-4">
      <div class="card card-body">
        Boatmen not applicable when passing through port
      </div>
    </div>
  </div>
</ng-template>
