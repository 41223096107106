<div class="row">
  <div class="col-md-6">
    <app-form-group><label>Exemption type</label>
      <app-select [(ngModel)]="exemptionInfo.exemptionType" [options]="exemptionTypes"
                  required placeholder="– Select exemption type –">
      </app-select>
    </app-form-group>
    <app-form-group><label>Name</label>
      <input class="form-control" [(ngModel)]="exemptionInfo.name">
    </app-form-group>
    <app-form-group><label>Municipalities</label>
      <app-multiselect id="municipality" [(ngModel)]="exemptionInfo.municipalities" [options]= "municipalities"
                       placement="top-end" title="Select Rotterdam municipality" required [hideSelected]="true"
                       [enableSelectAll]="true" [autoSelectOnlyOption]="true">
      </app-multiselect>
    </app-form-group>
    <app-form-group><label>Berths</label>
      <app-multi-search [(ngModel)]="exemptionInfo.berths"
                        [searchFunction]="berthSearchFunction"
                        [inputFormatter]="berthFormatter"
                        [resultFormatter]="berthFormatter"
                        [disabled]="municipalityNotSelected"
                        placeholder="Select applicable berths">
      </app-multi-search>
    </app-form-group>
  </div>
  <div class="col-md-6">
    <div *ngIf="isMunicipalitySelected('Dordrecht')">
      <app-form-group><label>Considering Dordrecht</label>
        <textarea class="form-control" [(ngModel)]="exemptionInfo.consideringDordrecht"></textarea>
      </app-form-group>
    </div>
    <div *ngIf="isMunicipalitySelected('Gorinchem')">
      <app-form-group><label>Considering Gorinchem</label>
        <textarea class="form-control" [(ngModel)]="exemptionInfo.consideringGorinchem"></textarea>
      </app-form-group>
    </div>
    <div *ngIf="isMunicipalitySelected('Moerdijk')">
      <app-form-group><label>Considering Moerdijk</label>
        <textarea class="form-control" [(ngModel)]="exemptionInfo.consideringMoerdijk"></textarea>
      </app-form-group>
    </div>
    <div *ngIf="isMunicipalitySelected('Nijmegen')">
      <app-form-group><label>Considering Nijmegen</label>
        <textarea class="form-control" [(ngModel)]="exemptionInfo.consideringNijmegen"></textarea>
      </app-form-group>
    </div>
    <div *ngIf="isMunicipalitySelected('Papendrecht')">
      <app-form-group><label>Considering Papendrecht</label>
        <textarea class="form-control" [(ngModel)]="exemptionInfo.consideringPapendrecht"></textarea>
      </app-form-group>
    </div>
    <div *ngIf="isMunicipalitySelected('Rotterdam')">
      <app-form-group><label>Considering Rotterdam</label>
        <textarea class="form-control" [(ngModel)]="exemptionInfo.consideringRotterdam"></textarea>
      </app-form-group>
    </div>
    <div *ngIf="isMunicipalitySelected('Schiedam')">
      <app-form-group><label>Considering Schiedam</label>
        <textarea class="form-control" [(ngModel)]="exemptionInfo.consideringSchiedam"></textarea>
      </app-form-group>
    </div>
    <div *ngIf="isMunicipalitySelected('Stellendam')">
      <app-form-group><label>Considering Stellendam</label>
        <textarea class="form-control" [(ngModel)]="exemptionInfo.consideringStellendam"></textarea>
      </app-form-group>
    </div>
    <div *ngIf="isMunicipalitySelected('Vlaardingen')">
      <app-form-group><label>Considering Vlaardingen</label>
        <textarea class="form-control" [(ngModel)]="exemptionInfo.consideringVlaardingen"></textarea>
      </app-form-group>
    </div>
    <div *ngIf="isMunicipalitySelected('Zwijndrecht')">
      <app-form-group><label>Considering Zwijndrecht</label>
        <textarea class="form-control" [(ngModel)]="exemptionInfo.consideringZwijndrecht"></textarea>
      </app-form-group>
    </div>
  </div>
</div>
