<div class="row head">
    <div class="col-2 ms-auto" style="margin-top:12px; margin-bottom:12px">
        <button type="button" class="btn btn-primary" (click)="addExemption();">
            <i class="button-icon fa-solid fa-light fa-plus"></i> <span
                class="ps-2 d-none d-md-inline">New request</span>
        </button>
    </div>
</div>
<hr style="margin: 0.1em"/>
<div class="row">
    <div class="col-5">Facilities</div>
    <div class="col-2">Request type</div>
    <div class="col-2">Requested</div>
    <div class="col-2">Status</div>
    <div class="col-1"></div>
</div>
<hr style="margin: 0.1em"/>

<ng-container *ngFor="let fuelExemption of fuelExemptions;">
    <app-collapse [collapse]="collapz" [showOnLoad]="!fuelExemption.requestedDate">
        <div class="row">
            <div class="col-5">
                <ul class="taglist">
                    <li *ngFor="let facility of fuelExemption.facilities">{{ facility }}</li>
                </ul>
            </div>
            <div class="col-2">{{ fuelExemption.requestType }}</div>
            <div class="col-2">{{ fuelExemption.requestedDate | date:'dd-MM-YYYY' }}</div>
            <div class="col-2">{{ fuelExemption.status }}</div>
            <div class="col-1"></div>
        </div>
    </app-collapse>

    <ng-template #collapz>
        <div class="collapse">
            <div class="py-3 ms-2">
                <div class="row" style="margin-bottom: 12px; margin-right: 4px;">
                    <button type="button" class="btn btn-primary ms-auto col-md-auto" (click)="send(fuelExemption);">
                        <span class="ps-2 d-none d-md-inline">Send</span>
                    </button>
                </div>
                <app-form-group label="Type">
                    <app-select [(ngModel)]="fuelExemption.requestType" required
                                [options]="['Ship to Ship', 'Truck to Ship', 'Mobile Facility to Ship']">
                    </app-select>
                </app-form-group>
                <app-form-group label="Bunker vessels" *ngIf="fuelExemption.requestType == 'Ship to Ship'">
                    <app-multi-input [(ngModel)]="fuelExemption.facilities" required></app-multi-input>
                </app-form-group>
                <app-form-group label="Trucks" *ngIf="fuelExemption.requestType == 'Truck to Ship'">
                    <app-multi-input [(ngModel)]="fuelExemption.facilities" required></app-multi-input>
                </app-form-group>
                <app-form-group label="Facilities" *ngIf="fuelExemption.requestType == 'Mobile Facility to Ship'">
                    <app-multi-input [(ngModel)]="fuelExemption.facilities" required></app-multi-input>
                </app-form-group>
                <app-form-group label="Fuel to be bunkered">
                  <app-search [(ngModel)]="fuelExemption.fuel" required [searchFunction]="products"
                              [resultFormatter]="formatProduct" [inputFormatter]="formatProduct">
                  </app-search>
                </app-form-group>
                <app-form-group label="Type of Operation">
                  <textarea [(ngModel)]="fuelExemption.typeOfOperation" class="form-control" required
                              placeholder="Please give a full and detailed description of the bunker operations you request a permission to operate for">
                  </textarea>
                </app-form-group>
                <app-form-group label="Locations">
                  <textarea [(ngModel)]="fuelExemption.locations" class="form-control" required
                              placeholder="Please describe for which locations in the port you request a permission to operate">
                  </textarea>
                </app-form-group>
                <app-form-group label="Is or has your company been operating bunker operations in other ports?">
                  <app-yes-no [(ngModel)]="fuelExemption.operateInOtherPorts" class="form-control" required></app-yes-no>
                </app-form-group>
                <app-form-group *ngIf="fuelExemption.operateInOtherPorts">
                  <label>In which other ports is the company <strong>presently</strong> operational and which operations are performed?</label>
                  <textarea [(ngModel)]="fuelExemption.presentOtherPorts" class="form-control" ></textarea>
                </app-form-group>
                <app-form-group *ngIf="fuelExemption.operateInOtherPorts">
                  <label>In which other ports <strong>was</strong> the company operational and which operations have been performed?</label>
                    <textarea [(ngModel)]="fuelExemption.previousOtherPorts" class="form-control"></textarea>
                </app-form-group>
                <app-form-group label="May we contact the above ports?" *ngIf="fuelExemption.operateInOtherPorts">
                  <app-yes-no [(ngModel)]="fuelExemption.mayContactOtherPorts" class="form-control"></app-yes-no>
                </app-form-group>
                <app-form-group label="Contacts for above-described port authorities or competent authorities." *ngIf="fuelExemption.operateInOtherPorts && fuelExemption.mayContactOtherPorts">
                  <textarea [(ngModel)]="fuelExemption.contactsOtherPorts" class="form-control"></textarea>
                </app-form-group>
                <app-form-group label="Are you willing to share incident and near miss information on bunker activities in the above-described port(s)?" *ngIf="fuelExemption.operateInOtherPorts">
                  <app-yes-no [(ngModel)]="fuelExemption.shareIncidentInformationOtherPorts" class="form-control"></app-yes-no>
                </app-form-group>
                <app-form-group label="Have you in the past been audited by a port authority or competent authority that used the IAPH audit tool?">
                  <app-yes-no [(ngModel)]="fuelExemption.audited" class="form-control" required></app-yes-no>
                </app-form-group>
                <app-form-group label="Describe audit." *ngIf="fuelExemption.audited">
                  <textarea [(ngModel)]="fuelExemption.auditDescription" class="form-control"></textarea>
                </app-form-group>
                <app-form-group label="Are you presently in the process of applying for a License to operate in another port?">
                  <app-yes-no [(ngModel)]="fuelExemption.applyingForLicense" class="form-control" required></app-yes-no>
                </app-form-group>
                <app-form-group label="Describe the other ports you are applying for permission to operate." *ngIf="fuelExemption.applyingForLicense">
                  <textarea [(ngModel)]="fuelExemption.applyingForLicenseDescription" class="form-control"></textarea>
                </app-form-group>
                <app-form-group label="May we contact the above-described port(s)?" *ngIf="fuelExemption.applyingForLicense">
                  <app-yes-no [(ngModel)]="fuelExemption.mayContactPortsApplyingLicense" class="form-control"></app-yes-no>
                </app-form-group>
                <app-form-group label="Would you agree upon a joined audit by ports?" *ngIf="fuelExemption.applyingForLicense">
                  <app-yes-no [(ngModel)]="fuelExemption.agreeToJoinedAudit" class="form-control"></app-yes-no>
                </app-form-group>
                <app-form-group label="Are you willing to share all incident and near miss information from the past four years?">
                  <app-yes-no [(ngModel)]="fuelExemption.shareIncidentInformation" class="form-control" required></app-yes-no>
                </app-form-group>
                <app-form-group label="Are you willing to share previous audit and inspection result information from other parties? E.g. Class and Port State inspections?">
                  <app-yes-no [(ngModel)]="fuelExemption.sharePreviousResults" class="form-control" required></app-yes-no>
                </app-form-group>
                <app-form-group label="Would you be in consent of discrete peer-review of the audit by a third-party, port authority or competent authority for quality assurance purposes?">
                  <app-yes-no [(ngModel)]="fuelExemption.consentPeerReview" class="form-control" required></app-yes-no>
                </app-form-group>
                <app-form-group label="Would you be in consent of another port authority or competent authority joining the audit, either as an auditor or as an observer during audit, for audit or training purposes?">
                  <app-yes-no [(ngModel)]="fuelExemption.consentOtherPortJoinAudit" class="form-control" required></app-yes-no>
                </app-form-group>
                <app-form-group>
                  <app-info class="formGroupLabel">
                    <label>Would you, after the audit, be in consent of us sharing audit, re-audit and reality-checks information with other port authorities or competent authorities that we cooperate with in performing audits?</label>
                    <span class="tooltipContent">Please note that permission will be requested on a case-by-case basis.</span>
                  </app-info>
                  <app-yes-no [(ngModel)]="fuelExemption.consentShareAuditInformation" class="form-control" required></app-yes-no>
                </app-form-group>
                <app-form-group>
                  <app-info class="formGroupLabel">
                    <label>Reason for request for exemption</label>
                    <span class="tooltipContent">Clearly substantiate why the regulations and restrictions stated in the Port By-Law cannot be met.</span>
                  </app-info>
                  <textarea type="text" [(ngModel)]="fuelExemption.reason" class="form-control" required></textarea>
                </app-form-group>

            </div>
        </div>
    </ng-template>
</ng-container>


