import {
    toBase64
} from '../../../common/upload/upload.utils';

export function uploadExemptionFiles(files: File[]) {

    files.forEach(async (file, index) => {

        this.uploadedExemptionFileName = file.name;
        this.uploadedExemptionFileContent = await toBase64(file).toPromise();

        console.log(this.uploadedExemptionFileContent)

        // const saveExemptionFileCommand = <SaveExemptionFileCommand> {
        //     crn: VisitContext.visit.crn,
        //     berthVisitId: this.berthVisit.id,
        //     exemptionId: this.selectedExemptionId,
        //     exemptionType: this.selectedExemptionType,
        //     index: this.index,
        //     fileName: this.uploadedExemptionFileName,
        //     fileContent: this.uploadedExemptionFileContent
        // };

       // Post files to be stored under S3
       // sendCommand('com.portbase.bezoekschip.common.api.exemptions.SaveExemptionFileCommand',
       //     saveExemptionFileCommand, () => {
       //     AppContext.registerSuccess('Exemption was saved successfully.');
       // });
    });
}
