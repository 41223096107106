import {Component} from '@angular/core';
import {
  Exemption_4_5,
  Exemption_4_5_ActivityType,
  Exemption_4_5_ActivityTypeExecutor,
  Exemption_4_5_ActivityTypeFuel,
  Exemption_4_5_ActivityTypeLocation,
} from '@portbase/bezoekschip-service-typescriptmodels';
import {ExemptionBaseComponent} from "../exemption_base/exemption_base.component";

@Component({
  selector: 'app-visit-exemptions-exemption-4-5',
  templateUrl: './exemption_4_5.component.html',
  styleUrls: ['./exemption_4_5.component.css']
})
export class Exemption_4_5Component extends ExemptionBaseComponent {

  exemptionSubType: Exemption_4_5;

  activityTypes: Exemption_4_5_ActivityType[] = [null,
                                               Exemption_4_5_ActivityType.REPAIR_WITH_FLAME_HEAT,
                                               Exemption_4_5_ActivityType.REPAIR_WITHOUT_FLAME_HEAT,
                                               Exemption_4_5_ActivityType.TRAIL_SAILING,
                                               Exemption_4_5_ActivityType.UNDERWATER_WORK,
                                               Exemption_4_5_ActivityType.TEST_RUNNING];

  activityTypeExecutors: Exemption_4_5_ActivityTypeExecutor[] = [null,
    Exemption_4_5_ActivityTypeExecutor.OWN_CREW,
    Exemption_4_5_ActivityTypeExecutor.OTHER];

  activityTypeFuel: Exemption_4_5_ActivityTypeFuel[] = [null,
    Exemption_4_5_ActivityTypeFuel.AMMONIA,
    Exemption_4_5_ActivityTypeFuel.LNG,
    Exemption_4_5_ActivityTypeFuel.METHANOL,
    Exemption_4_5_ActivityTypeFuel.HYDROGEN,
    Exemption_4_5_ActivityTypeFuel.CONVENTIONAL_FUELS,
    Exemption_4_5_ActivityTypeFuel.OTHER];

  activityTypeLocations: Exemption_4_5_ActivityTypeLocation[] = [null,
    Exemption_4_5_ActivityTypeLocation.ON_CONSTRUCTION,
    Exemption_4_5_ActivityTypeLocation.AFT,
    Exemption_4_5_ActivityTypeLocation.LOADING_ZONE,
    Exemption_4_5_ActivityTypeLocation.ENGINE_ROOM,
    Exemption_4_5_ActivityTypeLocation.FOREDECK];

  activityTypesFormatter = (reasonType: Exemption_4_5_ActivityType) => {
    switch (reasonType) {
      case null:
        return 'None';
      case "REPAIR_WITH_FLAME_HEAT":
        return 'Repair with open flame / heat';
      case "REPAIR_WITHOUT_FLAME_HEAT":
        return 'Repair without flame or heat';
      case "TRAIL_SAILING":
        return 'Trial sailing, push and pull test';
      case "UNDERWATER_WORK":
        return 'Underwater work, screw polishing, underwater brushing';
      case "TEST_RUNNING":
        return 'Test running of engines/propellers moored or in front of the quay';
    }
  };

  activityTypeExecutorsFormatter = (activityTypeExecutor: Exemption_4_5_ActivityTypeExecutor) => {
    switch (activityTypeExecutor) {
      case null:
        return 'None';
      case "OWN_CREW":
        return 'Own crew';
      case "OTHER":
        return 'Other';
    }
  };

  activityTypeFuelFormatter = (activityTypeFuel: Exemption_4_5_ActivityTypeFuel) => {
    switch (activityTypeFuel) {
      case null:
        return 'None';
      case "AMMONIA":
        return 'Ammonia';
      case "LNG":
        return 'LNG';
      case "METHANOL":
        return 'Methanol';
      case "HYDROGEN":
        return 'Hydrogen';
      case "CONVENTIONAL_FUELS":
        return 'Conventional Fuels';
      case "OTHER":
        return 'Other';
    }
  };

  activityTypeLocationsFormatter = (activityTypeLocation: Exemption_4_5_ActivityTypeLocation) => {
    switch (activityTypeLocation) {
      case null:
        return 'None';
      case "ON_CONSTRUCTION":
        return 'On construction';
      case "AFT":
        return 'Aft';
      case "LOADING_ZONE":
        return 'In loading zone';
      case "ENGINE_ROOM":
        return 'In engine room';
      case "FOREDECK":
        return 'Foredeck';
    }
  };
}

