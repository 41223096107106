import {Component, Input} from '@angular/core';
import {Exemption, TaskMessageStatus} from "@portbase/bezoekschip-service-typescriptmodels";
import {DeclarationMessageStatus} from "../../visit-overview/visit-overview-item/visit-overview.utils";
import {isArray} from "lodash";

@Component({
  selector: 'app-status-indicator',
  templateUrl: './status-indicator.component.html',
  styleUrls: ['./status-indicator.component.scss']
})
export class StatusIndicatorComponent {
  _statusIndicatorButtonId: string;
  _statusIndicatorSpanId: string;

  @Input() summarizeIcon: boolean;
  exemptions: Exemption[] = [];
  @Input() messageStatus: TaskMessageStatus;
  messageStatuses: DeclarationMessageStatus[] = [];

  @Input()
  set statusIndicatorButtonId(statusIndicatorButtonId: string) {
    this._statusIndicatorButtonId = statusIndicatorButtonId;
  }

  @Input()
  set statusIndicatorSpanId(statusIndicatorSpanId: string) {
    this._statusIndicatorSpanId = statusIndicatorSpanId;
  }

  @Input("messageStatuses")
  set setMessageStatuses(messageStatuses: DeclarationMessageStatus | DeclarationMessageStatus[]) {
    this.messageStatuses = isArray(messageStatuses) ? messageStatuses || [] : messageStatuses ? [messageStatuses] : [];
  }

  hoverStatusIndicatorSpan(statusIndicatorSpanId: string, hover: boolean): void {
    const element = document.getElementById(statusIndicatorSpanId);
    if (hover) {
      element.style.textDecoration = 'underline';
    }
    else {
      element.style.textDecoration = 'none';
    }
  }
}
