<ng-container>

  <div>
    <b>{{ name }}</b>
    <app-info class="formGroupLabel" [wide]="false" [placement]="'left'">
      <div class="tooltipContent">
        <b>Considering</b>
        <ul>
          <div *ngFor="let consider of splitConsidering()">
            <li>
              {{consider}}
            </li>
          </div>
        </ul>
      </div>
    </app-info>
  </div>

  <app-visit-exemptions-exemption-general [exemption]="exemption"></app-visit-exemptions-exemption-general>

  <ng-container>
    <div class="row fieldset">
      <div class="col-md">
        <app-form-group label="Substance name (Proper shipping name)">
          <app-search [(ngModel)]="dangerousGoods" dataKey="name"
                      (ngModelChange)="onDangerousGoodsChange(dangerousGoods)"
                      [inputFormatter]="portVisitUtils.dangerInformationFormatter"
                      [searchFunction]="portVisitUtils.findDangerInformation"
                      [resultFormatter]="portVisitUtils.dangerInformationFormatter"
                      placeholder="Find by name or UN code" >
          </app-search>
        </app-form-group>
      </div>
      <div class="col-md">
        <app-form-group label="Substance name">
          <textarea [(ngModel)]="exemption['substanceName']" id="substanceName" type="text" rows="3" maxlength="1024" class="form-control" required></textarea>
        </app-form-group>
      </div>
    </div>
    <div class="row fieldset">
      <div class="col-md">
      </div>
      <div class="col-md">
        <app-form-group label="UN or VN number">
          <input [(ngModel)]="exemption['unVnNumber']" id="unVnNumber" type="text" maxlength="256" class="form-control" required>
        </app-form-group>
      </div>
    </div>

    <div class="row fieldset">
      <div class="col-md">
        <app-form-group>
          <app-info class="formGroupLabel">
            <label>Start transshipment</label>
            <div class="tooltipContent">If total berthing time > 4 hours; Request not possible due to exceeding berthing time</div>
          </app-info>
          <app-date-field [(ngModel)]="exemption['from']" id="from" required></app-date-field>
        </app-form-group>
        <app-form-group>
          <app-info class="formGroupLabel">
            <label>End transshipment</label>
            <div class="tooltipContent">If total berthing time > 4 hours; Request not possible due to exceeding berthing time</div>
          </app-info>
          <app-date-field [(ngModel)]="exemption['to']" id="to" required></app-date-field>
        </app-form-group>
      </div>
      <div class="col-md">
      </div>
    </div>

    <div class="row fieldset">
      <div class="col-md">
        <app-form-group label="Type of mobile facility / vehicle registration number">
          <input [(ngModel)]="exemption['typeOfFacilityAndVehicleNumber']" id="typeOfFacilityAndVehicleNumber" type="text" maxlength="256" class="form-control" required>
        </app-form-group>
      </div>
      <div class="col-md">
        <app-form-group label="Truck max. axle weight (ton)">
          <input [(ngModel)]="exemption['maxAxleWeight']" id="maxAxleWeight" type="number" maxlength="256" class="form-control" required>
        </app-form-group>
      </div>
    </div>

    <div class="row fieldset">
      <div class="col-md">
        <app-form-group label="Reason for request for exemption">
          <textarea [(ngModel)]="exemption['reason']" id="reason" type="text" rows="3" minlength="20" maxlength="1024" class="form-control" required></textarea>
        </app-form-group>
      </div>
      <div class="col-md">
        <app-form-group label="Details">
          <textarea [(ngModel)]="exemption['details']" id="details" type="text" rows="3" maxlength="256" class="form-control"></textarea>
        </app-form-group>
      </div>
    </div>
  </ng-container>
</ng-container>
