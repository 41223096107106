<div class="modal-header">
  <button type="button" class="btn-close ms-2" data-bs-dismiss="modal" aria-label="Close"></button>
  <div class="d-inline-flex me-1 ms-2">
    <span class="page-title">Fuel exemption</span>
  </div>
</div>

<div class="modal-body">
  <div class="card">
    <div class="card-body">
      <div class="container py-3 px-5">
        <div class="row">
          <h5 class="header-1 col-10">Exemption details</h5>
        </div>
        <app-visit-exemptions-exemption-general [exemption]="data"></app-visit-exemptions-exemption-general>
        <div class="row">
          <app-form-field class="col-4" label="Type">
            <app-select [(ngModel)]="data.requestType" required [small]
                        [options]="['Ship to Ship', 'Truck to Ship', 'Mobile Facility to Ship']">
            </app-select>
          </app-form-field>
        </div>
        <div class="row">
          <app-form-field class="col-6" label="Bunker vessels" *ngIf="data.requestType == 'Ship to Ship'">
            <app-multi-input [(ngModel)]="data.facilities" required></app-multi-input>
          </app-form-field>
          <app-form-field class="col-6" label="Trucks" *ngIf="data.requestType == 'Truck to Ship'">
            <app-multi-input [(ngModel)]="data.facilities" required></app-multi-input>
          </app-form-field>
          <app-form-field class="col-6" label="Facilities" *ngIf="data.requestType == 'Mobile Facility to Ship'">
            <app-multi-input [(ngModel)]="data.facilities" required></app-multi-input>
          </app-form-field>
        </div>
        <div class="row">
          <app-form-field class="col-6" label="Fuel to be bunkered">
            <app-search [(ngModel)]="data.fuel" required [searchFunction]="products"
                        [resultFormatter]="formatProduct" [inputFormatter]="formatProduct">
            </app-search>
          </app-form-field>
        </div>
        <app-form-field>
          <app-info class="formGroupLabel">
            <label>Type of Operation</label>
            <span class="tooltipContent">Please give a full and detailed description of the bunker operations you request a permission to operate for</span>
          </app-info>
          <textarea [(ngModel)]="data.typeOfOperation" class="form-control" required></textarea>
        </app-form-field>
        <app-form-field>
          <app-info class="formGroupLabel">
            <label>Locations</label>
            <span class="tooltipContent">Please describe for which locations in the port you request a permission to operate</span>
          </app-info>
          <textarea [(ngModel)]="data.locations" class="form-control" required></textarea>
        </app-form-field>
        <app-form-field>
          <app-info class="formGroupLabel">
            <label>Operations in other ports</label>
            <span class="tooltipContent">Is or has your company been operating bunker operations in other ports?</span>
          </app-info>
          <app-yes-no [(ngModel)]="data.operateInOtherPorts" required></app-yes-no>
        </app-form-field>
        <div class="card" *ngIf="data.operateInOtherPorts">
          <div class="card-body px-4">
            <app-form-field>
              <app-info class="formGroupLabel">
                <label>Ports with <strong>present</strong> operations</label>
                <span class="tooltipContent">In which other ports is the company <strong>presently</strong>
                  operational and which operations are performed?</span>
              </app-info>
              <textarea [(ngModel)]="data.presentOtherPorts" class="form-control"></textarea>
            </app-form-field>
            <app-form-field>
              <app-info class="formGroupLabel">
                <label>Ports with <strong>previous</strong> operations</label>
                <span class="tooltipContent">In which other ports <strong>was</strong> the company operational and which operations have been
                  performed?</span>
              </app-info>
              <textarea [(ngModel)]="data.previousOtherPorts" class="form-control"></textarea>
            </app-form-field>
            <app-form-field label="May we contact the above ports?">
              <app-yes-no [(ngModel)]="data.mayContactOtherPorts"></app-yes-no>
            </app-form-field>
            <div *ngIf="data.mayContactOtherPorts" class="px-4">
              <app-form-field>
                <app-info class="formGroupLabel">
                  <label>Contacts</label>
                  <span
                    class="tooltipContent">Contacts for above-described port authorities or competent authorities.</span>
                </app-info>
                <textarea [(ngModel)]="data.contactsOtherPorts" class="form-control"></textarea>
              </app-form-field>
            </div>
            <app-form-field>
              <app-info class="formGroupLabel">
                <label>Share with other ports</label>
                <span class="tooltipContent">Are you willing to share incident and near miss information on bunker activities in the above-described port(s)?</span>
              </app-info>
              <app-yes-no [(ngModel)]="data.shareIncidentInformationOtherPorts"></app-yes-no>
            </app-form-field>
          </div>
        </div>
        <app-form-field>
          <app-info class="formGroupLabel">
            <label>Audited with IAPH</label>
            <span class="tooltipContent">Have you in the past been audited by a port authority or competent authority that used the IAPH audit tool?</span>
          </app-info>
          <app-yes-no [(ngModel)]="data.audited" required></app-yes-no>
        </app-form-field>
        <div *ngIf="data.audited" class="px-4">
          <app-form-field label="Describe audit.">
            <textarea [(ngModel)]="data.auditDescription" class="form-control"></textarea>
          </app-form-field>
        </div>
        <app-form-field>
          <app-info class="formGroupLabel">
            <label>Applying for license</label>
            <span class="tooltipContent">Are you presently in the process of applying for a License to operate in another port?</span>
          </app-info>
          <app-yes-no [(ngModel)]="data.applyingForLicense" required></app-yes-no>
        </app-form-field>
        <div class="card" *ngIf="data.applyingForLicense">
          <div class="card-body px-4">
            <app-form-field>
              <app-info class="formGroupLabel">
                <label>Ports where applying</label>
                <span class="tooltipContent">Describe the other ports you are applying for permission to operate.</span>
              </app-info>
              <textarea [(ngModel)]="data.applyingForLicenseDescription" class="form-control"></textarea>
            </app-form-field>
            <app-form-field label="May we contact the above-described port(s)?">
              <app-yes-no [(ngModel)]="data.mayContactPortsApplyingLicense"></app-yes-no>
            </app-form-field>
            <app-form-field label="Would you agree upon a joined audit by ports?">
              <app-yes-no [(ngModel)]="data.agreeToJoinedAudit"></app-yes-no>
            </app-form-field>
          </div>
        </div>
        <app-form-field>
          <app-info class="formGroupLabel">
            <label>Share incident information</label>
            <span class="tooltipContent">Are you willing to share all incident and near miss information from the past four years?</span>
          </app-info>
          <app-yes-no [(ngModel)]="data.shareIncidentInformation" required></app-yes-no>
        </app-form-field>
        <app-form-field>
          <app-info class="formGroupLabel">
            <label>Share previous results</label>
            <span class="tooltipContent">Are you willing to share previous audit and inspection result information from other parties? E.g. Class and Port State inspections?</span>
          </app-info>
          <app-yes-no [(ngModel)]="data.sharePreviousResults" required></app-yes-no>
        </app-form-field>
        <app-form-field>
          <app-info class="formGroupLabel">
            <label>Consent peer review</label>
            <span class="tooltipContent">Would you be in consent of discrete peer review of the audit by a third party, port authority or competent authority for quality assurance purposes?</span>
          </app-info>
          <app-yes-no [(ngModel)]="data.consentPeerReview" required></app-yes-no>
        </app-form-field>
        <app-form-field>
          <app-info class="formGroupLabel">
            <label>Consent joint audit</label>
            <span class="tooltipContent">Would you consent to another port authority or competent authority joining the audit, either as an auditor or as an observer during audit, for audit or training purposes?</span>
          </app-info>
          <app-yes-no [(ngModel)]="data.consentOtherPortJoinAudit" required></app-yes-no>
        </app-form-field>
        <app-form-field>
          <app-info class="formGroupLabel">
            <label>Consent sharing with other authorities</label>
            <span class="tooltipContent">Would you, after the audit, be consent to us sharing audit, re-audit and reality-checks
              information with other port authorities or competent authorities that we cooperate with in performing
              audits?<br/>
              Please note that permission will be requested on a case-by-case basis.</span>
          </app-info>
          <app-yes-no [(ngModel)]="data.consentShareAuditInformation" required></app-yes-no>
        </app-form-field>
        <app-form-field>
          <app-info class="formGroupLabel">
            <label>Reason for request for exemption</label>
            <span class="tooltipContent">Clearly substantiate why the regulations and restrictions stated in the Port By-Law cannot be met.</span>
          </app-info>
          <textarea type="text" [(ngModel)]="data.reason" class="form-control" required></textarea>
        </app-form-field>

      </div>

    </div>
  </div>
</div>

<div class="modal-footer">
  <div class="footer-container" style="display: inline;">
    <div class="float-end">
      <button type="button" class="btn btn-secondary me-3" style="min-width: 6rem" (click)="cancel()">Cancel
      </button>
      <button type="button" class="btn btn-primary" style="min-width: 6rem" (click)="send()">Send
      </button>
    </div>
  </div>
</div>
