<div class="position-fixed sticky-top w-100" style="left: 0; right: 0; z-index: 15000;">
  <app-alerting></app-alerting>
</div>
<div class="mx-auto mb-5 mt-4">
  <div class="row head">
    <div class="col-4">
      <app-filter [(ngModel)]="searchTerm"
                  [minCharacters]="3" [searchFunction]="searchFunction" (resultsFound)="renderBunkerings($event)"
                  placeholder="Search by vessel, berth or product"></app-filter>
    </div>
    <div class="col-6"></div>
    <div class="col-2">
      <button type="button" class="btn btn-primary" (click)="addBunkering();">
        <i class="button-icon fa-solid fa-light fa-plus"></i> <span
        class="ps-2 d-none d-md-inline">Add Bunkering</span>
      </button>
    </div>
  </div>

  <hr style="margin: 0.1em"/>
  <div class="row">
    <div class="col-2">Vessel</div>
    <div class="col-2">Berth</div>
    <div class="col-2">Product(s)</div>
    <div class="col-2">Start</div>
    <div class="col-2">Completion</div>
    <div class="col-1">Status</div>
    <div class="col-1"></div>
  </div>

  <ng-container>
    <app-pagination #pagination [items]="bunkerings" class="accordion" id="bunkeringAccordeon">
      <ng-container *ngFor="let bunkering of pagination.getItemsOnPage()">
        <hr style="margin: 0.4em"/>
        <div class="row regular-14-16 py-2">
          <div class="col-2" [ngClass]="{'cancelled': bunkering.cancelled}">
            <a class="text-decoration-none cursor-pointer text-center my-auto"
               (click)="editBunkering(bunkering)">{{ bunkering.vesselName }}</a>
          </div>
          <div class="col-2" [ngClass]="{'cancelled': bunkering.cancelled}">{{ bunkering.berthName }}</div>
          <ul class="col-2" [ngClass]="{'cancelled': bunkering.cancelled}" style="margin: 0">
            <li style="list-style-type: none;" *ngFor="let item of bunkering.items">
              {{ item.productType }} ({{ item.quantity }} {{ item.unitOfMeasure }})
            </li>
          </ul>
          <div class="col-2" [ngClass]="{'cancelled': bunkering.cancelled}">
            <span *ngIf="bunkering.ets">{{ formatDate(bunkering.ets) }}</span>
          </div>
          <div class="col-2" [ngClass]="{'cancelled': bunkering.cancelled}">
                  <span
                    *ngIf="bunkering.etc">{{ !bunkering.atc ? 'ETC:' : 'ATC:' }} {{ formatDate(bunkering.atc || bunkering.etc) }}</span>
          </div>
          <div class="col-1">
            <div class="text-nowrap">
              <ng-container *ngIf="'DECLARED' == bunkering.harbourMasterDeclarationStatus?.status">
                <i class="fa-light fa-circle-question declared"></i> port authority
              </ng-container>
              <ng-container *ngIf="'ACCEPTED' == bunkering.harbourMasterDeclarationStatus?.status">
                <i class="fa-light fa-circle-check accepted"></i> port authority
              </ng-container>
              <ng-container *ngIf="'REJECTED' == bunkering.harbourMasterDeclarationStatus?.status">
                <app-tooltip [placement]="'bottom'">
                  <i class="fa-light fa-circle-xmark rejected"></i> port authority
                  <div class="tooltipContent">
                    <div class="text-start">
                <span *ngIf="bunkering.harbourMasterDeclarationStatus?.rejectionReason">
                  {{ bunkering.harbourMasterDeclarationStatus.rejectionReason }}
                </span>
                      <span *ngIf="!bunkering.harbourMasterDeclarationStatus?.rejectionReason">
                  No rejection reason given
                </span>
                    </div>
                  </div>
                </app-tooltip>
              </ng-container>
            </div>
            <div *ngIf="bunkering.sendProToCustoms">
              <ng-container *ngIf="'DECLARED' == bunkering.customsDeclarationStatus?.status">
                <i class="fa-light fa-circle-question declared"></i> customs
              </ng-container>
              <ng-container *ngIf="'ACCEPTED' == bunkering.customsDeclarationStatus?.status">
                <i class="fa-light fa-circle-check accepted"></i> customs
              </ng-container>
              <ng-container *ngIf="'REJECTED' == bunkering.customsDeclarationStatus?.status">
                <app-tooltip [placement]="'bottom'">
                  <i class="fa-light fa-circle-xmark rejected"></i> customs
                  <div class="tooltipContent">
                    <div class="text-start">
                <span *ngIf="bunkering?.customsDeclarationStatus?.rejectionReason">
                  {{ bunkering.customsDeclarationStatus.rejectionReason }}
                </span>
                      <span *ngIf="!bunkering?.customsDeclarationStatus?.rejectionReason">
                  No rejection reason given
                </span>
                    </div>
                  </div>
                </app-tooltip>
              </ng-container>
            </div>
          </div>
          <div class="col-auto">
            <a class="btn rounded-circle d-flex align-items-center justify-content-center text-primary h1 mb-0"
               type="button" (click)="editBunkering(bunkering)"
               style="width: 40px; height: 40px;margin-top: -10px;">
              <i class="fa-light fa-angle-right"></i>
            </a>
          </div>
        </div>
      </ng-container>
    </app-pagination>
  </ng-container>
  <hr style="margin: 0.1em"/>
</div>
