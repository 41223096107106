import {Component, ElementRef, Input, OnInit} from '@angular/core';
import {TransitDeclaration, TransitStatus, TransitTemplate} from "@portbase/bezoekschip-service-typescriptmodels";
import {PortvisitUtils} from "../../../refdata/portvisit-utils";
import {initializeTransitData} from "../../transit.utils";

@Component({
  selector: 'app-transit-template',
  templateUrl: './transit-template.component.html',
  styleUrls: ['./transit-template.component.css']
})

export class TransitTemplateComponent implements OnInit {
  utils = PortvisitUtils;
  @Input() model: TransitTemplate;
  declarationTemplate: TransitDeclaration;

  constructor(public elementRef: ElementRef) {
  }

  ngOnInit(): void {
    this.model.templateData = initializeTransitData(this.model.templateData);
    this.declarationTemplate = <TransitDeclaration>{
      data: this.model.templateData,
      status: <TransitStatus>{
        phase: 'undeclared'
      },
      dataFromCustoms: this.model.templateData,
      declarantShortName: this.model.ownerShortName
    };
  }
}
