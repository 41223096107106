import {Component, Input} from '@angular/core';
import {AppContext} from "../../../../app-context";
import {
  IE3ConsignmentProcessSummary,
  IE3ConsignmentStatus,
  IE3DischargeStatus,
  IE3EnsStatus,
  IE3RefreshConsignmentProcessSummary,
  TaskMessageStatus
} from "@portbase/bezoekschip-service-typescriptmodels";
import lodash from "lodash";
import {CheckboxSelectionState, nonEmpty, nonNull, openEditModal, sendCommand} from "../../../../common/utils";
import {
  MasterConsignmentDetailsComponent,
  MasterConsignmentDetailsComponentData
} from "../../details/master-consignment-details/master-consignment-details.component";
import {ConsignmentUtils} from "../../consignment.utils";
import {
  DeclarationMessageStatus,
  VisitOverviewUtils
} from "../../../visit-overview/visit-overview-item/visit-overview.utils";
import {SentenceCasePipe} from "../../../../common/sentence-case.pipe";
import {PortvisitUtils} from "../../../../refdata/portvisit-utils";
import {MiddleEllipsisPipe} from "../../../../common/middle-ellipsis.pipe";
import {ConsignmentRules} from "../../consignment.rules";

@Component({
  selector: 'app-master-consignment-overview-item',
  templateUrl: './master-consignment-overview-item.component.html',
  styleUrls: ['./master-consignment-overview-item.component.scss']
})
export class MasterConsignmentOverviewItemComponent {
  utils = ConsignmentUtils;
  appContext = AppContext;

  @Input() term: string;
  @Input() depth: number;
  @Input() selectable: boolean;
  collapsible: boolean;

  selectionWidth: number = 40;
  paddingPerStep: number = 20;
  collapsed: boolean = true;

  protected _consignment: IE3ConsignmentProcessSummary;
  protected _ensStatus: DeclarationMessageStatus;
  protected _notificationOfArrivalStatus: DeclarationMessageStatus;
  protected _temporaryStorageStatus: DeclarationMessageStatus;
  protected _temporaryStorageSummaryStatus: TaskMessageStatus;
  protected _notificationOfArrivalStatuses: DeclarationMessageStatus[];
  protected _ensStatuses: DeclarationMessageStatus[] = [];
  protected _temporaryStorageStatuses: DeclarationMessageStatus[];

  formatDate = (dateString: string): string => ConsignmentUtils.formatDate(dateString);

  @Input()
  set consignment(consignment: IE3ConsignmentProcessSummary) {
    this._consignment = consignment;
    this._ensStatus = this.getEnsStatus();
    this._notificationOfArrivalStatus = this.getNotificationOfArrivalStatus();
    this._notificationOfArrivalStatuses = [this._notificationOfArrivalStatus];
    this._ensStatuses = [this._ensStatus];
    this._temporaryStorageStatus = this.getTemporaryStorageStatus()
    this._temporaryStorageStatuses = [this._temporaryStorageStatus, this.getDischargeStatus(), this.getInspectionStatus(), this.getClearanceStatus()];
    this._temporaryStorageSummaryStatus = VisitOverviewUtils.getLeastTaskMessageStatus(this._temporaryStorageStatuses);
    this.collapsible = !!consignment.masterConsignment.equipments?.length || !!consignment.masterConsignment.goodsItems?.length
      || !!lodash.flatMap(consignment.masterConsignment.houseConsignments, h => h.goodsItems).length;
  }

  get indentionDepth() {
    return this.depth * this.paddingPerStep;
  }

  get terminalValue(): string {
    const time = this._consignment.ataPort || this._consignment.etaPort;
    const terminalName = SentenceCasePipe.format(this._consignment.masterConsignment.dischargeTerminal?.terminalName);
    return [time ? this.formatDate(time) : null, terminalName].filter(a => a).join(",");
  }

  get isVessel(): boolean {
    return !this._consignment.masterConsignment.vehicle || this._consignment.masterConsignment.vehicle.type === 'N10';
  }

  get vehicleValue(): string {
    return SentenceCasePipe.format(this._consignment.masterConsignment.vehicle?.name)
      || this._consignment.masterConsignment.vehicle?.identificationNumber || '';
  }

  get portOfLoading() {
    return this._consignment.masterConsignment.placeOfLoading
      ? `${this._consignment.masterConsignment.placeOfLoading.name} (${this._consignment.masterConsignment.placeOfLoading.countryUnCode})`
      : "";
  }

  get equipmentCount() {
    return lodash.sum([this._consignment.masterConsignment.equipments.length]);
  }

  get totalWeight() {
    return this._consignment.straight
      ? lodash.round(lodash.sum(lodash.flatMap(this._consignment.masterConsignment.houseConsignments,
        h => h.goodsItems.map(g => g.grossWeight))), 1)
      : lodash.round(lodash.sum(this._consignment.masterConsignment.goodsItems.map(g => g.grossWeight)), 1);
  }

  get selectionState(): CheckboxSelectionState {
    const subItems = this.getSubItems();
    if ([CheckboxSelectionState.unselected, CheckboxSelectionState.selected].includes(this._consignment["selected"])) {
      subItems.forEach(s => s["selected"] = this._consignment["selected"]);
    }
    if (subItems.length > 0 && subItems.every(s => s["selected"] === CheckboxSelectionState.selected)) {
      return CheckboxSelectionState.selected;
    }
    if (subItems.length > 0 && subItems.some(s => [CheckboxSelectionState.selected, CheckboxSelectionState.indeterminate].includes(s["selected"]))) {
      return CheckboxSelectionState.indeterminate;
    }
    return CheckboxSelectionState.unselected;
  }

  set selectionState(value: CheckboxSelectionState) {
    this._consignment["selected"] = value;
    if (value === CheckboxSelectionState.selected || value === CheckboxSelectionState.unselected) {
      this.getSubItems().forEach(s => s["selected"] = value);
    }
  }

  editConsignment = () => openEditModal(MasterConsignmentDetailsComponent, <MasterConsignmentDetailsComponentData>{
    consignmentProcessId: this._consignment.consignmentProcessId,
  }, {
    warnOnClose: true,
    currentToStack: true
  });

  refreshSummary = () => {
    sendCommand('com.portbase.bezoekschip.common.api.consignments.commands.RefreshConsignmentProcessSummary', <IE3RefreshConsignmentProcessSummary>{
      consignmentProcessId: this._consignment.consignmentProcessId
    }, (summary: IE3ConsignmentProcessSummary) => {
      this.consignment = summary;
      AppContext.registerSuccess('Consignment summary has been rebuild successfully.');
    });
  }

  private getSubItems = () => this._consignment.masterConsignment.equipments;

  validSelector = (input: string): string => "SELECTOR_" + input.replace(/[^a-zA-Z0-9]/g, '');

  getEnsStatus = (): DeclarationMessageStatus => {
    const status = this._consignment.status;
    return {
      name: "Entry Summary Declaration",
      taskStatus: ConsignmentRules.isTemporaryStorageOnlyConsignment(this._consignment.filingType) ? TaskMessageStatus.DISABLED : this._consignment.ensTaskStatus,
      messages: this.getMessagesEns(this._consignment.ensTaskStatus, status),
      customLabel: this._consignment.ensStatus != null ?
        PortvisitUtils.enumFormatter(this._consignment.ensStatus).replace("Ens", "ENS") : null,
      sentenceCase: false,
      asInnerHtml: [IE3EnsStatus.CONTROL_NOTIFICATION, IE3EnsStatus.ADVANCE_CONTROL_NOTIFICATION].includes(this._consignment.ensStatus)
    }
  }

  getTemporaryStorageStatus = (): DeclarationMessageStatus => {
    return {
      name: "Temporary storage",
      taskStatus: this._consignment.temporaryStorageTaskStatus,
      messages: ConsignmentUtils.getTemporaryStorageStatusMessage(
        this._consignment.status?.filingStatusTemporaryStorage, this._consignment.temporaryStorageStatus)
        .map(e => e.summary),
      customLabel: this._consignment.temporaryStorageStatus != null
        ? PortvisitUtils.enumFormatter(this._consignment.temporaryStorageStatus) : null
    }
  }

  private getInspectionStatus(): DeclarationMessageStatus {
    return ConsignmentUtils.getInspectionsStatus([lodash.last(this._consignment.masterConsignment.bulkInspections)]
      .concat(this._consignment.masterConsignment?.equipments?.map(e => lodash.last(e.inspections)))
      .filter(nonNull));
  }

  private getClearanceStatus(): DeclarationMessageStatus {
    const clearance = this._consignment.status?.clearanceStatus;
    const taskStatus = clearance?.writtenOff ? TaskMessageStatus.ACCEPTED : clearance?.expirySummary ? TaskMessageStatus.WARNING : TaskMessageStatus.DISABLED;
    const goodsItems = ConsignmentUtils.getGoodsOfConsignment(this._consignment)
      .map(g => g.goodsItem);
    return {
      name: "Clearance",
      taskStatus: taskStatus,
      messages: taskStatus === TaskMessageStatus.WARNING ? clearance.expirySummary.items.map(i => {
        const remainings = [i.remainingWeight ? `<div class="row" style="width: 80%"><span class="col">Remaining weight: </span><span class="ms-auto col-auto">${i.remainingWeight}</span></div>` : ""]
          .concat([i.remainingPackages ? `<div class="row"><span class="col">Remaining packages: </span><span class="ms-auto col-auto">${i.remainingPackages}</span></div>` : ""]).filter(nonEmpty);
        const goodsItem = goodsItems.find(g => g.goodsItemNumber === i.itemNumber);
        return `<div class="d-block">${MiddleEllipsisPipe.format(ConsignmentUtils.goodsItemsAsText(goodsItem), 20)}: <div class="d-block">${remainings.join("")}</div></div>`;
      }) : [],
      customLabel: clearance?.writtenOff ? "Written off" : clearance?.expirySummary ? "Differences in:" : "",
      asInnerHtml: true,
      hideComment: true
    };
  }

  private getDischargeStatus(): DeclarationMessageStatus {
    const numberOfEquipmentMatchingStatus = (status: IE3DischargeStatus) => this._consignment.masterConsignment.equipments
      .filter(e => e.dischargeStatus === status).length;
    const amountShortLanded = numberOfEquipmentMatchingStatus(IE3DischargeStatus.SHORTLANDED);
    const amountDischarged = numberOfEquipmentMatchingStatus(IE3DischargeStatus.DISCHARGED);
    return {
      name: "Discharges",
      taskStatus: amountShortLanded ? TaskMessageStatus.ERROR : amountDischarged ? TaskMessageStatus.ACCEPTED : TaskMessageStatus.DISABLED,
      messages: [],
      customLabel: amountShortLanded ? "Shortlanded equipment" : amountDischarged ? "Discharged" : ""
    }
  }

  getNotificationOfArrivalStatus = (): DeclarationMessageStatus => {
    if (this._consignment.fromVisit) {
      return null;
    }
    const taskStatus = this._consignment.status?.notificationOfArrivalStatus || TaskMessageStatus.UNKNOWN;
    return {
      name: "Notification of Arrival",
      taskStatus: this._consignment.ensTaskStatus === TaskMessageStatus.DISABLED || ConsignmentRules.isTemporaryStorageOnlyConsignment(this._consignment.filingType) ? TaskMessageStatus.DISABLED : taskStatus,
      messages: [],
      customLabel: taskStatus === TaskMessageStatus.ACCEPTED ? "Accepted" : PortvisitUtils.enumFormatter(taskStatus)
    }
  }

  private getMessagesEns = (taskStatus: TaskMessageStatus, status: IE3ConsignmentStatus): string[] =>
    ConsignmentUtils.getMessagesEns(null, taskStatus, status).map(e => e.summary);


  duplicate = () =>  {
    openEditModal(MasterConsignmentDetailsComponent, <MasterConsignmentDetailsComponentData>{copyOfConsignmentProcessId: this._consignment.consignmentProcessId,
    })};
}
