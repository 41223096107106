import {AfterViewInit, Component, EventEmitter, Input, Output, TemplateRef, ViewChild} from '@angular/core';
import {IE3ConsignmentProcess, IE3DischargeStatus, IE3GoodsItem} from "@portbase/bezoekschip-service-typescriptmodels";
import {TableField} from "../../../../../common/table/table-view/table-view.component";
import {openEditModal} from "../../../../../common/utils";
import {
  EquipmentConsignmentDetailsComponent,
  EquipmentConsignmentDetailsComponentData
} from "../../equipment-consignment-details/equipment-consignment-details.component";
import {ConsignmentUtils, EquipmentWithPlacement} from "../../../consignment.utils";
import {
  EquipmentWithHouseConsignments
} from "../consignment-equipments-link-table/consignment-equipments-link-table.component";
import {DeclarationMessageStatus} from "../../../../visit-overview/visit-overview-item/visit-overview.utils";
import {ConsignmentRules} from "../../../consignment.rules";

@Component({
  selector: 'app-consignment-equipments-table',
  templateUrl: './consignment-equipments-table.component.html',
  styleUrls: ['./consignment-equipments-table.component.scss']
})
export class ConsignmentEquipmentsTableComponent implements AfterViewInit {
  utils = ConsignmentUtils;

  @Input() consignmentProcess: IE3ConsignmentProcess;
  @Input() houseConsignmentNumber: string;
  @Input() goodsItem: IE3GoodsItem;
  @Input() term: string;
  @Input() editable: boolean = true;
  @Input() editMode: boolean = false;
  @Input() showPlacement: boolean = false;
  fieldsDefinition: TableField[] = [];
  _data: EquipmentWithPlacement[];
  equipmentLinkData: EquipmentWithHouseConsignments[];

  @Output() goodsItemChange: EventEmitter<IE3GoodsItem> = new EventEmitter<IE3GoodsItem>();

  @ViewChild("equipmentRef") equipmentRef: TemplateRef<any>;
  @ViewChild("goodsRef") goodsRef: TemplateRef<any>;
  @ViewChild("actionColumnRef") actionColumnRef: TemplateRef<any>;
  @ViewChild("shortLandedColumnRef") shortLandedColumnRef: TemplateRef<any>;
  @ViewChild("equipmentLinkModalContent") equipmentLinkModalContent: TemplateRef<any>;
  @ViewChild("houseConsignmentRef") houseConsignmentRef: TemplateRef<any>;

  ngAfterViewInit(): void {
    this.fieldsDefinition = [
      {
        type: "string",
        name: "equipment",
        header: "Equipment",
        template: this.equipmentRef
      }, {
        type: "string",
        name: "goodsItems",
        hidden: this.showPlacement,
        header: "Goods",
        template: this.goodsRef
      }, {
        type: "string",
        name: "houseConsignments",
        header: "House B/Ls",
        hidden: !!this.houseConsignmentNumber,
        template: this.houseConsignmentRef
      }
    ];
    if (this.showPlacement) {
      this.fieldsDefinition = this.fieldsDefinition.concat([
        {
          type: "number",
          step: "any",
          name: "placement.grossWeight",
          header: "Weight (kg)",
          editable: true,
          required: true
        }, {
          type: "number",
          step: "1",
          name: "placement.numberOfPackages",
          header: "Packages",
          editable: true,
          required: true
        }
      ])
    }
    this.fieldsDefinition = this.fieldsDefinition.concat([{
      type: "action",
      name: "shortLandedColumn",
      template: this.shortLandedColumnRef,
      cellClass: "p-1 auto-width"
    }, {
      type: "action",
      name: "actionColumn",
      template: this.actionColumnRef,
      cellClass: "p-1 auto-width"
    }]);
    this.updateLinkTableData();
  }

  @Input()
  set data(data: EquipmentWithPlacement[]) {
    this._data = data;
    this.updateLinkTableData();
  }

  trackByContainerNumber = (index: number, record: EquipmentWithPlacement) => record.equipment.containerIdentificationNumber;

  edit = (record: EquipmentWithPlacement) =>
    openEditModal(EquipmentConsignmentDetailsComponent, <EquipmentConsignmentDetailsComponentData>{
      consignmentProcessId: this.consignmentProcess.consignmentProcessId,
      houseConsignmentNumber: this.houseConsignmentNumber,
      equipmentNumber: record?.equipment.containerIdentificationNumber,
      cachedConsignmentProcess: this.consignmentProcess,
      goodsItem: record ? null : this.goodsItem
    }, {
      warnOnClose: true,
      currentToStack: true
    });

  editEquipment = (equipmentNumber: string) => openEditModal(EquipmentConsignmentDetailsComponent, <EquipmentConsignmentDetailsComponentData>{
    consignmentProcessId: this.consignmentProcess.consignmentProcessId,
    equipmentNumber: equipmentNumber,
    cachedConsignmentProcess: this.consignmentProcess,
    houseConsignmentNumber: this.houseConsignmentNumber
  }, {
    warnOnClose: true,
    currentToStack: true
  })

  openLinkModal = () => ConsignmentUtils.openSubModal({
    modalContent: this.equipmentLinkModalContent
  });

  closeLinkModal = () => ConsignmentUtils.closeSubModal();

  updateLink = () => {
    if (this.goodsItem) {
      const goodsPlacements = this.equipmentLinkData.filter(h => h["selected"]);
      const alreadyLinkedEquipment = this.goodsItem.goodsPlacements.filter(g => goodsPlacements
        .map(gp => gp.equipment.containerIdentificationNumber).includes(g.containerIdentificationNumber));
      const newlyLinkedEquipment = goodsPlacements
        .filter(g => !this.goodsItem.goodsPlacements.map(gp =>
          gp.containerIdentificationNumber).includes(g.equipment.containerIdentificationNumber))
        .map(g => ({
          containerIdentificationNumber: g.equipment.containerIdentificationNumber
        }));
      this.goodsItem.goodsPlacements = alreadyLinkedEquipment.concat(newlyLinkedEquipment);
      this.goodsItemChange.emit(this.goodsItem);
    }
    this.closeLinkModal();
  }

  private updateLinkTableData = () => {
    if (this.consignmentProcess) {
      this.equipmentLinkData = Object.entries(this.consignmentProcess.consignmentMasterLevel.transportEquipmentMap)
        .map(([containerIdentificationNumber, equipment]) => (<EquipmentWithHouseConsignments>{
          equipment: equipment,
          houseConsignments: this.consignmentProcess.consignmentMasterLevel.consignmentsHouseLevel
            .filter(h => ConsignmentUtils.getEquipmentNumbersOfHouseConsignment(
              this.consignmentProcess, h).includes(containerIdentificationNumber))
            .map(h => h.consignmentNumber),
          goods: ConsignmentUtils.getGoodsOfEquipment(this.consignmentProcess, containerIdentificationNumber)
        }));
    }
  }

  get hasDepartured() {
    return !!this.consignmentProcess.departureTime;
  }

  getDischargeStatus = (equipment: EquipmentWithPlacement): DeclarationMessageStatus => {
    const isShortlanded = ConsignmentRules.isShortLanded(this.consignmentProcess, equipment.equipment.containerIdentificationNumber);
    const isDischarged = ConsignmentRules.isDischarged(this.consignmentProcess, equipment.equipment.containerIdentificationNumber);
    return ConsignmentUtils.getDischargedStatus(isShortlanded
      ? IE3DischargeStatus.SHORTLANDED : isDischarged
        ? IE3DischargeStatus.DISCHARGED : IE3DischargeStatus.EXPECTED);
  }
}
