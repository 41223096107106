import {Component, ElementRef, OnInit} from '@angular/core';
import {VisitContext} from '../visit-context';
import {
  CancelWaste,
  DeclarationType,
  DeclareWaste,
  Port,
  SaveWaste,
  WasteDeclaration,
  WasteItem
} from '@portbase/bezoekschip-service-typescriptmodels';
import {
  $try,
  checkValidity,
  clearValidation,
  openConfirmationModalWithCallback,
  sendCommand,
  sendQuery,
  uuid,
  wrapCommand
} from '../../common/utils';
import {wasteCategories, WasteCategory, WasteDisplayItem} from './waste.categories';
import {uploadWaste} from './waste.upload';
import {AppContext} from '../../app-context';
import {toBase64} from "../../common/upload/upload.utils";
import {ModalConfirmAutofocus, ModalConfirmAutofocusData} from 'src/app/common/modal/modal-confirm.component';

@Component({
  selector: 'app-waste',
  templateUrl: './waste.component.html',
  styleUrls: ['./waste.component.css']
})
export class WasteComponent implements OnInit {
  VISIT = DeclarationType.VISIT;
  WASTE = DeclarationType.WASTE;

  stringify = (obj) => JSON.stringify(obj);

  context = VisitContext;
  appContext = AppContext;
  categories: WasteCategory[] = wasteCategories;
  portInputFormatter = (port: Port) => port ? port.name + ' – ' + port.locationUnCode : '';
  searchPort = term => sendQuery('com.portbase.bezoekschip.common.api.visit.FindPorts', {term: term});
  searchPortOrWayPoint = term => sendQuery('com.portbase.bezoekschip.common.api.visit.FindPortsOrWayPoints', {term: term});
  getCollectorProvider = (annex: number) => sendQuery('com.portbase.bezoekschip.common.api.waste.GetWasteCollectors',
    {portUnCode: this.context.visit.portOfCall.port.locationUnCode, annex: annex});
  getBerthIds = () => this.context.savedVisit.visitDeclaration.portVisit.berthVisits.filter(v => !!v.berth).map(v => v.id);
  formatBerth = (id) => {
    const berthVisit = this.context.savedVisit.visitDeclaration.portVisit.berthVisits.filter(v => v.id === id)[0];
    return berthVisit && berthVisit.berth && berthVisit.berth.name;
  };
  hasLastDelivery: boolean;
  showSendWarning: boolean;
  uploadedXls: String;


  constructor(private element: ElementRef) {
  }

  ngOnInit(): void {
    if (!this.context.visit.wasteDeclaration) {
      this.context.visit.wasteDeclaration = createDeclaration();
    }
    this.initializeDeclaration();
  }

  upload = (file: File) => {
    clearValidation(this.element);
    AppContext.clearAlerts();
    toBase64(file).subscribe(value => this.uploadedXls = value);
    uploadWaste(file).subscribe(declaration => {
      this.context.visit.wasteDeclaration = declaration;
      this.initializeDeclaration();
    });
  };

  save = () => {
    this.clearWarningsAndErrors();
    clearValidation(this.element);

    const command = <SaveWaste>{
      crn: this.context.visit.crn,
      wasteDeclaration: this.prunedWasteDeclaration(this.context.visit.wasteDeclaration)
    };

    sendCommand('com.portbase.bezoekschip.common.api.waste.SaveWaste',
      this.uploadedXls ? wrapCommand(command, this.uploadedXls) : command, () => {
        this.context.replaceVisit(this.context.visit);
        AppContext.registerSuccess('The declaration was saved successfully.');
      });
  };

  trySend = () => {
    this.clearWarningsAndErrors();
    if (checkValidity(this.element)) {
      if (!this.checkForErrors() && !this.checkForWarnings()) {
        this.doSend();
      }
    }
  };

  itemInfoNotEmpty(item: WasteDisplayItem) :  boolean {
    if (item.info) {
      return true;
    }
    return false;
  }

  doSend = () => {
    this.clearWarningsAndErrors();
    if (checkValidity(this.element)) {
      const command = <DeclareWaste>{
        crn: this.context.visit.crn,
        wasteDeclaration: this.addDeclarationId(this.prunedWasteDeclaration(this.context.visit.wasteDeclaration))
      };

      sendCommand('com.portbase.bezoekschip.common.api.waste.DeclareWaste',
        this.uploadedXls ? wrapCommand(command, this.uploadedXls) : command, () => {
          this.context.replaceVisit(this.context.visit);
          AppContext.registerSuccess('The declaration was sent successfully.');
        });
    }
  };

  // Conversion of old items for which specification was required but is not anymore.
  prunedWasteDeclaration(wasteDeclaration: WasteDeclaration): WasteDeclaration {
    wasteDeclaration.wasteItems = wasteDeclaration.wasteItems.map(i => {
      if (!i.ssn.specificationRequired) {
        i.specification = null;
      }
      return i;
    })
    return wasteDeclaration;
  }

  addDeclarationId(wasteDeclaration: WasteDeclaration): WasteDeclaration {
    wasteDeclaration.declarationId = uuid();
    return wasteDeclaration;
  }

  doCancelWaste = () => {
    clearValidation(this.element);
    sendCommand('com.portbase.bezoekschip.common.api.waste.CancelWaste', <CancelWaste>{
      crn: this.context.visit.crn
    }, () => {
      this.context.visit.wasteDeclaration = createDeclaration();
      this.initializeDeclaration();
      this.context.replaceVisit(this.context.visit);
      AppContext.registerSuccess('The declaration was cancelled successfully.');
    });
  };

  cancelWaste = () => {
    openConfirmationModalWithCallback((confirmed) => {
      if (confirmed) {
        this.doCancelWaste();
      }
    }, ModalConfirmAutofocus, <ModalConfirmAutofocusData>{
      type: "danger",
      title: "Danger",
      question: "Are you sure that you want to cancel this declaration?",
      confirmText: "Yes",
      cancelText: "No"
    }, 'static');
  };

  afterItemUpdate = (displayItem: WasteDisplayItem, category: WasteCategory) => {
    const item = displayItem.wasteItem;
    if (item.quantityToBeDelivered > 0) {
      if (!item.collector) {
        item.collector = category.items.map(i => VisitContext.visit.wasteDeclaration.wasteItems.find(wc => i.ssn.code === wc.ssn.code).collector).find(c => !!c);
      }
      if (!item.berthIdForPickup) {
        const firstBerthVisit = VisitContext.visit.visitDeclaration.portVisit.berthVisits[0];
        item.berthIdForPickup = firstBerthVisit && firstBerthVisit.id;
      }
    } else {
      item.collector = null;
      item.berthIdForPickup = null;
    }
    if (item.collector) {
      category.items.map(i => VisitContext.visit.wasteDeclaration.wasteItems.find(wc => i.ssn.code === wc.ssn.code))
        .filter(i => !i.collector && i.quantityToBeDelivered > 0).forEach(i => i.collector = item.collector);
    }
    if (item.quantityToBeRetained > 0) {
      if (!item.portForRetainedWaste) {
        const firstNextPort = getFirstNextPort();
        item.portForRetainedWaste = firstNextPort && firstNextPort.port;
      }
    } else {
      item.portForRetainedWaste = null;
      item.quantityToBeGenerated = 0;
      if (item.quantityToBeDelivered == 0) {
        item.maxDedicatedCapacity = 0;
      }
    }
    this.showSendWarning = false;
  };

  afterLastDeliveryUpdate = () => {
    const previousPort = VisitContext.visit.visitDeclaration.previousPorts[0];
    if (!VisitContext.visit.wasteDeclaration.lastDeliveryDate) {
      VisitContext.visit.wasteDeclaration.lastDeliveryDate = previousPort && previousPort.departure;
    }
    if (!VisitContext.visit.wasteDeclaration.portOfLastDelivery) {
      VisitContext.visit.wasteDeclaration.portOfLastDelivery = previousPort && previousPort.port;
    }
    const nextPort = getFirstNextPort();
    if (!VisitContext.visit.wasteDeclaration.portOfNextDelivery) {
      VisitContext.visit.wasteDeclaration.portOfNextDelivery = nextPort && nextPort.port;
    }
  };
  cancelVisit
  private initializeDeclaration = () => {
    this.clearWarningsAndErrors();
    for (let category of wasteCategories) {
      for (let item of category.items) {
        this.afterItemUpdate(item, category);
      }
    }
    this.afterLastDeliveryUpdate();
  };

  private clearWarningsAndErrors = () => {
    this.showSendWarning = false;
    wasteCategories.forEach(c => c.items.forEach(i => {
      i.warnings = [];
      i.errors = [];
    }));
  };

  getRejectReason() {
    return $try(() => this.context.findLatestDeclaration(DeclarationType.WASTE).rejectReasons);
  }

  private checkForErrors = (): boolean => {
    let hasErrors: boolean = false;
    for (let category of wasteCategories) {
      for (let item of category.items) {
        hasErrors = addErrors(item) || hasErrors;
      }
    }
    if (hasErrors) {
      AppContext.registerError('Please review the fields with errors.');
    }
    return hasErrors;
  };

  private checkForWarnings = (): boolean => {
    let hasWarnings: boolean = false;
    for (let category of wasteCategories) {
      for (let item of category.items) {
        hasWarnings = addWarnings(item, category) || hasWarnings;
      }
    }
    if (hasWarnings) {
      this.showSendWarning = true;
    }
    return hasWarnings;
  };

  private maxCapacityEnabled(wasteItem: WasteItem): boolean {
    return maxCapacityRequired(wasteItem)
  }
}

function createDeclaration(): WasteDeclaration {
  const result = <WasteDeclaration>{
    wasteItems: []
  };
  for (let category of wasteCategories) {
    for (let item of category.items) {
      result.wasteItems.push(<WasteItem>{
        type: item.type,
        ssn: item.ssn,
        quantityOfLastDelivery: 0,
        quantityToBeDelivered: 0,
        collector: null,
        berthIdForPickup: null,

        quantityToBeRetained: 0,
        quantityToBeGenerated: 0,
        maxDedicatedCapacity: 0,
        portForRetainedWaste: null,
        specification: null,
      });
    }
  }
  return result;
}

function getFirstNextPort() {
  return VisitContext.visit.visitDeclaration.nextPorts[0];
}

function addWarnings(displayItem: WasteDisplayItem, category: WasteCategory): boolean {
  const item = displayItem.wasteItem;
  const warnings: string[] = displayItem.warnings;

  //add insufficient capacity warnings
  if (item.quantityToBeGenerated > 0) {
    if (item.quantityToBeDelivered > item.maxDedicatedCapacity) {
      warnings.push('To be delivered > Max. storage capacity.');
    }
    if (item.quantityToBeRetained > item.maxDedicatedCapacity) {
      warnings.push('To be retained > Max. storage capacity.');
    }
    if (item.quantityToBeGenerated > item.maxDedicatedCapacity) {
      warnings.push('To be generated > Max. storage capacity.');
    }
    if (item.quantityToBeRetained > 0 && (item.quantityToBeGenerated + item.quantityToBeRetained > item.maxDedicatedCapacity)) {
      warnings.push('To be retained + To be generated > Max. storage capacity.');
    }
  }

  enum PortStatus { unknown, inEuPlus, other}

  function getMinTankCapacityPercentage(portStatus: PortStatus, annex: number): number {
    switch (portStatus) {
      case PortStatus.inEuPlus:
        switch (annex) {
          case 1: return 50;
          case 4: return 50;
          case 5: return 75;
          case 6: return 25;
        }
        break;
      case PortStatus.other:
        switch (annex) {
          case 1: return 75;
          case 4: return 50;
          case 5: return 80;
          case 6: return 75;
        }
        break;
      case PortStatus.unknown: {
        return 100;
      }
    }
    return 0;
  }

//add remaining capacity warnings
  if (item.maxDedicatedCapacity > 0 && item.quantityToBeRetained > 0) {
    const portStatus: PortStatus = isNextPortKnown() ? isNextPortInEu() ? PortStatus.inEuPlus : PortStatus.other : PortStatus.unknown
    const minTankCapacityPercentage: number = getMinTankCapacityPercentage(portStatus, category.annex)
    if (determinePercentageOfRemainingCapacity(item) < minTankCapacityPercentage) {
      switch (portStatus) {
        case PortStatus.inEuPlus:
          warnings.push('At least ' + minTankCapacityPercentage + '% tank capacity should remain for this type of waste when the next port (' + nextPortInVisit() + ') is in the EU.');
          break;
        case PortStatus.other:
          warnings.push('At least ' + minTankCapacityPercentage + '% tank capacity should remain for this type of waste when the next port (' + nextPortInVisit() + ') is not in the EU.');
          break;
        case PortStatus.unknown:
          warnings.push('At least ' + minTankCapacityPercentage + '% tank capacity should remain for this type of waste when the next port is unknown.');
          break;
      }
    }
  }

  //add large quantity warnings
  addQuantityWarning(item.quantityToBeDelivered, 'To be delivered', category, warnings);
  addQuantityWarning(item.quantityToBeGenerated, 'To be generated', category, warnings);
  addQuantityWarning(item.quantityToBeRetained, 'To be retained', category, warnings);
  addQuantityWarning(item.maxDedicatedCapacity, 'Max. storage capacity', category, warnings);

  return warnings.length > 0;

  function addQuantityWarning(value: number, name: string, category: WasteCategory, warnings: string[]) {
    switch (category.annex) {
      case 1:
        if (value > 100) {
          warnings.push(name + ' > 100 m3.');
        }
        break;
      case 4:
        if (value > 50) {
          warnings.push(name + ' > 50 m3.');
        }
        break;
      case 5:
        if (value > 25 && category.vesselType === 'NON-TANKERS') {
          warnings.push(name + ' > 25 m3.');
        }
        break;
    }
  }

  function isNextPortInEu(): boolean {
    const nextPort = getFirstNextPort();
    return nextPort && nextPort.port && (nextPort.port.euPort || isConsideredAsEuPort(nextPort.port));
  }

  function isNextPortKnown(): boolean {
    const nextPort = getFirstNextPort();
    return nextPort && nextPort.port && (nextPort.port.countryUnCode != "XZ");
  }

  function nextPortInVisit(): string {
    return getFirstNextPort().port.name;
  }

  function isConsideredAsEuPort(port: Port): boolean {
    return ["IS", "NO", "GB", "IM", "GG", "JE", "GI"].includes(port.countryUnCode) ||
      ["RUULU", "RUPRI", "RULED", "RUVYS", "RUVYG", "RUKGD"].includes(port.locationUnCode)
  }

  function determinePercentageOfRemainingCapacity(item: WasteItem) {
    return 100 * (1 - item.quantityToBeRetained / item.maxDedicatedCapacity);
  }
}

function addErrors(displayItem: WasteDisplayItem): boolean {
  const errors: string[] = displayItem.errors;
  const item = displayItem.wasteItem;
  if (item.quantityToBeDelivered > 0 && (!item.collector || !item.berthIdForPickup)) {
    errors.push('Collector and Berth of delivery are required.');
  }
  if (displayItem.ssn.specificationRequired && !item.specification
    && (item.quantityToBeDelivered || item.quantityToBeRetained)) {
    errors.push('Specification is required.');
  }

  addQuantityErrors(item.quantityToBeDelivered, 'To be delivered', errors);
  addQuantityErrors(item.quantityToBeGenerated, 'To be generated', errors);
  addQuantityErrors(item.quantityToBeRetained, 'To be retained', errors);
  addQuantityErrors(item.maxDedicatedCapacity, 'Max. storage capacity', errors);

  if (item.quantityToBeRetained > 0 && !item.portForRetainedWaste) {
    errors.push('Port for retained waste delivery is mandatory when waste is retained.');
  }
  if (item.quantityToBeRetained === 0 && item.portForRetainedWaste) {
    errors.push('Port for retained waste delivery is forbidden when no waste is retained.');
  }
  if (item.quantityToBeRetained === 0 && item.quantityToBeGenerated > 0) {
    errors.push('When no waste is retained, do not report waste to be generated.');
  }
  if (item.quantityToBeRetained === 0 && item.quantityToBeGenerated > 0) {
    errors.push('When no waste is retained, do not report storage capacity.');
  }
  if (item.maxDedicatedCapacity === 0 && maxCapacityRequired(item)) {
    errors.push('For Marpol Annex I, IV, V and VI max capacity is required when waste is to be delivered or retained.');
  }
  return errors.length > 0;

  function addQuantityErrors(quantity, name, errors) {
    if (quantity === null || quantity === undefined) {
      errors.push(name + ' is required');
      return;
    }
    if (quantity < 0) {
      errors.push(name + ' should not be negative.');
    }
    const parts = quantity.toString().split('.');
    if (parts.length > 1 && parts[1].length > 6) {
      errors.push(name + ' should have fewer than 7 decimals.');
    }
    const digits = parts[0].length + (parts.length > 1 ? parts[1].length : parts[0].length);
    if (digits > 16) {
      errors.push(name + ' should have fewer than 16 digits.');
    }
  }
}

function maxCapacityRequired(wasteItem: WasteItem): boolean {
  if (["101", "102", "999", "501", "502", "503", "504", "505", "506", "507", "508", "509", "401", "601", "602"].indexOf(wasteItem.ssn.code) > -1) {
    return wasteItem.quantityToBeDelivered > 0 || wasteItem.quantityToBeRetained > 0;
  }
  return wasteItem.quantityToBeRetained > 0;
}
