import {Component} from '@angular/core';
import {berthInputFormatter, sendQuery} from "../../../../../common/utils";
import {ExemptionBaseComponent} from "../exemption_base/exemption_base.component";
import {
  Berth,
  Exemption_3_11,
  Exemption_3_11_ReasonType,
  Exemption_3_11_ReasonTypeActivity,
  Exemption_3_11_ReasonTypeVessel,
  Vessel
} from '@portbase/bezoekschip-service-typescriptmodels';

@Component({
  selector: 'app-visit-exemptions-exemption-3-11',
  templateUrl: './exemption_3_11.component.html',
  styleUrls: ['./exemption_3_11.component.css']
})
export class Exemption_3_11Component extends ExemptionBaseComponent {

  exemptionSubType: Exemption_3_11;
  reasonTypes: Exemption_3_11_ReasonType[] = [null, Exemption_3_11_ReasonType.WIDER_OR_LONGER, Exemption_3_11_ReasonType.OTHER_VESSELS];
  reasonTypeActivities: Exemption_3_11_ReasonTypeActivity[] = [null, Exemption_3_11_ReasonTypeActivity.SHIP_TO_SHIP, Exemption_3_11_ReasonTypeActivity.OTHER];
  reasonTypeVessels: Exemption_3_11_ReasonTypeVessel[] = [null, Exemption_3_11_ReasonTypeVessel.FLOATING_CRANE, Exemption_3_11_ReasonTypeVessel.PONTOON, Exemption_3_11_ReasonTypeVessel.OTHER];

  secondVessel: Vessel;
  thirdVessel: Vessel;

  secondBerth: Berth;
  thirdBerth: Berth;

  searchVessel = term => sendQuery('com.portbase.bezoekschip.common.api.visit.FindVessels', {term: term});
  searchBerth = (term) => sendQuery('com.portbase.bezoekschip.common.api.visit.FindBerthsAndBuoys',
    {term: term, portUnCode: this.visitContext.visit.portOfCall.port.locationUnCode}
  );

  vesselFormatter = (vessel: Vessel) => {
    return vessel.name + ' – ' + vessel.imoCode;
  };

  exemptionBerthInputFormatter = berthInputFormatter;

  reasonTypesFormatter = (reasonType: Exemption_3_11_ReasonType) => {
    switch (reasonType) {
      case null:
        return 'None';
      case "WIDER_OR_LONGER":
        return 'The ship is wider or longer than the berth dimensions';
      case "OTHER_VESSELS":
        return 'Other vessels mooring alongside';
    }
  };

  reasonTypeActivitiesFormatter = (reasonTypeActivity: Exemption_3_11_ReasonTypeActivity) => {
    switch (reasonTypeActivity) {
      case null:
        return 'None';
      case "SHIP_TO_SHIP":
        return 'Ship-to-ship handling';
      case "OTHER":
        return 'Other';
    }
  };

  reasonTypeVesselsFormatter = (reasonTypeVessel: Exemption_3_11_ReasonTypeVessel) => {
    switch (reasonTypeVessel) {
      case null:
        return 'None';
      case "FLOATING_CRANE":
        return 'Floating crane';
      case "PONTOON":
        return 'Pontoon';
      case "OTHER":
        return 'Other';
    }
  };

  onVesselChange(prefix: String, vessel: Vessel) {
    this.exemption[prefix + 'VesselName'] = vessel.name;
    this.exemption[prefix + 'VesselIMO'] = vessel.imoCode;
  }

  onBerthChange(prefix: String, berth: Berth) {
    this.exemption[prefix + 'VesselBerth'] = this.exemptionBerthInputFormatter(berth);
  }
}

