<ng-container>

  <div>
    <b>{{ name }}</b>
    <app-info class="formGroupLabel" [wide]="false" [placement]="'left'">
      <div class="tooltipContent">
        <b>Considering</b>
        <ul>
          <div *ngFor="let consider of splitConsidering()">
            <li>
              {{consider}}
            </li>
          </div>
        </ul>
      </div>
    </app-info>
  </div>

  <app-visit-exemptions-exemption-general [exemption]="exemption"></app-visit-exemptions-exemption-general>

  <ng-container>

    <div class="row fieldset">
      <div class="col-md">
        <app-form-group label="Type of activities">
          <app-select [(ngModel)]="exemption['activityType']" id="activityType" [options]="activityTypes"
                      [formatter]="activityTypesFormatter" required>
          </app-select>
        </app-form-group>
      </div>
      <div class="col-md">
        <app-form-group label="Specification of activities">
          <textarea [(ngModel)]="exemption['activitySpecification']" id="activitySpecification" type="text" rows="3" minlength="20" maxlength="1024" class="form-control" required></textarea>
        </app-form-group>
      </div>
    </div>

    <div class="row fieldset">
      <div class="col-md">
        <app-form-group label="Location on board">
          <app-select [(ngModel)]="exemption['locationOnBoard']" id="locationOnBoard" [options]="activityTypeLocations"
                      [formatter]="activityTypeLocationsFormatter" required>
          </app-select>
        </app-form-group>
      </div>
      <div class="col-md">
        <div class="col-md">
          <app-form-group label="Description of exact location of work">
            <textarea [(ngModel)]="exemption['locationExact']" id="locationExact" type="text" rows="3" minlength="20" maxlength="1024" class="form-control" required></textarea>
          </app-form-group>
        </div>
      </div>
    </div>

    <div class="row fieldset">
      <div class="col-md">
        <app-form-group>
          <app-info class="formGroupLabel">
            <label>From</label>
            <div class="tooltipContent">Start and end date of operations</div>
          </app-info>
          <app-date-field [(ngModel)]="exemption['from']" id="from" required></app-date-field>
        </app-form-group>
        <app-form-group>
          <app-info class="formGroupLabel">
            <label>To</label>
            <div class="tooltipContent">Start and end date of operations</div>
          </app-info>
          <app-date-field [(ngModel)]="exemption['to']" id="to" required></app-date-field>
        </app-form-group>
      </div>
      <div class="col-md">
      </div>
    </div>

    <div class="row fieldset">
      <div class="col-md">
        <app-form-group label="Executor of activities">
          <app-select [(ngModel)]="exemption['executorType']" id="executorType" [options]="activityTypeExecutors"
                      [formatter]="activityTypeExecutorsFormatter" required>
          </app-select>
        </app-form-group>
      </div>
      <div class="col-md">
        <app-form-group label="Other" *ngIf="exemption['executorType'] === 'OTHER'">
          <input [(ngModel)]="exemption['executorTypeOther']" id="executorTypeOther" type="text" maxlength="256" class="form-control" required>
        </app-form-group>
      </div>
    </div>

    <div class="row fieldset">
      <div class="col-md">
        <app-form-group label="Does the vessel continue to have propulsive power ?">
          <input [(ngModel)]="exemption['continueToHavePropulsivePower']" id="continueToHavePropulsivePower" type="checkbox" class="form-check-input" required>
        </app-form-group>
      </div>
      <div class="col-md">
      </div>
    </div>

    <div class="row fieldset">
      <div class="col-md">
        <app-form-group label="What fuel does the propulsion use ?">
          <app-select [(ngModel)]="exemption['fuelType']" id="fuelType" [options]="activityTypeFuel"
                      [formatter]="activityTypeFuelFormatter" required>
          </app-select>
        </app-form-group>
      </div>
      <div class="col-md">
        <app-form-group label="Other" *ngIf="exemption['fuelType'] === 'OTHER'">
          <input [(ngModel)]="exemption['fuelTypeOther']" id="fuelTypeOther" type="text" maxlength="256" class="form-control" required>
        </app-form-group>
      </div>
    </div>

    <div class="row fieldset">
      <div class="col-md">
        <app-form-group label="Is a repair carried out within 25 meters of the (fuel) tank and/or engine and/or flammable materials and/or dangerous cargo ?">
          <input [(ngModel)]="exemption['repair25MetersOf']" id="repair25MetersOf" type="checkbox" class="form-check-input" required>
        </app-form-group>
      </div>
      <div class="col-md">
        <app-form-group *ngIf="exemption['repair25MetersOf']" label="Has a health and safety certificate been issued by a recognised gas expert ?">
          <input [(ngModel)]="exemption['hasCertificateIssued']" id="hasCertificateIssued" type="checkbox" class="form-check-input" required>
        </app-form-group>
        <app-form-group *ngIf="exemption['repair25MetersOf']" label="Gas doctor's statement">
          <div style="display: flex; justify-content: flex-end; gap: 10px;">
            <button class="btn btn-secondary" role="button"
                    title="Upload gas doctor's statement">
              <span class="fa fa-file-upload"></span>
              <input type="file" hidden multiple>
              Upload
            </button>
            <input disabled type="text" [ngModel]="" maxlength="30" class="form-control" style="width: 225px;">
          </div>
        </app-form-group>
      </div>
    </div>

    <div class="row fieldset">
      <div class="col-md">
        <app-form-group label="Is work being carried out on the installation or on the tank itself ?">
          <input [(ngModel)]="exemption['workOnInstallationOrOnTank']" id="workOnInstallationOrOnTank" type="checkbox" class="form-check-input" required>
        </app-form-group>
      </div>
      <div class="col-md">
        <app-form-group *ngIf="exemption['workOnInstallationOrOnTank']" label="How many fuel is on board ? (m3)">
          <input [(ngModel)]="exemption['fuelOnBoard']" id="fuelOnBoard" type="number" maxlength="32" class="form-control">
        </app-form-group>
        <app-form-group *ngIf="exemption['workOnInstallationOrOnTank']" label="What is the pressure in the tanks ? (Bar)">
          <input [(ngModel)]="exemption['pressureInTheTank']" id="pressureInTheTank" type="number" maxlength="32" class="form-control">
        </app-form-group>
        <app-form-group *ngIf="exemption['workOnInstallationOrOnTank']" label="What is the MARV-setting ?">
          <input [(ngModel)]="exemption['marvSetting']" id="marvSetting" type="text" maxlength="256" class="form-control">
        </app-form-group>
        <app-form-group *ngIf="exemption['workOnInstallationOrOnTank']" label="How is the Boil Off Control managed ?">
          <input [(ngModel)]="exemption['boilOffControlManagement']" id="boilOffControlManagement" type="text" minlength="20" maxlength="256" class="form-control">
        </app-form-group>
        <app-form-group *ngIf="exemption['workOnInstallationOrOnTank']" label="What control measures are taken to prevent an emission ?">
          <input [(ngModel)]="exemption['controlMeasuresTaken']" id="controlMeasuresTaken" type="text" minlength="20" maxlength="256" class="form-control">
        </app-form-group>
      </div>
    </div>

    <div class="row fieldset">
      <div class="col-md">
        <app-form-group label="Is there permission from the owner of the berth ? ">
          <input [(ngModel)]="exemption['thereIsPermissionFromTheOwner']" id="thereIsPermissionFromTheOwner" type="checkbox" class="form-check-input" required>
        </app-form-group>
      </div>
      <div class="col-md">
        <app-form-group label="Is there a firefighter who holds VCA/SCC diploma and AV-011 fire-fighting certificate and supervises the repair work ?">
          <input [(ngModel)]="exemption['thereIsFirefighter']" id="thereIsFirefighter" type="checkbox" class="form-check-input" required>
        </app-form-group>
      </div>
    </div>

    <div class="row fieldset">
      <div class="col-md">
        <app-form-group label="Is there a plan of action that includes an accurate indication of the duration of the total time of the work and any concurrent activities carried out during the work ?">
          <input [(ngModel)]="exemption['thereIsPlanOfAction']" id="thereIsPlanOfAction" type="checkbox" class="form-check-input" required>
        </app-form-group>
      </div>
      <div class="col-md">
      </div>
    </div>

    <div class="row fieldset">
      <div class="col-md">
        <app-form-group label="Reason for request for exemption">
          <textarea [(ngModel)]="exemption['reason']" id="reason" type="text" rows="3" minlength="20" maxlength="1024" class="form-control" required></textarea>
        </app-form-group>
      </div>
      <div class="col-md">
        <app-form-group label="Details">
          <textarea [(ngModel)]="exemption['details']" id="details" type="text" rows="3" maxlength="256" class="form-control"></textarea>
        </app-form-group>
      </div>
    </div>
  </ng-container>
</ng-container>
