import {Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {
  Country,
  IE3AdditionalSupplyChainActor,
  IE3ConsignmentHouseLevel,
  IE3ConsignmentMasterLevel,
  IE3ConsignmentProcess,
  IE3FilingType,
  IE3GetConsignment,
  IE3Party,
  IE3SaveConsignment
} from "@portbase/bezoekschip-service-typescriptmodels";
import {
  checkValidity,
  formDataSaved,
  openConfirmationModalWithCallback,
  openEditModal,
  publishEvent,
  removeItem,
  sendCommand,
  sendQuery
} from "../../../../common/utils";
import {
  ConsignmentSubModalEvent,
  ConsignmentUtils,
  EquipmentWithPlacement,
  GoodsItemSummaryWithHouseConsignments
} from "../../consignment.utils";
import {
  MasterConsignmentDetailsComponent,
  MasterConsignmentDetailsComponentData
} from "../master-consignment-details/master-consignment-details.component";
import {PortvisitUtils} from "../../../../refdata/portvisit-utils";
import {
  ConsignmentRouteInfo
} from "../master-consignment-details/master-consignment-route/master-consignment-route.component";
import {EventGateway} from "../../../../common/event-gateway";
import {AppContext} from "../../../../app-context";
import {ModalConfirmAutofocus, ModalConfirmAutofocusData} from "../../../../common/modal/modal-confirm.component";
import lodash, {cloneDeep} from "lodash";
import {ConsignmentRules} from "../../consignment.rules";

@Component({
  selector: 'app-house-consignment-details',
  templateUrl: './house-consignment-details.component.html',
  styleUrls: ['./house-consignment-details.component.scss']
})
export class HouseConsignmentDetailsComponent implements OnInit, OnDestroy {
  utils = ConsignmentUtils;
  rules = ConsignmentRules;
  refData = PortvisitUtils;

  consignmentProcess: IE3ConsignmentProcess;
  houseConsignment: IE3ConsignmentHouseLevel;
  data: HouseConsignmentDetailsComponentData;
  recordIndex: number;
  isNewConsignment: boolean;
  editMode: boolean;
  countries: Map<string, Country>;
  _routes: ConsignmentRouteInfo[] = [];

  equipmentListSummaries: EquipmentWithPlacement[];
  goodsItems: GoodsItemSummaryWithHouseConsignments[];

  @ViewChild("subModalContainer", {read: ViewContainerRef}) subModalContainer: ViewContainerRef;
  showSubModal: boolean = false;

  private readonly registration: () => void;

  constructor(private eventGateway: EventGateway, private elementRef: ElementRef) {
    this.registration = this.eventGateway.registerLocalHandler(this);
  }

  ngOnInit(): void {
    if (this.data.cachedConsignmentProcess) {
      this.setConsignment(this.data.cachedConsignmentProcess, true);
    } else {
      sendQuery("com.portbase.bezoekschip.common.api.consignments.queries.GetConsignment", <IE3GetConsignment>{
        consignmentProcessId: this.data.consignmentProcessId
      }, {
        caching: false
      }).subscribe((c: IE3ConsignmentProcess) => this.setConsignment(c, true));
    }
    ConsignmentUtils.getCountries().subscribe(c => {
      this.countries = c;
      this.updateRoutes();
    });
  }

  ngOnDestroy() {
    this.registration();
  }

  'cachedConsignmentUpdated' = (c: IE3ConsignmentProcess) => {
    if (c?.consignmentProcessId === this.consignmentProcess.consignmentProcessId) {
      this.setConsignment(c);
    }
  }

  get masterLevel(): IE3ConsignmentMasterLevel {
    return this.consignmentProcess.consignmentMasterLevel;
  }

  get routes(): ConsignmentRouteInfo[] {
    return this._routes;
  }

  set routes(routes: ConsignmentRouteInfo[]) {
    this.houseConsignment.itinerary = routes.map(r => r.country.code);
    this.updateRoutes();
  }

  addRoute = () => {
    this._routes.push({
      country: {}
    });
  }

  remove = () => {
    openConfirmationModalWithCallback((confirmed) => {
      if (confirmed) {
        this.masterLevel.consignmentsHouseLevel = removeItem(
          this.masterLevel.consignmentsHouseLevel, this.houseConsignment);
        this.elementRef.nativeElement.dispatchEvent(new Event("change", { bubbles: true, cancelable: true }));
        this.openMasterConsignment();
      }
    }, ModalConfirmAutofocus, <ModalConfirmAutofocusData>{
      type: "danger",
      title: "Remove house B/L",
      message: `You are about to remove house B/L: ${this.houseConsignment.consignmentNumber} from master B/L ${this.masterLevel.consignmentNumber}`,
      question: "Are you sure that you want to remove this house B/L?",
      confirmText: "Yes",
      cancelText: "No"
    }, 'static');
  }

  toggleEdit = () => {
    this.editMode = !this.editMode && !this.consignmentProcess.cancelled;
  }

  saveOrDeclare = () => {
    const records = this.masterLevel.consignmentsHouseLevel;
    if (!records[this.recordIndex]) {
      this.recordIndex = records.push(this.houseConsignment) - 1;
    } else {
      records[this.recordIndex] = this.houseConsignment;
    }
    if (this.isValid && this.processModel(this.hasBeenDeclared)) {
      if (this.hasBeenDeclared) {
        if (this.allowedToDeclare) {
          if (checkValidity(this.elementRef)) {
            ConsignmentUtils.declareConsignment(this.consignmentProcess);
          }
        } else {
          this.toggleEdit();
        }
      } else {
        sendCommand("com.portbase.bezoekschip.common.api.consignments.commands.SaveConsignment", <IE3SaveConsignment>{
          consignmentProcessId: this.consignmentProcess.consignmentProcessId,
          consignment: this.masterLevel,
          filing: this.consignmentProcess.filing
        }, () => {
          formDataSaved();
          this.toggleEdit();
        }, (error) => {
          AppContext.registerError(error);
          this.houseConsignment.goodsShipment = this.houseConsignment.goodsShipment || {};
        });
      }
    }
  }

  get allowedToDeclare(): boolean {
    return ConsignmentRules.isAllowedToDeclare(this.consignmentProcess);
  }

  get hasBeenDeclared(): boolean {
    return ConsignmentUtils.hasBeenDeclared(this.consignmentProcess);
  }

  processModelAndOpenMasterConsignment = () => {
    this.processModel(false);
    this.openMasterConsignment();
  }

  private openMasterConsignment = () => {
    this.registration();
    publishEvent('cachedConsignmentUpdated', this.consignmentProcess);
    openEditModal(MasterConsignmentDetailsComponent, <MasterConsignmentDetailsComponentData>{
      cachedConsignmentProcess: this.consignmentProcess
    }, {
      warnOnClose: true,
      currentToStack: true
    });
  }

  addNotifyParty = (): void => {
    const parties = this.houseConsignment.notifyParties || [];
    parties.push((<IE3Party>{["isNewRecord"]: true, name: "New notify party", address: {}, communications: []}) as any);
    this.houseConsignment.notifyParties = parties;
  }

  checkPartyDeletion = (party: IE3Party, index: number) => {
    if (!party) {
      this.houseConsignment.notifyParties.splice(index, 1);
    }
  }

  addSupplyChainActor = (): void => {
    const actors = this.houseConsignment.additionalSupplyChainActors || [];
    actors.push({["isNewRecord"]: true} as any);
    this.houseConsignment.additionalSupplyChainActors = actors;
  }

  checkSupplyChainActorDeletion = (actor: IE3AdditionalSupplyChainActor, index: number) => {
    if (!actor) {
      this.houseConsignment.additionalSupplyChainActors.splice(index, 1);
    }
  }

  processModel = (registerErrors: boolean) => ConsignmentUtils.validateHouseConsignment(this.consignmentProcess, this.houseConsignment, registerErrors);

  get isValid(): boolean {
    if (!checkValidity(this.elementRef)) {
      return false;
    }
    const duplicateHouseConsignments = this.masterLevel.consignmentsHouseLevel
      .filter(h => h.consignmentNumber === this.houseConsignment.consignmentNumber).length > 1;
    if (duplicateHouseConsignments) {
      AppContext.registerError(`Multiple house consignments with consignment number ${this.houseConsignment.consignmentNumber} found`);
      return false;
    }
    return true;
  }

  get isEditable() {
    return ConsignmentUtils.isEditable(this.consignmentProcess);
  }

  private setConsignment = (c: IE3ConsignmentProcess, updateEditMode?: boolean) => {
    this.consignmentProcess = c;
    if (updateEditMode && !ConsignmentUtils.hasBeenDeclared(c) && this.isEditable) {
      this.editMode = true;
    }
    this.houseConsignment = c.consignmentMasterLevel.consignmentsHouseLevel
      .find(c => c.consignmentNumber === this.data.consignmentNumber);
    if (this.houseConsignment) {
      if (!this.houseConsignment.consignmentNumber) {
        this.isNewConsignment = true;
      }
      this.recordIndex = c.consignmentMasterLevel.consignmentsHouseLevel.indexOf(this.houseConsignment);
      if (!this.houseConsignment.goodsShipment) {
        this.houseConsignment.goodsShipment = {};
      }
    } else {
      this.isNewConsignment = true;
      this.editMode = true;
      this.houseConsignment = HouseConsignmentDetailsComponent.createHouseConsignment(this.masterLevel);
    }
    this.houseConsignment.transportCharges = this.houseConsignment.transportCharges || {};
    this.updateRoutes();
    this.equipmentListSummaries = ConsignmentUtils.getEquipmentNumbersOfHouseConsignment(this.consignmentProcess, this.houseConsignment)
      .map(e => this.masterLevel.transportEquipmentMap[e])
      .map(equipment => (<EquipmentWithPlacement>{
        equipment: equipment,
        placement: null,
        goodsItems: ConsignmentUtils.getGoodsOfEquipment(
          this.consignmentProcess, equipment.containerIdentificationNumber, this.houseConsignment.consignmentNumber),
        houseConsignments: this.masterLevel.consignmentsHouseLevel
          .filter(h => ConsignmentUtils.getEquipmentNumbersOfHouseConsignment(
            this.consignmentProcess, h).includes(equipment.containerIdentificationNumber))
          .map(h => h.consignmentNumber)
      }));
    this.goodsItems = ConsignmentUtils.toConsignmentGoodsItemsSummaries(this.houseConsignment.goodsItems)
      .map(g => (<GoodsItemSummaryWithHouseConsignments>{
        goodsItem: g,
        houseConsignments: [],
        clearance: ConsignmentUtils.getClearanceOfGoodsItem(this.consignmentProcess.status, g.goodsItemNumber)
      }));
  }

  static createHouseConsignment = (masterLevel: IE3ConsignmentMasterLevel): IE3ConsignmentHouseLevel => {
    return {
      consignmentNumber: `${masterLevel.consignmentNumber}/${(masterLevel.consignmentsHouseLevel.length + 1)}`,
      transportCharges: {
        methodOfPayment: masterLevel.transportCharges.methodOfPayment
      },
      goodsShipment: {},
      goodsItems: [],
      itinerary: masterLevel.activeBorderTransportMeans.itinerary || [],
      placeOfAcceptance: masterLevel.placeOfAcceptance,
      placeOfDelivery: masterLevel.placeOfDelivery,
      traderAssignedReference: masterLevel.traderAssignedReference,
      consignee: masterLevel.consignee,
      consignor: masterLevel.consignor,
    }
  }

  updateRoutes() {
    if (!this.countries) {
      return;
    }
    const itinerary = cloneDeep(this.masterLevel.activeBorderTransportMeans.itinerary);
    if (this.houseConsignment.placeOfAcceptance) {
      itinerary.unshift(this.houseConsignment.placeOfAcceptance.countryUnCode);
    }
    if (this.houseConsignment.placeOfDelivery) {
      itinerary.push(this.houseConsignment.placeOfDelivery.countryUnCode);
    }
    this.houseConsignment.itinerary = lodash.uniqWith(itinerary, (a, b) => a === b);
    this._routes = ConsignmentUtils.createRoutesInfo(this.consignmentProcess, this.countries, this.houseConsignment.itinerary,
      this.houseConsignment.placeOfAcceptance, this.houseConsignment.placeOfDelivery);
  }

  canHavePassiveBorderTransportMeans() {
    return this.consignmentProcess?.filing?.filingType != IE3FilingType.F13;
  }

  "openConsignmentSubModal" = (event: ConsignmentSubModalEvent) => {
    this.subModalContainer.clear();
    if (event.modalContent) {
      this.subModalContainer.createEmbeddedView(event.modalContent);
    }
    this.showSubModal = true;
  }

  "closeConsignmentSubModal" = () => {
    this.showSubModal = false;
    this.setConsignment(this.consignmentProcess);
  }
}

export interface HouseConsignmentDetailsComponentData {
  consignmentProcessId: string;
  consignmentNumber: string;
  cachedConsignmentProcess: IE3ConsignmentProcess;
}
