import {WasteTypeSsn} from "@portbase/bezoekschip-service-typescriptmodels";

const wasteTypesSsn : WasteTypeSsn[] = [
  { "code": "101", "description": "Oily Bilge water", "marpolCategory": "Annex I", "specificationRequired": false},
  { "code": "102", "description": "Oily Residues (Sludge)", "marpolCategory": "Annex I", "specificationRequired": false},
  { "code": "103", "description": "Oily tank washings (Slops)", "marpolCategory": "Annex I", "specificationRequired": false},
  { "code": "104", "description": "Dirty ballast water", "marpolCategory": "Annex I", "specificationRequired": false},
  { "code": "105", "description": "Scale and sludge from tank cleaning", "marpolCategory": "Annex I","specificationRequired": false},
  { "code": "201", "description": "Category X substance", "marpolCategory": "Annex II", "specificationRequired": true},
  { "code": "202", "description": "Category Y substance", "marpolCategory": "Annex II", "specificationRequired": true},
  { "code": "203", "description": "Category Z substance", "marpolCategory": "Annex II", "specificationRequired": true},
  { "code": "204", "description": "OS - other substances", "marpolCategory": "Annex II", "specificationRequired": true},
  { "code": "401", "description": "Untreated sewage", "marpolCategory": "Annex IV", "specificationRequired": false},
  { "code": "501", "description": "A. Plastics", "marpolCategory": "Annex V", "specificationRequired": false},
  { "code": "502", "description": "B. Food waste", "marpolCategory": "Annex V", "specificationRequired": false},
  { "code": "503", "description": "C. Domestic wastes", "marpolCategory": "Annex V", "specificationRequired": false},
  { "code": "504", "description": "D. Cooking oil", "marpolCategory": "Annex V", "specificationRequired": false},
  { "code": "505", "description": "E. Incinerator ashes", "marpolCategory": "Annex V", "specificationRequired": false},
  { "code": "506", "description": "F. Operational wastes", "marpolCategory": "Annex V", "specificationRequired": false},
  { "code": "507", "description": "G. Animal carcasses", "marpolCategory": "Annex V", "specificationRequired": false},
  { "code": "508", "description": "H. Fishing gear", "marpolCategory": "Annex V", "specificationRequired": false},
  { "code": "509", "description": "I. E-waste (electronic waste)", "marpolCategory": "Annex V", "specificationRequired": false},
  { "code": "510", "description": "J. Cargo residues (Non-HME))", "marpolCategory": "Annex V", "specificationRequired": true},
  { "code": "511", "description": "K. Cargo residues (HME)", "marpolCategory": "Annex V", "specificationRequired": true},
  { "code": "601", "description": "Ozone-depleting substances", "marpolCategory": "Annex VI", "specificationRequired": false},
  { "code": "602", "description": "Exhaust gas-cleaning residues", "marpolCategory": "Annex VI", "specificationRequired": false},
  { "code": "991", "description": "Passively fished waste", "marpolCategory": "Annex V", "specificationRequired": false},
  { "code": "999", "description": "Other", "marpolCategory": "Annex I", "specificationRequired": true}
]

export function getWasteTypeSsn(ssnCode) : WasteTypeSsn {
  return wasteTypesSsn.find(wt => wt.code == ssnCode)
}
