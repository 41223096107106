import {Component} from '@angular/core';
import {
  Exemption_5_2,
  Exemption_5_2_RequestType
} from '@portbase/bezoekschip-service-typescriptmodels';
import {ExemptionBaseComponent} from "../exemption_base/exemption_base.component";

@Component({
  selector: 'app-visit-exemptions-exemption-5-2',
  templateUrl: './exemption_5_2.component.html',
  styleUrls: ['./exemption_5_2.component.css']
})
export class Exemption_5_2Component extends ExemptionBaseComponent {

  exemptionSubType: Exemption_5_2;

  requestTypes: Exemption_5_2_RequestType[] = [
    null,
    Exemption_5_2_RequestType.VESSEL,
    Exemption_5_2_RequestType.PASSENGER_VESSEL
  ];

  requestTypesFormatter = (requestType: Exemption_5_2_RequestType) => {
    switch (requestType) {
      case null:
        return 'None';
      case "VESSEL":
        return 'Vessel in petroleum port';
      case "PASSENGER_VESSEL":
        return 'Passenger vessel in petroleum port';
    }
  };
}

