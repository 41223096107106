<ng-container>
  <div *ngIf="showGeneralInformation">
    <ng-container>
      <div>
        <div class="declaration-block my-4">
          <h2>Exemption Information</h2>
        </div>
      </div>
    </ng-container>
  </div>

  <ng-container>
    <div *ngFor="let exemptionReferenceData of berthVisit.selectedExemptionTypes; let i = index">
      <div *ngIf="showExemption">
        {{
          setSelectedExemptionInfo(exemptionReferenceData.id, exemptionReferenceData.exemptionType, i)
        }}
        <div [ngSwitch]="exemptionReferenceData.exemptionType">
          <div *ngSwitchCase="'Exemption 3.2'">
            <app-visit-exemptions-exemption-3-2 [berthVisit]="berthVisit" [index]="i" [exemptionType]="exemptionReferenceData.exemptionType"
                                                [considering]="getConsidering(i)" [name]="getName(i)">
            </app-visit-exemptions-exemption-3-2>
          </div>
          <div *ngSwitchCase="'Exemption 3.4'">
            <app-visit-exemptions-exemption-3-4 [berthVisit]="berthVisit" [index]="i" [exemptionType]="exemptionReferenceData.exemptionType"
                                                [considering]="getConsidering(i)" [name]="getName(i)">
            </app-visit-exemptions-exemption-3-4>
          </div>
          <div *ngSwitchCase="'Exemption 3.5'">
            <app-visit-exemptions-exemption-3-5 [berthVisit]="berthVisit" [index]="i" [exemptionType]="exemptionReferenceData.exemptionType"
                                                [considering]="getConsidering(i)" [name]="getName(i)">
            </app-visit-exemptions-exemption-3-5>
          </div>
          <div *ngSwitchCase="'Exemption 3.6'">
            <app-visit-exemptions-exemption-3-6 [berthVisit]="berthVisit" [index]="i" [exemptionType]="exemptionReferenceData.exemptionType"
                                                [considering]="getConsidering(i)" [name]="getName(i)">
            </app-visit-exemptions-exemption-3-6>
          </div>
          <div *ngSwitchCase="'Exemption 3.11'">
            <app-visit-exemptions-exemption-3-11 [berthVisit]="berthVisit" [index]="i" [exemptionType]="exemptionReferenceData.exemptionType"
                                                [considering]="getConsidering(i)" [name]="getName(i)">
            </app-visit-exemptions-exemption-3-11>
          </div>
          <div *ngSwitchCase="'Exemption 3.13'">
            <app-visit-exemptions-exemption-3-13 [berthVisit]="berthVisit" [index]="i" [exemptionType]="exemptionReferenceData.exemptionType"
                                                [considering]="getConsidering(i)" [name]="getName(i)">
            </app-visit-exemptions-exemption-3-13>
          </div>
         <div *ngSwitchCase="'Exemption 4.1'">
            <app-visit-exemptions-exemption-4-1 [berthVisit]="berthVisit" [index]="i" [exemptionType]="exemptionReferenceData.exemptionType"
                                                [considering]="getConsidering(i)" [name]="getName(i)">
            </app-visit-exemptions-exemption-4-1>
         </div>
         <div *ngSwitchCase="'Exemption 4.5'">
            <app-visit-exemptions-exemption-4-5 [berthVisit]="berthVisit" [index]="i" [exemptionType]="exemptionReferenceData.exemptionType"
                                                [considering]="getConsidering(i)" [name]="getName(i)">
            </app-visit-exemptions-exemption-4-5>
         </div>
         <div *ngSwitchCase="'Exemption 4.6'">
            <app-visit-exemptions-exemption-4-6 [berthVisit]="berthVisit" [index]="i" [exemptionType]="exemptionReferenceData.exemptionType"
                                                [considering]="getConsidering(i)" [name]="getName(i)">
            </app-visit-exemptions-exemption-4-6>
         </div>
          <div *ngSwitchCase="'Exemption 4.11'">
            <app-visit-exemptions-exemption-4-11 [berthVisit]="berthVisit" [index]="i"
                                                 [exemptionType]="exemptionReferenceData.exemptionType"
                                                 [considering]="getConsidering(i)" [name]="getName(i)">
            </app-visit-exemptions-exemption-4-11>
          </div>
          <div *ngSwitchCase="'Exemption 4.11b'">
            <app-visit-exemptions-exemption-4-11b [berthVisit]="berthVisit" [index]="i"
                                                 [exemptionType]="exemptionReferenceData.exemptionType"
                                                 [considering]="getConsidering(i)" [name]="getName(i)">
            </app-visit-exemptions-exemption-4-11b>
          </div>
          <div *ngSwitchCase="'Exemption 4.13 (1)'">
            <app-visit-exemptions-exemption-4-13-1 [berthVisit]="berthVisit" [index]="i"
                                                 [exemptionType]="exemptionReferenceData.exemptionType"
                                                 [considering]="getConsidering(i)" [name]="getName(i)">
            </app-visit-exemptions-exemption-4-13-1>
          </div>
          <div *ngSwitchCase="'Exemption 4.13 (2)'">
            <app-visit-exemptions-exemption-4-13-2 [berthVisit]="berthVisit" [index]="i"
                                                 [exemptionType]="exemptionReferenceData.exemptionType"
                                                 [considering]="getConsidering(i)" [name]="getName(i)">
            </app-visit-exemptions-exemption-4-13-2>
          </div>
          <div *ngSwitchCase="'Exemption 5.2'">
            <app-visit-exemptions-exemption-5-2 [berthVisit]="berthVisit" [index]="i"
                                                [exemptionType]="exemptionReferenceData.exemptionType"
                                                [considering]="getConsidering(i)" [name]="getName(i)">
            </app-visit-exemptions-exemption-5-2>
          </div>
          <div *ngSwitchCase="'Exemption 5.3'">
            <app-visit-exemptions-exemption-5-3 [berthVisit]="berthVisit" [index]="i"
                                                [exemptionType]="exemptionReferenceData.exemptionType"
                                                [considering]="getConsidering(i)" [name]="getName(i)">
            </app-visit-exemptions-exemption-5-3>
          </div>
          <div *ngSwitchCase="'Exemption 5.4, 5.5, 5.6'">
            <app-visit-exemptions-exemption-5-4 [berthVisit]="berthVisit" [index]="i"
                                                [exemptionType]="exemptionReferenceData.exemptionType"
                                                [considering]="getConsidering(i)" [name]="getName(i)">
            </app-visit-exemptions-exemption-5-4>
          </div>
          <div *ngSwitchCase="'Exemption 6.3.1'">
            <app-visit-exemptions-exemption-6-3-1 [berthVisit]="berthVisit" [index]="i"
                                                [exemptionType]="exemptionReferenceData.exemptionType"
                                                [considering]="getConsidering(i)" [name]="getName(i)">
            </app-visit-exemptions-exemption-6-3-1>
          </div>
          <div *ngSwitchCase="'Exemption 6.3.3, 6.3.4'">
            <app-visit-exemptions-exemption-6-3-3 [berthVisit]="berthVisit" [index]="i"
                                                  [exemptionType]="exemptionReferenceData.exemptionType"
                                                  [considering]="getConsidering(i)" [name]="getName(i)">
            </app-visit-exemptions-exemption-6-3-3>
          </div>
          <div *ngSwitchCase="'Exemption 6.3.5'">
            <app-visit-exemptions-exemption-6-3-5 [berthVisit]="berthVisit" [index]="i"
                                                  [exemptionType]="exemptionReferenceData.exemptionType"
                                                  [considering]="getConsidering(i)" [name]="getName(i)">
            </app-visit-exemptions-exemption-6-3-5>
          </div>
          <div *ngSwitchCase="'Exemption 6.4'">
            <app-visit-exemptions-exemption-6-4 [berthVisit]="berthVisit" [index]="i"
                                                  [exemptionType]="exemptionReferenceData.exemptionType"
                                                  [considering]="getConsidering(i)" [name]="getName(i)">
            </app-visit-exemptions-exemption-6-4>
          </div>
          <div *ngSwitchCase="'Exemption 6.5'">
            <app-visit-exemptions-exemption-6-5 [berthVisit]="berthVisit" [index]="i"
                                                [exemptionType]="exemptionReferenceData.exemptionType"
                                                [considering]="getConsidering(i)" [name]="getName(i)">
            </app-visit-exemptions-exemption-6-5>
          </div>
          <div *ngSwitchCase="'Exemption 7.1 lid 1'">
            <app-visit-exemptions-exemption-7-1-1 [berthVisit]="berthVisit" [index]="i"
                                                [exemptionType]="exemptionReferenceData.exemptionType"
                                                [considering]="getConsidering(i)" [name]="getName(i)">
            </app-visit-exemptions-exemption-7-1-1>
          </div>
          <div *ngSwitchCase="'Exemption 7.1 lid 2'">
            <app-visit-exemptions-exemption-7-1-2 [berthVisit]="berthVisit" [index]="i"
                                                  [exemptionType]="exemptionReferenceData.exemptionType"
                                                  [considering]="getConsidering(i)" [name]="getName(i)">
            </app-visit-exemptions-exemption-7-1-2>
          </div>
          <div *ngSwitchCase="'Exemption 8.1'">
            <app-visit-exemptions-exemption-8-1 [berthVisit]="berthVisit" [index]="i"
                                                  [exemptionType]="exemptionReferenceData.exemptionType"
                                                  [considering]="getConsidering(i)" [name]="getName(i)">
            </app-visit-exemptions-exemption-8-1>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
