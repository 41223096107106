import {Component} from '@angular/core';
import {
  DangerInformation,
  Exemption_6_4,
  Exemption_6_4_BerthType,
  Vessel
} from '@portbase/bezoekschip-service-typescriptmodels';
import {ExemptionBaseComponent} from "../exemption_base/exemption_base.component";
import {PortvisitUtils} from "../../../../../refdata/portvisit-utils";
import {sendQuery} from "../../../../../common/utils";

@Component({
  selector: 'app-visit-exemptions-exemption-6-4',
  templateUrl: './exemption_6_4.component.html',
  styleUrls: ['./exemption_6_4.component.css']
})
export class Exemption_6_4Component extends ExemptionBaseComponent {

  exemptionSubType: Exemption_6_4;

  portVisitUtils = PortvisitUtils;
  dangerousGoods?: DangerInformation;

  vessel: Vessel;

  searchVessel = term => sendQuery('com.portbase.bezoekschip.common.api.visit.FindVessels', {term: term});

  berthTypes: Exemption_6_4_BerthType[] = [
    null,
    Exemption_6_4_BerthType.QUAY,
    Exemption_6_4_BerthType.BUOY_MOORING,
    Exemption_6_4_BerthType.POLE_BERTHING
  ];

  berthTypesFormatter = (berthType: Exemption_6_4_BerthType) => {
    switch (berthType) {
      case null:
        return 'None';
      case "QUAY":
        return 'Quay';
      case "BUOY_MOORING":
        return 'Buoy mooring';
      case "POLE_BERTHING":
        return 'Pole berthing';
    }
  };

  vesselFormatter = (vessel: Vessel) => {
    return vessel.name + ' – ' + vessel.imoCode;
  };

  onVesselChange(vessel: Vessel) {
    this.exemption['vesselName'] = vessel.name;
    this.exemption['vesselIMO'] = vessel.imoCode;
  }

  onDangerousGoodsChange(dangerousGoods: DangerInformation) {
    this.exemption['substanceName'] = dangerousGoods.name;
    this.exemption['unVnNumber'] = dangerousGoods.unCode;
  }
}
