import {Component, Input, OnInit} from '@angular/core';
import {BerthVisit, Exemption} from '@portbase/bezoekschip-service-typescriptmodels';
import {AppContext} from 'src/app/app-context';
import {VisitContext} from '../../visit-context';
import {uploadExemptionFiles} from './exemptions.upload';
import {PortvisitUtils} from "../../../refdata/portvisit-utils";

@Component({
  selector: 'app-visit-exemptions',
  templateUrl: './exemptions.component.html',
  styleUrls: ['./exemptions.component.css']
})
export class ExemptionsComponent implements OnInit {

  visitContext = VisitContext;
  appContext = AppContext;

  uploadedExemptionFileName: string;
  uploadedExemptionFileContent: string;

  selectedExemptionId: string;
  selectedExemptionType: string;
  index: number;

  @Input() berthVisit : BerthVisit;
  @Input() exemptions : { [index: string]: Exemption };

  onBehalfOfOptions: string[] = [
    "Captain",
    "Skipper",
    "Operator",
    "Owner",
    "Terminal",
    "Natural Person",
    "Other",
  ]

  ngOnInit() {

    if (this.visitContext.visit.visitDeclaration.exemptions == null) {
      this.visitContext.visit.visitDeclaration.exemptions = {};
    }

    if (this.exemptions && this.exemptions[this.index] == null) {
      this.exemptions[this.index] = {};
      this.exemptions[this.index].company = AppContext.userProfile.organisation.fullName;
    }

    if (this.exemptions && this.exemptions[this.index].company == null) {
      this.exemptions[this.index].company = "";
    }
  }

  upload = uploadExemptionFiles;

  get showGeneralInformation(): boolean {
    return this.berthVisit.selectedExemptionTypes?.length > 0 && this.berthVisit.selectedExemptionTypes[0] != null;
  }

  showExemption(index: number): boolean {
    return this.berthVisit.selectedExemptionTypes[index] != null
  }

  setSelectedExemptionInfo(selectedExemptionId: string, selectedExemptionType: string, index: number) {
    this.selectedExemptionId = selectedExemptionId;
    this.selectedExemptionType = selectedExemptionType;
    this.index = index;
  }

  getUploadedTerminalAgreements() {
    return "";
  }

  getConsidering(index: number): string {
    switch (this.berthVisit.berth.municipality) {
      case "Dordrecht": {
        return this.berthVisit.selectedExemptionTypes[index].consideringDordrecht;
      }
      case "Gorinchem": {
        return this.berthVisit.selectedExemptionTypes[index].consideringGorinchem;
      }
      case "Moerdijk": {
        return this.berthVisit.selectedExemptionTypes[index].consideringMoerdijk;
      }
      case "Nijmegen": {
        return this.berthVisit.selectedExemptionTypes[index].consideringNijmegen;
      }
      case "Papendrecht": {
        return this.berthVisit.selectedExemptionTypes[index].consideringPapendrecht;
      }
      case "Rotterdam": {
        return this.berthVisit.selectedExemptionTypes[index].consideringRotterdam;
      }
      case "Schiedam": {
        return this.berthVisit.selectedExemptionTypes[index].consideringSchiedam;
      }
      case "Stellendam": {
        return this.berthVisit.selectedExemptionTypes[index].consideringStellendam;
      }
      case "Vlaardingen": {
        return this.berthVisit.selectedExemptionTypes[index].consideringVlaardingen;
      }
      case "Zwijndrecht": {
        return this.berthVisit.selectedExemptionTypes[index].consideringZwijndrecht;
      }
      default: {
        return "";
      }
    }
  }

  getName(index: number): string {
    return this.berthVisit.selectedExemptionTypes[index].name;
  }

  protected readonly refData = PortvisitUtils;
}
