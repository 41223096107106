import {Component} from '@angular/core';
import {
  DangerInformation,
  Exemption_7_1_1
} from '@portbase/bezoekschip-service-typescriptmodels';
import {ExemptionBaseComponent} from "../exemption_base/exemption_base.component";
import {PortvisitUtils} from "../../../../../refdata/portvisit-utils";

@Component({
  selector: 'app-visit-exemptions-exemption-7-1-1',
  templateUrl: './exemption_7_1_1.component.html',
  styleUrls: ['./exemption_7_1_1.component.css']
})
export class Exemption_7_1_1Component extends ExemptionBaseComponent {

  exemptionSubType: Exemption_7_1_1;

  portVisitUtils = PortvisitUtils;
  dangerousGoods?: DangerInformation;

  onDangerousGoodsChange(dangerousGoods: DangerInformation) {
    this.exemption['substanceName'] = dangerousGoods.name;
    this.exemption['unVnNumber'] = dangerousGoods.unCode;
  }
}
