import {Component} from '@angular/core';
import {
  DangerInformation,
  Exemption_4_13_1
} from '@portbase/bezoekschip-service-typescriptmodels';
import {ExemptionBaseComponent} from "../exemption_base/exemption_base.component";
import {PortvisitUtils} from "../../../../../refdata/portvisit-utils";

@Component({
  selector: 'app-visit-exemptions-exemption-4-13-1',
  templateUrl: './exemption_4_13_1.component.html',
  styleUrls: ['./exemption_4_13_1.component.css']
})
export class Exemption_4_13_1Component extends ExemptionBaseComponent {

  exemptionSubType: Exemption_4_13_1;

  dangerousGoods?: DangerInformation
  portVisitUtils = PortvisitUtils;

  onDangerousGoodsChange(dangerousGoods: DangerInformation) {
    this.exemption['substanceName'] = dangerousGoods.name;
    this.exemption['unVnNumber'] = dangerousGoods.unCode;
  }
}

