import {Component, Input} from '@angular/core';
import {
  AcceptCommercialRelease,
  CommercialRelease,
  RejectCommercialRelease,
  ResubmitCommercialRelease
} from "@portbase/bezoekschip-service-typescriptmodels";
import {PortvisitUtils} from "../../../refdata/portvisit-utils";
import {sendCommand} from "../../../common/utils";
import {AppContext} from "../../../app-context";

@Component({
  selector: 'app-commercial-release-admin',
  templateUrl: './commercial-release-admin.component.html',
  styleUrls: ['./commercial-release-admin.component.css']
})
export class CommercialReleaseAdminComponent {
  @Input() declaration: CommercialRelease;
  @Input() rejectionReasons = "Rejected by Portbase";
  utils = PortvisitUtils;
  appContext = AppContext;


  ////
  // None of these work well in the corrent system
  // They WILL update the commercial release, but never trigger any communication with the terminal
  // They SHOULD (in the future) mimic a terminal response, but do not
  ////

  acceptRelease() {
    sendCommand('com.portbase.bezoekschip.common.api.commercialrelease.AcceptCommercialRelease',
      <AcceptCommercialRelease>{id: this.declaration.id},
      () => AppContext.registerSuccess('The release has been accepted manually.'))
  }

  rejectRelease() {
    sendCommand('com.portbase.bezoekschip.common.api.commercialrelease.RejectCommercialRelease',
      <RejectCommercialRelease>{id: this.declaration.id, reasons: this.rejectionReasons},
      () => AppContext.registerSuccess('The release has been rejected manually.'))
  }

  resubmit() {
    sendCommand("com.portbase.bezoekschip.common.api.commercialrelease.ResubmitCommercialRelease",
      <ResubmitCommercialRelease>{id: this.declaration.id},
      () => AppContext.registerSuccess('The release has been resubmitted.'))
  }
}
