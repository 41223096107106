import {Component, OnDestroy, OnInit} from '@angular/core';
import {
  ApiCustomer,
  CustomerReference,
  CustomerReferenceStatus,
  DateTimeRange,
  FindCustomers,
  RegisterCustomer,
  RemoveCustomer
} from '@portbase/bezoekschip-service-typescriptmodels';
import {filterByTerm, removeItem, sendCommand, sendQuery} from '../common/utils';
import {AppContext} from '../app-context';
import {AbstractOverviewComponent} from '../common/abstract-overview.component';
import {PortvisitUtils} from '../refdata/portvisit-utils';
import {Observable} from 'rxjs';
import {ComparatorChain} from "../common/comparator-chain";
import lodash from "lodash";
import {exportDataAsExcel} from "../common/upload/excel.utils";
import {map} from "rxjs/operators";

@Component({
  selector: 'app-release-to-party-overview',
  templateUrl: './release-to-party-overview.component.html',
  styleUrls: ['./release-to-party-overview.component.css']
})
export class ReleaseToPartyOverviewComponent extends AbstractOverviewComponent<ApiCustomerUi> implements OnInit, OnDestroy {
  appContext = AppContext;
  refData = PortvisitUtils;
  values: ApiCustomerUi[] = [];
  actionRequired: boolean = true;
  countryFilter: string = "";

  doLoad(dateTimeRange: DateTimeRange): Observable<ApiCustomerUi[]> {
    return sendQuery(
        'com.portbase.bezoekschip.common.api.commercialrelease.FindCustomers',
        <FindCustomers> {
          term: this.filterTerm,
          maxSize: 10000
        },
        {
          caching: false,
          showSpinner: true
        }
      ).pipe(map((result: ApiCustomer[]) => result.map(a => (<ApiCustomerUi>{
      ...a,
      status: this.getStatus(a),
    }))));
  }

  public override filterItems() {
    return this.items.filter(filterByTerm(this.filterTerm, this.excludedFilterFields))
      .filter(value => !this.actionRequired || this.actionRequired && value && (value.status == CustomerStatus.Incomplete))
      .filter(value => !this.countryFilter || this.countryFilter.toUpperCase() === value.customerData?.countryUnCode)
      .sort(new ComparatorChain('ownerFullName', 'customerData.fullName').compare);
  }

  doRender(entries: ApiCustomerUi[]) {
    this.values = Array.from(this.filterItems());
  }

  remove(value: ApiCustomer) {
    const removeLocal = () => {
      removeItem(this.values, value);
      removeItem(this.items, value);
    }

    if (value['new']) {
      removeLocal();
    } else {
      sendCommand('com.portbase.bezoekschip.common.api.accountmanagement.RemoveCustomer', <RemoveCustomer>{
        shortName: AppContext.userProfile.organisation?.shortName,
        customerShortName: value.portbaseId
      }, () => {
        removeLocal();
        AppContext.registerSuccess("Release-to party was removed successfully");
      });
    }
  }

  add = () => {
    const item = <ApiCustomerUi>{
      new: true, ownerPortbaseId: AppContext.isAdmin() ?
        undefined : AppContext.userProfile.organisation?.shortName,
      status: CustomerStatus.Incomplete
    };
    this.values.splice(0, 0, item);
    this.items.splice(0, 0, item);
  };

  register(value: ApiCustomer) {
    if (!!value['new']) {
      value.references = {};
    }
    lodash.values(value['additions']).map(s => value.references[s] = <CustomerReference>{reference: s, status: "NEW"});
    sendCommand('com.portbase.bezoekschip.common.api.accountmanagement.RegisterCustomer', <RegisterCustomer>{
      shortName: value.ownerPortbaseId,
      customerShortName: value.portbaseId,
      customerReferences: lodash.values(value.references).map(value1 => value1.reference)
    }, () => {
      delete value['new'];
      AppContext.registerSuccess("Release-to party was saved successfully");
    });
  }

  trackBy(index: number, obj: ApiCustomer): any {
    return obj['new'] ? obj : obj.ownerPortbaseId + obj.portbaseId;
  }

  allReferences(value: ApiCustomer): string[] {
    return lodash.values(value.references).map(customerRef => customerRef.reference);
  }

  downloadReleaseToParties() {

    let header = ["Customer organisation", "Address", "ZIP code", "Country",
      "Customer references(verified)", "Customer references(unverified)", "Customer references(rejected)"];

    if (this.appContext.isAdmin()) {
      header.unshift("Carrier organisation");
    }

    const data = [header];

    this.values.forEach(value => {
      const verified = this.filterWithStatus(value, CustomerReferenceStatus.VERIFIED);
      const unverified = this.filterWithStatus(value, CustomerReferenceStatus.NEW);
      const rejected = this.filterWithStatus(value, CustomerReferenceStatus.REJECTED);
      const customerData: string[] = [value.customerData?.fullName, value.customerData?.zipCode,
        value.customerData?.city, value.customerData?.countryName, verified, unverified, rejected];

      if (this.appContext.isAdmin()) {
        customerData.unshift(value.ownerPortbaseId);
      }

      data.push(customerData);
    })

    exportDataAsExcel(data, 'releaseToParties.xlsx');
  }

  filterWithStatus(value: ApiCustomer, status: CustomerReferenceStatus) {
    return lodash.values(value.references)
      .filter(customer => customer.status == status)
      .map(value1 => value1.reference).join(",");
  }

  private getStatus(a: ApiCustomer): CustomerStatus {
    const references = Object.values(a.references);
    if (!references.length || references.some(r => r.status === CustomerReferenceStatus.NEW || !r.status)) {
      return CustomerStatus.Incomplete;
    }
    if (references.every(r => r.status === CustomerReferenceStatus.REJECTED)) {
      return CustomerStatus.Rejected;
    }
    return CustomerStatus.Completed;
  }
}

interface ApiCustomerUi extends ApiCustomer {
  status?: CustomerStatus;
}

enum CustomerStatus {
  Incomplete = "Incomplete",
  Rejected = "Rejected",
  Completed = "Completed"
}
