<ng-container>

  <div>
    <b>{{ name }}</b>
    <app-info class="formGroupLabel" [wide]="false" [placement]="'left'">
      <div class="tooltipContent">
        <b>Considering</b>
        <ul>
          <div *ngFor="let consider of splitConsidering()">
            <li>
              {{consider}}
            </li>
          </div>
        </ul>
      </div>
    </app-info>
  </div>

  <app-visit-exemptions-exemption-general [exemption]="exemption"></app-visit-exemptions-exemption-general>

  <ng-container>
    <div class="row fieldset">
      <div class="col-md">
        <app-form-group>
          <app-info class="formGroupLabel">
            <label>Substance (Proper shipping name)</label>
          </app-info>
          <app-search [(ngModel)]="dangerousGoods" dataKey="name"
                      (ngModelChange)="onDangerousGoodsChange(dangerousGoods)"
                      [inputFormatter]="portVisitUtils.dangerInformationFormatter"
                      [searchFunction]="portVisitUtils.findDangerInformation"
                      [resultFormatter]="portVisitUtils.dangerInformationFormatter"
                      placeholder="Find by name or UN code">
          </app-search>
        </app-form-group>
      </div>
      <div class="col-md">
        <app-form-group label="Substance name">
          <textarea [(ngModel)]="exemption['substanceName']" id="substanceName" type="text" rows="3" maxlength="1024" class="form-control" required></textarea>
        </app-form-group>
      </div>
    </div>
    <div class="row fieldset">
      <div class="col-md">
      </div>
      <div class="col-md">
        <app-form-group label="UN or VN number">
          <input [(ngModel)]="exemption['unVnNumber']" id="unVnNumber" type="text" maxlength="256" class="form-control" required>
        </app-form-group>
      </div>
    </div>
    <div class="row fieldset">
      <div class="col-md">
        <app-form-group label="Where does transhipment take place ?">
          <app-select [(ngModel)]="exemption['placeType']" id="placeType" [options]="placeTypes"
                      [formatter]="placeTypesFormatter" required>
          </app-select>
        </app-form-group>
      </div>
      <div class="col-md">
      </div>
    </div>

    <div class="row fieldset">
      <div class="col-md">
        <app-form-group *ngIf="exemption['placeType'] === 'ANOTHER_VESSEL'" label="Vessel" >
          <app-search [(ngModel)]="vessel" (ngModelChange)="onVesselChange(vessel)" [searchFunction]="searchVessel" [inputFormatter]="vesselFormatter"
                      [minCharacters]="3" dataKey="imoCode" id="vessel">
          </app-search>
        </app-form-group>
      </div>
      <div class="col-md">
        <app-form-group *ngIf="exemption['placeType'] === 'ANOTHER_VESSEL'" label="Vessel Name">
          <input [(ngModel)]="exemption['vesselName']" id="vesselName" type="text" rows="3" maxlength="64" class="form-control" readonly>
        </app-form-group>
        <app-form-group *ngIf="exemption['placeType'] === 'ANOTHER_VESSEL'" label="Vessel IMO">
          <input [(ngModel)]="exemption['vesselIMO']" id="vesselIMO" type="text" rows="3" maxlength="64" class="form-control" readonly>
        </app-form-group>
      </div>
    </div>

    <div class="row fieldset">
      <div class="col-md">
        <app-form-group label="Reason type">
          <app-select [(ngModel)]="exemption['reasonType']" id="reasonType" [options]="reasonTypes"
                      [formatter]="reasonTypesFormatter" required>
          </app-select>
        </app-form-group>
      </div>
      <div class="col-md">
        <app-form-group *ngIf="exemption['reasonType'] === 'OTHER'">
          <app-info class="formGroupLabel">
            <label>Reason for request for exemption explanation</label>
            <div class="tooltipContent">What is the reason the fixed pump or hose cannot be used ?</div>
          </app-info>
          <textarea [(ngModel)]="exemption['reason']" id="reason" type="text" rows="3" minlength="20" maxlength="1024" class="form-control" required></textarea>
        </app-form-group>
      </div>
    </div>

    <div class="row fieldset">
      <div class="col-md">
        <app-form-group label="Has permission been granted by the terminal concerned ?">
          <input [(ngModel)]="exemption['hasPermissionBeenGranted']" id="hasPermissionBeenGranted" type="checkbox" class="form-check-input" required>
        </app-form-group>
      </div>
      <div class="col-md">
      </div>
    </div>

    <div class="row fieldset">
      <div class="col-md">
        <app-form-group label="Details">
          <textarea [(ngModel)]="exemption['details']" id="details" type="text" rows="3" maxlength="256" class="form-control"></textarea>
        </app-form-group>
      </div>
      <div class="col-md">
      </div>
    </div>
  </ng-container>
</ng-container>
