import {formDataSaved, sendCommand, sendQuery} from "../../common/utils";
import {DeclareDischargeList, GetDischargeListAsCsv} from "@portbase/bezoekschip-service-typescriptmodels";
import {exportDataAsExcel} from "../../common/upload/excel.utils";
import {VisitContext} from "../../visit-details/visit-context";
import {AppContext} from "../../app-context";

export class ContainerUtils {

  static constructContainerProcessId = (crn: string, equipmentNumber: string) =>
    !crn || !equipmentNumber ? null : `${crn}_${equipmentNumber}`;

  static statusFormatter = (value: String) => {
    switch (value) {
      case "EXPECTED":
        return "Expected";
       case "Dischared":
        return "Discharged";
      case null:
        return "";
    }
  }

  static downloadDischargeList(crn: string, cargoDeclarantShortName? : string, terminalCode?: string) {
    sendQuery("com.portbase.bezoekschip.common.api.cargo.GetDischargeListAsCsv", <GetDischargeListAsCsv>{
      crn: crn,
      cargoDeclarantShortName: cargoDeclarantShortName,
      terminalCode: terminalCode
    }, {responseType: 'text'}).subscribe(csv => {
      const data: string[][] = [];
      csv.split('\n').forEach(line => data.push(line.split(';').map(s => s.replace(/"+/g, ''))));
      exportDataAsExcel(data, 'dischargeList-' + crn + (terminalCode ? '-' + terminalCode : '') + '.xlsx');
    })
  }

  static sendDischargeListToTerminal(crn: string, cargoDeclarantShortName? : string, terminalCode?: string) {
    sendCommand("com.portbase.bezoekschip.common.api.cargo.DeclareDischargeList",
      <DeclareDischargeList>{
        crn: crn,
        cargoDeclarantShortName: cargoDeclarantShortName,
        terminalCodes: [terminalCode]
      }, () => {
        AppContext.registerSuccess("Discharge list sent successfully");
      })
  }

}
