import {Component, Input, OnInit, ViewChild} from "@angular/core";
import {ExemptionGeneralComponent} from '../exemption_general/exemption_general.component';
import {BerthVisit, Exemption} from "@portbase/bezoekschip-service-typescriptmodels";
import {VisitContext} from "../../../../visit-context";
import {AppContext} from "../../../../../app-context";
import {uuid} from "../../../../../common/utils";

@Component({
  selector: 'app-visit-exemptions-exemption-base',
  templateUrl: './exemption_base.component.html',
  styleUrls: ['./exemption_base.component.css']
})
export class ExemptionBaseComponent implements OnInit {

  visitContext = VisitContext;
  appContext = AppContext;

  @ViewChild(ExemptionGeneralComponent, { static: true }) exemptionGeneralComponent!: ExemptionGeneralComponent;

  exemption: Exemption;
  exemptionSubType: Exemption;

  @Input() berthVisit : BerthVisit;
  @Input() exemptionType : string;
  @Input() index : number;
  @Input() considering: string;
  @Input() name: string;

  createNewExemption(): Exemption {
    return {
      id: uuid(),
      exemptionType : this.berthVisit.selectedExemptionTypes[this.index].exemptionType.valueOf(),
      index : this.index,
      berthVisitId : this.berthVisit.id
    };
  }

  ngOnInit() {
    this.onInit(typeof this.exemptionSubType);
  }

  onInit<T>(classRef: T) {
    if (!this.exemption) {
      // Match exemption on visit using bertVisitId and index
      for (const value of Object.values(this.visitContext.visit.visitDeclaration.exemptions)) {
        if (value.index == this.index &&
          value.berthVisitId == this.berthVisit.id) {
          this.exemption = value;
        }
      }

      if (!this.exemption) {
        this.exemption = this.createNewExemption() as (typeof classRef);
        this.visitContext.visit.visitDeclaration.exemptions[this.exemption.id] = this.exemption;
      }
    }
  }

  splitConsidering(): string[] {
    return this.considering.split(/\r?\n/).filter(considering => considering);
  }
}
