import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  TemplateRef,
  Type,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {EventGateway} from '../event-gateway';
import {checkValidity, clearValidation, closeConfirmationModal, publishEvent} from '../utils';
import Modal from "bootstrap/js/dist/modal";
import {Subscription, TeardownLogic} from "rxjs";

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmationModalComponent implements AfterViewInit, OnDestroy {

  @ViewChild('modal', {static: true}) modal: ElementRef;
  @ViewChild('container', {read: ViewContainerRef}) container: ViewContainerRef;
  private readonly registration: () => void;

  modalSize: string;

  constructor(private eventGateway: EventGateway) {
    this.registration = this.eventGateway.registerLocalHandler(this);
  }

  ngAfterViewInit(): void {
    $(this.modal.nativeElement).on('hidden.bs.modal', () => this.container.clear());
  }

  'openConfirmationModal' = (event: ConfirmationModalEvent) => {
    if (event.component instanceof TemplateRef) {
      this.container.createEmbeddedView(event.component);
    } else {
      const componentRef = this.container.createComponent(<any>event.component);
      componentRef.instance['data'] = event.data;
      componentRef.instance['callback'] = close => {
        if (close && !checkValidity(this.modal)) {
          return;
        } else {
          clearValidation(this.modal);
        }
        if (event.callback) {
          const callbackResult = event.data.withReason || event.data.withChosableReason
            ? event.callback(close, event.data.reason) : event.callback(close);
          if (callbackResult instanceof Subscription) {
            callbackResult.add((_: TeardownLogic) => {
              closeConfirmationModal();
              publishEvent("confirmationModalClosed", close);
            });
          } else {
            closeConfirmationModal();
            publishEvent("confirmationModalClosed", close);
          }
        } else {
          closeConfirmationModal();
        }
      }
      $(componentRef.location.nativeElement).addClass('modal-content');
    }
    this.modalSize = event.modalSize;
    const modal = new Modal(this.modal.nativeElement, {
      backdrop: event.backdrop || true
    });
    publishEvent("confirmationModalOpened");
    modal.show();
  }

  'closeConfirmationModal' = () => {
    $(this.modal.nativeElement).modal('hide');
  }

  ngOnDestroy(): void {
    this.registration();
  }
}

export interface ConfirmationModalEvent {
  component : Type<any>;
  data? : any;
  backdrop?: boolean | 'static';
  modalSize: null | 'sm' | 'lg' | 'xl';
  callback: (boolean, string?) => any;
}
