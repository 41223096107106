import {
  IE3ConsignmentProcess, IE3ConsignmentProcessSummary,
  IE3CustomsProcess,
  IE3FilingType,
  IE3Place
} from "@portbase/bezoekschip-service-typescriptmodels";
import {ConsignmentUtils} from "./consignment.utils";

export class ConsignmentRules {

  private static northernIrelandPorts: string[] = ["IEBEF", "GBBEL", "GBBAN", "GBLAR", "GBWPT"];

  static isCustomsOfficeFirstEntryAllowed(p : IE3ConsignmentProcess): boolean {
    return ConsignmentRules.isCustomsOfficeFirstEntryRequired(p)
  }
  static isCustomsOfficeFirstEntryRequired(p : IE3ConsignmentProcess): boolean {
    return ConsignmentRules.isEnsFiling(p);
  }

  static isSplintConsignmentAllowed(p : IE3ConsignmentProcess): boolean {
    return ConsignmentRules.isEnsFiling(p);
  }

  static isPlaceOfDeliveryMasterLevelAllowed(p : IE3ConsignmentProcess): boolean {
    return !ConsignmentRules.isStraightConsignment(p);
  }
  static isPlaceOfDeliveryMasterLevelRequired(p : IE3ConsignmentProcess): boolean {
    return ConsignmentRules.isMasterConsignment(p) || p.filing.filingType === 'TSD'
  }

  static isPlaceOfAcceptanceMasterLevelAllowed(p : IE3ConsignmentProcess): boolean {
    return !ConsignmentRules.isStraightConsignment(p)
  }
  static isPlaceOfAcceptanceMasterLevelRequired(p : IE3ConsignmentProcess): boolean {
    return ConsignmentRules.isMasterConsignment(p)
  }

  static isCustomsStatusAllowed(p : IE3ConsignmentProcess): boolean {
    return ConsignmentRules.isDutchPort(p);
  }
  static isCustomsProcessAllowed(p : IE3ConsignmentProcess): boolean {
    return ConsignmentRules.isDutchPort(p);
  }

  static isBulkAuthorizationAllowed(p : IE3ConsignmentProcess): boolean {
    return ConsignmentRules.isDutchPort(p);
  }

  static isDischargeTerminalAllowed(p : IE3ConsignmentProcess): boolean {
    return ConsignmentRules.isDutchPort(p);
  }

  static isExchangeRateAllowed(p : IE3ConsignmentProcess): boolean {
    return ConsignmentRules.isDutchPort(p);
  }

  static isCrnRequired(p : IE3ConsignmentProcess): boolean {
    return ConsignmentRules.isDutchPort(p)
  }

  static isVoyageNumberAllowed(p : IE3ConsignmentProcess): boolean {
    return !ConsignmentRules.isCrnRequired(p)
  }

  static isReEntryIndicatorAllowed(p : IE3ConsignmentProcess): boolean {
    return ConsignmentRules.isEnsFiling(p);
  }
  static isReEntryIndicatorRequired(p : IE3ConsignmentProcess): boolean {
    return ConsignmentRules.isEnsFiling(p);
  }
  static isTransportChargesAllowed(p : IE3ConsignmentProcess): boolean {
    return ConsignmentRules.isTransportChargesRequired(p);
  }
  static isTransportChargesRequired(p : IE3ConsignmentProcess): boolean {
    return ConsignmentRules.isMasterConsignment(p) || ConsignmentRules.isRoadConsignment(p);
  }

  static isTemporaryStorageFromEuCountryToNL = (p : IE3ConsignmentProcess) => {
    return ConsignmentRules.isDutchPort(p)
      && ConsignmentRules.isTemporaryStorageOnlyConsignment(p.filing.filingType)
      && p.consignmentMasterLevel.activeBorderTransportMeans.itinerary.every(c => ConsignmentRules.isEuCountryOrNorthernIreland(c, p.consignmentMasterLevel.placeOfLoading?.locationUnCode));
  }

  // static isDutchPort = (place: IE3Place) => place?.countryUnCode === "NL";

  static isTemporaryStorageOnlyConsignment = (filingType: IE3FilingType): boolean => [IE3FilingType.TSD, IE3FilingType.TSR]
    .includes(filingType);

  static isNoFilingConsignment = (filingType: IE3FilingType): boolean => [IE3FilingType.NONE]
    .includes(filingType);

  static isEnsFiling = (p: IE3ConsignmentProcess): boolean => [IE3FilingType.F10, IE3FilingType.F11, IE3FilingType.F12,  IE3FilingType.F13, IE3FilingType.F50]
    .includes(p?.filing?.filingType);

  static isSummaryAllowedToDeclare = (consignment: IE3ConsignmentProcessSummary): boolean => !consignment.fromVisit;

  static isAllowedToDeclare = (consignment: IE3ConsignmentProcess): boolean => !consignment.fromVisit;

  static isMasterConsignment = (consignment: IE3ConsignmentProcess): boolean => [IE3FilingType.F11, IE3FilingType.F12]
    .includes(consignment.filing.filingType);

  static isStraightConsignment = (consignment: IE3ConsignmentProcess): boolean => consignment.filing.straight ||
    [IE3FilingType.F10, IE3FilingType.F13].includes(consignment.filing.filingType);

  static isStraight = (filingType: IE3FilingType, straight: boolean): boolean => straight ||
    [IE3FilingType.F10, IE3FilingType.F13].includes(filingType);

  static isRoadConsignment = (consignment: IE3ConsignmentProcess): boolean => [IE3FilingType.F50]
    .includes(consignment.filing.filingType);

  static isMultipleFilingConsignment = (consignment: IE3ConsignmentProcess): boolean => [IE3FilingType.F12, IE3FilingType.F13]
    .includes(consignment.filing.filingType);

  static isSingleFilingConsignment = (consignment: IE3ConsignmentProcess): boolean => [IE3FilingType.F10, IE3FilingType.F11, IE3FilingType.TSD]
    .includes(consignment.filing.filingType);

  static isSingleFilingConsignmentOrRoad = (consignment: IE3ConsignmentProcess): boolean => [IE3FilingType.F10, IE3FilingType.F11, IE3FilingType.F50]
    .includes(consignment.filing.filingType);

  static isPreviousDocumentAllowed(consignment: IE3ConsignmentProcess): boolean {
    return !ConsignmentRules.isNoFilingConsignment(consignment.filing.filingType);
  }

  static isPreviousEnsDocumentRequired(consignment: IE3ConsignmentProcess): boolean {
    if (ConsignmentRules.isNoFilingConsignment(consignment.filing.filingType)) {
      return false;
    }
    return ConsignmentRules.isDutchPort(consignment)
        && ConsignmentRules.isTemporaryStorageOnlyConsignment(consignment.filing.filingType)
        && consignment.consignmentMasterLevel.activeBorderTransportMeans.itinerary.some(c => !ConsignmentRules.isEuCountryOrNorthernIreland(c, consignment.consignmentMasterLevel.placeOfLoading?.locationUnCode)
            && consignment.consignmentMasterLevel.customsProcess != IE3CustomsProcess.MILITARY);
  }

  // place?.countryUnCode === "NL";
  static isDutchPort(p : IE3ConsignmentProcess) {
    return p?.consignmentMasterLevel?.placeOfUnloading?.countryUnCode == 'NL';
  }
  static isEuPort = (place: IE3Place) => place?.euLocation;
  static isNorthernIrelandPort = (unloCode: string) => this.northernIrelandPorts.includes(unloCode);
  static isEuCountryOrNorthernIreland = (countryCode: string, unloCode: string) =>
    this.isNorthernIrelandPort(unloCode) || ConsignmentUtils.euCountries.includes(countryCode)

  static isShortLanded = (consignmentProcess: IE3ConsignmentProcess, equipmentNumber: string) =>
    consignmentProcess?.shortLandedContainers?.includes(equipmentNumber);
  static isDischarged = (consignmentProcess: IE3ConsignmentProcess, equipmentNumber: string) =>
    consignmentProcess?.dischargedContainers?.includes(equipmentNumber);

  static ensFilingRequired = (filingType: IE3FilingType) => [IE3FilingType.F10, IE3FilingType.F11, IE3FilingType.F12,
    IE3FilingType.F13, IE3FilingType.F50].includes(filingType);


}

