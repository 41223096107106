import {Component, Input} from '@angular/core';
import {ExemptionsComponent} from "../../exemptions.component";
import {Exemption} from "@portbase/bezoekschip-service-typescriptmodels";

@Component({
  selector: 'app-visit-exemptions-exemption-general',
  templateUrl: './exemption_general.component.html',
  styleUrls: ['./exemption_general.component.css']
})
export class ExemptionGeneralComponent extends ExemptionsComponent {
  @Input() exemption: Exemption
}
