import {NgModule} from "@angular/core";
import {BrowserModule} from "@angular/platform-browser";
import {CommonModule} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {HttpClientModule} from "@angular/common/http";
import {DragulaModule} from "ng2-dragula";
import {NgChartsModule} from "ng2-charts";
import {TypeaheadWindowComponent} from "./typeahead/typeahead-window.component";
import {PopoverComponent} from "./popover/popover.component";
import {FilterComponent} from "./search/filter/filter.component";
import {TooltipComponent} from "./tooltip/tooltip.component";
import {PopoverDirective} from "./popover/popover.directive";
import {MessageStatusComponent} from "./message-status/message-status.component";
import {MessageStatusIconComponent} from "./message-status/message-status-icon/message-status-icon.component";
import {DateRangeComponent} from "./date/date-range/date-range.component";
import {DateFieldComponent} from "./date/date-field/date-field.component";
import {DatePickerComponent} from "./date/date-picker/date-picker.component";
import {SelectComponent} from "./select/select.component";
import {SearchComponent} from "./search/search.component";
import {FormGroupComponent} from "./form-group/form-group.component";
import {MultiselectComponent} from "./multiselect/multiselect.component";
import {YesNoComponent} from "./yes-no/yes-no.component";
import {DecimalNumberDirective} from "./number-input/decimal-number.directive";
import {TimestampPipe} from "./date/timestamp.pipe";
import {InfoComponent} from "./info/info.component";
import {RadioComponent} from "./radio/radio.component";
import {AutoFocusDirective} from "./autofocus/auto-focus.directive";
import {DefaultValueDirective} from "./default-value.directive";
import {ValidatorDirective} from "./validator.directive";
import {MinValidatorDirective} from "./min-validator-directive";
import {MaxValidatorDirective} from "./max-validator-directive";
import {ScrollerComponent} from "./scroller/scroller.component";
import {CollapseComponent} from "./collapse/collapse.component";
import {LocalFilterComponent} from "./search/local-filter/local-filter.component";
import {TagsComponent} from "./tags/tags.component";
import {ModalConfirmAutofocus} from "./modal/modal-confirm.component";
import {CompareDirective} from "./compare/compare.directive";
import {StartCasePipe} from "./start-case.pipe";
import {LargeNumberFormatPipe} from "./large-number-format.pipe";
import {MultiSearchComponent} from "./search/multi-search/multi-search.component";
import {MultiInputComponent} from "./multi-input/multi-input.component";
import {DaysComponent} from "./duration/days.component";
import {ConfirmationToggleComponent} from "./confirmation-toggle/confirmation-toggle.component";
import {JoinPipe} from "./join.pipe";
import {AddElementComponent} from "./add-element/add-element.component";
import {FormFieldComponent} from "./form-field/form-field.component";
import {EditModalComponent} from "./edit-modal/edit-modal.component";
import {TabPanelComponent} from "./tab-panel/tab-panel.component";
import {TabItemComponent} from "./tab-panel/tab-item/tab-item.component";
import {TypeaheadDirective} from "./typeahead/typeahead.directive";
import {TimePickerComponent} from "./date/time-picker/time-picker.component";
import {TaskMessageStatusComponent} from "./task-message-status/task-message-status.component";
import {
  TaskMessageStatusIconComponent
} from "./task-message-status/task-message-status-icon/task-message-status-icon.component";
import {SentenceCasePipe} from "./sentence-case.pipe";
import {RoundPipe} from "./round.pipe";
import {SelectionCheckboxComponent} from "./selection-checkbox/selection-checkbox.component";
import {AlertingComponent} from "../alerting/alerting.component";
import {StatusAlertComponent} from "./status-alert/status-alert.component";
import {OrderByPipe} from "./table/order-by.pipe";
import {TableViewComponent} from "./table/table-view/table-view.component";
import {HighlightComponent} from "./typeahead/highlight/highlight.component";
import {WebsocketService} from "./websocket.service";
import {MiddleEllipsisPipe} from "./middle-ellipsis.pipe";
import {CalendarPipe} from "./date/calendar.pipe";
import {QuarterHourPickerComponent} from "./date/quarter-hour-picker/quarter-hour-picker.component";
import {NewDateFieldComponent} from "./date/new-date-field/new-date-field.component";
import {NumberFormatPipe} from "./number-format-pipe";
import {CountryFieldComponent} from "./country-field/country-field.component";
import {PaginationComponent} from "./pagination/pagination.component";
import {
  ExemptionGeneralComponent
} from "../visit-details/visit/exemptions/types/exemption_general/exemption_general.component";

export const commonDeclarations = [
  // Pipes
  TimestampPipe,
  CalendarPipe,
  SentenceCasePipe,
  StartCasePipe,
  LargeNumberFormatPipe,
  NumberFormatPipe,
  JoinPipe,
  RoundPipe,
  OrderByPipe,
  MiddleEllipsisPipe,

  // Directives
  PopoverDirective,
  DecimalNumberDirective,
  AutoFocusDirective,
  DefaultValueDirective,
  ValidatorDirective,
  MinValidatorDirective,
  MaxValidatorDirective,
  CompareDirective,
  AutoFocusDirective,
  TypeaheadDirective,

  // Components
  FilterComponent,
  TooltipComponent,
  MessageStatusComponent,
  MessageStatusIconComponent,
  DateFieldComponent,
  NewDateFieldComponent,
  CountryFieldComponent,
  DateRangeComponent,
  DatePickerComponent,
  QuarterHourPickerComponent,
  SelectionCheckboxComponent,
  SelectComponent,
  SearchComponent,
  FormGroupComponent,
  MultiselectComponent,
  YesNoComponent,
  InfoComponent,
  RadioComponent,
  ScrollerComponent,
  CollapseComponent,
  LocalFilterComponent,
  TagsComponent,
  ModalConfirmAutofocus,
  MultiSearchComponent,
  MultiInputComponent,
  DaysComponent,
  ConfirmationToggleComponent,
  AddElementComponent,
  FormFieldComponent,
  EditModalComponent,
  TabPanelComponent,
  TabItemComponent,
  TimePickerComponent,
  TaskMessageStatusComponent,
  TaskMessageStatusIconComponent,
  AlertingComponent,
  StatusAlertComponent,

  TableViewComponent,
  PaginationComponent,
  ExemptionGeneralComponent
]

@NgModule({
  declarations: commonDeclarations,
    imports: [
        BrowserModule,
        CommonModule,
        FormsModule,
        HttpClientModule,
        DragulaModule.forRoot(),
        NgChartsModule,
        TypeaheadWindowComponent,
        PopoverComponent,
        HighlightComponent,
    ],
  exports: commonDeclarations,
  providers: [
    WebsocketService
  ]
})
export class PortVisitCommonModule {

}
