<ng-container *ngIf="appContext.isAdmin() && !context.eventId">
  <div class="dropdown">
    <button class="btn btn-secondary" type="button" id="adminHamburgerMenu" data-bs-toggle="dropdown" aria-haspopup="true"
      aria-expanded="false">
      <i class="fa fa-user-shield" aria-hidden="true"></i>
    </button>
    <div class="dropdown-menu dropdown-menu-end" aria-labelledby="adminHamburgerMenu">
      <!-- Reject declaration -->
      <a (click)="openConfirmationModal(rejectModal)" id="rejectShipStores" class="dropdown-item text-danger" tabindex="-1" role="button">
        Reject
      </a>
      <!-- Accept declaration -->
      <a (click)="accept()" id="acceptShipStores" class="dropdown-item text-success" tabindex="-1" role="button">
        Accept
      </a>
      <!-- Upload declaration -->
      <a download="{{context.visit.crn}} + '_stores.xls'" *ngIf="downloadLink" href="{{downloadLink}}"
        class="dropdown-item" tabindex="-1">
        <i class="fas fa-file-excel"></i> Download uploaded sheet
      </a>
      <!-- Messages -->
      <ng-container>
        <h6 class="dropdown-header" style="font-size: 18px;">Messages</h6>
        <a href="#" (click)="$event.preventDefault(); openSwMessages()" class="dropdown-item">
          STO to Singlewindow
        </a>
      </ng-container>
    </div>
  </div>
</ng-container>

<ng-template #rejectModal>
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title">Reject reason</h5>
    </div>
    <div class="modal-body">
      <input id="rejectShipStores-reason" type="text" class="form-control">
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Back</button>
      <button type="button" class="btn btn-primary" data-bs-dismiss="modal"
              (click)="reject(appContext.getDocument().getElementById('rejectShipStores-reason')['value'])">Send</button>
    </div>
  </div>
</ng-template>
