import {AfterViewInit, Component} from '@angular/core';
import {sendCommand, sendQuery} from '../common/utils';
import {DeclarationType, PendingDeclaration} from '@portbase/bezoekschip-service-typescriptmodels';
import moment from 'moment';
import {AppContext} from '../app-context';

@Component({
  selector: 'app-message-overview',
  templateUrl: './message-overview.component.html',
  styleUrls: ['./message-overview.component.css']
})
export class MessageOverviewComponent implements AfterViewInit {
  declarationTypes: DeclarationType[] = <any>["All types", "VISIT", "SECURITY", "DANGEROUS_GOODS", "WASTE", "HEALTH", "NOA", "NOD",
    "PAX", "STO", "ATA", "ATD", "COA", "EXP", "SEC", "WAS", "WAR", "HZA", "HZD", "SDT", "ENS", "COPRAR"];
  harbours: string [] = <any>["All harbours", "NLRTM", "NLAMS", "NLDZL", "NLHAR", "NLSCE", "NLBRS", "NLHAN", "NLLAN",
    "NLTNZ", "NLWTE",	"NLDHR", "NLVLI", "NLANK"];
  filter: MessageFilter = {
    declarationType: <any>"All types",
		harbour: 'All harbours',
    minimumAge: 1,
    minimumAgeUnit: 'hours'
  };
  entries: PendingDeclaration[] = [];

  ngAfterViewInit(): void {
    this.loadPendingDeclarations();
  }

  private loadPendingDeclarations() {
    sendQuery("com.portbase.bezoekschip.common.api.admin.GetPendingDeclarations", {}, {caching: false, showSpinner: true})
      .subscribe((result: PendingDeclaration[]) => this.entries = result);
  }

  resubmit = () => {
    sendCommand('com.portbase.bezoekschip.common.api.admin.ResubmitDeclarations',
      {
        commandIndexes: this.getPendingDeclarations().filter(e => e['selected']).map(e => e.messageIndex)
      },
      () => {
        this.loadPendingDeclarations();
        AppContext.registerSuccess("Declarations were resubmitted successfully")
      })
  };

  remove = () => {
    sendCommand('com.portbase.bezoekschip.common.api.admin.RemoveDeclarations',
      {
        declarations: this.getPendingDeclarations().filter(e => e['selected'])
      },
      () => {
        setTimeout(() => {
          this.loadPendingDeclarations();
          AppContext.registerSuccess("Declarations were removed successfully")
          //query is steeds net te vroeg, en dit is toch maar een admin scherm
        }, 1000);
      })
  };

  selectAll = () => {
    this.entries.forEach(e => e['selected'] = true);
  };

  deselectAll = () => {
    this.entries.forEach(e => e['selected'] = false);
  };

  getPendingDeclarations = (): PendingDeclaration[] => {
    let threshold = moment().subtract(this.filter.minimumAge, this.filter.minimumAgeUnit);
    return this.entries.filter(m => {
      if ((this.filter.declarationType != <any>'All types' && m.type !== this.filter.declarationType)
				|| (this.filter.harbour != <any> 'All harbours' && !m.crn.startsWith(this.filter.harbour))) {
        return false;
      }
      return moment(m.timestamp).isBefore(threshold);
    });
  };

  allEntriesChecked = () => this.entries.every(e => e['selected']);

  noEntriesChecked = () => !this.entries.some(e => e['selected']);

  cannotBeResubmitted = () => {
    return this.filter.declarationType.includes("VISIT" || "WASTE" || "SECURITY" || "DANGEROUS_GOODS" || "HEALTH") &&
      this.filter.harbour.includes("NLRTM" || "NLAMS");
  }
}

interface MessageFilter {
  declarationType: DeclarationType;
  harbour: string;
  minimumAge: number;
  minimumAgeUnit: 'minutes' | 'hours' | 'days';
}
