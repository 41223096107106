import {Component, Input} from '@angular/core';
import {IE3ConsignmentProcess} from "@portbase/bezoekschip-service-typescriptmodels";
import {ConsignmentUtils} from "../../consignment.utils";

@Component({
  selector: 'app-consignment-details-alerts',
  templateUrl: './consignment-details-alerts.component.html',
  styleUrls: ['./consignment-details-alerts.component.scss']
})
export class ConsignmentDetailsAlertsComponent {
  consignmentProcess: IE3ConsignmentProcess;
  errors: ErrorWithPointer[];

  trackByIndex = (index: number) => index;

  @Input("consignmentProcess")
  set setConsignmentProcess(value: IE3ConsignmentProcess) {
    this.consignmentProcess = value;
    if (value) {
      this.errors = ConsignmentUtils.getMessagesEns(value, value.ensTaskStatus, value.status)
        .map(s => ({
          ...s,
          header: `${s.header} – Reported by European customs`,
          summary: `${s.summary} – Reported by European customs`
        }))
        .concat(ConsignmentUtils.getTemporaryStorageStatusMessage(value.status?.filingStatusTemporaryStorage)
          .map(s => ({
            ...s,
            header: `${s.header} – Reported by Dutch customs`,
            summary: `${s.summary} – Reported by Dutch customs`
          })));
    }
  }

  getPointersWithDescription = (error: ErrorWithPointer): ErrorPointer[] =>
    error.pointers?.filter(e => e.description);
}

export interface ErrorWithPointer {
  header: string;
  summary: string;
  pointers: ErrorPointer[];
}

export interface ErrorPointer {
  description: string;
  index?: number;
  identification?: string;
  screenPointer?: string;
  model?: any;
  type?: string;
}
