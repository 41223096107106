import {Component, Input} from '@angular/core';
import {PortvisitUtils} from '../../../refdata/portvisit-utils';
import {
  ApiCustomer,
  CommercialRelease,
  FindCustomers,
  GetMyCarriers,
  IE3ConsignmentProcessSummary,
  ReleaseToParty
} from '@portbase/bezoekschip-service-typescriptmodels';
import {AppContext} from "../../../app-context";
import lodash, {toUpper} from 'lodash';
import {of} from 'rxjs';
import {sendQuery} from '../../../common/utils';
import {map} from 'rxjs/operators';
import {terminals} from "../../commercial-release.utils";

@Component({
  selector: 'app-commercial-release-details',
  templateUrl: './commercial-release-details.component.html',
  styleUrls: ['./commercial-release-details.component.css']
})
export class CommercialReleaseDetailsComponent {
  refData = PortvisitUtils;
  appContext = AppContext;
  @Input() declaration: CommercialRelease;

  selectedConsignment: IE3ConsignmentProcessSummary;

  selectConsignment(entity: IE3ConsignmentProcessSummary) {
    delete this.declaration.releaseData.equipmentNumber;
    this.selectedConsignment = entity;
    this.declaration.releaseData.consignmentNumber = toUpper(entity?.masterConsignment?.consignmentNumber);
    this.declaration.releaseData.terminalShortName = entity?.masterConsignment?.dischargeTerminal?.organisationShortName;
    delete this.declaration.releaseData.containerOperator;
    this.declaration.releaseData.empty = entity?.masterConsignment?.equipments?.every(e => e.empty)
      || (entity && lodash.sumBy(entity?.masterConsignment?.goodsItems, g => g.grossWeight) <= 1);
    this.declaration.crn = entity?.crn;
  }

  containersInConsignment = (summary: IE3ConsignmentProcessSummary) =>
  {
    return of(summary ? lodash.uniq(lodash.flatMap(
      summary.straight ? summary.masterConsignment?.houseConsignments[0].goodsItems : summary.masterConsignment.goodsItems, g => g.placements)
      .map(p => p.containerIdentificationNumber)) : []);
  }

  carriersProvider = (iamConnectedId: string) => sendQuery(
    "com.portbase.bezoekschip.common.api.visit.GetMyCarriers", <GetMyCarriers> {
      cargoDeclarantIamConnectedId: iamConnectedId || AppContext.userProfile.organisation?.iamConnectedId
    });

  findReleaseToParties = term => {
    const ownerShortName = (AppContext.isAdmin() && this.declaration.releaseData?.containerOperator?.cargoDeclarantShortName)
      || AppContext.userProfile.organisation?.shortName;
    return sendQuery(
        "com.portbase.bezoekschip.common.api.commercialrelease.FindCustomers",
        <FindCustomers> {
          term: term,
          ownerShortName: ownerShortName
        }
       )
      .pipe(map((results: ApiCustomer[]) => {
        return lodash.flatMap(results, r => lodash.values(r.references).map(customerRef => <ReleaseToParty>{
          name: r.customerData?.fullName,
          portbaseId: r.portbaseId,
          customerReference: customerRef.reference
        }));
      }));
  };
  protected readonly terminals = terminals;
}
