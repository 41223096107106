import {Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {ConsignmentSubModalEvent, ConsignmentUtils, EquipmentWithPlacement} from "../../consignment.utils";
import {
  IE3ConsignmentHouseLevel,
  IE3ConsignmentProcess,
  IE3GetConsignment,
  IE3GoodsItem,
  IE3Packaging,
  IE3SaveConsignment,
  IE3SupportingDocument
} from "@portbase/bezoekschip-service-typescriptmodels";
import {
  checkValidity,
  formDataSaved,
  openConfirmationModalWithCallback,
  openEditModal,
  publishEvent,
  removeItem,
  sendCommand,
  sendQuery
} from "../../../../common/utils";
import {
  MasterConsignmentDetailsComponent,
  MasterConsignmentDetailsComponentData
} from "../master-consignment-details/master-consignment-details.component";
import {
  HouseConsignmentDetailsComponent,
  HouseConsignmentDetailsComponentData
} from "../house-consignment-details/house-consignment-details.component";
import {PortvisitUtils} from "../../../../refdata/portvisit-utils";
import {EventGateway} from "../../../../common/event-gateway";
import {ModalConfirmAutofocus, ModalConfirmAutofocusData} from "../../../../common/modal/modal-confirm.component";
import {ConsignmentRules} from "../../consignment.rules";

@Component({
  selector: 'app-goods-consignment-details',
  templateUrl: './goods-consignment-details.component.html',
  styleUrls: ['./goods-consignment-details.component.scss']
})
export class GoodsConsignmentDetailsComponent implements OnInit, OnDestroy {
  utils = ConsignmentUtils;
  refData = PortvisitUtils;

  consignmentProcess: IE3ConsignmentProcess;
  houseConsignment: IE3ConsignmentHouseLevel;
  _goodsItem: IE3GoodsItem;
  goodsItemIndex: number;
  data: GoodsConsignmentDetailsComponentData;
  isNewGoodsItem: boolean;
  editMode: boolean;
  equipmentListSummaries: EquipmentWithPlacement[];
  isNewConsignment: boolean;

  @ViewChild("subModalContainer", {read: ViewContainerRef}) subModalContainer: ViewContainerRef;
  showSubModal: boolean = false;
  private readonly registration: () => void;

  constructor(private eventGateway: EventGateway, private elementRef: ElementRef) {
    this.registration = this.eventGateway.registerLocalHandler(this);
  }

  ngOnInit(): void {
    this.isNewConsignment = !this.data.consignmentProcessId && !this.data.cachedConsignmentProcess?.consignmentProcessId;
    if (this.data.cachedConsignmentProcess) {
      this.setConsignment(this.data.cachedConsignmentProcess, true);
    } else {
      sendQuery("com.portbase.bezoekschip.common.api.consignments.queries.GetConsignment", <IE3GetConsignment>{
        consignmentProcessId: this.data.consignmentProcessId
      }, {caching: false}).subscribe((c: IE3ConsignmentProcess) => this.setConsignment(c, true));
    }
  }

  ngOnDestroy() {
    this.registration();
  }

  set goodsItem(goodsItem: IE3GoodsItem) {
    if (goodsItem != null) {
      this._goodsItem = goodsItem
    } else {
      this._goodsItem = this.createGoodsItem();
      if (this.isEditable) {
        this.isNewGoodsItem = true;
        this.editMode = true;
      }
    }
    this.updateEquipmentSummaries();
  }

  get goodsItem(): IE3GoodsItem {
    return this._goodsItem;
  }

  goodsItemsOfConsignment = (): IE3GoodsItem[] => {
    return this.houseConsignment ? this.houseConsignment.goodsItems : this.consignmentProcess.consignmentMasterLevel.goodsItems;
  }

  remove = () => {
    openConfirmationModalWithCallback((confirmed) => {
      if (confirmed) {
        if (this.houseConsignment) {
          this.houseConsignment.goodsItems = this.houseConsignment.goodsItems
            .filter(g => g.goodsItemNumber !== this._goodsItem.goodsItemNumber);
        } else {
          this.consignmentProcess.consignmentMasterLevel.goodsItems = this.consignmentProcess.consignmentMasterLevel.goodsItems
            .filter(g => g.goodsItemNumber !== this._goodsItem.goodsItemNumber);
        }
        this.elementRef.nativeElement.dispatchEvent(new Event("change", { bubbles: true, cancelable: true }));
        this.openConsignment();
      }
    }, ModalConfirmAutofocus, <ModalConfirmAutofocusData>{
      type: "danger",
      title: "Remove goods item",
      message: this.houseConsignment
        ? `You are about to remove goods item: ${this.goodsItem.commodity?.descriptionOfGoods} from house B/L ${this.houseConsignment.consignmentNumber}`
        : `You are about to remove goods item: ${this.goodsItem.commodity?.descriptionOfGoods} from master B/L ${this.consignmentProcess.consignmentMasterLevel.consignmentNumber}`,
      question: "Are you sure that you want to remove this goods item?",
      confirmText: "Yes",
      cancelText: "No"
    }, 'static');
  }

  toggleEdit = () => {
    this.editMode = !this.editMode && !this.consignmentProcess.cancelled;
  }

  saveOrDeclare = () => {
    const goodsItems = this.houseConsignment ? this.houseConsignment.goodsItems
      : this.consignmentProcess.consignmentMasterLevel.goodsItems;
    if (!goodsItems[this.goodsItemIndex]) {
      this.goodsItemIndex = goodsItems.push(this.goodsItem) - 1;
    } else {
      goodsItems[this.goodsItemIndex] = this.goodsItem;
    }
    if (this.isValid && this.processModel(this.hasBeenDeclared)) {
      if (this.hasBeenDeclared) {
        if (this.allowedToDeclare) {
          ConsignmentUtils.declareConsignment(this.consignmentProcess);
        } else {
          this.toggleEdit();
        }
      } else {
        sendCommand("com.portbase.bezoekschip.common.api.consignments.commands.SaveConsignment", <IE3SaveConsignment>{
          consignmentProcessId: this.consignmentProcess.consignmentProcessId,
          consignment: this.consignmentProcess.consignmentMasterLevel,
          filing: this.consignmentProcess.filing
        }, () => {
          formDataSaved();
          this.toggleEdit();
        });
      }
    }
  }

  get allowedToDeclare(): boolean {
    return ConsignmentRules.isAllowedToDeclare(this.consignmentProcess);
  }

  get hasBeenDeclared(): boolean {
    return ConsignmentUtils.hasBeenDeclared(this.consignmentProcess);
  }

  get isValid(): boolean {
    return checkValidity(this.elementRef);
  }

  processModel = (registerErrors: boolean) => ConsignmentUtils.validateGoodsItem(
    this.consignmentProcess, this.goodsItem, registerErrors, this.houseConsignment);

  private openConsignment() {
    if (this.houseConsignment) {
      this.openHouseConsignment();
    } else {
      this.openMasterConsignment();
    }
  }

  processModelAndOpenMasterConsignment = () => {
    this.processModel(false);
    this.openMasterConsignment();
  }

  private openMasterConsignment = () => {
    this.registration();
    publishEvent('cachedConsignmentUpdated', this.consignmentProcess);
    openEditModal(MasterConsignmentDetailsComponent, <MasterConsignmentDetailsComponentData>{
      consignmentProcessId: this.consignmentProcess.consignmentProcessId,
      cachedConsignmentProcess: this.consignmentProcess
    }, {
      warnOnClose: true,
      currentToStack: true
    });
  }

  processModelAndOpenHouseConsignment = () => {
    this.processModel(false);
    this.openHouseConsignment();
  }

  private openHouseConsignment = () => {
    publishEvent('cachedConsignmentUpdated', this.consignmentProcess);
    openEditModal(HouseConsignmentDetailsComponent, <HouseConsignmentDetailsComponentData>{
      consignmentProcessId: this.consignmentProcess.consignmentProcessId,
      consignmentNumber: this.houseConsignment.consignmentNumber,
      cachedConsignmentProcess: this.consignmentProcess
    }, {
      warnOnClose: true,
      currentToStack: true
    });
  }

  private createGoodsItem = (): IE3GoodsItem => ({
    goodsItemNumber: this.goodsItemsOfConsignment().length + 1,
    innerPackagingList: [],
    passiveBorderTransportMeans: [],
    goodsPlacements: this.data?.equipmentNumber ? [{
      containerIdentificationNumber: this.data.equipmentNumber
    }] : [],
    outerPackaging: {},
    commodity: {}
  })

  get isEditable() {
    return ConsignmentUtils.isEditable(this.consignmentProcess);
  }

  get bulkDischargeWeight() {
    const bulkDischarges = this.consignmentProcess.bulkDischarges || {};
    return bulkDischarges[this.goodsItem.goodsItemNumber]?.grossWeight;
  }

  addInnerPackage = () => this.goodsItem.innerPackagingList.push({
    shippingMarks: []
  });

  deleteInnerPackage = (item: IE3Packaging) => removeItem(this.goodsItem.innerPackagingList, item);

  addSupportingDocument = () => this.goodsItem.supportingDocuments.push({});

  deleteSupportingDocument = (item: IE3SupportingDocument) => removeItem(this.goodsItem.supportingDocuments, item);

  private setConsignment = (c: IE3ConsignmentProcess, updateEditMode?: boolean) => {
    this.consignmentProcess = c;
    if (updateEditMode && !ConsignmentUtils.hasBeenDeclared(c) && this.isEditable) {
      this.editMode = true;
    }
    this.houseConsignment = this.data.cachedHouseConsignment || c.consignmentMasterLevel.consignmentsHouseLevel
      .find(h => h.consignmentNumber === this.data.houseConsignmentNumber);
    const goodsItems = this.goodsItemsOfConsignment();
    this.goodsItem = goodsItems.find(g => g.goodsItemNumber === this.data.goodsItemNumber);
    this.goodsItemIndex = this.goodsItem ? goodsItems.indexOf(this.goodsItem) : goodsItems.length;
    this.goodsItem.supportingDocuments = this.goodsItem.supportingDocuments || [];
    this.updateEquipmentSummaries();
  }

  private updateEquipmentSummaries() {
    this.equipmentListSummaries = Object.entries(this.consignmentProcess.consignmentMasterLevel.transportEquipmentMap)
      .filter(([containerIdentificationNumber, equipment]) =>
        this.goodsItem.goodsPlacements && this.goodsItem.goodsPlacements.some(g => g.containerIdentificationNumber === containerIdentificationNumber))
      .map(([containerIdentificationNumber, equipment]) =>
        ConsignmentUtils.getContainerWithPlacement(equipment, this.goodsItem));
  }

  "openConsignmentSubModal" = (event: ConsignmentSubModalEvent) => {
    this.subModalContainer.clear();
    if (event.modalContent) {
      this.subModalContainer.createEmbeddedView(event.modalContent);
    }
    this.showSubModal = true;
  }

  "closeConsignmentSubModal" = () => {
    this.showSubModal = false;
    this.editMode = true;
  }
}

export interface GoodsConsignmentDetailsComponentData {
  consignmentProcessId: string;
  houseConsignmentNumber: string;
  goodsItemNumber: number;
  equipmentNumber: string;
  cachedConsignmentProcess: IE3ConsignmentProcess;
  cachedHouseConsignment: IE3ConsignmentHouseLevel;
}
