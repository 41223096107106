<div class="border-bottom container-fluid mw-100">
  <div class="row p-3 gx-3 main-row" *ngIf="summary">
    <div class="col-auto d-none d-md-none d-lg-block d-vlg-none d-xl-block">
      <button *ngIf="summary.vesselImageUrl" class="hover-show position-relative" type="button"  data-bs-toggle="modal"  [attr.data-bs-target]="'#vesselImageModal' + summary.crn"
              style="background-color: transparent; border-width: 0; padding: 0;">
        <img src="{{summary.vesselImageUrl}}" width="102" height="68" alt="Vessel image" class="rounded-1"/>
        <img src="/assets/new-portvisit/zoom.svg" width="30px" alt="zoom" class="to-show rounded-1 position-absolute end-0 bottom-0"/>
      </button>
      <img *ngIf="!summary.vesselImageUrl" src="/assets/new-portvisit/no-vessel-image.svg" width="102" height="68" alt="Vessel image" class="rounded-1"/>

      <!-- Modal for the vessel image -->
      <div class="modal" [id]="'vesselImageModal' + summary.crn" tabindex="-1" role="dialog" aria-labelledby="vesselImageModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document" style="--bs-modal-width: fit-content; top:0;">
          <div class="modal-content border-0">
            <div class="modal-body p-0">
              <button type="button" class="btn btn-close btn-close-white btn-plain" data-bs-dismiss="modal" aria-label="Close"
                      style="background-color: transparent; border-width: 0; position:fixed; top:20px; right:20px;">
              </button>
              <img style="max-height:90vh; max-width:90vw" src="{{summary.vesselImageUrl || '/assets/new-portvisit/no-vessel-image.svg'}}" alt="Vessel image">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col col-vlg-3 col-xxl-2">
      <a [href]="navigationUrl">
        <span class="h2 text-info">
          <app-highlight [term]="term" [result]="summary.vesselName | sentenceCase"></app-highlight>
        </span>
        <span class="h2 text-info d-none d-lg-inline"> - </span>
        <span class="h2 text-info d-none d-lg-inline"><app-highlight [term]="term" [result]="summary.imoCode"></app-highlight></span>
      </a>
      <app-tooltip *ngIf="summary.tidalShip" placement="top" class="ms-2">
        <span class="fa fa-fw fa-water-arrow-down text-info"></span>
        <span class="tooltipContent">This ship is tide restricted</span>
      </app-tooltip>
      <app-tooltip *ngIf="summary.dependencyType === 'EXCHANGE'" placement="top" class="ms-2">
        <span class="fa fa-light fa-fw fa-arrow-right-arrow-left text-info mx-1"></span>
        <span class="tooltipContent">Exchanges with <span class="fw-medium">{{summary.dependentVesselName | titlecase}}</span></span>
      </app-tooltip>
      <app-tooltip *ngIf="!summary.hasBeenTransferred && summary.nextDeclarantShortName && (summary.declarantShortName === appContext.userProfile.organisation.shortName || appContext.isAdmin())" placement="top" class="ms-2">
        <span class="fa fa-light fa-fw fa-people-arrows text-info mx-1"></span>
        <span class="tooltipContent">Handover to <span class="fw-medium">{{summary.nextDeclarant}}</span></span>
      </app-tooltip>
      <app-tooltip *ngIf="!summary.hasBeenTransferred && summary.nextDeclarantShortName && summary.nextDeclarantShortName === appContext.userProfile.organisation.shortName" placement="top" class="ms-2">
        <span class="fa fa-light fa-fw fa-people-arrows text-info mx-1"></span>
        <span class="tooltipContent">Handover from <span class="fw-medium">{{summary.declarant}}</span></span>
      </app-tooltip>
      <app-tooltip *ngIf="summary.hasBeenTransferred && summary.originalDeclarantShortName !== summary.declarantShortName && summary.declarantShortName === appContext.userProfile.organisation.shortName" placement="top" class="ms-2">
        <span class="fa fa-light fa-fw fa-arrow-right text-info mx-0" style="margin-right: -5px !important;"></span>
        <span class="fa fa-light fa-fw fa-person text-info mx-0"></span>
        <span class="tooltipContent">Handed over from <span class="fw-medium">{{summary.originalDeclarant}}</span></span>
      </app-tooltip>
      <app-tooltip *ngIf="summary.cancelled" placement="top" class="ms-2">
        <span class="fa text-danger fa-fw fa-ban"></span>
        <span class="tooltipContent">Cancelled</span>
      </app-tooltip>
      <div class="d-flex flex-row align-items-stretch">
        <div class="d-flex flex-column" style="min-width: 130px; max-width: 130px;">
          <span class="d-inline-block text-truncate mw-100">
            <app-highlight [term]="term" [result]="summary.crn"></app-highlight>
          </span>
          <span class="d-inline-block text-truncate mw-100">
            <app-highlight [term]="term" [result]="summary.arrivalVoyageNumber"></app-highlight>
          </span>
        </div>
        <div class="d-none flex-column d-lg-flex" style="min-width: 0" *ngIf="summary.vessel">
        <span class="d-inline-block text-truncate mw-100 me-2">{{summary.vessel.length | round}}
          x {{summary.vessel.width | round}} m</span>
          <span class="d-inline-block text-truncate mw-100">{{summary.vessel.grossTonnage | round}} t</span>
        </div>
      </div>
    </div>

    <div class="col-3 d-none d-vlg-block col-vlg col-xxl-3 movement-section">
      <div class="row h-100 d-flex align-items-stretch">
        <div class="col-12 d-flex align-items-start" [ngClass]="(summary.ataPort ? ' arrived ' : ' expected ') + (entryDate ? '' : ' text-muted ')">
          <div class="row gx-2 w-100">
            <i class="col-auto movement-icon fa fa-solid fa-circle-arrow-right"></i>
            <span class="col-auto arrival-date">{{formatDate(entryDate)}}</span>
            <span class="col text-truncate"><app-highlight [term]="term"
                                                           [result]="entryPointName | sentenceCase"></app-highlight></span>
          </div>
        </div>
        <div class="col-12 d-flex align-items-center" *ngIf="summary.currentBerthVisit"
             [ngClass]="(summary.currentBerthVisit.ata ? ' arrived ' : ' expected ')">
          <div class="row gx-2 w-100">
            <ng-container *ngIf="summary.visitStatus === 'ARRIVED' else normalIcon">
              <div class="col-auto movement-icon" [ngClass]="(hasNextDate(summary.currentBerthVisit) ? '' : ' text-muted ')">
                <div class="berth-visit-arrived"></div>
              </div>
            </ng-container>
            <ng-template #normalIcon>
              <i class="col-auto movement-icon fa fa-solid fa-circle-dot" [ngClass]="(hasNextDate(summary.currentBerthVisit) ? '' : ' text-muted ')"></i>
            </ng-template>
            <span class="col-auto arrival-date" [ngClass]="(hasNextDate(summary.currentBerthVisit) ? '' : ' text-muted ')">{{formatDate(getNextDate(summary.currentBerthVisit))}}</span>
            <div class="col position-relative">
              <div class="position-absolute d-flex align-items-center h-100 w-100 pe-2">
              <span class="me-2 text-truncate" style="min-width: 0" [ngClass]="(hasNextDate(summary.currentBerthVisit) ? '' : ' text-muted ')">
                <app-highlight [term]="term"
                               [result]="getBerthVisitName(summary.currentBerthVisit) | sentenceCase"></app-highlight>
              </span>
              <button onPopover *ngIf="summary.berthVisitSearches.length > 1"
                      class="btn btn-outline-info p-1 px-2" style="min-width: 33px"
                      data-bs-toggle="popover" [popoverContent]="berthVisitsPopover" [placement]="'top'"
                      aria-describedby="tooltip" popoverClass="pop-auto-container position-fixed" [popoverData]="summary">
                <span class="text-primary">+{{summary.berthVisitSearches.length - 1}}</span>
              </button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 d-flex align-items-end" [ngClass]="(summary.atdPort ? ' arrived ' : ' expected ') + (exitDate ? '' : ' text-muted ')">
          <div class="row gx-2 w-100">
            <i class="col-auto movement-icon fa fa-solid fa-circle-arrow-left"
               [ngClass]="summary.berthVisitSearches.length === 0 ? 'passing-through' : ''"></i>
            <span class="col-auto arrival-date">{{formatDate(exitDate)}}</span>
            <span class="col text-truncate">
            <app-highlight [term]="term" [result]="exitPointName | sentenceCase"></app-highlight>
          </span>
          </div>
        </div>
      </div>
    </div>

    <div class="col-xxl d-none d-xxl-flex align-items-center justify-content-end flex-column" [style.min-width.px]="150">
      <div class="row gy-2 gx-1 mw-100">
        <div class="col-auto">
          <app-tooltip style="line-height: 1.5rem;">
            <i class="fa-light fa-user"></i>
            <span class="tooltipContent">Last edited by this user from your company</span>
          </app-tooltip>
          <div></div>
        </div>
        <div class="col d-flex flex-column" style="min-width: 0">
        <span class="d-inline-block text-truncate mw-100">
          <app-highlight [term]="term"
                         [result]="summary.lastEditedByUser?.name || summary.lastEditedByUser?.orgName || 'Not available'"></app-highlight>
        </span>
          <span
            class="d-inline-block text-truncate mw-100">{{summary.lastEditedByUser ? formatDate(summary.lastEditedByUser.timestamp) : 'Not available'}}</span>
        </div>
      </div>
    </div>

    <div class="col-auto ms-auto">
      <div class="row gy-2 gx-3">
        <div class="col-auto d-flex flex-column status-column status-column-left">
          <div class="row g-0 gx-2">
            <div class="col-auto d-flex flex-column">
              <span (mouseover)="hoverStatusIndicatorSpan('spanPortAuthority' + summary.crn, true)"
                    (mouseout)="hoverStatusIndicatorSpan('spanPortAuthority' + summary.crn, false)"
                    id="spanPortAuthority{{summary.crn}}"
                    class="d-none d-sm-inline-block text-start span-cursor" [style.line-height.rem]="1.5" (click)="popupStatusIndicator('buttonPortAuthority' + summary.crn)">Port Authority</span>
              <span (mouseover)="hoverStatusIndicatorSpan('spanCustoms' + summary.crn, true)"
                    (mouseout)="hoverStatusIndicatorSpan('spanCustoms' + summary.crn, false)"
                    id="spanCustoms{{summary.crn}}"
                    class="d-none d-sm-inline-block text-start span-cursor" [style.line-height.rem]="1.5" (click)="popupStatusIndicator('buttonCustoms' + summary.crn)">Customs</span>
              <span (mouseover)="hoverStatusIndicatorSpan('spanTerminal' + summary.crn, true)"
                    (mouseout)="hoverStatusIndicatorSpan('spanTerminal' + summary.crn, false)"
                    id="spanTerminal{{summary.crn}}" *ngIf="utils.getLeastTerminalStatus(summary, clearanceService) !== 'DISABLED'"
                    class="d-none d-sm-inline-block text-start span-cursor" [style.line-height.rem]="1.5" (click)="popupServiceStatus('buttonTerminal' + summary.crn)">Terminal</span>
            </div>
            <div class="col d-flex flex-column">
              <app-visit-overview-service-status
                [summary]="summary"
                [declarationTypes]="['VISIT', 'HEALTH', 'SECURITY', 'WASTE', 'DANGEROUS_GOODS']"
                [statusIndicatorButtonId]="'buttonPortAuthority' + summary.crn"
                [statusIndicatorSpanId]="'spanPortAuthority' + summary.crn">
              </app-visit-overview-service-status>
              <app-visit-overview-service-status
                [summary]="summary"
                [declarationTypes]=declarationTypesConsideringICS2()
                [inspectionStatus]="true"
                [statusIndicatorButtonId]="'buttonCustoms' + summary.crn"
                [statusIndicatorSpanId]="'spanCustoms' + summary.crn">
              </app-visit-overview-service-status>
              <app-terminal-visit-overview-service-status
                *ngIf="utils.getLeastTerminalStatus(summary, clearanceService) !== 'DISABLED'"
                [summary]="summary"
                [serviceStatusButtonId]="'buttonTerminal' + summary.crn"
                [serviceStatusSpanId]="'spanTerminal' + summary.crn">
              </app-terminal-visit-overview-service-status>
            </div>
          </div>
        </div>
        <div class="col-auto d-flex flex-column status-column status-column-right">
          <div class="row g-0 gx-2">
            <div class="col-auto d-flex flex-column">
              <span (mouseover)="hoverStatusIndicatorSpan('spanServices' + summary.crn, true)"
                    (mouseout)="hoverStatusIndicatorSpan('spanServices' + summary.crn, false)"
                    id="spanServices{{summary.crn}}" *ngIf="!summary.portUnCode || portVisitUtils.areNauticalServicesApplicable(summary.portUnCode)"
                    class="d-none d-sm-inline-block text-start span-cursor" [style.line-height.rem]="1.5" (click)="popupStatusIndicator('buttonServices' + summary.crn)">Services</span>
              <span (mouseover)="hoverStatusIndicatorSpan('spanBorderPatrol' + summary.crn, true)"
                    (mouseout)="hoverStatusIndicatorSpan('spanBorderPatrol' + summary.crn, false)"
                    id="spanBorderPatrol{{summary.crn}}"
                    class="d-none d-sm-inline-block text-start span-cursor" [style.line-height.rem]="1.5" (click)="popupStatusIndicator('buttonBorderPatrol' + summary.crn)">Border patrol</span>
            </div>
            <div class="col d-flex flex-column">
              <app-visit-overview-service-status *ngIf="!summary.portUnCode || portVisitUtils.areNauticalServicesApplicable(summary.portUnCode)"
                                                 [summary]="summary"
                                                 [statusIndicatorButtonId]="'buttonServices' + summary.crn"
                                                 [statusIndicatorSpanId]="'spanServices' + summary.crn"
                                                 [orderStatus]="true"></app-visit-overview-service-status>
              <app-visit-overview-service-status [summary]="summary"
                                                 [statusIndicatorButtonId]="'buttonBorderPatrol' + summary.crn"
                                                 [statusIndicatorSpanId]="'spanBorderPatrol' + summary.crn"
                                                 [declarationTypes]="['PAX']"></app-visit-overview-service-status>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-auto d-flex flex-column align-items-center dropdown px-2">
      <a [href]="navigationUrl" class="btn rounded-circle p-2 text-primary h1 mb-0" type="button" [style.margin-top.px]="-10">
        <i class="fa-light fa-fw fa-angle-right"></i>
      </a>
      <button class="btn rounded-circle p-2 text-primary h1 mt-0" type="button" [style.margin-bottom.px]="-10"
              data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <i class="fa-light fa-fw fa-ellipsis-vertical"></i>
      </button>
      <div class="dropdown-menu position-fixed">
        <button *ngIf="appContext.isAdmin()" class="btn dropdown-item" (click)="tryRefreshVisitSummary()">
          <i class="fa-light fa-arrows-rotate"></i>
          Refresh visit summary
        </button>
        <a [href]="'https://www.marinetraffic.com/en/ais/details/ships/imo:'+summary.imoCode"
           class="btn dropdown-item">
          <i class="fa-light fa-arrow-up-right-from-square"></i>
          View in MarineTraffic
        </a>
        <ng-container *ngIf="isOrganisationCurrentVisitDeclarant() && !summary.cancelled && expected">
          <hr class="dropdown-divider">
          <button class="btn dropdown-item" (click)="cancelVisit()">
            <i class="fa-light fa-ban"></i>
            Cancel visit
          </button>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<ng-template #berthVisitsPopover let-data="$implicit">
  <div class="movement-section text-nowrap p-1 pe-4 flex-row" [style.max-width.px]="320">
    <div class="btn-close-wrapper rounded-circle position-absolute end-0 top-0 mt-2 me-2">
      <button type="button" class="btn-close" data-bs-toggle="popover" aria-label="Close"></button>
    </div>
    <div class="row flex-column">
      <ng-container *ngFor="let berthVisit of data.berthVisitSearches; let i = index">
        <div class="col-12" [ngClass]="berthVisit.ata ? 'arrived' : 'expected'">
          <div class="row gx-2 flex-nowrap" [ngClass]="(hasNextDate(berthVisit) ? '' : ' text-muted ')">
            <ng-container *ngIf="getBerthVisitStatus(berthVisit) === 'ARRIVED' else normalIcon">
              <div class="col-auto movement-icon" [ngClass]="i === 0 ? 'no-pseudo': ''">
                <div class="berth-visit-arrived"></div>
              </div>
            </ng-container>
            <ng-template #normalIcon>
              <i class="col-auto movement-icon fa fa-solid fa-circle-dot" [ngClass]="i === 0 ? 'no-pseudo': ''"></i>
            </ng-template>
            <span class="col-auto arrival-date">{{formatDate(getNextDate(berthVisit))}}</span>
            <div class="col text-truncate">
              <span>{{getBerthVisitName(berthVisit) | sentenceCase}}</span>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</ng-template>
