import {
  ArrayTemplate,
  DateField,
  MappedField,
  parseExcel,
  RequiredField,
  ValidatedField,
  WorkBookTemplate
} from '../../common/upload/excel.utils';

import {
  Gender,
  HealthDeclaration,
  PersonJoinedVessel,
  PortOfVoyage,
  RecoveredIllOrDeadPerson
} from '@portbase/bezoekschip-service-typescriptmodels';
import {Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {cloneObject, sendQuery} from '../../common/utils';
import {AppContext} from '../../app-context';
import {VisitContext} from '../visit-context';

export function uploadHealthDeclaration(excelFile: File): Observable<HealthDeclaration[]> {
  return parseExcel(excelFile, healthDeclarationTemplate).pipe(map(model => {
    const result = [
      model,
      <HealthDeclaration>{
        vesselMaster: {
          name: model.vesselMasterName,
          email: model.vesselMasterEmail,
          phoneNumber: model.vesselMasterPhoneNumber,
          truthfullyDeclared: model.vesselMasterTruthfullyDeclared?.toUpperCase() === 'YES',
          date: model.vesselMasterDeclarationDate
        },
        vesselSurgeon: {
          name: model.vesselSurgeonName,
          email: model.vesselSurgeonEmail,
          phoneNumber: model.vesselSurgeonPhoneNumber,
          truthfullyDeclared: model.vesselSurgeonTruthfullyDeclared ? model.vesselSurgeonTruthfullyDeclared?.toUpperCase() === 'YES' : model.vesselSurgeonTruthfullyDeclared,
          date: model.vesselSurgeonDeclarationDate
        },
        additionalContact: {
          name: model.additionalContactName,
          email: model.additionalContactEmail,
          phoneNumber: model.additionalContactPhoneNumber
        },
        arrivingFrom: model.arrivingFrom,
        sailingTo: model.sailingTo,
        numberOfCrew: model.numberOfCrew,
        numberOfPassengers: model.numberOfPassengers,
        generalQuestions: {
          hasSanitationCertificateOnBoard: {
            answer: model.hasSanitationCertificateOnBoard?.toUpperCase() === 'YES',
            additionalInfo: {
              issuedAt: model.sanitationCertificatePlace,
              date: model.sanitationCertificateDate
            }
          },
          reinspectionRequired: {
            answer : model.reinspectionRequired?.toUpperCase() === 'YES'
          },
          vesselVisitedAffectedArea: {
            answer: model.vesselVisitedAffectedArea?.toUpperCase() === 'YES',
            additionalInfo: {
              port: model.affectedAreaPort,
              date: model.affectedAreaDate
            }
          }
        },
        healthQuestions: {
          nonAccidentalDeathOfPersonOnBoard: {
            answer: typeof model.personDiedOnBoard?.toUpperCase === "function" &&
              model.personDiedOnBoard?.toUpperCase() === 'YES',
            additionalInfo: {
              totalNumberOfDeaths: model.totalNumberOfDeaths
            }
          },
          caseOfInfectiousDiseaseOnBoard: {
            answer: model.caseOfInfectiousDiseaseOnBoard?.toUpperCase() === 'YES'
          },
          illPassengersOnBoardIsGreaterThanNormal: {
            answer: model.illPassengersOnBoardGreaterThanNormal?.toUpperCase() === 'YES',
            additionalInfo: {
              numberOfIllPeople: model.numberOfIllPeople
            }
          },
          illPersonOnBoardNow: {
            answer: model.illPersonOnBoardNow?.toUpperCase() === 'YES'
          },
          medicalPractitionerConsulted: {
            answer: model.medicalPractitionerConsulted?.toUpperCase() === 'YES'
          },
          awareOfConditionOnBoardWhichMayLeadToInfection: {
            answer: model.awareOfConditionOnBoardWhichMayLeadToInfection?.toUpperCase() === 'YES'
          },
          sanitaryMeasuresApplied: {
            answer: model.sanitaryMeasureApplied?.toUpperCase() === 'YES',
            additionalInfo: {
              type: model.sanitaryMeasureType,
              place: model.sanitaryMeasurePlace,
              date: model.sanitaryMeasureDate
            }
          },
          stowawaysOnBoard: {
            answer: model.stowawayFoundOnBoard?.toUpperCase() === 'YES',
            additionalInfo: {
              joinedAt: model.stowawayPlaceOfJoin
            }
          },
          sickAnimalsOnBoard: {
            answer: model.sickAnimalsOnBoard?.toUpperCase() === 'YES'
          }
        },
        recoveredIllOrDeadPeople: model.recoveredIllOrDeadPeople.map(p => convertRecoveredIllOrDeadPerson(p)),
        remarks: model.remarks,
        peopleJoinedVessel: model.peopleJoinedVessel.map(p => convertPersonJoinedVessel(p)),
        portsFromCommencementOfVoyage: model.portsFromCommencementOfVoyage.map(p => convertPortsOfVoyage(p))
      }
    ];
    return AppContext.hasErrors() ? [null, null] : result;
  }));
}

function convertRecoveredIllOrDeadPerson(recoveredIllOrDeadPerson: any): RecoveredIllOrDeadPerson {
  const result: RecoveredIllOrDeadPerson = cloneObject(recoveredIllOrDeadPerson);
  result.nationality = recoveredIllOrDeadPerson.nationality;
  result.reportedToMedicalOfficer = recoveredIllOrDeadPerson.reportedToMedicalOfficer?.toUpperCase() === 'YES';
  result.gender = convertGender(recoveredIllOrDeadPerson.gender);
  return result;
}

function convertPersonJoinedVessel(personJoinedVessel: any): PersonJoinedVessel {
  const result: PersonJoinedVessel = cloneObject(personJoinedVessel);
  result.joinedVesselAtPorts = personJoinedVessel.joinedVesselAtPorts.filter(p => p);
  return result;
}

function convertPortsOfVoyage(portOfVoyage: any): PortOfVoyage {
  return cloneObject(portOfVoyage);
}

const healthDeclarationTemplate: WorkBookTemplate = {
  sheets: [
    {
      name: 'General',
      template: {
        version: new ValidatedField(new RequiredField('B6'),
        value => {
          if (value !== '1.0' && value !== '1.1') {
            throw 'The version of your Excel file is not supported. Please download the latest template and try again.';
          }
        })
      }
    },
    {
      name: 'Maritime declaration of health',
      template: {
        submittedAtThePortOf: new ValidatedField(new RequiredField('C5'), sizeValidator(5, 5)),
        shipName: new ValidatedField(new RequiredField('C6'), sizeValidator(0, 200)),
        nationality: new ValidatedField(new RequiredField('C7'), sizeValidator(0, 100)),
        grossTonnage: new ValidatedField(new RequiredField('C8'), sizeValidator(0, 100)),
        date: new RequiredField(new DateField('G5')),
        imoNumber: new ValidatedField(new RequiredField('G6'),
        value => {
          const expectedImo = VisitContext.visit.vessel.imoCode;
          if (String(value) !== expectedImo) {
            throw 'The IMO code in the Excel file (' + value + ') does not match IMO code of the vessel (' + expectedImo + ').';
          }
        }),
        mastersName: new ValidatedField(new RequiredField('G7'), sizeValidator(0, 100)),
        arrivingFrom: getRequiredPortField('K6'),
        sailingTo: getRequiredPortField('O6'),
        hasSanitationCertificateOnBoard: new ValidatedField(new RequiredField('G10'), yesNoValidator),
        sanitationCertificatePlace: 'K10',
        sanitationCertificateDate: new DateField('O10'),
        reinspectionRequired: new ValidatedField(new RequiredField('G11'), yesNoValidator),
        vesselVisitedAffectedArea: new ValidatedField(new RequiredField('G12'), yesNoValidator),
        affectedAreaPort: 'K12',
        affectedAreaDate: new DateField('O12'),
        numberOfCrew: new ValidatedField(new RequiredField('C46'), numberValidator),
        numberOfPassengers: new ValidatedField(new RequiredField('G46'), numberValidator),
        personDiedOnBoard: new ValidatedField(new RequiredField('K49'), yesNoValidator),
        totalNumberOfDeaths: new ValidatedField('K50', numberValidator),
        caseOfInfectiousDiseaseOnBoard: new ValidatedField(new RequiredField('K51'), yesNoValidator),
        illPassengersOnBoardGreaterThanNormal: new ValidatedField(new RequiredField('K52'), yesNoValidator),
        numberOfIllPeople: new ValidatedField('K53', numberValidator),
        illPersonOnBoardNow: new ValidatedField(new RequiredField('K54'), yesNoValidator),
        medicalPractitionerConsulted: new ValidatedField(new RequiredField('K55'), yesNoValidator),
        awareOfConditionOnBoardWhichMayLeadToInfection: new ValidatedField(new RequiredField('K56'), yesNoValidator),
        sanitaryMeasureApplied: new ValidatedField(new RequiredField('K57'), yesNoValidator),
        sanitaryMeasureType: 'C58',
        sanitaryMeasurePlace: 'G58',
        sanitaryMeasureDate: new DateField('K58'),
        stowawayFoundOnBoard: new ValidatedField(new RequiredField('K59'), yesNoValidator),
        stowawayPlaceOfJoin: 'K60',
        sickAnimalsOnBoard: new ValidatedField(new RequiredField('K61'), yesNoValidator),
        vesselMasterName: new ValidatedField(new RequiredField('C70'), sizeValidator(0, 150)),
        vesselMasterEmail: new ValidatedField(new RequiredField('C71'), emailValidator),
        vesselMasterPhoneNumber: new ValidatedField(new RequiredField('C72'), sizeValidator(0, 150)),
        vesselMasterTruthfullyDeclared: new ValidatedField(new RequiredField('K69'), yesValidator),
        vesselMasterDeclarationDate: new RequiredField(new DateField('O69')),
        vesselSurgeonName: new ValidatedField('C76', sizeValidator(0, 150)),
        vesselSurgeonEmail: new ValidatedField('C77', emailValidator),
        vesselSurgeonPhoneNumber: new ValidatedField('C78', sizeValidator(0, 150)),
        vesselSurgeonTruthfullyDeclared: new ValidatedField('K75', yesValidator),
        vesselSurgeonDeclarationDate: new DateField('O75'),
        additionalContactName: 'C81',
        additionalContactEmail: new ValidatedField('C82', emailValidator),
        additionalContactPhoneNumber: new ValidatedField('C83', sizeValidator(0, 150)),
        peopleJoinedVessel: new ArrayTemplate(computePeopleJoinedVesselTemplate(arrayTemplate => {
          return arrayTemplate;
        }), [25, 44]),
        portsFromCommencementOfVoyage: new ArrayTemplate(computePortsFromCommencementOfVoyage(arrayTemplate => {
          return arrayTemplate;
        }), [16, 20])
      }
    },
    {
      name: 'Attachment',
      template: {
        recoveredIllOrDeadPeople: new ArrayTemplate(computePatientTemplate(arrayTemplate => {
          return arrayTemplate;
        }), [4, 24]),
        remarks: new ValidatedField('A27', sizeValidator(0, 1000))
      }
    },
    {
      name: 'Verificatie',
      template: {
        verificationHash: new ValidatedField(new RequiredField('A1'),
          value => {
            if (value !== '842d09dce8a21e90645c8e69d598db4df781e0d7') {
              throw 'Your Excel file could not be verified. Please download the latest template and try again.';
            }
          })
      }
    }
  ]
};

function computePatientTemplate(mapper: (template) => any) {
  const patientTemplate: any = {
    name: new ValidatedField(new RequiredField('A$'), sizeValidator(0, 70)),
    jobTitleOrRank: new RequiredField('B$'),
    dateOfBirth: new RequiredField(new DateField('C$')),
    gender: new ValidatedField(new RequiredField('D$'), genderValidator),
    nationality: new RequiredField('E$'),
    joinedVesselAtPort: new MappedField(new RequiredField('F$'), (portUnCode, cell) => {
      return portUnCode ? sendQuery('com.portbase.bezoekschip.common.api.visit.GetPortOrWayPoint',
        {portUnCode: portUnCode}).pipe(tap(v => {
        if (!v) {
          throw 'Cell ' + cell.cell + ' in sheet "' + cell.sheetName + '" contains an unknown port: ' + portUnCode;
        }
      })) : null;
    }),
    joinedVesselAtDate: new RequiredField(new DateField('G$')),
    natureOfIllness: new RequiredField('H$'),
    dateOnsetOfSymptoms: new RequiredField(new DateField('J$')),
    reportedToMedicalOfficer: new RequiredField('K$'),
    disposalOfCase: new ValidatedField(new RequiredField('L$'), disposalOfCaseValidator),
    treatmentGiven: new RequiredField('M$'),
    comments: new ValidatedField('N$', sizeValidator(0, 150))
  };

  return mapper(cloneObject(patientTemplate));
}

function computePeopleJoinedVesselTemplate(mapper: (template) => any) {
  const port1 : MappedField = getPort('G$');
  const port2 : MappedField = getPort('I$');
  const port3 : MappedField = getPort('K$');

  const peopleJoinedVesselTemplate: any = {
    name: new ValidatedField(new RequiredField('B$'), sizeValidator(5, 150)),
    joinedVesselAtPorts: convertJoinedVesselAtPorts(port1, port2, port3)
  };

  return mapper(cloneObject(peopleJoinedVesselTemplate));
}

function computePortsFromCommencementOfVoyage(mapper: (template) => any) {
  const portsTemplate: any = {
    departureDate: new DateField('I$'),
    port: new MappedField('B$', (portUnCode, cell) => {
      return portUnCode ? sendQuery('com.portbase.bezoekschip.common.api.visit.GetPortOrWayPoint',
        {portUnCode: portUnCode}).pipe(tap(v => {
        if (!v) {
          throw 'Cell ' + cell.cell + ' in sheet "' + cell.sheetName + '" contains an unknown port: ' + portUnCode;
        }
      })) : null;
    })
  };

  return mapper(cloneObject(portsTemplate));
}

function yesNoValidator(value: any) {
  if (!value) {
    return;
  }
  if (!(value.toUpperCase() === 'YES' || value.toUpperCase() === 'NO')) {
    throw 'Possible values (Yes/No)';
  }
}

function yesValidator(value: any) {
  if (!value) {
    return;
  }
  if (value.toUpperCase() !== 'YES') {
    throw 'This field must be answered with Yes';
  }
}

function emailValidator(value: any) {
  if(value){
    if (value.length > 50) {
      throw 'Email: ' +  value + ' may not be more than 50 characters';
    }
    let validEmail = new RegExp("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$");
    if (!validEmail.test(value)) {
      throw 'Email: ' + value + ' format is invalid or contains invalid characters';
    }
  }
}

function phoneNumberValidator(value: any) {
  if(value){
    if (value.toString().length > 15) {
      throw 'Phone number: ' + value + ' may not be more than 15 characters';
    }
    let validPhone = new RegExp("^[0-9()（）\\s+\\-]{0,15}$");
    if (!validPhone.test(value)) {
      throw 'Phone number: ' + value + ' contains invalid characters';
    }
  }
}

function genderValidator(value: any) {
  if (!value) {
    return;
  }
  if (!(value.toUpperCase() === 'MALE' || value.toUpperCase() === 'FEMALE' || value.toUpperCase() === 'NOT KNOWN')) {
    throw 'Possible genders are Male, Female and Not known';
  }
}

function disposalOfCaseValidator(value: any) {
  if (!value) {
    return;
  }
  if (!(value.toLowerCase() === 'recovered and still on board' || value.toLowerCase() === 'recovered and evacuated'
    || value.toLowerCase() === 'ill and still on board' || value.toLowerCase() === 'ill and evacuated'
    || value.toLowerCase() === 'died and still on board' || value.toLowerCase() === 'died and evacuated'
    || value.toLowerCase() === 'died and buried at sea')) {
    throw 'Possible disposal of case are Recovered and still on board, Recovered and evacuated, Ill and still on board, Ill and evacuated' +
    ', Died and still on board, Died and evacuated, Died and buried at sea';
  }
}






function numberValidator(value: any) {
  if (!value) {
    return;
  }
  if (typeof value !== 'number') {
    throw 'Value must be a number';
  }
}

function sizeValidator(min, max) {
  return (value: any) => {
    if (!value) {
      return;
    }
    if (value.length < min || value.length > max) {
      throw 'Value must be between ' + min + ' and ' + max + ' characters long';
    }
  }
}

function convertGender(gender: string): Gender {
  if (gender) {
    switch (gender.toUpperCase()) {
      case 'MALE':
        return Gender.MALE;
      case 'FEMALE':
        return Gender.FEMALE;
      case 'NOT KNOWN':
        return Gender.NOT_KNOWN;
      default:
        AppContext.registerError('Unrecognized gender ' + gender);
    }
  }
}

function getPort(port: string): MappedField {
  return new MappedField(port, (portUnCode, cell) => {
    return portUnCode ? sendQuery('com.portbase.bezoekschip.common.api.visit.GetPortOrWayPoint', {portUnCode: portUnCode})
          .pipe(tap(v => {
            if (!v) {
              throw 'Cell ' + cell.cell + ' in sheet "' + cell.sheetName + '" contains an unknown port: ' + portUnCode;
            }
          })) : null;
    })
}

function getRequiredPortField(field: string): MappedField {
  return new MappedField(new RequiredField(field), (portUnCode, cell) => {
    return portUnCode ? sendQuery('com.portbase.bezoekschip.common.api.visit.GetPortOrWayPoint', {portUnCode: portUnCode})
          .pipe(tap(v => {
            if (!v) {
              throw 'Cell ' + cell.cell + ' in sheet "' + cell.sheetName + '" contains an unknown port: ' + portUnCode;
            }
          })) : null;
    })
}

function convertJoinedVesselAtPorts(...ports: MappedField[]): MappedField[] {
  return ports;
}
