<ng-container *ngIf="appContext.isAdmin()">
  <div class="dropdown always-enabled">
    <button class="btn btn-secondary" type="button" id="adminHamburgerMenu" data-bs-toggle="dropdown" aria-haspopup="true"
            aria-expanded="false">
      <!-- Hamburger menu icon -->
      <i class="fa fa-user-shield" aria-hidden="true"></i>
    </button>
    <div class="dropdown-menu dropdown-menu-end" aria-labelledby="adminHamburgerMenu">
      <a class="dropdown-item" tabindex="-1" role="button" (click)="openAcceptPlanningModal()">Respond to planning</a>
      <!-- Messages -->
      <h6 class="dropdown-header" style="font-size: 18px;">Messages</h6>
      <a *ngIf="terminalVisit?.status?.declarationDate" href="#" (click)="$event.preventDefault(); openIftsaiOut()" class="dropdown-item">
        IFTSAI with Terminal
      </a>
    </div>
  </div>

  <app-register-terminal-planning [berthVisit]="berthVisit"></app-register-terminal-planning>
</ng-container>
