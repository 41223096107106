import {Component, OnInit} from "@angular/core";
import {openEditModal, sendQuery, uuid} from "../../common/utils";
import {PortvisitUtils} from "../../refdata/portvisit-utils";
import {Observable} from "rxjs";
import {BunkerProduct, FuelExemption} from "@portbase/bezoekschip-service-typescriptmodels";
import {BunkeringService} from "../bunkering.service";
import {EditFuelExemption} from "./edit/edit-fuel-exemption";

@Component({
  selector: 'app-fuel-exemptions',
  templateUrl: './fuel-exemptions.html',
  styleUrls: ['./fuel-exemptions.css']
})
export class FuelExemptions implements OnInit {
  fuelExemptions: FuelExemption[] = [];

  ngOnInit(): void {
    sendQuery('com.portbase.bezoekschip.common.api.visit.exemption.GetExemptions', {}).subscribe(
      result => {
        this.fuelExemptions = result;
      }
    );
  }

  addExemption() {
    openEditModal(EditFuelExemption, <FuelExemption>{
      id: uuid(),
      status: "new",
      "@class": "com.portbase.bezoekschip.common.api.common.visit.exemptions.FuelExemption"
    });
  }

  products(term: string): Observable<BunkerProduct[]> {
    return BunkeringService.products(term);
  }

  formatProduct(product: BunkerProduct) {
    return product.type + ' (' + product.provisionType + ') ' + ' (' + product.density + ') ' + product.unitOfMeasure;
  }

  protected readonly refData = PortvisitUtils;
}
