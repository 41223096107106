import {AfterViewInit, Component, ElementRef, Input} from '@angular/core';
import {
  BaseForPlanning,
  BerthVisit,
  DeclarationType,
  GetCurrentUserPreferences,
  IsAllowedToRemoveBerth,
  PortEntryUnion,
  UserPreferences
} from '@portbase/bezoekschip-service-typescriptmodels';
import {AppContext} from 'src/app/app-context';
import {Alert} from 'src/app/common/status-alert/alert';
import {cloneObject, removeItem, scrollToTop, sendQuery, uuid} from 'src/app/common/utils';
import {VisitContext} from '../../visit-context';
import {ShipMovementService} from '../ship-movement.service';

@Component({
	selector: 'app-add-remove-berth',
	templateUrl: './add-remove-berth.component.html',
	styleUrls: ['./add-remove-berth.component.css']
})
export class AddRemoveBerthComponent implements AfterViewInit {

	context = VisitContext;
	appContext = AppContext;

	@Input()
	berthVisit: BerthVisit;

	@Input()
	isNextBerthVisits: boolean;

	@Input()
	isAddingFirstBerth: boolean;

	private emailAddress: string;
	private smsNumber: string;

	constructor(private shipMovementService: ShipMovementService, private elementRef: ElementRef) {
	}

	ngAfterViewInit(): void {
		sendQuery("com.portbase.bezoekschip.common.api.authorisation.GetCurrentUserPreferences", <GetCurrentUserPreferences>{})
			.subscribe(result => {
				this.emailAddress = (<UserPreferences>result).orderEmail;
				this.smsNumber = (<UserPreferences>result).orderSms;
			});
	}

	getShipStatus(): string {
		return this.shipMovementService.shipStatus(this.berthVisit);
	}

	addFirstBerth() {
		this.addBerth(this.context.visit.visitDeclaration.portVisit.berthVisits, 0, false);
	}

	addBerthBefore() {
		let berthVisits = this.getBerthVisits();
		let insertAtIndex = berthVisits.indexOf(this.berthVisit);
		this.addBerth(berthVisits, insertAtIndex, false);
	}

	addBerthAfter() {
		if (this.isLastBerth() && this.userIsNextOwnerOrDeclarant()) {
			this.context.visit.nextVisitDeclaration.nextBerthVisits.splice(0, 0,
				<BerthVisit>{
					id: uuid(),
					nextMovement: {},
					visitPurposes: []
				})
		} else {
			let berthVisits = this.getBerthVisits();
			let insertAtIndex = berthVisits.indexOf(this.berthVisit) + 1;
			this.addBerth(berthVisits, insertAtIndex, false);
		}
	}

  addTerminalPlanningBefore() {
    let berthVisits = this.getBerthVisits();
    let insertAtIndex = berthVisits.indexOf(this.berthVisit);
    this.addBerth(berthVisits, insertAtIndex, true);
  }

  addTerminalPlanningAfter() {
    if (this.isLastBerth() && this.userIsNextOwnerOrDeclarant()) {
      this.context.visit.nextVisitDeclaration.nextBerthVisits.splice(0, 0,
        <BerthVisit>{
          id: uuid(),
          nextMovement: {},
          visitPurposes: [],
          terminalPlanningEnabled: true
        })
    } else {
      let berthVisits = this.getBerthVisits();
      let insertAtIndex = berthVisits.indexOf(this.berthVisit) + 1;
      this.addBerth(berthVisits, insertAtIndex, true);
    }
  }

	isPermittedToAddFirstBerth(): boolean {
		return (this.userIsOwnerOrDeclarant() || this.appContext.isAdmin()) && this.context.isExpected();
	}

	isPermittedToAddBerthBefore(): boolean {
		return this.getShipStatus() === 'EXPECTED'&&
			(this.appContext.isAdmin()
				|| (this.isNextBerthVisits && this.userIsNextOwnerOrDeclarant())
				|| (!this.isNextBerthVisits && this.userIsOwnerOrDeclarant()));
	}

	isPermittedToAddBerthAfter(): boolean {
		if (this.getShipStatus() === 'DEPARTED') {
			return false;
		} else {
			return ((this.isBerthOfTransfer() || this.isNextBerthVisits) && this.userIsNextOwnerOrDeclarant())
				|| (!(this.isBerthOfTransfer() || this.isNextBerthVisits) && (this.userIsOwnerOrDeclarant() || this.appContext.isAdmin()));
		}
	}

  isPermittedToAddTerminalPlanningBefore(): boolean {
    return this.appContext.isTerminalPlanner() && this.isPermittedToAddBerthBefore() && this.context.visit.terminalPlanningEnabled;
  }

  isPermittedToAddTerminalPlanningAfter(): boolean {
    return this.appContext.isTerminalPlanner() && this.isPermittedToAddBerthAfter() && this.context.visit.terminalPlanningEnabled;
  }

	isPermittedToDeleteBerth() {
		// EXCLUSIONS //
		// Cannot delete a berth that is potentially being worked on by the next agent
		if(this.isBerthOfTransfer()) {
			return false;
		}
		// Cannot delete a berth to which waste is being delivered
		if(this.hasWasteItemsScheduledForPickup()) {
			return false;
		}
		// Cannot delete berth if a ship has already arrived
		if(this.berthVisit.ata) {
			return false;
		}
    // Cannot to delete the last terminal planning
    if (this.context.visitInTerminalPlanning() && this.context.visit.visitDeclaration.portVisit.berthVisits.length == 1) {
      return false;
    }

		// INCLUSIONS //
		// Administrators can delete berths
		if(this.appContext.isAdmin()) {
			return true;
		}
		// Next agent can delete (their own) berths
		if(this.isNextBerthVisits && this.userIsNextOwnerOrDeclarant()) {
			return true;
		}
		// Current agent can delete berths
		if(!this.isNextBerthVisits && this.userIsOwnerOrDeclarant()) {
			return true;
		}
	}

	moveBerthDown() {
		if(this.isNextBerthVisits)
		{
			var nextBerthVisits = this.context.visit.nextVisitDeclaration.nextBerthVisits;
			this.swapBerthWithBerthBelow(nextBerthVisits.indexOf(this.berthVisit), this.context.visit.nextVisitDeclaration.nextBerthVisits);

		}
		else {
			var berthVisits = this.context.visit.visitDeclaration.portVisit.berthVisits;
			this.swapBerthWithBerthBelow(berthVisits.indexOf(this.berthVisit), this.context.visit.visitDeclaration.portVisit.berthVisits);
		}
	}

	moveBerthUp() {
		if(this.isNextBerthVisits)
		{
			var nextBerthVisits = this.context.visit.nextVisitDeclaration.nextBerthVisits;
			this.swapBerthWithBerthAbove(nextBerthVisits.indexOf(this.berthVisit), this.context.visit.nextVisitDeclaration.nextBerthVisits);

		}
		else {
			var berthVisits = this.context.visit.visitDeclaration.portVisit.berthVisits;
			this.swapBerthWithBerthAbove(berthVisits.indexOf(this.berthVisit), this.context.visit.visitDeclaration.portVisit.berthVisits);
		}
	}

	private swapBerthWithBerthAbove(index: number, berthVisits: BerthVisit[]) {
		if (index === 0 || berthVisits.length < 2) {
			return;
		}
		berthVisits[index] = berthVisits.splice(index - 1, 1, berthVisits[index])[0];
    if (index == 1) {
      this.context.updateCalculatedEtas();
    }
		this.triggerValidityCheck();
	}

	private swapBerthWithBerthBelow(index: number, berthVisits: BerthVisit[]) {
		if (index === berthVisits.length - 1 || berthVisits.length < 2) {
			return;
		}
		berthVisits[index] = berthVisits.splice(index + 1, 1, berthVisits[index])[0];
		this.triggerValidityCheck();
	}

	isPermittedToMoveBerthDown(): boolean {
		if (this.isNextBerthVisits) {
			var nextBerthVisits = this.context.visit.nextVisitDeclaration.nextBerthVisits;
			return nextBerthVisits.length !== 1 && nextBerthVisits.indexOf(this.berthVisit) !== nextBerthVisits.length -1;
		}
		else {
			return !this.berthVisit.ata && !this.nextBerthIsBerthOfTransfer() && !this.isBerthOfTransfer() && !this.isLastBerth();
		}
	}

	isPermittedToMoveBerthUp(): boolean {
		if (this.isNextBerthVisits) {
			var nextBerthVisits = this.context.visit.nextVisitDeclaration.nextBerthVisits;
			return nextBerthVisits.length !== 1 && nextBerthVisits.indexOf(this.berthVisit) !== 0;
		}
		else {
			return !this.berthVisit.ata && !this.previousBerthHasAta() && !this.isBerthOfTransfer();
		}
	}

	private nextBerthIsBerthOfTransfer() {
		return this.context.visit.visitDeclaration.portVisit.berthVisits.length ===
			(this.context.visit.visitDeclaration.portVisit.berthVisits.indexOf(this.berthVisit) + 2)  && !!this.context.visit.nextDeclarant;
	}

	private previousBerthHasAta() {
		var index = this.context.visit.visitDeclaration.portVisit.berthVisits.indexOf(this.berthVisit);
		return index === 0 || this.context.visit.visitDeclaration.portVisit.berthVisits[index - 1].ata;
	}

	isBerthOfTransfer(): boolean {
		return this.isLastBerth() && !!this.context.visit.nextDeclarant;
	}

	private isLastBerth(): boolean {
		let berthVisits = VisitContext.visit.visitDeclaration.portVisit.berthVisits;
		return berthVisits.indexOf(this.berthVisit) === berthVisits.length - 1;
	}

	private getBerthVisits(): BerthVisit[] {
		return this.isNextBerthVisits ? this.context.visit.nextVisitDeclaration.nextBerthVisits : this.context.visit.visitDeclaration.portVisit.berthVisits;
	}

	private userIsOwnerOrDeclarant(): boolean {
		return this.appContext.userProfile.organisation?.shortName === this.context.visit.declarant.shortName
      || this.appContext.userProfile.organisation?.shortName === this.context.visit.owner.shortName;
	}

	private userIsNextOwnerOrDeclarant(): boolean {
		return this.context.isOrganisationNextDeclarant();
	}

	private addBerth(berthVisits: BerthVisit[], index: number, isTerminalPlanning: boolean) {
		let tugboatAtArrival = null;
		let boatmenAtArrival = null;
		if (index > 0) {
			let previousBerthVisit = berthVisits[index -1];
			tugboatAtArrival = previousBerthVisit.tugboatAtDeparture;
			boatmenAtArrival = previousBerthVisit.boatmenAtDeparture;
		}
		if (this.isNextBerthVisits) {
			berthVisits.splice(index, 0,
				<BerthVisit>{
					id: uuid(),
					nextMovement: {
						orderEmail: this.emailAddress,
						orderSms: this.smsNumber
					},
					visitPurposes: [],
					boatmenAtArrival: boatmenAtArrival,
					tugboatAtArrival: tugboatAtArrival,
          terminalPlanningEnabled: isTerminalPlanning
				});
			return;
		} else {
			if (index === 0) {
				this.context.updateCalculatedEtas();
				this.context.clearDependency();
				this.context.visit.visitDeclaration.portVisit.firstMovement['ngInvalid'] = true;
			}
			// this.setBerthVisitModelValidation();
			berthVisits.splice(index, 0, <BerthVisit>{
				id: uuid(),
				nextMovement: {
					orderEmail: this.emailAddress,
					orderSms: this.smsNumber
				},
				visitPurposes: [],
				tugboatAtDeparture: tugboatAtArrival,
				boatmenAtDeparture: boatmenAtArrival,
				boatmenAtArrival: boatmenAtArrival,
				tugboatAtArrival: tugboatAtArrival,
        terminalPlanningEnabled: isTerminalPlanning
			});
			this.context.visit.visitDeclaration.portVisit.passingThroughTugboats = null;
			this.triggerValidityCheck();
		}
	}

	deleteBerth(): void {
		if (this.isNextBerthVisits) {
			// Remove berth from global array
			const berthVisits = this.getBerthVisits();
			berthVisits.splice(berthVisits.indexOf(this.berthVisit), 1);
		} else {
			if (this.isPermittedToDeleteBerth()) {
				const backup: BerthVisit[] = this.cloneBerthVisits();
				const portEntryBackup: PortEntryUnion = this.clonePortEntry();

				// Delete berth
				removeItem(this.getBerthVisits(), this.berthVisit)

				// Invalidate first movement
				if (backup[0].id === this.berthVisit.id) {
					this.context.visit.visitDeclaration.portVisit.firstMovement['ngInvalid'] = true;
				}

				if (VisitContext.visit.orderIncomingMovement && backup[0].id === this.berthVisit.id) {
					this.context.clearDependency();
					this.context.updateCalculatedEtas();
					if (this.cloneBerthVisits().length == 0) {
						this.context.visit.visitDeclaration.portVisit.portEntry.baseForPlanning =
              BaseForPlanning.PILOT_BOARDING_PLACE;
					}
				}

				if (VisitContext.declarationStatusOf(DeclarationType.VISIT) != null && this.berthVisit.berth != null) {
					const errorText = 'Deleting berth ' + this.berthVisit.berth.name + ' is not allowed, because dangerous cargo or dangerous cargo cleaning is registered and/or reported for the berth.';
					const removedId = this.berthVisit.id;
					sendQuery('com.portbase.bezoekschip.common.api.visit.IsAllowedToRemoveBerth', <IsAllowedToRemoveBerth>{
						crn: this.context.visit.crn,
						berthVisit: this.berthVisit
					}).subscribe(result => {
						if (!result && this.cloneBerthVisits().filter(berth => berth.id === removedId).length == 0) {
							VisitContext.visit.visitDeclaration.portVisit.berthVisits = backup;
							VisitContext.visit.visitDeclaration.portVisit.portEntry = portEntryBackup;
							AppContext.registerError(errorText);
							scrollToTop();
						}
					}, () => {
						if (this.cloneBerthVisits().filter(berth => berth.id === removedId).length == 0) {
							VisitContext.visit.visitDeclaration.portVisit.portEntry = portEntryBackup;
							AppContext.registerError(errorText);
							scrollToTop();
						}
					})
				}
			}
		}
	};

	hasWasteItemsScheduledForPickup(): boolean {
		return this.context.visit.wasteDeclaration
			&& this.context.visit.wasteDeclaration.wasteItems.filter(wasteItem => wasteItem.berthIdForPickup === this.berthVisit.id).length > 0
	}

	cloneBerthVisits(): BerthVisit[] {
		return cloneObject(VisitContext.visit.visitDeclaration.portVisit.berthVisits);
	}

	clonePortEntry(): PortEntryUnion {
		return cloneObject(VisitContext.visit.visitDeclaration.portVisit.portEntry);
	}

	addBerthOriginal = () => {
		if (this.userIsOwnerOrDeclarant() || this.appContext.isAdmin()) {
			let berthVisits = this.context.visit.visitDeclaration.portVisit.berthVisits;
			let newBerthVisit = <BerthVisit>{
				id: uuid(),
				nextMovement: {
					orderEmail: this.emailAddress,
					orderSms: this.smsNumber
				},
				visitPurposes: []
			};
			if (this.context.visit.nextDeclarant) {
				berthVisits.splice(berthVisits.length - 1, 0, newBerthVisit);
			} else {
				berthVisits.push(newBerthVisit);
			}
			this.context.visit.visitDeclaration.portVisit.passingThroughTugboats = null;
			this.triggerValidityCheck();
		} else if (this.userIsNextOwnerOrDeclarant()) {
			this.context.visit.nextVisitDeclaration.nextBerthVisits = this.context.visit.nextVisitDeclaration.nextBerthVisits || [];
			this.context.visit.nextVisitDeclaration.nextBerthVisits.push(<BerthVisit>{
				id: uuid(),
				nextMovement: {
					orderEmail: this.emailAddress,
					orderSms: this.smsNumber
				},
				visitPurposes: []
			});
		} else {
			this.appContext.addAlert(<Alert>{
				content: "You are not allow to add a new berth visit", level: "warning", type: "visit"
			})
		}
	};

	private triggerValidityCheck = () => {
		let event;
		if (typeof (Event) === 'function') {
			event = new Event('change');
		} else {
			event = document.createEvent('Event');
			event.initEvent('change', true, true);
		}
		setTimeout(() => this.elementRef.nativeElement.dispatchEvent(event, 0));
	};

}
