import {Component, NgZone, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {
  BulkRedeclareCommercialReleases,
  CancelCommercialRelease,
  CommercialRelease,
  DateTimeRange,
  FindCommercialReleases,
  RedeclareCommercialReleaseData
} from '@portbase/bezoekschip-service-typescriptmodels';
import {AppContext} from '../app-context';
import {
  nonNull,
  openConfirmationModalWithCallback,
  removeIf,
  removeItem,
  replaceItem,
  sendCommand,
  sendCommandAndWait,
  sendQuery,
  toWebsocketUrl
} from '../common/utils';
import {AbstractOverviewComponent} from '../common/abstract-overview.component';
import {combineLatest, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {CommercialReleaseFilter} from './commercial-release-filter/commercial-release-filter.component';
import moment from 'moment';
import {PortvisitUtils} from '../refdata/portvisit-utils';
import {
  commercialReleaseDateComparator,
  commercialReleaseItemComparator,
  commercialReleaseStatusComparator,
  initializeCommercialRelease,
  terminals
} from "./commercial-release.utils";
import lodash from 'lodash';
import {EventGateway} from '../common/event-gateway';
import {exportDataAsExcel} from '../common/upload/excel.utils';
import {ModalConfirmAutofocus, ModalConfirmAutofocusData} from "../common/modal/modal-confirm.component";

@Component({
  selector: 'app-commercial-release-overview',
  templateUrl: './commercial-release-overview.component.html',
  styleUrls: ['./commercial-release-overview.component.css']
})

export class CommercialReleaseOverviewComponent extends AbstractOverviewComponent<CommercialRelease> implements OnInit, OnDestroy {
  protected readonly terminals = terminals;

  appContext = AppContext;
  refData = PortvisitUtils;
  commercialReleases: CommercialRelease[] = [];
  socket: WebSocket;
  commercialReleaseFilter: CommercialReleaseFilter = {deselectedOptions: [], selectedOptions: [], checkCondition: null};
  sortBy = 'date';

  redeclareData: RedeclareCommercialReleaseData = {};
  @ViewChild("bulkRedeclareBody") bulkRedeclareBody: TemplateRef<any>;

  constructor(private zone: NgZone, private eventGateway: EventGateway) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.subscribeToCommercialReleaseUpdates();
  }

  localStorageKey(): string {
    return "release-overview";
  }

  get visibleReleases(): CommercialRelease[] {
    return this.commercialReleases.filter(s => !s['hidden']);
  }

  doLoad(dateTimeRange: DateTimeRange): Observable<(CommercialRelease)[]> {
    return sendQuery('com.portbase.bezoekschip.common.api.visit.FindCommercialReleases',
      <FindCommercialReleases>{
        dateTimeRange: !!this.filterTerm ? null : dateTimeRange,
        declarantShortName: AppContext.isAdmin() ? null : AppContext.userProfile.organisation?.shortName,
        term: this.filterTerm,
        maxHits: computeMaxHits(),
      }, {caching: false, showSpinner: true})
      .pipe(map((d: CommercialRelease[]) => {
        return this.zone.runOutsideAngular(() => this.items.filter(d => !d.releaseStatus?.status)
          .concat(d.map(declaration => initializeCommercialRelease(declaration))));
      }));

    function computeMaxHits(): number {
      const start = moment(dateTimeRange.start);
      const end = moment(dateTimeRange.end);
      const days = end.diff(start, 'days');
      return 100 * Math.max(days, 1);
    }
  }

  doRender = (declarations: CommercialRelease[]) => {
    this.zone.runOutsideAngular(() => {
      if (this.commercialReleaseFilter.selectedOptions.length !== 0) {
        declarations = declarations.filter(t => this.commercialReleaseFilter.selectedOptions.some(option =>
          this.commercialReleaseFilter.checkCondition(option, t)
        ));
      }
      if (this.commercialReleaseFilter.deselectedOptions.length !== 0) {
        declarations = declarations.filter(t => !this.commercialReleaseFilter.deselectedOptions.some(option =>
          this.commercialReleaseFilter.checkCondition(option, t)
        ));
      }
      declarations = declarations.sort(this.getComparator()).slice(0, 200);
    });
    this.commercialReleases = declarations;
  };

  addNewRelease = () => {
    const release = initializeCommercialRelease();
    this.items.splice(0, 0, release);
    this.commercialReleases.splice(0, 0, release);
  };

  removeNewDeclaration(item: CommercialRelease) {
    return () => {
      removeItem(this.items, item);
      removeItem(this.commercialReleases, item);
    };
  }

  isNewDeclaration(item: CommercialRelease) {
    return !item.releaseStatus || !item.releaseStatus.status
  }

  applyTransitFilters(filter: CommercialReleaseFilter) {
    this.commercialReleaseFilter = filter;
    this.renderFilteredItems();
  }

  trackById(index: number, obj: CommercialRelease): any {
    return obj.id;
  }

  private getComparator(): (a, b) => number {
    switch (this.sortBy) {
      case 'item':
        return commercialReleaseItemComparator.compare;
      case 'itemReversed':
        return commercialReleaseItemComparator.reverseCompare;
      case 'statusReversed':
        return commercialReleaseStatusComparator.reverseCompare;
      case 'date':
        return commercialReleaseDateComparator.compare;
    }
    return commercialReleaseStatusComparator.compare;
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.commercialReleases.forEach(c => {
      delete c['selected'];
    });
    try {
      this.socket.onclose = () => {
      };
      this.socket.close();
      this.socket = null;
    } catch (ignored) {
    }
  }

  /*
    Handle websocket updates
   */

  private subscribeToCommercialReleaseUpdates = () => {
    if (environment.production || environment.docker) {
      try {
        this.socket = new WebSocket(toWebsocketUrl('/api/ui/commercial-release'));
      } catch (e) {
        console.info('Could not open websocket. Retrying every minute...', e);
        setTimeout(this.subscribeToCommercialReleaseUpdates, 60_000);
        return;
      }
      this.socket.onmessage = (message: MessageEvent) => {
        if (typeof message.data === 'string') {
          this.eventGateway.publish('commercialReleaseUpdate', JSON.parse(message.data));
        }
      };
      this.socket.onclose = (event: CloseEvent) => {
        if (!event.wasClean) {
          console.warn('Websocket closed with reason: ' + event.reason + ' (' + event.code + '). Trying to reconnect...');
        }
        setTimeout(() => this.subscribeToCommercialReleaseUpdates(), 5_000);
      };
    }
  };

  'commercialReleaseUpdate' = (d: CommercialRelease) => {
    let item = this.items.find(i => i.id === d.id);
    const newItem = this.items.find(i => !i.id &&
      i.releaseData?.consignmentNumber === d.releaseData?.consignmentNumber
      && i.releaseData?.equipmentNumber === d.releaseData?.equipmentNumber);
    if (!!item && !!newItem) {
      this.removeNewDeclaration(newItem)();
      replaceItem(this.items, item, item ? lodash.assign(item, d) : d);
    } else if (!item && !!newItem) {
      replaceItem(this.items, newItem, newItem ? lodash.assign(newItem, d) : d);
    } else {
      replaceItem(this.items, item, item ? lodash.assign(item, d) : d);
    }

    if (item) {
      removeIf(this.items, i => i.id === d.id && i !== item);
      removeIf(this.commercialReleases, i => i.id === d.id && i !== item);
    }
  };


  /*
    Bulk actions
   */

  get selectedItems(): CommercialRelease[] {
    return this.commercialReleases.filter(s => !!s['selected']);
  }

  toggleSelectAll = () => {
    if (this.selectedItems.length === this.visibleReleases.length) {
      this.visibleReleases.forEach(c => c['selected'] = false);
    } else {
      this.visibleReleases.forEach(c => c['selected'] = true);
    }
  };

  cancelSelected() {
    combineLatest(this.selectedItems.filter(i => !i.releaseStatus?.cancelled)
      .map(i => sendCommandAndWait('com.portbase.bezoekschip.common.api.commercialrelease.CancelCommercialRelease',
        <CancelCommercialRelease>{id: i.id})))
      .subscribe(() => AppContext.registerSuccess('The releases were cancelled successfully'),
        error => AppContext.registerError(error));
  }

  exportSelected() {
    let header = ["Container number", "Consignment number", "Terminal", "Carrier",
      "Release-to party", "Declaration date", "Status"];
    const data = [header];
    this.selectedItems.forEach(value => {
      const entry: string[] = [value.releaseData.equipmentNumber, value.releaseData.consignmentNumber,
        value.releaseData.terminalShortName, value.releaseData.containerOperator?.name,
        value.releaseData.releaseToParty?.name, value.releaseStatus?.declarationDate, value.releaseStatus?.status];
      data.push(entry);
    })
    exportDataAsExcel(data, 'releases.xlsx');
  }

  redeclareCommercialReleases() {
    openConfirmationModalWithCallback((confirmed) => {
      if (confirmed) {
        const ids = this.selectedItems.map(s => s.id).filter(nonNull);
        sendCommand("com.portbase.bezoekschip.common.api.commercialrelease.BulkRedeclareCommercialReleases", <BulkRedeclareCommercialReleases>{
          ids: ids,
          redeclareData: this.redeclareData
        }, () => {
          AppContext.registerSuccess(`Sucessfully changed the terminal of ${ids.length} commercial releases`);
          this.redeclareData = {};
        });
      }
    }, ModalConfirmAutofocus, <ModalConfirmAutofocusData>{
      type: "primary",
      title: "Change terminal",
      confirmText: "Send",
      cancelText: "Cancel",
      modalSize: "lg",
      body: this.bulkRedeclareBody
    }, 'static')
  }
}
