import {Component} from '@angular/core';
import {ExemptionBaseComponent} from "../exemption_base/exemption_base.component";
import {Exemption_3_5} from '@portbase/bezoekschip-service-typescriptmodels';

@Component({
  selector: 'app-visit-exemptions-exemption-3-5',
  templateUrl: './exemption_3_5.component.html',
  styleUrls: ['./exemption_3_5.component.css']
})
export class Exemption_3_5Component extends ExemptionBaseComponent {
  exemptionSubType : Exemption_3_5;
}

