import {Component, ElementRef, Input} from '@angular/core';
import {PortvisitUtils} from "../../../refdata/portvisit-utils";
import {
  Guarantee,
  RequestTransitGuaranteeBalance,
  SaveTransitGuarantee
} from "@portbase/bezoekschip-service-typescriptmodels";
import {QueryGateway} from "../../../common/query-gateway";
import {checkValidity, sendCommand} from "../../../common/utils";
import {AppContext} from "../../../app-context";
import moment from "moment";
import {Observable, of} from "rxjs";
import {map} from "rxjs/operators";
import {find} from "lodash";
import {fixUpTransitGuaranteeBeforeSubmission} from "../../transit.utils";

@Component({
  selector: 'app-transit-guarantee',
  templateUrl: './transit-guarantee.component.html',
  styleUrls: ['./transit-guarantee.component.css']
})

export class TransitGuaranteeComponent {
  refData = PortvisitUtils;
  appContext = AppContext;
  @Input() model: Guarantee;
  @Input() deleteHandle : () => any;

  constructor(private queryGateway: QueryGateway, public elementRef: ElementRef) {
  }

  delete() {
    this.deleteHandle();
  }

  save() {
    if (checkValidity(this.elementRef)) {
      const guaranteeData = fixUpTransitGuaranteeBeforeSubmission(this.model.guaranteeData);

      if (!guaranteeData) {
        AppContext.registerError("Guarantee type is not valid");
      } else {
        sendCommand('com.portbase.bezoekschip.common.api.transit.SaveTransitGuarantee', <SaveTransitGuarantee>{
          id: this.model.id,
          ownerShortName: this.model.ownerShortName,
          guaranteeData: guaranteeData
        }, () => {
          AppContext.registerSuccess('The guarantee was saved successfully.');
          delete this.model['new'];
          this.queryGateway.removeFromCache("com.portbase.bezoekschip.common.api.transit.FindGuarantees");
        });
      }
    }
  }

  requestBalance() {
    sendCommand('com.portbase.bezoekschip.common.api.transit.RequestTransitGuaranteeBalance', <RequestTransitGuaranteeBalance>{
      id: this.model.id,
    }, () => {
      AppContext.registerSuccess('The request for balance was send successfully.');
      this.queryGateway.removeFromCache("com.portbase.bezoekschip.common.api.transit.FindGuarantees");
    })
  }

  get expired() : boolean {
    let dateFrom = this.model.guaranteeData?.validFrom ? Date.parse(this.model.guaranteeData?.validFrom) : 0;
    let dateTo = this.model.guaranteeData?.validTo ? Date.parse(this.model.guaranteeData?.validTo) : Number.MAX_VALUE;
    let now = Date.now().valueOf();
    return !(dateFrom <= now && now <= dateTo);
  };

  get lastQueryDateIsToday() : boolean {
    return moment().isSame(this.model.timestamp, 'date');
  }

  get sendable() : Observable<boolean> {
    if (this.model['new']) {
      return of(false)
    }
    return this.getSelectedGuarantee().pipe(map(x => x?.withBalance || false))
  }

  get requiresGrn() : Observable<boolean> {
    return this.getSelectedGuarantee().pipe(map(x => x?.grnRequired || false))
  }

  get canHaveOtherReference() : Observable<boolean> {
    return this.getSelectedGuarantee().pipe(map(x => x?.otherReferenceAllowed || false));
  }

  get requiresOtherReference() : Observable<boolean> {
    return this.getSelectedGuarantee().pipe(map(x => x?.otherReferenceRequired || false));
  }

  private getSelectedGuarantee() {
    return PortvisitUtils.getGuaranteeTypes().pipe(map(types => find(types, element => element.code == this.model?.guaranteeData?.type?.code)));
  }
}
