import {Component} from '@angular/core';
import {
  DangerInformation,
  Exemption_6_3_3,
  Exemption_6_3_3_PlaceType,
  Exemption_6_3_3_ReasonType, Vessel
} from '@portbase/bezoekschip-service-typescriptmodels';
import {ExemptionBaseComponent} from "../exemption_base/exemption_base.component";
import {PortvisitUtils} from "../../../../../refdata/portvisit-utils";
import {sendQuery} from "../../../../../common/utils";

@Component({
  selector: 'app-visit-exemptions-exemption-6-3-3',
  templateUrl: './exemption_6_3_3.component.html',
  styleUrls: ['./exemption_6_3_3.component.css']
})
export class Exemption_6_3_3Component extends ExemptionBaseComponent {

  exemptionSubType: Exemption_6_3_3;

  portVisitUtils = PortvisitUtils;
  dangerousGoods?: DangerInformation;

  vessel: Vessel;

  searchVessel = term => sendQuery('com.portbase.bezoekschip.common.api.visit.FindVessels', {term: term});

  placeTypes: Exemption_6_3_3_PlaceType[] = [
    null,
    Exemption_6_3_3_PlaceType.ASHORE,
    Exemption_6_3_3_PlaceType.INTERNAL_SHIPMENT,
    Exemption_6_3_3_PlaceType.ANOTHER_VESSEL
  ];

  reasonTypes: Exemption_6_3_3_ReasonType[] = [
    null,
    Exemption_6_3_3_ReasonType.PERMANENT_TECHNICAL_DEFECT,
    Exemption_6_3_3_ReasonType.OPERATIONAL_LIMITATIONS,
    Exemption_6_3_3_ReasonType.OTHER
  ];

  placeTypesFormatter = (placeType: Exemption_6_3_3_PlaceType) => {
    switch (placeType) {
      case null:
        return 'None';
      case "ASHORE":
        return 'Ashore';
      case "INTERNAL_SHIPMENT":
        return 'Internal transhipment';
      case "ANOTHER_VESSEL":
        return 'Another vessel';
    }
  };

  reasonTypesFormatter = (reasonType: Exemption_6_3_3_ReasonType) => {
    switch (reasonType) {
      case null:
        return 'None';
      case "PERMANENT_TECHNICAL_DEFECT":
        return 'There is a permanent technical defect';
      case "OPERATIONAL_LIMITATIONS":
        return 'There are operational limitations of the vessel unloading facility';
      case "OTHER":
        return 'Other';
    }
  };

  vesselFormatter = (vessel: Vessel) => {
    return vessel.name + ' – ' + vessel.imoCode;
  };

  onVesselChange(vessel: Vessel) {
    this.exemption['vesselName'] = vessel.name;
    this.exemption['vesselIMO'] = vessel.imoCode;
  }

  onDangerousGoodsChange(dangerousGoods: DangerInformation) {
    this.exemption['substanceName'] = dangerousGoods.name;
    this.exemption['unVnNumber'] = dangerousGoods.unCode;
  }
}

