import {Component} from '@angular/core';
import {AppContext} from "../app-context";
import {Bunkering} from "@portbase/bezoekschip-service-typescriptmodels";

@Component({
  selector: 'app-bunkering',
  templateUrl: './bunkering.component.html',
  styleUrls: ['./bunkering.component.scss']
})
export class BunkeringComponent {
  appContext = AppContext;
}

export interface LocalBunkering extends Bunkering {
  draft: boolean;
}
