import {Component} from '@angular/core';
import {Exemption_4_6} from '@portbase/bezoekschip-service-typescriptmodels';
import {ExemptionBaseComponent} from "../exemption_base/exemption_base.component";

@Component({
  selector: 'app-visit-exemptions-exemption-4-6',
  templateUrl: './exemption_4_6.component.html',
  styleUrls: ['./exemption_4_6.component.css']
})
export class Exemption_4_6Component extends ExemptionBaseComponent {
  exemptionSubType: Exemption_4_6;
}

