import {Component, OnInit} from '@angular/core';
import {sendQuery} from 'src/app/common/utils';
import {VisitContext} from '../../visit-context';
import {PortvisitUtils} from "../../../refdata/portvisit-utils";

@Component({
  selector: 'app-nautical-services-inbound',
  templateUrl: './nautical-services-inbound.component.html',
  styleUrls: ['./nautical-services-inbound.component.css']
})
export class NauticalServicesInboundComponent implements OnInit {
  areNauticalServicesApplicable = PortvisitUtils.areNauticalServicesApplicable;
  hasPilots = PortvisitUtils.hasPilots;
  hasTugboats = PortvisitUtils.hasTugboats;
  hasBoatmen = PortvisitUtils.hasBoatmen;

  context = VisitContext;

  constructor() {
  }

  ngOnInit() {
  }

  pilotProvider = sendQuery(
    'com.portbase.bezoekschip.common.api.visit.GetPilots',
    {portUnCode: this.context.visit.portOfCall.port.locationUnCode});
  tugboatProvider = sendQuery(
    'com.portbase.bezoekschip.common.api.visit.GetTugboats',
    {portUnCode: this.context.visit.portOfCall.port.locationUnCode});
  boatmenProvider = sendQuery(
    'com.portbase.bezoekschip.common.api.visit.GetBoatmen',
    {portUnCode: this.context.visit.portOfCall.port.locationUnCode});
}
