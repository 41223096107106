import {exportDataAsExcel} from "../../common/upload/excel.utils";
import {IE3ConsignmentProcess, Visit} from "@portbase/bezoekschip-service-typescriptmodels";
import {cloneObject, lodash} from "../../common/utils";
import {formatTimestamp} from "../../common/date/time-utils";
import {ConsignmentUtils} from "./consignment.utils";

export class ConsignmentDownloadUtils {

  private static getFirstValidValue<T>(values: (T | null | undefined)[], defaultValue: T): T {
    return values.find(p => p) || defaultValue;
  }

  static downloadBlDetails(visit: Visit, consignments: IE3ConsignmentProcess[]) {
    const data =
      [["Call Reference Number", "Vessel name", "Voyage No", "PoL-UnCode", "PoL-Name", "B/L Number",
        "GI no", "Out.pkg", "Pack type", "Goods description",
        "Cargo weight", "Marks and numbers", "UNNO", "IMO",
        "Flashpoint", "Flashp.unit", "Temp.set", "Temp.unit",
        "ETA", "Terminal", "Place of acceptance", "Port of origin (code)", "Port of origin (name)",
        "In transit to (code)", "In transit to (name)", "Place of delivery",
        "Custom process", "Custom status", "Shipper", "Consignee", "Notify1", "Notify2", "Notify3", "Notify4", "Notify5",
        "Inner pkge", "Pack type", "Commodity code", "Container number", "Size type", "No. pkgs", "Cargo weight",
        "Tare weight", "Seal Shipper", "Seal Carrier", "Seal Customs", "Empty"]];

    const berthVisits = lodash.keyBy(visit.visitDeclaration.portVisit.berthVisits, v => v?.berth?.terminalCode);
    consignments.forEach(consignment => {
      const masterLevel = consignment.consignmentMasterLevel;
      const terminal = masterLevel?.dischargeTerminal;
      const placeOfLoading = masterLevel?.placeOfLoading;
      const berthVisit = terminal && berthVisits[terminal.terminalCode];
      const goodsItems = ConsignmentUtils.getGoodsOfConsignmentProcess(consignment);

      goodsItems.forEach(g => {
        const goodsItem = g.goodsItem;
        const dangerInfo = goodsItem.commodity.dangerousGoods;
        const placeOfAcceptance = this.getFirstValidValue(g.houseConsignments.map(h => h.placeOfAcceptance), masterLevel.placeOfAcceptance);
        const placeOfDelivery = this.getFirstValidValue(g.houseConsignments.map(h => h.placeOfDelivery), masterLevel.placeOfDelivery);
        const consignor = this.getFirstValidValue(g.houseConsignments.map(h => h.consignor), masterLevel.consignor);
        const consignee = this.getFirstValidValue(g.houseConsignments.map(h => h.consignee), masterLevel.consignee);
        const notifyParties = this.getFirstValidValue(g.houseConsignments.map(h => h.notifyParties), masterLevel.notifyParties);
        const goodData = [visit.crn, visit.vessel.name,
          visit.visitDeclaration.arrivalVoyage.voyageNumber,
          placeOfLoading.locationUnCode, placeOfLoading.name, masterLevel.consignmentNumber,

          goodsItem.goodsItemNumber, goodsItem?.outerPackaging?.numberOfPackages, goodsItem.outerPackaging?.typeOfPackages && goodsItem.outerPackaging?.typeOfPackages.code, goodsItem.commodity?.descriptionOfGoods,
          goodsItem.grossWeight, goodsItem.outerPackaging?.shippingMarks.join(', '), dangerInfo && dangerInfo.unCode, dangerInfo && dangerInfo.hazardClass,
          dangerInfo && dangerInfo.flashPoint, "CEL", goodsItem.minimumTemperature || goodsItem.maximumTemperature, "CEL",

          formatTimestamp(berthVisit && berthVisit.eta, 'D/M/YY H:mm'),
          berthVisit && berthVisit.berth.name, null,
          placeOfAcceptance && placeOfAcceptance.locationUnCode, placeOfAcceptance && placeOfAcceptance.name,
          placeOfDelivery && placeOfDelivery.locationUnCode, placeOfDelivery && placeOfDelivery.name,
          null, masterLevel.customsProcess, masterLevel.customsStatus,
          consignor && consignor.name,
          consignee && consignee.name,
          notifyParties.length > 0 ? notifyParties[0].name : null,
          notifyParties.length > 1 ? notifyParties[1].name : null,
          notifyParties.length > 2 ? notifyParties[2].name : null,
          notifyParties.length > 3 ? notifyParties[3].name : null,
          notifyParties.length > 4 ? notifyParties[4].name : null,

          lodash.sum(goodsItem.innerPackagingList?.map(i => i.numberOfPackages)),
          goodsItem.innerPackagingList?.map(i => i.typeOfPackages.code).join(', '),
          (goodsItem.commodity?.commodityCode?.harmonizedSystemSubHeadingCode || '') + (goodsItem.commodity?.commodityCode?.combinedNomenclatureCode || ''),
        ];

        if (goodsItem.goodsPlacements.length > 0) {
          goodsItem.goodsPlacements.forEach(p => {
            const containerData = cloneObject(goodData);
            const container = masterLevel.transportEquipmentMap[p.containerIdentificationNumber];
            containerData.push(
              p.containerIdentificationNumber, container && container.containerSizeAndType && container.containerSizeAndType.code,
              p.numberOfPackages, p.grossWeight, container && container.tareWeight,
              container && container.sealIdentifiers[2], container && container.sealIdentifiers[0],
              container && container.sealIdentifiers[1], container && (container.empty ? 'Y' : 'N')
            );
            data.push(containerData);
          })
        } else {
          data.push(goodData);
        }
      });
    });

    exportDataAsExcel(data, `${visit.crn}_blDetails.xlsx`);
  }

  static downloadMrns(crn: string, consignments: IE3ConsignmentProcess[]) {
    const data = [["Port of discharge", "Port of loading", "B/L number", "MRN number"]];
    consignments
      .filter(c => c.consignmentMasterLevel?.placeOfLoading
        && c.consignmentMasterLevel?.placeOfUnloading)
      .forEach(c => {
        data.push([
          c.consignmentMasterLevel.placeOfUnloading.locationUnCode,
          c.consignmentMasterLevel.placeOfLoading.locationUnCode,
          c.consignmentMasterLevel.consignmentNumber,
          c.ensMrn || ''
        ]);
      });
    exportDataAsExcel(data, `${crn}_movementReferenceNumbers.xlsx`);
  }

  static downloadClearanceDifferences(visit: Visit, consignments: IE3ConsignmentProcess[]) {
    const data =
      [["Vessel Call", "Vessel", "Voyage nr", "ATA-date", "ATA-time", "POL", "POD", "B/L nr", "PCS colli",
        "PCS weight (KGM)", "Remaining colli", "Remaining weight (KGM)", "Differences report date"]];

    const berthVisits = lodash.keyBy(visit.visitDeclaration.portVisit.berthVisits, v => v.berth.terminalCode);

    consignments.forEach(consignment => {
      const terminal = consignment.consignmentMasterLevel?.dischargeTerminal;
      const placeOfLoading = consignment.consignmentMasterLevel?.placeOfLoading;
      const placeOfUnloading = consignment.consignmentMasterLevel?.placeOfUnloading;
      const berthVisit = berthVisits[terminal.terminalCode];
      const goodsItems = ConsignmentUtils.getGoodsOfConsignmentProcess(consignment);
      const differenceSummary = consignment.status.clearanceStatus.expirySummary;
      const row = [visit.crn, visit.vessel.name,
        visit.visitDeclaration.arrivalVoyage.voyageNumber,
        formatTimestamp(berthVisit && berthVisit.ata, 'D-M-YYYY'),
        formatTimestamp(berthVisit && berthVisit.ata, 'HH:mm'),
        placeOfLoading.locationUnCode, placeOfUnloading.locationUnCode,
        consignment.consignmentMasterLevel.consignmentNumber,
        lodash.sumBy(goodsItems, g => Number(g.goodsItem.outerPackaging.numberOfPackages || 0)),
        lodash.sumBy(goodsItems, g => Number(g.goodsItem.grossWeight || 0)),
        lodash.sumBy(differenceSummary.items, d => Number(d.remainingPackages || 0)),
        lodash.sumBy(differenceSummary.items, d => Number(d.remainingWeight || 0)),
        formatTimestamp(differenceSummary.notificationTime, 'D-M-YYYY'),
      ];
      data.push(row);
    });

    exportDataAsExcel(data, `${visit.crn}_clearanceDifferences.xlsx`);
  }
}
